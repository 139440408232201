import { useTileContext } from "../tile.context";
import ClimateRelatedIncetiveChart from "components/visualizations/charts/climate.related.incetive.chart";
import CliamteRelatedIncentiveTable from "components/visualizations/charts/climate.related.incentive.table";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import TileEmptyContent from "../tile-empty-content";
import { valueSeparator } from "utils/constants";

const ClimateRelatedIncentive = () => {
  const {
    dataset,
    isTableViewActive,
    metadata: { benchmarkType, isDetailedView, associatedMetricsTooltips },
  } = useTileContext();
  if (
    dataset.length === 0 ||
    (dataset.length > 0 &&
      dataset.every((d: InsightData) =>
        d.metrics.every((m: InsightMetricData) => !m.metricValue)
      ))
  )
    return (
      <div>
        <TileEmptyContent />
      </div>
    );
  // Currently *no data available message is not required for this tile
  const valueMissing = dataset.some(
    (data: InsightData) =>
      data.metrics[0].metricValue === "" || data.metrics[0].metricValue === null
  );

  const getMetricsDeatil = (metrics: any) => {
    const modifiedData: any = [];
    let length = 0;
    metrics.forEach((met: any) => {
      if (met.metricValue !== null) {
        length = met.metricValue?.split(valueSeparator).length;
      }
    });
    if (
      metrics.every(
        (met: any) => met.metricValue === null || met.metricValue === ""
      )
    ) {
      modifiedData.push(metrics.map((met: any) => ""));
    }
    for (let i = 0; i < length; i++) {
      modifiedData.push(
        metrics.map((met: any) =>
          met.metricValue ? met.metricValue?.split(valueSeparator)[i] : ""
        )
      );
    }
    return modifiedData;
  };

  const mapData = () => {
    const filterDataset = dataset.map((data: any) => ({
      groupName: data.companyName,
      metrics: data.metrics,
      firstMetric: data.metrics[0],
      otherMetricDetail: getMetricsDeatil(data.metrics.slice(1)),
    }));

    return filterDataset;
  };

  mapData();

  return isTableViewActive ? (
    <CliamteRelatedIncentiveTable
      tableData={mapData()}
      valueMissing={valueMissing}
      benchmarkType={benchmarkType}
      associatedMetricsTooltip={associatedMetricsTooltips}
    />
  ) : (
    <>
      <ClimateRelatedIncetiveChart
        data={mapData()}
        benchmarkType={benchmarkType}
        isDetailedView={isDetailedView}
        associatedMetricsTooltip={associatedMetricsTooltips}
      />
    </>
  );
};

export default ClimateRelatedIncentive;
