import classNames from "classnames";
import { useEffect, useState } from "react";
import Overlay from "./overlay";
import { ESGReportLayout } from "services/document-viewer/document-viewer.model";
import { Tag, TagMetadata } from "services/tags/tags.model";

type Props = {
  BLOCK: string;
  pageId: string;
  reportId: number;
  metadata: ESGReportLayout;
  zoomLevel: number;
  highlights: {
    keywordTextContent: string;
    pageNumber: number;
    locations: any[];
    colorCode?: string;
  }[];
  showHighlights: boolean;
  rotation: number;
  changePageFunction: any;
  scrollToHightlightPage: number;
  setScrollToHightlightPage: any;
  documentRef: any;
  comparisonItemId: number;
  pageTags: TagMetadata[];
  tagsList: Tag[];
};

const Page = ({
  BLOCK,
  pageId,
  reportId,
  metadata,
  zoomLevel,
  highlights,
  showHighlights,
  rotation,
  scrollToHightlightPage,
  setScrollToHightlightPage,
  documentRef,
  comparisonItemId,
  pageTags,
  tagsList,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [selection, setSelection] = useState<any>(null);
  const [pageImage, setPageImage] = useState<any>();

  const pageStyles = {
    width: `${metadata.width * zoomLevel}px`,
    height: `${metadata.height * zoomLevel}px`,
  };
  const [containerStyle, setContainerStyle] = useState<any>({});

  useEffect(() => {
    setSelection(null);
    if (rotation === -0 || rotation === -180) {
      setContainerStyle({
        width: `${metadata.width * zoomLevel}px`,
        height: `${metadata.height * zoomLevel}px`,
      });
    } else {
      setContainerStyle({
        height: `${metadata.width * zoomLevel}px`,
        width: `${metadata.height * zoomLevel}px`,
      });
    }
  }, [rotation, metadata, zoomLevel]);

  useEffect(() => {
    setPageImage(null);
    setLoading(true);
  }, [reportId]);

  const handleContextMenu = (e: any) => {
    e.preventDefault();
  };

  return (
    <div
      id={`${pageId}${metadata.pageNumber}`}
      style={containerStyle}
      className={`${BLOCK}__page-container`}
      onContextMenu={handleContextMenu}
      data-testid="page-component"
    >
      <div
        style={{ ...pageStyles, transform: `rotate(${rotation}deg)` }}
        className={classNames(`${BLOCK}__page`, {
          [`${BLOCK}__page--loading`]: loading,
        })}
      >
        <Overlay
          BLOCK={BLOCK}
          zoomLevel={zoomLevel}
          highlights={highlights}
          showHighlights={showHighlights}
          scrollToHightlightPage={scrollToHightlightPage}
          setScrollToHightlightPage={setScrollToHightlightPage}
          documentRef={documentRef}
          metadata={metadata}
          pageImage={pageImage}
          setPageImage={setPageImage}
          setLoading={setLoading}
          reportId={reportId}
          selection={selection}
          setSelection={setSelection}
          comparisonItemId={comparisonItemId}
          pageTags={pageTags}
          tagsList={tagsList}
        />
        {!loading && <img style={pageStyles} src={pageImage} alt="" />}
      </div>
    </div>
  );
};

export default Page;
