import Modal, { ModalWidth } from "../modal/modal";
import Button from "../button/button";

type props = {
  show: boolean;
  title?: string;
  handleCloseModal: () => void;
  handleConfirm: () => void;
  content: any;
  customClass: string;
  customTitle?: JSX.Element;
  customConfirmBtnText?: string;
  customCancelBtnText?: string;
};

const ConfirmationModal = ({
  show,
  title,
  handleCloseModal,
  handleConfirm,
  content,
  customClass,
  customTitle,
  customConfirmBtnText,
  customCancelBtnText,
}: props) => {
  const BLOCK = "confirmation-modal";

  return (
    <Modal
      show={show}
      header={title}
      handleClose={handleCloseModal}
      config={false}
      data-testid="confirmation-modal"
      customHeader={customTitle}
      modalWidth={ModalWidth.small}
    >
      <div className={`${BLOCK} ${customClass}`}>
        <div className={`${BLOCK}__content-wrapper`}>{content}</div>
        <div className={`${BLOCK}__options`}>
          <Button
            formattedText={customCancelBtnText ?? "cancel"}
            dataTest="no-btn"
            onClick={handleCloseModal}
            className={`button-secondary`}
          ></Button>

          <Button
            formattedText={customConfirmBtnText ?? "confirm"}
            dataTest="yes-btn"
            onClick={handleConfirm}
            className={`button-primary`}
          ></Button>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmationModal;
