import { Group } from "@visx/group";
import React, { Fragment, useMemo } from "react";
import { scaleBand, scaleLinear } from "@visx/scale";
import { ScaleSVG } from "@visx/responsive";
import { GroupedLegends, Legend } from "services/dashboard/dashboard.model";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { defaultStyles, withTooltip } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { GridRows } from "@visx/grid";
import { darkGrey, lightColor } from "utils/constants";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { isInteger } from "lodash";
import { Bar } from "@visx/shape";
import { getMarginBasedOnMax, isNumeric } from "utils/functions";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import classNames from "classnames";
import Popover from "components/shared/popover/popover";

const axisBaseOffset = -0.25;
const axisOffsetMultiplier = -0.45;

const tooltipStyles = {
  ...defaultStyles,
  zIndex: 2,
  minWidth: 50,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
  fontSize: 15,
  width: "fit-content",
};

type Props = {
  data: Legend[] | GroupedLegends[];
  name: string;
  index: number;
  width?: number;
  height?: number;
  axis?: { primary: string[]; secondary: string[] };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  verticalOrientation?: boolean;
  dahsedGridLine?: boolean;
  hideDashedBaseLine?: boolean;
  hideDatasetInfo?: boolean;
  displayNoDataPattern?: boolean;
};

type GraphProps = {
  data: Legend[] | GroupedLegends[];
  name: string;
  width: number;
  height: number;
  axis?: { primary: string[]; secondary: string[] };
  margin?: { top: number; right: number; bottom: number; left: number };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  showSpaceWhenEmpty?: boolean;
  prefix: string;
  dahsedGridLine?: boolean;
  hideDashedBaseLine?: boolean;
  displayNoDataPattern?: boolean;
};

const IndividualChart = ({
  data,
  axis,
  name,
  width,
  height,
  margin = { top: 10, right: 15, bottom: 5, left: 40 },
  prefix = "",
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
  handleGraphElementClicked,
  dahsedGridLine,
  hideDashedBaseLine,
  displayNoDataPattern,
}: GraphProps & WithTooltipProvidedProps<Legend>) => {
  const dataset = data as Legend[];
  let isHavingOnlyZeroValues: boolean = false;
  isHavingOnlyZeroValues =
    (dataset.length === 1 && dataset[0].legendData === 0) ||
    (dataset &&
      dataset.every(
        (data) =>
          data.legendData === "0" ||
          data.legendData === "*" ||
          data.legendData === null
      ));
  let tooltipTimeout: number;
  margin.left = getMarginBasedOnMax(
    Math.max(
      ...dataset.map(({ legendData }) =>
        legendData && isNumeric(legendData) ? parseFloat(legendData) : 0
      ),
      0
    )
  );
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const yMaxLegend = Math.max(...dataset.map((d) => d.legendData ?? 0));
  const topOffset = yMaxLegend > 0 ? 0 : height / 2 - 16;

  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: dataset.map((d) => d.legendValue),
        padding: 0.3,
      }),
    [xMax, dataset]
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        nice: true,
        clamp: true,
        domain: [0, yMaxLegend],
      }),
    [yMax, yMaxLegend]
  );

  return (
    <div style={{ position: "relative" }}>
      <ScaleSVG width={width} height={height}>
        <Group left={margin.left + (prefix ? 15 : 1)} top={margin.top}>
          <GridRows
            scale={yScale}
            width={xMax}
            height={yMax}
            stroke={lightColor}
            top={topOffset}
            strokeDasharray={dahsedGridLine ? "5,5" : ""}
          />
          <AxisLeft
            strokeWidth={1}
            scale={yScale}
            stroke={lightColor}
            tickStroke={lightColor}
            top={topOffset}
            tickValues={yScale
              .ticks()
              .filter(isInteger)
              .filter((v) => v >= 0)}
            tickFormat={(tick: any) =>
              parseInt(tick.toFixed()).toLocaleString() + prefix
            }
            tickComponent={(tickProps) => {
              const valueLength = tickProps.formattedValue
                ? tickProps.formattedValue.length
                : 1;

              const dx = axisBaseOffset + valueLength * axisOffsetMultiplier;

              return (
                <text
                  fontFamily="Open Sans"
                  fontSize={12}
                  textAnchor="start"
                  dx={`${dx}em`}
                  dy={tickProps.dy}
                  x={tickProps.x}
                  y={tickProps.y}
                >
                  <tspan x={tickProps.x} dy="0.4em">
                    {tickProps.formattedValue}
                  </tspan>
                </text>
              );
            }}
          />
          {dahsedGridLine ? (
            <AxisBottom
              strokeWidth={1}
              scale={xScale}
              stroke={lightColor}
              top={yMax}
              hideTicks
            />
          ) : null}
          {dataset
            .filter((d, i) => displayNoDataPattern || d.legendData !== null)
            .sort((d1, d2) => d1.legendOrder - d2.legendOrder)
            .map((data, i) => {
              const barHeightTmp: number =
                isHavingOnlyZeroValues && data.legendData !== "*"
                  ? yMax - yMax * 0.95
                  : isNumeric(data.legendData) &&
                    parseFloat(data.legendData) >= 0
                  ? yMax -
                    (yScale(
                      data.legendData > 0 ? data.legendData : yMaxLegend / 100
                    ) ?? 0)
                  : !isNumeric(data.legendData) && displayNoDataPattern
                  ? yMax - yScale(yMaxLegend / 10)
                  : 0;
              const barHeight =
                barHeightTmp <= 1 && Math.round(data.legendData) > 0
                  ? 1
                  : barHeightTmp;
              const barWidth = xScale.bandwidth();
              const barX: number = xScale(dataset[i].legendValue) || 0;
              const barY: number = yMax - barHeight;
              return barX >= 0 &&
                barY >= 0 &&
                barWidth >= 0 &&
                barHeight >= 0 ? (
                <Fragment key={`bar-${data.legendValue}-${i * 100}`}>
                  {/* An invisible bar to extend the tooltip area so that it is easier to hover over the bar */}
                  {barHeight <= 8 && data.legendData && (
                    <Bar
                      key={`bar-${data.legendValue}-${i * 10000}`}
                      x={barX}
                      y={barY - 20} // extend the bar 20px upwards
                      width={barWidth}
                      height={barHeight + 20} // increase the height by 20px
                      fillOpacity={0} // make the bar invisible
                      onMouseLeave={() =>
                        (tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 100))
                      }
                      onMouseMove={() => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        showTooltip({
                          tooltipData: data,
                          tooltipTop: isHavingOnlyZeroValues
                            ? barY - barHeight * 10
                            : barY,
                          tooltipLeft: barX,
                        });
                      }}
                    />
                  )}
                  <Bar
                    key={`bar-${data.legendValue}-${i}`}
                    x={barX}
                    y={barY}
                    width={barWidth}
                    height={barHeight}
                    style={
                      handleGraphElementClicked ? { cursor: "pointer" } : {}
                    }
                    strokeWidth={
                      displayNoDataPattern && !data.legendData ? 0.5 : ""
                    }
                    stroke={
                      displayNoDataPattern && !data.legendData
                        ? darkGrey
                        : "unset"
                    }
                    fillOpacity={
                      tooltipOpen &&
                      tooltipData?.legendValue !== data.legendValue
                        ? 0.4
                        : 1
                    }
                    fill={
                      displayNoDataPattern &&
                      (data.legendData === null || data.legendData === "*")
                        ? `url(#GradientRepeat)`
                        : data.legendColor
                    }
                    onMouseLeave={() =>
                      (tooltipTimeout = window.setTimeout(() => {
                        hideTooltip();
                      }, 100))
                    }
                    onClick={() =>
                      handleGraphElementClicked
                        ? handleGraphElementClicked(data)
                        : null
                    }
                    onMouseMove={() => {
                      if (tooltipTimeout) clearTimeout(tooltipTimeout);
                      showTooltip({
                        tooltipData: data,
                        tooltipTop: isHavingOnlyZeroValues
                          ? barY - barHeight * 10
                          : barY,
                        tooltipLeft: barX,
                      });
                    }}
                  ></Bar>
                </Fragment>
              ) : null;
            })}
          {displayNoDataPattern ? (
            <pattern
              id={`GradientRepeat`}
              patternUnits="userSpaceOnUse"
              width="8"
              height="8"
              patternTransform="rotate(135)"
            >
              <line
                x1="0"
                y="0"
                x2="0"
                y2="8"
                stroke={darkGrey}
                strokeWidth="1"
              />
            </pattern>
          ) : null}
        </Group>
      </ScaleSVG>
      {dataset.length &&
      !hideDashedBaseLine &&
      dataset[0].legendData !== null &&
      dataset[0].legendData !== "*" &&
      Math.round(dataset[0].legendData).toString() !== "0" ? (
        <div
          className=""
          style={{
            width: xMax,
            top:
              topOffset + margin.top + yScale(dataset[0].legendData ?? 0.1) ??
              0,
            left: margin.left,
            position: "absolute",
            borderBottom: `0.125rem dashed ${dataset[0].legendColor}`,
          }}
          onMouseLeave={() =>
            (tooltipTimeout = window.setTimeout(() => {
              hideTooltip();
            }, 100))
          }
          onClick={() =>
            handleGraphElementClicked
              ? handleGraphElementClicked(dataset[0])
              : null
          }
          onMouseMove={() => {
            if (tooltipTimeout) clearTimeout(tooltipTimeout);
            showTooltip({
              tooltipData: dataset[0],
              tooltipTop: yScale(dataset[0].legendData ?? 0.1) ?? 0,
              tooltipLeft: margin.left,
            });
          }}
        ></div>
      ) : null}
      {tooltipOpen && tooltipData && (
        <div
          style={{
            ...tooltipStyles,
            top: tooltipTop! + topOffset,
            left: tooltipLeft,
            position: "absolute",
          }}
        >
          <strong>
            {
              (tooltipData.legendTooltip
                ? tooltipData.legendTooltip
                : tooltipData.legendValue) as React.ReactNode
            }
          </strong>
        </div>
      )}
    </div>
  );
};

const MultipleBarChart = ({
  data,
  name,
  axis,
  width = 1000,
  height = 1000,
  handleGraphElementClicked,
  verticalOrientation,
  dahsedGridLine,
  hideDashedBaseLine,
  hideDatasetInfo,
  displayNoDataPattern,
}: Props) => {
  const dataset = data as GroupedLegends[];
  const noData = dataset.reduce(
    (acc, group) =>
      acc &&
      group.legends.reduce(
        (acc, curr) => acc && curr.legendData === null,
        true
      ),
    true
  );
  if (noData)
    return (
      <div className="speedometer__no-data">
        <FormattedMessage id="insights.speedometer.no-data" />
      </div>
    );
  const Bar = withTooltip<GraphProps, Legend>(IndividualChart);
  return (
    <div
      className="multiple-chart"
      style={{
        flexDirection: verticalOrientation ? "column" : "row",
        height: verticalOrientation ? "100%" : "unset",
      }}
    >
      {dataset.map((group, index) => {
        const hasData = group.legends.reduce(
          (acc, curr) => acc || curr.legendData !== null,
          false
        );
        return (
          <div
            className={classNames("multiple-chart__bar", {
              "multiple-chart__bar--vertical": verticalOrientation,
            })}
            key={`multiple-chart-${index}`}
          >
            {hasData ? (
              <ParentSize debounceTime={0}>
                {({ width: visWidth, height: visHeight }: any) =>
                  Bar({
                    data: group.legends,
                    name,
                    axis,
                    width: visWidth,
                    height: visHeight,
                    handleGraphElementClicked,
                    prefix: group.groupPrefix ?? "",
                    dahsedGridLine,
                    hideDashedBaseLine,
                    displayNoDataPattern,
                  })
                }
              </ParentSize>
            ) : (
              <div className="speedometer__no-data">
                <FormattedMessage id="insights.speedometer.no-data" />
              </div>
            )}
            <span className="multiple-chart__bar-label-container">
              <Popover
                metricClassName="multiple-chart__bar-label"
                displayText={group.groupName}
                content={
                  group.description && Array.isArray(group.description)
                    ? group.description
                    : []
                }
              />
            </span>
            {hasData &&
            !hideDatasetInfo &&
            group.legends.reduce(
              (acc, curr) =>
                acc || !curr.legendData || curr.legendData === null,
              false
            ) ? (
              <span className="DashboardTile__dataset-info">
                <FormattedMessage
                  id="multiple-chart.no-data"
                  values={{
                    metric: group.groupName,
                    companies: group.legends
                      .filter((d) => !d.legendData)
                      .map((d) => d.legendValue)
                      .join("; "),
                  }}
                />
              </span>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default MultipleBarChart;
