import TabularGovernanceChart from "components/visualizations/charts/tabular-governance.chart";
import {
  AssociatedMetricTooltips,
  TabularMetrics,
  Visualization,
} from "services/dashboard/dashboard.model";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import {
  BENCHMARK_TILE_TYPES,
  ESG_IVA_RATING_TILE_PEER_INDUSTRY,
  METRIC_VALUES,
  NEUTRAL,
} from "utils/constants";
import { getTabularMetrics, useTileContext } from "../tile.context";
import TableChart from "components/visualizations/charts/table-chart";
import classNames from "classnames";
import Popover from "components/shared/popover/popover";
import Parser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { TooltipPosition } from "components/shared/tooltip/tooltip";
import { showTooltip } from "services/commons.service";
import FormattedMessage from "components/shared/formatted-message/formatted-message";

const GovernanceDetails = () => {
  const {
    dataset,
    metadata,
    associatedMetrics,
    associatedMetricsDisplayNames,
    metadata: {
      benchmarkType,
      isDetailedView,
      benchmarkTileType,
      associatedMetricsTooltips,
    },
    isTableViewActive,
  } = useTileContext();

  const BLOCK = "TabularTile";
  const isCompanyView = benchmarkType !== 6 && benchmarkType !== 5;

  const getFormattedValue = (value: any, metricKey?: any) => {
    return value
      ? !isNaN(value) && metricKey !== "CorpGovernanceScore"
        ? value > 1
          ? value
          : value > 0
          ? "Yes"
          : "No"
        : value
      : "*";
  };

  const mapTabularChartDataforGovernance = (
    metadata: Visualization,
    dataset: InsightData[],
    removeEmptyMetric: boolean = false
  ) => {
    let metrics = getTabularMetrics(
      metadata.benchmarkMetadata.associatedMetricsDisplayNames,
      metadata.benchmarkMetadata.associatedMetrics,
      metadata.associatedMetricsTooltips,
      dataset
    );
    let hasEmptyValue = false;

    const cleanedData = dataset.reduce((data: any[], current: InsightData) => {
      let currentGroup: any = {
        globalCompanyId: current.globalCompanyId,
        companyName: current.companyName,
      };
      current.metrics.forEach((m: InsightMetricData) => {
        let metricProp =
          m.metricKey?.charAt(0).toUpperCase() + m.metricKey?.slice(1);
        currentGroup = {
          ...currentGroup,
          [metricProp]: getFormattedValue(m.metricValue, m.metricKey),
        };
        if (currentGroup[metricProp] === "*") hasEmptyValue = true;
      });

      return [
        ...data,
        {
          ...currentGroup,
        },
      ];
    }, []);

    const firstMetric: TabularMetrics = { metric: "", description: [] };
    return {
      metrics: removeEmptyMetric ? metrics : [firstMetric].concat(metrics),
      data: cleanedData,
      tileType: metadata.benchmarkTileType,
      tileView: metadata.benchmarkType,
      isDetailView: metadata.isDetailedView,
      hasEmptyValue: hasEmptyValue,
    };
  };

  const tabularData = mapTabularChartDataforGovernance(metadata, dataset);
  const dispatch = useDispatch();
  let hasEmptyValue = false;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  // eslint-disable-next-line react-hooks/rules-of-hooks

  let hasNoData =
    tabularData.data && tabularData.data.length
      ? tabularData.data.reduce(
          (acc: boolean, { companyName, cikNumber, ...d }: any) =>
            acc && Object.values(d).every((v) => v === null),
          true
        )
      : true;
  if (hasNoData)
    return (
      <div className={`${BLOCK}__no-data`}>
        <span className="speedometer__no-data" style={{ fontWeight: "bold" }}>
          <FormattedMessage id="insights.cdp.no-data" />
        </span>
      </div>
    );

  const handleMouseEnter = (
    e: any,
    children: any,
    position: TooltipPosition = TooltipPosition.TileBottomRight,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("div");
    if (!element) {
      return;
    }
    dispatch(
      showTooltip({
        children: <div className={`${BLOCK}__tooltip`}>{Parser(children)}</div>,
        position: position,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;

    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  const govDetailsGHG = () => {
    return (
      <>
        {
          <div
            className={classNames(`${BLOCK}__content`, {
              [`${BLOCK}--gov`]: isDetailedView,
            })}
            data-testid={`${BLOCK}__content`}
            style={{ justifyContent: "space-between" }}
          >
            <div
              className={classNames(
                `${BLOCK}__Governance-GHG`,
                {
                  [`${BLOCK}__Governance-GHG-detailed`]: isDetailedView,
                  [`${BLOCK}__Governance-GHG--esrs`]:
                    benchmarkTileType === BENCHMARK_TILE_TYPES.GOVERNANCE_ESRS,
                },
                {
                  [`${BLOCK}__Governance-GHG--esrs-peerGrid`]:
                    benchmarkType === 5 && !isCompanyView,
                },
                {
                  [`${BLOCK}__Governance-GHG--esrs-IndustryGrid`]:
                    benchmarkType === 6 && !isCompanyView,
                }
              )}
            >
              {metricData
                .filter((m: any) => m.isHeader === false)
                .map((metric: any, metricIndex: number) => {
                  return (
                    <div
                      className={classNames(`${BLOCK}__Governance-tile-GHG`, {
                        [`${BLOCK}__Governance-tileEsrs-GHG`]:
                          benchmarkTileType ===
                          BENCHMARK_TILE_TYPES.GOVERNANCE_ESRS,
                        [`${BLOCK}__Governance-tileEsrs-GHG--first`]:
                          metricIndex === 0 &&
                          benchmarkTileType ===
                            BENCHMARK_TILE_TYPES.GOVERNANCE_ESRS,
                      })}
                      key={metricIndex}
                    >
                      <div className={`${BLOCK}__Governance-text-GHG`}>
                        <span className={`${BLOCK}__Governance-text-span`}>
                          <Popover
                            displayText={metric.metricTitle}
                            metricClassName={`${BLOCK}__Governance-text ${BLOCK}__Governance-text--12`}
                            buttonClassName={`${BLOCK}__Governance-tileEsrs--metricName`}
                            content={
                              metric.metricDescription
                                ? metric.metricDescription
                                : []
                            }
                          />
                        </span>
                      </div>
                      <div
                        className={classNames({
                          [`${BLOCK}__Governance-fistrow-container`]:
                            metricIndex === 0 &&
                            !isCompanyView &&
                            benchmarkTileType ===
                              BENCHMARK_TILE_TYPES.GOVERNANCE_ESRS,
                        })}
                      >
                        {metric.metricValues.map((value: any, index: any) => (
                          <div
                            className={classNames("", {
                              [`${BLOCK}__Governance-wrapper-GHG`]:
                                !isCompanyView,
                              [`${BLOCK}__Governance-row-Column1`]:
                                !isCompanyView &&
                                index === 0 &&
                                metricIndex === 0,
                              [`${BLOCK}__Governance-row-Column2`]:
                                !isCompanyView &&
                                index === 1 &&
                                metricIndex === 0,
                            })}
                            key={index}
                          >
                            {!isCompanyView && (
                              <span
                                className={classNames({
                                  [`${BLOCK}__Governance-row-cell1`]:
                                    metricIndex === 0 &&
                                    index === 1 &&
                                    !isCompanyView,
                                  [`${BLOCK}__Governance-longName`]:
                                    metricIndex > 0 &&
                                    metric.metricValuesTooltip[index] &&
                                    metric.metricValuesTooltip[index][0]
                                      .length >= 20,
                                })}
                              >
                                {metric.metricValuesTooltip
                                  ? metric.metricValuesTooltip[index]
                                  : ""}
                              </span>
                            )}
                            <div
                              className={classNames(
                                `${BLOCK}__Governance-metric-GHG ${BLOCK}__Governance-metric-GHG--no-background  ${BLOCK}__Governance-value--${
                                  (METRIC_VALUES as any)[value] ?? value
                                }  `,
                                {
                                  [`${BLOCK}__Governance-metric-GHG--ESRS`]:
                                    benchmarkTileType ===
                                    BENCHMARK_TILE_TYPES.GOVERNANCE_ESRS,
                                }
                              )}
                              onMouseEnter={(e) => {
                                if ((METRIC_VALUES as any)[value] === "Neutral")
                                  handleMouseEnter(e, NEUTRAL);
                              }}
                              onMouseLeave={(e) => {
                                handleMouseLeave(e);
                              }}
                            >
                              {(METRIC_VALUES as any)[value] ?? value}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
            </div>
            {!hasNoData && hasEmptyValue && (
              <span className={`${BLOCK}__rating-legend`}>
                <FormattedMessage id="no.data.available" />
              </span>
            )}
          </div>
        }
      </>
    );
  };

  const govDetailsEnergyComp = () => {
    return (
      <>
        <div
          className={classNames(`${BLOCK}__content`, {
            [`${BLOCK}--gov`]: isDetailedView,
          })}
          data-testid="govDetailsEnergyComp"
        >
          <div className={`${BLOCK}__Governance`}>
            {metricData
              .filter((m: any) => m.isHeader === false)
              .map((metric: any, metricIndex: number) => {
                return (
                  <div
                    className={`${BLOCK}__Governance-tile`}
                    key={metricIndex}
                  >
                    <div
                      className={`${BLOCK}__Governance-value--${metric.metricValues}`}
                    >
                      <div className={`${BLOCK}__Governance-text`}>
                        {metric.metricValues}
                      </div>
                    </div>
                    <div className={`${BLOCK}__Governance-metric-container`}>
                      <Popover
                        metricClassName={`${BLOCK}__Governance-metric ${BLOCK}__Governance-metric--no-padding`}
                        displayText={metric.metricTitle}
                        content={
                          metric.metricDescription
                            ? metric.metricDescription
                            : []
                        }
                        buttonClassName={`${BLOCK}__Governance-metric-button`}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          {!hasNoData && hasEmptyValue && (
            <span className={`${BLOCK}__rating-legend`}>
              <FormattedMessage id="no.data.available" />
            </span>
          )}
        </div>
      </>
    );
  };

  const getMetricData = (tabularData: any) => {
    return [
      tabularData.reduce(
        (data: any[], current: InsightData) => {
          let currentGroup: any = [];
          current.metrics.forEach((m: InsightMetricData) => {
            if (!current.isBaseCompany) {
              let tempValue = data.find((x) => x.metricTitle === m.metricName);
              if (tempValue) {
                tempValue.metricValues = [
                  ...tempValue.metricValues,
                  getFormattedValue(m.metricValue, m.metricKey),
                ];
                tempValue.metricValuesTooltip = [
                  tempValue.metricValuesTooltip,
                  current.companyName,
                ];
                return [data];
              }
            }
            currentGroup = [
              ...currentGroup,
              {
                isHeader: false,
                metricValuesTooltip: [current.companyName],
                metricDescription: m.metricTooltip,
                metricValues: [getFormattedValue(m.metricValue, m.metricKey)],
                metricTitle: m.metricName,
              },
            ];
          });

          return [...data, ...currentGroup];
        },
        [
          {
            isHeader: true,
            metricValuesTooltip: [],
            metricDescription: [],
            metricValues: [],
            metricTitle: "",
          },
        ]
      ),
    ];
  };
  const [metricData] = getMetricData(dataset);
  hasEmptyValue = metricData.find((x: any) => x.metricValues.includes("*"));
  const hasAllDataMissing = metricData
    .filter((m: any) => m.isHeader === false)
    .every((metric: any) => metric.metricValues.every((mv: any) => mv === "*"));

  if (hasAllDataMissing)
    return (
      <div className={`${BLOCK}__no-data`}>
        <span className="speedometer__no-data" style={{ fontWeight: "bold" }}>
          <FormattedMessage id="insights.cdp.no-data" />
        </span>
      </div>
    );

  const mapTableChart = () => {
    let tmpResponse = dataset.reduce((data: any[], current: InsightData) => {
      let currentGroup: any = {
        globalCompanyId: current.globalCompanyId,
        header: current.companyName,
      };
      current.metrics.forEach((m: InsightMetricData) => {
        let metricProp =
          m.metricKey?.charAt(0).toLowerCase() + m.metricKey?.slice(1);
        currentGroup = {
          ...currentGroup,
          [metricProp]: getFormattedValue(m.metricValue, m.metricKey),
        };
      });

      return [
        ...data,
        {
          ...currentGroup,
        },
      ];
    }, []);

    return {
      MSCIESGRatingView: false,
      tileType: benchmarkTileType,
      labels: associatedMetrics
        .map((metric: any, i: number) => ({
          label: associatedMetricsDisplayNames
            ? associatedMetricsDisplayNames[i]
            : metric,
          id: metric?.charAt(0).toLowerCase() + metric?.slice(1),
          tooltip:
            metadata.associatedMetricsTooltips &&
            metadata.associatedMetricsTooltips.length > 0
              ? metadata.associatedMetricsTooltips.filter(
                  (t: AssociatedMetricTooltips) => t.associatedMetric === metric
                )
              : [],
        }))
        .filter(
          (label: any) =>
            label.id !== ESG_IVA_RATING_TILE_PEER_INDUSTRY.IVA_RATING_DATE
        ),
      response: tmpResponse,
      isDetailView: isDetailedView,
    };
  };

  const esrsGHGDetailedViewChart = () => {
    const metricKeys = metadata.benchmarkMetadata.associatedMetrics;
    const datasetForDetailedView =
      metricKeys.length &&
      metricKeys.map((metricKey: string) => {
        const companiesDataset = dataset.map((d: InsightData) => {
          return {
            metric: d.metrics.filter(
              (metric: InsightMetricData) => metric.metricKey === metricKey
            )[0],
            companyName: d.companyName,
            globalCompanyId: d.globalCompanyId,
          };
        });
        return {
          metricName: companiesDataset[0]?.metric?.metricName,
          companiesData: companiesDataset,
        };
      });
    return (
      <>
        {dataset && dataset.length > 0
          ? datasetForDetailedView &&
            datasetForDetailedView.length &&
            datasetForDetailedView.map((data: any, dataIndex: number) => (
              <div
                className={`${BLOCK}__DetailedMetric`}
                key={`${dataIndex}-data`}
              >
                <div className={`${BLOCK}__DetailedMetricName`}>
                  <Popover
                    displayText={data.metricName}
                    content={
                      associatedMetricsTooltips?.length > 0
                        ? associatedMetricsTooltips?.filter(
                            (
                              associatedMetricsTooltip: AssociatedMetricTooltips
                            ) =>
                              associatedMetricsTooltip.associatedMetricDisplayName ===
                              data.metricName
                          )
                        : []
                    }
                    metricClassName={`${BLOCK}__detailedMetricName`}
                    buttonClassName={`${BLOCK}__detailedMetricBorder`}
                  />
                </div>
                <div className={`${BLOCK}__DetailedCompanies`}>
                  {data?.companiesData?.length > 0 &&
                    data?.companiesData.map(
                      (companyData: any, companyIndex: number) => {
                        return (
                          <div
                            className={`${BLOCK}__detailedCompanyData`}
                            key={`${dataIndex}-${companyIndex}-company`}
                          >
                            <div className={`${BLOCK}__detailedCompanyName`}>
                              {companyData?.companyName}
                            </div>
                            <div
                              className={classNames(
                                `${BLOCK}__detailedCompanyMetricValue`,
                                `${BLOCK}__detailedCompanyMetricValue--${
                                  (METRIC_VALUES as any)[
                                    companyData?.metric?.metricValue
                                  ] ?? companyData?.metric?.metricValue
                                }`,
                                {
                                  [`${BLOCK}__detailedCompanyMetricValue--noValue`]:
                                    (companyData?.metric?.metricValue &&
                                      companyData?.metric?.metricValue.search(
                                        new RegExp("\\bno\\b", "i")
                                      ) !== -1) ||
                                    !companyData?.metric?.metricValue,
                                }
                              )}
                            >
                              {getFormattedValue(
                                companyData?.metric?.metricValue,
                                companyIndex
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            ))
          : null}
      </>
    );
  };

  return isTableViewActive ? (
    <TableChart
      data={mapTableChart()}
      currentInsightView={benchmarkType}
      leftAlignment={true}
    />
  ) : (benchmarkTileType === 40 ||
      benchmarkTileType === BENCHMARK_TILE_TYPES.GOVERNANCE_ESRS) &&
    !isDetailedView ? (
    govDetailsGHG()
  ) : benchmarkTileType === 34 && !isDetailedView && isCompanyView ? (
    govDetailsEnergyComp()
  ) : isDetailedView &&
    !isTableViewActive &&
    benchmarkTileType === BENCHMARK_TILE_TYPES.GOVERNANCE_ESRS ? (
    <div>
      <div
        className={`${BLOCK}__Detailed-container`}
        data-testid={`${BLOCK}__Detailed-container`}
      >
        {esrsGHGDetailedViewChart()}
      </div>
      {!hasNoData && hasEmptyValue && (
        <div className={`${BLOCK}__deatiled-no-data-section`}>
          <span>
            <FormattedMessage id="no.data.available" />
          </span>
        </div>
      )}
    </div>
  ) : (
    <TabularGovernanceChart
      data={tabularData}
      dataTable={{
        ...mapTabularChartDataforGovernance(metadata, dataset),
        isDetailedView: isDetailedView,
      }}
      isTableView={isTableViewActive}
      isDetailedView={isDetailedView}
      hasEmptyValue={tabularData.hasEmptyValue}
    />
  );
};

export default GovernanceDetails;
