import Button from "components/shared/button/button";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Modal from "components/shared/modal/modal";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "services/commons.model";
import { RootStore } from "services/store.service";
import {
  loadUsers,
  saveSuperAdmin,
} from "services/user-management/user-management.service";
import UserDropdown from "./user-dropdown";

type props = {
  showModal: boolean;
  onClose: () => void;
};

const UserManagementModal = ({ showModal, onClose }: props) => {
  const BLOCK = "user-management-modal";
  const dispatch = useDispatch();

  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );
  const userManagementState = useSelector(
    (state: RootStore) => state.userManagement
  );
  const existingAdmins = userManagementState.results.map((item) => item.user);
  const [selectedUser, setSelectedUser] = useState<User>();

  const debounceChangeHandler = useMemo(() => {
    return debounce((e: any) => {
      // change state of input
      const input = e.target.value.trim();
      if (input.length < 2) {
        return;
      }
      dispatch(loadUsers(input, currentUser.userId));
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const selectUserHandle = (user: User) => {
    setSelectedUser(user);
  };

  const onSave = () => {
    if (selectedUser) {
      dispatch(saveSuperAdmin(selectedUser.userId));
    }
    onClose();
  };

  return (
    <Modal
      show={true}
      header={"super-admin.add"}
      handleClose={() => onClose()}
      config={false}
    >
      <div
        className={`${BLOCK}__container`}
        data-testid={`${BLOCK}__container`}
      >
        <UserDropdown
          className={`${BLOCK}__dropdown`}
          label={
            <div
              className={`${BLOCK}__dropdown-label`}
              data-test="dropdown-label"
            >
              <FormattedMessage id="super-admin.user.name" />
            </div>
          }
          placeholder={<FormattedMessage id="share.any.dropdown.placeholder" />}
          disableValues={existingAdmins}
          selectedValue={selectedUser}
          objectKeys={{
            name: "displayName",
            id: "userId",
            firstName: "firstName",
            lastName: "lastName",
            externalId: "externalSystemId",
            email: "email",
            memberFirmCode: "memberFirmCode",
            countryName: "countryName",
          }}
          loading={false}
          options={userManagementState.userListData}
          handleChange={debounceChangeHandler}
          handleSelect={selectUserHandle}
        />
        <div className={`${BLOCK}__footer`}>
          <Button
            className="button-secondary"
            formattedText="cancel"
            onClick={() => onClose()}
            dataTest="cancel-button"
          />
          <Button
            className="button-primary"
            formattedText="save"
            onClick={() => onSave()}
            disabled={!selectedUser}
            dataTest="save-button"
          />
        </div>
      </div>
    </Modal>
  );
};

export default UserManagementModal;
