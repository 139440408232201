import Icon from "components/shared/icon/icon";
import { Fragment, useEffect, useRef, useState } from "react";
import { peerBenchmarkColor, peerBenchmarkShowHover } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { mod } from "utils/functions";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import classNames from "classnames";
import { showTooltip } from "services/commons.service";
import Parser from "html-react-parser";
import ResizeGridRow from "./resize-grid-row";
import { RowItemCompany } from "./benchmark-grid";
import {
  UserPreference,
  companiesColumnWidthObject,
  fieldColumnWidthObject,
} from "services/peer-benchmark/peer-benchmark.model";
import { patchUserPreference } from "services/peer-benchmark/peer-benchmark.service";

type BenchmarkGridViewRowProps = {
  rowIndex: number;
  rowItems: RowItemCompany;
  selectedMetrics: any;
  companyMetricValue: any;
  transpose: boolean;
  rowItemsCompany: any;
  removeCellOrRow: Function;
  userPreferences: Partial<UserPreference>;
  setUserPreferences: Function;
  tableHeight: number;
  tableHeaderHeight: number;
};

const BenchmarkGridRow = ({
  rowIndex,
  rowItems,
  selectedMetrics,
  companyMetricValue,
  transpose,
  rowItemsCompany,
  removeCellOrRow,
  userPreferences,
  setUserPreferences,
  tableHeight,
  tableHeaderHeight,
}: BenchmarkGridViewRowProps) => {
  const BLOCK = "benchmark-grid-row";
  const MARGIN_FOR_METRIC = 8;
  const metricBoxRef = useRef([]);
  const metricHeightRef = useRef(null);
  const commonsState: { [key: string]: any } = useSelector(
    (state: RootStore) => state.commons
  );
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const [rowHeight, setRowHeight] = useState<{ [index: number]: number }>({});
  const [originalHeight, setOriginalHeight] = useState<{
    [index: number]: number;
  }>({});
  const [transposeHeight, setTransposeHeight] = useState<{
    [index: number]: number;
  }>({});
  const [isRowResize, setIsRowResize] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("span");
    if (!element) {
      return;
    }

    dispatch(
      showTooltip({
        children: children,
        position: TooltipPosition.top,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };
  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };
  useEffect(() => {
    if (transpose) {
      setOriginalHeight({});
    } else {
      setTransposeHeight({});
    }
  }, [transpose]);

  const getMetricDisplayName = (fieldId: number) => {
    return selectedMetrics.find(
      (sm: {
        displayName: string;
        fieldId: number;
        fieldName: string;
        description: string;
      }) => sm.fieldId === fieldId
    )?.displayName;
  };
  const renderFieldValue = (value: string, i: number) => {
    return (
      <span
        key={`field-${i}`}
        className={`${BLOCK}__multipleItem__item`}
        ref={metricHeightRef}
      >
        {value}
      </span>
    );
  };

  const renderField = (
    field: any,
    index: number,
    rowId: number,
    transpose: boolean
  ) => {
    const fieldValueColor = peerBenchmarkColor[field.fieldId];
    let height: number;
    if (transpose) {
      height =
        userPreferences.userPreferenceValue?.fieldColumnWidths.find(
          (fieldColumnObj: fieldColumnWidthObject) =>
            fieldColumnObj.fieldId === rowId
        )?.height ?? 0;
    } else {
      height =
        userPreferences.userPreferenceValue?.companiesColumnWidths.find(
          (companyColumnObj: companiesColumnWidthObject) =>
            companyColumnObj.globalCompanyId === rowId
        )?.height ?? 0;
    }

    if (!fieldValueColor) {
      if (field.fieldValueIcon) {
        return (
          <div
            className={classNames(
              `${BLOCK}__rowItem--box ${BLOCK}__rowItem--icon`,
              { [`${BLOCK}__rowItem--center`]: !transpose }
            )}
          >
            <span className={`${BLOCK}__icon`}>
              {Parser(field.fieldValueIcon)}
            </span>
            {peerBenchmarkShowHover.includes(field.dataType) ? (
              <Tooltip position={TooltipPosition.right}>
                <FormattedMessage
                  id={`peer-benchmarks.hover-${field.fieldValue}`}
                />
              </Tooltip>
            ) : null}
          </div>
        );
      }
      return (
        <div
          className={
            !!field.fieldValueColorCode
              ? `${BLOCK}__multipleItem single-item`
              : `${BLOCK}__rowItem--box`
          }
        >
          <span
            className={classNames(`${BLOCK}__rowItem--box--value`, {
              [`${BLOCK}__multipleItem__item`]: !!field.fieldValueColorCode,
            })}
            style={{ color: field.fieldValueColorCode }}
          >
            {field.fieldValue !== "" ? (
              field.fieldValue
            ) : (
              <span
                className={classNames(`${BLOCK}__multipleItem__see-more`, {
                  [`${BLOCK}__multipleItem__see-more--left`]:
                    index === selectedMetrics.length - 1 ||
                    (transpose &&
                      index === rowItemsCompany.length - 1 &&
                      index > 0),
                })}
                onMouseEnter={(e) => {
                  getMetricDisplayName(field.fieldId) &&
                    handleMouseEnter(
                      e,
                      <div className={`${BLOCK}__multipleItem__more-values`}>
                        <FormattedMessage
                          id="peer.benchmark.metric.not.results"
                          values={{
                            metricName: getMetricDisplayName(field.fieldId),
                          }}
                        />
                      </div>
                    );
                }}
                onMouseLeave={handleMouseLeave}
                data-test="multipleItems-more-values"
              >
                *
              </span>
            )}
          </span>
        </div>
      );
    }
    const fieldValuesNotRanked =
      field && field.fieldValue
        ? field.fieldValue
            .split(",")
            .sort((v1: string, v2: string) => (v1 < v2 ? -1 : 1))
        : [];

    const fieldValuesRanked = fieldValuesNotRanked
      ? fieldValuesNotRanked.map((e: any) => {
          return e.split("##RANK##");
        })
      : [];

    const fieldValues = fieldValuesRanked
      ? fieldValuesRanked
          .sort((a: any, b: any) => {
            return a[1] - b[1];
          })
          .reduce((result: any, obj: any) => {
            if (result[obj[1] - 1]) {
              result[obj[1] - 1].push(obj);
            } else {
              (result[obj[1] - 1] = result[obj[0]] || []).push(obj);
            }
            return result;
          }, [])
      : [];
    const maxValuesToShow =
      fieldValues.length > 0 &&
      !rowHeight[rowId] &&
      height &&
      height !== 0 &&
      !isRowResize &&
      originalHeight
        ? Math.floor(height / 24)
        : fieldValues.length > 0 &&
          rowHeight &&
          originalHeight &&
          rowHeight[rowId] < originalHeight[rowId]
        ? tableHeight < originalHeight[rowId] + tableHeaderHeight
          ? fieldValues.length -
            Math.ceil(
              (0.3 * tableHeight - rowHeight[rowId]) /
                ((0.3 * tableHeight) / fieldValues.length)
            )
          : fieldValues.length -
            Math.ceil(
              (originalHeight[rowId] - rowHeight[rowId]) /
                (originalHeight[rowId] / fieldValues.length)
            )
        : fieldValues.length;

    if (fieldValues.length === 0)
      return (
        <div
          className={`${BLOCK}__multipleItem ${BLOCK}__multipleItem--center`}
        >
          <span
            className={`${BLOCK}__multipleItem__see-more`}
            onMouseEnter={(e) => {
              getMetricDisplayName(field.fieldId) &&
                handleMouseEnter(
                  e,
                  <div className={`${BLOCK}__multipleItem__more-values`}>
                    <FormattedMessage
                      id="peer.benchmark.metric.not.results"
                      values={{
                        metricName: getMetricDisplayName(field.fieldId),
                      }}
                    />
                  </div>
                );
            }}
            onMouseLeave={handleMouseLeave}
          >
            *
          </span>
        </div>
      );
    let childMetricValuesToShow =
      height && height !== 0
        ? Math.floor(
            height /
              ((metricHeightRef as any).current?.offsetHeight +
                MARGIN_FOR_METRIC)
          )
        : tableHeight < originalHeight[rowId] + tableHeaderHeight ||
          (transpose &&
            transposeHeight[rowId] &&
            tableHeight < transposeHeight[rowId] + tableHeaderHeight)
        ? Math.floor(
            (0.3 * tableHeight) /
              ((metricHeightRef as any).current?.offsetHeight +
                MARGIN_FOR_METRIC)
          )
        : 0;

    return (
      <div
        className={`${BLOCK}__multipleItem`}
        style={{
          ...(rowHeight && rowHeight[rowId] > 0
            ? { height: `${rowHeight[rowId]}px` }
            : height && height !== 0
            ? { height: `${height}px` }
            : transpose &&
              transposeHeight[rowId] &&
              tableHeight < transposeHeight[rowId] + tableHeaderHeight
            ? { height: ` ${0.3 * tableHeight}px` }
            : tableHeight < originalHeight[rowId] + tableHeaderHeight
            ? { height: ` ${0.3 * tableHeight}px` }
            : null),
          overflow: "hidden",
        }}
      >
        <>
          {fieldValues.slice(0, maxValuesToShow).map((v: any, i: number) => {
            const colors = commonsState[fieldValueColor];
            const color = colors[v[0][1] - 1];
            return (
              <span
                key={`field-color-value-${i}`}
                style={{
                  border: `1px solid ${color}`,
                }}
                className={`${BLOCK}__topics`}
                ref={(ref) => {
                  (metricBoxRef as any).current[i] = ref;
                }}
              >
                {v.length > 1 &&
                childMetricValuesToShow &&
                childMetricValuesToShow > 0 ? (
                  <Fragment>
                    {v
                      .slice(0, childMetricValuesToShow - 1)
                      .map((e: any, index: number) => (
                        <Fragment key={`field-map-values-${index}`}>
                          {renderFieldValue(e[0], i)}
                        </Fragment>
                      ))}
                    {v.length > 1 &&
                    childMetricValuesToShow &&
                    childMetricValuesToShow > 0 &&
                    v.length >= childMetricValuesToShow ? (
                      <span
                        className={`${BLOCK}__multipleItem__see-more`}
                        onMouseEnter={(e) => {
                          handleMouseEnter(
                            e,
                            <ul
                              className={`${BLOCK}__multipleItem__more-values ${BLOCK}__multipleItem__more-values--list`}
                            >
                              {v.map((vData: string, index: number) => (
                                <li key={`multiple-field-value-${index}`}>
                                  {vData[0]}
                                </li>
                              ))}
                            </ul>
                          );
                        }}
                        onMouseLeave={handleMouseLeave}
                      >
                        + {v.length - childMetricValuesToShow + 1}
                      </span>
                    ) : null}
                  </Fragment>
                ) : (
                  v.map((e: any, index: number) => {
                    childMetricValuesToShow--;
                    return (
                      <Fragment key={`field-map-values-${index}`}>
                        {renderFieldValue(e[0], i)}
                      </Fragment>
                    );
                  })
                )}
              </span>
            );
          })}
          {fieldValues.length > maxValuesToShow ? (
            <span
              className={`${BLOCK}__multipleItem__see-more`}
              onMouseEnter={(e) => {
                handleMouseEnter(
                  e,
                  <ul
                    className={`${BLOCK}__multipleItem__more-values ${BLOCK}__multipleItem__more-values--list`}
                  >
                    {fieldValues.map((v: string, index: number) => (
                      <li key={`multiple-field-value-${index}`}>{v}</li>
                    ))}
                  </ul>
                );
              }}
              onMouseLeave={handleMouseLeave}
            >
              + {fieldValues.length - maxValuesToShow}
            </span>
          ) : null}
        </>
      </div>
    );
  };

  const renderRow = (item: any, index: number, globalCompanyId: number) => {
    const currentField = companyMetricValue?.fields?.find(
      (field: any) => field?.fieldId === item?.fieldId
    );

    return (
      <td className={`${BLOCK}__rowItem--td`}>
        {renderField(
          currentField ? currentField : { fieldValue: "" },
          index,
          globalCompanyId,
          false
        )}
      </td>
    );
  };

  const renderTransposeData = (metric: any, company: any, index: number) => {
    const currentField = company.fields.find(
      (field: any) => field.fieldId === metric.fieldId
    );
    return (
      <td className={`${BLOCK}__rowItem--td ${BLOCK}__rowItem--transpose`}>
        {renderField(
          currentField ? currentField : { fieldValue: "" },
          index,
          metric.fieldId,
          true
        )}
      </td>
    );
  };

  const savePreferenceForRowHeight = (
    rowId: number,
    size: number,
    transpose: boolean
  ) => {
    if (transpose) {
      let fieldColumnWidth: fieldColumnWidthObject[] = [];
      if (
        userPreferences.userPreferenceValue?.fieldColumnWidths &&
        userPreferences.userPreferenceValue?.fieldColumnWidths?.length > 0
      ) {
        let filtered =
          userPreferences.userPreferenceValue?.fieldColumnWidths.find(
            (field: fieldColumnWidthObject) => field.fieldId === rowId
          );

        if (filtered) {
          fieldColumnWidth =
            userPreferences.userPreferenceValue?.fieldColumnWidths?.map(
              (field: fieldColumnWidthObject) => {
                if (field.fieldId === rowId) {
                  return {
                    ...field,
                    height: size,
                  };
                } else {
                  return field;
                }
              }
            );
        } else {
          fieldColumnWidth = [
            ...userPreferences.userPreferenceValue?.fieldColumnWidths,
            ...[{ fieldId: rowId, height: size }],
          ];
        }
      } else {
        fieldColumnWidth = [
          {
            fieldId: rowId,
            height: size,
          },
        ];
      }
      const newUserPreference: any = {
        ...userPreferences,
        userPreferenceValue: {
          ...userPreferences.userPreferenceValue,
          fieldColumnWidths: fieldColumnWidth ?? [],
        },
      };
      dispatch(patchUserPreference(newUserPreference));
      setUserPreferences((userPref: UserPreference) => {
        return {
          ...userPref,
          userPreferenceValue: {
            ...userPref.userPreferenceValue,
            fieldColumnWidths: fieldColumnWidth,
          },
        };
      });
    } else {
      let companiesColumnWidth: companiesColumnWidthObject[] = [];
      if (
        userPreferences.userPreferenceValue?.companiesColumnWidths &&
        userPreferences.userPreferenceValue?.companiesColumnWidths?.length > 0
      ) {
        let filtered =
          userPreferences.userPreferenceValue?.companiesColumnWidths.find(
            (companies: companiesColumnWidthObject) =>
              companies.globalCompanyId === rowId
          );

        if (filtered) {
          companiesColumnWidth =
            userPreferences.userPreferenceValue?.companiesColumnWidths?.map(
              (companies: companiesColumnWidthObject) => {
                if (companies.globalCompanyId === rowId) {
                  return {
                    ...companies,
                    height: size,
                  };
                } else {
                  return companies;
                }
              }
            );
        } else {
          companiesColumnWidth = [
            ...userPreferences.userPreferenceValue?.companiesColumnWidths,
            ...[{ globalCompanyId: rowId, height: size }],
          ];
        }
      } else {
        companiesColumnWidth = [
          {
            globalCompanyId: rowId,
            height: size,
          },
        ];
      }
      const newUserPreference: any = {
        ...userPreferences,
        userPreferenceValue: {
          ...userPreferences.userPreferenceValue,
          companiesColumnWidths: companiesColumnWidth ?? [],
        },
      };

      dispatch(patchUserPreference(newUserPreference));
      setUserPreferences((userPref: UserPreference) => {
        return {
          ...userPref,
          userPreferenceValue: {
            ...userPref.userPreferenceValue,
            companiesColumnWidths: companiesColumnWidth,
          },
        };
      });
    }
  };

  return transpose ? (
    selectedMetrics &&
      selectedMetrics.map((metric: any, i: number) => (
        <Fragment key={`company-row-${metric.fieldId}`}>
          <ResizeGridRow
            className={BLOCK}
            setSize={(size: number) => {
              setRowHeight((rw) => ({ ...rw, ...{ [metric.fieldId]: size } }));
              savePreferenceForRowHeight(metric.fieldId, size, transpose);
            }}
            setOriginalSize={(size: number) => {
              setOriginalHeight((rw) => ({
                ...rw,
                ...{ [metric.fieldId]: size },
              }));
              setTransposeHeight((rw) => ({
                ...rw,
                ...{ [metric.fieldId]: size },
              }));
            }}
            setIsRowResize={setIsRowResize}
            height={
              userPreferences?.userPreferenceValue?.fieldColumnWidths.filter(
                (fieldColumnWidthObj: fieldColumnWidthObject) =>
                  fieldColumnWidthObj.fieldId === metric.fieldId
              )[0]?.height
                ? userPreferences?.userPreferenceValue?.fieldColumnWidths.filter(
                    (fieldColumnWidthObj: fieldColumnWidthObject) =>
                      fieldColumnWidthObj.fieldId === metric.fieldId
                  )[0]?.height
                : transpose &&
                  transposeHeight[metric.fieldId] &&
                  tableHeight <
                    transposeHeight[metric.fieldId] + tableHeaderHeight
                ? parseInt(`${0.3 * tableHeight}`)
                : tableHeight <
                  originalHeight[metric.fieldId] + tableHeaderHeight
                ? parseInt(`${0.3 * tableHeight}`)
                : 0
            }
            maxheightLimit={
              tableHeight <
                originalHeight[metric.fieldId] + tableHeaderHeight ||
              tableHeight < transposeHeight[metric.fieldId] + tableHeaderHeight
                ? parseInt(`${0.3 * tableHeight}`)
                : 0
            }
            rowContent={
              <>
                <td
                  className={`${BLOCK}__rowItem--td ${BLOCK}__rowItem--transpose`}
                >
                  <div className={`${BLOCK}__rowItem--transpose-metric-title`}>
                    {selectedMetrics.length > 1 && (
                      <button
                        className={`${BLOCK}__rowItem--transpose-remove-button`}
                        onClick={() =>
                          removeCellOrRow
                            ? removeCellOrRow(metric.fieldName)
                            : null
                        }
                      >
                        <Icon name="cross" width={16} height={16} />
                      </button>
                    )}
                    {metric.displayName}
                    <span
                      className={`${BLOCK}__info-icon ${BLOCK}__info-icon--transpose`}
                      onMouseEnter={(e) => {
                        handleMouseEnter(
                          e,
                          metric.description,
                          `${BLOCK}__info-icon-tooltip `
                        );
                      }}
                      onMouseLeave={handleMouseLeave}
                    >
                      <Icon name="information" width={25} height={25} />
                    </span>
                  </div>
                </td>
                {rowItemsCompany.map((company: any, j: number) => (
                  <Fragment key={`metric-value-${j}`}>
                    {renderTransposeData(metric, company, j)}
                  </Fragment>
                ))}
              </>
            }
          />
        </Fragment>
      ))
  ) : (
    <ResizeGridRow
      className={BLOCK}
      setSize={(size: number) => {
        setRowHeight({ [rowItems.globalCompanyId]: size });
        savePreferenceForRowHeight(rowItems.globalCompanyId, size, transpose);
      }}
      setOriginalSize={(size: number) =>
        setOriginalHeight((rw) => ({
          ...rw,
          ...{ [rowItems.globalCompanyId]: size },
        }))
      }
      height={
        userPreferences?.userPreferenceValue?.companiesColumnWidths.filter(
          (companiesColumnWidthObj: companiesColumnWidthObject) =>
            companiesColumnWidthObj.globalCompanyId === rowItems.globalCompanyId
        )[0]?.height
          ? userPreferences?.userPreferenceValue?.companiesColumnWidths.filter(
              (companiesColumnWidthObj: companiesColumnWidthObject) =>
                companiesColumnWidthObj.globalCompanyId ===
                rowItems.globalCompanyId
            )[0]?.height
          : tableHeight <
              originalHeight[rowItems.globalCompanyId] + tableHeaderHeight ||
            (transposeHeight[rowItems.globalCompanyId] &&
              tableHeight <
                transposeHeight[rowItems.globalCompanyId] + tableHeaderHeight)
          ? parseInt(`${0.3 * tableHeight}`)
          : 0
      }
      maxheightLimit={
        tableHeight <
          originalHeight[rowItems.globalCompanyId] + tableHeaderHeight ||
        tableHeight <
          transposeHeight[rowItems.globalCompanyId] + tableHeaderHeight
          ? parseInt(`${0.3 * tableHeight}`)
          : 0
      }
      setIsRowResize={setIsRowResize}
      rowContent={
        <>
          <td className={`${BLOCK}__rowItem--company`}>
            <div className={`${BLOCK}__rowItem--company--items`}>
              {renderCompanyData(BLOCK, rowItems, removeCellOrRow)}
            </div>
          </td>
          {selectedMetrics &&
            selectedMetrics.map((metric: any, index: number) => (
              <Fragment key={`selected-metric-${index}`}>
                {renderRow(metric, index, rowItems.globalCompanyId)}
              </Fragment>
            ))}
        </>
      }
    />
  );
};

export const renderCompanyData = (
  BLOCK: string,
  rowItems: any,
  removeCellOrRow?: Function,
  transpose?: boolean
) => (
  <>
    {!transpose && !rowItems.isBaseline && (
      <button
        className={`${BLOCK}__rowItem--remove-button`}
        onClick={() =>
          removeCellOrRow ? removeCellOrRow(rowItems.companyName) : null
        }
      >
        <Icon name="cross" width={16} height={16} />
      </button>
    )}
    <span className={`${BLOCK}__rowItem--company--items--name`}>
      {transpose && !rowItems.isBaseline && (
        <button
          className={`${BLOCK}__rowItem--remove-button`}
          onClick={() =>
            removeCellOrRow ? removeCellOrRow(rowItems.companyName) : null
          }
        >
          <Icon name="cross" width={16} height={16} />
        </button>
      )}
      {rowItems.companyName}
      {!transpose && rowItems.isBaseline && (
        <Icon
          name={"baseline-star"}
          width={16}
          height={16}
          className={`${BLOCK}__rowItem--company--items--icon`}
          data-testid="baseline-icon"
        />
      )}
    </span>
    {transpose && (
      <>
        <br />
        <br />
      </>
    )}
    <span
      className={classNames(`${BLOCK}__rowItem--company--items--date`, {
        [`${BLOCK}__rowItem--removable`]: transpose && !rowItems.isBaseline,
      })}
    >
      {rowItems.ratingDate}
    </span>
  </>
);

export default BenchmarkGridRow;
