import { Dispatch } from "redux";
import { createAction } from "utils/redux.utils";
import { CommonsModel, CommonsState, ToastMessage } from "./commons.model";
import * as api from "./commons.api";
import { APP_SETTING } from "utils/constants";

/*
 * Actions
 */

export const ACTIONS = {
  COMMONS_ADD_TOAST_MESSAGE: "ESG/UI/COMMONS_ADD_TOAST_MESSAGE",
  COMMONS_REMOVE_TOAST_MESSAGE: "ESG/UI/COMMONS_REMOVE_TOAST_MESSAGE",
  COMMONS_SHOW_TOOLTIP: "ESG/UI/COMMONS_SHOW_TOOLTIP",
  COMMONS_LOAD_CURRENT_USER: "ESG/UI/COMMONS_LOAD_CURRENT_USER",
  COMMONS_SHARE_LOADING: "ESG/UI/COMMONS_SHARE_LOADING",
  COMMONS_LOAD_ESG_ASSETS: "ESG/UI/COMMONS_LOAD_ESG_ASSETS",
  COMMONS_LOAD_DASHBOARD_COLORS: "ESG/UI/COMMONS_LOAD_DASHBOARD_COLORS",
  COMMONS_LOAD_DETAIL_PEER_VIEW_COLOR_CODES:
    "COMMONS_LOAD_DETAIL_PEER_VIEW_COLOR_CODES",
  COMMONS_LOAD_LOWEST_SCORING_COLORS:
    "ESG/UI/COMMONS_LOAD_LOWEST_SCORING_COLORS",
  COMMONS_LOAD_HIGHEST_SCORING_COLORS:
    "ESG/UI/COMMONS_LOAD_HIGHEST_SCORING_COLORS",
  COMMONS_LOAD_COMPANY_RATING_COLORS:
    "ESG/UI/COMMONS_LOAD_COMPANY_RATING_COLORS",
  ESG_ASSETS_LOADING: "ESG/UI/ESG_ASSETS_LOADING",
  COMMONS_SHOW_CALENDAR: "ESG/UI/COMMONS_SHOW_CALENDAR",
  COMMONS_ADD_APP_SETTING: "ESG/UI/COMMONS_ADD_APP_SETTING",
  COMMONS_ADD_DASHBOARD_EXPORT_DISCLAIMER:
    "ESG/UI/COMMONS_ADD_DASHBOARD_EXPORT_DISCLAIMER",
  COMMONS_ADD_INSIGHTS_PAGE_DISCLAIMER:
    "ESG/UI/COMMONS_ADD_INSIGHTS_PAGE_DISCLAIMER",
  COMMONS_ADD_PEER_COMPANY_LIMIT: "ESG/UI/ COMMONS_ADD_PEER_COMPANY_LIMIT",
  COMMONS_SHOW_MAIN_TOOLTIP: "ESG/UI/COMMONS_SHOW_MAIN_TOOLTIP",
  COMMONS_SHOW_TERMS_OF_USE_FOOTER: "ESG/UI/COMMONS_SHOW_TERMS_OF_USE_FOOTER",
  COMMONS_PEER_BENCHMARK_METRICS_MAX_COUNT:
    "ESG/UI/COMMONS_PEER_BENCHMARK_METRICS_MAX_COUNT",
  COMMONS_SCOPE_3_CATOGRIES_STATUS_COLOR_CODES:
    "ESG/UI/COMMONS_SCOPE_3_CATOGRIES_STATUS_COLOR_CODES",
  GLOBAL_COMPANY_GROUPING_TRACK: "ESG/UI/GLOBAL_COMPANY_GROUPING_TRACK",
};

export const globalCompanyGroupingTrack = createAction(
  ACTIONS.GLOBAL_COMPANY_GROUPING_TRACK
);

export const commonsAddToastMessage = createAction(
  ACTIONS.COMMONS_ADD_TOAST_MESSAGE
);

export const showTermOfUserFooter = createAction(
  ACTIONS.COMMONS_SHOW_TERMS_OF_USE_FOOTER
);

export const setShareForLoading = createAction(ACTIONS.COMMONS_SHARE_LOADING);

export const commonsRemoveToastMessage = createAction(
  ACTIONS.COMMONS_REMOVE_TOAST_MESSAGE
);
export const commonsShowTooltip = createAction(ACTIONS.COMMONS_SHOW_TOOLTIP);
export const commonsLoadCurrentUser = createAction(
  ACTIONS.COMMONS_LOAD_CURRENT_USER
);
export const commonsLoadDashboardColors = createAction(
  ACTIONS.COMMONS_LOAD_DASHBOARD_COLORS
);

export const commonLoadLowestScoringColors = createAction(
  ACTIONS.COMMONS_LOAD_LOWEST_SCORING_COLORS
);
export const commonLoadHighestScoringColors = createAction(
  ACTIONS.COMMONS_LOAD_HIGHEST_SCORING_COLORS
);
export const commonLoadDetailPeerViewColorCodes = createAction(
  ACTIONS.COMMONS_LOAD_DETAIL_PEER_VIEW_COLOR_CODES
);
export const commonLoadCompanyRatingColors = createAction(
  ACTIONS.COMMONS_LOAD_COMPANY_RATING_COLORS
);
export const addAppSetting = createAction(ACTIONS.COMMONS_ADD_APP_SETTING);

export const addDashboardExportDisclaimer = createAction(
  ACTIONS.COMMONS_ADD_DASHBOARD_EXPORT_DISCLAIMER
);

export const addInsightsPageDisclaimer = createAction(
  ACTIONS.COMMONS_ADD_INSIGHTS_PAGE_DISCLAIMER
);

export const addInsightsPeerCompanyLimit = createAction(
  ACTIONS.COMMONS_ADD_PEER_COMPANY_LIMIT
);

export const loadCommonsPeerBenchmarkMetricsMaxCount = createAction(
  ACTIONS.COMMONS_PEER_BENCHMARK_METRICS_MAX_COUNT
);

export const setScope3CtaegoriesColorCodes = createAction(
  ACTIONS.COMMONS_SCOPE_3_CATOGRIES_STATUS_COLOR_CODES
);

export const addToastMessage =
  (toastMessage: ToastMessage) => (dispatch: Dispatch<any>) => {
    dispatch(commonsAddToastMessage(toastMessage));
  };

export const removeToastMessage =
  (index: number) => (dispatch: Dispatch<any>) => {
    dispatch(commonsRemoveToastMessage(index));
  };

export const setShareLoader =
  (shareLoader: boolean) => (dispatch: Dispatch<any>) => {
    dispatch(setShareForLoading(shareLoader));
  };

export const esgAssetsLoading = createAction(ACTIONS.ESG_ASSETS_LOADING);
export const loadEsgAssets = createAction(ACTIONS.COMMONS_LOAD_ESG_ASSETS);

export const updateCalendar = createAction(ACTIONS.COMMONS_SHOW_CALENDAR);

export const showTooltip = (toolTip: any) => (dispatch: Dispatch<any>) => {
  dispatch(commonsShowTooltip(toolTip));
};

export const commonsShowMainTooltip = createAction(
  ACTIONS.COMMONS_SHOW_MAIN_TOOLTIP
);
export const showMainTooltip =
  (mainToolTip: any) => (dispatch: Dispatch<any>) => {
    dispatch(commonsShowMainTooltip(mainToolTip));
  };

export const loadCurrentUser = () => async (dispatch: Dispatch<any>) => {
  try {
    const currentUser = await api.getCurrentUser();
    window.localStorage.setItem("userID", currentUser.email);
    //currentUser.adminRoles = [];
    dispatch(commonsLoadCurrentUser(currentUser));
  } catch (error) {
    console.error(error);
  }
};

export const loadCookieBanner = () => async (dispatch: Dispatch<any>) => {
  var scriptData = window.ESG?.ENV?.GEOS?.find(
    (geo: { GEO_CODE: string }) => geo.GEO_CODE && geo.GEO_CODE !== "NONE"
  );
  const script = document.createElement("script");
  script.src = scriptData.ONETRUST_URL;
  script.type = "text/javascript";
  script.dataset.domainScript = scriptData.ONETRUST_DOMAIN;
  document.body.appendChild(script);
};

export const getEsgAssets = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(esgAssetsLoading(true));
    const result = await api.getEsgAssets();
    dispatch(loadEsgAssets(result));
    dispatch(esgAssetsLoading(false));
  } catch (e) {
    dispatch(esgAssetsLoading(false));
  }
};

export const loadAppSettingColors =
  (appSettingColor: string) => async (dispatch: Dispatch<any>) => {
    const colorSetting = (
      await api.getAppSetting(appSettingColor)
    ).applicationSettingValue.split(",");

    switch (appSettingColor) {
      case APP_SETTING.DashboardColorCodes:
        return dispatch(commonsLoadDashboardColors(colorSetting));
      case APP_SETTING.LowestScoringColorCodes:
        return dispatch(commonLoadLowestScoringColors(colorSetting));
      case APP_SETTING.HighestScoringColorCodes:
        return dispatch(commonLoadHighestScoringColors(colorSetting));
      case APP_SETTING.InsightsDetailedPeerViewColorCodes:
        return dispatch(commonLoadDetailPeerViewColorCodes(colorSetting));
      case APP_SETTING.IvaCompanyRatingColorCodes:
        return dispatch(commonLoadCompanyRatingColors(colorSetting));
    }
  };

export const loadAppSetting =
  (appSetting: string) => async (dispatch: Dispatch<any>) => {
    const appSettingValue = await api.getAppSetting(appSetting);
    dispatch(addAppSetting(appSettingValue));
  };

export const loadDashboardExportDisclaimer =
  (appSetting: string) => async (dispatch: Dispatch<any>) => {
    const appSettingValue = await api.getAppSetting(appSetting);
    dispatch(addDashboardExportDisclaimer(appSettingValue));
  };

export const loadFooterAppSetting =
  (appSetting: string) => async (dispatch: Dispatch<any>) => {
    const appSettingValue = await api.getAppSetting(appSetting);
    dispatch(showTermOfUserFooter(appSettingValue));
  };

export const loadInsightsPageDisclaimer =
  (appSetting: string) => async (dispatch: Dispatch<any>) => {
    const appSettingValue = await api.getAppSetting(appSetting);
    dispatch(addInsightsPageDisclaimer(appSettingValue));
  };

export const loadInsightsPeerCompanyLimit =
  (appSetting: string) => async (dispatch: Dispatch<any>) => {
    const appSettingValue = await api.getAppSetting(appSetting);
    dispatch(addInsightsPeerCompanyLimit(appSettingValue));
  };

export const loadPeerBenchmarkMetricsSelectionMaxCount =
  (payload: string) => async (dispatch: Dispatch<any>) => {
    try {
      const appSettingValue = await api.getAppSetting(payload);
      let obj = JSON.parse(
        appSettingValue.applicationSettingValue.replace("\\", "")
      );
      let peerBenchmarkMetricsSelectionMaxCount = Object.keys(
        obj.MetricsSelectionMaxCount
      ).map((key: string) => ({ [key]: obj.MetricsSelectionMaxCount[key] }));

      dispatch(
        loadCommonsPeerBenchmarkMetricsMaxCount(
          peerBenchmarkMetricsSelectionMaxCount
        )
      );
    } catch (e) {
      //
    }
  };

export const Scope3CategoriesStatusColorCodes =
  (payload: string) => async (dispatch: Dispatch<any>) => {
    try {
      const appSettingValue = await api.getAppSetting(payload);
      let obj = JSON.parse(
        appSettingValue.applicationSettingValue.replace("\\", "")
      );

      dispatch(setScope3CtaegoriesColorCodes(obj));
    } catch (e) {
      //
    }
  };

/*
 * Reducer
 */

const commonsModel = new CommonsModel();

const commonsReducer = (
  state: CommonsState = commonsModel.initialState(),
  action: any
): CommonsState => {
  switch (action.type) {
    case ACTIONS.COMMONS_ADD_TOAST_MESSAGE:
      return commonsModel.addToastMessage(action.payload);
    case ACTIONS.COMMONS_REMOVE_TOAST_MESSAGE:
      return commonsModel.removeToastMessage(action.payload);
    case ACTIONS.COMMONS_SHOW_TOOLTIP:
      return commonsModel.showToolTip(action.payload);
    case ACTIONS.COMMONS_LOAD_CURRENT_USER:
      return commonsModel.loadCurrentUser(action.payload);
    case ACTIONS.COMMONS_LOAD_ESG_ASSETS:
      return commonsModel.setEsgAssets(action.payload);
    case ACTIONS.COMMONS_SHARE_LOADING:
      return commonsModel.setShareLoading(action.payload);
    case ACTIONS.COMMONS_LOAD_DASHBOARD_COLORS:
      return commonsModel.setDashboardColorCodes(action.payload);
    case ACTIONS.COMMONS_LOAD_DETAIL_PEER_VIEW_COLOR_CODES:
      return commonsModel.setDetailPeerViewColorCodes(action.payload);
    case ACTIONS.COMMONS_SHOW_TERMS_OF_USE_FOOTER:
      return commonsModel.setShowTermsOfUseFooter(action.payload);
    case ACTIONS.COMMONS_LOAD_LOWEST_SCORING_COLORS:
      return commonsModel.setLowestScoringColorCodes(action.payload);
    case ACTIONS.COMMONS_LOAD_HIGHEST_SCORING_COLORS:
      return commonsModel.setHighestScoringColorCodes(action.payload);
    case ACTIONS.COMMONS_LOAD_COMPANY_RATING_COLORS:
      return commonsModel.setIvaCompanyRatingColorCodes(action.payload);
    case ACTIONS.ESG_ASSETS_LOADING:
      return commonsModel.setEsgLoading(action.payload);
    case ACTIONS.COMMONS_SHOW_CALENDAR:
      return commonsModel.setShowCalendar(action.payload);
    case ACTIONS.COMMONS_ADD_APP_SETTING:
      return commonsModel.setAppSetting(action.payload);
    case ACTIONS.COMMONS_ADD_DASHBOARD_EXPORT_DISCLAIMER:
      return commonsModel.setDashboardExportDisclaimer(action.payload);
    case ACTIONS.COMMONS_ADD_INSIGHTS_PAGE_DISCLAIMER:
      return commonsModel.setInsightsPageDisclaimer(action.payload);
    case ACTIONS.COMMONS_ADD_PEER_COMPANY_LIMIT:
      return commonsModel.setInsightsPeerCompanyLimit(action.payload);
    case ACTIONS.COMMONS_SHOW_MAIN_TOOLTIP:
      return commonsModel.showMainToolTip(action.payload);
    case ACTIONS.COMMONS_PEER_BENCHMARK_METRICS_MAX_COUNT:
      return commonsModel.setPeerBenchmarkMetricsSelectionMaxCount(
        action.payload
      );
    case ACTIONS.COMMONS_SCOPE_3_CATOGRIES_STATUS_COLOR_CODES:
      return commonsModel.setScope3CtaegoriesColorCodes(action.payload);
    default:
      return state;
  }
};

export default commonsReducer;
