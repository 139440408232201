import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { showTooltip } from "services/commons.service";
import { Fragment, useState, useEffect } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import classNames from "classnames";
import Popover from "components/shared/popover/popover";
import { NEUTRAL } from "utils/constants";
import Parser from "html-react-parser";

type Props = {
  data: any;
  currentInsightView: number;
};

const setHyperLinks = (data: string) => {
  const links = data && data?.match(/<link>(.*?)<\/link>/g);

  return links
    ? links.reduce((prev, curr) => {
        const relativeLink: string = curr
          .replace("<link>", "")
          .startsWith("http")
          ? ""
          : "//";
        return prev.replace(
          /<link>(.*?)<\/link>/g,
          `<a target="_blank" href="${relativeLink}$1">$1</a>`
        );
      }, data)
    : data;
};
const BLOCK = "risksAndOpportunities-table";

const RisksAndOpportuniesTableTile = ({
  data,
  metricGroupValues,
  metricGroupDisplayNames,
}: any) => {
  const BLOCK = "risksAndOpportunities-table";
  let showNoDataFootnote = false;
  const dispatch = useDispatch();
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);

  let NoDataAvailable = data.every((companyData: any) =>
    companyData?.data?.every((metricData: any) =>
      metricData?.metrics?.every((metric: any) => metric.value === null)
    )
  );

  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("span");
    if (!element) {
      return;
    }

    dispatch(
      showTooltip({
        children: <div className={`${BLOCK}__tooltip`}>{Parser(children)}</div>,
        position: TooltipPosition.TileBottomRight,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  if (!data)
    return (
      <div className={`${BLOCK}__no-data`}>
        <span className="speedometer__no-data" style={{ fontWeight: "bold" }}>
          <FormattedMessage id="insights.cdp.no-data" />
        </span>
      </div>
    );

  const renderTableDataSectionally = (d: any, sectionId: number) => {
    return (
      <table
        className={`${BLOCK}-${sectionId}__data`}
        data-test={`${BLOCK}-industry`}
      >
        <tbody>
          {d.data.reduce(
            (acc: boolean, curr: any) =>
              acc &&
              curr.metrics.reduce(
                (acc: any, curr: any) => acc && curr.value === null,
                true
              ),
            true
          ) ? (
            <div className={`risksAndOpportunities__no-data`}>
              <FormattedMessage id="insights.speedometer.no-data" />
            </div>
          ) : (
            d.data.map((companyData: any, i: number) => {
              const metrics = companyData.metrics;
              let metricData: any[] = metrics.filter((m: any) =>
                [0].includes(sectionId)
                  ? sectionId === 1
                    ? true
                    : m.value && m.value.toLowerCase() !== ""
                  : true
              );
              if (
                metricData &&
                metricData.length > 0 &&
                metricData.filter(
                  (d: any) => d.value && d.value?.toLowerCase() === "no"
                ).length === metricData.length
              )
                metricData = metricData.filter(
                  (d: any) => d && d.value.toLowerCase() !== "no"
                );
              const displayMetric =
                metricData && metricData.length ? metricData : "*";

              if (!showNoDataFootnote && displayMetric === "*")
                showNoDataFootnote = true;
              return (
                <tr key={`risk-opportunities-${sectionId}-${i}`}>
                  <td className={`risksAndOpportunities--table__data`}>
                    {companyData.companyName}
                  </td>
                  <td className={`${BLOCK}__data`}>
                    <span className={`risksAndOpportunities--table__data`}>
                      <>
                        {metricData.length > 0
                          ? metricData.map((metric: any, index: number) => {
                              const metricValueTooltipIndex = metricGroupValues[
                                sectionId
                              ]?.groupValues?.indexOf(metric.value);
                              if (index !== metricData.length - 1) {
                                return (
                                  <span
                                    key={index}
                                    className={`${BLOCK}__data-value-metric`}
                                    onMouseEnter={(e) =>
                                      metric?.value?.toLowerCase() === "neutral"
                                        ? handleMouseEnter(e, NEUTRAL)
                                        : companyData.metrics[
                                            metricValueTooltipIndex
                                          ]?.tooltip &&
                                          handleMouseEnter(
                                            e,
                                            setHyperLinks(
                                              companyData.metrics[
                                                metricValueTooltipIndex
                                              ]?.tooltip
                                            )
                                          )
                                    }
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    {sectionId === 0 ? (
                                      metric?.value?.toLowerCase() ===
                                      "neutral" ? (
                                        <>
                                          {metric.metric}{" "}
                                          <span
                                            className={`${BLOCK}__normal-text`}
                                          >
                                            (Neutral)
                                          </span>
                                        </>
                                      ) : metric?.value?.toLowerCase() ===
                                        "yes" ? (
                                        metric.metric
                                      ) : null
                                    ) : (
                                      metric.value
                                    )}
                                  </span>
                                );
                              } else {
                                return (
                                  <span
                                    key={index}
                                    className={`${BLOCK}__data-value-metric`}
                                    onMouseEnter={(e) =>
                                      metric?.value?.toLowerCase() === "neutral"
                                        ? handleMouseEnter(e, NEUTRAL)
                                        : companyData.metrics[
                                            metricValueTooltipIndex
                                          ]?.tooltip &&
                                          handleMouseEnter(
                                            e,
                                            setHyperLinks(
                                              companyData.metrics[
                                                metricValueTooltipIndex
                                              ]?.tooltip
                                            )
                                          )
                                    }
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    {sectionId === 0 ? (
                                      metric?.value?.toLowerCase() ===
                                      "neutral" ? (
                                        <>
                                          {metric.metric}{" "}
                                          <span
                                            className={`${BLOCK}__normal-text`}
                                          >
                                            (Neutral)
                                          </span>
                                        </>
                                      ) : metric?.value?.toLowerCase() ===
                                        "yes" ? (
                                        metric.metric
                                      ) : null
                                    ) : (
                                      metric.value ?? "*"
                                    )}
                                  </span>
                                );
                              }
                            })
                          : "*"}
                      </>
                    </span>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    );
  };

  const renderTableInTranspose = (
    d: any,
    sectionId: number,
    metricGroupDisplayNames: any
  ) => {
    const BLOCK = "risksAndOpportunities";
    return (
      <table className={`${BLOCK}__section--table`}>
        {d.data.reduce(
          (acc: boolean, curr: any) =>
            acc &&
            curr.metrics.reduce(
              (acc: any, curr: any) => acc && curr.value === null,
              true
            ),
          true
        ) ? (
          <div className={`risksAndOpportunities__no-data`}>
            <FormattedMessage id="insights.speedometer.no-data" />
          </div>
        ) : (
          <Fragment>
            <thead className={`${BLOCK}__section--table-metric`}>
              <tr>
                <th></th>
                {d.data.map((section: any, index: number) => (
                  <th key={index} style={{ textAlign: "left" }}>
                    <span className={`${BLOCK}__company-name`}>
                      {section.companyName}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {metricGroupDisplayNames?.groupValues.map(
                (metric: any, indexForMetric: number) => (
                  <tr
                    key={`${indexForMetric}`}
                    className={`${BLOCK}__section--table`}
                  >
                    <td className={`${BLOCK}__section--table-metric`}>
                      {metric}
                    </td>
                    {d.data.map((section: any, index: number) => {
                      let value = section.metrics.filter(
                        (metricField: any) => metricField.metric === metric
                      )[0].value;
                      return (
                        <td
                          key={`${index}`}
                          className={`${BLOCK}__section--table-value`}
                        >
                          <span
                            onMouseEnter={(e) =>
                              value &&
                              value?.toLowerCase() === "neutral" &&
                              handleMouseEnter(e, NEUTRAL)
                            }
                            onMouseLeave={handleMouseLeave}
                          >
                            {value ?? "*"}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                )
              )}
            </tbody>
          </Fragment>
        )}
      </table>
    );
  };

  return (
    <div className={BLOCK} data-testid={BLOCK}>
      {!NoDataAvailable &&
        data.map((d: any, sectionId: number) => (
          <div
            key={`reporting-table-${sectionId}`}
            className={`${BLOCK}__section`}
          >
            <span className={`${BLOCK}__section-name`}>
              <Popover
                displayText={d.groupName}
                metricClassName={`${BLOCK}__section-name-lbl`}
                content={d.groupTooltip ? d.groupTooltip : []}
              />
            </span>
            {sectionId !== 1
              ? renderTableDataSectionally(d, sectionId)
              : renderTableInTranspose(
                  d,
                  sectionId,
                  metricGroupDisplayNames[sectionId]
                )}
          </div>
        ))}
      {showNoDataFootnote ? (
        <span className={`reporting__no-data-footnote`}>
          <FormattedMessage id="no.data.available" />
        </span>
      ) : null}
      {NoDataAvailable ? (
        <div className={`risksAndOpportunities__no-data`}>
          <FormattedMessage id="insights.speedometer.no-data" />
        </div>
      ) : null}
    </div>
  );
};

const RisksAndOpportuniesPeerTableTile = ({ data }: any) => {
  const BLOCK = "risks-and-opportunities-table";
  const RISKOPPSBLOCK = "risksAndOpportunities";
  let showNoDataFootnote = false;
  const renderForNormalMetric = (metricData: any, indexForMetric: number) => {
    switch (indexForMetric) {
      case 0:
        const filteredData = metricData?.metrics?.filter(
          (metric: any) => metric.value === "Yes"
        );
        if (filteredData.length === 0) showNoDataFootnote = true;
        return (
          <td
            className={classNames(`${RISKOPPSBLOCK}__peer-table-chart`)}
            key={`${RISKOPPSBLOCK}__peer-table-${indexForMetric}`}
          >
            {filteredData.length > 0
              ? filteredData.map((metric: any, index: number) => (
                  <span
                    className={`${RISKOPPSBLOCK}__peer-table-blocklevelmetric`}
                    key={`${RISKOPPSBLOCK}-${index}`}
                  >
                    {metric.value === "Yes" ? metric.metric : ""}
                  </span>
                ))
              : "*"}
          </td>
        );
      case 1:
        if (metricData.metrics.some((metric: any) => !metric.value))
          showNoDataFootnote = true;
        return (
          <Fragment key={`${RISKOPPSBLOCK}__peer-table-${indexForMetric}`}>
            {metricData.metrics.map((metric: any, metricIndex: number) => (
              <td
                className={classNames(`${BLOCK}__data data`)}
                key={`${BLOCK}-metric-${metricIndex}`}
              >
                {metric.value ?? "*"}
              </td>
            ))}
          </Fragment>
        );
      case 2:
        if (metricData.metrics.some((metric: any) => !metric.value))
          showNoDataFootnote = true;
        return (
          <td
            className={classNames(`${BLOCK}__data data`)}
            key={`${BLOCK}__peer-table-${indexForMetric}`}
          >
            {metricData.metrics.map((metric: any, metricIndex: number) => (
              <span key={`${BLOCK}-metric-${metricIndex}`}>
                {metric.value ?? "*"}
              </span>
            ))}
          </td>
        );
    }
  };

  let NoDataAvailable = data.response.every((companyData: any) =>
    companyData?.data.length > 0
      ? companyData?.data?.every((metricData: any) =>
          metricData?.metrics?.every((metric: any) => metric.value === null)
        )
      : true
  );

  return (
    <Fragment>
      {!NoDataAvailable && (
        <table
          className={classNames(`${BLOCK}__table`)}
          data-test={`${BLOCK}-peer`}
          data-testid={`${BLOCK}-peer`}
        >
          <thead className={`${RISKOPPSBLOCK}__peer-table-header`}>
            <tr>
              <td></td>
              {data.labels.map((l: any, i: number) => {
                return (
                  <th
                    key={`data-label-${i}`}
                    className={`${RISKOPPSBLOCK}__peer-table-header-text`}
                    colSpan={i === 1 ? l.groupMetric.length : 0}
                  >
                    <span
                      className={classNames(
                        `${RISKOPPSBLOCK}__peer-table-header`,
                        {
                          [`${BLOCK}__label--left`]: data.data,
                        }
                      )}
                    >
                      <div className={`${RISKOPPSBLOCK}__container`}>
                        <Popover
                          metricClassName={classNames({
                            [`${BLOCK}__label--align-left`]: data,
                          })}
                          displayText={<span>{Parser(l.label ?? "")}</span>}
                          content={l.tooltip ? l.tooltip : []}
                          buttonClassName={`${RISKOPPSBLOCK}__peer-table-header-text`}
                        />
                      </div>
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr
              className={classNames(`${RISKOPPSBLOCK}__peer-table-subheader`)}
              key={`table-row`}
            >
              <td></td>
              {data.labels?.map((d: any, sectionId: number) => {
                if (sectionId !== 1) {
                  return (
                    <td
                      className={classNames(`${BLOCK}__company`)}
                      key={`table-row=${sectionId}`}
                    />
                  );
                } else {
                  return d.groupMetric.map(
                    (metric: any, metricIndex: number) => {
                      return (
                        metric !== "" && (
                          <td
                            className={classNames(
                              `${RISKOPPSBLOCK}__peer-table-subheader`
                            )}
                            key={`table-row-${metricIndex}`}
                          >
                            {metric}
                          </td>
                        )
                      );
                    }
                  );
                }
              })}
            </tr>
            {data.response?.map((d: any, columnId: number) => {
              return (
                <tr
                  className={classNames(`${RISKOPPSBLOCK}__peer-table-chart`)}
                  key={`row-data-${columnId}`}
                >
                  <td className={classNames(`${BLOCK}__company`, {})}>
                    {d.header}
                  </td>
                  {d.data.map((metricData: any, metricNumber: number) => {
                    return renderForNormalMetric(metricData, metricNumber);
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {NoDataAvailable ? (
        <div className={`risksAndOpportunities__no-data`}>
          <FormattedMessage id="insights.speedometer.no-data" />
        </div>
      ) : null}
      {!NoDataAvailable && showNoDataFootnote ? (
        <span className={`reporting__no-data-footnote`}>
          <FormattedMessage id="no.data.available" />
        </span>
      ) : null}
    </Fragment>
  );
};

const RiskAndOpportunitiesTableView = ({ data, currentInsightView }: Props) => {
  return (
    <>
      {data && currentInsightView === 6 ? (
        <RisksAndOpportuniesTableTile
          data={data.data}
          metricGroupValues={data.metricGroupValues}
          metricGroupDisplayNames={data.metricGroupDisplayNames}
        />
      ) : (
        data && (
          <RisksAndOpportuniesPeerTableTile
            data={data}
            metricGroupValues={data.metricGroupValues}
            metricGroupDisplayNames={data.metricGroupDisplayNames}
          />
        )
      )}
    </>
  );
};

export default RiskAndOpportunitiesTableView;
