import HorizontalGraduatedChart from "components/visualizations/charts/horizontal.graduated.chart";
import { mapTableChart, useTileContext } from "../tile.context";
import {
  getFormattedNumber,
  getInsightsDetailPeerViewColorCode,
  mod,
} from "utils/functions";
import TileEmptyContent from "../tile-empty-content";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import TableChart from "components/visualizations/charts/table-chart";
import {
  INSIGHT_BENCHMARK_ID_ARRAY,
  INSIGHT_BENCHMARK_TYPE,
} from "utils/constants";
import classNames from "classnames";
import { DatasetLegend } from "components/visualizations/dataset-structure";
import { RootStore } from "services/store.service";
import { useSelector } from "react-redux";
import CustomMultipleBarChart from "components/visualizations/charts/custom-multiple.bar.chart";
import DetailPeerViewLegend from "./DetailPeerViewLegend";

const BusinessEthicsAndFraudScore = () => {
  const {
    dataset,
    index,
    metadata,
    metadata: {
      benchmarkType,
      isDetailedView,
      benchmarkMetadata,
      benchmarkTileType,
    },
    isTableViewActive,
  } = useTileContext();
  const BLOCK = "businessEthicsAndFraudScore";
  const colorCodes = benchmarkMetadata.colorCodes;
  const conditionalColorCodes = benchmarkMetadata?.conditionalColorCodes ?? [
    "#86BC25",
  ];
  const detailPeerViewColorCodes = useSelector(
    (state: RootStore) => state.commons.InsightsDetailedPeerViewColorCodes
  );

  if (
    dataset.every((d: InsightData) =>
      d.metrics.every((m: InsightMetricData) => !m.metricValue)
    )
  )
    return <TileEmptyContent />;

  const mapChartData = () => {
    return dataset.map((d: InsightData, dIndex: number) => ({
      ...d,
      metrics: d.metrics.map((m: InsightMetricData) => ({
        ...m,
        metricValue:
          m.metricValue === null
            ? m.metricValue
            : getFormattedNumber(m.metricValue, 0, 1, true),
        metricValueColor: INSIGHT_BENCHMARK_ID_ARRAY.includes(d.globalCompanyId)
          ? conditionalColorCodes
          : colorCodes[1],
      })),
    }));
  };

  const mapMultipleChartData = () => {
    return metadata.benchmarkMetadata.axis.secondary
      ? metadata.benchmarkMetadata.axis.secondary.map(
          (axis: any, i: number) => ({
            groupName: axis,
            groupPrefix: metadata.benchmarkMetadata.valueLimitProperties
              ? metadata.benchmarkMetadata.valueLimitProperties[i]
                  .fieldValueSuffix ?? ""
              : "",
            description: metadata.associatedMetricsTooltips
              ? metadata.associatedMetricsTooltips.filter(
                  (metrics: any) => metrics.associatedMetricDisplayName === axis
                )
              : [],
            separateBars: true,
            legends: dataset.reduce((prev: any[], ds: InsightData) => {
              let currentMetric = ds.metrics.filter(
                (m: InsightMetricData) =>
                  m.metricKey.toLowerCase() ===
                  metadata.benchmarkMetadata.associatedMetrics[i].toLowerCase()
              )[0];

              return [
                ...prev,
                {
                  legendData: currentMetric.metricValue,
                  legendOrder: prev.length,
                  legendTooltip: currentMetric.metricValue
                    ? `${ds.companyName} (${currentMetric.metricValue}) `
                    : `${ds.companyName} (*)`,
                  legendValue: ds.companyName,
                  legendColor: getInsightsDetailPeerViewColorCode(
                    detailPeerViewColorCodes,
                    ds.displayOrder!
                  ),
                },
              ];
            }, []),
          })
        )
      : [];
  };

  const tileChartProps = {
    benchmarkType,
    benchmarkTileType,
    benchmarkSubTitle: benchmarkMetadata.benchmarkSubTitle,
    gradientBackgroundValue: `var(--gradient-2, linear-gradient(90deg, ${
      colorCodes[0].split(",")[0]
    } 0%, ${colorCodes[0].split(",")[1]} 100%))`,
    data: mapChartData(),
    legendgradientBackground: `linear-gradient(90deg, ${
      colorCodes[0].split(",")[0]
    } 0%, ${colorCodes[0].split(",")[1]} 100%)`,
  };

  const getMultipleChart = () => {
    const tileProps = {
      name:
        metadata.benchmarkMetadata.benchmarkValue?.charAt(0).toUpperCase() +
        metadata.benchmarkMetadata.benchmarkValue?.slice(1),
      data: mapMultipleChartData(),
      index: index,
      verticalOrientation:
        metadata.benchmarkMetadata.graphOrientation === "vertical",
    };
    const tmpData = mapMultipleChartData();
    let tilePropsMultipleBar = {
      ...tileProps,
      verticalOrientation: false,
      customChartContainerClass: `${BLOCK}__chart-container`,
      customBarContainerClass: `${BLOCK}__chart-bar`,
      dashedline: true,
      yAxisMaxValue: 10,
      noDataAvailableDisplayForCompany: true,
    };
    return (
      <div
        className={`${BLOCK}__detailedcontainer`}
        data-testid={`${BLOCK}__detailedcontainer`}
      >
        <span className={`${BLOCK}__legend-text`}>
          {benchmarkMetadata.benchmarkSubTitle}
        </span>
        <DetailPeerViewLegend
          dataset={dataset}
          isDetailedView={isDetailedView}
          colorCodes={isDetailedView ? detailPeerViewColorCodes : []}
        />
        <CustomMultipleBarChart {...tilePropsMultipleBar} />
      </div>
    );
  };

  return (
    <div 
      className={`${BLOCK}__container`}
      data-testid={`${BLOCK}__container`}
      >
      {isDetailedView && !isTableViewActive ? (
        getMultipleChart()
      ) : isTableViewActive ? (
        <div
          className={classNames(
            `${BLOCK}__table-wrapper ${BLOCK}__table-wrapper--company-peer`
          )}
        >
          <span className={`${BLOCK}__legend-text`}>
            {benchmarkMetadata.benchmarkSubTitle}
          </span>
          <TableChart
            data={mapTableChart(dataset, metadata)}
            currentInsightView={benchmarkType}
            hideTableHeader={benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY}
            horizontalTable={benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY}
          />
        </div>
      ) : (
        <HorizontalGraduatedChart {...tileChartProps} />
      )}
    </div>
  );
};

export default BusinessEthicsAndFraudScore;
