import { ACTIONS as savedComparisonsActions } from "services/saved-comparisons/saved-comparisons.service";
/** Utils to scrap and clean up data to send to analytics  */
const PAGE = {
  SEARCH: "Search",
  HOMEPAGE: "Home",
  DOWNLOADS: "Downloads",
  SHORTLIST: "Shortlist",
  MYSEARCHES: "My searches",
  COMPARISONS: "Comparisons",
  PEERBENCHMARK: "Peerbenchmark",
  DASHBOARD: "Dashboard",
};

/** Object table listing the different pages on ESG Insights to be displayed on Analytics Page */
const LOCATION_METADATA: any = {
  search: {
    pathTemplate: "/search",
    pageName: PAGE.SEARCH,
  },

  home: {
    pathTemplate: "/",
    pageName: PAGE.HOMEPAGE,
  },

  downloads: {
    pathTemplate: "/downloads",
    pageName: PAGE.DOWNLOADS,
  },

  shortlist: {
    pathTemplate: "/shortlist",
    pageName: PAGE.SHORTLIST,
  },
  mysearches: {
    pathTemplate: "/mysearches",
    pageName: PAGE.MYSEARCHES,
  },
  comparisons: {
    pathTemplate: "/comparisons",
    pageName: PAGE.COMPARISONS,
  },
  peerbenchmark: {
    pathTemplate: "/peerbenchmark",
    pageName: PAGE.PEERBENCHMARK,
  },
  dashboard: {
    pathTemplate: "/dashboard",
    pageName: PAGE.DASHBOARD,
  },
};

/** Convert the pathname to the corresponding page name */
const checkInput = (path: any, hash = "") => {
  let match = null;
  const input = path + hash;
  Object.keys(LOCATION_METADATA).forEach((keyword) => {
    if (input.includes(keyword)) {
      match = LOCATION_METADATA[keyword];
    }
  });

  if (!match && input === "/") {
    match = { pageName: PAGE.HOMEPAGE };
  }

  return match ? match : { pageName: "ESG" };
};

/** Clean up email helper, removed '@deloitte.com' */
export const cleanEmail = (email: string) => {
  if (typeof email === "string") {
    return { email: email.slice(0, -13) };
  }
  return { email: "" };
};

/** Get location page name helper function to be merged into data layer */
export const getLocation = (path: any, obj: { hash: string }) => {
  const copy = obj;
  return Object.assign(copy, checkInput(path, obj.hash));
};

export const getFilters = (state: any, filters: any) => {
  return {
    filter: {
      company: state.filters.companyFilter.map(
        ({ companyName }: any) => companyName
      ),
      keywords: filters.contentText,
      reportPeriod: filters.years,
      industry: state.filters.industryFilter.map(({ sector }: any) => sector),
      auditor: state.filters.auditorFilter.map(
        ({ auditorName }: any) => auditorName
      ),
      governingBoard: state.filters.governingBoardFilter.map(
        ({ governingBoardName }: any) => governingBoardName
      ),
    },
  };
};

export const getComparisonData = (eventType: any, payload: any) => {
  let comparisonData = {};
  if (eventType === savedComparisonsActions.ADD_TO_COMPARISON_TRACK) {
    comparisonData = {
      ...comparisonData,
      addToComparison: payload.addToComparison,
    };
  }
  if (eventType === savedComparisonsActions.NEW_COMPARISON_TRACK) {
    comparisonData = {
      ...comparisonData,
      newComparison: payload.newComparison,
    };
  }
  if (eventType === savedComparisonsActions.SHARE_COMPARISON_TRACK) {
    comparisonData = { ...comparisonData, comparisonShared: payload };
  }
  return comparisonData;
};
