import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  LegendMarker,
  getLegendMarker,
} from "components/visualizations/legend-marker";
import {
  AssociatedMetricTooltips,
  GroupedLegends,
  Legend,
} from "services/dashboard/dashboard.model";
import { GRAPH_OPTIONS } from "utils/constants";

type GroupedLegendType = {
  group: string;
  legends: Legend[];
};

export const DatasetLegend = ({
  dataset,
  BLOCK,
  groupedData,
  selectedGraphOption,
  name,
  custom,
  insight,
  hideNoData,
  hideLegend,
  isCDPLegend,
  associatedMetricsTooltip,
  associatedMetricGroupTooltips,
  addAsterisk,
  empty,
  handleMouseEnter,
  handleMouseLeave,
  hideCompanyInfo,
}: {
  dataset: Legend[];
  BLOCK: string;
  groupedData: GroupedLegends[];
  selectedGraphOption: string;
  name?: string;
  custom?: boolean;
  insight?: boolean;
  hideNoData?: boolean;
  hideLegend?: boolean;
  isCDPLegend?: boolean;
  associatedMetricsTooltip?: any;
  associatedMetricGroupTooltips?: AssociatedMetricTooltips[];
  addAsterisk?: boolean;
  empty?: boolean;
  handleMouseEnter?: any;
  handleMouseLeave?: any;
  hideCompanyInfo?: boolean;
}) => {
  if (
    empty ||
    (insight &&
      !isCDPLegend &&
      groupedData.every((group) =>
        group.legends.every((data: any) => data.legendData === null)
      ))
  )
    return <></>;
  const LegendMarkerComponent = getLegendMarker(selectedGraphOption);

  if (isCDPLegend && dataset.length > 0) {
    return (
      <div
        data-testid="cdp-metric"
        className={`${BLOCK}__datasetLegend--right`}
      >
        <div className={`${BLOCK}__datasetLegend-title-container`}>
          <span className={`${BLOCK}__datasetLegend-title`}>
            <FormattedMessage id="insights.categories" />
          </span>
        </div>
        <ol className={`${BLOCK}__datasetLegend-list`}>
          {dataset &&
            dataset.length > 0 &&
            dataset.map((item: any, index: number) => (
              <li
                className={`${BLOCK}__datasetLegend-list-item`}
                key={`${index}__${item.legendTitle}`}
              >
                {item.legendTitle}
              </li>
            ))}
        </ol>
      </div>
    );
  }
  if (selectedGraphOption === GRAPH_OPTIONS.line)
    return (
      <div className={`${BLOCK}__dataset`} data-testid="line-dataset-legend">
        <div className={`${BLOCK}__data ${BLOCK}__data--centered`}>
          <LegendMarkerComponent BLOCK={BLOCK} />
          <div className={`${BLOCK}__data--value ${BLOCK}__data--value`}>
            <span>{name}</span>
          </div>
        </div>
      </div>
    );

  const legends = insight
    ? InsightLegend(
        groupedData,
        dataset,
        BLOCK,
        LegendMarkerComponent,
        hideNoData,
        hideLegend,
        addAsterisk,
        hideCompanyInfo
      )
    : custom
    ? CustomLegend(
        dataset.sort((d1, d2) => d1.legendOrder - d2.legendOrder),
        BLOCK,
        LegendMarkerComponent
      )
    : groupedData.length || (dataset.length && dataset[0].legendTitle)
    ? GroupedLegend(
        dataset.sort((d1, d2) => d1.legendOrder - d2.legendOrder),
        groupedData,
        BLOCK,
        LegendMarkerComponent,
        custom
      )
    : LegendList(
        dataset.sort((d1, d2) => d1.legendOrder - d2.legendOrder),
        BLOCK,
        LegendMarkerComponent
      );
  if (groupedData.length && !custom) {
    return (
      <div className={`${BLOCK}__legends`}>
        <div className={`${BLOCK}__legend-groups`}>
          {groupedData.map((group, i) => (
            <span key={i} className={`${BLOCK}__legend-groups--group`}>
              <b>{i + 1}.</b>
              {group.groupName}
            </span>
          ))}
        </div>
        <div className={`${BLOCK}__legend-dataset`}>{legends}</div>
      </div>
    );
  }

  return legends;
};

const CustomLegend = (
  dataset: Legend[],
  BLOCK: string,
  LegendMarkerComponent: LegendMarker
) => {
  return (
    <>
      <div className={`${BLOCK}__dataset`} data-testid="custom-dataset-legend">
        {dataset.map((data, i) => (
          <div
            key={`data-${i}`}
            className={`${BLOCK}__data ${BLOCK}__data--custom`}
          >
            <LegendMarkerComponent BLOCK={BLOCK} data={data} />
            <div className={`${BLOCK}__data--value ${BLOCK}__data--value`}>
              <span>
                {data.legendData <= 0
                  ? `${data.legendValue}*`
                  : `${data.legendValue}`}
              </span>
            </div>
          </div>
        ))}
      </div>
      {dataset.find((data) => data.legendData <= 0) && (
        <div className={`${BLOCK}__dataset-info`}>
          <FormattedMessage id="custom.benchmark.no.rating.score.info" />
        </div>
      )}
    </>
  );
};

const InsightLegend = (
  groupedDataset: GroupedLegends[],
  dataset: Legend[],
  BLOCK: string,
  LegendMarkerComponent: LegendMarker,
  hideNoData?: boolean,
  hideLegend?: boolean,
  addAsterisk?: boolean,
  hideCompanyInfo?: boolean
) => {
  return (
    <>
      {!hideLegend && !hideCompanyInfo && dataset.length >= 1 && (
        <div
          className={`${BLOCK}__dataset`}
          data-testid="custom-dataset-legend"
          style={{ paddingBottom: hideNoData ? 0 : "1rem" }}
        >
          {dataset.map((data, i) => (
            <div
              key={`data-${i}`}
              className={`${BLOCK}__data ${BLOCK}__data--custom`}
            >
              <LegendMarkerComponent BLOCK={BLOCK} data={data} />
              <div className={`${BLOCK}__data--value ${BLOCK}__data--value`}>
                <span>{data.legendValue}</span>
              </div>
              {addAsterisk
                ? groupedDataset.reduce(
                    (acc: boolean, curr: GroupedLegends) => {
                      const legend = curr.legends.find(
                        (d) => d.legendValue === data.legendValue
                      );
                      return acc && legend?.legendData !== null;
                    },
                    true
                  )
                  ? null
                  : "*"
                : null}
            </div>
          ))}
        </div>
      )}
      {!hideNoData &&
        groupedDataset.map((data, i) =>
          data.legends.map((legend, j) => {
            const legendPrefix =
              data.legends.length === 1
                ? data.groupName
                : `${legend.legendValue} ${data.groupName}`;
            return legend.legendData ? null : (
              <div
                key={`dataset-info-${j}`}
                className={`${BLOCK}__dataset-info`}
              >
                {j === 0 && data.legends.length > 1 ? (
                  <FormattedMessage
                    id="insight.benchmark.baseline.no.data"
                    values={{ legendData: data.groupName }}
                  />
                ) : (
                  <FormattedMessage
                    id="insight.benchmark.no.data"
                    values={{ legendData: legendPrefix }}
                  />
                )}
              </div>
            );
          })
        )}
    </>
  );
};

const LegendList = (
  dataset: Legend[],
  BLOCK: string,
  LegendMarkerComponent: LegendMarker
) => (
  <div
    className={classNames(`${BLOCK}__dataset`)}
    data-testid="dataset-legend-list"
  >
    {dataset.map((data, i) => (
      <div key={`data-${i}`} className={`${BLOCK}__data-number`}>
        <LegendMarkerComponent BLOCK={BLOCK} data={data} />
        <div className={`${BLOCK}__data--value`}>
          <span>{data.legendValue}</span>
          <span>{data.legendData ?? 0}</span>
        </div>
      </div>
    ))}
  </div>
);

const GroupedLegend = (
  dataset: Legend[],
  groupedData: GroupedLegends[],
  BLOCK: string,
  LegendMarkerComponent: LegendMarker,
  custom?: boolean
) => {
  const groupedLegend: GroupedLegendType[] = dataset.reduce(
    (groupedLegends: any[], current) => {
      const currentLegend = groupedData.length
        ? {
            ...current,
            legendData: groupedData.reduce(
              (acc, curr) =>
                acc +
                curr.legends.reduce(
                  (acc, curr) =>
                    // eslint-disable-next-line eqeqeq
                    curr.legendData && curr.legendValue == current.legendValue
                      ? acc + curr.legendData
                      : acc,
                  0
                ),
              0
            ),
          }
        : current;
      const currentGroup = groupedLegends.find(
        ({ group }) => group === currentLegend.legendTitle
      );
      if (currentGroup) {
        currentGroup.legends.push(currentLegend);
        return [
          ...groupedLegends.filter(
            ({ group }) => group !== currentLegend.legendTitle
          ),
          currentGroup,
        ];
      }

      return [
        ...groupedLegends,
        {
          group: currentLegend.legendTitle,
          legends: [currentLegend],
        },
      ];
    },
    []
  );

  return dataset.length && dataset[0].legendTitle ? (
    <div className={`${BLOCK}__dataset`} data-testid="grouped-dataset-legend">
      {groupedLegend.map((group, i) => (
        <div key={`dataset-group-${i}`} className={`${BLOCK}__group`}>
          <div className={`${BLOCK}__group__title`}>{group.group}</div>
          {group.legends.map((data, j) => (
            <div
              key={`data-group-${j}`}
              className={`${BLOCK}__data ${BLOCK}__data--group`}
            >
              <LegendMarkerComponent BLOCK={BLOCK} data={data} />
              <div className={`${BLOCK}__data--value`}>
                <span>{data.legendValue}</span>
                <span>{data.legendData ?? 0}</span>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  ) : (
    LegendList(
      groupedLegend[0].legends.sort(
        (d1, d2) => d1.legendOrder - d2.legendOrder
      ),
      BLOCK,
      LegendMarkerComponent
    )
  );
};
