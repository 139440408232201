import api from "../../services/api.service";
import { companyPagination } from "utils/constants";

export const fetchDocumentFilter = (searchText: string) =>
  api
    .post(`search/companies`, {
      contentText: searchText,
      pageInfo: companyPagination,
      searchCompanies: "msci",
    })
    .then(({ data }) => data);

export const fetchGlobalCompaniesFilter = (
  searchText: string,
  isSECFiler: boolean
) =>
  api
    .post(`globalcompanies/list`, {
      contentText: searchText,
      pageInfo: companyPagination,
      searchCompanies: "msci",
      isSECFiler: isSECFiler,
    })
    .then(({ data }) => data);

export const fetchSuggestedPeers = (cikNumber: number) =>
  api.get(`company/${cikNumber}/suggestedPeers`).then(({ data }) => data);

export const fetchVisualizationsMetadata = () =>
  api.get(`dashboard`).then(({ data }) => data.data);

export const updateVisualizationsMetadata = (
  payload: string,
  benchmarkId: number
) =>
  api.patch(`benchmarks/${benchmarkId}`, payload).then(({ data }) => data.data);

export const editDashboardView = (payload: any) =>
  api.put(`dashboard`, payload).then(({ data }) => data);

export const fetchVisualizationDataGet = (endPoint: string, payload?: any) =>
  api.get(endPoint).then(({ data }) => data.data);

export const fetchVisualizationDataPost = (endPoint: string, payload: any) =>
  api.post(endPoint, payload).then(({ data }) => data.data);

export const applyCustomBenchmarkData = (payload: any) =>
  api.post(`benchmarks`, payload).then(({ data }) => data);

export const editCustomBenchmarkData = (payload: any) =>
  api
    .put(`benchmarks/${payload.benchmark.benchmarkId}`, payload)
    .then(({ data }) => data);

export const fetchCustomBenchmarkMetics = (payload: any) =>
  api.get(`benchmarks/${payload}/metrics`).then(({ data }) => data);

export const deleteCustomBenchmark = (benchmarkId: any) =>
  api.delete(`benchmarks/${benchmarkId}`).then(({ data }) => data);

export const duplicateCustomBenchmark = (payload: number) =>
  api.post(`dashboard/${payload}`).then(({ data }) => data);

export const rearrangeDashboardTiles = (payload: number) =>
  api.put(`dashboard`, payload).then(({ data }) => data);

export const fetchIndustryFilter = () =>
  api.get(`dashboard/industries`).then(({ data }) => data);
