import React, { Fragment, useEffect, useState } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { useDispatch, useSelector } from "react-redux";
import Icon from "components/shared/icon/icon";
import classNames from "classnames";
import { showTooltip } from "services/commons.service";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CDPBenchmarkGridViewRow from "./cdpbenchmark-grid-row";
import ResizeGridHeader from "./resize-grid-header";
import {
  GlobalCompanyGroup,
  GlobalCompanyMapping,
} from "services/insights/insights.model";
import {
  UserPreference,
  companiesColumnWidthObject,
  companiesDisplayOrderObject,
  fieldColumnWidthObject,
} from "services/peer-benchmark/peer-benchmark.model";
import { patchUserPreference } from "services/peer-benchmark/peer-benchmark.service";

type RowItemCompany = {
  companyName: string;
  globalCompanyId: number;
  isBaseline: boolean;
  fields: any;
};

type MetricCategory = {
  metricCategoryId: number;
  metricCategoryName: string;
  fields: any;
  displayOrder: string;
  displayName: string;
};

type BenchmarkCompany = {
  benchmarkId: number;
  globalCompanyId: number;
  isBaseCompany: boolean;
  displayOrder: number;
  company: {
    companyId: number;
    companyName: string;
    globalCompanyId: number;
  };
  globalCompanyMapping?: Partial<GlobalCompanyGroup>;
  globalCompany?: {
    companyId: number;
    companyName: string;
    globalCompanyId: number;
  };
};

export type fieldData = {
  displayName: string;
  fieldId: number;
  fieldName: string;
  description: string;
  displayOrder: number;
};

const CDPBenchmarkGridView = ({
  removeCellOrRow,
  loadingTable,
  setLoadingTable,
  setHasNullValues,
  setOpenCategory,
  openCategory,
  expandAllMetricsValues,
  setExpandAllMetricsValue,
  benchmarkCompanies,
  userPreferences,
  setUserPreferences,
}: {
  removeCellOrRow: Function;
  loadingTable: boolean;
  setLoadingTable: Function;
  setHasNullValues: Function;
  expandAllMetricsValues: boolean;
  openCategory: any[];
  setOpenCategory: Function;
  setExpandAllMetricsValue: Function;
  benchmarkCompanies: BenchmarkCompany[];
  userPreferences: Partial<UserPreference>;
  setUserPreferences: Function;
}) => {
  const BLOCK = "cdp-benchmark-grid";
  const peerBenchmarkState = useSelector(
    (state: RootStore) => state.peerBenchmarkState
  );
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const [rowItemsCompany, setRowItemsCompany] = useState<RowItemCompany[]>();
  const [categoryRowMetrics, setCategoryRowMetrics] = useState<any>();

  const dispatch = useDispatch();

  const handleTouchScroll = (e: any) => {
    if (!tooltip.children) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  useEffect(() => {
    const { currentSavedBenchmark } = peerBenchmarkState;
    const { savedUserPreference } = peerBenchmarkState;
    if (currentSavedBenchmark && savedUserPreference) {
      const benchmarkFieldValues = currentSavedBenchmark.benchmarkFieldValues;
      const fieldData = currentSavedBenchmark.fields;
      fieldData.sort(compare);
      const { userPreferenceValue } = userPreferences;
      const metricCategories = currentSavedBenchmark.metricCategories;
      metricCategories.sort(compare);
      let rowItems: RowItemCompany[] = [];
      let dataToMap: MetricCategory[] = [];
      let { companiesDisplayOrder } = userPreferenceValue || {};

      if (companiesDisplayOrder && companiesDisplayOrder.length > 0) {
        let tmpBaseCompany = benchmarkCompanies.filter(
          (benchmarkCompanyObj: BenchmarkCompany) =>
            benchmarkCompanyObj.isBaseCompany
        );
        let tmpBenchmarkCompanies = benchmarkCompanies
          .filter(
            (benchmarkCompanyObj: BenchmarkCompany) =>
              !benchmarkCompanyObj.isBaseCompany
          )
          .map((company: BenchmarkCompany) => {
            return {
              ...company,
              displayOrder:
                companiesDisplayOrder?.filter(
                  (companyDisplay: companiesDisplayOrderObject) =>
                    companyDisplay.globalCompanyId === company.globalCompanyId
                )[0]?.displayOrder ?? company.displayOrder,
            };
          })
          .sort(compare);

        benchmarkCompanies = tmpBaseCompany!.concat(tmpBenchmarkCompanies);
      }

      benchmarkCompanies.forEach((company: BenchmarkCompany) => {
        let globalCompanyIds =
          company.globalCompanyMapping &&
          company.globalCompanyMapping.globalCompanyMappingDetails &&
          company.globalCompanyMapping.globalCompanyMappingDetails.length > 0
            ? company.globalCompanyMapping.globalCompanyMappingDetails.map(
                (d: GlobalCompanyMapping) =>
                  d.globalCompanyId
                    ? d.globalCompanyId
                    : d.globalCompany.globalCompanyId
              )
            : [company.globalCompanyId];
        const fieldIds: number[] = [];

        const currentBenchmarkFieldValues = benchmarkFieldValues.filter(
          (fieldValue: any) =>
            globalCompanyIds.includes(fieldValue.globalCompanyId)
        );
        //REMOVE DUPLICATED VALUES
        let tmpCurrentBenchmarkFieldValues: any = [];
        currentBenchmarkFieldValues.forEach((val: any) => {
          if (fieldIds.includes(val.fieldId)) {
            return;
          }
          let entries = currentBenchmarkFieldValues.filter(
            (currentVal: any) => currentVal.fieldId === val.fieldId
          );
          let tmpBenchmarkFieldValue = val;
          if (
            entries.length > 1 &&
            entries.some((bv: any) => bv.fieldValue || bv.fieldValue !== "")
          )
            tmpBenchmarkFieldValue = entries.find(
              (bv: any) => bv.fieldValue || bv.fieldValue !== ""
            );

          tmpCurrentBenchmarkFieldValues.push(tmpBenchmarkFieldValue);
          fieldIds.push(val.fieldId);
        });
        const companyFieldValues = currentBenchmarkFieldValues.map(
          (fieldValue: any) => {
            const fieldValueData = fieldData.find(
              (d: any) => d.fieldId === fieldValue.fieldId
            );
            return { ...fieldValue, dataType: fieldValueData?.dataType };
          }
        );

        rowItems.push({
          companyName: company.globalCompany!.companyName,
          globalCompanyId: company.globalCompanyId,
          isBaseline: company.isBaseCompany,
          fields: companyFieldValues,
        });
      });

      dataToMap = metricCategories?.map((metricCategory: MetricCategory) => {
        return {
          ...metricCategory,
          fields: metricCategory.fields
            .sort(compare)
            .map((field: fieldData) => {
              return {
                ...field,
                height:
                  userPreferences?.userPreferenceValue?.fieldColumnWidths?.find(
                    (fieldColumnObj: fieldColumnWidthObject) =>
                      fieldColumnObj.fieldId === field.fieldId
                  )?.height,
              };
            }),
        };
      });
      setCategoryRowMetrics(dataToMap);
      setRowItemsCompany(rowItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    peerBenchmarkState.currentSavedBenchmark,
    peerBenchmarkState.savedUserPreference,
  ]);

  useEffect(() => {
    if (rowItemsCompany?.some((rowItem: any) => rowItem.fields.length === 0)) {
      setHasNullValues(true);
    }
    if (
      categoryRowMetrics?.length > 0 &&
      rowItemsCompany &&
      rowItemsCompany?.length > 0
    ) {
      categoryRowMetrics.forEach((categoryRowMetric: any) => {
        categoryRowMetric.fields.forEach((field: any) => {
          rowItemsCompany.forEach((company: any) => {
            if (
              !company.fields.some(
                (companyfield: any) => companyfield.fieldId === field.fieldId
              )
            ) {
              setHasNullValues(true);
            }
          });
        });
      });
    }
  }, [rowItemsCompany, categoryRowMetrics]);

  useEffect(() => {
    if (openCategory?.length === 0) {
      setExpandAllMetricsValue(false);
      setUserPreferences((userPref: UserPreference) => {
        return {
          ...userPref,
          userPreferenceValue: {
            ...userPref.userPreferenceValue,
            isExpanded: false,
            expandedMetricCategories: [],
          },
        };
      });
    } else if (
      openCategory?.length > 0 &&
      peerBenchmarkState?.currentSavedBenchmark?.metricCategories?.length ===
        openCategory?.length
    ) {
      setExpandAllMetricsValue(true);
      setUserPreferences((userPref: UserPreference) => {
        return {
          ...userPref,
          userPreferenceValue: {
            ...userPref.userPreferenceValue,
            isExpanded: true,
            expandedMetricCategories:
              peerBenchmarkState?.currentSavedBenchmark?.metricCategories?.map(
                (metric: any) => metric?.metricCategoryId
              ),
          },
        };
      });
    }
  }, [openCategory]);

  const savePreferencesForColumnWidth = (index: number, width: number) => {
    let companiesColumnWidth: companiesColumnWidthObject[] = [];
    if (
      userPreferences.userPreferenceValue?.companiesColumnWidths &&
      userPreferences.userPreferenceValue?.companiesColumnWidths?.length > 0
    ) {
      let filtered =
        userPreferences.userPreferenceValue?.companiesColumnWidths.find(
          (companies: companiesColumnWidthObject) =>
            companies.globalCompanyId === index
        );

      if (filtered) {
        companiesColumnWidth =
          userPreferences.userPreferenceValue?.companiesColumnWidths?.map(
            (companies: companiesColumnWidthObject) => {
              if (companies.globalCompanyId === index) {
                return {
                  ...companies,
                  width: width,
                };
              } else {
                return companies;
              }
            }
          );
      } else {
        companiesColumnWidth = [
          ...userPreferences.userPreferenceValue?.companiesColumnWidths,
          ...[{ globalCompanyId: index, width: width }],
        ];
      }
    } else {
      companiesColumnWidth = [
        {
          globalCompanyId: index,
          width: width,
        },
      ];
    }
    const newUserPreference: any = {
      ...userPreferences,
      userPreferenceValue: {
        ...userPreferences.userPreferenceValue,
        companiesColumnWidths: companiesColumnWidth ?? [],
      },
    };

    dispatch(patchUserPreference(newUserPreference));
    setUserPreferences((userPref: UserPreference) => {
      return {
        ...userPref,
        userPreferenceValue: {
          ...userPref.userPreferenceValue,
          companiesColumnWidths: companiesColumnWidth,
        },
      };
    });
  };

  const compare = (company1: any, company2: any) => {
    if (company1.displayOrder < company2.displayOrder) {
      return -1;
    }
    if (company1.displayOrder > company2.displayOrder) {
      return 1;
    }
    return 0;
  };

  const renderCompanyData = (rowItems: any, removeCellOrRow?: Function) => {
    return (
      <>
        {!rowItems.isBaseline && (
          <button
            className={`${BLOCK}__rowItem--remove-button`}
            onClick={() =>
              removeCellOrRow ? removeCellOrRow(rowItems.companyName) : null
            }
            data-testid="remove-button"
          >
            <Icon name="cross" width={16} height={16} />
          </button>
        )}
        <div className={`${BLOCK}__rowItem-companyName`}>
          {rowItems.companyName}
        </div>
      </>
    );
  };

  const reorderColumns = (
    currentIndex: number,
    newIndex: number,
    items: any[],
    setItems: Function
  ) => {
    if (!items) return;
    const itemsCompany = [
      ...items.slice(0, currentIndex),
      ...items.slice(currentIndex + 1),
    ];
    let reorderedColumns = [
      ...itemsCompany.slice(0, newIndex),
      items[currentIndex],
      ...itemsCompany.slice(newIndex),
    ];
    dispatch(
      patchUserPreference({
        ...userPreferences,
        userPreferenceValue: {
          ...userPreferences.userPreferenceValue,
          companiesDisplayOrder: reorderedColumns.map((company: any, index) => {
            return {
              globalCompanyId: company.globalCompanyId,
              displayOrder: index + 1,
            };
          }),
        },
      })
    );
    setUserPreferences((userpref: UserPreference) => {
      return {
        ...userpref,
        userPreferenceValue: {
          ...userpref.userPreferenceValue,
          companiesDisplayOrder: reorderedColumns.map((company: any, index) => {
            return {
              globalCompanyId: company.globalCompanyId,
              displayOrder: index + 1,
            };
          }),
        },
      };
    });

    setItems([
      ...itemsCompany.slice(0, newIndex),
      items[currentIndex],
      ...itemsCompany.slice(newIndex),
    ]);
  };

  return (
    <div className={`${BLOCK}`} data-testid="cdp-benchmark-grid">
      <div
        className={`${BLOCK}__table--container`}
        onTouchMove={handleTouchScroll}
        data-testid="cdp-table-container"
      >
        <table
          className={classNames(`${BLOCK}__table ${BLOCK}__table--transpose`)}
          style={{ ...(loadingTable ? {} : { tableLayout: "fixed" }) }}
        >
          <thead className={`${BLOCK}__table--heading`}>
            <tr className={`${BLOCK}__table--header`}>
              <DndProvider backend={HTML5Backend}>
                <th
                  style={{ width: `304px` }}
                  className={`${BLOCK}__table--header--content`}
                >
                  <div className={`${BLOCK}__table--header-metrics`}>
                    <span>
                      <FormattedMessage id="peer.benchmark.header.metrics" />
                    </span>
                  </div>
                </th>
                {rowItemsCompany &&
                  rowItemsCompany.map((item: any, index: number) => (
                    <Fragment
                      key={`table-header-company-${item.globalCompanyId}`}
                    >
                      <ResizeGridHeader
                        BLOCK={BLOCK}
                        className={`${BLOCK}__table--header--content`}
                        disableDragDrop={
                          item.isBaseline || rowItemsCompany.length <= 2
                        }
                        finishLoading={
                          rowItemsCompany.length === index + 1
                            ? () => setLoadingTable(false)
                            : undefined
                        }
                        headerTooltip={<>{item.companyName}</>}
                        index={index}
                        columnID={item.globalCompanyId}
                        width={
                          userPreferences.userPreferenceValue?.companiesColumnWidths?.find(
                            (
                              companiesColumnWidthObj: companiesColumnWidthObject
                            ) =>
                              companiesColumnWidthObj.globalCompanyId ===
                              item.globalCompanyId
                          )?.width ?? 0
                        }
                        savePreferences={savePreferencesForColumnWidth}
                        reorderColumns={(c: number, n: number) =>
                          reorderColumns(
                            c,
                            n,
                            rowItemsCompany,
                            setRowItemsCompany
                          )
                        }
                        isBaseline={item.isBaseline}
                        header={
                          <div
                            className={`${BLOCK}__table--transpose__company-rows`}
                          >
                            {renderCompanyData(item, removeCellOrRow)}
                          </div>
                        }
                      />
                    </Fragment>
                  ))}
              </DndProvider>
              <th className={`${BLOCK}__resize-header`}></th>
            </tr>
          </thead>
          <tbody
            className={classNames(
              `${BLOCK}__table--body ${BLOCK}__table--body--normal`
            )}
          >
            {categoryRowMetrics &&
              categoryRowMetrics.length > 0 &&
              categoryRowMetrics.map(
                (categoryRowMetric: MetricCategory, indexForCategory: number) =>
                  categoryRowMetric &&
                  categoryRowMetric.fields &&
                  categoryRowMetric.fields.length > 0 && (
                    <Fragment key={`cdp-${indexForCategory}`}>
                      <CDPBenchmarkGridViewRow
                        categoryRowMetric={categoryRowMetric}
                        allCategoryRowMetrics={categoryRowMetrics}
                        indexForCategory={categoryRowMetric?.metricCategoryId}
                        expandAllMetrics={expandAllMetricsValues}
                        rowItemsCompany={rowItemsCompany}
                        removeCellOrRow={removeCellOrRow}
                        setOpenCategory={setOpenCategory}
                        openCategory={openCategory}
                        userPreferences={userPreferences}
                        setUserPreferences={setUserPreferences}
                      />
                    </Fragment>
                  )
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CDPBenchmarkGridView;
