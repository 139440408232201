import classNames from "classnames";
import Icon from "components/shared/icon/icon";
import { useEffect, useRef, useState } from "react";
import { dropdownHeight } from "utils/constants";
import FormattedMessage from "components/shared/formatted-message/formatted-message";

type ObjectKeys = {
  name: string;
  id: string;
};

type Props = {
  showLabel?: boolean;
  classForOutline?: string;
  classForChevron?: string;
  loading: boolean;
  options: any[];
  values: any;
  handleChange?: any;
  handleSelect: any;
  handleUnselect?: any;
  objectKeys?: ObjectKeys;
  textValue?: string;
  displayFunction?: any;
  handleOnEnter?: any;
  module?: string;
};

const RegionType = ({
  loading,
  options,
  values,
  handleSelect,
  objectKeys,
  textValue,
  handleOnEnter,
  module,
  classForOutline,
  classForChevron,
}: Props) => {
  const [showOptions, toggleOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<any[]>(options);
  const [inputFilter, setInputFilter] = useState<any>();
  const [input, setInput] = useState(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const inputSizeRef = useRef<HTMLDivElement>(null);
  const BLOCK = "bench-mark";

  const handleLocalSelect = (option: any) => {
    handleSelect(option);
    toggleOptions(!showOptions);
  };

  useEffect(() => {
    setFilteredOptions(options ? options : []);
  }, [options]);

  useEffect(() => {
    if (values && values.length) {
      setInputFilter(values?.regionName);
    }

    if (
      module !== "" &&
      (!values || values == null || values === "undefined")
    ) {
      setInputFilter("Select");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (showOptions && optionsRef.current) {
      optionsRef.current.focus();
      const dropdownElement = optionsRef.current?.parentElement;
      const scrollView = optionsRef.current?.parentElement?.parentElement;
      if (dropdownElement && scrollView) {
        const dropdownBottomOffset = dropdownElement.offsetTop + dropdownHeight;
        const offsetToScroll =
          dropdownBottomOffset > scrollView.clientHeight
            ? dropdownBottomOffset - scrollView.clientHeight
            : 0;
        setTimeout(() => {
          if (scrollView.scrollTo !== undefined) {
            scrollView.scrollTo({ top: offsetToScroll, behavior: "smooth" });
          }
        }, 200); // wait for the transition to finish
      }
    }
  }, [showOptions, values]);

  useEffect(() => {
    if (!loading && filteredOptions?.length === 0 && inputFilter !== "") {
      setInput(true);
    } else {
      setInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFilter, loading, filteredOptions?.length]);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (input && textValue?.length === 0) {
        setInputFilter("");
        setInput(false);
      }

      return showOptions || event.target.nodeName !== "svg"
        ? toggleOptions(
            wrapperRef.current !== null &&
              wrapperRef.current.contains(event.target)
          )
        : null;
    };

    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);

    textareaRef.current?.setAttribute(
      "style",
      `width: ${inputSizeRef.current?.clientWidth}px;
      height: ${inputSizeRef.current?.clientHeight}px`
    );
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, options, input, inputFilter, showOptions]);

  const determineIsTypeDashboardTypeSelected = (option: any) => {
    return option.regionName === values.regionName;
  };

  const getOption = (option: any, index: number) => {
    const isSelected = determineIsTypeDashboardTypeSelected(option);
    return (
      <button
        className={classNames(`${BLOCK}__option `, {
          [`${BLOCK}__option--selected`]: isSelected,
        })}
        key={`${index}-option-${index}`}
        onClick={() => handleLocalSelect(option)}
        data-test="select-option-button"
        data-testid="select-option-button"
        disabled={option?.isDisabled}
      >
        <table>
          <tbody>
            <tr>
              <td className={`${BLOCK}__option-value`} data-test="option-value">
                {option?.regionName}
              </td>
            </tr>
          </tbody>
        </table>
        {isSelected && <Icon name="tick" height={20} />}
      </button>
    );
  };
  return (
    <div
      ref={wrapperRef}
      id={`${BLOCK}`}
      className={`maintenance-modal__region-container`}
      data-test="drop-down"
      key={`${BLOCK}`}
    >
      <div className={`${BLOCK}__outlineFilter ${classForOutline}`}>
        <textarea
          ref={textareaRef}
          value={
            values && values?.regionName ? values?.regionName : inputFilter
          }
          className={`${BLOCK}__input`}
          data-test="input-text-area"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleOnEnter({});
            }
          }}
          onFocus={() => toggleOptions(true)}
          disabled={true}
          data-testid="textarea-dropdown"
        />

        {filteredOptions?.length > 0 && (
          <button
            className={classNames(`${BLOCK}__chevron-button`, {
              [`${BLOCK}__chevron-button--not-empty`]: values && values.length,
              [`${classForChevron}`]: classForChevron,
            })}
            data-test="chevron-button"
            data-testid="chevron-button"
            onClick={() => toggleOptions(!showOptions)}
          >
            <Icon
              className={classNames(`${BLOCK}__chevron-icon`, {
                [`${BLOCK}__chevron-icon--open`]: showOptions,
                [`${BLOCK}__chevron-icon--close`]: !showOptions,
              })}
              name="chevron-down"
              height={24}
            />
          </button>
        )}
      </div>
      <div
        ref={optionsRef}
        className={classNames(
          `${BLOCK}__options  maintenance-modal__outlineContainer`,
          {
            [`${BLOCK}__options--show`]: showOptions,
            [`${BLOCK}__options--hide ${BLOCK}__options--region-hide`]:
              !showOptions,
          }
        )}
        data-test="drop-down-options"
        data-testid="drop-down-options"
      >
        {loading || filteredOptions?.length === 0 ? (
          <button className={`${BLOCK}__option`}>
            {loading && (
              <Icon
                name="loading"
                width={20}
                height={20}
                data-test="loading"
                className={classNames(`${BLOCK}__loading`, {
                  "loading-icon": loading,
                })}
              />
            )}
            {!loading &&
              inputFilter !== "" &&
              filteredOptions?.length === 0 && (
                <div>
                  <FormattedMessage id="filter.no.matches" />
                </div>
              )}
          </button>
        ) : (
          filteredOptions?.map((option: any, index: number) =>
            getOption(option, index)
          )
        )}
      </div>
    </div>
  );
};

export default RegionType;
