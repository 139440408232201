import React, {
  ChangeEvent,
  useState,
  MouseEvent,
  useEffect,
  useRef,
} from "react";
import Icon from "../shared/icon/icon";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import Checkbox from "components/shared/checkbox/checkbox";

type Props = {
  onSearchDocument: (event: ChangeEvent<HTMLInputElement>) => void;
  onCollapseAll: () => void;
  onShowFullDocument: () => void;
  BLOCK: string;
  collapseValue: boolean;
  activeTab: number | undefined;
  secLinkPresent: boolean;
  updateAllSelected: Function;
  selectedAll: boolean;
};

// Header Component for TOC
const TableHeader = ({
  onSearchDocument,
  onCollapseAll,
  onShowFullDocument,
  BLOCK,
  collapseValue,
  activeTab,
  secLinkPresent,
  updateAllSelected,
  selectedAll,
}: Props) => {
  const [value, setValue] = useState("");
  const collapse = collapseValue ? "Collapse All" : "Expand All";
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (activeTab !== undefined && activeTab !== 0) {
      inputRef.current!.value = "";
      setValue("");
    }
  }, [activeTab]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onSearchDocument(e);
    setValue(e.target.value);
  };

  const onCollapseAllHandler = (e: MouseEvent<HTMLButtonElement>) => {
    onCollapseAll();
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setValue("");
    }
  };

  return (
    <div className={`${BLOCK}__secondHeader`} data-testid="table-secondHeader">
      <div className={`${BLOCK}__searchDocument`}>
        <div className={`${BLOCK}__keywords`}>
          <Checkbox
            value={selectedAll}
            onChange={() => {
              updateAllSelected(selectedAll);
            }}
          />
        </div>
        <div className={`${BLOCK}__secondHeader-icon`}>
          <Icon name="add-all" height={24} width={24} />
        </div>
        <div className={`${BLOCK}__secondHeader-search-icon2`}>
          <Icon name="search" height={24} />
        </div>
        <div className={`${BLOCK}__secondHeader-search`} data-testid="secondHeader-search">
          <input
            type="text"
            ref={inputRef}
            onChange={onChangeHandler}
            onKeyDown={onKeyDownHandler}
            value={value}
            placeholder="Search Document"
            data-testid="input-search"
          />
        </div>
      </div>

      <div className={`${BLOCK}__actions`}>
        <div className={`${BLOCK}__secondHeader-collapse`} data-testid="secondHeader-collapse">
          <span onClick={onCollapseAllHandler} data-testid="secondHeader-collapse-icon">{collapse}</span>
        </div>
        {secLinkPresent && (
          <div className={`${BLOCK}__secondHeader-show`} data-testid="secondHeader-show">
            <span onClick={onShowFullDocument} data-testid="full-document">
              <FormattedMessage id="toc.header.show.document" />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableHeader;
