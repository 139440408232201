import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { Fragment, useLayoutEffect } from "react";
import { TabularChartDataset } from "services/dashboard/dashboard.model";
import { INSIGHT_PEER_COMPANY, METRIC_VALUES } from "utils/constants";
import { isNumeric } from "utils/functions";

export type Props = {
  data: TabularChartDataset;
  dataTable: any;
  isTableView: Boolean;
  isDetailedView: Boolean;
  hasEmptyValue: Boolean;
};

export type StructuredData = {
  isHeader: boolean;
  metricTitle: boolean;
  metricDescription: boolean;
  metricValues: any[];
};

const TabularGovernanceChart = ({
  data,
  isTableView,
  isDetailedView,
  hasEmptyValue,
}: Props) => {
  const BLOCK = "TabularTile";

  useLayoutEffect(() => {
    isDetailedView &&
    nonNumericData.forEach((_, index: any) => {
      let maxHeight = 0;
      const rowElements = document.querySelectorAll(
        `.metric-row-dyn-height-${index}`
      ) as any;
      const cellElements = document.querySelectorAll(
        `.metric-value-dyn-height-${index}`
      ) as any;
      cellElements.forEach((element: any) => {
        maxHeight = Math.max(maxHeight, element.offsetHeight);
        element.style.height = `${element.offsetHeight}px`;
      });
      rowElements.forEach((element: any) => {
        element.style.minHeight = `${maxHeight}px`;
      });
    });
  }, []);

  const noPeer =
    data &&
    data.data.filter(
      (d: any) => d.companyName !== INSIGHT_PEER_COMPANY.companyName
    );
  const dataToUse = isTableView ? noPeer : data.data;
  let hasNoData =
    data && dataToUse.length
      ? dataToUse.reduce(
          (acc: boolean, { companyName, cikNumber, ...d }: any) =>
            acc && Object.values(d).every((v) => v === null),
          true
        )
      : true;

  if (hasNoData)
    return (
      <div className={`${BLOCK}__no-data`}>
        <span className="speedometer__no-data" style={{ fontWeight: "bold" }}>
          <FormattedMessage id="insights.cdp.no-data" />
        </span>
      </div>
    );
  const getNumericData = () => {
    let numericData: StructuredData[] = [];
    let nonNumericData: StructuredData[] = [];
    data.metrics.forEach((metric: any, index: number) => {
      const metricKey = metric.metricKey || metric.metric;
      let metricValues: any[] = [];
      let metricValuesTooltip: any[] = [];
      dataToUse.forEach((dataVal: any) => {
        const metricValue = dataVal[metricKey];
        if (index === 0) {
          metricValues.push(dataVal.companyName);
        } else if (!metricValue) {
          hasEmptyValue = true;
          metricValues.push("*");
          metricValuesTooltip.push(dataVal.companyName);
        } else {
          hasNoData = false;
          metricValues.push(metricValue);
          metricValuesTooltip.push(dataVal.companyName);
        }
      });

      const metricDetails = {
        isHeader: index === 0,
        metricTitle: metric.metric || "*",
        metricDescription: metric.description,
        metricValues: metricValues,
        metricValuesTooltip: metricValuesTooltip,
      };

      isNaN(metricValues[0])
        ? nonNumericData.push(metricDetails)
        : numericData.push(metricDetails);
    });

    return [nonNumericData, numericData];
  };
  const [nonNumericData, numericData] = getNumericData();

  return (
    <div
      className={classNames(`${BLOCK}__content`, {
        [`${BLOCK}--gov`]: isDetailedView,
      })}
      data-testid={`${BLOCK}__content`}>
      {!hasNoData && (
        <div
          className={classNames(`${BLOCK}__metrics`, {
            [`${BLOCK}__metrics-horizontal`]: isDetailedView,
          })}>
          {nonNumericData.map((metric: any, metricIndex: number) => {
            return (
              <div
                key={`metric-row-${metricIndex}`}
                className={classNames(`${BLOCK}__metric-row`, {
                  [`${BLOCK}__metric-row-hidden`]:
                    metricIndex === 0 &&
                    metric.metricValues.length === 1 &&
                    !isDetailedView,
                  [`${BLOCK}__metric-row-horizontal`]: isDetailedView,
                  [`${BLOCK}__metric-row-horizontal-governance-detailed`]:
                    isDetailedView,
                })}
                style={isDetailedView ? { alignItems: "flex-start" } : {}}>
                <div
                  className={classNames(`${BLOCK}__metric-title-wrapper`, {
                    [`${BLOCK}__metric-title-wrapper-height`]: !metric.isHeader,
                    [`${BLOCK}__metric-title-wrapper-governance-detailed`]:
                      isDetailedView,
                  })}
                  key={metricIndex}>
                  <div className={classNames(`${BLOCK}__metric-title`, {})}>
                    {
                      <span>
                        <Popover
                          metricClassName={`${BLOCK}__metric-title-lbl`}
                          buttonClassName={classNames(
                            `${BLOCK}__Governance-metric-button`,
                            {}
                          )}
                          displayText={metric.metricTitle}
                          content={
                            metric.metricDescription
                              ? metric.metricDescription
                              : []
                          }
                        />
                      </span>
                    }
                  </div>
                </div>
                {metric.metricValues.map((value: any, index: number) => (
                  <Fragment key={`metric-value-${index}`}>
                    <div
                      className={classNames(`${BLOCK}__metric-value-wrapper`, `metric-row-dyn-height-${index}`, {
                        [`${BLOCK}__metric-value-wrapper-header`]:
                          metricIndex === 0,
                        [`${BLOCK}__metric-value-wrapper-${
                          (METRIC_VALUES as any)[value] ?? value
                        }`]: value && isDetailedView,
                        [`${BLOCK}__metric-value-wrapper-${value}`]: value,
                        [`${BLOCK}__metric-value-wrapper-horizontal`]:
                          isDetailedView,

                        [`${BLOCK}__metric-value-wrapper-detail`]:
                          isDetailedView,
                      })}
                      style={isDetailedView ? {
                        minWidth: metricIndex === 0 ? 0 : "fit-content",
                        alignItems:
                          ((METRIC_VALUES as any)[value] ??
                            value in ["Yes", "No"]) ||
                            value === "*"
                            ? "center"
                            : "flex-start",
                      } : {}}
                      >
                      <div
                        className={classNames(`${BLOCK}__metric-value`, `metric-value-dyn-height-${index}`, {
                          [`${BLOCK}__metric-value-horizontal`]: isDetailedView,
                          [`${BLOCK}__metric-value-header`]: metricIndex === 0,
                          [`${BLOCK}__metric-value-header-hidden`]:
                            metricIndex === 0 &&
                            metric.metricValues.length === 1 &&
                            !isDetailedView,
                          [`${BLOCK}__metric-value-${value}`]:
                            value && !isTableView,
                          [`multiple`]:
                            (isDetailedView &&
                              metric.metricValues.length > 1) ||
                            isDetailedView,
                          [`${BLOCK}__metric-value-ghg-gov--blue`]:
                            data.tileType === 40 &&
                            isDetailedView &&
                            metricIndex === 1 &&
                            value !== "*",
                        })}
                        key={metric.metricTitle}
                        style={isDetailedView ? {
                          textAlign:
                            ((METRIC_VALUES as any)[value] ??
                              value in ["Yes", "No"]) ||
                            value === "*"
                              ? "center"
                              : "left",
                          minHeight: "fit-content",
                        }:{}}>
                        <span>
                          {isDetailedView
                            ? (METRIC_VALUES as any)[value] ?? value
                            : value?.charAt(0).toUpperCase() + value?.slice(1)}
                        </span>
                        {(value === "Neutral" || value === "neutral") && (
                          <Tooltip position={TooltipPosition.bottom}>
                            <>
                              <FormattedMessage id="insights.tabular.neutral" />
                            </>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
            );
          })}

          {numericData.map((metric: any, metricIndex: number) => {
            const isSingleDataView = metric.metricValues.length === 1;

            return (
              <div
                key={`metroc-row-2-${metricIndex}`}
                className={classNames(`${BLOCK}__metric-row`, {
                  [`${BLOCK}__metric-row-horizontal`]: isDetailedView,
                })}>
                {(!isSingleDataView || isDetailedView) && (
                  <div
                    className={classNames(`${BLOCK}__metric-title-wrapper`, {
                      [`${BLOCK}__metric-title-wrapper-height`]:
                        !metric.isHeader,
                      [`${BLOCK}__metric-title-wrapper-governance-detailed`]:
                        isDetailedView,
                    })}
                    key={metricIndex}>
                    <div className={`${BLOCK}__metric-title`}>
                      <Popover
                        metricClassName=""
                        buttonClassName={`${BLOCK}__metric-title-reporting`}
                        displayText={metric.metricTitle}
                        content={
                          metric.metricDescription
                            ? metric.metricDescription
                            : []
                        }
                      />
                    </div>
                  </div>
                )}
                {isSingleDataView &&
                  !isDetailedView &&
                  metric.metricValues.map((value: any, index: number) => (
                    <div
                      key={`metric-numeric-${index}`}
                      className={`${BLOCK}__metric-numeric`}>
                      <div className={`${BLOCK}__metric-numeric-wrapper`}>
                        <span className={`${BLOCK}__metric-numeric-value`}>
                          {value}
                        </span>
                        <span className={`${BLOCK}__metric-title-container`}>
                          <Popover
                            displayText={metric.metricTitle}
                            metricClassName={`${BLOCK}__metric-title ${BLOCK}__metric-title-reporting`}
                            content={
                              metric.metricDescription
                                ? metric.metricDescription
                                : []
                            }
                          />
                        </span>
                      </div>
                      <div className={`${BLOCK}__metric-numeric-visual`}>
                        <div
                          className={`${BLOCK}__metric-numeric-visual-total`}></div>
                        <div
                          className={classNames(
                            `${BLOCK}__metric-numeric-visual-score`
                          )}
                          style={{
                            width: `${value === "*" ? 0 : value * 10}%`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                {!isSingleDataView && !isDetailedView && (
                  <div
                    className={`${BLOCK}__metric-numeric ${BLOCK}__metric-numeric-multiple`}>
                    <div className={`${BLOCK}__metric-numeric-wrapper`}>
                      <span className={`${BLOCK}__metric-numeric-value`}>
                        {metric.metricValues[0]}/{metric.metricValues[1]}
                      </span>
                    </div>
                    <div className={`${BLOCK}__metric-numeric-visual`}>
                      <div
                        className={`${BLOCK}__metric-numeric-visual-total`}></div>
                      <div
                        className={classNames(
                          `${BLOCK}__metric-numeric-visual-score ${BLOCK}__metric-numeric-visual-score-multiple`
                        )}
                        style={{
                          width: `${
                            metric.metricValues[0] === "*"
                              ? 0
                              : metric.metricValues[0] * 10
                          }%`,
                        }}>
                        <div
                          className={`${BLOCK}__metric-numeric-visual-score-multiple-tooltip`}>
                          <Tooltip position={TooltipPosition.top}>
                            <span>
                              {metric.metricValues[0]}{" "}
                              {metric.metricValuesTooltip[0]}
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                      {metric.metricValues[1] !== "*" && (
                        <div
                          className={`${BLOCK}__metric-numeric-visual-score ${BLOCK}__metric-numeric-visual-score-secondary`}
                          style={{
                            left: `${metric.metricValues[1] * 10}%`,
                          }}>
                          <div
                            className={`${BLOCK}__metric-numeric-visual-score-secondary-tooltip`}>
                            <Tooltip position={TooltipPosition.top}>
                              <span>
                                {metric.metricValues[1]}{" "}
                                {metric.metricValuesTooltip[1]}
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {metric.metricValues.map((value: any, index: number) => (
                  <Fragment key={`metric-value-${index}`}>
                    <div
                      className={classNames(`${BLOCK}__metric-value-wrapper`, {
                        [`${BLOCK}__metric-value-wrapper-${value}`]: value,
                        [`${BLOCK}__metric-value-wrapper-detail`]:
                          isDetailedView,
                      })}>
                      <div
                        className={classNames(`${BLOCK}__metric-value`, {
                          [`${BLOCK}__metric-value-${value}`]:
                            value && !isTableView,
                          [`${BLOCK}__metric-value-detail`]:
                            isDetailedView && isNumeric(value),
                        })}
                        key={metric.metricTitle}>
                        {!isDetailedView ? null : <span>{value} </span>}
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
            );
          })}
        </div>
      )}

      {!hasNoData && hasEmptyValue && (
        <span className={`${BLOCK}__rating-legend`}>
          <FormattedMessage id="no.data.available" />
        </span>
      )}
    </div>
  );
};

export default TabularGovernanceChart;
