import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import ToolTip from "./tooltip";

const TooltipContainer = () => {
  const BLOCK = "tooltip-overlay";
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);

  return (
    <div className={`${BLOCK}`}>
      {(tooltip.customPosition || tooltip.position) && (
        <div className={`${BLOCK}__not-empty`}>
          <ToolTip
            position={tooltip.position}
            customPosition={tooltip.customPosition}
            arrowPosition={tooltip.arrowPosition}
            elementDimensions={tooltip.elementDimensions}
            className={tooltip.className}
            width={tooltip.width}
            executeMouseLeaveEvent={tooltip.executeMouseLeaveEvent}
          >
            <>{tooltip.children}</>
          </ToolTip>
        </div>
      )}
    </div>
  );
};

export default TooltipContainer;
