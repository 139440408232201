import { useEffect, useState } from "react";
import classNames from "classnames";
import SidePanel from "components/shared/side-panel/side-panel";
import moment from "moment";
import { Tag, TagDetails } from "services/tags/tags.model";
import TagCard from "./tag-card";
import { useTagContext } from "./tag.context";
import { showMainTooltip } from "services/commons.service";
import { useDispatch } from "react-redux";

type Props = {
  handleClose: any;
  showPanel: boolean;
};
const TagsPanel = ({ handleClose, showPanel }: Props) => {
  const BLOCK = "tags-panel";
  const dispatch = useDispatch();
  const { tagPanelMaxMargin, selectedTag, tagPanelMinMargin, currentTagsList } =
    useTagContext();
  const [sortedTagsList, setSortedTagsList] = useState<any>([]);

  useEffect(() => {
    sortTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTagsList]);

  const sortTags = () => {
    if (currentTagsList && currentTagsList.length > 0) {
      currentTagsList.forEach((tag: Tag) => {
        tag.tagDetails.sort(
          (a: Partial<TagDetails>, b: Partial<TagDetails>) => {
            if (a.tagMetadata && b.tagMetadata) {
              if (
                a.tagMetadata.firstIndex === b.tagMetadata.firstIndex &&
                a.tagMetadata.lastIndex === b.tagMetadata.lastIndex
              ) {
                if (moment(a.lastUpdatedDate).isAfter(b.lastUpdatedDate))
                  return -1;
                else if (moment(a.lastUpdatedDate).isBefore(b.lastUpdatedDate))
                  return 1;
              } else if (
                a.tagMetadata.firstIndex === b.tagMetadata.firstIndex &&
                a.tagMetadata.lastIndex !== b.tagMetadata.lastIndex
              )
                return a.tagMetadata.lastIndex - b.tagMetadata.lastIndex;
              else return a.tagMetadata.firstIndex - b.tagMetadata.firstIndex;
            }

            return 0;
          }
        );
      });
      currentTagsList.sort((a: Tag, b: Tag) => {
        if (a.tagDetails[0].tagMetadata && b.tagDetails[0].tagMetadata) {
          if (
            a.tagDetails[0].tagMetadata.firstIndex ===
              b.tagDetails[0].tagMetadata.firstIndex &&
            a.tagDetails[0].tagMetadata.lastIndex ===
              b.tagDetails[0].tagMetadata.lastIndex
          ) {
            if (moment(a.lastUpdatedDate).isAfter(b.lastUpdatedDate)) return -1;
            else if (moment(a.lastUpdatedDate).isBefore(b.lastUpdatedDate))
              return 1;
          } else if (
            a.tagDetails[0].tagMetadata.firstIndex ===
              b.tagDetails[0].tagMetadata.firstIndex &&
            a.tagDetails[0].tagMetadata.lastIndex !==
              b.tagDetails[0].tagMetadata.lastIndex
          )
            return (
              a.tagDetails[0].tagMetadata.lastIndex -
              b.tagDetails[0].tagMetadata.lastIndex
            );
          else
            return (
              a.tagDetails[0].tagMetadata.firstIndex -
              b.tagDetails[0].tagMetadata.firstIndex
            );
        }
        return 0;
      });
      setSortedTagsList(JSON.parse(JSON.stringify(currentTagsList)));
    }
  };

  useEffect(() => {
    if (selectedTag)
      document
        .getElementById(`tag-panel-card-${selectedTag.tagId}`)
        ?.scrollIntoView();
  }, [selectedTag]);

  useEffect(() => {
    const handleScroll = () => {
      dispatch(
        showMainTooltip({
          children: null,
          position: null,
          elementDimensions: null,
          executeMouseLeaveEvent: null,
          isOverTooltip: null,
        })
      );
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <SidePanel
      headerText="tags.panel.title"
      handleClose={() => handleClose(false)}
      showPanel={showPanel}
      className={classNames(`${BLOCK}__wrapper`, {
        [`${BLOCK}__wrapper--max-margin`]: tagPanelMaxMargin,
        [`${BLOCK}__wrapper--min-margin`]: tagPanelMinMargin,
      })}
      dataTest="SidePanel-tags"
    >
      <div className={`${BLOCK}__container`} data-testid="tags-panel">
        {sortedTagsList.map((t: any, i: number) =>
          t.tagDetails.length ? (
            <TagCard
              key={`${t.tagId}__${i}`}
              tag={t}
              userDetail={t.tagDetails[0].userDetail}
              lastUpdatedDate={t.tagDetails[0].lastUpdatedDate}
              referenceId={t.tagDetails[0].referenceId}
              tagMetadata={t.tagDetails[0].tagMetadata}
            />
          ) : null
        )}
      </div>
    </SidePanel>
  );
};

export default TagsPanel;
