import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import emptyimg from "images/saved.shortlist-empty.png";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Button from "components/shared/button/button";
import MaintenanceMessageModal from "./maintenance-message-modal";
import {
  deleteMaintenanceMessage,
  loadRegions,
  loadTimezones,
  setActiveMaintenanceMessages,
  setDeleteMaintenanceMessage,
  setPostMaintenanceSuccess,
} from "services/manage-files/manage-files.service";
import { addToastMessage } from "services/commons.service";
import { TableHeaderItem } from "components/shared/table/table";
import Table from "components/shared/table/table";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import Icon from "components/shared/icon/icon";
import { loadMaintenanceMessages } from "services/manage-files/manage-files.service";
import { INDICATOR_VALUES } from "utils/constants";
import moment from "moment";
import { MaintenanceMessage } from "services/manage-files/manage-files.model";
import DeleteModal from "components/shared/delete-modal/delete-modal";

const MaintenanceMessages = () => {
  const BLOCK = "maintenance-messages";

  const dispatch = useDispatch();
  const manageFilesState = useSelector((state: RootStore) => state.manageFiles);
  const [showDeletePopUp, setshowDeletePopUp] = useState<boolean>(false);
  const [showEditPopUp, setShowEditPopUp] = useState<boolean>(false);
  const [isNoRecord, setIsNoRecord] = useState<boolean>(false);
  const [showMaintenanceModal, setShowMaintenanceModal] =
    useState<boolean>(false);
  const [savedMessage, setSavedMessage] = useState<MaintenanceMessage>();
  const [deleteMessageId, setDeleteMessageId] = useState<number>();

  //Table header columns
  const tableHeaders: TableHeaderItem[] = [
    {
      text: "maintenance.messages.indicator",
      isFormattedText: true,
    },
    {
      text: "maintenance.messages.region",
      isFormattedText: true,
    },
    {
      text: "maintenance.messages.message.text",
      isFormattedText: true,
    },
    {
      text: "maintenance.messages.start.datetime",
      isFormattedText: true,
    },
    {
      text: "maintenance.messages.end.datetime",
      isFormattedText: true,
    },
    {
      text: "maintenance.messages.time.zone",
      isFormattedText: true,
    },
    {
      text: "maintenance.messages.createdby.name",
      isFormattedText: true,
    },
    {
      text: "maintenance.messages.last.saved",
      isFormattedText: true,
    },
    {
      text: "saved-shortlist.actions",
      isFormattedText: true,
    },
  ];

  //load initial data
  useEffect(() => {
    dispatch(loadMaintenanceMessages());
  }, [dispatch]);

  //check records in grid
  useEffect(() => {
    if (
      manageFilesState.maintenanceMessagesResult == null ||
      (manageFilesState.maintenanceMessagesResult &&
        manageFilesState.maintenanceMessagesResult.length == 0)
    ) {
      setIsNoRecord(true);
    } else {
      setIsNoRecord(false);
    }
  }, [manageFilesState]);

  //Get table rows data
  const getTableRows = () => {
    return (
      manageFilesState.maintenanceMessagesResult &&
      manageFilesState.maintenanceMessagesResult.map((item, index) => {
        const indicatorColor = (INDICATOR_VALUES as any)[item.status];
        const actions = [
          {
            name: "edit",
            iconName: "pencil",
            id: "edit",
            onClick: () => {
              setSavedMessage(item);
              setShowEditPopUp(true);
            },
            disabled: item.status == 3 ? `${BLOCK}__disabled` : "",
          },
          {
            name: "delete",
            iconName: "remove1",
            id: "delete",
            onClick: () => {
              setDeleteMessageId(item.maintenanceMessageId);
              setshowDeletePopUp(!showDeletePopUp);
            },
            disabled: item.status == 3 ? `${BLOCK}__disabled` : "",
          },
        ];

        return {
          id: item.maintenanceMessageId,
          key: `mange-files-report-${item.maintenanceMessageId}-${index}`,
          className: `${BLOCK}__row`,
          columnItems: [
            {
              text: "",
              renderContent: () => (
                <span
                  className={`${BLOCK}__indicator ${BLOCK + indicatorColor}`}
                ></span>
              ),
            },
            { text: item.region.regionName },
            { text: item.maintenanceMessageText },
            {
              text: moment(item.startDate, "MM/DD/YYYY HH:mm:ss").format(
                "MM/DD/YYYY, hh:mm:ss A"
              ),
              tableColumnClassName: "message-table-column-text",
            },
            {
              text: moment(item.endDate, "MM/DD/YYYY HH:mm:ss").format(
                "MM/DD/YYYY, hh:mm:ss A"
              ),
              tableColumnClassName: "message-table-column-text",
            },
            {
              text: item.timezone.timezoneName,
              tableColumnClassName: "message-table-column-text",
            },
            {
              text: item.createdByName,
              tableColumnClassName: "message-table-column-text",
            },
            {
              text: moment(item.lastUpdatedDate, "YYYY-MM-DD").format(
                "MM/DD/YYYY"
              ),
              tableColumnClassName: "message-table-column-text",
            },
            {
              renderContent: () =>
                actions.map((action) => (
                  <span
                    key={action.name}
                    className={`${BLOCK}__action-icon ${BLOCK}__${action.name} ${action.disabled}`}
                    onClick={() => action.onClick()}
                    data-test={`maintenance-messages-${action.name}`}
                  >
                    <Tooltip position={TooltipPosition.top}>
                      <FormattedMessage id={action.id} />
                    </Tooltip>
                    <Icon name={action.iconName} height={24} width={24} />
                  </span>
                )),
              className: `${BLOCK}__column-content ${BLOCK}__actions`,
            },
          ],
        };
      })
    );
  };

  const handleButtonClick = () => {
    setShowMaintenanceModal(true);
  };

  useEffect(() => {
    if (manageFilesState.regions?.length === 0) {
      dispatch(loadRegions());
    }
    if (manageFilesState.timezones?.length === 0) {
      dispatch(loadTimezones());
    }
  }, []);

  useEffect(() => {
    if (manageFilesState.postMessageSuccess) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage id="manage-files.maintenance.message.add.success" />
          ),
        })
      );
      dispatch(setPostMaintenanceSuccess(false));
      dispatch(loadMaintenanceMessages());
    }
  }, [manageFilesState.postMessageSuccess]);

  useEffect(() => {
    if (manageFilesState.postMessageError) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="Bad.Request" />,
        })
      );
    }
  }, [manageFilesState.postMessageError]);

  useEffect(() => {
    if (manageFilesState.deleteMaintenanceMessageSuccess) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage id="maintenance.messages.delete.success" />
          ),
        })
      );
      dispatch(loadMaintenanceMessages());
      dispatch(setActiveMaintenanceMessages());
      dispatch(setDeleteMaintenanceMessage(false));
    }
  }, [manageFilesState.deleteMaintenanceMessageSuccess]);

  return (
    <div className={BLOCK} data-test="maintenance-message-content">
      {/* Loader Section */}
      {manageFilesState.maintenanceMessageloading && (
        <div className={`${BLOCK}__loading`} data-test="loading-icon">
          <Icon
            name="loading"
            height={50}
            width={50}
            className="loading-icon"
          />
        </div>
      )}

      {/* Header Section */}
      {!manageFilesState.maintenanceMessageloading && !isNoRecord && (
        <header className={`${BLOCK}__header`}>
          <Button
            className="button-primary"
            formattedText="maintenance.messages.add.message"
            iconName="add-plus"
            iconHeight={24}
            iconWidth={20}
            onClick={() => handleButtonClick()}
            dataTest="maintenance-message-add-button"
          />
        </header>
      )}

      {/* Table Grid */}
      {!manageFilesState.maintenanceMessageloading && !isNoRecord && (
        <div className={`${BLOCK}__table-container`}>
          <div className={`${BLOCK}__data`}>
            <div
              className={`${BLOCK}__results-count`}
              data-test="message-count"
            >
              <FormattedMessage
                id="manage-files.report.count"
                values={{
                  count: manageFilesState.maintenanceMessagesResult
                    ? manageFilesState.maintenanceMessagesResult.length
                    : 0,
                }}
              />
            </div>
          </div>

          <div
            className={`${BLOCK}__table-wrapper`}
            data-test="maintenance-message-grid-content"
          >
            <Table
              className={`${BLOCK}__table-inner-wrapper`}
              innerClassName={`${BLOCK}__table`}
              headerItems={tableHeaders}
              rowItems={getTableRows()}
            />
          </div>
        </div>
      )}

      {/* Empty page section with image */}
      {isNoRecord && !manageFilesState.maintenanceMessageloading && (
        <div
          className={`${BLOCK}__empty`}
          data-test="maintenance-message-empty"
        >
          <div className={`${BLOCK}__img`}>
            <img className={""} src={emptyimg} alt={emptyimg} />
          </div>
          <div className={`${BLOCK}__emptytext`}>
            <FormattedMessage id="maintenance.messages.tab.empty" />
          </div>
          <div className={`${BLOCK}__emptyredirect`}>
            <FormattedMessage id="maintenance.messages.empty-redirect" />
            &nbsp;
            <span
              onClick={() => handleButtonClick()}
              className={`${BLOCK}__link`}
            >
              <FormattedMessage id="maintenance.messages.empty-redirect-link" />
            </span>
            <span>.</span>
          </div>
        </div>
      )}

      {showMaintenanceModal && (
        <MaintenanceMessageModal
          showModal={showMaintenanceModal}
          setShowModal={setShowMaintenanceModal}
        />
      )}
      {showEditPopUp && (
        <MaintenanceMessageModal
          showModal={showMaintenanceModal}
          setShowModal={setShowMaintenanceModal}
          setShowEditPopUp={setShowEditPopUp}
          savedMessage={savedMessage}
        />
      )}
      {showDeletePopUp && (
        <DeleteModal
          title="maintenance.messages.delete.title"
          message={
            <>
              <FormattedMessage id="maintenance.messages.delete.subtitle" />
            </>
          }
          handleCloseModal={() => {
            setshowDeletePopUp(false);
          }}
          deleteItem={() => {
            dispatch(deleteMaintenanceMessage(deleteMessageId));
            setshowDeletePopUp(false);
          }}
        />
      )}
    </div>
  );
};

export default MaintenanceMessages;
