import TabularCDPChartValid from "components/visualizations/charts/tabular-cdp.chart.valid";
import {
  InsightCompanyFlat,
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import { INSIGHT_BENCHMARK_IDS, INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import { useTileContext } from "../tile.context";

const RelevantRisks = () => {
  const {
    metadata,
    dataset,
    originalDataset,
    metadata: { benchmarkType, isDetailedView },
    associatedMetrics,
    associatedMetricsDisplayNames,
    isTableViewActive,
  } = useTileContext();

  const companies = originalDataset.map((company: InsightData) => ({
    externalCompanyId: company.externalCompanyId,
    globalCompanyId: company.globalCompanyId,
    companyName: company.companyName,
    isBaseCompany: company.isBaseCompany,
    industryId: company.industryId,
    industryName: company.industryName,
  }));

  const mapTabularTableData = () => {
    return dataset.reduce(
      (acc: any[], curr: InsightData) => [
        ...acc,
        {
          globalCompanyId: curr.globalCompanyId,
          categories: curr.metrics.map((m: InsightMetricData, i: number) => ({
            fieldNameId: m.metricKey,
            fieldValue: m.metricValue,
            fieldName: m.metricName,
            displayOrder: i,
          })),
          companyName: curr.companyName,
        },
      ],
      []
    );
  };

  const commonsProps = {
    isDetailedView: isDetailedView,
    isTableView: isTableViewActive,
    name: "",
    currentInsightView: benchmarkType,
    benchmarkTileType: metadata.benchmarkTileType,
    baselineCompany: companies.filter(
      (c: InsightCompanyFlat) => c.isBaseCompany
    )[0],
    associatedMetrics: associatedMetrics,
    associatedMetricsDisplayNames: associatedMetricsDisplayNames,
    // TO DO: REMOVE FOLLOWING PROPERTY WHEN TABULAR CDP CHART COMPONENT IS DONE
    companies: companies,
    associatedMetricsTooltip: metadata.associatedMetricsTooltips,
    // TO DO: REMOVE FOLLOWING PROPERTY WHEN TABULAR CDP CHART COMPONENT IS DONE
    cikNumberForCDP:
      benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK
        ? INSIGHT_BENCHMARK_IDS.PEER
        : INSIGHT_BENCHMARK_IDS.INDUSTRY,
    // TO DO: REMOVE FOLLOWING PROPERTY WHEN TABULAR CDP CHART COMPONENT IS DONE
    peerCompanies: companies.filter(
      (c: InsightCompanyFlat) => !c.isBaseCompany
    ),
  };

  return (
    <TabularCDPChartValid data={mapTabularTableData()} {...commonsProps} />
  );
};

export default RelevantRisks;
