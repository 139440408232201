import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Icon from "components/shared/icon/icon";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import DropDownCollab from "../dropdown/dropdown";
import { SHARE_COMPARISON_ROLES } from "utils/constants";
import Parser from "html-react-parser";

type ObjectKeys = {
  name: string;
  id: string;
  externalId: string;
  email: string;
  firstName: string;
  lastName: string;
  title: string;
};

type Props = {
  className: string;
  label: string | object;
  showLabel?: boolean;
  placeholder: string | object;
  loading: boolean;
  options: any[];
  values: any[];
  handleChange: any;
  handleSelect: any;
  handleUnselect: any;
  objectKeys: ObjectKeys;
  textValue?: string;
  displayFunction?: any;
  handleOnEnter?: any;
  isCollab?: boolean;
};

const DropDown = ({
  className,
  label,
  showLabel = true,
  placeholder,
  loading,
  options,
  values,
  handleChange,
  handleSelect,
  handleUnselect,
  objectKeys,
  textValue,
  handleOnEnter,
  isCollab,
}: Props) => {
  const [showOptions, toggleOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputFilter, setInputFilter] = useState("");
  const [selectedRole, setSelectedRole] = useState<any>(
    SHARE_COMPARISON_ROLES.filter((e) => e.id === 2)[0]
  );
  const wrapperRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const outlineRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const inputSizeRef = useRef<HTMLDivElement>(null);
  const BLOCK = "dropdown";

  const handleLocalSelect = (option: any) => {
    handleSelect(option);
    setInputFilter("");
    setFilteredOptions([]);
  };

  const handleLocalUnselect = (option: any) => {
    handleUnselect(option);
  };

  useEffect(() => {
    const currValues = values.reduce(
      (curr: any, prev: any) => ({
        ...curr,
        [prev[objectKeys.id]]: prev[objectKeys.id],
      }),
      {}
    );

    setFilteredOptions(
      options
        ? options.filter((option: any) => !currValues[option[objectKeys.id]])
        : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    setInputFilter(textValue ? textValue : "");
  }, [textValue]);

  useEffect(() => {
    if (!loading && filteredOptions.length === 0) {
      toggleOptions(false);
    }

    if (!loading && inputFilter.trim().length > 1) {
      toggleOptions(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, filteredOptions.length]);

  useEffect(() => {
    if (inputFilter === "") {
      toggleOptions(false);
    }
  }, [inputFilter]);

  useEffect(() => {
    const handleClick = (event: any) => {
      return showOptions
        ? toggleOptions(
            (outlineRef.current !== null &&
              outlineRef.current.contains(event.target)) ||
              (optionsRef.current != null &&
                optionsRef.current.contains(event.target) &&
                filteredOptions.length !== 0)
          )
        : null;
    };

    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlineRef, options, inputFilter, showOptions]);

  const highlightOption = (option: string) => {
    if (inputFilter !== "" && option) {
      const finalStr = inputFilter.replace(
        // eslint-disable-next-line
        /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
        "\\$&"
      );
      const replace = new RegExp(`(${finalStr})`, "i");
      return option
        .split(replace)
        .map((subtext) =>
          replace.test(subtext) ? `<b>${subtext}</b>` : subtext
        )
        .join("");
    }
    return option;
  };

  const getOption = (
    option: any,
    optionKeys: ObjectKeys,
    parentId?: number
  ) => {
    return (
      <button
        className={classNames(`${BLOCK}__option`)}
        key={`${optionKeys.id}-option-${option[optionKeys.id]}-${
          option[optionKeys.externalId]
        }`}
        onClick={() =>
          handleLocalSelect(
            isCollab
              ? { ...option, userRole: selectedRole.id, parentId }
              : { ...option, parentId }
          )
        }
        data-test="select-option-button"
        data-testid="select-option-button"
      >
        <div className={`${BLOCK}__option-container`}>
          <span className={`${BLOCK}__option-value`}>
            {Parser(
              highlightOption(
                `${option[optionKeys.firstName]} ${option[optionKeys.lastName]}`
              ).toString()
            )}
          </span>
          <span className={`${BLOCK}__option-secondary-value`}>
            <span>
              {Parser(highlightOption(option[optionKeys.email]).toString())}
            </span>
            {` | ${option[optionKeys.title]}`}
          </span>
        </div>
      </button>
    );
  };

  const handleFilterChange = (e: any) => {
    const value = e.target.value;
    setInputFilter(value);

    if (value) {
      handleChange(e);
    } else {
      setFilteredOptions([]);
    }
  };

  return (
    <div
      ref={wrapperRef}
      id={`${BLOCK}-${objectKeys.id}`}
      className={`${BLOCK} ${className}`}
      data-test="drop-down"
    >
      <div className={`${BLOCK}__label`} data-test="drop-down">
        {showLabel && (label as React.ReactNode)}
      </div>
      <div ref={outlineRef} className={`${BLOCK}__outline`}>
        <textarea
          ref={textareaRef}
          value={inputFilter}
          className={`${BLOCK}__input`}
          data-test="input-text-area"
          data-testid="input-text-area-dropdown-share"
          onChange={handleFilterChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleOnEnter();
            }
          }}
        />
        {isCollab && (
          <DropDownCollab
            parentBlock={""}
            data={SHARE_COMPARISON_ROLES.filter((e) => e.id !== 0)}
            selected={selectedRole}
            keys={{
              displayName: "name",
              id: "id",
            }}
            onChange={setSelectedRole}
          ></DropDownCollab>
        )}

        <div ref={inputSizeRef} className={`${BLOCK}__input-size`}>
          {inputFilter}
        </div>
        {inputFilter === "" && (
          <div className={`${BLOCK}__placeholder`}>
            {placeholder as React.ReactNode}
          </div>
        )}
      </div>
      <div
        ref={optionsRef}
        className={classNames(`${BLOCK}__options`, {
          [`${BLOCK}__options--show`]: showOptions,
          [`${BLOCK}__options--hide`]: !showOptions,
        })}
        data-test="drop-down-options"
      >
        {loading || filteredOptions.length === 0 ? (
          <button className={`${BLOCK}__option ${BLOCK}__unclickable`}>
            {loading && (
              <Icon
                name="loading"
                width={20}
                height={20}
                data-test="loading"
                className={classNames(`${BLOCK}__loading`, {
                  "loading-icon": loading,
                })}
              />
            )}
            {!loading && inputFilter !== "" && filteredOptions.length === 0 && (
              <div>
                <FormattedMessage id="shortlists.notfound" />
              </div>
            )}
          </button>
        ) : (
          filteredOptions.map((option: any) => getOption(option, objectKeys))
        )}
      </div>
      {!isCollab && (
        <div className={`${BLOCK}__values`} data-test="values">
          {values &&
            values.map((value: any, index: number) => (
              <div
                className={`${BLOCK}__value`}
                key={`value-${value[objectKeys.id]}-${index}`}
              >
                {`${value[objectKeys.firstName]} ${value[objectKeys.lastName]}`}
                <button
                  className={`${BLOCK}__remove`}
                  data-test="unselect-option"
                  data-testid="unselect-option"
                  onClick={() => handleLocalUnselect(value)}
                >
                  <Icon name="cross" height={20} />
                </button>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
