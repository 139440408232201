import SuperAdmin from "./super-admin/super-admin";

const UserManagement = () => {
  return (
    <div data-testid="user-management-wrapper">
      <SuperAdmin />
    </div>
  );
};

export default UserManagement;
