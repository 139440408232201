import { BaseModel } from "../../utils/redux.utils";
import { UserObj } from "services/searchlist/searchlist.model";
export interface TagsState {
  loading: boolean;
  comparisonItemIdToUpdate: number;
}

export class TagsModel extends BaseModel {
  constructor() {
    super({
      loading: false,
      comparisonItemIdToUpdate: -1,
    });
  }
  setLoading = (loading: boolean): TagsState => this.merge({ loading });
  setComparisonItemIdToUpdate = (
    comparisonItemIdToUpdate: boolean
  ): TagsState => this.merge({ comparisonItemIdToUpdate });
}

export type Tag = {
  tagId: number;
  tagName: string;
  tagDescription: string;
  displayOrder: number;
  tagType: number;
  createdBy: number;
  tagDetails: Partial<TagDetails>[];
  userDetail: UserObj;
  createdDate: string;
  lastUpdatedDate: string;
  isVisible?: false;
};

export type TagDetails = {
  tagDetailId: number;
  referenceId: number;
  tagMetadata: TagMetadata;
  displayOrder: number;
  createdBy: number;
  userDetail: UserObj;
  createdDate: string;
  lastUpdatedDate: string;
};

export type TagMetadata = {
  firstIndex: number;
  lastIndex: number;
  page: number;
  text: string;
  userDetails?: UserObj;
  tagId: number;
  date: string;
  tagDetailId: number;
};

export type TagCountDetail = {
  count: number;
  referenceId: number;
};

export type TagSelected = {
  tagId: number;
  referenceId: number;
  page: number;
  tagDetailId?: number;
};
