import { Dispatch } from "redux";
import { createAction } from "utils/redux.utils";
import { NotificationModel, NotificationState } from "./notification.model";
import * as api from "./notification.api";

/*
 * Actions
 */

export const ACTIONS = {
  NOTIFICATION_ERROR: "ESG/UI/NOTIFICATION_ERROR",
  NOTIFICATION_LOADING: "ESG/UI/NOTIFICATION_LOADING",
  NOTIFICATION_FETCH: "ESG/UI/NOTIFICATION_FETCH",
};

export const notificationError = createAction(ACTIONS.NOTIFICATION_ERROR);
export const notificationLoading = createAction(ACTIONS.NOTIFICATION_LOADING);
export const notificationGet = createAction(ACTIONS.NOTIFICATION_FETCH);

export const loadNotification = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(notificationLoading(true));
    const res = await api.fetchNotification();
    dispatch(notificationGet(res.data));
    dispatch(notificationLoading(false));
  } catch (e) {
    dispatch(notificationLoading(false));
    dispatch(notificationError(e));
  }
};

export const updateNotification =
  (Payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      await api.updateNotification(Payload);
      const res = await api.fetchNotification();
      dispatch(notificationGet(res.data));
    } catch (e) {
      dispatch(notificationError(e));
    }
  };

/*
 * Reducer
 */

const notificationModel = new NotificationModel();

const notificationReducer = (
  state: NotificationState = notificationModel.initialState(),
  action: any
): NotificationState => {
  switch (action.type) {
    case ACTIONS.NOTIFICATION_ERROR:
      return notificationModel.setError(action.payload);
    case ACTIONS.NOTIFICATION_LOADING:
      return notificationModel.setLoading(action.payload);
    case ACTIONS.NOTIFICATION_FETCH:
      return notificationModel.setNotificationList(action.payload);
    default:
      return state;
  }
};

export default notificationReducer;
