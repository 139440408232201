import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import {
  MENU_ITEMS,
  ESG_REPORT_STATUS,
  ScrollOffset,
  DEFAULT_ESG_EXPORT_PAYLOAD,
  DEFAULT_ESG_RAW_EXPORT_PAYLOAD,
  SORT_ORDERS,
  manageFilesFilter,
  MAPPING_TEMPLATE_FILENAME,
  MAPPING_TEMPLATE_EXPORT_TYPE,
  maintenanceMessages,
  MANAGE_ADF_PIPELINE,
  Roles,
  unauthorizedPath,
  GENERAL_ADMIN_ROUTES,
  USER_MANAGEMENT,
  ADMIN_TABS,
  MODULE_ACCESS,
} from "utils/constants";
import history from "utils/history";
import TabbedContent from "components/shared/tabbed-content/tabbed-content";
import UploadFiles from "./upload-files";
import Table from "components/shared/table/table";
import { TableHeaderItem } from "components/shared/table/table";
import {
  editFilePopUp,
  editManageFilesReport,
  fileToEdit,
  refreshFilesList,
  loadManageFilesReports,
  loadManageFilesReportsNextPage,
  sortManageFilesReports,
  isFilteringReports,
  fileDeleteError,
  fileDeleteSuccess,
  deleteManageFilesReports,
  updateReportState,
} from "services/manage-files/manage-files.service";
import { DEFAULT_ESG_REPORT_PAYLOAD } from "utils/constants";
import { RootStore } from "services/store.service";
import EditFile from "./edit-file";
import moment from "moment";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import {
  FormTypeFilter,
  GoverningBoardFilter,
} from "services/search/filters.model";
import MapFiles from "./mapfiles";
import { sendExportItem } from "services/download/download.service";
import ActiveFilterTag from "components/shared/active-filter-tag/active-filter-tag";
import { addToastMessage } from "services/commons.service";
import DeleteModal from "components/shared/delete-modal/delete-modal";
import Button from "components/shared/button/button";
import ValidateReportsPanel from "./validate-reports";
import { exportMappingTemplate } from "services/download/download.api";
import socket, { rooms } from "utils/socket";
import MaintenanceMessages from "./maintenance-messages";
import ManageADFPipeline from "./manage-adf-pipeline";
import { isEmpty } from "lodash";
import UserManagement from "components/user-management/user-management";
import ModuleAccess from "components/module-access/module-access";

type props = {
  currTab: number;
};
const ManageFiles = ({ currTab }: props) => {
  const BLOCK = "manage-files";
  const location = useLocation();
  const dispatch = useDispatch();
  const manageFilesState = useSelector((state: RootStore) => state.manageFiles);
  const downloadState = useSelector((state: RootStore) => state.download);

  const [currentTab, setCurrentTab] = useState<number>(currTab);
  const [showDeletePopUp, setshowDeletePopUp] = useState<boolean>(false);
  const [showEditPopUp, setShowEditPopUp] = useState<boolean>(false);
  const [showUploadPopUp, setShowUploadPopUp] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<any>([]);
  const [finalTabItem, setFinalTabItems] = useState<any>([]);

  const [showValidateReports, setShowValidateReports] =
    useState<boolean>(false);
  const commonsState = useSelector((state: RootStore) => state.commons);
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );

  const tabItems = [
    {
      id: ADMIN_TABS.USER_MANAGEMENT,
      text: "manage-files.tab.user-management",
      tabId: "user-management-view--tab",
      tabPanelId: "user-management-view--tab-panel",
      location: USER_MANAGEMENT,
    },
    {
      id: ADMIN_TABS.MODULE_ACCESS,
      text: "module-access.title",
      tabId: "module-access-view--tab",
      tabPanelId: "module-access-view--tab-panel",
      location: MODULE_ACCESS,
    },
    {
      id: ADMIN_TABS.MANAGE_FILES,
      text: "manage-files.tab.manage-files",
      tabId: "manage-files-view--tab",
      tabPanelId: "manage-files-view--tab-panel",
      location: MENU_ITEMS.manageFiles.path,
    },
    {
      id: ADMIN_TABS.MAP_FILES,
      text: "manage-files.tab.map-files",
      tabId: "map-files-view--tab",
      tabPanelId: "map-files-view--tab-panel",
      location: `${MENU_ITEMS.manageFiles.path}/map-files`,
    },
    {
      id: ADMIN_TABS.MAINTENANCE_MESSAGES,
      text: "manage-files.tab.maintenance-messages",
      tabId: "maintenance-messages-view--tab",
      tabPanelId: "maintenance-messages-view--tab-panel",
      location: `${maintenanceMessages}`,
    },
  ];

  const technicalAdminTab = [
    {
      id: ADMIN_TABS.MANAGE_ADF_PIPELINE,
      text: "technical.admin.manage-adf.pipeline",
      tabId: "manage-adf-pipeline--tab",
      tabPanelId: "manage-adf-pipeline--tab-panel",
      location: `${MANAGE_ADF_PIPELINE}`,
    },
  ];

  const allAdminTabs = tabItems.concat(technicalAdminTab);
  const isSuperAdmin: boolean =
    currentUser?.adminRole?.adminRoleId === Roles.superAdmin;
  const isTechnicalAdmin: boolean = currentUser?.isTechnicalAdmin;

  useEffect(() => {
    setFinalTabItems(
      isSuperAdmin && isTechnicalAdmin
        ? allAdminTabs
        : isSuperAdmin && !isTechnicalAdmin
        ? tabItems
        : isTechnicalAdmin
        ? technicalAdminTab
        : []
    );
    if (currentUser.adminRole && isEmpty(currentUser.adminRole)) {
      history.push(unauthorizedPath);
    }
  }, [currentUser.adminRole]);

  useEffect(() => {
    socket.join(rooms.manageFiles());
    socket.on("report.status.update", (payload: any) =>
      dispatch(updateReportState(payload.data))
    );

    return () => socket.leave(rooms.manageFiles());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (manageFilesState.deleteError) {
      dispatch(fileDeleteError(false));
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="manage-files.delete.error" />,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageFilesState.deleteError]);

  useEffect(() => {
    if (manageFilesState.deleteSuccess) {
      dispatch(fileDeleteSuccess(false));
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="manage-files.delete.message" />,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageFilesState.deleteSuccess]);

  const download = (report: any) => {
    if (report) {
      if (report.formTypeId === DEFAULT_ESG_EXPORT_PAYLOAD.exportType) {
        dispatch(
          sendExportItem({
            ...DEFAULT_ESG_RAW_EXPORT_PAYLOAD,
            exportFileName: report.reportTitle,
            exportReferenceId: report.reportId,
            searchCriteria: {
              contentText: "",
            },
            downloadTabOpen: downloadState.showDownloads,
          })
        );
      }
    }
  };

  const tableHeaders: TableHeaderItem[] = [
    {
      text: "manage-files.cik.no",
      isFormattedText: true,
      sortId: "CIKNumber",
      filterId: "CIKNumber",
    },
    {
      text: "search.results.company",
      isFormattedText: true,
      sortId: "CompanyName",
      filterId: "CompanyName",
    },
    {
      text: "search.results.title",
      isFormattedText: true,
      sortId: "ReportTitle",
      filterId: "ReportTitle",
    },
    {
      text: "search.results.document-type",
      isFormattedText: true,
      sortId: "FormTypeName",
      filterId: "FormTypeName",
    },
    { text: "manage-files.esg.framework", isFormattedText: true },
    { text: "manage-files.report.year", isFormattedText: true },
    {
      text: "manage-files.upload.date",
      isFormattedText: true,
      sortId: "CreatedDate",
      filterId: "CreatedDate",
      filterPosition: "left",
      dateRange: true,
    },
    {
      text: "my-searches-status",
      isFormattedText: true,
      sortId: "ReportStatus",
      filterId: "ReportStatus",
      filterPosition: "left",
    },
    { text: "saved-shortlist.actions", isFormattedText: true },
  ];

  const getFilterOptions = () => {
    const { pageInfo } = manageFilesState.esgReports;

    return {
      activeFilter: pageInfo && pageInfo.filterBy,
      currentFilterText: pageInfo && pageInfo.filterText,
      currentFilter: manageFilesState.currentFilter,
      setFilterPop: (filter: string) => {
        dispatch(isFilteringReports(filter));
      },
      handleClose: () => {
        dispatch(isFilteringReports(""));
      },
      handleApply: (filterText: string, filterBy: string) => {
        dispatch(
          loadManageFilesReports({
            ...manageFilesState.esgReports.pageInfo,
            filterBy: filterBy,
            filterText: filterText,
          })
        );
        dispatch(isFilteringReports(""));
      },
      handleDateFilter: (filterText: string, filterBy: string) => {
        const dateRange = filterText.split(" - ");
        dispatch(
          loadManageFilesReports({
            ...manageFilesState.esgReports.pageInfo,
            filterBy: filterBy,
            filterText: filterText,
            startDate: dateRange[0],
            endDate: dateRange[1],
          })
        );
        dispatch(isFilteringReports(""));
      },
    };
  };

  useEffect(() => {
    if (finalTabItem.length > 0 && finalTabItem[0].id === tabItems[0].id) {
      dispatch(loadManageFilesReports(DEFAULT_ESG_REPORT_PAYLOAD));
    }
  }, [dispatch, finalTabItem]);

  useEffect(() => {
    setCurrentTab(
      finalTabItem.find((item: any) => item.location === location.pathname)?.id
    );
    if (
      isSuperAdmin &&
      !isTechnicalAdmin &&
      location.pathname === MANAGE_ADF_PIPELINE
    ) {
      history.push(unauthorizedPath);
    }

    if (
      !isSuperAdmin &&
      isTechnicalAdmin &&
      GENERAL_ADMIN_ROUTES.includes(location.pathname)
    ) {
      history.push(unauthorizedPath);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, finalTabItem, currentUser.adminRole]);

  const handleTabChange = (index: number) => {
    history.push(finalTabItem[index].location, location.state);
    if (
      index === 0 &&
      finalTabItem.length > 0 &&
      finalTabItem[0].id === tabItems[0].id
    ) {
      dispatch(loadManageFilesReports(DEFAULT_ESG_REPORT_PAYLOAD));
    }
  };

  useEffect(() => {
    if (manageFilesState.refreshFilesList && !manageFilesState.editFilePopUp) {
      dispatch(loadManageFilesReports(DEFAULT_ESG_REPORT_PAYLOAD));
      dispatch(refreshFilesList(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageFilesState.refreshFilesList]);

  const getTableRows = () => {
    return (
      manageFilesState.esgReports &&
      manageFilesState.esgReports.reports &&
      manageFilesState.esgReports.reports.map((report, reportIndex) => {
        const actions = [
          {
            name: "download",
            iconName: "download",
            onClick: () => {
              download(report);
            },
            id: "download",
          },
          {
            name: "edit",
            iconName: "pencil",
            onClick: () => {
              dispatch(fileToEdit(report));
              dispatch(editFilePopUp(true));
              setShowEditPopUp(true);
            },
            id: "edit",
          },
          {
            name: "delete",
            iconName: "remove1",
            onClick: () => {
              setshowDeletePopUp(!showDeletePopUp);
              setDeleteId([{ id: report.reportId }]);
            },
            id: "delete",
          },
        ];

        return {
          id: report.reportId,
          key: `mange-files-report-${report.reportId}-${reportIndex}`,
          className: `${BLOCK}__row`,
          columnItems: [
            {
              text: report.reportCompany.cikNumber
                ? report.reportCompany.cikNumber.toString()
                : "",
            },
            { text: report.companyName },
            { text: report.reportTitle },
            { text: report.formTypeName },
            {
              text:
                report.governingBoards && report.governingBoards.length > 0
                  ? report.governingBoards.reduce((prev, curr, index) => {
                      prev += curr.governingBoardName;

                      if (index !== report.governingBoards.length - 1) {
                        prev += ", ";
                      }

                      return prev;
                    }, "")
                  : "",
            },
            {
              text: report.reportYears
                ? report.reportYears.reduce((prev, curr, index) => {
                    prev += curr;

                    if (index !== report.reportYears.length - 1) {
                      prev += ", ";
                    }

                    return prev;
                  }, "")
                : "",
            },
            { text: moment(report.createdDate).format("YYYY-MM-DD") },
            {
              renderContent: () => (
                <>
                  {(ESG_REPORT_STATUS as any)[report.reportStatus] &&
                    (ESG_REPORT_STATUS as any)[report.reportStatus].status ===
                      "Failed" && (
                      <div className={`${BLOCK}__status-icon`}>
                        <Icon name="warning-2" height={18} width={18} />
                        <Tooltip position={TooltipPosition.top}>
                          <FormattedMessage
                            id={
                              (ESG_REPORT_STATUS as any)[report.reportStatus]
                                .errorId
                            }
                          />
                        </Tooltip>
                      </div>
                    )}
                  <span
                    className={`${BLOCK}__${
                      (ESG_REPORT_STATUS as any)[report.reportStatus]
                        ? (ESG_REPORT_STATUS as any)[report.reportStatus]
                            .className
                        : ""
                    }`}
                  >
                    {(ESG_REPORT_STATUS as any)[report.reportStatus]
                      ? (ESG_REPORT_STATUS as any)[report.reportStatus].status
                      : ""}
                  </span>
                </>
              ),
              className: `${BLOCK}__column-content ${BLOCK}__status`,
            },
            {
              renderContent: () =>
                actions
                  .filter(
                    (action) =>
                      (ESG_REPORT_STATUS as any)[report.reportStatus] &&
                      (ESG_REPORT_STATUS as any)[
                        report.reportStatus
                      ].actions.indexOf(action.name) !== -1
                  )
                  .map((action) => (
                    <span
                      key={action.name}
                      className={`${BLOCK}__action-icon ${BLOCK}__${action.name}`}
                      onClick={() => action.onClick()}
                    >
                      <Tooltip position={TooltipPosition.top}>
                        <FormattedMessage id={action.id} />
                      </Tooltip>
                      <Icon name={action.iconName} height={24} width={24} />
                    </span>
                  )),
              className: `${BLOCK}__column-content ${BLOCK}__actions`,
            },
          ],
        };
      })
    );
  };

  const onScroll = (e: any) => {
    if (
      e.target.scrollHeight <=
        e.target.scrollTop + e.target.clientHeight + ScrollOffset &&
      !manageFilesState.nextPageLoading
    ) {
      const { pageNumber, totalPageCount } =
        manageFilesState.esgReports.pageInfo;

      if (pageNumber < totalPageCount) {
        let payload = {
          ...manageFilesState.esgReports.pageInfo,
          pageNumber: pageNumber + 1,
        };

        dispatch(loadManageFilesReportsNextPage(payload));
      }
    }
  };

  const handlerEditFile = (
    cikNumber: number,
    reportTitle: string,
    formType: FormTypeFilter,
    governingBoards: GoverningBoardFilter[],
    reportYears: { periodYear: number }[]
  ) => {
    let payload = {
      reportId: manageFilesState.fileToEdit.reportId,
      reportTitle: reportTitle,
      reportFileName: manageFilesState.fileToEdit.reportFileName,
      reportFormType: formType,
      reportCompany: {
        cikNumber: cikNumber,
        companyName: manageFilesState.fileToEdit.reportCompany.companyName,
      },
      governingBoards: governingBoards,
      reportYears: reportYears.map((y: any) => y.periodYear),
      reportstatus: ESG_REPORT_STATUS[3].statusId,
    };
    dispatch(editManageFilesReport(payload));
  };

  const getFilterName = (filterType: string) => {
    return (manageFilesFilter as any)[filterType];
  };

  const exportTemplateForManageFile = async (payload: any) => {
    try {
      const fileURL = await exportMappingTemplate(payload);
      var downloadLink = document.createElement("a");
      downloadLink.href = fileURL;
      downloadLink.download =
        MAPPING_TEMPLATE_FILENAME +
        "_" +
        moment(new Date()).format("YYYY-MM-DD hh_mm_ss") +
        ".xlsx";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage id="downloads.toaster.progress.error" />
          ),
        })
      );
    }
  };

  const onSort = (sortId: string) => {
    if (!commonsState.showCalendar) {
      let { sortBy, sortOrder } = manageFilesState.esgReports.pageInfo;

      if (sortId === sortBy) {
        sortOrder =
          sortOrder === SORT_ORDERS.DESC ? SORT_ORDERS.ASC : SORT_ORDERS.DESC;
      } else {
        sortOrder = SORT_ORDERS.DESC;
      }

      dispatch(
        sortManageFilesReports({
          ...manageFilesState.esgReports.pageInfo,
          sortBy: sortId,
          sortOrder,
        })
      );
    }
  };

  return (
    <div className={`${BLOCK}`}>
      <div className={`${BLOCK}__header`} data-test="tab-content">
        <TabbedContent
          tabItems={finalTabItem}
          selectedIndex={finalTabItem.length === 1 ? 0 : currentTab}
          onChange={handleTabChange}
        />
      </div>
      {currentTab === tabItems[0].id && (
        <div className={`${BLOCK}__content`} data-test="user-management">
          <UserManagement />
        </div>
      )}
      {currentTab === tabItems[1].id && (
        <div className={`${BLOCK}__content`} data-test="user-management">
          <ModuleAccess />
        </div>
      )}
      {currentTab === tabItems[2].id && (
        <div className={`${BLOCK}__content`} data-test="manage-files-content">
          {manageFilesState.loading && (
            <div className={`${BLOCK}__loading`} data-test="loading-icon">
              <Icon
                name="loading"
                height={50}
                width={50}
                className="loading-icon"
              />
            </div>
          )}
          {!manageFilesState.loading && (
            <>
              <div className={`${BLOCK}__content-header`}>
                <div className={`${BLOCK}__content-header-actions`}>
                  <Button
                    className={`button-secondary`}
                    dataTest="validate-button"
                    formattedText="manage-files.validate.reports.button"
                    onClick={() => setShowValidateReports(true)}
                  />
                  <Button
                    className={`${BLOCK}__upload-button button-primary`}
                    dataTest="upload-button"
                    formattedText="manage-files.upload.button"
                    iconName="upload"
                    iconHeight={16}
                    iconWidth={16}
                    onClick={() => setShowUploadPopUp(true)}
                  />
                  <Button
                    className={`${BLOCK}__upload-button button-primary`}
                    dataTest="download-button"
                    formattedText="manage-files.download.button"
                    iconName="download"
                    iconHeight={22}
                    iconWidth={22}
                    onClick={() =>
                      exportTemplateForManageFile(MAPPING_TEMPLATE_EXPORT_TYPE)
                    }
                  />
                </div>
              </div>
              <div className={`${BLOCK}__table-container`}>
                {manageFilesState.esgReports && (
                  <>
                    <div className={`${BLOCK}__data`}>
                      <div
                        className={`${BLOCK}__results-count`}
                        data-test="reports-count"
                      >
                        <FormattedMessage
                          id="manage-files.report.count"
                          values={{
                            count: manageFilesState.esgReports
                              ? manageFilesState.esgReports.pageInfo
                                  .totalRecordCount
                              : 0,
                          }}
                        />
                      </div>

                      {manageFilesState.esgReports.pageInfo.filterBy &&
                        manageFilesState.esgReports.pageInfo.filterText && (
                          <ActiveFilterTag
                            filterText={`${getFilterName(
                              manageFilesState.esgReports.pageInfo.filterBy
                            )}: ${
                              manageFilesState.esgReports.pageInfo.filterText
                            }`}
                            handleRemove={() =>
                              dispatch(
                                loadManageFilesReports({
                                  ...DEFAULT_ESG_REPORT_PAYLOAD,
                                  sortBy:
                                    manageFilesState.esgReports.pageInfo.sortBy,
                                  sortOrder:
                                    manageFilesState.esgReports.pageInfo
                                      .sortOrder,
                                })
                              )
                            }
                          />
                        )}
                    </div>
                    <div
                      className={`${BLOCK}__table-wrapper`}
                      data-test="table-wrapper"
                      onScroll={onScroll}
                    >
                      <Table
                        className={`${BLOCK}__table-inner-wrapper`}
                        innerClassName={`${BLOCK}__table`}
                        headerItems={tableHeaders}
                        rowItems={getTableRows()}
                        sortBy={manageFilesState.esgReports.pageInfo.sortBy}
                        sortOrder={
                          manageFilesState.esgReports.pageInfo.sortOrder
                        }
                        showSpinner={manageFilesState.nextPageLoading}
                        onSort={onSort}
                        filter={getFilterOptions()}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {currentTab === tabItems[3].id && (
        <div className={`${BLOCK}__content`} data-test="map-files-content">
          <MapFiles handleTabChange={handleTabChange} />
        </div>
      )}
      {currentTab === tabItems[4].id && (
        <div className={`${BLOCK}__content`} data-test="maintenance-message">
          <MaintenanceMessages />
        </div>
      )}
      {currentTab === technicalAdminTab[0].id && (
        <div className={`${BLOCK}__content`} data-test="manage-adf-pipeline">
          <ManageADFPipeline />
        </div>
      )}
      {showDeletePopUp && (
        <DeleteModal
          title="manage-files.delete.title"
          message={
            <>
              <FormattedMessage id="manage-files.report.delete.name" />
            </>
          }
          handleCloseModal={() => {
            setshowDeletePopUp(false);
          }}
          deleteItem={() => {
            dispatch(deleteManageFilesReports(deleteId));
            setshowDeletePopUp(false);
          }}
        />
      )}
      {showUploadPopUp && (
        <UploadFiles
          setShowModal={setShowUploadPopUp}
          handleTabChange={handleTabChange}
        />
      )}
      {showEditPopUp && (
        <EditFile setShowModal={setShowEditPopUp} editFile={handlerEditFile} />
      )}
      {showValidateReports && (
        <ValidateReportsPanel
          showPanel={showValidateReports}
          handleClose={setShowValidateReports}
        />
      )}
    </div>
  );
};

export default ManageFiles;
