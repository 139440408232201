import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { TooltipPosition } from "components/shared/tooltip/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { showTooltip } from "services/commons.service";
import { RootStore } from "services/store.service";
import Parser from "html-react-parser";
import { INSIGHT_BENCHMARK_TYPE, NEUTRAL } from "utils/constants";
import TileEmptyContent from "components/insight-tile/tile-empty-content";
import { checkOtherMetricMissing } from "utils/functions";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import Popover from "components/shared/popover/popover";

type props = {
  data: any;
  benchmarkType: number;
  isDetailedView: boolean;
  associatedMetricsTooltip: AssociatedMetricTooltips[];
};

const ClimateRelatedIncetiveChart = ({
  data,
  benchmarkType,
  isDetailedView,
  associatedMetricsTooltip,
}: props) => {
  const BLOCK = "climate-related-incentive";
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const dispatch = useDispatch();

  const checkAllMetricValueMissing = data.every(
    (metricData: any) =>
      (metricData.firstMetric.metricValue === null ||
        metricData.firstMetric.metricValue === "") &&
      metricData.otherMetricDetail.every((detail: any) =>
        detail.every((data: string) => data === "")
      )
  );
  if (checkAllMetricValueMissing) {
    return (
      <div>
        <TileEmptyContent />
      </div>
    );
  }

  // Currently *no data available message is not required for this tile
  let valueMissing = data.some(
    (metricData: any) =>
      metricData.firstMetric.metricValue === null ||
      metricData.firstMetric.metricValue === "" ||
      metricData.otherMetricDetail.some((detail: any) =>
        detail.some((data: string) => data === "*")
      )
  );

  const checkAllValueMissing = (metric: any) => {
    if (metric.every((value: string) => value.trim() === "")) {
      return true;
    } else {
      return false;
    }
  };

  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("div");

    dispatch(
      showTooltip({
        children: Parser(children),
        position: TooltipPosition.top,
        customPosition: true,
        elementDimensions: element?.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  const getCompanyView = () => {
    return (
      <>
        <div className={`${BLOCK}`} data-testid={`${BLOCK}`}>
          <div className={`${BLOCK}__wrapper`}>
            <div className={`${BLOCK}__firstHeader`}>
              <div>
                <Popover
                  metricClassName={`${BLOCK}__header-name`}
                  displayText={data[0].firstMetric.metricName}
                  content={
                    associatedMetricsTooltip &&
                    associatedMetricsTooltip.length > 0
                      ? associatedMetricsTooltip.filter(
                          (tooltip: AssociatedMetricTooltips) =>
                            tooltip.associatedMetric ===
                            data[0].firstMetric.metricKey
                        )
                      : []
                  }
                />
              </div>
            </div>
            <div className={`${BLOCK}__header-border`}></div>

            <div
              className={classNames(`${BLOCK}__metricValue`, {
                [`${BLOCK}__metricValue--yes`]:
                  data[0].firstMetric.metricValue?.toLocaleLowerCase() ===
                  "yes",
              })}
            >
              {" "}
              {data[0].firstMetric.metricValue
                ? data[0].firstMetric.metricValue
                : ""}
            </div>
          </div>

          <div className={`${BLOCK}__wrapper`}>
            <div className={`${BLOCK}__header`}>
              {data[0].metrics.map(
                (metric: any, index: number) =>
                  index !== 0 && (
                    <div key={`${index}_header-name`}>
                      <Popover
                        metricClassName={`${BLOCK}__header-name`}
                        displayText={metric.metricName}
                        content={
                          associatedMetricsTooltip &&
                          associatedMetricsTooltip.length > 0
                            ? associatedMetricsTooltip.filter(
                                (tooltip: AssociatedMetricTooltips) =>
                                  tooltip.associatedMetric === metric.metricKey
                              )
                            : []
                        }
                      />
                    </div>
                  )
              )}
            </div>
            <div className={`${BLOCK}__header-border`}></div>

            <div className={`${BLOCK}__chart-otherMetric-detail`}>
              {data[0].otherMetricDetail.map(
                (metric: any, ind: number) =>
                  !checkAllValueMissing(metric) && (
                    <div
                      key={`${ind}_metric-detail`}
                      className={`${BLOCK}__chart-otherMetric-detail--wrapper`}
                    >
                      {metric.map((value: string, index: number) => (
                        <div
                          className={`${BLOCK}__chart-otherMetric-detail--value`}
                          key={`${index}--value`}
                        >
                          {value}
                        </div>
                      ))}
                    </div>
                  )
              )}
            </div>
          </div>
          {/* {valueMissing && (
            <div className="noData">
              * <FormattedMessage id="insights.speedometer.no-data" />
            </div>
          )} */}
        </div>
      </>
    );
  };

  const getDetailPeerView = () => {
    return (
      <div>
        <div className={`${BLOCK}__wrapper`} data-testid={`${BLOCK}`}>
          <div className={`${BLOCK}__firstHeader`}>
            <div>
              <Popover
                metricClassName={`${BLOCK}__header-name`}
                displayText={data[0].firstMetric.metricName}
                content={
                  associatedMetricsTooltip &&
                  associatedMetricsTooltip.length > 0
                    ? associatedMetricsTooltip.filter(
                        (tooltip: AssociatedMetricTooltips) =>
                          tooltip.associatedMetric ===
                          data[0].firstMetric.metricKey
                      )
                    : []
                }
              />
            </div>
          </div>
          <div className={`${BLOCK}__header-border`}></div>
          <table className={`${BLOCK}__tablePeer ${BLOCK}__tablePeer--detail`}>
            <thead className={`${BLOCK}__tablePeer-header`}>
              <tr>
                {data.map((data: any, index: number) => (
                  <th key={`${index}__tablePeer-header`}>{data.groupName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {data.map((data: any, index: number) => (
                  <td
                    key={`${index}_data`}
                    className={classNames(`${BLOCK}__metricValueDetail`, {
                      [`${BLOCK}__metricValueDetail--yes`]:
                        data.firstMetric.metricValue?.toLocaleLowerCase() ===
                        "yes",
                    })}
                  >
                    {data.firstMetric.metricValue
                      ? data.firstMetric.metricValue
                      : ""}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <div className={`${BLOCK}__tablePeer-container`}>
          {data.map((data: any, index: number) => (
            <div
              key={`${index}__tablePeer-container`}
              className={`${BLOCK}__tablePeer-container--body`}
            >
              <div className={`${BLOCK}__groupName`}>{data.groupName}</div>
              {!checkOtherMetricMissing(data.otherMetricDetail) ? (
                <>
                  <div
                    className={`${BLOCK}__wrapper ${BLOCK}__wrapper--detail`}
                  >
                    <div
                      className={`${BLOCK}__tablePeer-container--metricHeader ${BLOCK}__tablePeer-container--metricHeader-detail`}
                    >
                      {data.metrics.map(
                        (metric: any, index: number) =>
                          index !== 0 && (
                            <div key={`${index}__tablePeer-header-name`}>
                              <Popover
                                metricClassName={`${BLOCK}__header-name`}
                                displayText={metric.metricName}
                                content={
                                  associatedMetricsTooltip &&
                                  associatedMetricsTooltip.length > 0
                                    ? associatedMetricsTooltip.filter(
                                        (tooltip: AssociatedMetricTooltips) =>
                                          tooltip.associatedMetric ===
                                          metric.metricKey
                                      )
                                    : []
                                }
                              />
                            </div>
                          )
                      )}
                    </div>

                    <div
                      className={`${BLOCK}__chart-otherMetric-detail ${BLOCK}__chart-otherMetric-detail-detailView`}
                    >
                      {data.otherMetricDetail?.map(
                        (metric: any, index: number) =>
                          !checkAllValueMissing(metric) && (
                            <div
                              className={`${BLOCK}__chart-otherMetric-detail--tableWrapper ${BLOCK}__chart-otherMetric-detail--tableWrapper-detail`}
                              key={`${index}__chart-otherMetric-detail--tableWrapper`}
                            >
                              {metric.map((value: string, index: number) => (
                                <div
                                  className={`${BLOCK}__chart-otherMetric-detail--value`}
                                  key={`${index}__chart-otherMetric-detail--value`}
                                >
                                  {value}
                                </div>
                              ))}
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <TileEmptyContent />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getPeerIndiustryView = () => {
    return (
      <>
        <div className={`${BLOCK}__peer`} data-testid={`${BLOCK}__peer`}>
          <div className={`${BLOCK}__peer--wrapper`}>
            <div className={`${BLOCK}__wrapper`}>
              <div className={`${BLOCK}__firstHeader`}>
                <div>
                  <Popover
                    metricClassName={`${BLOCK}__header-name`}
                    displayText={data[0].firstMetric.metricName}
                    content={
                      associatedMetricsTooltip &&
                      associatedMetricsTooltip.length > 0
                        ? associatedMetricsTooltip.filter(
                            (tooltip: AssociatedMetricTooltips) =>
                              tooltip.associatedMetric ===
                              data[0].firstMetric.metricKey
                          )
                        : []
                    }
                  />
                </div>
              </div>
              <div className={`${BLOCK}__header-border`}></div>

              <div className={`${BLOCK}__chart-peer`}>
                <div className={`${BLOCK}__chart-peer--companyWrapper`}>
                  {data.map((metricData: any, index: number) => (
                    <div
                      className={`${BLOCK}__chart-peer--companyWrapper--companyName`}
                      key={`${index}_compname`}
                    >
                      {metricData.groupName}
                    </div>
                  ))}
                </div>

                <div className={`${BLOCK}__chart-peer--valueWrapper`}>
                  {data.map((metricData: any, index: number) => (
                    <div
                      key={`${index}-valuwrapper`}
                      className={classNames(
                        `${BLOCK}__chart-peer--valueWrapper--value`,
                        {
                          [`${BLOCK}__chart-peer--valueWrapper--value--yes`]:
                            metricData.firstMetric.metricValue?.toLocaleLowerCase() ===
                            "yes",
                        }
                      )}
                      data-testid={`${BLOCK}__chart-peer--valueWrapper--value-${metricData.firstMetric.metricValue}`}
                      onMouseEnter={(e) => {
                        if (
                          metricData.firstMetric.metricValue &&
                          metricData.firstMetric.metricValue.toLocaleLowerCase() ===
                            "neutral"
                        )
                          handleMouseEnter(e, NEUTRAL);
                      }}
                      onMouseLeave={(e) => {
                        handleMouseLeave(e);
                      }}
                    >
                      {metricData.firstMetric.metricValue
                        ? metricData.firstMetric.metricValue
                        : ""}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={`${BLOCK}__peer--wrapper`}>
            <div className={`${BLOCK}__wrapper`}>
              <div className={`${BLOCK}__chart-peer--header`}>
                <div className={`${BLOCK}__header-name`}> {""}</div>
                {data[0].metrics.map(
                  (metric: any, index: number) =>
                    index !== 0 && (
                      <div key={`${index}_header-name`}>
                        <Popover
                          metricClassName={`${BLOCK}__chart-peer--header-name`}
                          displayText={metric.metricName}
                          content={
                            associatedMetricsTooltip &&
                            associatedMetricsTooltip.length > 0
                              ? associatedMetricsTooltip.filter(
                                  (tooltip: AssociatedMetricTooltips) =>
                                    tooltip.associatedMetric ===
                                    metric.metricKey
                                )
                              : []
                          }
                        />
                      </div>
                    )
                )}
              </div>
              <div className={`${BLOCK}__header-border`}></div>
              <div className={`${BLOCK}__chart-peer-body`}>
                {data.map((metric: any, index: number) => (
                  <div
                    key={`${index}_chart-peer`}
                    className={`${BLOCK}__chart-peer-body--value`}
                  >
                    <div className={`${BLOCK}__chart-peer-body--companyName`}>
                      {metric.groupName}
                    </div>
                    <div>
                      {metric.otherMetricDetail.map(
                        (value: any, ind: number) =>
                          !checkAllValueMissing(value) && (
                            <div
                              key={`${ind}_valuedetail`}
                              className={`${BLOCK}__chart-peer-body--valueDetail`}
                            >
                              {value.map((val: any, ind: number) => (
                                <div
                                  key={`${ind}_val`}
                                  className={`${BLOCK}__chart-peer-body--valueDetail--value`}
                                  data-testid={`${BLOCK}__chart-peer-body--valueDetail--value-${val}`}
                                  onMouseEnter={(e) => {
                                    if (
                                      val &&
                                      val.toLocaleLowerCase() === "neutral"
                                    )
                                      handleMouseEnter(e, NEUTRAL);
                                  }}
                                  onMouseLeave={(e) => {
                                    handleMouseLeave(e);
                                  }}
                                >
                                  {val ? val : ""}
                                </div>
                              ))}
                            </div>
                          )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* {valueMissing && (
          <div className="noData">
            * <FormattedMessage id="insights.speedometer.no-data" />
          </div>
        )} */}
      </>
    );
  };
  return benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
    ? getCompanyView()
    : isDetailedView
    ? getDetailPeerView()
    : getPeerIndiustryView();
};

export default ClimateRelatedIncetiveChart;
