import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Position } from "services/commons.model";
import { showTooltip } from "services/commons.service";
import { RootStore } from "services/store.service";

export enum TooltipPosition {
  right = "right",
  left = "left",
  top = "top",
  bottom = "bottom",
  periodRight = "periodRight",
  topRight = "topRight",
  topLeft = "topLeft",
  tableTopLeft = "tableTopLeft",
  frameworkleft = "frameworkleft",
  bottomLeft = "bottomLeft",
  progressBarTop = "progressBarTop",
  TileBottomRight = "TileBottomRight",
  TileTopLeft = "TileTopLeft",
  TileTopRight = "TileTopRight",
  TileTop = "TileTop",
  TileLeft = "TileLeft",
}

type Props = {
  id?: string;
  children: JSX.Element;
  position: TooltipPosition;
  customPosition?: boolean;
  arrowPosition?: Position;
  elementDimensions?: any;
  className?: string;
  width?: number;
  executeMouseLeaveEvent?: boolean;
  hideArrow?: boolean;
};

const Tooltip = ({
  id,
  children,
  position,
  customPosition,
  arrowPosition,
  elementDimensions,
  className,
  width,
  executeMouseLeaveEvent,
  hideArrow,
}: Props) => {
  const BLOCK = "tooltip";
  const tooltipMaxWidth = 240;
  const overflowMaxHeight = 270;
  const MAX_HEIGHT = 180;
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const dispatch = useDispatch();
  const [overflowFlag, setOverflow] = useState<Boolean>(false);
  const [verticalScroll, setVerticalScroll] = useState<Boolean>(false);
  const offset: number = 100;
  const [newPositionStyle, setNewPositionStyle] = useState<any>({});

  useEffect(() => {
    if (elementDimensions) {
      setNewPositionStyle(getPredefinedPosition());
    }
  }, []);

  /* istanbul ignore next */
  const buildCustomPosition = (
    suggestedPosition: TooltipPosition,
    activeMaxHeight: boolean
  ) => {
    const tooltipElement = divRef.current
      ? divRef.current?.getBoundingClientRect()
      : null;

    const refElementMiddleX =
      elementDimensions.left + elementDimensions.width / 2;
    const tooltipElementHeight =
      !activeMaxHeight && tooltipElement ? tooltipElement.height : MAX_HEIGHT;

    const tooltipElementWidth = tooltipMaxWidth;
    let newPositionStyles = {};

    switch (suggestedPosition) {
      case TooltipPosition.bottom: {
        let tmpLeft = refElementMiddleX - tooltipElementWidth / 2;

        if (tmpLeft < 0) tmpLeft = elementDimensions.left;
        else if (tmpLeft + tooltipMaxWidth > window.innerWidth)
          tmpLeft = elementDimensions.right - tooltipMaxWidth;

        let tmpTop = elementDimensions.bottom;
        if (tmpTop + tooltipElementHeight > window.innerHeight)
          tmpTop = elementDimensions.top - tooltipElementHeight;

        newPositionStyles = {
          top: `${tmpTop}px`,
          bottom: "unset",
          left: `${tmpLeft}px`,
        };
        break;
      }
      case TooltipPosition.bottomLeft: {
        let tmpLeft = refElementMiddleX - tooltipElementWidth / 2;

        if (tmpLeft < 0) tmpLeft = elementDimensions.left;
        else if (tmpLeft + tooltipElementWidth > window.innerWidth)
          tmpLeft = elementDimensions.right - tooltipElementWidth;

        let tmpTop = elementDimensions.bottom;
        if (tmpTop + tooltipElementHeight > window.innerHeight)
          tmpTop = elementDimensions.top - tooltipElementHeight;
        newPositionStyles = {
          top: `${tmpTop}px`,
          bottom: "unset",
          left: `${tmpLeft}}px`,
        };
        break;
      }
      case TooltipPosition.TileBottomRight: {
        let tmpLeft = refElementMiddleX;

        if (tmpLeft < 0) tmpLeft = elementDimensions.left;
        else if (tmpLeft + tooltipElementWidth > window.innerWidth)
          tmpLeft = elementDimensions.right - tooltipElementWidth;

        let tmpTop = elementDimensions.bottom;
        if (tmpTop + tooltipElementHeight > window.innerHeight)
          tmpTop = elementDimensions.top - tooltipElementHeight;

        newPositionStyles = {
          top: `${tmpTop}px`,
          bottom: "unset",
          left: `${tmpLeft}px`,
        };
        break;
      }
      case TooltipPosition.TileTopLeft: {
        let tmpLeft = refElementMiddleX - tooltipElementWidth / 2;

        if (tmpLeft < 0) tmpLeft = elementDimensions.left;
        else if (tmpLeft + tooltipElementWidth > window.innerWidth)
          tmpLeft = elementDimensions.right - tooltipElementWidth;

        let tmpTop = elementDimensions.top - tooltipElementHeight;
        if (tmpTop < 0) tmpTop = elementDimensions.bottom;

        newPositionStyles = {
          top: `${tmpTop}px`,
          bottom: `unset`,
          left: `${tmpLeft}px`,
        };
        break;
      }
      case TooltipPosition.TileTopRight: {
        let tmpLeft = refElementMiddleX + tooltipElementWidth / 2;

        if (tmpLeft < 0) tmpLeft = elementDimensions.left;
        else if (tmpLeft + tooltipElementWidth > window.innerWidth)
          tmpLeft = elementDimensions.right - tooltipElementWidth;

        let tmpTop = elementDimensions.top - tooltipElementHeight;
        if (tmpTop < 0) tmpTop = elementDimensions.bottom;

        newPositionStyles = {
          top: `${tmpTop}px`,
          bottom: `unset`,
          left: `${tmpLeft}px`,
        };
        break;
      }
      case TooltipPosition.TileTop: {
        let tmpLeft = refElementMiddleX - tooltipElementWidth / 2;

        if (tmpLeft < 0) tmpLeft = elementDimensions.left;
        else if (tmpLeft + tooltipElementWidth > window.innerWidth)
          tmpLeft = elementDimensions.right - tooltipElementWidth;

        let tmpTop = elementDimensions.top - tooltipElementHeight;

        if (tmpTop < 0)
          tmpTop = elementDimensions.bottom + tooltipElementHeight;

        newPositionStyles = {
          top: `${tmpTop}px`,
          bottom: `unset`,
          left: `${tmpLeft}px`,
        };
        break;
      }
      case TooltipPosition.right: {
        let tmpLeft = elementDimensions.left;

        if (tmpLeft > window.innerWidth)
          tmpLeft = elementDimensions.right - tooltipElementWidth;

        let tmpTop = elementDimensions.top - tooltipElementHeight / 2;

        if (tmpTop < 0) tmpTop = elementDimensions.bottom;
        else if (tmpTop > window.innerHeight)
          tmpTop = elementDimensions.bottom - tooltipElementHeight;

        newPositionStyles = {
          top: `${tmpTop}px`,
          bottom: `unset`,
          left: `${tmpLeft}px`,
        };
        break;
      }
      case TooltipPosition.TileLeft: {
        let tmpLeft = elementDimensions.left - tooltipElementWidth;

        if (tmpLeft < 0) tmpLeft = elementDimensions.left;

        let tmpTop = elementDimensions.top - tooltipElementHeight / 2;

        if (tmpTop < 0) tmpTop = elementDimensions.bottom;
        else if (tmpTop > window.innerHeight)
          tmpTop = elementDimensions.bottom - tooltipElementHeight;
        newPositionStyles = {
          top: `${tmpTop}px`,
          bottom: `unset`,
          left: `${tmpLeft - 25}px`,
        };
        break;
      }
      default:
        newPositionStyles = {
          top: null,
          bottom: null,
          right: null,
          left: null,
        };
        break;
    }
    setNewPositionStyle(newPositionStyles);
  };

  const getPredefinedPosition = () => {
    switch (position) {
      case TooltipPosition.topRight:
        return {
          top: `unset`,
          bottom: `${window.innerHeight - elementDimensions.y}px`,
          left: `${elementDimensions.x + elementDimensions.width}px`,
        };
      case TooltipPosition.topLeft:
        return {
          top: `unset`,
          bottom: `${window.innerHeight - elementDimensions.y}px`,
          left: `${
            elementDimensions.x + elementDimensions.width - tooltipMaxWidth
          }px`,
        };
      case TooltipPosition.top:
        return {
          top: "unset",
          bottom: `${window.innerHeight - elementDimensions.y}px`,
          left: `${
            elementDimensions.x +
            elementDimensions.width / 2 -
            tooltipMaxWidth / 2
          }px`,
        };
      case TooltipPosition.progressBarTop:
        return {
          top: "unset",
          bottom: `${window.innerHeight - elementDimensions.y}px`,
          left: `${
            elementDimensions.x + elementDimensions.width / 2 - offset / 2
          }px`,
        };
      case TooltipPosition.left: {
        return {
          top: "unset",
          bottom: `${window.innerHeight - elementDimensions.y}px`,
          left: `${
            elementDimensions.x - (width ? width * 0.7 : tooltipMaxWidth)
          }px`,
        };
      }
      case TooltipPosition.bottom: {
        return {
          top: `${elementDimensions.bottom}px`,
          bottom: "unset",
          left: `${
            elementDimensions.x - (width ? width / 2 : tooltipMaxWidth)
          }px`,
        };
      }
      case TooltipPosition.bottomLeft: {
        return {
          top: `${elementDimensions.bottom}px`,
          bottom: "unset",
          left: `${elementDimensions.x - (width ? width : tooltipMaxWidth)}px`,
        };
      }
      case TooltipPosition.TileBottomRight: {
        return {
          top: `${elementDimensions.bottom}px`,
          bottom: "unset",
          left: `${
            elementDimensions.x -
            (width ? width / 2 : tooltipMaxWidth) +
            offset * 2.5
          }px`,
        };
      }
      case TooltipPosition.TileTopLeft: {
        return {
          top: "unset",
          bottom: `${window.innerHeight - elementDimensions.y}px`,
          left: `${
            elementDimensions.x +
            elementDimensions.width / 2 -
            tooltipMaxWidth / 2 -
            offset * 2.25
          }px`,
        };
      }
      case TooltipPosition.TileTopRight: {
        return {
          top: "unset",
          bottom: `${window.innerHeight - elementDimensions.y}px`,
          left: `${
            (elementDimensions.x +
              elementDimensions.width / 2 -
              tooltipMaxWidth / 2 -
              offset * 2.25) *
            -1
          }px`,
        };
      }
      case TooltipPosition.TileTop: {
        return {
          top: "unset",
          bottom: `${
            window.innerHeight - elementDimensions.y - offset * 0.25
          }px`,
          left: `${
            elementDimensions.x +
            elementDimensions.width / 2 -
            tooltipMaxWidth / 2 -
            offset * 0.5
          }px`,
        };
      }
      case TooltipPosition.right: {
        return {
          top: "unset",
          bottom: `${window.innerHeight - elementDimensions.y - offset}px`,
          left: `${
            (elementDimensions.x +
              elementDimensions.width / 2 -
              tooltipMaxWidth / 2 -
              offset * 2.25) *
            -1
          }px`,
        };
      }
      case TooltipPosition.TileLeft: {
        return {
          top: "unset",
          bottom: `${window.innerHeight - elementDimensions.y - offset}px`,
          left: `${
            elementDimensions.x +
            elementDimensions.width / 2 -
            tooltipMaxWidth / 2 -
            offset * 2
          }px`,
        };
      }
      case TooltipPosition.tableTopLeft:
        return {
          top: `unset`,
          bottom: `${window.innerHeight - elementDimensions.y}px`,
          left: `${elementDimensions.x - elementDimensions.width}px`,
        };
      default:
        return {
          top: null,
          bottom: null,
          right: null,
          left: null,
        };
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (customPosition)
        dispatch(
          showTooltip({
            children: null,
            position: null,
            customPosition: null,
            elementDimensions: null,
            className: null,
            width: null,
            executeMouseLeaveEvent: null,
            isOverTooltip: null,
          })
        );
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  const divRef = useRef<HTMLDivElement>(null);

  /* istanbul ignore next */
  useEffect(() => {
    if (
      customPosition &&
      divRef.current &&
      Object.keys(newPositionStyle).length > 0
    ) {
      const element = divRef.current?.getBoundingClientRect();
      let isMaxHeightNeeded = element!.height > overflowMaxHeight;
      setVerticalScroll(isMaxHeightNeeded);
      let tmpPosition: TooltipPosition = position;
      //bottom right overlap - TopLeft reposition
      if (
        element!.right > window.innerWidth &&
        element!.bottom > window.innerHeight &&
        element!.height < overflowMaxHeight
      ) {
        tmpPosition = TooltipPosition.TileTopLeft;
      }
      //bottom left overlap - TopRight reposition
      else if (
        element!.left < 0 &&
        element!.bottom > window.innerHeight &&
        element!.height < overflowMaxHeight
      ) {
        tmpPosition = TooltipPosition.TileTopRight;
      }
      //top right overlap - bottomLeft reposition
      else if (element!.right > window.innerWidth && element!.top < 0) {
        tmpPosition = TooltipPosition.bottomLeft;
      }
      //top left overlap - bottomRight reposition
      else if (element!.left < 0 && element!.top < 0) {
        tmpPosition = TooltipPosition.TileBottomRight;
      }
      //right overlap - left reposition
      else if (element!.right > window.innerWidth) {
        tmpPosition = TooltipPosition.TileLeft;
      }
      //bottom overlap - top reposition
      else if (
        element!.bottom > window.innerHeight &&
        element!.height < overflowMaxHeight
      ) {
        tmpPosition = TooltipPosition.TileTop;
      }
      //left overlap - right reposition
      else if (element!.left < 0) {
        tmpPosition = TooltipPosition.right;
      }
      //top overlap - bottom reposition
      else if (element!.top < 0) {
        tmpPosition = TooltipPosition.bottom;
      }
      if (tmpPosition !== position) {
        buildCustomPosition(tmpPosition, isMaxHeightNeeded);
      }
    }
    setOverflow(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef.current]);

  const handleMouseLeave = (e: any) => {
    if (executeMouseLeaveEvent)
      dispatch(
        showTooltip({
          children: null,
          position: null,
          customPosition: null,
          elementDimensions: null,
          className: null,
          width: null,
          executeMouseLeaveEvent: null,
          isOverTooltip: null,
        })
      );
  };
  return (
    <>
      {!customPosition && (
        <div id={id} className={`${BLOCK} ${BLOCK}--${position} ${className}`}>
          {hideArrow ? null : (
            <div
              className={`${BLOCK}__arrow ${BLOCK}--${position}__arrow`}
            ></div>
          )}
          <div className={`${BLOCK}__label`}>{children}</div>
        </div>
      )}
      {customPosition && (
        <div
          id={id}
          className={classNames(`${BLOCK} ${className}`, {
            [`tooltip__vertical-scroll`]: verticalScroll,
          })}
          style={{
            visibility: overflowFlag ? "visible" : "hidden",
            ...newPositionStyle,
          }}
          onMouseEnter={(e) => {
            if (executeMouseLeaveEvent)
              dispatch(showTooltip({ ...tooltip, isOverTooltip: true }));
          }}
          ref={divRef}
          onMouseLeave={handleMouseLeave}
        >
          {hideArrow ? null : (
            <div
              className={`${BLOCK}__arrow ${BLOCK}--${position}__arrow`}
            ></div>
          )}
          <div className={`${BLOCK}__label`}>{children}</div>
        </div>
      )}
    </>
  );
};

export default Tooltip;
