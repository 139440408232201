import { useState, useEffect } from "react";
import Note from "../shared/note/note-items";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import { loadNote } from "services/note/note.service";
import { NOTE_TYPES } from "utils/constants";

type props = {
  setShowItems: Function;
  referenceId: number;
  noteId?: any;
};

const ShortlistItemNotes = ({ setShowItems, referenceId, noteId }: props) => {
  // const BLOCK = "Note";
  // const shortListState = useSelector((state: RootStore) => state.shortlist);
  const noteState = useSelector((state: RootStore) => state.note);
  const dispatch = useDispatch();
  const headerAddNote = "shortlist.note.header";
  const headerEditNote = "shortlist.note.edit.header";
  const inputNote = "shortlist.note.items.input";
  const [showNoteId, setNodeId] = useState(noteId);

  useEffect(() => {
    setNodeId(noteId ? noteId : 0);
    const Payload = {
      commentId: noteId ? noteId : 0,
    };
    dispatch(loadNote(Payload));
  }, [noteId, dispatch]);
  return (
    <>
      {!noteState.loading && !noteState.NoteFetch.commentNoteId ? (
        <div data-test="note-add-component">
          <Note
            setShowModal={() => {}}
            setCommentBlue={() => {}}
            headerNote={headerAddNote}
            inputNote={inputNote}
            referenceId={referenceId}
            noteExist={false}
            commentNoteReferenceType={NOTE_TYPES.SHORTLIST_ITEM}
            setShowItems={setShowItems}
          />
        </div>
      ) : (
        !noteState.loading &&
        noteState.NoteFetch.commentNoteId &&
        noteState.NoteFetch.commentNotes && (
          <div data-test="note-component">
            <Note
              setShowModal={() => {}}
              setCommentBlue={() => {}}
              headerNote={headerEditNote}
              inputNote={inputNote}
              referenceId={referenceId}
              noteId={showNoteId}
              noteExist={true}
              commentNoteReferenceType={NOTE_TYPES.SHORTLIST_ITEM}
              setShowItems={setShowItems}
            />
          </div>
        )
      )}
    </>
  );
};

export default ShortlistItemNotes;
