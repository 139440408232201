import React, { useMemo } from "react";
import { Group } from "@visx/group";
import { Circle } from "@visx/shape";
import { AxisLeft } from "@visx/axis";
import { scaleLinear, scalePoint } from "@visx/scale";
import { withTooltip, defaultStyles } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { ScaleSVG } from "@visx/responsive";
import { Grid } from "@visx/grid";
import { isInteger } from "lodash";
import { Legend } from "services/dashboard/dashboard.model";
import { lightColor } from "utils/constants";
import { getTooltipData } from "utils/functions";
const BLOCK = "DashboardTile";

export type Props = {
  data: Legend[];
  width: number;
  height: number;
  axis?: { primary: string[]; secondary: string[] };
  margin?: { top: number; right: number; bottom: number; left: number };
};

const ScatterDotChart = ({
  data,
  axis,
  width,
  height,
  margin = { top: 15, right: 50, bottom: 36, left: 20 },
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
}: Props & WithTooltipProvidedProps<Legend>) => {
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const dataset = data.filter((d) => d.legendData > 0);

  const tooltipStyles = {
    ...defaultStyles,
    backgroundColor: "rgba(0,0,0,0.9)",
    color: "white",
    fontSize: 15,
  };
  const yMaxLegend = axis?.primary.length
    ? axis?.primary.length
    : Math.max(...dataset.map((d) => d.legendData ?? 0));

  const xScale = useMemo(
    () =>
      scalePoint<string>({
        domain: dataset.map((d) => d.legendValue),
        range: [0, xMax],
      }),
    [xMax, dataset]
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [yMaxLegend, 0],
        range: [0, yMax],
      }),
    [yMax, yMaxLegend]
  );

  if (width < 10) return null;

  let tooltipTimeout: number;

  return (
    <div className="limit-size">
      <ScaleSVG width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <AxisLeft
            hideTicks
            hideAxisLine
            left={margin.left}
            top={margin.top}
            scale={yScale}
            stroke={lightColor}
            tickValues={yScale.ticks().filter(isInteger)}
            tickFormat={(tick: any) =>
              axis?.primary.length && tick <= axis.primary.length
                ? axis.primary[tick - 1]
                : tick.toFixed()
            }
            tickStroke={lightColor}
            axisClassName={`${BLOCK}__axis`}
            tickComponent={(tickProps) => {
              return (
                <text
                  fontFamily="Open Sans"
                  fontSize={12}
                  textAnchor="middle"
                  dx={tickProps.dx}
                  dy={tickProps.dy}
                  x={tickProps.x}
                  y={tickProps.y}
                >
                  <tspan x={tickProps.x} dy={tickProps.dy}>
                    {tickProps.formattedValue}
                  </tspan>
                </text>
              );
            }}
          />
          <Grid
            xScale={xScale}
            yScale={yScale}
            width={xMax}
            height={yMax}
            left={margin.left}
            top={margin.top}
            stroke={lightColor}
          />
          {dataset.map((data, i) => {
            const xLegendOffset = i === dataset.length - 1 ? -100 : 0;

            return (
              <Circle
                key={`point-${data.legendValue}-${i}`}
                className="dot"
                cx={Number(xScale(data.legendValue)) + margin.left}
                cy={yScale(data.legendData) + margin.top}
                r={5}
                fill={data.legendColor}
                onMouseLeave={() =>
                  (tooltipTimeout = window.setTimeout(() => {
                    hideTooltip();
                  }, 100))
                }
                onMouseMove={() => {
                  if (tooltipTimeout) clearTimeout(tooltipTimeout);
                  showTooltip({
                    tooltipData: data,
                    tooltipTop: yScale(data.legendData) + margin.top - 20,
                    tooltipLeft:
                      Number(xScale(data.legendValue)) +
                      margin.left +
                      xLegendOffset,
                  });
                }}
              />
            );
          })}
        </Group>
      </ScaleSVG>
      {tooltipOpen && tooltipData && (
        <div
          style={{
            ...tooltipStyles,
            top: tooltipTop,
            left: tooltipLeft,
            position: "absolute",
          }}
        >
          <div>{`${tooltipData.legendValue} (${getTooltipData(
            axis,
            tooltipData
          )})`}</div>
        </div>
      )}
    </div>
  );
};

export default withTooltip<Props, Legend>(ScatterDotChart);
