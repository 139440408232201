import { Dispatch } from "redux";
import { createAction } from "../../utils/redux.utils";
import {
  InsightsModel,
  InsightsState,
  FilterPayloadToSend,
  InsightCategory,
  InsightsDashboard,
  InsightsSharePayload,
  GlobalCompanyGroup,
} from "./insights.model";
import * as api from "./insights.api";
import * as commonApi from "../commons.api";
import {
  DEFAULT_SAVED_INSIGHTS_LIST_PAYLOAD,
  FILTER_OPTIONS,
  USER_PREFERENCES,
} from "utils/constants";
import { CompanyFilter } from "services/search/filters.model";
import { Visualization } from "services/dashboard/dashboard.model";
import { setSharedWith } from "services/saved-shortlist/saved-shortlist.service";
import { setShareLoader } from "services/commons.service";

export const ACTIONS = {
  INSIGHTS_LOADING: "ESG/UI/INSIGHTS_LOADING",
  INSIGHTS_SHOW_CREATE_NEW_TAB: "ESG/UI/ INSIGHTS_SHOW_CREATE_NEW_TAB",
  INSIGHTS_SHOW_TALKING_POINTS: "ESG/UI/INSIGHTS_sHOW_TALKING_POINTS",
  INSIGHTS_SHOW_EXPORT_PANEL: "ESG/UI/INSIGHTS_SHOW_EXPORT_POINTS",
  INSIGHTS_SHOW_EDIT_TAB: "ESG/UI/ INSIGHTS_SHOW_EDIT_TAB",
  SAVED_INSIGHTS_LIST: "ESG/UI/SAVED_INSIGHTS",
  SAVED_INSIGHTS_COUNT: "ESG/UI/SAVED_INSIGHTS_COUNT",
  INSIGHTS_SHARE_TO_USERS: "ESG/UI/INSIGHTS_SHARE_TO_USERS",
  DELETE_SAVED_INSIGHTS_POP_UP: "ESG/UI/DELETE_SAVED_INSIGHTS_POP_UP",
  REFRESH_SAVED_INSIGHTS_LIST: "ESG/UI/REFRESH_SAVED_INSIGHTS_LIST",
  RESET_SAVED_INSIGHTS: "ESG/UI/RESET_SAVED_INSIGHTS",
  SHOW_CURRENT_INSIGHT_DASHBOARD: "ESG/UI/SHOW_CURRENT_INSIGHT_DASHBOARD",
  CURRENT_SAVED_INSIGHT_DASHBOARD: "ESG/UI/CURRENT_SAVED_INSIGHT_DASHBOARD",
  CURRENT_SELECTED_DETAILED_VIEW: "ESG/UI/CURRENT_SELECTED_DETAILED_VIEW",
  GET_FEATURES_SETTINGS: "ESG/UI/GET_FEATURE_SETTINGS",
  INSIGHTS_LOAD_TALKING_POINTS: "ESG/UI/INSIGHTS_LOAD_TALKING_POINTS",
  INSIGHTS_LOAD_COMPANY_FILTER: "ESG/UI/INSIGHTS_LOAD_COMPANY_FILTER",
  INSIGHTS_LOAD_PEER_COMPANIES: "ESG/UI/INSIGHTS_LOAD_PEER_COMPANIES",
  INSIGHTS_LOAD_SUGGESTED_PEER_COMPANIES:
    "ESG/UI/INSIGHTS_LOAD_SUGGESTED_PEER_COMPANIES",
  INSIGHTS_SET_COMPANY_FILTER: "ESG/UI/INSIGHTS_SET_COMPANY_FILTER",
  INSIGHTS_SET_PEER_COMPANY_FILTER: "ESG/UI/INSIGHTS_SET_PEER_COMPANY_FILTER",
  INSIGHTS_SET_TALKING_POINTS: "ESG/UI/INSIGHTS_SET_TALKING_POINTS",
  INSIGHTS_LOADING_FILTER: "ESG/UI/INSIGHTS_LOADING_FILTER",
  INSIGHTS_SET_SELECTED_ITEMS: "ESG/UI/INSIGHTS_SET_SELECTED_ITEMS",
  SAVED_INSIGHT_DASHBOARD_ID: "ESG/UI/SAVED_INSIGHT_DASHBOARD_ID",
  INSIGHTS_SIDE_DASHBOARD_CATEGORY_DATA:
    "ESG/UI/INSIGHTS_SIDE_DASHBOARD_CATEGORY_DATA",
  INSIGHTS_SIDE_DASHBOARD_CATEGORY_SELECTED_DATA:
    "ESG/UI/INSIGHTS_SIDE_DASHBOARD_CATEGORY_SELECTED_DATA",
  INSIGHTS_RESET_DATA: "ESG/UI/INSIGHTS_RESET_DATA",
  INSIGHTS_DUPLICATE_NAME_ERROR: "ESG/UI/INSIGHTS_DUPLICATE_NAME_ERROR",
  INSIGHTS_DUPLICATE_PEER_GROUP_NAME:
    "ESG/UI/INSIGHTS_DUPLICATE_PEER_GROUP_NAME",
  INSIGHTS_SAVED_SUCCESS: "ESG/UI/INSIGHTS_SAVED_SUCCESS",
  INSIGHTS_PEER_GROUP_SUCCESS: "ESG/UI/INSIGHTS_PEER_GROUP_SUCCESS",
  INSIGHTS_SET_ADDITIONAL_INFO: "ESG/UI/ INSIGHTS_SET_ADDITIONAL_INFO",
  INSIGHTS_CREATE_INSIGHT_TRACK: "ESG/UI/INSIGHTS_CREATE_INSIGHT_TRACK",
  INSIGHTS_EXPORT_INSIGHT_TRACK: "ESG/UI/INSIGHTS_EXPORT_INSIGHT_TRACK",
  INSIGHTS_EDIT_INSIGHT_TRACK: "ESG/UI/INSIGHTS_EDIT_INSIGHT_TRACK",
  INSIGHTS_SHOW_EDITOR: "ESG/UI/INSIGHTS_SHOW_EDITOR",
  INSIGHTS_SHOW_INFORMATION: "ESG/UI/INSIGHTS_SHOW_INFORMATION",
  INSIGHTS_TILE_TO_EXPORT: "ESG/UI/INSIGHTS_TILE_TO_EXPORT",
  INSIGHTS_TILES_DETAIL: "ESG/UI/INSIGHTS_TILES_DETAIL",
  INSIGHTS_DELETE_TILE_TRACK: "ESG/UI/INSIGHTS_DELETE_TILE_TRACK",
  INSIGHTS_ADD_INFORMATION_TILE_TRACK:
    "ESG/UI/INSIGHTS_ADD_INFORMATION_TILE_TRACK",
  INSIGHTS_ESG_LINK_TRACK: "ESG/UI/INSIGHTS_ESG_LINK_TRACK",
  INSIGHTS_SEC_LINK_TRACK: "ESG/UI/INSIGHTS_SEC_LINK_TRACK",
  INSIGHTS_COMPANY_TAB_TRACK: "ESG/UI/INSIGHTS_COMPANY_TAB_TRACK",
  INSIGHTS_PEER_BENCHMARK_TAB_TRACK: "ESG/UI/INSIGHTS_PEER_BENCHMARK_TAB_TRACK",
  INSIGHTS_INDUSTRY_TAB_TRACK: "ESG/UI/INSIGHTS_INDUSTRY_TAB_TRACK",
  INSIGHTS_PDF_EXPORT_ALL_TRACK: "ESG/UI/INSIGHTS_PDF_EXPORT_ALL_TRACK",
  INSIGHTS_XLS_EXPORT_ALL_TRACK: "ESG/UI/INSIGHTS_XLS_EXPORT_ALL_TRACK",
  INSIGHTS_PPT_EXPORT_ALL_TRACK: "ESG/UI/INSIGHTS_PPT_EXPORT_ALL_TRACK",
  INSIGHTS_FILTER_TRACK: "ESG/UI/INSIGHTS_FILTER_TRACK",
  INSIGHTS_PDF_EXPORT_TILE_TRACK: "ESG/UI/INSIGHTS_PDF_EXPORT_TILE_TRACK",
  INSIGHTS_XLS_EXPORT_TILE_TRACK: "ESG/UI/INSIGHTS_XLS_EXPORT_TILE_TRACK",
  INSIGHTS_PPT_EXPORT_TILE_TRACK: "ESG/UI/INSIGHTS_PPT_EXPORT_TILE_TRACK",
  INSIGHTS_TABLE_VIEW_TRACK: "ESG/UI/INSIGHTS_TABLE_VIEW_TRACK",
  INSIGHTS_TABLE_VIEW_TILE_TRACK: "ESG/UI/INSIGHTS_TABLE_VIEW_TILE_TRACK",
  INSIGHTS_CHART_VIEW_TRACK: "ESG/UI/INSIGHTS_CHART_VIEW_TRACK",
  INSIGHTS_CHART_VIEW_TILE_TRACK: "ESG/UI/INSIGHTS_CHART_VIEW_TILE_TRACK",
  INSIGHTS_DETAILED_VIEW_TILE_TRACK: "ESG/UI/INSIGHTS_DETAILED_VIEW_TILE_TRACK",
  INSIGHTS_SET_PEER_GROUP_LIST: "ESG/UI/INSIGHTS_SET_PEER_GROUP_LIST",
  INSIGHTS_DETAILED_VIEW_TRACK: "ESG/UI/INSIGHTS_DETAILED_VIEW_TRACK",
  INSIGHTS_CREATE_PEER_GROUP_TRACK: "ESG/UI/INSIGHTS_CREATE_PEER_GROUP_TRACK",
  INSIGHTS_EDIT_PEER_GROUP_TRACK: "ESG/UI/INSIGHTS_EDIT_PEER_GROUP_TRACK",
  INSIGHTS_DELETE_PEER_GROUP_TRACK: "ESG/UI/INSIGHTS_DELETE_PEER_GROUP_TRACK",
  INSIGHTS_TALKING_POINTS_TRACK: "ESG/UI/INSIGHTS_TALKING_POINTS_TRACK",
  DELETE_SAVED_PEER_GROUP_POP_UP: "ESG/UI/DELETE_SAVED_PEER_GROUP_POP_UP",
  REFRESH_SAVED_PEER_GROUPS_LIST: "ESG/UI/REFRESH_SAVED_PEER_GROUPS_LIST",
  INSIGHTS_SET_SELECTED_PEER_GROUP_ITEMS:
    "ESG/UI/INSIGHTS_SET_SELECTED_PEER_GROUP_ITEMS",
  INSIGHTS_COMPANY_FILTER_TRACK: "ESG/UI/INSIGHTS_COMPANY_FILTER_TRACK",
  INSIGHTS_UPDATE_FILTERS: "ESG/UI/INSIGHTS_UPDATE_FILTERS",
  INSIGHTS_RESET_FILTERS: "ESG/UI/INSIGHTS_RESET_FILTERS",
  INSIGHTS_SHARE_TRACK: "ESG/UI/INSIGHTS_SHARE_TRACK",
  INSIGHTS_SHARE_WITH_TRACK: "ESG/UI/INSIGHTS_SHARE_WITH_TRACK",
  INSIGHTS_SET_GLOBAL_COMPANY_MAPPING_FILTER:
    "ESG/UI/INSIGHTS_SET_GLOBAL_COMPANY_MAPPING_FILTER",
  INSIGHTS_SET_SAVED_USER_PREFERENCE:
    "ESG/UI/INSIGHTS_SET_SAVED_USER_PREFERENCE",
  INSIGHTS_USER_PREFERENCE_ERROR: "ESG/UI/INSIGHTS_USER_PREFERENCE_ERROR",
  INSIGHTS_CURRENT_TAB: "ESG/UI/INSIGHTS_CURRENT_TAB",
};

export const setEditor = createAction(ACTIONS.INSIGHTS_SHOW_EDITOR);
export const setInformation = createAction(ACTIONS.INSIGHTS_SHOW_INFORMATION);
export const setLoading = createAction(ACTIONS.INSIGHTS_LOADING);
export const showCreateNew = createAction(ACTIONS.INSIGHTS_SHOW_CREATE_NEW_TAB);
export const showTalkingPoints = createAction(
  ACTIONS.INSIGHTS_SHOW_TALKING_POINTS
);
export const ShareInsightsToUsers = createAction(
  ACTIONS.INSIGHTS_SHARE_TO_USERS
);
export const showEdit = createAction(ACTIONS.INSIGHTS_SHOW_EDIT_TAB);
export const showExportPanel = createAction(ACTIONS.INSIGHTS_SHOW_EXPORT_PANEL);
export const savedInsights = createAction(ACTIONS.SAVED_INSIGHTS_LIST);
export const deleteSavedInsightPopUP = createAction(
  ACTIONS.DELETE_SAVED_INSIGHTS_POP_UP
);
export const refreshSavedInsightsList = createAction(
  ACTIONS.REFRESH_SAVED_INSIGHTS_LIST
);
export const resetSavedInsights = createAction(ACTIONS.RESET_SAVED_INSIGHTS);
export const currentSavedInsightDashboard = createAction(
  ACTIONS.CURRENT_SAVED_INSIGHT_DASHBOARD
);

export const currentSelectedDetailedView = createAction(
  ACTIONS.CURRENT_SELECTED_DETAILED_VIEW
);

export const fetchFeatureSettings = createAction(ACTIONS.GET_FEATURES_SETTINGS);

export const setSharedInsightsCount = createAction(
  ACTIONS.SAVED_INSIGHTS_COUNT
);

export const showCurrentInsightDashboard = createAction(
  ACTIONS.SHOW_CURRENT_INSIGHT_DASHBOARD
);
export const searchLoadingFilter = createAction(
  ACTIONS.INSIGHTS_LOADING_FILTER
);

export const loadTalkingPoints = createAction(
  ACTIONS.INSIGHTS_LOAD_TALKING_POINTS
);

export const loadCompanyFilter = createAction(
  ACTIONS.INSIGHTS_LOAD_COMPANY_FILTER
);

export const loadPeerCompanies = createAction(
  ACTIONS.INSIGHTS_LOAD_PEER_COMPANIES
);

export const loadSuggestedPeerCompanies = createAction(
  ACTIONS.INSIGHTS_LOAD_SUGGESTED_PEER_COMPANIES
);

export const setCompanyFilter = createAction(
  ACTIONS.INSIGHTS_SET_COMPANY_FILTER
);

export const searchSetPeerCompanyFilter = createAction(
  ACTIONS.INSIGHTS_SET_PEER_COMPANY_FILTER
);

export const updateSelectedItems = createAction(
  ACTIONS.INSIGHTS_SET_SELECTED_ITEMS
);

export const savedInsightDashboardId = createAction(
  ACTIONS.SAVED_INSIGHT_DASHBOARD_ID
);
export const setCategoryItems = createAction(
  ACTIONS.INSIGHTS_SIDE_DASHBOARD_CATEGORY_DATA
);

export const setTalkingPoints = createAction(
  ACTIONS.INSIGHTS_SET_TALKING_POINTS
);
export const setAdditionalInformation = createAction(
  ACTIONS.INSIGHTS_SET_ADDITIONAL_INFO
);

export const selectCategoryIds = createAction(
  ACTIONS.INSIGHTS_SIDE_DASHBOARD_CATEGORY_SELECTED_DATA
);
export const resetInsightTab = createAction(ACTIONS.INSIGHTS_RESET_DATA);

export const setduplicateInsightsError = createAction(
  ACTIONS.INSIGHTS_DUPLICATE_NAME_ERROR
);

export const setduplicatePeerGroupError = createAction(
  ACTIONS.INSIGHTS_DUPLICATE_PEER_GROUP_NAME
);

export const setInsightsSavedSuccess = createAction(
  ACTIONS.INSIGHTS_SAVED_SUCCESS
);

export const setPeerGroupSavedSuccess = createAction(
  ACTIONS.INSIGHTS_PEER_GROUP_SUCCESS
);

export const insightsCreateInsightTrack = createAction(
  ACTIONS.INSIGHTS_CREATE_INSIGHT_TRACK
);
export const insightsExportInsightTrack = createAction(
  ACTIONS.INSIGHTS_EXPORT_INSIGHT_TRACK
);
export const insightsEditInsightTrack = createAction(
  ACTIONS.INSIGHTS_EDIT_INSIGHT_TRACK
);

export const setInsightTileToExport = createAction(
  ACTIONS.INSIGHTS_TILE_TO_EXPORT
);

export const updateTileDetails = createAction(ACTIONS.INSIGHTS_TILES_DETAIL);

export const deleteInsightTileTrack = createAction(
  ACTIONS.INSIGHTS_DELETE_TILE_TRACK
);

export const addInformationTileTrack = createAction(
  ACTIONS.INSIGHTS_ADD_INFORMATION_TILE_TRACK
);

export const esgLinkTrack = createAction(ACTIONS.INSIGHTS_ESG_LINK_TRACK);

export const secLinkTrack = createAction(ACTIONS.INSIGHTS_SEC_LINK_TRACK);

export const companyTabTrack = createAction(ACTIONS.INSIGHTS_COMPANY_TAB_TRACK);

export const peerBenchmarkTabTrack = createAction(
  ACTIONS.INSIGHTS_PEER_BENCHMARK_TAB_TRACK
);

export const industryTabTrack = createAction(
  ACTIONS.INSIGHTS_INDUSTRY_TAB_TRACK
);

export const pdfExportAllInsightTrack = createAction(
  ACTIONS.INSIGHTS_PDF_EXPORT_ALL_TRACK
);

export const xlsExportAllInsightTrack = createAction(
  ACTIONS.INSIGHTS_XLS_EXPORT_ALL_TRACK
);

export const pptExportAllInsightTrack = createAction(
  ACTIONS.INSIGHTS_PPT_EXPORT_ALL_TRACK
);

export const filterInsightTrack = createAction(ACTIONS.INSIGHTS_FILTER_TRACK);

export const companyfilterInsightTrack = createAction(
  ACTIONS.INSIGHTS_COMPANY_FILTER_TRACK
);

export const pdfExportInsightTileTrack = createAction(
  ACTIONS.INSIGHTS_PDF_EXPORT_TILE_TRACK
);

export const xlsExportInsightTileTrack = createAction(
  ACTIONS.INSIGHTS_XLS_EXPORT_TILE_TRACK
);

export const pptExportInsightTileTrack = createAction(
  ACTIONS.INSIGHTS_PPT_EXPORT_TILE_TRACK
);

export const tableViewInsightTrack = createAction(
  ACTIONS.INSIGHTS_TABLE_VIEW_TRACK
);

export const tableViewTileInsightTrack = createAction(
  ACTIONS.INSIGHTS_TABLE_VIEW_TILE_TRACK
);

export const detailedViewTileInsightTrack = createAction(
  ACTIONS.INSIGHTS_DETAILED_VIEW_TILE_TRACK
);

export const savedPeerGroupList = createAction(
  ACTIONS.INSIGHTS_SET_PEER_GROUP_LIST
);

export const chartViewInsightTrack = createAction(
  ACTIONS.INSIGHTS_CHART_VIEW_TRACK
);

export const chartViewTileInsightTrack = createAction(
  ACTIONS.INSIGHTS_CHART_VIEW_TILE_TRACK
);

export const detailedViewInsightTrack = createAction(
  ACTIONS.INSIGHTS_DETAILED_VIEW_TRACK
);

export const insightsCreatePeerGroupTrack = createAction(
  ACTIONS.INSIGHTS_CREATE_PEER_GROUP_TRACK
);
export const insightsEditPeerGroupTrack = createAction(
  ACTIONS.INSIGHTS_EDIT_PEER_GROUP_TRACK
);
export const insightsDeletePeerGroupTrack = createAction(
  ACTIONS.INSIGHTS_DELETE_PEER_GROUP_TRACK
);

export const insightsTalkingPointsTrack = createAction(
  ACTIONS.INSIGHTS_TALKING_POINTS_TRACK
);

export const insightsShareTrack = createAction(ACTIONS.INSIGHTS_SHARE_TRACK);

export const insightsShareWithTrack = createAction(
  ACTIONS.INSIGHTS_SHARE_WITH_TRACK
);

export const deletePeerGroupPopUP = createAction(
  ACTIONS.DELETE_SAVED_PEER_GROUP_POP_UP
);
export const refreshSavedPeerGroupsList = createAction(
  ACTIONS.REFRESH_SAVED_PEER_GROUPS_LIST
);

export const updateSelectedPeerGroupItems = createAction(
  ACTIONS.INSIGHTS_SET_SELECTED_PEER_GROUP_ITEMS
);

export const setFiltersSelected = createAction(ACTIONS.INSIGHTS_UPDATE_FILTERS);
export const resetBenchmarkFilters = createAction(
  ACTIONS.INSIGHTS_RESET_FILTERS
);

export const setGlobalCompanyMappingsFilter = createAction(
  ACTIONS.INSIGHTS_SET_GLOBAL_COMPANY_MAPPING_FILTER
);

export const setSavedUserPreference = createAction(
  ACTIONS.INSIGHTS_SET_SAVED_USER_PREFERENCE
);

export const userPreferenceError = createAction(
  ACTIONS.INSIGHTS_USER_PREFERENCE_ERROR
);

export const setCurrentTab = createAction(ACTIONS.INSIGHTS_CURRENT_TAB);

export const showCreateNewTab =
  (payload: boolean) => async (dispatch: Dispatch<any>) =>
    dispatch(showCreateNew(payload));

export const showTalkingPointsTab =
  (payload: boolean) => async (dispatch: Dispatch<any>) =>
    dispatch(showTalkingPoints(payload));

export const showEditTab =
  (payload: boolean) => async (dispatch: Dispatch<any>) =>
    dispatch(showEdit(payload));

export const shareInsightSuccess =
  (payload: boolean) => async (dispatch: Dispatch<any>) => {
    dispatch(ShareInsightsToUsers(payload));
  };

export const setShowExportPanel =
  (payload: boolean) => (dispatch: Dispatch<any>) => {
    dispatch(showExportPanel(payload));
  };

export const loadSavedInsightsList =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      const res = await api.fetchSavedInsightsList(payload);
      dispatch(savedInsights(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const getSavedInsight =
  (
    insightId: number,
    tabId: number,
    update: boolean = false,
    config: any = {}
  ) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      dispatch(savedInsightDashboardId(insightId));
      let res;
      if (config) {
        res = await api.getSavedInsight(insightId, tabId, config);
      } else {
        res = await api.getSavedInsight(insightId, tabId);
      }

      if (update) {
        dispatch(currentSavedInsightDashboard(null));
      }
      dispatch(currentSavedInsightDashboard(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const getFeatureSettings = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setLoading(true));
    const res = await api.getFeatureSettings();
    if (res.data) {
      dispatch(fetchFeatureSettings(res.data));
    }
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const deleteSavedInsight =
  (payload: any, insightCategoryName: any) =>
  async (dispatch: Dispatch<any>) => {
    try {
      //TO ENABLE WHEN ENDPOINT IS DONE
      dispatch(setLoading(true));
      await api.deleteInsight(payload);
      dispatch(deleteSavedInsightPopUP(false));
      dispatch(resetSavedInsights());
      dispatch(setLoading(false));
      dispatch(refreshSavedInsightsList(true));
      dispatch(loadSavedInsightsList(DEFAULT_SAVED_INSIGHTS_LIST_PAYLOAD));
      dispatch(deleteInsightTileTrack({ dashboardType: insightCategoryName }));
    } catch (e) {
      console.log("error ", e);
    }
  };

export const createInsights =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      const insightId = await api.createInsight(payload);
      dispatch(savedInsightDashboardId(insightId));
      dispatch(setInformation(false));
      dispatch(setAdditionalInformation([]));
      dispatch(setInsightsSavedSuccess(true));
      dispatch(setduplicateInsightsError(false));
    } catch (e) {
      dispatch(setduplicateInsightsError(true));
    }
  };

export const updateFilterData =
  (payload: any, updateValue: any, insightId: number) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      let payloadToSend: FilterPayloadToSend = {
        benchmarkTileTypeId: payload.benchmarkTileType,
        benchmarkMetadataSection: {
          filters: {
            label: payload.benchmarkMetadata.filters.label,
            values: updateValue.updateValue,
            columnName: payload.benchmarkMetadata.filters.columnName,
          },
        },
      };

      await api.updateFilterMetadata(payloadToSend!, insightId);
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const updateTileMetaDataForExtraChartMetrics =
  (
    benchmarkMetadataPath: string,
    benchmarkMetadataValue: any,
    insightId: number,
    BenchmarkTileType: number
  ) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      let payload = {
        benchmarkMetadataPath: benchmarkMetadataPath,
        benchmarkMetadataValue,
      };
      await api.updateTileMetadata(payload, insightId, BenchmarkTileType);
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const editInsights =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      await api.editInsight(payload);
      dispatch(setInsightsSavedSuccess(true));
      dispatch(setduplicateInsightsError(false));
      dispatch(refreshSavedInsightsList(true));
    } catch (e) {
      dispatch(setduplicateInsightsError(true));
    }
  };

export const savePeerGroup =
  (payload: any, page: string) => async (dispatch: Dispatch<any>) => {
    try {
      await api.savePeerGroupName(payload);
      dispatch(setPeerGroupSavedSuccess(true));
      dispatch(setduplicatePeerGroupError(false));
      dispatch(insightsCreatePeerGroupTrack({ page: page }));
    } catch (e) {
      dispatch(setduplicatePeerGroupError(true));
    }
  };

export const editPeerGroupServ =
  (payload: any, page: string) => async (dispatch: Dispatch<any>) => {
    try {
      await api.editSavedPeerGroup(payload);
      dispatch(setPeerGroupSavedSuccess(true));
      dispatch(setduplicatePeerGroupError(false));
      dispatch(insightsEditPeerGroupTrack({ page: page }));
    } catch (e) {
      dispatch(setduplicatePeerGroupError(true));
    }
  };

export const peerGroupSelectionTracking =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      await api.savepeerGroupDataTracking(payload);
    } catch (e) {}
  };

export const editInsightDashboardView =
  (payload: any, insightId: number) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      await api.editInsightDashboardView(payload, insightId);
      dispatch(setLoading(false));
      dispatch(refreshSavedInsightsList(true));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const shareInsightsToUsers =
  (payload: InsightsSharePayload) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(insightsShareTrack(true));
      dispatch(setShareLoader(true));
      // API call to send the share search to the backend
      await api.shareInsights(payload);
      dispatch(shareInsightSuccess(true));

      dispatch(
        insightsShareWithTrack(
          `Share insight - ${
            payload.sharedWith ? payload.sharedWith.length : 0
          }`
        )
      );
      dispatch(setSharedWith([]));
      dispatch(setShareLoader(false));
    } catch (e) {
      dispatch(setSharedWith([]));
      dispatch(setShareLoader(false));
    }
  };

export const getCompanyFilter =
  (searchText: string) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = searchText ? await api.fetchDocumentFilter(searchText) : [];
      dispatch(loadCompanyFilter(res.data));
    } catch (e) {}
  };

export const getGlobalCompanyFilter =
  (searchText: string, isSECFiler: boolean = true) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = searchText
        ? await api.fetchGlobalCompaniesFilter(searchText, isSECFiler)
        : [];
      dispatch(loadCompanyFilter(res.data.globalCompanies));
    } catch (e) {
      dispatch(loadCompanyFilter([]));
    }
  };

export const setBaselineCompanyFilter =
  (companyFilter: CompanyFilter[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setCompanyFilter(companyFilter));
    } catch (e) {}
  };

export const setGlobalMappingFilter =
  (globalMappingFilter: Partial<GlobalCompanyGroup>[]) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setGlobalCompanyMappingsFilter(globalMappingFilter));
    } catch (e) {}
  };

export const getSavedInsightCount = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setLoading(true));
    const res = await api.getCount();
    dispatch(setSharedInsightsCount(res.data));
    dispatch(loadSavedInsightsList(DEFAULT_SAVED_INSIGHTS_LIST_PAYLOAD));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(setLoading(false));
  }
};

export const getPeerCompanyFilter =
  (searchText: string) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = searchText ? await api.fetchDocumentFilter(searchText) : [];
      dispatch(loadPeerCompanies(res.data));
    } catch (e) {}
  };

export const getGlobalPeerCompanyFilter =
  (searchText: string, isSECFiler: boolean = true) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = searchText
        ? await api.fetchGlobalCompaniesFilter(searchText, isSECFiler)
        : [];
      dispatch(loadPeerCompanies(res.data.globalCompanies));
    } catch (e) {}
  };

export const setPeerCompanyFilter =
  (companyFilter: CompanyFilter[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchSetPeerCompanyFilter(companyFilter));
    } catch (e) {}
  };

export const loadSuggestedPeerCompanyFilter =
  (cikNumber: number) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = cikNumber ? await api.fetchSuggestedPeers(cikNumber) : [];
      dispatch(loadSuggestedPeerCompanies(res.data));
    } catch (e) {}
  };

export const loadTalkingPointsTab =
  (categoryId: number, tabId: number) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      let payload = { insightsCategoryId: categoryId, benchmarkTypeId: tabId };
      const res = await api.fetchTalkingPoints(payload);
      dispatch(setTalkingPoints(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(setTalkingPoints([]));
    }
  };

export const fetchCategories = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setLoading(true));
    const res = await api.fetchCustomBenchmarkMetics();
    dispatch(setCategoryItems(res.data));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(setLoading(false));
    dispatch(setCategoryItems([]));
  }
};

export const fetchTilesDetail =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      const res = await api.getTilesDetail(
        payload.insightId,
        payload.insightsCategoryId,
        payload.benchmarkType
      );
      dispatch(updateTileDetails(res.data));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const saveAdditionalInfo =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      await api.saveAdditionalInfo(payload);
      dispatch(getAdditionalInfo(payload.referenceId, 0));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const updateAdditionalInfo =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      await api.updateAdditionalInfo(payload);
      dispatch(getAdditionalInfo(payload.referenceId, 0));
      if (payload && payload.additionalInformationText === "") {
        dispatch(setInformation(false));
      }

      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const getAdditionalInfo =
  (referenceId: number, additionalInformationTypeId: number) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      const res = await api.fetchAdditionalInfo(
        referenceId,
        additionalInformationTypeId
      );
      dispatch(setAdditionalInformation(res.data));
      //dispatch(setInformation(true));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const setSelectedCategoryItemIds =
  (selectedCategoryIds: InsightCategory[]) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(selectCategoryIds(selectedCategoryIds));
  };

export const changeInsightsView =
  (savedInsightDashboard: InsightsDashboard, payload: any) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      await api.saveInsightsPreferences(
        savedInsightDashboard.insightsId,
        payload
      );

      savedInsightDashboard.insightsCategories[0].benchmarks.forEach(
        (b: Visualization) => {
          if (
            payload.benchmarkMetadataList.filter(
              (v: any) => v.benchmarkId === b.benchmarkId
            )[0]
          ) {
            b.benchmarkMetadata.selectedStyleOption =
              payload.benchmarkMetadataList.filter(
                (v: any) => v.benchmarkId === b.benchmarkId
              )[0].benchmarkMetadataValue.selectedStyleOption;
          }
        }
      );
      dispatch(
        currentSavedInsightDashboard({
          ...savedInsightDashboard,
          insightsViewType: payload.insightsViewTypeId,
        })
      );
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

export const setInsightsPreferences =
  (insightsId: number, payload: any) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      dispatch(setFiltersSelected(payload.benchmarkMetadataList[0]));
      await api.saveInsightsPreferences(insightsId, payload);
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

export const saveSelectedDetailedViewBenchmarkIds =
  (
    insightsId: number,
    selectedDetailedViewBenchmarkIds: number[],
    selected: boolean
  ) =>
  async (dispatch: Dispatch<any>) => {
    const payload = selectedDetailedViewBenchmarkIds.map((d) => ({
      benchmarkId: d,
      IsDetailedPeerViewSelected: selected,
    }));
    dispatch(setLoading(true));
    await api.setSelectedDetailedViewBenchmarkIds(insightsId, {
      benchmarks: payload,
    });
    dispatch(setLoading(false));
  };

export const loadSavedPeerGroupList =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      const res = await api.getPeerGroupList(payload);
      dispatch(savedPeerGroupList(res.data.companyGroups));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const deletePeerGroup =
  (payload: any, page: any) => async (dispatch: Dispatch<any>) => {
    try {
      //TO ENABLE WHEN ENDPOINT IS DONE
      dispatch(setLoading(true));
      await api.deleteSavedPeerGroup(payload);
      dispatch(insightsDeletePeerGroupTrack({ page: page }));
      dispatch(deletePeerGroupPopUP(false));
      dispatch(setLoading(false));
      dispatch(refreshSavedPeerGroupsList(true));
    } catch (e) {
      console.log("error ", e);
    }
  };

export const getSavedUserPreference =
  (insightId: number) => async (dispatch: Dispatch<any>) => {
    try {
      const userPreference = await commonApi.getUserPreference(
        insightId,
        USER_PREFERENCES.INSIGHT_SECTION
      );
      dispatch(setSavedUserPreference(userPreference.data));
    } catch {
      dispatch(setSavedUserPreference({ referenceId: insightId }));
      dispatch(userPreferenceError(true));
    }
  };

export const patchUserPreference =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      await commonApi.updateUserPreference(payload);
    } catch {
      dispatch(userPreferenceError(true));
    }
  };

export const setCurrentTabID =
  (payload: number) => async (dispatch: Dispatch<any>) =>
    dispatch(setCurrentTab(payload));

export const refreshInsightsData =
  (insightId: number) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      await api.refreshInsightsData(insightId);
      dispatch(setLoading(false));
      dispatch(refreshSavedInsightsList(true));
    } catch (e) {}
  };

const insightsModel = new InsightsModel();

const insightsReducer = (
  state: InsightsState = insightsModel.initialState(),
  action: any
): InsightsState => {
  switch (action.type) {
    case ACTIONS.INSIGHTS_SHOW_CREATE_NEW_TAB:
      return insightsModel.setShowCreateNew(action.payload);
    case ACTIONS.INSIGHTS_SHOW_TALKING_POINTS:
      return insightsModel.setShowTalkingPoints(action.payload);
    case ACTIONS.INSIGHTS_SHOW_EXPORT_PANEL:
      return insightsModel.setShowExportPanel(action.payload);
    case ACTIONS.INSIGHTS_SHOW_EDIT_TAB:
      return insightsModel.setShowEdit(action.payload);
    case ACTIONS.INSIGHTS_LOADING:
      return insightsModel.setLoading(action.payload);
    case ACTIONS.SAVED_INSIGHTS_LIST:
      return insightsModel.setSavedInsights(action.payload);
    case ACTIONS.DELETE_SAVED_INSIGHTS_POP_UP:
      return insightsModel.setDeleteSavedInsightPopUP(action.payload);
    case ACTIONS.REFRESH_SAVED_INSIGHTS_LIST:
      return insightsModel.setRefreshSavedInsightsList(action.payload);
    case ACTIONS.RESET_SAVED_INSIGHTS:
      return insightsModel.resetSavedInsights(action.payload);
    case ACTIONS.CURRENT_SAVED_INSIGHT_DASHBOARD:
      return insightsModel.setCurrentSavedInsightDashboard(action.payload);
    case ACTIONS.CURRENT_SELECTED_DETAILED_VIEW:
      return insightsModel.setCurrentSelectedDetailedViewBenchmarkIds(
        action.payload
      );
    case ACTIONS.INSIGHTS_LOAD_TALKING_POINTS:
      return insightsModel.loadTalkingPoints(action.payload);
    case ACTIONS.INSIGHTS_LOAD_COMPANY_FILTER:
      return insightsModel.loadCompanyFilterOptions(action.payload);
    case ACTIONS.INSIGHTS_SHARE_TO_USERS:
      return insightsModel.setInsightsShareSuccess(action.payload);
    case ACTIONS.INSIGHTS_LOAD_PEER_COMPANIES:
      return insightsModel.loadPeerCompanyFilterOptions(action.payload);
    case ACTIONS.INSIGHTS_LOAD_SUGGESTED_PEER_COMPANIES:
      return insightsModel.loadSuggestedPeerCompanyFilterOptions(
        action.payload
      );
    case ACTIONS.INSIGHTS_SET_COMPANY_FILTER:
      return insightsModel.setCompanyFilter(action.payload);
    case ACTIONS.INSIGHTS_SET_PEER_COMPANY_FILTER:
      return insightsModel.setPeerCompanyFilter(action.payload);
    case ACTIONS.INSIGHTS_LOADING_FILTER:
      return insightsModel.setLoadingFilter(action.payload);
    case ACTIONS.INSIGHTS_SET_SELECTED_ITEMS:
      return insightsModel.setSelectedItems(action.payload);
    case ACTIONS.SHOW_CURRENT_INSIGHT_DASHBOARD:
      return insightsModel.setShowCurrentInsightDashboard(action.payload);
    case ACTIONS.GET_FEATURES_SETTINGS:
      return insightsModel.setFeatureSettings(action.payload);
    case ACTIONS.SAVED_INSIGHT_DASHBOARD_ID:
      return insightsModel.setSavedInsightDashboardId(action.payload);
    case ACTIONS.INSIGHTS_SIDE_DASHBOARD_CATEGORY_DATA:
      return insightsModel.setCategoryData(action.payload);
    case ACTIONS.INSIGHTS_SIDE_DASHBOARD_CATEGORY_SELECTED_DATA:
      return insightsModel.setSelectedCategoryIds(action.payload);
    case ACTIONS.INSIGHTS_SET_TALKING_POINTS:
      return insightsModel.setTalkingPoints(action.payload);
    case ACTIONS.INSIGHTS_RESET_DATA:
      return insightsModel.resetInsightsData();
    case ACTIONS.INSIGHTS_DUPLICATE_NAME_ERROR:
      return insightsModel.setDuplicateInsightError(action.payload);
    case ACTIONS.INSIGHTS_DUPLICATE_PEER_GROUP_NAME:
      return insightsModel.setDuplicatePeerGroupNameError(action.payload);
    case ACTIONS.INSIGHTS_SAVED_SUCCESS:
      return insightsModel.setInsightSavedSuccess(action.payload);
    case ACTIONS.INSIGHTS_PEER_GROUP_SUCCESS:
      return insightsModel.setInsightPeerGroupSuccess(action.payload);
    case ACTIONS.INSIGHTS_SET_ADDITIONAL_INFO:
      return insightsModel.setAdditionalInfo(action.payload);
    case ACTIONS.INSIGHTS_SHOW_EDITOR:
      return insightsModel.setShowEditor(action.payload);
    case ACTIONS.INSIGHTS_SHOW_INFORMATION:
      return insightsModel.setShowInformation(action.payload);
    case ACTIONS.INSIGHTS_TILE_TO_EXPORT:
      return insightsModel.setInsightTileToExport(action.payload);
    case ACTIONS.INSIGHTS_TILES_DETAIL:
      return insightsModel.setTileDetails(action.payload);
    case ACTIONS.SAVED_INSIGHTS_COUNT:
      return insightsModel.setInsightsCount(action.payload);
    case ACTIONS.INSIGHTS_SET_PEER_GROUP_LIST:
      return insightsModel.setSavedPeerGroupList(action.payload);
    case ACTIONS.DELETE_SAVED_PEER_GROUP_POP_UP:
      return insightsModel.setDeleteSavedInsightPopUP(action.payload);
    case ACTIONS.REFRESH_SAVED_PEER_GROUPS_LIST:
      return insightsModel.setRefreshSavedPeerGroupsList(action.payload);
    case ACTIONS.INSIGHTS_SET_SELECTED_PEER_GROUP_ITEMS:
      return insightsModel.setSelectedPeerGroupItems(action.payload);
    case ACTIONS.INSIGHTS_UPDATE_FILTERS:
      return insightsModel.updateTileFilters(action.payload);
    case ACTIONS.INSIGHTS_RESET_FILTERS:
      return insightsModel.resetBenchmarkFilters();
    case ACTIONS.INSIGHTS_SET_GLOBAL_COMPANY_MAPPING_FILTER:
      return insightsModel.setGlobalCompanyMappingsFilter(action.payload);
    case ACTIONS.INSIGHTS_SET_SAVED_USER_PREFERENCE:
      return insightsModel.setSavedUserPreference(action.payload);
    case ACTIONS.INSIGHTS_USER_PREFERENCE_ERROR:
      return insightsModel.setUserPreferenceError(action.payload);
    case ACTIONS.INSIGHTS_CURRENT_TAB:
      return insightsModel.setCurrentTab(action.payload);
    default:
      return state;
  }
};

export default insightsReducer;
