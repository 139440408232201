import { Dispatch } from "redux";
import { createAction } from "utils/redux.utils";
import {
  SavedShortListModel,
  SavedShortListState,
} from "./saved-shortlist.model";
import * as api from "./saved-shortlist.api";
import { loadShortListResults } from "services/shortlist/shortlist.service";

import { DEFAULT_SHORTLISTS_PAYLOAD } from "utils/constants";
import { User } from "services/commons.model";

/*
 * Actions
 */

export const ACTIONS = {
  SAVED_SHORTLIST_ERROR: "ESG/UI/SAVED_SHORTLIST_ERROR",
  SAVED_SHORTLIST_LOADING: "ESG/UI/SAVED_SHORTLIST_LOADING",
  SAVED_SHORTLIST_FETCH: "ESG/UI/SAVED_SHORTLIST_FETCH",
  SHORTLIST_RENAME: "ESG/UI/SHORTLIST_RENAME",
  SHORTLIST_DELETE: "ESG/UI/SHORTLIST_DELETE",
  SHORTLIST_SHARE: "ESG/UI/SHORTLIST_SHARE",
  SHORTLIST_DELETE_LOADING: "ESG/UI/SHORTLIST_DELETE_LOADING",
  SHORTLIST_RENAME_LOADING: "ESG/UI/SHORTLIST_RENAME_LOADING",
  SHORTLIST_SHARE_LOADING: "ESG/UI/SHORTLIST_RENAME_LOADING",
  SHORTLIST_LOADING: "ESG/UI/SHORTLIST_LOADING",
  SHORT_LIST_DELETE: "ESG/UI/SHORT_LIST_DELETE",
  SHORT_LIST_RENAME: "ESG/UI/SHORT_LIST_RENAME",
  SAVED_SHORTLIST_SORT_LOADING: "ESG/UI/SAVED_SHORTLIST_SORT_LOADING",
  SAVED_SHORTLIST_SORT_FETCH: "ESG/UI/SAVED_SHORTLIST_SORT_FETCH",
  SAVED_SHORTLIST_RENAME_ERROR: "ESG/UI/SAVED_SHORTLIST_RENAME_ERROR",
  SHORT_LIST_RENAME_SUCCESS: "ESG/UI/SHORT_LIST_RENAME_SUCCESS",
  SHORTLIST_SHARE_SUCCESS: "ESG/UI/SHORTLIST_SHARE_SUCCESS",
  USER_SEARCH_LOADING: "ESG/UI/USER_SEARCH_LOADING",
  USER_FETCH: "ESG/UI/USER_FETCH",
  SHORTLIST_SHARED_WITH: "ESG/UI/SHORTLIST_SHARED_WITH",
  SHORT_LIST_COUNT: "ESG/UI/SHORT_LIST_COUNT",
  SHORTLIST_SHARE_SUCCESS_FLAG: "ESG/UI/SHORTLIST_SHARE_SUCCESS_FLAG",
  SAVED_SHORT_LIST_RENAME_SUCCESS: "ESG/UI/SAVED_SHORT_LIST_RENAME_SUCCESS",
};

export const savedShortlistError = createAction(ACTIONS.SAVED_SHORTLIST_ERROR);
export const savedShortlistLoading = createAction(
  ACTIONS.SAVED_SHORTLIST_LOADING
);
export const savedShortlistGet = createAction(ACTIONS.SAVED_SHORTLIST_FETCH);

export const shortlistDeletePop = createAction(ACTIONS.SHORTLIST_DELETE);

export const shortlistRenamePop = createAction(ACTIONS.SHORT_LIST_RENAME);

export const shortlistSharePop = createAction(ACTIONS.SHORTLIST_SHARE);

export const shortlistDelete = createAction(ACTIONS.SHORT_LIST_DELETE);

export const shortlistRename = createAction(ACTIONS.SHORTLIST_RENAME);

export const shortlistCount = createAction(ACTIONS.SHORT_LIST_COUNT);

export const shortlistShareSuccess = createAction(
  ACTIONS.SHORTLIST_SHARE_SUCCESS_FLAG
);

export const renameShortlistSuccess = createAction(
  ACTIONS.SHORT_LIST_RENAME_SUCCESS
);

export const renameSavedShortlistSuccess = createAction(
  ACTIONS.SAVED_SHORT_LIST_RENAME_SUCCESS
);

export const shareShortlistSuccess = createAction(
  ACTIONS.SHORTLIST_SHARE_SUCCESS
);

export const shortlistDeleteLoading = createAction(
  ACTIONS.SHORTLIST_DELETE_LOADING
);

export const shortlistRenameLoading = createAction(
  ACTIONS.SHORTLIST_RENAME_LOADING
);

export const shortlistShareLoading = createAction(ACTIONS.SHORTLIST_LOADING);

export const savedShortlistSortLoading = createAction(
  ACTIONS.SAVED_SHORTLIST_SORT_LOADING
);
export const savedShortlistSortGet = createAction(
  ACTIONS.SAVED_SHORTLIST_SORT_FETCH
);
export const shortlistRenameError = createAction(
  ACTIONS.SAVED_SHORTLIST_RENAME_ERROR
);

export const searchLoadUser = createAction(ACTIONS.USER_SEARCH_LOADING);
export const sharedWithUsers = createAction(ACTIONS.USER_FETCH);
export const searchSetSharedWith = createAction(ACTIONS.SHORTLIST_SHARED_WITH);

export const loadsavedShortlist =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedShortlistLoading(true));
      const res = await api.fetchShortlist(payload);
      dispatch(savedShortlistGet(res.data));
      dispatch(savedShortlistLoading(false));
    } catch (e) {
      dispatch(savedShortlistLoading(false));
      dispatch(savedShortlistError(e));
    }
  };

export const deleteShortlistPop =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shortlistDeleteLoading(true));
      //const res = await api.fetchShortListResults(payload);
      dispatch(shortlistDeletePop(payload));
      dispatch(shortlistDeleteLoading(false));
    } catch (e) {
      dispatch(shortlistDeleteLoading(false));
    }
  };

export const renameShortlistPop =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shortlistRenameLoading(true));
      //const res = await api.fetchShortListResults(payload);
      dispatch(shortlistRenamePop(payload));
      dispatch(shortlistRenameLoading(false));
    } catch (e) {
      dispatch(shortlistRenameLoading(false));
    }
  };

export const shareShortlistPop =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shortlistShareLoading(true));
      dispatch(shortlistSharePop(payload));
      dispatch(shortlistShareLoading(false));
    } catch (e) {
      dispatch(shortlistShareLoading(false));
    }
  };

export const deleteShortlist =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      //const res = await api.fetchShortListResults(payload);
      dispatch(savedShortlistLoading(true));
      dispatch(shortlistDelete(true));
      await api.removeShortlist(payload);
      dispatch(shortlistDeletePop(null));
      dispatch(savedShortlistLoading(false));
      dispatch(loadsavedShortlist(DEFAULT_SHORTLISTS_PAYLOAD));
    } catch (e) {
      dispatch(savedShortlistLoading(false));
    }
  };

export const renameShortlist =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedShortlistLoading(true));

      await api.saveShortlist({
        shortlistName: payload.shortlistName,
        shortlistId: payload.shortlistId,
      });
      if (payload.type === "shortlist") {
        dispatch(
          loadShortListResults(
            {
              pageInfo: {
                pageNumber: 1,
                pageSize: 999,
              },
            },
            payload.shortlistId
          )
        );
        dispatch(renameShortlistSuccess(true));
      }
      dispatch(shortlistRenamePop(null));
      dispatch(renameShortlistSuccess(false));
      dispatch(renameSavedShortlistSuccess(true));
      dispatch(savedShortlistLoading(false));
      dispatch(loadsavedShortlist(DEFAULT_SHORTLISTS_PAYLOAD));
    } catch (e) {
      dispatch(shortlistRenameError());
      dispatch(renameShortlistSuccess(false));
      dispatch(savedShortlistLoading(false));
    }
  };

export const shareShortlist =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedShortlistLoading(true));
      await api.shareShortlist({
        shortlistName: payload.shortlistName,
        shortlistId: payload.shortlistId,
        SharedWith: payload.sharedWith,
        sourceShortlistId: payload.sourceShortlistId,
      });

      if (payload.type === "shortlist") {
        dispatch(
          loadShortListResults(
            {
              pageInfo: {
                pageNumber: 1,
                pageSize: 999,
              },
            },
            payload.shortlistId
          )
        );
      }

      dispatch(setSharedWith([]));
      dispatch(shortlistSharePop(null));
      dispatch(savedShortlistLoading(false));
      dispatch(shareShortlistSuccess(true));
      dispatch(shortlistShareSuccess(true));
      dispatch(loadsavedShortlist(DEFAULT_SHORTLISTS_PAYLOAD));
    } catch (e) {
      dispatch(savedShortlistLoading(false));
      dispatch(shareShortlistSuccess(false));
    }
  };

export const loadUsers =
  (searchText: string, currentUserId: number) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadUser(true));
      const res = searchText ? await api.getUsers(searchText) : [];
      dispatch(
        sharedWithUsers({
          sharedWithOptions: res,
          currentUserId: currentUserId,
        })
      );
      dispatch(searchLoadUser(false));
    } catch (e) {
      dispatch(searchLoadUser(false));
    }
  };

export const setSharedWith =
  (sharedWith: User[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchSetSharedWith(sharedWith));
    } catch (e) {
      console.log(e);
    }
  };

export const loadsavedShortlistSort =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedShortlistSortLoading(true));
      const res = await api.fetchShortlist(payload);
      dispatch(savedShortlistSortGet(res.data));
      dispatch(savedShortlistSortLoading(false));
    } catch (e) {
      dispatch(savedShortlistSortLoading(false));
    }
  };

export const getSavedShortlistCount = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(savedShortlistSortLoading(true));
    const res = await api.getCount();
    dispatch(shortlistCount(res.data));
    dispatch(loadsavedShortlist(DEFAULT_SHORTLISTS_PAYLOAD));
    dispatch(savedShortlistSortLoading(false));
  } catch (e) {
    dispatch(savedShortlistSortLoading(false));
  }
};

/*
 * Reducer
 */

const savedShortlistModel = new SavedShortListModel();

const savedShorlistReducer = (
  state: SavedShortListState = savedShortlistModel.initialState(),
  action: any
): SavedShortListState => {
  switch (action.type) {
    case ACTIONS.SAVED_SHORTLIST_ERROR:
      return savedShortlistModel.setError(action.payload);
    case ACTIONS.SAVED_SHORTLIST_LOADING:
      return savedShortlistModel.setLoading(action.payload);
    case ACTIONS.SAVED_SHORTLIST_FETCH:
      return savedShortlistModel.setSavedShortList(action.payload);
    case ACTIONS.SHORTLIST_DELETE_LOADING:
      return savedShortlistModel.setDeleteLoading(action.payload);
    case ACTIONS.SHORTLIST_RENAME_LOADING:
      return savedShortlistModel.setRenameLoading(action.payload);
    case ACTIONS.SHORTLIST_SHARE_LOADING:
      return savedShortlistModel.setShareLoading(action.payload);
    case ACTIONS.SHORT_LIST_RENAME:
      return savedShortlistModel.setRenameItem(action.payload);
    case ACTIONS.SHORTLIST_DELETE:
      return savedShortlistModel.setDeleteItem(action.payload);
    case ACTIONS.SHORTLIST_SHARE:
      return savedShortlistModel.setShareItem(action.payload);
    case ACTIONS.SAVED_SHORTLIST_SORT_LOADING:
      return savedShortlistModel.setSortLoading(action.payload);
    case ACTIONS.SAVED_SHORTLIST_SORT_FETCH:
      return savedShortlistModel.setSavedShortList(action.payload);
    case ACTIONS.SAVED_SHORTLIST_RENAME_ERROR:
      return savedShortlistModel.setErrorRename();
    case ACTIONS.SHORT_LIST_RENAME_SUCCESS:
      return savedShortlistModel.setRenameSuccess(action.payload);
    case ACTIONS.SAVED_SHORT_LIST_RENAME_SUCCESS:
      return savedShortlistModel.setSavedRenameSuccess(action.payload);
    case ACTIONS.SHORTLIST_SHARE_SUCCESS:
      return savedShortlistModel.setShareSuccess(action.payload);
    case ACTIONS.USER_SEARCH_LOADING:
      return savedShortlistModel.setSearchLoading(action.payload);
    case ACTIONS.USER_FETCH:
      return savedShortlistModel.loadSharedWithOptions(action.payload);
    case ACTIONS.SHORTLIST_SHARED_WITH:
      return savedShortlistModel.searchSetSharedWith(action.payload);
    case ACTIONS.SHORT_LIST_COUNT:
      return savedShortlistModel.setSavedShortlistCount(action.payload);
    default:
      return state;
  }
};

export default savedShorlistReducer;
