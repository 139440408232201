import VerificationChart from "components/visualizations/charts/verification.chart";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import {
  mapTableChart,
  mapTabularChartData,
  useTileContext,
} from "../tile.context";
import TableChart from "components/visualizations/charts/table-chart";

const Verification = () => {
  const {
    metadata,
    dataset,
    metadata: { benchmarkType },
    isTableViewActive,
  } = useTileContext();
  const BLOCK = "verification";

  return (
    <div className={`${BLOCK}__container`}>
      {isTableViewActive ? (
        <TableChart
          data={mapTableChart(dataset, metadata)}
          currentInsightView={benchmarkType}
          renderNeutralTooltip={true}
        />
      ) : (
        <VerificationChart
          data={
            metadata.benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
              ? mapTabularChartData(metadata, dataset, true)
              : {}
          }
          peerData={
            metadata.benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
              ? {}
              : mapTableChart(dataset, metadata)
          }
          metadata={metadata}
        />
      )}
    </div>
  );
};

export default Verification;
