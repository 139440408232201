import classNames from "classnames";
import DropdownContainer from "components/shared/dropdown-container/dropdown-container";
import PeerCompanies from "components/shared/peer-companies/peer-companies";
import SavedPeerModal from "components/shared/save-peer-modal/save-peer-modal";
import SuggestedPeers from "components/shared/suggested-peers/suggested-peers";
import ActionContainer from "components/shared/action-container/actions-container";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import ShowHideTile from "components/shared/show-hide-tile/show-hide-tile";
import Icon from "components/shared/icon/icon";

import { debounce, parseInt } from "lodash";
import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createInsights,
  editInsights,
  fetchCategories,
  fetchTilesDetail,
  getCompanyFilter,
  getGlobalCompanyFilter,
  getGlobalPeerCompanyFilter,
  getPeerCompanyFilter,
  insightsCreateInsightTrack,
  insightsEditInsightTrack,
  loadCompanyFilter,
  loadPeerCompanies,
  loadSavedPeerGroupList,
  loadSuggestedPeerCompanyFilter,
  resetInsightTab,
  savePeerGroup,
  setBaselineCompanyFilter,
  setCompanyFilter,
  setduplicateInsightsError,
  setGlobalCompanyMappingsFilter,
  setGlobalMappingFilter,
  setInsightsSavedSuccess,
  setPeerCompanyFilter,
  setPeerGroupSavedSuccess,
  setSelectedCategoryItemIds,
  showCreateNewTab,
  showEditTab,
  updateSelectedItems,
  updateSelectedPeerGroupItems,
} from "services/insights/insights.service";
import { RootStore } from "services/store.service";
import {
  APP_SETTING,
  BENCHMARK_TILE_TYPES,
  COMPANY_IDENTIFIER,
  COMPANY_LIMIT,
  COMPANY_TYPE,
  COUNTRY_CODE,
  DEFAULT_PEER_GROUP_LIST_PAYLOAD,
  FILTER_OPTIONS,
  insightsPath,
  PEER_GROUP_CREATION_PAGE,
  SOURCE_PAGE,
} from "utils/constants";
import {
  BenchmarkCompanies,
  Visualization,
} from "services/dashboard/dashboard.model";
import { CompanyFilter } from "services/search/filters.model";
import history from "utils/history";
import {
  addToastMessage,
  loadInsightsPeerCompanyLimit,
} from "services/commons.service";
import DashboardType from "components/shared/dropdown/dashboard-type";
import {
  baselineCompanyForPeerGroupPayload,
  CompanyGroupCompany,
  companyGroupGlobal,
  GlobalPeerGroup,
  GlobalCompanyGroup,
  GlobalCompanyMapping,
  InsightCategory,
  PeerGroup,
  peerGroupCompanies,
  savePeerGroupNamePayload,
  HybridCompany,
  InsightDashboardCompany,
} from "services/insights/insights.model";
import SidePanel from "components/shared/side-panel/side-panel";
import Parser from "html-react-parser";
import Button from "components/shared/button/button";
import GlobalSearchModal from "components/shared/global-search/global-search-modal";
import ConfirmationModal from "components/shared/confirmation-modal/confirmation-modal";
import { FormattedMessageString, isNumeric } from "utils/functions";
import {
  deleteSavedGrouping,
  fetchGlobalCompaniesFilter,
  fetchSavedGroupings,
  saveCompanyGrouping,
} from "services/insights/insights.api";
import {
  isAnyGlobalCompanyInGlobalGroups,
  isCompanyInGlobalGroup,
} from "components/shared/global-search/global-search-dropdown";

type props = {
  BLOCK: string;
  edit?: boolean;
  tabId?: number;
  setCreateNewTab: Function;
};

const CreateNew = ({ BLOCK, edit, tabId, setCreateNewTab }: props) => {
  const [dashboardName, setDashboardName] = useState<string>("");
  const insightsState = useSelector((state: RootStore) => state.insights);
  const commonsState = useSelector((state: RootStore) => state.commons);
  const [suggestedPeers, setSuggestedPeers] = useState<boolean>(false);
  const [savedPeers, setSavedPeers] = useState<boolean>(false);
  const [selectedPeerCompany, setSelectedPeerCompany] = useState<
    CompanyFilter[]
  >([]);
  const [disabledButtonOne, setDisabledButtonOne] = useState<boolean>(true);
  const [disbaledButtonTwo, setDisabledButtonTwo] = useState<boolean>(true);
  const [duplicateName, setDuplicateName] = useState<boolean>(false);
  const [peerSelection, setPeerSelection] = useState<boolean>(false);
  const [selectionType, setSelectionType] = useState<string>("");
  const [editedVisualizationsState, setEditedVisualizationsState] = useState<
    Visualization[]
  >([]);
  const [selectedGlobalPeerCompanies, setSelectedGlobalPeerCompanies] =
    useState<Partial<GlobalCompanyGroup>[]>([]);
  const [
    hideTilesIfSelectedNonSECBaseline,
    setHideTilesIfSelectedNonSECBaseline,
  ] = useState<boolean>(false);

  //If the business wants the charecter counter then just
  // uncomment the code else we will remove it
  // const [nameCount, setNameCount] = useState<number>(0);

  const dispatch = useDispatch();
  const [, setPeerGroups] = useState<GlobalPeerGroup[]>([]);
  const [peerGroupsLoaded, setPeerGroupsLoaded] = useState<boolean>(false);
  const [, setSelectedCheckboxPeerGroup] = useState<any[]>([]);

  const [isBaseCompanySuggested, setIsBaseCompanySuggested] =
    useState<boolean>(false);

  const [companiesUnselected, setCompaniesUnselected] = useState<number[]>([]);

  //Global search implementation
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showGlobalSearch, setShowGlobalSearch] = useState<boolean>(false);

  const [
    isConfirmationModalTriggerByDropdown,
    setIsConfirmationModalTriggerByDropdown,
  ] = useState<boolean>(false);
  const [changeNonSecCompany, setChangeNonSecCompany] =
    useState<boolean>(false);

  const [localSelectedBaseline, setLocalSelectedBaseline] =
    useState<CompanyFilter | null>(null);

  const [peerOrder, setPeerOrder] = useState<number>(1);

  useEffect(() => {
    dispatch(loadSavedPeerGroupList(DEFAULT_PEER_GROUP_LIST_PAYLOAD));
    dispatch(updateSelectedPeerGroupItems([]));
    setLocalSelectedBaseline(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (insightsState.savedPeerGroupList && !peerGroupsLoaded) {
      setPeerGroups(insightsState.savedPeerGroupList);
      setPeerGroupsLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.savedPeerGroupList]);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(
      loadInsightsPeerCompanyLimit(APP_SETTING.InsightsPeerCompaniesLimit)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (edit && insightsState.selectedCategoryIds.length) {
      const payload = {
        insightId: insightsState.currentSavedInsightDashboard.insightsId,
        insightsCategoryId:
          insightsState.selectedCategoryIds[0].insightsCategoryId,
        benchmarkType: tabId!,
      };
      setEditedVisualizationsState([]);
      dispatch(fetchTilesDetail(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.selectedCategoryIds, edit]);
  useEffect(() => {
    if (insightsState.tileDetails.length) {
      let tempVisualizationsStateCopy = JSON.parse(
        JSON.stringify(
          [...insightsState.tileDetails].sort(
            (a: Visualization, b: Visualization) =>
              a.displayOrder - b.displayOrder
          )
        )
      );
      //setTempVisualizationsState(insightsState.tileDetails);
      setEditedVisualizationsState(tempVisualizationsStateCopy);
    }
  }, [insightsState.tileDetails]);

  useEffect(() => {
    if (insightsState.diplicateInsightError) {
      setDuplicateName(true);
      dispatch(setduplicateInsightsError(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.diplicateInsightError, dispatch]);

  useEffect(() => {
    if (insightsState.insightSavedSuccess) {
      setDuplicateName(false);
      dispatch(setInsightsSavedSuccess(false));
      dispatch(showCreateNewTab(false));
      dispatch(showEditTab(false));
      setCreateNewTab(false);
      if (insightsState.savedInsightDashboardId) {
        history.push(
          `${insightsPath}/${insightsState.savedInsightDashboardId}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.insightSavedSuccess, dispatch]);

  useEffect(() => {
    setSelectedPeerCompany(insightsState.peerCompanyFilter);
    setSelectedGlobalPeerCompanies(
      insightsState.globalCompanyMappingsFilter
        ? insightsState.globalCompanyMappingsFilter.filter(
            (g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping
          )
        : []
    );
  }, [
    insightsState.peerCompanyFilter,
    savedPeers,
    insightsState.globalCompanyMappingsFilter,
  ]);

  const isEditActionsDisabled = () => {
    const dashboardStateHiddenValues = insightsState.tileDetails
      .sort(
        (a: Visualization, b: Visualization) => a.displayOrder - b.displayOrder
      )
      .map((vis: Visualization) => ({
        benchmarkId: vis.benchmarkId,
        isHidden: vis.isHidden,
        displayOrder: vis.displayOrder,
      }));

    const tempEditStateHiddenValues = editedVisualizationsState.map(
      (vis: Visualization) => ({
        benchmarkId: vis.benchmarkId,
        isHidden: vis.isHidden,
        displayOrder: vis.displayOrder,
      })
    );

    return (
      JSON.stringify(dashboardStateHiddenValues) ===
      JSON.stringify(tempEditStateHiddenValues)
    );
  };

  const handleApplyEditDashboard = () => {
    const payload: any[] = [];
    if (
      insightsState.selectedCategoryIds[0]?.insightsCategoryId !==
      insightsState.currentSavedInsightDashboard.insightsCategories[0]
        ?.insightsCategoryId
    ) {
      editedVisualizationsState.forEach((vis: Visualization) => {
        payload.push({
          predefinedBenchmarkId: vis.predefinedBenchmarkId,
          benchmarkTitle: vis.benchmarkTitle,
          isHidden: vis.isHidden,
          displayOrder: vis.displayOrder,
          benchmarkId: 0,
        });
      });
    } else {
      editedVisualizationsState.forEach((vis: Visualization) => {
        payload.push({
          benchmarkId: vis.benchmarkId,
          benchmarkTitle: vis.benchmarkTitle,
          isHidden: vis.isHidden,
          displayOrder: vis.displayOrder,
          predefinedBenchmarkId: 0,
        });
      });
    }
    return payload;
  };

  const getTotalPeersAdded = () => {
    return (
      (insightsState.globalCompanyMappingsFilter
        ? insightsState.globalCompanyMappingsFilter.filter(
            (g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping
          ).length
        : 0) +
      (insightsState.peerCompanyFilter
        ? insightsState.peerCompanyFilter.length
        : 0)
    );
  };

  useEffect(() => {
    // Enabling/Disabling Button 1
    if (insightsState.showCreateNewTab) {
      if (
        (insightsState.selectedCategoryIds &&
          insightsState.selectedCategoryIds.length !== 0) ||
        (trimStartFunc(dashboardName) && trimStartFunc(dashboardName) !== "") ||
        (insightsState.companyFilter &&
          insightsState.companyFilter.length !== 0) ||
        (insightsState.peerCompanyFilter &&
          insightsState.peerCompanyFilter.length !== 0) ||
        (insightsState.globalCompanyMappingsFilter &&
          insightsState.globalCompanyMappingsFilter.length !== 0)
      ) {
        setDisabledButtonOne(false);
      } else if (!disabledButtonOne) {
        setDisabledButtonOne(true);
      }

      // Enabling/Disbaling Button 2
      if (
        insightsState.selectedCategoryIds &&
        insightsState.selectedCategoryIds.length !== 0 &&
        trimStartFunc(dashboardName) &&
        trimStartFunc(dashboardName) !== "" &&
        insightsState.companyFilter &&
        insightsState.companyFilter.length !== 0 &&
        (insightsState.globalCompanyMappingsFilter
          ? insightsState.globalCompanyMappingsFilter.filter(
              (gc: Partial<GlobalCompanyGroup>) => !gc.isBaseMapping
            ).length
          : 0) +
          (insightsState.peerCompanyFilter
            ? insightsState.peerCompanyFilter.length
            : 0) <=
          (isNumeric(
            commonsState.insightsPeerCompanyLimit?.InsightsPeerCompaniesLimit
          )
            ? parseInt(
                commonsState.insightsPeerCompanyLimit
                  ?.InsightsPeerCompaniesLimit
              )
            : COMPANY_LIMIT.PEER)
      ) {
        setDisabledButtonTwo(false);
      } else {
        setDisabledButtonTwo(true);
      }
    }
    if (insightsState.showEditTab) {
      // Enabling/Disabling Button 1
      if (
        insightsState.selectedCategoryIds[0]?.insightsCategoryId !==
          insightsState.currentSavedInsightDashboard.insightsCategories[0]
            ?.insightsCategoryId ||
        dashboardName !==
          insightsState.currentSavedInsightDashboard.insightsName ||
        !compareArrays(
          insightsState.currentSavedInsightDashboard.insightsCompanies
            .filter((baseline: any) => {
              return (
                baseline.isBaseCompany === false &&
                (insightsState.currentSavedInsightDashboard
                  .globalCompanyMappings.length === 0 ||
                  isCompanyInGlobalGroup(
                    insightsState.currentSavedInsightDashboard
                      .globalCompanyMappings,
                    baseline.globalCompanyId
                  ) === 0)
              );
            })
            .map((comp) => comp.globalCompany.globalCompanyId ?? 0),
          insightsState.peerCompanyFilter.map(
            (comp: CompanyFilter) => comp.globalCompanyId ?? 0
          )
        ) ||
        !compareArrays(
          insightsState.currentSavedInsightDashboard.globalCompanyMappings
            .filter(
              (company: Partial<GlobalCompanyGroup>) =>
                company.isBaseMapping === false
            )
            .map((comp) => comp.globalCompanyMappingId),
          insightsState.globalCompanyMappingsFilter
            .filter(
              (company: Partial<GlobalCompanyGroup>) =>
                company.isBaseMapping === false
            )
            .map((comp) => comp.globalCompanyMappingId!)
        ) ||
        !isEditActionsDisabled()
      ) {
        setDisabledButtonOne(false);
      } else if (!disabledButtonOne) {
        setDisabledButtonOne(true);
      }
      // Enabling/Disbaling Button 2
      if (
        insightsState.selectedCategoryIds &&
        insightsState.selectedCategoryIds.length !== 0 &&
        trimStartFunc(dashboardName) &&
        dashboardName !== "" &&
        insightsState.companyFilter &&
        insightsState.companyFilter.length !== 0 &&
        (insightsState.selectedCategoryIds[0]?.insightsCategoryId !==
          insightsState.currentSavedInsightDashboard.insightsCategories[0]
            ?.insightsCategoryId ||
          dashboardName !==
            insightsState.currentSavedInsightDashboard.insightsName ||
          !compareArrays(
            insightsState.currentSavedInsightDashboard.insightsCompanies
              .filter(
                (baseline: any) =>
                  baseline.isBaseCompany === false &&
                  (insightsState.currentSavedInsightDashboard
                    .globalCompanyMappings.length === 0 ||
                    isCompanyInGlobalGroup(
                      insightsState.currentSavedInsightDashboard
                        .globalCompanyMappings,
                      baseline.globalCompanyId
                    ) === 0)
              )
              .map((comp) => comp.globalCompany.globalCompanyId ?? 0),
            insightsState.peerCompanyFilter.map(
              (comp: CompanyFilter) => comp.globalCompanyId ?? 0
            )
          ) ||
          !compareArrays(
            insightsState.currentSavedInsightDashboard.globalCompanyMappings
              .filter(
                (company: Partial<GlobalCompanyGroup>) =>
                  company.isBaseMapping === false
              )
              .map(
                (comp) => comp.globalCompanyMappingDetails[0].globalCompanyId
              ),
            insightsState.globalCompanyMappingsFilter
              .filter(
                (company: Partial<GlobalCompanyGroup>) =>
                  company.isBaseMapping === false
              )
              .map(
                (comp) => comp.globalCompanyMappingDetails![0].globalCompanyId
              )
          ) ||
          !isEditActionsDisabled())
      ) {
        setDisabledButtonTwo(false);
      } else {
        setDisabledButtonTwo(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    insightsState.selectedCategoryIds,
    insightsState.companyFilter,
    insightsState.peerCompanyFilter,
    insightsState.globalCompanyMappingsFilter,
    dashboardName,
    editedVisualizationsState,
  ]);

  const compareArrays = (arrayOne: number[], arrayTwo: number[]) => {
    let flag = true;
    if (arrayOne.length !== arrayTwo.length) return !flag;
    arrayOne.forEach((item) => {
      if (!arrayTwo.includes(item)) {
        flag = false;
      }
    });

    return flag;
  };

  const trimStartFunc = (value: any) => {
    if (!value) return value;
    return value.replace(/^\s+/g, "");
  };

  const onChangeHandler = (event: any) => {
    setDashboardName(event.target.value);
    setDuplicateName(false);
  };

  const setEditSavedData = () => {
    if (
      insightsState.currentSavedInsightDashboard &&
      insightsState.currentSavedInsightDashboard?.insightsName &&
      insightsState.currentSavedInsightDashboard?.insightsCompanies
    ) {
      let peersCounter =
        1 +
        insightsState.currentSavedInsightDashboard.globalCompanyMappings.filter(
          (g: GlobalCompanyGroup) => !g.isBaseMapping
        ).length +
        insightsState.currentSavedInsightDashboard.insightsCompanies.filter(
          (baseline: any) =>
            baseline.isBaseCompany === false &&
            baseline.globalCompany.countryCode === COUNTRY_CODE.US
        ).length;
      setDashboardName(insightsState.currentSavedInsightDashboard.insightsName);
      dispatch(
        updateSelectedItems(
          insightsState.currentSavedInsightDashboard.insightsCompanies
            .filter((baseline: any) => baseline.isBaseCompany === false)
            .map((comp) => comp.globalCompanyId)
        )
      );

      dispatch(
        setGlobalCompanyMappingsFilter(
          [
            ...insightsState.currentSavedInsightDashboard.globalCompanyMappings.map(
              (gcg: GlobalCompanyGroup) => {
                let defaultCompany = gcg.globalCompanyMappingDetails.find(
                  (m: GlobalCompanyMapping) => m.isDefault
                );
                let defaultCompanyInCompanies =
                  insightsState.currentSavedInsightDashboard.insightsCompanies.find(
                    (icf: InsightDashboardCompany) =>
                      defaultCompany &&
                      icf.globalCompanyId === defaultCompany.globalCompanyId
                  );

                return {
                  ...gcg,
                  displayOrder:
                    defaultCompanyInCompanies &&
                    defaultCompanyInCompanies.displayOrder
                      ? defaultCompanyInCompanies.displayOrder
                      : 0,
                };
              }
            ),
          ].sort((a: GlobalCompanyGroup, b: GlobalCompanyGroup) => {
            return (a.displayOrder ?? 0) > (b.displayOrder ?? 0)
              ? 1
              : (a.displayOrder ?? 0) < (b.displayOrder ?? 0)
              ? -1
              : 0;
          })
        )
      );

      dispatch(
        setCompanyFilter(
          insightsState.currentSavedInsightDashboard.insightsCompanies
            .filter(
              (baseline: any) =>
                (baseline.isBaseCompany === true &&
                  baseline.globalCompany.countryCode === COUNTRY_CODE.US) ||
                (baseline.isBaseCompany === true &&
                  insightsState.currentSavedInsightDashboard.globalCompanyMappings.find(
                    (gcg: GlobalCompanyGroup) =>
                      gcg.isBaseMapping &&
                      gcg.globalCompanyMappingDetails.find(
                        (m: GlobalCompanyMapping) =>
                          m.isDefault &&
                          m.globalCompanyId === baseline.globalCompanyId
                      )
                  ))
            )
            .map((comp) => ({ ...comp.globalCompany, displayOrder: 0 }))
        )
      );
      //CHECK IF IS BASELINE COMPANY IS A SAVED MAPPING
      if (
        insightsState.currentSavedInsightDashboard.insightsCompanies.filter(
          (baseline: any) =>
            baseline.isBaseCompany === true && baseline.cikNumber
        ).length
      ) {
        dispatch(
          loadSuggestedPeerCompanyFilter(
            insightsState.currentSavedInsightDashboard.insightsCompanies
              .filter((baseline: any) => baseline.isBaseCompany === true)
              .map((comp) => comp.globalCompany)[0].cikNumber
          )
        );
        setPeerSelection(true);
      } else {
        let peerCompanies: any =
          insightsState.currentSavedInsightDashboard.insightsCompanies
            .filter(
              (baseline: any) =>
                baseline.isBaseCompany === false &&
                baseline.globalCompany.countryCode === COUNTRY_CODE.US
            )
            .map((comp, compIndex) => ({
              ...comp.globalCompany,
              displayOrder: comp.displayOrder ?? compIndex,
            }));

        dispatch(setPeerCompanyFilter(peerCompanies));
      }

      dispatch(
        setSelectedCategoryItemIds([
          insightsState.currentSavedInsightDashboard.insightsCategories[0],
        ])
      );

      setPeerOrder(peersCounter);
    }
  };

  useEffect(setEditSavedData, [
    insightsState.currentSavedInsightDashboard,
    dispatch,
  ]);
  useEffect(() => {
    if (
      edit &&
      peerSelection &&
      insightsState?.suggestedPeerCompanyFilter?.length > 0
    ) {
      let peerCompanies: any =
        insightsState.currentSavedInsightDashboard.insightsCompanies
          .filter(
            (baseline: any) =>
              baseline.isBaseCompany === false &&
              (insightsState.currentSavedInsightDashboard.globalCompanyMappings
                .length === 0 ||
                isCompanyInGlobalGroup(
                  insightsState.currentSavedInsightDashboard
                    .globalCompanyMappings,
                  baseline.globalCompanyId
                ) === 0)
          )
          .map((comp, compIndex) => ({
            ...comp.globalCompany,
            displayOrder: comp.displayOrder ?? compIndex,
          }));

      let setPeerCompanies: any = [];
      peerCompanies.forEach((peer: any) => {
        if (
          insightsState.suggestedPeerCompanyFilter.filter(
            (peers: any) => peers.globalCompanyId === peer.globalCompanyId
          ).length > 0
        ) {
          setPeerCompanies.push({ ...peer, isSuggestedPeerCompany: true });
        } else {
          setPeerCompanies.push({ ...peer, isSuggestedPeerCompany: false });
        }
      });
      dispatch(setPeerCompanyFilter(setPeerCompanies));
      setPeerSelection(false);
    } else if (edit && peerSelection) {
      let peerCompanies: any =
        insightsState.currentSavedInsightDashboard.insightsCompanies
          .filter(
            (baseline: any) =>
              baseline.isBaseCompany === false &&
              (insightsState.currentSavedInsightDashboard.globalCompanyMappings
                .length === 0 ||
                isCompanyInGlobalGroup(
                  insightsState.currentSavedInsightDashboard
                    .globalCompanyMappings,
                  baseline.globalCompanyId
                ) === 0)
          )
          .map((comp, compIndex) => ({
            ...comp.globalCompany,
            displayOrder: comp.displayOrder ?? compIndex,
          }));

      dispatch(setPeerCompanyFilter(peerCompanies));

      setPeerSelection(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, insightsState.suggestedPeerCompanyFilter, edit, peerSelection]);

  const resetBenchmarkHandler = () => {
    //reset all fields here
    setDashboardName("");
    // setNameCount(0);
    setDuplicateName(false);
    dispatch(resetInsightTab());
    dispatch(updateSelectedPeerGroupItems([]));
    setLocalSelectedBaseline(null);
    dispatch(setGlobalCompanyMappingsFilter([]));
    setPeerOrder(1);
  };
  const resetEditBenchmarkHandler = () => {
    //reset all fields here
    dispatch(resetInsightTab());
    setEditSavedData();
    setDuplicateName(false);
    handleResetEditDashboard();
    dispatch(updateSelectedPeerGroupItems([]));
    setLocalSelectedBaseline(null);
  };

  const handleResetEditDashboard = () => {
    const newStateCopy = JSON.parse(
      JSON.stringify(
        [
          ...insightsState.currentSavedInsightDashboard?.insightsCategories[0]
            ?.benchmarks,
        ].sort(
          (a: Visualization, b: Visualization) =>
            a.displayOrder - b.displayOrder
        )
      )
    );
    setEditedVisualizationsState(newStateCopy);
  };

  const applyBenchmarkHandler = () => {
    //add logic for when apply is pressed
    //call api and everything else related to that

    let displayOrder = 0;
    let insightsCompanies: BenchmarkCompanies[] = [];
    let peerDisplayOrderValues = insightsState.globalCompanyMappingsFilter
      .filter((gc: Partial<GlobalCompanyGroup>) => !gc.isBaseMapping)
      .map((gc: Partial<GlobalCompanyGroup>) => gc.displayOrder)
      .concat(
        insightsState.peerCompanyFilter.map(
          (pc: CompanyFilter) => pc.displayOrder
        )
      )
      .sort();

    let globalBaseCompany: any = insightsState.globalCompanyMappingsFilter
      .length
      ? insightsState.globalCompanyMappingsFilter.find(
          (gbc: Partial<GlobalCompanyGroup>) => gbc.isBaseMapping
        )
      : null;

    let baseCompany: any = {
      cikNumber: insightsState.companyFilter[0].cikNumber,
      globalCompanyId: insightsState.companyFilter[0].globalCompanyId,
      isBaseCompany: true,
      displayOrder: 0,
      isSuggestedPeerCompany: false,
      company: insightsState.companyFilter[0],
    };
    if (!globalBaseCompany) {
      insightsCompanies.push(baseCompany);
    }

    insightsState.peerCompanyFilter.forEach((company) => {
      let peerCompany: any = {
        cikNumber: company.cikNumber,
        globalCompanyId: company.globalCompanyId,
        isBaseCompany: false,
        displayOrder:
          peerDisplayOrderValues.indexOf(company.displayOrder) + 1 ??
          ++displayOrder,
        isSuggestedPeerCompany:
          company.isSuggestedPeerCompany === true ? true : false,
        company: company,
      };
      insightsCompanies.push(peerCompany);
    });
    // Setting up metrics payload
    let insightsCategories: {
      insightsCategoryId: number;
      insightsCategoryName: string;
      displayOrder: number;
      benchmarks?: any[];
    }[] = [];

    let globalCompanyMappings: {
      globalCompanyMappingId: number;
      isBaseMapping: boolean;
    }[] =
      insightsState.globalCompanyMappingsFilter &&
      insightsState.globalCompanyMappingsFilter.length
        ? insightsState.globalCompanyMappingsFilter.map(
            (globalComp: Partial<GlobalCompanyGroup>) => ({
              globalCompanyMappingId: globalComp.globalCompanyMappingId ?? 1,
              isBaseMapping: globalComp.isBaseMapping ?? false,
              displayOrder: globalComp.isBaseMapping
                ? 0
                : peerDisplayOrderValues.indexOf(globalComp.displayOrder) + 1 ??
                  ++displayOrder,
            })
          )
        : [];

    if (edit) {
      insightsCategories.push({
        insightsCategoryId:
          insightsState.selectedCategoryIds[0]?.insightsCategoryId,
        insightsCategoryName:
          insightsState.selectedCategoryIds[0]?.insightsCategoryName,
        displayOrder: ++displayOrder,
        benchmarks: handleApplyEditDashboard(),
      });
    } else {
      insightsCategories.push({
        insightsCategoryId:
          insightsState.selectedCategoryIds[0]?.insightsCategoryId,
        insightsCategoryName:
          insightsState.selectedCategoryIds[0]?.insightsCategoryName,
        displayOrder: ++displayOrder,
      });
    }

    if (hideTilesIfSelectedNonSECBaseline) {
      let filteredLinkBenchmark =
        insightsState.currentSavedInsightDashboard.insightsCategories[0].benchmarks.filter(
          (benchmark) =>
            benchmark.benchmarkTileType === BENCHMARK_TILE_TYPES.LINKS
        )[0];
      //check implemented for the case if benchmarks does not have the links tile
      if (filteredLinkBenchmark !== undefined) {
        insightsCategories[0]?.benchmarks?.push({
          benchmarkId: filteredLinkBenchmark.benchmarkId,
          benchmarkTitle: filteredLinkBenchmark.benchmarkTitle,
          displayOrder: filteredLinkBenchmark.displayOrder,
          isHidden: true,
          predefinedBenchmarkId: 0,
        });
      }
    }

    let payload: any = {
      insightsName: trimStartFunc(dashboardName),
      insightsCompanies,
      insightsCategories,
      globalCompanyMappings,
    };

    if (edit) {
      payload.insightsId =
        insightsState.currentSavedInsightDashboard.insightsId;
      dispatch(editInsights(payload));
      dispatch(insightsEditInsightTrack(true));
    } else {
      dispatch(
        insightsCreateInsightTrack({
          dashboardType:
            insightsState.selectedCategoryIds[0]?.insightsCategoryName,
        })
      );
      dispatch(createInsights(payload));
    }
  };

  useEffect(() => {
    if (insightsState.insightPeerGroupSuccess) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id={"save.peer.group.success"} />,
        })
      );
      dispatch(setPeerGroupSavedSuccess(false));
      dispatch(loadSavedPeerGroupList(DEFAULT_PEER_GROUP_LIST_PAYLOAD));
      setPeerGroupsLoaded(false);
      setSavedPeers(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.insightPeerGroupSuccess]);

  useEffect(() => {
    if (editedVisualizationsState.length > 0) {
      const hasNonSECBaseline =
        insightsState.currentSavedInsightDashboard?.globalCompanyMappings?.filter(
          (industry) => industry.isBaseMapping
        ).length === 1
          ? true
          : false;

      if (hasNonSECBaseline) {
        setHideTilesIfSelectedNonSECBaseline(true);
      }
    }
  }, [editedVisualizationsState]);

  const SavePeerGroupHandler = (peerGroupName: string) => {
    let displayOrder = 1;
    let baselineGlobalCompany = insightsState.globalCompanyMappingsFilter.find(
      (g: Partial<GlobalCompanyGroup>) => g.isBaseMapping
    );
    let baselineCompany: baselineCompanyForPeerGroupPayload = {
      cikNumber: insightsState.companyFilter[0]
        ? insightsState.companyFilter[0].cikNumber
        : "",
      company: insightsState.companyFilter[0] ?? "",
    };

    let companyGroupCompanies: peerGroupCompanies[] = [];

    selectedPeerCompany.forEach((company) => {
      let peerCompany: any = {
        cikNumber: company.cikNumber,
        displayOrder: company.displayOrder ?? displayOrder++,
        isSuggestedPeerCompany:
          company.isSuggestedPeerCompany === true ? true : false,
        company: company,
        globalCompanyId: company.globalCompanyId,
      };
      companyGroupCompanies.push(peerCompany);
    });

    let payload: savePeerGroupNamePayload = {
      companyGroupName: peerGroupName,
      companyGroupCompanies,
      globalCompanyMappings: selectedGlobalPeerCompanies.length
        ? selectedGlobalPeerCompanies.map((g: Partial<GlobalCompanyGroup>) => ({
            globalCompanyMappingId: g.globalCompanyMappingId ?? 0,
            isBaseMapping: g.isBaseMapping,
          }))
        : [],
    };

    if (!baselineGlobalCompany)
      payload = {
        ...payload,
        baselineCompanyCikNumber: insightsState.companyFilter[0]
          ? insightsState.companyFilter[0].cikNumber
          : "",
        baselineCompany,
      };
    else {
      if (payload.globalCompanyMappings)
        payload.globalCompanyMappings.splice(0, 0, {
          globalCompanyMappingId: baselineGlobalCompany?.globalCompanyMappingId,
          isBaseMapping: baselineGlobalCompany.isBaseMapping,
        });
      else
        payload.globalCompanyMappings = [
          {
            globalCompanyMappingId:
              baselineGlobalCompany?.globalCompanyMappingId,
            isBaseMapping: baselineGlobalCompany.isBaseMapping,
          },
        ];
    }
    dispatch(savePeerGroup(payload, PEER_GROUP_CREATION_PAGE.INSIGHT));
  };

  const debounceChangeHandler = useMemo(() => {
    return debounce((e: any, type: any) => {
      if (type === "baseline") {
        dispatch(getGlobalCompanyFilter(e.target.value));
      } else {
        dispatch(getGlobalPeerCompanyFilter(e.target.value));
      }
    }, 300);
  }, [dispatch]);

  const dropdownHeadersForSavePeerModal: {
    label: string;
    placeholder: string;
    values: CompanyFilter[];
    options: CompanyFilter[];
    loading: FILTER_OPTIONS[];
    textValue: string;
    type: string;
    limit?: string;
    showOnEmpty?: boolean;
  }[] = [
    {
      label: "peer.group.add.more",
      placeholder: "filter.company.placeholder",
      values: selectedPeerCompany,
      options:
        insightsState.companyFilter.length > 0
          ? insightsState.peerCompanyFilterOptions?.filter(
              (peerCompany: CompanyFilter) =>
                peerCompany.globalCompanyId !==
                insightsState.companyFilter[0].globalCompanyId
            )
          : insightsState.peerCompanyFilterOptions,
      loading: insightsState.loadingFilter,
      textValue: "",
      type: "peers",
      limit: commonsState.insightsPeerCompanyLimit?.InsightsPeerCompaniesLimit,
      showOnEmpty: false,
    },
  ];

  const getLabelWrapper = (label: string, type: string) => {
    return (
      <div className={`${BLOCK}__dropdown-label-wrapper`}>
        <div className={`${BLOCK}__dropdown-label`}>
          <FormattedMessage id={label} />
        </div>
        <Button
          className={classNames(`${BLOCK}__non-sec-btn`)}
          formattedText="non.sec.filer.company"
          onClick={() => {
            if (type === COMPANY_TYPE.BASELINE) {
              setSelectionType(COMPANY_TYPE.BASELINE);
              if (
                insightsState.companyFilter.length > 0 &&
                insightsState.companyFilter[0].countryCode === COUNTRY_CODE.US
              ) {
                setShowConfirmationModal(true);
              } else {
                setShowGlobalSearch(true);
              }
            } else {
              setShowGlobalSearch(true);
              setSelectionType(COMPANY_TYPE.PEER);
            }
          }}
          data-test="non-filer-btn"
          iconName={"external-link"}
          iconWidth={24}
          iconHeight={24}
        />
      </div>
    );
  };

  const dropdownHeaders: {
    label: string;
    placeholder: string;
    values: CompanyFilter[];
    options: CompanyFilter[];
    loading: FILTER_OPTIONS[];
    textValue: string;
    type: string;
    limit?: string;
    showOnEmpty?: boolean;
    additionalText?: string;
    labelWrapper?: any;
    count?: number;
    additionalContent?: any;
    disabled?: boolean;
    beforeSelectBaselineCompany?: any;
    changeNonSecCompany?: boolean;
    localSelectedBaseline?: CompanyFilter | null;
    setLocalSelectedBaseline?: any;
  }[] = edit
    ? [
        {
          label: "select.insights.peers",
          placeholder: "filter.company.peerplaceholder",
          values: insightsState.peerCompanyFilter,
          options:
            insightsState.companyFilter.length > 0
              ? insightsState.peerCompanyFilterOptions?.filter(
                  (peerCompany: CompanyFilter) =>
                    peerCompany.globalCompanyId !==
                    insightsState.companyFilter[0].globalCompanyId
                )
              : insightsState.peerCompanyFilterOptions,
          loading: insightsState.loadingFilter,
          textValue: "",
          type: "peers",
          limit:
            commonsState.insightsPeerCompanyLimit?.InsightsPeerCompaniesLimit,
          showOnEmpty: false,
          labelWrapper: getLabelWrapper(
            "select.insights.peers",
            COMPANY_TYPE.PEER
          ),
          count:
            insightsState.peerCompanyFilter.length +
            insightsState.globalCompanyMappingsFilter.filter(
              (gcm: Partial<GlobalCompanyGroup>) => !gcm.isBaseMapping
            ).length,
          disabled:
            getTotalPeersAdded() >=
            (isNumeric(
              commonsState.insightsPeerCompanyLimit?.InsightsPeerCompaniesLimit
            )
              ? parseInt(
                  commonsState.insightsPeerCompanyLimit
                    ?.InsightsPeerCompaniesLimit
                )
              : COMPANY_LIMIT.PEER),
          additionalContent: (
            <div className={`${BLOCK}__label`} data-test="drop-down">
              {getTotalPeersAdded() <
              (isNumeric(
                commonsState.insightsPeerCompanyLimit
                  ?.InsightsPeerCompaniesLimit
              )
                ? parseInt(
                    commonsState.insightsPeerCompanyLimit
                      ?.InsightsPeerCompaniesLimit
                  )
                : COMPANY_LIMIT.PEER) ? (
                <div
                  className={`global-search-modal__dropdown-search-box-limit-legend`}
                >
                  <FormattedMessage
                    id={"insight.search.peers.qty.msg"}
                    values={{
                      qty:
                        insightsState.peerCompanyFilter.length +
                        insightsState.globalCompanyMappingsFilter.filter(
                          (gcm: Partial<GlobalCompanyGroup>) =>
                            !gcm.isBaseMapping
                        ).length,
                      limit:
                        commonsState.insightsPeerCompanyLimit
                          ?.InsightsPeerCompaniesLimit &&
                        isNumeric(
                          commonsState.insightsPeerCompanyLimit
                            ?.InsightsPeerCompaniesLimit
                        )
                          ? parseInt(
                              commonsState.insightsPeerCompanyLimit
                                ?.InsightsPeerCompaniesLimit
                            )
                          : COMPANY_LIMIT.PEER,
                    }}
                  />
                </div>
              ) : (
                <div
                  className={classNames(`global-search-modal__warning-text`, {
                    [`global-search-modal__error-text`]:
                      getTotalPeersAdded() > 8,
                  })}
                >
                  <Icon
                    name={
                      getTotalPeersAdded() > 8
                        ? "error"
                        : "warning-circle-yellow"
                    }
                    width={24}
                    height={24}
                  />

                  <FormattedMessage
                    id={
                      getTotalPeersAdded() > 8
                        ? "global.search.peers.error.msg"
                        : "global.search.peers.warning.msg"
                    }
                    values={{
                      limit:
                        commonsState.insightsPeerCompanyLimit
                          ?.InsightsPeerCompaniesLimit &&
                        isNumeric(
                          commonsState.insightsPeerCompanyLimit
                            ?.InsightsPeerCompaniesLimit
                        )
                          ? parseInt(
                              commonsState.insightsPeerCompanyLimit
                                ?.InsightsPeerCompaniesLimit
                            )
                          : COMPANY_LIMIT.PEER,
                    }}
                  />
                </div>
              )}
            </div>
          ),
        },
      ]
    : [
        {
          label: "select.benchmark.company",
          placeholder: "filter.company.placeholder",
          values: insightsState.companyFilter,
          options: insightsState.companyFilterOptions,
          loading: insightsState.loadingFilter,
          textValue: insightsState.companyFilter[0]?.companyName
            ? insightsState.companyFilter[0].companyName
            : "",
          type: "baseline",
          showOnEmpty: false,
          labelWrapper: edit
            ? null
            : getLabelWrapper(
                "select.benchmark.company",
                COMPANY_TYPE.BASELINE
              ),
          beforeSelectBaselineCompany: () => {
            setShowConfirmationModal(true);
            setIsConfirmationModalTriggerByDropdown(true);
          },
          changeNonSecCompany: changeNonSecCompany,
          localSelectedBaseline: localSelectedBaseline,
          setLocalSelectedBaseline: setLocalSelectedBaseline,
        },
        {
          label: "select.insights.peers",
          placeholder: "filter.company.peerplaceholder",
          values: insightsState.peerCompanyFilter,
          options:
            insightsState.companyFilter.length > 0
              ? insightsState.peerCompanyFilterOptions?.filter(
                  (peerCompany: CompanyFilter) =>
                    peerCompany.globalCompanyId !==
                    insightsState.companyFilter[0].globalCompanyId
                )
              : insightsState.peerCompanyFilterOptions,
          loading: insightsState.loadingFilter,
          textValue: "",
          type: "peers",
          limit:
            commonsState.insightsPeerCompanyLimit?.InsightsPeerCompaniesLimit,
          showOnEmpty: false,
          labelWrapper: edit
            ? null
            : getLabelWrapper("select.insights.peers", COMPANY_TYPE.PEER),
          count:
            insightsState.peerCompanyFilter.length +
            insightsState.globalCompanyMappingsFilter.filter(
              (gcm: Partial<GlobalCompanyGroup>) => !gcm.isBaseMapping
            ).length,
          disabled:
            getTotalPeersAdded() >=
            (isNumeric(
              commonsState.insightsPeerCompanyLimit?.InsightsPeerCompaniesLimit
            )
              ? parseInt(
                  commonsState.insightsPeerCompanyLimit
                    ?.InsightsPeerCompaniesLimit
                )
              : COMPANY_LIMIT.PEER),
          additionalContent: (
            <div className={`${BLOCK}__label`} data-test="drop-down">
              {getTotalPeersAdded() <
              (isNumeric(
                commonsState.insightsPeerCompanyLimit
                  ?.InsightsPeerCompaniesLimit
              )
                ? parseInt(
                    commonsState.insightsPeerCompanyLimit
                      ?.InsightsPeerCompaniesLimit
                  )
                : COMPANY_LIMIT.PEER) ? (
                <div
                  className={`global-search-modal__dropdown-search-box-limit-legend`}
                >
                  <FormattedMessage
                    id={"insight.search.peers.qty.msg"}
                    values={{
                      qty:
                        insightsState.peerCompanyFilter.length +
                        insightsState.globalCompanyMappingsFilter.filter(
                          (gcm: Partial<GlobalCompanyGroup>) =>
                            !gcm.isBaseMapping
                        ).length,
                      limit:
                        commonsState.insightsPeerCompanyLimit
                          ?.InsightsPeerCompaniesLimit &&
                        isNumeric(
                          commonsState.insightsPeerCompanyLimit
                            ?.InsightsPeerCompaniesLimit
                        )
                          ? parseInt(
                              commonsState.insightsPeerCompanyLimit
                                ?.InsightsPeerCompaniesLimit
                            )
                          : COMPANY_LIMIT.PEER,
                    }}
                  />
                </div>
              ) : (
                <div
                  className={classNames(`global-search-modal__warning-text`, {
                    [`global-search-modal__error-text`]:
                      getTotalPeersAdded() > 8,
                  })}
                >
                  <Icon
                    name={
                      getTotalPeersAdded() > 8
                        ? "error"
                        : "warning-circle-yellow"
                    }
                    width={24}
                    height={24}
                  />
                  <FormattedMessage
                    id={
                      getTotalPeersAdded() > 8
                        ? "global.search.peers.error.msg"
                        : "global.search.peers.warning.msg"
                    }
                    values={{
                      limit:
                        commonsState.insightsPeerCompanyLimit
                          ?.InsightsPeerCompaniesLimit &&
                        isNumeric(
                          commonsState.insightsPeerCompanyLimit
                            ?.InsightsPeerCompaniesLimit
                        )
                          ? parseInt(
                              commonsState.insightsPeerCompanyLimit
                                ?.InsightsPeerCompaniesLimit
                            )
                          : COMPANY_LIMIT.PEER,
                    }}
                  />
                </div>
              )}
            </div>
          ),
        },
      ];

  const selectCompany = (
    companyFilter: CompanyFilter,
    type: any,
    fromGlobalSearch: boolean = false
  ) => {
    if (type === "peers") {
      let tmpPeerOrder = peerOrder;
      dispatch(
        setPeerCompanyFilter([
          ...insightsState.peerCompanyFilter,
          { ...companyFilter, displayOrder: tmpPeerOrder++ },
        ])
      );
      setPeerOrder(tmpPeerOrder);
      dispatch(
        updateSelectedItems([
          ...insightsState.selectedItems,
          companyFilter.globalCompanyId,
        ])
      );
    } else {
      dispatch(setCompanyFilter([companyFilter]));
      setChangeNonSecCompany(false);
      if (!fromGlobalSearch) {
        dispatch(loadSuggestedPeerCompanyFilter(companyFilter.cikNumber));
        dispatch(
          setGlobalMappingFilter(
            insightsState.globalCompanyMappingsFilter.filter(
              (item: Partial<GlobalCompanyGroup>) => !item.isBaseMapping
            )
          )
        );
      }
    }
  };

  const selectDashboardType = (data: InsightCategory) => {
    dispatch(setSelectedCategoryItemIds([data]));
  };

  const unselectDashboardType = () => {
    dispatch(setSelectedCategoryItemIds([]));
  };

  const handleUnselectCompany = (
    companyFilter: CompanyFilter,
    type: string
  ) => {
    if (type === "peers") {
      dispatch(
        setPeerCompanyFilter(
          insightsState.peerCompanyFilter.filter(
            ({ globalCompanyId }) =>
              globalCompanyId !== companyFilter.globalCompanyId
          )
        )
      );
      dispatch(
        updateSelectedItems(
          insightsState.selectedItems.filter(
            (item: number) => item !== companyFilter.globalCompanyId
          )
        )
      );
    } else {
      dispatch(
        setGlobalMappingFilter(
          insightsState.globalCompanyMappingsFilter.filter(
            (item: Partial<GlobalCompanyGroup>) => !item.isBaseMapping
          )
        )
      );

      dispatch(setBaselineCompanyFilter([]));
    }
  };

  const unselectCompany = (companyFilter: CompanyFilter, type: string) => {
    dispatch(
      setPeerCompanyFilter(
        insightsState.peerCompanyFilter.filter(
          ({ globalCompanyId }) =>
            globalCompanyId !== companyFilter.globalCompanyId
        )
      )
    );

    dispatch(
      loadPeerCompanies(
        insightsState.peerCompanyFilterOptions.filter(
          ({ globalCompanyId }) =>
            globalCompanyId !== companyFilter.globalCompanyId
        )
      )
    );
    dispatch(
      updateSelectedItems(
        insightsState.selectedItems.filter(
          (item: number) => item !== companyFilter.globalCompanyId
        )
      )
    );

    if (insightsState.selectedPeerGroupItems.length > 0) {
      let tmpSelectedCheckboxPeerGroup = insightsState.savedPeerGroupList
        .filter((pg: PeerGroup) =>
          insightsState.selectedPeerGroupItems.includes(pg.companyGroupId)
        )
        .filter((pg: PeerGroup) =>
          pg.companyGroupCompanies.every(
            (cgc: CompanyGroupCompany) =>
              cgc.globalCompanyId !== companyFilter.globalCompanyId
          )
        )
        .map((pg: PeerGroup) => pg.companyGroupId);
      setSelectedCheckboxPeerGroup(tmpSelectedCheckboxPeerGroup);
      dispatch(updateSelectedPeerGroupItems(tmpSelectedCheckboxPeerGroup));
    }
  };

  const unselectGlobalCompany = (
    globalCompanyGroup: GlobalCompanyGroup,
    type: string
  ) => {
    if (
      insightsState.globalCompanyMappingsFilter &&
      insightsState.globalCompanyMappingsFilter.find(
        (g: Partial<GlobalCompanyGroup>) =>
          g.globalCompanyMappingId ===
            globalCompanyGroup.globalCompanyMappingId && g.isBaseMapping
      )
    )
      dispatch(setBaselineCompanyFilter([]));

    dispatch(
      setGlobalMappingFilter(
        insightsState.globalCompanyMappingsFilter.filter(
          (item: Partial<GlobalCompanyGroup>) =>
            item.globalCompanyMappingId !==
            globalCompanyGroup.globalCompanyMappingId
        )
      )
    );

    if (insightsState.selectedPeerGroupItems.length > 0) {
      let tmpSelectedCheckboxPeerGroup = insightsState.savedPeerGroupList
        .filter((pg: PeerGroup) =>
          insightsState.selectedPeerGroupItems.includes(pg.companyGroupId)
        )
        .filter(
          (pg: PeerGroup) =>
            pg.globalCompanyMappings &&
            pg.globalCompanyMappings.every(
              (gc: Partial<GlobalCompanyGroup>) =>
                gc.globalCompanyMappingId !==
                globalCompanyGroup.globalCompanyMappingId
            )
        )
        .map((pg: PeerGroup) => pg.companyGroupId);
      setSelectedCheckboxPeerGroup(tmpSelectedCheckboxPeerGroup);
      dispatch(updateSelectedPeerGroupItems(tmpSelectedCheckboxPeerGroup));
    }
  };

  const unSelectCompanyFromFilter = (pcikNumberCompanies: number[] = []) => {
    dispatch(
      setPeerCompanyFilter(
        insightsState.peerCompanyFilter.filter(
          ({ globalCompanyId }) =>
            !pcikNumberCompanies.includes(globalCompanyId ?? 0)
        )
      )
    );

    dispatch(
      loadPeerCompanies(
        insightsState.peerCompanyFilterOptions.filter(
          ({ globalCompanyId }) =>
            !pcikNumberCompanies.includes(globalCompanyId ?? 0)
        )
      )
    );
  };

  const companiesUnselectedHandler = (punseletedCompanies: number[]) => {
    let tmpCompaniesUnselected = [...companiesUnselected].concat(
      punseletedCompanies.filter(
        (comp: number) => !companiesUnselected.includes(comp)
      )
    );

    setCompaniesUnselected(tmpCompaniesUnselected);
  };

  const addSelected = (
    selectedChekbox: number[],
    selectedPeerGroups: number[]
  ) => {
    let tmpIsBaseCompanySuggested = false;
    let peerCompanies: CompanyFilter[] = [...insightsState.peerCompanyFilter];
    let tmpGlobalCompanyMappings: Partial<GlobalCompanyGroup>[] = [
      ...insightsState.globalCompanyMappingsFilter,
    ];
    peerCompanies = peerCompanies.filter(
      (pcf: CompanyFilter) =>
        !companiesUnselected.includes(pcf.globalCompanyId ?? 0)
    );

    for (let i = 0; i < insightsState.suggestedPeerCompanyFilter?.length; i++) {
      const { globalCompanyId } = insightsState.suggestedPeerCompanyFilter[i];
      insightsState.suggestedPeerCompanyFilter[i].isSuggestedPeerCompany = true;
      if (
        !selectedChekbox.includes(globalCompanyId ?? 0) &&
        !!peerCompanies.find(
          (peerComp) => peerComp.globalCompanyId === globalCompanyId
        )
      ) {
        peerCompanies = peerCompanies.filter(
          (peer) => peer.globalCompanyId !== globalCompanyId
        );
      }
    }
    let tmpPeerOrder = peerOrder;
    selectedChekbox.forEach((globalCompanyId) => {
      if (
        !peerCompanies.find(
          (peerComp) => peerComp.globalCompanyId === globalCompanyId
        )
      ) {
        const company = insightsState.suggestedPeerCompanyFilter.find(
          (peerComp) => peerComp.globalCompanyId === globalCompanyId
        );
        if (company) {
          company.isSuggestedPeerCompany = true;
          peerCompanies.push({ ...company, displayOrder: tmpPeerOrder++ });
        }
      }
    });
    if (selectedPeerGroups && selectedPeerGroups.length) {
      setSelectedCheckboxPeerGroup(selectedPeerGroups);
      let tmpPeerGroupSelection = insightsState.savedPeerGroupList.filter(
        (group: PeerGroup) => selectedPeerGroups.includes(group.companyGroupId)
      );
      tmpPeerGroupSelection.forEach((pg: PeerGroup) => {
        pg.companyGroupCompanies.forEach(
          (companyGroup: CompanyGroupCompany) => {
            if (
              !peerCompanies.find(
                (companyFilter: CompanyFilter) =>
                  companyFilter.globalCompanyId === companyGroup.globalCompanyId
              ) &&
              companyGroup.globalCompanyId !==
                insightsState.companyFilter[0].globalCompanyId
            ) {
              //We will change company -> globalCompany once backend is ready with the change
              peerCompanies.push({
                companyName: companyGroup.company!.companyName,
                companyId: companyGroup?.company!.companyId,
                externalCompanyId: companyGroup?.company?.externalCompanyId,
                cikNumber: companyGroup.cikNumber,
                ticker: companyGroup.company?.ticker,
                isSuggestedPeerCompany: true,
                globalCompanyId: companyGroup.company?.globalCompanyId,
                displayOrder: tmpPeerOrder++,
              });
            }
            if (
              companyGroup.globalCompanyId ===
              insightsState.companyFilter[0].globalCompanyId
            ) {
              tmpIsBaseCompanySuggested = true;
            }
          }
        );
        if (pg.globalCompanyMappings) {
          pg.globalCompanyMappings
            .filter((g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping)
            .forEach((g: Partial<GlobalCompanyGroup>) => {
              if (
                !isAnyGlobalCompanyInGlobalGroups(
                  tmpGlobalCompanyMappings,
                  g
                ) ||
                tmpGlobalCompanyMappings.length === 0
              ) {
                tmpGlobalCompanyMappings.push({
                  ...g,
                  displayOrder: tmpPeerOrder++,
                });
              }
            });
        }
      });
    }
    if (isBaseCompanySuggested !== tmpIsBaseCompanySuggested)
      setIsBaseCompanySuggested(tmpIsBaseCompanySuggested);
    dispatch(setPeerCompanyFilter(peerCompanies));
    if (tmpGlobalCompanyMappings.length)
      dispatch(setGlobalCompanyMappingsFilter(tmpGlobalCompanyMappings));
    setSuggestedPeers(false);

    if (tmpPeerOrder > peerOrder) setPeerOrder(tmpPeerOrder);
  };

  const handleConfirmation = () => {
    if (!isConfirmationModalTriggerByDropdown) {
      setShowGlobalSearch(true);
    } else {
      setChangeNonSecCompany(true);
    }
    setShowConfirmationModal(false);
    setIsConfirmationModalTriggerByDropdown(false);
  };

  const getCustomConfirmationHeader = () => (
    <span className={`${BLOCK}__confirmation-header`}>
      <Icon name="warning-circle-yellow" width={24} height={24} />
      <FormattedMessage id="non.sec.confirmation.header" />
    </span>
  );

  const getCustomConfirmationContent = () => (
    <span className={`${BLOCK}__confirmation-content`}>
      <FormattedMessage
        id={
          insightsState.companyFilter.length > 0 &&
          insightsState.companyFilter[0].countryCode === COUNTRY_CODE.US
            ? "sec.confirmation.msg"
            : "non.sec.confirmation.msg"
        }
      />
    </span>
  );

  return (
    <>
      <SidePanel
        showPanel={true}
        headerText={`${edit ? "insights.edit" : "insights.create.new"}`}
        handleClose={() => {
          dispatch(showCreateNewTab(false));
          dispatch(showEditTab(false));
          setCreateNewTab(false);
        }}
        dataTest="create-new-panel"
      >
        <>
          <div className={`${BLOCK}__body`}>
            <div className={`${BLOCK}__dashboard-name`}>
              <div className={`${BLOCK}__dashboard-name--label`}>
                <FormattedMessage id="dashboard.name" />
              </div>
              <div className={`${BLOCK}__dashboard-name--input`}>
                <input
                  type="text"
                  maxLength={200}
                  onChange={onChangeHandler}
                  value={dashboardName}
                  name="dashboardName"
                  placeholder="Enter insight name"
                  autoComplete="off"
                  data-testid="Insight-name"
                />
              </div>
              {duplicateName && (
                <span
                  className={`${BLOCK}__contentError`}
                  data-testid="duplicate-name-error"
                >
                  <FormattedMessage id="duplicate.insight.name.error" />
                </span>
              )}
            </div>

            {!insightsState.loading && (
              <div key={`dropdown--insights.select.categories`}>
                <DashboardType
                  className={`${BLOCK}__dropdown`}
                  label={
                    <div className={`${BLOCK}__dropdown-label`}>
                      <FormattedMessage id={"select.dashboard.type"} />
                    </div>
                  }
                  placeholder={
                    <FormattedMessage id={"select.dashboard.type"} />
                  }
                  objectKeys={{
                    name: "insightsCategoryName",
                    id: "insightsCategoryId",
                  }}
                  values={insightsState.selectedCategoryIds}
                  options={insightsState.categoryData}
                  handleChange={debounceChangeHandler}
                  loading={false}
                  handleSelect={selectDashboardType}
                  handleUnselect={unselectDashboardType}
                  key={`dropdown--insights.select.categories1`}
                  type={!edit ? "dashboardType" : "edit"}
                />
              </div>
            )}
            {edit && (
              <>
                <div
                  className={`${BLOCK}__dashboard-name`}
                  data-testid="baseline-company"
                >
                  <div className={`${BLOCK}__dropdown-label-wrapper`}>
                    <div className={`${BLOCK}__dropdown-label`}>
                      <FormattedMessage id={"select.benchmark.company"} />
                    </div>
                    <Button
                      className={classNames(
                        `${BLOCK}__suggested-peers--disabled`
                      )}
                      formattedText="non.sec.filer.company"
                      dataTest="non-filer-btn"
                      iconName={"external-link"}
                      iconWidth={24}
                      iconHeight={24}
                      disabled={true}
                    />
                  </div>
                  <div className={`${BLOCK}__dashboard-name--inputDisabled`}>
                    <input
                      type="text"
                      value={
                        insightsState.companyFilter[0]?.companyName
                          ? insightsState.companyFilter[0]?.companyName
                          : ""
                      }
                      name="baseline"
                      disabled={true}
                    />
                  </div>
                </div>
              </>
            )}
            <div
              className={`${BLOCK}__dropdown`}
              data-test="benchmark-baseline-company-dropdown"
              data-testid="benchmark-baseline-company-dropdown"
            >
              <DropdownContainer
                BLOCK={BLOCK}
                dropdownLabel={dropdownHeaders}
                debounceChangeHandler={debounceChangeHandler}
                selectCompany={selectCompany}
                unselectCompany={handleUnselectCompany}
                fromInsight={true}
              />
            </div>
            <div className={`${BLOCK}__suggested-peers-section`}>
              <button
                className={classNames(`${BLOCK}__suggested-peers`, {
                  [`${BLOCK}__suggested-peers--disabled`]:
                    insightsState.companyFilter.length === 0 ||
                    (insightsState.companyFilter.length > 0 &&
                      insightsState.globalCompanyMappingsFilter.find(
                        (gpm: Partial<GlobalCompanyGroup>) => gpm.isBaseMapping
                      )),
                })}
                onClick={() => {
                  if (
                    insightsState.companyFilter.length > 0 &&
                    !insightsState.globalCompanyMappingsFilter.find(
                      (gpm: Partial<GlobalCompanyGroup>) => gpm.isBaseMapping
                    )
                  ) {
                    setCompaniesUnselected([]);
                    dispatch(loadPeerCompanies([]));
                    setSuggestedPeers(true);
                  }
                }}
                data-testid="suggested-peers-link"
              >
                <Icon
                  name="external-link"
                  height={24}
                  width={24}
                  className="icon"
                  data-testid={
                    insightsState.companyFilter.length > 0 &&
                    !insightsState.companyFilter[0].fromGlobalSearch
                      ? "benchmark-suggested-peers-link-enabled"
                      : "benchmark-suggested-peers-link-disabled"
                  }
                />
                <FormattedMessage id="suggested.peers" />
              </button>
            </div>
            <div className={`${BLOCK}__peerCompanies-container`}>
              <PeerCompanies
                BLOCK={BLOCK}
                peerCompanies={[]}
                globalPeerCompanies={[]}
                unselectCompany={unselectCompany}
                unselectGlobalCompanyMapping={unselectGlobalCompany}
                hybridCompanies={insightsState.peerCompanyFilter
                  .map(
                    (
                      cf: CompanyFilter,
                      cfIndex: number
                    ): Partial<HybridCompany> => ({
                      displayOrder: cf.displayOrder ?? cfIndex,
                      company: cf,
                    })
                  )
                  .concat(
                    insightsState.globalCompanyMappingsFilter
                      .filter(
                        (gc: Partial<GlobalCompanyGroup>) => !gc.isBaseMapping
                      )
                      .map(
                        (
                          gc: Partial<GlobalCompanyGroup>,
                          gcIndex: number
                        ): Partial<HybridCompany> => ({
                          displayOrder: gc.displayOrder ?? gcIndex,
                          globalCompanyGroup: gc,
                        })
                      )
                  )}
              />
            </div>
            <div className={`${BLOCK}__suggested-peers-section`}>
              <button
                className={classNames(`${BLOCK}__suggested-peers`, {
                  [`${BLOCK}__suggested-peers--disabled`]:
                    insightsState.peerCompanyFilter.length === 0 &&
                    insightsState.globalCompanyMappingsFilter.filter(
                      (g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping
                    ).length === 0,
                })}
                onClick={() => {
                  if (
                    insightsState.peerCompanyFilter.length > 0 ||
                    insightsState.globalCompanyMappingsFilter.filter(
                      (g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping
                    ).length > 0
                  ) {
                    setSavedPeers(true);
                  }
                }}
                data-testid="suggested-peers-link"
                data-test="suggested-peers-link"
              >
                <Icon
                  name="plus24"
                  height={24}
                  width={24}
                  className="icon"
                  data-testid={
                    insightsState.peerCompanyFilter.length > 0
                      ? "benchmark-suggested-peers-link-enabled"
                      : "benchmark-suggested-peers-link-disabled"
                  }
                />

                <FormattedMessage id="save.peer.group" />
              </button>
            </div>

            {edit && (
              <ShowHideTile
                editedVisualizationsState={
                  hideTilesIfSelectedNonSECBaseline
                    ? editedVisualizationsState.filter(
                        (visualization: Visualization) =>
                          visualization.benchmarkTileType !==
                          BENCHMARK_TILE_TYPES.LINKS
                      )
                    : editedVisualizationsState
                }
                setEditedVisualizationsState={setEditedVisualizationsState}
              />
            )}
            {commonsState.insightsPageDisclaimer && !edit && (
              <div className={`${BLOCK}__disclaimer`}>
                <p className={`${BLOCK}__disclaimer-text`}>
                  {Parser(
                    commonsState.insightsPageDisclaimer.InsightsPageDisclaimer
                  )}
                </p>
              </div>
            )}
          </div>
          <div data-testid="side-custom-benchmark-panel-buttons">
            <ActionContainer
              disabled={{
                // Both the buttons are dependent on the same conditions
                actionButtonOneState: disabledButtonOne,
                actionButtonTwoState: disbaledButtonTwo,
              }}
              actionButtonOne={{
                handleActionButton: !edit
                  ? resetBenchmarkHandler
                  : resetEditBenchmarkHandler,
                actionButtonText: "custom.benchmark.actions.reset",
                actionButtonType: "button",
              }}
              actionButtonTwo={
                !edit
                  ? {
                      handleActionButton: applyBenchmarkHandler,
                      actionButtonText: "insight.action.create",
                      actionButtonType: "primary",
                    }
                  : {
                      handleActionButton: applyBenchmarkHandler,
                      actionButtonText: "apply",
                      actionButtonType: "primary",
                    }
              }
            />
          </div>
          {suggestedPeers && (
            <SuggestedPeers
              BLOCK={BLOCK}
              setShowModal={setSuggestedPeers}
              addSelected={addSelected}
              selectedItems={insightsState.selectedItems}
              dispatchFunction={updateSelectedItems}
              dispatchFunctionPeerGroups={updateSelectedPeerGroupItems}
              suggestedPeerCompanies={insightsState.suggestedPeerCompanyFilter}
              selectedBaselineComp={{
                companyName: insightsState.companyFilter[0].companyName
                  ? insightsState.companyFilter[0].companyName
                  : "",
                cikNumber: insightsState.companyFilter[0].cikNumber
                  ? insightsState.companyFilter[0].cikNumber
                  : 0,
                companyId: insightsState.companyFilter[0].companyId
                  ? insightsState.companyFilter[0].companyId
                  : 0,
                countryCode: insightsState.companyFilter[0].companyId
                  ? insightsState.companyFilter[0].countryCode
                  : undefined,
                globalCompanyMapping:
                  insightsState.globalCompanyMappingsFilter.find(
                    (gm: Partial<GlobalCompanyGroup>) => gm.isBaseMapping
                  ),
              }}
              selectedCheckboxPeerGroupItems={
                insightsState.selectedPeerGroupItems
              }
              peerGroups={insightsState.savedPeerGroupList}
              page={PEER_GROUP_CREATION_PAGE.INSIGHT}
              unSelectCompanyFromFilter={unSelectCompanyFromFilter}
              setCompaniesUnselected={companiesUnselectedHandler}
              identifier={COMPANY_IDENTIFIER.GLOBAL_COMPANY_ID}
              debounceFunction={debounceChangeHandler}
              companyFilterOptions={
                insightsState.companyFilter.length > 0
                  ? insightsState.peerCompanyFilterOptions?.filter(
                      (peerCompany: CompanyFilter) =>
                        peerCompany.globalCompanyId !==
                        insightsState.companyFilter[0].globalCompanyId
                    )
                  : insightsState.peerCompanyFilterOptions
              }
            />
          )}
          {savedPeers && (
            <SavedPeerModal
              setShowModal={setSavedPeers}
              dropdownHeadersForSavePeerModal={dropdownHeadersForSavePeerModal}
              handleUnselectCompany={handleUnselectCompany}
              isdispatchFunction={true}
              dispatchFunction={setPeerCompanyFilter}
              peerCompanyFilter={selectedPeerCompany}
              globalPeerCompanies={selectedGlobalPeerCompanies}
              setSelectedPeerCompany={setSelectedPeerCompany}
              setSelectedGlobalPeerCompanies={setSelectedGlobalPeerCompanies}
              savePeerGroupHandler={SavePeerGroupHandler}
              debounceChangeHandler={debounceChangeHandler}
              peerOrder={peerOrder}
              setPeerOrder={setPeerOrder}
            />
          )}
          {showConfirmationModal && (
            <ConfirmationModal
              show={true}
              handleCloseModal={() => {
                setShowConfirmationModal(false);
                setLocalSelectedBaseline(
                  insightsState.companyFilter &&
                    insightsState.companyFilter.length
                    ? insightsState.companyFilter[0]
                    : null
                );
                setIsConfirmationModalTriggerByDropdown(false);
              }}
              handleConfirm={handleConfirmation}
              customTitle={getCustomConfirmationHeader()}
              content={getCustomConfirmationContent()}
              customClass=""
            />
          )}
          {showGlobalSearch && (
            <GlobalSearchModal
              show={showGlobalSearch}
              handleClose={() => {
                setShowGlobalSearch(false);
                setIsConfirmationModalTriggerByDropdown(false);
              }}
              selectionType={selectionType}
              selectCompany={selectCompany}
              unselectCompany={handleUnselectCompany}
              peersSelected={
                insightsState.peerCompanyFilter
                  ? insightsState.peerCompanyFilter
                  : []
              }
              globalBaseSelected={
                insightsState.globalCompanyMappingsFilter &&
                insightsState.globalCompanyMappingsFilter.length > 0
                  ? insightsState.globalCompanyMappingsFilter.find(
                      (v: Partial<GlobalCompanyGroup>) => v.isBaseMapping
                    )
                  : undefined
              }
              globalPeersSelected={
                insightsState.globalCompanyMappingsFilter &&
                insightsState.globalCompanyMappingsFilter.length
                  ? insightsState.globalCompanyMappingsFilter.filter(
                      (v: Partial<GlobalCompanyGroup>) => !v.isBaseMapping
                    )
                  : []
              }
              limit={
                commonsState.insightsPeerCompanyLimit
                  ?.InsightsPeerCompaniesLimit &&
                isNumeric(
                  commonsState.insightsPeerCompanyLimit
                    ?.InsightsPeerCompaniesLimit
                )
                  ? parseInt(
                      commonsState.insightsPeerCompanyLimit
                        ?.InsightsPeerCompaniesLimit
                    )
                  : COMPANY_LIMIT.PEER
              }
              unselectGlobalCompany={unselectGlobalCompany}
              globalCompanyMappingsFilter={
                insightsState.globalCompanyMappingsFilter
              }
              fetchSavedGroupings={fetchSavedGroupings}
              fetchGlobalCompaniesFilter={fetchGlobalCompaniesFilter}
              deleteSavedGrouping={deleteSavedGrouping}
              guidanceCustomText={FormattedMessageString("company.insight")}
              saveCompanyGrouping={saveCompanyGrouping}
              setGlobalCompanyMappingsFilter={setGlobalCompanyMappingsFilter}
              peerOrder={peerOrder}
              setPeerOrder={setPeerOrder}
            />
          )}
        </>
      </SidePanel>
    </>
  );
};
export default CreateNew;
