import api from "../../services/api.service";
import { Tag } from "./tags.model";

export const getTagsList = (comparisonItemId: number) =>
  api.get(`comparisons/${comparisonItemId}/tags`).then(({ data }) => data);

export const createTag = (payload: Partial<Tag>) =>
  api.post(`comparisons/tags`, payload).then(({ data }) => data);

export const getTagNameList = (tagType: number) =>
  api.get(`tags/tagType/${tagType}`).then(({ data }) => data);

export const deleteTag = (payload: any) =>
   api.delete(`tags`, {data: payload}).then(({ data }) => data);

export const updateTag = (payload: Partial<Tag>) =>
  api.put(`comparisons/tags`, payload).then(({ data }) => data);

export const broadcastUserClosesCB = (payload: any) =>
  api.post(`notifications`, payload).then(({ data }) => data);

export const updateTagInstance = (payload: Partial<Tag>) => api.patch(`comparisons/tagDetail`, payload).then(({data})=>data);
