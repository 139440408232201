import classNames from "classnames";
import Icon from "components/shared/icon/icon";
import { useEffect, useRef, useState } from "react";
import { dropdownHeight } from "utils/constants";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { InsightCategory } from "services/insights/insights.model";
import Parser from "html-react-parser";

type ObjectKeys = {
  name: string;
  id: string;
};

type Props = {
  className: string;
  label: string | object;
  showLabel?: boolean;
  placeholder: string | object;
  loading: boolean;
  options: InsightCategory[];
  values: InsightCategory[];
  handleChange?: any;
  handleSelect: any;
  handleUnselect?: any;
  handleGroupUnselect?: any;
  handleChangeValue?: any;
  objectKeys: ObjectKeys;
  textValue?: string;
  displayFunction?: any;
  handleOnEnter?: any;
  type?: string;
};

const DashboardType = ({
  className,
  label,
  showLabel = true,
  placeholder,
  loading,
  options,
  values,
  handleSelect,
  handleUnselect,
  objectKeys,
  displayFunction,
  textValue,
  handleOnEnter,
  type,
}: Props) => {
  const [showOptions, toggleOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] =
    useState<InsightCategory[]>(options);
  const [inputFilter, setInputFilter] = useState("");
  const [input, setInput] = useState(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const inputSizeRef = useRef<HTMLDivElement>(null);
  const BLOCK = "bench-mark";

  const handleLocalSelect = (option: any) => {
    handleSelect(option, type);

    setInputFilter(option[objectKeys.name]);
    toggleOptions(!showOptions);
  };

  const handleLocalUnselect = (option: any) => {
    handleUnselect();
    toggleOptions(!showOptions);
  };

  useEffect(() => {
    setFilteredOptions(options ? options : []);
  }, [options]);

  useEffect(() => {
    if (values && values.length) {
      setInputFilter(values[0].insightsCategoryName);
    }

    if (values && values.length === 0) setInputFilter("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (showOptions && optionsRef.current) {
      optionsRef.current.focus();
      const dropdownElement = optionsRef.current?.parentElement;
      const scrollView = optionsRef.current?.parentElement?.parentElement;
      if (dropdownElement && scrollView) {
        const dropdownBottomOffset = dropdownElement.offsetTop + dropdownHeight;
        const offsetToScroll =
          dropdownBottomOffset > scrollView.clientHeight
            ? dropdownBottomOffset - scrollView.clientHeight
            : 0;
        setTimeout(() => {
          if (scrollView.scrollTo !== undefined) {
            scrollView.scrollTo({ top: offsetToScroll, behavior: "smooth" });
          }
        }, 200); // wait for the transition to finish
      }
    }
  }, [showOptions, values]);

  useEffect(() => {
    if (!loading && filteredOptions.length === 0 && inputFilter !== "") {
      setInput(true);
    } else {
      setInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFilter, loading, filteredOptions.length]);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (input && textValue?.length === 0) {
        setInputFilter("");
        setInput(false);
      }

      return showOptions || event.target.nodeName !== "svg"
        ? toggleOptions(
            wrapperRef.current !== null &&
              wrapperRef.current.contains(event.target)
          )
        : null;
    };

    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);

    textareaRef.current?.setAttribute(
      "style",
      `width: ${inputSizeRef.current?.clientWidth}px;
      height: ${inputSizeRef.current?.clientHeight}px`
    );
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, options, input, inputFilter, showOptions]);

  const highlightOption = (option: string) => {
    if (inputFilter !== "" && option) {
      let replace: any;
      // Replacing Open Braces and Close braces if found individually
      // Would break the regex condition
      if (inputFilter.includes("(") && inputFilter.includes(")")) {
        replace = new RegExp(`${inputFilter}`, "i");
      } else if (inputFilter.includes("(")) {
        replace = new RegExp(`${inputFilter})`, "i");
      } else if (inputFilter.includes(")")) {
        replace = new RegExp(`(${inputFilter}`, "i");
      } else replace = new RegExp(`(${inputFilter})`, "i");

      return option
        .split(replace)
        .map((subtext) =>
          replace.test(subtext) ? `<b>${subtext}</b>` : subtext
        )
        .join("");
    }
    return option;
  };

  const determineIsTypeDashboardTypeSelected = (option: any) => {
    return (
      values?.findIndex(
        (value: any) => value.insightsCategoryId === option.insightsCategoryId
      ) >= 0
    );
  };

  const getOption = (
    option: any,
    optionKeys: ObjectKeys,
    parentId?: number
  ) => {
    const isSelected = determineIsTypeDashboardTypeSelected(option);
    return (
      <button
        className={classNames(`${BLOCK}__option`, {
          [`${BLOCK}__option--selected`]: isSelected,
        })}
        key={`${optionKeys.id}-option-${option[optionKeys.id]}`}
        onClick={() =>
          isSelected
            ? handleLocalUnselect({ ...option, parentId })
            : handleLocalSelect({ ...option, parentId })
        }
        data-test="select-option-button"
        data-testid="select-option-button"
      >
        <table>
          <tbody>
            <tr>
              <td className={`${BLOCK}__option-value`} data-test="option-value">
                {Parser(
                  highlightOption(
                    displayFunction
                      ? displayFunction(option)
                      : option[optionKeys.name]
                  ).toString()
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </button>
    );
  };
  return (
    <div
      ref={wrapperRef}
      id={`${BLOCK}-${objectKeys.id}`}
      className={`${BLOCK} ${className}`}
      data-test="drop-down"
      key={`${BLOCK}-${objectKeys.id}`}
    >
      <div className={`${BLOCK}__label`} data-test="drop-down">
        {showLabel && (label as React.ReactNode)}
      </div>

      <div
        className={classNames(`${BLOCK}__outline`, {
          [`${BLOCK}__outline-disabled`]: type === "edit",
        })}
      >
        <textarea
          ref={textareaRef}
          value={inputFilter}
          className={`${BLOCK}__input`}
          data-test="input-text-area"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleOnEnter({});
            }
          }}
          onFocus={() => toggleOptions(true)}
          disabled={true}
          data-testid="textarea-dropdown"
        />

        {!values?.length && inputFilter === "" && (
          <div className={`${BLOCK}__placeholder--dashboardType`}>
            {placeholder as React.ReactNode}
          </div>
        )}

        {filteredOptions.length > 0 && type !== "edit" && (
          <button
            className={classNames(`${BLOCK}__chevron-button`, {
              [`${BLOCK}__chevron-button--not-empty`]: values && values.length,
            })}
            data-test="chevron-button"
            data-testid="chevron-button"
            onClick={() => toggleOptions(!showOptions)}
          >
            <Icon
              className={classNames(`${BLOCK}__chevron-icon`, {
                [`${BLOCK}__chevron-icon--open`]: showOptions,
                [`${BLOCK}__chevron-icon--close`]: !showOptions,
              })}
              name="chevron-down"
              height={24}
            />
          </button>
        )}
      </div>
      {type !== "edit" && (
        <div
          ref={optionsRef}
          className={classNames(`${BLOCK}__options`, {
            [`${BLOCK}__options--show`]: showOptions,
            [`${BLOCK}__options--hide`]: !showOptions,
          })}
          data-test="drop-down-options"
          data-testid="drop-down-options"
        >
          {loading || filteredOptions.length === 0 ? (
            <button className={`${BLOCK}__option`}>
              {loading && (
                <Icon
                  name="loading"
                  width={20}
                  height={20}
                  data-test="loading"
                  className={classNames(`${BLOCK}__loading`, {
                    "loading-icon": loading,
                  })}
                />
              )}
              {!loading &&
                inputFilter !== "" &&
                filteredOptions.length === 0 && (
                  <div>
                    <FormattedMessage id="filter.no.matches" />
                  </div>
                )}
            </button>
          ) : (
            filteredOptions.map((option: any) => getOption(option, objectKeys))
          )}
        </div>
      )}
    </div>
  );
};

export default DashboardType;
