import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
export const cookiesToIgnore = ["OptanonConsent", "OptanonAlertBoxClosed"];
export const dashboardPath = "/dashboard";
export const insightsPath = "/insights";
export const MENU_ITEMS = {
  search: {
    icon: "search",
    path: "/search",
  },
  mysearches: {
    icon: "mysearches",
    path: "/mysearches",
  },
  publishedsearches: {
    icon: "published",
    path: "/mysearches/published",
  },
  saved: {
    icon: "saved",
    path: "/shortlist",
  },
  comparisons: {
    icon: "comparisons",
    path: "/comparisons",
  },
  workspace: {
    icon: "workspace",
    path: insightsPath,
  },
  downloads: {
    icon: "downloads",
    path: "/downloads",
  },
  manageFiles: {
    icon: "managefiles",
    path: "/managefiles",
  },
  manageAdfPipeline: {
    icon: "managefiles",
    path: "/manage-adf-pipelines",
  },
};

export enum FILTER_OPTIONS {
  COMPANY = "company",
  CONTENT = "content",
  DOCUMENT_TYPE = "document_type",
  PERIOD = "period",
  INDUSTRY = "industry",
  AUDITOR = "auditor",
  GOVERNING_BOARD = "goberning_board",
  ESG_RATING = "esg_rating",
  SUGGESTED_PEER = "suggested_peer",
  GLOBAL_COMPANY = "global_company",
}

export const FORM_TYPES = [
  "N/A",
  "ESG",
  "N/AF",
  "10-Q",
  "10-K",
  "20-F",
  "DEF 14A",
  "DEFA14A",
];

export const HighlightOptions = {
  padding: 5,
  color: "rgba(255, 230, 127,0.5)",
  baseColor: "rgba(160, 220, 255, 0.6)",
  tagColor: "rgba(160, 220, 255, 0.3)",
  tagEnd: "rgba(160, 120, 255, 1)",
  bookends: "#00A3E0",
};

export const SessionTimeout = {
  CHECK_INTERVAL_MS: 1000,
  IDLE_WAIT_TIME_MS: 1500000,
  WARNING_TIME_MS: 300000,
};

export const GRADIENT_UNIT_FOR_PROGRESSBAR = 100 / 3;

export const companyPagination = {
  pageNumber: 1,
  totalRecordCount: 30,
};

export const CHECKBOX_DATA = {
  TalkingPoints: "Talking points",
  Single_Slide: "Single slide",
  Multiple_Slides: "Multiple slides",
  NOTES: "Notes",
  TAGS: "Tags",
  EXPORT_EXCEL: "Export excel",
  EXPORT_PDF: "Export pdf",
  EXPORT_DETAILEDVIEW: "Detailed view",
  EXPORT_COMPACTVIEW: "Compact view",
};

export const COMPARISON_EXPORT_PANEL_EXCEPTIONS = {
  EXPORT_PANEL_BLOCK: "comparison__exportPanel",
  EXPORT_PANEL_ALIGN_MARGIN_REMOVE: "comparison__exportPanel-NoMargin",
};

export const NOTE_TYPES = {
  SHORTLIST: 0,
  SHORTLIST_ITEM: 1,
  COMPARISON: 2,
  COMPARISON_ITEM: 3,
};

export const INSIGHT_BENCHMARK_TYPE = {
  COMPANY: 4,
  PEER_BENCHMARK: 5,
  INDUSTRY: 6,
};

export const TABULAR_TILE_HEADER = {
  PEER_BENCHMARK: "Peers",
  INDUSTRY: "Industry",
};

export const esgFrameworksShortNames = [
  { governingBoardId: 2, shortname: "SDGs" },
  { governingBoardId: 3, shortname: "GRI" },
  { governingBoardId: 5, shortname: "SASB" },
  { governingBoardId: 6, shortname: "CDP" },
  { governingBoardId: 7, shortname: "TCFD" },
];

export const REPORT_STATUS_ERRORS = {
  FILE_NOT_FOUND: 5,
  INVALID_CIK_NUMBER: 6,
  METADATA_MISSING: 7,
  DUPLICATE_FILE: 8,
  VIRUS_INFECTED: 9,
  UNSUPPORTED_FORMAT: 10,
};

export const homepage = "/";
export const unauthorizedPath = "/unauthorized";
export const logout = `${
  window.ESG?.ENV?.LOGOUT_URL ? window.ESG?.ENV?.LOGOUT_URL : "/logout"
}`;
export const dashboard = "/dashboard";
export const searchPath = "/search";
export const shortlistPath = "/shortlist";
export const searchShortlistPath = "/search/shortlist";
export const reportPath = "/report/";
export const comparisonPath = "/comparison";
export const contentBenchmarkPathRegex = /^\/comparison\/\d+$/;
export const savedSearchesPath = "/mysearches";
export const publishedSearchesPath = "/mysearches/published";
export const dropdownHeight = 340;
export const peerBenchmarkPath = "/peerbenchmark";
export const powerbiDashboardPath = "/humancapital";
export const esrs = "/esrs";
export const powerbiEnable = `${window.ESG?.ENV?.POWERBI_ENABLE}`;
export const workSpaceId = `${window.ESG?.ENV?.WORKSPACE_ID}`;
export const reportId = `${window.ESG?.ENV?.REPORT_ID}`;
export const esrsReportId = `${window.ESG?.ENV?.ESRS_REPORT_ID}`;
export const POWERBI_BASE_URL = "https://api.powerbi.com/v1.0/myorg/groups";
export const secretKey = "75968036-ee3b-4f5d-9d24-4010df003ed3";

export const exportOptions = {
  export_ChartView: [
    {
      type: "pdf",
      icon: "pdf-2",
      text: "Export PDF",
    },
    {
      type: "ppt",
      icon: "ppt",
      text: "Export PPT",
    },
  ],
};

export const DASHBOARD_TYPE = {
  HUMAN_CAPITAL: 5,
  ESRS: 101,
};
export const POWERBI_DASHBOARD = {
  HUMAN_CAPITAL: 3,
  ESRS: 4,
};

export const ESGsortResults = {
  sortBy: "ReportYear",
  sortOrder: "desc",
};
export const SECsortResults = {
  sortBy: "PeriodName",
  sortOrder: "desc",
};

export const SORT_ORDERS = {
  ASC: "asc",
  DESC: "desc",
  NONE: null,
};

export const MAPPING_TEMPLATE_FILENAME = "ESG report import template";

export const NOTIFICATION_TYPES = {
  SHARE_SEARCH: 1,
  SHARE_SHORTLIST: 2,
  TO_BE_PURGED_SHARE: 3,
  SHARE_COMPARE: 4,
  SHARED_INSIGHT: 8,
  AUTO_DELETE_COMPARE: 5,
  AUTO_DELETE_INSIGHT: 9,
  SHARED_COMPARISON_FOR_COLLABORATION: 10,
  CONTENT_BENCHMARK_ROL_CHANGED: 13,
  CONTENT_BENCHMARK_USER_REMOVED: 14,
  CONTENT_BENCHMARK_DELETED: 15,
  SHARED_PEER_BENCHMARK: 16,
};

export const NOTIFICATION_STATUS = {
  SHOW: 1,
  CLOSED: 2,
};

export const TOAST_OPTIONS = {
  dismissTime: 5000,
  position: "bottom-center",
};

export const TABLE_ACTIONS = {
  SORT: "sort",
  NEXT_PAGE: "next_page",
  INIT: "init",
};

export const DEFAULT_SHORTLISTS_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "lastUpdatedDate",
  sortOrder: "desc",
  contentText: "",
};

export const DEFAULT_SAVED_COMPARISONS_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "lastUpdatedDate",
  sortOrder: "desc",
};

export const DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "lastUpdatedDate",
  sortOrder: "desc",
  contentText: "",
};

export const DEFAULT_SAVED_SEARCHES_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "null",
  sortOrder: "null",
};

export const DEFAULT_ESG_EXPORT_PAYLOAD = {
  exportReferenceId: 0, // Respective Id
  exportFileName: "", // Respective Name
  exportType: 1, // 1 - ESG, 2 - SEC, 3 - SHORTLIST
  exportFormat: 1, // 1 - pdf, 2 - xls, 3 - word
};

export const DEFAULT_SEC_EXPORT_PAYLOAD = {
  exportReferenceId: 0,
  exportFileName: "",
  exportType: 2,
  exportFormat: 1,
};
export const EXPORT_FORMAT = {
  xls: 0,
  pdf: 1,
  pptx: 3,
};

export const DEFAULT_ESG_RAW_EXPORT_PAYLOAD = {
  exportReferenceId: 0,
  exportFileName: "",
  exportType: 6,
  exportFormat: 1,
};

export const DEFAULT_SHORTLIST_EXPORT_PAYLOAD = {
  exportReferenceId: 0,
  exportFileName: "",
  exportType: 3,
  exportFormat: 1,
  searchCriteria: {
    contentText: "",
  },
};

export const EXPORT_RELATED_EXCEPTIONAL_CLASSES = {
  MAIN_TILE: ".main-tile",
  ADDITIONAL_INFO_LINKS_CLASS: "__linkInfo",
  TILE_ACTIONS: "__tile-actions",
  INSIGHT_DASHBOARD_CONTENT: ".insight-dashboard__content",
  INSIGHT_ESRS_DASHBOARD_CONTENT: ".collapsable-layout__parent",
  S_Y_P_SOURCE: "--hide-sp-source",
};

export const DEFAULT_COMPARISON_EXPORT_PAYLOAD: {
  exportReferenceId: number;
  exportFileName: string;
  exportType: number;
  exportFormat: number;
  withoutKeywordHighlightItems: number[];
  isExportNotes: boolean;
  isExportTags: boolean;
} = {
  exportReferenceId: 0,
  exportFileName: "",
  exportType: 5,
  exportFormat: 1,
  withoutKeywordHighlightItems: [],
  isExportNotes: false,
  isExportTags: false,
};

export const DEFAULT_DASHBOARD_EXPORT_PAYLOAD = {
  exportFileName: "Dashboard",
  exportType: 9,
  exportFormat: 0,
};

export const DEFAULT_INSIGHT_EXPORT_PAYLOAD = {
  exportType: 13,
  exportFormat: 0,
};

export const DEFAULT_INDIVIDUAL_INSIGHT_EXPORT_PAYLOAD = {
  exportFileName: "Insight",
  exportType: 14,
  exportFormat: 0,
};

export const DEFAULT_SAVED_INSIGHTS_LIST_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "lastUpdatedDate",
  sortOrder: "desc",
  filterBy: null,
  filterText: null,
};

export const DOWNLOAD_EXPORT_TYPES = {
  NEW: 1,
  IN_PROGRESS: 2,
  READY_TO_DOWNLOAD: 3,
  DONE: 4,
  ERROR: 5,
};

export const MAPPING_TEMPLATE_EXPORT_TYPE = 1;

export const FILEUPLOAD_STATUS_TYPES = {
  PRE_UPLOAD: 0,
  UPLOADED: 1,
  PROCESSING: 2,
  PROCESSED: 3,
  PROCESSING_ERROR: 4,
  FILE_NOT_FOUND: 5,
  INVALID_CIK_NUMBER: 6,
  METADATA_MISSING: 7,
  DUPLICATE_FILE: 8,
  VIRUS_INFECTED: 9,
  UNSUPPORTED_FORMAT: 10,
};

export const BenchmarkActionTypes = {
  Null_or_Zero_tracking_type: 0,
  Create_Benchmark_Custom: 1,
  Edit_Benchmark_Custom: 2,
  Create_Benchmark_Peer: 3,
  Edit_Benchmark_Peer: 4,
  Share_Benchmark_Peer: 5,
  Edit_Benchmark_Transpose: 6,
  Edit_Benchmark_RemoveMetric: 7,
  Edit_Benchmark_RemoveCompany: 8,
};

export const DEFAULT_ESG_REMOVE_PAYLOAD = {
  shortlistId: 0,
  esgReport: {
    reportId: null,
  },
  sectionItem: {
    filingId: null,
    sectionId: null,
  },
  shortlistItemType: 0,
  contentKeywords: "",
  displayOrder: 0,
};

export const DEFAULT_SEC_REMOVE_PAYLOAD = {
  shortlistId: 0,
  esgReport: {
    reportId: null,
  },
  sectionItem: {
    filingId: null,
    sectionId: null,
  },
  shortlistItemType: 1,
  contentKeywords: "",
  displayOrder: 0,
};

export const DEFAULT_PEER_GROUP_LIST_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "lastUpdatedDate",
  sortOrder: "desc",
  contentText: "",
};

export const ScrollOffset = 10;

export const overlay = {
  MAX_SELECTION_OFFSET: 30,
  NEXT_WORD_DELTA: 1,
  BREAK_CHARACTERS: [32],
};

export const shareItemType = {
  search: 1,
  shortlist: 2,
  compare: 3,
  peerbenchmark: 4,
  insights: 5,
};

export const comparisonItemTypes = {
  ESG: 0,
  SEC: 1,
};

export const shortlistItemType = {
  ESG: 0,
  SEC: 1,
};

export const NOCALC_PERIOD = "Entity deemed question not applicable";

export const GHG_INTESITY_UNIT_TOOLTIP = "Unit of Intensity Measure";

export const shortlistTableFilter = {
  companyName: "Company",
  shortlistItemTitle: "Title",
  shortlistItemDocumentType: "Document Type",
  shortlistItemPeriod: "Period",
  // eslint-disable-next-line no-useless-computed-key
  ["esgReport.reportCompany.ivaCompanyRating"]: "ESG Rating",
  // eslint-disable-next-line no-useless-computed-key
  ["esgReport.reportCompany.corpGovernanceScore"]: "Governance Score",
};

export const comparisonTableFilter = {
  ComparisonName: "Name",
  ComparisonDescription: "Description",
  CreatedDate: "Created Date",
  LastUpdatedDate: "Last Saved",
};

export const peerBenchmarkTableFilter = {
  company: {
    type: "companyName",
    name: "Company",
  },
  "my-searches-name": "Name",
  "my-searches-description": "Description",
};

export const CHART_TYPES = {
  custom: 1,
  predefined: 3,
  tabular: 4,
};

export const manageFilesFilter = {
  CIKNumber: "CIK No",
  CompanyName: "Company",
  ReportTitle: "Title",
  FormTypeName: "Document Type",
  ReportStatus: "Status",
  CreatedDate: "Upload Date",
};

export const REPORT_STATUS_ID = {
  PRELOADED: 0,
  FILE_NOT_FOUND: 5,
  METADATA_MISSING: 6,
  VIRUS_INFECTED: 8,
};

export const publishSearchType = {
  savedSearches: 1,
  publishedSearches: 2,
};

export const editorConfig = {
  buttons: ["bold", "italic", "link"],
  toolbarAdaptive: false,
  height: "30px",
  width: "100%",
  minHeight: 400,
  minWidth: 260,
  limitChars: 2000,
  options: {
    iframe: false,
  },
};

export const comparisonNameLimit = 200;
export const reportNameLimit = 100;
export const pageSelectorPlaceholder = "e.g. 1-5,8,13";
export const pageSelectorSource = {
  SHORTLIST: 1,
  COMPARISON: 2,
};

export const Roles = {
  superAdmin: 1,
};

export const FILE_FORMATS = [{ label: "ZIP", extension: ".zip", icon: "zip" }];

export enum UPLOAD_STATUS {
  UPLOADING = "uploading",
  UPLOADED = "uploaded",
  FAILED = "failed",
}

export const FILE_VALIDATION_ERRORS = [
  {
    errorId: 1,
    code: "file-invalid-size",
  },
  {
    errorId: 2,
    code: "file-invalid-type",
  },
  {
    errorId: 3,
    code: "too-many-files",
  },
];

export const FILE_REPORT_STATUS_ERRORS = [
  {
    reportStatus: 5,
    text: "File Not Found",
  },
  {
    reportStatus: 10,
    text: "Specified file format not supported",
  },
  {
    reportStatus: 20,
    text: "File exceeds maximum size of 1GB",
  },
];
export enum REPORT_STATUS {
  SUCCESS = "success",
  FAILED = "failed",
  NOT_STARTED = "not started",
  IN_PROGRESS = "in progress",
}

export const ESG_REPORT_STATUS = {
  0: {
    status: "Not started",
    statusId: 0,
    className: "not-started",
    errorId: "",
    actions: ["delete"],
  },
  1: {
    status: "In progress",
    statusId: 1,
    className: "in-progress",
    errorId: "",
    actions: ["delete"],
  },
  2: {
    status: "In progress",
    statusId: 2,
    className: "in-progress",
    errorId: "",
    actions: ["delete"],
  },
  3: {
    status: "Success",
    statusId: 3,
    className: "success",
    errorId: "",
    actions: ["download", "edit", "delete"],
  },
  4: {
    status: "Failed",
    statusId: 4,
    className: "failed",
    errorId: "manage-files.processing.failed",
    actions: ["delete"],
  },
  5: {
    status: "Failed",
    statusId: 5,
    className: "failed",
    errorId: "manage-files.file.not.found",
    actions: ["delete"],
  },
  6: {
    status: "Failed",
    statusId: 6,
    className: "failed",
    errorId: "manage-files.invalid.cik",
    actions: ["delete"],
  },
  7: {
    status: "Failed",
    statusId: 7,
    className: "failed",
    errorId: "manage-files.missing.metadata",
    actions: ["delete"],
  },
  8: {
    status: "Failed",
    statusId: 8,
    className: "failed",
    errorId: "manage-files.duplicate.files",
    actions: ["delete"],
  },
  9: {
    status: "Failed",
    statusId: 9,
    className: "failed",
    errorId: "manage-files.virus.scan",
    actions: ["delete"],
  },
  10: {
    status: "Failed",
    statusId: 10,
    className: "failed",
    errorId: "manage-files.unsupported.file",
    actions: ["delete"],
  },
  11: {
    status: "Draft",
    statusId: 11,
    className: "draft",
    errorId: "manage-files.draft.state",
    actions: ["delete"],
  },
};

export const DEFAULT_ESG_REPORT_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "CreatedDate",
  sortOrder: "desc",
};

// Visualizations
export const primaryColor = "#007cb0";
export const textColor = "#000000";
export const subTextColor = "#757B7B";
export const lightGrey = "#D0D0CE";
export const lightColor = "#F0F0F0";
export const grey = "#E0E0E0";
export const darkGrey = "#BBBCBC";

export const ghgEmissionFilteroptions = ["Location-Based", "Market-Based"];

export const HIDEMTETRICS = {
  "Location-Based": "ReportedLocationBasedScope2tCO2e",
  "Market-Based": "ReportedMarketBasedScope2tCO2e",
};

export const statusColors = [
  {
    fieldValue: "Not evaluated",
    colorCode: "#A7A8AA",
  },
  {
    fieldValue: "Not relevant, calculated",
    colorCode: "#EA075E",
  },
  {
    fieldValue: "Not relevant, explanation provided",
    colorCode: "#6FC2B4",
  },
  {
    fieldValue: "Relevant, calculated",
    colorCode: "#FFCD00",
  },
  {
    fieldValue: "Relevant, not yet calculated",
    colorCode: "#00A3E0",
  },
  {
    fieldValue: "Entity deemed question not applicable",
    colorCode: "#55AA0D",
  },
];

export const FILTEROPTION = {
  LOCATION_BASED: "Location-Based",
  MARKET_BASED: "  Market-Based",
};

export const peerColorCodes = [
  "#C4D600",
  "#68BC25",
  "#009A44",
  "#046A38",
  "#004F59",
];

export const constantColorCodes = [
  "#FCEBA3",
  "#FFB700",
  "#F4706D",
  "#8D2E63",
  "#ACDBFB",
  "#4C83B2",
  "#012169",
  "#00ABAB",
  "#007680",
];

export const FILTER_METRICS = {
  REPORTED_SCOPE: "ReportedScope1tCO2e",
  ESTIMATED_SCOPE: "EstimatedScope1tCO2e",
};

export const FILTER_DISPLAY_NAME = {
  ESTIMATED_SCOPE: "Estimated Scope 1 Total (MtCO2e)",
};

export const GRAPH_OPTIONS: any = {
  multi_bar: "multi-bar",
  bar: "bar",
  multi_column: "multi-column",
  column: "column",
  pie: "pie",
  bubble: "bubble",
  donut: "donut",
  scatter: "scatter plot",
  line: "line chart",
  tabular: "tabular",
  trend: "trend",
  speedometer: "speedometer",
  verticalBar: "verticalBar",
  score: "score",
  textbox: "textbox",
  table: "table",
  table_2: "table-2",
  tabular_2: "tabular-2",
  tabular_table: "tabular-table",
  tabularFA_table: "tabularFA-table",
  column_2: "column-2",
  column_2_table: "column-2-table",
  trend_table: "trend-table",
  table_3: "table-3",
  detailed_score: "detailed-score",
  energy_chart: "energy-chart",
  energy_table: "energy-table",
  reporting: "reporting",
  reporting_table: "reporting-table",
  energy_undertaken: "energy-undertaken",
  energy_undertaken_table: "energy-undertaken-table",
  time_chart: "time-chart",
  time_table: "time-table",
};

export const PROGRESS_BAR_DIVISOR = 5;

export const DELETE_MAP_FILES_STATUS = 12;

export const PRE_UPLOAD_STATUS = 0;

export const FEATURESETTINGS = {
  TalkingPoints: "TalkingPoints",
};

export const EXPORT_INCLUDE_IN_SHARING = {
  TALKING_POINTS: "Talking points",
  HIDDEN_TILES: "Hidden tiles",
};

export const IGNORADED_MESSAGES = {
  INVALID_CIK: "Invalid.CIK",
  DUPLICATE_NAME: "Duplicate.Name",
};

export const MINUTES_BEFORE_EXPIRATION = 10 * 60 * 1000;

export const STATUS_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  REQUEST_TIMEOUT: 408,
  INTERNAL_SERVER_ERROR: 500,
};

export const BENCHMARK_METRICS = {
  STANDAR_RAITINGS: {
    label: "peer-benchmarks.standard.ratings",
    metricCategoryID: 2,
    payload: "Standard_Ratings",
  },
  DETAILED_FACTORS: {
    label: "peer-benchmarks.standard.factors",
    metricCategoryID: 3,
    payload: "Detailed_Factors",
  },
  OTHER_INDICATORS: {
    label: "peer-benchmarks.other.indicators",
    metricCategoryID: 4,
    payload: "Other_Indicators",
  },
};

export const METRIC_VALUES = {
  1: "Yes",
  Yes: "Yes",
  yes: "Yes",
  0: "No",
  No: "No",
  no: "No",
  Neutral: "Neutral",
  neutral: "Neutral",
  "Not yet": "Not yet",
  "not yet": "Not yet",
};

export const DEFAULT_METRICS_PAYLOAD = [
  BENCHMARK_METRICS.STANDAR_RAITINGS.payload,
  BENCHMARK_METRICS.DETAILED_FACTORS.payload,
  BENCHMARK_METRICS.OTHER_INDICATORS.payload,
];
export const BENCHMARK_TYPE = {
  MSCI: 2,
  CDP: 11,
  GET_SAVED_LIST: 12,
};

export const PEER_BENCHMARK_TABS = { MSCI: "MSCI", CDP: "CDP" };

export const DEFAULT_SELECTED_FIELD_PEER_BENCHMARK = {
  fieldName: "IvaRatingDate",
  fieldGroupName: null,
  displayOrder: 1,
};

export const DEFAULT_VISUALIZATION_EXCEL_EXPORT_PAYLOAD = {
  exportReferenceId: 0,
  exportFileName: "",
  exportType: 8,
  exportFormat: 0,
  searchCriteria: { contentText: "" },
};

export const BENCHMARK_TILE_TITLE_MAPPING = {
  37: "Base Year",
  38: "Target Year",
  39: "Targeted Emissions Reduction %",
  40: "Emissions Targets in Reporting Year",
};

export const NEUTRAL = "Equal distribution across categories";

export const UNAVAILABLE = "Unavailable";

export const GROUP_VALUES_TARGET_METRICS = [
  "Base Year",
  "Target Year",
  "Targeted Emissions Reduction %",
];
export const TARGETTED_EMISSIONS_PERCENT_SPLIT = [
  "Targeted Emissions",
  "Reduction %",
];

export const MULTI_TARGET_COLOR_CODES = [`#E3F2E5`, `#F1EEF8`, `#EEF5F8`];

export const NO_DATA_AVAILABLE_MSG = "*";
export const NEUTRAL_TOOLTIP = `Equal distribution across categories`;

export const TARGET_VALUES = {
  QUESTION_NOT_APPLICABLE: "Question not applicable",
  ENTITY_QUESTION: "Entity deemed question not applicable",
  NEUTRAL: "Neutral",
};

export const DEFAULT_INSIGHT_VISUALIZATION_EXCEL_EXPORT_PAYLOAD = {
  exportFormat: 0,
  exportType: 14,
};

export const PREDEFINED_CHART_EXPORT_TYPE = 7;
export const CUSTOM_CHART_EXPORT_TYPE = 8;
export const CHART_EXPORT_TYPE_UI = 10;
export const INSIGHT_PDF_ALL_EXPORT_TITLE = "Insight";
export const DASHBOARD_PDF_ALL_EPXORT_TITLE = "Dashboard";
export const EXPORT_TYPES = {
  PEER_BENCHMARK: 11,
  INSIGHTS: 13,
  INSIGHTS_INDIVIDUAL: 14,
  INSIGHTS_ALL: 13,
};

export const PPT_EXPORT_FORMAT = {
  SINGLE: {
    NAME: "Single slide",
    ID: 0,
  },
  MULTI: {
    NAME: "Multiple slides",
    ID: 1,
  },
};

export const EXCEPTION_FOR_INSIGHT_TILE_EXPORT = {
  LINKS: "Links",
  ADD_ADDITIONAL_INFO: "Add Additional Information",
};

export const SIDE_CUSTOM_TYPE_EDIT = 1;
export const SIDE_CUSTOM_TYPE_SAVE = 0;

export const UPDATE_VALUE_TYPE = {
  GRAPH_OPTION: "graphOption",
  FILTER: "filter",
  FILTER_INDUSTRY: "industryFilter",
};

export const APP_SETTING = {
  DashboardColorCodes: "DashboardColorCodes",
  LowestScoringColorCodes: "LowestScoringColorCodes",
  HighestScoringColorCodes: "HighestScoringColorCodes",
  IvaCompanyRatingColorCodes: "IvaCompanyRatingColorCodes",
  InsightsDetailedPeerViewColorCodes: "InsightsDetailedPeerViewColorCodes",
  MSCIDataSourceLabel: "MSCIDataSourceLabel",
  DashboardExportDisclaimer: "DashboardExportDisclaimer",
  InsightsPageDisclaimer: "InsightsPageDisclaimer",
  InsightsPeerCompaniesLimit: "InsightsPeerCompaniesLimit",
  PotentialPeersPopUpTooltip: "PotentialPeersPopUpTooltip",
  termOfUse: "TermsOfUse",
  PeerBenchmarkMetricsSelectionMaxCount:
    "PeerBenchmarkMetricsSelectionMaxCount",
  Scope3CategoriesStatusColorCodes: "Scope3CategoriesStatusColorCodes",
  DataPeriodDropDownLimit: "DataPeriodDropDownLimit",
  DisableTalkingPointsExportInsightsCategoryIds:
    "DisableTalkingPointsExportInsightsCategoryIds",
};

export const peerBenchmarkColor: { [key: number]: string } = {
  309: APP_SETTING.HighestScoringColorCodes,
  310: APP_SETTING.LowestScoringColorCodes,
};

export const peerBenchmarkNoResultMesages: { [key: number]: string } = {
  309: "peer-benchmarks.highest.metric.not.results",
  310: "peer-benchmarks.lowest.metric.not.results",
};

export const peerBenchmarkShowHover = ["Integer/Boolean"];
export const peerBenchmarkIvaRatingTrend = "IvaRatingTrend";

export const OVERLAY_INSIGHTS = [
  {
    name: "img1",
    imgUrl: "assets/overlay-insight-2.4-1.JPG",
    alt: "img1",
  },
  {
    name: "img2",
    imgUrl: "assets/overlay-search-2.4-1.JPG",
    alt: "img2",
  },
];
export const OVERLAY_DASHBOARD = [];

export const OVERLAY_PEER_BENCHMARKS = [];

export const OVERLAY_SEARCHES = [
  {
    name: "img1",
    imgUrl: "assets/overlay-insight-2.4-1.JPG",
    alt: "img1",
  },
  {
    name: "img2",
    imgUrl: "assets/overlay-search-2.4-1.JPG",
    alt: "img2",
  },
];
export const DASHBOARD_CATEGORIES = [
  {
    insightsCategoryId: 1,
    insightsCategoryName: "SEC Readiness",
    icon: "icon-sec-readiness",
    color: "#F1EEF8",
  },
  {
    insightsCategoryId: 2,
    insightsCategoryName: "Assurance Readiness",
    icon: "icon-assurance-readiness",
    color: "#F8F4EE",
  },
  {
    insightsCategoryId: 3,
    insightsCategoryName: "GHG",
    icon: "icon-ghg",
    color: "#EBECEC",
  },
  {
    insightsCategoryId: 4,
    insightsCategoryName: "Assurance",
    icon: "icon-assurance",
    color: "#E3F2E5",
  },
  {
    insightsCategoryId: 5,
    insightsCategoryName: "Climate",
    icon: "icon-climat",
    color: "#F7EDED",
  },
  {
    insightsCategoryId: 6,
    insightsCategoryName: "Materiality",
    icon: "icon-materiality",
    color: "#EEF5F8",
  },
];

export const BENCHMARK_TILE_TYPES = {
  REFERENCED_FRAMEWORK: 4,
  GHG_EMISSIONS_TOTALS: 5,
  MSCI_ESG_Rating: 6,
  MSCI_ESG_Rating_Climate: 13,
  LINKS: 21,
  CDP_SCOPE_3_CATEGORIES_REPORTED: 3,
  RELEVANT_RISKS: 26,
  CLIMATE_TOPICS: 27,
  CLIMATE_RELATED_TOPICS: 12,
  REPORTING_BOUNDARY: 28,
  RENEWABLE_ENERGY: 11,
  ENERGY_SPEND: 33,
  ENERGY_CONSUMPTION_TOTALS: 30,
  ENERGY_UNDERTAKEN: 32,
  TIME_HORIZON: 29,
  ADD_ADITIONAL_INFORMATION: 25,
  EMISSIONS_TARGET_REPORTING: 38,
  TARGET_TILES: 37,
  BELOW_EMISSION: 41,
  COMPANY_EMISSION: 42,
  ALIGNED_EMISSION: 43,
  ALIGNED_GAP: 44,
  CLIMATE_STRATEGY: 45,
  ABSOLUTE_SCOPE3_EMISSIONS: 47,
  OTHER_SUSTAINABILITY_RANKING: 23,
  GHG_INTENSITY: 50,
  SCENARIO_ANALYSIS: 54,
  CLIMATE_RELATED_MANAGEMENT_ESRS: 55,
  DIRECT_INDIRECT_COST: 71,
  WATER_INTENSITY: 65,
  RAW_MATERIALS_AND_SOURCING: 66,
  BUSINESS_ETHICS_AND_FRAUD_SCORE: 67,
  TOXIC_EMISSIONS_WASTE_SCORE: 68,
  CLIMATE_RELATED_VALUE_CHAIN_ENGAGEMENT: 72,
  GOVERNANCE_ESRS: 73,
  OWN_WORKFORCE: 74,
  CORPORATE_GOVERNANCE_THEME_SCORE: 61,
  SOCIAL_OPPORTINITIES_THEME_SCORE: 62,
  WASTE: 69,
  PRODUCT_SAFETY_AND_QUALITY_SCORE: 70,
  BIODIVERSITY_AND_LAND_USE_SCORE: 75,
  SUPPLY_CHAIN_LABOR_SCORE: 76,
  BIODIVERSITY_MANAGEMENT: 77,
  CLIMATE_RISK_MANAGEMENT: 79,
  OPPORTUINITIES_RENEWABLE_ENERGY_SCORE: 63,
  WATER_STRESS_SCORE: 64,
  RAW_MATERIAL_AND_SOURCING_SCORE: 66,
  CLIMATE_RELATED_INCENTIVES: 81,
  ENERGY_CONSUMPTION: 82,
  HEALTH_AND_SAFETY: 83,
  LABOR_MANAGEMENT: 84,
  HUMAN_CAPITAL_DEVELOPMENT: 85,
  GHG_EMISSIONS_TOTALS_ESRS: 86,
};

export const ESG_RATING_TILE = {
  IVA_COMPANY_RATING: "IvaCompanyRating",
  IVA_RATING_TREND: "IvaRatingTrend",
  IVA_RATING_DATE: "IvaRatingDate",
  MSCI_Strengths: "MSCIStrengths",
  MSCI_Risks: "MSCIRisks",
};

export const IVA_RATING_TREND = {
  INCREASE: "Increased",
  NO_CHANGE: "No Change",
  DECREASE: "Decreased",
};

export const ESG_IVA_RATING_TILE_PEER_INDUSTRY = {
  IVA_COMPANY_RATING: "ivaCompanyRating",
  IVA_RATING_TREND: "ivaRatingTrend",
  IVA_RATING_DATE: "ivaRatingDate",
  MSCI_Strengths: "mSCIStrengths",
  MSCI_Risks: "mSCIRisks",
};

export const INSIGHT_DASHBOARD_BASE_LINKS = [
  {
    label: "insight.esg.link",
    description: "insight.esg.description.link",
    hash: "#esg",
    formTypeName: "ESG",
  },
  {
    label: "insight.sec.link",
    description: "insight.sec.description.link",
    hash: "#sec",
    formTypeName: "10-Q,10-K,20-F,DEF 14A,DEFA14A",
  },
];

export const SPEEDOMETER_DEFAULT_COLORS = {
  COLORS: ["#007680, #9DD4CF", "#0076A8, #62B5E5", "#3D2074,#C8158B"],
  CONDITIONAL_COLORS: ["#046A38, #86BC25", "#C82518, #FF9D00"],
};

export const INSIGHT_BENCHMARK_IDS = {
  PEER: -98,
  INDUSTRY: -99,
};

export const INSIGHT_BENCHMARK_ID_ARRAY = [-99, -98];

export const tableViews = [
  GRAPH_OPTIONS.table,
  GRAPH_OPTIONS.table_2,
  GRAPH_OPTIONS.column_2_table,
  GRAPH_OPTIONS.tabular_table,
  GRAPH_OPTIONS.tabularFA_table,
  GRAPH_OPTIONS.trend_table,
  GRAPH_OPTIONS.table_3,
  GRAPH_OPTIONS.energy_table,
  GRAPH_OPTIONS.reporting_table,
  GRAPH_OPTIONS.energy_undertaken_table,
  GRAPH_OPTIONS.time_table,
  GRAPH_OPTIONS.reporting_table,
];

export const typeOfTimeHorizon = {
  0: "short-time",
  1: "medium-time",
  2: "long-time",
};

export const timeHorizonMetricIndex = {
  0: [0, 1],
  1: [2, 3],
  2: [4, 5],
};

export const INSIGHTS_VIEW_OPTION_INDEX = {
  GRAPH: 0,
  TABLE: 1,
};

export const FULFILLED_PROMISE_STATUS = "fulfilled";

export const RISKS_VALUES = {
  NEUTRAL: "Neutral",
};

export const PEER_GROUP_CREATION_PAGE = {
  DASHBOARD: "Dashboard",
  INSIGHT: "Insight",
  PEER_BENCHMARK: "Peer benchmark",
  SEARCH: "Search",
};

export const POPULAR_SEARCHES = {
  AUTO_COMPLETE: 2,
  POPULAR: 1,
};

export const INSIGHT_DASHBOARD_FILTER_LABELS = {
  SCOPE: "Scope",
  COMPANIES: "Company",
  ASSOCIATED_METRICS: "AssociatedMetrics",
};

export const INSIGHT_CDP_INDUSTRY_SUBTITLE = {
  BASE: "Scope 3 Emissions",
  INDUSTRY: "Number of companies reported",
};

export const TRACKING_IDS = {
  ADD_PEER_GROUP: 15,
  PEER_COMMPANY_SELECTION_IN_SEARCH: 16,
};

export const RENEWABLE_ENERGY_METRICS = {
  REPORTED_SHEC_RENEWABLE_PERCENTAGE: "reportedSHECRenewablePercentage",
};

export const INSIGHTS_VIEW_TYPES = {
  CHART_VIEW: 1,
  TABLE_VIEW: 2,
};

export const INSIGHT_PEER_COMPANY = {
  cikNumber: -98,
  cik: -98,
  companyName: "Peers",
  header: "Peers",
  globalCompanyId: -98,
};

export const INSIGHT_INDUSTRY_COMPANY = {
  cikNumber: -99,
  cik: -99,
  companyName: "Industry",
  header: "Industry",
  globalCompanyId: -99,
};

export const BENCHMARK_METADATA_PATH = {
  SELECTED_METRIC_OPTION: "selectedMetricOption",
  SELECTED_CHART_OPTION: "selectedChartOption",
  FILTERS: "filters",
  ALL_UNITS_EXPANDED: "expandAllUnits",
};

export const MAX_WIDTH_PERCENTAGE = 11.5;
export const CUSTOM_SPACING_PERCENTAGE = 9.3;

export const MAX_WIDTH_STACKED_BAR_PERCENTAGE = 6;
export const CUSTOM_SPACING_STACKED_BAR__PERCENTAGE = 5;

export const METRIC_ORDER = {
  framework_adherence: 4,
  assurance_received: 0,
  reporting_boundary: 1,
  reporting_period: 2,
  climate_related: 3,
};

export const REPORTING_DISCLOSURE_METRIC_ORDER = {
  organizational_boundary: 0,
  reporting_standard: 1,
  reporting_period: 2,
  climate_disclosure_out_cdp: 3,
  verification_emissions: 4,
};

export const EXCEPTION_FOR_RISKS_OPPS_TILE = {
  Reported_Identified_Opportunities: "Reported Identified Opportunities",
  ReportedIdentifiedOpportunitiesFlag: "ReportedIdentifiedOpportunitiesCustom",
  NO_OPPORTUNITIES_AVAILABLE: "No Opportunities Available",
};

export const DEFAULT_EXPORT_VALIDATE_REPORTS_PAYLOAD = {
  exportFormat: 0,
  exportType: 15,
};

export const CONTENT_BENCHMARK_USER_ROLES: any = {
  1: "Owner",
  2: "Contributor",
};

export const COMPARISON_TAG_TYPE = 1;

export const CONTENT_COMPARISON_EVENT_NAME_TRACKS = {
  SHARE: "Share Content benchmark - ",
};
export const SHARE_COMPARISON_TYPE = {
  collaboration: 1,
  copy: 0,
};
export const SHARE_COMPARISON_ROLES = [
  { name: "Owner", id: 1 },
  { name: "Contributor", id: 2 },
  { name: "Remove", id: 0 },
];

export const INSIGHT_SOURCE = {
  SYP: "Source: S&P",
};

export const INSIGHT_CATEGORY = {
  CLIMATE: 3,
};

export const TAG_NOTIFICATION_ACTIONS = {
  CONTENT_BENCHMARK_DELETED: 15,
  USER_REMOVED: 14,
  ROL_CHANGEG: 13,
  USER_LEAVE: 12,
};

export const ACCESS_MODULES = {
  Feature_ESG_All: 0,
  Feature_Portal: 1,
  Feature_ESG_Search: 2,
  Feature_ESG_SavedSearch: 3,
  Feature_ESG_Comparison: 4,
  Feature_ESG_SavedShortlist: 5,
  Feature_ESG_Insights: 6,
  Feature_ESG_Dashboard: 7,
  Feature_ESG_PeerBenchmark: 8,
  Feature_ESG_Research_Downloads: 9,
  Feature_ESG_Workspace_Downloads: 10,
  Feature_ESG_ManageFiles: 20,
  Feature_ESG_HumanCapitalDashboard: 30,
};

export const ACCESS_MODULES_URL_MAPPING = {
  [searchPath]: ACCESS_MODULES.Feature_ESG_Search,
  [savedSearchesPath]: ACCESS_MODULES.Feature_ESG_SavedSearch,
  [comparisonPath]: ACCESS_MODULES.Feature_ESG_Comparison,
  [shortlistPath]: ACCESS_MODULES.Feature_ESG_SavedShortlist,
  [insightsPath]: ACCESS_MODULES.Feature_ESG_Insights,
  [dashboardPath]: ACCESS_MODULES.Feature_ESG_Dashboard,
  [peerBenchmarkPath]: ACCESS_MODULES.Feature_ESG_PeerBenchmark,
};

export const MERGE_TOOLTIP_TITLE: number[] = [
  30, 33, 49, 61, 62, 63, 64, 65, 66, 67, 68, 70, 75, 76, 83, 84, 85,
];

export const SOURCE_REGEX = [
  /Source: <link>(.*?)<\/link>/,
  /; <link>(.*?)<\/link>/,
  /<link>(.*?)<\/link>/,
];

export const UNITS = {
  WATER_INTENSITY: "m<sup>3</sup>/$M",
};

export const NORMAL_LINK_INDEX = [2];

export const NEUTRAL_VALUES = ["Neutral", "neutral"];
export const SCOPE3_DATA = {
  EMISSIONS: 0,
  RELEVANCE: 1,
};

export const SEPARATORS = {
  VALUE: "##VALUE##",
  FIELD: "##FIELDSEP##",
};

export const getCommaSeparatedValues = (data: string) =>
  data && data.split(",");

export const GLOBAL_SEARCH_TABS = {
  GROUP: "Group",
  SAVED_GROUPINGS: "Saved-groupings",
};

export const VENDOR_DETAILS = {
  cdp: {
    priority: 1,
    vendorId: 3,
    vendorName: "CDP",
    color: "#0D8390",
  },
  snp: {
    priority: 2,
    vendorId: 4,
    vendorName: "S&P",
    color: "#00A3E0",
  },
  msci: {
    priority: 3,
    vendorId: 1,
    vendorName: "MSCI",
    color: "#26890D",
  },
  sec: {
    priority: 4,
    vendorId: 2,
    vendorName: "SEC",
    color: "#26890D",
  },
};

export const COMPANY_TYPE = {
  BASELINE: "baseline",
  PEER: "peers",
};

export const COUNTRY_CODE = {
  US: "US",
};

export const COMPANY_LIMIT = {
  PEER: 8,
};

export const COMPANY_IDENTIFIER = {
  CIK_NUMBER: "cikNumber",
  GLOBAL_COMPANY_ID: "globalCompanyId",
};

export const INSIGHTS_QUARTER_DROPDOWN_YEAR_LIMIT = 10;

export const SOURCE_PAGE = {
  INSIGHTS: "insights",
  PEER_BENCHMARK: "peerBenchmark",
};

export const valueSeparator = "##VALUE##";
export const USER_PREFERENCE_TYPE = {
  PEER_BENCHMARK: 1,
};

export const COLLAPSABLE_SUBSECTION_SIZES: { [k: number]: number } = {
  1: 12, // Fullwidth
  2: 6, // Half-width
  3: 4, // One third width
  4: 3, // One quarter width
};

export const PERIOD = "period";
export const maintenanceMessages = "/maintenance-messages";
export const MANAGE_ADF_PIPELINE = "/manage-adf-pipelines";
export const MANAGE_FILES = "/managefiles";
export const INDICATOR_VALUES = {
  1: "__activeIndicator",
  2: "__upcomingIndicator",
  3: "__expiredIndicator",
};
export const USER_MANAGEMENT = "/user-management";
export const MODULE_ACCESS = "/module-access";
export const ADMIN_TABS = {
  USER_MANAGEMENT: 0,
  MODULE_ACCESS: 1,
  MANAGE_FILES: 2,
  MAP_FILES: 3,
  MAINTENANCE_MESSAGES: 4,
  MANAGE_ADF_PIPELINE: 5,
};

export const INVALID_CLASS_FOR_DATE_TIME_PICKER =
  ".react-datetime-picker__inputGroup__input:invalid";

export const GENERAL_ADMIN_ROUTES = [
  MANAGE_FILES,
  "/managefiles/map-files",
  maintenanceMessages,
];

export const MAX_CHARACTER_ALLOWED = {
  FOR_MAINTENANCE_MESSAGE: 250,
};

export const CONSTS_FOR_DATE_TIME_PICKER = {
  MONTH: "month",
  DAY: "day",
  YEAR: "year",
  MINUTE: "minute",
};

export const LOCALSTORAGE_VARIABLE = {
  closedActiveMaitenanceMessage: "closed-active-maintenance-message",
};

export type costChartPeerIndustryMetricsType = {
  metricName: string;
  metricValueBase: string;
  metricValuePeerIndustry: string;
  baseCompanyName: string;
  peerIndustry: string;
  metricKey?: String;
  metricTooltip?: any | AssociatedMetricTooltips[] | undefined;
};

export type costChartMetricDataDetailedType = {
  metricKey?: string;
  metricNameDirect: string;
  metricNameIndirect: string;
  metricValueIndirect: String;
  metricValueDirect: string;
  companyName: string;
  metricTooltipDirect?: any | AssociatedMetricTooltips[] | undefined;
  metricTooltipIndirect?: any | AssociatedMetricTooltips[] | undefined;
};

export const CARBON_CREDIT_NO_AVG_AVAILABLE_MESSAGE =
  "Refer to detailed peer view for additional details";

export const USER_PREFERENCES = {
  PEER_BENCHMARK: 1,
  INSIGHT_SECTION: 2,
};

export const ESRS_DEFAULT_ROW_TILE_GROUPS = [
  [
    BENCHMARK_TILE_TYPES.DIRECT_INDIRECT_COST,
    BENCHMARK_TILE_TYPES.TOXIC_EMISSIONS_WASTE_SCORE,
    BENCHMARK_TILE_TYPES.WATER_INTENSITY,
    BENCHMARK_TILE_TYPES.WATER_STRESS_SCORE,
  ],
  [
    BENCHMARK_TILE_TYPES.BIODIVERSITY_AND_LAND_USE_SCORE,
    BENCHMARK_TILE_TYPES.BIODIVERSITY_MANAGEMENT,
  ],
  [
    BENCHMARK_TILE_TYPES.WASTE,
    BENCHMARK_TILE_TYPES.RAW_MATERIAL_AND_SOURCING_SCORE,
  ],
  [
    BENCHMARK_TILE_TYPES.CLIMATE_RELATED_VALUE_CHAIN_ENGAGEMENT,
    BENCHMARK_TILE_TYPES.SUPPLY_CHAIN_LABOR_SCORE,
  ],
  [
    BENCHMARK_TILE_TYPES.SOCIAL_OPPORTINITIES_THEME_SCORE,
    BENCHMARK_TILE_TYPES.PRODUCT_SAFETY_AND_QUALITY_SCORE,
  ],
  [
    BENCHMARK_TILE_TYPES.BUSINESS_ETHICS_AND_FRAUD_SCORE,
    BENCHMARK_TILE_TYPES.CORPORATE_GOVERNANCE_THEME_SCORE,
    BENCHMARK_TILE_TYPES.GOVERNANCE_ESRS,
  ],
];

export const ESRS_WORKFORCE_ROW_TILE_GROUPS_COMPANY_VIEWS = [
  BENCHMARK_TILE_TYPES.HEALTH_AND_SAFETY,
  BENCHMARK_TILE_TYPES.LABOR_MANAGEMENT,
  BENCHMARK_TILE_TYPES.HUMAN_CAPITAL_DEVELOPMENT,
  BENCHMARK_TILE_TYPES.CLIMATE_RELATED_INCENTIVES,
];

export const ESRS_WORKFORCE_ROW_TILE_GROUPS_NON_COMPANY_VIEWS = [
  BENCHMARK_TILE_TYPES.HEALTH_AND_SAFETY,
  BENCHMARK_TILE_TYPES.LABOR_MANAGEMENT,
  BENCHMARK_TILE_TYPES.HUMAN_CAPITAL_DEVELOPMENT,
];

export const WORKFORCE_SUBSECTION_TITLE = "ESRS S1: Own workforce";
export const ESRS_SECTIONS_IDS = [1, 2, 3];
export const PDF_PPT_COLLAPSED_MESSAGE =
  "Please expand one or more sections on the dashboard to enable export functionality.";

export const EXCEPTION_FOR_SnP_METRICS_FILTER = [69];

export const EXCEPTIONAL_CARBON_CREDITS_METRIC_KEY =
  "Potential Credits Retired?";

export const WORKFORCE_TILE_GROUPNAME = "WorkforceRelatedScoresTile";

export const AI_CUSTOM_METRIC_NAME = "ESGI-UI-Engagement-Time";

export const AI_CUSTOM_METRIC_PAGE_NAME = "Page Name";

export const AI_COMPONENT_PATH_MAP: { [key: string]: string } = {
  "/dashboard": "Dashboard",
  "/insights": "Insights",
  "/peerbenchmark": "Peer Benchmark",
  "/search": "Search",
  "/shortlist": "Saved Shortlist",
  "/mysearches": "My Searches",
  "/comparisons": "Comparison",
  "/user-management": "Super Admin",
  "/search/shortlist": "Shortlist",
};
