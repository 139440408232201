import api from "../api.service";

export const fetchShortlist = (payload: any) =>
  api.post(`shortlists`, payload).then(({ data }) => data);

export const saveShortlist = (payload: {
  shortlistName: string;
  shortlistId: number;
}) => {
  return api.put(`/shortlists`, payload).then(({ data }) => data);
};

export const shareShortlist = (payload: {
  shortlistName: string;
  shortlistId: number;
  SharedWith: any;
  sourceShortlistId: number;
}) => {
  return api.post(`/shortlists/share`, payload).then(({ data }) => data);
};

// Remove shortlists contaning the shortlistId
export const removeShortlist = (payload: number) => {
  return api
    .delete(`shortlists?shortlistId=${payload}`)
    .then(({ data }) => data);
};

export const getUsers = (searchText: string) => {
  return api
    .post("users/search", {
      contentText: searchText,
      pageInfo: {
        pageNumber: 1,
        totalRecordCount: 25,
      },
    })
    .then(({ data }) => {
      if (data && data.data != null) return data.data;
      return [];
    })
    .catch((error) => {
      return [];
    });
};

// Get saved shortlist count
export const getCount = () => {
  return api.get(`/shortlists/shared/newCount`).then(({ data }) => data);
};
