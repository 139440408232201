import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { MouseEventHandler, useEffect, useState } from "react";
import Checkbox from "components/shared/checkbox/checkbox";

export type Slide = {
  name: string,
  imgUrl: string,
  alt: string,
}

interface SliderProps {
  slides: Slide[],
  slide?: Slide,
  nextButtonMsjId: string,
  previousButtonMsjId: string,
  skipButtonMsjId?: string,
  dontShowAgainMsjId?: string,
  finishButtonMsjId?: string,
  showAgain?: boolean,
  handleClose?: MouseEventHandler,
  handleDontShowAgain?: any,
  autoSlide?: number,
}

const Slider = (props: SliderProps) => {
  const {
    slides,
    slide,
    nextButtonMsjId,
    previousButtonMsjId,
    skipButtonMsjId,
    dontShowAgainMsjId,
    finishButtonMsjId,
    autoSlide,
    showAgain = false,
    handleClose = () => {},
    handleDontShowAgain = () => {},
  } = props;
  const BLOCK = 'slider-component';
  const [currentSlide, setCurrentSlide] = useState(slides[0]);
  const [shouldSlide, setShouldSlide] = useState(true);
  const currentSlideIndex = slides.indexOf(currentSlide);
  const isLastSlide = currentSlideIndex === slides.length - 1;
  const isFirstSlide = currentSlideIndex === 0;

  useEffect(() => {
    if (slide) setCurrentSlide(slide);
  }, [slide]);

  useEffect(() => {
    if(!autoSlide) return;

    const autoScrollIntervalId = setInterval(() => {
      const nextSlideIndex = currentSlideIndex === (slides.length - 1) ? 0 : currentSlideIndex + 1;
  
      if(shouldSlide) setCurrentSlide(slides[nextSlideIndex]);
    }, autoSlide * 1000);

    return () => { clearInterval(autoScrollIntervalId); }
  }, [autoSlide, setCurrentSlide, slides, currentSlideIndex, shouldSlide])


    


  return (
    <div className={BLOCK} data-testid="slider-component">
      <div className={`${BLOCK}__slides`}>
        {slides.map((slide) => (
          <div
            key={`filter-tips-${slide.name}`}
            className={classNames(`${BLOCK}__slide`, {
              [`${BLOCK}__slide--active`]: slide.name === currentSlide.name,
            })}
            onMouseEnter={() => { setShouldSlide(false); }}
            onMouseLeave={() => { setShouldSlide(true); }}
          >
            <img
              src={slide.imgUrl}
              alt={slide.alt}
              data-test="slide-image"
            />
          </div>
        ))}
      </div>
      <div className={`${BLOCK}__controls`}>
        {slides.map((slide) => (
          <button
            key={`control-${slide.name}`}
            className={classNames(`${BLOCK}__control`, {
              [`${BLOCK}__control--active`]: slide.name === currentSlide.name,
            })}
            onClick={() => setCurrentSlide(slide)}
            data-test="control-btn"
          />
        ))}
      </div>
      {
        dontShowAgainMsjId && (
          <div className={`${BLOCK}__do-not-show-checkbox`}>
            <Checkbox value={showAgain} onChange={handleDontShowAgain} />
            <FormattedMessage id={dontShowAgainMsjId} />
          </div>
        )
      }
      <div className={`${BLOCK}__next-prev`}>
        {!isFirstSlide && (
          <button
            className={`${BLOCK}__button ${BLOCK}__previous`}
            onClick={() => setCurrentSlide(slides[currentSlideIndex - 1])}
            data-test="previous-btn"
          >
            <FormattedMessage id={previousButtonMsjId} />
          </button>
        )}
        {!isLastSlide && skipButtonMsjId && (
          <button
            className={`${BLOCK}__button ${BLOCK}__next`}
            onClick={handleClose}
            data-test="skip-btn"
          >
            <FormattedMessage id={skipButtonMsjId} />
          </button>
        )}
        {!isLastSlide && (
          <button
            className={`${BLOCK}__button ${BLOCK}__next`}
            onClick={() => setCurrentSlide(slides[currentSlideIndex + 1])}
            data-test="next-btn"
          >
            <FormattedMessage id={nextButtonMsjId} />
          </button>
        )}
        {isLastSlide && finishButtonMsjId && (
          <button
            className={`${BLOCK}__button ${BLOCK}__next`}
            onClick={handleClose}
            data-test="finish-btn"
          >
            <FormattedMessage id={finishButtonMsjId} />
          </button>
        )}
      </div>
    </div>
  );
}

export default Slider;
