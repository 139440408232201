import FormattedMessage from "../formatted-message/formatted-message";
import classNames from "classnames";
import Icon from "../icon/icon";
import { useDispatch } from "react-redux";
import { Visualization } from "../../../services/dashboard/dashboard.model";
import { showTooltip } from "../../../services/commons.service";
import { TooltipPosition } from "../tooltip/tooltip";

type props = {
  editedVisualizationsState: Visualization[];
  setEditedVisualizationsState: Function;
};
const ShowHideTile = ({
  editedVisualizationsState,
  setEditedVisualizationsState,
}: props) => {
  const BLOCK = "ShowHideTile";
  const dispatch = useDispatch();

  const handleMouseEnter = (e: any) => {
    const titleElement = e.target.closest("div");
    const textElement = titleElement
      ? titleElement.querySelector("span")
      : null;

    if (!titleElement || !textElement) {
      return;
    }

    if (
      textElement.getBoundingClientRect().width >
      titleElement.getBoundingClientRect().width
    ) {
      dispatch(
        showTooltip({
          children: <>{textElement.textContent}</>,
          position: TooltipPosition.top,
          customPosition: true,
          elementDimensions: titleElement.getBoundingClientRect(),
        })
      );
    }
  };

  const handleMouseLeave = (e: any) => {
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
      })
    );
  };

  return (
    <div
      className={`${BLOCK}__current-benchmarks`}
      data-testid="show-hide-tile-container"
    >
      <div className={`${BLOCK}__current-benchmarks-heading-text`}>
        <FormattedMessage id="select.chart.type" />
      </div>
      <div className={`${BLOCK}__current-benchmarks-charts-list`}>
        {editedVisualizationsState
          .filter((vis) => !vis.isDetailedView)
          .map((vis, i) => {
            return (
              <div
                className={classNames(`${BLOCK}__benchmark-show-wrapper`, {
                  [`${BLOCK}__benchmark-show-wrapper-hidden`]: vis.isHidden,
                })}
                key={i}
              >
                <div className={`${BLOCK}__benchmark-show-title`}>
                  <Icon name="chart" width={15} height={15} />
                  <div
                    className={`${BLOCK}__benchmark-show-title-text`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    data-testid="vis-title"
                  >
                    <span>{vis.benchmarkTitle}</span>
                  </div>
                </div>
                {editedVisualizationsState.length > 1 && (
                  <input
                    type="checkbox"
                    checked={!vis.isHidden}
                    onChange={() => {
                      const visibleChartCount =
                        editedVisualizationsState.filter(
                          (vis: Visualization) => !vis.isHidden
                        ).length;

                      vis.isHidden =
                        !vis.isHidden && visibleChartCount === 1
                          ? false
                          : !vis.isHidden;
                      let detailedViewTileIndex =
                        editedVisualizationsState.findIndex(
                          (normalVis: Visualization) =>
                            normalVis.benchmarkTitle === vis.benchmarkTitle &&
                            normalVis.isDetailedView
                        );

                      if (detailedViewTileIndex !== -1) {
                        editedVisualizationsState[
                          detailedViewTileIndex
                        ].isHidden = vis.isHidden;
                      }

                      setEditedVisualizationsState([
                        ...editedVisualizationsState,
                      ]);
                    }}
                    data-testid="checkbox"
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default ShowHideTile;
