import { BaseModel, errorModel } from "../../utils/redux.utils";
import { FILTER_OPTIONS } from "utils/constants";
import { CompanyFilter } from "services/search/filters.model";

export interface DashboardState {
  error?: errorModel;
  loading: boolean;
  sideDashboardLoading: boolean;
  showSideDashboard: boolean;
  showEditBenchmarkTab: boolean;
  selectedCheckboxIds: number[];
  selectedGroupCheckboxIds: number[];
  checkboxdata: CustomMetricsData[];
  loadingFilter: FILTER_OPTIONS[];
  companyFilterOptions: CompanyFilter[];
  peerCompanyFilterOptions: CompanyFilter[];
  companyFilter: CompanyFilter[];
  peerCompanyFilter: CompanyFilter[];
  suggestedPeerCompanyFilter: SuggestPeerCompanyFilter[];
  selectedItems: number[];
  visualizations: VisualizationData;
  applyBenchmark: boolean;
  editBenchmarkData: EditBenchMarkData;
  deleteCustomBenchMarkPopUp: boolean;
  refreshDashboard: boolean;
  executeScrollDuplicatedTile: boolean;
  benchmarkToFocus: number;
  selectedPeerGroupItems: number[];
}

export class DashboardModel extends BaseModel {
  constructor() {
    super({
      sideDashboardLoading: false,
      loading: false,
      showSideDashboard: false,
      showEditBenchmarkTab: false,
      selectedCheckboxIds: [],
      selectedGroupCheckboxIds: [],
      checkboxdata: [],
      loadingFilter: [],
      companyFilterOptions: [],
      peerCompanyFilterOptions: [],
      companyFilter: [],
      peerCompanyFilter: [],
      suggestedPeerCompanyFilter: [],
      selectedItems: [],
      visualizations: [],
      applyBenchmark: false,
      editBenchmarkData: null,
      deleteCustomBenchMarkPopUp: false,
      refreshDashboard: false,
      executeScrollDuplicatedTile: false,
      benchmarkToFocus: null,
      selectedPeerGroupItems: [],
    });
  }

  setLoading = (loading: boolean): DashboardState => this.merge({ loading });

  setEditBenchmarkData = (
    editBenchmarkData: EditBenchMarkData
  ): DashboardState => this.merge({ editBenchmarkData });

  setSideDashboardLoading = (sideDashboardLoading: boolean): DashboardState =>
    this.merge({ sideDashboardLoading });

  setShowSideDashboard = (showSideDashboard: boolean): DashboardState =>
    this.merge({ showSideDashboard });

  setShowEditBenchmark = (showEditBenchmarkTab: boolean): DashboardState =>
    this.merge({ showEditBenchmarkTab });

  setSelectedCheckboxIds = (selectedCheckboxIds: number[]): DashboardState =>
    this.merge({ selectedCheckboxIds });

  setSelectedGroupCheckboxIds = (
    selectedGroupCheckboxIds: number[]
  ): DashboardState => this.merge({ selectedGroupCheckboxIds });

  setCheckboxData = (checkboxdata: CustomMetricsData[]): DashboardState =>
    this.state.checkboxData && this.state.checkboxData.length
      ? this.merge({})
      : this.merge({ checkboxdata });

  setApplyBenchmark = (applyBenchmark: boolean): DashboardState =>
    this.merge({ applyBenchmark });

  setLoadingFilter = (loadingFilter: FILTER_OPTIONS): DashboardState =>
    this.merge({ loadingFilter: [...this.state.loadingFilter, loadingFilter] });

  loadCompanyFilterOptions = (
    companyFilterOptions: CompanyFilter[]
  ): DashboardState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      companyFilterOptions,
    });

  loadPeerCompanyFilterOptions = (
    peerCompanyFilterOptions: CompanyFilter[]
  ): DashboardState => {
    return this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      peerCompanyFilterOptions,
    });
  };

  loadSuggestedPeerCompanyFilterOptions = (
    suggestedPeerCompanyFilter: SuggestPeerCompanyFilter[]
  ): DashboardState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      suggestedPeerCompanyFilter,
    });

  setCompanyFilter = (companyFilter: CompanyFilter[]): DashboardState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      companyFilter,
      peerCompanyFilter:
        companyFilter.length !== 0
          ? this.state.peerCompanyFilter.filter(
              (company: CompanyFilter) =>
                company.cikNumber !== companyFilter[0].cikNumber
            )
          : this.state.peerCompanyFilter,
      selectedItems:
        companyFilter.length !== 0
          ? this.state.selectedItems.filter(
              (item: number) => item !== companyFilter[0].cikNumber
            )
          : this.state.selectedItems,
    });

  setPeerCompanyFilter = (
    peerCompanyFilter: CompanyFilter[]
  ): DashboardState => {
    return this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      peerCompanyFilter: peerCompanyFilter,
    });
  };

  setSelectedItems = (selectedItems: number[]): DashboardState =>
    this.merge({ selectedItems });
  setError = (
    error: errorModel,
    loadingFilter?: FILTER_OPTIONS
  ): DashboardState => {
    let state = this.merge({ loading: false, error });
    if (loadingFilter) {
      state = this.merge({
        loadingFilter: this.state.loadingFilter.filter(
          (filter: FILTER_OPTIONS) => filter !== loadingFilter
        ),
      });
    }

    return state;
  };

  setBenchmark = (type: number): DashboardState =>
    type === 0
      ? this.merge({
          selectedCheckboxIds: [],
          loadingFilter: [],
          companyFilterOptions: [],
          peerCompanyFilterOptions: [],
          companyFilter: [],
          peerCompanyFilter: [],
          suggestedPeerCompanyFilter: [],
          selectedItems: [],
          selectedGroupCheckboxIds: [],
          editBenchmarkData: null,
        })
      : this.merge({
          loadingFilter: [],
          companyFilterOptions: [],
          peerCompanyFilterOptions: [],
          companyFilter: [],
          peerCompanyFilter: [],
          suggestedPeerCompanyFilter: [],
          selectedItems: [],
        });

  setVisualizations = (visualizations: Visualization[]): DashboardState => {
    let tmpBenchmarks = visualizations
      .sort(
        (a: Visualization, b: Visualization) => a.displayOrder - b.displayOrder
      )
      .map((vis: Visualization, index: number) => {
        return { ...vis, displayOrder: index + 1 };
      });

    return this.merge({
      visualizations: {
        benchmarks: tmpBenchmarks,
      },
    });
  };

  setDeleteCustomBenchMarkPopUp = (
    deleteCustomBenchMarkPopUp: boolean
  ): DashboardState => this.merge({ deleteCustomBenchMarkPopUp });

  setRefreshDashboard = (refreshDashboard: boolean): DashboardState =>
    this.merge({ refreshDashboard });

  setExecuteScrollDuplicatedTile = (
    executeScrollDuplicatedTile: boolean
  ): DashboardState => this.merge({ executeScrollDuplicatedTile });

  setBenchmarkToFocus = (benchmarkToFocus: number): DashboardState =>
    this.merge({ benchmarkToFocus });

  setSelectedPeerGroupItems = (
    selectedPeerGroupItems: number[]
  ): DashboardState => this.merge({ selectedPeerGroupItems });
}

export type VisualizationData = {
  // predefinedBenchmarks: Visualization[];
  // customBenchmarks: Visualization[];
  benchmarks: Visualization[];
};

export type Visualization = {
  benchmarkTitle: string;
  associatedMetricsTooltips: AssociatedMetricTooltips[];
  benchmarkId: number;
  benchmarkTileType: number;
  benchmarkType: number;
  displayOrder: number;
  isDefault: boolean;
  isHidden: boolean;
  IsDetailedPeerViewSelected: boolean;
  predefinedBenchmarkId: number;
  benchmarkMetadata: Metadata;
  description?: any;
  isDetailedView?: boolean;
};

export type AssociatedMetricTooltips = {
  associatedMetric: string;
  associatedMetricGroupName?: string;
  associatedMetricDisplayName: string;
  associatedMetricTooltipText: string;
  associatedMetricGroupTooltipText?: string;
  displayOrder: number;
  tooltipType: number;
  predefinedBenchmarkAssociatedMetricId?: number,
  predefinedBenchmarkId? : number
};

export type Metadata = {
  benchmarkValue: string;
  benchmarkTitle: string;
  benchmarkSubTitle?: string;
  benchmarkTitleTooltip: string;
  benchmarkInformationIconToolTip?: string;
  actionUrl: string;
  associatedMetric: string;
  associatedMetrics: string[];
  associatedMetricsTooltip?: string[];
  associatedMetricsValueTooltip?: string[];
  associatedMetricsDisplayNames: string[];
  associatedMetricsDisplayName?: string[];
  bechmarkStyleOptions: string[];
  displayOrder: number;
  fieldValueNames: string[];
  isDefault: boolean;
  legends: Legend[];
  filters: VisualizationFilter[];
  predefinedBenchmarkId: number;
  groupProperties: string[];
  api: {
    endPoint: string;
    placeHolders: string[];
    selectedIndustry: any;
    payload?: any;
  };
  displayPropertyNames: string[];
  hScale: number | number[];
  vScale: number | number[];
  axis: {
    primary: string[];
    secondary: string[];
  };
  selectedStyleOption: string;
  selectedChartOption?: string;
  selectedMetricOption?: string;
  hideMSCIDataSourceLabel: boolean;
  source?: string[];
  sourceTooltip?: string[];
  colorCodes?: string[];
  conditionalColorCodes?: string[];
  hideTooltipLegendValue?: boolean;
  fieldValueLimit?: number;
  fieldValuePercentLimit?: number;
  graphOrientation?: string;
  valueLimitProperties?: {
    associatedMetricRef: string;
    fieldValueLimit: number;
    fieldValueSuffix: string;
    showSubLabel: boolean;
  }[];
  snpMetrics?: string[];
};

export type VisualizationFilter = {
  label: string;
  values: any[];
  columnName?: string;
};

export type BubbleChartDataset = {
  xProperty: number;
  zProperty: number;
  yProperty: string;
  id: string;
};

export type GroupedLegends = {
  groupName: string;
  groupPrefix?: string;
  description?: string | AssociatedMetricTooltips[];
  subtitle?: string;
  legends: Legend[];
  separateBars?: boolean;
};

export type Legend = {
  legendValue: string;
  legendOrder: number;
  legendColor: string;
  legendData: number | any;
  legendTitle?: string;
  legendTooltip?: string | AssociatedMetricTooltips[];
  legendParent?: string;
  legendHideData?: boolean;
  reportId?: number;
  legendDataPercent?: string;
};

export type GhgEmissionPieChartLegend = {
  groupName: string;
  legends: Legend[];
};

export type TrendChartDataset = {
  metrics: any;
  data: any;
};

export type TabularChartDataset = {
  metrics: TabularMetrics[];
  data: any;
  tileType?: any;
  tileView?: any;
  isDetailView?: any;
};

export type TabularMetrics = {
  metric: string;
  description: AssociatedMetricTooltips[];
  metricKey?: string;
};

export type SpeedometerTileDataset = {
  title: string;
  valueSufix?: string;
  limit: number;
  value: number;
  avgValue: number;
  tooltip: string;
  label: string;
  subLabel?: string;
  speedometerValues: SpeedometerChartDataset[];
  speedometerAverage: SpeedometerChartDataset[];
};

export type SpeedometerChartDataset = {
  id?: string;
  tooltip?: string;
  order: number;
  value: number;
  color: string[];
};

export type VerticalBarChartLegend = {
  label: string;
  displayName: string;
  order: number;
  value: string;
  color: string;
  tooltip: string;
  data?: number | any;
};

export type SuggestPeerCompanyFilter = {
  companyName: string;
  companyId: number;
  externalCompanyId: number;
  cikNumber: number;
  ticker: string;
  esgRating?: {
    esgRatingValue: string;
    order: number;
    displayColor: string;
  };
  governanceScore?: number;
  totalAssets: number;
  mrMarketCap: number;
  mrfyRevenue: number;
  auditor: {
    auditorId: number;
    auditorName: string;
    auditorNumber: number;
    externalAuditorId: number;
  };
  isSuggestedPeerCompany?: boolean;
  ivaCompanyRating: string;
  corpGovernanceScore: number;
  globalCompanyId?: number;
};

export type TableChartDataset = {
  labels: any[];
  response: any;
};

export type BenchmarkCompanies = {
  cikNumber: number;
  isBaseCompany: boolean;
  displayOrder: number;
  isSuggestedPeerCompany: boolean;
  globalCompanyId?: number;
  company?: {
    companyId: number;
    companyName: string;
    cikNumber: number;
    globalCompanyId?: number;
  };
  globalCompany?: {
    companyName: string;
    cikNumber?: number;
    globalCompanyId?: number;
  };
};

export type SelectedMetrics = {
  fields: number[];
  fieldGroups: number[];
};

export type EditBenchMarkData = {
  benchmarkId: number;
  benchmarkType: number;
  benchmarkTitle: string;
  companyFilter: EditCompany;
  peerCompanyFilter: EditCompany[];
  metrics: string[];
  benchmarkMetadata: any;
  description: any;
};

export type EditCompany = {
  cikNumber: number;
  companyName: string;
  companyId: number;
};

export type FieldMetricData = {
  fieldId: number;
  fieldName: string;
  vendorFieldName: null;
  description: string;
  displayName: string;
  displayOrder: number;
  dataType: null;
  dataFormat: null;
  vendorId: number;
  fieldValue: any;
};

export type FieldGroupMetricData = {
  fieldGroupId: number;
  fieldGroupName: string;
  displayName: string;
  displayOrder: number;
  fields: FieldMetricData[];
};

export type CustomMetricsData = {
  metricCategoryID: number;
  metricCategoryName: string;
  displayName: string;
  displayOrder: number;
  fields: any;
  fieldGroups: any;
};

export type CDPScopeTabularDataset = {
  companyName: string;
  categories: CDPCategory[];
  displayOrder: number;
  isBaseCompany?: boolean;
  globalCompanyId: number;
};

export type CDPCategory = {
  fieldNameId: string;
  fieldValue: number | string;
  fieldName: string;
  displayOrder: number;
};
