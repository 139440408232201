import { useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCompanyFilter,
  setCompanyFilter,
  loadDocumentTypeFilter,
  setDocumentTypeFilter,
  loadGoverningBoardFilter,
  setGoverningBoardFilter,
  setIndustryFilter,
  loadIndustryFilter,
  loadContentFilter,
  setContentFilter,
  setAuditorFilter,
  loadAuditorFilter,
  loadPeriodFilter,
  setPeriodFilter,
  setContentValue,
  setShowFilters,
  loadCompanyIdFilter,
  setLoaded,
  loadMultipleDocumentTypeFilter,
  fetchSuggestedPeerCompaniesByCompanyFilter,
  updateSelectedPeerGroupItems,
  updateSelectedItemsMultiple,
} from "services/search/filters.service";
import {
  loadEsgSearchResults,
  loadSecSearchResults,
} from "services/search/search.results.service";
import { RootStore } from "services/store.service";
import {
  COMPANY_IDENTIFIER,
  DEFAULT_PEER_GROUP_LIST_PAYLOAD,
  ESGsortResults,
  FILTER_OPTIONS,
  PEER_GROUP_CREATION_PAGE,
  POPULAR_SEARCHES,
  SECsortResults,
  TRACKING_IDS,
} from "utils/constants";
import {
  AuditorFilter,
  CompanyFilter,
  FormTypeFilter,
  GoverningBoardFilter,
  IndustryFilter,
  PeriodFilter,
  PopularSearchFilter,
  SelectedCheckboxMultiple,
  SuggestedPeerCompaniesByCompanyFilter,
  SuggestPeerCompanyFilterMultiple,
} from "services/search/filters.model";

import Icon from "components/shared/icon/icon";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import DropDown from "./drop-down";
import Modal from "components/shared/modal/modal";
import FilterTips, { FilterTipsPage } from "./filters.tips";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import {
  setSelectedDisclosure,
  setSelectedReport,
} from "services/document-viewer/document-viewer.service";
import { useLocation } from "react-router";
import { fetchCompany } from "services/search/search.api";
import { addToastMessage } from "services/commons.service";
import {
  executeSavedSeach,
  executeSharedSeach,
  getSharedSearchCount,
  loadCurrentSavedSearch,
  resetSavedSearch,
  savedSearchResult,
  saveNewSearch,
} from "services/searchlist/searchlist.service";
import { executeSavedSearch } from "services/searchlist/searchlist.api";
import Button from "components/shared/button/button";
import classNames from "classnames";
import SuggestedPeers from "components/shared/suggested-peers/suggested-peers";
import {
  getGlobalPeerCompanyFilter,
  loadSavedPeerGroupList,
  loadSuggestedPeerCompanies,
  peerGroupSelectionTracking,
  savePeerGroup,
  setPeerGroupSavedSuccess,
} from "services/insights/insights.service";
import SavedPeerModal from "components/shared/save-peer-modal/save-peer-modal";
import {
  CompanyGroupCompany,
  PeerGroup,
  peerGroupCompanies,
  savePeerGroupNamePayload,
} from "services/insights/insights.model";
type filterProps = {
  BLOCK: string;
};

type hiddenFiltersProps = {
  showFilters: () => void;
  BLOCK: string;
  activeFilters: number;
  reportOrDisclosureSelected: boolean;
};

const HiddenFilters = ({
  BLOCK,
  showFilters,
  activeFilters,
  reportOrDisclosureSelected,
}: hiddenFiltersProps) => {
  if (!reportOrDisclosureSelected) {
    return (
      <div
        className={`${BLOCK}__filter-side`}
        onClick={() => showFilters()}
        data-testid="show-filters"
      >
        <Icon
          name="filter"
          width={14}
          height={14}
          className={`${BLOCK}__show-filters`}
        />
        {activeFilters > 0 && (
          <span
            className={`${BLOCK}__active-filters ${BLOCK}__active-filters--hidden`}
          >
            {activeFilters}
          </span>
        )}
        <span className={`${BLOCK}__filter-span ${BLOCK}__filter-span--hidden`}>
          <FormattedMessage id="search.filters" />
        </span>
      </div>
    );
  } else {
    return <div data-testid="empty-filter"></div>;
  }
};

const Filters = ({ BLOCK }: filterProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchResultsState = useSelector(
    (state: RootStore) => state.searchResults
  );
  const searchState = useSelector((state: RootStore) => state.filters);
  const shortListState = useSelector((state: RootStore) => state.shortlist);
  const searchListState = useSelector((state: RootStore) => state.searchlist);
  const insightsState = useSelector((state: RootStore) => state.insights);
  const commonsState = useSelector((state: RootStore) => state.commons);
  const [showModal, setShowModal] = useState(false);
  const [filterTipsPage, setFilterTipsPage] = useState<FilterTipsPage>();
  const [savedPeers, setSavedPeers] = useState<boolean>(false);
  const [selectedPeerCompany, setSelectedPeerCompany] = useState<
    CompanyFilter[]
  >([]);
  const [documentViewerSelected, setDocumentViewerSelected] = useState(false);
  const filtersRef = useRef<HTMLDivElement>(null);
  const documentViewerState = useSelector(
    (state: RootStore) => state.documentViewer
  );
  const [showSuggestedPeers, setShowSuggestedPeers] = useState<boolean>(false);
  const [triggerSuggestedPeers, setTriggerSuggestedPeers] =
    useState<boolean>(false);
  const [suggestedPeerCompaniesGroup, setsuggestedPeerCompaniesGroup] =
    useState<any>([]);

  const [peerGroupsLoaded, setPeerGroupsLoaded] = useState<boolean>(false);

  const [companiesUnselected, setCompaniesUnselected] = useState<number[]>([]);

  useEffect(() => {
    dispatch(updateSelectedPeerGroupItems([]));
    dispatch(updateSelectedItemsMultiple([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(loadSuggestedPeerCompanies([]));
    dispatch(loadSavedPeerGroupList(DEFAULT_PEER_GROUP_LIST_PAYLOAD));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (insightsState.savedPeerGroupList && !peerGroupsLoaded) {
      setPeerGroupsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.savedPeerGroupList]);

  useEffect(() => {
    if (
      searchState.companyFilter.length > 0 &&
      searchState.loadingFilter.filter(
        (filter) => filter === FILTER_OPTIONS.SUGGESTED_PEER
      ).length === 0 &&
      triggerSuggestedPeers
    ) {
      setsuggestedPeerCompaniesGroup(
        searchState.suggestedPeerCompanyByCompanyFilter
      );
      let companiesAdded = searchState.companyFilter.map(
        (val: CompanyFilter) => val.cikNumber
      );

      // DEFAULT SELECTION FOR COMPANIES ADDED TO COMPANY FILTER
      // SUGGESTED PEERS
      let tmpSelectedCheckboxMultiple = [...searchState.selectedItemsMultiple];
      searchState.suggestedPeerCompanyByCompanyFilter
        .filter((suggestedGroup: SuggestedPeerCompaniesByCompanyFilter) =>
          suggestedGroup.suggestedPeerCompanyFilter
            .map((comp: SuggestPeerCompanyFilterMultiple) => comp.cikNumber)
            .find((cik: number) => companiesAdded?.includes(cik))
        )
        .forEach((spf: SuggestedPeerCompaniesByCompanyFilter) => {
          let currentIndex = -1;
          let selectedCheckboxCompanyExist =
            searchState.selectedItemsMultiple.find(
              (item: SelectedCheckboxMultiple, i: number) => {
                if (item.parentCikNumber === spf.cikNumber) {
                  currentIndex = i;
                  return item.parentCikNumber === spf.cikNumber;
                }
                return false;
              }
            );
          if (selectedCheckboxCompanyExist) {
            let missingCompanies = spf.suggestedPeerCompanyFilter
              .filter(
                (comp: SuggestPeerCompanyFilterMultiple) =>
                  companiesAdded?.includes(comp.cikNumber) &&
                  !selectedCheckboxCompanyExist?.selectedCompanies.includes(
                    comp.cikNumber
                  )
              )
              .map((c: SuggestPeerCompanyFilterMultiple) => c.cikNumber);

            tmpSelectedCheckboxMultiple[currentIndex].selectedCompanies =
              selectedCheckboxCompanyExist.selectedCompanies.concat(
                missingCompanies
              );
          } else {
            let missingCompanies = spf.suggestedPeerCompanyFilter
              .filter((comp: SuggestPeerCompanyFilterMultiple) =>
                companiesAdded?.includes(comp.cikNumber)
              )
              .map((c: SuggestPeerCompanyFilterMultiple) => c.cikNumber);

            tmpSelectedCheckboxMultiple.push({
              parentCikNumber: spf.cikNumber,
              selectedCompanies: missingCompanies,
            });
          }
        });
      dispatch(updateSelectedItemsMultiple(tmpSelectedCheckboxMultiple));
      setCompaniesUnselected([]);
      setShowSuggestedPeers(true);
      setTriggerSuggestedPeers(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchState.suggestedPeerCompanyByCompanyFilter,
    peerGroupsLoaded,
    triggerSuggestedPeers,
  ]);

  useEffect(() => {
    if (!searchState.loaded) {
      const abortController = new AbortController();
      dispatch(loadContentFilter(""));
      dispatch(loadGoverningBoardFilter());
      dispatch(loadPeriodFilter());
      dispatch(loadIndustryFilter());
      dispatch(loadAuditorFilter());
      dispatch(loadDocumentTypeFilter());
      return () => {
        abortController.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !searchState.loaded &&
      searchState.auditorFilterOptions.length &&
      searchState.contentFilterOptions.length &&
      searchState.documentTypeFilterOptions.length &&
      searchState.periodFilterOptions.length &&
      searchState.governingBoardFilterOptions.length &&
      searchState.industryFilterOptions.length
    ) {
      dispatch(setLoaded());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState]);

  useEffect(() => {
    setSelectedPeerCompany(searchState.companyFilter);
  }, [searchState.companyFilter, savedPeers]);

  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(location.search);
      const companyId = parseInt(queryParams.get("companyId") as string);
      const reportYear = queryParams.get("reportYear") || undefined;
      const formTypeName = queryParams.get("formTypeName") || undefined;
      let companyData = null;
      let documentTypes = formTypeName ? formTypeName.split(",") : [];

      if (documentTypes.length > 1) {
        dispatch(loadMultipleDocumentTypeFilter(documentTypes));
      } else if (formTypeName) {
        dispatch(loadDocumentTypeFilter(formTypeName));
      }
      if (reportYear) dispatch(loadPeriodFilter(reportYear));

      if (companyId) {
        const companyCkiNumbers = searchState.companyFilter?.map(
          (company) => company.cikNumber
        );
        const rawCompanyData = await fetchCompany(companyId);
        companyData =
          rawCompanyData &&
          rawCompanyData.data.find(
            (cmp: CompanyFilter) => cmp.cikNumber === companyId
          );

        if (companyCkiNumbers && !companyCkiNumbers.includes(companyId)) {
          if (companyData) {
            selectCompany(companyData);
            dispatch(loadCompanyIdFilter(companyData));
          } else {
            dispatch(
              addToastMessage({
                description: <FormattedMessage id="filter.company.not-found" />,
              })
            );
          }
        }
      }

      const searchPayload = {
        ...(companyData ? { companyData } : {}),
        ...(reportYear ? { reportYear } : {}),
        ...(formTypeName
          ? {
              formTypeNames:
                documentTypes.length > 1 ? documentTypes : [formTypeName],
            }
          : {}),
      };
      if (Object.entries(searchPayload).length) submitSearch(searchPayload);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Use Effect to setup the Filters and Execute Search when clicking on any Saved Search in My Searches
  useEffect(() => {
    const locationState = location.state as any;
    if (locationState && locationState.searchCriteria && searchState.loaded) {
      setExistingFilters(locationState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, searchState.loaded]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reportId = parseInt(queryParams.get("reportId") as string);
    if (
      !documentViewerSelected &&
      reportId > 0 &&
      searchResultsState.esgResults &&
      searchResultsState.esgResults.length &&
      searchResultsState.esgResults[0].reports &&
      searchResultsState.esgResults[0].reports.length
    ) {
      const report = searchResultsState.esgResults[0].reports.find(
        (r) => r.reportId === reportId
      );
      setDocumentViewerSelected(true);
      if (report) {
        dispatch(setSelectedReport(report));
      } else {
        dispatch(
          addToastMessage({
            description: <FormattedMessage id="filter.report.not-found" />,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, documentViewerSelected, searchResultsState]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const savedSearchId = queryParams.get("savedSearchId");

    if (savedSearchId) {
      dispatch(loadCurrentSavedSearch(savedSearchId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (searchListState.currentSavedSearch && searchState.loaded) {
      setExistingFilters(searchListState.currentSavedSearch);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchListState.currentSavedSearch, searchState.loaded]);

  const setExistingFilters = async (objectToSearch: any) => {
    resetSearch();

    // Setting Content Text
    if (objectToSearch.searchCriteria.contentText !== "") {
      changeContentValue(objectToSearch.searchCriteria.contentText);
    }

    // Setting Form Types
    if (objectToSearch.searchCriteria.formTypeNames.length > 0) {
      let formtypes: FormTypeFilter[] = [];
      searchState.documentTypeFilterOptions.forEach((type, index) => {
        type.formTypes.forEach((form) => {
          if (
            objectToSearch.searchCriteria.formTypeNames.includes(
              form.formTypeName
            )
          ) {
            formtypes.push({ ...form, parentId: index + 1 });
          }
        });
      });
      dispatch(setDocumentTypeFilter(formtypes));
    }

    // Setting Industry Filter
    let externalIndustyIds: string[] = [];
    if (objectToSearch.searchCriteria.industryIds.length > 0) {
      let industries: IndustryFilter[] = [];
      searchState.industryFilterOptions.forEach((industry) => {
        industry.sectors!.forEach((sector) => {
          if (
            objectToSearch.searchCriteria.industryIds.includes(
              sector.industryId
            )
          ) {
            industries.push({
              ...sector,
              parentId: industry.industryName!,
            });
            externalIndustyIds.push(sector.externalIndustryId);
          }
        });
      });
      dispatch(setIndustryFilter(industries));
    }

    //  Esg Rating filter is hidden for now
    //Setting ivaCompanyRatings Filter
    // if (objectToSearch.searchCriteria.ivaCompanyRatings?.length > 0) {
    //   let esgRatingFilter: ESGRatingFilter[] =
    //     searchState.esgRatingFilterOptions.filter((esgRating) =>
    //       objectToSearch.searchCriteria.ivaCompanyRatings.includes(
    //         esgRating.ivaCompanyRating
    //       )
    //     );
    //   dispatch(setESGRatingFilter(esgRatingFilter));
    // }

    // Setting Period Filter
    if (objectToSearch.searchCriteria.years.length > 0) {
      let periods: PeriodFilter[] = searchState.periodFilterOptions.filter(
        (period) =>
          objectToSearch.searchCriteria.years.includes(
            parseInt(period.periodYear)
          )
      );
      dispatch(setPeriodFilter(periods));
    }

    // Setting Auditor Filter
    let externalAuditorIds: number[] = [];
    if (objectToSearch.searchCriteria.auditorIds.length > 0) {
      const auditors: AuditorFilter[] = [];
      const audIds: number[] = [];
      const externalAudIds: number[] = [];
      objectToSearch.searchCriteria.auditorIds.forEach(
        (audId: number, index: number) => {
          audId > 0
            ? audIds.push(audId)
            : externalAudIds.push(
                objectToSearch.searchCriteria.externalAuditorIds[index]
              );
        }
      );
      searchState.auditorFilterOptions.forEach((auditor) => {
        if (
          audIds.includes(auditor.auditorId) ||
          externalAudIds.includes(auditor.externalAuditorId)
        ) {
          auditors.push(auditor);
          externalAuditorIds.push(auditor.externalAuditorId);
        }
      });

      dispatch(setAuditorFilter(auditors));
    }

    if (objectToSearch.searchCriteria.governingBoardIds.length > 0) {
      let governingBoardIds: GoverningBoardFilter[] = [];
      searchState.governingBoardFilterOptions.forEach((governingBoard) => {
        if (
          objectToSearch.searchCriteria.governingBoardIds.includes(
            governingBoard.governingBoardId
          )
        ) {
          governingBoardIds.push(governingBoard);
        }
      });
      dispatch(setGoverningBoardFilter(governingBoardIds));
    }

    // Setting Company Filter
    if (objectToSearch.searchCriteria.companyIds.length > 0) {
      let companiesPromises: any[] = [];
      objectToSearch.searchCriteria.companyIds.forEach((companyId: number) => {
        let newPromise = new Promise((resolve, reject) => {
          fetchCompany(companyId).then((company) => {
            const companyData = company.data.find(
              (cmp: CompanyFilter) => cmp.cikNumber === companyId
            );
            if (companyData) {
              dispatch(loadCompanyIdFilter(companyData));
              resolve(companyData);
            }
          });
        });
        companiesPromises.push(newPromise);
      });
      await Promise.all(companiesPromises).then((values) => {
        dispatch(setCompanyFilter(values));
        submitSearch({
          companyIds: objectToSearch.searchCriteria.companyIds,
          reportYears: objectToSearch.searchCriteria.years,
          formTypeNames: objectToSearch.searchCriteria.formTypeNames,
          contentText: objectToSearch.searchCriteria.contentText,
          industryIds: objectToSearch.searchCriteria.industryIds,
          auditorIds: objectToSearch.searchCriteria.auditorIds,
          goveringBoardIds: objectToSearch.searchCriteria.governingBoardIds,
          externalAuditorIds: externalAuditorIds,
          externalIndustryIds: externalIndustyIds,
          fromSavedSearch: true,
          externalCompanyIds: values
            ? values.map((company) => company.externalCompanyId)
            : [],
          ivaCompanyRatings: objectToSearch.searchCriteria.ivaCompanyRatings,
        });
      });
    } else {
      // Implement search after setting up the filters based on SearchCriteria
      submitSearch({
        companyIds: objectToSearch.searchCriteria.companyIds,
        reportYears: objectToSearch.searchCriteria.years,
        formTypeNames: objectToSearch.searchCriteria.formTypeNames,
        contentText: objectToSearch.searchCriteria.contentText,
        industryIds: objectToSearch.searchCriteria.industryIds,
        auditorIds: objectToSearch.searchCriteria.auditorIds,
        goveringBoardIds: objectToSearch.searchCriteria.governingBoardIds,
        externalAuditorIds: externalAuditorIds,
        externalIndustryIds: externalIndustyIds,
        fromSavedSearch: true,
        externalCompanyIds: objectToSearch.searchCriteria.externalCompanyIds,
        ivaCompanyRatings: objectToSearch.searchCriteria.ivaCompanyRatings,
      });
    }

    if (objectToSearch.savedSearchId) {
      executeSavedSearch(objectToSearch.savedSearchId)
        .then((data) => {
          dispatch(getSharedSearchCount());
          if (objectToSearch.sharedBy) {
            dispatch(executeSharedSeach(true));
          } else {
            dispatch(executeSavedSeach(true));
          }
        })
        .catch((e) => {
          dispatch(
            addToastMessage({
              description: <FormattedMessage id="error.default" />,
            })
          );
        });
    }
  };

  const selectCompany = (companyFilter: CompanyFilter) => {
    if (
      !searchState.companyFilter.find(
        (val: CompanyFilter) => val.cikNumber === companyFilter.cikNumber
      )
    ) {
      dispatch(setCompanyFilter([...searchState.companyFilter, companyFilter]));
    }
  };

  const unselectCompany = (companyFilter: CompanyFilter) => {
    dispatch(
      setCompanyFilter(
        searchState.companyFilter.filter(
          ({ cikNumber }) => cikNumber !== companyFilter.cikNumber
        )
      )
    );
    if (searchState.selectedItemsMultiple.length > 0) {
      let tmpSelectedCheckboxMultiple = searchState.selectedItemsMultiple
        .filter(
          (selection: SelectedCheckboxMultiple) =>
            selection.parentCikNumber !== companyFilter.cikNumber
        )
        .map((sc: SelectedCheckboxMultiple) => ({
          ...sc,
          selectedCompanies: sc.selectedCompanies.filter(
            (compCikNumber: number) => compCikNumber !== companyFilter.cikNumber
          ),
        }));
      dispatch(updateSelectedItemsMultiple(tmpSelectedCheckboxMultiple));
    }
    if (searchState.selectedPeerGroupItems.length > 0) {
      let tmpSelectedCheckboxPeerGroup = insightsState.savedPeerGroupList
        .filter((pg: PeerGroup) =>
          searchState.selectedPeerGroupItems.includes(pg.companyGroupId)
        )
        .filter((pg: PeerGroup) =>
          pg.companyGroupCompanies.every(
            (cgc: CompanyGroupCompany) =>
              cgc.cikNumber !== companyFilter.cikNumber
          )
        )
        .map((pg: PeerGroup) => pg.companyGroupId);
      dispatch(updateSelectedPeerGroupItems(tmpSelectedCheckboxPeerGroup));
    }
  };

  const unSelectCompanyFromFilter = (pcikNumberCompanies: number[]) => {
    let tmpCompnayFilter = [...searchState.companyFilter];

    pcikNumberCompanies.forEach((comp: number) => {
      if (tmpCompnayFilter.find(({ cikNumber }) => cikNumber === comp)) {
        tmpCompnayFilter = tmpCompnayFilter.filter(
          ({ cikNumber }) => cikNumber !== comp
        );
      }
    });
    dispatch(setCompanyFilter(tmpCompnayFilter));
  };

  const changeContentValue = (companyValue: string) => {
    dispatch(setContentValue(companyValue));
    dispatch(loadContentFilter(companyValue.length > 3 ? companyValue : ""));
    dispatch(
      setContentFilter(
        searchState.contentFilter.filter(({ popularSearchText }) =>
          companyValue.includes(popularSearchText)
        )
      )
    );
  };

  const selectContent = (contentFilter: PopularSearchFilter) => {
    dispatch(setContentFilter([...searchState.contentFilter, contentFilter]));
  };

  const unselectContent = (contentFilter: PopularSearchFilter) => {
    dispatch(
      setContentFilter(
        searchState.contentFilter.filter(
          ({ popularSearchId }) =>
            popularSearchId !== contentFilter.popularSearchId
        )
      )
    );
  };

  const selectDocumentType = (documentTypeFilter: FormTypeFilter) => {
    dispatch(
      setDocumentTypeFilter([
        ...searchState.documentTypeFilter,
        documentTypeFilter,
      ])
    );
  };

  const unselectDocumentType = (documentTypeFilter: FormTypeFilter) => {
    dispatch(
      setDocumentTypeFilter(
        searchState.documentTypeFilter.filter(
          ({ formTypeId }) => formTypeId !== documentTypeFilter.formTypeId
        )
      )
    );
  };

  const unselectDocumentTypes = (documentTypeFilters: FormTypeFilter[]) => {
    dispatch(
      setDocumentTypeFilter(
        searchState.documentTypeFilter.filter(
          ({ formTypeId }) =>
            documentTypeFilters.filter(
              (documentTypeFilter) =>
                documentTypeFilter.formTypeId === formTypeId
            ).length === 0
        )
      )
    );
  };

  const selectPeriod = (periodFilter: PeriodFilter) => {
    dispatch(setPeriodFilter([...searchState.periodFilter, periodFilter]));
  };

  const unselectPeriod = (periodFilter: PeriodFilter) => {
    dispatch(
      setPeriodFilter(
        searchState.periodFilter.filter(
          ({ periodId }) => periodId !== periodFilter.periodId
        )
      )
    );
  };

  const selectIndustry = (industryFilter: IndustryFilter) => {
    dispatch(
      setIndustryFilter([...searchState.industryFilter, industryFilter])
    );
  };

  const unselectIndustry = (industryFilter: IndustryFilter) => {
    dispatch(
      setIndustryFilter(
        searchState.industryFilter.filter(
          ({ industryId, externalIndustryId }) =>
            industryId !== industryFilter.industryId ||
            externalIndustryId !== industryFilter.externalIndustryId
        )
      )
    );
  };

  const unselectIndustries = (industryFilters: IndustryFilter[]) => {
    dispatch(
      setIndustryFilter(
        searchState.industryFilter.filter(
          ({ industryId, externalIndustryId }) =>
            industryFilters.filter(
              (industryFilter) =>
                industryFilter.industryId === industryId ||
                industryFilter.externalIndustryId === externalIndustryId
            ).length === 0
        )
      )
    );
  };

  const selectGoverningBoard = (governingBoardFilter: GoverningBoardFilter) => {
    dispatch(
      setGoverningBoardFilter([
        ...searchState.governingBoardFilter,
        governingBoardFilter,
      ])
    );
  };

  const unselectGoverningBoard = (
    governingBoardFilter: GoverningBoardFilter
  ) => {
    dispatch(
      setGoverningBoardFilter(
        searchState.governingBoardFilter.filter(
          ({ governingBoardId }) =>
            governingBoardId !== governingBoardFilter.governingBoardId
        )
      )
    );
  };

  /* ESg Ratings filter will be hidden for now */

  // const selectESGRating = (esgRatingFilter: ESGRatingFilter) => {
  //   dispatch(
  //     setESGRatingFilter([...searchState.esgRatingFilter, esgRatingFilter])
  //   );
  // };

  // const unselectESGRating = (esgRatingFilter: ESGRatingFilter) => {
  //   dispatch(
  //     setESGRatingFilter(
  //       searchState.esgRatingFilter.filter(
  //         ({ ivaCompanyRating }) =>
  //           ivaCompanyRating !== esgRatingFilter.ivaCompanyRating
  //       )
  //     )
  //   );
  // };

  const selectAuditor = (auditorFilter: AuditorFilter) => {
    dispatch(setAuditorFilter([...searchState.auditorFilter, auditorFilter]));
  };

  const unselectAuditor = (auditorFilter: AuditorFilter) => {
    dispatch(
      setAuditorFilter(
        searchState.auditorFilter.filter(
          ({ auditorId, externalAuditorId }) =>
            auditorId !== auditorFilter.auditorId ||
            externalAuditorId !== auditorFilter.externalAuditorId
        )
      )
    );
  };

  const companiesUnselectedHandler = (punseletedCompanies: number[]) => {
    let tmpCompaniesUnselected = [...companiesUnselected].concat(
      punseletedCompanies.filter(
        (comp: number) => !companiesUnselected.includes(comp)
      )
    );

    setCompaniesUnselected(tmpCompaniesUnselected);
  };

  const selectSuggestedPeers = (
    pselectedCheckboxMultiple: SelectedCheckboxMultiple[],
    selectedPeerGroups: number[]
  ) => {
    let companyExists: CompanyFilter | undefined = undefined;
    let tmpCompanyFilter: CompanyFilter[] = JSON.parse(
      JSON.stringify(searchState.companyFilter)
    ).filter(
      (pcf: CompanyFilter) => !companiesUnselected.includes(pcf.cikNumber)
    );

    pselectedCheckboxMultiple
      .filter((scm) => scm.selectedCompanies.length > 0)
      .forEach((selectedCompanyGroup: SelectedCheckboxMultiple) => {
        selectedCompanyGroup.selectedCompanies.forEach((cikNumber) => {
          companyExists = tmpCompanyFilter.find(
            (company: CompanyFilter) => company.cikNumber === cikNumber
          );
          if (!companyExists) {
            let tmpCompany = searchState.suggestedPeerCompanyByCompanyFilter
              .find(
                (suggestedPeerCompany: SuggestedPeerCompaniesByCompanyFilter) =>
                  suggestedPeerCompany.cikNumber ===
                  selectedCompanyGroup.parentCikNumber
              )
              ?.suggestedPeerCompanyFilter.find(
                (suggestedPeer: SuggestPeerCompanyFilterMultiple) =>
                  suggestedPeer.cikNumber === cikNumber
              );
            if (tmpCompany) {
              tmpCompanyFilter.push({
                companyName: tmpCompany.companyName,
                companyId: tmpCompany.companyId,
                externalCompanyId: tmpCompany.externalCompanyId,
                cikNumber: tmpCompany.cikNumber,
                ticker: tmpCompany.ticker,
                isSuggestedPeerCompany: true,
              });
            }
          }
        });
      });

    if (selectedPeerGroups && selectedPeerGroups.length) {
      let tmpPeerGroupSelection = insightsState.savedPeerGroupList.filter(
        (group: PeerGroup) => selectedPeerGroups.includes(group.companyGroupId)
      );

      tmpPeerGroupSelection.forEach((pg: PeerGroup) => {
        pg.companyGroupCompanies.forEach(
          (companyGroup: CompanyGroupCompany) => {
            if (
              !tmpCompanyFilter.find(
                (companyFilter: CompanyFilter) =>
                  companyFilter.cikNumber === companyGroup.cikNumber
              )
            ) {
              tmpCompanyFilter.push({
                companyName: companyGroup?.company?.companyName ?? "",
                companyId: companyGroup?.company?.companyId ?? 0,
                externalCompanyId:
                  companyGroup?.company?.externalCompanyId ?? 0,
                cikNumber: companyGroup.cikNumber,
                ticker: companyGroup?.company?.ticker ?? "",
                isSuggestedPeerCompany: true,
              });
            }
          }
        );
      });
    }
    dispatch(setCompanyFilter(tmpCompanyFilter));
  };

  const resetSearch = () => {
    dispatch(setCompanyFilter([]));
    dispatch(setContentValue(""));
    dispatch(setContentFilter([]));
    dispatch(setDocumentTypeFilter([]));
    dispatch(setPeriodFilter([]));
    dispatch(setIndustryFilter([]));
    dispatch(setGoverningBoardFilter([]));
    dispatch(setAuditorFilter([]));
    dispatch(updateSelectedPeerGroupItems([]));
  };

  const submitSearch = ({
    companyData,
    reportYear,
    companyIds,
    reportYears,
    formTypeNames,
    contentText,
    industryIds,
    auditorIds,
    goveringBoardIds,
    externalAuditorIds,
    externalIndustryIds,
    fromSavedSearch,
    externalCompanyIds,
    ivaCompanyRatings,
  }: {
    companyData?: CompanyFilter;
    reportYear?: string;
    companyIds?: number[];
    reportYears?: string[];
    formTypeNames?: string[];
    contentText?: string;
    industryIds?: number[];
    auditorIds?: number[];
    goveringBoardIds?: number[];
    externalAuditorIds?: number[];
    externalIndustryIds?: string[];
    fromSavedSearch?: boolean;
    externalCompanyIds?: number[];
    ivaCompanyRatings?: string[];
  }) => {
    const payload = {
      shortlistId: shortListState.results
        ? shortListState.results.shortlistId
        : 0,
      contentText: contentText ? contentText : searchState.contentValue,
      formTypeNames: formTypeNames
        ? formTypeNames
        : searchState.documentTypeFilter.map(
            ({ formTypeName }) => formTypeName
          ),
      years: reportYears
        ? reportYears
        : reportYear
        ? [reportYear]
        : searchState.periodFilter.map(({ periodYear }) => periodYear),
      companyIds: companyIds
        ? companyIds
        : companyData
        ? [companyData.cikNumber]
        : searchState.companyFilter.map(({ cikNumber }) => cikNumber),
      industryIds: industryIds
        ? industryIds
        : searchState.industryFilter.map(({ industryId }) => industryId),
      auditorIds: auditorIds
        ? auditorIds
        : searchState.auditorFilter.map(({ auditorId }) => auditorId),

      governingBoardIds: goveringBoardIds
        ? goveringBoardIds
        : searchState.governingBoardFilter.length !== 0
        ? searchState.governingBoardFilter.map(
            ({ governingBoardId }) => governingBoardId
          )
        : [],
      pageInfo: {
        pageNumber: 1,
        pageSize: 30,
        ...ESGsortResults,
      },
    };
    dispatch(setSelectedDisclosure(null));
    dispatch(setSelectedReport(null));
    dispatch(loadEsgSearchResults(payload));
    dispatch(
      loadSecSearchResults({
        ...payload,
        companyIds: fromSavedSearch
          ? externalCompanyIds
          : companyData
          ? [companyData.externalCompanyId]
          : searchState.companyFilter.map(
              ({ externalCompanyId }) => externalCompanyId
            ).filter(companyId => !!companyId),
        auditorIds: fromSavedSearch
          ? externalAuditorIds
          : searchState.auditorFilter.map(
              ({ externalAuditorId }) => externalAuditorId
            ),
        industryIds: fromSavedSearch
          ? externalIndustryIds
          : searchState.industryFilter.map(
              ({ externalIndustryId }) => externalIndustryId
            ),
        pageInfo: {
          pageNumber: 1,
          pageSize: 30,
          ...SECsortResults,
        },
      })
    );
    dispatch(resetSavedSearch());
    dispatch(saveNewSearch(true));
    dispatch(savedSearchResult(null));

    if (
      searchState.companyFilter.length > 0 &&
      searchState.companyFilter.find(
        (company: CompanyFilter) => company.isSuggestedPeerCompany
      )
    ) {
      let trakingPayload = {
        trackingType: TRACKING_IDS.PEER_COMMPANY_SELECTION_IN_SEARCH,
        referenceId: null,
        trackingData: {
          companies: searchState.companyFilter.map((comp: CompanyFilter) => ({
            cikNumber: comp.cikNumber,
            companyName: comp.companyName,
            ticker: comp.ticker,
            companyId: comp.companyId,
            externalCompanyId: comp.externalCompanyId,
            isSuggestedPeerCompany: comp.isSuggestedPeerCompany
              ? comp.isSuggestedPeerCompany
              : false,
          })),
        },
      };
      dispatch(peerGroupSelectionTracking(trakingPayload));
    }
  };

  const debounceChangeHandler = useMemo(() => {
    return debounce((e: any) => {
      dispatch(loadCompanyFilter(e.target.value));
    }, 300);
  }, [dispatch]);

  const debounceChangeHandlerSaved = useMemo(() => {
    return debounce((e: any, type: any) => {
      dispatch(getGlobalPeerCompanyFilter(e.target.value));
    }, 300);
  }, [dispatch]);

  useEffect(() => {
    if (insightsState.insightPeerGroupSuccess) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id={"save.peer.group.success"} />,
        })
      );
      dispatch(setPeerGroupSavedSuccess(false));
      dispatch(loadSavedPeerGroupList(DEFAULT_PEER_GROUP_LIST_PAYLOAD));
      setPeerGroupsLoaded(false);
      setSavedPeers(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.insightPeerGroupSuccess]);

  const SavePeerGroupHandler = (peerGroupName: string) => {
    let displayOrder = 0;

    let companyGroupCompanies: peerGroupCompanies[] = [];

    selectedPeerCompany.forEach((company) => {
      let peerCompany: any = {
        cikNumber: company.cikNumber,
        displayOrder: ++displayOrder,
        isSuggestedPeerCompany:
          company.isSuggestedPeerCompany === true ? true : false,
        company: company,
        globalCompanyId: company.globalCompanyId,
      };
      companyGroupCompanies.push(peerCompany);
    });

    let payload: savePeerGroupNamePayload = {
      companyGroupName: peerGroupName,
      companyGroupCompanies,
    };
    dispatch(savePeerGroup(payload, PEER_GROUP_CREATION_PAGE.SEARCH));
  };

  const dropdownHeadersForSavePeerModal: {
    label: string;
    placeholder: string;
    values: CompanyFilter[];
    options: CompanyFilter[];
    loading: FILTER_OPTIONS[];
    textValue: string;
    type: string;
    limit?: string;
    showOnEmpty?: boolean;
  }[] = [
    {
      label: "peer.group.add.more",
      placeholder: "filter.company.placeholder",
      values: selectedPeerCompany,
      options: searchState.companyFilterOptions,
      loading: insightsState.loadingFilter,
      textValue: "",
      type: "peers",
      limit: commonsState.insightsPeerCompanyLimit?.InsightsPeerCompaniesLimit,
      showOnEmpty: false,
    },
  ];

  const setTooltipPosition = (tooltipName: string) => {
    const tooltip = document.getElementById(`${tooltipName}-tooltip`);
    const icon = filtersRef.current;

    if (tooltip && icon)
      tooltip.setAttribute("style", `top: ${-icon.scrollTop}px;`);
  };

  const activeFilters =
    searchState.companyFilter.length +
    (searchState.contentValue.length > 0 ? 1 : 0) +
    searchState.documentTypeFilter.length +
    searchState.periodFilter.length +
    searchState.industryFilter.length +
    searchState.auditorFilter.length +
    searchState.governingBoardFilter.length;

  const fetchSuggestedPeers = () => {
    let tmpCompanies = searchState.companyFilter.filter(
      (item: CompanyFilter, index) => index < 5
    );
    let companiesToFetch: any[] = [];

    tmpCompanies.forEach((company: CompanyFilter) => {
      companiesToFetch.push({
        companyName: company.companyName,
        cikNumber: company.cikNumber,
        suggestedPeerCompanyFilter: [],
        checked: false,
      });
    });

    if (companiesToFetch.length > 0) {
      dispatch(fetchSuggestedPeerCompaniesByCompanyFilter(companiesToFetch));
    }
    setTriggerSuggestedPeers(true);
  };

  return searchState.showFilters ? (
    <>
      <div className={`${BLOCK}__filter-options`} data-testid="filter-options">
        <div className={`${BLOCK}__filter-header`}>
          <span className={`${BLOCK}__filter-span`}>
            <FormattedMessage id="search.filters" />
            {activeFilters > 0 && (
              <span className={`${BLOCK}__active-filters`}>
                {activeFilters}
              </span>
            )}
          </span>
          <button
            className={`${BLOCK}__hide-filters`}
            data-testid="hide-filters"
            onClick={() => dispatch(setShowFilters(false))}
          >
            <Icon name="arrow-double-left" width={24} height={24} />
          </button>
        </div>
        <div className={`${BLOCK}__filter-content`}>
          <div ref={filtersRef} className={`${BLOCK}__filter-scroll`}>
            <DropDown
              className={`${BLOCK}__drop-down`}
              label={
                <div
                  className={`${BLOCK}__drop-down-label`}
                  data-testid="drop-down-label"
                >
                  <FormattedMessage id="filter.company" />
                  <div
                    className={`${BLOCK}__drop-down-icon`}
                    data-testid="tooltip-company"
                    onMouseOver={() => setTooltipPosition("company")}
                  >
                    <Icon name="information" width={24} height={24} />
                    <Tooltip
                      id="company-tooltip"
                      position={TooltipPosition.right}
                    >
                      <FormattedMessage
                        id="filter.company.tooltip"
                        values={{
                          modalLink: (
                            <button
                              key="filter.company-tooltip"
                              className={`${BLOCK}__link-button`}
                              data-testid="tooltip-company-link-button"
                              onClick={() => {
                                setFilterTipsPage(FilterTipsPage.company);
                                setShowModal(true);
                              }}
                            >
                              <FormattedMessage id="filter.company.tooltip.modalLink" />
                            </button>
                          ),
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              }
              placeholder={<FormattedMessage id="filter.company.placeholder" />}
              loading={
                searchState.loadingFilter.filter(
                  (filter) => filter === FILTER_OPTIONS.COMPANY
                ).length > 0
              }
              objectKeys={{
                name: "companyName",
                ticker: "ticker",
                id: "companyId",
                externalId: "externalCompanyId",
              }}
              values={searchState.companyFilter}
              options={searchState.companyFilterOptions}
              handleChange={debounceChangeHandler}
              handleSelect={selectCompany}
              handleUnselect={unselectCompany}
              showOnEmpty={false}
              companyFilter={true}
            />
            <div className={`${BLOCK}__suggested-peers-section`}>
              <button
                className={classNames(`${BLOCK}__suggested-peers-btn`, {
                  [`${BLOCK}__suggested-peers-btn--disabled`]:
                    searchState.companyFilter.length === 0,
                })}
                onClick={() => {
                  fetchSuggestedPeers();
                }}
                data-testid="suggested-peers-link"
                disabled={searchState.companyFilter.length === 0}
              >
                <Icon
                  name="external-link"
                  height={24}
                  width={24}
                  className="icon"
                  data-testid={
                    searchState.companyFilter.length > 0
                      ? "benchmark-suggested-peers-link-enabled"
                      : "benchmark-suggested-peers-link-disabled"
                  }
                />

                <FormattedMessage id="search.suggested.peers.btn" />
              </button>
            </div>
            <div className={`${BLOCK}__suggested-peers-section`}>
              <button
                className={classNames(`insights__create-new__suggested-peers`, {
                  [`insights__create-new__suggested-peers--disabled`]:
                    searchState.companyFilter.length < 2,
                })}
                onClick={() => {
                  if (searchState.companyFilter.length >= 2) {
                    setSavedPeers(true);
                  }
                }}
                data-testid="suggested-peers-link"
              >
                <Icon
                  name="plus24"
                  height={24}
                  width={24}
                  className="icon"
                  data-testid={
                    searchState.companyFilter.length >= 2
                      ? "benchmark-suggested-peers-link-enabled"
                      : "benchmark-suggested-peers-link-disabled"
                  }
                />

                <FormattedMessage id="save.peer.group" />
              </button>
            </div>
            <DropDown
              className={`${BLOCK}__drop-down`}
              label={
                <div className={`${BLOCK}__drop-down-label`}>
                  <FormattedMessage id="filter.content" />
                  <div
                    className={`${BLOCK}__drop-down-icon`}
                    onMouseOver={() => setTooltipPosition("content")}
                    data-testid="tooltip-content"
                  >
                    <Icon name="information" width={24} height={24} />
                    <Tooltip
                      id={`content-tooltip`}
                      position={TooltipPosition.right}
                    >
                      <FormattedMessage
                        id="filter.content.tooltip"
                        values={{
                          modalLink: (
                            <button
                              key="filter.content-tooltip"
                              className={`${BLOCK}__link-button`}
                              data-testid="tooltip-content-link-button"
                              onClick={() => {
                                setFilterTipsPage(FilterTipsPage.content);
                                setShowModal(true);
                              }}
                            >
                              <FormattedMessage id="filter.content.tooltip.modalLink" />
                            </button>
                          ),
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              }
              placeholder={<FormattedMessage id="filter.content.placeholder" />}
              loading={
                searchState.loadingFilter.filter(
                  (filter) => filter === FILTER_OPTIONS.CONTENT
                ).length > 0
              }
              objectKeys={{
                name: "popularSearchName",
                id: "sectionId",
                externalId: "sectionId",
                valueText: "popularSearchText",
                children: "popularSearches",
                childName: "popularSearchName",
                childId: "popularSearchId",
              }}
              values={searchState.contentFilter}
              textValue={searchState.contentValue}
              options={[
                {
                  sectionId: 1,
                  popularSearches: searchState.contentFilterOptions.filter(
                    (item: PopularSearchFilter) =>
                      item.popularSearchType === POPULAR_SEARCHES.AUTO_COMPLETE
                  ),
                },
                {
                  popularSearchName: "Popular Searches",
                  sectionId: 2,
                  popularSearches: searchState.contentFilterOptions.filter(
                    (item: PopularSearchFilter) =>
                      item.popularSearchType === POPULAR_SEARCHES.POPULAR
                  ),
                },
              ]}
              handleSelect={selectContent}
              handleUnselect={unselectContent}
              handleChangeValue={changeContentValue}
              handleOnEnter={submitSearch}
              contentStyle={true}
            />

            <DropDown
              className={`${BLOCK}__drop-down`}
              label={<FormattedMessage id="filter.document-type" />}
              placeholder={
                <FormattedMessage id="filter.document-type.placeholder" />
              }
              loading={
                searchState.loadingFilter.filter(
                  (filter) => filter === FILTER_OPTIONS.DOCUMENT_TYPE
                ).length > 0
              }
              objectKeys={{
                name: "documentCategoryName",
                id: "documentCategoryId",
                externalId: "documentCategoryId",
                children: "formTypes",
                childName: "formTypeName",
                childId: "formTypeId",
              }}
              values={searchState.documentTypeFilter}
              options={searchState.documentTypeFilterOptions}
              handleSelect={selectDocumentType}
              handleUnselect={unselectDocumentType}
              handleGroupUnselect={unselectDocumentTypes}
            />

            <DropDown
              className={`${BLOCK}__drop-down`}
              label={
                <div className={`${BLOCK}__drop-down-label`}>
                  <FormattedMessage id="filter.period" />
                  <div
                    className={`${BLOCK}__drop-down-icon`}
                    data-testid="tooltip-period"
                    onMouseOver={() => setTooltipPosition("period")}
                  >
                    <Icon name="information" width={24} height={24} />
                    <Tooltip
                      id="period-tooltip"
                      position={TooltipPosition.periodRight}
                    >
                      <FormattedMessage id="filter.period.tooltip" />
                    </Tooltip>
                  </div>
                </div>
              }
              placeholder={<FormattedMessage id="filter.period.placeholder" />}
              loading={
                searchState.loadingFilter.filter(
                  (filter) => filter === FILTER_OPTIONS.PERIOD
                ).length > 0
              }
              objectKeys={{
                name: "periodYear",
                id: "periodId",
                externalId: "periodId",
              }}
              values={searchState.periodFilter}
              options={searchState.periodFilterOptions}
              handleSelect={selectPeriod}
              handleUnselect={unselectPeriod}
            />

            <DropDown
              className={`${BLOCK}__drop-down`}
              label={<FormattedMessage id="filter.industry" />}
              placeholder={
                <FormattedMessage id="filter.industry.placeholder" />
              }
              loading={
                searchState.loadingFilter.filter(
                  (filter) => filter === FILTER_OPTIONS.INDUSTRY
                ).length > 0
              }
              objectKeys={{
                name: "industryName",
                id: "industryName",
                externalId: "externalIndustryId",
                children: "sectors",
                childName: "sector",
                childId: "industryId",
                childExternalId: "externalIndustryId",
              }}
              values={searchState.industryFilter}
              options={searchState.industryFilterOptions}
              handleSelect={selectIndustry}
              handleUnselect={unselectIndustry}
              handleGroupUnselect={unselectIndustries}
            />

            <DropDown
              className={`${BLOCK}__drop-down`}
              label={<FormattedMessage id="filter.auditor" />}
              placeholder={<FormattedMessage id="filter.auditor.placeholder" />}
              loading={
                searchState.loadingFilter.filter(
                  (filter) => filter === FILTER_OPTIONS.AUDITOR
                ).length > 0
              }
              objectKeys={{
                name: "auditorName",
                id: "auditorId",
                externalId: "externalAuditorId",
              }}
              values={searchState.auditorFilter}
              options={searchState.auditorFilterOptions}
              handleSelect={selectAuditor}
              handleUnselect={unselectAuditor}
              displayFunction={(option: AuditorFilter) =>
                option.auditorNumber < 5 && option.auditorNumber > 0
                  ? `<b>${option.auditorName}</b>`
                  : option.auditorName
              }
            />

            <DropDown
              className={`${BLOCK}__drop-down`}
              label={
                <div
                  className={`${BLOCK}__drop-down-label`}
                  data-testid="drop-down-label"
                >
                  <FormattedMessage id="filter.esg.frameworks.label" />
                  <div
                    className={`${BLOCK}__drop-down-icon`}
                    onMouseOver={() => setTooltipPosition("frameworks")}
                    data-testid="tooltip-frameworks"
                  >
                    <Icon name="information" width={24} height={24} />
                    <Tooltip
                      id="frameworks-tooltip"
                      position={TooltipPosition.frameworkleft}
                    >
                      <FormattedMessage id="filter.esg.frameworks.tooltip" />
                    </Tooltip>
                  </div>
                </div>
              }
              placeholder={
                <FormattedMessage id="filter.esg.frameworks.placeholder" />
              }
              loading={
                searchState.loadingFilter.filter(
                  (filter) => filter === FILTER_OPTIONS.GOVERNING_BOARD
                ).length > 0
              }
              objectKeys={{
                name: "governingBoardName",
                id: "governingBoardId",
                externalId: "governingBoardId",
              }}
              values={searchState.governingBoardFilter}
              options={searchState.governingBoardFilterOptions}
              handleSelect={selectGoverningBoard}
              handleUnselect={unselectGoverningBoard}
            />
            {/* ESg Ratings filter will be hidden for now */}
            {/* <DropDown
              className={`${BLOCK}__drop-down`}
              label={<FormattedMessage id="filter.esg.ratings.label" />}
              placeholder={
                <FormattedMessage id="filter.esg.ratings.placeholder" />
              }
              loading={
                searchState.loadingFilter.filter(
                  (filter) => filter === FILTER_OPTIONS.ESG_RATING
                ).length > 0
              }
              objectKeys={{
                name: "ivaCompanyRating",
                id: "ivaCompanyRating",
                externalId: "ivaCompanyRating",
              }}
              values={searchState.esgRatingFilter}
              options={searchState.esgRatingFilterOptions}
              handleSelect={selectESGRating}
              handleUnselect={unselectESGRating}
            /> */}
          </div>
        </div>
        <div className={`${BLOCK}__filter-buttons-container`}>
          <Button
            className={`button-primary`}
            onClick={() => submitSearch({})}
            dataTest="search-button"
            iconName="search"
            iconHeight={20}
            iconWidth={20}
            formattedText="filter.search.button"
          />
          {activeFilters > 0 && (
            <button
              onClick={resetSearch}
              className={`${BLOCK}__reset-button`}
              data-testid="reset-button"
            >
              <Icon name="eraser" width={24} height={24} />
              <FormattedMessage id="filter.search.reset" />
            </button>
          )}
        </div>
      </div>
      <div data-testid="filter-tips-modal">
        <Modal
          show={showModal}
          handleClose={() => {
            setFilterTipsPage(undefined);
            setShowModal(false);
          }}
          header="overlay.search.company-content"
        >
          <FilterTips
            page={filterTipsPage}
            handleClose={() => {
              setFilterTipsPage(undefined);
              setShowModal(false);
            }}
          />
        </Modal>
      </div>
      {showSuggestedPeers && (
        <SuggestedPeers
          BLOCK={BLOCK}
          setShowModal={setShowSuggestedPeers}
          addSelected={() => {}}
          selectedItems={[]}
          suggestedPeerCompanies={[]}
          dispatchFunction={dispatch}
          dispatchFunctionPeerGroups={updateSelectedPeerGroupItems}
          dispatchFunctionMultiple={updateSelectedItemsMultiple}
          selectedBaselineComp={{ companyName: "", companyId: 0, cikNumber: 0 }}
          isMultiple
          suggestedPeerCompaniesByCompany={suggestedPeerCompaniesGroup}
          selectItemsMultiple={selectSuggestedPeers}
          selectedItemsMultiple={searchState.selectedItemsMultiple}
          unSelectCompanyFromFilter={unSelectCompanyFromFilter}
          selectedCheckboxPeerGroupItems={searchState.selectedPeerGroupItems}
          peerGroups={insightsState.savedPeerGroupList ?? []}
          page={PEER_GROUP_CREATION_PAGE.SEARCH}
          setCompaniesUnselected={companiesUnselectedHandler}
          identifier={COMPANY_IDENTIFIER.CIK_NUMBER}
          debounceFunction={debounceChangeHandlerSaved}
          companyFilterOptions={
            insightsState.companyFilter.length > 0
              ? insightsState.peerCompanyFilterOptions?.filter(
                  (peerCompany: CompanyFilter) =>
                    peerCompany.globalCompanyId !==
                    insightsState.companyFilter[0].globalCompanyId
                )
              : insightsState.peerCompanyFilterOptions
          }
        />
      )}
      {savedPeers && (
        <SavedPeerModal
          setShowModal={setSavedPeers}
          dropdownHeadersForSavePeerModal={dropdownHeadersForSavePeerModal}
          handleUnselectCompany={unselectCompany}
          isdispatchFunction={true}
          dispatchFunction={setCompanyFilter}
          peerCompanyFilter={selectedPeerCompany}
          setSelectedPeerCompany={setSelectedPeerCompany}
          savePeerGroupHandler={SavePeerGroupHandler}
          debounceChangeHandler={debounceChangeHandler}
        />
      )}
    </>
  ) : (
    <HiddenFilters
      BLOCK={BLOCK}
      showFilters={() => dispatch(setShowFilters(true))}
      activeFilters={activeFilters}
      reportOrDisclosureSelected={
        documentViewerState.selectedReport ||
        documentViewerState.selectedDisclosure
          ? true
          : false
      }
    />
  );
};

export default Filters;
