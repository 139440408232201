import { useState } from "react";
import Slider, { Slide } from "components/slider/slider";
import Modal from "components/shared/modal/modal";
import useWhatsNewModal, { PAGES } from "./useWhatsNewModal";

interface Props {
  slides: Slide[];
  slide?: Slide;
  page: PAGES;
}

const WhatsNewModal = (props: Props) => {
  const { page, slides, slide } = props;
  const { shouldShowWhatsNew, setDontShowAgain, setToShowAgain } =
    useWhatsNewModal();
  const [doNotShowAgain, setDoNotShowAgain] = useState(
    shouldShowWhatsNew(page)
  );
  const [show, setShow] = useState(shouldShowWhatsNew(page));

  return (
    <Modal
      show={show}
      handleClose={() => {
        setShow(false);
      }}
      header="whats.new.modal.title"
    >
      <Slider
        slides={slides}
        slide={slide}
        handleClose={() => {
          setShow(false);
        }}
        showAgain={!doNotShowAgain}
        autoSlide={5}
        handleDontShowAgain={() => {
          setDoNotShowAgain(!shouldShowWhatsNew(page));

          if (shouldShowWhatsNew(page)) {
            setDontShowAgain(page);
          } else {
            setToShowAgain(page);
          }
        }}
        finishButtonMsjId="slider.buttom.finish"
        nextButtonMsjId="slider.buttom.next"
        previousButtonMsjId="slider.buttom.previous"
        skipButtonMsjId="slider.buttom.skip"
        dontShowAgainMsjId="slider.dont.show.again"
      />
    </Modal>
  );
};

export default WhatsNewModal;
