import api from "services/api.service";

export const fetchDocumentViewerESGKeywords = (
  reportId: number,
  contentText: string
) =>
  api
    .post(`reports/${reportId}/extraction`, { contentText })
    .then(({ data }) => data);

export const fetchDocumentViewerESGLayout = (reportId: number) =>
  api.get(`reports/${reportId}/layout/metadata`).then(({ data }) => data);

export const fetchDocumentViewerSECStatements = (
  sectionId: number,
  contentText: string
) =>
  api
    .post(`reports/statements`, { sectionId, contentText })
    .then(({ data }) => data);

export const fetchDocumentViewerESGPageOCR = (
  reportId: number,
  pageNumber: number
) =>
  api
    .get(`reports/${reportId}/layout/data/${pageNumber}`)
    .then(({ data }) => data);

export const fetchDocumentViewerESGPageBinary = (
  reportId: number,
  pageNumber: number
) =>
  api
    .get(`reports/${reportId}/binary/${pageNumber}`, {
      responseType: "blob",
    })
    .then(({ data }) => data);

export const downloadESGReport = (reportId: number, payload: any) =>
  api
    .post(`reports/esg/${reportId}/binary`, payload, {
      responseType: "blob",
    })
    .then((res) => {
      const file = new Blob([res.data], { type: res.headers["content-type"] });
      const fileURL = URL.createObjectURL(file);

      return fileURL;
    });

export const downloadSECDisclosure = (
  filingId: number,
  elementId: number,
  payload: any
) =>
  api
    .post(`reports/secDisclosures/${filingId}/${elementId}/binary`, payload, {
      responseType: "blob",
    })
    .then((res) => {
      const file = new Blob([res.data], { type: res.headers["content-type"] });
      const fileURL = URL.createObjectURL(file);

      return fileURL;
    });

export const fetchESGReportMetadata = (reportID: number | undefined) =>
  api.get(`/reports/${reportID}/metadata`).then(({ data }) => data);
