import DropDown from "components/compare/dropdown";
import Button from "components/shared/button/button";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { es } from "date-fns/locale";
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addToastMessage } from "services/commons.service";
import { createTag, getTagNameList } from "services/tags/tags.api";
import { createNewTagTrack } from "services/tags/tags.service";
import { COMPARISON_TAG_TYPE } from "utils/constants";
import { trimStartFunc } from "utils/functions";
import Modal from "../shared/modal/modal";
import { useTagContext } from "./tag.context";

const CreateTag = () => {
  const BLOCK = "create-tag";
  const dispatch = useDispatch();
  const [tagList, setTagList] = useState([]);
  const [counts, setCounts] = useState({ name: 0, description: 0 });
  const [values, setValues] = useState({ name: "", description: "" });
  const [selected, setSelected] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const {
    setShowCreateTag,
    tagSelection,
    setTagSelection,
    referenceId,
    setComparisonItemIdToUpdate,
    setShowTagPanel,
    setTagCreated,
  } = useTagContext();

  useEffect(() => {
    getTagNameList(COMPARISON_TAG_TYPE).then((data) => {
      setTagList(data.data);
    });
  }, []);

  const onChangeHandler = (inputName: string, inputValue: string) => {
    setCounts((c) => ({
      ...c,
      [inputName]: inputValue.length,
    }));
    setValues((v) => ({
      ...v,
      [inputName]: inputValue,
    }));
    if (inputName === "name") {
      setDisabled(inputValue.trim().length === 0);
    }
  };

  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !disabled) {
      createTagAction();
    }
  };

  const createTagAction = () => {
    //TODO: ADD OR REMOVE TRIMSTARTFUNC WHEN BUSSINESS HAS CONFIRMED
    const tagPayload = {
      tagName: values.name,
      tagDescription: values.description,
      displayOrder: 1,
      tagType: COMPARISON_TAG_TYPE,
      tagDetails: [
        {
          tagMetadata: tagSelection,
          referenceId,
          displayOrder: 1,
        },
      ],
    };
    setDisabled(true);
    createTag(tagPayload).then((data) => {
      if (data.data) {
        setShowCreateTag(false);
        setTagSelection(null);
        setTagCreated(true);
        setShowTagPanel(true);
        dispatch(createNewTagTrack(true));

        dispatch(
          addToastMessage({
            description: <FormattedMessage id="tag.create.success-message" />,
          })
        );
      }
      setComparisonItemIdToUpdate(referenceId);
    });
  };

  return (
    <Modal
      show={true}
      header="tags.create.header"
      handleClose={() => {
        setShowCreateTag(false);
        setSelected(false);
      }}
      config={false}
    >
      <div className={BLOCK} data-testid="tag-create-panel">
        <div className={`${BLOCK}`}>
          <div className={BLOCK}>
            <span className={`${BLOCK}__name`}>
              <DropDown
                className={`${BLOCK}__dropdown`}
                label={<FormattedMessage id="tags.create.name" />}
                createNewLabel={<FormattedMessage id="tags.create.message" />}
                placeholder={<FormattedMessage id="tags.create.placeholder" />}
                values={[]}
                textValue={values.name}
                objectKeys={{
                  name: "tagName",
                  id: "tagId",
                }}
                options={tagList}
                loading={false}
                handleSelect={(s: any) => {
                  if (s.tagName === values.name && selected) {
                    onChangeHandler("name", "");
                    onChangeHandler("description", "");
                    setSelected(false);
                  } else {
                    setSelected(true);
                    onChangeHandler("name", s.tagName);
                    onChangeHandler("description", s.tagDescription);
                  }
                }}
                handleChange={(e: any) => {
                  onChangeHandler(e.target.name, e.target.value);
                }}
                onSelectCreateNew={() => {
                  setSelected(false);
                  if (
                    tagList.filter((t: any) => t.tagName === values.name).length
                  ) {
                    setSelected(false);
                    onChangeHandler("name", "");
                    onChangeHandler("description", "");
                  }
                }}
                inputMaxLength={50}
              />
              <span className={`${BLOCK}__char-limit`}>
                {counts.name}
                <FormattedMessage id="tag.create.name.character.limit" />
              </span>
            </span>

            <div>
              <span className={`${BLOCK}__label ${BLOCK}__description-label`}>
                <FormattedMessage id="tags.create.description" />
              </span>
              <span className={`${BLOCK}__input`}>
                <textarea
                  ref={descriptionInputRef}
                  maxLength={2000}
                  onChange={(e: any) =>
                    onChangeHandler(e.target.name, e.target.value)
                  }
                  onKeyDown={onEnter}
                  value={values.description}
                  data-testid="descriptionTextArea"
                  placeholder="Write a description"
                  className={`${BLOCK}__description`}
                  name="description"
                />
              </span>
              <span className={`${BLOCK}__char-limit`}>
                {counts.description}
                <FormattedMessage id="tag.create.description.character.limit" />
              </span>
            </div>

            <div className={`${BLOCK}__options`}>
              <Button
                className={`button-secondary`}
                dataTest="cancel-btn"
                formattedText="shortlist.save.button.cancel"
                onClick={() => {
                  setShowCreateTag(false);
                  setTagSelection(null);
                }}
              />
              <Button
                className={`button-primary`}
                dataTest="edit-saved-search-btn"
                disabled={disabled}
                formattedText="shortlist.save.button.save"
                onClick={createTagAction}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default CreateTag;
