import Filters from "./filters/filters";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import SearchResults from "./search.results";
import TabbedContent from "components/shared/tabbed-content/tabbed-content";
import Shortlist from "components/shortlist/shortlist";
import WhatsNewModal from "components/whatsNewModal/whatsNewModal";
import { useLocation } from "react-router";
import {
  loadShortListResults,
  saveNewShortList,
} from "services/shortlist/shortlist.service";
import {
  setSelectedDisclosure,
  setSelectedReport,
} from "services/document-viewer/document-viewer.service";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import ReportModal from "./report.modal";
import Modal from "components/shared/modal/modal";
import { resetSearchResult } from "services/search/search.results.service";
import { saveShortlistCopy } from "services/shortlist/shortlist.service";
import Feedback from "../feedback/feedback";
import {
  setCompanyFilter,
  setDocumentTypeFilter,
  setGoverningBoardFilter,
  setIndustryFilter,
  setContentFilter,
  setAuditorFilter,
  setPeriodFilter,
  setContentValue,
} from "services/search/filters.service";
import {
  deleteShortlist,
  getSavedShortlistCount,
} from "services/saved-shortlist/saved-shortlist.service";
import { loadCurrentSavedSearch } from "services/searchlist/searchlist.service";
import { resetShortList } from "services/shortlist/shortlist.service";
import SaveShortlist from "../shortlist-actions/save-shortlist";
import { submitFeedback } from "services/download/download.api";
import history from "utils/history";
import {
  ACCESS_MODULES,
  MENU_ITEMS,
  OVERLAY_SEARCHES,
  unauthorizedPath,
} from "utils/constants";
import { setShowFilters } from "services/search/filters.service";

import { PAGES } from "components/whatsNewModal/useWhatsNewModal";
import { addToastMessage } from "services/commons.service";
import RequestUploadPanel from "./request-upload-panel";
import Button from "components/shared/button/button";
import useAppInsightsCustomMetric from "appinsights/app-insights-custom-metrics";

const Search = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);
  const shortListState = useSelector((state: RootStore) => state.shortlist);
  const [showSave, setShowSave] = useState<boolean>(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const BLOCK = "Search";
  const searchResults = useSelector((state: RootStore) => state.searchResults);
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );
  const tabItems = [
    {
      text: "search.tab.search",
      tabId: "search-view--tab",
      tabPanelId: "search-view--tab-panel",
      location: MENU_ITEMS.search.path,
    },
    {
      text: "search.tab.shortlist",
      tabId: "short-list-view--tab",
      tabPanelId: "short-list-view--tab-panel",
      location: `${MENU_ITEMS.search.path}/shortlist`,
    },
  ];

  // App insights custom metric
  useAppInsightsCustomMetric(location.pathname);

  useEffect(() => {
    setCurrentTab(
      tabItems.findIndex((item) => item.location === location.pathname)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (shortListState.saveNewShortList) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="shortlist.save" />,
        })
      );

      dispatch(saveNewShortList(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortListState.saveNewShortList]);

  const handleTabChange = (index: number) => {
    dispatch(setSelectedReport(null));
    dispatch(setSelectedDisclosure(null));
    history.push(tabItems[index].location, location.state);
  };

  useEffect(() => {
    if (
      currentUser &&
      currentUser.accessibleFeatures &&
      currentUser.accessibleFeatures.length > 0 &&
      !currentUser.accessibleFeatures.some(
        (feature) =>
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_All ||
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_Search
      )
    ) {
      history.push(unauthorizedPath);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, currentUser]);

  useEffect(() => {
    const locationState = location.state as any;
    const queryParams = new URLSearchParams(location.search);
    const savedSearchId = queryParams.get("savedSearchId");

    dispatch(
      loadShortListResults(
        {
          pageInfo: {
            pageNumber: 1,
            pageSize: 999,
          },
        },
        locationState && locationState.shortlistId
          ? locationState.shortlistId
          : 0
      )
    );
    if (locationState && locationState.shortlistId) {
      dispatch(getSavedShortlistCount());
    }

    if (savedSearchId) {
      dispatch(loadCurrentSavedSearch(savedSearchId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (shortListState.saveNewShortList) {
      dispatch(resetSearchResult());
      dispatch(resetShortList());
      dispatch(setCompanyFilter([]));
      dispatch(setContentValue(""));
      dispatch(setContentFilter([]));
      dispatch(setDocumentTypeFilter([]));
      dispatch(setPeriodFilter([]));
      dispatch(setIndustryFilter([]));
      dispatch(setGoverningBoardFilter([]));
      dispatch(setAuditorFilter([]));
      setShowModal(false);
      history.push(MENU_ITEMS.search.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortListState.saveNewShortList]);

  const handleStartNewModal = () => {
    if (shortListState.results.shortlistName !== null) {
      dispatch(setCompanyFilter([]));
      dispatch(setContentValue(""));
      dispatch(setContentFilter([]));
      dispatch(setDocumentTypeFilter([]));
      dispatch(setPeriodFilter([]));
      dispatch(setIndustryFilter([]));
      dispatch(setGoverningBoardFilter([]));
      dispatch(setAuditorFilter([]));
      dispatch(resetSearchResult());
      dispatch(resetShortList());
      dispatch(deleteShortlist(0));
      dispatch(setSelectedDisclosure(null));
      dispatch(setSelectedReport(null));
      dispatch(setShowFilters(true));
      history.push(MENU_ITEMS.search.path);
    } else if (shortListState.results.shortlistName == null) {
      dispatch(setSelectedDisclosure(null));
      dispatch(setSelectedReport(null));
      dispatch(setShowFilters(true));
      setShowModal(true);
    }
  };

  const handleStartNew = () => {
    dispatch(setCompanyFilter([]));
    dispatch(setContentValue(""));
    dispatch(setContentFilter([]));
    dispatch(setDocumentTypeFilter([]));
    dispatch(setPeriodFilter([]));
    dispatch(setIndustryFilter([]));
    dispatch(setGoverningBoardFilter([]));
    dispatch(setAuditorFilter([]));
    dispatch(resetSearchResult());
    if (shortListState.results.shortlistName == null) {
      dispatch(
        deleteShortlist(
          shortListState.results.shortlistId !== undefined
            ? shortListState.results.shortlistId
            : 0
        )
      );
      dispatch(resetShortList());
    }
    setShowModal(false);
    history.push(MENU_ITEMS.search.path);
  };

  const handleSaveShortList = () => {
    if (shortListState.results.shortlistName == null) {
      setShowSave(true);
      setShowModal(false);
    }
  };
  const onSaveShortlistHandler = (shortlistName: string) => {
    dispatch(
      saveShortlistCopy(
        shortListState.results && shortListState.results.shortlistId
          ? shortListState.results.shortlistId
          : 0,
        shortlistName
      )
    );
  };

  const submitFeedbackHandler = ({
    feedback,
    rating,
  }: {
    feedback: string;
    rating: number;
  }) => {
    submitFeedback({ feedback, rating }).then();
  };

  const showStartNew =
    shortListState.results !== null &&
    (shortListState.results.shortlistName ||
      (shortListState.results.shortlistItems &&
        shortListState.results.shortlistItems.length));

  return (
    <>
      {currentUser &&
        currentUser.accessibleFeatures &&
        currentUser.accessibleFeatures.length > 0 && (
          <div className={BLOCK} data-test="search-component">
            <div className={`${BLOCK}__header`} data-test="tab-content">
              <TabbedContent
                tabItems={tabItems}
                selectedIndex={currentTab}
                onChange={handleTabChange}
                data-test="tab-content"
              />
              <div className={`${BLOCK}__header-end-section`}>
                {showStartNew ? (
                  <div className={`${BLOCK}__start-new-section`}>
                    <button
                      className={`${BLOCK}__start-new-button`}
                      onClick={handleStartNewModal}
                      data-test="start-new-button"
                      data-testid="start-new-button"
                    >
                      <FormattedMessage id="shortlists.start.new" />
                    </button>
                  </div>
                ) : null}

                {showModal ? (
                  <div
                    data-test="start-new-modal"
                    data-testid="start-new-modal"
                  >
                    <Modal
                      show={showModal}
                      handleClose={() => {
                        setShowModal(false);
                      }}
                      header="seaechresult.unsaved.report"
                      config={false}
                    >
                      <ReportModal
                        handleClose={handleStartNew}
                        handleSave={handleSaveShortList}
                      />
                    </Modal>
                  </div>
                ) : (
                  ""
                )}

                {showSave && (
                  <div
                    data-test="saveShortList-modal"
                    data-testid="saveShortList-modal"
                  >
                    <SaveShortlist
                      setShowModal={setShowSave}
                      saveShortlist={onSaveShortlistHandler}
                    />
                  </div>
                )}
                <div className={`${BLOCK}__feedback-section`}>
                  <Button
                    className={`${BLOCK}__feedback-button button-primary-small`}
                    dataTest="feedback-button"
                    iconName="commenting"
                    iconHeight={24}
                    iconWidth={24}
                    formattedText="submit.feedback"
                    onClick={() => setShowFeedbackModal(true)}
                  />
                </div>
                {showFeedbackModal && (
                  <div
                    data-test="feedback-button-modal"
                    data-testid="feedback-button-modal"
                  >
                    <Feedback
                      setShowFeedbackModal={setShowFeedbackModal}
                      submitFeedback={submitFeedbackHandler}
                    />
                  </div>
                )}
              </div>
            </div>
            {currentTab === 0 && (
              <div
                className={`${BLOCK}__content ${BLOCK}__tab-panel ${
                  currentTab === 0 && `${BLOCK}__tab-panel--selected`
                }`}
                role="tabpanel"
                id={"short-list-view--tab-panel"}
                aria-labelledby={"short-list-view--tab"}
              >
                <Filters BLOCK={BLOCK} />
                <SearchResults BLOCK={BLOCK} />
              </div>
            )}
            {currentTab === 1 && (
              <div
                className={`${BLOCK}__content ${BLOCK}__tab-panel ${
                  currentTab === 1 && `${BLOCK}__tab-panel--selected`
                }`}
                role="tabpanel"
                id={"search-view--tab-panel"}
                aria-labelledby={"search-view--tab"}
              >
                <Shortlist />
              </div>
            )}
            <WhatsNewModal
              slides={OVERLAY_SEARCHES}
              slide={OVERLAY_SEARCHES[0]}
              page={PAGES.search}
            />
            {searchResults.showRequestUploadPanel ? (
              <RequestUploadPanel />
            ) : null}
          </div>
        )}
    </>
  );
};

export default Search;
