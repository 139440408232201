import api from "../api.service";

export const fetchNotification = () =>
  api.get(`notifications`).then(({ data }) => data);

  export const updateNotification = (payload: {
    notificationId: number;
    notificationStatus: number;

    
  }) => api.put(`notifications`, payload).then(({ data }) => data);

