import ProgressBar from "./progress-bar";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import { Fragment } from "react";
import Popover from "components/shared/popover/popover";
type Props = {
  data: any;
};
const ClimateScoreChart = ({ data }: Props) => {
  const companyName =
    data?.companyName !== "" ? data?.companyName?.companyName : "";
  const isDetailedView = data.isDetailView;
  const isNotCompany = data?.benchmarkType === 5 || data?.benchmarkType === 6;
  const noDataAvailable = isDetailedView
    ? data?.data?.map((element: any) =>
        element?.data?.reduce((acc: any, curr: any) => {
          if (
            curr.data.filter((item: any) => item.fieldValue === "*").length > 0
          )
            return [
              ...acc,
              curr.data?.filter((item: any) => item.fieldValue === "*"),
            ];
          return acc;
        }, [])
      )
    : data?.data?.filter((obj: any) =>
        obj?.data?.some(
          (field: any) => field.compScore === "*" || field.fieldValue === "*"
        )
      );

  return (
    <div
      className={"DashboardTile__scoreContainer"}
      data-test="climate-score-container"
    >
      {isDetailedView ? (
        <>
          {data?.data?.length > 0 ? (
            data?.data?.map((elem: any, i: number) => {
              return (
                <div
                  className={"DashboardTile__climateMetricWrapper"}
                  key={i}
                  data-test="detailed-climate-score-container"
                >
                  <div className={"DashboardTile__climateScoreSeparator"}>
                    <div className={"DashboardTile__scoreHolder"}>
                      <span className={"DashboardTile__scoreLimit"}>0</span>
                      <span>to</span>
                      <span className={"DashboardTile__scoreLimit"}>
                        {elem.fieldValueLimit}
                      </span>
                      <span>score</span>
                    </div>
                  </div>
                  {elem.data.map((metric: any, index: number) => (
                    <div
                      className={"DashboardTile__metricWrapperClimate"}
                      key={`metric-detailed-${index}`}
                    >
                      <div className={"DashboardTile__climateGroupDeatail"}>
                        <span className={""}>
                          <Popover
                            displayText={`${metric.fieldName}`}
                            content={metric.description ?? []}
                            metricClassName={"DashboardTile__companyDetailed"}
                          />
                        </span>
                      </div>
                      {metric.data.map((item: any, index: number) => (
                        <Fragment key={`score-detailed-${index}`}>
                          <div className={"DashboardTile__climateScoreDetail"}>
                            <span
                              className={
                                "DashboardTile__climateScoreNameDetail"
                              }
                              data-testid="score-name"
                            >
                              {item.companyName}
                            </span>
                            <span
                              className={
                                "DashboardTile__climateScoreValueDetail"
                              }
                            >
                              {item.fieldValue !== "" ? item.fieldValue : ""}
                            </span>
                            <ProgressBar
                              mainWidth={item.baseWidth}
                              baseWidth={""}
                              mainTooltip={`${item.fieldValue} ${item.companyName}`}
                              baseTooltip={""}
                              colors={item.colorCode}
                              gradient={false}
                            />
                          </div>
                        </Fragment>
                      ))}
                    </div>
                  ))}
                </div>
              );
            })
          ) : (
            <div className={"DashboardTile__noData"}>
              <FormattedMessage id="insights.cdp.no-data" />
            </div>
          )}
          {noDataAvailable?.length > 0 ? (
            <div className={"DashboardTile__noDataAny"}>
              <FormattedMessage id="no.data.available" />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          {isNotCompany && (
            <Fragment key={`legend-climate`}>
              <div className={`DashboardTile__legend`} key={"Legend metric"}>
                {[
                  { id: 1, colors: ["#00A3E0", "#00ABAB"] },
                  { id: 2, colors: ["#3D2074", "#EA078E"] },
                ].map((legend: any, index) => {
                  return (
                    legend && (
                      <div
                        className={`DashboardTile__legend__metric`}
                        key={`Legend-${index}`}
                      >
                        <div
                          className={`DashboardTile__legend__bar`}
                          key={`Legend-Bar-${index}`}
                        >
                          <ProgressBar
                            mainWidth={100}
                            baseWidth={""}
                            mainTooltip={""}
                            baseTooltip={""}
                            colors={legend.colors}
                            gradient={true}
                          />
                        </div>
                      </div>
                    )
                  );
                })}
                <span>{companyName}</span>
                <div className={`DashboardTile__legend__metric`}>
                  <div className="DashboardTile__legend__dot"></div>
                  <span>
                    {data.benchmarkType ===
                    INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK ? (
                      <FormattedMessage id="majority.peer" />
                    ) : (
                      <FormattedMessage id="industry.average" />
                    )}
                  </span>
                </div>
              </div>
            </Fragment>
          )}
          {data?.data?.length > 0 ? (
            data?.data?.map((elem: any, i: number) => (
              <div key={`score-chart${i}`}>
                {
                  <div className={"DashboardTile__climateScoreSeparator"}>
                    <div className={"DashboardTile__scoreHolder"}>
                      <span className={"DashboardTile__scoreLimit"}>0</span>
                      <span>to</span>
                      <span className={"DashboardTile__scoreLimit"}>
                        {elem.fieldValueLimit}
                      </span>
                      <span>score</span>
                    </div>
                  </div>
                }
                <div
                  className={"DashboardTile__scoreWrapper"}
                  key={`score-wrapper-${i}`}
                >
                  {elem?.data?.map((item: any, i: number) => (
                    <Fragment key={`score-detail-${i}`}>
                      <div className={"DashboardTile__scoreDetail"}>
                        {data.benchmarkType ===
                          INSIGHT_BENCHMARK_TYPE.INDUSTRY ||
                        data.benchmarkType ===
                          INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK ? (
                          <div className={"DashboardTile__scoreholder"}>
                            <span
                              className={"DashboardTile__scoreholder--base"}
                            >
                              {item.compScore}
                            </span>
                          </div>
                        ) : (
                          <span className={"DashboardTile__scoreValue"}>
                            {item.fieldValue !== "" ? item.fieldValue : ""}
                          </span>
                        )}
                        <span
                          data-testid="score-name"
                          className="DashboardTile__scoreSpan"
                        >
                          <Popover
                            metricClassName="DashboardTile__scoreName"
                            displayText={item.fieldName}
                            content={item.description ?? []}
                          />
                        </span>
                      </div>
                      <ProgressBar
                        mainWidth={item.feildWidth}
                        baseWidth={item.baseWidth !== "" ? item.baseWidth : ""}
                        mainTooltip={item.tooltip}
                        baseTooltip={item.baseTooltip}
                        colors={item.colors}
                        gradient={data.gradient}
                        value={item.fieldValue}
                        tileType={data.tileType}
                      />
                    </Fragment>
                  ))}
                </div>
                <div className={"DashboardTile__scoreRange"}>
                  {Array.from({ length: 11 }, (value, index) => index).map(
                    (e) => {
                      return (
                        <span key={`range-${e}`}>
                          {elem.fieldValueLimit === 100 ? (e % 100) * 10 : e}
                        </span>
                      );
                    }
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className={"DashboardTile__noData"}>
              <FormattedMessage id="insights.cdp.no-data" />
            </div>
          )}
          {noDataAvailable?.length > 0 ? (
            <div className={"DashboardTile__noDataAny"}>
              <FormattedMessage id="no.data.available" />
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
export default ClimateScoreChart;
