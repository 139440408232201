import { BaseModel, errorModel } from "../../utils/redux.utils";

export type Download = {
  exportDetailId: number;
  exportReferenceId: number;
  globalPersonId: number;
  fileKey: string;
  exportStartDate: string;
  exportState: number;
  exportFileName: string;
  exportType: number;
  exportFormat: number;
  createdDate: string;
  lastUpdatedDate?: string;
};

export interface DownloadState {
  error?: errorModel;
  loading: boolean;
  showDownloads: boolean;
  downloads: Download[];
}

export class DownloadModel extends BaseModel {
  constructor() {
    super({
      loading: false,
      showDownloads: false,
      downloads: null,
    });
  }

  setLoading = (loading: boolean): DownloadState => this.merge({ loading });

  toggleShowDownloads = (): DownloadState =>
    this.merge({ showDownloads: !this.state.showDownloads });

  setShowDownloads = (showDownloads: boolean): DownloadState =>
    this.merge({ showDownloads });

  setDownloads = (downloads: Download[]): DownloadState =>
    this.merge({ downloads: downloads ? downloads : [] });

  setDownloadState = (downloadState: any) => {
    const downloads = [...this.state.downloads];
    const download = downloads.find(
      (d: Download) => d.exportDetailId === downloadState.exportDetailId
    );
    if (download) download.exportState = downloadState.exportState;
    return this.merge({
      downloads: downloads,
    });
  };
}
