import api from "..//api.service";

export const fetchNote = (payload: { commentId: number }) =>
  api.get(`comments/${payload.commentId}`).then(({ data }) => data);

export const saveNote = (payload: {
  referenceId: number;
  commentNotes: string;
  commentNoteReferenceType: number;
}) => api.post(`comments`, payload).then(({ data }) => data);

export const editNote = (payload: {
  commentNoteId: number;
  referenceId: number;
  commentNotes: string;
  commentNoteReferenceType: number;
}) => {
  return api.put(`comments`, payload).then(({ data }) => data);
};

export const deleteNote = (payload: { commentId: number }) => {
  return api.delete(`/comments/${payload.commentId}`).then(({ data }) => data);
};
