import React, { useEffect, useState } from "react";
import Modal from "../shared/modal/modal";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import classNames from "classnames";
import Icon from "components/shared/icon/icon";
import { FileRejection, useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  FILE_FORMATS,
  FILE_VALIDATION_ERRORS,
  UPLOAD_STATUS,
} from "utils/constants";
import UploadItem from "./upload-item";
import { RootStore } from "services/store.service";
import {
  setFilesToUpload,
  uploadFileReport,
} from "services/manage-files/manage-files.service";
import { Upload } from "services/manage-files/manage-files.model";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { addToastMessage } from "services/commons.service";
import Button from "components/shared/button/button";
type props = {
  setShowModal: (value: boolean) => void;
  handleTabChange: (value: number) => void;
};

const UploadFiles = ({ setShowModal, handleTabChange }: props) => {
  const BLOCK = "upload-files";
  const [okDisabled, setOkDisabled] = useState<boolean>(true);
  const [files, setFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const FILE_SIZE_LIMIT = 1073741824;
  const [hover, setHover] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const labelFormats = FILE_FORMATS.map((f) => f.label).reduce(
    (prev, curr, index) => (index === 0 ? curr : `${prev}, ${curr}`)
  );
  // const acceptedFormats = FILE_FORMATS.map((f) => f.extension).reduce(
  //   (prev, curr, index) => (index === 0 ? curr : `${prev},${curr}`)
  // );
  const manageFilesState = useSelector((state: RootStore) => state.manageFiles);
  const [isDragDropDisabled, setIsDragDropDisabled] = useState<boolean>(false);
  function fileSizeValidator(file: File) {
    if (file.size > FILE_SIZE_LIMIT) {
      return {
        code: FILE_VALIDATION_ERRORS[0].code,
        message: FILE_VALIDATION_ERRORS[0].code,
      };
    }
    return null;
  }
  function onDropAcceptedFiles(tmpFiles: File[], event: any) {
    setFiles([...files, ...tmpFiles]);
    let filesToUpload = [...files, ...tmpFiles].map((f, i) => {
      return {
        fileId: i,
        file: f,
        progress: 5,
        progressStatus: UPLOAD_STATUS.UPLOADING,
        errorMessages: [],
      };
    });
    dispatch(setFilesToUpload(filesToUpload));
    setIsDragDropDisabled(true);
    setRejectedFiles([]);
  }

  function onDropRejectedFiles(tmpFiles: any, event: any) {
    setRejectedFiles([...tmpFiles]);
  }

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isFileDialogActive,
  } = useDropzone({
    accept: ".zip",
    noClick: true,
    noKeyboard: true,
    validator: fileSizeValidator,
    onDropAccepted: onDropAcceptedFiles,
    onDropRejected: onDropRejectedFiles,
    onDragOver: () => {
      setHover(true);
    },
    onDragLeave: () => {
      setHover(false);
    },
    maxFiles: 1,
    disabled: isDragDropDisabled,
  });

  //const [disabled, setDisabled] = useState(true);
  const [showNextStep, setShowNextStep] = useState<boolean>(false);

  const getFilesList = manageFilesState.uploads.map(
    (item: Upload, index: number) => (
      <UploadItem key={index} BLOCK={BLOCK} item={item} index={index} />
    )
  );
  useEffect(() => {
    if (
      manageFilesState.uploads.some(
        (u) => u.progressStatus === UPLOAD_STATUS.UPLOADING
      )
    ) {
      setOkDisabled(true);
    } else {
      if (
        manageFilesState.uploads.some(
          (u) => u.progressStatus === UPLOAD_STATUS.UPLOADED
        )
      ) {
        setOkDisabled(false);
        setTabIndex(0);
      }
    }
  }, [manageFilesState.uploads]);
  const getRejectedFilesErrors = () => {
    let errorCounter = 0;
    let maxFileError = 0;
    let renderRejectedFiles = rejectedFiles.map((rejected, index) => {
      return rejected.errors.map((error, ind) => {
        if (error.code === FILE_VALIDATION_ERRORS[2].code) maxFileError++;
        if (maxFileError > 1 && error.code === FILE_VALIDATION_ERRORS[2].code)
          // The following code was added for console warning. Earlier version didn't return undefined but only returned
          return undefined;
        errorCounter++;
        let msj =
          error.code === FILE_VALIDATION_ERRORS[0].code
            ? "upload.files.size.error"
            : error.code === FILE_VALIDATION_ERRORS[1].code
            ? "upload.files.type.error"
            : "upload.files.max.error";

        let tmpName: any = rejected.file.name;

        return (
          <div
            key={ind}
            className={classNames({
              [`${BLOCK}__error-file-wrapper`]: errorCounter > 2,
              [`${BLOCK}__error-file-wrapper--bottom`]: errorCounter <= 2,
            })}
          >
            <span key={ind} className={`${BLOCK}__error-file-validation`}>
              <FormattedMessage
                id={msj}
                values={{
                  fileName:
                    tmpName.length > 45
                      ? `${tmpName.slice(0, 45)}...`
                      : tmpName,
                }}
              />
              {tmpName.length > 45 && (
                <Tooltip
                  position={
                    errorCounter > 2
                      ? TooltipPosition.top
                      : TooltipPosition.bottom
                  }
                >
                  {tmpName}
                </Tooltip>
              )}
            </span>
          </div>
        );
      });
    });
    return renderRejectedFiles;
  };

  const uploadFiles = () => {
    manageFilesState.uploads.forEach((f, i) => {
      dispatch(uploadFileReport(f.file, i));
    });
  };
  return (
    <Modal
      show={true}
      header="upload.files.title"
      handleClose={
        showNextStep
          ? undefined
          : () => {
              setShowModal(false);
            }
      }
      config={false}
    >
      <div className={`${BLOCK}__container`}>
        <div
          className={classNames(`${BLOCK}__dropzone-container`, {
            [`${BLOCK}__dropzone-container--disabled`]:
              isDragDropDisabled && !showNextStep,

            [`${BLOCK}__dropzone-container--white`]: showNextStep,
          })}
        >
          {!showNextStep ? (
            <div
              className={classNames(`${BLOCK}__dropzone`, {
                [`${BLOCK}__dropzone--white`]: hover,
                [`${BLOCK}__dropzone--disabled`]: isDragDropDisabled,
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div
                className={classNames(`${BLOCK}__icon-container`, {
                  [`${BLOCK}__icon-container--disabled`]: isDragDropDisabled,
                })}
              >
                <Icon height={48} width={48} name="upload2" />
              </div>
              <span className={`${BLOCK}__subtitle`}>
                <FormattedMessage id="upload.files.drag.and.drop" />
              </span>
              <span className={`${BLOCK}__formats`}>
                <FormattedMessage
                  id="upload.files.format.files"
                  values={{ formats: labelFormats }}
                />
              </span>
              <button
                className={classNames(`${BLOCK}__btn-select`, {
                  [`${BLOCK}__btn-select--disabled`]: isDragDropDisabled,
                })}
                onClick={open}
              >
                <FormattedMessage id="upload.files.select.files" />
              </button>
            </div>
          ) : (
            files.length > 0 && getFilesList
          )}
        </div>
        {!showNextStep && files.length > 0 && (
          <div className={`${BLOCK}__count-wrapper`}>
            <Icon name="documents2" height={18} width={18} />
            <span className={`${BLOCK}__count-label`}>
              <FormattedMessage
                id="upload.files.accepted.count"
                values={{ count: files.length }}
              />
            </span>
          </div>
        )}
        {!showNextStep && rejectedFiles.length > 0 && (
          <div
            className={classNames({
              [`${BLOCK}__errors`]: rejectedFiles.length <= 3,
              [`${BLOCK}__errors--yscroll`]: rejectedFiles.length > 3,
            })}
          >
            {getRejectedFilesErrors()}
          </div>
        )}

        <div className={`${BLOCK}__options`}>
          {!showNextStep && (
            <Button
              className={`button-secondary`}
              formattedText="upload.files.cancel.button"
              onClick={() => setShowModal(false)}
            />
          )}

          {showNextStep ? (
            <Button
              className={"button-primary"}
              dataTest="button-continue"
              disabled={okDisabled}
              formattedText="upload.files.ok.button"
              onClick={() => {
                handleTabChange(tabIndex);
                setShowModal(false);
                dispatch(
                  addToastMessage({
                    description: (
                      <FormattedMessage id="manage-files.upload.in-progress" />
                    ),
                  })
                );
              }}
            />
          ) : (
            <Button
              className="button-primary"
              formattedText="upload.files.upload.button"
              disabled={
                isDragActive || isFileDialogActive || files.length === 0
              }
              onClick={() => {
                if (files.length > 0 && !isFileDialogActive && !isDragActive) {
                  uploadFiles();
                  setShowNextStep(true);
                }
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UploadFiles;
