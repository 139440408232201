import { BaseModel, errorModel } from "utils/redux.utils";

export interface NoteState {
  error?: errorModel;
  loading: boolean;
  NoteFetch: NoteData;
  saveSuccess: boolean;
  saveError: boolean;
  deleteSuccess: boolean;
  deleteError: boolean;
}

export class NoteModel extends BaseModel {
  constructor() {
    super({
      loading: false,
      NoteFetch: {},
      saveSuccess: false,
      saveError: false,
      deleteSuccess:false,
      deleteError:false,
    });
  }
  setError = (error: errorModel): NoteState => this.merge({ error });
  setLoading = (loading: boolean): NoteState => this.merge({ loading });
  setNote = (NoteFetch: NoteData): NoteState =>
    this.merge({ NoteFetch, loading: false });
  setSaveSuccess = (saveSuccess: boolean): NoteState => this.merge({ saveSuccess });
  setSaveError = (saveError: boolean): NoteState => this.merge({ saveError });
  setDeleteSuccess = (deleteSuccess: boolean): NoteState => this.merge({ deleteSuccess });
  setDeleteError = (deleteError: boolean): NoteState => this.merge({ deleteError });
}

export type NoteData = {
  commentNoteId: number;
  referenceId: number;
  commentNotes: string;
  commentNoteReferenceType: number;
  createdBy: number;
  createdDate: string;
  lastUpdatedDate: string;
};
