import { useEffect, useState } from "react";

const useDebounceMetrics = (pfilter: string) => {
  const [filter, setFilter] = useState<string>("");
  useEffect(() => {
    let timer = setTimeout(() => {
      setFilter(pfilter);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [pfilter]);

  return filter;
};

export default useDebounceMetrics;
