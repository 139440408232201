import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import React, { Fragment, useRef } from "react";
import { subTextColor, textColor } from "utils/constants";
import { ScaleSVG } from "@visx/responsive";
import { GroupedLegends, Legend } from "services/dashboard/dashboard.model";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { defaultStyles, withTooltip } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { mod } from "utils/functions";

const tooltipStyles = {
  ...defaultStyles,
  zIndex: 2,
  minWidth: 50,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
  fontSize: 15,
  width: "fit-content",
};

type Props = {
  data: Legend[] | GroupedLegends[];
  name: string;
  index: number;
  size?: number;
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
};

const IndividualChart = ({
  data,
  name,
  size,
  index,
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
  handleGraphElementClicked,
}: Props & WithTooltipProvidedProps<Legend>) => {
  let tooltipTimeout: number;
  const radius = size ? size / 2 : 75;
  const dataset = (data as Legend[]).filter((d) => d.legendData > 0);
  const containerRef = useRef(null);
  return (
    <div ref={containerRef} className="pie-chart">
      <ScaleSVG width={size} height={size}>
        <Group top={radius} left={radius}>
          <Pie
            data={dataset}
            pieSort={(d1, d2) => d1.legendOrder - d2.legendOrder}
            pieValue={(data) => data.legendData ?? 0.1}
            outerRadius={radius}
            innerRadius={({ data }) => {
              const innerRadius =
                radius /
                (tooltipOpen &&
                tooltipData &&
                tooltipData.legendValue === data.legendValue
                  ? 4
                  : 4.5);
              return radius - innerRadius;
            }}
            padAngle={0.01}
          >
            {(pie) =>
              pie.arcs.map((arc, i) => (
                <g
                  key={`${arc.data.legendValue}-${i}`}
                  onMouseLeave={() =>
                    (tooltipTimeout = window.setTimeout(() => {
                      hideTooltip();
                    }, 100))
                  }
                  onMouseMove={(e) => {
                    const container = (
                      containerRef.current as any
                    ).getBoundingClientRect();
                    if (tooltipTimeout) clearTimeout(tooltipTimeout);
                    showTooltip({
                      tooltipData: arc.data,
                      tooltipTop: e.clientY - container.y,
                      tooltipLeft: e.clientX - container.x,
                    });
                  }}
                  style={handleGraphElementClicked ? { cursor: "pointer" } : {}}
                  onClick={() =>
                    handleGraphElementClicked
                      ? handleGraphElementClicked(arc.data)
                      : null
                  }
                >
                  <path
                    d={pie.path(arc) || undefined}
                    fill={arc.data.legendColor}
                  />
                </g>
              ))
            }
          </Pie>

          <>
            <Text textAnchor="middle" fill={textColor} fontSize={radius / 3}>
              {dataset.reduce((acc, data) => acc + (data.legendData ?? 0), 0)}
            </Text>

            <Text
              textAnchor="middle"
              verticalAnchor="start"
              fill={subTextColor}
              width={size ? size - size / 3 : 50}
              fontSize={radius / 5}
              dy={radius / 10}
            >
              {name}
            </Text>
          </>
        </Group>
      </ScaleSVG>
      {tooltipOpen && tooltipData && (
        <div
          style={{
            ...tooltipStyles,
            top: tooltipTop,
            left: mod(index + 1, 4) === 0 ? "unset" : tooltipLeft,
            right:
              mod(index + 1, 4) === 0
                ? `calc(100% - ${tooltipLeft}px)`
                : "unset",
            position: "absolute",
          }}
        >
          <strong>
            {
              (tooltipData.legendTooltip
                ? tooltipData.legendTooltip
                : tooltipData.legendValue) as React.ReactNode
            }
          </strong>{" "}
          ({tooltipData.legendData ?? 0})
        </div>
      )}
    </div>
  );
};

const MultipleChart = ({
  data,
  name,
  size = 250,
  handleGraphElementClicked,
}: Props) => {
  const dataset = data as GroupedLegends[];
  const Pie = withTooltip<Props, Legend>(IndividualChart);
  return (
    <div className="multiple-chart">
      {dataset.map((group, index) => (
        <div className="multiple-chart__pie" key={`multiple-chart-${index}`}>
          <ParentSize debounceTime={10}>
            {({ width: visWidth, height: visHeight }: any) => (
              <>
                {Pie({
                  data: group.legends,
                  name,
                  index,
                  size: 5,
                  handleGraphElementClicked,
                })}
                <b style={{ textAlign: "center" }}>{index + 1}</b>
              </>
            )}
          </ParentSize>
        </div>
      ))}
    </div>
  );
};

const PieChart = ({
  data,
  name,
  size = 150,
  handleGraphElementClicked,
}: Props) => {
  const Pie = withTooltip<Props, Legend>(IndividualChart);
  return data && data.length && (data[0] as GroupedLegends).groupName
    ? MultipleChart({ data, name, index: 0, size, handleGraphElementClicked })
    : Pie({ data, name, index: 0, size, handleGraphElementClicked });
};

export default PieChart;
