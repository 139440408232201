import { showTooltip } from "services/commons.service";
import { TooltipPosition } from "components/shared/tooltip/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import {
  BENCHMARK_TILE_TYPES,
  GRADIENT_UNIT_FOR_PROGRESSBAR,
} from "utils/constants";

type Props = {
  mainWidth: any;
  baseWidth: any;
  mainTooltip: any;
  baseTooltip: any;
  colors: any;
  gradient: boolean;
  isDetailedView?: boolean;
  tileType?: number;
  value?: string;
  exporting?: boolean;
} & GHGProps;

type GHGProps = {
  peerOrIndustryTooltip?: any;
  baseScorePos?: any;
  peerScorePos?: any;
};

const ProgressBar = ({
  mainWidth,
  baseWidth,
  mainTooltip,
  baseTooltip,
  peerOrIndustryTooltip,
  colors,
  gradient,
  isDetailedView,
  tileType,
  value,
  baseScorePos,
  peerScorePos,
  exporting,
}: Props) => {
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const dispatch = useDispatch();
  const colorCode = isDetailedView ? colors.split(",") : colors;
  const isEnergyUndertaken =
    tileType === BENCHMARK_TILE_TYPES.ENERGY_UNDERTAKEN;
  const isClimateScores =
    tileType === BENCHMARK_TILE_TYPES.CLIMATE_RELATED_TOPICS;
  const isOtherSustainability =
    tileType === BENCHMARK_TILE_TYPES.OTHER_SUSTAINABILITY_RANKING;
  const isGhgIntensity = tileType === BENCHMARK_TILE_TYPES.GHG_INTENSITY;
  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    position: TooltipPosition = isEnergyUndertaken
      ? TooltipPosition.top
      : TooltipPosition.progressBarTop,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("div");
    if (!element) {
      return;
    }

    dispatch(
      showTooltip({
        children: children,
        position: position,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };
  const Parentdiv = {
    height: 2,
    width: isClimateScores ? "100%" : "80%",
    backgroundColor: "#E0E0E0",
    borderRadius: 20,
    margin: 10,
  };

  const ParentdivForDetailed = {
    height: 2,
    width: isClimateScores ? "100%" : "80%",
    backgroundColor: "#E0E0E0",
    borderRadius: 20,
    marginTop: 10,
    marginBottom: 10,
  };

  const ParentdivForEnergyUndertaken = {
    height: 10,
    width: "95%",
    border: "solid 1px #E0E0E0",
    backgroundColor: "#FAFAFA",
    borderRadius: 20,
    margin: 10,
  };

  const ParentdivForGhgIntensity = {
    height: 5,
    width: "100%",
    border: "1px solid #E0E0E0",
    backgroundColor: "#FAFAFA",
    borderRadius: 4,
    margin: 10,
  };

  const ChilddivForEnergyUndertaken = {
    height: 10,
    width:
      value && ["yes", "no"].includes(value.toLowerCase()) ? `50%` : "100%",
    background:
      gradient && mainWidth > 0.5
        ? `linear-gradient(300.88deg, ${colors[0]} 8.83%, ${colors[1]} 91.73%)`
        : `${colors[1]}`,
    borderRadius:
      value?.toLowerCase() === "yes"
        ? "0px 4px 4px 0px"
        : value?.toLowerCase() === "no"
        ? "4px 0px 0px 4px"
        : 32,
    marginLeft: value?.toLowerCase() === "yes" ? "50%" : 0,
  };

  const Childdiv = {
    height: 5,
    width: `${mainWidth}%`,
    background:
      gradient && mainWidth > 0.5
        ? `linear-gradient(300.88deg, ${colors[0]} 8.83%, ${colors[1]} 91.73%)`
        : `${colors[0]}`,
    borderRadius: 32,
    top: "-1px",
  };

  const ChilddivForDetailedView = {
    height: 5,
    width: `${mainWidth}%`,
    background: gradient
      ? mainWidth < GRADIENT_UNIT_FOR_PROGRESSBAR
        ? `linear-gradient(270deg, ${colorCode[0]} -792.3%, ${colorCode[1]}  -431.07%,
      ${colorCode[2]}  -291.65%, ${colorCode[3]}  -105.75%,${colorCode[4]}  100%
      )`
        : mainWidth > GRADIENT_UNIT_FOR_PROGRESSBAR &&
          mainWidth < GRADIENT_UNIT_FOR_PROGRESSBAR * 2
        ? `linear-gradient(270deg, ${colorCode[0]} -76.15%, ${colorCode[1]}  -19.52%,
    ${colorCode[2]}  14.43%, ${colorCode[3]}  59.38%,${colorCode[4]}  100%
    )`
        : `linear-gradient(270deg, ${colorCode[0]} 0%, ${colorCode[1]}  20.83%,
          ${colorCode[2]}  39.59%, ${colorCode[3]}  59.9%,${colorCode[4]}  76.94%, ${colorCode[5]}  100%
          )`
      : `${colors[1]}`,
    borderRadius: 32,
    top: "-1px",
  };

  const ChilddivForGhgIntensity = {
    height: 8,
    width: `101%`,
    background: `linear-gradient(270deg, ${colorCode[0]} 0%, ${colorCode[1]} 103.97%)`,
    borderRadius: 4,
    top: "-1px",
  };

  const score = {
    height: "1.25rem",
    background: !isOtherSustainability
      ? "#00A3E0"
      : baseWidth < mainWidth
      ? "radial-gradient(48.07% 48.07% at 49.34% 50.68%, #FFC700 0%, #E35A0E 64.44%, #C82518 100%)"
      : "radial-gradient(50% 50% at 50% 50%, #90E74A 0%, #68BC25 53.5%, #046A38 100%)",
    boxShadow: !isOtherSustainability
      ? "0px 0px 0px 1px #005587, 0px 0px 2px 3px rgba(0, 85, 135, 0.12), inset 0px 0px 6px 3px #0076A8"
      : baseWidth < mainWidth
      ? "0px 0px 0px 1px #C82518, 0px 0px 2px 3px rgba(4, 106, 56, 0.12), inset 0px 0px 6px 3px #E35A0E"
      : "0px 0px 0px 1px #046A38, 0px 0px 2px 3px rgba(4, 106, 56, 0.12), inset 0px 0px 6px 3px #26890D",
    borderRadius: "51px",
    left: `${baseWidth - 2.6}%`,
    width: "1.25rem",
    top: "-9px",
  };

  //To show baseline company tick mark in GHG Intensity range chart
  const GhgIntensityScore = {
    height: "20px",
    width: "8px",
    background: "#00A3E0",
    boxShadow: !exporting
      ? "inset 0px 0px 1px #002254, inset 0px 0px 5px #005C83"
      : "",
    borderRadius: "3px",
    left: `${baseScorePos}%`,
    top: "calc(50% - 20px/2)",
  };

  //To show peers average value tick mark in GHG Intensity range chart
  const GhgIntensityPeerScore = {
    height: "20px",
    width: "8px",
    background: "#C8158B",
    boxShadow: !exporting
      ? "inset 0px 0px 1px #580060, inset 0px 0px 5px #9600BC"
      : "",
    borderRadius: "3px",
    left: `${peerScorePos}%`,
    top: "calc(50% - 20px/2)",
  };

  return (
    <div
      style={
        isDetailedView
          ? ParentdivForDetailed
          : isEnergyUndertaken
          ? ParentdivForEnergyUndertaken
          : isGhgIntensity
          ? ParentdivForGhgIntensity
          : Parentdiv
      }
      className={"DashboardTile__parentdiv"}
      data-testid={`progress-bar`}
    >
      {mainWidth !== "" && (
        <div
          data-testid="child-div"
          style={
            isDetailedView
              ? ChilddivForDetailedView
              : isEnergyUndertaken
              ? ChilddivForEnergyUndertaken
              : isGhgIntensity
              ? ChilddivForGhgIntensity
              : Childdiv
          }
          className={"DashboardTile__child"}
          onMouseEnter={(e) => {
            if (mainTooltip)
              handleMouseEnter(e, mainTooltip, "DashboardTile__tooltip");
          }}
          onMouseLeave={handleMouseLeave}
        ></div>
      )}
      {/* Here the acceptable values are numbers and alphabets like 1, 9.7 ,88
      ,100 ,A ,-B,D // we dont show the dot when value is null ,undefined or "*" */}
      {baseWidth !== "" &&
      baseWidth !== null &&
      value !== "*" &&
      value !== null &&
      typeof baseWidth !== "undefined" ? (
        <div>
          {
            //for showing peer average value score in GHG Intensity Tile
            isGhgIntensity && (peerScorePos || peerScorePos === 0) && (
              <div
                data-testid="ghg-intensity-score-peer"
                style={GhgIntensityPeerScore}
                className={"DashboardTile__score"}
                onMouseEnter={(e) => {
                  if (peerOrIndustryTooltip)
                    handleMouseEnter(
                      e,
                      peerOrIndustryTooltip,
                      "DashboardTile__tooltip"
                    );
                }}
                onMouseLeave={handleMouseLeave}
              />
            )
          }
          <div
            data-testid="ghg-intensity-score-base"
            style={
              isGhgIntensity && (baseScorePos || baseScorePos === 0) //Check to detrmine whether or not to show GHGIntensity baseAvg tick mark
                ? GhgIntensityScore
                : !isGhgIntensity
                ? score // if not GHGintensity tile show appropriate score on progress bar if applicable
                : undefined
            }
            className={"DashboardTile__score"}
            onMouseEnter={(e) => {
              if (baseTooltip)
                handleMouseEnter(e, baseTooltip, "DashboardTile__tooltip");
            }}
            onMouseLeave={handleMouseLeave}
          >
            {isClimateScores && (
              <span>{`${
                value && Number(value) >= 10
                  ? Math.round(Number(value))
                  : isNaN(Number(value))
                  ? value
                  : Number(value).toFixed(1)
              }`}</span>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProgressBar;
