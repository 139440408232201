import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report } from "powerbi-client";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  secretKey,
  MINUTES_BEFORE_EXPIRATION,
  DASHBOARD_TYPE,
  exportOptions,
  POWERBI_DASHBOARD,
} from "utils/constants";
import { RootStore } from "services/store.service";
import {
  getEmbedTokenpowerbi,
  getEmbedURLpowerbi,
  getHumancapitalBookmark,
  getPowerbiBookmark,
  saveBookmark,
  setAccessTokenpowerbi,
  esrsDashboardAccessed,
} from "services/powerbi-dashboard/powerbi.service";
import {
  getReport,
  getReportId,
  getReportStatus,
} from "services/powerbi-dashboard/powerbi.api";
import { PowerbiBookmark } from "services/powerbi-dashboard/powerbi.model";
import { setShowExportPanel } from "services/insights/insights.service";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import ExportPanel from "components/insights/export-panel";
import moment from "moment";
import { addToastMessage } from "services/commons.service";

export interface Bookmark extends models.IReportBookmark {
  checked?: boolean;
}

type Props = {
  reportId: string;
  workSpaceId: string;
  currentTab: number;
};

const PowerbiDashboard = ({ reportId, workSpaceId, currentTab }: Props) => {
  const BLOCK = "powerbi-dashboard";
  const dispatch = useDispatch();
  const powerbiState = useSelector((state: RootStore) => state.powerbi);
  const insightsState = useSelector((state: RootStore) => state.insights);
  const manageState = useSelector((state: RootStore) => state.manageFiles);
  const [selectedExport, setExport] = useState<string>("pdf");
  const [exportReportId, setExportReportId] = useState<string>("");
  const [exportStatus, setExportStatus] = useState<any>({});
  const [showExport, setShowExport] = useState<boolean>(false);
  const [showExportPanel, setExportPanel] = useState<boolean>(false);
  const [currentPageName, setCurrentPageName] = useState<string>("");
  const [activeMessagesForEmbedHeight, setActiveMessagesForEmbedHeight] =
    useState<number>(0);
  const eventHandlersMap: Map<string, any> = new Map();
  useEffect(() => {
    dispatch(getPowerbiBookmark(null));
    dispatch(
      getHumancapitalBookmark(
        currentTab === POWERBI_DASHBOARD.ESRS
          ? DASHBOARD_TYPE.ESRS
          : DASHBOARD_TYPE.HUMAN_CAPITAL
      )
    );
    if (!powerbiState.accessToken) {
      dispatch(setAccessTokenpowerbi(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (powerbiState.embedToken.expiration) {
      let expirationTime = Date.parse(powerbiState.embedToken.expiration);
      // EXPIRATION TIME - CURRENT DATETIME - 10 MINUTES
      let timerForRefreshingToken =
        expirationTime - Date.now() - MINUTES_BEFORE_EXPIRATION;

      let timeout = setTimeout(() => {
        dispatch(setAccessTokenpowerbi(true));
      }, timerForRefreshingToken);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powerbiState.embedToken.expiration]);

  useEffect(() => {
    setActiveMessagesForEmbedHeight(
      manageState.cachedActiveMaintenanceMessages.length
    );
  }, [manageState.cachedActiveMaintenanceMessages]);

  const exportHandler = () => {
    getReportId(
      workSpaceId,
      reportId,
      selectedExport === "pdf" ? "PDF" : "PPTX",
      currentPageName,
      powerbiState.powerBiBookmark.powerBIBookmarkState!,
      powerbiState.accessToken
    )
      .then((data: any) => {
        setExportReportId(data.id);
        setExportPanel(false);
        dispatch(
          addToastMessage({
            description: <FormattedMessage id="processing.report" />,
          })
        );
        dispatch(setShowExportPanel(false));
      })
      .catch((error: any) => {
        dispatch(setShowExportPanel(false));
      });
  };

  function statusReport() {
    getReportStatus(
      workSpaceId,
      reportId,
      exportReportId,
      powerbiState.accessToken
    )
      .then((data: any) => {
        setExportStatus(data);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    if (
      exportReportId &&
      Object.getOwnPropertyNames(exportStatus).length === 0
    ) {
      //PowerBI in backend takes around 20 sec to process the reports that is why delaying it for 12 sec
      let timeout = setTimeout(() => {
        statusReport();
      }, 12000);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportReportId]);

  useEffect(() => {
    if (
      exportReportId &&
      Object.getOwnPropertyNames(exportStatus).length !== 0 &&
      exportStatus.status !== "Succeeded"
    ) {
      //PowerBI in backend takes around 20 sec to process the reports
      // that is why calling the api in  every 5 sec to
      // check the report status after the initial delay of 12 sec
      let timeout = setTimeout(() => {
        statusReport();
      }, 5000);
      return () => clearTimeout(timeout);
    } else if (exportReportId && exportStatus.status === "Succeeded") {
      getReport(
        workSpaceId,
        reportId,
        exportReportId,
        powerbiState.accessToken
      ).then((data: any) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        var blob = new Blob([data], {
            type:
              selectedExport === "pdf"
                ? "application/pdf"
                : "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          }),
          url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "ESRS_" + moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
        a.click();
        window.URL.revokeObjectURL(url);
        dispatch(
          addToastMessage({
            description: <FormattedMessage id="download.success" />,
          })
        );
        setExportReportId("");
        setExportStatus({});
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportStatus, exportReportId]);

  //USING IN PAYLOAD
  const generateTokenPayload = {
    accessLevel: "View",
  };

  useEffect(() => {
    if (powerbiState.accessToken) {
      dispatch(
        getEmbedTokenpowerbi(
          workSpaceId,
          reportId,
          generateTokenPayload,
          powerbiState.accessToken
        )
      );
      dispatch(
        getEmbedURLpowerbi(workSpaceId, reportId, powerbiState.accessToken)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powerbiState.accessToken]);

  eventHandlersMap.set(
    "loaded",
    async function (event: any, embeddedReport: Report) {
      const report = embeddedReport as Report;
      if (
        powerbiState.powerBiBookmark &&
        powerbiState.powerBiBookmark.powerBIBookmarkState
      ) {
        await report.bookmarksManager.applyState(
          powerbiState.powerBiBookmark.powerBIBookmarkState!
        );
      }
      setShowExport(true);
      embeddedReport.render();
    }
  );

  eventHandlersMap.set(
    "rendered",
    async function (event: any, embeddedReport: Report) {
      dispatch(esrsDashboardAccessed(true));
      const latestReport = embeddedReport as Report;

      let activePage = await latestReport.getActivePage();
      setCurrentPageName(activePage.name);
      if (latestReport) {
        const capturedBookmark: PowerbiBookmark =
          await latestReport!.bookmarksManager.capture();
        if (capturedBookmark.state) {
          let bookmarkPayload = {
            powerBIBookmarkDisplayName: capturedBookmark.displayName,
            powerBIBookmarkName: capturedBookmark.name,
            powerBIBookmarkState: capturedBookmark.state,
            insightsCategoryId:
              currentTab === POWERBI_DASHBOARD.ESRS
                ? DASHBOARD_TYPE.ESRS
                : DASHBOARD_TYPE.HUMAN_CAPITAL,
          };
          dispatch(saveBookmark(bookmarkPayload));
        }
      }
    }
  );

  eventHandlersMap.set(
    "visualClicked",
    async function (event: any, embeddedReport: Report) {
      dispatch(esrsDashboardAccessed(true));
    }
  );

  return (
    <div className={`${BLOCK}`} data-testid={`${BLOCK}`}>
      <header className={`${BLOCK}__header`}>
        {showExport && (
          <button
            className={`${BLOCK}__export-all-button`}
            data-testid={`${BLOCK}__export-all-button`}
            onClick={() => {
              setExportPanel(true);
            }}
          >
            <span>
              <FormattedMessage id="dashboard.export.all" />
            </span>
          </button>
        )}
      </header>
      {powerbiState.embedToken.token &&
        powerbiState.embedURL &&
        powerbiState.powerBiBookmark && (
          <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual and qna
              id: reportId,
              embedUrl: powerbiState.embedURL,
              accessToken: powerbiState.embedToken.token,
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                // background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={eventHandlersMap}
            cssClassName={`embed-container active-maintenance-height-${activeMessagesForEmbedHeight}`}
            phasedEmbedding={true}

            // getEmbeddedComponent = { (embeddedReport) => {
            // 	window.report = embeddedReport;
            // }}
          />
        )}

      {showExportPanel && (
        <ExportPanel
          BLOCK={`insights__create-new`}
          exportOptions={exportOptions}
          selectedExport={selectedExport}
          setExport={setExport}
          ExportButtonHandler={exportHandler}
          setShowExport={setExportPanel}
        />
      )}
    </div>
  );
};
export default PowerbiDashboard;
