import { useRef } from "react";
import { DateRange } from "react-date-range";
import { Range } from "react-date-range";
import { useClickOutside } from "utils/functions";
import FormattedMessage from "../formatted-message/formatted-message";
import { useDispatch } from "react-redux";
import { updateCalendar } from "services/commons.service";
import moment from "moment";
type Props = {
  BLOCK?: string;
  state: Range[];
  apply: boolean;
  setApply: (value: boolean) => void;
  setState: (state: any[]) => void;
};

const DateRangePicker = ({
  BLOCK,
  state,
  apply,
  setState,
  setApply,
}: Props) => {
  const dateRef = useRef(null);
  const dispatch = useDispatch();

  useClickOutside(dateRef, () => dispatch(updateCalendar(false)));

  return (
    <div
      className={`${BLOCK}__outline__datepicker`}
      ref={dateRef}
      data-testid="date-range-picker"
    >
      <DateRange
        onChange={(item) => {
          setState([item.selection]);
        }}
        moveRangeOnFirstSelection={false}
        ranges={state}
        months={2}
        direction="horizontal"
        minDate={new Date("01-01-2000")}
        maxDate={new Date("12-31-"+moment().year())}
        rangeColors={["#91dcf8"]}
      />
      <div className={`${BLOCK}__back`}>
        <button
          className={`${BLOCK}__back-to-today`}
          onClick={() =>
            setState([
              {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
              },
            ])
          }
          data-testid="back-to-today"
        >
          <FormattedMessage id="back.to.today" />
        </button>
        <div className={`${BLOCK}__calendar-options`}>
          <button
            className={`${BLOCK}__cancel`}
            data-test="cancel-button"
            data-testid="cancel-button"
            onClick={() => {
              setState([
                {
                  startDate: null,
                  endDate: null,
                  key: "selection",
                },
              ]);
              dispatch(updateCalendar(false));
              setApply(false);
            }}
          >
            <FormattedMessage id="shortlist.edit.button.cancel" />
          </button>
          {!apply ? (
            <button
              className={`${BLOCK}__calendar-apply-disabled`}
              disabled={!apply}
              onClick={() => {}}
              data-testid="apply-disabled"
            >
              <FormattedMessage id="apply" />
            </button>
          ) : (
            <button
              className={`${BLOCK}__calendar-apply`}
              onClick={() => dispatch(updateCalendar(false))}
              data-testid="apply-enabled"
            >
              <FormattedMessage id="apply" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
