import Button from "components/shared/button/button";
import Checkbox from "components/shared/checkbox/checkbox";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToastMessage } from "services/commons.service";
import {
  GlobalCompanyGroup,
  GlobalCompanyMapping,
} from "services/insights/insights.model";
import { CompanyFilter } from "services/search/filters.model";
import { COMPANY_TYPE, SOURCE_PAGE, VENDOR_DETAILS } from "utils/constants";
import { getVendorCollection } from "utils/functions";

const BLOCK = "saved-groupings";

const SavedGroupings = ({
  selectGrouping,
  selectionType,
  selectedGroups,
  peersSelected,
  globalPeersSelected,
  limit,
  globalBaseSelected,
  handleDeleteSavedMapping,
  fetchSavedGroupings,
  deleteSavedGrouping,
  triggeredBy,
}: {
  selectGrouping: Function;
  selectionType: string;
  selectedGroups: GlobalCompanyGroup[];
  peersSelected: CompanyFilter[];
  globalPeersSelected: Partial<GlobalCompanyGroup>[];
  limit?: number;
  globalBaseSelected?: Partial<GlobalCompanyGroup>;
  handleDeleteSavedMapping: Function;
  fetchSavedGroupings: any;
  deleteSavedGrouping?: any;
  triggeredBy?: string;
}) => {
  const [loading, setLoading] = useState(true);
  const [savedGroupings, setSavedGroupings] = useState<GlobalCompanyGroup[]>(
    []
  );
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      fetchSavedGroupings()
        .then((response: any) => {
          let tmpGroupings = response.data.filter(
            (g: GlobalCompanyGroup) => g.isSaved
          );
          if (triggeredBy && SOURCE_PAGE.PEER_BENCHMARK) {
            tmpGroupings.forEach((sg: GlobalCompanyGroup) => {
              sg.globalCompanyMappingDetails =
                sg.globalCompanyMappingDetails.filter(
                  (gcm: GlobalCompanyMapping) => {
                    let tmpVendorCollection = getVendorCollection(
                      gcm.globalCompany
                    );
                    return !(
                      tmpVendorCollection.length === 1 &&
                      tmpVendorCollection[0].vendorName ===
                        VENDOR_DETAILS.snp.vendorName
                    );
                  }
                );
            });
            tmpGroupings = tmpGroupings.filter(
              (sg: GlobalCompanyGroup) => sg.globalCompanyMappingDetails.length
            );
          }

          tmpGroupings.forEach((sg: GlobalCompanyGroup) => {
            sg.globalCompanyMappingDetails.forEach(
              (gcm: GlobalCompanyMapping) => {
                let tmpVendorCollection = getVendorCollection(
                  gcm.globalCompany
                );
                if (tmpVendorCollection.length)
                  gcm.globalCompany.vendorCollection =
                    triggeredBy && triggeredBy === SOURCE_PAGE.PEER_BENCHMARK
                      ? tmpVendorCollection.filter(
                          (v: {
                            priority: number;
                            vendorId: number;
                            vendorName: string;
                            color: string;
                          }) => v.vendorName !== VENDOR_DETAILS.snp.vendorName
                        )
                      : tmpVendorCollection;
              }
            );
          });
          setSavedGroupings(tmpGroupings);
          setLoading(false);
        })
        .catch((e: any) => setLoading(false));
      setReload(false);
    }
  }, [reload]);

  return (
    <div className={BLOCK}>
      <span>
        <FormattedMessage id="global.search.saved.groupings.title" />
      </span>
      {loading ? (
        <Icon
          name={"loading"}
          width={50}
          height={50}
          className="loading-icon"
        />
      ) : (
        <SavedGroupingList
          selectionType={selectionType}
          savedGroupings={savedGroupings}
          setSelectedGrouping={selectGrouping}
          selectedGroups={selectedGroups}
          peersSelected={peersSelected}
          globalPeersSelected={globalPeersSelected}
          limit={limit}
          globalBaseSelected={globalBaseSelected}
          handleDeleteSavedMapping={handleDeleteSavedMapping}
          setReload={setReload}
          deleteSavedGrouping={deleteSavedGrouping}
        />
      )}
    </div>
  );
};

const SavedGroupingList = ({
  savedGroupings,
  setSelectedGrouping,
  selectionType,
  selectedGroups,
  peersSelected,
  globalPeersSelected,
  limit,
  globalBaseSelected,
  handleDeleteSavedMapping,
  setReload,
  deleteSavedGrouping,
}: {
  savedGroupings: GlobalCompanyGroup[];
  setSelectedGrouping: Function;
  selectionType: string;
  selectedGroups: GlobalCompanyGroup[];
  peersSelected: CompanyFilter[];
  globalPeersSelected: Partial<GlobalCompanyGroup>[];
  limit?: number;
  globalBaseSelected?: Partial<GlobalCompanyGroup>;
  handleDeleteSavedMapping: Function;
  setReload: Function;
  deleteSavedGrouping?: any;
}) => {
  const dispatch = useDispatch();
  const handleDelete = (globalCompanyMapping: GlobalCompanyGroup) => {
    deleteSavedGrouping(globalCompanyMapping.globalCompanyMappingId).then(
      (res: any) => {
        handleDeleteSavedMapping(globalCompanyMapping, -1);
        setReload(true);
        dispatch(
          addToastMessage({
            description: (
              <FormattedMessage id={"global.search.remove.saved.grouping"} />
            ),
          })
        );
      }
    );
  };
  return savedGroupings.length > 0 ? (
    <div className={`${BLOCK}__list`} data-testid={"groupings-list"}>
      {savedGroupings.map((g) => (
        <div
          key={`global-company-mapping-${g.globalCompanyMappingId}`}
          className={`${BLOCK}__grouping`}
        >
          {selectionType === COMPANY_TYPE.BASELINE ? (
            <input
              data-testid="radio-btn"
              name="selected-grouping"
              type="radio"
              defaultChecked={
                selectedGroups.filter(
                  (sl) => sl.globalCompanyMappingId === g.globalCompanyMappingId
                ).length > 0
              }
              onClick={() => setSelectedGrouping([g])}
            />
          ) : (
            <Checkbox
              value={
                selectedGroups.filter(
                  (sl) => sl.globalCompanyMappingId === g.globalCompanyMappingId
                ).length > 0
              }
              onChange={() => {
                selectionType === COMPANY_TYPE.BASELINE
                  ? setSelectedGrouping([g])
                  : setSelectedGrouping((sl: GlobalCompanyGroup[]) =>
                      sl.includes(g)
                        ? sl.filter(
                            (c) =>
                              c.globalCompanyMappingId !==
                              g.globalCompanyMappingId
                          )
                        : [...sl, g]
                    );
              }}
              disabled={
                selectedGroups.filter(
                  (sl) => sl.globalCompanyMappingId === g.globalCompanyMappingId
                ).length === 0 &&
                (limit
                  ? (selectedGroups.length >= limit &&
                      selectedGroups.filter(
                        (sl) =>
                          sl.globalCompanyMappingId === g.globalCompanyMappingId
                      ).length === 0) ||
                    (peersSelected.length +
                      globalPeersSelected.length +
                      selectedGroups.length >=
                      limit &&
                      selectedGroups.filter(
                        (sl) =>
                          sl.globalCompanyMappingId === g.globalCompanyMappingId
                      ).length === 0)
                  : false)
              }
              isGlobalSearch={true}
            />
          )}
          <span className={`${BLOCK}__grouping__name`}>
            {g.globalCompanyMappingName}
          </span>
          <span>
            {g.globalCompanyMappingDetails
              .map(
                (m: GlobalCompanyMapping) =>
                  `${m.globalCompany.companyName} (${
                    m.globalCompany.vendorCollection
                      ? m.globalCompany.vendorCollection
                          .map((v) => v.vendorName)
                          .join(", ")
                      : ""
                  })`
              )
              .join(", ")}
          </span>
          <Button
            className={`${BLOCK}__grouping__remove`}
            onClick={() => {
              deleteSavedGrouping && handleDelete(g);
            }}
            iconName="cross"
            dataTest="remove-btn"
          />
        </div>
      ))}
    </div>
  ) : (
    <div className={`${BLOCK}__no-data`}>
      <FormattedMessage id="global.search.saved.groupings.no-data" />
    </div>
  );
};

export default SavedGroupings;
