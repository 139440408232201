import { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { shareShortlistPop } from "services/saved-shortlist/saved-shortlist.service";
import Modal from "../modal/modal";
import FormattedMessage from "../formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import {
  setSharedWith,
  loadUsers,
} from "services/saved-shortlist/saved-shortlist.service";
import Dropdown from "./dropdown";
import DropDown from "../dropdown/dropdown";
import { debounce } from "lodash";
import { User } from "services/commons.model";
import { Search } from "services/searchlist/searchlist.model";
import { currentShareSearchItem } from "services/searchlist/searchlist.service";
import {
  shareItemType,
  SHARE_COMPARISON_ROLES,
  SHARE_COMPARISON_TYPE,
} from "utils/constants";
import Checkbox from "../checkbox/checkbox";
import Button from "../button/button";

type props = {
  setShowShare: (value: boolean) => void;
  shareShortlist: ({
    shortlistName,
    shortlistId,
    sharedWith,
    sourceShortlistId,
  }: {
    shortlistName: string;
    shortlistId: number;
    sharedWith: any[];
    sourceShortlistId: number;
  }) => void;
  shareCompare: (
    sharedWith: any[],
    includeNotes: boolean,
    includeTags: boolean,
    IsCollaboration: number
  ) => void;
  shareSearch: (payload: Search) => void;
  sharePeerBenchmark?: (sharedWith: any[]) => void;
  shareInsights?: (sharedWith: any) => void;
  setShowSearchModal: (value: boolean) => void;
  shareType: number;
  currentComparison?: any;
};

const ShareToUsersModal = ({
  setShowShare,
  shareShortlist,
  shareSearch,
  setShowSearchModal,
  shareCompare,
  sharePeerBenchmark,
  shareInsights,
  shareType,
  currentComparison,
}: props) => {
  const BLOCK = "ShareShortlist";
  const dispatch = useDispatch();
  const [shareNotes, setShareNotes] = useState<boolean>(false);
  const [shareTags, setShareTags] = useState<boolean>(false);
  const [onScroll, setOnScroll] = useState<boolean>(false);
  const [shareComparisonList, setShareComparisonList] = useState<any[]>(
    currentComparison?.sharedWith && currentComparison?.createdUserDetail
      ? [
          currentComparison.createdUserDetail,
          ...currentComparison.sharedWith.filter(
            (user: any) => user.userRole !== 0
          ),
        ]
      : []
  );
  const [shareCopyComparisonList, setShareCopyComparisonList] = useState<any[]>(
    currentComparison?.sharedWith && currentComparison?.createdUserDetail
      ? [
          currentComparison.createdUserDetail,
          ...currentComparison.sharedWith.filter(
            (user: any) => user.userRole !== 0
          ),
        ]
      : []
  );
  const savedShortlistState = useSelector(
    (state: RootStore) => state.savedShorList
  );
  const shareSearchlistState = useSelector(
    (state: RootStore) => state.searchlist
  );
  let searchName = "";
  if (shareType === shareItemType.search) {
    searchName =
      shareSearchlistState.currentShareSearch!.name &&
      shareSearchlistState.currentShareSearch.name!.length > 70
        ? shareSearchlistState.currentShareSearch.name!.slice(0, 70) + "....."
        : shareSearchlistState.currentShareSearch.name!;
  }

  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );

  const [shareComparisonType, setShareComparisonType] = useState<number>(
    shareComparisonList.find((user) => user.userId === currentUser.userId)
      ?.userRole === 2
      ? SHARE_COMPARISON_TYPE.copy
      : SHARE_COMPARISON_TYPE.collaboration
  );
  const isCollab =
    shareType === shareItemType.compare &&
    shareComparisonType === SHARE_COMPARISON_TYPE.collaboration;

  const debounceChangeHandler = useMemo(() => {
    return debounce((e: any) => {
      // change state of input
      const input = e.target.value.trim();
      if (input.length < 2) {
        return;
      }
      dispatch(loadUsers(input, currentUser.userId));
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const shareShortlistHandler = () => {
    if (shareType === shareItemType.shortlist) {
      shareShortlist({
        shortlistName: savedShortlistState.shareShortlist.shortlistName,
        shortlistId: savedShortlistState.shareShortlist.shortlistId,
        sharedWith: savedShortlistState.sharedWith.map((user: User) => ({
          userId: user.userId,
        })),
        sourceShortlistId: savedShortlistState.shareShortlist.shortlistId,
      });
    }

    if (shareType === shareItemType.search) {
      shareSearch({
        ...shareSearchlistState.currentShareSearch,
        sharedWith: savedShortlistState.sharedWith,
      });
    }

    if (shareType === shareItemType.compare) {
      isCollab
        ? shareCompare(
            shareComparisonList
              .filter(
                (user: any) => user.userId !== currentComparison?.createdBy
              )
              .map((user: any) => ({
                userId: user.userId,
                userRole: user.userRole,
              })),
            true,
            true,
            shareComparisonType
          )
        : shareCompare(
            savedShortlistState.sharedWith.map((user: User) => ({
              userId: user.userId,
            })),
            shareNotes,
            shareTags,
            shareComparisonType
          );
    }
    if (shareType === shareItemType.insights) {
      return shareInsights
        ? shareInsights(
            savedShortlistState.sharedWith.map((user: any) => ({
              userId: user.userId,
            }))
          )
        : "";
    }

    if (shareType === shareItemType.peerbenchmark) {
      sharePeerBenchmark &&
        sharePeerBenchmark(
          savedShortlistState.sharedWith.map(
            ({
              userId,
              email,
              firstName,
              lastName,
              preferredFullName,
              displayName,
              memberFirmCode,
              title,
            }) => ({
              userId,
              email,
              firstName,
              lastName,
              preferredFullName,
              displayName,
              memberFirmCode,
              title,
            })
          )
        );
    }
  };

  const selectUser = (user: User) => {
    isCollab
      ? setShareComparisonList([...shareComparisonList, user])
      : dispatch(setSharedWith([...savedShortlistState.sharedWith, user]));
  };

  const unselectUser = (user: User) => {
    dispatch(
      setSharedWith(
        savedShortlistState.sharedWith.filter(
          ({ userId }) => userId !== user.userId
        )
      )
    );
  };

  const determineHeader = (shareType: number) => {
    if (shareType === shareItemType.shortlist) {
      return "shortlist.share.title";
    } else if (shareType === shareItemType.search) {
      return "share.search.header";
    } else if (shareType === shareItemType.compare) {
      return "compare.share.title";
    } else if (shareType === shareItemType.peerbenchmark) {
      return "peerbenchmark.share.title";
    } else if (shareType === shareItemType.insights) {
      return "insights.share.hover";
    }
  };

  const determineShareMessage = (shareType: number) => {
    if (shareType === shareItemType.shortlist) {
      return "shortlist.share.message";
    } else if (shareType === shareItemType.search) {
      return "share.search.content";
    } else if (shareType === shareItemType.compare) {
      return shareComparisonType === SHARE_COMPARISON_TYPE.collaboration
        ? "compare.share.collaboration.message"
        : "compare.share.message";
    } else if (shareType === shareItemType.insights) {
      return "insights.share.message";
    } else {
      return "peerbenchmark.share.message";
    }
  };

  const handleCancelAndClose = (shareType: number) => {
    if (shareType === shareItemType.shortlist) {
      setShowShare(false);
    } else if (shareType === shareItemType.search) {
      setShowSearchModal(false);
      dispatch(currentShareSearchItem(null));
    } else {
      //means it is compare share modal
      setShowShare(false);
    }
    dispatch(setSharedWith([]));
    dispatch(shareShortlistPop(null));
  };

  const shareLabel = (shareType: number) => {
    if (shareType === shareItemType.shortlist) {
      return "shortlist.share.button.share";
    } else if (shareType === shareItemType.search) {
      return "share.search.share.search";
    } else if (shareType === shareItemType.compare) {
      return "compare.share.button.share";
    } else if (shareType === shareItemType.insights) {
      return "insights.share.hover";
    } else {
      return "share.peer.benchmark";
    }
  };

  return (
    <Modal
      show={true}
      header={determineHeader(shareType)}
      headerParams={{ searchName }}
      handleClose={() => handleCancelAndClose(shareType)}
      config={false}
    >
      <div className={BLOCK}>
        <span className={`${BLOCK}__message`}>
          <FormattedMessage id={determineShareMessage(shareType)} />
        </span>
        {shareType === shareItemType.compare && (
          <div className={`${BLOCK}__messageNote`}>
            <span>
              <FormattedMessage id={"compare.share.type"} />
            </span>
            <div className={`${BLOCK}__checkbox__wrapper`}>
              {shareComparisonList.find(
                (user) => user.userId === currentUser.userId
              )?.userRole === 1 && (
                <>
                  <div className={`${BLOCK}__sharetype`}>
                    <input
                      checked={
                        shareComparisonType ===
                        SHARE_COMPARISON_TYPE.collaboration
                      }
                      onChange={() => {
                        setShareComparisonType(
                          SHARE_COMPARISON_TYPE.collaboration
                        );
                      }}
                      disabled={false}
                      type="radio"
                    />
                    <span className={`${BLOCK}__checkmark`}></span>
                  </div>
                  <span>
                    <FormattedMessage id={"compare.share.type.collab"} />
                  </span>
                </>
              )}
              <div className={`${BLOCK}__sharetype`}>
                <input
                  checked={shareComparisonType === SHARE_COMPARISON_TYPE.copy}
                  onChange={() => {
                    setShareComparisonType(SHARE_COMPARISON_TYPE.copy);
                  }}
                  disabled={false}
                  type="radio"
                />
                <span className={`${BLOCK}__checkmark`}></span>
              </div>
              <span>
                <FormattedMessage id={"compare.share.type.copy"} />
              </span>
            </div>
          </div>
        )}
        {shareType === shareItemType.compare &&
          shareComparisonType === SHARE_COMPARISON_TYPE.copy && (
            <div className={`${BLOCK}__messageNote`}>
              <span>
                <FormattedMessage id={"compare.share.note"} />
              </span>
              <div className={`${BLOCK}__checkbox__wrapper`}>
                <span className={`${BLOCK}__checkbox`}>
                  <Checkbox
                    label={<FormattedMessage id={"compare.share.notes"} />}
                    value={shareNotes}
                    onChange={() => setShareNotes(!shareNotes)}
                  ></Checkbox>
                </span>
                <span className={`${BLOCK}__checkbox`}>
                  <Checkbox
                    label={<FormattedMessage id={"compare.share.tags"} />}
                    value={shareTags}
                    onChange={() => setShareTags(!shareTags)}
                  ></Checkbox>
                </span>
              </div>
            </div>
          )}
        <Dropdown
          className={`${BLOCK}__dropdown`}
          label={
            <div
              className={`${BLOCK}__dropdown-label`}
              data-test="dropdown-label"
            >
              <FormattedMessage id="share.any.input.label" />
            </div>
          }
          placeholder={<FormattedMessage id="share.any.dropdown.placeholder" />}
          values={
            isCollab ? shareComparisonList : savedShortlistState.sharedWith
          }
          objectKeys={{
            name: "displayName",
            id: "userId",
            firstName: "firstName",
            lastName: "lastName",
            externalId: "externalSystemId",
            email: "email",
            title: "title",
          }}
          loading={savedShortlistState.searchShareLoading}
          options={savedShortlistState.sharedWithOptions}
          handleChange={debounceChangeHandler}
          handleSelect={selectUser}
          handleUnselect={unselectUser}
          isCollab={isCollab}
        />
        {isCollab && (
          <div
            onScroll={() => setOnScroll(!onScroll)}
            className={`${BLOCK}__users-wrapper`}
          >
            {shareComparisonList.map((user: any, i) => {
              return (
                <div key={`${BLOCK}-user-${i}`} className={`${BLOCK}__users`}>
                  <div className={`${BLOCK}__names`}>
                    <span
                      className="tag-card__owner-icon tag-card__nameIcon"
                      onClick={() => {}}
                      style={{
                        width: `2rem`,
                        height: `2rem`,
                        borderRadius: "100%",
                        fontSize: ".75rem",
                      }}
                    >
                      {user.firstName.charAt(0) + user.lastName.charAt(0)}
                    </span>
                    <span className={`${BLOCK}__users__text`}>
                      {user.firstName + " " + user.lastName}
                    </span>
                  </div>
                  <div className={`${BLOCK}__role`}>
                    {currentComparison?.createdBy === user.userId ? (
                      <span>
                        {
                          SHARE_COMPARISON_ROLES.filter(
                            (e) => e.id !== 0
                          ).filter((role) => role.id === user.userRole)[0]?.name
                        }
                      </span>
                    ) : (
                      <DropDown
                        className={"role"}
                        parentBlock={"users"}
                        data={SHARE_COMPARISON_ROLES}
                        selected={
                          SHARE_COMPARISON_ROLES.filter(
                            (e) => e.id !== 0
                          ).filter((role) => role.id === user.userRole).length >
                          0
                            ? SHARE_COMPARISON_ROLES.filter(
                                (e) => e.id !== 0
                              ).filter((role) => role.id === user.userRole)[0]
                            : []
                        }
                        keys={{
                          displayName: "name",
                          id: "id",
                        }}
                        onChange={(option: any) => {
                          setShareComparisonList(
                            option.id === 0
                              ? shareComparisonList.filter(
                                  (e) => e.userId !== user.userId
                                )
                              : shareComparisonList.map((e) =>
                                  e.userId === user.userId
                                    ? { ...user, userRole: option.id }
                                    : e
                                )
                          );
                        }}
                        onScroll={onScroll}
                      ></DropDown>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className={`${BLOCK}__options`}>
          <Button
            className={`button-secondary`}
            onClick={() => handleCancelAndClose(shareType)}
            formattedText="share.any.button.cancel"
            dataTest="cancel-btn"
          />

          <Button
            className={`button-primary`}
            onClick={shareShortlistHandler}
            dataTest="share-to-users-share-button"
            formattedText={`${shareLabel(shareType)}`}
            disabled={
              isCollab
                ? shareComparisonList === null ||
                  (shareComparisonList.length <= 1 &&
                    shareCopyComparisonList.length <= 1) ||
                  (shareCopyComparisonList.length ===
                    shareComparisonList.length &&
                    shareCopyComparisonList.filter(
                      (user: any, i) =>
                        shareComparisonList.find(
                          (e) => e.userId === user.userId
                        )?.userRole !== user.userRole
                    ).length === 0)
                : savedShortlistState.sharedWith === null ||
                  savedShortlistState.sharedWith.length === 0
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default ShareToUsersModal;
