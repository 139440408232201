import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import { TooltipPosition } from "components/shared/tooltip/tooltip";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showTooltip } from "services/commons.service";
import { Legend } from "services/dashboard/dashboard.model";
import { RootStore } from "services/store.service";
import { BENCHMARK_TILE_TYPES, INSIGHT_BENCHMARK_TYPE } from "utils/constants";

type props = {
  data?: Legend[];
  benchmarkTileType?: number;
  isDetailedView?: boolean;
  isTableView?: boolean;
  currentInsightView?: number;
  baselineCompany?: any;
};
const EnergyChart = ({
  data,
  benchmarkTileType,
  isDetailedView,
  isTableView,
  currentInsightView,
  baselineCompany,
}: props) => {
  const BLOCK = "energySpendChart";

  const isCompanyView = currentInsightView === INSIGHT_BENCHMARK_TYPE.COMPANY;
  const [cleanedData, setCleanedData] = useState<any>([]);
  const [emptyValues, setEmptyValues] = useState<boolean>(false);
  const isEnergySpend = benchmarkTileType === BENCHMARK_TILE_TYPES.ENERGY_SPEND;

  const isEmissionTiles =
    benchmarkTileType === BENCHMARK_TILE_TYPES.ALIGNED_EMISSION ||
    benchmarkTileType === BENCHMARK_TILE_TYPES.ALIGNED_GAP ||
    benchmarkTileType === BENCHMARK_TILE_TYPES.BELOW_EMISSION ||
    benchmarkTileType === BENCHMARK_TILE_TYPES.CLIMATE_STRATEGY ||
    benchmarkTileType === BENCHMARK_TILE_TYPES.COMPANY_EMISSION;

  const isEmissionTilesCompanyView = isEmissionTiles && isCompanyView;

  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const dispatch = useDispatch();

  const values = {
    DONT_KNOW: `Entity submitted the response "Don't Know." Reference CDP report for details`,
    QUESTION_NO_APPLICABLE: `Entity deemed question not applicable`,
    NO_DATA_AVAILABLE: `No data available`,
    NEUTRAL: `Neutral`,
    ZERO: `Entity indicated an energy consumption of 0 in their CDP response`,
  };

  const labels = ["Peers", "Industry"];

  useEffect(() => {
    if (cleanedData.length > 0) setCleanedData([]);
    if (emptyValues) setEmptyValues(false);
    if (data && data.length > 0) {
      let tmpData: any = [];
      tmpData = data.map((item: Legend) => {
        let value: any = item.legendValue;
        let isGray = false;
        if (!isEmissionTilesCompanyView) {
          if (item.legendValue === "0" || item.legendValue === values.ZERO) {
            value = values.ZERO;
            isGray = true;
          } else if (!item.legendValue) {
            value = "*";
            isGray = true;
          } else if (
            value === "Don't know" ||
            value === values.DONT_KNOW ||
            value ===
              "Entity submitted the response 'Don't Know'. Reference CDP Report for details"
          ) {
            value = values.DONT_KNOW;
            isGray = true;
          } else if (
            value === "Question not applicable" ||
            value === values.QUESTION_NO_APPLICABLE
          ) {
            value = values.QUESTION_NO_APPLICABLE;
            isGray = true;
          }
        }

        return {
          companyName: item.legendTitle,
          value: value,
          isGray: isGray,
          tooltip: item.legendTooltip,
        };
      });

      if (isEmissionTilesCompanyView) {
        tmpData = tmpData.filter((data: any) => data.value);
      }

      if (isEmissionTiles && !isCompanyView) {
        tmpData = tmpData.every((data: any) => data.value === "*")
          ? []
          : tmpData;
      }

      if (
        (!isTableView &&
          currentInsightView &&
          !isCompanyView &&
          !isDetailedView) ||
        (isTableView && currentInsightView === INSIGHT_BENCHMARK_TYPE.INDUSTRY)
      ) {
        tmpData = tmpData.filter(
          (val: any) =>
            val.companyName &&
            (val.companyName === baselineCompany.company.companyName ||
              labels.includes(val.companyName))
        );
      }

      if (
        (isTableView || isDetailedView) &&
        currentInsightView !== INSIGHT_BENCHMARK_TYPE.INDUSTRY
      ) {
        tmpData =
          tmpData &&
          tmpData.filter(
            (val: any) => val.companyName && !labels.includes(val.companyName)
          );
      }
      if (
        tmpData &&
        tmpData?.find((element: any) => element.value === "*" && !isCompanyView)
      ) {
        setEmptyValues(true);
      } else {
        setEmptyValues(false);
      }

      setCleanedData(tmpData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTableView, data]);

  const handleMouseEnter = (
    e: any,
    children: any,
    position: TooltipPosition = TooltipPosition.bottom,
    className: string,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("span");
    if (!element) {
      return;
    }

    dispatch(
      showTooltip({
        children: (
          <div
          // Removing this for clearing fortify issues as we will be integrating Popover component
          // dangerouslySetInnerHTML={{
          //   __html: children,
          // }}
          />
        ),
        position: position,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  const getRowValues = (company: any) => {
    return isEnergySpend ? (
      company.value === "Neutral" ? (
        <span
          className={classNames(
            `${BLOCK}__value ${BLOCK}__value--fix-width ${BLOCK}__value--small `,
            {
              [`${BLOCK}__value--black`]: isTableView,
              [`${BLOCK}__value--gray`]: company.isGray,
              [`${BLOCK}__value--tall-font`]: isDetailedView && !isTableView,
              [`${BLOCK}__value--right`]: !isEnergySpend,
              [`${BLOCK}__value--number-2`]:
                !isEnergySpend && !isNaN(company.value) && !isTableView,
              [`${BLOCK}__value--special`]: company.isGray,
              [`general-neutral-value--color`]: company.value === "Neutral",
            }
          )}
          onMouseEnter={(e) =>
            company.value === "Neutral"
              ? handleMouseEnter(
                  e,
                  <FormattedMessage id="insights.relevant.risks.neutral" />,
                  TooltipPosition.bottom,
                  `${BLOCK}__value-tooltip-neutral`
                )
              : company.tooltip
              ? handleMouseEnter(
                  e,
                  company.tooltip,
                  TooltipPosition.bottom,
                  `${BLOCK}__value-tooltip`
                )
              : null
          }
          onMouseLeave={handleMouseLeave}
        >
          {!isEnergySpend && !isNaN(company.value)
            ? new Intl.NumberFormat().format(company.value)
            : company.value}
        </span>
      ) : (
        <span>
          <Popover
            displayText={
              !isEnergySpend && !isNaN(company.value)
                ? new Intl.NumberFormat().format(company.value)
                : company.value
            }
            metricClassName={classNames(
              `${BLOCK}__value ${BLOCK}__value--fix-width ${BLOCK}__value--small `,
              {
                [`${BLOCK}__value--black`]: isTableView,
                [`${BLOCK}__value--gray`]: company.isGray,
                [`${BLOCK}__value--tall-font`]: isDetailedView && !isTableView,
                [`${BLOCK}__value--right`]: !isEnergySpend,
                [`${BLOCK}__value--number-2`]:
                  !isEnergySpend && !isNaN(company.value) && !isTableView,
                [`${BLOCK}__value--special`]: company.isGray,
                [`general-neutral-value--color`]: company.value === "Neutral",
              }
            )}
            content={company.tooltip ? company.tooltip : []}
          />
        </span>
      )
    ) : (
      <span className={`${BLOCK}__value-wrapper`}>
        {company.value === "Neutral" ? (
          <span
            className={classNames(
              `${BLOCK}__value ${BLOCK}__value--fix-width ${BLOCK}__value--small ${BLOCK}__value--grow`,
              {
                [`${BLOCK}__value--black`]: isTableView,
                [`${BLOCK}__value--gray`]: company.isGray,
                [`${BLOCK}__value--tall-font`]: isDetailedView && !isTableView,
                [`${BLOCK}__value--right`]: !isEnergySpend,
                [`${BLOCK}__value--emission-peer`]: isEmissionTiles,
                [`${BLOCK}__value--number-2`]:
                  !isEnergySpend && !isNaN(company.value) && !isTableView,
                [`${BLOCK}__value--number-3`]:
                  !isEnergySpend &&
                  !isNaN(company.value) &&
                  !isTableView &&
                  isDetailedView,
                [`${BLOCK}__value--special`]: company.isGray,
                [`general-neutral-value--color`]: company.value === "Neutral",
              }
            )}
            onMouseEnter={(e) =>
              company.value === "Neutral"
                ? handleMouseEnter(
                    e,
                    <FormattedMessage id="insights.relevant.risks.neutral" />,
                    TooltipPosition.bottom,
                    `${BLOCK}__value-tooltip-neutral`
                  )
                : company.tooltip
                ? handleMouseEnter(
                    e,
                    company.tooltip,
                    TooltipPosition.bottom,
                    `${BLOCK}__value-tooltip`
                  )
                : null
            }
            onMouseLeave={handleMouseLeave}
          >
            {!isEnergySpend && !isNaN(company.value)
              ? new Intl.NumberFormat().format(company.value)
              : company.value}
          </span>
        ) : (
          <span>
            <Popover
              metricClassName={classNames(
                `${BLOCK}__value ${BLOCK}__value--fix-width ${BLOCK}__value--small ${BLOCK}__value--grow`,
                {
                  [`${BLOCK}__value--black`]: isTableView,
                  [`${BLOCK}__value--gray`]: company.isGray,
                  [`${BLOCK}__value--tall-font`]:
                    isDetailedView && !isTableView,
                  [`${BLOCK}__value--right`]: !isEnergySpend,
                  [`${BLOCK}__value--emission-peer`]: isEmissionTiles,
                  [`${BLOCK}__value--number-2`]:
                    !isEnergySpend && !isNaN(company.value) && !isTableView,
                  [`${BLOCK}__value--number-3`]:
                    !isEnergySpend &&
                    !isNaN(company.value) &&
                    !isTableView &&
                    isDetailedView,
                  [`${BLOCK}__value--special`]: company.isGray,
                  [`general-neutral-value--color`]: company.value === "Neutral",
                }
              )}
              displayText={
                !isEnergySpend && !isNaN(company.value)
                  ? new Intl.NumberFormat().format(company.value)
                  : company.value
              }
              content={company.tooltip ? company.tooltip : []}
            />
          </span>
        )}

        {!isEmissionTiles && !isEnergySpend && !isNaN(company.value) && (
          <span
            className={classNames(`${BLOCK}__unit-2`, {
              [`${BLOCK}__unit-2--table`]: isTableView,
            })}
          >
            <FormattedMessage id="energy.consumption.mwh" />
          </span>
        )}
      </span>
    );
  };
  return (
    <div
      className={classNames(`${BLOCK}__container`, {
        [`${BLOCK}__container--no-data`]: cleanedData.length === 0,
        [`${BLOCK}__container--col`]: !isCompanyView,
        [`${BLOCK}__container--big-top`]: isCompanyView,
        [`${BLOCK}__container--medium-top`]: !isCompanyView && !isTableView,
        [`${BLOCK}__container--small-top`]:
          !isCompanyView && (isTableView || isDetailedView),
      })}
      data-testid="energy-chart-container"
    >
      {cleanedData.length > 0 ? (
        isCompanyView ? (
          <span>
            <Popover
              displayText={
                <>
                  {!isEnergySpend && !isNaN(cleanedData[0].value)
                    ? new Intl.NumberFormat().format(cleanedData[0].value)
                    : cleanedData[0].value}
                  {!isEmissionTiles &&
                    !isEnergySpend &&
                    !isNaN(cleanedData[0].value) && (
                      <span className={`${classNames(`${BLOCK}__unit`)}`}>
                        <FormattedMessage id="energy.consumption.mwh" />
                      </span>
                    )}
                </>
              }
              content={cleanedData[0].tooltip ? cleanedData[0].tooltip : []}
              metricClassName={classNames(`${BLOCK}__value`, {
                [`${BLOCK}__value--horizontal-padding`]:
                  isCompanyView && !isTableView,
                [`${BLOCK}__value--black`]: isTableView,
                [`${BLOCK}__value--small`]: !isCompanyView,
                [`${BLOCK}__value--gray`]: cleanedData[0].isGray,
                [`${BLOCK}__value--light-font`]: cleanedData[0].isGray,
                [`${BLOCK}__value--number`]:
                  !isEnergySpend && !isNaN(cleanedData[0].value),
                [`${BLOCK}__value--emission`]: isEmissionTiles,
              })}
            />
          </span>
        ) : (
          <div
            className={classNames(`${BLOCK}__rows-wrapper`, {
              [`${BLOCK}__rows-wrapper--no-row-gap`]: isTableView,
              [`${BLOCK}__rows-wrapper--min-row-gap`]:
                isDetailedView && !isTableView,
            })}
          >
            {cleanedData.map((company: any, index: number) => {
              return (
                <div
                  className={classNames(`${BLOCK}__row`, {
                    [`${BLOCK}__row--dashed-border`]: isTableView,
                    [`${BLOCK}__row--dashed-top-border`]:
                      isTableView && index === 0,
                    [`${BLOCK}__row--table`]: isTableView,
                    [`${BLOCK}__justify-unset`]: isEnergySpend,
                  })}
                  key={`company-${index}`}
                >
                  <span className={`${BLOCK}__name`}>
                    {company.companyName}
                  </span>

                  {getRowValues(company)}
                </div>
              );
            })}
          </div>
        )
      ) : (
        <span className={`${BLOCK}__no-data`}>
          <FormattedMessage id="energy.spend.no.data" />
        </span>
      )}

      {emptyValues && !isCompanyView && (
        <span className={`${BLOCK}__empty-values`}>
          <FormattedMessage id="no.data.available" />
        </span>
      )}
    </div>
  );
};

export default EnergyChart;
