import { isCompanyInGlobalGroup } from "components/shared/global-search/global-search-dropdown";
import { useContext, createContext } from "react";
import {
  AssociatedMetricTooltips,
  Visualization,
  VisualizationFilter,
} from "services/dashboard/dashboard.model";
import { TabularMetrics, Metadata } from "services/dashboard/dashboard.model";
import {
  InsightData,
  InsightMetricData,
  InsightCompanyFlat,
  InsightDashboardCompany,
  GlobalCompanyGroup,
  GlobalCompanyMapping,
} from "services/insights/insights.model";
import {
  BENCHMARK_TILE_TYPES,
  ESG_IVA_RATING_TILE_PEER_INDUSTRY,
  INSIGHT_BENCHMARK_TYPE,
  INSIGHT_DASHBOARD_FILTER_LABELS,
  INSIGHT_INDUSTRY_COMPANY,
  INSIGHT_PEER_COMPANY,
  SPEEDOMETER_DEFAULT_COLORS,
} from "utils/constants";
import { getLegendTooltip, isNumeric } from "utils/functions";

interface ITileContext {
  BLOCK: string;
  index: number;
  metadata: any;
  originalDataset: InsightData[];
  dataset: InsightData[];
  setDataset: any;
  isTableViewActive: boolean;
  setIsTableViewActive: any;
  isPieViewActive: boolean;
  setIsPieViewActive: any;
  filters: VisualizationFilter[];
  setFilters: any;
  response: any[];
  associatedMetrics: any[];
  associatedMetricsDisplayNames: any[];
  footerChild: any;
  setFooterChild: any;
  exporting: boolean;
  manualHScale: number;
  setManualHScale: any;
}

const TileContext = createContext<ITileContext>({
  BLOCK: "",
  index: 0,
  metadata: null,
  originalDataset: [],
  dataset: [],
  setDataset: null,
  isTableViewActive: false,
  setIsTableViewActive: null,
  isPieViewActive: false,
  setIsPieViewActive: null,
  filters: [],
  setFilters: null,
  response: [],
  associatedMetrics: [],
  associatedMetricsDisplayNames: [],
  footerChild: null,
  setFooterChild: null,
  exporting: false,
  manualHScale: -1,
  setManualHScale: null,
});

export const useTileContext = () => useContext(TileContext);
export default TileContext;

// Check the type of response and send the corresponding filter and mapper
export const responseFunctions = {
  //TODO: REMOVE SECOND CONDITION FROM SEARCH METRIC ONCE BACKEND HAVE ADDED GLOBALCOMPANYID PROP TO THE RES
  searchMetric: (r: any, globalCompanyId: number, associatedMetric: string) =>
    (r.globalCompanyId === globalCompanyId ||
      (r.cikNumber && r.cikNumber !== 0 && r.cikNumber === globalCompanyId)) &&
    (r.fieldName ? r.fieldName === associatedMetric : true),
  getMetricFromResponse: (
    metricData: any,
    associatedMetric: string
  ): {
    value: string;
    isRound: boolean;
    metricValueIcon?: any;
    metricValueTooltip?: any;
    metricDescription?: string | null;
    metricMinValue?: string | null;
    metricMaxValue?: string | null;
  } => {
    return metricData && metricData.fieldName
      ? {
          value: metricData?.fieldValue || null,
          isRound: false,
          metricValueIcon: metricData.fieldValueIcon,
          metricValueTooltip: metricData.fieldValueToolTip,
          metricDescription: metricData.description ?? null,
          metricMinValue: metricData?.fieldValueMin || null,
          metricMaxValue: metricData?.fieldValueMax || null,
        }
      : {
          value: metricData ? metricData[associatedMetric] ?? null : null,
          isRound: metricData ? metricData.isRound : false,
        };
  },
};

export const getTabularMetrics = (
  associatedMetricsDisplayNames: string[],
  associatedMetrics: string[],
  associatedMetricsTooltip: AssociatedMetricTooltips[],
  data: InsightData[]
) => {
  const tempMetrics =
    associatedMetricsDisplayNames && associatedMetricsDisplayNames.length
      ? associatedMetricsDisplayNames
      : associatedMetrics;

  let metrics: TabularMetrics[] =
    tempMetrics && tempMetrics.length
      ? tempMetrics.map((metric, index) => ({
          metric: metric,
          description: [],
        }))
      : [];
  if (associatedMetricsDisplayNames && associatedMetricsDisplayNames.length) {
    metrics = metrics.map((metric, index) => ({
      ...metric,
      metricKey: associatedMetrics && associatedMetrics[index],
      description:
        associatedMetricsTooltip && associatedMetricsTooltip.length > 0
          ? associatedMetricsTooltip.filter(
              (t: AssociatedMetricTooltips) =>
                t.associatedMetric === associatedMetrics[index]
            )
          : [],
    }));
  }

  return metrics;
};

export const flattenInsightCompany = (
  company: InsightDashboardCompany
): InsightCompanyFlat => ({
  externalCompanyId: company.globalCompany.externalCompanyId ?? 0,
  globalCompanyId: company.globalCompanyId ?? 0,
  companyName: company.globalCompany.companyName,
  isBaseCompany: company.isBaseCompany,
  industryId: company.globalCompany.industry
    ? company.globalCompany.industry.industryId
    : 0,
  industryName: company.globalCompany.industry
    ? company.globalCompany.industry.industryName
    : "",
  displayOrder: company.displayOrder ?? 0,
});

const filterDatasetByView = (
  unfilteredDataset: InsightData[],
  benchmarkType: number,
  isDetailedView: boolean,
  isTableViewActive: boolean,
  benchmarkTileType: number
) => {
  switch (benchmarkType) {
    case INSIGHT_BENCHMARK_TYPE.COMPANY:
      return unfilteredDataset.filter(({ isBaseCompany }) => isBaseCompany);
    case INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK:
      return unfilteredDataset.filter(({ globalCompanyId, isBaseCompany }) =>
        // Sending all the companies if we are on a detailed view
        benchmarkTileType ===
          BENCHMARK_TILE_TYPES.CDP_SCOPE_3_CATEGORIES_REPORTED &&
        isTableViewActive
          ? globalCompanyId > 0 ||
            INSIGHT_PEER_COMPANY.globalCompanyId === globalCompanyId
          : isDetailedView || isTableViewActive
          ? INSIGHT_PEER_COMPANY.globalCompanyId !== globalCompanyId &&
            INSIGHT_INDUSTRY_COMPANY.globalCompanyId !== globalCompanyId
          : isBaseCompany ||
            INSIGHT_PEER_COMPANY.globalCompanyId === globalCompanyId
      );

    case INSIGHT_BENCHMARK_TYPE.INDUSTRY:
      return unfilteredDataset.filter(
        ({ globalCompanyId, isBaseCompany }) =>
          isBaseCompany ||
          INSIGHT_INDUSTRY_COMPANY.globalCompanyId === globalCompanyId
      );
    default:
      return unfilteredDataset;
  }
};

export const filterDataset = (
  filters: VisualizationFilter[],
  cleanData: InsightData[],
  benchmarkType: number,
  isDetailedView: boolean,
  isTableViewActive: boolean,
  benchmarkTileType: number
) => {
  // Filter the data according to the selected view and the saved filters

  const companyFilters = filters.find(
    ({ label }) => label === INSIGHT_DASHBOARD_FILTER_LABELS.COMPANIES
  );
  const metricFilters = filters.find(
    ({ label }) => label === INSIGHT_DASHBOARD_FILTER_LABELS.SCOPE
  );

  let filteredDataset =
    !isTableViewActive && companyFilters && companyFilters.values.length > 0
      ? cleanData.filter(({ globalCompanyId }) =>
          companyFilters.values.includes(globalCompanyId)
        )
      : filterDatasetByView(
          cleanData,
          benchmarkType,
          !!isDetailedView,
          isTableViewActive,
          benchmarkTileType
        );
  if (!isTableViewActive && metricFilters && metricFilters.values.length)
    filteredDataset = filteredDataset.map((d) => ({
      ...d,
      metrics: d.metrics.filter((m) =>
        metricFilters.values.includes(m.metricKey)
      ),
    }));

  return filteredDataset;
};

const getMetricValue = (metricValue: string, metadata: Visualization) => {
  if (metadata.benchmarkTileType === BENCHMARK_TILE_TYPES.DIRECT_INDIRECT_COST)
    return `$ ${metricValue.toLocaleString()}`;

  if (
    [
      BENCHMARK_TILE_TYPES.CORPORATE_GOVERNANCE_THEME_SCORE,
      BENCHMARK_TILE_TYPES.SOCIAL_OPPORTINITIES_THEME_SCORE,
      BENCHMARK_TILE_TYPES.BIODIVERSITY_AND_LAND_USE_SCORE,
      BENCHMARK_TILE_TYPES.OWN_WORKFORCE,
      BENCHMARK_TILE_TYPES.OPPORTUINITIES_RENEWABLE_ENERGY_SCORE,
      BENCHMARK_TILE_TYPES.WATER_STRESS_SCORE,
    ].includes(metadata.benchmarkTileType)
  )
    return parseFloat(metricValue ?? 0).toFixed(1);

  return parseFloat(metricValue ?? 0)
    .toFixed(2)
    .toLocaleString();
};

const isSuffixRequired = (metricValue: any, metadata: Visualization) => {
  if (metadata.benchmarkTileType === BENCHMARK_TILE_TYPES.ENERGY_CONSUMPTION) {
    return isNumeric(metricValue.replaceAll(",", ""));
  }
  return true;
};

export const mapTableChart = (
  dataset: InsightData[],
  metadata: Visualization,
  isLeftAlinged: boolean = false,
  hideToolip: boolean = false,
  numberFormat: boolean = false
) => {
  let tmpResponse = dataset.reduce((data: any[], current: InsightData) => {
    let currentGroup: any = {
      globalCompanyId: current.globalCompanyId,
      header: current.companyName,
    };
    current.metrics.forEach((m: InsightMetricData) => {
      const sufix = metadata.benchmarkMetadata.valueLimitProperties?.find(
        (vl: any) => vl.associatedMetricRef === m.metricKey
      );
      let metricProp =
        m.metricKey?.charAt(0).toLowerCase() + m.metricKey?.slice(1);
      let metricValue: any;
      if (m?.metricValue === null) {
        metricValue = null;
      } else
        metricValue =
          m?.metricValue +
          (sufix && isSuffixRequired(m.metricValue, metadata)
            ? sufix.fieldValueSuffix
            : "");
      currentGroup = {
        ...currentGroup,
        [metricProp]:
          numberFormat && isNumeric(metricValue)
            ? getMetricValue(metricValue, metadata)
            : metricValue,
      };
    });

    return [
      ...data,
      {
        ...currentGroup,
      },
    ];
  }, []);
  return {
    MSCIESGRatingView:
      metadata.benchmarkTileType === BENCHMARK_TILE_TYPES.MSCI_ESG_Rating,
    isLeftAlinged,
    tileType: metadata.benchmarkTileType,
    labels: metadata.benchmarkMetadata.associatedMetrics
      .map((metric: string, i: number) => ({
        label: metadata
          ? metadata.benchmarkMetadata.associatedMetricsDisplayNames[i]
          : metric,
        id: metric?.charAt(0).toLowerCase() + metric?.slice(1),
        tooltip: hideToolip
          ? []
          : metadata.associatedMetricsTooltips?.filter(
              (t: AssociatedMetricTooltips) => t.associatedMetric === metric
            ) ?? [],
      }))
      .filter(
        ({ id }: { id: string }) =>
          id !== ESG_IVA_RATING_TILE_PEER_INDUSTRY.IVA_RATING_DATE
      ),
    response: tmpResponse,
  };
};

export const mapTabularChartData = (
  metadata: Visualization,
  dataset: InsightData[],
  removeEmptyMetric: boolean = false
) => {
  let metrics = getTabularMetrics(
    metadata.benchmarkMetadata.associatedMetricsDisplayNames,
    metadata.benchmarkMetadata.associatedMetrics,
    metadata.associatedMetricsTooltips,
    dataset
  );
  let hasEmptyValue = false;

  const cleanedData = dataset.reduce((data: any[], current: InsightData) => {
    let currentGroup: any = {
      globalCompanyId: current.globalCompanyId,
      companyName: current.companyName,
    };
    current.metrics.forEach((m: InsightMetricData) => {
      let metricProp =
        m.metricKey?.charAt(0).toUpperCase() + m.metricKey?.slice(1);
      currentGroup = {
        ...currentGroup,
        [metricProp]: m.metricValue
          ? !isNaN(m.metricValue)
            ? m.metricValue > 1
              ? m.metricValue
              : m.metricValue > 0
              ? "Yes"
              : "No"
            : m.metricValue
          : "*",
      };
      if (currentGroup[metricProp] === "*") hasEmptyValue = true;
    });

    return [
      ...data,
      {
        ...currentGroup,
      },
    ];
  }, []);

  const firstMetric: TabularMetrics = { metric: "", description: [] };
  return {
    metrics: removeEmptyMetric ? metrics : [firstMetric].concat(metrics),
    data: cleanedData,
    tileType: metadata.benchmarkTileType,
    tileView: metadata.benchmarkType,
    isDetailView: metadata.isDetailedView,
    hasEmptyValue: hasEmptyValue,
  };
};

const getMetrics = (data: InsightData, associatedMetrics: string[]) =>
  associatedMetrics.reduce((acc: any, metric: any) => {
    const d = data?.metrics.find(
      (m: InsightMetricData) => m.metricKey === metric
    );
    return {
      ...acc,
      [metric]: d?.metricValue,
      [`${metric}-tooltip`]: d?.metricTooltip,
    };
  }, []);

export const mapSpeedometerChartData = (
  metadata: Metadata,
  originalDataset: InsightData[],
  dataset: InsightData[]
) => {
  const companies = originalDataset.map((company: InsightData) => ({
    externalCompanyId: company.externalCompanyId,
    globalCompanyId: company.globalCompanyId,
    companyName: company.companyName,
    isBaseCompany: company.isBaseCompany,
    industryId: company.industryId,
    industryName: company.industryName,
  }));
  const baseCompany = companies.filter((c: any) => c.isBaseCompany)[0];
  const groupBy: any = "globalCompanyId";
  const colorCodes = metadata.colorCodes?.length
    ? metadata.colorCodes
    : SPEEDOMETER_DEFAULT_COLORS.COLORS;
  const conditionalColorCodes = metadata.conditionalColorCodes?.length
    ? metadata.conditionalColorCodes
    : SPEEDOMETER_DEFAULT_COLORS.CONDITIONAL_COLORS;
  const defaultLimit = metadata.fieldValueLimit ?? 10;

  const groupByIndexValue = (metadata.api.payload ?? [])[groupBy];

  const dataClean: any[] = [
    {
      ...baseCompany,
      ...getMetrics(
        dataset.filter((d: InsightData) => d.isBaseCompany)[0],
        metadata.associatedMetrics
      ),
    },
  ];

  if (groupByIndexValue !== undefined)
    dataClean.push({
      [groupBy]: groupByIndexValue,
      ...getMetrics(
        dataset.filter(
          (d: InsightData) => d.globalCompanyId === groupByIndexValue
        )[0],
        metadata.associatedMetrics
      ),
    });

  const dataIndex = dataClean.findIndex((d) => d[groupBy]);
  const avgIndex = dataClean.findIndex((d) => d[groupBy] === groupByIndexValue);
  return dataClean.length
    ? metadata.axis.secondary.map((title: any, index: number) => {
        const currentIndex = metadata.associatedMetrics![index] ?? "";
        const value = parseFloat(dataClean[dataIndex][currentIndex]);

        const limit = metadata.valueLimitProperties
          ? metadata.valueLimitProperties[index].fieldValueLimit ?? defaultLimit
          : defaultLimit;
        const dot = limit * 0.055;
        const avgValue =
          avgIndex >= 0 ? parseFloat(dataClean[avgIndex][currentIndex]) : 0;
        const tooltipData = {
          value,
          average: avgValue,
          ...dataClean[dataIndex],
        };
        const mainSpeedometerValue = avgIndex >= 0 ? avgValue : value;
        const label: string | number =
          metadata.axis?.primary.length > 0
            ? getLegendTooltip(tooltipData, metadata.axis.primary[0])
            : value;
        const subLabel =
          metadata.axis?.primary.length > 1
            ? getLegendTooltip(tooltipData, metadata.axis.primary[1])
            : metadata.valueLimitProperties &&
              metadata.valueLimitProperties[index].showSubLabel
            ? `out of ${limit}`
            : "";
        return {
          title,
          valueSufix: metadata.valueLimitProperties
            ? metadata.valueLimitProperties[index].fieldValueSuffix ?? ""
            : "",
          limit,
          label,
          subLabel,
          value: mainSpeedometerValue,
          avgValue: value,
          tooltip: dataClean[dataIndex][`${currentIndex}-tooltip`],
          speedometerValues: [
            {
              id: `data-${metadata.associatedMetrics[index] ?? ""}-${index}`,
              order: 1,
              tooltip:
                avgIndex >= 0
                  ? label
                  : getLegendTooltip(tooltipData, `{value} {companyName}`),
              value: value,
              color: colorCodes[index].split(","),
            },
            { order: 2, value: limit - value, color: [] },
          ],
          speedometerAverage:
            avgIndex >= 0
              ? [
                  {
                    order: 1,
                    value: mainSpeedometerValue - dot * 0.2,
                    color: [],
                  },
                  {
                    id: `avgData-${
                      metadata.associatedMetrics[index] ?? ""
                    }-${index}`,
                    tooltip: subLabel,
                    order: 2,
                    value: dot,
                    color: conditionalColorCodes[0].split(","),
                  },
                  {
                    order: 3,
                    value: limit - mainSpeedometerValue + dot * 0.2,
                    color: [],
                  },
                ]
              : [],
        };
      })
    : [];
};

export const cleanCompanies = (
  globalCompanyMappings: GlobalCompanyGroup[],
  companies: InsightCompanyFlat[]
) => {
  let isBaseCompanyAGroup = globalCompanyMappings.find(
    (g: GlobalCompanyGroup) => g.isBaseMapping
  );

  let tmpCompanies = globalCompanyMappings.map(
    (g: GlobalCompanyGroup): InsightCompanyFlat => {
      let defaultCompany = g.globalCompanyMappingDetails.find(
        (m: GlobalCompanyMapping) => m.isDefault
      );
      let defaultCompanyInCompanies = companies.find(
        (icf: InsightCompanyFlat) =>
          defaultCompany &&
          icf.globalCompanyId === defaultCompany.globalCompanyId
      );

      return {
        externalCompanyId:
          defaultCompany &&
          defaultCompany.globalCompany &&
          defaultCompany.globalCompany.externalCompanyId
            ? defaultCompany.globalCompany.externalCompanyId
            : 0,
        globalCompanyId: defaultCompany
          ? defaultCompany.globalCompany.globalCompanyId
          : g.globalCompanyMappingDetails[0]
          ? g.globalCompanyMappingDetails[0].globalCompanyId
          : 0,
        companyName: g.globalCompanyMappingName,
        isBaseCompany: g.isBaseMapping,
        industryId: defaultCompany
          ? defaultCompany.globalCompany.industryId
          : 0,
        globalCompanyMapping: g,
        displayOrder:
          defaultCompanyInCompanies && defaultCompanyInCompanies.displayOrder
            ? defaultCompanyInCompanies.displayOrder
            : 0,
      };
    }
  );
  tmpCompanies = isBaseCompanyAGroup
    ? tmpCompanies.concat(
        companies.filter(
          (c: InsightCompanyFlat) =>
            isCompanyInGlobalGroup(globalCompanyMappings, c.globalCompanyId) ===
            0
        )
      )
    : companies
        .filter(
          (c: InsightCompanyFlat) =>
            isCompanyInGlobalGroup(globalCompanyMappings, c.globalCompanyId) ===
            0
        )
        .concat(tmpCompanies);

  tmpCompanies = tmpCompanies.sort(
    (a: InsightCompanyFlat, b: InsightCompanyFlat) => {
      return (a.displayOrder ?? 0) > (b.displayOrder ?? 0)
        ? 1
        : (a.displayOrder ?? 0) < (b.displayOrder ?? 0)
        ? -1
        : 0;
    }
  );
  return tmpCompanies;
};
