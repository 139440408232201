import ParentSize from "@visx/responsive/lib/components/ParentSize";
import VerticalBarChart from "components/visualizations/charts/vertical-bar.chart";
import { DatasetLegend } from "components/visualizations/dataset-structure";
import { useDispatch, useSelector } from "react-redux";
import {
  InsightCompanyFlat,
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import {
  APP_SETTING,
  BENCHMARK_TILE_TYPES,
  INSIGHT_BENCHMARK_IDS,
  INSIGHT_BENCHMARK_TYPE,
  INSIGHT_PEER_COMPANY,
  SCOPE3_DATA,
} from "utils/constants";
import { useTileContext } from "../tile.context";
import CDPScope3CategoriesStatus from "./CDPScope3CategoriesStatus";
import CDPScope3PeerIndChart from "./CDPScope3PeerIndChart";
import { useEffect } from "react";
import { Scope3CategoriesStatusColorCodes } from "services/commons.service";
import CDPScope3Table from "./CDPScope3Table";
import { RootStore } from "services/store.service";
import Popover from "components/shared/popover/popover";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";

const CDPScope3CategoriesReported = () => {
  const {
    metadata,
    dataset,
    originalDataset,
    associatedMetrics,
    associatedMetricsDisplayNames,
    metadata: {
      benchmarkType,
      benchmarkMetadata,
      isDetailedView,
      associatedMetricGroupTooltips,
    },
    isTableViewActive,
  } = useTileContext();
  const BLOCK = "cdpScope3CategoriesReported";
  const baseCompany = dataset.find((d: InsightData) => d.isBaseCompany);
  const dispatch = useDispatch();
  const commonsState = useSelector((state: RootStore) => state.commons);

  useEffect(() => {
    if (!commonsState.scope3CategoriesColorCodes) {
      dispatch(
        Scope3CategoriesStatusColorCodes(
          APP_SETTING.Scope3CategoriesStatusColorCodes
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companies = originalDataset.map((company: InsightData) => ({
    externalCompanyId: company.externalCompanyId,
    globalCompanyId: company.globalCompanyId,
    companyName: company.companyName,
    isBaseCompany: company.isBaseCompany,
    industryId: company.industryId,
    industryName: company.industryName,
  }));

  const mapChartData = () => {
    let group = benchmarkMetadata.associatedMetrics;
    return dataset[0].metrics
      .filter(
        (item: InsightMetricData) =>
          item.metricValue !== null &&
          group[0]?.groupValues.includes(item.metricKey)
      )
      .map((item: InsightMetricData, i: number) => {
        return {
          legendValue: (i + 1).toString(),
          legendOrder: i + 1,
          legendData: item.metricValue,
          legendColor: "",
          legendTooltip: "",
          legendTitle: item.metricName,
        };
      });
  };

  const getCompanyData = (index: number) => {
    let group = benchmarkMetadata.associatedMetrics;
    const dataSet = dataset[0].metrics
      .filter((item: InsightMetricData) =>
        index === 0
          ? item.metricValue !== null &&
            group[index]?.groupValues.includes(item.metricKey)
          : group[index]?.groupValues.includes(item.metricKey)
      )
      .map((item: InsightMetricData, i: number) => {
        return {
          categoryValue: item.metricValue,
          categoryColor: "",
          categoryName: item.metricName,
        };
      });

    const hasAllMissingData = dataSet?.every(
      (data: any) => data.categoryValue === "" || data.categoryValue === null
    );

    return {
      data: index === 0 ? dataSet : !hasAllMissingData ? dataSet : [],
      categoryName:
        metadata.benchmarkMetadata.associatedMetricsDisplayNames[index]
          ?.groupName,
    };
  };

  const getPeerIndChartData = () => {
    let group = benchmarkMetadata.associatedMetrics;
    const filteredData = dataset.map((data: InsightData) => {
      return {
        ...data,
        metrics: data.metrics.filter((metric: InsightMetricData) =>
          group[0]?.groupValues.includes(metric.metricKey)
        ),
      };
    });

    let highestMetricValue = 0;
    filteredData.forEach((insight: InsightData) => {
      insight.metrics.forEach((metric: InsightMetricData) => {
        if (
          parseInt(metric.metricValue) &&
          parseInt(metric.metricValue) > highestMetricValue
        ) {
          highestMetricValue = metric.metricValue;
        }
      });
    });

    const metricData: any = {};

    filteredData.forEach((insight: InsightData) => {
      insight.metrics.forEach((metric: InsightMetricData) => {
        const { metricKey, metricName, metricValue } = metric;
        if (!(metricKey in metricData)) {
          metricData[metricKey] = {
            metricKey,
            metricName,
            baseCompanyValue: null,
            peerIndValue: null,
            baseCompanyTooltip: "",
            peerCompanyTooltip: "",
            baseCompPosition: null,
            peerIndPosition: null,
          };
        }
        if (insight.isBaseCompany) {
          metricData[metricKey].baseCompanyValue = metricValue;
          metricData[metricKey].baseCompanyTooltip = metricValue
            ? `${insight.companyName} ${new Intl.NumberFormat("en-US").format(
                metricValue
              )}`
            : `${insight.companyName}  -  No data available`;
          metricData[metricKey].baseCompPosition = metricValue
            ? (100 / highestMetricValue) * metricValue
            : null;
        } else if (metricData[metricKey].peerIndValue === null) {
          metricData[metricKey].peerIndValue = metricValue;
          metricData[metricKey].peerCompanyTooltip = metricValue
            ? `${insight.companyName} ${new Intl.NumberFormat("en-US").format(
                metricValue
              )}`
            : "";
          metricData[metricKey].peerIndPosition = metricValue
            ? (100 / highestMetricValue) * metricValue
            : null;
        }
      });
    });

    const number = highestMetricValue;
    const parts = 6;
    const rangeArray = [];

    for (let i = 0; i <= parts; i++) {
      let value = (number / (parts - 1)) * i;
      if (value <= number) {
        rangeArray.push(Math.round(value));
      }
    }

    const formattedMetricData = Object.values(metricData);
    const filteredDataSet = formattedMetricData.filter(
      (metric: any) =>
        metric.baseCompanyValue !== null || metric.peerIndValue !== null
    );
    return {
      dataset: filteredDataSet,
      dataRange: rangeArray,
      categoryName:
        metadata.benchmarkMetadata.associatedMetricsDisplayNames[0]?.groupName,
    };
  };

  const getDataHavingValue = (data: any[]) => {
    const properties = data.reduce((props: any, obj: any) => {
      Object.keys(obj).forEach((prop: any) => {
        if (!props.includes(prop) && obj[prop] !== "") {
          props.push(prop);
        }
      });
      return props;
    }, []);
    return data.map((obj) => {
      const newObj: any = {};

      properties.forEach((prop: any) => {
        if (obj[prop] !== undefined) {
          newObj[prop] = obj[prop];
        }
      });

      return newObj;
    });
  };

  const getPeerIndData = (index: number) => {
    let group = benchmarkMetadata.associatedMetrics;
    const filteredData = dataset.map((data: InsightData) => {
      return {
        ...data,
        metrics: data.metrics.filter((metric: InsightMetricData) =>
          group[index]?.groupValues.includes(metric.metricKey)
        ),
      };
    });

    let tmpResponse = filteredData.reduce(
      (data: any[], current: InsightData) => {
        let currentGroup: any = {
          globalCompanyId: current.globalCompanyId,
          header: current.companyName,
        };
        current.metrics.forEach((m: InsightMetricData) => {
          let metricProp =
            m.metricKey?.charAt(0).toLowerCase() + m.metricKey?.slice(1);
          currentGroup = {
            ...currentGroup,
            [metricProp]: m.metricValue ? m.metricValue : "",
          };
        });
        return [
          ...data,
          {
            ...currentGroup,
          },
        ];
      },
      []
    );

    tmpResponse =
      index === SCOPE3_DATA.EMISSIONS
        ? getDataHavingValue(tmpResponse)
        : tmpResponse;
    let labels =
      metadata.benchmarkMetadata &&
      metadata.benchmarkMetadata.associatedMetrics[index] &&
      metadata.benchmarkMetadata.associatedMetrics[index].groupValues
        ? metadata.benchmarkMetadata.associatedMetrics[index].groupValues.map(
            (metric: string, i: number) => ({
              label: metadata
                ? metadata.benchmarkMetadata.associatedMetricsDisplayNames[
                    index
                  ]?.groupValues[i]
                : metric,
              id: metric?.charAt(0).toLowerCase() + metric?.slice(1),
            })
          )
        : [];
    labels =
      index === SCOPE3_DATA.EMISSIONS
        ? labels.filter((label: any) =>
            tmpResponse[0]?.hasOwnProperty(label.id)
          )
        : labels;
    return {
      tileType: metadata.benchmarkTileType,
      categoryName:
        metadata.benchmarkMetadata.associatedMetricsDisplayNames[index]
          ?.groupName,
      labels: labels,

      response: isTableViewActive
        ? tmpResponse.filter(
            (res: any) => res.header !== INSIGHT_PEER_COMPANY.companyName
          )
        : tmpResponse,
    };
  };

  const commonsProps = {
    isDetailedView: isDetailedView,
    isTableView: isTableViewActive,
    name: "",
    currentInsightView: benchmarkType,
    benchmarkTileType: BENCHMARK_TILE_TYPES.CDP_SCOPE_3_CATEGORIES_REPORTED,
    baselineCompany: companies.filter(
      (c: InsightCompanyFlat) => c.isBaseCompany
    )[0],
    associatedMetricGroupTooltips: associatedMetricGroupTooltips,
    associatedMetrics: associatedMetrics,
    associatedMetricsDisplayNames: associatedMetricsDisplayNames,
    associatedMetricsTooltip: metadata.associatedMetricsTooltips
      ? metadata.associatedMetricsTooltips
      : [],
    // TO DO: REMOVE FOLLOWING PROPERTY WHEN TABULAR CDP CHART COMPONENT IS DONE
    companies: companies,
    gradient: {
      colorCodes: metadata.benchmarkMetadata.colorCodes ?? [],
      grouped: true,
      gradientId: `${metadata.benchmarkId}-gradient`,
    },
    bottomText: true,
    showAxisDetails: true,
    test: true,
    // TO DO: REMOVE FOLLOWING PROPERTY WHEN TABULAR CDP CHART COMPONENT IS DONE
    cikNumberForCDP:
      benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK
        ? INSIGHT_BENCHMARK_IDS.PEER
        : benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY
        ? INSIGHT_BENCHMARK_IDS.INDUSTRY
        : -1,
  };

  const getCDPVerticalBarChart = () => {
    let tmpData = mapChartData();
    let emissionData = getCompanyData(SCOPE3_DATA.EMISSIONS);
    let categoryStatusData = getCompanyData(SCOPE3_DATA.RELEVANCE);
    return (
      <>
        <div className={`${BLOCK}__vertical-container`}>
          <div className={`${BLOCK}__vertical-chart`}>
            <div className={`${BLOCK}__metricWrapper`}>
              <Popover
                displayText={emissionData.categoryName}
                metricClassName={``}
                buttonClassName={`${BLOCK}__heading`}
                content={
                  associatedMetricGroupTooltips
                    ? associatedMetricGroupTooltips.filter(
                        (tooltip: AssociatedMetricTooltips) =>
                          tooltip.associatedMetricGroupName ===
                          emissionData.categoryName
                      )
                    : []
                }
              />
            </div>

            <ParentSize>
              {({ width: visWidth, height: visHeight }) => (
                <VerticalBarChart
                  data={tmpData}
                  {...commonsProps}
                  width={visWidth}
                  height={visHeight}
                />
              )}
            </ParentSize>
          </div>
          <div className={`${BLOCK}__vertical-legends`}>
            {dataset && dataset.length > 0 && (
              <DatasetLegend
                dataset={tmpData}
                BLOCK={`${BLOCK}__vertical`}
                groupedData={[]}
                selectedGraphOption={
                  metadata.benchmarkMetadata.selectedGraphOption
                }
                name={
                  metadata.benchmarkMetadata.benchmarkValue
                    ?.charAt(0)
                    .toUpperCase() +
                  metadata.benchmarkMetadata.benchmarkValue?.slice(1)
                }
                custom={true}
                insight={true}
                hideLegend={true}
                isCDPLegend={true}
                associatedMetricsTooltip={metadata.associatedMetricsTooltips}
                associatedMetricGroupTooltips={associatedMetricGroupTooltips}
              />
            )}
          </div>
        </div>
        <CDPScope3CategoriesStatus
          categoryStatusData={categoryStatusData}
          benchmarkType={benchmarkType}
          associatedMetricGroupTooltips={associatedMetricGroupTooltips}
        />
      </>
    );
  };

  const getPeerIndustryView = () => {
    const peerIndustryChartData: any = getPeerIndChartData();
    const peerIndReportedRelevance: any = getPeerIndData(SCOPE3_DATA.RELEVANCE);
    return (
      <>
        <CDPScope3PeerIndChart
          dataObj={peerIndustryChartData}
          benchmarkType={benchmarkType}
          baseCompany={baseCompany?.companyName ?? ""}
          associatedMetricGroupTooltips={associatedMetricGroupTooltips}
        />
        <CDPScope3CategoriesStatus
          benchmarkType={benchmarkType}
          peerIndStatusData={peerIndReportedRelevance}
          associatedMetricGroupTooltips={associatedMetricGroupTooltips}
        />
      </>
    );
  };

  return isTableViewActive || isDetailedView ? (
    <CDPScope3Table
      data-test="table"
      reportedEmission={getCompanyData(SCOPE3_DATA.EMISSIONS)}
      reportedRelevance={getCompanyData(SCOPE3_DATA.RELEVANCE)}
      benchmarkType={benchmarkType}
      reportedEmissionPeerInd={getPeerIndData(SCOPE3_DATA.EMISSIONS)}
      reportedRelevancePeerInd={getPeerIndData(SCOPE3_DATA.RELEVANCE)}
      isDetailedView={isDetailedView}
      isTableViewActive={isTableViewActive}
      associatedMetricGroupTooltips={associatedMetricGroupTooltips}
    />
  ) : benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY ? (
    getCDPVerticalBarChart()
  ) : (
    getPeerIndustryView()
  );
};

export default CDPScope3CategoriesReported;
