import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "components/shared/icon/icon";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import Tooltip, { TooltipPosition } from "../shared/tooltip/tooltip";
import { RootStore } from "services/store.service";

import {
  BenchmarkActionTypes,
  DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD,
  USER_PREFERENCE_TYPE,
  peerBenchmarkPath,
  peerBenchmarkTableFilter,
  shareItemType,
} from "utils/constants";

import moment from "moment";
import classNames from "classnames";
import { SavedBenchmark } from "services/peer-benchmark/peer-benchmark.model";
import history from "../../utils/history";
import ShareToUsersModal from "components/shared/share-to-users-modal/share-to-users-modal";
import {
  deletePeerBenchmark,
  errorEditPeerRename,
  handleEditPeerBenchmark,
  loadCurrentBenchmarkData,
  loadSavedPeerbenchmarks,
  renameEditPeerSuccess,
  saveEditPeerBnechmarkData,
  sharePeerBenchmark,
  sharePeerBenchmarkSuccess,
} from "services/peer-benchmark/peer-benchmark.service";
import { addToastMessage, showTooltip } from "services/commons.service";
import DeleteModal from "components/shared/delete-modal/delete-modal";
import EditModal from "components/shared/edit-modal/edit-modal";
import Table, { TableRowItem } from "components/shared/table/table";
import ActiveFilterTag from "components/shared/active-filter-tag/active-filter-tag";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";

const SavedPeerbenchmarksTable = () => {
  const BLOCK = "saved-peerbenchmarks-table";
  const dispatch = useDispatch();
  const commonsState = useSelector((state: RootStore) => state.commons);
  const [currentBenchmarkSelected, setCurrentBenchmarkSelected] =
    useState<any>();
  const [showDeleteBenchmark, setShowDeleteBenchmark] =
    useState<boolean>(false);
  const [showShareBenchmark, setShowShareBenchmark] = useState<boolean>(false);
  const [showEditBenchmark, setShowEditBenchmark] = useState<boolean>(false);
  const peerBenchmarkState = useSelector(
    (state: RootStore) => state.peerBenchmarkState
  );
  const [savedPeerbanchmarks, setSavedPeerbanchmarks] = useState(
    peerBenchmarkState.savedBenchmark.benchmarks
  );

  const [showBold, setShowBold] = useState(true);
  const [currentTableFilter, setCurrentTableFilter] = useState<{
    open: string;
    id: string;
    value: string;
  }>({ open: "", id: "", value: "" });

  useEffect(() => {
    if (peerBenchmarkState.sharePeerBenchmarkSuccess) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="benchmark.successfully.shared" />,
        })
      );
      setShowShareBenchmark(false);
      dispatch(sharePeerBenchmarkSuccess(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peerBenchmarkState.sharePeerBenchmarkSuccess]);

  useEffect(() => {
    if (peerBenchmarkState.editPeerBenchmarkSuccess) {
      setShowEditBenchmark(false);
      dispatch(renameEditPeerSuccess(false));
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="benchmark.successfully.saved" />,
        })
      );
      dispatch(loadSavedPeerbenchmarks(DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peerBenchmarkState.editPeerBenchmarkSuccess]);

  useEffect(() => {
    if (peerBenchmarkState.errorPeerEditRename) {
      dispatch(errorEditPeerRename(false));
      dispatch(renameEditPeerSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peerBenchmarkState.errorPeerEditRename]);

  useEffect(() => {
    setSavedPeerbanchmarks(
      currentTableFilter.value === ""
        ? peerBenchmarkState.savedBenchmark.benchmarks
        : peerBenchmarkState.savedBenchmark.benchmarks.filter((sp) => {
            const textToFilter =
              currentTableFilter.id === "my-searches-name"
                ? sp.benchmarkTitle
                : sp.description;
            return textToFilter
              ? textToFilter
                  .toLowerCase()
                  .includes(currentTableFilter.value.toLowerCase())
              : false;
          })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTableFilter.value]);

  // const handleMouseEnter = (
  //   e: any,
  //   children: any,
  //   position?: TooltipPosition
  // ) => {
  //   const element = e.target.closest(".compact-wrapper .compact");

  //   if (!element || element.scrollWidth > element.offsetWidth) {
  //     dispatch(
  //       showTooltip({
  //         children: children,
  //         position: position || TooltipPosition.topRight,
  //         customPosition: true,
  //         elementDimensions: e.target.getBoundingClientRect(),
  //         executeMouseLeaveEvent: true,
  //       })
  //     );
  //   }
  // };

  // const handleMouseLeave = (e: any) => {
  //   if (
  //     commonsState.toolTip.elementDimensions &&
  //     !commonsState.toolTip.isOverTooltip
  //   ) {
  //     dispatch(
  //       showTooltip({
  //         children: null,
  //         customPosition: null,
  //         position: null,
  //         arrowPosition: null,
  //         elementDimensions: null,
  //         executeMouseLeaveEvent: false,
  //         isOverTooltip: null,
  //       })
  //     );
  //   }
  // };
  const setBenchmarkHandler = (benchmark: SavedBenchmark) => {
    setShowShareBenchmark(true);
    setCurrentBenchmarkSelected(benchmark);
  };

  const editPeerBenchmarkHandler = (title: any, desc: any) => {
    let payload = {
      benchmark: {
        ...peerBenchmarkState.currentBenchMarkData,
        benchmarkTitle: title,
        description: desc,
      },
      trackingType: BenchmarkActionTypes.Edit_Benchmark_Peer,
    };
    dispatch(handleEditPeerBenchmark(payload));
  };

  const sharePeerBenchmarkHandler = (shareWith: any[]) => {
    let payload = {
      benchmarkType: currentBenchmarkSelected.benchmarkType,
      benchmarkTitle: currentBenchmarkSelected.benchmarkTitle,
      sourceBenchmarkId: currentBenchmarkSelected.benchmarkId,
      sharedWith: shareWith,
      userPreferenceType: USER_PREFERENCE_TYPE.PEER_BENCHMARK,
    };
    dispatch(sharePeerBenchmark(payload));
  };

  const deleteBenchmarkHandler = () => {
    setShowDeleteBenchmark(false);
    dispatch(deletePeerBenchmark(currentBenchmarkSelected.benchmarkId));
    setCurrentBenchmarkSelected(null);
  };

  const getFilterOptions = (filterId: string) => ({
    modalPosition: "right",
    currentFilterText:
      currentTableFilter.id === filterId ? currentTableFilter.value : "",
    isFiltering: currentTableFilter.open === filterId,
    setFilterPop: (filter: string) =>
      setCurrentTableFilter((c) => ({
        ...c,
        open: filter,
      })),
    handleClose: () => setCurrentTableFilter((c) => ({ ...c, open: "" })),
    handleApply: (filterText: string) => {
      setCurrentTableFilter({
        open: "",
        id: filterId,
        value: filterText,
      });
    },
  });

  useEffect(() => {
    let timeout = setTimeout(() => {
      setShowBold(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch]);

  function getAutoDelDays(lastRemainingDays: number) {
    let className =
      lastRemainingDays < 8 && lastRemainingDays > 3
        ? `${BLOCK}__auto-delete-7`
        : lastRemainingDays <= 3
        ? `${BLOCK}__auto-delete-less-than-7`
        : "";

    return (
      <span className={className}>
        {lastRemainingDays > 1 ? (
          `${lastRemainingDays} days left `
        ) : lastRemainingDays === 1 ? (
          `${lastRemainingDays} day left `
        ) : lastRemainingDays === null ? (
          ""
        ) : (
          <span>
            {"Less than ..."}{" "}
            <Tooltip position={TooltipPosition.right}>
              <div>{"Less than a day left."}</div>
            </Tooltip>
          </span>
        )}
      </span>
    );
  }

  const getDescription = (description: any) => {
    return (
      <div className={`${BLOCK}__descriptionTooltip`}>
        <span
          className={`${BLOCK}__name-text ${BLOCK}__hover-tooltip ${BLOCK}__name-tooltip`}
        >
          {description.length > 25
            ? description.slice(0, 25) + "..."
            : description}

          {description.length > 25 ? (
            <Tooltip position={TooltipPosition.right}>{description}</Tooltip>
          ) : (
            ""
          )}
        </span>
      </div>
    );
  };

  const getSharedBy = (comparison: any) => {
    let fullName: any = `${comparison.sharedBy.lastName} ${comparison.sharedBy.firstName}`;
    let sharedByName =
      fullName.length > 12 ? `${fullName.slice(0, 11)}...` : fullName;
    return (
      <span
        className={`${BLOCK}__shared-with-main`}
        onMouseEnter={(e: any) =>
          fullName.length > 12 &&
          handleMouseEnter(
            e,
            "span",
            <div>{fullName}</div>,
            "",
            dispatch,
            null,
            MainTooltipPosition.TopRight
          )
        }
        onMouseLeave={(e) => handleMouseLeave(e, dispatch, false)}
      >
        {sharedByName}
      </span>
    );
  };

  const getSharedWith = (comparison: any) => {
    const sharedWithUsersSorted = comparison.sharedWith
      .map(
        (sharedUser: any) => `${sharedUser.lastName} ${sharedUser.firstName}`
      )
      .sort();

    let firstUser =
      sharedWithUsersSorted[0].length > 12
        ? `${sharedWithUsersSorted[0].slice(0, 11)}...`
        : sharedWithUsersSorted[0];

    return (
      <div
        className={`${BLOCK}__column-content ${BLOCK}__shared-with compact-wrapper`}
      >
        <span
          className={`${BLOCK}__shared-with-main`}
          onMouseEnter={(e: any) =>
            sharedWithUsersSorted[0].length > 12 &&
            handleMouseEnter(
              e,
              "span",
              <div>{sharedWithUsersSorted[0]}</div>,
              "",
              dispatch,
              null,
              MainTooltipPosition.TopRight
            )
          }
          onMouseLeave={(e) => handleMouseLeave(e, dispatch, false)}
        >
          {firstUser}
        </span>
        {sharedWithUsersSorted.length > 1 && (
          <div
            className={`${BLOCK}__shared-with-count`}
            onMouseEnter={(e: any) =>
              handleMouseEnter(
                e,
                "div",
                <div className={`${BLOCK}__shared-with-count-tooltip`}>
                  {sharedWithUsersSorted.slice(1).join("; ")}
                </div>,
                "",
                dispatch,
                null,
                MainTooltipPosition.TopRight
              )
            }
            onMouseLeave={(e) => handleMouseLeave(e, dispatch, false)}
          >
            &nbsp;{`+${sharedWithUsersSorted.length - 1}`}
          </div>
        )}
      </div>
    );
  };

  const getTableHeaders = () => [
    {
      text: "my-searches-name",
      isFormattedText: true,
      sortId: "ComparisonName",
      filter: getFilterOptions("my-searches-name"),
    },
    {
      text: "my-searches-description",
      isFormattedText: true,
      filter: getFilterOptions("my-searches-description"),
    },

    {
      text: "peer-benchmarks-last-updated",
      isFormattedText: true,
      sortId: "LastUpdatedDate",
    },

    {
      text: "saved-shortlist.shared-by",
      isFormattedText: true,
      sortId: "SharedBy",
    },
    {
      text: "my-searches-shared-with",
      isFormattedText: true,
    },
    {
      text: "saved-shortlist.date-shared",
      isFormattedText: true,
      sortId: "SharedDate",
    },

    {
      text: "my-searches-auto-delete",
      isFormattedText: true,
    },
    {
      text: "saved-shortlist.actions",
      isFormattedText: true,
    },
  ];

  const getTableRows = () => {
    return savedPeerbanchmarks?.map(
      (benchmarks: SavedBenchmark, index: number) => {
        const actions = [
          {
            name: "saved-shortlist.share",
            iconName: "share",
            onClick: () => {
              setBenchmarkHandler(benchmarks);
            },
            id: "saved-shortlist.share",
          },

          {
            name: "saved-shortlist.edit",
            iconName: "pencil",
            onClick: () => {
              dispatch(saveEditPeerBnechmarkData(benchmarks));
              dispatch(loadCurrentBenchmarkData(benchmarks.benchmarkId));
              setShowEditBenchmark(true);
            },
            id: "saved-shortlist.edit",
          },
          {
            name: "saved-shortlist.delete",
            iconName: "remove1",
            onClick: () => {
              setCurrentBenchmarkSelected(benchmarks);
              setShowDeleteBenchmark(true);
            },
            id: "saved-shortlist.delete",
          },
        ];

        let tableRows = {
          id: benchmarks.benchmarkId,
          key: `${index}_${benchmarks.benchmarkId}__body`,
          className: classNames(`${BLOCK}__link`, {
            [`${BLOCK}__link--newItem`]:
              benchmarks.lastAccessedDate === null &&
              benchmarks.sharedBy?.firstName &&
              showBold,
          }),
          onClick: () => {
            history.push(`${peerBenchmarkPath}/${benchmarks.benchmarkId}`);
          },
          columnItems: [
            {
              text: benchmarks.benchmarkTitle,
            },
            {
              text: benchmarks.description ? (
                getDescription(benchmarks.description)
              ) : (
                <Icon name="chevron-minus" width={20} height={20} />
              ),
            },
            {
              text: benchmarks.lastUpdatedDate ? (
                benchmarks.lastUpdatedDate ? (
                  moment(benchmarks.lastUpdatedDate).format("YYYY-MM-DD")
                ) : (
                  ""
                )
              ) : (
                <Icon name="chevron-minus" width={20} height={20} />
              ),
            },
            {
              text:
                benchmarks.sharedBy &&
                benchmarks.sharedBy.firstName !== null ? (
                  getSharedBy(benchmarks)
                ) : (
                  <Icon name="chevron-minus" width={20} height={20} />
                ),
              type: "Peer-Benchmark",
            },
            {
              text:
                benchmarks.sharedWith.length > 0 ? (
                  getSharedWith(benchmarks)
                ) : (
                  <Icon name="chevron-minus" width={20} height={20} />
                ),
              type: "Peer-Benchmark",
            },
            {
              text: benchmarks.sharedDate ? (
                benchmarks.sharedDate ? (
                  moment(benchmarks.sharedDate).format("YYYY-MM-DD")
                ) : (
                  ""
                )
              ) : (
                <Icon name="chevron-minus" width={20} height={20} />
              ),
            },
            {
              text: benchmarks.remainingDays ? (
                getAutoDelDays(benchmarks.remainingDays)
              ) : (
                <Icon name="chevron-minus" width={20} height={20} />
              ),
            },
          ],
        } as TableRowItem;

        tableRows.columnItems.push({
          className: `${BLOCK}__column-content ${BLOCK}__actions`,
          onClick: (e: any) => {
            e.stopPropagation();
          },
          renderContent: () =>
            actions.map((action) => (
              <button
                data-testid={`${action.id}-button`}
                key={action.name}
                className={`${BLOCK}__action-icon ${BLOCK}__${action.name}`}
                onClick={(e: any) => {
                  action.onClick();
                }}
              >
                <Tooltip position={TooltipPosition.top}>
                  <FormattedMessage id={action.id} />
                </Tooltip>
                <Icon name={action.iconName} height={24} width={24} />
              </button>
            )),
        });

        return tableRows;
      }
    );
  };

  return (
    <div
      className={`${BLOCK}__table-container`}
      data-testid="comparisons-table-container"
    >
      <div className={`${BLOCK}__data`}>
        <div className={`${BLOCK}__results-count`}>
          <FormattedMessage
            id={
              savedPeerbanchmarks.length === 1
                ? "peer-benchmarks.header.count.singular"
                : "peer-benchmarks.header.count.multiple"
            }
            values={{
              count: savedPeerbanchmarks.length,
            }}
          />
        </div>
        {currentTableFilter.value !== "" && (
          <ActiveFilterTag
            filterText={`${
              (peerBenchmarkTableFilter as any)[currentTableFilter.id]
            }: ${currentTableFilter.value}`}
            handleRemove={() =>
              dispatch(setCurrentTableFilter({ open: "", id: "", value: "" }))
            }
          />
        )}
      </div>
      <div className={classNames(`${BLOCK}__table-wrapper`)}>
        <Table
          className={`${BLOCK}__table-inner-wrapper`}
          innerClassName={`${BLOCK}__table`}
          headerItems={getTableHeaders()}
          rowItems={getTableRows()}
          dataTest={BLOCK}
        />
      </div>
      {showDeleteBenchmark && (
        <DeleteModal
          title="peerbenchmark.delete.title"
          message={
            <>
              <FormattedMessage id="peerbenchmark.delete.subTitle" />
            </>
          }
          handleCloseModal={() => {
            setCurrentBenchmarkSelected(null);
            setShowDeleteBenchmark(false);
          }}
          deleteItem={deleteBenchmarkHandler}
          data-test="delete-modal-popup"
        />
      )}
      {showShareBenchmark && (
        <ShareToUsersModal
          setShowShare={setShowShareBenchmark}
          shareShortlist={() => {}}
          setShowSearchModal={() => {}}
          shareSearch={() => {}}
          shareCompare={() => {}}
          sharePeerBenchmark={sharePeerBenchmarkHandler}
          shareType={shareItemType.peerbenchmark}
        />
      )}
      {showEditBenchmark && (
        <EditModal
          title="peerbenchmark.edit.title"
          nameLabel="peer.benchmark.name"
          nameValue={
            peerBenchmarkState.editPeerBenchmarkData &&
            peerBenchmarkState.editPeerBenchmarkData.benchmarkTitle
              ? peerBenchmarkState.editPeerBenchmarkData.benchmarkTitle
              : ""
          }
          namePlaceholder="Enter Peer benchmark name"
          descriptionValue={
            peerBenchmarkState.editPeerBenchmarkData &&
            peerBenchmarkState.editPeerBenchmarkData.description
              ? peerBenchmarkState.editPeerBenchmarkData.description
              : ""
          }
          hasDescription={true}
          hasError={peerBenchmarkState.errorPeerEditRename}
          duplicateNameMessage="duplicate.benchmark.name"
          handleCloseModal={() => setShowEditBenchmark(false)}
          editItem={(name: string, description?: string) => {
            editPeerBenchmarkHandler(name, description);
          }}
        />
      )}
    </div>
  );
};

export default SavedPeerbenchmarksTable;
