import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useEffect, useState } from "react";
import { useTileContext } from "../tile.context";
import Popover from "components/shared/popover/popover";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";

type MetricValueDataObj = {
  metricName: string;
  metricValue: string | null;
  metricTooltip: AssociatedMetricTooltips[];
};

type FinalDataObj = {
  companyName: string;
  metrics: MetricValueDataObj[];
};

const WaterTileComponent = () => {
  const BLOCK = "water";
  const {
    response,
    dataset,
    isTableViewActive,
    metadata: { benchmarkMetadata, benchmarkType, isDetailedView },
  } = useTileContext();
  const [mappedData, setMappedData] = useState<any>();
  const [noDataToDisplay, setNoDataToDisplay] = useState<boolean>(true);
  const [showNoDataAvail, setShowNoDataAvail] = useState<boolean>(false);

  const getFormattedNumber = (num: string) => {
    const numberFormatter = new Intl.NumberFormat("en-US", {});
    let splitValue = num.split(".");
    let firstTwoDecimalValues = splitValue[1]
      ? splitValue[1].slice(0, 2)
      : "00"; //if there is no decimal point, just add 00 to ensure the code doesnt break
    const firstPart = numberFormatter.format(parseInt(splitValue[0]));

    if (firstTwoDecimalValues !== "00") {
      return `${firstPart}.${firstTwoDecimalValues}`;
    }
    return `${firstPart}`;
  };

  useEffect(() => {
    setNoDataToDisplay(true);
    setShowNoDataAvail(false);
    if (dataset && dataset.length > 0 && benchmarkMetadata) {
      let finalData: FinalDataObj[] = [];
      for (let i = 0; i < dataset.length; i++) {
        let metrics = dataset[i].metrics;
        let metricValues: MetricValueDataObj[] = [];
        metrics.map((metric: any) => {
          let finalValue: string | null;
          if (metric.metricValue !== null) {
            setNoDataToDisplay(false);
            finalValue = getFormattedNumber(metric.metricValue);
          } else {
            finalValue = null;
          }
          metricValues = [
            ...metricValues,
            {
              metricName: metric.metricName,
              metricValue: finalValue,
              metricTooltip: metric.metricTooltip,
            },
          ];
        });
        finalData = [
          ...finalData,
          {
            companyName: dataset[i].companyName,
            metrics: metricValues,
          },
        ];
      }
      setMappedData(finalData);
    }
  }, [isTableViewActive, isDetailedView, benchmarkType, dataset]);

  const renderCompanyViewRow = (metric: MetricValueDataObj, index: number) => {
    let finalValue: string;
    if (metric.metricValue === null) {
      finalValue = "*";
      if (!showNoDataAvail) setShowNoDataAvail(true);
    } else finalValue = metric.metricValue;

    return (
      <div
        key={`${index}__rcvr`}
        className={classNames(`${BLOCK}__companyView--row`, {
          [`${BLOCK}__companyView--row--tableView--topBorder`]:
            index === 0 && isTableViewActive,
          [`${BLOCK}__companyView--row--tableView`]: isTableViewActive,
        })}
      >
        <Popover
          displayText={
            <span
              className={classNames(
                `${BLOCK}__companyView--row--metricName`,
                {}
              )}
            >
              {metric.metricName}
            </span>
          }
          content={metric.metricTooltip ?? []}
          metricClassName={`${BLOCK}__companyView--row--metricName`}
        />
        <span
          className={classNames(`${BLOCK}__companyView--row--metricValue`, {})}
        >
          {finalValue}
        </span>
      </div>
    );
  };

  const renderPeerbenchmarkView = (
    metric: MetricValueDataObj,
    index: number
  ) => {
    let companyValue: string | null = metric.metricValue;
    let peersValue: string | null = mappedData[1].metrics[index].metricValue;
    let currentMetric: string = metric.metricName;

    if (companyValue === null) {
      companyValue = "*";
      if (!showNoDataAvail) setShowNoDataAvail(true);
    }

    if (peersValue === null) {
      peersValue = "*";
      if (!showNoDataAvail) setShowNoDataAvail(true);
    }

    return (
      <div
        key={`${index}__rpv`}
        className={classNames(`${BLOCK}__peerView--rowContainer`, {
          [`${BLOCK}__peerView--rowContainer--tableView`]: isTableViewActive,
        })}
      >
        <span
          className={classNames(
            `${BLOCK}__peerView--rowContainer--metricName`,
            {}
          )}
        >
          <Popover
            displayText={
              <span
                className={classNames(
                  `${BLOCK}__companyView--row--metricName`,
                  {}
                )}
              >
                {currentMetric}
              </span>
            }
            content={metric.metricTooltip ?? []}
            metricClassName={`${BLOCK}__companyView--row--metricName`}
          />
        </span>
        <div
          className={classNames(
            `${BLOCK}__peerView--rowContainer--valuesContainer`,
            {}
          )}
        >
          <span
            className={classNames(
              `${BLOCK}__peerView--rowContainer--value`,
              {}
            )}
          >
            {companyValue}
          </span>
          <span
            className={classNames(
              `${BLOCK}__peerView--rowContainer--value`,
              {}
            )}
          >
            {peersValue}
          </span>
        </div>
      </div>
    );
  };

  const renderPeerTableView = (data: FinalDataObj, index: number) => {
    const getFinalValue = (currentvalue: string | null) => {
      if (currentvalue === null) {
        if (!showNoDataAvail) setShowNoDataAvail(true);
        return "*";
      }
      return currentvalue;
    };
    let companyName: string = data.companyName;

    return (
      <div
        key={`${index}__rptv`}
        className={classNames(
          `${BLOCK}__peerView--rowContainer--tableView`,
          {}
        )}
      >
        <span
          className={classNames(
            `${BLOCK}__peerView--rowContainer--tableView--companyName`,
            {}
          )}
        >
          {companyName}
        </span>
        <div
          className={classNames(
            `${BLOCK}__peerView--rowContainer--tableView--valuesContainer`,
            {}
          )}
        >
          {data.metrics.map((metric: MetricValueDataObj, index: number) => (
            <span
              key={`${index}__v`}
              className={classNames(
                `${BLOCK}__peerView--rowContainer--tableView--value`,
                {}
              )}
            >
              {getFinalValue(metric.metricValue)}
            </span>
          ))}
        </div>
      </div>
    );
  };

  const renderHeadersPeerView = (
    headerName: string,
    index: number,
    tooltip?: AssociatedMetricTooltips[]
  ) => {
    return (
      <span
        key={`${index}__rhpv`}
        className={classNames(`${BLOCK}__peerView--header--companyName`, {
          [`${BLOCK}__peerView--header--companyName--tableView`]:
            isTableViewActive,
          [`${BLOCK}__peerView--header--companyName--detailedView`]:
            isDetailedView,
        })}
      >
        <Popover
          displayText={headerName}
          content={tooltip ?? []}
          metricClassName={classNames(`${BLOCK}__textHeader`, {
            [`${BLOCK}__textHeader--tableView`]: isTableViewActive,
          })}
        />
      </span>
    );
  };

  return (
    <div className={classNames(`${BLOCK}`, {})}>
      {!noDataToDisplay && mappedData ? (
        <div className={classNames(`${BLOCK}__container`, {})}>
          {benchmarkType === 4 &&
            mappedData[0].metrics.map(
              (metric: MetricValueDataObj, index: number) =>
                renderCompanyViewRow(metric, index)
            )}
          {(((benchmarkType === 5 || benchmarkType === 6) &&
            !isDetailedView &&
            !isTableViewActive) ||
            (benchmarkType === 6 && isTableViewActive)) && (
            <div className={classNames(`${BLOCK}__peerView--container`, {})}>
              <div
                className={classNames(`${BLOCK}__peerView--header`, {
                  [`${BLOCK}__peerView--header--tableView`]: isTableViewActive,
                })}
              >
                <div
                  className={classNames(`${BLOCK}__peerView--headerValues`, {})}
                >
                  {mappedData.map((data: FinalDataObj, index: number) =>
                    renderHeadersPeerView(data.companyName, index)
                  )}
                </div>
              </div>
              <div
                className={classNames(
                  `${BLOCK}__peerView--valuesContainer`,
                  {}
                )}
              >
                {mappedData[0].metrics.map(
                  (metric: MetricValueDataObj, index: number) =>
                    renderPeerbenchmarkView(metric, index)
                )}
              </div>
            </div>
          )}
          {benchmarkType === 5 && (isTableViewActive || isDetailedView) && (
            <div className={classNames(`${BLOCK}__peerView--container`, {})}>
              <div
                className={classNames(`${BLOCK}__peerView--header`, {
                  [`${BLOCK}__peerView--header--tableView`]: isTableViewActive,
                })}
              >
                <div
                  className={classNames(
                    `${BLOCK}__peerView--headerValues--tableView`,
                    {}
                  )}
                >
                  {mappedData[0].metrics.map(
                    (metric: MetricValueDataObj, index: number) =>
                      renderHeadersPeerView(
                        metric.metricName,
                        index,
                        metric.metricTooltip
                      )
                  )}
                </div>
              </div>
              <div
                className={classNames(
                  `${BLOCK}__peerView--valuesContainer--tableView`,
                  {}
                )}
              >
                {mappedData.map((data: FinalDataObj, index: number) =>
                  renderPeerTableView(data, index)
                )}
              </div>
            </div>
          )}
          {showNoDataAvail && (
            <span className={`${BLOCK}__noData--footer`}>
              <FormattedMessage id="no.data.available" />
            </span>
          )}
        </div>
      ) : (
        <span className={classNames(`${BLOCK}__noData`, {})}>
          <FormattedMessage id="target.tile.no.data" />
        </span>
      )}
    </div>
  );
};

export default WaterTileComponent;
