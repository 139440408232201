import React, { useState, useEffect, useRef } from "react";
import FormattedMessage from "../formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { addToastMessage } from "services/commons.service";
import {
  deleteNote,
  noteDeleteSuccess,
  noteSaveSuccess,
  updateNote,
} from "services/note/note.service";
import Icon from "../icon/icon";
import { saveNote } from "services/note/note.service";
type props = {
  setShowModal: (value: boolean) => void;
  setCommentBlue: Function;
  headerNote: string;
  inputNote: string;
  referenceId: number;
  noteExist: boolean;
  noteId?: number;
  commentNoteReferenceType: number;
  setShowItems?: Function;
};

const Note = ({
  setShowModal,
  setCommentBlue,
  headerNote,
  inputNote,
  referenceId,
  noteId,
  noteExist,
  commentNoteReferenceType,
  setShowItems,
}: props) => {
  const BLOCK = "Note";
  const [counts, setCounts] = useState({ note: 0 });
  const [disabled, setDisabled] = useState(true);
  const noteInputRef = useRef<HTMLTextAreaElement>(null);
  const [values, setValues] = useState({ note: "" });
  const dispatch = useDispatch();
  const noteState = useSelector((state: RootStore) => state.note);

  useEffect(() => {
    if (noteState.NoteFetch) {
      setValues({
        ...values,
        note: noteState.NoteFetch.commentNotes
          ? noteState.NoteFetch.commentNotes
          : "",
      });
      setCounts({
        ...counts,
        note: noteState.NoteFetch.commentNotes
          ? noteState.NoteFetch.commentNotes.length
          : 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteState.NoteFetch]);

  useEffect(() => {
    if (noteState.saveSuccess) {
      if (setShowItems) {
        setShowItems([]);
      }
      dispatch(noteSaveSuccess(false));
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="note.edit.saved" />,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteState.saveSuccess, dispatch, setShowItems]);

  useEffect(() => {
    if (noteState.deleteSuccess) {
      if (setShowItems) {
        setShowItems([]);
      }
      dispatch(noteDeleteSuccess(false));
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="note.edit.delete" />,
        })
      );
      //setCommentBlue((state:any)=>[...state,{id:referenceId}]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteState.deleteSuccess, dispatch, setShowItems]);

  const trimStartFunc = (value: any) => {
    if (!value) return value;
    return value.replace(/^\s+/g, "");
  };
  const onChangeHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCounts({
      ...counts,
      [event.target.name]: trimStartFunc(event.target.value).length,
    });
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    if (
      noteState.NoteFetch.commentNotes === noteInputRef.current!.value.trim() ||
      noteInputRef.current!.value.trim().length === 0
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      saveNoteHandler();
    }
  };

  const saveNoteHandler = () => {
    if (trimStartFunc(noteInputRef.current!.value).trim().length === 0) {
      return;
    }
    if (!noteExist) {
      dispatch(
        saveNote({
          referenceId: referenceId,
          commentNotes: trimStartFunc(values.note).trim(),
          commentNoteReferenceType: commentNoteReferenceType,
        })
      );
    } else {
      dispatch(
        updateNote({
          commentNoteId: noteId,
          referenceId: referenceId,
          commentNotes: trimStartFunc(values.note).trim(),
          commentNoteReferenceType: commentNoteReferenceType,
        })
      );
    }
    // editNote(noteInputRef.current!.value.trim());
  };

  const deleteNoteHandler = () => {
    dispatch(
      deleteNote({
        commentId: noteId,
      })
    );
  };

  return (
    <>
      <div
        className={`${BLOCK}__ShortlistItems ${BLOCK}__saveNote__ShortlistItems`}
        data-test="note-component"
      >
        <div className={`ShortlistItemsActions`}>
          <span className={`${BLOCK}__nameShortlistItems`}>
            <FormattedMessage id={inputNote} />
          </span>
          <div className="ShortlistItemsButtons">
            {noteExist && (
              <button
                className={`delete-note-button-ShortlistItems`}
                onClick={deleteNoteHandler}
              >
                <div>
                  <FormattedMessage id="note.delete.button" />
                </div>
              </button>
            )}
            <button
              className={`shortlistItemsCancel`}
              onClick={() => {
                if (setShowItems)
                  setShowItems((show: [{ id: number; show: boolean }]) => {
                    setShowItems([]);
                  });
              }}
            >
              <FormattedMessage id="shortlist.save.button.cancel" />
            </button>
            <button
              onClick={saveNoteHandler}
              className={classNames(
                `${BLOCK}__continue SaveNote__btn__ShortlistItems`,
                {
                  "SaveNote__btn__ShortlistItems-disabled": disabled,
                }
              )}
            >
              <Icon name="plus" height={16} width={14} />
              <FormattedMessage
                id={
                  !noteExist ? "note.add.button" : "shortlist.save.button.save"
                }
              />
            </button>
          </div>
        </div>
        <span>
          <textarea
            ref={noteInputRef}
            maxLength={500}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={values.note}
            placeholder="Enter note"
            className={classNames(`${BLOCK}__noteShortlistItems`)}
            name="note"
          />
        </span>
        <span className={`ShortlistItemsCount`}>
          {counts.note}
          <FormattedMessage id="note.items.save.character" />
        </span>
      </div>
    </>
  );
};

export default Note;
