import Modal from "../modal/modal";
import FormattedMessage from "../formatted-message/formatted-message";
import classNames from "classnames";
import { APP_SETTING } from "utils/constants";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { useEffect } from "react";

type props = {
  title: string;
  namePlaceholder: string;
  handleCloseModal: () => void;
  exportDiclaimer: Function;
};

const DisclaimerModal = ({
  title,
  namePlaceholder,
  handleCloseModal,
  exportDiclaimer,
}: props) => {
  const BLOCK = "DisclaimerModal";
  const disclaimerHandler = () => {
    exportDiclaimer();
    handleCloseModal();
  };
  const commonsState = useSelector((state: RootStore) => state.commons);
  useEffect(() => {
    if (
      !commonsState.dashboardExportDisclaimer[
        APP_SETTING.DashboardExportDisclaimer
      ]
    )
      handleCloseModal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      show={
        commonsState.dashboardExportDisclaimer[
          APP_SETTING.DashboardExportDisclaimer
        ] !== ""
      }
      header={title}
      handleClose={handleCloseModal}
      config={false}
      data-testid="disclaimer-modal"
    >
      <div className={`${BLOCK} ${BLOCK}--disclaimer`}>
        <span className={classNames(`${BLOCK}__name--disclaimer`)}>
          <FormattedMessage
            id={namePlaceholder}
            values={{
              disclaimerMessage:
                commonsState.dashboardExportDisclaimer[
                  APP_SETTING.DashboardExportDisclaimer
                ],
            }}
          />
        </span>

        <div className={`${BLOCK}__options ${BLOCK}__options--disclaimer`}>
          <button
            data-testid="cancel-btn"
            className={`primary-outline Disclaimer__btn`}
            onClick={handleCloseModal}
          >
            <FormattedMessage id="shortlist.save.button.cancel" />
          </button>
          <button
            data-testid="ok-btn"
            onClick={disclaimerHandler}
            className={classNames(`${BLOCK}__continue`)}
          >
            <FormattedMessage id="upload.files.ok.button" />
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default DisclaimerModal;
