import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../components/shared/icon/icon";
import FormattedMessage from "../../components/shared/formatted-message/formatted-message";
import history from "../../utils/history";
import Tooltip, {
  TooltipPosition,
} from "../../components/shared/tooltip/tooltip";
import { showTooltip } from "services/commons.service";
import {
  deleteShortlistPop,
  renameShortlistPop,
  shareShortlistPop,
} from "../../services/saved-shortlist/saved-shortlist.service";
import moment from "moment";
import classNames from "classnames";
import { User } from "services/commons.model";
import { RootStore } from "services/store.service";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";

type Props = {
  block: string;
  shortlist: any;
};

const SavedShortListTableRow = ({ block, shortlist }: Props) => {
  const [showBold, setShowBold] = useState(true);

  const dispatch = useDispatch();
  const cellPadding = 34;
  const offset = 8;
  const tooltipContainer = useSelector(
    (store: RootStore) => store.commons.toolTip
  );
  const sortedSharedWith = shortlist.sharedWith
    .map((sharedUser: User) => `${sharedUser.lastName} ${sharedUser.firstName}`)
    .sort();

  useEffect(() => {
    let timeout = setTimeout(() => {
      setShowBold(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  const handleMouseEnterItems = (
    e: any,
    children: any,
    className: string | null = null,
    position: TooltipPosition = TooltipPosition.top,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("div");
    if (!element) {
      return;
    }
    dispatch(
      showTooltip({
        children: children,
        position: position,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeaveItems = (e: any) => {
    if (!tooltipContainer.isOverTooltip) {
      dispatch(
        showTooltip({
          children: null,
          position: null,
          customPosition: null,
          elementDimensions: null,
          className: null,
          width: null,
          executeMouseLeaveEvent: null,
          isOverTooltip: null,
        })
      );
    }
  };

  const handleCompactTooltip = (
    e: any,
    type?: string,
    tooltipContent?: JSX.Element,
    tooltipPosition?: TooltipPosition
  ) => {
    const tdElement = e.target.closest("td");
    const valueElement = e.target.closest(`.${block}__hover-tooltip`);
    const defaultArrowPosition = {
      top: 10,
    };
    /*istanbul ignore next*/
    if (!valueElement && !tdElement) {
      return;
    }

    if (e.type === "mouseleave" || e.type === "click") {
      dispatch(
        showTooltip({
          children: null,
          customPosition: null,
          position: null,
          arrowPosition: null,
          elementDimensions: null,
        })
      );

      return;
    }

    const count = tdElement.querySelector(`.${block}__shared-with-count`);
    const countWidth = count ? count.getBoundingClientRect().width : 0;
    const tdDimensions = tdElement.getBoundingClientRect();
    const valueDimensions = valueElement.getBoundingClientRect();

    const hasOverflowText =
      tdDimensions.width - cellPadding < valueDimensions.width + countWidth;

    if (hasOverflowText) {
      let tooltipState: any = {
        children: tooltipContent,
        position: tooltipPosition,
        customPosition: true,
        elementDimensions: valueDimensions,
      };

      if (type === "name") {
        tooltipState = {
          ...tooltipState,
          position: TooltipPosition.top,
        };
      } else {
        tooltipState = {
          ...tooltipState,
          position: TooltipPosition.topRight,
          arrowPosition: {
            top: `${defaultArrowPosition.top + offset}px`,
          },
        };
      }

      dispatch(showTooltip(tooltipState));
    }
  };

  const getSharedWith = () => {
    return (
      <>
        <span
          className={`${block}__shared-with-main ${block}__hover-tooltip`}
          onMouseOver={(e) =>
            handleMouseEnter(
              e,
              "span",
              <>{sortedSharedWith[0]}</>,
              "",
              dispatch,
              null,
              MainTooltipPosition.TopRight
            )
          }
          onMouseLeave={(e) => handleMouseLeave(e, dispatch, false)}
          onClick={(e) => handleCompactTooltip(e)}
          data-testid="shared-with"
        >
          {sortedSharedWith[0]}
        </span>
        {sortedSharedWith.length - 1 > 0 ? (
          <div
            className={`${block}__shared-with-count`}
            onMouseEnter={(e) =>
              handleMouseEnter(
                e,
                "div",
                <div className={`${block}__shared-with-count-tooltip`}>
                  {sortedSharedWith.slice(1).join("; ")}
                </div>,
                `${block}__shared-with-count-tooltip`,
                dispatch,
                null,
                MainTooltipPosition.TopMiddle
              )
            }
            onMouseLeave={(e) => handleMouseLeave(e, dispatch, false)}
          >
            &nbsp;{`+${sortedSharedWith.length - 1}`}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <tr
      className={classNames(`${block}__link`, {
        [`${block}__newItem`]:
          /*istanbul ignore next*/
          shortlist.lastAccessedDate === null && shortlist.sharedBy && showBold,
      })}
    >
      <td
        className={`${block}__name`}
        onClick={() =>
          history.push("/search/shortlist", {
            shortlistId: shortlist.shortlistId,
          })
        }
      >
        <span
          /*istanbul ignore next*/
          className={`${block}__name-text ${block}__hover-tooltip`}
          onMouseOver={(e) =>
            handleCompactTooltip(
              e,
              "name",
              <>{shortlist.shortlistName}</>,
              TooltipPosition.top
            )
          }
          onMouseLeave={(e) => handleCompactTooltip(e, "name")}
          onClick={(e) => handleCompactTooltip(e)}
          data-test="name-button"
          data-testid="name"
        >
          {shortlist.shortlistName}
        </span>
      </td>
      <td
        data-test="table-createdDate"
        onClick={() =>
          history.push("/search/shortlist", {
            shortlistId: shortlist.shortlistId,
          })
        }
      >
        {moment(shortlist.createdDate).format("YYYY-MM-DD")}
      </td>
      <td
        data-test="table-lastupdatedDate"
        onClick={() =>
          history.push("/search/shortlist", {
            shortlistId: shortlist.shortlistId,
          })
        }
      >
        {moment(shortlist.lastUpdatedDate).format("YYYY-MM-DD")}
      </td>
      <td
        data-test="table-items"
        onClick={() =>
          history.push("/search/shortlist", {
            shortlistId: shortlist.shortlistId,
          })
        }
      >
        <div className={`${block}__items`}>
          <div
            className={`${block}__doc-icon`}
            data-testid="doc-icon"
            onMouseEnter={(e) => {
              handleMouseEnterItems(
                e,
                <>
                  <div style={{ textAlign: "left", fontWeight: 600 }}>
                    <FormattedMessage id="saved-shortlist.tooltip" />
                  </div>
                  <div style={{ textAlign: "left" }}>
                    <FormattedMessage id="saved-shortlist.tooltip.header" />
                  </div>
                  {shortlist.shortlistItems?.map((items: any, index: any) => (
                    <div
                      style={{ textAlign: "left" }}
                      key={`${index}__body_item`}
                    >
                      <li key={`${index}__body_list_item`}>
                        {items.companyName}
                      </li>
                    </div>
                  ))}
                </>,
                `${block}__doc-icon--tooltip`
              );
            }}
            onMouseLeave={handleMouseLeaveItems}
          >
            <Icon name="documents2" height={18} width={18} />
          </div>
          <div className={`${block}__items-text`}>
            {" "}
            {shortlist.shortlistItems?.length}{" "}
          </div>
        </div>
      </td>
      <td
        className={`${block}__shared-by`}
        onClick={() =>
          history.push("/search/shortlist", {
            shortlistId: shortlist.shortlistId,
          })
        }
      >
        {shortlist.sharedBy &&
        shortlist.sharedBy.firstName &&
        shortlist.sharedBy.lastName ? (
          <span
            /*istanbul ignore next*/
            className={`${block}__hover-tooltip`}
            onMouseOver={(e) =>
              handleCompactTooltip(
                e,
                "sharedBy",
                <>{`${shortlist.sharedBy.lastName} ${shortlist.sharedBy.firstName}`}</>,
                TooltipPosition.right
              )
            }
            onMouseLeave={(e) => handleCompactTooltip(e, "sharedBy")}
            onClick={(e) => handleCompactTooltip(e)}
            data-testid="shared-by"
            data-test="sharedby-item"
          >
            {`${shortlist.sharedBy.lastName} ${shortlist.sharedBy.firstName}`}
          </span>
        ) : (
          <Icon name="chevron-minus" width={20} height={20} />
        )}
      </td>
      <td
        className={`${block}__shared-with`}
        onClick={() =>
          history.push("/search/shortlist", {
            shortlistId: shortlist.shortlistId,
          })
        }
      >
        {sortedSharedWith.length > 0 ? (
          getSharedWith()
        ) : (
          <Icon name="chevron-minus" width={20} height={20} />
        )}
      </td>
      <td
        className={`${block}__shared-date`}
        onClick={() =>
          history.push("/search/shortlist", {
            shortlistId: shortlist.shortlistId,
          })
        }
      >
        {shortlist.sharedDate ? (
          moment(shortlist.sharedDate).format("YYYY-MM-DD")
        ) : (
          <Icon name="chevron-minus" width={20} height={20} />
        )}
      </td>
      <td className={`${block}__outside-td`}>
        <div className={`${block}__icons`}>
          <span
            className={`${block}__share`}
            data-test="share-icon"
            onClick={() => {
              dispatch(shareShortlistPop(shortlist));
            }}
          >
            <Tooltip position={TooltipPosition.top}>
              <FormattedMessage id="saved-shortlist.share" />
            </Tooltip>
            <Icon name="share" height={24} width={24} />
          </span>
          <span
            className={`${block}__edit`}
            data-test="edit-icon"
            onClick={() => {
              dispatch(renameShortlistPop(shortlist));
            }}
          >
            <Tooltip position={TooltipPosition.top}>
              <FormattedMessage id="saved-shortlist.edit" />
            </Tooltip>
            <Icon name="pencil" height={24} width={24} />
          </span>
          <span
            className={`${block}__delete`}
            data-test="delete-icon"
            onClick={() => {
              dispatch(deleteShortlistPop(shortlist));
            }}
          >
            <Tooltip position={TooltipPosition.top}>
              <FormattedMessage id="saved-shortlist.delete" />
            </Tooltip>
            <Icon name="remove1" height={24} width={24} />
          </span>
        </div>
      </td>
    </tr>
  );
};

export default SavedShortListTableRow;
