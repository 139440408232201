import EnergyUndertaken from "components/visualizations/charts/energy-undertaken.chart";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import {
  getTabularMetrics,
  mapTableChart,
  useTileContext,
} from "../tile.context";
import TableChart from "components/visualizations/charts/table-chart";

const EnergyRelatedActivitiesUndertaken = () => {
  const {
    dataset,
    metadata,
    associatedMetrics,
    associatedMetricsDisplayNames,
    metadata: {
      benchmarkMetadata,
      benchmarkType,
      isDetailedView,
      benchmarkTileType,
      associatedMetricsTooltips,
    },
    isTableViewActive,
  } = useTileContext();

  const mapTabularChartData = () => {
    let metrics = getTabularMetrics(
      associatedMetricsDisplayNames,
      associatedMetrics,
      associatedMetricsTooltips,
      dataset
    );
    const cleanedData = dataset.reduce((data: any[], current: InsightData) => {
      let currentGroup: any = {
        globalCompanyId: current.globalCompanyId,
        companyName: current.companyName,
      };
      current.metrics.forEach((m: InsightMetricData) => {
        let metricProp =
          m.metricKey?.charAt(0).toUpperCase() + m.metricKey?.slice(1);
        currentGroup = { ...currentGroup, [metricProp]: m.metricValue };
      });

      return [
        ...data,
        {
          ...currentGroup,
        },
      ];
    }, []);

    return {
      metrics: metrics,
      data: cleanedData,
      tileType: benchmarkTileType,
      tileView: benchmarkType,
      isDetailedView: isDetailedView,
      colors: benchmarkMetadata.colorCodes,
    };
  };

  return isTableViewActive ? (
    <TableChart
      data={mapTableChart(dataset, metadata, false, true)}
      currentInsightView={benchmarkType}
      leftAlignment={true}
    />
  ) : (
    <EnergyUndertaken data={mapTabularChartData()} />
  );
};

export default EnergyRelatedActivitiesUndertaken;
