import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useClickOutside } from "utils/functions";
import Icon from "../icon/icon";
type Props = {
  parentBlock: string;
  data: any[];
  selected: any;
  keys: { displayName: string; id: string };
  onChange: any;
  className?: any;
  onScroll?: boolean;
};

const Dropdown = ({
  parentBlock,
  data,
  selected,
  keys,
  onChange,
  className,
  onScroll,
}: Props) => {
  const BLOCK = "shared-dropdown";
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef<any>(null);
  useClickOutside(dropdownRef, () => setShowOptions(false));
  const elemDim = dropdownRef?.current?.getBoundingClientRect();

  useEffect(() => {
    if (onScroll) setShowOptions(false);
  }, [onScroll]);
  
  return (
    <div
      ref={dropdownRef}
      className={classNames(`${parentBlock}__dropdown ${BLOCK}`, {
        [`${BLOCK}-dropdown-quater`]: className === "dropdown-quater",
      })}
      data-testid="sharedDropdown"
    >
      <span
        onClick={() => setShowOptions(!showOptions)}
        className={`${BLOCK}__selected`}
        data-testid="showOptionsBtn"
      >
        {selected[keys.displayName]}
        <Icon
          name={
            className === "dropdown-quater"
              ? showOptions
                ? "chevron-up-blue"
                : "chevron-down-blue"
              : "chevron-down"
          }
          width={className === "dropdown-quater" ? 16 : 20}
          height={className === "dropdown-quater" ? 16 : 20}
        />
      </span>
      {showOptions ? (
        <div
          style={
            className === "role"
              ? {
                  top: `${elemDim.bottom ? elemDim.bottom : 0}px`,
                  bottom: "unset",
                  left: `${
                    elemDim.left ? elemDim.left - elemDim.width * 0.9 : 0
                  }px`,
                }
              : {}
          }
          className={`${BLOCK}__options ${BLOCK}__${className}`}
        >
          {data.map((option, i) => (
            <div
              key={`dropdown-option-${i}`}
              onClick={() => {
                setShowOptions(false);
                onChange(option);
              }}
              className={classNames(`${BLOCK}__option`, {
                [`${BLOCK}__option--selected`]:
                  option[keys.id] === selected[keys.id],
              })}
              data-testid="dropdown-option"
            >
              {option[keys.displayName]}
              {option[keys.id] === selected[keys.id] ? (
                <Icon name="tick" width={20} height={20} />
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
