import Button from "components/shared/button/button";
import Checkbox from "components/shared/checkbox/checkbox";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Modal from "components/shared/modal/modal";
import Textfield from "components/shared/textfield/textfield";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { useTagContext } from "components/tags/tag.context";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { viewSpecifiedPages } from "services/saved-comparisons/saved-comparisons.service";
import { EsgSearchResultsReport } from "services/search/search.results.model";
import { createShortlist } from "services/shortlist/shortlist.service";
import { RootStore } from "services/store.service";
import {
  comparisonPath,
  pageSelectorPlaceholder,
  pageSelectorSource,
} from "utils/constants";

type Props = {
  BLOCK: string;
  fromComparison: boolean;
  report: Partial<EsgSearchResultsReport>;
};

const PageSelector = ({ BLOCK, fromComparison, report }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showPageSelectorModal, setShowPageSelectorModal] =
    useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState<string>(
    report.esgReport &&
      report.esgReport.pageSelection &&
      report.esgReport.reportType !== pageSelectorSource.SHORTLIST
      ? report.esgReport.pageSelection
      : ""
  );
  const [viewAllPages, setViewAllPages] = useState<boolean>(
    !(
      report.esgReport &&
      report.esgReport.pageSelection &&
      report.esgReport.reportType !== pageSelectorSource.SHORTLIST
    )
  );
  const [validValue, setValidValue] = useState<boolean>(true);
  const shortlistResults = useSelector((state: RootStore) => state.shortlist);
  const searchResults = useSelector((state: RootStore) => state.searchResults);

  const { setCurrentTagsList, setShowTagPanel } = useTagContext();
  useEffect(() => {
    if (
      report.esgReport?.pageSelection &&
      report.esgReport.reportType !== pageSelectorSource.SHORTLIST
    )
      /* istanbul ignore next */
      setSelectedPages(report.esgReport.pageSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.esgReport?.pageSelection]);

  const checkSelectedPages = (selectedPagesValue: string) => {
    setValidValue(
      new RegExp("^(\\d+\\-\\d+|\\d+)(,\\d+\\-\\d+|,\\d+)*$").test(
        selectedPagesValue
      ) || selectedPagesValue === ""
    );
    setSelectedPages(selectedPagesValue);
  };

  const submitPages = () => {
    if (fromComparison) {
      const comparisonId = parseInt(
        location.pathname.replace(`${comparisonPath}/`, "")
      );
      dispatch(
        viewSpecifiedPages(
          report,
          selectedPages !== "" ? selectedPages : null,
          comparisonId
        )
      );
    } else {
      dispatch(
        createShortlist(
          {
            sectionItem: {
              filingId: null,
              sectionId: null,
            },
            shortlistItemType: 0,
            displayOrder: 0,
            shortlistId:
              shortlistResults.results !== null &&
              shortlistResults.results.shortlistId !== 0
                ? shortlistResults.results.shortlistId
                : 0,
            esgReport: {
              ...report,
              reportType: pageSelectorSource.SHORTLIST,
              pageSelection: selectedPages !== "" ? selectedPages : null,
              baseReportId: report.reportId,
            },
            reportId: report.reportId,
            contentKeywords:
              report && report.keywords && report.keywords.length > 0
                ? report.keywords[0].keywordTextContent
                : searchResults.searchPayloadESG.contentText,
          },
          <FormattedMessage id="document-viewer.page-selector.shortlist.success" />
        )
      );
    }

    setSelectedPages("");
    setShowPageSelectorModal(false);
  };

  const defaultValue = () => {
    setSelectedPages("");
    setValidValue(true);
    setShowPageSelectorModal(false);
  };

  return (
    <>
      <div className={`${BLOCK}__action ${BLOCK}__action--left-tooltip`} data-testid={`${BLOCK}__action`}>
        <Button
          id="open-modal-button"
          dataTest="open-modal-button"
          className=""
          iconName="pages"
          onClick={() => {
            if (fromComparison) {
              setShowTagPanel(false);
              setCurrentTagsList([]);
            }
            setShowPageSelectorModal(true);
          }}
        />
        <Tooltip position={TooltipPosition.left}>
          <FormattedMessage
            id={`document-viewer.page-selector.tooltip.${
              fromComparison ? "view" : "add"
            }`}
          />
        </Tooltip>
      </div>
      <Modal
        show={showPageSelectorModal}
        header={`document-viewer.page-selector.title${
          fromComparison ? "" : ".shortlist"
        }`}
        handleClose={() => {
          defaultValue();
        }}
        config={false}
      >
        <div className={`${BLOCK}__page-selector`} data-testid={`${BLOCK}__page-selector`}>
          <Textfield
            id="selected-pages-value"
            disabled={fromComparison ? viewAllPages : false}
            label="document-viewer.page-selector.label"
            value={selectedPages}
            onChange={(value: any) => checkSelectedPages(value.target.value)}
            placeholder={pageSelectorPlaceholder}
            assertiveText={`document-viewer.page-selector.assertive-text${
              validValue
                ? fromComparison
                  ? selectedPages === "" && viewAllPages == false
                    ? ".allPages"
                    : ""
                  : ".shortlist"
                : ".error"
            }`}
            error={!validValue}
          />

          {fromComparison ? (
            <div className={`${BLOCK}__checkbox`} data-testid={`${BLOCK}__checkbox`}>
              <Checkbox
                label={
                  <FormattedMessage id="document-viewer.page-selector.checkbox-label" />
                }
                value={viewAllPages}
                onChange={() => {
                  if (!viewAllPages) checkSelectedPages("");
                  setViewAllPages(!viewAllPages);
                }}
              />
            </div>
          ) : null}

          <div className={`${BLOCK}__options`} data-testid={`${BLOCK}__options`}>
            <Button
              className={`button-secondary`}
              onClick={defaultValue}
              formattedText="shortlist.edit.button.cancel"
              dataTest="cancel-btn"
            />

            <Button
              className={`button-primary`}
              onClick={submitPages}
              disabled={
                fromComparison
                  ? !viewAllPages && (!validValue || selectedPages === "")
                  : !validValue || selectedPages === ""
              }
              formattedText="compare.button"
              dataTest="select-pages-button"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PageSelector;
