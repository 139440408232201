import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../components/shared/icon/icon";
import FormattedMessage from "../../components/shared/formatted-message/formatted-message";
import Tooltip, {
  TooltipPosition,
} from "../../components/shared/tooltip/tooltip";
import history from "../../utils/history";
import moment from "moment";
import { Search } from "../../services/searchlist/searchlist.model";
import {
  currentShareSearchItem,
  shareSearchList,
  deleteSearch,
  saveSearch,
  savedSearchResult,
  loadSavedSearchList,
  setDeleteSavedSearchPopUp,
  setEditSavedSearchPopUp,
  setRefreshSavedSearchesList,
  loadSavedSearchListSort,
  addNewTopic,
  editTopic,
  resetTopic,
} from "services/searchlist/searchlist.service";
import { RootStore } from "services/store.service";
import EditSearch from "../searchlist-actions/edit-search";
import { addToastMessage, showTooltip } from "services/commons.service";
import { Role, User } from "services/commons.model";
import classNames from "classnames";
import {
  SORT_ORDERS,
  DEFAULT_SAVED_SEARCHES_PAYLOAD,
  shareItemType,
  Roles,
} from "utils/constants";
import DeleteModal from "components/shared/delete-modal/delete-modal";
import ShareToUsersModal from "components/shared/share-to-users-modal/share-to-users-modal";
import PublishSearch from "components/searchlist-actions/publish-search";
import UnpublishSearch from "components/searchlist-actions/unpublish-search";
import {
  setCurrentSearchItem,
  unpublishSearchItem,
} from "services/published-searchlist/published-searchlist.service";
import { PublishedSearchPayload } from "services/published-searchlist/published-searchlist.model";
import { publishSearchType } from "../../utils/constants";
import { useLocation } from "react-router";
import { savedSearchesPath } from "../../utils/constants";
import Table, { TableRowItem } from "components/shared/table/table";
import { resetFilter, setContentValue } from "services/search/filters.service";
import { resetSearchResult } from "services/search/search.results.service";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";

const SavedSearchesTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const savedSearchListState = useSelector(
    (state: RootStore) => state.searchlist
  );
  const commonsState = useSelector((state: RootStore) => state.commons);
  const currentUser = commonsState.currentUser;
  const isSuperAdmin: boolean = currentUser?.adminRole?.adminRoleId === Roles.superAdmin;
  const BLOCK = "SavedSearchTable";
  const [sortInfo, setSortInfo] = useState<any>({
    sortOrder: null,
    sortBy: null,
  });
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  const [showEditSearch, setShowEditSearch] = useState(false);
  const [showDeleteSearch, setShowDeleteSearch] = useState(false);
  const [showBold, setShowBold] = useState(true);
  const [publishSearch, setPublishSearch] = useState<boolean>(false);
  const [unpublishSearch, setUnpublishSearch] = useState<boolean>(false);
  const [topicPayload, setTopicPayload] = useState<any>({});

  useEffect(() => {
    if (
      savedSearchListState.newTopic &&
      location.pathname === savedSearchesPath
    ) {
      let payload = {
        savedSearchId: topicPayload.savedSearchId,
        name: topicPayload.name,
        description: topicPayload.description,
        searchCriteria: {},
        topic: {
          topicId: savedSearchListState.newTopic.topicId,
        },
        hasPublished: true,
        type: "publish",
        location: publishSearchType.savedSearches,
      };

      dispatch(saveSearch(payload));
      dispatch(resetTopic());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSearchListState.newTopic]);

  const resetSearch = () => {
    dispatch(setContentValue(""));
    dispatch(resetSearchResult());
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      setShowBold(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch]);

  useEffect(() => {
    if (
      savedSearchListState.refreshSavedSearchesList &&
      !savedSearchListState.deleteSavedSearchPopUP &&
      !savedSearchListState.editSavedSearchPopUp
    ) {
      dispatch(loadSavedSearchList(DEFAULT_SAVED_SEARCHES_PAYLOAD));
      dispatch(setRefreshSavedSearchesList(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSearchListState.refreshSavedSearchesList]);

  useEffect(() => {
    setShowSearchModal(savedSearchListState.currentShareSearch !== null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSearchListState.currentShareSearch]);

  let savedSearch = savedSearchListState.savedSearchList.savedSearches;

  const getAutoDelDays = (lastRemainingDays: number) => {
    let delayDaysText = "";

    if (lastRemainingDays > 1) {
      delayDaysText = `${lastRemainingDays} days left`;
    } else if (lastRemainingDays === 1) {
      delayDaysText = `${lastRemainingDays} day left`;
    } else if (lastRemainingDays !== null) {
      delayDaysText = `Less than a day left`;
    }

    return delayDaysText;
  };

  const onSort = (sortId: string) => {
    let newSortOrder = sortInfo.sortOrder;

    if (sortId === sortInfo.sortBy) {
      newSortOrder =
        sortInfo.sortOrder === SORT_ORDERS.DESC
          ? SORT_ORDERS.ASC
          : SORT_ORDERS.DESC;
    } else {
      newSortOrder = SORT_ORDERS.DESC;
    }

    const payload = {
      ...DEFAULT_SAVED_SEARCHES_PAYLOAD,
      sortBy: sortId,
      sortOrder: newSortOrder,
    };
    setSortInfo({ sortBy: sortId, sortOrder: newSortOrder });
    dispatch(loadSavedSearchListSort(payload));
  };

  const shareSearchHandler = (payload: Search) => {
    dispatch(shareSearchList(payload));
    setShowSearchModal(false);
  };

  const onEditSearchHandler = (name: string, description?: string) => {
    let payload = {
      savedSearchId: savedSearchListState.savedSearch.savedSearchId,
      name: name,
      description: description,
      searchCriteria: {},
      type: "edit",
    };

    dispatch(saveSearch(payload));
  };

  const onDeleteSearchHandler = (savedSearchId: number) => {
    let payload = {
      savedSearchId: savedSearchId,
    };
    dispatch(deleteSearch(payload));
    dispatch(
      addToastMessage({
        description: <FormattedMessage id="delete.search-query.success" />,
      })
    );
    setShowDeleteSearch(false);
    dispatch(setDeleteSavedSearchPopUp(false));
  };

  const onUnpublishSearchHandler = (payload: {
    payload: PublishedSearchPayload;
    type: string;
  }) => {
    dispatch(unpublishSearchItem(payload));
    setUnpublishSearch(false);
  };

  const publishSearchHandler = (value: any, selectedTopic: any) => {
    let payload: any;
    if (selectedTopic && selectedTopic.topicId) {
      payload = {
        savedSearchId: savedSearchListState.savedSearch.savedSearchId,
        name: value.name,
        description: value.description,
        searchCriteria: {},
        topic: {
          topicId: selectedTopic.topicId,
        },
        hasPublished: true,
        type: "publish",
        location: publishSearchType.savedSearches,
      };
      setTopicPayload(payload);
      let EditPayload = {
        topicId: selectedTopic.topicId,
        topicName: selectedTopic.topicName,
        topicDescription: selectedTopic.topicDescription,
      };
      dispatch(editTopic(EditPayload));
    } else {
      payload = {
        savedSearchId: savedSearchListState.savedSearch.savedSearchId,
        name: value.name,
        description: value.description,
        searchCriteria: {},
        hasPublished: true,
        type: "publish",
        location: publishSearchType.savedSearches,
      };
      setTopicPayload(payload);
      let newTopicPayload = {
        topicName: selectedTopic.topicName,
        topicDescription: selectedTopic.topicDescription,
      };
      dispatch(addNewTopic(newTopicPayload));
    }
  };

  const getTableHeaders = () => {
    const tableHeaders = [
      {
        text: "my-searches-name",
        isFormattedText: true,
        sortId: "name",
        role: false,
      },
      {
        text: "my-searches-description",
        isFormattedText: true,
        role: false,
      },
      {
        text: "my-searches-date-created",
        isFormattedText: true,
        role: false,
      },
      {
        text: "my-searches-last-executed",
        isFormattedText: true,
        sortId: "lastexecuteddate",
        role: false,
      },
      {
        text: "my-searches-shared-by",
        isFormattedText: true,
        role: false,
      },
      {
        text: "my-searches-shared-with",
        isFormattedText: true,
        role: false,
      },
      {
        text: "my-searches-shared-date",
        isFormattedText: true,
        role: false,
      },

      {
        iconWithText: (
          <div
            className={`${BLOCK}__auto-delete-icon`}
            data-testid="auto-delete-icon"
            onMouseEnter={(e: any) =>
              handleMouseEnter(
                e,
                "div",
                <FormattedMessage id="my-searches-auto-delete-message" />,
                "",
                dispatch,
                null,
                MainTooltipPosition.TopLeft
              )
            }
            onMouseLeave={(e) => handleMouseLeave(e, dispatch, false)}
          >
            <Icon name="information" height={20} width={20} />
          </div>
        ),
        text: "my-searches-auto-delete",
        isFormattedText: true,
        isTooltip: true,
        role: false,
      },
    ];


    tableHeaders.push({
      text: "my-searches-actions",
      isFormattedText: true,
      role: false,
    });

    return tableHeaders;
  };

  const getSharedWith = (sharedWith: User[]) => {
    const fullName = `${sharedWith[0].lastName} ${sharedWith[0].firstName}`;
    const sortedSharedWith = sharedWith
      .map(
        (sharedUser: User) => `${sharedUser.lastName} ${sharedUser.firstName}`
      )
      .sort();
    return (
      <div
        className={`${BLOCK}__column-content ${BLOCK}__shared-with compact-wrapper`}
      >
        <div
          className={`${BLOCK}__column-text compact`}
          data-testid="shared-with"
          onMouseEnter={(e: any) =>
            handleMouseEnter(
              e,
              "div",
              <>{fullName}</>,
              "",
              dispatch,
              null,
              MainTooltipPosition.TopRight
            )
          }
          onMouseLeave={(e) => handleMouseLeave(e, dispatch, false)}
        >
          {fullName}
        </div>
        {sharedWith.length > 1 && (
          <div
            className={`${BLOCK}__shared-with-count`}
            data-testid="shared-with-count"
            onMouseEnter={(e: any) =>
              handleMouseEnter(
                e,
                "div",
                <div className={`${BLOCK}__shared-with-count-tooltip`}>
                  {sortedSharedWith.slice(1).join("; ")}
                </div>,
                "",
                dispatch,
                null,
                MainTooltipPosition.TopRight
              )
            }
            onMouseLeave={(e) => handleMouseLeave(e, dispatch, false)}
          >
            {`+${sharedWith.length - 1}`}
          </div>
        )}
      </div>
    );
  };

  const getTableRows = () => {
    return savedSearch?.map((item: any, index: number) => {
      const actions = [
        {
          name: item.hasPublished ? "unpublish" : "publish",
          iconName: item.hasPublished ? "unpublish" : "publish",
          onClick: () => {
            if (item.hasPublished) {
              dispatch(setCurrentSearchItem({ item, type: "savedSearches" }));
              setUnpublishSearch(true);
            } else {
              dispatch(savedSearchResult(item));
              setPublishSearch(true);
            }
          },
          id: item.hasPublished ? "unpublish" : "publish",
          admin: true,
        },
        {
          name: "share",
          iconName: "share",
          onClick: () => {
            dispatch(currentShareSearchItem(item));
          },
          id: "share",
        },
        {
          name: "edit",
          iconName: "pencil",
          onClick: () => {
            dispatch(savedSearchResult(item));
            if (item.hasPublished) {
              setPublishSearch(true);
            } else {
              setShowEditSearch(true);
              dispatch(setEditSavedSearchPopUp(true));
            }
          },
          id: "edit",
        },
        {
          name: "delete",
          iconName: "remove1",
          onClick: () => {
            dispatch(savedSearchResult(item));
            setShowDeleteSearch(true);
            dispatch(setDeleteSavedSearchPopUp(true));
          },
          id: "delete",
        },
      ];

      let tableRows = {
        id: item.savedSearchId,
        key: `${item.savedSearchId}-${index}`,
        className: `${BLOCK}__row ${
          item.lastExecutedDate == null && item.sharedBy && showBold
            ? `${BLOCK}__new-item`
            : ""
        }`,
        onClick: () => {
          history.push("/search", {
            searchCriteria: item.searchCriteria,
            savedSearchId: item.savedSearchId,
            sharedBy: item.sharedBy,
          });
          dispatch(resetFilter());
          resetSearch();
        },
        columnItems: [
          {
            text: item.name,
          },
          {
            text: item.description || (
              <Icon name="chevron-minus" width={20} height={20} />
            ),
          },
          {
            text: moment(item.createdDate).format("YYYY-MM-DD"),
          },
          {
            text: item.lastExecutedDate ? (
              moment(item.lastExecutedDate).format("YYYY-MM-DD")
            ) : (
              <Icon name="chevron-minus" width={20} height={20} />
            ),
          },
          {
            text:
              item.sharedBy &&
              item.sharedBy.firstName !== null &&
              item.sharedBy.lastName !== null ? (
                <>{`${item.sharedBy.firstName} ${item.sharedBy.lastName}`}</>
              ) : (
                <Icon name="chevron-minus" width={20} height={20} />
              ),
          },
          {
            renderContent: () => (
              <>
                {item.sharedWith && item.sharedWith.length > 0 ? (
                  getSharedWith(item.sharedWith)
                ) : (
                  <Icon name="chevron-minus" width={20} height={20} />
                )}
              </>
            ),
          },
          {
            text: item.sharedDate ? (
              moment(item.sharedDate).format("YYYY-MM-DD")
            ) : (
              <Icon name="chevron-minus" width={20} height={20} />
            ),
          },
          {
            text: getAutoDelDays(item.remainingDays),
            className:
              item.remainingDays < 8 && item.remainingDays > 3
                ? `${BLOCK}__auto-delete-7`
                : item.remainingDays <= 3
                ? `${BLOCK}__auto-delete-less-than-7`
                : "",
          },
        ],
      } as TableRowItem;


      tableRows.columnItems.push({
        className: `${BLOCK}__column-content ${BLOCK}__actions`,
        onClick: (e: any) => {
          e.stopPropagation();
        },
        renderContent: () =>
          actions
            .filter((action) => (isSuperAdmin ? action : !action.admin))
            .map((action) => (
              <button
                data-testid={`${action.id}-search-button`}
                key={action.name}
                className={`${BLOCK}__action-icon ${BLOCK}__${action.name}`}
                onClick={(e: any) => {
                  action.onClick();
                }}
              >
                <Tooltip position={TooltipPosition.top}>
                  <FormattedMessage id={action.id} />
                </Tooltip>
                <Icon name={action.iconName} height={24} width={24} />
              </button>
            )),
      });

      return tableRows;
    });
  };

  return (
    <>
      <div
        className={classNames(`${BLOCK}__table-container`, {
          [`${BLOCK}__notAdmin-table-container`]: !isSuperAdmin
        })}
        data-testid="saved-search-table"
      >
        <div className={`${BLOCK}__data`}>
          <div className={`${BLOCK}__results-count`}>
            <FormattedMessage
              id={
                savedSearch?.length === 1
                  ? "saved-searches-count-singular"
                  : "saved-searches-count-plural"
              }
              values={{ count: savedSearch?.length }}
            />
          </div>
        </div>
        <div
          className={`${
            !isSuperAdmin
              ? `${BLOCK}__table-notAdmin-wrapper`
              : `${BLOCK}__table-wrapper`
          }`}
        >
          <Table
            className={`${BLOCK}__table-inner-wrapper`}
            innerClassName={`${BLOCK}__table`}
            headerItems={getTableHeaders()}
            rowItems={getTableRows()}
            onSort={onSort}
            sortBy={sortInfo.sortBy}
            sortOrder={sortInfo.sortOrder}
          />
        </div>
        {unpublishSearch && isSuperAdmin && (
          <UnpublishSearch
            setShowModal={setUnpublishSearch}
            unpublishSearch={onUnpublishSearchHandler}
          />
        )}
        {publishSearch && isSuperAdmin && (
          <PublishSearch
            setShowModal={setPublishSearch}
            publishSearch={publishSearchHandler}
            type={publishSearchType.savedSearches}
          />
        )}
        {showSearchModal && (
          <ShareToUsersModal
            setShowShare={() => {}}
            shareShortlist={() => {}}
            setShowSearchModal={setShowSearchModal}
            shareSearch={shareSearchHandler}
            shareCompare={() => {}}
            shareType={shareItemType.search}
          />
        )}
        {showEditSearch && (
          <EditSearch
            setShowModal={setShowEditSearch}
            editSearch={onEditSearchHandler}
          />
        )}
        {showDeleteSearch && (
          <DeleteModal
            title="searchlist.delete.title"
            message={
              <>
                <FormattedMessage id="searchlist.delete.message" />
                <span
                  style={{ fontWeight: 600 }}
                >{` "${savedSearchListState.savedSearch.name}"`}</span>
                ?
              </>
            }
            handleCloseModal={() => {
              setShowDeleteSearch(false);
              dispatch(setDeleteSavedSearchPopUp(false));
            }}
            deleteItem={() =>
              onDeleteSearchHandler(
                savedSearchListState.savedSearch.savedSearchId
              )
            }
          />
        )}
      </div>
    </>
  );
};

export default SavedSearchesTable;
