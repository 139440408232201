import Header from "components/header/header";
import Footer from "components/footer/footer";
import SideMenu from "components/side-menu/side-menu";
import LoaderPage from "components/unauthorized/loaderPage";
import ToastContainer from "components/shared/toast/toast-container";
import ToolTipContainer from "components/shared/tooltip/tooltip.container";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import { useLocation } from "react-router";
import {
  APP_SETTING,
  dashboard,
  esrs,
  IGNORADED_MESSAGES,
  insightsPath,
  logout,
  peerBenchmarkPath,
  powerbiDashboardPath,
  searchPath,
  searchShortlistPath,
  STATUS_CODE,
} from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import api from "services/api.service";
import { Fragment, useEffect, useState } from "react";
import {
  addToastMessage,
  loadAppSetting,
  loadAppSettingColors,
  loadCurrentUser,
  loadDashboardExportDisclaimer,
  loadInsightsPageDisclaimer,
  loadCookieBanner,
  loadFooterAppSetting,
} from "services/commons.service";
import Downloads from "components/downloads/downloads";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import SessionTimeoutModal from "components/shared/session-timeout-modal/session-timeout-modal";
import Notifications from "components/notifications/notifications";
import { getFeatureSettings } from "services/insights/insights.service";
import classNames from "classnames";
import MainTooltipContainer from "components/shared/main-tooltip/main-tooltip-container";
import MaintenanceBanner from "components/maintenance-messages/active-maintenance-message-list";
import { setActiveMaintenanceMessages } from "services/manage-files/manage-files.service";
import { RootStore } from "services/store.service";
import { isEmpty } from "lodash";
import AccessDeniedModal from "components/module-access/access-denied.modal";

type Props = {
  hideHeader?: boolean;
  hideSideMenu?: boolean;
  children: JSX.Element;
};

const Layout = ({ hideHeader, hideSideMenu, children }: Props) => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const [showNotifications, setShowNotifications] = useState(true);
  const [marginTop, setMarginTop] = useState<number>(0);
  const [numberOfActiveMessages, setNumberOfActiveMessages] =
    useState<number>(0);
  const manageState = useSelector((state: RootStore) => state.manageFiles);
  const insightState = useSelector((state: RootStore) => state.insights);
  const commonState = useSelector((state: RootStore) => state.commons);
  const [isAllSectionCollapsed, setIsAllSectionCollapsed] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      insightState.currentTabId > 0 &&
      location.pathname.includes(insightsPath)
    ) {
      setIsAllSectionCollapsed(
        insightState.savedUserPreference?.userPreferenceValue?.insightsSectionsPreference?.find(
          (x) => x.benchmarkTypeId === insightState.currentTabId
        )?.expandedSectionIds?.length === 0 ?? false
      );
    }
  }, [insightState, insightState.currentTabId]);

  useEffect(() => {
    // Interceptor for catching erros and displaying toast messages
    api.interceptors.response.use(undefined, (err) => {
      const { response } = err;
      if (
        response &&
        (response.status === STATUS_CODE.INTERNAL_SERVER_ERROR ||
          response.status === STATUS_CODE.BAD_REQUEST ||
          response.status === STATUS_CODE.REQUEST_TIMEOUT ||
          response.status === STATUS_CODE.NOT_FOUND)
      ) {
        let isIgnored = false;
        if (response.data.messages) {
          isIgnored = Object.values(IGNORADED_MESSAGES).includes(
            response.data.messages[0].code
          );
        }

        if (!isIgnored) {
          dispatch(
            addToastMessage({
              description: (
                <FormattedMessage
                  id={
                    response.data &&
                    response.data.messages &&
                    response.data.messages[0].code
                      ? "error.default"
                      : "error.default"
                  }
                />
              ),
            })
          );
        }
      }

      throw err;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname !== logout) {
      if (isAuthenticated) {
        dispatch(loadCurrentUser());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (location.pathname !== logout) {
      if (!isEmpty(commonState.currentUser)) {
        dispatch(setActiveMaintenanceMessages());
        dispatch(loadAppSettingColors(APP_SETTING.DashboardColorCodes));
        dispatch(loadAppSettingColors(APP_SETTING.LowestScoringColorCodes));
        dispatch(loadAppSettingColors(APP_SETTING.HighestScoringColorCodes));
        dispatch(loadAppSettingColors(APP_SETTING.IvaCompanyRatingColorCodes));
        dispatch(
          loadDashboardExportDisclaimer(APP_SETTING.DashboardExportDisclaimer)
        );
        dispatch(
          loadInsightsPageDisclaimer(APP_SETTING.InsightsPageDisclaimer)
        );
        dispatch(getFeatureSettings());
        dispatch(loadFooterAppSetting(APP_SETTING.termOfUse));
        dispatch(loadAppSetting(APP_SETTING.DataPeriodDropDownLimit));
        dispatch(loadAppSetting(APP_SETTING.MSCIDataSourceLabel));
        dispatch(loadAppSetting(APP_SETTING.PotentialPeersPopUpTooltip));
        dispatch(
          loadAppSetting(
            APP_SETTING.DisableTalkingPointsExportInsightsCategoryIds
          )
        );
        dispatch(loadCookieBanner());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonState.currentUser, dispatch]);

  useEffect(() => {
    setMarginTop(manageState.cachedActiveMaintenanceMessages.length * 2.67);
    setNumberOfActiveMessages(
      manageState.cachedActiveMaintenanceMessages.length
    );
  }, [manageState.cachedActiveMaintenanceMessages]);

  return (
    <>
      <AuthenticatedTemplate>
        {window === window.parent && <SessionTimeoutModal />}
        {showNotifications && (
          <Notifications setShowNotification={setShowNotifications} />
        )}
        {!hideHeader && <Header />}

        {hideSideMenu ? (
          <Fragment>
            <MaintenanceBanner />
            {children}
          </Fragment>
        ) : (
          <>
            <div
              className={classNames(`ESG`, {
                [`ESG__Search`]: [searchPath, searchShortlistPath].includes(
                  location.pathname
                ),
                [`ESG__Workspace`]: [
                  dashboard,
                  powerbiDashboardPath,
                  peerBenchmarkPath,
                  insightsPath,
                ].includes(location.pathname),
              })}
              data-test="layout-component-children-esg"
              data-testid="layout-component-children-esg"
            >
              <SideMenu />
              <div className="ESG__content">
                <MaintenanceBanner />
                <div
                  style={{ marginTop: `${marginTop}rem` }}
                  className={classNames(``, {
                    [`activeMaintenanceMessage-Powerbi-${numberOfActiveMessages}`]:
                      [esrs, powerbiDashboardPath].includes(location.pathname),
                    [`adjustHeight`]: isAllSectionCollapsed,
                    [`messageCountBasedHeight-${numberOfActiveMessages}`]:
                      location.pathname + location.hash === "/search#sec",
                  })}
                >
                  {children}
                </div>
              </div>
            </div>
            <Footer />
          </>
        )}
        <Downloads
          className={
            hideSideMenu
              ? ""
              : location.pathname === dashboard ||
                location.pathname === peerBenchmarkPath
              ? "dashboard-download"
              : `content-download`
          }
        />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoaderPage />
      </UnauthenticatedTemplate>
      <ToastContainer />
      <ToolTipContainer></ToolTipContainer>
      <MainTooltipContainer></MainTooltipContainer>
      <AccessDeniedModal />
    </>
  );
};

export default Layout;
