import ParentSize from "@visx/responsive/lib/components/ParentSize";
import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import BatteryChart from "components/visualizations/charts/battery/battery-chart";
import HorizontalInsightsBarChart from "components/visualizations/charts/horizontal-insights-bar.chart";
import TableChart from "components/visualizations/charts/table-chart";
import { useSelector } from "react-redux";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import { RootStore } from "services/store.service";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import { getInsightsDetailPeerViewColorCode, isNumeric } from "utils/functions";
import TileEmptyContent from "../tile-empty-content";
import { mapTableChart, useTileContext } from "../tile.context";
import DetailPeerViewLegend from "./DetailPeerViewLegend";

const OpportinitiesRenewableEnergyComponent = () => {
  const {
    dataset,
    isTableViewActive,
    metadata,
    metadata: { benchmarkType, benchmarkMetadata, isDetailedView },
  } = useTileContext();
  const BLOCK = "opportunitiesRenewableEnergy";
  const commonsState = useSelector((state: RootStore) => state.commons);

  const hasEmptyValues = dataset.some((d: InsightData) =>
    d.metrics.some(
      (m: InsightMetricData) => m.metricValue === null || m.metricValue === "*"
    )
  );

  const batteryChartMetaData = {
    benchmarkType,
    minValue: 0,
    maxValue: 10,
    colorCodes: benchmarkMetadata.colorCodes,
  };

  const batteryChartView = () => {
    return (
      <>
        {benchmarkMetadata.benchmarkSubTitle ? (
          <span className={`${BLOCK}__range`}>
            {benchmarkMetadata.benchmarkSubTitle}
          </span>
        ) : null}
        <div className={`${BLOCK}__legend-section`}>
          {benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY ? (
            <div className={`${BLOCK}__legend-companies`}>
              {dataset.map((id: InsightData, companyInd: number) => (
                <div
                  key={`${companyInd}-company-legend`}
                  className={`${BLOCK}__legend-company-block`}
                >
                  {id.metrics[0].metricValue === null ? null : (
                    <div
                      className={`${BLOCK}__legend-company-pill`}
                      style={{
                        backgroundColor: id.isBaseCompany
                          ? benchmarkMetadata.colorCodes[0]
                          : benchmarkMetadata.colorCodes[1],
                      }}
                    ></div>
                  )}
                  <span>{`${id.companyName}${
                    id.metrics[0].metricValue === null ? " *" : ""
                  }`}</span>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div className={`${BLOCK}__container`}>
          {dataset.map((company) =>
            company.metrics.length > 0 &&
            ((benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY &&
              company.isBaseCompany) ||
              benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY) ? (
              <BatteryChart
                {...batteryChartMetaData}
                key={`battery-chart-${company.globalCompanyId}`}
                value={parseFloat(company.metrics[0].metricValue)}
                footer={
                  benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY
                    ? company.companyName
                    : ""
                }
                isBaseCompany={company.isBaseCompany ?? false}
                companyTooltip={company.companyName ?? null}
              />
            ) : null
          )}
        </div>
        {hasEmptyValues && (
          <div className={`${BLOCK}__no-data-section`}>
            <span>
              <FormattedMessage id="no.data.available" />
            </span>
          </div>
        )}
      </>
    );
  };

  const tableView = () => {
    return (
      <div
        className={classNames(
          `${BLOCK}__table-wrapper`,
          `${BLOCK}__table-wrapper--company-peer`
        )}
      >
        <span className={`${BLOCK}__legend-text`}>
          {benchmarkMetadata?.benchmarkSubTitle}
        </span>
        <TableChart
          data={mapTableChart(dataset, metadata, false, false, true)}
          currentInsightView={benchmarkType}
          hideTableHeader={benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY}
          horizontalTable={benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY}
          rightAlignment={true}
        />
      </div>
    );
  };

  const mapDataForDetailedView = () => {
    return dataset.length
      ? dataset.map((data: InsightData, i: number) => ({
          legendValue: data.companyName,
          legendOrder: i,
          legendColor: getInsightsDetailPeerViewColorCode(
            commonsState.InsightsDetailedPeerViewColorCodes,
            data.displayOrder!
          ),
          legendData: isNumeric(parseFloat(data.metrics[0].metricValue))
            ? parseFloat(data.metrics[0].metricValue)
            : null,
        }))
      : [];
  };

  const detailedView = () => {
    return (
      <div className={`${BLOCK}__detailedView`}>
        <div>
          {benchmarkMetadata.benchmarkSubTitle ? (
            <span className={`${BLOCK}__range`}>
              {benchmarkMetadata.benchmarkSubTitle}
            </span>
          ) : null}
        </div>
        <DetailPeerViewLegend
          dataset={dataset}
          isDetailedView={isDetailedView}
          colorCodes={
            isDetailedView
              ? commonsState.InsightsDetailedPeerViewColorCodes
              : []
          }
        />
        <ParentSize
          className={classNames(`${BLOCK}__content`)}
          debounceTime={100}
        >
          {({ width: visWidth, height: visHeight }) => {
            const barChartProps = {
              name: `${BLOCK}-DetailedPeerView`,
              axis: metadata.benchmarkMetadata.axis,
              width: visWidth,
              height: visHeight,
              handleGraphElementClicked: () => {},
              data: mapDataForDetailedView(),
            };
            return <HorizontalInsightsBarChart {...barChartProps} />;
          }}
        </ParentSize>
        {hasEmptyValues && (
          <div className={`${BLOCK}__no-data-section`}>
            <span>
              <FormattedMessage id="no.data.available" />
            </span>
          </div>
        )}
      </div>
    );
  };

  if (dataset.every((d) => d.metrics.every((m) => !m.metricValue)))
    return <TileEmptyContent />;

  return !isTableViewActive && !isDetailedView
    ? batteryChartView()
    : isTableViewActive
    ? tableView()
    : detailedView();
};

export default OpportinitiesRenewableEnergyComponent;
