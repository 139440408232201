import classNames from "classnames";
import Icon from "components/shared/icon/icon";

type Props = {
  label?: any;
  value: boolean;
  onChange: any;
  indeterminate?: boolean;
  item?: any;
  isCheckboxBlue?: boolean;
  labelChild?: any;
  disabled?: boolean;
  isGlobalSearch?: boolean;
};

const Checkbox = ({
  label,
  value,
  onChange,
  indeterminate,
  item,
  isCheckboxBlue,
  labelChild,
  disabled,
  isGlobalSearch,
}: Props) => {
  const BLOCK = "checkbox";
  return (
    <>
      <input
        className={BLOCK}
        type="checkbox"
        checked={value}
        onChange={!item ? onChange : () => onChange(item.id)}
        disabled={disabled}
        data-testid="checkbox-item"
      />

      {indeterminate && (
        <div className={`${BLOCK}__indeterminate`}>
          <Icon name="indeterminate2" height={16} width={16} />
        </div>
      )}
      {isCheckboxBlue && (
        <div
          className={classNames(`${BLOCK}__checkbox-blue`, {
            [`${BLOCK}__checkbox-blue--global-search`]: isGlobalSearch,
          })}
        >
          <Icon name="checkbox-blue" height={16} width={16} />
        </div>
      )}
      <span
        className={`${BLOCK}__label`}
        onClick={
          !item ? (!disabled ? onChange : () => onChange(item.id)) : () => {}
        }
        data-testid="checkbox-label"
      >
        {!item ? label : item.name}
        {labelChild && labelChild}
      </span>
    </>
  );
};

export default Checkbox;
