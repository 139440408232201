import { Dispatch } from "react";
import {
  loadSavedSearchList,
  savedSearchListLoading,
} from "services/searchlist/searchlist.service";
import { DEFAULT_SAVED_SEARCHES_PAYLOAD } from "utils/constants";
import { createAction } from "../../utils/redux.utils";
import * as api from "./published-searchlist.api";
import {
  PublishedSearchlistModel,
  PublishedSearchlistState,
  PublishedSearchPayload,
} from "./published-searchlist.model";

export const ACTIONS = {
  PUBLISHED_SEARCHLIST_SAVE: "ESG/UI/PUBLISHED_SEARCHLIST_SAVE",
  PUBLISHED_SEARCHLIST_LOADING: "ESG/UI/PUBLISHED_SEARCHLIST_LOADING",
  PUBLISHED_SEARCHLIST_CURRENT_SEARCH:
    "ESG/UI/PUBLISHED_SEARCHLIST_CURRENT_SEARCH",
  PUBLISHED_SEARCHLIST_CURRENT_SEARCH_LOADING:
    "ESG/UI/PUBLISHED_SEARCHLIST_CURRENT_SEARCH_LOADING",
  PUBLISHED_SEARCHLIST_UNPUBLISH_TOASTER:
    "ESG/UI/PUBLISHED_SEARCHLIST_UNPUBLISH_TOASTER",
};

export const publishedSearchlistSave = createAction(
  ACTIONS.PUBLISHED_SEARCHLIST_SAVE
);
export const publishedsearchlistLoading = createAction(
  ACTIONS.PUBLISHED_SEARCHLIST_LOADING
);
export const publishedsearchlistCurrentSearch = createAction(
  ACTIONS.PUBLISHED_SEARCHLIST_CURRENT_SEARCH
);
export const publishedsearchlistCurrentSearchLoading = createAction(
  ACTIONS.PUBLISHED_SEARCHLIST_CURRENT_SEARCH_LOADING
);
export const publishedsearchlistUnpublishToaster = createAction(
  ACTIONS.PUBLISHED_SEARCHLIST_UNPUBLISH_TOASTER
);

export const fetchPublishedSearch = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(publishedsearchlistLoading(true));
    const response = await api.fetchPublishedSearchList();
    dispatch(publishedSearchlistSave(response.data));
    dispatch(publishedsearchlistLoading(false));
  } catch (e) {
    dispatch(publishedsearchlistLoading(false));
  }
};

export const setCurrentSearchItem =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(publishedsearchlistCurrentSearchLoading(true));
      dispatch(publishedsearchlistCurrentSearch(payload));
      dispatch(publishedsearchlistCurrentSearchLoading(false));
    } catch (e) {
      dispatch(publishedsearchlistCurrentSearchLoading(false));
    }
  };

export const unpublishSearchItem =
  (payload: { payload: PublishedSearchPayload; type: string }) =>
  async (dispatch: Dispatch<any>) => {
    try {
      if (payload.type === "publishedSearches") {
        dispatch(publishedsearchlistLoading(true));
        await api.unpublishSearch(payload.payload);
        const response = await api.fetchPublishedSearchList();
        dispatch(publishedSearchlistSave(response.data));
        dispatch(publishedsearchlistLoading(false));
      } else if (payload.type === "savedSearches") {
        dispatch(savedSearchListLoading(true));
        await api.unpublishSearch(payload.payload);
        dispatch(loadSavedSearchList(DEFAULT_SAVED_SEARCHES_PAYLOAD));
        dispatch(savedSearchListLoading(false));
      }
      dispatch(setCurrentSearchItem(null));
      dispatch(publishedsearchlistUnpublishToaster(true));
    } catch (e) {
      dispatch(publishedsearchlistLoading(false));
      dispatch(savedSearchListLoading(false));
    }
  };

const publishedSearchlistModel = new PublishedSearchlistModel();

const publishedSearchlistReducer = (
  state: PublishedSearchlistState = publishedSearchlistModel.initialState(),
  action: any
): PublishedSearchlistState => {
  switch (action.type) {
    case ACTIONS.PUBLISHED_SEARCHLIST_SAVE:
      return publishedSearchlistModel.setPublishedSearchlist(action.payload);
    case ACTIONS.PUBLISHED_SEARCHLIST_LOADING:
      return publishedSearchlistModel.setLoading(action.payload);
    case ACTIONS.PUBLISHED_SEARCHLIST_CURRENT_SEARCH:
      return publishedSearchlistModel.setCurrentSearchItem(action.payload);
    case ACTIONS.PUBLISHED_SEARCHLIST_CURRENT_SEARCH_LOADING:
      return publishedSearchlistModel.setCurrentSearchLoading(action.payload);
    case ACTIONS.PUBLISHED_SEARCHLIST_UNPUBLISH_TOASTER:
      return publishedSearchlistModel.setUnpublishToaster(action.payload);
    default:
      return state;
  }
};
export default publishedSearchlistReducer;
