import Dashboard from "components/dashboard/dashboard";
import Feedback from "components/feedback/feedback";
import Insights from "components/insights/insights";
import PeerBenchmark from "components/peer-benchmark/peer-benchmark";
import MainDashboard from "components/powerbi-dashboard/main-dashboard";
import Button from "components/shared/button/button";
import TabbedContent from "components/shared/tabbed-content/tabbed-content";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { submitFeedback } from "services/download/download.api";
import { loadSavedPeerbenchmarks } from "services/peer-benchmark/peer-benchmark.service";
import { RootStore } from "services/store.service";
import {
  ACCESS_MODULES,
  DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD,
  Roles,
  dashboardPath,
  esrs,
  insightsPath,
  peerBenchmarkPath,
  powerbiDashboardPath,
  powerbiEnable,
  unauthorizedPath,
} from "utils/constants";
import history from "utils/history";

type props = {
  currTab: number;
};
const Workspace = ({ currTab }: props) => {
  const BLOCK = "workspace";
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState<number>(currTab);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );
  const isSuperAdmin: boolean =
    currentUser?.adminRole?.adminRoleId === Roles.superAdmin;

  const tabItemsForAdmin = [
    {
      text: "workspace.tab.insights",
      tabId: "insightsPath-view--tab",
      tabPanelId: "insightsPath-view--tab-panel",
      location: `${insightsPath}`,
    },
    {
      text: "workspace.tab.dashboard",
      tabId: "dashboard-view--tab",
      tabPanelId: "dashboard-view--tab-panel",
      location: `${dashboardPath}`,
    },
    {
      text: "workspace.tab.peerbenchmark",
      tabId: "peerbenchmarks-view--tab",
      tabPanelId: "peerbenchmarks-view--tab-panel",
      location: `${peerBenchmarkPath}`,
    },
    {
      text: "workspace.powerbi.dashboard",
      tabId: "powerbiDashboardPath-view--tab",
      tabPanelId: "powerbiDashboardPath-view--tab-panel",
      location: `${powerbiDashboardPath}`,
    },

    /* Hiding ESRS Tab for release 2.5 */
    // {
    //   text: "workspace.tab.esrs",
    //   tabId: "esrs-view--tab",
    //   tabPanelId: "esrs-view--tab-panel",
    //   location: `${esrs}`,
    // },
  ];

  const tabItems = [
    {
      text: "workspace.tab.insights",
      tabId: "insightsPath-view--tab",
      tabPanelId: "insightsPath-view--tab-panel",
      location: `${insightsPath}`,
    },
    {
      text: "workspace.tab.dashboard",
      tabId: "dashboard-view--tab",
      tabPanelId: "dashboard-view--tab-panel",
      location: `${dashboardPath}`,
    },
    {
      text: "workspace.tab.peerbenchmark",
      tabId: "peerbenchmarks-view--tab",
      tabPanelId: "peerbenchmarks-view--tab-panel",
      location: `${peerBenchmarkPath}`,
    },
    /* Hiding ESRS Tab for release 2.5 */
    // {
    //   text: "workspace.tab.esrs",
    //   tabId: "esrs-view--tab",
    //   tabPanelId: "esrs-view--tab-panel",
    //   location: `${esrs}`,
    // },
  ];

  useEffect(() => {
    if (currentTab === 2) {
      dispatch(loadSavedPeerbenchmarks(DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    setCurrentTab(
      tabItemsForAdmin.findIndex((item) =>
        location.pathname.includes(item.location)
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.accessibleFeatures &&
      currentUser.accessibleFeatures.length > 0 &&
      !currentUser.accessibleFeatures.some(
        (feature) =>
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_All ||
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_Insights
      )
    ) {
      history.push(unauthorizedPath);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, currentUser]);

  const handleTabChange = (index: number) => {
    isSuperAdmin && powerbiEnable === "TRUE"
      ? history.push(tabItemsForAdmin[index].location, location.state)
      : history.push(tabItems[index].location, location.state);
  };

  const submitFeedbackHandler = ({
    feedback,
    rating,
  }: {
    feedback: string;
    rating: number;
  }) => {
    submitFeedback({ feedback, rating }).then();
  };

  const workspaceRef = useRef(null);
  return (
    <>
      {currentUser && (
        <div className={BLOCK} data-testid="workspace-component">
          <div className={`${BLOCK}__header`}>
            <TabbedContent
              tabItems={
                isSuperAdmin && powerbiEnable === "TRUE"
                  ? tabItemsForAdmin
                  : tabItems
              }
              selectedIndex={currentTab}
              onChange={handleTabChange}
            />
            <div className={`${BLOCK}__header-end-section`}>
              <div className={`${BLOCK}__feedback-section`}>
                <Button
                  className={`${BLOCK}__feedback-button button-primary-small`}
                  dataTest="feedback-button"
                  iconName="commenting"
                  iconHeight={24}
                  iconWidth={24}
                  formattedText="submit.feedback"
                  onClick={() => setShowFeedbackModal(true)}
                />
              </div>
              {showFeedbackModal && (
                <div
                  data-test="feedback-button-modal"
                  data-testid="feedback-button-modal"
                >
                  <Feedback
                    setShowFeedbackModal={setShowFeedbackModal}
                    submitFeedback={submitFeedbackHandler}
                  />
                </div>
              )}
            </div>
          </div>
          {currentTab === 0 && (
            <div
              className={`${BLOCK}__content__myworkspace ${BLOCK}__tab-panel ${
                currentTab === 0 && `${BLOCK}__tab-panel--selected`
              }`}
              role="tabpanel"
            >
              <Insights />
            </div>
          )}
          {currentTab === 1 && (
            <div
              className={`${BLOCK}__content ${BLOCK}__tab-panel ${
                currentTab === 1 && `${BLOCK}__tab-panel--selected`
              }`}
              role="tabpanel"
              ref={workspaceRef}
            >
              {/* <DndProvider backend={HTML5Backend}>
            <Dashboard />
          </DndProvider> */}
              <Dashboard workspaceRef={workspaceRef} />
            </div>
          )}
          {currentTab === 2 && (
            <div
              className={`${BLOCK}__content__myworkspace ${BLOCK}__tab-panel ${
                currentTab === 2 && `${BLOCK}__tab-panel--selected`
              }`}
              role="tabpanel"
            >
              <PeerBenchmark />
            </div>
          )}
          {isSuperAdmin && powerbiEnable === "TRUE" && currentTab === 3 && (
            <div
              className={`${BLOCK}__content__myworkspace ${BLOCK}__tab-panel ${
                currentTab === 3 && `${BLOCK}__tab-panel--selected`
              } ${BLOCK}__content__myworkspace--100-h `}
              role="tabpanel"
            >
              <MainDashboard currentTab={currentTab} />
            </div>
          )}
          {/* Hiding ESRS Tab for release 2.5 */}
          {/* {currentTab === 4 && (
            <div
              className={`${BLOCK}__content__myworkspace ${BLOCK}__tab-panel ${
                currentTab === 4 && `${BLOCK}__tab-panel--selected`
              } ${BLOCK}__content__myworkspace--100-h `}
              role="tabpanel"
            >
              <MainDashboard currentTab={currentTab} />
            </div>
          )} */}
        </div>
      )}
    </>
  );
};

export default Workspace;
