import React, { useMemo } from "react";
import { Group } from "@visx/group";
import { Circle } from "@visx/shape";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { scaleLinear, scaleSqrt, scalePoint } from "@visx/scale";
import { withTooltip, defaultStyles } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { ScaleSVG } from "@visx/responsive";
import { Grid } from "@visx/grid";
import { BubbleChartDataset } from "services/dashboard/dashboard.model";
import { lightColor } from "utils/constants";

const BLOCK = "DashboardTile";
const STRING_AXIS_ORDER: { [key: string]: number } = {
  AAA: 9,
  AA: 8,
  A: 7,
  BBB: 6,
  BB: 5,
  B: 4,
  CCC: 3,
};

const axisBaseOffset = -0.25;
const axisOffsetMultiplier = -0.45;

export type Props = {
  data: BubbleChartDataset[];
  width: number;
  height: number;
  showControls?: boolean;
  axis?: { primary: string[]; secondary: string[] };
  margin?: { top: number; right: number; bottom: number; left: number };
};

const BubbleChart = ({
  data,
  width,
  height,
  axis,
  margin = { top: 5, right: 50, bottom: 36, left: 20 },
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
}: Props & WithTooltipProvidedProps<BubbleChartDataset>) => {
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const primaryAxis = axis?.primary || [];
  const secondaryAxis = useMemo(() => axis?.secondary || [], [axis]);
  const sortedPrimaryAxis = primaryAxis.sort(
    (a, b) => STRING_AXIS_ORDER[b] - STRING_AXIS_ORDER[a]
  );

  const zPropMax = Math.max(...data.map((d) => d.zProperty));
  const minRadius =
    0.5 * Math.min(width / secondaryAxis.length, height / primaryAxis.length);

  const tooltipStyles = {
    ...defaultStyles,
    backgroundColor: "rgba(0,0,0,0.9)",
    color: "white",
    fontSize: 15,
  };

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [
          Math.min(...secondaryAxis.map((d) => Number(d))),
          Math.max(...secondaryAxis.map((d) => Number(d))),
        ],
        range: [0, xMax],
      }),
    [xMax, secondaryAxis]
  );
  const yScale = useMemo(
    () =>
      scalePoint<string>({
        domain: sortedPrimaryAxis,
        range: [0, yMax],
      }),
    [yMax, sortedPrimaryAxis]
  );

  if (width < 10) return null;

  const zScale = scaleSqrt<number>({
    domain: [0, zPropMax],
    range: [0, minRadius],
  });

  let tooltipTimeout: number;

  return (
    <div className="bubble-chart">
      <ScaleSVG width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <AxisLeft
            hideTicks
            hideAxisLine
            left={margin.left}
            top={margin.top}
            scale={yScale}
            stroke={lightColor}
            tickStroke={lightColor}
            axisClassName={`${BLOCK}__axis`}
            tickComponent={(tickProps) => {
              const valueLength = tickProps.formattedValue
                ? tickProps.formattedValue.length
                : 1;

              const dx = axisBaseOffset + valueLength * axisOffsetMultiplier;

              return (
                <text
                  fontFamily="Open Sans"
                  fontSize={12}
                  textAnchor="start"
                  dx={`${dx}em`}
                  dy={tickProps.dy}
                  x={tickProps.x}
                  y={tickProps.y}
                >
                  <tspan x={tickProps.x} dy={tickProps.dy}>
                    {tickProps.formattedValue}
                  </tspan>
                </text>
              );
            }}
          />
          <AxisBottom
            hideTicks
            hideAxisLine
            top={yMax + margin.top}
            left={margin.left}
            axisClassName={`${BLOCK}__axis`}
            scale={xScale}
            stroke={lightColor}
            tickStroke={lightColor}
            orientation="bottom"
            tickComponent={(tickProps) => {
              return (
                <text
                  fontFamily="Open Sans"
                  fontSize={12}
                  textAnchor="middle"
                  dx={tickProps.dx}
                  dy={tickProps.dy}
                  x={tickProps.x}
                  y={tickProps.y}
                >
                  <tspan x={tickProps.x} dy={tickProps.dy}>
                    {tickProps.formattedValue}
                  </tspan>
                </text>
              );
            }}
          />
          <Grid
            xScale={xScale}
            yScale={yScale}
            width={xMax}
            height={yMax}
            left={margin.left}
            top={margin.top}
            stroke={lightColor}
          />
          {data.map((data, i) => (
            <Circle
              key={`point-${data.id}-${i}`}
              className="dot"
              cx={xScale(data.xProperty) + margin.left}
              cy={(yScale(data.yProperty) || 0) + margin.top}
              r={zScale(data.zProperty)}
              fill="#85F4FF"
              stroke="#42C2FF"
              fillOpacity={0.4}
              onMouseLeave={() =>
                (tooltipTimeout = window.setTimeout(() => {
                  hideTooltip();
                }, 100))
              }
              onMouseMove={() => {
                if (tooltipTimeout) clearTimeout(tooltipTimeout);
                showTooltip({
                  tooltipData: data,
                  tooltipTop: Number(yScale(data.yProperty)) + margin.top + 20,
                  tooltipLeft: xScale(data.xProperty) + margin.left,
                });
              }}
            />
          ))}
        </Group>
      </ScaleSVG>
      {tooltipOpen && tooltipData && (
        <div
          style={{
            ...tooltipStyles,
            top: tooltipTop,
            left: tooltipLeft,
            position: "absolute",
          }}
        >
          <div>{tooltipData.zProperty}</div>
        </div>
      )}
    </div>
  );
};

export default withTooltip<Props, BubbleChartDataset>(BubbleChart);
