import { BaseModel, errorModel } from "../../utils/redux.utils";
import { User } from "services/commons.model";

// Data structure for payload request - search query to save
export interface SearchResult {
  SavedSearchId: number;
  name: string;
  description: string;
  SearchCriteria: {
    shortlistId: number;
    contentText: [];
    formTypeNames: [];
    years: [];
    companyIds: [];
    industryIds: [];
    auditorIds: [];
    governingBoardIds: [];
    pageInfo: {};
  };
  CreatedBy: number | null;
  savedSearchId?: number;
}

export interface UserObj {
  userId: number;
  externalSystemId: string;
  email: string;
  displayName: string;
  preferredFullName: string;
  firstName: string;
  lastName: string;
  title: string;
  memberFirmCode: string;
  geoCode: string;
  containerCode: string;
  containerName: string;
  organizationCode: string;
  employeeStatusCode: string;
  isActive: boolean;
  accessibleFeatures: any;
}
export interface SearchlistState {
  error?: errorModel;
  currentSearchlistId: number;
  loading: boolean;
  results: Search[];
  errorRename: boolean;
  renameSearchSuccess: boolean;
  saveSearch: boolean;
  saveNewSearch: false;
  savedSearch: Search;
  searchToSave: SearchResult;
  shareSelectedUsers: UserObj[];
  shareFetchedUsers: User[];
  shareLoading: boolean;
  currentShareSearch: Search;
  shareSearchSuccess: boolean;
  savedSearchList: SavedSearchList;
  savedSeachListLoading: boolean;
  editSavedSearchPopUp: boolean;
  deleteSavedSearchPopUP: boolean;
  refreshSavedSearchesList: boolean;
  sharedSearchCount: number;
  executeSavedSearch: boolean;
  executeSharedSearch: boolean;
  editSavedSearch: boolean;
  deleteSavedSearch: boolean;
  topics: Topic[];
  newTopic: Topic;
  topicNameError: boolean;
  currentSavedSearch: SearchResult;
}

export interface Response {
  data: boolean;
  messages: [];
}

export class SearchlistModel extends BaseModel {
  constructor() {
    super({
      currentSearchId: 0,
      loading: false,
      results: null,
      errorRename: false,
      renameSearchSuccess: false,
      saveSearch: false,
      saveNewSearch: false,
      savedSearch: null,
      searchToSave: null,
      shareSelectedUsers: [],
      shareFetchedUsers: [],
      shareLoading: false,
      currentShareSearch: null,
      shareSearchSuccess: false,
      savedSearchList: [],
      savedSeachListLoading: false,
      editSavedSearchPopUp: false,
      deleteSavedSearchPopUP: false,
      refreshSavedSearchesList: false,
      sharedSearchCount: 0,
      executeSavedSearch: false,
      executeSharedSearch: false,
      deleteSavedSearch: false,
      editSavedSearch: false,
      topics: [],
      newTopic: null,
      topicNameError: false,
      currentSavedSearch: null,
    });
  }

  setLoading = (loading: boolean): SearchlistState => this.merge({ loading });
  resetSearchList = (): SearchlistState =>
    this.merge({
      currentShortlistId: null,
      loading: false,
      results: null,
    });
  resetSavedSearch = (): SearchlistState =>
    this.merge({
      savedSearch: null,
    });
  resetSearchToSave = (): SearchlistState =>
    this.merge({
      searchToSave: null,
    });
  setErrorRename = (errorRename: boolean): SearchlistState =>
    this.merge({ errorRename });
  setRenameSearchSuccess = (renameSearchSuccess: boolean): SearchlistState =>
    this.merge({ renameSearchSuccess });
  setSaveSuccess = (saveSearch: boolean): SearchlistState =>
    this.merge({ saveSearch });
  setSaveNewSearch = (saveNewSearch: boolean): SearchlistState =>
    this.merge({ saveNewSearch });
  setSavedSearch = (savedSearch: Search): SearchlistState =>
    this.merge({ savedSearch });
  setSearchToSave = (searchToSave: SearchResult): SearchlistState =>
    this.merge({ searchToSave });

  setShareSelectedUsers = (shareSelectedUsers: UserObj[]): SearchlistState =>
    this.merge({ shareSelectedUsers });

  setShareFetchedUsers = (shareFetchedUsers: UserObj[]): SearchlistState =>
    this.merge({ shareFetchedUsers });

  setShareLoading = (shareLoading: boolean): SearchlistState =>
    this.merge({ shareLoading });

  setCurrentSearch = (currentShareSearch: Search): SearchlistState =>
    this.merge({ currentShareSearch });
  setShareSearchSuccess = (shareSearchSuccess: boolean): SearchlistState =>
    this.merge({ shareSearchSuccess });
  savedSearchListLoading = (savedSeachListLoading: boolean): SearchlistState =>
    this.merge({ savedSeachListLoading });
  savedSearchList = (savedSearchList: SavedSearchList[]): SearchlistState =>
    this.merge({ savedSearchList });
  setEditSavedSearchPopUp = (editSavedSearchPopUp: boolean): SearchlistState =>
    this.merge({ editSavedSearchPopUp });
  setExecuteSavedSearch = (executeSavedSearch: boolean): SearchlistState =>
    this.merge({ executeSavedSearch });
  setExecuteSharedSearch = (executeSharedSearch: boolean): SearchlistState =>
    this.merge({ executeSharedSearch });
  setEditSavedSearch = (editSavedSearch: boolean): SearchlistState =>
    this.merge({ editSavedSearch });
  setDeleteSavedSearch = (deleteSavedSearch: boolean): SearchlistState =>
    this.merge({ deleteSavedSearch });
  setDeleteSavedSearchPopUp = (
    deleteSavedSearchPopUp: boolean
  ): SearchlistState => this.merge({ deleteSavedSearchPopUp });
  setRefreshSavedSearchesList = (
    refreshSavedSearchesList: boolean
  ): SearchlistState => this.merge({ refreshSavedSearchesList });
  setSharedSearchCount = (sharedSearchCount: number): SearchlistState =>
    this.merge({ sharedSearchCount });

  setTopics = (topics: Topic): SearchlistState => this.merge({ topics });
  setNewTopic = (newTopic: Topic): SearchlistState => this.merge({ newTopic });
  resetNewTopic = (): SearchlistState =>
    this.merge({
      newTopic: null,
    });
  setTopicNameError = (topicNameError: boolean): SearchlistState =>
    this.merge({ topicNameError });

  setCurrentSavedSearch = (currentSavedSearch: SearchResult): SearchlistState =>
    this.merge({
      currentSavedSearch,
    });
}

// Data structure for response - saved search query
export type Search = {
  savedSearchId: number;
  name?: string;
  description?: string;
  searchCriteria: {
    shortlistId: number;
    contentText: [];
    formTypeNames: [];
    years: [];
    companyIds: [];
    industryIds: [];
    auditorIds: [];
    governingBoardIds: [];
    pageInfo: {};
  };
  sourceSavedSearchId: number;
  createdBy: string;
  sharedBy: string;
  sharedDate: string;
  dateCreated?: string;
  lastExecutedDate: string;
  sharedWith: User[];
  publishedBy?: User;
  publishedDate?: string;
  hasPublished?: boolean;
  topic?: Topic;
};

export type Topic = {
  topicId?: number;
  topicName?: string;
  topicDescription?: string;
};

export type SearchListPageInfo = {
  totalRecordCount: number;
  pageNumber: number;
  pageSize: number;
  totalPageCount: number;
  sortBy: string;
  sortOrder: string;
};

export type savedSearchListPageInfo = {
  totalRecordCount: number;
  pageNumber: number;
  pageSize: number;
  totalPageCount: number;
  sortBy: string;
  sortOrder: string;
};

export type SavedSearchList = {
  pageInfo: savedSearchListPageInfo;
  savedSearches: SavedSearches[];
};

export type SavedSearches = {
  savedSearchId: number;
  name: string;
  description: string;
  searchCriteria: {
    contentText: string;
    formTypeNames: [];
    years: [];
    companyIds: [];
    industryIds: [];
    auditorIds: [];
    governingBoardIds: [];
    pageInfo: {
      totalPageCount: number;
      totalRecordCount: number;
      pageNumber: number;
      pageSize: number;
      sortBy: string;
      sortOrder: string;
    };
    shortlistId: number;
  };
  sourceSavedSearchId: number;
  displayOrder: number;
  createdBy: number;
  sharedBy: UserObj;
  sharedDate: string;
  lastExecutedDate: string;
  sharedWith: UserObj[];
  createdDate: string;
  lastUpdatedDate: string;
  remainingDays: number;
};
