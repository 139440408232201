import { TooltipPosition } from "components/shared/tooltip/tooltip";
import Parser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { showTooltip } from "services/commons.service";
import { RootStore } from "services/store.service";

const useHandleTooltip = (BLOCK: string) => {
  const dispatch = useDispatch();
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);

  const handleMouseEnter = (
    e: any,
    children: any,
    position: TooltipPosition = TooltipPosition.TileBottomRight,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("div");
    if (!element) {
      return;
    }
    dispatch(
      showTooltip({
        children: <div className={`${BLOCK}__tooltip`}>{Parser(children)}</div>,
        position: position,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;

    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  return { handleMouseEnter, handleMouseLeave };
};

export default useHandleTooltip;
