import { useEffect, useState } from "react";
import TargetsTileCharts, {
  SCOPE_OF_TARGET,
  TargetData,
  TargetMetric,
  TargetMetricGroup,
} from "components/visualizations/charts/targets-tile-chart";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import TileEmptyContent from "../tile-empty-content";
import { useTileContext } from "../tile.context";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Parser from "html-react-parser";
import { sanitize } from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";
import classNames from "classnames";
import TableChart from "components/visualizations/charts/table-chart";

export const ENTITY_DEEMED_VALUE = "Entity deemed question not applicable";
const TargetsTile = () => {
  const {
    dataset,
    metadata,
    metadata: {
      benchmarkType,
      isDetailedView,
      benchmarkMetadata,
      associatedMetricGroupTooltips,
    },
    isTableViewActive,
  } = useTileContext();
  const [hasEmptyValues, setHasEmptyValues] = useState<boolean>(false);
  const TABLE_PROPS = [
    "header",
    "globalCompanyId",
    "groupName",
    "isBaseCompany",
  ];
  const BLOCK = "targetsTileChart";
  const PREFIX = "Target";
  const keywordForNumberTypes = ["TargetYear", "BaseYear", "Percentage"];
  const keywordForFirstMetric = "TargetThatWasActiveInTheReportingYearCustom";
  const dispatch = useDispatch();
  const mainTooltip = useSelector(
    (store: RootStore) => store.commons.mainTooltip
  );

  useEffect(() => {
    setHasEmptyValues(false);
  }, [isDetailedView, isTableViewActive]);

  if (
    dataset.length === 0 ||
    (dataset.length > 0 &&
      (dataset.every((d: InsightData) =>
        d.metrics.every(
          (m: InsightMetricData) =>
            !m.metricValue ||
            (m.metricKey.includes(keywordForFirstMetric) && !m.metricValue)
        )
      ) ||
        (benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY &&
          dataset
            .filter((v: InsightData) => v.isBaseCompany)
            .every((d: InsightData) =>
              d.metrics.every(
                (m: InsightMetricData) =>
                  !m.metricValue ||
                  (m.metricKey.includes(keywordForFirstMetric) &&
                    !m.metricValue)
              )
            )) ||
        (benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY &&
          dataset.every((d: InsightData) =>
            d.metrics.every(
              (m: InsightMetricData) =>
                !m.metricValue ||
                (m.metricKey.includes(keywordForFirstMetric) && !m.metricValue)
            )
          ))))
  )
    return (
      <div>
        <TileEmptyContent />
      </div>
    );

  const metricTypes = {
    TEXT: 1,
    NUMBER: 2,
  };

  const mapChartData = () => {
    let groups = benchmarkMetadata.associatedMetrics;
    let tmpData: TargetData[] = dataset.map((d: InsightData) => {
      let tmpTargets = groups.map(
        (g: { groupName: string; groupValues: string[] }) => ({
          groupName: g.groupName,
          metrics: g.groupValues.map((gv: string) => {
            let currentMetric = d.metrics.filter(
              (mt: InsightMetricData) => mt.metricKey === gv
            )[0];
            return {
              metricKey: gv,
              metricName: currentMetric.metricName ?? "",
              metricValue:
                currentMetric.metricValue &&
                gv.includes(keywordForNumberTypes[2]) &&
                !isNaN(+currentMetric.metricValue)
                  ? `${currentMetric.metricValue}%`
                  : currentMetric.metricValue
                  ? currentMetric.metricValue
                  : "*",
              metricType: keywordForNumberTypes.every(
                (kw: string) => !gv.includes(kw)
              )
                ? metricTypes.TEXT
                : metricTypes.NUMBER,
              metricTooltip: associatedMetricGroupTooltips
                ? associatedMetricGroupTooltips.filter(
                    ({ associatedMetricGroupName }: any) =>
                      associatedMetricGroupName === currentMetric.metricName
                  )
                : [],
            };
          }),
        })
      );

      return {
        companyData: { ...d },
        targetGroups: [...tmpTargets],
      };
    });
    let tmpTargetsDataProccesed: any = JSON.parse(JSON.stringify(tmpData));
    if (benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY) {
      let targetsGroupNames: string[] = [];
      if (isDetailedView) {
        tmpTargetsDataProccesed = tmpData.map((td: TargetData) => {
          let tmpTargetGroups = td.targetGroups.filter(
            (tg: TargetMetricGroup, ind: number) =>
              !tg.metrics.every(
                (m: TargetMetric, i: number) =>
                  m.metricValue === "*" || (i === 0 && ind !== 0)
              )
          );
          tmpTargetGroups.forEach(
            (tg: any, tgInd: number) => (tg.groupName = `${PREFIX}${tgInd + 1}`)
          );
          return { ...td, targetGroups: tmpTargetGroups };
        });
      }
      //Get targets from baseline company and peers
      tmpTargetsDataProccesed.forEach((td: TargetData) => {
        let tmpTargetGroups = td.targetGroups.filter(
          (tg: TargetMetricGroup, ind: number) =>
            !tg.metrics.every(
              (m: TargetMetric, i: number) =>
                m.metricValue === "*" || (i === 0 && ind !== 0)
            )
        );
        targetsGroupNames = targetsGroupNames.concat(
          tmpTargetGroups.map((tmg: TargetMetricGroup) => tmg.groupName)
        );
      });
      targetsGroupNames = Array.from(new Set(targetsGroupNames));
      //Validate baseline company targets for peer and industry chart
      if (!isDetailedView) {
        let bc = tmpTargetsDataProccesed.filter(
          (td: TargetData) => td.companyData.isBaseCompany
        );
        let bcTargets = bc[0].targetGroups.filter(
          (bctg: TargetMetricGroup) =>
            !bctg.metrics
              .filter(
                (tm: TargetMetric) =>
                  !tm.metricKey.includes(keywordForFirstMetric)
              )
              .every(
                (bctm: TargetMetric) =>
                  bctm.metricValue === "*" || !bctm.metricValue
              )
        );
        if (bcTargets.length === 0) targetsGroupNames = [`${PREFIX}1`];
        else
          targetsGroupNames = bcTargets.map(
            (tmg: TargetMetricGroup) => tmg.groupName
          );
      }
      tmpTargetsDataProccesed.forEach((td: TargetData) => {
        //Get targets for each company
        td.targetGroups = td.targetGroups.filter(
          (tg: TargetMetricGroup, ind: number) =>
            targetsGroupNames.includes(tg.groupName)
        );
        if (td.targetGroups.length !== targetsGroupNames.length) {
          let missedTargets = targetsGroupNames.slice(td.targetGroups.length);
          missedTargets.forEach((mt: string) => {
            let mtc = tmpData.filter(
              (ttdv: TargetData) =>
                ttdv.companyData.globalCompanyId ===
                td.companyData.globalCompanyId
            );
            if (mtc.length > 0) {
              let tmpMissedTarget = mtc[0].targetGroups.filter(
                (mctg: TargetMetricGroup) => mctg.groupName === mt
              );
              if (tmpMissedTarget.length > 0) {
                td.targetGroups.push({
                  ...tmpMissedTarget[0],
                  metrics: [
                    ...tmpMissedTarget[0].metrics.map((mtg: TargetMetric) => ({
                      ...mtg,
                      metricValue: mtg.metricKey.includes(keywordForFirstMetric)
                        ? mtg.metricValue
                        : "*",
                    })),
                  ],
                });
              }
            }
          });
        }
        //Set target group Name in ascending order
        td.targetGroups.forEach((tg: TargetMetricGroup, ind: number) => {
          tg.groupName = `${PREFIX} ${ind + 1}`;
        });

        if (
          td.targetGroups.filter(
            (tgr) => !tgr.metrics.every((met) => met.metricValue !== "*")
          ).length > 0 &&
          !hasEmptyValues
        )
          setHasEmptyValues(true);
      });
    } else {
      // get baseline company targets
      tmpTargetsDataProccesed.forEach((td: TargetData) => {
        if (td.companyData.isBaseCompany) {
          td.targetGroups = td.targetGroups.filter(
            (tg: TargetMetricGroup, ind: number) =>
              !tg.metrics.every(
                (m: TargetMetric, i: number) =>
                  m.metricValue === "*" || (i === 0 && ind !== 0)
              )
          );
          td.targetGroups.forEach((tg: TargetMetricGroup, ind: number) => {
            tg.groupName = `${PREFIX} ${ind + 1}`;
          });

          if (
            td.targetGroups.filter(
              (tgr) => !tgr.metrics.every((met) => met.metricValue !== "*")
            ).length > 0 &&
            !hasEmptyValues
          )
            setHasEmptyValues(true);
        }
      });
      //Remove targets
      let baselineCompanyData = tmpTargetsDataProccesed.filter(
        (td: TargetData) => td.companyData.isBaseCompany
      );
      let baselineCompanyMetrics =
        baselineCompanyData.length > 0 &&
        baselineCompanyData[0].targetGroups.length > 0
          ? baselineCompanyData[0].targetGroups.reduce(
              (prev: string[], current: TargetMetricGroup) =>
                [...prev].concat(
                  current.metrics.map((tm: TargetMetric) => tm.metricKey)
                ),

              []
            )
          : [];

      tmpTargetsDataProccesed.forEach((td: TargetData) => {
        if (!td.companyData.isBaseCompany) {
          td.targetGroups = td.targetGroups.filter((tg: TargetMetricGroup) =>
            tg.metrics.every((tm: TargetMetric) =>
              baselineCompanyMetrics.includes(tm.metricKey)
            )
          );
          td.targetGroups.forEach((tg: TargetMetricGroup, ind: number) => {
            tg.groupName = `${PREFIX} ${ind + 1}`;
          });
          if (
            td.targetGroups.filter(
              (tgr) => !tgr.metrics.every((met) => met.metricValue !== "*")
            ).length > 0 &&
            !hasEmptyValues
          )
            setHasEmptyValues(true);
        }
      });
    }

    return tmpTargetsDataProccesed;
  };

  const mapTableChart = () => {
    let groups = benchmarkMetadata.associatedMetrics;

    let labels = groups[0].groupValues.map((gv: string, i: Number) => {
      let tmpLabel =
        benchmarkMetadata.associatedMetricsDisplayNames[0].groupValues.filter(
          (dn: string, ind: number) => i === ind
        )[0];
      return {
        label: tmpLabel,
        id: gv.slice(-20),
        tooltip: associatedMetricGroupTooltips
          ? associatedMetricGroupTooltips.filter(
              ({ associatedMetricGroupName }: any) =>
                associatedMetricGroupName.toLowerCase() ===
                tmpLabel.toLowerCase()
            )
          : [],
      };
    });
    let tmpResponse = dataset.reduce((data: any[], current: InsightData) => {
      let currentGroup: any = {
        globalCompanyId: current.globalCompanyId,
        header:
          benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
            ? ""
            : current.companyName,
      };
      current.metrics.forEach((m: InsightMetricData) => {
        let metricProp =
          m.metricKey?.charAt(0).toLowerCase() + m.metricKey?.slice(1);
        let metricValue = m?.metricValue;
        currentGroup = {
          ...currentGroup,
          [metricProp]:
            metricValue &&
            metricProp.includes(keywordForNumberTypes[2]) &&
            !isNaN(+metricValue)
              ? `${metricValue}%`
              : metricValue,
        };
      });

      return [
        ...data,
        {
          ...currentGroup,
        },
      ];
    }, []);

    tmpResponse = groups.map(
      (g: { groupName: string; groupValues: string[] }) => {
        let tmpMetrics = g.groupValues.reduce((prev: any, curr: string) => {
          let metricValue = dataset[0].metrics.filter(
            (m: InsightMetricData) => m.metricKey === curr
          )[0].metricValue;
          return {
            ...prev,
            [curr.slice(-20)]: metricValue
              ? curr.slice(-20).includes(keywordForNumberTypes[2]) &&
                !isNaN(+metricValue)
                ? `${metricValue}%`
                : metricValue
              : null,
          };
        }, {});

        return {
          globalCompanyId: dataset[0].globalCompanyId,
          header: groups.length > 1 ? g.groupName : dataset[0].companyName,
          ...tmpMetrics,
        };
      }
    );

    tmpResponse = tmpResponse.filter((tr: any, i: number) => {
      let keyValues = Object.entries(tr);
      keyValues = keyValues.filter((kv) => !TABLE_PROPS.includes(kv[0]));
      return !keyValues.every(
        (kvalue) =>
          !kvalue[1] || (keywordForFirstMetric.includes(kvalue[0]) && i !== 0)
      );
    });
    tmpResponse.forEach((value, ind: number) => {
      value.header = `${PREFIX} ${ind + 1}`;
    });

    return {
      MSCIESGRatingView: false,
      tileType: metadata.benchmarkTileType,
      labels: labels,
      response: tmpResponse,
      isLeftAlinged: true,
    };
  };

  const tileProps = {
    currentInsightView: benchmarkType,
    isDetailedView: isDetailedView,
    isTableViewActive: isTableViewActive,
    data: mapChartData(),
    hasEmptyValues: hasEmptyValues,
  };

  const getTableViewForPeersIndustry = () => {
    let groups = benchmarkMetadata.associatedMetrics;

    let labels = groups[0].groupValues.map((gv: string, i: Number) => {
      let tmpLabel =
        benchmarkMetadata.associatedMetricsDisplayNames[0].groupValues.filter(
          (dn: string, ind: number) => i === ind
        )[0];
      return {
        label: tmpLabel,
        id: gv.slice(-20),
        tooltip: associatedMetricGroupTooltips
          ? associatedMetricGroupTooltips.filter(
              ({ associatedMetricGroupName }: any) =>
                associatedMetricGroupName.toLowerCase() ===
                tmpLabel.toLowerCase()
            )
          : [], //TBD,
      };
    });

    let tmpTargetsData = dataset.map((idv: InsightData) => {
      return groups.map((g: { groupName: string; groupValues: string[] }) => {
        let tmpMetrics = g.groupValues.reduce((prev: any, curr: string) => {
          let metricValue = idv.metrics.filter(
            (m: InsightMetricData) => m.metricKey === curr
          )[0].metricValue;

          return {
            ...prev,
            [curr.slice(-20)]: metricValue
              ? curr.slice(-20).includes(keywordForNumberTypes[2]) &&
                !isNaN(+metricValue)
                ? `${metricValue}%`
                : metricValue
              : null,
          };
        }, {});

        return {
          groupName: g.groupName,
          globalCompanyId: idv.globalCompanyId,
          header: idv.companyName,
          isBaseCompany: idv.isBaseCompany,
          ...tmpMetrics,
        };
      });
    });

    let targetsGroupNames: string[] = [];
    let tmpTargetsDataProccesed: any = JSON.parse(
      JSON.stringify(tmpTargetsData)
    );
    if (benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK) {
      //Find targets for each company and add target name dynamically
      tmpTargetsDataProccesed = tmpTargetsData.map((td: any) => {
        let tmpGroup = td.filter((tr: any, i: number) => {
          let keyValues = Object.entries(tr);
          keyValues = keyValues.filter(
            (kv) =>
              !TABLE_PROPS.includes(kv[0]) &&
              !keywordForFirstMetric.includes(kv[0])
          );
          return !keyValues.every(
            (kvalue) =>
              !kvalue[1] ||
              (kvalue[0].includes(keywordForFirstMetric) && i !== 0)
          );
        });
        tmpGroup.forEach(
          (tg: any, tgInd: number) => (tg.groupName = `${PREFIX}${tgInd + 1}`)
        );
        return tmpGroup;
      });
    }
    //Collect targets name
    tmpTargetsDataProccesed.forEach((td: any) => {
      let tmpGroup = td.filter((tr: any, i: number) => {
        let keyValues = Object.entries(tr);
        keyValues = keyValues.filter(
          (kv) =>
            !TABLE_PROPS.includes(kv[0]) &&
            !keywordForFirstMetric.includes(kv[0])
        );
        return !keyValues.every(
          (kvalue) =>
            !kvalue[1] || (kvalue[0].includes(keywordForFirstMetric) && i !== 0)
        );
      });

      targetsGroupNames =
        tmpGroup && tmpGroup.length > 0
          ? targetsGroupNames.concat(
              tmpGroup.map((tmpG: any) => tmpG.groupName)
            )
          : targetsGroupNames;
    });
    targetsGroupNames = Array.from(new Set(targetsGroupNames));

    //Validate baseline company targets for peer and industry chart
    if (benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY) {
      let bc = tmpTargetsDataProccesed.filter((ttd: any) =>
        ttd.every((d: any) => d.isBaseCompany)
      );
      if (bc.length > 0) {
        //baseline company targets
        let bcTargets = bc[0].filter((tr: any, i: number) => {
          let keyValues = Object.entries(tr);
          keyValues = keyValues.filter(
            (kv) =>
              !TABLE_PROPS.includes(kv[0]) &&
              !keywordForFirstMetric.includes(kv[0])
          );
          return !keyValues.every(
            (kvalue) =>
              !kvalue[1] ||
              (typeof kvalue[1] === "string" &&
                kvalue[1].includes(ENTITY_DEEMED_VALUE))
          );
        });
        if (bcTargets.length === 0) targetsGroupNames = [`${PREFIX}1`];
        else
          targetsGroupNames = bcTargets.map(
            (tmg: TargetMetricGroup) => tmg.groupName
          );
      }
      tmpTargetsDataProccesed = targetsGroupNames.map(
        (gn: string, i: number) => {
          return {
            groupName: `${PREFIX} ${i + 1}`,
            responses: tmpTargetsDataProccesed.map((ttd: any) => {
              return ttd.filter((d: any) => d.groupName === gn).length > 0
                ? ttd.filter((d: any) => d.groupName === gn)[0]
                : null;
            }),
          };
        }
      );
    } else {
      let getTargetTemplate = (data: any) => {
        return Object.entries(data).reduce((prev, current) => {
          return {
            ...prev,
            [current[0]]:
              TABLE_PROPS.includes(current[0]) ||
              keywordForFirstMetric.includes(current[0])
                ? current[1]
                : null,
          };
        }, {});
      };
      tmpTargetsDataProccesed = targetsGroupNames.map(
        (gn: string, i: number) => {
          return {
            groupName: `${PREFIX} ${i + 1}`,
            responses: tmpTargetsDataProccesed.map(
              (ttd: any, ttdpind: number) => {
                return ttd.filter((d: any) => d.groupName === gn).length > 0
                  ? ttd.filter((d: any) => d.groupName === gn)[0]
                  : {
                      ...getTargetTemplate(
                        tmpTargetsData.filter(
                          (ttdata: any, ind: number) => ttdpind === ind
                        )[0][0]
                      ),
                    };
              }
            ),
          };
        }
      );
    }

    let tableProps = {
      MSCIESGRatingView: false,
      tileType: metadata.benchmarkTileType,
      labels: labels,
      isLeftAlinged: true,
    };

    let targets = null;
    if (benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK) {
      targets = tmpTargetsDataProccesed.map((ttd: any) => {
        if (
          ttd.responses.filter((tr: any, i: number) => {
            let keyValues = Object.entries(tr).filter(
              (kv) => !TABLE_PROPS.includes(kv[0])
            );
            return keyValues.every((kvalue) => kvalue[1] !== "*");
          }).length === 0
        )
          setHasEmptyValues(true);

        let peertableProps = {
          ...tableProps,
          response: ttd.responses,
        };
        return (
          <div className={`${BLOCK}__table-view-target`}>
            {tmpTargetsDataProccesed.length > 1 && (
              <div className={`${BLOCK}__table-view-target-subtitle`}>
                {ttd.groupName}
              </div>
            )}
            <TableChart
              data={peertableProps}
              currentInsightView={benchmarkType}
              hasCustomNoDataAvailableMsg={true}
              leftAlignment={true}
              getCustomValue={getCustomValueForTableView}
            />
          </div>
        );
      });
    } else {
      let tmpResponse = tmpTargetsDataProccesed.reduce(
        (prev: any, curr: any) =>
          [...prev].concat(
            curr.responses.map((r: any) => ({
              ...r,
              groupName: curr.groupName,
            }))
          ),
        []
      );
      if (tmpResponse.length > 2) {
        tmpResponse = tmpResponse.map((tr: any, i: number) =>
          tr.isBaseCompany
            ? {
                ...tr,
                header: (
                  <>
                    {" "}
                    <div
                      style={{
                        marginBottom: "0.25rem",
                        fontWeight: 600,
                        lineHeight: "16px",
                        color: "#212121",
                      }}
                    >{`${tr.groupName}`}</div>
                    {`${tr.header}`}
                  </>
                ),
              }
            : tr
        );
      }

      let industryTableProps = {
        ...tableProps,
        response: tmpResponse,
      };

      targets = (
        <TableChart
          data={industryTableProps}
          currentInsightView={benchmarkType}
          hasCustomNoDataAvailableMsg={true}
          horizontalTable={
            industryTableProps.response &&
            industryTableProps.response.length > 2
          }
          leftAlignment={true}
          customTableClassName={classNames({
            [`${BLOCK}__table-view--no-dashed-line`]:
              industryTableProps.response &&
              industryTableProps.response.length > 2,
          })}
        />
      );
    }

    return (
      <div className={`${BLOCK}__table-view-target-wrapper`}>
        {(Array.isArray(targets) && targets.length > 0) ||
        (!Array.isArray(targets) && targets) ? (
          <>
            {targets}
            {hasEmptyValues && (
              <div className={`${BLOCK}__no-data-available`}>
                <FormattedMessage id="no.data.available" />
              </div>
            )}
          </>
        ) : (
          <div>
            <TileEmptyContent />
          </div>
        )}
      </div>
    );
  };

  const getCustomValueForTableView = (label: any, fieldValue: string) => {
    return fieldValue === "" || fieldValue === null ? (
      "*"
    ) : label.label === SCOPE_OF_TARGET ? (
      <>
        <span
          className={`targetsTileChart__scope-metric`}
          onMouseEnter={(e) => {
            if (
              (e.currentTarget as HTMLSpanElement).offsetHeight + 1 <
              (e.currentTarget as HTMLSpanElement).scrollHeight
            ) {
              handleMouseEnter(
                e,
                "span",
                <div>{Parser(sanitize(fieldValue ?? ""))}</div>,
                "",
                dispatch,
                null,
                MainTooltipPosition.BottomMiddle
              );
            }
          }}
          onMouseLeave={(e) => {
            handleMouseLeave(
              e,
              dispatch,
              mainTooltip.isOverTooltip ? mainTooltip.isOverTooltip : false
            );
          }}
        >
          {fieldValue}
        </span>
      </>
    ) : (
      fieldValue
    );
  };

  return (
    <div>
      {!isTableViewActive && <TargetsTileCharts {...tileProps} />}
      {/* {TO DO: HANDLE TABLE VIEW FOR PEER AND INDUSTRY IN CASE THOSE ARE DIFFERENT} */}
      {/* {TO DO: REMOVE BENCHMARK TYPE FLAG} */}
      {isTableViewActive ? (
        benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY ? (
          <div className={`${BLOCK}__table-view-target-wrapper`}>
            <TableChart
              data={mapTableChart()}
              currentInsightView={benchmarkType}
              horizontalTable={
                mapTableChart().response && mapTableChart().response.length > 1
              }
              leftAlignment={true}
              getCustomValue={getCustomValueForTableView}
              hasCustomNoDataAvailableMsg={true}
            />
            {hasEmptyValues && (
              <div className={`${BLOCK}__no-data-available`}>
                <FormattedMessage id="no.data.available" />
              </div>
            )}
          </div>
        ) : (
          getTableViewForPeersIndustry()
        )
      ) : null}
    </div>
  );
};

export default TargetsTile;
