import { Dispatch } from "redux";
import { PublishedSearchPayload } from "services/published-searchlist/published-searchlist.model";
import {
  setSharedWith,
  shareShortlistSuccess,
  shortlistSharePop,
} from "services/saved-shortlist/saved-shortlist.service";
import { DEFAULT_SAVED_SEARCHES_PAYLOAD } from "utils/constants";
import { createAction } from "../../utils/redux.utils";
import * as api from "./searchlist.api";
import {
  Search,
  SearchlistModel,
  SearchlistState,
  UserObj,
} from "./searchlist.model";
import { publishSearchType } from "../../utils/constants";
import { fetchPublishedSearch } from "services/published-searchlist/published-searchlist.service";

export const ACTIONS = {
  SEARCH_SAVE: "ESG/UI/SEARCH_SAVE",
  SEARCH_LIST_LOADING: "ESG/UI/SEARCH_LIST_LOADING",
  RESET_SEARCH_LIST: "ESG/UI/RESET_SEARCH_LIST",
  RESET_SAVED_SEARCH: "ESG/UI/RESET_SAVED_SEARCH",
  RESET_SEARCH_TO_SAVE: "ESG/UI/RESET_SEARCH_TO_SAVE",
  SAVED_SEARCH_RENAME_ERROR: "ESG/UI/SAVED_SEARCH_RENAME_ERROR",
  SAVED_SEARCH_RENAME_SUCCESS: "ESG/UI/SAVED_SEARCH_RENAME_SUCCESS",
  SAVED_SEARCH_SUCCESS: "ESG/UI/SAVED_SEARCH_SUCCESS",
  SAVE_NEW_SEARCH: "ESG/UI/SAVED_NEW_SEARCH",
  SAVED_SEARCH_RESULT: "ESG/UI/SAVED_SEARCH_RESULT",
  SEARCH_TO_SAVE: "ESG/UI/SEARCH_TO_SAVE",
  SHARE_SELECTED_USERS: "ESG/UI/SHARE_SELECTED_USERS",
  SHARE_FETCHED_USERS: "ESG/UI/SHARE_FETCHED_USERS",
  SHARE_SEARCH: "ESG/UI/SHARE_SEARCH",
  SHARE_LOADING: "ESG/UI/SHARE_LOADING",
  CURRENT_SHARE_SEARCH: "ESG/UI/CURRENT_SHARE_SEARCH",
  SAVE_SEARCH_LIST_LOADING: "ESG/UI/SAVE_SEARCH_LIST_LOADING",
  SAVE_SEARCH_LIST: "ESG/UI/SAVE_SEARCH_LIST",
  EDIT_SAVED_SEARCH_POPUP: "ESG/UI/EDIT_SAVED_SEARCH_POPUP",
  DELETE_SAVED_SEARCH_POPUP: "ESG/UI/DELETE_SAVED_SEARCH_POPUP",
  REFRESH_SAVED_SEARCHES_LIST: "ESG/UI/REFRESH_SAVED_SEARCHES_LIST",
  SHARED_SEARCH_COUNT: "ESG/UI/SHARED_SEARCH_COUNT",
  SHARE_SEARCH_SUCCESS: "ESG/UI/SHARE_SEARCH_SUCCESS",
  EXECUTE_SAVED_SEARCH: "ESG/UI/EXECUTE_SAVED_SEARCH",
  EXECUTE_SHARED_SEARCH: "ESG/UI/EXECUTE_SHARED_SEARCH",
  EDIT_SEARCH: "ESG/UI/EDIT_SEARCH",
  DELETE_SEARCH: "ESG/UI/DELETE_SEARCH",
  GET_TOPICS: "ESG/UI/GET_TOPICS",
  GET_NEW_TOPIC: "ESG/UI/GET_NEW_TOPIC",
  RESET_NEW_TOPIC: "ESG/UI/RESET_NEW_TOPIC",
  TOPIC_NAME_ERROR: "ESG/UI/ TOPIC_NAME_ERROR",
  SET_CURRENT_SAVED_SEARCH: "ESG/UI/SET_CURRENT_SAVED_SEARCH",
};

export const searchSave = createAction(ACTIONS.SEARCH_SAVE);
export const searchListLoading = createAction(ACTIONS.SEARCH_LIST_LOADING);
export const searchRenameError = createAction(
  ACTIONS.SAVED_SEARCH_RENAME_ERROR
);
export const searchRenameSearchSuccess = createAction(
  ACTIONS.SAVED_SEARCH_RENAME_SUCCESS
);
export const savedSearchResult = createAction(ACTIONS.SAVED_SEARCH_RESULT);
export const searchToSave = createAction(ACTIONS.SEARCH_TO_SAVE);
export const resetSavedSearch = createAction(ACTIONS.RESET_SAVED_SEARCH);
export const resetSearchToSave = createAction(ACTIONS.RESET_SEARCH_TO_SAVE);
export const saveNewSearch = createAction(ACTIONS.SAVE_NEW_SEARCH);
export const shareSelectedUsers = createAction(ACTIONS.SHARE_SELECTED_USERS);
export const shareFetchedUsers = createAction(ACTIONS.SHARE_FETCHED_USERS);
export const shareSearch = createAction(ACTIONS.SHARE_SEARCH);
export const shareSearchSuccess = createAction(ACTIONS.SHARE_SEARCH_SUCCESS);
export const shareLoading = createAction(ACTIONS.SHARE_LOADING);
export const currentShareSearch = createAction(ACTIONS.CURRENT_SHARE_SEARCH);
export const setEditSavedSearchPopUp = createAction(
  ACTIONS.EDIT_SAVED_SEARCH_POPUP
);
export const setDeleteSavedSearchPopUp = createAction(
  ACTIONS.DELETE_SAVED_SEARCH_POPUP
);
export const setRefreshSavedSearchesList = createAction(
  ACTIONS.REFRESH_SAVED_SEARCHES_LIST
);
export const savedSearchListLoading = createAction(
  ACTIONS.SAVE_SEARCH_LIST_LOADING
);
export const executeSavedSeach = createAction(ACTIONS.EXECUTE_SAVED_SEARCH);
export const executeSharedSeach = createAction(ACTIONS.EXECUTE_SHARED_SEARCH);
export const editSavedSearch = createAction(ACTIONS.EDIT_SEARCH);
export const deleteSaveSearch = createAction(ACTIONS.DELETE_SEARCH);
export const updateTopics = createAction(ACTIONS.GET_TOPICS);
export const updateNewTopic = createAction(ACTIONS.GET_NEW_TOPIC);
export const resetTopic = createAction(ACTIONS.RESET_NEW_TOPIC);
export const topicNameError = createAction(ACTIONS.TOPIC_NAME_ERROR);

export const saveSearchList = createAction(ACTIONS.SAVE_SEARCH_LIST);
export const sharedSearchCount = createAction(ACTIONS.SHARED_SEARCH_COUNT);
export const setCurrentSavedSearch = createAction(
  ACTIONS.SET_CURRENT_SAVED_SEARCH
);

export const saveSearch =
  (payload: PublishedSearchPayload) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchListLoading(true));
      if (payload.type !== "edit" && payload.type !== "publish") {
        dispatch(searchSave(true));
      }
      let response: any;
      if (payload.type === "publish") {
        response = await api.saveSearchlist({
          savedSearchId: payload.savedSearchId,
          name: payload.name,
          description: payload.description,
          searchCriteria: payload.searchCriteria,
          topic: payload.topic,
          hasPublished: payload.hasPublished,
        });
      } else {
        response = await api.saveSearchlist({
          savedSearchId: payload.savedSearchId,
          name: payload.name,
          description: payload.description,
          searchCriteria: payload.searchCriteria,
        });
      }

      if (
        payload.location &&
        payload.location === publishSearchType.publishedSearches
      ) {
        dispatch(fetchPublishedSearch());
      } else {
        if (
          payload.location &&
          payload.location !== publishSearchType.savedSearches
        ) {
          dispatch(savedSearchResult(response.data));
        }
        dispatch(searchRenameSearchSuccess(true));
        dispatch(setEditSavedSearchPopUp(false));
      }
      dispatch(searchListLoading(false));
      dispatch(resetSearchToSave());
    } catch (e) {
      dispatch(searchToSave(payload));
      dispatch(searchRenameError(true));
      dispatch(searchListLoading(false));
    }
  };

export const addNewTopic =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      const response = await api.getNewTopic(payload);
      dispatch(updateNewTopic(response.data));
      dispatch(topicNameError(false));
    } catch (e) {
      dispatch(topicNameError(true));
      dispatch(searchListLoading(false));
    }
  };

export const editTopic = (payload: any) => async (dispatch: Dispatch<any>) => {
  try {
    const response = await api.editTopic(payload);
    dispatch(updateNewTopic(response.data));
    dispatch(topicNameError(false));
  } catch (e) {
    dispatch(topicNameError(true));
    dispatch(searchListLoading(false));
  }
};

export const deleteSearch =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchListLoading(true));
      await api.deleteSavedSearch(payload);
      dispatch(deleteSaveSearch(true));
      dispatch(resetSavedSearch(null));
      dispatch(getSharedSearchCount());
      dispatch(setRefreshSavedSearchesList(true));
      dispatch(searchListLoading(false));
    } catch (e) {
      dispatch(searchListLoading(false));
    }
  };

export const fetchShareUsersList =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shareLoading(true));
      // API call to get the matching user list
      if (payload.contentText.trim().length !== 0) {
        const res = await api.getUsersList(payload);
        if (res.data) {
          dispatch(shareFetchedUsers(res.data));
        } else if (res.data === null) {
          dispatch(shareFetchedUsers([]));
        }
      }
      dispatch(shareLoading(false));
    } catch (e) {
      dispatch(shareLoading(false));
    }
  };

export const selectShareUsersList =
  (payload: UserObj[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shareLoading(true));
      dispatch(shareSelectedUsers(payload));
      dispatch(shareLoading(false));
    } catch (e) {
      dispatch(shareLoading(false));
    }
  };

export const shareSearchList =
  (payload: Search) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shareLoading(true));
      dispatch(savedSearchListLoading(true));
      // API call to send the share search to the backend
      await api.shareSearchlist(payload);
      dispatch(shareSearchSuccess(true));
      dispatch(currentShareSearch(null));
      dispatch(setSharedWith([]));
      dispatch(shortlistSharePop(null));
      dispatch(shareShortlistSuccess(true));
      dispatch(loadSavedSearchList(DEFAULT_SAVED_SEARCHES_PAYLOAD));
      dispatch(shareLoading(false));
    } catch (e) {
      dispatch(shareLoading(false));
      dispatch(savedSearchListLoading(false));
    }
  };

export const currentShareSearchItem =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shareLoading(true));
      dispatch(currentShareSearch(payload));
      dispatch(shareLoading(false));
    } catch (e) {
      dispatch(shareLoading(false));
    }
  };

export const loadSavedSearchList =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedSearchListLoading(true));
      const result = await api.fetchSavedSearchList(payload);
      dispatch(saveSearchList(result.data));
      dispatch(savedSearchListLoading(false));
    } catch (e) {
      dispatch(savedSearchListLoading(false));
    }
  };
export const loadSavedSearchListSort =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      // dispatch(savedSearchListLoading(true));
      // const res = await api.fetchSavedSearchList();

      const result = await api.fetchSavedSearchList(payload);
      dispatch(saveSearchList(result.data));
      // dispatch(savedSearchListLoading(false));
    } catch (e) {
      dispatch(savedSearchListLoading(false));
    }
  };
export const getSharedSearchCount = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(searchListLoading(true));
    const res = await api.getCount();
    dispatch(sharedSearchCount(res.data));
    dispatch(loadSavedSearchList(DEFAULT_SAVED_SEARCHES_PAYLOAD));
    dispatch(searchListLoading(false));
  } catch (e) {
    dispatch(searchListLoading(false));
  }
};

export const getTopics = () => async (dispatch: Dispatch<any>) => {
  try {
    const topics = await api.getAllTopics();
    dispatch(updateTopics(topics.data));
  } catch (e) {}
};

export const loadCurrentSavedSearch =
  (savedSearchId: string) => async (dispatch: Dispatch<any>) => {
    try {
      const response = await api.fetchSavedSearch(savedSearchId);
      dispatch(setCurrentSavedSearch(response.data));
    } catch (e) {}
  };

const searchlistModel = new SearchlistModel();

const searchlistReducer = (
  state: SearchlistState = searchlistModel.initialState(),
  action: any
): SearchlistState => {
  switch (action.type) {
    case ACTIONS.SEARCH_LIST_LOADING:
      return searchlistModel.setLoading(action.payload);
    case ACTIONS.SAVED_SEARCH_RENAME_ERROR:
      return searchlistModel.setErrorRename(action.payload);
    case ACTIONS.SAVED_SEARCH_RENAME_SUCCESS:
      return searchlistModel.setRenameSearchSuccess(action.payload);
    case ACTIONS.SAVE_NEW_SEARCH:
      return searchlistModel.setSaveNewSearch(action.payload);
    case ACTIONS.SAVED_SEARCH_SUCCESS:
      return searchlistModel.setSaveSuccess(action.payload);
    case ACTIONS.SAVED_SEARCH_RESULT:
      return searchlistModel.setSavedSearch(action.payload);
    case ACTIONS.SEARCH_TO_SAVE:
      return searchlistModel.setSearchToSave(action.payload);
    case ACTIONS.RESET_SEARCH_LIST:
      return searchlistModel.resetSearchList();
    case ACTIONS.RESET_SAVED_SEARCH:
      return searchlistModel.resetSavedSearch();
    case ACTIONS.RESET_SEARCH_TO_SAVE:
      return searchlistModel.resetSearchToSave();
    case ACTIONS.SHARE_SELECTED_USERS:
      return searchlistModel.setShareSelectedUsers(action.payload);
    case ACTIONS.SHARE_FETCHED_USERS:
      return searchlistModel.setShareFetchedUsers(action.payload);
    case ACTIONS.SHARE_LOADING:
      return searchlistModel.setShareLoading(action.payload);
    case ACTIONS.CURRENT_SHARE_SEARCH:
      return searchlistModel.setCurrentSearch(action.payload);
    case ACTIONS.SHARE_SEARCH_SUCCESS:
      return searchlistModel.setShareSearchSuccess(action.payload);
    case ACTIONS.SAVE_SEARCH_LIST_LOADING:
      return searchlistModel.savedSearchListLoading(action.payload);
    case ACTIONS.SAVE_SEARCH_LIST:
      return searchlistModel.savedSearchList(action.payload);
    case ACTIONS.EDIT_SAVED_SEARCH_POPUP:
      return searchlistModel.setEditSavedSearchPopUp(action.payload);
    case ACTIONS.DELETE_SAVED_SEARCH_POPUP:
      return searchlistModel.setDeleteSavedSearchPopUp(action.payload);
    case ACTIONS.REFRESH_SAVED_SEARCHES_LIST:
      return searchlistModel.setRefreshSavedSearchesList(action.payload);
    case ACTIONS.SHARED_SEARCH_COUNT:
      return searchlistModel.setSharedSearchCount(action.payload);
    case ACTIONS.EXECUTE_SAVED_SEARCH:
      return searchlistModel.setExecuteSavedSearch(action.payload);
    case ACTIONS.EXECUTE_SHARED_SEARCH:
      return searchlistModel.setExecuteSharedSearch(action.payload);
    case ACTIONS.EDIT_SEARCH:
      return searchlistModel.setEditSavedSearch(action.payload);
    case ACTIONS.DELETE_SEARCH:
      return searchlistModel.setDeleteSavedSearch(action.payload);
    case ACTIONS.GET_TOPICS:
      return searchlistModel.setTopics(action.payload);
    case ACTIONS.GET_NEW_TOPIC:
      return searchlistModel.setNewTopic(action.payload);
    case ACTIONS.RESET_NEW_TOPIC:
      return searchlistModel.resetNewTopic();
    case ACTIONS.TOPIC_NAME_ERROR:
      return searchlistModel.setTopicNameError(action.payload);
    case ACTIONS.SET_CURRENT_SAVED_SEARCH:
      return searchlistModel.setCurrentSavedSearch(action.payload);
    default:
      return state;
  }
};
export default searchlistReducer;
