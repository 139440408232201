import { ShortlistResult } from "./shortlist.model";
import api from "../../services/api.service";

// Create Shortlist, payload as above
export const createShortlist = (payload: ShortlistResult) => {
  return api
    .post(`shortlists/${payload.shortlistId}/shortlistItem`, payload)
    .then(({ data }) => data);
};

// Remove Shortlist Report
export const removeShortlistESG = (payload: ShortlistResult) => {
  return api
    .delete(
      `shortlists/${payload.shortlistId}/items/${payload.esgReport.reportId}`
    )
    .then(({ data }) => data);
};

// Remove Shortlist Filings
export const removeShortlistSEC = (payload: ShortlistResult) => {
  return api
    .delete(
      `shortlists/${payload.shortlistId}/items/${payload.sectionItem.filingId}/${payload.sectionItem.elementId}`
    )
    .then(({ data }) => data);
};

export const saveShortlist = (payload: {
  shortlistName: string;
  shortlistId: number;
}) => {
  return api.put(`/shortlists`, payload).then(({ data }) => data);
};

export const saveShortlistCopy = (payload: {
  shortlistName: string;
  shortlistId: number;
}) => {
  return api
    .put(`/shortlists/copy/shortlist`, payload)
    .then(({ data }) => data);
};

export const fetchESGShortListResults = (payload: any, shortlistId?: number) =>
  api
    .post(`/shortlists/${shortlistId ? shortlistId : 0}/esg/list`, payload)
    .then(({ data }) => {
      let reportList = data.data && data.data.reportList;
      reportList.reports = reportList.reports.map((report: any) => ({
        isSelected: false,
        ...report,
      }));

      data.data = {
        ...data.data,
        reportList,
      };

      return data;
    });

export const fetchSECShortListResults = (payload: any, shortlistId?: number) =>
  api
    .post(
      `/shortlists/${shortlistId ? shortlistId : 0}/secDisclosures/list`,
      payload
    )
    .then(({ data }) => {
      let secFilingList = data.data && data.data.secFilingList;
      secFilingList.secFilings = secFilingList.secFilings.map(
        (report: any) => ({
          isSelected: false,
          ...report,
        })
      );

      data.data = {
        ...data.data,
        secFilingList,
      };

      return data;
    });

export const fetchShortListResults = (payload: any, shortlistId?: number) =>
  api
    .post(`/shortlists/${shortlistId ? shortlistId : 0}/list`, payload)
    .then(({ data }) => {
      let shortlistItems = data.data && data.data.shortlistItems;
      shortlistItems = shortlistItems.map((report: any) => ({
        isSelected: false,
        ...report,
      }));

      data.data = {
        ...data.data,
        pageInfo: {
          ...data.data.pageInfo,
          totalSelectedCount: 0,
        },
        shortlistItems,
      };

      return data;
    });

export const exportShortlistGridXls = (payload: any) => {
  return api.post(`/exports`, payload).then(({ data }) => data);
};

export const exportShortlistGridPdf = (payload: any) => {
  return api.post(`/exports`, payload).then(({ data }) => data);
};
