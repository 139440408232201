import { Fragment } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import classNames from "classnames";
import { GRADIENT_UNIT_FOR_PROGRESSBAR } from "utils/constants";
import { isNumeric } from "utils/functions";
import ProgressBar from "./progress-bar";
import Popover from "components/shared/popover/popover";

type Props = {
  data: any;
  currentInsightView: number;
};

const DetailedScoreChart = ({ data }: Props) => {
  const BLOCK = "detailed-score-table";
  const colorCode = data.colorCodes.slice(0, 2);
  const gradient = true;

  let nodata: any = [];

  const hasNoData = data?.labels?.reduce(
    (acc: any, l: any) =>
      acc &&
      data?.response?.filter(
        (d: any) => d[l.id] === null || d[l.id] === undefined
      ).length === data.response.length,
    true
  );

  const hasMissingData = data?.labels?.reduce(
    (acc: any, l: any) =>
      acc ||
      data?.response?.filter(
        (d: any) => d[l.id] === null || d[l.id] === undefined
      ).length > 0,
    false
  );

  function generatePercentage(type: any, value: any) {
    if (type?.toLowerCase() === "score") {
      const score = ["D-", "D", "C-", "C", "B-", "B", "A-", "A"];

      const eachScore = 100 / score.length;
      return (score.indexOf(value) + 1) * eachScore;
    }

    if (type?.toLowerCase() === "cdpclimatechangescore") {
      const score = ["F", "D-", "D", "C-", "C", "B-", "B", "A-", "A"];

      const eachScore = 100 / score.length;
      return (score.indexOf(value) + 1) * eachScore;
    }
  }

  if (hasNoData || !data || !data.labels || data.labels.length === 0)
    return (
      <div className={`${BLOCK}__no-data`}>
        <span className="speedometer__no-data" style={{ fontWeight: "bold" }}>
          <FormattedMessage id="insights.cdp.no-data" />
        </span>
      </div>
    );

  return (
    <>
      <table
        className={classNames(`${BLOCK}__table--left`)}
        data-test="detailed-score-chart"
      >
        <thead>
          <tr>
            <td></td>
            {data.labels.map((l: any, i: number) => {
              return (
                <td key={`data-label-${i}`} className={`${BLOCK}__label data`}>
                  <span className={`${BLOCK}__label--left`}>
                    <Popover
                      metricClassName=""
                      displayText={l.label}
                      content={l.tooltip}
                      buttonClassName={`${BLOCK}__label-button`}
                    />
                  </span>
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.response.map((d: any, i: number) => (
            <tr
              className={classNames(`${BLOCK}__Detailrow`)}
              key={`table-row-${i}`}
            >
              <td>{d.header}</td>
              {data.labels.map((l: any, j: number) => {
                const fieldValue = isNumeric(d[l.id])
                  ? parseFloat(d[l.id] ?? 0).toLocaleString()
                  : d[l.id];
                const mainWidth =
                  fieldValue && l.id
                    ? !isNaN(fieldValue)
                      ? fieldValue
                      : generatePercentage(l.id, fieldValue)
                    : "";

                return (
                  <Fragment key={`table-data-${i}-${j}`}>
                    <td
                      className={classNames(`${BLOCK}__dataProgressBar data`)}
                      key={`data-data-${i}`}
                    >
                      {fieldValue ? fieldValue : "*"}
                      <ProgressBar
                        mainWidth={fieldValue && l.id ? mainWidth : ""}
                        baseWidth={""}
                        mainTooltip={
                          fieldValue ? fieldValue + " " + d.header : ""
                        }
                        baseTooltip={
                          fieldValue ? fieldValue + " " + d.header : ""
                        }
                        colors={
                          mainWidth &&
                          mainWidth < GRADIENT_UNIT_FOR_PROGRESSBAR * 2
                            ? colorCode[0]
                            : colorCode[1]
                        }
                        gradient={gradient}
                        isDetailedView={data.isDetailedView}
                      />
                    </td>
                  </Fragment>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      {hasMissingData || nodata.length > 0 ? (
        <div className={"DashboardTile__noDataAny"}>
          <FormattedMessage id="no.data.available" />
        </div>
      ) : null}
    </>
  );
};

export default DetailedScoreChart;
