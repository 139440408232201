import axios from "axios";
import api from "services/api.service";
import { POWERBI_BASE_URL } from "utils/constants";
import { Bookmark } from "./powerbi.model";

const apiForPowerBI = axios.create({
  baseURL: POWERBI_BASE_URL,
});

export const getAccessTokenForPowerBI = (refreshToken: boolean) => {
  return api
    .get(`powerbi?refreshToken=${refreshToken}`)
    .then(({ data }) => data);
};

export const getHumanCapitalBookmark = (dashboardType: number) =>
  api
    .get(`powerbi/bookmark/insightsCategoryId/${dashboardType}`)
    .then(({ data }) => data);
export const saveHumanCapitalBookmark = (payload: Bookmark) =>
  api.post(`powerbi/bookmark`, payload).then(({ data }) => data);

export const getEmbedToken = (
  workSpaceId: string,
  reportId: string,
  payload: any,
  headers: any
) => {
  return apiForPowerBI
    .post(`${workSpaceId}/reports/${reportId}/GenerateToken`, payload, {
      headers: {
        Authorization: `Bearer ${headers}`,
      },
    })
    .then(({ data }) => data);
};

export const getEmbedURL = (
  workSpaceId: string,
  reportId: string,
  headers: any
) => {
  return apiForPowerBI
    .get(`${workSpaceId}/reports/${reportId}`, {
      headers: {
        Authorization: `Bearer ${headers}`,
      },
    })
    .then(({ data }) => data);
};

export const getReportId = (
  workSpaceId: string,
  reportId: string,
  format: string,
  currentPageName: string,
  bookmarkState: string,
  headers: any
) => {
  return apiForPowerBI
    .post(
      `${workSpaceId}/reports/${reportId}/ExportTo`,
      {
        format: format,
        powerBIReportConfiguration: {
          defaultBookmark: {
            state: bookmarkState,
          },
          pages: [
            {
              pageName: currentPageName,
            },
          ],
        },
      },
      {
        headers: {
          Authorization: `Bearer ${headers}`,
        },
      }
    )
    .then(({ data }) => data);
};

export const getReportStatus = (
  workSpaceId: string,
  reportId: string,
  exportId: string,
  headers: any
) => {
  return apiForPowerBI
    .get(`${workSpaceId}/reports/${reportId}/exports/${exportId}`, {
      headers: {
        Authorization: `Bearer ${headers}`,
      },
    })
    .then(({ data }) => data);
};

export const getReport = (
  workSpaceId: string,
  reportId: string,
  exportId: string,
  headers: any
) => {
  return apiForPowerBI
    .get(`${workSpaceId}/reports/${reportId}/exports/${exportId}/file`, {
      headers: {
        Authorization: `Bearer ${headers}`,
      },
      responseType: "arraybuffer",
    })
    .then(({ data }) => data);
};
