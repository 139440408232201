import React, { useMemo } from "react";
import { Group } from "@visx/group";
import { Line, Circle, AreaClosed } from "@visx/shape";
import { LinearGradient } from "@visx/gradient";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { Point } from "@visx/point";
import { scaleLinear, scalePoint } from "@visx/scale";
import { withTooltip, defaultStyles } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { ScaleSVG } from "@visx/responsive";
import { GridRows } from "@visx/grid";
import { Legend } from "services/dashboard/dashboard.model";
import { lightColor } from "utils/constants";

const BLOCK = "DashboardTile";
const axisBaseOffset = -0.25;
const axisOffsetMultiplier = -0.45;

export type Props = {
  data: Legend[];
  width: number;
  height: number;
  showControls?: boolean;
  margin?: { top: number; right: number; bottom: number; left: number };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
};

type Vectors = [Point, Point][];

const LineChart = ({
  data,
  width,
  height,
  margin = { top: 15, right: 50, bottom: 36, left: 20 },
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
  handleGraphElementClicked,
}: Props & WithTooltipProvidedProps<Legend>) => {
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const tooltipStyles = {
    ...defaultStyles,
    backgroundColor: "rgba(0,0,0,0.9)",
    color: "white",
    fontSize: 15,
  };

  const xScale = useMemo(
    () =>
      scalePoint<string>({
        domain: data.map((d) => d.legendValue),
        range: [0, xMax],
      }),
    [xMax, data]
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [
          Math.min(0, ...data.map((d) => d.legendData)),
          Math.max(...data.map((d) => d.legendData)),
        ],
        range: [yMax, 0],
      }),
    [yMax, data]
  );

  const getY = (data: number) => yScale(data) + margin.top;
  const getX = (value: string) => Number(xScale(value)) + margin.left;

  if (width < 10) return null;

  const lineVectors = data.reduce<Vectors>((vectors, d, index) => {
    if (index === data.length - 1) return vectors;

    return [
      ...vectors,
      [
        new Point({
          x: getX(d.legendValue),
          y: getY(d.legendData),
        }),
        new Point({
          x: getX(data[index + 1].legendValue),
          y: getY(data[index + 1].legendData),
        }),
      ],
    ];
  }, []);

  let tooltipTimeout: number;

  return (
    <div className="limit-size">
      <ScaleSVG width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <AxisLeft
            hideTicks
            hideAxisLine
            left={margin.left}
            top={margin.top}
            scale={yScale}
            stroke={lightColor}
            tickStroke={lightColor}
            axisClassName={`${BLOCK}__axis`}
            tickComponent={(tickProps) => {
              const valueLength = tickProps.formattedValue
                ? tickProps.formattedValue.length
                : 1;

              const dx = axisBaseOffset + valueLength * axisOffsetMultiplier;

              return (
                <text
                  fontFamily="Open Sans"
                  fontSize={12}
                  textAnchor="start"
                  dx={`${dx}em`}
                  dy={tickProps.dy}
                  x={tickProps.x}
                  y={tickProps.y}
                >
                  <tspan x={tickProps.x} dy={tickProps.dy}>
                    {tickProps.formattedValue}
                  </tspan>
                </text>
              );
            }}
          />
          <AxisBottom
            hideTicks
            hideAxisLine
            top={yMax + margin.top}
            left={margin.left}
            axisClassName={`${BLOCK}__axis`}
            scale={xScale}
            stroke={lightColor}
            tickStroke={lightColor}
            orientation="bottom"
            tickComponent={(tickProps) => {
              return (
                <text
                  fontFamily="Open Sans"
                  fontSize={12}
                  textAnchor="middle"
                  dx={tickProps.dx}
                  dy={tickProps.dy}
                  x={tickProps.x}
                  y={tickProps.y}
                >
                  <tspan x={tickProps.x} dy={tickProps.dy}>
                    {tickProps.formattedValue}
                  </tspan>
                </text>
              );
            }}
          />
          <GridRows
            scale={yScale}
            width={xMax}
            left={margin.left}
            top={margin.top}
          />
          <LinearGradient
            id="line-grid-area-gradient"
            from={"#227786"}
            to={"#227786"}
            fromOpacity={0.3}
            toOpacity={0}
          />
          <AreaClosed<Legend>
            data={data}
            x={(d) => getX(d.legendValue)}
            y={(d) => getY(d.legendData)}
            yScale={yScale}
            fill="url(#line-grid-area-gradient)"
          />
          {lineVectors.map(([from, to], i) => (
            <Line
              key={`line-${from.x}-${from.y}-${i}`}
              from={from}
              to={to}
              stroke="#227786"
              strokeWidth={2}
            />
          ))}
          {data.map((data, i) => (
            <Circle
              key={`point-${data.legendValue}-${i}`}
              className="dot"
              cx={getX(data.legendValue)}
              cy={getY(data.legendData)}
              r={3}
              fill="#FFFFFF"
              stroke="#227786"
              strokeWidth={2}
              style={handleGraphElementClicked ? { cursor: "pointer" } : {}}
              onClick={() =>
                handleGraphElementClicked
                  ? handleGraphElementClicked(data)
                  : null
              }
              onMouseLeave={() =>
                (tooltipTimeout = window.setTimeout(() => {
                  hideTooltip();
                }, 100))
              }
              onMouseMove={() => {
                if (tooltipTimeout) clearTimeout(tooltipTimeout);
                showTooltip({
                  tooltipData: data,
                  tooltipTop: getY(data.legendData),
                  tooltipLeft: getX(data.legendValue),
                });
              }}
            />
          ))}
        </Group>
      </ScaleSVG>
      {tooltipOpen && tooltipData && (
        <div
          style={{
            ...tooltipStyles,
            top: tooltipTop,
            left: tooltipLeft,
            position: "absolute",
          }}
        >
          <div>{`${tooltipData.legendValue} (${tooltipData.legendData})`}</div>
        </div>
      )}
    </div>
  );
};

export default withTooltip<Props, Legend>(LineChart);
