import EnergyChart from "components/visualizations/charts/energy.chart";
import { Legend } from "services/dashboard/dashboard.model";
import {
  InsightCompanyFlat,
  InsightData,
} from "services/insights/insights.model";
import TileEmptyContent from "../tile-empty-content";
import { useTileContext } from "../tile.context";

//common mapping func
export const mapEnergySpendData = (dataset: any) => {
  return dataset.length
    ? dataset
        .map((d: InsightData, i: number) => ({
          legendValue: d.metrics[0].metricValue ? d.metrics[0].metricValue : "",
          legendOrder: i,
          legendColor: "",
          legendData: "",
          legendTitle: d.companyName,
          legendTooltip:
            d.isBaseCompany && d.metrics[0].metricTooltip
              ? d.metrics[0].metricTooltip
              : [],
        }))
        .sort((a: Legend, b: Legend) => a.legendOrder - b.legendOrder)
    : [];
};

// common mapping func for companies
export const mapCompanies = (originalDataset: InsightData[]) =>
  originalDataset.map((company: InsightData) => ({
    externalCompanyId: company.externalCompanyId,
    globalCompanyId: company.globalCompanyId,
    companyName: company.companyName,
    isBaseCompany: company.isBaseCompany,
    industryId: company.industryId,
    industryName: company.industryName,
  }));

const EnergyConsumptionTotals = () => {
  const {
    metadata,
    dataset,
    originalDataset,
    metadata: { benchmarkType, isDetailedView },
    isTableViewActive,
  } = useTileContext();

  if (mapEnergySpendData(dataset).every((d: Legend) => d.legendValue === "")) {
    return (
      <div>
        <TileEmptyContent />
      </div>
    );
  }

  const companies = mapCompanies(originalDataset);

  const tileProps = {
    data: mapEnergySpendData(dataset),
    benchmarkTileType: metadata.benchmarkTileType,
    isDetailedView: isDetailedView,
    isTableView: isTableViewActive,
    currentInsightView: benchmarkType,
    baselineCompany: {
      company: {
        companyName: companies.filter(
          (c: InsightCompanyFlat) => c.isBaseCompany
        )[0].companyName,
      },
    },
  };

  return <EnergyChart {...tileProps} />;
};

export default EnergyConsumptionTotals;
