import React, { useState, useEffect, useRef } from "react";
import Modal from "../modal/modal";
import FormattedMessage from "../formatted-message/formatted-message";
import classNames from "classnames";
import Button from "../button/button";

type props = {
  title: string;
  nameLabel: string;
  nameValue: string;
  namePlaceholder?: string;
  descriptionValue?: string;
  duplicateNameMessage: string;
  hasDescription?: boolean;
  hasError: boolean;
  handleCloseModal: () => void;
  editItem: (name: string, description?: string) => void;
};

const EditModal = ({
  title,
  nameLabel,
  nameValue,
  namePlaceholder,
  descriptionValue,
  hasDescription,
  hasError,
  duplicateNameMessage,
  handleCloseModal,
  editItem,
}: props) => {
  const BLOCK = "EditModal";
  const [counts, setCounts] = useState({ name: 0, description: 0 });
  const [disabled, setDisabled] = useState(true);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const [values, setValues] = useState({ name: "", description: "" });
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    nameInputRef.current!.focus();

    if (nameValue) {
      setValues({
        name: nameValue,
        description: descriptionValue ? descriptionValue : "",
      });

      setCounts({
        name: nameValue.length,
        description: descriptionValue ? descriptionValue.length : 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasError) {
      setErrorMessage(true);
      setDisabled(true);
      nameInputRef.current!.focus();
      if (nameValue) {
        setValues({
          name: nameInputRef.current!.value,
          description: descriptionValue ? descriptionValue : "",
        });

        setCounts({
          name: nameInputRef.current!.value.length,
          description: descriptionValue ? descriptionValue.length : 0,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  const trimStartFunc = (value: any) => {
    if (!value) return value;
    return value.replace(/^\s+/g, "");
  };

  const onChangeHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCounts({
      ...counts,
      [event.target.name]: trimStartFunc(event.target.value).length,
    });
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "name") {
      setDisabled(event.target.value.trim().length === 0);
    }

    if (
      (nameValue === nameInputRef.current!.value.trim() &&
        descriptionValue === descriptionInputRef.current!.value.trim()) ||
      nameInputRef.current!.value.trim().length === 0
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setErrorMessage(false);
  };

  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      editHandler();
    }
  };

  const editHandler = () => {
    if (nameInputRef.current!.value.trim().length === 0 || disabled) {
      return;
    }
    editItem(
      trimStartFunc(nameInputRef.current!.value).trim(),
      descriptionInputRef.current!.value.trim()
    );
    setDisabled(true);
  };

  return (
    <Modal
      show={true}
      header={title}
      handleClose={handleCloseModal}
      config={false}
      data-testid="edit-modal"
    >
      <div className={`${BLOCK} ${BLOCK}--saveSearchlist`}>
        <span
          className={classNames(`${BLOCK}__name--saveSearchList`, {
            [`${BLOCK}__name`]: !errorMessage,
            [`${BLOCK}__nameError`]: errorMessage,
          })}
        >
          <FormattedMessage id={nameLabel} />
        </span>
        <span
          className={classNames({
            [`${BLOCK}__input`]: !errorMessage,
            [`${BLOCK}__inputError`]: errorMessage,
            [`${BLOCK}__input--saveSearchList`]: !errorMessage,
            [`${BLOCK}__inputError--saveSearchList`]: errorMessage,
          })}
        >
          <input
            type="text"
            ref={nameInputRef}
            maxLength={200}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={values.name}
            name="name"
            data-testid="name-input"
            placeholder={namePlaceholder || "Enter name"}
            className={classNames({
              [`${BLOCK}__input--saveSearchList`]: !errorMessage,
              [`${BLOCK}__inputError--saveSearchList`]: errorMessage,
            })}
          />
        </span>

        {errorMessage ? (
          <span
            className={`${BLOCK}__contentError ${BLOCK}__contentError--saveSearchList`}
            data-testid="error-message"
          >
            <FormattedMessage id={duplicateNameMessage} />
          </span>
        ) : (
          <span
            className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}
          >
            {counts.name}
            <FormattedMessage id="shortlist.save.input.character" />
          </span>
        )}

        {hasDescription && (
          <>
            <span
              className={classNames(
                `${BLOCK}__name--saveSearchList ${BLOCK}__name ${BLOCK}__description-label`
              )}
            >
              <FormattedMessage id="searchlist.save.description" />
            </span>
            <span
              className={classNames(
                `${BLOCK}__input ${BLOCK}__input--saveSearchList`
              )}
            >
              <textarea
                ref={descriptionInputRef}
                maxLength={2000}
                onChange={onChangeHandler}
                onKeyDown={onEnter}
                value={values.description}
                data-testid="descriptionTextArea"
                placeholder="Enter description"
                className={classNames(`${BLOCK}__description`)}
                name="description"
              />
            </span>
            <span
              className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}
            >
              {counts.description}
              <FormattedMessage id="comparison.description.character.limit" />
            </span>
          </>
        )}
        <div className={`${BLOCK}__options ${BLOCK}__options--saveSearchList`}>
          <Button
            className={`button-secondary`}
            onClick={handleCloseModal}
            dataTest="cancel-btn"
            formattedText="shortlist.save.button.cancel"
          />
          <Button
            className={`button-primary`}
            dataTest="edit-btn"
            formattedText="shortlist.save.button.save"
            disabled={disabled}
            onClick={editHandler}
          />
        </div>
      </div>
    </Modal>
  );
};
export default EditModal;
