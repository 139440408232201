import ReportingAndDisclosureTableView from "components/visualizations/charts/reporting.disclosure.table";
import ReportingDisclosureTile from "components/visualizations/charts/reporting.disclosure.tile";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import {
  INSIGHT_BENCHMARK_IDS,
  INSIGHT_BENCHMARK_ID_ARRAY,
  INSIGHT_BENCHMARK_TYPE,
  REPORTING_DISCLOSURE_METRIC_ORDER,
} from "utils/constants";
import { useTileContext } from "../tile.context";

const ReportingDisclosure = () => {
  const {
    response,
    dataset,
    isTableViewActive,
    metadata: {
      benchmarkMetadata,
      benchmarkTileType,
      benchmarkType,
      isDetailedView,
      associatedMetricGroupTooltips,
    },
    associatedMetrics,
    associatedMetricsDisplayNames,
  } = useTileContext();
  const getReportingTileMetricValue = (
    dataValue: any,
    metricId: number,
    globalCompanyId: number
  ) => {
    if (!dataValue) return null;
    if (
      (metricId === REPORTING_DISCLOSURE_METRIC_ORDER.organizational_boundary ||
        metricId === REPORTING_DISCLOSURE_METRIC_ORDER.reporting_standard ||
        metricId ===
          REPORTING_DISCLOSURE_METRIC_ORDER.climate_disclosure_out_cdp) &&
      !INSIGHT_BENCHMARK_ID_ARRAY.includes(globalCompanyId)
    )
      return "yes";

    return dataValue.metricValue;
  };

  const getReportingTileMetrics = (
    metric: any,
    metricId: number,
    globalCompanyId: number
  ) => {
    const metricList =
      !INSIGHT_BENCHMARK_ID_ARRAY.includes(globalCompanyId) &&
      metric.groupMetricsExcpectedValue &&
      metric.groupMetricsExcpectedValue.length
        ? metric.groupMetricsExcpectedValue
        : metric.groupValues;

    const metricsData = dataset
      .find((d) => d.globalCompanyId === globalCompanyId)
      ?.metrics.filter((m) => metric.groupValues.includes(m.metricKey));

    if (
      metricId === 3 &&
      (globalCompanyId === INSIGHT_BENCHMARK_IDS.INDUSTRY ||
        globalCompanyId === INSIGHT_BENCHMARK_IDS.PEER)
    ) {
      let distinctData: any[] = [];
      const valueSet = new Set();
      metricsData?.forEach((metricData: any) => {
        if (!valueSet.has(metricData.metricValue)) {
          valueSet.add(metricData.metricValue);
          distinctData.push({
            metric: metricData.metricName,
            value: metricData.metricValue,
          });
        }
      });
      return distinctData;
    }
    return metricList.map((metricName: string, i: number) => {
      const datasetMetric = dataset
        .find((d) => d.globalCompanyId === globalCompanyId)
        ?.metrics.filter((m) => metric.groupValues.includes(m.metricKey))
        .find(
          (m) =>
            ([0, 1, 3].includes(metricId) &&
            !INSIGHT_BENCHMARK_ID_ARRAY.includes(globalCompanyId)
              ? m.metricValue
              : m.metricKey) === metricName
        );

      return {
        metric: metricName,
        tooltip: datasetMetric?.metricTooltip,
        value: getReportingTileMetricValue(
          datasetMetric,
          metricId,
          globalCompanyId
        ),
      };
    });
  };

  const mapReporingTileData = () =>
    associatedMetrics.map((metric: any, i: number) => ({
      groupName: metric.groupName,
      groupTooltip: !associatedMetricGroupTooltips
        ? []
        : associatedMetricGroupTooltips?.filter(
            ({ associatedMetricGroupName }: AssociatedMetricTooltips) =>
              metric.groupName === associatedMetricGroupName
          ),
      data: dataset
        .filter((c: any) =>
          i === REPORTING_DISCLOSURE_METRIC_ORDER.reporting_period
            ? !INSIGHT_BENCHMARK_ID_ARRAY.includes(c.globalCompanyId)
            : true
        )
        .map((c: any) => ({
          globalCompanyId: c.globalCompanyId,
          companyName: c.companyName,
          metrics: getReportingTileMetrics(metric, i, c.globalCompanyId),
        })),
      sectionheader: associatedMetricsDisplayNames[i].groupName,
      metricName: associatedMetricsDisplayNames[i].groupValues,
    }));

  const getReportingTableMetricValue = (
    metric: any,
    company: any,
    data: any[],
    sectionId: number
  ) => {
    let metricValue = data.filter(
      (d: any) =>
        d.globalCompanyId === company.globalCompanyId &&
        metric.groupValues.includes(d.fieldName) &&
        (sectionId === 4 ? d.fieldValue && d.fieldValue !== null : true) &&
        (sectionId === 2 ? d.fieldValue && d.fieldValue !== null : true) &&
        (sectionId === 3 ? d.fieldValue && d.fieldValue !== null : true)
    );

    metricValue = metricValue.length
      ? metricValue.map((d: any) => {
          if (!d.fieldValue) return null;

          switch (sectionId) {
            case 4:
              return d.fieldValue.toLowerCase() === "no"
                ? d.fieldValue.toLowerCase()
                : d.fieldValue;

            default:
              return d.fieldValue;
          }
        })
      : [];

    if (
      sectionId === 3 &&
      (company.globalCompanyId === INSIGHT_BENCHMARK_IDS.INDUSTRY ||
        company.globalCompanyId === INSIGHT_BENCHMARK_IDS.PEER)
    ) {
      let distinctData: any[] = [];
      const valueSet = new Set();
      metricValue.forEach((item: any) => {
        if (!valueSet.has(item)) {
          valueSet.add(item);
          distinctData.push(item);
        }
      });
      metricValue = distinctData;
    }

    metricValue =
      metricValue.length && metricValue.every((m) => m === "no")
        ? ["-"]
        : metricValue.filter((m) => m !== "no");

    return metricValue.length
      ? metricValue.join(sectionId === 2 ? " - " : ",")
      : null;
  };

  const mapReportingTableData = () => {
    return {
      MSCIESGRatingView: false,
      isReportingTile: true,
      labels: associatedMetrics.map((metric: any, i: number) => ({
        label: metric.groupName,
        tooltip: !associatedMetricGroupTooltips
          ? []
          : associatedMetricGroupTooltips?.filter(
              ({ associatedMetricGroupName }: AssociatedMetricTooltips) =>
                metric.groupName === associatedMetricGroupName
            ),
        id: metric.groupName,
        groupValues: metric.groupValues,
        sectionHeader: (associatedMetricsDisplayNames[i] as any).groupName,
        groupMetric: (associatedMetricsDisplayNames[i] as any).groupValues,
      })),
      response: dataset.map((c: any) => ({
        header: c.companyName,
        globalCompanyId: c.globalCompanyId,
        companyName: c.companyName,
        data: associatedMetrics.map((metric: any, i: number) => {
          return {
            groupName: metric.groupName,
            sectionHeader: (associatedMetricsDisplayNames[i] as any).groupName,
            metrics: getReportingTileMetrics(metric, i, c.globalCompanyId),
          };
        }),
      })),
      tileType: 48,
    };
  };

  const mapReportingIndustryTableData = () => {
    return {
      labels: benchmarkMetadata.associatedMetricsTooltip.map(
        (metric: any, i: number) => ({
          label: metric.groupName,
          tooltip: !associatedMetricGroupTooltips
            ? []
            : associatedMetricGroupTooltips?.filter(
                ({ associatedMetricGroupName }: AssociatedMetricTooltips) =>
                  metric.groupName === associatedMetricGroupName
              ),
          id: metric.groupName,
          sectionHeader: (associatedMetricsDisplayNames[i] as any).groupName,
          groupValues: (associatedMetricsDisplayNames[i] as any).groupValues,
          groupMetric: (benchmarkMetadata.associatedMetrics[i] as any)
            .groupValues,
        })
      ),
      response: dataset.map((c: any) => ({
        header: c.companyName,
        globalCompanyId: c.globalCompanyId,
        companyName: c.companyName,
        ...Object.fromEntries(
          associatedMetrics.map((metric: any, i: number) => {
            return [
              metric.groupName,
              getReportingTableMetricValue(metric, c, response, i),
            ];
          })
        ),
      })),
      tileType: 48,
    };
  };

  const tileProps = {
    data: mapReporingTileData(),
    currentInsightView: benchmarkType,
    isDetailedView,
    benchmarkTileType,
  };

  return isTableViewActive ? (
    <ReportingAndDisclosureTableView
      data={
        benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY
          ? mapReportingIndustryTableData()
          : mapReportingTableData()
      }
      currentInsightView={benchmarkType}
    />
  ) : (
    <ReportingDisclosureTile {...tileProps} />
  );
};

export default ReportingDisclosure;
