import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { Fragment, useRef } from "react";

type Props = {
  data: any;
  currentInsightView: number;
  isDetailedView: boolean;
};
const BLOCK = "reporting";

export const ReportingMetric = ({
  metric,
  sectionId,
  isBaseCompany,
  cikNumber,
  isReportingBoundary,
}: any) => {
  const metricRef = useRef(null);

  return (
    <div
      className={classNames(
        `${BLOCK}__metric-wrapper`,
        `${BLOCK}__metric-wrapper--${
          sectionId === 1 && cikNumber < 0 ? 0 : sectionId
        }`,
        {
          [`${BLOCK}__metric-wrapper--${metric.value?.toLowerCase()}`]:
            sectionId === 1 && cikNumber < 0
              ? false
              : [1, 4].includes(sectionId),
        }
      )}
    >
      {sectionId === 4 || isReportingBoundary ? (
        <div ref={metricRef}>
          <Popover
            displayText={
              [1, 4].includes(sectionId) ? metric.metric : metric.value
            }
            metricClassName={classNames(
              `${BLOCK}__metric ${BLOCK}__metric-wrapper-lbl`,
              {
                [`general-neutral-value--color`]:
                  metric.value &&
                  metric.value.toLowerCase() === "neutral" &&
                  ![1, 4].includes(sectionId),
                [`${BLOCK}__metric-wrapper--${metric.value?.toLowerCase()}-lbl`]:
                  sectionId === 1 && cikNumber < 0
                    ? false
                    : [1, 4].includes(sectionId),
                [`${BLOCK}__metric-wrapper--${
                  sectionId === 1 && cikNumber < 0 ? 0 : sectionId
                }`]: !isReportingBoundary,
                [`${BLOCK}__metric--no-padding`]: isReportingBoundary,
              }
            )}
            content={metric.tooltip}
          />
        </div>
      ) : (
        <div
          ref={metricRef}
          className={classNames(`${BLOCK}__metric`, {
            [`general-neutral-value--color`]:
              metric.value &&
              metric.value.toLowerCase() === "neutral" &&
              ![1, 4].includes(sectionId),
          })}
        >
          {[1, 4].includes(sectionId)
            ? sectionId === 1 && cikNumber < 0
              ? metric.value
              : metric.metric
            : metric.value}
        </div>
      )}

      {metric.value && metric.value.toLowerCase() === "neutral" ? (
        <>
          <Tooltip position={TooltipPosition.bottom}>
            <FormattedMessage id="insights.tabular.neutral" />
          </Tooltip>
        </>
      ) : null}
      {(sectionId === 1 && cikNumber < 0
        ? false
        : [1, 4].includes(sectionId)) &&
      metric.value &&
      metric.value.toLowerCase() === "neutral" ? (
        <>
          <span
            className={classNames(`${BLOCK}__neutral-label`, {
              [`general-neutral-value--color`]:
                metric.value && metric.value.toLowerCase() === "neutral",
            })}
          >
            Neutral
          </span>
          <Tooltip position={TooltipPosition.bottom}>
            <FormattedMessage id="insights.tabular.neutral" />
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

const ReportingTile = ({ data, currentInsightView, isDetailedView }: Props) => {
  let showNoDataFootnote = false;
  const renderSection = (sectionData: any, sectionId: number) => (
    <div
      className={classNames(`${BLOCK}__section`, {
        [`${BLOCK}__section--horizontal`]: isDetailedView,
      })}
    >
      <span className={`${BLOCK}__section-name-container`}>
        <Popover
          displayText={sectionData.groupName}
          metricClassName={`${BLOCK}__section-name`}
          content={sectionData.groupTooltip}
        />
      </span>
      {sectionData.data.reduce(
        (acc: boolean, curr: any) =>
          acc &&
          curr.metrics.reduce(
            (acc: any, curr: any) => acc && curr.value === null,
            true
          ),
        true
      ) ? (
        <div className={`${BLOCK}__no-data`} data-test="No_data">
          <FormattedMessage id="insights.speedometer.no-data" />
        </div>
      ) : (
        sectionData.data.map((companyData: any, i: number) => {
          const noDataCompany = companyData.metrics.reduce(
            (acc: any, curr: any) => acc && curr.value === null,
            true
          );
          const hasNullMetric = companyData.metrics.some((metric: any) => metric.value === null);
          if (!showNoDataFootnote && hasNullMetric) showNoDataFootnote = true;
          const metrics =
            sectionId === 2 && companyData.metrics.length > 1
              ? [
                  {
                    ...companyData.metrics[0],
                    value: `${companyData.metrics[0].value ?? "*"} - ${companyData.metrics[1].value ?? "*"}`,
                  },
                ]
              : companyData.metrics;
          return (
            <div key={`section-data-${i}`} className={`${BLOCK}__company-row`}>
              {currentInsightView !== 4 ? (
                <span className={`${BLOCK}__company-name`}>
                  {companyData.companyName} {noDataCompany ? "*" : null}
                </span>
              ) : null}

              {noDataCompany ? null : (
                <div
                  className={`${BLOCK}__section-data ${BLOCK}__section-data--${
                    sectionId === 1 && companyData.globalCompanyId < 0
                      ? 0
                      : sectionId
                  }`}
                >
                  {metrics.map((metric: any, index: number) => (
                    <Fragment key={`section-metric-${i}-${index}`}>
                      <ReportingMetric
                        metric={metric}
                        sectionId={sectionId}
                        isBaseCompany={i === 0}
                        cikNumber={companyData.globalCompanyId}
                      />
                    </Fragment>
                  ))}
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
  return (
    <div
      className={classNames(BLOCK, {
        [`${BLOCK}--horizontal`]: isDetailedView,
      })}
      data-test="reporting-tile-container"
      data-testid="reporting-tile-container"
    >
      {data.map((d: any, i: number) => (
        <Fragment key={`section-data-${i}`}>{renderSection(d, i)}</Fragment>
      ))}
      {showNoDataFootnote ? (
        <span
          className={`${BLOCK}__no-data-footnote`}
          data-test="asterisk-No_data"
        >
          <FormattedMessage id="no.data.available" />
        </span>
      ) : null}
    </div>
  );
};

export default ReportingTile;
