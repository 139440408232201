import { TableRowItem } from "./table";
import classNames from "classnames";
import TableCell from "./table-cell";
import ShortlistItemNotes from "components/notes/shortlist-item-notes";

const TableRow = ({
  id,
  columnItems,
  rowSelected,
  isSelected,
  className,
  updateSelected,
  showItems,
  setShowItems,
  filterContent,
  onClick,
  tableRowClassName
}: TableRowItem) => {
  const BLOCK = "Table";
  return (
    <>
      <tr
        className={classNames(`${BLOCK}__row`, {
          [`${BLOCK}__row--selected`]: rowSelected,
          [`${BLOCK}__row-link`]: onClick,
          [`${className}`]: className,
        })}
        onClick={onClick}
      >
        {columnItems &&
          columnItems.map((item, index) => (
            <TableCell
              key={`${id}-${index}`}
              {...item}
              id={id}
              updateSelected={updateSelected}
              isSelected={item.checkbox ? isSelected : rowSelected}
              setShowNotes={setShowItems}
              filterContent={filterContent}
              tableRowClassName= {tableRowClassName}
            />
          ))}
      </tr>
      {showItems && showItems.filter((item: any) => item.id === id).length > 0 && (
        <tr>
          <td colSpan={10}>
            <ShortlistItemNotes
              setShowItems={setShowItems ? setShowItems : () => {}}
              referenceId={id}
              noteId={
                showItems
                  .filter((item: any) => item.id === id)
                  .map((item: any) => item.noteId)[0]
              }
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRow;
