import { GlobalCompanyGroup } from "services/insights/insights.model";
import { FILTER_OPTIONS } from "utils/constants";
import { BaseModel, errorModel } from "utils/redux.utils";

export interface FiltersState {
  error?: errorModel;
  loaded: boolean;
  showFilters: boolean;
  loadingFilter: FILTER_OPTIONS[];
  companyFilterOptions: CompanyFilter[];
  companyFilter: CompanyFilter[];
  contentFilterOptions: PopularSearchFilter[];
  contentFilter: PopularSearchFilter[];
  contentValue: string;
  documentTypeFilterOptions: DocumentTypeFilter[];
  documentTypeFilter: FormTypeFilter[];
  periodFilterOptions: PeriodFilter[];
  periodFilter: PeriodFilter[];
  industryFilterOptions: IndustryFilter[];
  industryFilter: IndustryFilter[];
  auditorFilterOptions: AuditorFilter[];
  auditorFilter: AuditorFilter[];
  governingBoardFilterOptions: GoverningBoardFilter[];
  governingBoardFilter: GoverningBoardFilter[];
  esgRatingFilterOptions: ESGRatingFilter[];
  esgRatingFilter: ESGRatingFilter[];
  suggestedPeerCompanyByCompanyFilter: SuggestedPeerCompaniesByCompanyFilter[];
  selectedPeerGroupItems: number[];
  selectedItemsMultiple: SelectedCheckboxMultiple[];
}

export class FiltersModel extends BaseModel {
  constructor() {
    super({
      loaded: false,
      showFilters: true,
      loadingFilter: [],
      companyFilterOptions: [],
      companyFilter: [],
      contentFilterOptions: [],
      contentFilter: [],
      contentValue: "",
      documentTypeFilterOptions: [],
      documentTypeFilter: [],
      periodFilterOptions: [],
      periodFilter: [],
      industryFilterOptions: [],
      industryFilter: [],
      auditorFilterOptions: [],
      auditorFilter: [],
      governingBoardFilterOptions: [],
      governingBoardFilter: [],
      esgRatingFilterOptions: [],
      esgRatingFilter: [],
      suggestedPeerCompanyByCompanyFilter: [],
      selectedPeerGroupItems: [],
      selectedItemsMultiple: []
    });
  }

  setLoaded = (loaded: boolean): FiltersState => this.merge({ loaded });

  setShowFilters = (showFilters: boolean): FiltersState =>
    this.merge({ showFilters });

  setLoadingFilter = (loadingFilter: FILTER_OPTIONS): FiltersState =>
    this.merge({ loadingFilter: [...this.state.loadingFilter, loadingFilter] });

  setError = (
    error: errorModel,
    loadingFilter?: FILTER_OPTIONS
  ): FiltersState => {
    let state = this.merge({ loading: false, error });
    if (loadingFilter) {
      state = this.merge({
        loadingFilter: this.state.loadingFilter.filter(
          (filter: FILTER_OPTIONS) => filter !== loadingFilter
        ),
      });
    }

    return state;
  };

  loadCompanyFilterOptions = (
    companyFilterOptions: CompanyFilter[]
  ): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      companyFilterOptions,
    });

  setCompanyFilter = (companyFilter: CompanyFilter[]): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      companyFilter,
    });

  loadContentFilterOptions = (
    contentFilterOptions: PopularSearchFilter[]
  ): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.CONTENT
      ),
      contentFilterOptions,
    });

  setContentFilter = (contentFilter: PopularSearchFilter[]): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.CONTENT
      ),
      contentFilter,
    });

  setContentValue = (contentValue: string): FiltersState =>
    this.merge({
      contentValue,
    });

  loadDocumentTypesFilterOptions = (
    documentTypeFilterOptions: DocumentTypeFilter[]
  ): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.DOCUMENT_TYPE
      ),
      documentTypeFilterOptions,
    });

  setDocumentTypesFilter = (
    documentTypeFilter: FormTypeFilter[]
  ): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.DOCUMENT_TYPE
      ),
      documentTypeFilter,
    });

  loadPeriodFilterOptions = (
    periodFilterOptions: PeriodFilter[]
  ): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.PERIOD
      ),
      periodFilterOptions,
    });

  setPeriodFilter = (periodFilter: PeriodFilter[]): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.PERIOD
      ),
      periodFilter,
    });

  loadIndustryFilterOptions = (
    industryFilterOptions: IndustryFilter[]
  ): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.INDUSTRY
      ),
      industryFilterOptions,
    });

  setIndustryFilter = (industryFilter: IndustryFilter[]): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.INDUSTRY
      ),
      industryFilter,
    });

  loadAuditorFilterOptions = (
    auditorFilterOptions: AuditorFilter[]
  ): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.AUDITOR
      ),
      auditorFilterOptions,
    });

  setAuditorFilter = (auditorFilter: AuditorFilter[]): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.AUDITOR
      ),
      auditorFilter,
    });

  loadGoverningBoardFilterOptions = (
    governingBoardFilterOptions: GoverningBoardFilter[]
  ): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.GOVERNING_BOARD
      ),
      governingBoardFilterOptions,
    });

  setGoverningBoardFilter = (
    governingBoardFilter: GoverningBoardFilter[]
  ): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.GOVERNING_BOARD
      ),
      governingBoardFilter,
    });

  loadESGRatingFilterOptions = (
    esgRatingFilterOptions: ESGRatingFilter[]
  ): FiltersState => {
    esgRatingFilterOptions = esgRatingFilterOptions.sort(
      (ratingA, ratingB) => ratingA.order - ratingB.order
    );

    return this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.ESG_RATING
      ),
      esgRatingFilterOptions,
    });
  };

  setESGRatingFilter = (esgRatingFilter: ESGRatingFilter[]): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.ESG_RATING
      ),
      esgRatingFilter,
    });

  resetFilter = (): FiltersState =>
    this.merge({
      loaded: false,
      showFilters: true,
      loadingFilter: [],
      companyFilterOptions: [],
      companyFilter: [],
      contentFilterOptions: [],
      contentFilter: [],
      contentValue: "",
      documentTypeFilterOptions: [],
      documentTypeFilter: [],
      periodFilterOptions: [],
      periodFilter: [],
      industryFilterOptions: [],
      industryFilter: [],
      auditorFilterOptions: [],
      auditorFilter: [],
      governingBoardFilterOptions: [],
      governingBoardFilter: [],
      esgRatingFilterOptions: [],
      esgRatingFilter: [],
    });

  loadSuggestedPeerCompanyByCompanyFilterOptions = (
    suggestedPeerCompanyByCompanyFilter: SuggestedPeerCompaniesByCompanyFilter[]
  ): FiltersState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.SUGGESTED_PEER
      ),
      suggestedPeerCompanyByCompanyFilter,
    });

  setSelectedPeerGroupItems= (selectedPeerGroupItems: number []): FiltersState => this.merge({selectedPeerGroupItems})
  setSelectedItemsMultiple= (selectedItemsMultiple: SelectedCheckboxMultiple []): FiltersState => this.merge({selectedItemsMultiple})

}

export type SelectedCheckboxMultiple = {
  parentCikNumber: number;
  selectedCompanies: any[];
};

export type CompanyFilter = {
  companyName: string;
  companyId: number;
  externalCompanyId?: number;
  cikNumber: number;
  ticker?: string;
  isSuggestedPeerCompany?: boolean;
  globalCompanyId?: number;
  countryCode?: string;
  isinNumber?: string;
  isinInt?:number;
  vendor? : number;
  vendorCollection?: {priority: number,vendorId: number,vendorName: string,color: string}[];
  subCompanies?: CompanyFilter[];
  isSelected?: boolean;
  disabled?: boolean;
  fromGlobalSearch?: boolean;
  isDefault?: boolean;
  displayOrder?: number;
  isCDPCompany?: boolean;
  isMSCICompany?: boolean;
  isRaterRankerCompany?: boolean;
  isSNPCompany?: boolean;
  globalCompanyMapping? : Partial<GlobalCompanyGroup>;
};

export type PopularSearchFilter = {
  popularSearchText: string;
  popularSearchName: string;
  popularSearchId: number;
  popularSearchType?: number;
  displayOrder?: number;
};

export type DocumentTypeFilter = {
  documentCategoryName: string;
  documentCategoryId: number;
  formTypes: FormTypeFilter[];
};

export type FormTypeFilter = {
  formTypeId: number;
  formTypeName: string;
  parentId?: number;
};

export type PeriodFilter = {
  periodYear: string;
  periodId: number;
};

export type Sectors = {
  industryId: number;
  externalIndustryId: string;
  sector: string;
};

export type IndustryFilter = {
  sector: string;
  sectors?: Sectors[];
  industryName?: string;
  parentId?: string;
  industryId: number;
  externalIndustryId: string;
};

export type AuditorFilter = {
  auditorName: string;
  auditorId: number;
  auditorNumber: number;
  externalAuditorId: number;
};

export type GoverningBoardFilter = {
  governingBoardName: string;
  governingBoardId: number;
};

export type ESGRatingFilter = {
  ivaCompanyRating: string;
  groupName: string;
  order: number;
  displayColor: string;
};

export type CurrentSearchedFilters = {
  companyFilter: CompanyFilter[];
  contentFilter: PopularSearchFilter[];
  contentValue: string;
  documentTypeFilter: FormTypeFilter[];
  periodFilter: PeriodFilter[];
  industryFilter: IndustryFilter[];
  auditorFilter: AuditorFilter[];
  governingBoardFilter: GoverningBoardFilter[];
};

export const findDocumentTypeFilterOption = (
  documentTypeFilterOptions: DocumentTypeFilter[],
  formTypeName: string
) => {
  const formTypes = documentTypeFilterOptions.reduce(
    (formTypes, filterOption) => {
      return [
        ...formTypes,
        ...filterOption.formTypes.map((formType) => ({
          ...formType,
          parentId: filterOption.documentCategoryId,
        })),
      ];
    },
    [] as FormTypeFilter[]
  );

  return formTypes.find((formType) => formType.formTypeName === formTypeName);
};

export type SuggestPeerCompanyFilterMultiple = {
  companyName: string;
  companyId: number;
  externalCompanyId: number;
  cikNumber: number;
  ticker: string;
  esgRating?: {
    esgRatingValue: string;
    order: number;
    displayColor: string;
  };
  governanceScore?: number;
  totalAssets: number;
  mrMarketCap: number;
  mrfyRevenue: number;
  auditor: {
    auditorId: number;
    auditorName: string;
    auditorNumber: number;
    externalAuditorId: number;
  };
  isSuggestedPeerCompany?: boolean;
  ivaCompanyRating: string;
  corpGovernanceScore: number;
  checked: boolean;
  globalCompanyId?: number; 
};

export type SuggestedPeerCompaniesByCompanyFilter = {
  companyName: string;
  cikNumber: number;
  suggestedPeerCompanyFilter: SuggestPeerCompanyFilterMultiple[];
  checked: boolean;
  globalCompanyId?: number; 
};
