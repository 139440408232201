import { useTileContext } from "../tile.context";
import CarbonCreditChart from "components/visualizations/charts/carbon.credit.chart";
import CarbonCreditTable from "components/visualizations/charts/carbon.credit.table";
import { valueSeparator } from "utils/constants";

const CabonCredit = () => {
  const {
    dataset,
    isTableViewActive,
    metadata: { benchmarkType, associatedMetricsTooltips, isDetailedView },
  } = useTileContext();
  const getMetricsDeatil = (metrics: any) => {
    const modifiedData: any = [];
    if (
      metrics.every(
        (met: any) => met.metricValue === null || met.metricValue === ""
      )
    ) {
      modifiedData.push(metrics.map((met: any) => ""));
    }
    let length = 0;
    metrics.forEach((met: any) => {
      if (met.metricValue !== null) {
        length = met.metricValue?.split(valueSeparator).length;
      }
    });
    for (let i = 0; i < length; i++) {
      modifiedData.push(
        metrics.map((met: any) =>
          met.metricValue ? met.metricValue?.split(valueSeparator)[i] : ""
        )
      );
    }
    return modifiedData;
  };

  const mapData = () => {
    const filterDataset = dataset.map((data: any) => ({
      groupName: data.companyName,
      metrics: data.metrics,
      metricsDetail: getMetricsDeatil(data.metrics),
    }));

    return filterDataset;
  };

  return isTableViewActive ? (
    <CarbonCreditTable
      tableData={mapData()}
      benchmarkType={benchmarkType}
      associatedMetricsTooltip={associatedMetricsTooltips}
    />
  ) : (
    <CarbonCreditChart
      chartData={mapData()}
      associatedMetricsTooltip={associatedMetricsTooltips}
      isDetailedView={isDetailedView}
    />
  );
};

export default CabonCredit;
