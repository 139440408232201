import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";

type Props = {
  data: any;
  metricGroupValues: any;
  associatedMetricsTooltips: AssociatedMetricTooltips[];
};

const ReportingTableChart = ({
  data,
  metricGroupValues,
  associatedMetricsTooltips,
}: Props) => {
  const BLOCK = "reporting-table";
  let showNoDataFootnote = false;

  if (!data)
    return (
      <div className={`${BLOCK}__no-data`}>
        <span className="speedometer__no-data" style={{ fontWeight: "bold" }}>
          <FormattedMessage id="insights.cdp.no-data" />
        </span>
      </div>
    );

  return (
    <div
      className={BLOCK}
      data-test="reporting-table-chart"
      data-testid="reporting-table-chart"
    >
      {data.map((d: any, sectionId: number) => (
        <div
          key={`reporting-table-${sectionId}`}
          className={`${BLOCK}__section`}
        >       
          <span
            className={`${BLOCK}__section-name`}
          >
            <Popover
              displayText={d.groupName}
              metricClassName={`${BLOCK}__section-name-lbl`}
              content={d.groupTooltip}
            />
          </span>
          <table className={`${BLOCK}__data`}>
            <tbody>
              {d.data.map((companyData: any, i: number) => {
                const metrics =
                  sectionId === 2 && companyData.metrics.length > 1
                    ? [
                        {
                          ...companyData.metrics[0],
                          value:
                            companyData.metrics[0].value &&
                            companyData.metrics[1].value
                              ? `${companyData.metrics[0].value} - ${companyData.metrics[1].value}`
                              : null,
                        },
                      ]
                    : companyData.metrics;
                let metricData: string[] = metrics
                  .filter((m: any) =>
                    [1, 4].includes(sectionId)
                      ? sectionId === 1 && companyData.globalCompanyId < 0
                        ? true
                        : m.value && m.value.toLowerCase() !== ""
                      : true
                  )
                  .map((m: any) =>
                    ![1, 4].includes(sectionId) ||
                    (sectionId === 1 && companyData.globalCompanyId < 0) ||
                    m.value.toLowerCase() === "no"
                      ? m.value
                      : m.value.toLowerCase() === "neutral"
                      ? `${m.metric} (${m.value})`
                      : m.metric
                  );
                if (
                  metricData &&
                  metricData.length > 0 &&
                  metricData.filter(
                    (d: string) => d && d.toLowerCase() === "no"
                  ).length === metricData.length
                )
                  metricData = ["-"];
                metricData = metricData.filter(
                  (d: string) => d && d.toLowerCase() !== "no"
                );
                const displayMetric =
                  metricData && metricData.length > 0
                    ? metricData.join(",")
                    : "*";
                if (!showNoDataFootnote && displayMetric === "*")
                  showNoDataFootnote = true;
                return (
                  <tr key={`reporting-data-${sectionId}-${i}`}>
                    <td className={`${BLOCK}__company`}>
                      {companyData.companyName}
                    </td>
                    <td className={`${BLOCK}__data`}>
                      <span className={`${BLOCK}__data-value`}>
                        {displayMetric === "-" ||
                        displayMetric.includes("(Neutral)") ? (
                          <>
                            {displayMetric}

                            <Tooltip position={TooltipPosition.bottom}>
                              {displayMetric === "-" ? (
                                <>None</>
                              ) : (
                                <FormattedMessage id="insights.tabular.neutral" />
                              )}
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            {metricData && metricData.length > 0
                              ? metricData?.map(
                                  (metricValue: any, index: number) => {
                                    if (index !== metricData.length - 1) {
                                      return (
                                        <span
                                          key={index}
                                        >
                                          <span
                                            className={`tableChart__fit-content-reporting`}
                                          >
                                            <Popover
                                              metricClassName={classNames(
                                                `tableChart__table-body-value tableChart__table-body-value--bold`
                                              )}
                                              displayText={
                                                metricData &&
                                                metricData.length > 0
                                                  ? metricValue + ","
                                                  : "*"
                                              }
                                              content={
                                                associatedMetricsTooltips
                                                  ? associatedMetricsTooltips.filter(
                                                      (
                                                        tooltip: AssociatedMetricTooltips
                                                      ) =>
                                                        tooltip.associatedMetric ===
                                                        metricValue
                                                    )
                                                  : []
                                              }
                                              buttonClassName={``}
                                            />
                                          </span>
                                        </span>
                                      );
                                    } else {
                                      return (
                                        <span
                                          key={index}
                                        >
                                          <span
                                            className={`tableChart__fit-content`}
                                          >
                                            <Popover
                                              metricClassName={classNames(
                                                `tableChart__table-body-value tableChart__table-body-value--bold`
                                              )}
                                              displayText={
                                                metricData &&
                                                metricData.length > 0
                                                  ? metricValue
                                                  : "*"
                                              }
                                              content={
                                                associatedMetricsTooltips
                                                  ? associatedMetricsTooltips.filter(
                                                      (
                                                        tooltip: AssociatedMetricTooltips
                                                      ) =>
                                                        tooltip.associatedMetric ===
                                                        metricValue
                                                    )
                                                  : []
                                              }
                                              buttonClassName={``}
                                            />
                                          </span>
                                        </span>
                                      );
                                    }
                                  }
                                )
                              : "*"}
                          </>
                        )}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ))}
      {showNoDataFootnote ? (
        <span className={`reporting__no-data-footnote`}>
          <FormattedMessage id="no.data.available" />
        </span>
      ) : null}
    </div>
  );
};

export default ReportingTableChart;
