import React from "react";
import { Legend } from "services/dashboard/dashboard.model";
import { GRAPH_OPTIONS } from "utils/constants";

interface Props {
  BLOCK: string;
  data?: Legend;
}

export type LegendMarker = (props: Props) => JSX.Element;

const DefaultLegendMarker: LegendMarker = ({ BLOCK, data }: Props) => (
  <span
    className={`${BLOCK}__data--color`}
    style={{ backgroundColor: data?.legendColor }}
    data-testid={`${BLOCK}__data--color`}
  />
);

const LineChartLegendMarker: LegendMarker = ({ BLOCK }: Props) => (
  <span className={`${BLOCK}__data--line-chart-color`} data-testid={`${BLOCK}__data--line-chart-color`}/>
);

const BubbleChartLegendMarker: LegendMarker = ({ BLOCK }: Props) => (
  <span className={`${BLOCK}__data--bubble-chart-color`} data-testid={`${BLOCK}__data--bubble-chart-color`}/>
);

export const getLegendMarker = (selectedGraphOption: string): LegendMarker => {
  if (selectedGraphOption === GRAPH_OPTIONS.line) {
    return LineChartLegendMarker;
  }

  if (selectedGraphOption === GRAPH_OPTIONS.bubble) {
    return BubbleChartLegendMarker;
  }

  // return here any other custom markers

  return DefaultLegendMarker;
};
