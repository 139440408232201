import { mapTableChart, useTileContext } from "../tile.context";
import TileEmptyContent from "../tile-empty-content";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import TableChart from "components/visualizations/charts/table-chart";
import {
  INSIGHT_BENCHMARK_TYPE,
  METRIC_VALUES,
  NEUTRAL_VALUES,
} from "utils/constants";
import classNames from "classnames";
import Popover from "components/shared/popover/popover";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useDispatch } from "react-redux";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";

const BiodiversityManagement = () => {
  const {
    dataset,
    metadata,
    metadata: {
      benchmarkType,
      isDetailedView,
      benchmarkMetadata,
      associatedMetricGroupTooltips,
      associatedMetricsTooltips,
      benchmarkTileType,
    },
    isTableViewActive,
  } = useTileContext();
  const BLOCK = "biodiversityMangament";
  const dispatch = useDispatch();
  if (
    dataset.length === 0 ||
    dataset.every((d: InsightData) =>
      d.metrics.every((m: InsightMetricData) => !m.metricValue)
    )
  )
    return <TileEmptyContent />;

  const hasEmptyValues = dataset.some((d: InsightData) =>
    d.metrics.some((m: InsightMetricData) => !m.metricValue)
  );

  const getPeerIndustryData = (): {
    metric: InsightMetricData;
    innerContent: any;
  }[] => {
    if (dataset && dataset.length > 0) {
      return dataset[0].metrics.map((m: InsightMetricData, mIndex: number) => {
        return {
          metric: m,
          innerContent: (
            <table className={`${BLOCK}__innerContent-table`}>
              <thead>
                <tr>
                  {dataset.map((comp: InsightData, compIndex: number) => (
                    <th key={`${compIndex}-${mIndex}-company`}>
                      {comp.companyName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {dataset.map((comp: InsightData, compIndex: number) => {
                    let compMetric = comp.metrics.find(
                      (compMetric: InsightMetricData) =>
                        compMetric.metricKey === m.metricKey
                    );

                    return compMetric && compMetric.metricValue ? (
                      <td
                        key={`${compIndex}-${mIndex}-value`}
                        className={classNames(`${BLOCK}__metric-value`, {
                          [`${BLOCK}__metric-value--no`]:
                            (compMetric.metricValue &&
                              compMetric.metricValue.search(
                                new RegExp("\\b" + "no" + "\\b", "i")
                              ) !== -1) ||
                            !compMetric.metricValue,
                        })}
                      >
                        <span
                          onMouseEnter={(e: any) => {
                            if (
                              NEUTRAL_VALUES.find(
                                (nv: string) =>
                                  compMetric &&
                                  compMetric.metricValue.toLowerCase() === nv
                              )
                            )
                              handleMouseEnter(
                                e,
                                "span",
                                <div>
                                  <FormattedMessage id="insights.tabular.neutral" />
                                </div>,
                                "",
                                dispatch,
                                null,
                                MainTooltipPosition.BottomMiddle
                              );
                          }}
                          onMouseLeave={(e) => {
                            handleMouseLeave(e, dispatch, false);
                          }}
                        >
                          {compMetric.metricValue}
                        </span>
                      </td>
                    ) : (
                      <td
                        key={`${compIndex}-${mIndex}-value`}
                        className={`${BLOCK}__metric-value ${BLOCK}__metric-value--no`}
                      >
                        *
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          ),
        };
      });
    }
    return [];
  };
  const graph = () => {
    const peerIndustryData =
      benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
        ? null
        : getPeerIndustryData();

    return (
      <div className={`${BLOCK}__container`}>
        {benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY ? (
          <>
            {dataset.map((d: InsightData) => {
              return d.metrics.map(
                (m: InsightMetricData, metricIndex: number) =>
                  metricBox(m, metricIndex)
              );
            })}
          </>
        ) : (
          <>
            {peerIndustryData && peerIndustryData.length > 0
              ? peerIndustryData.map(
                  (
                    d: {
                      metric: InsightMetricData;
                      innerContent: any;
                    },
                    dIndex: number
                  ) => metricBox(d.metric, dIndex, d.innerContent)
                )
              : null}
          </>
        )}
      </div>
    );
  };

  const detailedViewChart = () => {
    const metricKeys = metadata.benchmarkMetadata.associatedMetrics;
    const datasetForDetailedView =
      metricKeys.length &&
      metricKeys.map((metricKey: string) => {
        const companiesDataset = dataset.map((d: InsightData) => {
          return {
            metric: d.metrics.filter(
              (metric: InsightMetricData) => metric.metricKey === metricKey
            )[0],
            companyName: d.companyName,
            globalCompanyId: d.globalCompanyId,
          };
        });
        return {
          metricName: companiesDataset[0]?.metric?.metricName,
          companiesData: companiesDataset,
        };
      });
    return dataset && dataset.length > 0
      ? datasetForDetailedView &&
          datasetForDetailedView.length &&
          datasetForDetailedView.map((data: any, dataIndex: number) => (
            <div
              className={`${BLOCK}__DetailedMetric`}
              key={`${dataIndex}-data`}
            >
              <div className={`${BLOCK}__DetailedMetricName`}>
                <Popover
                  displayText={data.metricName}
                  content={
                    associatedMetricsTooltips?.length > 0
                      ? associatedMetricsTooltips?.filter(
                          (
                            associatedMetricsTooltip: AssociatedMetricTooltips
                          ) =>
                            associatedMetricsTooltip.associatedMetricDisplayName ===
                            data.metricName
                        )
                      : []
                  }
                  metricClassName={`${BLOCK}__detailedMetricName`}
                  buttonClassName={`${BLOCK}__detailedMetricBorder`}
                />
              </div>
              <div className={`${BLOCK}__DetailedCompanies`}>
                {data?.companiesData?.length > 0 &&
                  data?.companiesData.map(
                    (companyData: any, companyIndex: number) => {
                      return (
                        <div
                          className={`${BLOCK}__detailedCompanyData`}
                          key={`${dataIndex}-${companyIndex}-company`}
                        >
                          <div className={`${BLOCK}__detailedCompanyName`}>
                            {companyData?.companyName}
                          </div>
                          <div
                            className={classNames(
                              `${BLOCK}__detailedCompanyMetricValue`,
                              {
                                [`${BLOCK}__detailedCompanyMetricValue--no`]:
                                  (companyData?.metric?.metricValue &&
                                    companyData?.metric?.metricValue.search(
                                      new RegExp("\\b" + "no" + "\\b", "i")
                                    ) !== -1) ||
                                  !companyData?.metric?.metricValue,
                              }
                            )}
                          >
                            {companyData?.metric?.metricValue ?? "*"}
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          ))
      : null;
  };

  const metricBox = (
    metric: InsightMetricData,
    i: number,
    innerContent: any = null
  ) => {
    return (
      <div
        className={classNames(`${BLOCK}__metric-wrapper`, {
          [`${BLOCK}__metric-wrapper--2-width`]: i < 2,
        })}
        key={`${i}-metric`}
      >
        <div
          className={`${BLOCK}__metric-box ${BLOCK}__metric-box--border-bottom`}
        >
          <Popover
            displayText={metric.metricName}
            content={
              associatedMetricsTooltips?.length > 0
                ? associatedMetricsTooltips?.filter(
                    (associatedMetricsTooltip: AssociatedMetricTooltips) =>
                      associatedMetricsTooltip.associatedMetric ===
                      metric.metricKey
                  )
                : []
            }
            metricClassName={`${BLOCK}__metric-name`}
            buttonClassName={`${BLOCK}__popover-container`}
          />
        </div>
        <div
          className={classNames(`${BLOCK}__metric-box ${BLOCK}__metric-value`, {
            [`${BLOCK}__metric-value--no`]:
              ((metric.metricValue &&
                metric.metricValue.search(
                  new RegExp("\\b" + "no" + "\\b", "i")
                ) !== -1) ||
                !metric.metricValue) &&
              !innerContent,
            [`${BLOCK}__metric-box--y-padding`]: !innerContent,
            [`${BLOCK}__metric-box--min-height`]: !innerContent,
            [`${BLOCK}__metric-box--for-innerContent`]: innerContent,
          })}
        >
          {innerContent ? (
            innerContent
          ) : metric.metricValue ? (
            <span
              onMouseEnter={(e: any) => {
                if (
                  NEUTRAL_VALUES.find(
                    (nv: string) =>
                      metric && metric.metricValue.toLowerCase() === nv
                  )
                )
                  handleMouseEnter(
                    e,
                    "span",
                    <div>
                      <FormattedMessage id="insights.tabular.neutral" />
                    </div>,
                    "",
                    dispatch,
                    null,
                    MainTooltipPosition.BottomMiddle
                  );
              }}
              onMouseLeave={(e) => {
                handleMouseLeave(e, dispatch, false);
              }}
            >
              {metric.metricValue}
            </span>
          ) : (
            "*"
          )}
        </div>
      </div>
    );
  };
  return (
    <div data-testid={`${BLOCK}__container`} style={{width:"100%", height:"inherit"}}>
      {isDetailedView && !isTableViewActive ? (
        <div className={`${BLOCK}__graph-wrapper`}>
          <div
            data-testid={`${BLOCK}__Detailed-container`}
            className={`${BLOCK}__Detailed-container`}
          >
            {detailedViewChart()}
          </div>
          {hasEmptyValues && (
            <div className={`${BLOCK}__no-data-section`}>
              <span>
                <FormattedMessage id="no.data.available" />
              </span>
            </div>
          )}
        </div>
      ) : isTableViewActive ? (
        <TableChart
          data={mapTableChart(dataset, metadata)}
          currentInsightView={benchmarkType}
          leftAlignment={benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY}
          customTableClassName={
            benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY
              ? `${BLOCK}__table-chart`
              : ""
          }
          horizontalTable={benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY}
        />
      ) : (
        <div className={`${BLOCK}__graph-wrapper`}>
          {graph()}
          {hasEmptyValues && (
            <div className={`${BLOCK}__no-data-section`}>
              <span>
                <FormattedMessage id="no.data.available" />
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BiodiversityManagement;
