import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import Table, {
  TableHeaderItem,
  TableRowItem,
} from "components/shared/table/table";
import { useDispatch, useSelector } from "react-redux";
import {
  ApplicationAccess,
  MemberFirm,
  ModuleAccessList,
} from "services/module-access/module-access.model";
import { RootStore } from "services/store.service";
import MemberFirmDropdown from "./member-firm.drop-down";
import { useEffect } from "react";
import {
  loadModuleAccessData,
  updateModuleAccessToMemberFirm,
} from "services/module-access/module-access.service";

const ModuleAccess = () => {
  const BLOCK = "module-access";

  const dispatch = useDispatch();
  const moduleAccessState = useSelector(
    (state: RootStore) => state.moduleAccess
  );

  const tableHeaders: TableHeaderItem[] = [
    {
      text: "module-access.modules",
      isFormattedText: true,
    },
    {
      text: "module-access.member.firms",
      isFormattedText: true,
    },
  ];

  /*Get unique member firms from moduleAccessObject to populate in dropdown*/
  const getUniqueMemberFirms = (moduleAccessObject: ModuleAccessList) => {
    const uniqueMemberFirms =
      moduleAccessObject.memberFirmLevelAccessibleFeatures.reduce(
        (acc: MemberFirm[], cur: ApplicationAccess) => {
          if (!acc.find((x) => x.memberFirmCode === cur.memberFirmCode)) {
            acc.push({
              memberFirmCode: cur.memberFirmCode,
              isDefault: cur.isDefault,
              isEnabled: cur.isEnabled,
            });
          }
          return acc;
        },
        []
      );
    return uniqueMemberFirms;
  };

  const handleMemberFirmAccess = (
    memberFirm: MemberFirm,
    module: ModuleAccessList,
    isEnable: boolean
  ) => {
    dispatch(updateModuleAccessToMemberFirm(memberFirm, module, isEnable));
  };

  const mapTableRowData = (): TableRowItem[] => {
    return (
      moduleAccessState.results &&
      moduleAccessState.results.map((item) => {
        const dropdownOptions = getUniqueMemberFirms(item);
        const selectedMemberFirms = dropdownOptions.filter((x) => x.isEnabled);
        return {
          id: item.applicationModuleId,
          key: `${BLOCK}__${item.applicationModuleId}`,
          className: `${BLOCK}__table-row`,
          columnItems: [
            { text: item.applicationModuleName },
            {
              renderContent: () => {
                return (
                  <MemberFirmDropdown
                    className={`${BLOCK}__drop-down`}
                    placeholder={
                      <FormattedMessage id="module-access.member-firm.placeholder" />
                    }
                    loading={false}
                    objectKeys={{
                      name: "memberFirmCode",
                      id: "memberFirmCode",
                      isDefault: "isDefault",
                      isEnabled: "isEnabled",
                    }}
                    values={selectedMemberFirms}
                    options={dropdownOptions}
                    handleSelect={(memberFirm: MemberFirm) =>
                      handleMemberFirmAccess(memberFirm, item, true)
                    }
                    handleUnselect={(memberFirm: MemberFirm) =>
                      handleMemberFirmAccess(memberFirm, item, false)
                    }
                  />
                );
              },
            },
          ],
        };
      })
    );
  };

  /* Load Initial Data for Module Access */
  useEffect(() => {
    dispatch(loadModuleAccessData());
  }, [dispatch]);

  return (
    <div className={BLOCK} data-testid={BLOCK}>
      {/* Loader Section */}
      {moduleAccessState.loading && (
        <div
          className={`${BLOCK}__loading`}
          data-testid={`${BLOCK}-loading-icon`}
        >
          <Icon
            name="loading"
            height={50}
            width={50}
            className="loading-icon"
          />
        </div>
      )}
      {!moduleAccessState.loading && (
        <div
          className={`${BLOCK}__container`}
          data-testid={`${BLOCK}-container`}
        >
          {/* Header Section */}
          <div className={`${BLOCK}__header`}>
            <FormattedMessage id="module-access.title" />
          </div>
          {/* Table Grid */}
          <div
            className={`${BLOCK}__table-wrapper`}
            data-testid={`${BLOCK}-table-content`}
          >
            <Table
              className={`${BLOCK}__table-inner-wrapper`}
              innerClassName={`${BLOCK}__table`}
              headerItems={tableHeaders}
              rowItems={mapTableRowData()}
              dataTest={`${BLOCK}-table`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ModuleAccess;
