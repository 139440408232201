import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { useEffect, useState } from "react";
import {
  AssociatedMetricTooltips,
  CDPCategory,
  CDPScopeTabularDataset,
} from "services/dashboard/dashboard.model";
import { InsightCompanyFlat } from "services/insights/insights.model";
import {
  BENCHMARK_TILE_TYPES,
  INSIGHT_BENCHMARK_IDS,
  INSIGHT_BENCHMARK_TYPE,
  RISKS_VALUES,
} from "utils/constants";
import Popover from "components/shared/popover/popover";
import ReactDOMServer from "react-dom/server";

type PeerDataTable = {
  expand: boolean;
  globalCompanyId: number;
  companyName: string;
  categories: CDPCategory[];
};

type Risk = {
  associatedMetric: string;
  associatedMetricDisplayName: string;
  associatedMetricTooltip?: any;
};
type Props = {
  data: CDPScopeTabularDataset[];
  isTableView: boolean;
  cikNumberForCDP?: number;
  currentInsightView: number;
  associatedMetricGroupTooltips?: AssociatedMetricTooltips[];
  baselineCompany: InsightCompanyFlat;
  associatedMetricsTooltip?: any;
  benchmarkTileType?: number;
  associatedMetrics: any;
  associatedMetricsDisplayNames: any;
  isDetailedView: boolean;
};
const TabularCDPChartValid = ({
  data,
  isTableView,
  cikNumberForCDP,
  currentInsightView,
  associatedMetricGroupTooltips,
  baselineCompany,
  associatedMetricsTooltip,
  benchmarkTileType,
  associatedMetrics,
  associatedMetricsDisplayNames,
  isDetailedView,
}: Props) => {
  const BLOCK = "tabularCDPView";
  const MAX_LENGTH = 4;
  const [peers, setPeers] = useState<PeerDataTable[]>([]);
  const isRelevantRisks =
    benchmarkTileType === BENCHMARK_TILE_TYPES.RELEVANT_RISKS;
  const baselineCompanyMetrics = data.find(
    (item: CDPScopeTabularDataset) =>
      item.globalCompanyId === baselineCompany.globalCompanyId
  );
  const isCompanyView = currentInsightView === INSIGHT_BENCHMARK_TYPE.COMPANY;
  const halfIndex =
    isCompanyView &&
    baselineCompanyMetrics &&
    baselineCompanyMetrics.categories &&
    !isRelevantRisks
      ? Math.round(baselineCompanyMetrics!.categories!.length / 2)
      : 0;

  const rightSideTitle =
    currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK
      ? "Peers"
      : "Industry";

  const [leftData, setLeftData] = useState<any>();
  const [rightData, setRightData] = useState<any>();
  const [colsData, setColsData] = useState<any>(null);
  const [noData, setNoData] = useState<boolean>(false);
  const [noDataAtAll, setNoDataAtAll] = useState<boolean>(false);
  const [risks, setRisks] = useState([]);

  useEffect(() => {
    if (
      isTableView &&
      currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
      !isRelevantRisks
    ) {
      let peersData = data
        .filter(
          (item: CDPScopeTabularDataset) =>
            item.globalCompanyId !== baselineCompany.globalCompanyId &&
            item.globalCompanyId !== INSIGHT_BENCHMARK_IDS.PEER
        )
        .map((element: CDPScopeTabularDataset) => ({
          ...element,
          expand: false,
        }));

      setPeers(peersData);
    }

    const tmpleftData =
      isCompanyView &&
      baselineCompanyMetrics &&
      baselineCompanyMetrics.categories &&
      baselineCompanyMetrics.categories.length > MAX_LENGTH &&
      !isRelevantRisks
        ? {
            ...baselineCompanyMetrics,
            categories: baselineCompanyMetrics!.categories.slice(0, halfIndex),
          }
        : data.find(
            (item: CDPScopeTabularDataset) =>
              item.globalCompanyId === baselineCompany.globalCompanyId
          );

    setLeftData(tmpleftData);

    const orderedData = data.sort(
      (a: CDPScopeTabularDataset, b: CDPScopeTabularDataset) =>
        a.displayOrder - b.displayOrder
    );

    const tmpRightData =
      isCompanyView &&
      baselineCompanyMetrics &&
      baselineCompanyMetrics.categories &&
      baselineCompanyMetrics!.categories!.length > MAX_LENGTH &&
      !isRelevantRisks
        ? {
            ...baselineCompanyMetrics,
            categories: baselineCompanyMetrics!.categories.slice(
              halfIndex,
              baselineCompanyMetrics!.categories.length
            ),
          }
        : isRelevantRisks
        ? orderedData.filter(
            (item: CDPScopeTabularDataset) =>
              item.globalCompanyId !== baselineCompany.globalCompanyId
          )
        : data.find(
            (item: CDPScopeTabularDataset) =>
              item.globalCompanyId === cikNumberForCDP
          );

    setRightData(tmpRightData);

    if (isRelevantRisks) {
      let tmpRisks =
        associatedMetrics && associatedMetricsDisplayNames
          ? associatedMetrics.map((associatedMetric: any, i: number) => {
              return {
                associatedMetric: associatedMetric,
                associatedMetricDisplayName: associatedMetricsDisplayNames.find(
                  (name: any, ind: number) => ind === i
                ),
                associatedMetricTooltip: associatedMetricsTooltip.filter(
                  (am: AssociatedMetricTooltips) =>
                    am.associatedMetric === associatedMetric
                ),
              };
            })
          : [];
      setRisks(tmpRisks);
      if (isCompanyView) {
        tmpleftData &&
        tmpleftData.categories &&
        tmpleftData.categories.find((val: CDPCategory) => val.fieldValue)
          ? setNoDataAtAll(false)
          : setNoDataAtAll(true);

        (tmpleftData &&
          tmpleftData.categories &&
          tmpleftData.categories.find((val: CDPCategory) => !val.fieldValue)) ||
        tmpleftData === undefined ||
        tmpleftData.categories.length !== associatedMetrics.length
          ? setNoData(true)
          : setNoData(false);
      } else {
        setColsData(orderedData);

        (orderedData &&
          orderedData.length > 0 &&
          orderedData.find((element: CDPScopeTabularDataset) =>
            element.categories.find((val: CDPCategory) => !val.fieldValue)
          )) ||
        orderedData.length === 0 ||
        orderedData.find(
          (element: CDPScopeTabularDataset) =>
            element.categories.length !== associatedMetrics.length
        )
          ? setNoData(true)
          : setNoData(false);

        orderedData &&
        orderedData.length > 0 &&
        orderedData.find((element: CDPScopeTabularDataset) =>
          element.categories.find((val: CDPCategory) => val.fieldValue)
        )
          ? setNoDataAtAll(false)
          : setNoDataAtAll(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isCategoryMissed = (category: CDPCategory) => {
    return leftData?.categories
      .filter((category: CDPCategory) => category.fieldValue)
      .find((item: CDPCategory) => item.fieldNameId === category.fieldNameId)
      ? false
      : true;
  };

  const expandItemHandler = (
    id: number = 0,
    handleAll: boolean = false,
    expandAll: boolean = false
  ) => {
    let tmpPeers = [...peers];
    tmpPeers.forEach((peer: PeerDataTable) => {
      peer.expand = handleAll
        ? expandAll
        : peer.globalCompanyId === id
        ? !peer.expand
        : peer.expand;
    });

    setPeers(tmpPeers);
  };

  const getAccordionItem = (item: PeerDataTable, index: any) => {
    return (
      <div
        className={`${BLOCK}__accordion-item`}
        key={`accordion-item-${index}`}
      >
        <button
          className={classNames(`${BLOCK}__accordion-header`, {
            [`${BLOCK}__accordion-header--top-border`]: index === 0,
          })}
          onClick={() => {
            expandItemHandler(item.globalCompanyId);
          }}
        >
          <Icon
            name={item.expand ? "caret-down" : "caret-right"}
            width={11}
            height={11}
          />
          <span className={`${BLOCK}__accordion-header-text`}>
            {item.companyName}
          </span>
        </button>

        <div
          className={classNames(`${BLOCK}__accordion-collapsable`, {
            [`${BLOCK}__accordion-collapsable--show`]: item.expand,
          })}
        >
          <div className={`${BLOCK}__accordion-body`}>
            {item.categories?.filter((val: CDPCategory) => val.fieldValue)
              .length ? (
              <span
                className={`${BLOCK}__accordion-header-text ${BLOCK}__accordion-header-text--peer`}
              >
                {item.companyName}
              </span>
            ) : null}
            {item.categories?.filter((val: CDPCategory) => val.fieldValue)
              .length
              ? item.categories
                  .filter((val: CDPCategory) => val.fieldValue)
                  .map((val: CDPCategory, i: number) =>
                    getRowCategory(val, i, false, true)
                  )
              : null}
            {!isRelevantRisks &&
            item.categories?.filter((val: CDPCategory) => val.fieldValue)
              .length === 0 ? (
              <div className={`${BLOCK}__no-data-container`}>
                <span className={`${BLOCK}__label`}>
                  <FormattedMessage id="insights.cdp.no-data" />
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const baselineHasMissingCategories = (
    otherData: any,
    baseCompanyData: any
  ) => {
    if (otherData && !baseCompanyData) return true;
    if (!otherData || !otherData.categories || !baseCompanyData) return false;

    const otherCategories = otherData?.categories.reduce(
      (prev: any, curr: any) => {
        prev[curr.fieldNameId] = curr.fieldValue;
        return prev;
      },
      {}
    );
    const baseCompanyCategories = baseCompanyData?.categories.reduce(
      (prev: any, curr: any) => {
        prev[curr.fieldNameId] = curr.fieldValue;
        return prev;
      },
      {}
    );

    return Object.keys(otherCategories).some(
      (key: string) => !baseCompanyCategories[key]
    );
  };

  const getRowCategory = (
    element: CDPCategory,
    i: number,
    isRightSide: boolean = false,
    showValue: boolean = false
  ) => {
    return (
      <div
        key={`cdp-peer${i}`}
        className={classNames(`${BLOCK}__label-box`, {
          [`${BLOCK}__label-box--missed`]:
            isCategoryMissed(element) && isRightSide && !isCompanyView,
          [`${BLOCK}__label-box--dotted`]: isTableView,
          [`${BLOCK}__label-box--top-dotted`]: isTableView && i === 0,
        })}
      >
        <span className={classNames(`${BLOCK}__label`)}>
          {isCategoryMissed(element) && isRightSide && !isCompanyView && (
            <Icon className={`${BLOCK}__guidance`} width={16} name="guidance" />
          )}
          {element.fieldName}{" "}
        </span>
        {(showValue || (isTableView && isCompanyView)) && (
          <span className={classNames(`${BLOCK}__value`)}>
            {parseFloat(element.fieldValue.toString()).toLocaleString()}
          </span>
        )}
      </div>
    );
  };

  const getRowRisk = (
    element: CDPScopeTabularDataset,
    i: number,
    showValue: boolean = false,
    risk: Risk
  ) => {
    return (
      <div
        key={`cdp-peer-${i}`}
        className={classNames(`${BLOCK}__label-box`, {
          [`${BLOCK}__label-box--pd-0`]: isTableView && isRelevantRisks,
          [`${BLOCK}__label-box--br-8`]: !isTableView && isRelevantRisks,
        })}
      >
        <span className={`${BLOCK}__label-container`}>
          <Popover
            displayText={risk.associatedMetricDisplayName}
            content={
              risk.associatedMetricTooltip ? risk.associatedMetricTooltip : []
            }
            metricClassName={classNames(`${BLOCK}__label`, {
              [`${BLOCK}__label--white`]: isTableView && isRelevantRisks,
            })}
          />
        </span>
        {(showValue || (isTableView && isCompanyView)) && (
          <span
            className={classNames(`${BLOCK}__value`, {
              [`${BLOCK}__value--fs-12`]: isRelevantRisks,
              [`${BLOCK}__value--gray`]: isRelevantRisks && isTableView,
            })}
          >
            {getRiskValue(risk, element)}
          </span>
        )}
      </div>
    );
  };
  const getRiskValue = (risk: Risk, item: CDPScopeTabularDataset) => {
    let tmpCategory = item.categories.find(
      (category: CDPCategory) => category.fieldNameId === risk.associatedMetric
    );
    return tmpCategory && tmpCategory.fieldValue ? tmpCategory.fieldValue : "*";
  };

  const getRelevantRisksPeerIndustry = () => {
    return noDataAtAll ? (
      <div className={`${BLOCK}__relevant-no-data-wrapper`}>
        <span className="speedometer__no-data" style={{ fontWeight: "bold" }}>
          <FormattedMessage id="insights.cdp.no-data" />
        </span>
      </div>
    ) : (
      <table
        className={classNames(`${BLOCK}__relevant-table`, {
          [`${BLOCK}__relevant-table--tableView`]:
            isTableView &&
            currentInsightView === INSIGHT_BENCHMARK_TYPE.INDUSTRY,
        })}
      >
        <thead>
          <tr>
            <th></th>
            {(isTableView || isDetailedView) &&
            currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK
              ? risks && risks.length > 0
                ? risks.map((item: Risk, i: number) => {
                    return (
                      <th key={`thr-${i}`}>
                        <span className={``}>
                          <Popover
                            content={
                              item.associatedMetricTooltip
                                ? item.associatedMetricTooltip
                                : []
                            }
                            displayText={item.associatedMetricDisplayName}
                            metricClassName={`${BLOCK}__metricHeader`}
                          />
                        </span>
                      </th>
                    );
                  })
                : null
              : colsData && colsData.length
              ? colsData.map((item: CDPScopeTabularDataset, ind: number) => (
                  <th key={`thd-${ind}`}>{item.companyName}</th>
                ))
              : null}
          </tr>
        </thead>
        <tbody>
          {(isTableView || isDetailedView) &&
          currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK
            ? colsData && colsData.length
              ? colsData.map((item: CDPScopeTabularDataset, ind: number) => (
                  <tr
                    key={`${ind}-trow`}
                    className={classNames({
                      [`${BLOCK}__relevant-table-row`]:
                        isTableView || isDetailedView,
                    })}
                  >
                    <td>{item.companyName}</td>

                    {risks.map((risk: Risk, i: number) => {
                      let tmpCategory = item.categories.find(
                        (category: CDPCategory) =>
                          category.fieldNameId === risk.associatedMetric
                      );
                      return (
                        <td key={`${i}-td`}>
                          {tmpCategory && tmpCategory.fieldValue
                            ? tmpCategory.fieldValue
                            : "*"}
                          {tmpCategory &&
                          tmpCategory.fieldValue &&
                          tmpCategory.fieldValue === RISKS_VALUES.NEUTRAL ? (
                            <Tooltip position={TooltipPosition.bottom}>
                              <FormattedMessage id="insights.relevant.risks.neutral" />
                            </Tooltip>
                          ) : null}
                        </td>
                      );
                    })}
                  </tr>
                ))
              : null
            : risks && risks.length > 0
            ? risks.map((risk: Risk, index: number) => {
                return (
                  <tr
                    key={`tr-risk-${index}`}
                    className={classNames({
                      [`${BLOCK}__relevant-table-row-industry`]:
                        isTableView &&
                        currentInsightView === INSIGHT_BENCHMARK_TYPE.INDUSTRY,
                    })}
                  >
                    <td>
                      <span className={`${BLOCK}__subtitle-container`}>
                        <Popover
                          displayText={risk.associatedMetricDisplayName}
                          metricClassName={`${BLOCK}__label-risk`}
                          content={
                            risk.associatedMetricTooltip
                              ? risk.associatedMetricTooltip
                              : []
                          }
                        />
                      </span>
                    </td>
                    {colsData && colsData.length
                      ? colsData.map(
                          (item: CDPScopeTabularDataset, ind: number) => {
                            let tmpCategory = item.categories.find(
                              (category: CDPCategory) =>
                                category.fieldNameId === risk.associatedMetric
                            );

                            return (
                              <td key={`${ind}-td-col`}>
                                {tmpCategory && tmpCategory.fieldValue
                                  ? tmpCategory.fieldValue
                                  : "*"}
                                {tmpCategory &&
                                tmpCategory.fieldValue &&
                                tmpCategory.fieldValue ===
                                  RISKS_VALUES.NEUTRAL ? (
                                  <Tooltip position={TooltipPosition.bottom}>
                                    <FormattedMessage id="insights.relevant.risks.neutral" />
                                  </Tooltip>
                                ) : null}
                              </td>
                            );
                          }
                        )
                      : null}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    );
  };

  const getCDPDetailedView = () => {
    return data.map((c: any, i: number) => {
      return (
        <div key={`company_${i}`} className={`${BLOCK}__data-block--sm`}>
          <div className={`${BLOCK}__subtitle-wrapper`}>
            <span className={`${BLOCK}__subtitle`}>{c.companyName}</span>
          </div>
          {c.categories.length > 0 ? (
            c.categories
              .filter((category: CDPCategory) => category.fieldValue)
              .map((element: CDPCategory, i: number) =>
                getRowCategory(element, i, false, true)
              )
          ) : (
            <div className={`${BLOCK}__label-box`}>
              <span className={`${BLOCK}__label`}>
                <FormattedMessage id="insights.cdp.no-data" />
              </span>
            </div>
          )}
        </div>
      );
    });
  };

  const missingCategoriesLabel = () => (
    <div className={`${BLOCK}__note`}>
      <Icon className={`${BLOCK}__guidance`} width={16} name="guidance" />
      <span className={`${BLOCK}__note-label`}>
        {currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK && (
          <FormattedMessage id="insights.cdp.peers.missing" />
        )}
        {currentInsightView === INSIGHT_BENCHMARK_TYPE.INDUSTRY && (
          <FormattedMessage id="insights.cdp.industry.missing" />
        )}
      </span>
    </div>
  );

  if (data.length === 0)
    return (
      <div className="speedometer__no-data">
        <FormattedMessage id="insights.speedometer.no-data" />
      </div>
    );

  return (
    <div
      className={classNames(`${BLOCK}__container`, {
        [`${BLOCK}__container--inherit`]: isRelevantRisks,
      })}
      data-testid={`${BLOCK}`}
    >
      <div
        className={classNames(`${BLOCK}__data-block`, {
          [`${BLOCK}__data-block--detailed`]:
            !isRelevantRisks && isDetailedView && !isTableView,
        })}
      >
        {isRelevantRisks && !isCompanyView ? (
          getRelevantRisksPeerIndustry()
        ) : !isRelevantRisks && isDetailedView && !isTableView ? (
          getCDPDetailedView()
        ) : (
          <>
            <div
              className={classNames(`${BLOCK}__data-block-left`, {
                [`${BLOCK}__data-block-left--full-width`]: isRelevantRisks,
              })}
            >
              {noDataAtAll ? (
                <div className={`${BLOCK}__relevant-no-data-wrapper`}>
                  <span
                    className="speedometer__no-data"
                    style={{ fontWeight: "bold" }}
                  >
                    <FormattedMessage id="insights.cdp.no-data" />
                  </span>
                </div>
              ) : null}
              <div className={`${BLOCK}__subtitle-wrapper`}>
                {isCompanyView && isTableView && !isRelevantRisks ? (
                  <span className={`${BLOCK}__subtitle-container`}>
                    <Popover
                      displayText={
                        <FormattedMessage id="insights.categories" />
                      }
                      content={
                        associatedMetricGroupTooltips
                          ? associatedMetricGroupTooltips.filter(
                              (tooltip: AssociatedMetricTooltips) =>
                                tooltip.associatedMetricGroupName ===
                                ReactDOMServer.renderToStaticMarkup(
                                  <FormattedMessage id="insights.categories" />
                                )
                            )
                          : []
                      }
                      metricClassName={`${BLOCK}__subtitle`}
                    />
                  </span>
                ) : isRelevantRisks ? null : (
                  <span className={`${BLOCK}__subtitle`}>
                    {leftData?.companyName || baselineCompany.companyName}
                  </span>
                )}
              </div>

              {leftData &&
              leftData.categories &&
              leftData?.categories.length ? (
                isRelevantRisks && !noDataAtAll ? (
                  risks.map((risk: Risk, i: number) =>
                    getRowRisk(leftData, i, true, risk)
                  )
                ) : (
                  leftData?.categories
                    .filter((category: CDPCategory) => category.fieldValue)
                    .map((element: CDPCategory, i: number) =>
                      getRowCategory(element, i, false, true)
                    )
                )
              ) : isRelevantRisks ? null : (
                <div className={`${BLOCK}__no-data-container`}>
                  <span className={`${BLOCK}__label`}>
                    <FormattedMessage id="insights.cdp.no-data" />
                  </span>
                </div>
              )}
            </div>

            {isRelevantRisks ? null : (
              <div
                className={classNames(`${BLOCK}__data-block-right`, {
                  [`${BLOCK}__data-block-right--margin-top`]:
                    isTableView && isCompanyView,
                })}
              >
                <div className={`${BLOCK}__subtitle-wrapper`}>
                  {isCompanyView ? null : (
                    <span className={`${BLOCK}__subtitle-container`}>
                      <Popover
                        displayText={!isCompanyView ? rightSideTitle : ""}
                        content={
                          associatedMetricGroupTooltips
                            ? associatedMetricGroupTooltips.filter(
                                (tooltip: AssociatedMetricTooltips) =>
                                  tooltip.associatedMetricGroupName ===
                                  rightSideTitle
                              )
                            : []
                        }
                        metricClassName={`${BLOCK}__subtitle`}
                      />
                    </span>
                  )}
                </div>

                {rightData &&
                rightData.categories &&
                rightData?.categories.length ? (
                  rightData?.categories.map((element: CDPCategory, i: number) =>
                    getRowCategory(element, i, true, false)
                  )
                ) : (
                  <div className={`${BLOCK}__no-data-container`}>
                    <span className={`${BLOCK}__label`}>
                      <FormattedMessage id="insights.cdp.no-data" />
                    </span>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>

      {!isRelevantRisks &&
        !noDataAtAll &&
        currentInsightView !== INSIGHT_BENCHMARK_TYPE.COMPANY &&
        baselineHasMissingCategories(rightData, leftData) &&
        (isTableView
          ? missingCategoriesLabel()
          : isDetailedView
          ? null
          : missingCategoriesLabel())}

      {isRelevantRisks && noData && !noDataAtAll ? (
        <div className={`${BLOCK}__relevant-no-data`}>
          * &nbsp;
          <span>
            <FormattedMessage id="insights.relevant.risks.no-data" />
          </span>
        </div>
      ) : null}
      {currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
        isTableView &&
        !isRelevantRisks && (
          <div className={`${BLOCK}__accordion`}>
            <div className={`${BLOCK}__accordion-actions`}>
              <button
                className={`${BLOCK}__accordion-btn`}
                onClick={() => {
                  expandItemHandler(0, true, true);
                }}
              >
                <Icon name="increase2" width={24} />
                <FormattedMessage id="insights.cdp.expand.all" />
              </button>
              <button
                className={`${BLOCK}__accordion-btn`}
                onClick={() => {
                  expandItemHandler(0, true, false);
                }}
              >
                <Icon name="decrease" width={24} />
                <FormattedMessage id="insights.cdp.collapse.all" />
              </button>
            </div>

            {isTableView &&
            currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
            peers.length
              ? peers.map((peer: PeerDataTable, index: number) =>
                  getAccordionItem(peer, index)
                )
              : null}
          </div>
        )}
    </div>
  );
};

export default TabularCDPChartValid;
