import classNames from "classnames";
import Button from "components/shared/button/button";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import SidePanel from "components/shared/side-panel/side-panel";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import { useDispatch } from "react-redux";
import { sendExportItem } from "services/download/download.service";
import { DEFAULT_EXPORT_VALIDATE_REPORTS_PAYLOAD } from "utils/constants";
import { useClickOutside } from "utils/functions";

type Props = {
  showPanel?: boolean;
  handleClose: Function;
};

const DATES_ERRORS = {
  FORMAT: 1,
  INVALID_DATES: 2,
  INVALID_RANGE: 3,
};

const ValidateReportsPanel = ({ showPanel, handleClose }: Props) => {
  const BLOCK = "validateReportsPanel";
  const [pickerState, setPickerState] = useState<any[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [disabled, setDisabled] = useState(true);
  const [inputDate, setInputDate] = useState("");
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const dateRef = useRef(null);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>([
    {
      errorType: DATES_ERRORS.FORMAT,
      msg: "manage-files.invalid.date.format.message",
      active: false,
    },
    {
      errorType: DATES_ERRORS.INVALID_DATES,
      msg: "manage-files.invalid.dates.message",
      active: false,
    },
    {
      errorType: DATES_ERRORS.INVALID_RANGE,
      msg: "manage-files.invalid.date.range",
      active: false,
    },
  ]);
  const dispatch = useDispatch();
  useClickOutside(dateRef, () => {
    setPickerState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setShowCalendar(false);
  });

  const onChangeDateRange = (pickerSelection: any) => {
    onChangeInputDate(
      `${
        pickerState[0].startDate
          ? moment(pickerSelection.startDate).format("MM[/]DD[/]YYYY")
          : ""
      } - ${
        pickerState[0].endDate
          ? moment(pickerSelection.endDate).format("MM[/]DD[/]YYYY")
          : ""
      }`
    );
  };

  const onChangeInputDate = (value: any) => {
    setInputDate(value);
    value = value.replace(/ /g, "");
    let datesArray = value.split("-");

    if (
      datesArray.length === 2 &&
      moment(datesArray[0], "MM[/]DD[/]YYYY", true).isValid() &&
      !moment(datesArray[0]).isAfter(moment()) &&
      moment(datesArray[1], "MM[/]DD[/]YYYY", true).isValid() &&
      !moment(datesArray[1]).isAfter(moment()) &&
      moment(datesArray[0], "MM[/]DD[/]YYYY", true).isSameOrBefore(
        moment(datesArray[1], "MM[/]DD[/]YYYY", true)
      )
    ) {
      setIsValid(true);
      setDisabled(false);
      setErrors([...errors.map((e: any) => ({ ...e, active: false }))]);
    } else {
      setErrors([
        ...errors.map((e: any) =>
          (moment(datesArray[0], "MM[/]DD[/]YYYY", true).isAfter(moment()) &&
            e.errorType === DATES_ERRORS.INVALID_DATES &&
            !moment(datesArray[0], "MM[/]DD[/]YYYY", true).isAfter(
              moment(datesArray[1], "MM[/]DD[/]YYYY", true)
            )) ||
          (moment(datesArray[1], "MM[/]DD[/]YYYY", true).isAfter(moment()) &&
            e.errorType === DATES_ERRORS.INVALID_DATES &&
            !moment(datesArray[0], "MM[/]DD[/]YYYY", true).isAfter(
              moment(datesArray[1], "MM[/]DD[/]YYYY", true)
            )) ||
          (!moment(datesArray[0], "MM[/]DD[/]YYYY", true).isAfter(moment()) &&
            !moment(datesArray[1], "MM[/]DD[/]YYYY", true).isAfter(moment()) &&
            e.errorType === DATES_ERRORS.FORMAT &&
            !moment(datesArray[0], "MM[/]DD[/]YYYY", true).isAfter(
              moment(datesArray[1], "MM[/]DD[/]YYYY", true)
            )) ||
          (e.errorType === DATES_ERRORS.INVALID_RANGE &&
            moment(datesArray[0], "MM[/]DD[/]YYYY", true).isAfter(
              moment(datesArray[1], "MM[/]DD[/]YYYY", true)
            ))
            ? { ...e, active: true }
            : { ...e, active: false }
        ),
      ]);
      setIsValid(false);
      setDisabled(true);
    }
  };

  const handleGenerateReport = () => {
    let dates = inputDate.replace(" ", "").split("-");
    const payload = {
      ...DEFAULT_EXPORT_VALIDATE_REPORTS_PAYLOAD,
      exportFileName: "Company lookup validation report",
      startDate: dates[0],
      endDate: dates[1],
    };
    dispatch(sendExportItem(payload));
    handleClose(false);
  };

  return (
    <SidePanel
      showPanel={showPanel}
      handleClose={() => {
        handleClose(false);
      }}
      headerText="manage-files.validate.title"
    >
      <div className={`${BLOCK}__container`} data-testid={`${BLOCK}`}>
        <div className={`${BLOCK}__body`}>
          <span className={`${BLOCK}__subtitle`}>
            <FormattedMessage id="manage-files.date.range" />{" "}
          </span>
          <div className={`${BLOCK}__outline`}>
            <input
              autoFocus
              type="text"
              value={inputDate}
              className={`${BLOCK}__input`}
              placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
              onChange={(e) => {
                onChangeInputDate(e.target.value);
              }}
              data-testid="calendar-input"
            />
            <div className={`${BLOCK}__input-size`}>{inputDate}</div>

            <span
              onClick={() => setShowCalendar(true)}
              data-test="Calendar-icon"
              data-testid="calendar-icon"
            >
              <Icon name={"calendar"} width={20} height={20} />
            </span>
          </div>
          {!isValid &&
            errors.map(
              (e: any) =>
                e.active && (
                  <span
                    className={`${BLOCK}__error-message`}
                    data-testid="error-msg"
                  >
                    <FormattedMessage id={e.msg} />{" "}
                  </span>
                )
            )}
          {showCalendar && (
            <div
              className={`${BLOCK}__outline__datepicker`}
              ref={dateRef}
              data-testid="date-range-picker"
            >
              <div className={`${BLOCK}__calendar-section`}>
                <DateRange
                  onChange={(item) => {
                    setPickerState([item.selection]);
                    onChangeDateRange(item.selection);
                  }}
                  months={2}
                  direction="horizontal"
                  ranges={pickerState}
                  moveRangeOnFirstSelection={false}
                  rangeColors={["#91dcf8"]}
                  maxDate={new Date()}
                />
              </div>

              <div className={`${BLOCK}__back`}>
                <button
                  className={`${BLOCK}__back-to-today`}
                  onClick={() =>
                    setPickerState([
                      {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: "selection",
                      },
                    ])
                  }
                  data-testid="back-to-today"
                >
                  <FormattedMessage id="back.to.today.capital" />
                </button>
              </div>
            </div>
          )}
        </div>
        <div className={`${BLOCK}__footer`} data-testid="panel-footer">
          <Button
            className={`button-secondary`}
            dataTest="cancel-button"
            formattedText="cancel"
            onClick={() => {
              handleClose(false);
            }}
          />
          <Button
            className={classNames(`button-primary `, {
              ["disabled"]: disabled,
            })}
            dataTest="generate-button"
            formattedText="manage-files.generate.report"
            onClick={() => handleGenerateReport()}
            disabled={disabled}
          />
        </div>
      </div>
    </SidePanel>
  );
};

export default ValidateReportsPanel;
