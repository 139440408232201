import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import {
  loadTalkingPointsTab,
  showTalkingPointsTab,
} from "services/insights/insights.service";
import TalkingPointsAccordion from "./talking-points-accordion";
import SidePanel from "components/shared/side-panel/side-panel";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
type props = {
  BLOCK: string;
  tabId: number;
};

const TalkingPoints = ({ BLOCK, tabId }: props) => {
  const insightsState = useSelector((state: RootStore) => state.insights);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      insightsState?.currentSavedInsightDashboard?.insightsCategories[0]
        ?.insightsCategoryId &&
      tabId
    ) {
      dispatch(
        loadTalkingPointsTab(
          insightsState.currentSavedInsightDashboard.insightsCategories[0]
            .insightsCategoryId,
          tabId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    insightsState?.currentSavedInsightDashboard?.insightsCategories[0]
      ?.insightsCategoryId,
    tabId,
  ]);

  return (
    <SidePanel
      dataTest="talking-points-tab"
      headerText="talking.points.header"
      showPanel={true}
      handleClose={() => dispatch(showTalkingPointsTab(false))}
    >
      <div className={`${BLOCK}__body`}>
        <div className={`${BLOCK}__dashboard-name`}>
          <div className={`${BLOCK}__dashboard-name--label`}>
            <FormattedMessage id="talking.points.subtitle" />
            <TalkingPointsAccordion
              tpData={insightsState.talkingPointCollection}
            />
          </div>
        </div>
      </div>
    </SidePanel>
  );
};
export default TalkingPoints;
