import { Dispatch } from "redux";
import { createAction } from "../../utils/redux.utils";

import {
  ShortList,
  ShortlistModel,
  ShortListReport,
  ShortlistState,
} from "./shortlist.model";

import * as api from "./shortlist.api";
import {
  changeEsgSearchResults,
  changeSecSearchResults,
  changeTocSearchResults,
  loadESGShortlistItemIds,
  loadSECShortlistItemIds,
} from "services/search/search.results.service";
import { shortlistItemType, SORT_ORDERS } from "utils/constants";
import { addToastMessage } from "services/commons.service";

export const ACTIONS = {
  SHORTLIST_ITEM_ADD: "ESG/UI/SHORTLIST_ITEM_ADD",
  SHORTLIST_ITEM_REMOVE: "ESG/UI/SHORTLIST_ITEM_REMOVE",
  SHORTLIST_ITEM_UPDATE: "ESG/UI/SHORTLIST_ITEM_UPDATE",
  SHORTLIST_ITEM_CREATE: "ESG/UI/SHORTLIST_ITEM_CREATE",
  SHORTLIST_ITEM_SELECT_TOGGLE: "ESG/UI/SHORTLIST_ITEM_SELECT_TOGGLE",
  SHORTLIST_ITEMS_FILTERING: "ESG/UI/SHORTLIST_ITEMS_FILTERING",
  SHORTLIST_ITEMS_SORTING: "ESG/UI/SHORTLIST_ITEMS_SORTING",
  SHORTLIST_TOGGLE_SELECT_ALL: "ESG/UI/SHORTLIST_ITEM_SELECT_ALL",
  SHORTLIST_SAVE: "ESG/UI/SHORTLIST_SAVE",
  SHORT_LIST_LOADING: "ESG/UI/SHORT_LIST_LOADING",
  SHORT_LIST_LOAD_RESULTS: "ESG/UI/SHORT_LIST_LOAD_RESULTS",
  RESET_SHORT_LIST: "ESG/UI/ RESET_SHORT_LIST",
  SAVED_SHORTLIST_RENAME_ERROR: "ESG/UI/SAVED_SHORTLIST_RENAME_ERROR",
  SAVED_SHORTLIST_SUCCESS: "ESG/UI/SAVED_SHORTLIST_SUCCESS",
  SAVED_NEW_SHORTLIST: "ESG/UI/SAVED_NEW_SHORTLIST",
  SHORTLIST_EXPORT_GRID_XLS: "ESG/UI/SHORTLIST_EXPORT_GRID_XLS",
};

export const shortlistAdd = createAction(ACTIONS.SHORTLIST_ITEM_ADD);
export const shortlistRemove = createAction(ACTIONS.SHORTLIST_ITEM_REMOVE);
export const shortlistCreate = createAction(ACTIONS.SHORTLIST_ITEM_CREATE);
export const shortlistToggleSelectAll = createAction(
  ACTIONS.SHORTLIST_TOGGLE_SELECT_ALL
);
export const shortlistSave = createAction(ACTIONS.SHORTLIST_SAVE);
export const shortListLoading = createAction(ACTIONS.SHORT_LIST_LOADING);
export const resetShortList = createAction(ACTIONS.RESET_SHORT_LIST);
export const shortListLoadResults = createAction(
  ACTIONS.SHORT_LIST_LOAD_RESULTS
);
export const shortlistRenameError = createAction(
  ACTIONS.SAVED_SHORTLIST_RENAME_ERROR
);
export const saveShortlistSuccess = createAction(
  ACTIONS.SAVED_SHORTLIST_SUCCESS
);
export const shortlistFilter = createAction(ACTIONS.SHORTLIST_ITEMS_FILTERING);
export const saveNewShortList = createAction(ACTIONS.SAVED_NEW_SHORTLIST);
export const exportShortlistXls = createAction(
  ACTIONS.SHORTLIST_EXPORT_GRID_XLS
);

const constPayload = {
  pageInfo: {
    pageNumber: 1,
    pageSize: 999,
  },
};

const frontEndPageInfo = {
  pageSize: 25,
};

const filterShortlistItems = (results: ShortList, allResults?: boolean) => {
  let { shortlistItems, pageInfo } = results;
  let newReports: ShortListReport[];
  if (pageInfo.filterBy && pageInfo.filterText) {
    newReports = shortlistItems.filter((report: any) => {
      if (
        report.esgReport !== undefined &&
        report.esgReport.reportId !== 0 &&
        pageInfo.filterBy === "shortlistItemPeriod"
      ) {
        return (
          report.esgReport.reportYears !== undefined &&
          !isNaN(parseInt(pageInfo.filterText)) &&
          report.esgReport.reportYears.join("-").includes(pageInfo.filterText)
        );
      } else {
        const reportValue = pageInfo.filterBy
          .split(".")
          .reduce((prev, curr) => (prev[curr] ? prev[curr] : ""), report);

        return (
          reportValue !== undefined &&
          reportValue
            .toString()
            .toLowerCase()
            .includes(pageInfo.filterText.toLowerCase())
        );
      }
    });
  } else {
    newReports = [...shortlistItems];
  }

  if (
    pageInfo.currentFilterType === shortlistItemType.ESG ||
    pageInfo.currentFilterType === shortlistItemType.SEC
  ) {
    newReports = newReports.filter((report: any) => {
      return report.shortlistItemType === pageInfo.currentFilterType;
    });
  }

  results.pageInfo.totalFilteredCount = newReports.length;
  results.activeShortlistItems = allResults
    ? newReports
    : newReports.slice(0, results.pageInfo.totalPageCount);

  return results;
};

const sortShortlistItems = (results: ShortList, field: string[]) => {
  let { shortlistItems, pageInfo } = results;

  if (pageInfo.sortBy && pageInfo.sortOrder) {
    let newReports =
      SORT_ORDERS.ASC === pageInfo.sortOrder
        ? shortlistItems.sort((reportA: any, reportB: any) => {
            const reportAValue = field
              .reduce((prev, curr) => (prev[curr] ? prev[curr] : ""), reportA)
              .toString()
              .toLowerCase();
            const reportBValue = field
              .reduce((prev, curr) => (prev[curr] ? prev[curr] : ""), reportB)
              .toString()
              .toLowerCase();
            if (reportAValue < reportBValue) {
              return -1;
            }

            if (reportAValue > reportBValue) {
              return 1;
            }

            return 0;
          })
        : shortlistItems.sort((reportA: any, reportB: any) => {
            const reportAValue = field
              .reduce((prev, curr) => (prev[curr] ? prev[curr] : ""), reportA)
              .toString()
              .toLowerCase();
            const reportBValue = field
              .reduce((prev, curr) => (prev[curr] ? prev[curr] : ""), reportB)
              .toString()
              .toLowerCase();
            if (reportAValue > reportBValue) {
              return -1;
            }

            if (reportAValue < reportBValue) {
              return 1;
            }

            return 0;
          });
    results.shortlistItems = newReports;
  }
  return results;
};

export const loadShortListResults =
  (esgPayload: any, shortlistId?: number) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shortListLoading(true));

      const shortlistItems = await api.fetchShortListResults(
        esgPayload,
        shortlistId
      );
      dispatch(shortListLoadResults(shortlistItems.data));

      const esgShortlist = shortlistItems.data.shortlistItems.filter(
        (item: any) => item.shortlistItemType === shortlistItemType.ESG
      );
      const secShortlist = shortlistItems.data.shortlistItems.filter(
        (item: any) => item.shortlistItemType === shortlistItemType.SEC
      );
      dispatch(
        loadESGShortlistItemIds(
          esgShortlist ? esgShortlist.map((item: any) => item.reportId) : []
        )
      );
      dispatch(
        loadSECShortlistItemIds(
          secShortlist ? secShortlist.map((item: any) => item.elementId) : []
        )
      );
    } catch (e) {
      dispatch(shortListLoading(false));
    }
  };

export const loadShortlistResultsSort =
  (sortBy: string, sortOrder: string, field: string, results: ShortList) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(shortListLoading(true));

    results.pageInfo.sortBy = sortBy;
    results.pageInfo.sortOrder = sortOrder;

    results = sortShortlistItems(results, field.split("."));
    results = filterShortlistItems(results);

    dispatch(shortlistToggleSelectAll(results));
    dispatch(shortListLoading(false));
  };

export const loadShortListResultsNextPage =
  (esgPayload: any, secPayload: any, shortlistId?: number) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shortListLoading(true));
      const esg = await api.fetchESGShortListResults(esgPayload, shortlistId);
      const sec = await api.fetchSECShortListResults(secPayload, shortlistId);
      dispatch(shortListLoadResults({ esg: esg.data, sec: sec.data }));
    } catch (e) {
      dispatch(shortListLoading(false));
    }
  };

export const loadMoreShortlists =
  (results: ShortList) => async (dispatch: Dispatch<any>) => {
    if (results.activeShortlistItems.length === results.shortlistItems.length) {
      return;
    }

    const newTotalPageCount =
      results.activeShortlistItems.length + frontEndPageInfo.pageSize;
    results.pageInfo.totalPageCount =
      newTotalPageCount < results.shortlistItems.length
        ? newTotalPageCount
        : results.shortlistItems.length;

    results = filterShortlistItems(results);

    dispatch(shortlistToggleSelectAll(results));
  };

export const addShortlistItem =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shortListLoading(true));
      dispatch(shortlistAdd(true));
      await api.createShortlist(payload);
      if (payload.esgReport.reportId !== null) {
        dispatch(changeEsgSearchResults(payload.esgReport.reportId));
      } else if (payload.sectionItem.sectionId !== null) {
        dispatch(changeTocSearchResults(payload.sectionItem.elementId));
        dispatch(changeSecSearchResults(payload.sectionItem.elementId));
      }

      const shortlistItems = await api.fetchShortListResults(constPayload);

      dispatch(shortListLoadResults(shortlistItems.data));
    } catch (e) {
      dispatch(shortListLoading(false));
    }
  };

export const createShortlist =
  (payload: any, successMessage?: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shortListLoading(true));
      dispatch(shortlistAdd(true));
      await api.createShortlist(payload);
      if (payload.esgReport.reportId !== null && !successMessage) {
        dispatch(changeEsgSearchResults(payload.esgReport.reportId));
      } else if (payload.sectionItem.elementId !== null) {
        dispatch(changeTocSearchResults(payload.sectionItem.elementId));
        dispatch(changeSecSearchResults(payload.sectionItem.elementId));
      }
      const shortlistId = payload.shortlistId ? payload.shortlistId : 0;
      const shortlistItems = await api.fetchShortListResults(
        constPayload,
        shortlistId
      );
      dispatch(shortListLoadResults(shortlistItems.data));
      if (shortlistItems && successMessage) {
        dispatch(
          addToastMessage({
            description: successMessage,
          })
        );
      }
    } catch (e) {
      dispatch(shortListLoading(false));
    }
  };

export const removeShortlistItem =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shortListLoading(true));
      dispatch(shortlistRemove(true));
      // Doesn't work with Shortlist id as 0
      if (payload.esgReport.reportId === null) {
        // For SEC report shortlist
        await api.removeShortlistSEC(payload);
        dispatch(changeSecSearchResults(payload.sectionItem.elementId));
        dispatch(changeTocSearchResults(payload.sectionItem.elementId));
      } else if (payload.esgReport.reportId !== null) {
        // For ESG Report Shortlist
        await api.removeShortlistESG(payload);
        dispatch(changeEsgSearchResults(payload.esgReport.reportId));
      }
      const shortlistId = payload.shortlistId ? payload.shortlistId : 0;

      const shortlistItems = await api.fetchShortListResults(
        constPayload,
        shortlistId
      );
      dispatch(shortListLoadResults(shortlistItems.data));
    } catch (e) {
      dispatch(shortListLoading(false));
    }
  };

export const toggleSelectShortlistItem =
  (results: ShortList, id: number, field: string) =>
  async (dispatch: Dispatch<any>) => {
    const newResults = results;

    newResults.activeShortlistItems = newResults.activeShortlistItems.map(
      (report: any) => {
        if (report[field] === id) {
          report.isSelected = !report.isSelected;
        }

        return report;
      }
    );

    const selectedItems = newResults.activeShortlistItems.filter(
      (report: any) => report.isSelected
    );
    newResults.pageInfo.totalSelectedCount = selectedItems.length;

    dispatch(shortlistToggleSelectAll(newResults));
  };

export const filterReports =
  (filterBy: string, filterText: string, results: ShortList) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(shortListLoading(true));

    results.pageInfo = {
      ...results.pageInfo,
      filterBy: filterBy,
      filterText: filterText,
    };

    results = filterShortlistItems(results);

    dispatch(shortlistToggleSelectAll(results));
    dispatch(shortlistFilter(""));
    dispatch(shortListLoading(false));
  };

export const filterReportsByType =
  (filterText: number, results: ShortList) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(shortListLoading(true));

    filterText =
      results.pageInfo.currentFilterType === filterText ? -1 : filterText;

    results.pageInfo.currentFilterType = filterText;

    results = filterShortlistItems(results);

    dispatch(shortlistToggleSelectAll(results));
    dispatch(shortlistFilter(""));
    dispatch(shortListLoading(false));
  };

export const toggleSelectShortlist =
  (results: ShortList) => async (dispatch: Dispatch<any>) => {
    const selectAll =
      results.selectedItems.length === results.activeShortlistItems.length;

    if (selectAll) {
      const filteredResults = filterShortlistItems(results, true);
      results.selectedItems = filteredResults.activeShortlistItems.map(
        (report: any) => report.shortlistItemId
      );
    }

    results.pageInfo.totalSelectedCount = results.selectedItems.length;
    dispatch(shortlistToggleSelectAll(results));
  };

export const filteringShortlistItems =
  (currentFilter: string) => async (dispatch: Dispatch<any>) => {
    dispatch(shortlistFilter(currentFilter));
  };

export const saveShortlist =
  (shortlistId: number, shortlistName: string) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shortListLoading(true));
      dispatch(shortlistSave(true));
      const response = await api.saveShortlist({ shortlistName, shortlistId });
      // // To add the shortlistId after doing fetch when the save is done
      const shortlistItems = await api.fetchShortListResults(
        constPayload,
        response.shortlistId ? response.shortlistId : shortlistId
      );

      dispatch(shortListLoadResults(shortlistItems.data));

      dispatch(saveShortlistSuccess(true));
    } catch (e) {
      dispatch(saveShortlistSuccess(false));
      dispatch(shortlistRenameError());
      dispatch(shortListLoading(false));
    }
  };

export const saveShortlistCopy =
  (shortlistId: number, shortlistName: string) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(shortListLoading(true));
      await api.saveShortlist({ shortlistName, shortlistId });
      dispatch(saveNewShortList(true));

      //dispatch(shortlistCreate(payload));
    } catch (e) {
      dispatch(shortListLoading(false));
      dispatch(shortlistRenameError());
    }
  };

export const shareSavedShortlist = (
  shortlistId: number,
  shortlistName: string
) => {};

const shortlistModel = new ShortlistModel();

const shortlistReducer = (
  state: ShortlistState = shortlistModel.initialState(),
  action: any
): ShortlistState => {
  switch (action.type) {
    case ACTIONS.SHORT_LIST_LOADING:
      return shortlistModel.setLoading(action.payload);
    case ACTIONS.SHORT_LIST_LOAD_RESULTS:
      return shortlistModel.setResults(action.payload);
    case ACTIONS.SHORTLIST_ITEMS_FILTERING:
      return shortlistModel.setCurrentFilter(action.payload);
    case ACTIONS.RESET_SHORT_LIST:
      return shortlistModel.resetShortList();
    case ACTIONS.SHORTLIST_TOGGLE_SELECT_ALL:
      return shortlistModel.toggleSelectAll(action.payload);
    case ACTIONS.SAVED_SHORTLIST_RENAME_ERROR:
      return shortlistModel.setErrorRename();
    case ACTIONS.SAVED_SHORTLIST_SUCCESS:
      return shortlistModel.setSaveSuccess(action.payload);
    case ACTIONS.SAVED_NEW_SHORTLIST:
      return shortlistModel.setSaveNewShortList(action.payload);

    default:
      return state;
  }
};

export default shortlistReducer;
