import classNames from "classnames";
import TileEmptyContent from "components/insight-tile/tile-empty-content";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";

type props = {
  tableData: any;
  valueMissing: boolean;
  benchmarkType: number;
  associatedMetricsTooltip: AssociatedMetricTooltips[];
};

const CliamteRelatedIncentiveTable = ({
  tableData,
  valueMissing,
  benchmarkType,
  associatedMetricsTooltip,
}: props) => {
  const BLOCK = "climate-related-incentive";
  const checkAllValueMissing = (metric: any) => {
    if (metric.every((value: string) => value.trim() === "")) {
      return true;
    } else {
      return false;
    }
  };

  const checkAllMetricValueMissing = tableData.every(
    (metricData: any) =>
      (metricData.firstMetric.metricValue === null ||
        metricData.firstMetric.metricValue === "") &&
      metricData.otherMetricDetail.every((detail: any) =>
        detail.every((data: string) => data === "")
      )
  );
  if (checkAllMetricValueMissing) {
    return (
      <div>
        <TileEmptyContent />
      </div>
    );
  }

  const checkOtherMetricMissing = (otherMetric: any) => {
    if (
      otherMetric.every((mrtric: string[]) =>
        mrtric.every((value: string) => value === "")
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getCompnayView = () => {
    return (
      <div className={`${BLOCK}__table`} data-testid={`${BLOCK}__table`}>
        <div className={`${BLOCK}__table-firstMetric`}>
          <div>
            <Popover
              metricClassName={`${BLOCK}__table-firstMetric--name ${BLOCK}__tableIndustry-container--metricHeaderName`}
              displayText={tableData[0].firstMetric.metricName}
              content={
                associatedMetricsTooltip && associatedMetricsTooltip.length > 0
                  ? associatedMetricsTooltip.filter(
                      (tooltip: AssociatedMetricTooltips) =>
                        tooltip.associatedMetric ===
                        tableData[0].firstMetric.metricKey
                    )
                  : []
              }
            />
          </div>
          <div
            className={classNames(`${BLOCK}__table-firstMetric--value`, {
              [`${BLOCK}__table-firstMetric--value--yes`]:
                tableData[0].firstMetric.metricValue?.toLocaleLowerCase() ===
                "yes",
            })}
          >
            {tableData[0].firstMetric.metricValue
              ? tableData[0].firstMetric.metricValue
              : ""}
          </div>
        </div>

        <div className={`${BLOCK}__table-otherMetric`}>
          <div className={`${BLOCK}__table-otherMetric-header`}>
            {tableData[0].metrics.map(
              (metric: any, index: number) =>
                index !== 0 && (
                  <div key={`${index}__compnameTable`}>
                    {}
                    <Popover
                      metricClassName={`${BLOCK}__table-otherMetric-header--name`}
                      displayText={metric.metricName}
                      content={
                        associatedMetricsTooltip &&
                        associatedMetricsTooltip.length > 0
                          ? associatedMetricsTooltip.filter(
                              (tooltip: AssociatedMetricTooltips) =>
                                tooltip.associatedMetric === metric.metricKey
                            )
                          : []
                      }
                    />
                  </div>
                )
            )}
          </div>
          <div className={`${BLOCK}__table-otherMetric-detail`}>
            {tableData[0].otherMetricDetail?.map(
              (metric: any, ind: number) =>
                !checkAllValueMissing(metric) && (
                  <div
                    className={`${BLOCK}__table-otherMetric-detail--wrapper`}
                    key={`${ind}__table-otherMetric-detail--wrapper`}
                  >
                    {metric.map((value: string, ind: number) => (
                      <div
                        className={`${BLOCK}__table-otherMetric-detail--value`}
                        key={`${ind}__table-otherMetric-detail--value`}
                      >
                        {value}
                      </div>
                    ))}
                  </div>
                )
            )}
          </div>
        </div>
        {/* {valueMissing && (
          <div className="noData">
            * <FormattedMessage id="insights.speedometer.no-data" />
          </div>
        )} */}
      </div>
    );
  };

  const getPeerView = () => {
    return (
      <div className={`${BLOCK}__table`} data-testid={`${BLOCK}__table`}>
        <table className={`${BLOCK}__tablePeer`}>
          <thead className={`${BLOCK}__tablePeer-header`}>
            <tr>
              <th></th>
              {tableData.map((data: any, index: number) => (
                <th key={`${index}__tablePeer-header`}>{data.groupName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Popover
                  metricClassName={`${BLOCK}__tablePeer-data-first ${BLOCK}__tableIndustry-container--metricHeaderName`}
                  displayText={tableData[0].firstMetric.metricName}
                  content={
                    associatedMetricsTooltip &&
                    associatedMetricsTooltip.length > 0
                      ? associatedMetricsTooltip.filter(
                          (tooltip: AssociatedMetricTooltips) =>
                            tooltip.associatedMetric ===
                            tableData[0].firstMetric.metricKey
                        )
                      : []
                  }
                />
              </td>
              {tableData.map((data: any, index: number) => (
                <td
                  key={`${index}_data`}
                  className={classNames(
                    `${BLOCK}__chart-peer--valueWrapper--value`,
                    {
                      [`${BLOCK}__tablePeer-data`]:
                        data.firstMetric.metricValue?.toLocaleLowerCase() ===
                        "yes",
                    }
                  )}
                >
                  {data.firstMetric.metricValue
                    ? data.firstMetric.metricValue
                    : ""}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className={`${BLOCK}__tablePeer-container`}>
          {tableData.map((data: any, index: number) => (
            <div
              key={`${index}__tablePeer-container`}
              className={`${BLOCK}__tablePeer-container--body`}
            >
              <div className={`${BLOCK}__tablePeer-container--header`}>
                {data.groupName}
              </div>
              {!checkOtherMetricMissing(data.otherMetricDetail) ? (
                <>
                  <div
                    className={`${BLOCK}__tablePeer-container--metricHeader`}
                  >
                    {data.metrics.map(
                      (metric: any, index: number) =>
                        index !== 0 && (
                          <div key={`${index}__tablePeer-header-name`}>
                            <Popover
                              metricClassName={`${BLOCK}__header-name ${BLOCK}__tableIndustry-container--metricHeaderName`}
                              displayText={metric.metricName}
                              content={
                                associatedMetricsTooltip &&
                                associatedMetricsTooltip.length > 0
                                  ? associatedMetricsTooltip.filter(
                                      (tooltip: AssociatedMetricTooltips) =>
                                        tooltip.associatedMetric ===
                                        metric.metricKey
                                    )
                                  : []
                              }
                            />
                          </div>
                        )
                    )}
                  </div>

                  <div className={`${BLOCK}__chart-otherMetric-detail`}>
                    {data.otherMetricDetail?.map(
                      (metric: any, index: number) =>
                        !checkAllValueMissing(metric) && (
                          <div
                            className={`${BLOCK}__chart-otherMetric-detail--tableWrapper`}
                            key={`${index}__chart-otherMetric-detail--tableWrapper`}
                          >
                            {metric.map((value: string, index: number) => (
                              <div
                                className={`${BLOCK}__chart-otherMetric-detail--value`}
                                key={`${index}__chart-otherMetric-detail--value`}
                              >
                                {value}
                              </div>
                            ))}
                          </div>
                        )
                    )}
                  </div>
                </>
              ) : (
                <TileEmptyContent />
              )}
            </div>
          ))}
        </div>
        {/* {valueMissing && (
          <div className="noData">
            * <FormattedMessage id="insights.speedometer.no-data" />
          </div>
        )} */}
      </div>
    );
  };

  const getIndustryView = () => {
    return (
      <div className={`${BLOCK}__table`} data-testid={`${BLOCK}__table`}>
        <table className={`${BLOCK}__tablePeer`}>
          <thead className={`${BLOCK}__tablePeer-header`}>
            <tr>
              <th></th>
              {tableData.map((data: any, index: number) => (
                <th key={index}>{data.groupName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Popover
                  metricClassName={`${BLOCK}__tablePeer-data-first ${BLOCK}__tableIndustry-container--metricHeaderName`}
                  displayText={tableData[0].firstMetric.metricName}
                  content={
                    associatedMetricsTooltip &&
                    associatedMetricsTooltip.length > 0
                      ? associatedMetricsTooltip.filter(
                          (tooltip: AssociatedMetricTooltips) =>
                            tooltip.associatedMetric ===
                            tableData[0].firstMetric.metricKey
                        )
                      : []
                  }
                />
              </td>
              {tableData.map((data: any, index: number) => (
                <td
                  key={`${index}_data`}
                  className={classNames(
                    `${BLOCK}__chart-peer--valueWrapper--value`,
                    {
                      [`${BLOCK}__tablePeer-data`]:
                        data.firstMetric.metricValue?.toLocaleLowerCase() ===
                        "yes",
                    }
                  )}
                >
                  {data.firstMetric.metricValue
                    ? data.firstMetric.metricValue
                    : ""}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className={`${BLOCK}__tableIndustry-container`}>
          {tableData.map((data: any, index: number) => (
            <div
              key={`${index}__tableIndustry-container`}
              className={`${BLOCK}__tableIndustry-container--body`}
            >
              {index === 0 && (
                <div
                  className={`${BLOCK}__tableIndustry-container--metricHeader`}
                >
                  {data.metrics.map(
                    (metric: any, index: number) =>
                      index !== 0 && (
                        <div
                          key={`${BLOCK}__tableIndustry-container--metricHeaderName-${index}`}
                        >
                          <Popover
                            metricClassName={`${BLOCK}__tableIndustry-container--metricHeaderName`}
                            displayText={metric.metricName}
                            content={
                              associatedMetricsTooltip &&
                              associatedMetricsTooltip.length > 0
                                ? associatedMetricsTooltip.filter(
                                    (tooltip: AssociatedMetricTooltips) =>
                                      tooltip.associatedMetric ===
                                      metric.metricKey
                                  )
                                : []
                            }
                          />
                        </div>
                      )
                  )}
                </div>
              )}
              <div className={`${BLOCK}__tableIndustry-container--header`}>
                {data.groupName}
              </div>
              <div className={`${BLOCK}__chart-otherMetric-detail`}>
                {data.otherMetricDetail?.map(
                  (metric: any, index: number) =>
                    !checkAllValueMissing(metric) && (
                      <div
                        className={`${BLOCK}__tableIndustry-tableWrapper`}
                        style={
                          data.otherMetricDetail?.length <= 1
                            ? { borderBottom: "none" }
                            : {}
                        }
                        key={`${BLOCK}__tableIndustry-tableWrapper-${index}`}
                      >
                        {metric.map((value: string, i: number) => (
                          <div
                            className={`${BLOCK}__chart-otherMetric-detail--value`}
                            key={`${BLOCK}__chart-otherMetric-detail--value-${i}`}
                          >
                            {value}
                          </div>
                        ))}
                      </div>
                    )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
    ? getCompnayView()
    : benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK
    ? getPeerView()
    : getIndustryView();
};

export default CliamteRelatedIncentiveTable;
