import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import ShortListEmptyState from "images/shortlist.empty-state.svg";
import { RootStore } from "services/store.service";
import {
  loadShortListResults,
  saveShortlist,
} from "services/shortlist/shortlist.service";
import Icon from "components/shared/icon/icon";
import SaveShortlist from "../shortlist-actions/save-shortlist";
import {
  renameSavedShortlistSuccess,
  renameShortlist,
  renameShortlistPop,
  shareShortlist,
  shareShortlistPop,
} from "services/saved-shortlist/saved-shortlist.service";
import RenameShortlist from "components/shortlist-actions/rename-shortlist";
import DocumentViewer from "components/document-viewer/document-viewer";
import DocumentViewerSEC from "components/document-viewer/document-viewer.sec";
import { sendExportItem } from "services/download/download.service";
import {
  DEFAULT_SHORTLISTS_PAYLOAD,
  shareItemType,
} from "../../utils/constants";
import { loadsavedShortlist } from "services/saved-shortlist/saved-shortlist.service";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import ShareToUsersModal from "components/shared/share-to-users-modal/share-to-users-modal";
import ShortlistTable from "./shortlist-table";
import history from "utils/history";
import Compare from "components/compare/compare";
import ShortlistNote from "components/notes/shortlist-notes";
import { comparisonPath } from "utils/constants";
import { addtoComparison } from "services/saved-comparisons/saved-comparisons.service";
import Button from "components/shared/button/button";
import { useLocation } from "react-router-dom";
import useAppInsightsCustomMetric from "appinsights/app-insights-custom-metrics";

const Shortlist = () => {
  const BLOCK = "Shortlist";
  const shortListState = useSelector((state: RootStore) => state.shortlist);
  const savedShortList = useSelector((state: RootStore) => state.savedShorList);
  const [hasNote, setHasNote] = useState<boolean>(false);
  const [showSave, setShowSave] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showShare, setShowShare] = useState<boolean>(false);
  const [showShortlistNote, setshowShortlistNote] = useState<boolean>(false);
  const [showCompareModal, setCompareModal] = useState<boolean>(false);
  const documentViewerState = useSelector(
    (state: RootStore) => state.documentViewer
  );
  const comparisonState = useSelector(
    (state: RootStore) => state.comparisonState
  );
  const downloadState = useSelector((state: RootStore) => state.download);
  const noteState = useSelector((state: RootStore) => state.note);
  const dispatch = useDispatch();

  const location = useLocation();
  // App insights custom metric
  useAppInsightsCustomMetric(location.pathname);

  useEffect(() => {
    if (
      (shortListState.results?.shortlistId && noteState.saveSuccess) ||
      (shortListState.results?.shortlistId && noteState.deleteSuccess)
    ) {
      dispatch(
        loadShortListResults(
          {
            pageInfo: {
              pageNumber: 1,
              pageSize: 999,
            },
          },
          shortListState.results.shortlistId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteState.saveSuccess, noteState.deleteSuccess]);

  useEffect(() => {
    dispatch(loadsavedShortlist(DEFAULT_SHORTLISTS_PAYLOAD));
  }, [dispatch]);

  useEffect(() => {
    setHasNote(shortListState.results?.commentNoteId !== 0);
  }, [shortListState.results?.commentNoteId]);

  useEffect(() => {
    if (!savedShortList.showRename && savedShortList.renameShortlist !== null) {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedShortList.renameShortlist]);

  useEffect(() => {
    if (!savedShortList.showShare && savedShortList.shareShortlist !== null) {
      setShowShare(true);
    } else setShowShare(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedShortList.shareShortlist]);

  useEffect(() => {
    if (savedShortList.savedShortListRename) {
      dispatch(renameSavedShortlistSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedShortList.savedShortListRename]);

  const onSaveShortlistHandler = (shortlistName: string) => {
    dispatch(
      saveShortlist(
        shortListState.results !== null &&
          shortListState.results.shortlistId !== undefined
          ? shortListState.results.shortlistId
          : 0,
        shortlistName
      )
    );
  };

  const renameShortlistHandler = ({
    shortlistName,
    shortlistId,
  }: {
    shortlistName: string;
    shortlistId: number;
  }) => {
    dispatch(
      renameShortlist({
        shortlistName,
        shortlistId,
        type: "shortlist",
      })
    );
  };

  const shareShortlistHandler = ({
    shortlistName,
    shortlistId,
    sharedWith,
  }: {
    shortlistName: string;
    shortlistId: number;
    sharedWith: any[];
  }) => {
    dispatch(
      shareShortlist({
        shortlistName:
          shortlistName !== null
            ? shortlistName
            : shortListState.results.shortlistName,
        shortlistId,
        sharedWith,
        type: "shortlist",
      })
    );
  };

  const submitCompare = (selected: any) => {
    if (selected.comparisonId)
      dispatch(
        addtoComparison(comparisonState.selectedList, selected.comparisonId)
      );
    history.push(
      `${comparisonPath}${
        selected.comparisonId ? `/${selected.comparisonId}` : ""
      }`
    );
  };

  const shareClickHandler = () => {
    let currentSavedShortlist =
      savedShortList.SavedShortListFetch &&
      savedShortList.SavedShortListFetch.shortlists
        ? savedShortList.SavedShortListFetch.shortlists.find(
            ({ shortlistId }) => {
              return shortListState.results.shortlistId === shortlistId;
            }
          )
        : {};

    if (currentSavedShortlist) {
      dispatch(shareShortlistPop(currentSavedShortlist));
    }
  };

  const exportGridXlsHandler = () => {
    let actualSortBy = "";
    const sortBy = shortListState.results.pageInfo.sortBy?.split(".");
    actualSortBy = sortBy && sortBy[sortBy.length - 1];
    actualSortBy =
      actualSortBy &&
      actualSortBy.charAt(0).toUpperCase() + actualSortBy.slice(1);
    const payload = {
      exportReferenceId: shortListState.results.shortlistId,
      exportFileName:
        shortListState.results.shortlistName &&
        shortListState.results.shortlistName.length > 0
          ? shortListState.results.shortlistName
          : "",
      exportType: 3,
      exportFormat: 0,
      searchCriteria: {
        contentText: "",
        // Taking pageInfo direct from the reducer to incorporate current sorting info
        pageInfo: { ...shortListState.results.pageInfo, sortBy: actualSortBy },
      },
      downloadTabOpen: downloadState.showDownloads,
    };

    dispatch(sendExportItem(payload));
  };

  const getEmptyState = () => (
    <div className={`${BLOCK}__empty-container`} data-test="empty-shortlist">
      <div className={`${BLOCK}__empty`}>
        <img
          className={`${BLOCK}__empty-image`}
          src={ShortListEmptyState}
          alt={"No lists"}
        />
        <span className={`${BLOCK}__empty-title`}>
          <FormattedMessage id={"shortlists.no-lists.title"} />
        </span>
        <span className={`${BLOCK}__empty-message`}>
          <p>
            <FormattedMessage id={"shortlists.no-lists.text"} />
            <FormattedMessage id={"shortlists.no-lists.search-link"} />
          </p>
        </span>
      </div>
    </div>
  );

  if (documentViewerState.selectedReport)
    return (
      <div
        className={`${BLOCK}__document-viewer`}
        data-test="esg-document-viewer"
      >
        <DocumentViewer
          report={documentViewerState.selectedReport}
          fromShortlist={true}
        />
      </div>
    );

  if (documentViewerState.selectedDisclosure)
    return (
      <div
        className={`${BLOCK}__document-viewer`}
        data-test="sec-document-viewer"
      >
        <DocumentViewerSEC
          report={documentViewerState.selectedDisclosure}
          fromShortlist={true}
        />
      </div>
    );

  return (
    <div className={BLOCK}>
      {shortListState.loading ? (
        <div className={`${BLOCK}__empty-container`}>
          <Icon
            name={"loading"}
            width={50}
            height={50}
            className="loading-icon"
            data-test="loading-icon"
          />
        </div>
      ) : (
        <>
          {shortListState &&
          shortListState.results &&
          (shortListState.results.shortlistName ||
            (shortListState.results.shortlistItems &&
              shortListState.results.shortlistItems.length)) ? (
            <>
              <div className={`${BLOCK}__top-section`}>
                <div className={`${BLOCK}__title`}>
                  {shortListState.results.shortlistName ? (
                    <>
                      <span className={`${BLOCK}__title-text`}>
                        {shortListState.results.shortlistName.length > 50
                          ? shortListState.results.shortlistName.slice(0, 50) +
                            "..."
                          : shortListState.results.shortlistName}
                        {shortListState.results.shortlistName.length > 50 ? (
                          <Tooltip position={TooltipPosition.right}>
                            <>{shortListState.results.shortlistName}</>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </span>
                      <Button
                        className=""
                        dataTest="rename-shortlist-button"
                        onClick={() =>
                          dispatch(renameShortlistPop(shortListState.results))
                        }
                        iconName="pencil"
                      />
                    </>
                  ) : (
                    <FormattedMessage id="shortlists.title" />
                  )}
                </div>
                <div className={`${BLOCK}__actions`}>
                  <div className={`${BLOCK}__cta-section`}>
                    <div className={`${BLOCK}__saved-actions`}>
                      <div className={`${BLOCK}__share-button`}>
                        <Button
                          className={`icon-text-button`}
                          dataTest="share-shortlist-button"
                          formattedText="shortlists.share"
                          onClick={shareClickHandler}
                          iconName="share"
                          iconHeight={24}
                          iconWidth={24}
                          disabled={
                            !shortListState.results.shortlistName ||
                            (shortListState.results.shortlistItems &&
                              shortListState.results.shortlistItems.length ===
                                0)
                          }
                        />
                        {!shortListState.results.shortlistName && (
                          <Tooltip position={TooltipPosition.top}>
                            <FormattedMessage id="shortlist.save.before.share" />
                          </Tooltip>
                        )}
                      </div>
                      <div className={`${BLOCK}__shortlist-note-button`}>
                        <Button
                          className={`icon-text-button`}
                          formattedText="shortlist.note.button"
                          dataTest="note-shortlist-button"
                          iconName={`${
                            hasNote ? "commenting" : "commenting-plus"
                          }`}
                          iconHeight={24}
                          iconWidth={24}
                          onClick={() => {
                            setshowShortlistNote(true);
                          }}
                        />
                        {shortListState.results.commentNoteId === 0 ? (
                          <Tooltip position={TooltipPosition.top}>
                            <FormattedMessage id="note.add.button" />
                          </Tooltip>
                        ) : (
                          <Tooltip position={TooltipPosition.top}>
                            <FormattedMessage id="note.view.button" />
                          </Tooltip>
                        )}
                      </div>
                      <div className={`${BLOCK}__export-button`}>
                        <Button
                          className={`icon-text-button`}
                          formattedText="shortlists.export"
                          dataTest="export-shortlist-button"
                          onClick={exportGridXlsHandler}
                          disabled={
                            !shortListState.results.shortlistName ||
                            (shortListState.results.shortlistItems &&
                              shortListState.results.shortlistItems.length ===
                                0)
                          }
                          iconName="xls"
                          iconHeight={24}
                          iconWidth={24}
                        />
                        {!shortListState.results.shortlistName &&
                        shortListState.results.shortlistItems.length > 0 ? (
                          <Tooltip position={TooltipPosition.top}>
                            <FormattedMessage id="shortlist.save.before.export" />
                          </Tooltip>
                        ) : (
                          <Tooltip position={TooltipPosition.top}>
                            <FormattedMessage id="shortlist.export.no.item" />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={`${BLOCK}__cta-section`}>
                    {!shortListState.results.shortlistName && (
                      <Button
                        className={`primary`}
                        dataTest="save-shortlist-button"
                        formattedText="shortlists.save"
                        onClick={() => setShowSave(true)}
                      />
                    )}
                  </div>
                </div>
              </div>
              {!shortListState.results.shortlistItems ||
              !shortListState.results.shortlistItems.length ? (
                getEmptyState()
              ) : (
                <div className={`${BLOCK}__lists-container`}>
                  {shortListState.results.shortlistItems &&
                    shortListState.results.shortlistItems.length > 0 && (
                      <ShortlistTable
                        BLOCK={BLOCK}
                        setCompareModal={setCompareModal}
                      />
                    )}
                </div>
              )}
            </>
          ) : (
            getEmptyState()
          )}
        </>
      )}
      {showSave &&
        shortListState &&
        shortListState.results &&
        shortListState.results.shortlistName == null && (
          <SaveShortlist
            setShowModal={setShowSave}
            saveShortlist={onSaveShortlistHandler}
          />
        )}
      {showModal && (
        <RenameShortlist
          setShowModal={setShowModal}
          renameShortlist={renameShortlistHandler}
        />
      )}
      {showShare && (
        <ShareToUsersModal
          setShowShare={setShowShare}
          shareShortlist={shareShortlistHandler}
          setShowSearchModal={() => {}}
          shareSearch={() => {}}
          shareCompare={() => {}}
          shareType={shareItemType.shortlist}
        />
      )}
      {showCompareModal && (
        <Compare
          setShowCompareModal={setCompareModal}
          submitCompare={submitCompare}
        />
      )}
      {showShortlistNote && (
        <ShortlistNote
          setShowNote={setshowShortlistNote}
          setCommentBlue={setHasNote}
          noteId={
            shortListState.results.commentNoteId
              ? shortListState.results.commentNoteId
              : 0
          }
        />
      )}
    </div>
  );
};

export default Shortlist;
