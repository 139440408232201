import { ACTIONS as downloadActions } from "services/download/download.service";
import { getComparisonData, getFilters, getLocation } from "./utils";
import { ACTIONS as searchActions } from "services/search/search.results.service";
import { ACTIONS as documentViewerActions } from "services/document-viewer/document-viewer.service";
import { ACTIONS as savedComparisonsActions } from "services/saved-comparisons/saved-comparisons.service";
import { ACTIONS as dashboardActions } from "services/dashboard/dashboard.service";
import { ACTIONS as insightActions } from "services/insights/insights.service";
import { ACTIONS as peerBenchmarkActions } from "services/peer-benchmark/peer-benchmark.service";
import { ACTIONS as commonsActions } from "services/commons.service";
import TRACKING_ACTIONS from "./actions";

declare global {
  interface Window {
    digitalData: any;
  }
}
window.digitalData = window.digitalData || {};

export type TypeLocation = {
  hash: string;
  pageName: string;
  pathname?: string;
};

const getDataLayer = ({ pageName = "", pathname = "", email = "" }) => {
  return {
    page: {
      pageInfo: { pageName: pageName },
      category: { primaryCategory: pathname },
    },
    user: [
      {
        profile: [
          {
            profileInfo: { userName: email },
          },
        ],
      },
    ],
    company: {
      companyInfo: {
        companyVendorList: [],
      },
    },
    search: {
      company: [],
      keywords: "",
      reportPeriod: [],
      industry: [],
      auditor: [],
      governingBoard: [],
    },
    visualization: {
      visualizationName: "",
      visualizationFilters: [],
      visualizationStyle: [],
    },
  };
};

class DataLayer {
  _data: {
    page: { pageInfo: { pageName: any }; category: { primaryCategory: any } };
    user: { profile: { profileInfo: { userName: any } }[] }[];
    company: { companyInfo: { companyVendorList: string[] } };
    search: {
      company: number[];
      keywords: string;
      reportPeriod: number[];
      industry: number[];
      auditor: number[];
      governingBoard: number[];
    };
    visualization: {
      visualizationName: string;
      visualizationFilters: any;
      visualizationStyle: any;
    };
  };
  constructor() {
    this._data = getDataLayer({});
  }

  // Assign a fresh data layer to this data with specified merge props
  set(mergeProps: {
    pageName?: string | undefined;
    pathname?: string | undefined;
    email?: string | undefined;
  }) {
    this._data = getDataLayer(mergeProps);
  }

  // Clear existing digital data
  clear() {
    window.digitalData = getDataLayer({});
  }

  // Merge in props to existing data layer
  merge(...props: any[]) {
    Object.assign(this._data, ...props);
  }

  // Update the global digital data to this data
  update() {
    window.digitalData = this._data;
  }
}

const dl = new DataLayer();
// Update digital data based on the event action and paylaod
const updateDataLayer = (
  eventType: any,
  eventPayload: any,
  reduxState: any
) => {
  // User Data is not yet configured, to be taken from the user info in redux store where it will be located
  const user = reduxState.commons.currentUser;
  const page: TypeLocation = getLocation(
    reduxState.router.location.pathname,
    reduxState.router.location
  );

  // User info and location will always be available on the data layer
  dl.merge({
    user: [
      {
        profile: [
          {
            profileInfo: {
              userName: user.email
                ? user.email
                : window.localStorage.getItem("userID"),
            },
          },
        ],
      },
    ],
    page: {
      pageInfo: {
        pageName:
          eventType === "@@router/LOCATION_CHANGE"
            ? page.pageName
            : eventType === insightActions.INSIGHTS_CREATE_INSIGHT_TRACK
            ? `${TRACKING_ACTIONS[eventType].action} ${eventPayload.dashboardType}`
            : TRACKING_ACTIONS[eventType].action,
      },
      category: { primaryCategory: page.pathname },
    },
    visualization: {},
    company: {},
  });

  if (
    eventType === insightActions.INSIGHTS_CREATE_PEER_GROUP_TRACK ||
    eventType === insightActions.INSIGHTS_EDIT_PEER_GROUP_TRACK ||
    eventType === insightActions.INSIGHTS_DELETE_PEER_GROUP_TRACK
  ) {
    dl.merge({
      page: {
        pageInfo: {
          pageName: `${eventPayload.page} - ${TRACKING_ACTIONS[eventType].action}`,
        },
      },
    });
  }

  if (eventType === downloadActions.DOWNLOAD_SHOW_DOWNLOAD) {
    dl.merge({
      page: {
        pageInfo: {
          pageName: eventPayload,
        },
      },
    });
  }

  if (
    eventType === searchActions.SEARCH_ESG_REPORTS_TRACK ||
    eventType === searchActions.SEARCH_SEC_REPORTS_TRACK
  ) {
    dl.merge({
      search: getFilters(reduxState, eventPayload),
    });
  }

  if (eventType === documentViewerActions.DOCUMENT_VIEWER_SET_SELECTED_REPORT) {
    dl.merge({
      document: { documentInfo: { documentName: eventPayload.reportTitle } },
    });
  }

  if (
    eventType === documentViewerActions.DOCUMENT_VIEWER_SET_SELECTED_DISCLOSURE
  ) {
    dl.merge({
      document: {
        documentInfo: {
          documentName: reduxState.documentViewer.SECStatements
            ? reduxState.documentViewer.SECStatements.title
            : eventPayload.companyName,
        },
      },
    });
  }

  dl.merge({
    comparison: getComparisonData(eventType, eventPayload),
  });

  if (eventType === savedComparisonsActions.ADD_TO_COMPARISON_TRACK) {
    dl.merge({
      page: {
        pageInfo: {
          pageName: eventPayload.pageName,
        },
      },
    });
  }

  if (eventType === savedComparisonsActions.NEW_COMPARISON_TRACK) {
    dl.merge({
      page: {
        pageInfo: {
          pageName: eventPayload.pageName,
        },
      },
    });
  }
  if (eventType === dashboardActions.DASHBOARD_FILTER_TRACK) {
    dl.merge({
      visualization: {
        visualizationFilters: eventPayload.filters,
        visualizationName: eventPayload.name,
      },
    });
  }
  if (
    eventType === dashboardActions.DASHBOARD_MAXIMIZE_TRACK ||
    eventType === dashboardActions.DASHBOARD_EXPORT_TRACK_EXCEL ||
    eventType === dashboardActions.DASHBOARD_EXPORT_TRACK_PDF
  ) {
    dl.merge({
      visualization: {
        visualizationName: eventPayload,
      },
    });
  }
  if (eventType === dashboardActions.DASHBOARD_CHANGE_STYLE_TRACK) {
    dl.merge({
      visualization: {
        visualizationStyle: eventPayload.style,
        visualizationName: eventPayload.name,
      },
    });
  }
  if (
    eventType === insightActions.INSIGHTS_FILTER_TRACK ||
    eventType === insightActions.INSIGHTS_COMPANY_FILTER_TRACK
  ) {
    dl.merge({
      visualization: {
        visualizationFilters: eventPayload.filters,
        visualizationName: eventPayload.name,
      },
    });
  }
  if (
    eventType === insightActions.INSIGHTS_DELETE_TILE_TRACK ||
    eventType === insightActions.INSIGHTS_PDF_EXPORT_ALL_TRACK ||
    eventType === insightActions.INSIGHTS_XLS_EXPORT_ALL_TRACK ||
    eventType === insightActions.INSIGHTS_PPT_EXPORT_ALL_TRACK ||
    eventType === insightActions.INSIGHTS_CREATE_INSIGHT_TRACK
  ) {
    dl.merge({
      visualization: {
        dashboardType: eventPayload.dashboardType,
      },
    });
  }
  if (
    eventType === insightActions.INSIGHTS_PDF_EXPORT_TILE_TRACK ||
    eventType === insightActions.INSIGHTS_XLS_EXPORT_TILE_TRACK ||
    eventType === insightActions.INSIGHTS_PPT_EXPORT_TILE_TRACK ||
    eventType === insightActions.INSIGHTS_TABLE_VIEW_TILE_TRACK ||
    eventType === insightActions.INSIGHTS_DETAILED_VIEW_TILE_TRACK ||
    eventType === insightActions.INSIGHTS_CHART_VIEW_TILE_TRACK
  ) {
    dl.merge({
      visualization: {
        visualizationName: eventPayload.name,
      },
    });
  }
  if (
    eventType ===
      savedComparisonsActions.CONTENT_COMPARISON_COLABORATION_SHARE_TRACK ||
    eventType === insightActions.INSIGHTS_SHARE_WITH_TRACK
  ) {
    dl.merge({
      page: {
        pageInfo: {
          pageName: eventPayload,
        },
      },
    });
  }

  if (eventType === peerBenchmarkActions.SAVE_PEER_BENCHMARK)
    dl.merge({
      page: {
        pageInfo: {
          pageName: `${eventPayload} - ${TRACKING_ACTIONS[eventType].action}`,
        },
      },
    });

  if (dl && eventType === commonsActions.GLOBAL_COMPANY_GROUPING_TRACK)
    dl.merge({ ...eventPayload });

  return dl.update();
};

export default updateDataLayer;
