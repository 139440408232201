import { Route, Router, Switch } from "react-router-dom";
import Layout from "./layout";
import Home from "components/home/home";
import Search from "components/search/search";
import SavedShortList from "components/saved-shortlist/saved-shortlist";
import { useEffect, useState } from "react";
import { authenticate, msalInstance } from "services/api.service";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import UnauthorizedPage from "components/unauthorized/unauthorized";
import PdfViewer from "components/pdf-viewer/pdf-viewer";
import MyComparisons from "components/my-comparisons/my-comparisons";
import {
  comparisonPath,
  dashboardPath,
  peerBenchmarkPath,
  insightsPath,
  reportPath,
  searchPath,
  logout,
  unauthorizedPath,
  powerbiDashboardPath,
  esrs,
  maintenanceMessages,
  MANAGE_ADF_PIPELINE,
  USER_MANAGEMENT,
  ADMIN_TABS,
  MODULE_ACCESS,
} from "utils/constants";
import history from "utils/history";
import DocumentViewer from "components/document-viewer/document-viewer";
import Logout from "components/logout/logout";
import Comparison from "components/comparison/comparison";
import MySearchesContainer from "components/my-searches/my-searches-container";
import ManageFiles from "components/manage-files/manage-files";
import Workspace from "components/workspace/workspace";
import InsightDashboard from "components/insights/dashboard/insight-dashboard";
import CookieNotice from "../cookie-notice/cookie-notice";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { ConnectedRouter } from "connected-react-router-for-react18";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { inProgress } = useMsal();
  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated)
      authenticate().then(setIsAuthenticated);
  }, [setIsAuthenticated, inProgress, isAuthenticated]);
  return (
    <ConnectedRouter history={history}>
      <MsalProvider instance={msalInstance}>
        <span className="cookielink">
          <button id="ot-sdk-btn" className="ot-sdk-show-settings">
            <FormattedMessage id="footer.cookies" />
          </button>
        </span>
        <Switch>
          <Route exact path={insightsPath}>
            <Layout>
              <Workspace currTab={0} />
            </Layout>
          </Route>
          <Route path={`${insightsPath}/:id`}>
            <Layout>
              <InsightDashboard />
            </Layout>
          </Route>
          <Route path={dashboardPath}>
            <Layout>
              <Workspace currTab={1} />
            </Layout>
          </Route>
          <Route path={peerBenchmarkPath}>
            <Layout>
              <Workspace currTab={2} />
            </Layout>
          </Route>
          <Route path={powerbiDashboardPath}>
            <Layout>
              <Workspace currTab={3} />
            </Layout>
          </Route>
          {/* Hiding ESRS Tab for release 2.5 */}
          {/* <Route path={esrs}>
            <Layout>
              <Workspace currTab={4} />
            </Layout>
          </Route> */}
          <Route path={searchPath}>
            <Layout>
              <Search />
            </Layout>
          </Route>
          <Route exact path={`${reportPath}:id`}>
            <Layout hideHeader={true} hideSideMenu={true}>
              <DocumentViewer report={null} />
            </Layout>
          </Route>

          <Route path="/mysearches">
            <Layout>
              <MySearchesContainer />
            </Layout>
          </Route>

          <Route exact path={unauthorizedPath}>
            <Layout>
              <UnauthorizedPage />
            </Layout>
          </Route>

          <Route exact path="/shortlist">
            <Layout>
              <SavedShortList />
            </Layout>
          </Route>
          <Route exact path="/comparisons">
            <Layout>
              <MyComparisons />
            </Layout>
          </Route>
          <Route exact path={logout}>
            <Logout />
          </Route>

          <Route path={USER_MANAGEMENT}>
            <Layout>
              <ManageFiles currTab={ADMIN_TABS.USER_MANAGEMENT} />
            </Layout>
          </Route>
          <Route path={MODULE_ACCESS}>
            <Layout>
              <ManageFiles currTab={ADMIN_TABS.MODULE_ACCESS} />
            </Layout>
          </Route>
          <Route path="/managefiles">
            <Layout>
              <ManageFiles currTab={ADMIN_TABS.MANAGE_FILES} />
            </Layout>
          </Route>
          <Route path={maintenanceMessages}>
            <Layout>
              <ManageFiles currTab={ADMIN_TABS.MAINTENANCE_MESSAGES} />
            </Layout>
          </Route>
          <Route path={MANAGE_ADF_PIPELINE}>
            <Layout>
              <ManageFiles currTab={ADMIN_TABS.MANAGE_ADF_PIPELINE} />
            </Layout>
          </Route>

          <Route path="/cookie-notice">
            <Layout hideSideMenu={true}>
              <CookieNotice />
            </Layout>
          </Route>

          <Route exact path="/faq">
            <Layout hideHeader={true} hideSideMenu={true}>
              <PdfViewer page="faq" />
            </Layout>
          </Route>

          <Route exact path="/qrg">
            <Layout hideHeader={true} hideSideMenu={true}>
              <PdfViewer page="qrg" />
            </Layout>
          </Route>

          <Route path={comparisonPath}>
            <Layout hideSideMenu={true}>
              <Comparison />
            </Layout>
          </Route>

          <Route>
            <Layout>
              <Home />
            </Layout>
          </Route>
        </Switch>
      </MsalProvider>
    </ConnectedRouter>
  );
}

export default App;
