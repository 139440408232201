import { UPLOAD_STATUS } from "utils/constants";
import { BaseModel, errorModel } from "utils/redux.utils";
import { ShortListPageInfo } from "services/shortlist/shortlist.model";
import { FormTypeFilter } from "services/search/filters.model";

export interface ManageFiles {
  error?: errorModel;
  loading: boolean;
  mapFilesLoading: boolean;
  results: ESGReport[];
  pendingItems: PendingItem[];
  nextPageLoading: boolean;
  esgReports: ESGReportFetchResults;
  deleteSuccess: boolean;
  deleteError: boolean;
  uploads: Upload[];
  fileToEdit: ESGReport;
  editedFileResult: any;
  editFileError: boolean;
  editFileSuccess: boolean;
  editFilePopUp: boolean;
  refreshFilesList: boolean;
  deletedRows: number[];
  updatedPendingItems: PendingItem[];
  processFilesComplete: boolean;
  currentFilter: string;
  regions: Region[];
  timezones: Timezone[];
  postMessageSuccess: boolean;
  postMessageError: boolean;
  maintenanceMessagesResult: MaintenanceMessagesResult[];
  maintenanceMessageloading: false;
  activeMaintenanceMessages: activeMaintenanceMessages[];
  cachedActiveMaintenanceMessages: activeMaintenanceMessages[];
  deleteMaintenanceMessageSuccess: boolean;
}

export type PendingItem = {
  reportId: number;
  reportTitle: string;
  reportFileName: string;
  reportYear: number;
  reportStatus: number;
  reportType: number;
  companyId: number;
  companyName: string;
  reportCompany?: {
    cikNumber: string;
  };
  reportYears?: number[];
  governingBoards?: {
    governingBoardId: number;
    governingBoardName: string;
  }[];
  reportFormType?: {
    formTypeId: number;
    formTypeName: string;
  };
};

export type ESGReportFetchResults = {
  pageInfo: ShortListPageInfo;
  reports: ESGReport[];
  shortlistItemIds: number[];
  messages: string[];
};

export type ESGReport = {
  baseReportId: number;
  companyId: number;
  companyName: string;
  formTypeName: string;
  createdDate: string;
  governingBoards: GoverningBoard[];
  reportId: number;
  reportStatus: number;
  reportCompany: ESGReportCompany;
  reportTitle: string;
  reportType: number;
  reportYear: string;
  reportFormType: FormTypeFilter;
  reportYears: number[];
  reportFileName: string;
  invalidCikNumber: boolean;
  duplicatedReport: boolean;
};

export type GoverningBoard = {
  governingBoardId: number;
  governingBoardName: string;
};

export type ESGReportCompany = {
  cikNumber: number;
  companyName: string;
};

export type Region = {
  regionId: number;
  regionName: string;
  isDisabled: boolean;
};

export type Timezone = {
  timezoneId: string;
  timezoneName: string;
  offsetValue: string;
};

export type Upload = {
  fileId: number;
  file: File;
  progress: number;
  progressStatus: UPLOAD_STATUS;
  errorMessages: ErrorMessage[];
};

export type ErrorMessage = {
  reportStatus: number;
  text: string;
};

export interface MaintenanceMessagesResult {
  maintenanceMessageId: number;
  region: Region;
  maintenanceMessageText: string;
  startDate: string;
  endDate: string;
  utcStartDate: string;
  utcEndDate: string;
  timezone: Timezone;
  createdBy: string;
  lastUpdatedBy: string;
  status: number;
  createdDate: string;
  lastUpdatedDate: string;
  createdByName: string;
}

export type MaintenanceMessage = {
  maintenanceMessageId: number;
  region: Region;
  maintenanceMessageText: string;
  startDate: string;
  endDate: string;
  utcStartDate: string;
  utcEndDate: string;
  timezone: Timezone;
  createdBy: string;
  lastUpdatedBy: string;
  status: number;
  createdDate: string;
  lastUpdatedDate: string;
};

export type activeMaintenanceMessages = {
  maintenanceMessageId: number;
  region: Region;
  maintenanceMessageText: string;
  startDate: string;
  endDate: string;
};

export class ManageFilesModel extends BaseModel {
  constructor() {
    super({
      loading: false,
      mapFilesLoading: false,
      nextPageLoading: false,
      esgReports: null,
      uploads: [],
      deleteSuccess: false,
      deleteError: false,
      fileToEdit: null,
      editedFileResult: null,
      editFileError: false,
      editFileSuccess: false,
      editFilePopUp: false,
      refreshFilesList: false,
      deletedRows: [],
      updatedPendingItems: [],
      processFilesComplete: false,
      currentFilter: null,
      maintenanceMessagesResult: null,
      maintenanceMessageloading: false,
      regions: [],
      timezones: [],
      postMessageSuccess: false,
      postMessageError: false,
      activeMaintenanceMessages: [],
      cachedActiveMaintenanceMessages: [],
      deleteMaintenanceMessageSuccess: false,
    });
  }

  setLoading = (loading: boolean): ManageFiles => this.merge({ loading });

  setProcessFilesComplete = (processFilesComplete: boolean): ManageFiles =>
    this.merge({ processFilesComplete });

  setMapFilesLoading = (mapFilesLoading: boolean): ManageFiles =>
    this.merge({ mapFilesLoading });

  setRegions = (regions: Region): ManageFiles => this.merge({ regions });

  setTimezones = (timezones: Timezone): ManageFiles =>
    this.merge({ timezones });

  setActiveMaintenanceMessages = (
    activeMaintenanceMessages: activeMaintenanceMessages[]
  ): ManageFiles => this.merge({ activeMaintenanceMessages });

  setCachedActiveMaintenanceMessages = (
    cachedActiveMaintenanceMessages: activeMaintenanceMessages[]
  ): ManageFiles => this.merge({ cachedActiveMaintenanceMessages });

  setPostMessageSuccess = (postMessageSuccess: boolean): ManageFiles =>
    this.merge({ postMessageSuccess });

  setDeleteMaintenanceMessageSuccess = (
    deleteMaintenanceMessageSuccess: boolean
  ): ManageFiles => this.merge({ deleteMaintenanceMessageSuccess });

  setPostMessageError = (postMessageError: boolean): ManageFiles =>
    this.merge({ postMessageError });

  setNextPageLoading = (nextPageLoading: boolean): ManageFiles =>
    this.merge({ nextPageLoading });
  setESGReports = (esgReports: ESGReportFetchResults): ManageFiles =>
    this.merge({ esgReports });
  setESGReportsNextPage = (esgReports: ESGReportFetchResults): ManageFiles => {
    const allReports = [
      ...this.state.esgReports.reports,
      ...esgReports.reports,
    ];

    esgReports.reports = allReports;

    return this.merge({ esgReports: esgReports, nextPageLoading: false });
  };
  setUploads = (uploads: Upload[]): ManageFiles => this.merge({ uploads });
  updateUploadsProgress = (progressPayload: any): ManageFiles => {
    let tmpUploads = this.state.uploads.map((upload: Upload, i: number) => {
      if (i !== progressPayload.fileIndex) return upload;
      return {
        ...upload,
        progress: progressPayload.progress,
        progressStatus: progressPayload.progressStatus,
      };
    });
    return this.merge({ uploads: tmpUploads });
  };
  updateUploadsErrors = (errorPayload: any): ManageFiles => {
    let tmpUploads = this.state.uploads.map((upload: Upload, i: number) => {
      if (i !== errorPayload.fileIndex) return upload;
      return { ...upload, errorMessages: errorPayload.messages };
    });
    return this.merge({ uploads: tmpUploads });
  };

  setDeleteSuccess = (deleteSuccess: boolean): ManageFiles =>
    this.merge({ deleteSuccess });

  setDeleteError = (deleteError: boolean): ManageFiles =>
    this.merge({ deleteError });

  setFileToEdit = (fileToEdit: any): ManageFiles => this.merge({ fileToEdit });
  setEditedFileResult = (editedFileResult: any): ManageFiles =>
    this.merge({ editedFileResult });
  setEditFileError = (editFileError: boolean): ManageFiles =>
    this.merge({ editFileError });
  setEditFileSuccess = (editFileSuccess: boolean): ManageFiles =>
    this.merge({ editFileSuccess });
  seteditFilePopUp = (editFilePopUp: boolean): ManageFiles =>
    this.merge({ editFilePopUp });
  setRefreshFilesList = (refreshFilesList: boolean): ManageFiles =>
    this.merge({ refreshFilesList });
  resetFileToEdit = (): ManageFiles => this.merge({ fileToEdit: null });
  resetEditedFileResult = (): ManageFiles =>
    this.merge({ editedFileResult: null });
  setPendingItems = (pendingItems: any): ManageFiles =>
    this.merge({
      pendingItems: pendingItems.slice(0, 50),
      updatedPendingItems: pendingItems.slice(0, 50),
    });

  setReportStatus = (reportState: any) => {
    const reports = [...this.state.esgReports.reports];
    const reportIndex = reports.findIndex(
      (report: ESGReport) => report.reportId === reportState.reportId
    );
    if (reportIndex !== -1) {
      reports[reportIndex].reportStatus = reportState.reportStatus;
    }
    return this.merge({
      esgReports: {
        ...this.state.esgReports,
        reports,
      },
    });
  };

  setDeletedRows = (deletedRows: number[]): ManageFiles =>
    this.merge({ deletedRows });

  setUpdatedPendingItems = (updatedPendingItems: any): ManageFiles =>
    this.merge({ updatedPendingItems });

  setCurrentFilter = (currentFilter: any): ManageFiles =>
    this.merge({ currentFilter });

  setMaintenanceMessagesResult = (
    maintenanceMessagesResult: MaintenanceMessagesResult
  ): ManageFiles => this.merge({ maintenanceMessagesResult });

  setMaintenanceLoading = (maintenanceMessageloading: boolean): ManageFiles =>
    this.merge({ maintenanceMessageloading });
}
