import Button from "components/shared/button/button";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import Table, {
  TableHeaderItem,
  TableRowItem,
} from "components/shared/table/table";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import {
  addSuperAdminSuccess,
  apiError,
  deleteSuperAdmin,
  deleteSuperAdminSuccess,
  loadSuperAdmins,
} from "services/user-management/user-management.service";
import UserManagementModal from "../user-management-modal/user-management-modal";
import { addToastMessage } from "services/commons.service";
import DeleteModal from "components/shared/delete-modal/delete-modal";
import { sendExportItem } from "services/download/download.service";
import { DEFAULT_ESG_EXPORT_PAYLOAD } from "utils/constants";
import useAppInsightsCustomMetric from "appinsights/app-insights-custom-metrics";
import { useLocation } from "react-router-dom";

const SuperAdmin = () => {
  const BLOCK = "super-admin";

  const dispatch = useDispatch();
  const superAdminState = useSelector(
    (state: RootStore) => state.userManagement
  );
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );
  const downloadState = useSelector((state: RootStore) => state.download);

  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [showDeleteUserModal, setShowDeleteUserModal] =
    useState<boolean>(false);
  const [deleteUserId, setDeleteUserId] = useState<any>();

  const location = useLocation();
  // App insights custom metric
  useAppInsightsCustomMetric(location.pathname);

  const tableHeaders: TableHeaderItem[] = [
    {
      text: "dashboard.name",
      isFormattedText: true,
    },
    {
      text: "super-admin.email",
      isFormattedText: true,
    },
    {
      text: "super-admin.last.added",
      isFormattedText: true,
    },
    {
      text: "saved-shortlist.actions",
      isFormattedText: true,
    },
  ];

  /* Load Initial Data for Super Admins */
  useEffect(() => {
    dispatch(loadSuperAdmins());
  }, [dispatch]);

  useEffect(() => {
    if (superAdminState?.addSuperAdminSuccess) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="super-admin.add.success" />,
        })
      );
      dispatch(addSuperAdminSuccess(false));
      dispatch(loadSuperAdmins());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superAdminState.addSuperAdminSuccess]);

  useEffect(() => {
    if (superAdminState?.apiError) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="Bad.Request" />,
        })
      );
      dispatch(apiError(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superAdminState.apiError]);

  useEffect(() => {
    if (superAdminState?.deleteSuperAdminSuccess) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="super-admin.delete.success" />,
        })
      );
      dispatch(deleteSuperAdminSuccess(false));
      dispatch(loadSuperAdmins());
      setDeleteUserId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superAdminState.deleteSuperAdminSuccess]);

  const mapTableRowData = (): TableRowItem[] => {
    return (
      superAdminState.results &&
      superAdminState.results
        .filter((item) => item.user.userId !== currentUser.userId)
        .map((item, index) => {
          return {
            id: item.user.userId,
            key: `${BLOCK}-${item.user.userId}`,
            className: `${BLOCK}__table-row`,
            columnItems: [
              { text: `${item.user.lastName}, ${item.user.firstName}` },
              { text: item.user.email },
              { text: moment(item.createdDate).format("MMMM DD, YYYY") },
              {
                renderContent: () => {
                  return (
                    <span
                      key={`${BLOCK}-action-delete}`}
                      data-testid={`${BLOCK}-action-delete`}
                      className={`${BLOCK}__action-icon ${BLOCK}__delete`}
                      onClick={() => {
                        setDeleteUserId(item.user.userId);
                        setShowDeleteUserModal(true);
                      }}
                    >
                      <Tooltip position={TooltipPosition.top}>
                        <FormattedMessage id={"delete"} />
                      </Tooltip>
                      <Icon name={"remove1"} height={24} width={24} />
                    </span>
                  );
                },
              },
            ],
          };
        })
    );
  };

  const exportAdminUsers = () => {
    if (!superAdminState.loading && superAdminState.results) {
      dispatch(
        sendExportItem({
          ...DEFAULT_ESG_EXPORT_PAYLOAD,
          exportFormat: 0,
          exportType: 16,
          exportFileName: "SuperAdmins",
          exportReferenceId: 1,
          downloadTabOpen: downloadState.showDownloads,
        })
      );
    }
  };

  return (
    <div className={BLOCK} data-testid={BLOCK}>
      {/* Loader Section */}
      {superAdminState.loading && (
        <div
          className={`${BLOCK}__loading`}
          data-testid={`${BLOCK}-loading-icon`}
        >
          <Icon
            name="loading"
            height={50}
            width={50}
            className="loading-icon"
          />
        </div>
      )}
      {!superAdminState.loading && (
        <div
          className={`${BLOCK}__container`}
          data-testid={`${BLOCK}-container`}
        >
          {/* Header Section */}
          <div className={`${BLOCK}__header`}>
            <div className={`${BLOCK}__header-left`}>
              <FormattedMessage id="super-admin.title" />
            </div>
            <div className={`${BLOCK}__header-right`}>
              <Button
                className="button-secondary"
                formattedText="super-admin.export"
                iconName="download-blue"
                iconHeight={24}
                iconWidth={20}
                onClick={() => exportAdminUsers()}
                dataTest={`${BLOCK}-export-button`}
              />
              <Button
                className="button-primary"
                formattedText="super-admin.add"
                iconName="add-plus"
                iconHeight={24}
                iconWidth={20}
                onClick={() => setShowAddUserModal(true)}
                dataTest={`${BLOCK}-add-button`}
              />
            </div>
          </div>
          {/* Table Grid */}
          <div
            className={`${BLOCK}__table-wrapper`}
            data-testid={`${BLOCK}-table-content`}
          >
            <Table
              className={`${BLOCK}__table-inner-wrapper`}
              innerClassName={`${BLOCK}__table`}
              headerItems={tableHeaders}
              rowItems={mapTableRowData()}
              dataTest={`${BLOCK}-table`}
            />
          </div>
          {/* Add User Modal */}
          <div>
            {showAddUserModal && (
              <UserManagementModal
                showModal={showAddUserModal}
                onClose={() => setShowAddUserModal(false)}
              />
            )}
          </div>
          {/* Delete User Modal */}
          {showDeleteUserModal && (
            <DeleteModal
              title="super-admin.delete.title"
              message={
                <>
                  <FormattedMessage id="super-admin.delete.subtitle" />
                </>
              }
              handleCloseModal={() => {
                setShowDeleteUserModal(false);
              }}
              deleteItem={() => {
                dispatch(deleteSuperAdmin(deleteUserId));
                setShowDeleteUserModal(false);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SuperAdmin;
