import { useState, useEffect } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Checkbox from "components/shared/checkbox/checkbox";
import Icon from "components/shared/icon/icon";
import Spinner from "images/spinner.gif";
import { MouseEventHandler, useRef } from "react";
import { RootStore } from "services/store.service";
import FilterPopup from "components/shared/filter-popup/filter-popup";
import TableRow from "./table-row";
import { setShowRequestUploadPanel } from "services/search/search.results.service";

export type CheckboxOptions = {
  onChange: any;
  value?: boolean;
  indeterminate?: boolean;
};

type Filter = {
  activeFilter: string;
  currentFilterText: string;
  currentFilter: string;
  setFilterPop: Function;
  handleClose: Function;
  handleApply: Function;
  handleDateFilter?: Function;
};

type TableProps = {
  className?: string;
  innerClassName?: string;
  headerItems?: TableHeaderItem[];
  rowItems?: TableRowItem[];
  showSpinner?: boolean;
  selectedRow?: number;
  sortBy?: string;
  sortOrder?: string;
  onSort?: Function;
  defaultSelectedItems?: Partial<TableRowItem>[];
  filter?: Filter;
  showRequestUploadMsg?: boolean;
  customWidth?: number;
  maxHeight?: boolean;
  tableRef?: any;
  dataTest?: string;
};

export type TableHeaderItem = {
  icon?: string;
  iconWithText?: any;
  iconSize?: number;
  text?: string;
  isFormattedText?: boolean;
  className?: string;
  sortId?: string;
  filterId?: string;
  dateRange?: boolean;
  filterPosition?: string;
  type?: string;
  hidden?: boolean;
  checkbox?: CheckboxOptions;
  filter?: any;
};

export type TableRowItem = {
  id: number;
  columnItems: TableColumnItem[];
  key?: string;
  rowSelected?: boolean;
  isSelected?: boolean;
  className?: string;
  updateSelected?: Function;
  showItems?: any;
  setShowItems?: Function;
  filterContent?: boolean;
  onClick?: MouseEventHandler;
  tableRowClassName?: string
};

export type TableColumnItem = {
  id?: number;
  icon?: string;
  iconSize?: number;
  iconTooltip?: any;
  text?: string;
  textTooltip?: any;
  subTitle?: string;
  isFormattedText?: boolean;
  renderContent?: Function;
  className?: string;
  onClick?: MouseEventHandler;
  type?: string;
  wrapperClassName?: string;
  hidden?: boolean;
  checkbox?: CheckboxOptions;
  noteId?: number;
  commentNodeID?: number;
  filterContent?: boolean;
  updateSelected?: Function;
  isSelected?: boolean;
  setShowNotes?: Function;
  tableRowClassName?: string
  tableColumnClassName?: String
};

const Table = ({
  className,
  innerClassName,
  headerItems,
  rowItems,
  showSpinner,
  selectedRow,
  sortBy,
  sortOrder,
  onSort,
  defaultSelectedItems,
  filter,
  showRequestUploadMsg,
  customWidth,
  maxHeight,
  tableRef,
  dataTest,
}: TableProps) => {
  const BLOCK = "Table";
  const headerRef = useRef(null);
  const searchResults = useSelector((state: RootStore) => state.searchResults);
  const [selectedItems, setSelectedItems] = useState<Partial<TableRowItem>[]>(
    defaultSelectedItems ? defaultSelectedItems : []
  );

  const [showItems, setshowItems] = useState<Partial<any>[]>([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [filterContent, setFilterContent] = useState<boolean>(
    searchResults.searchPayloadSEC.contentText.length !== 0
  );

  const isSelected = (itemId: number): boolean =>
    selectedItems.filter(({ id }) => id === itemId).length > 0;

  const dispatch = useDispatch();

  const styleProps = customWidth
    ? {
        style: {
          width: customWidth,
        },
      }
    : {};

  const styleContainerProps = maxHeight
    ? {
        style: { minHeight: "30vh", maxHeight: "45vh", overflow: "auto" },
      }
    : {};

  const tableReference = tableRef ? { ref: tableRef } : {};

  const updateSelected = (item: TableRowItem) => {
    let selectedItemsTmp = [];
    if (isSelected(item.id)) {
      selectedItemsTmp = selectedItems.filter(({ id }) => id !== item.id);
    } else {
      selectedItemsTmp = [...selectedItems, item];
    }
    setSelectedItems(selectedItemsTmp);

    if (selectedAll) {
      setSelectedAll(!selectedAll);
    }
    if (!selectedAll && selectedItemsTmp.length === rowItems?.length) {
      setSelectedAll(!selectedAll);
    }

    return selectedItemsTmp;
  };

  const updateAllSelected = (item: any, selected: boolean) => {
    let selectedItemsTmp = [];
    //let notAllSelected = rowItems && rowItems.length !== item.length;
    if (!selected) {
      selectedItemsTmp = item;
    }
    setSelectedItems(selectedItemsTmp);
    return selectedItemsTmp;
  };

  useEffect(() => {
    setSelectedItems(defaultSelectedItems ? defaultSelectedItems : []);
  }, [defaultSelectedItems]);

  useEffect(() => {
    if (selectedItems.length !== rowItems?.length) {
      setSelectedAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowItems]);

  useEffect(() => {
    setFilterContent(searchResults.searchPayloadSEC.contentText.length !== 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults.searchPayloadSEC.contentText]);

  const renderHeaderItemContents = (item: TableHeaderItem) => {
    let icon;

    const contents = (
      <>
        {item.text && (
          <div
            className={classNames(`${BLOCK}__header-text`, {
              [`${innerClassName}__header-text`]: innerClassName,
            })}
          >
            <span id={item.text}>
              {item.isFormattedText ? (
                <FormattedMessage id={item.text} />
              ) : (
                item.text
              )}
            </span>
            {item.iconWithText}
            {item.filterId && filter ? (
              <>
                <div
                  className={classNames(`filter`, {
                    [`filter__active`]:
                      filter.activeFilter === item.filterId ||
                      filter.currentFilter === item.text,
                  })}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    filter.setFilterPop(item.text);
                  }}
                  data-testid="filter-icon"
                >
                  <Icon name="filter" height={14} width={14} />
                </div>
                {filter.currentFilter === item.text && (
                  <div
                    className={classNames(`filter-modal`, {
                      [`filter-modal--right`]: item.filterPosition === "right",
                      [`filter-modal--left`]: item.filterPosition === "left",
                    })}
                  >
                    <FilterPopup
                      iconName="cross"
                      headerId={item.text}
                      currentFilterText={
                        filter.activeFilter === item.filterId
                          ? filter.currentFilterText
                          : ""
                      }
                      handleClose={() => filter.handleClose()}
                      handleApply={(e: any, filterText: string) => {
                        if (item.dateRange && filter.handleDateFilter) {
                          filter.handleDateFilter(filterText, item.filterId);
                        } else {
                          filter.handleApply(filterText, item.filterId);
                        }
                      }}
                      dateRange={item.dateRange}
                    />
                  </div>
                )}
              </>
            ) : item.filter ? (
              <>
                <>
                  <div
                    className={classNames(`filter`, {
                      [`filter__active`]:
                        item.filter.activeFilter || item.filter.isFiltering,
                    })}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      item.filter.setFilterPop(item.text);
                    }}
                    data-test="filter button"
                  >
                    <Icon name="filter" height={14} width={14} />
                  </div>
                  {item.filter.isFiltering && (
                    <div
                      className={classNames(`filter-modal`, {
                        [`filter-modal--right`]:
                          item.filter.modalPosition === "right",
                        [`filter-modal--left`]:
                          item.filter.modalPosition === "left",
                      })}
                    >
                      <FilterPopup
                        iconName="cross"
                        headerId={item.text}
                        currentFilterText={item.filter.currentFilterText}
                        handleClose={() => item.filter.handleClose()}
                        handleApply={(e: any, filterText: string) => {
                          item.filter.handleApply(filterText);
                        }}
                        dateRange={item.filter.dateRange}
                        data-test="filter-popup"
                      />
                    </div>
                  )}
                </>
              </>
            ) : null}
          </div>
        )}
        {item.icon && item.type === "sec" && filterContent && (
          <Icon name={item.icon} height={item.iconSize} width={item.iconSize} />
        )}
        {item.icon && item.type === "esg" && (
          <Icon name={item.icon} height={item.iconSize} width={item.iconSize} />
        )}
        {item.checkbox &&
          (item.type === "shortlist" ||
            item.type === "esg" ||
            (item.type === "sec" && filterContent)) && (
            <div className={`${BLOCK}__checkbox-container`}>
              <Checkbox
                value={item.checkbox.value || selectedAll}
                indeterminate={item.checkbox.indeterminate}
                onChange={() => {
                  const selected = item.checkbox?.value || selectedAll;
                  const rows = rowItems?.map((row) => row);
                  const updatedRows = updateAllSelected(rows, selected);
                  item.checkbox?.onChange(updatedRows);
                  setSelectedAll(!selected);
                }}
              />
            </div>
          )}
      </>
    );

    if (onSort && item.sortId) {
      const className =
        sortBy === item.sortId
          ? `${BLOCK}__header-inner-content ${BLOCK}__header-button ${BLOCK}__header-button--sorted`
          : `${BLOCK}__header-inner-content ${BLOCK}__header-button`;
      if (sortBy === item.sortId && sortOrder && sortOrder === "asc") {
        icon = (
          <Icon
            name="sort-ascending"
            width={16}
            height={16}
            className={`${BLOCK}__sort-icon`}
          />
        );
      } else if (sortBy === item.sortId && sortOrder && sortOrder === "desc") {
        icon = (
          <Icon
            name="sort-descending"
            width={16}
            height={16}
            className={`${BLOCK}__sort-icon`}
          />
        );
      }

      return (
        <div
          className={className}
          role="button"
          data-testid="sort-button"
          onClick={() => onSort(item.sortId)}
        >
          {icon}
          {contents}
        </div>
      );
    } else {
      return <div className={`${BLOCK}__header-inner-content`}>{contents}</div>;
    }
  };

  return (
    <div
      className={`${BLOCK} ${className || ""}`}
      {...styleContainerProps}
      {...tableReference}
    >
      <table
        className={`${BLOCK}__table ${innerClassName || ""}`}
        {...styleProps}
        data-test={dataTest ?? ""}
        data-testid={dataTest ?? ""}
      >
        <thead ref={headerRef}>
          <tr
            className={classNames(`${BLOCK}__header`, {
              [`${innerClassName}__header`]: innerClassName,
            })}
          >
            {headerItems &&
              headerItems.map((item, index) =>
                item.hidden ? null : (
                  <th
                    className={classNames(`${BLOCK}__header-content`, {
                      [`${item.className}`]: item.className,
                      [`${innerClassName}__header-content`]: innerClassName,
                    })}
                    key={index}
                  >
                    {renderHeaderItemContents(item)}
                  </th>
                )
              )}
          </tr>
        </thead>
        <tbody>
          {rowItems &&
            rowItems.map((row) => (
              <TableRow
                key={row.key || row.id.toString()}
                {...row}
                rowSelected={row.id === selectedRow}
                isSelected={isSelected(row.id)}
                updateSelected={() => updateSelected(row)}
                showItems={showItems}
                setShowItems={setshowItems}
                filterContent={filterContent}
              />
            ))}
        </tbody>
      </table>
      {showRequestUploadMsg && rowItems && rowItems.length < 7 ? (
        <div className={`Search__upload-msg-container`}>
          <span className="Search__upload-text">
            <FormattedMessage id="search.no-results.message.second" />
            <button
              onClick={() => {
                dispatch(setShowRequestUploadPanel(true));
              }}
              className={`Search__upload-btn`}
            >
              <FormattedMessage id="search.request.upload.btn.first" />
            </button>
            <FormattedMessage id="search.request.upload.btn.second" />
          </span>
        </div>
      ) : null}
      {showSpinner && (
        <div className={`${BLOCK}__spinner-container`}>
          <img className={`${BLOCK}__spinner`} src={Spinner} alt={"Loading"} />
        </div>
      )}
    </div>
  );
};

export default Table;
