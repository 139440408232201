import DownloadsHeader from "./downloads-header";
import DownloadItem from "./download-item";
import { useState, useEffect } from "react";
import socket, { rooms } from "utils/socket";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { DOWNLOAD_EXPORT_TYPES } from "utils/constants";
import { addToastMessage } from "services/commons.service";
import { updateDownloadState } from "services/download/download.service";
import classNames from "classnames";
import { showSidedashboardTab } from "services/dashboard/dashboard.service";

type Props = {
  className?: string;
};

const Downloads = ({ className }: Props) => {
  const BLOCK = "Downloads";
  const dispatch = useDispatch();
  const downloadState = useSelector((state: RootStore) => state.download);
  const commonsState = useSelector((state: RootStore) => state.commons);
  const [activeTab, setActiveTab] = useState<number>(0);

  /*istanbul ignore next*/
  useEffect(() => {
    if (commonsState.currentUser.userId) {
      socket.on("export.update", updateDownloads);
      socket.join(rooms.exports(commonsState.currentUser.userId));

      return () => {
        socket.leave(rooms.exports(commonsState.currentUser.userId));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonsState.currentUser.userId]);

  useEffect(() => {
    if (downloadState.showDownloads) {
      dispatch(showSidedashboardTab(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadState.showDownloads]);

  const updateDownloads = (payload: any) => {
    const exportState = payload.data.exportState;
    if (exportState === DOWNLOAD_EXPORT_TYPES.READY_TO_DOWNLOAD) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage id="downloads.toaster.progress.success" />
          ),
        })
      );
    } else if (exportState === DOWNLOAD_EXPORT_TYPES.ERROR) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage id="downloads.toaster.progress.error" />
          ),
        })
      );
    }
    dispatch(updateDownloadState(payload.data));
  };

  return downloadState.showDownloads ? (
    <div
      className={classNames(`${BLOCK}`, className)}
      data-test="component-downloads"
    >
      <div className={`${BLOCK}__header`} data-test="downloads-header">
        <DownloadsHeader BLOCK={`${BLOCK}__header`} />
      </div>
      <div
        className={`${BLOCK}__secondHeader`}
        data-test="downloads-second-header"
      >
        <FormattedMessage id="downloads.header.second" />
      </div>
      <div className={`${BLOCK}__body`} data-test="downloads-body">
        {downloadState.downloads?.map((item) => (
          <DownloadItem
            key={item.exportDetailId}
            BLOCK={`${BLOCK}__body`}
            item={item}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default Downloads;
