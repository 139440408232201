import { useEffect } from "react";
import Note from "../shared/note/note";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import { loadNote } from "services/note/note.service";
import { NOTE_TYPES } from "utils/constants";

type props = {
  setShowNote: (value: boolean) => void;
  setCommentBlue: (value: boolean) => void;
  comparisonNote?: number;
  comparisonItemId?: number;
};

const ComparisonNotes = ({
  setShowNote,
  comparisonNote,
  setCommentBlue,
  comparisonItemId,
}: props) => {
  // const BLOCK = "Note";
  // const comparisonState = useSelector(
  //   (state: RootStore) => state.comparisonState
  // );
  const noteState = useSelector((state: RootStore) => state.note);
  const dispatch = useDispatch();
  const headerAddNote = "comparison.note.header";
  const headerEditNote = "comparison.note.edit.header";
  const inputNote = "comparison.note.input";

  useEffect(() => {
    const Payload = {
      commentId: comparisonNote,
    };
    dispatch(loadNote(Payload));
  }, [comparisonNote, dispatch]);

  return (
    <>
      {!noteState.loading && !noteState.NoteFetch.commentNoteId ? (
        <div data-test="note-add-component">
          <Note
            setShowModal={setShowNote}
            setCommentBlue={setCommentBlue}
            headerNote={headerAddNote}
            inputNote={inputNote}
            referenceId={comparisonItemId ? comparisonItemId : 0}
            noteExist={false}
            commentNoteReferenceType={NOTE_TYPES.COMPARISON_ITEM}
          />
        </div>
      ) : (
        !noteState.loading &&
        noteState.NoteFetch.commentNoteId &&
        noteState.NoteFetch.commentNotes && (
          <div data-test="note-component">
            <Note
              setShowModal={setShowNote}
              setCommentBlue={setCommentBlue}
              headerNote={headerEditNote}
              inputNote={inputNote}
              referenceId={comparisonItemId ? comparisonItemId : 0}
              noteId={comparisonNote}
              noteExist={true}
              commentNoteReferenceType={NOTE_TYPES.COMPARISON_ITEM}
            />
          </div>
        )
      )}
    </>
  );
};

export default ComparisonNotes;
