import Icon from "components/shared/icon/icon";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import FormattedMessage from "components/shared/formatted-message/formatted-message";

type props = {
  BLOCK: string;
  onClick: () => void;
  isMenuOpen: boolean;
};

const MenuButton = ({ BLOCK, onClick, isMenuOpen }: props) => {
  return (
    <button
      className={`${BLOCK}__menu-item ${BLOCK}__menu-item--button`}
      onClick={onClick}
    >
      <Icon name={isMenuOpen ? "cross" : "menu"} height={24} />
      <Tooltip position={TooltipPosition.right}>
        <FormattedMessage
          id={`side-menu.menu-item.${isMenuOpen ? "menu-collapse" : "menu"}`}
        />
      </Tooltip>
    </button>
  );
};

export default MenuButton;
