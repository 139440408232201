import { BaseModel, errorModel } from "utils/redux.utils";

export interface NotificationState {
  error?: errorModel;
  loading: boolean;
  NotificationFetch: NotificationData[];
}

export class NotificationModel extends BaseModel {
  constructor() {
    super({
      loading: false,
      NotificationFetch: [],
    });
  }
  setError = (error: errorModel): NotificationState => this.merge({ error });
  setLoading = (loading: boolean): NotificationState => this.merge({ loading });
  setNotificationList = (
    NotificationFetch: NotificationData[]
  ): NotificationState => this.merge({ NotificationFetch, loading: false });
}

export type NotificationData = {
  notificationId: number;
  globalPersonId: number;
  notificationTitle: string;
  notificationBody: string;
  notificationType: number;
  notificationStatus: number;
  notificationLogLevel: number;
  createdDate: string;
  lastUpdatedDate: string;
  referenceId: number;
};
