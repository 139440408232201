import React, { Fragment, useEffect, useRef, useState } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import BenchmarkGridRow, { renderCompanyData } from "./benchmark-grid-row";
import { RootStore } from "services/store.service";
import { useDispatch, useSelector } from "react-redux";
import Icon from "components/shared/icon/icon";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import classNames from "classnames";
import FilterPopup from "components/shared/filter-popup/filter-popup";
import { showTooltip } from "services/commons.service";
import ResizeGridHeader from "./resize-grid-header";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  GlobalCompanyGroup,
  GlobalCompanyMapping,
} from "services/insights/insights.model";
import { patchUserPreference } from "services/peer-benchmark/peer-benchmark.service";
import {
  UserPreference,
  companiesDisplayOrderObject,
  fieldDisplayOrderObject,
  fieldColumnWidthObject,
  companiesColumnWidthObject,
} from "services/peer-benchmark/peer-benchmark.model";

export type RowItemCompany = {
  companyName: string;
  ratingDate: string;
  globalCompanyId: number;
  isBaseline: boolean;
  fields: any;
};

export type BenchmarkCompany = {
  benchmarkId: number;
  globalCompanyId: number;
  isBaseCompany: boolean;
  displayOrder: number;
  company: {
    companyId: number;
    companyName: string;
    globalCompanyId: number;
  };
  globalCompanyMapping?: Partial<GlobalCompanyGroup>;
  globalCompany?: {
    companyId: number;
    companyName: string;
    globalCompanyId: number;
  };
};

type MetricsTableHeaders = {
  displayName: string;
  fieldId: number;
  fieldName: string;
  description: string;
};

type Filter = {
  activeFilter: string;
  currentFilterText: string;
  currentFilter: string;
  setFilterPop: Function;
  handleClose: Function;
  handleApply: Function;
  handleDateFilter?: Function;
};

export const compare = (company1: any, company2: any) => {
  if (company1.displayOrder < company2.displayOrder) {
    return -1;
  }
  if (company1.displayOrder > company2.displayOrder) {
    return 1;
  }
  return 0;
};

const BenchmarkGridView = ({
  transpose,
  filter,
  removeCellOrRow,
  loadingTable,
  setLoadingTable,
  benchmarkCompanies,
  userPreferences,
  setUserPreferences,
  setHasNullValues,
}: {
  transpose: boolean;
  filter?: Filter;
  removeCellOrRow: Function;
  loadingTable: boolean;
  setLoadingTable: Function;
  benchmarkCompanies: BenchmarkCompany[];
  userPreferences: Partial<UserPreference>;
  setUserPreferences: Function;
  setHasNullValues: Function;
}) => {
  const BLOCK = "benchmark-grid";
  const tableRef = useRef(null);
  const tableHeaderRef = useRef(null);
  const peerBenchmarkState = useSelector(
    (state: RootStore) => state.peerBenchmarkState
  );
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const [rowItemsCompany, setRowItemsCompany] = useState<RowItemCompany[]>();
  const [tableHeight, setTableHeight] = useState(0);
  const [tableHeaderHeight, setTableHeaderHeight] = useState(0);
  const [metricsTableHeaders, setMetricsTableHeaders] =
    useState<MetricsTableHeaders[]>();
  const infoHeadersTooltipMaxWidth = 300;
  const dispatch = useDispatch();
  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    position: TooltipPosition = TooltipPosition.top,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("span");
    if (!element) {
      return;
    }
    dispatch(
      showTooltip({
        children: children,
        position: position,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };
  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;

    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };
  const handleTouchScroll = (e: any) => {
    if (!tooltip.children) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };
  useEffect(() => {
    const { currentSavedBenchmark } = peerBenchmarkState;
    const { savedUserPreference } = peerBenchmarkState;
    if (currentSavedBenchmark && savedUserPreference) {
      let benchmarkFieldValues = currentSavedBenchmark?.benchmarkFieldValues;
      let fieldData = currentSavedBenchmark?.fields;
      const fieldIdRatingDate = fieldData[0]?.fieldId;

      const { userPreferenceValue } = userPreferences;
      let { companiesDisplayOrder, fieldDisplayOrder } =
        userPreferenceValue || {};

      let rowItems: RowItemCompany[] = [];

      if (companiesDisplayOrder && companiesDisplayOrder.length > 0) {
        let tmpBaseCompany = benchmarkCompanies.filter(
          (benchmarkCompanyObj: BenchmarkCompany) =>
            benchmarkCompanyObj.isBaseCompany
        );
        let tmpBenchmarkCompanies = benchmarkCompanies
          .filter(
            (benchmarkCompanyObj: BenchmarkCompany) =>
              !benchmarkCompanyObj.isBaseCompany
          )
          .map((company: BenchmarkCompany) => {
            return {
              ...company,
              displayOrder:
                companiesDisplayOrder?.filter(
                  (companyDisplay: companiesDisplayOrderObject) =>
                    companyDisplay.globalCompanyId === company.globalCompanyId
                )[0]?.displayOrder ?? company.displayOrder,
            };
          })
          .sort(compare);

        benchmarkCompanies = tmpBaseCompany!.concat(tmpBenchmarkCompanies);
      }

      if (fieldDisplayOrder && fieldDisplayOrder.length > 0) {
        fieldData = fieldData
          .map((field: any) => {
            return {
              ...field,
              displayOrder:
                fieldDisplayOrder?.filter(
                  (fieldObj: fieldDisplayOrderObject) =>
                    fieldObj.fieldId === field.fieldId
                )[0]?.displayOrder ?? field.displayOrder,
            };
          })
          .sort(compare);
        benchmarkFieldValues = benchmarkFieldValues
          .map((field: any) => {
            return {
              ...field,
              displayOrder:
                fieldDisplayOrder?.filter(
                  (fieldObj: fieldDisplayOrderObject) =>
                    fieldObj.fieldId === field.fieldId
                )[0]?.displayOrder ?? field.displayOrder,
            };
          })
          .sort(compare);
      } else {
        fieldData.sort(compare);
      }
      benchmarkCompanies.forEach((company: BenchmarkCompany) => {
        let globalCompanyIds =
          company.globalCompanyMapping &&
          company.globalCompanyMapping.globalCompanyMappingDetails &&
          company.globalCompanyMapping.globalCompanyMappingDetails.length > 0
            ? company.globalCompanyMapping.globalCompanyMappingDetails.map(
                (d: GlobalCompanyMapping) =>
                  d.globalCompanyId
                    ? d.globalCompanyId
                    : d.globalCompany.globalCompanyId
              )
            : [company.globalCompanyId];
        const fieldIds: number[] = [];
        const currentBenchmarkFieldValues = benchmarkFieldValues.filter(
          (fieldValue: any) =>
            globalCompanyIds.includes(fieldValue.globalCompanyId)
        );
        //REMOVE DUPLICATED VALUES
        let tmpCurrentBenchmarkFieldValues: any = [];
        currentBenchmarkFieldValues.forEach((val: any) => {
          if (fieldIds.includes(val.fieldId)) {
            return;
          }
          let entries = currentBenchmarkFieldValues.filter(
            (currentVal: any) => currentVal.fieldId === val.fieldId
          );
          let tmpBenchmarkFieldValue = val;
          if (
            entries.length > 1 &&
            entries.some((bv: any) => bv.fieldValue || bv.fieldValue !== "")
          )
            tmpBenchmarkFieldValue = entries.find(
              (bv: any) => bv.fieldValue || bv.fieldValue !== ""
            );

          tmpCurrentBenchmarkFieldValues.push(tmpBenchmarkFieldValue);
          fieldIds.push(val.fieldId);
        });

        const ratingDate = tmpCurrentBenchmarkFieldValues.find(
          (fieldValue: any) => fieldValue.fieldId === fieldIdRatingDate
        );

        const formattedDate = ratingDate ? ratingDate.fieldValue : "";

        const companyFieldValues = tmpCurrentBenchmarkFieldValues
          .filter((fieldValue: any) => fieldValue.fieldId !== fieldIdRatingDate)
          .map((fieldValue: any) => {
            const fieldValueData = fieldData.find(
              (d: any) => d.fieldId === fieldValue.fieldId
            );
            return { ...fieldValue, dataType: fieldValueData?.dataType };
          });

        rowItems.push({
          companyName: company.globalCompany!.companyName,
          ratingDate: ratingDate ? formattedDate : "N/A",
          globalCompanyId: company.globalCompanyId,
          isBaseline: company.isBaseCompany,
          fields: companyFieldValues,
        });
      });
      let metricHeaders: MetricsTableHeaders[] = [];
      fieldData.forEach((field: any, index: number) => {
        if (index !== 0) {
          metricHeaders.push({
            fieldId: field.fieldId,
            displayName: field.displayName,
            fieldName: field.fieldName,
            description: field.description,
          });
        }
      });

      if (!transpose && filter) {
        rowItems = rowItems.filter((company: any) =>
          company.companyName
            .toLowerCase()
            .includes(filter.currentFilterText.toLowerCase())
        );
      }
      setRowItemsCompany(rowItems);
      setMetricsTableHeaders(metricHeaders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    peerBenchmarkState.currentSavedBenchmark,
    peerBenchmarkState.savedUserPreference,
  ]);

  useEffect(() => {
    setLoadingTable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transpose]);

  useEffect(() => {
    if ((tableRef as any)?.current?.offsetHeight) {
      setTableHeight((tableRef as any)?.current?.offsetHeight);
    }
    if ((tableHeaderRef as any)?.current?.offsetHeight) {
      setTableHeaderHeight((tableHeaderRef as any)?.current?.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (rowItemsCompany?.some((rowItem: any) => rowItem.fields.length === 0)) {
      setHasNullValues(true);
    }
    if (
      rowItemsCompany &&
      rowItemsCompany?.length > 0 &&
      metricsTableHeaders &&
      metricsTableHeaders.length > 0
    ) {
      rowItemsCompany?.forEach((company: any) => {
        if (company.fields && company.fields.length > 0) {
          metricsTableHeaders?.forEach((field: any) => {
            if (
              !company.fields.some(
                (companyfield: any) => companyfield.fieldId === field.fieldId
              )
            ) {
              setHasNullValues(true);
            }
          });
        }
      });
    }
  }, [rowItemsCompany]);

  const tableHeaders = [
    {
      text: "peer-benchmarks.grid.company",
      needsInfo: false,
      filterId: "companyName",
      ratingDate: {
        text: "peer-benchmarks.grid.ratingDate",
        needsInfo: true,
      },
    },
  ];

  const renderHeaderItemContents = (
    item: any,
    index: number,
    canRemove?: boolean
  ) => {
    return (
      <div className={`${BLOCK}__table--header--content-wrapper`}>
        {metricsTableHeaders && metricsTableHeaders.length > 1 && canRemove && (
          <button
            className={`${BLOCK}__table--header-remove-button`}
            onClick={() => removeCellOrRow(item.fieldName)}
            data-testid="remove-button"
          >
            <Icon name="cross" width={16} height={16} />
          </button>
        )}
        {item.text && item.text.length > 0 ? (
          <div
            className={classNames({
              [`${BLOCK}__table--header--content--items`]: index !== 1,
              [`${BLOCK}__table--header--content--items--rating`]: index === 1,
              [`${BLOCK}__table--header--content--items--transpose`]: transpose,
            })}
          >
            {transpose ? (
              index === 0 ? (
                <span data-test="Metrics">
                  <FormattedMessage id="peer.benchmark.header.metrics" />
                </span>
              ) : null
            ) : (
              <>
                <div
                  className={classNames({
                    [`${BLOCK}__table--header--content--items--company`]:
                      index === 0,
                  })}
                >
                  <span
                    id={item.text}
                    className={`${BLOCK}__table--header--content--items--company--text`}
                  >
                    <FormattedMessage id={item.text} />
                  </span>
                  {item.filterId && filter && (
                    <>
                      <div
                        className={classNames(`filter`, {
                          [`filter__active`]:
                            filter.activeFilter === item.filterId ||
                            filter.currentFilter === item.text,
                        })}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          filter.setFilterPop(item.text);
                        }}
                        data-testid="filter"
                      >
                        <Icon name="filter" height={14} width={14} />
                      </div>
                      {filter.currentFilter === item.text && (
                        <div
                          className={classNames(`filter-modal`, {
                            [`filter-modal--right`]:
                              item.filterPosition === "right",
                            [`filter-modal--left`]:
                              item.filterPosition === "left",
                          })}
                        >
                          <FilterPopup
                            iconName="cross"
                            headerId={item.text}
                            currentFilterText={
                              filter.activeFilter === item.filterId
                                ? filter.currentFilterText
                                : ""
                            }
                            handleClose={() => filter.handleClose()}
                            handleApply={(e: any, filterText: string) => {
                              if (item.dateRange && filter.handleDateFilter) {
                                filter.handleDateFilter(
                                  filterText,
                                  item.filterId
                                );
                              } else {
                                filter.handleApply(filterText, item.filterId);
                              }
                            }}
                            dateRange={item.dateRange}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                {item.ratingDate && (
                  <div
                    className={classNames(
                      `${BLOCK}__table--header--content--items--rating`,
                      {
                        [`${BLOCK}__table--header--content--items--transpose`]:
                          transpose,
                      }
                    )}
                  >
                    <span
                      id={item.ratingDate.text}
                      className={`${BLOCK}__table--header--content--items--rating--label`}
                    >
                      <FormattedMessage id={item.ratingDate.text} />
                    </span>
                    {item.ratingDate.needsInfo && (
                      <div
                        className={`${BLOCK}__table--header--content--items--rating--tooltip`}
                      >
                        <Icon name="information" width={24} height={24} />
                        <Tooltip
                          id="company-tooltip"
                          position={TooltipPosition.right}
                        >
                          <FormattedMessage id="peer.benchmark.tooltip.ratingDate.info" />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <>
            <span id={item.text}>{item.displayName}</span>
            <span
              className={`${BLOCK}-row__info-icon ${BLOCK}-row__info-icon--bottom`}
              onMouseEnter={(e) => {
                handleMouseEnter(
                  e,
                  item.description,
                  `${BLOCK}-row__info-icon-tooltip `,
                  metricsTableHeaders &&
                    index === metricsTableHeaders.length - 1
                    ? TooltipPosition.bottomLeft
                    : TooltipPosition.bottom,
                  infoHeadersTooltipMaxWidth
                );
              }}
              onMouseLeave={handleMouseLeave}
              data-testid="row-info-icon"
            >
              <Icon name="information" width={25} height={25} />
            </span>
          </>
        )}
      </div>
    );
  };

  const renderRow = (item: any, index: number) => {
    const currentCompanyValues = rowItemsCompany?.find(
      (company) => company.globalCompanyId === item.globalCompanyId
    );
    return (
      <BenchmarkGridRow
        rowIndex={index}
        rowItems={item}
        selectedMetrics={metricsTableHeaders}
        companyMetricValue={currentCompanyValues}
        transpose={transpose}
        rowItemsCompany={rowItemsCompany}
        removeCellOrRow={removeCellOrRow}
        userPreferences={userPreferences}
        setUserPreferences={setUserPreferences}
        tableHeight={tableHeight}
        tableHeaderHeight={tableHeaderHeight}
      />
    );
  };

  const savePreferencesForColumnWidth = (index: number, width: number) => {
    if (!transpose) {
      if (index === -1) {
        dispatch(
          patchUserPreference({
            ...userPreferences,
            userPreferenceValue: {
              ...userPreferences.userPreferenceValue,
              columnHeaderWidth: width,
            },
          })
        );

        setUserPreferences((userPref: UserPreference) => {
          return {
            ...userPref,
            userPreferenceValue: {
              ...userPref.userPreferenceValue,
              columnHeaderWidth: width,
            },
          };
        });
      } else {
        let fieldColumnWidth: fieldColumnWidthObject[] = [];
        if (
          userPreferences.userPreferenceValue?.fieldColumnWidths &&
          userPreferences.userPreferenceValue?.fieldColumnWidths?.length > 0
        ) {
          let filtered =
            userPreferences.userPreferenceValue?.fieldColumnWidths.find(
              (field: fieldColumnWidthObject) => field.fieldId === index
            );

          if (filtered) {
            fieldColumnWidth =
              userPreferences.userPreferenceValue?.fieldColumnWidths?.map(
                (field: fieldColumnWidthObject) => {
                  if (field.fieldId === index) {
                    return {
                      ...field,
                      width: width,
                    };
                  } else {
                    return field;
                  }
                }
              );
          } else {
            fieldColumnWidth = [
              ...userPreferences.userPreferenceValue?.fieldColumnWidths,
              ...[{ fieldId: index, width: width }],
            ];
          }
        } else {
          fieldColumnWidth = [
            {
              fieldId: index,
              width: width,
            },
          ];
        }
        const newUserPreference: any = {
          ...userPreferences,
          userPreferenceValue: {
            ...userPreferences.userPreferenceValue,
            fieldColumnWidths: fieldColumnWidth ?? [],
          },
        };

        dispatch(patchUserPreference(newUserPreference));

        setUserPreferences((userPref: UserPreference) => {
          return {
            ...userPref,
            userPreferenceValue: {
              ...userPref.userPreferenceValue,
              fieldColumnWidths: fieldColumnWidth,
            },
          };
        });
      }
    } else {
      let companiesColumnWidth: companiesColumnWidthObject[] = [];
      if (
        userPreferences.userPreferenceValue?.companiesColumnWidths &&
        userPreferences.userPreferenceValue?.companiesColumnWidths?.length > 0
      ) {
        let filtered =
          userPreferences.userPreferenceValue?.companiesColumnWidths.find(
            (companies: companiesColumnWidthObject) =>
              companies.globalCompanyId === index
          );

        if (filtered) {
          companiesColumnWidth =
            userPreferences.userPreferenceValue?.companiesColumnWidths?.map(
              (companies: companiesColumnWidthObject) => {
                if (companies.globalCompanyId === index) {
                  return {
                    ...companies,
                    width: width,
                  };
                } else {
                  return companies;
                }
              }
            );
        } else {
          companiesColumnWidth = [
            ...userPreferences.userPreferenceValue?.companiesColumnWidths,
            ...[{ globalCompanyId: index, width: width }],
          ];
        }
      } else {
        companiesColumnWidth = [
          {
            globalCompanyId: index,
            width: width,
          },
        ];
      }
      const newUserPreference: any = {
        ...userPreferences,
        userPreferenceValue: {
          ...userPreferences.userPreferenceValue,
          companiesColumnWidths: companiesColumnWidth ?? [],
        },
      };

      dispatch(patchUserPreference(newUserPreference));
      setUserPreferences((userPref: UserPreference) => {
        return {
          ...userPref,
          userPreferenceValue: {
            ...userPref.userPreferenceValue,
            companiesColumnWidths: companiesColumnWidth,
          },
        };
      });
    }
  };

  const reorderColumns = (
    currentIndex: number,
    newIndex: number,
    items: any[],
    setItems: Function
  ) => {
    if (!items) return;
    const itemsCompany = [
      ...items.slice(0, currentIndex),
      ...items.slice(currentIndex + 1),
    ];

    let reorderedColumns = [
      ...itemsCompany.slice(0, newIndex),
      items[currentIndex],
      ...itemsCompany.slice(newIndex),
    ];
    if (items.some((column) => column.fieldId !== undefined)) {
      dispatch(
        patchUserPreference({
          ...userPreferences,
          userPreferenceValue: {
            ...userPreferences.userPreferenceValue,
            fieldDisplayOrder: reorderedColumns.map((field: any, index) => {
              return { fieldId: field.fieldId, displayOrder: index + 1 };
            }),
          },
        })
      );
      setUserPreferences((userpref: UserPreference) => {
        return {
          ...userpref,
          userPreferenceValue: {
            ...userpref.userPreferenceValue,
            fieldDisplayOrder: reorderedColumns.map((field: any, index) => {
              return { fieldId: field.fieldId, displayOrder: index + 1 };
            }),
          },
        };
      });
    } else {
      dispatch(
        patchUserPreference({
          ...userPreferences,
          userPreferenceValue: {
            ...userPreferences.userPreferenceValue,
            companiesDisplayOrder: reorderedColumns.map(
              (company: any, index) => {
                return {
                  globalCompanyId: company.globalCompanyId,
                  displayOrder: index,
                };
              }
            ),
          },
        })
      );
      setUserPreferences((userpref: UserPreference) => {
        return {
          ...userpref,
          userPreferenceValue: {
            ...userpref.userPreferenceValue,
            companiesDisplayOrder: reorderedColumns.map(
              (company: any, index) => {
                return {
                  globalCompanyId: company.globalCompanyId,
                  displayOrder: index + 1,
                };
              }
            ),
          },
        };
      });
    }

    setItems(reorderedColumns);
  };

  return (
    <div className={`${BLOCK}`} data-testid="benchmark-grid">
      <div
        className={`${BLOCK}__table--container`}
        onTouchMove={handleTouchScroll}
        data-testid="table-container"
        ref={tableRef}
      >
        <table
          className={classNames(`${BLOCK}__table`, {
            [`${BLOCK}__table--transpose`]: transpose,
            [`${BLOCK}__table--normal`]: !transpose,
          })}
          style={{ ...(loadingTable ? {} : { tableLayout: "fixed" }) }}
        >
          <thead className={`${BLOCK}__table--heading`} ref={tableHeaderRef}>
            <tr className={`${BLOCK}__table--header`}>
              <DndProvider backend={HTML5Backend}>
                {transpose ? (
                  <>
                    <th
                      style={{ width: `304px` }}
                      className={`${BLOCK}__table--header--content`}
                    >
                      <div
                        className={`${BLOCK}__table--transpose__company-rows`}
                      >
                        {tableHeaders.map((item, index) => (
                          <Fragment key={`header-item-${index}`}>
                            {renderHeaderItemContents(item, index)}
                          </Fragment>
                        ))}
                      </div>
                    </th>
                    {rowItemsCompany &&
                      rowItemsCompany.map((item: any, index: number) => (
                        <Fragment
                          key={`table-header-company-${item.globalCompanyId}`}
                        >
                          <ResizeGridHeader
                            BLOCK={BLOCK}
                            className={`${BLOCK}__table--header--content`}
                            disableDragDrop={
                              item.isBaseline || rowItemsCompany.length <= 2
                            }
                            finishLoading={
                              rowItemsCompany.length === index + 1
                                ? () => setLoadingTable(false)
                                : undefined
                            }
                            index={index}
                            columnID={item.globalCompanyId}
                            savePreferences={savePreferencesForColumnWidth}
                            width={
                              userPreferences.userPreferenceValue?.companiesColumnWidths?.find(
                                (
                                  companiesColumnWidthObj: companiesColumnWidthObject
                                ) =>
                                  companiesColumnWidthObj.globalCompanyId ===
                                  item.globalCompanyId
                              )?.width ?? 0
                            }
                            reorderColumns={(c: number, n: number) =>
                              reorderColumns(
                                c,
                                n,
                                rowItemsCompany,
                                setRowItemsCompany
                              )
                            }
                            headerTooltip={
                              <>
                                {item.companyName} <br />
                                {item.ratingDate}
                              </>
                            }
                            isBaseline={item.isBaseline}
                            header={
                              <div
                                className={`${BLOCK}__table--transpose__company-rows`}
                              >
                                {renderCompanyData(
                                  BLOCK,
                                  item,
                                  removeCellOrRow,
                                  transpose
                                )}
                              </div>
                            }
                          />
                        </Fragment>
                      ))}
                  </>
                ) : (
                  <>
                    <ResizeGridHeader
                      BLOCK={BLOCK}
                      disableDragDrop={true}
                      index={-1}
                      columnID={-1}
                      reorderColumns={() => {}}
                      lowerLimit={160}
                      width={
                        userPreferences.userPreferenceValue
                          ?.columnHeaderWidth ?? 0
                      }
                      savePreferences={savePreferencesForColumnWidth}
                      className={`${BLOCK}__table--header--content`}
                      header={
                        <div
                          className={`${BLOCK}__table--header--content--container`}
                        >
                          {tableHeaders.map((item, index) => (
                            <Fragment key={`header-item-${index}`}>
                              {renderHeaderItemContents(item, index)}
                            </Fragment>
                          ))}
                        </div>
                      }
                    />
                    {metricsTableHeaders &&
                      metricsTableHeaders.map(
                        (item: MetricsTableHeaders, index: number) => (
                          <Fragment key={`table-header-metric-${item.fieldId}`}>
                            <ResizeGridHeader
                              BLOCK={BLOCK}
                              disableDragDrop={metricsTableHeaders.length <= 1}
                              columnID={item.fieldId}
                              className={`${BLOCK}__table--header--content`}
                              index={index}
                              width={
                                userPreferences.userPreferenceValue?.fieldColumnWidths?.find(
                                  (
                                    fieldColumnWidthObj: fieldColumnWidthObject
                                  ) =>
                                    fieldColumnWidthObj.fieldId === item.fieldId
                                )?.width ?? 0
                              }
                              savePreferences={savePreferencesForColumnWidth}
                              showInfoIcon={true}
                              finishLoading={
                                metricsTableHeaders.length === index + 1
                                  ? () => setLoadingTable(false)
                                  : undefined
                              }
                              reorderColumns={(c: number, n: number) =>
                                reorderColumns(
                                  c,
                                  n,
                                  metricsTableHeaders,
                                  setMetricsTableHeaders
                                )
                              }
                              header={renderHeaderItemContents(
                                item,
                                index,
                                true
                              )}
                              headerTooltip={item.displayName}
                            />
                          </Fragment>
                        )
                      )}
                  </>
                )}
              </DndProvider>
              <th className={`${BLOCK}__resize-header`}></th>
            </tr>
          </thead>
          <tbody
            className={classNames(`${BLOCK}__table--body`, {
              [`${BLOCK}__table--body--normal`]: !transpose,
            })}
          >
            {rowItemsCompany &&
              rowItemsCompany.map((item: any, i: number) => (
                <Fragment key={`item-company-${i}-${item.globalCompanyId}`}>
                  {transpose && i > 0 ? null : renderRow(item, i)}
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BenchmarkGridView;
