import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import Modal from "components/shared/modal/modal";
import { useEffect, useState, useRef } from "react";
import { getQuarterRange, getQuarters, useClickOutside } from "utils/functions";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { toCanvas } from "html-to-image";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  GlobalCompanyGroup,
  GlobalCompanyMapping,
  InsightCategory,
  InsightDashboardCompany,
  exportOptions,
  InsightUserPreference,
  insightUserPreferences,
} from "services/insights/insights.model";
import {
  currentSavedInsightDashboard,
  getSavedInsight,
  refreshSavedInsightsList,
  resetInsightTab,
  savedInsightDashboardId,
  showCreateNewTab,
  showEdit,
  showEditTab,
  setShowExportPanel,
  setInsightTileToExport,
  changeInsightsView,
  getFeatureSettings,
  companyTabTrack,
  peerBenchmarkTabTrack,
  industryTabTrack,
  xlsExportAllInsightTrack,
  pdfExportAllInsightTrack,
  xlsExportInsightTileTrack,
  pdfExportInsightTileTrack,
  tableViewInsightTrack,
  setEditor,
  pptExportInsightTileTrack,
  pptExportAllInsightTrack,
  saveSelectedDetailedViewBenchmarkIds,
  chartViewInsightTrack,
  detailedViewInsightTrack,
  resetBenchmarkFilters,
  detailedViewTileInsightTrack,
  shareInsightsToUsers,
  shareInsightSuccess,
  getSavedInsightCount,
  patchUserPreference,
  getSavedUserPreference,
  setCurrentTabID,
  setSavedUserPreference,
  refreshInsightsData,
} from "services/insights/insights.service";
import { RootStore } from "services/store.service";
import {
  insightsPath,
  INSIGHT_BENCHMARK_TYPE,
  EXPORT_TYPES,
  DEFAULT_INSIGHT_EXPORT_PAYLOAD,
  tableViews,
  DEFAULT_INSIGHT_VISUALIZATION_EXCEL_EXPORT_PAYLOAD,
  INSIGHTS_VIEW_OPTION_INDEX,
  FEATURESETTINGS,
  PPT_EXPORT_FORMAT,
  EXCEPTION_FOR_INSIGHT_TILE_EXPORT,
  EXPORT_INCLUDE_IN_SHARING,
  INSIGHTS_VIEW_TYPES,
  shareItemType,
  CHECKBOX_DATA,
  EXPORT_RELATED_EXCEPTIONAL_CLASSES,
  ACCESS_MODULES,
  unauthorizedPath,
  INSIGHTS_QUARTER_DROPDOWN_YEAR_LIMIT,
  APP_SETTING,
  BENCHMARK_TILE_TYPES,
  ESRS_SECTIONS_IDS,
} from "utils/constants";
import {
  sendExportItem,
  setShowDownloadTab,
} from "services/download/download.service";
import { getMarketCap, getSectionId } from "utils/functions";
import history from "utils/history";
import CreateNew from "components/insights/create-new";
import TalkingPoints from "components/insights/talking-points";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import ExportPanel from "components/insights/export-panel";
import JSZip from "jszip";
import { Visualization } from "services/dashboard/dashboard.model";
import Toggle from "components/shared/toggle/toggle";
import Button from "components/shared/button/button";
import {
  addToastMessage,
  loadAppSetting,
  loadAppSettingColors,
  showTooltip,
} from "services/commons.service";
import ShareToUsersModal from "components/shared/share-to-users-modal/share-to-users-modal";
import Dropdown from "components/shared/dropdown/dropdown";
import moment from "moment";
import InsightDashboardMainLayout from "./insight-dasboard.main-layout";
import InsightDashboardCollapsableLayout from "./insight-dasboard.collapsable-layout";
import ConfirmationModal from "components/shared/confirmation-modal/confirmation-modal";

const InsightDashboard = () => {
  const BLOCK = "insight-dashboard";
  const dispatch = useDispatch();
  const location = useLocation();
  const [
    selectedDetailedViewBenchmarkIds,
    setSelectedDetailedViewBenchmarkIds,
  ] = useState<number[]>([]);
  const [showExportOptions, setShowExportOptions] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const insightsState = useSelector((state: RootStore) => state.insights);
  const downloadState = useSelector((state: RootStore) => state.download);
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const [doPdfExportAll, setDoPdfExportAll] = useState<boolean>(false);
  const [exportAllLoading, setExportAllLoading] = useState<boolean | number>(
    false
  );
  const [tableViewSelected, setTableViewSelected] = useState(0);
  const [activeTableToggle, setActiveTableToggle] = useState<boolean>(false);
  const [activeGraphToggle, setActiveGraphToggle] = useState<boolean>(false);
  const [createNewTab, setCreateNewTab] = useState<boolean>(false);
  const [showExportPanel, setExportPanel] = useState<boolean>(false);
  const [selectedView, setSelectedView] = useState<null | number>(null);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [IncludeInSharing, setIncludeInSharing] = useState<any[]>([]);
  const [exportFormat, setExportFormat] = useState<any[]>([
    PPT_EXPORT_FORMAT.SINGLE.NAME,
  ]);
  const [loading, setLoading] = useState<boolean>(true);
  const [doExport, setDoExport] = useState<boolean>(false);
  const [doExportForHidden, setDoExportForHidden] = useState<boolean>(false);
  const [enableTalkingPoints, setEnableTalkingPoints] =
    useState<boolean>(false);
  const [selectedExport, setExport] = useState<string>("pdf");
  const [currentTabId, setCurrentTabId] = useState<number>(
    INSIGHT_BENCHMARK_TYPE.COMPANY
  );
  const [showDetailedView, setShowDetailedView] = useState<boolean>(false);
  const [toggleClicked, setToggleClicked] = useState<boolean>(false);
  const initialCheckboxData: string[] = [];
  const [checkboxData, setCheckboxData] =
    useState<string[]>(initialCheckboxData);
  const exportFormatData: string[] = [
    CHECKBOX_DATA.Single_Slide,
    CHECKBOX_DATA.Multiple_Slides,
  ];
  const commonsState = useSelector((state: RootStore) => state.commons);
  const [userPreferences, setUserPreferences] = useState<
    Partial<InsightUserPreference>
  >({});
  const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false);

  const getQuarterData = (): any[] => {
    const currentTime = moment().utc();
    const currentYear = currentTime.year();
    const yearList = Array.from(
      { length: INSIGHTS_QUARTER_DROPDOWN_YEAR_LIMIT },
      (_, i) => currentYear - i
    );

    const currentQuarter = {
      name: `Q${currentTime.quarter()} - ${currentYear}`,
      year: currentYear,
      quarter: currentTime.quarter(),
    };

    if (!yearList || yearList.length === 0) return [currentQuarter];

    return yearList
      .sort((a, b) => b - a)
      .reduce(
        (years: any[], year) => [
          ...years,
          ...getQuarters(year).map((quarter) => ({
            name: `Q${quarter} - ${year}`,
            quarter,
            year,
          })),
        ],
        []
      );
  };

  const [quarterData, setQuarterData] = useState(getQuarterData());

  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );

  const getSelectedPeriodFromQuarter = (quarterRange: string) => {
    const startOfQuarter = quarterRange.split("-")[0];
    const year = moment(startOfQuarter, "DD/MM/YYYY").year();
    const quarter = moment(startOfQuarter, "DD/MM/YYYY").quarter();
    return { name: `Q${quarter} - ${year}`, quarter, year };
  };

  useEffect(() => {
    const topQuarterData = parseInt(
      commonsState.appSettings.DataPeriodDropDownLimit ?? -1
    );
    if (topQuarterData > 0) {
      setQuarterData((prev) => prev.splice(0, topQuarterData));
    }
  }, [commonsState.appSettings.DataPeriodDropDownLimit]);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.accessibleFeatures &&
      currentUser.accessibleFeatures.length > 0 &&
      !currentUser.accessibleFeatures.some(
        (feature) =>
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_All ||
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_Insights
      )
    ) {
      history.push(unauthorizedPath);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, currentUser]);

  const ExceptionForInsightDashboardExport =
    insightsState.currentSavedInsightDashboard
      ? insightsState.currentSavedInsightDashboard.insightsCategories[0].benchmarks
          .filter(
            (v: Visualization) =>
              v.benchmarkMetadata &&
              v.benchmarkMetadata.snpMetrics &&
              (v.benchmarkMetadata.associatedMetrics.every(
                (m: string) =>
                  v.benchmarkMetadata.snpMetrics &&
                  v.benchmarkMetadata.snpMetrics.includes(m)
              ) ||
                (v.benchmarkMetadata.associatedMetrics.every(
                  (m: any) => m.groupName !== null
                ) &&
                  v.benchmarkMetadata.associatedMetrics
                    .map((m: any) => m.groupValues)
                    .flat()
                    .every(
                      (m: string) =>
                        v.benchmarkMetadata.snpMetrics &&
                        v.benchmarkMetadata.snpMetrics.includes(m)
                    )))
          )
          .map((v: Visualization) => v.benchmarkTitle)
      : [];

  const exportOptions: exportOptions = {
    export_ChartView: [
      {
        type: "pdf",
        icon: "pdf-2",
        text: "Export PDF",
      },
      {
        type: "ppt",
        icon: "ppt",
        text: "Export PPT",
        disabled:
          insightsState.insightTileToExport &&
          insightsState.insightTileToExport.tileName &&
          ExceptionForInsightDashboardExport.includes(
            insightsState.insightTileToExport.tileName
          ),
      },
    ],
    export_TableView: [
      {
        type: "xls",
        icon: "xls",
        text: "Export Excel",
        disabled:
          insightsState.insightTileToExport &&
          insightsState.insightTileToExport.tileName &&
          ExceptionForInsightDashboardExport.includes(
            insightsState.insightTileToExport.tileName
          ),
      },
    ],
  };

  const editInsightsHandler = () => {
    dispatch(setShowDownloadTab(false));
    dispatch(showCreateNewTab(false));
    dispatch(resetInsightTab());
    dispatch(showEdit(true));
    setCreateNewTab(true);
  };

  const tabItems = [
    {
      text: "insight.tab.company",
      tabId: INSIGHT_BENCHMARK_TYPE.COMPANY,
      icon: "company",
      show: true,
      track: companyTabTrack,
    },
    {
      text: "insight.tab.peerBenchmark",
      tabId: INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK,
      icon: "peer-benchmark",
      show:
        insightsState.currentSavedInsightDashboard?.insightsCompanies.filter(
          (peer) => !peer.isBaseCompany
        ).length > 0,
      track: peerBenchmarkTabTrack,
    },
    {
      text: "insight.tab.industry",
      tabId: INSIGHT_BENCHMARK_TYPE.INDUSTRY,
      icon: "industry",
      show:
        insightsState.currentSavedInsightDashboard?.globalCompanyMappings?.filter(
          (industry) => industry.isBaseMapping
        ).length === 1
          ? false
          : true,
      track: industryTabTrack,
    },
  ];

  const exportAllMenuRef = useRef(null);
  useClickOutside(exportAllMenuRef, () => {
    setShowExportOptions(false);
  });

  const handleTabChange = (id: number) => {
    setCurrentTabId(id);
    dispatch(setEditor(false));
    dispatch(setCurrentTabID(id));
  };

  useEffect(() => {
    if (doPdfExportAll && !showExportOptions) {
      if (
        insightsState.insightTileToExport &&
        insightsState.insightTileToExport.index != null
      ) {
        if (selectedExport === "ppt") {
          exportPpt(
            insightsState.insightTileToExport.index,
            insightsState.insightTileToExport.index + 1,
            insightsState.insightTileToExport.tileName
          );
        } else {
          exportPdf(
            insightsState.insightTileToExport.index,
            insightsState.insightTileToExport.index + 1,
            insightsState.insightTileToExport.tileName
          );
        }
      } else {
        if (
          selectedExport === "ppt" &&
          exportFormat[0] === PPT_EXPORT_FORMAT.MULTI.NAME
        ) {
          exportPpt(
            0,
            insightsState?.currentSavedInsightDashboard?.insightsCategories[0]
              .benchmarks?.length
          );
        } else if (
          selectedExport === "ppt" &&
          exportFormat[0] === PPT_EXPORT_FORMAT.SINGLE.NAME
        ) {
          exportSinglePpt(
            0,
            insightsState?.currentSavedInsightDashboard?.insightsCategories[0]
              .benchmarks?.length
          );
        } else {
          exportPdf(
            0,
            insightsState?.currentSavedInsightDashboard?.insightsCategories[0]
              .benchmarks?.length
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doPdfExportAll]);

  useEffect(() => {
    if (showExportPanel) {
      if (
        insightsState.currentSavedInsightDashboard &&
        insightsState.currentSavedInsightDashboard.insightsCategories?.length &&
        insightsState.currentSavedInsightDashboard.insightsCategories[0].benchmarks.some(
          (benchmark) => benchmark.isHidden === true
        ) &&
        !insightsState.insightTileToExport &&
        !checkboxData.includes(EXPORT_INCLUDE_IN_SHARING.HIDDEN_TILES)
      ) {
        setCheckboxData((oldCheckBoxData) => [
          ...oldCheckBoxData,
          `${EXPORT_INCLUDE_IN_SHARING.HIDDEN_TILES}`,
        ]);
      } else if (
        (insightsState.currentSavedInsightDashboard &&
          insightsState.currentSavedInsightDashboard.insightsCategories
            ?.length &&
          insightsState.currentSavedInsightDashboard.insightsCategories[0].benchmarks.every(
            (benchmark) => !benchmark.isHidden
          )) ||
        insightsState.insightTileToExport
      ) {
        setCheckboxData(
          checkboxData.filter(
            (checkboxValue) =>
              checkboxValue !== EXPORT_INCLUDE_IN_SHARING.HIDDEN_TILES
          )
        );
      }

      if (insightsState.insightTileToExport) {
        setExportFormat([]);
      } else if (isESRS) {
        setExportFormat([PPT_EXPORT_FORMAT.MULTI.NAME]);
      } else if (
        selectedDetailedViewBenchmarkIds &&
        selectedDetailedViewBenchmarkIds.length > 0
      ) {
        setExportFormat([PPT_EXPORT_FORMAT.MULTI.NAME]);
      } else {
        setExportFormat([PPT_EXPORT_FORMAT.SINGLE.NAME]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExportPanel, selectedDetailedViewBenchmarkIds]);

  useEffect(() => {
    if (doExport) {
      setDoPdfExportAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doExport]);

  useEffect(() => {
    // JUST TO MAKE SURE HIDDEN TILES GET LOADED BEFORE STARTING EXPORT FLOW.
    if (doExportForHidden) {
      setExportAllLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doExportForHidden]);

  useEffect(() => {
    if (insightsState.shareInsightsSuccess) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="insight.share.toaster.message" />,
        })
      );
      dispatch(shareInsightSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.shareInsightsSuccess]);

  const getTilesInExpandedSection = () => {
    const currentTabSectionIDs: insightUserPreferences | any =
      userPreferences?.userPreferenceValue !== null &&
      userPreferences?.userPreferenceValue !== undefined
        ? userPreferences.userPreferenceValue?.insightsSectionsPreference?.filter(
            (sections) => sections.benchmarkTypeId === currentTabId
          )[0]
        : {};

    let expandedBenchmarkIds =
      insightsState.currentSavedInsightDashboard?.insightsCategories[0]?.sectionBenchmarkMappings
        ?.filter((sectionBenchmark) =>
          currentTabSectionIDs?.expandedSectionIds?.includes(
            sectionBenchmark.sectionId
          )
        )
        .map((sectionBenchmarkMapping) => sectionBenchmarkMapping.benchmarkId);

    let benchmarkTitles = isESRS
      ? insightsState.currentSavedInsightDashboard?.insightsCategories[0]?.benchmarks
          .filter((benchmark) =>
            expandedBenchmarkIds?.includes(benchmark.benchmarkId)
          )
          .map((benchmark) => benchmark.benchmarkTitle)
      : insightsState.currentSavedInsightDashboard?.insightsCategories[0]?.benchmarks.map(
          (benchmark) => benchmark.benchmarkTitle
        );
    return benchmarkTitles && benchmarkTitles.length > 0 ? benchmarkTitles : [];
  };
  // insightsState.currentSavedInsightDashboard
  // THIS STATE IS TO BE EXPORTED TO PDF/XLS
  const exportPdf = async (
    start: number,
    end: number,
    titleIndTile?: string
  ) => {
    const pdf = new jsPDF("p", "mm", "a4");
    let blob: any;

    const TilesInExpandedSection = getTilesInExpandedSection();
    let tilesShown = 0;

    let tempChartArrayTitle: any = [];
    let tempChartArrayData: any = [];
    // LOOPING FOR GETTING ACTUAL CHART TILE DATA AND FOR EXCEPTIONAL CASE - LINKS AND ADDITIONAL INFO

    let linkInfo = document.querySelectorAll(
      `${EXPORT_RELATED_EXCEPTIONAL_CLASSES.MAIN_TILE}${EXPORT_RELATED_EXCEPTIONAL_CLASSES.ADDITIONAL_INFO_LINKS_CLASS}`
    ) as any;
    linkInfo.forEach((element: any) => {
      element.style.visibility = "hidden";
    });

    const menu = document.querySelectorAll(
      `${EXPORT_RELATED_EXCEPTIONAL_CLASSES.MAIN_TILE}${EXPORT_RELATED_EXCEPTIONAL_CLASSES.TILE_ACTIONS}`
    ) as any;

    menu.forEach((element: any) => {
      element.style.display = "none";
    });

    const outerClass = document.querySelectorAll(
      `${EXPORT_RELATED_EXCEPTIONAL_CLASSES.MAIN_TILE}`
    ) as any;

    outerClass.forEach((element: any) => {
      element.style.border = "none";
    });

    for (let i = start; i < end; i++) {
      let elementTitle = document.querySelector(
        `#chart-tile-title-${i}`
      ) as HTMLElement;
      let elementData = document.querySelector(
        `#benchmark-graph-${i}`
      ) as HTMLElement;
      if (
        elementTitle &&
        elementTitle?.textContent?.trim() !==
          EXCEPTION_FOR_INSIGHT_TILE_EXPORT.LINKS &&
        elementTitle?.textContent?.trim() !==
          EXCEPTION_FOR_INSIGHT_TILE_EXPORT.ADD_ADDITIONAL_INFO &&
        TilesInExpandedSection.includes(elementTitle!.textContent!.trim())
      ) {
        tempChartArrayTitle.push(elementTitle);
        tempChartArrayData.push(elementData);
      }
    }
    tilesShown = tempChartArrayTitle.length;
    let endForExport = titleIndTile ? end : tempChartArrayTitle.length;

    for (let i = start; i < endForExport; i++) {
      const element = tempChartArrayData[i]
        ? tempChartArrayData[i]
        : (document.querySelector(`#benchmark-graph-${i}`) as HTMLElement);

      const elementTitle = tempChartArrayTitle[i]
        ? tempChartArrayTitle[i]
        : (document.querySelector(`#chart-tile-title-${i}`) as HTMLElement);

      if (!elementTitle || !element) {
        tilesShown -= 1;
        continue;
      }
      if (isESRS) {
        await toCanvas(element, {
          backgroundColor: "white",
          skipFonts: true,
          cacheBust: true,
          width: element.offsetWidth * 2, // Double canvas size
          height: element.offsetHeight * 2, // Double canvas size
          style: {
            transform: "scale(2)", // Scale up to maintain original size
            transformOrigin: "top left", // Maintain the position
            width: element.offsetWidth + "px",
            height: element.offsetHeight + "px",
          },
        })
          .then((canvas: any) => {
            const quality = 1;
            const imgData = canvas.toDataURL("image/jpeg", quality);

            const width = pdf.internal.pageSize.getWidth();
            const height = pdf.internal.pageSize.getHeight();

            const widthRatio = width / canvas.width;
            const heightRatio = height / canvas.height;

            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

            const finalWidth = canvas.width * ratio;
            const finalHeight = canvas.height * ratio;

            const xPos = (width - finalWidth) / 2;
            const yPos = 0;

            pdf.addImage(
              imgData,
              "JPEG",
              xPos,
              yPos,
              finalWidth,
              finalHeight,
              "",
              "FAST"
            );

            if (tilesShown > 1) {
              pdf.addPage();
              tilesShown -= 1;
            }
          })
          .catch((e: any) => {});
      } else {
        await html2canvas(element, {
          scale: 2,
        })
          // eslint-disable-next-line no-loop-func
          .then((canvas: any) => {
            const imgData = canvas.toDataURL("image/png");
            var width = pdf.internal.pageSize.getWidth();
            var height = pdf.internal.pageSize.getHeight();

            let widthRatio = width / canvas.width;
            let heightRatio = height / canvas.height;

            let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

            let fontSize = 15;

            pdf.setFontSize(fontSize);

            let finalWidth = canvas.width * ratio;
            let finalHeight = canvas.height * ratio;

            let xPos = (width - finalWidth) / 2;
            let yPos = 0;
            pdf.addImage(
              imgData,
              "JPEG",
              xPos,
              yPos,
              finalWidth,
              finalHeight,
              "",
              "FAST"
            );
            if (tilesShown > 1) {
              pdf.addPage();
              tilesShown -= 1;
            }
          })
          .catch((e) => {});
      }
    }
    blob = pdf.output("blob");
    let formData = new FormData();

    formData.append(
      "exportFileName",
      titleIndTile
        ? `${insightsState.currentSavedInsightDashboard.insightsName}_${titleIndTile}`
        : insightsState.currentSavedInsightDashboard.insightsName
    );
    formData.append("exportFormat", `${1}`);
    formData.append(
      "exportType",
      titleIndTile ? `${14}` : `${EXPORT_TYPES.INSIGHTS}`
    );
    formData.append(
      "exportReferenceId",
      `${insightsState.currentSavedInsightDashboard.insightsId}`
    );
    if (titleIndTile) {
      formData.append(
        "exportBenchmarkId",
        `${insightsState.insightTileToExport.exportBenchmarkId}`
      );
    }

    formData.append("exportBenchmarkTypeId", `${currentTabId}`);
    formData.append(
      "isExportTalkingPoints",
      IncludeInSharing.length > 0
        ? `${IncludeInSharing.includes(
            EXPORT_INCLUDE_IN_SHARING.TALKING_POINTS
          )}`
        : `${false}`
    );

    formData.append(
      "isExportHiddenTiles",
      IncludeInSharing.length > 0
        ? `${IncludeInSharing.includes(EXPORT_INCLUDE_IN_SHARING.HIDDEN_TILES)}`
        : `${false}`
    );

    formData.append("file", blob);
    setDoExport(false);
    setDoPdfExportAll(false);
    setExportAllLoading(false);
    if (titleIndTile) {
      dispatch(pdfExportInsightTileTrack({ name: titleIndTile }));
    } else {
      dispatch(
        pdfExportAllInsightTrack({
          dashboardType:
            insightsState.currentSavedInsightDashboard.insightsCategories[0]
              .insightsCategoryName,
        })
      );
    }
    dispatch(
      sendExportItem({
        payload: formData,
        chartItem: true,
        downloadTabOpen: downloadState.showDownloads,
      })
    );

    setIncludeInSharing([]);
    menu.forEach((element: any) => {
      element.style.display = "flex";
    });
    linkInfo.forEach((element: any) => {
      element.style.visibility = "visible";
    });
    setExport("pdf");
  };

  const exportPpt = async (
    start: number,
    end: number,
    titleIndTile?: string
  ) => {
    const TilesInExpandedSection = getTilesInExpandedSection();

    const menu = document.querySelectorAll(
      `${EXPORT_RELATED_EXCEPTIONAL_CLASSES.MAIN_TILE}${EXPORT_RELATED_EXCEPTIONAL_CLASSES.TILE_ACTIONS}`
    ) as any;

    menu.forEach((element: any) => {
      element.style.display = "none";
    });

    const outerDiv = document.querySelectorAll(
      `${EXPORT_RELATED_EXCEPTIONAL_CLASSES.MAIN_TILE}`
    ) as any;

    outerDiv.forEach((element: any) => {
      element.style.height = "fit-content";
    });

    const outerClass = document.querySelectorAll(
      `${EXPORT_RELATED_EXCEPTIONAL_CLASSES.MAIN_TILE}`
    ) as any;

    outerClass.forEach((element: any) => {
      element.style.border = "none";
    });

    let tempChartArrayTitle: any = [];
    let tempChartArrayData: any = [];
    let benchmarks: Visualization[] = [];

    let insightTiles: Visualization[] = IncludeInSharing.includes(
      EXPORT_INCLUDE_IN_SHARING.HIDDEN_TILES
    )
      ? insightsState?.currentSavedInsightDashboard?.insightsCategories[0]
          .benchmarks
      : insightsState?.currentSavedInsightDashboard?.insightsCategories[0].benchmarks.filter(
          (bench: Visualization) => bench.isHidden === false
        );
    // LOOPING FOR GETTING ACTUAL CHART TILE DATA AND FOR EXCEPTIONAL CASE - LINKS AND ADDITIONAL INFO
    if (!titleIndTile) {
      for (let i = start; i < insightTiles.length; i++) {
        let tmpbenchmark: Visualization = insightTiles[i];
        if (
          tmpbenchmark.benchmarkTitle !==
            EXCEPTION_FOR_INSIGHT_TILE_EXPORT.LINKS &&
          tmpbenchmark.benchmarkTitle !==
            EXCEPTION_FOR_INSIGHT_TILE_EXPORT.ADD_ADDITIONAL_INFO &&
          !ExceptionForInsightDashboardExport.includes(
            tmpbenchmark.benchmarkTitle
          ) &&
          TilesInExpandedSection.includes(tmpbenchmark.benchmarkTitle)
        ) {
          if (currentTabId === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK) {
            let filteredDetailedBenchmark =
              insightsState?.currentSavedInsightDashboard?.insightsCategories[0].benchmarks.filter(
                (benchmark) =>
                  selectedDetailedViewBenchmarkIds.includes(
                    benchmark.benchmarkId
                  ) &&
                  benchmark.benchmarkTileType === tmpbenchmark.benchmarkTileType
              );
            if (
              filteredDetailedBenchmark.length !== 0 &&
              tmpbenchmark.isDetailedView
            ) {
              benchmarks.push(tmpbenchmark);
            } else if (
              !tmpbenchmark.isDetailedView &&
              filteredDetailedBenchmark.length === 0
            ) {
              benchmarks.push(tmpbenchmark);
            }
          } else {
            benchmarks.push(tmpbenchmark);
          }
        }
      }
    }

    let lastTile = titleIndTile ? end : insightTiles.length;
    for (let i = start; i < lastTile; i++) {
      let elementTitle = document.querySelector(
        `#chart-tile-title-${i}`
      ) as HTMLElement;
      let elementData = document.querySelector(
        `#benchmark-graph-${i}`
      ) as HTMLElement;
      if (
        elementTitle &&
        elementTitle.innerText !== EXCEPTION_FOR_INSIGHT_TILE_EXPORT.LINKS &&
        elementTitle.innerHTML !==
          EXCEPTION_FOR_INSIGHT_TILE_EXPORT.ADD_ADDITIONAL_INFO &&
        !ExceptionForInsightDashboardExport.includes(elementTitle.innerText) &&
        TilesInExpandedSection.includes(elementTitle.innerText)
      ) {
        tempChartArrayTitle.push(elementTitle);
        tempChartArrayData.push(elementData);
      }
    }
    let endForExport = titleIndTile ? end : tempChartArrayTitle.length;
    var zip = new JSZip();

    for (let i = start; i < endForExport; i++) {
      const element = tempChartArrayData[i]
        ? tempChartArrayData[i]
        : (document.querySelector(`#benchmark-graph-${i}`) as HTMLElement);

      if (isESRS) {
        await toCanvas(element, {
          backgroundColor: "white",
          skipFonts: true,
          cacheBust: true,
          width: element.offsetWidth * 2, // Double canvas size
          height: element.offsetHeight * 2, // Double canvas size
          style: {
            transform: "scale(2)", // Scale up to maintain original size
            transformOrigin: "top left", // Maintain the position
            width: element.offsetWidth + "px",
            height: element.offsetHeight + "px",
          },
        })
          .then((canvas: any) => {
            const widthPercentage = 40.5;
            const heightPercentage = 40.5;
            const newWidth = (canvas.width * widthPercentage) / 100;
            const newHeight = (canvas.height * heightPercentage) / 100;
            const resizedCanvas = document.createElement("canvas");
            resizedCanvas.width = newWidth;
            resizedCanvas.height = newHeight;
            const resizedContext = resizedCanvas.getContext("2d");
            resizedContext!.drawImage(canvas, 0, 0, newWidth, newHeight);

            const imgData = resizedCanvas.toDataURL("image/png");
            //titleIndTile is present only when we are doing individual export
            if (
              insightsState.insightTileToExport !== null &&
              insightsState.insightTileToExport.exportBenchmarkId
            ) {
              zip!.file(
                `${insightsState.insightTileToExport?.exportBenchmarkId}.png`,
                imgData.replace(/^data:image\/(png|jpg);base64,/, ""),
                { base64: true }
              );
            } else {
              zip!.file(
                `${benchmarks[i].benchmarkId}.png`,
                imgData.replace(/^data:image\/(png|jpg);base64,/, ""),
                { base64: true }
              );
            }
          })
          .catch((e: any) => {});
      } else {
        // eslint-disable-next-line no-loop-func
        await html2canvas(element, { scale: 2 })
          .then((canvas: any) => {
            //After scaling by 2X reducing the height and width by 50 % so that will have actual size of the image
            const widthPercentage = 50;
            const heightPercentage = 50;

            const newWidth = (canvas.width * widthPercentage) / 100;
            const newHeight = (canvas.height * heightPercentage) / 100;
            const resizedCanvas = document.createElement("canvas");
            resizedCanvas.width = newWidth;
            resizedCanvas.height = newHeight;

            const resizedContext = resizedCanvas.getContext("2d");
            resizedContext!.drawImage(canvas, 0, 0, newWidth, newHeight);

            const imgData = resizedCanvas.toDataURL("image/png");
            //titleIndTile is present only when we are doing individual export
            if (
              insightsState.insightTileToExport !== null &&
              insightsState.insightTileToExport.exportBenchmarkId
            ) {
              zip!.file(
                `${insightsState.insightTileToExport?.exportBenchmarkId}.png`,
                imgData.replace(/^data:image\/(png|jpg);base64,/, ""),
                { base64: true }
              );
            } else {
              zip!.file(
                `${benchmarks[i].benchmarkId}.png`,
                imgData.replace(/^data:image\/(png|jpg);base64,/, ""),
                { base64: true }
              );
            }
          })
          .catch((e) => {});
      }
    }

    let formData = new FormData();
    zip.generateAsync({ type: "blob" }).then(function (content) {
      formData.append("file", content);
      formData.append(
        "exportFileName",
        titleIndTile
          ? `${insightsState.currentSavedInsightDashboard.insightsName}_${titleIndTile}`
          : insightsState.currentSavedInsightDashboard.insightsName
      );
      formData.append("exportFormat", `${3}`);
      formData.append(
        "exportType",
        titleIndTile
          ? `${EXPORT_TYPES.INSIGHTS_INDIVIDUAL}`
          : `${EXPORT_TYPES.INSIGHTS_ALL}`
      );
      formData.append(
        "exportReferenceId",
        `${insightsState.currentSavedInsightDashboard.insightsId}`
      );
      if (
        insightsState.insightTileToExport === null &&
        exportFormat[0] &&
        exportFormat[0] !== ""
      ) {
        formData.append(
          "exportPageLayout",
          exportFormat[0] === PPT_EXPORT_FORMAT.MULTI.NAME
            ? `${PPT_EXPORT_FORMAT.MULTI.ID}`
            : `${PPT_EXPORT_FORMAT.SINGLE.ID}`
        );
      }
      if (titleIndTile) {
        formData.append(
          "exportBenchmarkId",
          `${insightsState.insightTileToExport.exportBenchmarkId}`
        );
      }
      formData.append("exportBenchmarkTypeId", `${currentTabId}`);
      formData.append(
        "isExportTalkingPoints",
        IncludeInSharing.length > 0
          ? `${IncludeInSharing.includes(
              EXPORT_INCLUDE_IN_SHARING.TALKING_POINTS
            )}`
          : `${false}`
      );

      formData.append(
        "isExportHiddenTiles",
        IncludeInSharing.length > 0
          ? `${IncludeInSharing.includes(
              EXPORT_INCLUDE_IN_SHARING.HIDDEN_TILES
            )}`
          : `${false}`
      );

      setDoExport(false);
      setDoPdfExportAll(false);
      setExportAllLoading(false);
      setExportFormat([PPT_EXPORT_FORMAT.SINGLE.NAME]);

      if (titleIndTile) {
        dispatch(pptExportInsightTileTrack({ name: titleIndTile }));
      } else {
        dispatch(
          pptExportAllInsightTrack({
            dashboardType:
              insightsState.currentSavedInsightDashboard.insightsCategories[0]
                .insightsCategoryName,
          })
        );
      }

      dispatch(
        sendExportItem({
          payload: formData,
          chartItem: true,
          downloadTabOpen: downloadState.showDownloads,
        })
      );
      setIncludeInSharing([]);
      menu.forEach((element: any) => {
        element.style.display = "flex";
      });
      outerClass.forEach((element: any) => {
        element.style.border = `1px solid #f0f0f0`;
      });
      setExport("pdf");
    });
  };

  const exportSinglePpt = async (
    start: number,
    end: number,
    titleIndTile?: string
  ) => {
    let linkInfo = document.querySelectorAll(
      `${EXPORT_RELATED_EXCEPTIONAL_CLASSES.MAIN_TILE}${EXPORT_RELATED_EXCEPTIONAL_CLASSES.ADDITIONAL_INFO_LINKS_CLASS}`
    ) as any;

    linkInfo.forEach((element: any) => {
      element.style.visibility = "hidden";
    });
    const menu = document.querySelectorAll(
      `${EXPORT_RELATED_EXCEPTIONAL_CLASSES.MAIN_TILE}${EXPORT_RELATED_EXCEPTIONAL_CLASSES.TILE_ACTIONS}`
    ) as any;

    menu.forEach((element: any) => {
      element.style.display = "none";
    });

    const tilesToHide = document.querySelectorAll(
      `${EXPORT_RELATED_EXCEPTIONAL_CLASSES.MAIN_TILE}${EXPORT_RELATED_EXCEPTIONAL_CLASSES.S_Y_P_SOURCE}`
    );
    tilesToHide.forEach((element: any) => {
      element.style.display = "none";
    });

    const outerDiv = document.querySelectorAll(
      `${EXPORT_RELATED_EXCEPTIONAL_CLASSES.MAIN_TILE}`
    ) as any;

    outerDiv.forEach((element: any) => {
      element.style.height = "fit-content";
    });

    const element = document.querySelector(
      isESRS
        ? `${EXPORT_RELATED_EXCEPTIONAL_CLASSES.INSIGHT_ESRS_DASHBOARD_CONTENT}`
        : `${EXPORT_RELATED_EXCEPTIONAL_CLASSES.INSIGHT_DASHBOARD_CONTENT}`
    ) as HTMLElement;

    if (element && isESRS) {
      let governanceSectionTilesIds =
        insightsState.currentSavedInsightDashboard.insightsCategories[0].sectionBenchmarkMappings
          ?.filter((x) => x.sectionId === 3)
          .map((x) => x.benchmarkId);

      let governanceSectionTiles =
        governanceSectionTilesIds && governanceSectionTilesIds?.length > 0
          ? insightsState.currentSavedInsightDashboard.insightsCategories[0].benchmarks.filter(
              (benchmark) =>
                governanceSectionTilesIds?.includes(benchmark.benchmarkId) &&
                benchmark.isHidden === false
            )
          : [];

      if (governanceSectionTiles && governanceSectionTiles.length === 0)
        element.style.paddingBottom = "7rem";
    }

    var zip = new JSZip();
    if (isESRS) {
      try {
        const canvas = await toCanvas(element, {
          backgroundColor: "white",
          skipFonts: true,
          cacheBust: true,
        });
        const imgData = canvas.toDataURL("image/png");
        zip!.file(
          `${insightsState.currentSavedInsightDashboard.insightsId}.png`,
          imgData.replace(/^data:image\/(png|jpg);base64,/, ""),
          { base64: true }
        );
      } catch (e) {
        console.error("Error capturing canvas:", e);
      }
    } else {
      await html2canvas(element, {
        scale: 2,
      })
        .then((canvas: any) => {
          const imgData = canvas.toDataURL("image/png");
          zip!.file(
            `${insightsState.currentSavedInsightDashboard.insightsId}.png`,
            imgData.replace(/^data:image\/(png|jpg);base64,/, ""),
            { base64: true }
          );
        })
        .catch((e) => {});
    }

    let formData = new FormData();
    zip.generateAsync({ type: "blob" }).then(function (content) {
      formData.append("file", content);
      formData.append(
        "exportFileName",
        titleIndTile
          ? `${insightsState.currentSavedInsightDashboard.insightsName}_${titleIndTile}`
          : insightsState.currentSavedInsightDashboard.insightsName
      );
      formData.append("exportFormat", `${3}`);
      formData.append(
        "exportType",
        titleIndTile
          ? `${EXPORT_TYPES.INSIGHTS_INDIVIDUAL}`
          : `${EXPORT_TYPES.INSIGHTS_ALL}`
      );
      formData.append(
        "exportReferenceId",
        `${insightsState.currentSavedInsightDashboard.insightsId}`
      );
      if (
        insightsState.insightTileToExport === null &&
        exportFormat[0] &&
        exportFormat[0] !== ""
      ) {
        formData.append(
          "exportPageLayout",
          exportFormat[0] === PPT_EXPORT_FORMAT.MULTI.NAME
            ? `${PPT_EXPORT_FORMAT.MULTI.ID}`
            : `${PPT_EXPORT_FORMAT.SINGLE.ID}`
        );
      }
      if (titleIndTile) {
        formData.append(
          "exportBenchmarkId",
          `${insightsState.insightTileToExport.exportBenchmarkId}`
        );
      }
      formData.append("exportBenchmarkTypeId", `${currentTabId}`);
      formData.append(
        "isExportTalkingPoints",
        IncludeInSharing.length > 0
          ? `${IncludeInSharing.includes(
              EXPORT_INCLUDE_IN_SHARING.TALKING_POINTS
            )}`
          : `${false}`
      );

      formData.append(
        "isExportHiddenTiles",
        IncludeInSharing.length > 0
          ? `${IncludeInSharing.includes(
              EXPORT_INCLUDE_IN_SHARING.HIDDEN_TILES
            )}`
          : `${false}`
      );

      setDoExport(false);
      setDoPdfExportAll(false);
      setExportAllLoading(false);

      if (titleIndTile) {
        dispatch(pptExportInsightTileTrack({ name: titleIndTile }));
      } else {
        dispatch(
          pptExportAllInsightTrack({
            dashboardType:
              insightsState.currentSavedInsightDashboard.insightsCategories[0]
                .insightsCategoryName,
          })
        );
      }

      dispatch(
        sendExportItem({
          payload: formData,
          chartItem: true,
          downloadTabOpen: downloadState.showDownloads,
        })
      );
      setIncludeInSharing([]);

      menu.forEach((element: any) => {
        element.style.display = "flex";
      });
      linkInfo.forEach((element: any) => {
        element.style.visibility = "visible";
      });
      tilesToHide.forEach((element: any) => {
        element.style.display = "block";
      });
      setExport("pdf");
    });
  };

  const cancelHandler = () => {
    dispatch(setInsightTileToExport(null));
    setShowExportModal(false);
  };

  const ExportHandler = () => {
    if (selectedExport === "pdf" || selectedExport === "ppt") {
      if (IncludeInSharing.includes(EXPORT_INCLUDE_IN_SHARING.HIDDEN_TILES)) {
        setShowExportModal(false);
        setDoExportForHidden(true);
      } else {
        setExportAllLoading(
          insightsState.insightTileToExport
            ? insightsState.insightTileToExport.index + 1
            : true
        ); //insightsState.insightTileToExport.index + 1
        setShowExportModal(false);
      }
    } else if (selectedExport === "xls") {
      let payload = {};
      if (insightsState.insightTileToExport) {
        payload = {
          ...DEFAULT_INSIGHT_VISUALIZATION_EXCEL_EXPORT_PAYLOAD,
          exportFileName: insightsState.insightTileToExport.exportFileName,
          exportReferenceId:
            insightsState.insightTileToExport.exportReferenceId,
          exportBenchmarkId:
            insightsState.insightTileToExport.exportBenchmarkId,
          isExportTalkingPoints:
            IncludeInSharing.length > 0
              ? `${IncludeInSharing.includes(
                  EXPORT_INCLUDE_IN_SHARING.TALKING_POINTS
                )}`
              : false,
        };
        dispatch(setInsightTileToExport(null));
        dispatch(
          xlsExportInsightTileTrack({
            name: insightsState.insightTileToExport.tileName,
          })
        );
      } else {
        payload = {
          ...DEFAULT_INSIGHT_EXPORT_PAYLOAD,
          exportFileName:
            insightsState.currentSavedInsightDashboard?.insightsName,
          isExportTalkingPoints:
            IncludeInSharing.length > 0
              ? `${IncludeInSharing.includes(
                  EXPORT_INCLUDE_IN_SHARING.TALKING_POINTS
                )}`
              : false,
          isExportHiddenTiles:
            IncludeInSharing.length > 0
              ? `${IncludeInSharing.includes(
                  EXPORT_INCLUDE_IN_SHARING.HIDDEN_TILES
                )}`
              : false,
          exportReferenceId:
            insightsState.currentSavedInsightDashboard.insightsId,
          exportBenchmarkTypeId: currentTabId,
        };
        dispatch(
          xlsExportAllInsightTrack({
            dashboardType:
              insightsState.currentSavedInsightDashboard.insightsCategories[0]
                .insightsCategoryName,
          })
        );
      }
      dispatch(sendExportItem(payload));
      setExport("pdf");
      setShowExportModal(false);
      setIncludeInSharing([]);
    }
  };

  useEffect(() => {
    const insightId = parseInt(
      location.pathname.replace(`${insightsPath}/`, "")
    );
    if (insightId) {
      const insight = location.state as any;
      if (insight?.insightDetail?.sharedBy) {
        dispatch(
          getSavedInsight(insightId, currentTabId, false, {
            params: {
              execute: true,
            },
          })
        );
        insight.insightDetail.sharedBy = false;
      } else {
        dispatch(getSavedInsight(insightId, currentTabId));
      }
      if (
        currentTabId === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
        commonsState.InsightsDetailedPeerViewColorCodes.length === 0
      ) {
        dispatch(
          loadAppSettingColors(APP_SETTING.InsightsDetailedPeerViewColorCodes)
        );
      }
      dispatch(loadAppSetting(APP_SETTING.DataPeriodDropDownLimit));
      dispatch(resetBenchmarkFilters());
      setSelectedView(null);
      dispatch(getSavedUserPreference(insightId));
      dispatch(setCurrentTabID(currentTabId));
    }

    // eslint-disable-next-line
  }, [location, currentTabId]);

  useEffect(() => {
    dispatch(getSavedInsightCount());
    if (
      insightsState.currentSavedInsightDashboard &&
      insightsState.currentSavedInsightDashboard.insightsCategories?.length
    ) {
      const newSelectedDetailedView =
        insightsState.currentSavedInsightDashboard.insightsCategories.reduce(
          (acc: number[], curr) => [
            ...acc,
            ...curr.selectedDetailedViewBenchmarkIds,
          ],
          []
        );
      if (
        selectedDetailedViewBenchmarkIds.length !==
          newSelectedDetailedView.length ||
        selectedDetailedViewBenchmarkIds.every((d) =>
          newSelectedDetailedView.includes(d)
        )
      ) {
        setSelectedDetailedViewBenchmarkIds(newSelectedDetailedView);
      }
      setLoading(false);
      setTableViewSelected(
        insightsState.currentSavedInsightDashboard?.insightsCategories?.reduce(
          (acc, curr) =>
            acc +
            curr.benchmarks.filter(
              (tile: any) =>
                tile.benchmarkMetadata &&
                !tile.isDetailedView &&
                tableViews.includes(tile.benchmarkMetadata.selectedStyleOption)
            ).length,
          0
        )
      );
    }

    const sectionIDs =
      insightsState.currentSavedInsightDashboard?.insightsCategories[0].sections?.map(
        (section) => section.sectionId
      );

    if (
      insightsState.savedUserPreference?.userPreferenceValue !== null &&
      insightsState.savedUserPreference?.userPreferenceValue !== undefined
    ) {
      setUserPreferences(insightsState.savedUserPreference);
    }

    if (
      insightsState.savedUserPreference?.userPreferenceValue === undefined ||
      insightsState.savedUserPreference?.userPreferenceValue === null
    ) {
      let insightSectionsPreferences = {
        referenceId: insightsState.currentSavedInsightDashboard?.insightsId,
        userId: commonsState?.currentUser?.userId,
        userPreferenceId: 0,
        userPreferenceType: 2,
        userPreferenceValue: {
          insightsSectionsPreference: [
            {
              benchmarkTypeId: INSIGHT_BENCHMARK_TYPE.COMPANY,
              expandedSectionIds: sectionIDs ?? ESRS_SECTIONS_IDS,
            },
            {
              benchmarkTypeId: INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK,
              expandedSectionIds: sectionIDs ?? ESRS_SECTIONS_IDS,
            },
            {
              benchmarkTypeId: INSIGHT_BENCHMARK_TYPE.INDUSTRY,
              expandedSectionIds: sectionIDs ?? ESRS_SECTIONS_IDS,
            },
          ],
        },
      };
      setUserPreferences(insightSectionsPreferences);
      dispatch(setSavedUserPreference(insightSectionsPreferences));
    }

    // eslint-disable-next-line
  }, [insightsState.currentSavedInsightDashboard?.insightsCategories]);

  useEffect(() => {
    if (!insightsState.featureSetting) {
      dispatch(getFeatureSettings());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (insightsState.featureSetting.length > 0) {
      let disableTalkingPointsOnExportPanel = false;

      // IF MORE DASHBOARD TYPES ARE TO BE DISABLED, THEN COMMA SEPERATED CATEGORY IDs will come.
      // DISABLING TALKING POINTS ON DASHBOARD TYPE
      if (
        commonsState.appSettings &&
        commonsState.appSettings
          ?.DisableTalkingPointsExportInsightsCategoryIds &&
        commonsState.appSettings
          ?.DisableTalkingPointsExportInsightsCategoryIds !== ""
      ) {
        const DisableTalkingPointsExportInsightsCategoryIds =
          commonsState.appSettings?.DisableTalkingPointsExportInsightsCategoryIds?.split(
            ","
          );

        insightsState.currentSavedInsightDashboard?.insightsCategories?.forEach(
          (category) => {
            if (
              DisableTalkingPointsExportInsightsCategoryIds?.includes(
                category?.insightsCategoryId?.toString()
              )
            ) {
              disableTalkingPointsOnExportPanel = true;
            }
          }
        );
      }

      const enableTalking = insightsState.featureSetting?.filter(
        (setting) =>
          setting.featureName === FEATURESETTINGS.TalkingPoints &&
          setting.isEnabled
      );
      if (enableTalking.length > 0 && !disableTalkingPointsOnExportPanel) {
        setEnableTalkingPoints(true);
        if (!checkboxData.includes(EXPORT_INCLUDE_IN_SHARING.TALKING_POINTS)) {
          setCheckboxData((oldCheckBoxData) => [
            ...oldCheckBoxData,
            `${EXPORT_INCLUDE_IN_SHARING.TALKING_POINTS}`,
          ]);
        }
      } else {
        setCheckboxData((oldCheckBoxData) => [
          ...oldCheckBoxData.filter(
            (checkData) =>
              checkData !== `${EXPORT_INCLUDE_IN_SHARING.TALKING_POINTS}`
          ),
        ]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    insightsState.featureSetting,
    enableTalkingPoints,
    insightsState.currentSavedInsightDashboard,
    commonsState.appSettings.DisableTalkingPointsExportInsightsCategoryIds,
  ]);

  useEffect(() => {
    if (!exportAllLoading) {
      setDoExportForHidden(false);
    } else {
      // JUST TO MAKE SURE HIDDEN TILES GET LOADED BEFORE STARTING EXPORT FLOW.
      let timeout = setTimeout(() => {
        setDoExport(true);
      }, 3000);
      // THIS IS TO ENSURE LOADING OF HIDDEN TILES BEFORE EXPORTING
      // ALSO TO ENSURE LOADING OF TILES WHEN HIDDEN TILES ARE IN TABLE VIEW
      return () => clearTimeout(timeout);
    }
  }, [exportAllLoading]);

  useEffect(() => {
    if (insightsState.refreshSavedInsightsList) {
      const insightId = parseInt(
        location.pathname.replace(`${insightsPath}/`, "")
      );
      dispatch(getSavedInsight(insightId, currentTabId, true));
      dispatch(refreshSavedInsightsList(false));
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.refreshSavedInsightsList]);

  const changeView = (viewIndex: number) => {
    setSelectedView(viewIndex);
    setTableViewSelected(
      viewIndex === 0
        ? 0
        : insightsState.currentSavedInsightDashboard?.insightsCategories?.reduce(
            (acc, curr) =>
              acc +
              (curr.benchmarks.length
                ? curr.benchmarks.filter(
                    (tile: any) =>
                      tile.benchmarkMetadata &&
                      !tile.isDetailedView &&
                      tile.benchmarkMetadata.bechmarkStyleOptions &&
                      tile.benchmarkMetadata.bechmarkStyleOptions.length > 1 &&
                      tableViews.includes(
                        tile.benchmarkMetadata.bechmarkStyleOptions[1]
                      )
                  ).length
                : 0),
            0
          )
    );
    const payload = {
      insightsViewTypeId: viewIndex + 1,
      benchmarkMetadataList:
        insightsState.currentSavedInsightDashboard?.insightsCategories?.reduce(
          (acc: any, curr: any) => [
            ...acc,
            ...(curr.benchmarks.length
              ? curr.benchmarks
                  .filter(
                    (tile: any) =>
                      tile.benchmarkMetadata &&
                      tile.benchmarkMetadata.bechmarkStyleOptions &&
                      tile.benchmarkMetadata.bechmarkStyleOptions.length >
                        viewIndex
                  )
                  .map((tile: any) => ({
                    benchmarkId: tile.benchmarkId,
                    benchmarkMetadataPath: "selectedStyleOption",
                    benchmarkMetadataValue: {
                      selectedStyleOption:
                        tile.benchmarkMetadata.bechmarkStyleOptions[viewIndex],
                    },
                  }))
              : []),
          ],
          []
        ),
    };

    dispatch(
      changeInsightsView(insightsState.currentSavedInsightDashboard, payload)
    );
    if (viewIndex === INSIGHTS_VIEW_OPTION_INDEX.TABLE) {
      dispatch(tableViewInsightTrack(true));
    } else if (viewIndex === INSIGHTS_VIEW_OPTION_INDEX.GRAPH) {
      dispatch(chartViewInsightTrack(true));
    }
  };

  const ExportButtonHandler = () => {
    setShowExportModal(true);
    dispatch(setShowExportPanel(false));
    setExportPanel(false);
  };

  useEffect(() => {
    if (insightsState.showExportPanel) {
      setExportPanel(true);
      dispatch(setShowExportPanel(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.showExportPanel]);

  useEffect(() => {
    if (
      insightsState.currentSavedInsightDashboard &&
      insightsState.currentSavedInsightDashboard.insightsCompanies?.length
    ) {
      setActiveTableToggle(
        insightsState.currentSavedInsightDashboard?.insightsCategories?.reduce(
          (acc, curr) =>
            acc +
            (curr.benchmarks.length
              ? curr.benchmarks.filter(
                  (tile: any) =>
                    tile.benchmarkMetadata &&
                    !tile.isDetailedView &&
                    tile.benchmarkMetadata.bechmarkStyleOptions &&
                    tile.benchmarkMetadata.bechmarkStyleOptions.length > 1 &&
                    tableViews.includes(
                      tile.benchmarkMetadata.bechmarkStyleOptions[1]
                    )
                ).length
              : 0),
          0
        ) === tableViewSelected && tableViewSelected > 0
      );

      setActiveGraphToggle(tableViewSelected === 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    insightsState.currentSavedInsightDashboard?.insightsCategories,
    tableViewSelected,
  ]);

  const shareInsightHandler = (payload: any) => {
    dispatch(
      shareInsightsToUsers({
        insightsName: insightsState.currentSavedInsightDashboard?.insightsName,
        insightsId: insightsState.currentSavedInsightDashboard.insightsId,
        sharedWith: payload,
        sourceinsightsId: insightsState.currentSavedInsightDashboard.insightsId,
      })
    );
    setShowShareModal(false);
  };

  useEffect(() => {
    setShowDetailedView(
      insightsState.currentSavedInsightDashboard?.insightsCategories?.reduce(
        (acc, curr) =>
          acc +
          (curr.benchmarks.length
            ? curr.benchmarks.filter(
                (tile: any) => tile.benchmarkMetadata && tile.isDetailedView
              ).length
            : 0),
        0
      ) === selectedDetailedViewBenchmarkIds.length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDetailedViewBenchmarkIds]);

  const baseCompany = insightsState.currentSavedInsightDashboard
    ? insightsState.currentSavedInsightDashboard.globalCompanyMappings &&
      insightsState.currentSavedInsightDashboard.globalCompanyMappings.length
      ? insightsState.currentSavedInsightDashboard.insightsCompanies?.find(
          (company: InsightDashboardCompany) => {
            let baseMapping =
              insightsState.currentSavedInsightDashboard.globalCompanyMappings.find(
                (g: GlobalCompanyGroup) => g.isBaseMapping
              );

            return baseMapping &&
              baseMapping.globalCompanyMappingDetails.find(
                (m: GlobalCompanyMapping) => m.isDefault
              )
              ? baseMapping.globalCompanyMappingDetails.find(
                  (m: GlobalCompanyMapping) => m.isDefault
                )?.globalCompanyId === company.globalCompanyId &&
                  company.isBaseCompany
              : company.isBaseCompany;
          }
        )?.globalCompany
      : insightsState.currentSavedInsightDashboard.insightsCompanies?.find(
          (company: InsightDashboardCompany) => company.isBaseCompany
        )?.globalCompany
    : undefined;

  const isGraphToggleActive =
    (selectedView === null && activeGraphToggle) ||
    (insightsState.currentSavedInsightDashboard?.insightsViewType ===
      INSIGHTS_VIEW_TYPES.CHART_VIEW &&
      (!activeTableToggle || selectedView !== null));

  const listenScrollEvent = () => {
    if (tooltip.customPosition)
      dispatch(
        showTooltip({
          children: null,
          position: null,
          customPosition: null,
          elementDimensions: null,
          className: null,
          width: null,
          executeMouseLeaveEvent: null,
          isOverTooltip: null,
        })
      );
  };
  const toggleDetailView = (
    benchmark: Visualization,
    detailedViewId: number
  ) => {
    let selectedDetailedViewBenchmarkIdsTmp = [];
    if (benchmark.isDetailedView) {
      selectedDetailedViewBenchmarkIdsTmp =
        selectedDetailedViewBenchmarkIds.filter((id) => id !== detailedViewId);
    } else {
      dispatch(
        detailedViewTileInsightTrack({
          name: benchmark.benchmarkTitle,
        })
      );

      selectedDetailedViewBenchmarkIdsTmp = [
        ...selectedDetailedViewBenchmarkIds,
        detailedViewId,
      ];
    }

    setSelectedDetailedViewBenchmarkIds(selectedDetailedViewBenchmarkIdsTmp);
    let currentInsightDashboardCopy = JSON.parse(
      JSON.stringify(insightsState.currentSavedInsightDashboard)
    );
    currentInsightDashboardCopy.insightsCategories[0].selectedDetailedViewBenchmarkIds =
      selectedDetailedViewBenchmarkIdsTmp;
    dispatch(currentSavedInsightDashboard(currentInsightDashboardCopy));

    dispatch(
      saveSelectedDetailedViewBenchmarkIds(
        insightsState.currentSavedInsightDashboard.insightsId,
        detailedViewId ? [detailedViewId] : [],
        !benchmark.isDetailedView
      )
    );
  };

  const filterDashboardExpression = (
    vis: Visualization,
    category: InsightCategory
  ) => {
    const hasNonSECBaseline =
      insightsState.currentSavedInsightDashboard?.globalCompanyMappings?.filter(
        (industry) => industry.isBaseMapping
      ).length === 1
        ? true
        : false;
    if (
      hasNonSECBaseline &&
      vis.benchmarkTileType === BENCHMARK_TILE_TYPES.LINKS
    ) {
      return false;
    }

    return doExportForHidden
      ? vis.benchmarkMetadata !== null &&
          (vis.isDetailedView
            ? // Hide the detailed view if not selected
              selectedDetailedViewBenchmarkIds.includes(vis.benchmarkId)
            : // Hide the normal view if detailed view selected
              category.benchmarks.filter(
                ({ benchmarkId, benchmarkTileType }) =>
                  selectedDetailedViewBenchmarkIds.includes(benchmarkId) &&
                  vis.benchmarkTileType === benchmarkTileType
              ).length === 0)
      : !vis.isHidden &&
          vis.benchmarkMetadata !== null &&
          (vis.isDetailedView
            ? // Hide the detailed view if not selected
              selectedDetailedViewBenchmarkIds.includes(vis.benchmarkId)
            : // Hide the normal view if detailed view selected
              category.benchmarks.filter(
                ({ benchmarkId, benchmarkTileType }) =>
                  selectedDetailedViewBenchmarkIds.includes(benchmarkId) &&
                  vis.benchmarkTileType === benchmarkTileType
              ).length === 0);
  };

  if (!insightsState.currentSavedInsightDashboard)
    return (
      <Icon
        name={"loading"}
        width={50}
        height={50}
        className="loading-icon loading-icon--center"
      />
    );

  const ESRSCatgory =
    insightsState.currentSavedInsightDashboard.insightsCategories.find(
      (ic) => ic.insightsCategoryId === 6
    );
  const isESRS = !!ESRSCatgory;

  const showVendorUpdatedDates =
    insightsState.currentSavedInsightDashboard.insightsCategories.every(
      (ic) => !ic.isHidden
    );

  //Handle collapse of section in ESRS dashboard and save user preference
  const handleCollapse = (isCollapsed: boolean, section: string) => {
    let insightSectionsPreferences = {
      ...insightsState.savedUserPreference,
      userId: commonsState?.currentUser?.userId,
      userPreferenceValue: {
        insightsSectionsPreference: (
          userPreferences?.userPreferenceValue?.insightsSectionsPreference || []
        ).map((sectionPreference) => {
          if (sectionPreference.benchmarkTypeId === currentTabId) {
            return {
              ...sectionPreference,
              expandedSectionIds: isCollapsed
                ? (sectionPreference.expandedSectionIds || []).filter(
                    (id) => id !== getSectionId(section)
                  )
                : Array.from(
                    new Set([
                      ...sectionPreference.expandedSectionIds,
                      getSectionId(section),
                    ])
                  ),
            };
          }
          return sectionPreference;
        }),
      },
    };

    setUserPreferences(insightSectionsPreferences);
    dispatch(setSavedUserPreference(insightSectionsPreferences));
    dispatch(patchUserPreference(insightSectionsPreferences));
  };

  //get user preference for current tab from store or state as per initial load or after save
  const getUserSectionPreference = () => {
    return insightsState.savedUserPreference?.userPreferenceValue != null
      ? ((insightsState.savedUserPreference?.userPreferenceValue?.insightsSectionsPreference?.find(
          (x) => x.benchmarkTypeId === currentTabId
        ) || []) as insightUserPreferences)
      : ((userPreferences.userPreferenceValue?.insightsSectionsPreference?.find(
          (x) => x.benchmarkTypeId === currentTabId
        ) || []) as insightUserPreferences);
  };

  const getVendorUpdatedDates = () => {
    return (
      insightsState.currentSavedInsightDashboard &&
      insightsState.currentSavedInsightDashboard.vendorUpdatedDates.map(
        (vendor, i, { length }) => {
          return (
            <span>{`${vendor.vendorName} (${moment(vendor.updatedDate).format(
              "MM/DD/YY"
            )}) ${i + 1 !== length ? "/ " : ""} `}</span>
          );
        }
      )
    );
  };

  const handleRefreshInsightsData = () => {
    const insightId = parseInt(
      location.pathname.replace(`${insightsPath}/`, "")
    );
    dispatch(refreshInsightsData(insightId));
  };

  const layoutProps = {
    currentDashboard: insightsState.currentSavedInsightDashboard,
    dashboardVariables: {
      selectedExport,
      selectedView,
      exportAllLoading,
      currentTabId,
      toggleClicked,
    },
    dashboardFunctions: {
      setTableViewSelected,
      toggleDetailView,
      setToggleClicked,
    },
    filterExpression: filterDashboardExpression,
  };
  const LayoutComponent = isESRS ? (
    <InsightDashboardCollapsableLayout
      categoryData={{
        sections: ESRSCatgory.sections ?? [],
        sectionGroups: ESRSCatgory.sectionGroups ?? [],
        sectionMappings: ESRSCatgory.sectionBenchmarkMappings ?? [],
      }}
      {...layoutProps}
      handleCollapse={handleCollapse}
      insightUserPreferences={getUserSectionPreference()}
    />
  ) : loading ? (
    <Icon
      name={"loading"}
      width={50}
      height={50}
      className="loading-icon loading-icon--center"
    />
  ) : (
    <InsightDashboardMainLayout BLOCK={BLOCK} {...layoutProps} />
  );

  return (
    <>
      {currentUser && (
        <>
          <div
            className={BLOCK}
            data-testid="insights-dashboard-container"
            onScroll={listenScrollEvent}
            id={`insight-dashboard-container`}
          >
            <div className={`${BLOCK}__header`}>
              <div className={`${BLOCK}__header-content`}>
                <div
                  className={`${BLOCK}__back-to-dashboard`}
                  onClick={() => {
                    dispatch(currentSavedInsightDashboard(null));
                    dispatch(savedInsightDashboardId(-1));
                    dispatch(showEditTab(false));
                    dispatch(setEditor(false));
                    history.push(`${insightsPath}`);
                  }}
                  data-testid="insights-back-button"
                >
                  <Icon name="chevron-left" width={24} height={24} />
                </div>
                <div className={`${BLOCK}__header-data`}>
                  <div className={`${BLOCK}__name`}>
                    <span
                      className={`${BLOCK}__name-text`}
                      data-testid="Insight-header"
                    >
                      {insightsState.currentSavedInsightDashboard?.insightsName}
                    </span>
                    <span
                      className={`${BLOCK}__baseCompany`}
                      data-testid="Insight-baseline-company"
                    >
                      {baseCompany?.companyName}
                    </span>
                  </div>
                </div>
                <div className={`${BLOCK}__actions`}>
                  <Button
                    className={`${BLOCK}__edit-view-btn icon-text-button`}
                    dataTest="edit-view-button"
                    formattedText="insights.share.button"
                    onClick={() => setShowShareModal(true)}
                    iconName="share-blue"
                    iconWidth={14}
                    iconHeight={16}
                  ></Button>

                  {insightsState.showTalkingPoints && (
                    <TalkingPoints
                      BLOCK={`${BLOCK}__talking-points`}
                      tabId={currentTabId}
                    />
                  )}
                  {currentTabId === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
                  isGraphToggleActive ? (
                    <Toggle
                      value={showDetailedView}
                      label={
                        <FormattedMessage id="insights.actions.toggle-detailed" />
                      }
                      onChange={() => {
                        let selectedDetailedViewBenchmarkIdsTmp: number[] = [];
                        if (!showDetailedView) {
                          dispatch(detailedViewInsightTrack(true));
                          selectedDetailedViewBenchmarkIdsTmp =
                            insightsState.currentSavedInsightDashboard.insightsCategories?.reduce(
                              (acc: number[], curr) => [
                                ...acc,
                                ...curr.benchmarks
                                  .filter((b) => b.isDetailedView)
                                  .map((b) => b.benchmarkId),
                              ],
                              []
                            );
                        }

                        dispatch(
                          saveSelectedDetailedViewBenchmarkIds(
                            insightsState.currentSavedInsightDashboard
                              .insightsId,
                            showDetailedView
                              ? selectedDetailedViewBenchmarkIds
                              : selectedDetailedViewBenchmarkIdsTmp,
                            !showDetailedView
                          )
                        );
                        setSelectedDetailedViewBenchmarkIds(
                          selectedDetailedViewBenchmarkIdsTmp
                        );
                        let currentInsightDashboardCopy = JSON.parse(
                          JSON.stringify(
                            insightsState.currentSavedInsightDashboard
                          )
                        );
                        currentInsightDashboardCopy.insightsCategories[0].selectedDetailedViewBenchmarkIds =
                          selectedDetailedViewBenchmarkIdsTmp;
                        dispatch(
                          currentSavedInsightDashboard(
                            currentInsightDashboardCopy
                          )
                        );
                        setToggleClicked(true);
                        setShowDetailedView(!showDetailedView);
                      }}
                      data-test="transpose-button"
                    />
                  ) : null}

                  <div className={`${BLOCK}__toggle-view`}>
                    <button
                      type="button"
                      disabled={isGraphToggleActive}
                      className={classNames(`${BLOCK}__toggle`, {
                        [`${BLOCK}__toggle--active`]: isGraphToggleActive,
                      })}
                      onClick={() =>
                        changeView(INSIGHTS_VIEW_OPTION_INDEX.GRAPH)
                      }
                      data-testid="toggle-graph"
                    >
                      <Tooltip position={TooltipPosition.bottom}>
                        <FormattedMessage
                          id="insight.tooltip.chart"
                          values={{
                            chartType: "Chart",
                          }}
                        />
                      </Tooltip>
                      <Icon name={"graph"} width={20} height={20} />
                    </button>
                    <button
                      type="button"
                      disabled={
                        (selectedView === null && activeTableToggle) ||
                        (insightsState.currentSavedInsightDashboard
                          ?.insightsViewType === 2 &&
                          (!activeGraphToggle || selectedView !== null))
                      }
                      className={classNames(`${BLOCK}__toggle`, {
                        [`${BLOCK}__toggle--active`]:
                          (selectedView === null && activeTableToggle) ||
                          (insightsState.currentSavedInsightDashboard
                            ?.insightsViewType === 2 &&
                            (!activeGraphToggle || selectedView !== null)),
                      })}
                      onClick={() =>
                        changeView(INSIGHTS_VIEW_OPTION_INDEX.TABLE)
                      }
                      data-testid="toggle-table"
                    >
                      <Tooltip position={TooltipPosition.bottom}>
                        <FormattedMessage
                          id="insight.tooltip.chart"
                          values={{
                            chartType: "Table",
                          }}
                        />
                      </Tooltip>
                      <Icon name={"table"} width={20} height={20} />
                    </button>
                  </div>

                  {insightsState.currentSavedInsightDashboard && (
                    <Button
                      className={`${BLOCK}__edit-view-btn icon-text-button`}
                      dataTest="edit-view-button"
                      formattedText="peer.benchmark.grid.edit"
                      onClick={editInsightsHandler}
                      iconName="edit"
                      iconWidth={24}
                      iconHeight={24}
                    />
                  )}
                  <div
                    className={`${BLOCK}__export-all`}
                    ref={exportAllMenuRef}
                  >
                    <button
                      data-testid="export-all-dropdown-button"
                      className={`${BLOCK}__export-all-button`}
                      onClick={() => {
                        dispatch(setShowExportPanel(true));
                        setExportPanel(true);
                      }}
                    >
                      <span>
                        <FormattedMessage id="dashboard.export.all" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className={`${BLOCK}__header-info`}>
                <span
                  className={`${BLOCK}__header-chip`}
                  data-testid="insight-category-chip"
                >
                  <FormattedMessage id="workspace.tab.dashboard" />:{" "}
                  {
                    insightsState.currentSavedInsightDashboard
                      ?.insightsCategories[0]?.insightsCategoryName
                  }
                </span>
                <span
                  className={`${BLOCK}__header-chip`}
                  data-testid="insight-base-ticker"
                >
                  <FormattedMessage id="workspace.tab.companyInfo" />:{" "}
                  {baseCompany?.ticker}
                </span>
                /
                <span
                  className={`${BLOCK}__header-chip`}
                  data-testid="insight-base-industry"
                >
                  {baseCompany?.industry?.industryName}
                </span>
                /
                <span
                  className={`${BLOCK}__header-chip ${BLOCK}__header-chip--green`}
                  data-testid="insight-market-cap-chip"
                >
                  {baseCompany?.mrfyMarketCap &&
                    getMarketCap(baseCompany?.mrfyMarketCap)}
                </span>
                {showVendorUpdatedDates && (
                  <>
                    <div className={`${BLOCK}__refresh-btn`}>
                      <Button
                        className={`${BLOCK}__refresh-btn`}
                        dataTest="refresh-button"
                        onClick={() => setShowRefreshModal(true)}
                        iconName="refresh"
                        iconWidth={20}
                        iconHeight={20}
                      />
                      <Tooltip position={TooltipPosition.bottom}>
                        <FormattedMessage id="insights.data.refresh.tooltip" />
                      </Tooltip>
                    </div>
                    <span
                      className={`${BLOCK}__header-chip ${BLOCK}__header-last-updated`}
                      data-testid="insight-last-updated"
                    >
                      <FormattedMessage id="insights.header.last.updated.on" />:{" "}
                      {getVendorUpdatedDates()}
                    </span>
                  </>
                )}
              </div>
              {showExportPanel && (
                <ExportPanel
                  BLOCK={`insights__create-new`}
                  exportOptions={exportOptions}
                  IncludeInSharing={IncludeInSharing}
                  setIncludeInSharing={setIncludeInSharing}
                  checkboxData={checkboxData}
                  selectedExport={selectedExport}
                  setExport={setExport}
                  exportFormatData={exportFormatData}
                  exportFormat={exportFormat}
                  setExportFormat={setExportFormat}
                  selectedDetailedViewBenchmarkIds={
                    selectedDetailedViewBenchmarkIds
                  }
                  ExportButtonHandler={ExportButtonHandler}
                  showExportRestrictionMsg={true}
                  setShowExport={setExportPanel}
                  isESRS={isESRS}
                />
              )}
              {showShareModal && (
                <ShareToUsersModal
                  setShowShare={setShowShareModal}
                  shareSearch={() => {}}
                  setShowSearchModal={() => {}}
                  shareShortlist={() => {}}
                  shareInsights={shareInsightHandler}
                  shareCompare={() => {}}
                  shareType={shareItemType.insights}
                />
              )}
              {createNewTab && (
                <CreateNew
                  BLOCK={`insights__create-new`}
                  tabId={currentTabId}
                  edit={true}
                  setCreateNewTab={setCreateNewTab}
                />
              )}
            </div>

            {LayoutComponent}

            {exportAllLoading && (
              <div className={`${BLOCK}__exportAll-icon`}>
                <Modal show={true} hideBackgroud={true}>
                  <Icon
                    name={"loading"}
                    height={50}
                    width={50}
                    className="loading-icon"
                    data-testid="loading-icon"
                  />
                </Modal>
              </div>
            )}
          </div>
          <div className={`${BLOCK}__tab-separator`} />
          <div className={`${BLOCK}__tabs`}>
            {tabItems.map((tab, index) =>
              tab.show ? (
                <button
                  type="button"
                  key={`action-button-${index}`}
                  className={`${BLOCK}__tab-button ${
                    tab.tabId === currentTabId ? `${BLOCK}_selected` : ""
                  }`}
                  onClick={() => {
                    setLoading(true);
                    dispatch(tab.track(true));
                    handleTabChange(tab.tabId);
                  }}
                  data-testid={tab.text}
                >
                  <Icon name={tab.icon} width={15} height={15} />
                  <FormattedMessage id={tab.text} />
                </button>
              ) : null
            )}
          </div>
        </>
      )}
      {showExportModal && (
        <Modal
          show={true}
          header={"dashboard.export.header"}
          handleClose={() => {
            setShowExportModal(false);
          }}
          config={false}
        >
          <div className={`DisclaimerModal DisclaimerModal--disclaimer`}>
            <span className={`DisclaimerModal__name--disclaimer`}>
              <FormattedMessage id={"dashboard.export.modal.text"} />
            </span>
            <div
              className={`DisclaimerModal__options DisclaimerModal__options--disclaimer`}
            >
              <button
                data-testid="cancel-btn"
                className={`primary-outline Disclaimer__btn`}
                onClick={cancelHandler}
              >
                <FormattedMessage id="shortlist.save.button.cancel" />
              </button>
              <button
                data-testid="ok-btn"
                onClick={ExportHandler}
                className={classNames(`DisclaimerModal__continue`)}
              >
                <FormattedMessage id="upload.files.ok.button" />
              </button>
            </div>
          </div>
        </Modal>
      )}
      {showRefreshModal && (
        <ConfirmationModal
          customTitle={
            <span className={`${BLOCK}__refresh-modal-header`}>
              <Icon name="information_blue" width={24} height={24} />
              <FormattedMessage id="insights.data.refresh.title" />
            </span>
          }
          content={
            <span className={`${BLOCK}__refresh-modal-content`}>
              <FormattedMessage id="insights.data.refresh.message.1" />
              <br />
              <FormattedMessage id="insights.data.refresh.message.2" />
            </span>
          }
          handleCloseModal={() => {
            setShowRefreshModal(false);
          }}
          handleConfirm={() => {
            handleRefreshInsightsData();
            setShowRefreshModal(false);
          }}
          customClass=""
          show={true}
          customConfirmBtnText="update.btn"
        />
      )}
    </>
  );
};

export default InsightDashboard;

export type DashboardVariables = {
  toggleClicked: boolean;
  selectedExport: string;
  selectedView: number | null;
  exportAllLoading: number | boolean;
  currentTabId: number;
};

export type DashboardFunctions = {
  setToggleClicked: any;
  setTableViewSelected: Function;
  toggleDetailView: Function;
};
