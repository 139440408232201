import TableChart from "components/visualizations/charts/table-chart";
import { mapTableChart, useTileContext } from "../tile.context";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  getInsightsDetailPeerViewColorCode,
  isNumeric,
  mod,
} from "utils/functions";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import TileEmptyContent from "../tile-empty-content";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import {
  AssociatedMetricTooltips,
  GroupedLegends,
} from "services/dashboard/dashboard.model";
import MultipleBarChart from "components/visualizations/charts/multiple-bar.chart";
import { DatasetLegend } from "components/visualizations/dataset-structure";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import DetailPeerViewLegend from "./DetailPeerViewLegend";
import Popover from "components/shared/popover/popover";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";

const EnergyConsumption = () => {
  const {
    index,
    dataset,
    isTableViewActive,
    metadata,
    metadata: {
      benchmarkType,
      benchmarkTitle,
      benchmarkMetadata: { colorCodes, valueLimitProperties },
      isDetailedView,
      associatedMetricsTooltips,
    },
  } = useTileContext();
  const BLOCK = "energyConsumption";
  const dispatch = useDispatch();
  const commonsState = useSelector((state: RootStore) => state.commons);

  const hasEmptyValues = dataset.some((d: InsightData) =>
    d.metrics.some(
      (m: InsightMetricData) => m.metricValue === null || m.metricValue === "*"
    )
  );

  const hasNonNumericValues = dataset.some((d: InsightData) =>
    d.metrics.some((m: InsightMetricData) => !isNumeric(m.metricValue))
  );

  const formatMetricValue = (metric: InsightMetricData) => {
    return (
      new Intl.NumberFormat().format(
        metric.metricKey ===
          "TotalEnergyConsumptionEnergyConsumptionTotalsCustom"
          ? Number(Math.round(metric.metricValue))
          : Number(parseFloat(metric.metricValue).toFixed(2))
      ) + " "
    );
  };

  const renderMetricValue = (
    metric: InsightMetricData,
    isTooltipRender: boolean
  ) => {
    if (!isTooltipRender) {
      return isNumeric(metric.metricValue) ? (
        <>
          {formatMetricValue(metric)}
          <br />
          <span className={`${BLOCK}__metric-unit`}>
            <FormattedMessage id="energy.consumption.mwh" />
          </span>
        </>
      ) : metric.metricValue ? (
        metric.metricValue
      ) : (
        " * "
      );
    } else {
      return isNumeric(metric.metricValue)
        ? formatMetricValue(metric)
        : metric.metricValue ?? " * ";
    }
  };

  const renderChartView = () => {
    return (
      <>
        <div
          className={
            benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY
              ? `${BLOCK}__container`
              : ""
          }
          data-testid={`${BLOCK}__container`}
        >
          {dataset.map((company) =>
            company.metrics.length > 0 ? (
              <div
                className={
                  benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY
                    ? `${BLOCK}__company`
                    : ""
                }
                key={company.globalCompanyId}
              >
                {benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY ? (
                  <div className={`${BLOCK}__company-name`}>
                    {company.companyName}
                  </div>
                ) : null}
                <div className={`${BLOCK}__metrics`}>
                  {company.metrics.map((metric) => (
                    <div className={`${BLOCK}__metric`} key={metric.metricKey}>
                      <Popover
                        metricClassName={`${BLOCK}__header-name`}
                        displayText={metric.metricName}
                        content={
                          associatedMetricsTooltips &&
                          associatedMetricsTooltips.length > 0
                            ? associatedMetricsTooltips.filter(
                                (tooltip: AssociatedMetricTooltips) =>
                                  tooltip.associatedMetric === metric.metricKey
                              )
                            : []
                        }
                      />
                      <span
                        className={`${BLOCK}__metric-value`}
                        onMouseEnter={(e: any) => {
                          handleMouseEnter(
                            e,
                            "span",
                            <div>
                              {company.companyName +
                                " ( " +
                                `${renderMetricValue(metric, true)}` +
                                ")"}
                            </div>,
                            "",
                            dispatch,
                            null,
                            MainTooltipPosition.TopRight
                          );
                        }}
                        onMouseLeave={(e) => {
                          handleMouseLeave(e, dispatch, false);
                        }}
                      >
                        {renderMetricValue(metric, false)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ) : null
          )}
        </div>
        {hasEmptyValues && (
          <div className={`${BLOCK}__no-data-text`}>
            <span>
              <FormattedMessage id="no.data.available" />
            </span>
          </div>
        )}
      </>
    );
  };

  const renderTableView = () => {
    const formattedDataset = dataset.map((company: InsightData) => {
      const metrics = company.metrics.map((metric: InsightMetricData) => {
        return {
          ...metric,
          metricValue: isNumeric(metric.metricValue)
            ? formatMetricValue(metric)
            : metric.metricValue,
        };
      });
      return {
        ...company,
        metrics,
      };
    });
    return (
      <TableChart
        data={mapTableChart(formattedDataset, metadata, false, false, false)}
        currentInsightView={benchmarkType}
        rightAlignment={true}
      />
    );
  };

  const getLegendColor = (i: number) => colorCodes[mod(i, colorCodes.length)];

  const getValueTooltip = (i: number, j: number) => {
    let value: any = isNumeric(dataset[j].metrics[i].metricValue)
      ? formatMetricValue(dataset[j].metrics[i])
      : "*";
    return `${dataset[j].companyName} ( ${value})`;
  };

  const getMetricSuffix = (metric: InsightMetricData) => {
    const sufix = valueLimitProperties?.find(
      (vl: any) => vl.associatedMetricRef === metric.metricKey
    );
    return sufix ? ", " + sufix.fieldValueSuffix : "";
  };

  const mapBarChartData = (): GroupedLegends[] => {
    return dataset.length
      ? dataset[0].metrics.map((metric: InsightMetricData, i: number) => ({
          groupName: metric.metricName + getMetricSuffix(metric),
          separateBars: true,
          description: metric.metricTooltip,
          legends: dataset.map((data: InsightData, j) => ({
            legendValue: data.companyName,
            legendOrder: j,
            legendHideData: true,
            legendTooltip: getValueTooltip(i, j),
            legendColor: getInsightsDetailPeerViewColorCode(
              commonsState.InsightsDetailedPeerViewColorCodes,
              data.displayOrder!
            ),
            legendData: isNumeric(data.metrics[i].metricValue)
              ? parseInt(data.metrics[i].metricValue)
              : null,
          })),
        }))
      : [];
  };

  const renderDetailedView = () => {
    const chartProps = {
      name: benchmarkTitle,
      index: index,
      data: mapBarChartData(),
      verticalOrientation: false,
      dahsedGridLine: true,
      hideDashedBaseLine: true,
      hideDatasetInfo: true,
      displayNoDataPattern: true,
    };
    return (
      <div className={`${BLOCK}__detailedView`}>
        <DetailPeerViewLegend
          dataset={dataset}
          isDetailedView={isDetailedView}
          colorCodes={
            isDetailedView
              ? commonsState.InsightsDetailedPeerViewColorCodes
              : []
          }
        />
        <MultipleBarChart {...chartProps} />
        {hasNonNumericValues && (
          <div className={`${BLOCK}__no-data-section`}>
            <div className={`${BLOCK}__no-data-pill`}></div>
            <div className={`${BLOCK}__no-data-text`}>
              <span>
                <FormattedMessage id="no.data.available.or.question.not.applicable" />
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  if (dataset.every((d) => d.metrics.every((m) => !m.metricValue)))
    return <TileEmptyContent />;

  return !isTableViewActive && !isDetailedView
    ? renderChartView()
    : isTableViewActive
    ? renderTableView()
    : renderDetailedView();
};

export default EnergyConsumption;
