import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import MapfilesTableRow from "./mapfiles-row";

type TableProps = {
  className?: string;
  headerItems?: any;
  rowItems?: any;
  handleRemoveRow: any;
  searchState: any;
  periodYearsOptions: any;
  reportTypeOptions: any;
  esgFrameworksOptions: any;
  setMetaDataMissingReports: any;
  metaDataMissingReports: any;
  handleChange: any;
  metaDataMissing: any;
};

const ResponsiveMapFilesTable = ({
  className,
  headerItems,
  rowItems,
  handleRemoveRow,
  searchState,
  periodYearsOptions,
  reportTypeOptions,
  esgFrameworksOptions,
  setMetaDataMissingReports,
  metaDataMissingReports,
  handleChange,
  metaDataMissing,
}: TableProps) => {
  const BLOCK = "mapfiles-table";
  const renderHeaderItemContents = (item: any) => {
    return (
      <>
        {item.text.length > 0 && (
          <div className={`${BLOCK}__header--text`}>
            <span id={item.text}>
              <FormattedMessage id={item.text} />
            </span>
          </div>
        )}
      </>
    );
  };

  const renderRow = (rowItem: any, index: number) => {
    return (
      <MapfilesTableRow
        key={index}
        index={index}
        rowItem={rowItem}
        removeRow={handleRemoveRow}
        searchState={searchState}
        periodYearOptions={periodYearsOptions}
        reportTypeOptions={reportTypeOptions}
        esgFrameworksOptions={esgFrameworksOptions}
        addIndex={setMetaDataMissingReports}
        currentIndexesMissing={metaDataMissingReports}
        handleChange={handleChange}
      />
    );
  };

  return (
    <div
      className={classNames(`${BLOCK}`, {
        [`${className}`]: className,
      })}
    >
      <div className={`${BLOCK}__table--wrapper`}>
        <div className={`${BLOCK}__table--inner--wrapper`}>
          <table className={`${BLOCK}__table`}>
            <thead>
              {metaDataMissing && (
                <tr
                  className={`${BLOCK}__heading--fieldsMissing`}
                  key={`__head__heading--text--fieldsMissing`}
                  id={"requiredFieldsMissing"}
                >
                  <Icon
                    name={"warning-diamond"}
                    height={15}
                    width={15}
                    className={`${BLOCK}__heading--fieldsMissing--icon`}
                  />
                  <div className={`${BLOCK}__heading--fieldsMissing--text`}>
                    <FormattedMessage id="map-files.table-header-fields-missing" />
                  </div>
                </tr>
              )}
              <tr className={`${BLOCK}__header`}>
                {headerItems &&
                  headerItems.map((item: any, index: number) => (
                    <th
                      className={`${BLOCK}__header--content ${
                        item.className ? item.className : ""
                      }`}
                      key={index}
                    >
                      {renderHeaderItemContents(item)}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className={`${BLOCK}__table--body`}>
              {rowItems &&
                rowItems.map((item: any, index: any) => renderRow(item, index))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveMapFilesTable;
