import { Dispatch } from "redux";
import { createAction } from "utils/redux.utils";

import {
  DocumentViewerModel,
  DocumentViewerState,
} from "./document-viewer.model";
import * as api from "./document-viewer.api";
import {
  EsgSearchResultsReport,
  SecSearchResultsReport,
} from "services/search/search.results.model";
import { setSelectedTOCSection } from "services/search/search.results.service";

/*
 * Actions
 */

export const ACTIONS = {
  DOCUMENT_VIEWER_ERROR: "ESG/UI/DOCUMENT_VIEWER_ERROR",
  DOCUMENT_VIEWER_LOADING: "ESG/UI/DOCUMENT_VIEWER_LOADING",
  DOCUMENT_VIEWER_LOAD_SEC_STATEMENTS:
    "ESG/UI/DOCUMENT_VIEWER_LOAD_SEC_STATEMENTS",
  DOCUMENT_VIEWER_LOAD_ESG_KEYWORDS: "ESG/UI/DOCUMENT_VIEWER_LOAD_ESG_KEYWORDS",
  DOCUMENT_VIEWER_SET_SELECTED_REPORT:
    "ESG/UI/DOCUMENT_VIEWER_SET_SELECTED_REPORT",
  DOCUMENT_VIEWER_SET_SELECTED_DISCLOSURE:
    "ESG/UI/DOCUMENT_VIEWER_SET_SELECTED_DISCLOSURE",
  DOCUMENT_VIEWER_SET_FULLSCREEN: "ESG/UI/DOCUMENT_VIEWER_SET_FULLSCREEN",
  DOCUMENT_VIEWER_SET_CURRENT_SELECTION:
    "ESG/UI/DOCUMENT_VIEWER_SET_CURRENT_SELECTION",
  DOCUMENT_VIEWER_SET_ELEMENT_ID: "ESG/UI/DOCUMENT_VIEWER_SET_ELEMENT_ID",
};

export const documentViewerError = createAction(ACTIONS.DOCUMENT_VIEWER_ERROR);
export const documentViewerLoading = createAction(
  ACTIONS.DOCUMENT_VIEWER_LOADING
);
export const documentViewerLoadSECStatement = createAction(
  ACTIONS.DOCUMENT_VIEWER_LOAD_SEC_STATEMENTS
);
export const documentViewerSetSelectedReport = createAction(
  ACTIONS.DOCUMENT_VIEWER_SET_SELECTED_REPORT
);
export const documentViewerSetSelectedDisclosure = createAction(
  ACTIONS.DOCUMENT_VIEWER_SET_SELECTED_DISCLOSURE
);
export const documentViewerSetFullscreen = createAction(
  ACTIONS.DOCUMENT_VIEWER_SET_FULLSCREEN
);
export const documentViewerSetCurrentSelectionPage = createAction(
  ACTIONS.DOCUMENT_VIEWER_SET_CURRENT_SELECTION
);
export const documentViewerSetElementId = createAction(
  ACTIONS.DOCUMENT_VIEWER_SET_ELEMENT_ID
);

export const getDocumentViewerESGLayout = async (
  reportId: number,
  contentText?: string
) => {
  try {
    const res = await api.fetchDocumentViewerESGLayout(reportId);
    const keywords = contentText
      ? (await api.fetchDocumentViewerESGKeywords(reportId, contentText)).data
          .keywords
      : [];
    return {
      ESGLayout: res.data,
      ESGKeywords: keywords,
    };
  } catch (e) {
    return { ESGLayout: [], ESGKeywords: [] };
  }
};

export const getReport = (reportId: number) =>
  api.fetchESGReportMetadata(reportId);

export const getDisclosureStatements = (
  sectionId: number,
  contentText: string
) =>
  api.fetchDocumentViewerSECStatements(
    sectionId,
    contentText ? contentText : ""
  );

export const loadDocumentViewerSECStatements =
  (sectionId: number, contentText: string) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(documentViewerLoading());
      const res = await api.fetchDocumentViewerSECStatements(
        sectionId,
        contentText ? contentText : ""
      );
      dispatch(documentViewerLoadSECStatement(res.data));
      dispatch(setSelectedTOCSection(sectionId));
    } catch (e) {
      dispatch(documentViewerError(e));
    }
  };

export const setSelectedDisclosureElementId =
  (elementId: number) => async (dispatch: Dispatch<any>) => {
    dispatch(documentViewerSetElementId(elementId));
  };

export const setFullscreen =
  (fullscreen: boolean) => (dispatch: Dispatch<any>) => {
    dispatch(documentViewerSetFullscreen(fullscreen));
  };

export const setCurrentSelectionPage =
  (currentSelection: any) => (dispatch: Dispatch<any>) => {
    dispatch(documentViewerSetCurrentSelectionPage(currentSelection));
  };

export const setSelectedReport =
  (selectedReport: EsgSearchResultsReport | null) =>
  (dispatch: Dispatch<any>) => {
    dispatch(documentViewerSetSelectedReport(selectedReport));
    if (selectedReport === null) {
      dispatch(documentViewerLoading());
    }
  };

export const setSelectedDisclosure =
  (selectedDisclosure: SecSearchResultsReport | null) =>
  (dispatch: Dispatch<any>) => {
    dispatch(documentViewerSetSelectedDisclosure(selectedDisclosure));
  };

/*
 * Reducer
 */

const documentViewerModel = new DocumentViewerModel();

const documentViewerReducer = (
  state: DocumentViewerState = documentViewerModel.initialState(),
  action: any
): DocumentViewerState => {
  switch (action.type) {
    case ACTIONS.DOCUMENT_VIEWER_ERROR:
      return documentViewerModel.setError(action.payload);
    case ACTIONS.DOCUMENT_VIEWER_LOADING:
      return documentViewerModel.setLoading();
    case ACTIONS.DOCUMENT_VIEWER_LOAD_SEC_STATEMENTS:
      return documentViewerModel.setDocumentViewerSECStatements(action.payload);
    case ACTIONS.DOCUMENT_VIEWER_SET_SELECTED_REPORT:
      return documentViewerModel.setSelectedReport(action.payload);
    case ACTIONS.DOCUMENT_VIEWER_SET_SELECTED_DISCLOSURE:
      return documentViewerModel.setSelectedDisclosure(action.payload);
    case ACTIONS.DOCUMENT_VIEWER_SET_FULLSCREEN:
      return documentViewerModel.setFullscreen(action.payload);
    case ACTIONS.DOCUMENT_VIEWER_SET_CURRENT_SELECTION:
      return documentViewerModel.setCurrentSelectionPage(action.payload);
    case ACTIONS.DOCUMENT_VIEWER_SET_ELEMENT_ID:
      return documentViewerModel.setDisclosureElementId(action.payload);
    default:
      return state;
  }
};

export default documentViewerReducer;
