import { useEffect, useState } from "react";
import MaintenanceMessage from "./active-maintenance-message";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import {
  setActiveMaintenanceMessages,
  settingCachedActiveMaintenanceMessages,
} from "services/manage-files/manage-files.service";
import { getItem, setItem } from "utils/localStorageFunctions";
import {
  LOCALSTORAGE_VARIABLE,
  contentBenchmarkPathRegex,
} from "utils/constants";
import { activeMaintenanceMessages } from "services/manage-files/manage-files.model";
import { useLocation } from "react-router";
import classNames from "classnames";
import socket, { rooms } from "utils/socket";

const MaintenanceMessageList = () => {
  const location = useLocation();
  const BLOCK = `maintenance-message`;
  const dispatch = useDispatch();
  const [messageList, setMessageList] = useState<activeMaintenanceMessages[]>(
    []
  );
  const manageFilesState = useSelector((state: RootStore) => state.manageFiles);
  const commonState = useSelector((state: RootStore) => state.commons);

  const UpdateActiveMessagesFromNotification = (payload: any) => {
    dispatch(setActiveMaintenanceMessages());
    const closedActiveMessages = getItem<number[]>(
      LOCALSTORAGE_VARIABLE.closedActiveMaitenanceMessage
    );
    if (
      payload.data &&
      payload.data.maintenanceMessageId &&
      closedActiveMessages
    ) {
      let tempIndex = closedActiveMessages?.indexOf(
        payload.data.maintenanceMessageId
      );
      if (tempIndex !== -1) {
        closedActiveMessages.splice(tempIndex, 1);
        setItem(
          LOCALSTORAGE_VARIABLE.closedActiveMaitenanceMessage,
          closedActiveMessages
        );
        setMessageList(
          manageFilesState.activeMaintenanceMessages.filter(
            (activeMaintenanceMessage) =>
              closedActiveMessages.indexOf(
                activeMaintenanceMessage.maintenanceMessageId
              ) === -1
          )
        );
      }
    }
  };

  useEffect(() => {
    const closedActiveMessages = getItem<number[]>(
      LOCALSTORAGE_VARIABLE.closedActiveMaitenanceMessage
    );
    if (!closedActiveMessages || closedActiveMessages.length === 0) {
      setMessageList(manageFilesState.activeMaintenanceMessages);
    }

    if (closedActiveMessages && closedActiveMessages.length > 0) {
      setMessageList(
        manageFilesState.activeMaintenanceMessages.filter(
          (activeMaintenanceMessage) =>
            closedActiveMessages.indexOf(
              activeMaintenanceMessage.maintenanceMessageId
            ) === -1
        )
      );
    }
  }, [manageFilesState.activeMaintenanceMessages]);

  useEffect(() => {
    dispatch(settingCachedActiveMaintenanceMessages(messageList));
  }, [messageList]);

  useEffect(() => {
    if (commonState.currentUser.userId) {
      socket.join(rooms.maintenanceMessageUpdate());

      socket.on("maintenanceMessage.status.update", (payload: any) =>
        UpdateActiveMessagesFromNotification(payload)
      );

      return () => {
        socket.leave(rooms.maintenanceMessageUpdate());
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonState.currentUser.userId]);

  return (
    <div
      className={classNames(`${BLOCK}__message-container`, {
        [`${BLOCK}__message-container-for-contentbenchmark`]:
          contentBenchmarkPathRegex.test(location.pathname),
      })}
      data-test="active-maintenance-messages-container"
    >
      {messageList.map((messageObj: activeMaintenanceMessages) => (
        <MaintenanceMessage
          messageObj={messageObj}
          setMessageList={setMessageList}
          key={`messageObj-${messageObj.maintenanceMessageId}`}
        />
      ))}
    </div>
  );
};

export default MaintenanceMessageList;
