import { User } from "services/commons.model";
import { BaseModel, errorModel } from "../../utils/redux.utils";
import { Topic, Search } from "services/searchlist/searchlist.model";

export interface PublishedSearchlistState {
  error?: errorModel;
  results: PublishedSearches[];
  loading: boolean;
  currentSearchItem: {
    item: Search;
    type: string;
  };
  currentSearchLoading: boolean;
  unpublishToaster: boolean;
}

export class PublishedSearchlistModel extends BaseModel {
  constructor() {
    super({
      loading: false,
      results: [],
      currentSearchItem: null,
      currentSearchLoading: false,
      unpublishToaster: false,
    });
  }

  setLoading = (loading: boolean): PublishedSearchlistState =>
    this.merge({ loading });

  setPublishedSearchlist = (
    results: PublishedSearches[]
  ): PublishedSearchlistState => this.merge({ results });

  setCurrentSearchItem = (currentSearchItem: {
    item: Search;
    type: string;
  }): PublishedSearchlistState => this.merge({ currentSearchItem });

  setCurrentSearchLoading = (
    currentSearchLoading: boolean
  ): PublishedSearchlistState => this.merge({ currentSearchLoading });

  setUnpublishToaster = (unpublishToaster: boolean): PublishedSearchlistState =>
    this.merge({ unpublishToaster });
}

export type PublishedSearchList = {
  pageInfo: publishedSearchListPageInfo;
  publishedSearches: PublishedSearches[];
};

export type publishedSearchListPageInfo = {
  totalRecordCount: number;
  pageNumber: number;
  pageSize: number;
  totalPageCount: number;
  sortBy: string;
  sortOrder: string;
};

export type PublishedSearches = {
  savedSearchId: number;
  name: string;
  description: string;
  searchCriteria: {
    contentText: string;
    formTypeNames: [];
    years: [];
    companyIds: [];
    industryIds: [];
    auditorIds: [];
    governingBoardIds: [];
    pageInfo: {
      totalPageCount: number;
      totalRecordCount: number;
      pageNumber: number;
      pageSize: number;
      sortBy: string;
      sortOrder: string;
    };
    shortlistId: number;
  };
  sourceSavedSearchId: number;
  displayOrder: number;
  createdBy: User;
  sharedBy: User;
  sharedDate: string;
  lastExecutedDate: string;
  sharedWith: User[];
  remainingDays: number;
  hasPublished: boolean;
  topic: Topic;
};

export type PublishedSearchPayload = {
  savedSearchId: number;
  name: string | undefined;
  description: string | undefined;
  searchCriteria: {};
  hasPublished?: boolean;
  topicId?: number;
  topic?: Topic;
  type?: any;
  location?: number;
};
