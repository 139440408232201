import { useTileContext, mapTableChart } from "../tile.context";
import TileEmptyContent from "../tile-empty-content";
import TableChart from "components/visualizations/charts/table-chart";
import CircularBarChart from "components/visualizations/charts/circular-bar/circular-bar.chart";
import { primaryColor, INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import HorizontalInsightsBarChart from "components/visualizations/charts/horizontal-insights-bar.chart";
import classNames from "classnames";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import {
  getInsightsDetailPeerViewColorCode,
  isNumeric,
  mod,
} from "utils/functions";
import { DatasetLegend } from "components/visualizations/dataset-structure";
import CustomMultipleBarChart from "components/visualizations/charts/custom-multiple.bar.chart";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import DetailPeerViewLegend from "./DetailPeerViewLegend";

const CircularBarChartComponent = () => {
  const {
    dataset,
    index,
    isTableViewActive,
    metadata,
    metadata: { benchmarkType, benchmarkMetadata, isDetailedView },
  } = useTileContext();

  const BLOCK = "circular-bar-chart";

  const primaryAxisValues = benchmarkMetadata?.axis?.primary;

  const insightsCategoriesState = useSelector(
    (state: RootStore) =>
      state.insights?.currentSavedInsightDashboard?.insightsCategories
  );

  const commonsState = useSelector((state: RootStore) => state.commons);

  const mapDetailedViewData = () => {
    return dataset.length > 0
      ? dataset.map((data: InsightData, i: number) => ({
          legendValue: data.companyName,
          legendOrder: i,
          legendColor: getInsightsDetailPeerViewColorCode(
            commonsState.InsightsDetailedPeerViewColorCodes,
            data.displayOrder!
          ),
          legendData: isNumeric(parseFloat(data.metrics[0].metricValue))
            ? parseFloat(data.metrics[0].metricValue)
            : null,
        }))
      : [];
  };

  const mapMultipleChartData = () => {
    return [
      {
        groupName: "",
        groupPrefix: metadata.benchmarkMetadata?.valueLimitProperties
          ? metadata.benchmarkMetadata?.valueLimitProperties[0]
              ?.fieldValueSuffix ?? ""
          : "",
        description: metadata.associatedMetricsTooltips
          ? metadata.associatedMetricsTooltips.filter(
              (metrics: any) => metrics.associatedMetricDisplayName === ""
            )
          : [],
        separateBars: true,
        legends:
          dataset.length > 0
            ? dataset.map((data: InsightData, i: number) => ({
                legendValue: data.companyName,
                legendOrder: i,
                legendColor: getInsightsDetailPeerViewColorCode(
                  commonsState.InsightsDetailedPeerViewColorCodes,
                  data.displayOrder!
                ),
                legendData: data?.metrics[0]?.metricValue,
                legendTooltip:
                  data?.metrics.length > 0 && data?.metrics[0]?.metricValue
                    ? `${data.companyName} (${data.metrics[0].metricValue})`
                    : `${data.companyName} (*)`,
              }))
            : [],
      },
    ];
  };

  const sectionGroupId =
    insightsCategoriesState && insightsCategoriesState.length > 0
      ? insightsCategoriesState[0].sectionBenchmarkMappings?.find(
          (sectionBenchmarkMapping) =>
            sectionBenchmarkMapping.benchmarkId === metadata?.benchmarkId
        )?.sectionGroupId
      : null;

  const sectionGroupwidth =
    insightsCategoriesState && insightsCategoriesState.length > 0
      ? insightsCategoriesState[0].sectionGroups?.find(
          (sectionGroup) => sectionGroup.sectionGroupId === sectionGroupId
        )?.sectionGroupWidth
      : null;

  const tileWidth = Math.max(
    sectionGroupwidth ?? 0,
    benchmarkMetadata["hScale"][0]
  );

  const avgData = {
    isNotCompanyView:
      benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY ? true : false,
    avgValue: parseFloat(
      dataset.length > 1
        ? dataset[1]?.metrics[0]?.metricValue?.toString() ?? ""
        : ""
    ),
    avgName: dataset.length > 1 ? dataset[1]?.companyName ?? "" : "",
    avgColorCodes:
      benchmarkMetadata.conditionalColorCodes &&
      benchmarkMetadata.conditionalColorCodes.length
        ? [benchmarkMetadata.conditionalColorCodes[0]]
        : ["#86bc25"],
  };

  const hasEmptyValues = dataset.some((d: InsightData) =>
    d.metrics.some(
      (m: InsightMetricData) =>
        m.metricValue === null ||
        m.metricValue === "*" ||
        m.metricValue === "" ||
        m.metricValue === undefined
    )
  );

  if (dataset.every((d) => d.metrics.every((m) => !m.metricValue)))
    return <TileEmptyContent />;

  return isTableViewActive ? (
    <>
      <div
        className={classNames(
          `${BLOCK}__table-wrapper ${BLOCK}__table-wrapper--company-peer`
        )}
      >
        <span className={`${BLOCK}__subTitle`}>
          {benchmarkMetadata.benchmarkSubTitle}
        </span>
        <TableChart
          data={mapTableChart(dataset, metadata, false, false, true)}
          currentInsightView={benchmarkType}
          horizontalTable={benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY}
          hideTableHeader={benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY}
        />
      </div>
    </>
  ) : (
    <>
      {dataset[0]?.metrics.length ?? 0 > 0 ? (
        isDetailedView ? (
          <div className={`${BLOCK}__detailedWrapper`}>
            <div className={`${BLOCK}__detailedContainer`}>
              <span className={`${BLOCK}__subTitle`}>
                {benchmarkMetadata.benchmarkSubTitle}
              </span>
              <DetailPeerViewLegend
                dataset={dataset}
                isDetailedView={isDetailedView}
                colorCodes={
                  isDetailedView
                    ? commonsState.InsightsDetailedPeerViewColorCodes
                    : []
                }
              />
              <ParentSize
                className={classNames(`${BLOCK}__content`)}
                debounceTime={100}
              >
                {({ width: visWidth, height: visHeight }) => {
                  const barChartProps = {
                    name: `${BLOCK}-DetailedPeerView`,
                    axis: { primary: primaryAxisValues, secondary: [] },
                    width: visWidth,
                    height: visHeight,
                    descSort: false,
                    data: mapDetailedViewData(),
                  };
                  return tileWidth > 1 ? (
                    <CustomMultipleBarChart
                      {...{
                        ...barChartProps,
                        index: index,
                        data: mapMultipleChartData(),
                        verticalOrientation: false,
                        customChartContainerClass: `${BLOCK}__chart-container`,
                        customBarContainerClass: `${BLOCK}__chart-bar`,
                        dashedline: true,
                        yAxisMaxValue: 10,
                        noDataAvailableDisplayForCompany: true,
                      }}
                    />
                  ) : (
                    <HorizontalInsightsBarChart {...barChartProps} />
                  );
                }}
              </ParentSize>
            </div>
            {hasEmptyValues && tileWidth <= 1 && (
              <div className={`horizontalGraduatedChart__no-data-section`}>
                <span>
                  <FormattedMessage id="no.data.available" />
                </span>
              </div>
            )}
          </div>
        ) : (
          <CircularBarChart
            key={`${BLOCK}-${dataset[0].globalCompanyId}`}
            value={parseFloat(
              dataset[0]?.metrics[0]?.metricValue?.toString() ?? ""
            )}
            minValue={0}
            maxValue={10}
            subtitle={benchmarkMetadata.benchmarkSubTitle}
            colors={
              benchmarkMetadata.colorCodes &&
              benchmarkMetadata.colorCodes.length
                ? benchmarkMetadata.colorCodes
                : [primaryColor]
            }
            avgData={avgData}
            companyName={dataset[0]?.companyName ?? ""}
            tileWidth={tileWidth}
          />
        )
      ) : null}
    </>
  );
};

export default CircularBarChartComponent;
