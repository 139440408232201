import { useSelector } from "react-redux";
import { RootStore } from "../../services/store.service";
import { Role } from "../../services/commons.model";
import MySearches from "./my-searches";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { ACCESS_MODULES, MENU_ITEMS, Roles, unauthorizedPath } from "utils/constants";
import PublishedSearches from "./published-searches";
import Feedback from "components/feedback/feedback";
import { submitFeedback } from "services/download/download.api";
import TabbedContent from "components/shared/tabbed-content/tabbed-content";
import history from "utils/history";
import Button from "components/shared/button/button";

const MySearchesContainer = () => {
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );
  const isSuperAdmin: boolean = currentUser?.adminRole?.adminRoleId === Roles.superAdmin;

  const location = useLocation();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const BLOCK = "Search";
  const tabItems = [
    {
      text: "my.search.tab.saved.searches",
      tabId: "my-search-tab-saved-searches",
      tabPanelId: "saved-searches--tab-panel",
      location: MENU_ITEMS.mysearches.path,
    },
    {
      text: "my.search.tab.published.searches",
      tabId: "my-search-tab-published-searches",
      tabPanelId: "published-searches--tab-panel",
      location: MENU_ITEMS.publishedsearches.path,
    },
  ];

  useEffect(() => {
    setCurrentTab(
      tabItems.findIndex((item) => item.location === location.pathname)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.accessibleFeatures &&
      currentUser.accessibleFeatures.length > 0 &&
      !currentUser.accessibleFeatures.some(
        (feature) =>
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_All ||
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_SavedSearch
      )
    ) {
      history.push(unauthorizedPath);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, currentUser]);

  const handleTabChange = (index: number) => {
    history.push(tabItems[index].location, location.state);
  };

  const submitFeedbackHandler = ({
    feedback,
    rating,
  }: {
    feedback: string;
    rating: number;
  }) => {
    submitFeedback({ feedback, rating }).then();
  };

  return (
    <>
      {isSuperAdmin ? (
        <div className={BLOCK} data-test="my-searches-component">
          <div className={`${BLOCK}__header`} data-test="tab-content">
            <TabbedContent
              tabItems={tabItems}
              selectedIndex={currentTab}
              onChange={handleTabChange}
              data-test="tab-content"
            />
            <div className={`${BLOCK}__header-end-section`}>
              <div className={`${BLOCK}__feedback-section`}>
                <Button
                  className={`${BLOCK}__feedback-button button-primary-small`}
                  dataTest="feedback-button"
                  iconName="commenting"
                  iconHeight={24}
                  iconWidth={24}
                  formattedText="submit.feedback"
                  onClick={() => setShowFeedbackModal(true)}
                />
              </div>
              {showFeedbackModal && (
                <div
                  data-test="feedback-button-modal"
                  data-testid="feedback-button-modal"
                >
                  <Feedback
                    setShowFeedbackModal={setShowFeedbackModal}
                    submitFeedback={submitFeedbackHandler}
                  />
                </div>
              )}
            </div>
          </div>
          {currentTab === 0 && (
            <div
              className={`${BLOCK}__content ${BLOCK}__tab-panel ${
                currentTab === 0 && `${BLOCK}__tab-panel--selected`
              }`}
              role="tabpanel"
              id={"saved-searches--tab-panel"}
              aria-labelledby={"my-search-tab-saved-searches"}
            >
              <MySearches />
            </div>
          )}
          {currentTab === 1 && (
            <div
              className={`${BLOCK}__content ${BLOCK}__tab-panel ${
                currentTab === 1 && `${BLOCK}__tab-panel--selected`
              }`}
              role="tabpanel"
              id={"published-searches--tab-panel"}
              aria-labelledby={"my-search-tab-published-searches"}
            >
              <PublishedSearches />
            </div>
          )}
        </div>
      ) : (
        <>{currentUser && <MySearches />}</>
      )}
    </>
  );
};

export default MySearchesContainer;
