import { useSelector } from "react-redux";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import { RootStore } from "services/store.service";
import {
  getFormattedNumber,
  getInsightsDetailPeerViewColorCode,
  mod,
} from "utils/functions";
import { mapTableChart, useTileContext } from "../tile.context";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { GroupedLegends } from "services/dashboard/dashboard.model";
import { INSIGHT_BENCHMARK_TYPE, TABULAR_TILE_HEADER } from "utils/constants";
import TableChart from "components/visualizations/charts/table-chart";
import BarGraph from "components/visualizations/charts/bar.graph";
import TileEmptyContent from "../tile-empty-content";
import MultipleColumnChart from "components/visualizations/charts/multiple-column.chart";
import DetailPeerViewLegend from "./DetailPeerViewLegend";
import { Fragment } from "react";
import ProgressBar from "components/visualizations/charts/progress-bar";

const OwnWorkforce = () => {
  const {
    index,
    dataset,
    isTableViewActive,
    metadata,
    metadata: {
      benchmarkType,
      benchmarkTitle,
      isDetailedView,
      benchmarkMetadata,
      benchmarkMetadata: { colorCodes },
    },

    exporting,
  } = useTileContext();

  const commonsState = useSelector((state: RootStore) => state.commons);
  const colorList =
    colorCodes && colorCodes.length
      ? colorCodes
      : commonsState.dashboardColorCodes;

  if (
    dataset.length === 0 ||
    (dataset.length > 0 &&
      dataset.every((d: InsightData) =>
        d.metrics.every((m: InsightMetricData) => !m.metricValue)
      ))
  )
    return (
      <div>
        <TileEmptyContent />
      </div>
    );
  const BASELINE_COMPANY = dataset[0]?.companyName;
  const getLegendColor = (i: number) => colorList[mod(i, colorList.length)];

  const getValueTooltip = (i: number, j: number) => {
    let value: any = dataset[j].metrics[i].metricValue;
    value = parseFloat(value).toFixed(1); //Rounding off
    value = new Intl.NumberFormat().format(value); //Displaying Number in International format
    value = dataset[j].companyName + " (" + value + ") ";
    return value;
  };

  const mapBarChartData = (): GroupedLegends[] => {
    return dataset.length
      ? dataset[0].metrics.map((metric: InsightMetricData, i: number) => ({
          groupName: metric.metricName,
          separateBars: true,
          description: metric.metricTooltip,
          legends: dataset.map((data: InsightData, j) => ({
            legendValue: data.companyName,
            legendOrder: j,
            legendHideData: true,
            legendTooltip: getValueTooltip(i, j),
            legendColor: isDetailedView
              ? getInsightsDetailPeerViewColorCode(
                  commonsState.InsightsDetailedPeerViewColorCodes,
                  data.displayOrder!
                )
              : getLegendColor(
                  benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
                    ? i
                    : isDetailedView
                    ? data.displayOrder!
                    : j
                ),
            legendData: data.metrics[i].metricValue
              ? getFormattedNumber(data.metrics[i].metricValue, 0, 1, true)
              : data.metrics[i].metricValue,
          })),
        }))
      : [];
  };

  const tileProps = {
    name: benchmarkTitle,
    index: index,
    data: mapBarChartData(),
    showSpaceWhenEmpty: true,
    exporting,
  };

  return (
    <>
      <div className={`chart__range`}>
        <span className={`chart__range-score`}>
          {`${benchmarkMetadata.benchmarkSubTitle} ${
            benchmarkMetadata.benchmarkSubTitle &&
            !benchmarkMetadata.benchmarkSubTitle.toLowerCase().includes("score")
              ? "score"
              : ""
          }`}
        </span>
      </div>
      {isTableViewActive ? (
        <TableChart
          data={mapTableChart(dataset, metadata, false, false, true)}
          currentInsightView={benchmarkType}
        />
      ) : (
        <>
          {/* Show legend at the top of the tile for muliple data in case of detail peer view else show baseline company and/or Peers/Industry*/}
          {!isDetailedView ? (
            <Fragment key={`legend-OwnWorkForce`}>
              <div
                className={`DashboardTile__legend`}
                key={"Legend metric OwnWorkForce"}
              >
                {[
                  { id: 1, colors: [getLegendColor(0)] },
                  { id: 2, colors: [getLegendColor(1)] },
                ].map((legend: any, index: number) => {
                  return (
                    legend && (
                      <div
                        className={`DashboardTile__legend__metric`}
                        key={`LegendOwnWorkForce-${index}`}
                      >
                        {index === 0 && (
                          <div
                            className={`DashboardTile__legend__bar`}
                            key={`Legend-Bar-OwnWorkForce-${index}`}
                          >
                            <ProgressBar
                              mainWidth={100}
                              baseWidth={""}
                              mainTooltip={""}
                              baseTooltip={""}
                              colors={legend.colors}
                              gradient={false}
                            />
                          </div>
                        )}
                        {index === 1 &&
                          benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY && (
                            <div
                              className={`DashboardTile__legend__bar`}
                              key={`Legend-Bar-OwnWorkForce-${index}`}
                            >
                              <ProgressBar
                                mainWidth={100}
                                baseWidth={""}
                                mainTooltip={""}
                                baseTooltip={""}
                                colors={legend.colors}
                                gradient={false}
                              />
                            </div>
                          )}
                        {index === 0 ? (
                          <span>{BASELINE_COMPANY}</span>
                        ) : index === 1 &&
                          benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY ? (
                          benchmarkType === 5 ? (
                            <span>{TABULAR_TILE_HEADER.PEER_BENCHMARK}</span>
                          ) : (
                            <span>{TABULAR_TILE_HEADER.INDUSTRY}</span>
                          )
                        ) : undefined}
                      </div>
                    )
                  );
                })}
              </div>
            </Fragment>
          ) : (
            <DetailPeerViewLegend
              dataset={dataset}
              isDetailedView={isDetailedView}
              colorCodes={
                isDetailedView
                  ? commonsState.InsightsDetailedPeerViewColorCodes
                  : colorCodes
              }
            />
          )}

          {isDetailedView ? (
            <MultipleColumnChart {...tileProps} axisRange={10} />
          ) : (
            <div style={{ width: "100%" }}>
              <ParentSize>
                {({ width: visWidth, height: visHeight }) => (
                  <BarGraph
                    {...tileProps}
                    width={visWidth}
                    height={visHeight}
                  />
                )}
              </ParentSize>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OwnWorkforce;
