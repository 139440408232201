import { User } from "services/commons.model";
import { BaseModel, errorModel } from "utils/redux.utils";

export type UserManagementState = {
  error?: errorModel;
  loading: boolean;
  results: AdminUsersResult[];
  userListData: User[];
  addSuperAdminSuccess?: boolean;
  apiError?: boolean;
  deleteSuperAdminSuccess?: boolean;
};

export type AdminUsersResult = {
  user: User;
  createdDate: string;
  lastUpdatedDate: string;
};

export class UserManagementModel extends BaseModel {
  constructor() {
    super({
      error: null,
      loading: false,
      results: [],
      userListData: [],
      addSuperAdminSuccess: false,
      apiError: false,
      deleteSuperAdminSuccess: false,
    });
  }

  setLoading = (loading: boolean): UserManagementState =>
    this.merge({ loading });
  setResults = (results: AdminUsersResult[]): UserManagementState =>
    this.merge({ results });
  setUserListData = ({
    userListData,
    currentUserId,
  }: {
    userListData: User[];
    currentUserId: number;
  }): UserManagementState =>
    this.merge({
      userListData: userListData.filter(
        (option: User) => option.userId !== currentUserId
      ),
    });
  setAddSuperAdminSuccess = (
    addSuperAdminSuccess: boolean
  ): UserManagementState => this.merge({ addSuperAdminSuccess });
  setApiError = (apiError: boolean): UserManagementState =>
    this.merge({ apiError });
  setDeleteSuperAdminSuccess = (
    deleteSuperAdminSuccess: boolean
  ): UserManagementState => this.merge({ deleteSuperAdminSuccess });
}
