import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { Fragment, useRef } from "react";
import Parser from "html-react-parser";
import { NEUTRAL } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { showTooltip } from "services/commons.service";

type Props = {
  data: any;
  currentInsightView: number;
  isDetailedView: boolean;
  metricDisplayNames: any[];
  isTableViewActive: boolean;
};
const BLOCK = "risksAndOpportunities";

export const RiskAndOpportunitiesMetric = ({
  metric,
  sectionId,
  globalCompanyId,
  currentInsightView,
}: any) => {
  const metricRef = useRef(null);

  return (
    <div
      className={classNames(
        `${BLOCK}__metric-wrapper`,
        `${BLOCK}__metric-wrapper--${sectionId}`,
        `${BLOCK}__metric-wrapper--${sectionId}--${metric.value?.toLowerCase()}`
      )}
    >
      {sectionId === 0 ? (
        <div ref={metricRef}>
          <Popover
            displayText={metric.metric ?? null}
            metricClassName={classNames(
              `${BLOCK}__metric ${BLOCK}__metric-wrapper-lbl`,
              {
                [`general-neutral-value--color`]:
                  metric.value &&
                  metric.value.toLowerCase() === "neutral" &&
                  ![0].includes(sectionId),
                [`${BLOCK}__metric-wrapper--${metric.value?.toLowerCase()}-lbl`]:
                  sectionId === 1 && globalCompanyId < 0
                    ? false
                    : [0, 4].includes(sectionId),
              }
            )}
            content={metric.tooltip ?? []}
          />
          {metric.value && metric.value.toLowerCase() === "neutral" ? (
            <>
              <div className={`${BLOCK}__metric-wrapper--${sectionId}-neutral`}>
                Neutral
              </div>
            </>
          ) : null}
          {(metric.metric && metric.metric.toLowerCase() === "neutral") ||
          (metric.value && metric.value.toLowerCase() === "neutral") ? (
            <>
              <Tooltip position={TooltipPosition.bottom}>
                <FormattedMessage id="insights.tabular.neutral" />
              </Tooltip>
            </>
          ) : null}
        </div>
      ) : sectionId === 1 && currentInsightView === 4 ? (
        <>
          <span
            className={`${BLOCK}__metric-wrapper--${sectionId}-metric-name`}
          >
            {metric.metric}
          </span>
          <span
            className={`${BLOCK}__metric-wrapper--${sectionId}-metric-value`}
          >
            {metric.value ?? "*"}
          </span>
        </>
      ) : (
        <div
          ref={metricRef}
          className={classNames(`${BLOCK}__metric`, {
            [`general-neutral-value--color`]:
              metric.value && metric.value.toLowerCase() === "neutral",
          })}
        >
          {metric.value ?? "*"}
        </div>
      )}

      {metric.value && metric.value.toLowerCase() === "neutral" ? (
        <>
          <Tooltip position={TooltipPosition.bottom}>
            <FormattedMessage id="insights.tabular.neutral" />
          </Tooltip>
        </>
      ) : null}
      {(sectionId === 1 && globalCompanyId < 0
        ? false
        : [1, 4].includes(sectionId)) &&
      metric.value &&
      metric.value.toLowerCase() === "neutral" ? (
        <>
          <span
            className={classNames(`${BLOCK}__neutral-label`, {
              [`general-neutral-value--color`]:
                metric.value && metric.value.toLowerCase() === "neutral",
            })}
          >
            Neutral
          </span>
          <Tooltip position={TooltipPosition.bottom}>
            <FormattedMessage id="insights.tabular.neutral" />
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

const reportedIdentifiedRisksDetailedSubTile = (
  sectionData: any,
  metricDisplayNames: any
) => {
  return (
    <>
      <span className={`${BLOCK}__section-name-container`}>
        <Popover
          displayText={sectionData.groupName}
          metricClassName={`${BLOCK}__section-name`}
          content={sectionData.groupTooltip}
        />
      </span>
      {sectionData.data.reduce(
        (acc: boolean, curr: any) =>
          acc &&
          curr.metrics.reduce(
            (acc: any, curr: any) => acc && curr.value === null,
            true
          ),
        true
      ) ? (
        <div className={`${BLOCK}__no-data`}>
          <FormattedMessage id="insights.speedometer.no-data" />
        </div>
      ) : (
        <table className={`${BLOCK}__relevant-table-row`}>
          <thead>
            <tr>
              <th></th>
              {metricDisplayNames?.groupValues.map(
                (section: any, index: number) => {
                  return (
                    <th key={`${section}-${index}-${BLOCK}`}>
                      <span className={`${BLOCK}__company-name`}>
                        {section}
                      </span>
                    </th>
                  );
                }
              )}
            </tr>
          </thead>
          <tbody>
            {sectionData.data.map((section: any, index: number) => {
              return (
                <Fragment key={`${section.companyName}--${index}`}>
                  <tr>
                    <td>{section.companyName}</td>
                    {metricDisplayNames?.groupValues.map(
                      (metric: any, indexForMetric: number) => (
                        <td
                          key={`${BLOCK}--${indexForMetric}-${index}-${section.companyName}`}
                          className={`${BLOCK}__relevant-table-row-data`}
                        >
                          <div className={`${BLOCK}__relevant-colorBox`}>
                            {section.metrics.filter(
                              (metricField: any) =>
                                metricField.metric === metric
                            )[0].value ?? "*"}
                          </div>
                        </td>
                      )
                    )}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

const RiskAndOpportunitiesTableTile = ({
  metric,
  sectionId,
  globalCompanyId,
  currentInsightView,
}: any) => {
  const metricRef = useRef(null);

  return (
    <div
      className={classNames(
        `${BLOCK}__table-metric-wrapper`,
        `${BLOCK}__table-metric-wrapper--${sectionId}`,
        `${BLOCK}__table-metric-wrapper--${sectionId}--${metric.value?.toLowerCase()}`
      )}
      data-test={"company-table-view"}
    >
      {sectionId === 0 ? (
        <div ref={metricRef}>
          {metric.value && (
            <Popover
              displayText={metric.value ? metric.metric : null}
              metricClassName={classNames(`${BLOCK}__table-metric-wrapper`, {
                [`general-neutral-value--color`]:
                  metric.value &&
                  metric.value.toLowerCase() === "neutral" &&
                  ![0, 4].includes(sectionId),
              })}
              content={metric.tooltip}
            />
          )}
        </div>
      ) : sectionId === 1 && currentInsightView === 4 ? (
        <>
          <span
            className={`${BLOCK}__metric-wrapper--${sectionId}-table-metric-name`}
          >
            {metric.metric}
          </span>
          <span
            className={`${BLOCK}__metric-wrapper--${sectionId}-table-metric-value`}
          >
            {metric.value ?? "*"}
          </span>
        </>
      ) : (
        <div
          ref={metricRef}
          className={classNames(`${BLOCK}__metric--no-padding`)}
        >
          {metric.value ?? "*"}
        </div>
      )}

      {metric.value && metric.value.toLowerCase() === "neutral" ? (
        <>
          <Tooltip position={TooltipPosition.bottom}>
            <FormattedMessage id="insights.tabular.neutral" />
          </Tooltip>
        </>
      ) : null}
      {(sectionId === 1 && globalCompanyId < 0
        ? false
        : [1].includes(sectionId)) &&
      metric.value &&
      metric.value.toLowerCase() === "neutral" ? (
        <>
          <span
            className={classNames(`${BLOCK}__neutral-label`, {
              [`general-neutral-value--color`]:
                metric.value && metric.value.toLowerCase() === "neutral",
            })}
          >
            Neutral
          </span>
          <Tooltip position={TooltipPosition.bottom}>
            <FormattedMessage id="insights.tabular.neutral" />
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

const RiskAndOpportunitiesTile = ({
  data,
  currentInsightView,
  isDetailedView,
  metricDisplayNames,
  isTableViewActive,
}: Props) => {
  let showNoDataFootnote = false;
  const dispatch = useDispatch();
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("span");
    if (!element) {
      return;
    }
    dispatch(
      showTooltip({
        children: (
          <div className={`risksAndOpportunities-table__tooltip`}>
            {Parser(children)}
          </div>
        ),
        position: TooltipPosition.TileBottomRight,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  const renderDetailedSection = (sectionData: any, sectionId: number) => {
    return (
      <div className={classNames(`${BLOCK}__detailedSection`)}>
        <span className={`${BLOCK}__section-name-container`}>
          <Popover
            displayText={sectionData.groupName}
            metricClassName={`${BLOCK}__detailedSection-section-metric-title`}
            content={sectionData.groupTooltip}
          />
        </span>
        {sectionData.data.reduce(
          (acc: boolean, curr: any) =>
            acc &&
            curr.metrics.reduce(
              (acc: any, curr: any) => acc && curr.value === null,
              true
            ),
          true
        ) ? (
          <div className={`${BLOCK}__no-data`}>
            <FormattedMessage id="insights.speedometer.no-data" />
          </div>
        ) : (
          <div className={`${BLOCK}__detailedSection-section`}>
            {sectionData.data.map((companyData: any, i: number) => {
              const noDataCompany = companyData.metrics.reduce(
                (acc: any, curr: any) => acc && curr.value === null,
                true
              );
              if (!showNoDataFootnote && noDataCompany)
                showNoDataFootnote = true;
              return (
                <div
                  key={`section-data-${i}-${companyData.companyName}`}
                  className={classNames(
                    `${BLOCK}__detailedSection-company`,
                    `${BLOCK}__detailedSection-company-${sectionId}`
                  )}
                >
                  {sectionId === 0 || sectionId === 2 ? (
                    <Fragment key={`section-metric-${i}-${sectionId}`}>
                      <span
                        className={`${BLOCK}__detailedSection-company-name`}
                      >
                        {companyData.companyName}
                      </span>
                      <span
                        className={classNames(
                          `${BLOCK}__detailedSection-company-metrics`,
                          `${BLOCK}__detailedSection-company-metrics-${sectionId}`
                        )}
                      >
                        {companyData.metrics.every(
                          (metric: any) => metric?.value === null
                        )
                          ? "*"
                          : companyData.metrics.map((metric: any) =>
                              metric.value ? (
                                <span
                                  key={`${BLOCK}-${metric.metric}-${sectionId}`}
                                  className={`${BLOCK}__detailedSection-company-metrics-value`}
                                >
                                  {sectionId === 0
                                    ? metric.metric
                                    : metric.value}
                                </span>
                              ) : null
                            )}
                      </span>
                    </Fragment>
                  ) : null}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderSection = (sectionData: any, sectionId: number) => {
    return (
      <div
        className={classNames(`${BLOCK}__section`, {
          [`${BLOCK}__section--table`]: isTableViewActive,
        })}
      >
        <span className={`${BLOCK}__section-name-container`}>
          <Popover
            displayText={sectionData.groupName}
            metricClassName={`${BLOCK}__section-name`}
            content={sectionData.groupTooltip}
          />
        </span>
        {sectionData.data.reduce(
          (acc: boolean, curr: any) =>
            acc &&
            curr.metrics.reduce(
              (acc: any, curr: any) => acc && curr.value === null,
              true
            ),
          true
        ) ? (
          <div className={`${BLOCK}__no-data`}>
            <FormattedMessage id="insights.speedometer.no-data" />
          </div>
        ) : (
          sectionData.data.map((companyData: any, i: number) => {
            const noDataCompany = companyData.metrics.reduce(
              (acc: any, curr: any) => acc && curr.value === null,
              true
            );
            if (
              (sectionId !== 0 &&
                companyData.metrics.some(
                  (metric: any) => metric.value === null
                )) ||
              (sectionId === 0 &&
                companyData.metrics.every(
                  (metric: any) => metric.value === null
                ))
            ) {
              showNoDataFootnote = true;
            }

            if (!showNoDataFootnote && noDataCompany) showNoDataFootnote = true;
            const metrics = companyData.metrics;
            return (
              <div
                key={`section-data-${i}-${companyData.companyName}`}
                className={classNames(`${BLOCK}__company-row`, {
                  [`${BLOCK}__company-row-table`]: isTableViewActive,
                })}
              >
                {currentInsightView !== 4 && sectionId !== 1 ? (
                  <span className={`${BLOCK}__company-name`}>
                    {companyData.companyName}{" "}
                    {noDataCompany ? (
                      <div className={`${BLOCK}__no-data`}>
                        <FormattedMessage id="insights.speedometer.no-data" />
                      </div>
                    ) : null}
                  </span>
                ) : null}

                {noDataCompany ? null : !isTableViewActive ? (
                  <div
                    className={classNames(
                      `${BLOCK}__section-data ${BLOCK}__section-data--${sectionId}`
                    )}
                  >
                    {metrics.map((metric: any, index: number) => (
                      <Fragment
                        key={`section-metric-${i}-${index}-${companyData.companyName}`}
                      >
                        <RiskAndOpportunitiesMetric
                          metric={metric}
                          sectionId={sectionId}
                          isBaseCompany={i === 0}
                          globalCompanyId={companyData.globalCompanyId}
                          currentInsightView={currentInsightView}
                          isTableViewActive={isTableViewActive}
                        />
                      </Fragment>
                    ))}
                  </div>
                ) : (
                  <div
                    className={classNames(
                      `${BLOCK}__table-section-data ${BLOCK}__table-section-data--${sectionId}`
                    )}
                  >
                    {metrics.map((metric: any, index: number) => (
                      <Fragment
                        key={`section-metric-${i}-${index}-${companyData.companyName}`}
                      >
                        <RiskAndOpportunitiesTableTile
                          metric={metric}
                          sectionId={sectionId}
                          globalCompanyId={companyData.globalCompanyId}
                          currentInsightView={currentInsightView}
                        />
                      </Fragment>
                    ))}
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    );
  };

  const reportedIdentifiedRisksSubTile = (
    sectionData: any,
    sectionId: number,
    metricDisplayNames: any
  ) => {
    return (
      <>
        <span className={`${BLOCK}__section-name-container`}>
          <Popover
            displayText={sectionData.groupName}
            metricClassName={`${BLOCK}__section-name`}
            content={sectionData.groupTooltip}
          />
        </span>
        {sectionData.data.reduce(
          (acc: boolean, curr: any) =>
            acc &&
            curr.metrics.reduce(
              (acc: any, curr: any) => acc && curr.value === null,
              true
            ),
          true
        ) ? (
          <div className={`${BLOCK}__no-data`}>
            <FormattedMessage id="insights.speedometer.no-data" />
          </div>
        ) : (
          <table className={`${BLOCK}__relevant-table-row`}>
            <thead>
              <tr>
                <th></th>
                {sectionData.data.map((section: any, index: number) => (
                  <th key={`${section}---${index}`}>
                    <span className={`${BLOCK}__company-name`}>
                      {section.companyName}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {metricDisplayNames?.groupValues.map(
                (metric: any, indexForMetric: number) => (
                  <tr key={`${metric}-${indexForMetric}`}>
                    <td>{metric}</td>
                    {sectionData.data.map((section: any, index: number) => {
                      let value = section.metrics.filter(
                        (metricField: any) => metricField.metric === metric
                      )[0].value;

                      return (
                        <td
                          key={`${BLOCK}-risks-${index}`}
                          className={`${BLOCK}__relevant-table-row-data`}
                        >
                          <span
                            className={`${BLOCK}__relevant-colorBox`}
                            onMouseEnter={(e) =>
                              value &&
                              value?.toLowerCase() === "neutral" &&
                              handleMouseEnter(e, NEUTRAL)
                            }
                            onMouseLeave={handleMouseLeave}
                          >
                            {value ?? "*"}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                )
              )}
            </tbody>
          </table>
        )}
      </>
    );
  };

  let NoDataAvailable = data.every((companyData: any) =>
    companyData?.data?.every((metricData: any) =>
      metricData?.metrics?.every((metric: any) => metric.value === null)
    )
  );

  return (
    <div className={BLOCK} data-test={`${BLOCK}`} data-testid={`${BLOCK}`}>
      {!NoDataAvailable &&
        !isDetailedView &&
        data.map((d: any, i: number) => (
          <Fragment key={`section-data-${i}`}>
            {i !== 1
              ? renderSection(d, i)
              : currentInsightView === 4
              ? renderSection(d, i)
              : reportedIdentifiedRisksSubTile(d, i, metricDisplayNames[i])}
          </Fragment>
        ))}
      {!NoDataAvailable &&
        isDetailedView &&
        data.map((d: any, i: number) => (
          <Fragment key={`section-data-${i}`}>
            {i !== 1
              ? renderDetailedSection(d, i)
              : reportedIdentifiedRisksDetailedSubTile(
                  d,
                  metricDisplayNames[i]
                )}
          </Fragment>
        ))}
      {showNoDataFootnote ? (
        <span className={`${BLOCK}__no-data-footnote`}>
          <FormattedMessage id="no.data.available" />
        </span>
      ) : null}

      {NoDataAvailable ? (
        <div className={`${BLOCK}__no-data`}>
          <FormattedMessage id="insights.speedometer.no-data" />
        </div>
      ) : null}
    </div>
  );
};

export default RiskAndOpportunitiesTile;
