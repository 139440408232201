import classNames from "classnames";
import TileEmptyContent from "components/insight-tile/tile-empty-content";
import { ENTITY_DEEMED_VALUE } from "components/insight-tile/tiles/TargetsTile";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";
import Popover from "components/shared/popover/popover";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import { InsightData } from "services/insights/insights.model";
import { RootStore } from "services/store.service";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";
import { sanitize } from "dompurify";
import Parser from "html-react-parser";

export type TargetMetric = {
  metricKey: string;
  metricName: string;
  metricValue: string;
  metricType: number;
  metricTooltip?: AssociatedMetricTooltips[];
};

export type TargetMetricGroup = {
  groupName: string;
  metrics: TargetMetric[];
};

export type TargetData = {
  companyData: InsightData;
  targetGroups: TargetMetricGroup[];
};

type props = {
  isDetailedView: boolean;
  currentInsightView: number;
  isTableViewActive: boolean;
  data: TargetData[];
};
export const SCOPE_OF_TARGET = "Scope of Target";
const TargetsTileCharts = ({
  isDetailedView,
  currentInsightView,
  isTableViewActive,
  data,
}: props) => {
  const BLOCK = "targetsTileChart";
  const [hasEmptyValues, setHasEmptyValues] = useState<boolean>(false);

  const dispatch = useDispatch();
  const mainTooltip = useSelector(
    (store: RootStore) => store.commons.mainTooltip
  );
  useEffect(() => {
    setHasEmptyValues(false);
  }, [isDetailedView, isTableViewActive]);

  if (
    data.length === 0 ||
    (data.length > 0 &&
      data.every((tmpData: TargetData) => tmpData.targetGroups.length === 0))
  )
    return (
      <div>
        <TileEmptyContent />
      </div>
    );

  const getMetricSingleView = (
    metric: TargetMetric,
    index: number,
    targetIndex: number
  ) => {
    if (!hasEmptyValues && metric.metricValue === "*") setHasEmptyValues(true);
    if (metric.metricType === 1) {
      return (
        <div
          key={`${index}_t`}
          className={`${BLOCK}__single-metric-container ${BLOCK}__single-metric--transparent`}
        >
          <span className={`${BLOCK}__metric-container-popover`}>
            <Popover
              metricClassName={`${BLOCK}__single-metric-lbl`}
              content={metric.metricTooltip ?? []}
              displayText={metric.metricName}
            />
          </span>
          {metric.metricName === SCOPE_OF_TARGET ? (
            <span style={{ position: "relative", textAlign: "center" }}>
              <span
                className={classNames(`${BLOCK}__single-metric-value`, {
                  [`${BLOCK}__scope-metric`]:
                    metric.metricName === SCOPE_OF_TARGET,
                })}
                onMouseEnter={(e) => {
                  if (
                    metric.metricName === SCOPE_OF_TARGET &&
                    (e.currentTarget as HTMLSpanElement).offsetHeight + 1 <
                      (e.currentTarget as HTMLSpanElement).scrollHeight
                  ) {
                    handleMouseEnter(
                      e,
                      "span",
                      <div>{Parser(sanitize(metric.metricValue ?? ""))}</div>,
                      "",
                      dispatch,
                      null,
                      MainTooltipPosition.BottomMiddle
                    );
                  }
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(
                    e,
                    dispatch,
                    mainTooltip.isOverTooltip
                      ? mainTooltip.isOverTooltip
                      : false
                  );
                }}
              >
                {metric.metricValue}
              </span>
            </span>
          ) : (
            <span className={`${BLOCK}__single-metric-value`}>
              {metric.metricValue}
            </span>
          )}
        </div>
      );
    } else {
      return getWhiteBox(metric, index, false, targetIndex);
    }
  };

  const getWhiteBox = (
    metric: TargetMetric,
    index: number,
    multiple: boolean = false,
    targetIndex: number
  ) => {
    if (!hasEmptyValues && metric.metricValue === "*") setHasEmptyValues(true);
    return (
      <div
        key={`${index}_white`}
        className={classNames(
          `${BLOCK}__single-metric-container ${BLOCK}__single-metric--white`,
          {
            [`${BLOCK}__single-metric--flex`]: multiple,
          }
        )}
      >
        <span
          className={classNames({
            [`${BLOCK}__single-metric-wrapper`]: multiple && index !== 0,
          })}
        >
          <Popover
            metricClassName={`${BLOCK}__single-metric-lbl`}
            content={metric.metricTooltip ?? []}
            displayText={metric.metricName}
          />
        </span>
        <span style={{ position: "relative", textAlign: "center" }}>
          <span
            className={classNames({
              [`${BLOCK}__single-metric-year`]:
                (!multiple &&
                  metric.metricValue !== "*" &&
                  metric.metricValue !== ENTITY_DEEMED_VALUE) ||
                (multiple && index > 2 && metric.metricValue !== "*"),
              [`${BLOCK}__single-metric-value`]:
                (multiple && index <= 2) ||
                metric.metricValue === "*" ||
                metric.metricValue === ENTITY_DEEMED_VALUE,
              [`${BLOCK}__single-metric-year-string`]:
                metric.metricValue === ENTITY_DEEMED_VALUE,
              [`${BLOCK}__scope-metric`]: metric.metricName === SCOPE_OF_TARGET,
              [`${BLOCK}__single-metric-value--scope-max`]:
                metric.metricName === SCOPE_OF_TARGET,
            })}
            onMouseEnter={(e) => {
              if (
                metric.metricName === SCOPE_OF_TARGET &&
                (e.currentTarget as HTMLSpanElement).offsetHeight + 1 <
                  (e.currentTarget as HTMLSpanElement).scrollHeight
              ) {
                handleMouseEnter(
                  e,
                  "span",
                  <div>{Parser(sanitize(metric.metricValue ?? ""))}</div>,
                  "",
                  dispatch,
                  null,
                  MainTooltipPosition.BottomMiddle
                );
              }
            }}
            onMouseLeave={(e) => {
              handleMouseLeave(
                e,
                dispatch,
                mainTooltip.isOverTooltip ? mainTooltip.isOverTooltip : false
              );
            }}
          >
            {metric.metricValue}
          </span>
        </span>
      </div>
    );
  };

  const getGroupedMetricsSingleView = (
    tg: TargetMetricGroup,
    targetIndex: number
  ) => {
    return (
      <div className={`${BLOCK}__single-block`}>
        {tg.metrics.length > 0 &&
          tg.metrics.map((m: TargetMetric, i: number) =>
            getMetricSingleView(m, i, targetIndex)
          )}
      </div>
    );
  };

  const getTargetGroup = (targetGroup: TargetMetricGroup, index: number) => {
    return (
      <div key={`${index}__t-group`} className={`${BLOCK}__multiple-block`}>
        <span className={`${BLOCK}__multiple-subtitle`}>
          {targetGroup.groupName}
        </span>
        <div className={`${BLOCK}__multiple-row`}>
          {targetGroup.metrics &&
            targetGroup.metrics.map(
              (m: TargetMetric, i: number) =>
                i > 0 && getWhiteBox(m, i, true, index)
            )}
        </div>
      </div>
    );
  };

  const getMultipleView = (tg: TargetMetricGroup[]) => {
    return (
      <div className={`${BLOCK}__multiple-container`}>
        <div className={`${BLOCK}__multiple-row `}>
          {tg.length > 0 &&
            tg[0].metrics.length > 0 &&
            getWhiteBox(tg[0].metrics[0], 0, true, 0)}
        </div>
        {tg.map((g: TargetMetricGroup, ind: number) => getTargetGroup(g, ind))}
      </div>
    );
  };

  const getWhiteBoxPeer = (
    tg: {
      subheader: string;
      metricTooltip: any;
      companies: {
        companyName: string;
        metricValue: string;
        isVertical: boolean;
        metricType: number;
      }[];
    },
    i: number,
    isFirstMetric: boolean = false,
    isSingleTarget: boolean = false
  ) => {
    return (
      <div
        key={`${i}__wbp`}
        className={classNames(`${BLOCK}__multiple-peer-metric-box`, {
          [`${BLOCK}__multiple-peer-metric-box--100`]:
            isDetailedView && isFirstMetric,
          [`${BLOCK}__multiple-peer-metric-box--third`]: isSingleTarget,
          [`${BLOCK}__multiple-peer-metric-box--third-detailed`]:
            isSingleTarget && isDetailedView,
        })}
      >
        <span className={`${BLOCK}__metric-container-popover`}>
          <Popover
            metricClassName={`${BLOCK}__multiple-peer-metric-subtitle`}
            content={tg.metricTooltip ?? []}
            displayText={tg.subheader}
          />
        </span>

        {isDetailedView && isFirstMetric ? (
          <div className={`${BLOCK}__multiple-detailed-metric-box-wrapper`}>
            <div className={`${BLOCK}__multiple-detailed-metric-box`}>
              {tg.companies.slice(0, Math.round(tg.companies.length / 2)).map(
                (
                  c: {
                    companyName: string;
                    metricValue: string;
                    isVertical: boolean;
                    metricType: number;
                  },
                  index: number
                ) => getMetricPeer(c, index + i)
              )}
            </div>
            <div className={`${BLOCK}__multiple-detailed-metric-box`}>
              {tg.companies.slice(Math.round(tg.companies.length / 2)).map(
                (
                  c: {
                    companyName: string;
                    metricValue: string;
                    isVertical: boolean;
                    metricType: number;
                  },
                  index: number
                ) => getMetricPeer(c, index + i)
              )}
            </div>
          </div>
        ) : (
          tg.companies.map(
            (
              c: {
                companyName: string;
                metricValue: string;
                isVertical: boolean;
                metricType: number;
              },
              index: number
            ) => getMetricPeer(c, index + i, tg.subheader === SCOPE_OF_TARGET)
          )
        )}
      </div>
    );
  };

  const getTableWhiteBox = (
    target: {
      targetName: string;
      headers: string[];
      headersTooltips?: any;
      rows: {
        metricName: string;
        metricTooltip?: any;
        metricValues: string[];
      }[];
    },
    ind: number,
    isSingleTarget: boolean = false
  ) => {
    return (
      <div
        key={`table-white-box-${ind}`}
        className={classNames(`${BLOCK}__multiple-peer-metric-box`, {
          [`${BLOCK}__multiple-peer-metric-box--100`]: isDetailedView,
          [`${BLOCK}__multiple-peer-metric-box--third`]: isSingleTarget,
          [`${BLOCK}__multiple-peer-metric-box--third-detailed`]:
            isSingleTarget && isDetailedView,
        })}
      >
        <span className={`${BLOCK}__metric-container-popover`}>
          <Popover
            metricClassName={`${BLOCK}__multiple-peer-metric-subtitle`}
            content={isSingleTarget ? [] : []}
            displayText={target.targetName}
          />
        </span>
        <table
          className={classNames(`${BLOCK}__table `, {
            [`${BLOCK}__table--detailed`]: isDetailedView,
          })}
        >
          <thead>
            <tr>
              <th></th>
              {target.headers.map((h: string, headerIndex: number) => (
                <th key={`headers-${headerIndex}`}>
                  <span className={`${BLOCK}__metric-container-popover`}>
                    <Popover
                      metricClassName={`${BLOCK}__metric-name-popover--left`}
                      content={
                        !isSingleTarget &&
                        target.headersTooltips &&
                        target.headersTooltips.length > 0
                          ? target.headersTooltips[headerIndex]
                          : []
                      }
                      displayText={h}
                    />
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {target.rows?.map(
              (
                tr: {
                  metricName: string;
                  metricValues: string[];
                  metricTooltip?: any;
                },
                index: number
              ) => (
                <tr key={`rows-${index}`}>
                  <td>
                    <span className={`${BLOCK}__metric-container-popover`}>
                      <Popover
                        metricClassName={`${BLOCK}__metric-name-popover--left`}
                        content={tr.metricTooltip ?? []}
                        displayText={tr.metricName}
                      />
                    </span>
                  </td>
                  {tr.metricValues?.map((mv: string, ind: number) => {
                    if (!hasEmptyValues && mv === "*") setHasEmptyValues(true);
                    return (
                      <td key={`td-${ind}`}>
                        <span
                          className={classNames({
                            [`${BLOCK}__scope-metric`]:
                              tr.metricName === SCOPE_OF_TARGET ||
                              target.headers[ind] === SCOPE_OF_TARGET,
                            [`${BLOCK}__td-scope-max`]:
                              tr.metricName === SCOPE_OF_TARGET ||
                              target.headers[ind] === SCOPE_OF_TARGET,
                          })}
                          onMouseEnter={(e) => {
                            if (
                              (tr.metricName === SCOPE_OF_TARGET ||
                                target.headers[ind] === SCOPE_OF_TARGET) &&
                              (e.currentTarget as HTMLSpanElement)
                                .offsetHeight +
                                1 <
                                (e.currentTarget as HTMLSpanElement)
                                  .scrollHeight
                            ) {
                              handleMouseEnter(
                                e,
                                "span",
                                <div>{Parser(sanitize(mv ?? ""))}</div>,
                                "",
                                dispatch,
                                null,
                                MainTooltipPosition.BottomMiddle
                              );
                            }
                          }}
                          onMouseLeave={(e) => {
                            handleMouseLeave(
                              e,
                              dispatch,
                              mainTooltip.isOverTooltip
                                ? mainTooltip.isOverTooltip
                                : false
                            );
                          }}
                        >
                          {mv}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const getMetricPeer = (
    group: {
      companyName: string;
      metricValue: string;
      isVertical: boolean;
      metricType: number;
    },
    i: number,
    isScopeOfTargetMetric: boolean = false
  ) => {
    if (!hasEmptyValues && group.metricValue === "*") setHasEmptyValues(true);
    return (
      <div
        key={`metric-${i}`}
        className={classNames(`${BLOCK}__multiple-peer-group`, {
          [`${BLOCK}__multiple-peer-group--column`]: group.isVertical,
          [`${BLOCK}__multiple-detailed-group`]: isDetailedView,
        })}
      >
        <span
          className={`${BLOCK}__multiple-peer-text ${BLOCK}__multiple-peer-text--47`}
        >
          {group.companyName}
        </span>
        <div
          className={`${BLOCK}__multiple-peer-text--53`}
          style={{ position: "relative" }}
        >
          <span
            className={classNames(
              `${BLOCK}__multiple-peer-text ${BLOCK}__multiple-peer-text--bold `,
              {
                [`${BLOCK}__multiple-peer-text--14`]: group.isVertical,
                [`${BLOCK}__multiple-peer-text--fz-big`]:
                  group.metricType === 2 &&
                  group.metricValue !== "*" &&
                  group.metricValue !== ENTITY_DEEMED_VALUE &&
                  !group.isVertical,
                [`${BLOCK}__multiple-peer-text--align-right`]:
                  group.metricType === 2 && !group.isVertical,
                [`${BLOCK}__multiple-peer-text--detailed`]: isDetailedView,
                [`${BLOCK}__scope-metric`]: isScopeOfTargetMetric,
              }
            )}
            onMouseEnter={(e) => {
              if (
                isScopeOfTargetMetric &&
                (e.currentTarget as HTMLSpanElement).offsetHeight + 1 <
                  (e.currentTarget as HTMLSpanElement).scrollHeight
              ) {
                handleMouseEnter(
                  e,
                  "span",
                  <div>{Parser(sanitize(group.metricValue ?? ""))}</div>,
                  "",
                  dispatch,
                  null,
                  MainTooltipPosition.BottomMiddle
                );
              }
            }}
            onMouseLeave={(e) => {
              handleMouseLeave(
                e,
                dispatch,
                mainTooltip.isOverTooltip ? mainTooltip.isOverTooltip : false
              );
            }}
          >
            {group.metricValue}
          </span>
        </div>
      </div>
    );
  };

  const getMultiplePeerView = () => {
    return (
      <div
        className={classNames(`${BLOCK}__multiple-peer-container`, {
          [`${BLOCK}__multiple-peer-container--single-detailed`]:
            isDetailedView,
        })}
      >
        {getPeerView()}
      </div>
    );
  };

  const getPeerView = () => {
    let basecompany = data.filter(
      (td: TargetData) => td.companyData.isBaseCompany
    );
    basecompany = basecompany.length === 0 ? [data[0]] : basecompany;
    
    if (basecompany.length > 0 && basecompany[0].targetGroups.length > 1) {
      if (isDetailedView) {
        let firstMetric = basecompany[0].targetGroups[0].metrics[0];
        let metricBlockData = {
          subheader: firstMetric.metricName,
          metricTooltip: firstMetric.metricTooltip,
          companies: data.map((td: TargetData, ind: number) => {
            return {
              companyName: td.companyData.companyName,
              metricValue:
                td.targetGroups.length > 0
                  ? td.targetGroups[0].metrics.filter(
                      (tm: TargetMetric) =>
                        tm.metricKey === firstMetric.metricKey
                    )[0].metricValue ?? "*"
                  : "*",
              isVertical: false,
              metricType:
                td.targetGroups.length > 0
                  ? td.targetGroups[0].metrics.filter(
                      (tm: TargetMetric) =>
                        tm.metricKey === firstMetric.metricKey
                    )[0].metricType ?? 1
                  : 1,
            };
          }),
        };

        return (
          <>
            {data.length > 0 &&
              getWhiteBoxPeer(
                metricBlockData,
                0,
                true,
                basecompany[0].targetGroups.length === 1
              )}

            {basecompany[0].targetGroups.map(
              (tg: TargetMetricGroup, index: number) => {
                let peerChartTableData = {
                  targetName: tg.groupName,
                  headers: tg.metrics
                    .slice(1)
                    .map((m: TargetMetric) => m.metricName),
                  headersTooltips: tg.metrics
                    .slice(1)
                    .map((m: TargetMetric) => m.metricTooltip),
                  rows:
                    tg.metrics.length > 0
                      ? data.map((dat: TargetData) => {
                          return {
                            metricName: dat.companyData.companyName,
                            metricValues: dat.targetGroups
                              .filter(
                                (dtg: TargetMetricGroup) =>
                                  dtg.groupName === tg.groupName
                              )[0]
                              ?.metrics?.slice(1)
                              .map((met: TargetMetric) => met.metricValue),
                          };
                        })
                      : [],
                };

                return getTableWhiteBox(
                  peerChartTableData,
                  index,
                  basecompany[0].targetGroups.length === 1
                );
              }
            )}
          </>
        );
      } else {
        return (
          <>
            {data.length > 0 &&
              data.map((td: TargetData, i: number) => {
                let firstMetric = td.targetGroups[0].metrics[0];
                let metricBlockData = {
                  subheader: firstMetric.metricName,
                  metricTooltip: firstMetric.metricTooltip ?? [],
                  companies: [
                    {
                      companyName: td.companyData.companyName,
                      metricValue: firstMetric.metricValue,
                      metricType: firstMetric.metricType ?? 1,
                      isVertical: true,
                    },
                  ],
                };
                return getWhiteBoxPeer(metricBlockData, i);
              })}

            {basecompany[0].targetGroups.map(
              (tg: TargetMetricGroup, index: number) => {
                let peerChartTableData = {
                  targetName: tg.groupName,
                  headers: data.map(
                    (td: TargetData) => td.companyData.companyName
                  ),
                  rows:
                    tg.metrics.length > 0
                      ? tg.metrics.slice(1).map((tm: TargetMetric) => ({
                          metricName: tm.metricName,
                          metricTooltip: tm.metricTooltip,
                          metricValues: data.map((td: TargetData) => {
                            let tmpGroup = td.targetGroups.filter(
                              (tmg: TargetMetricGroup) =>
                                tmg.groupName === tg.groupName
                            );
                            return tmpGroup.length > 0 &&
                              tmpGroup[0].metrics.filter(
                                (targetM: TargetMetric) =>
                                  targetM.metricKey === tm.metricKey
                              ).length > 0
                              ? tmpGroup[0].metrics.filter(
                                  (targetM: TargetMetric) =>
                                    targetM.metricKey === tm.metricKey
                                )[0].metricValue
                              : "*";
                          }),
                        }))
                      : [],
                };

                return getTableWhiteBox(peerChartTableData, index);
              }
            )}
          </>
        );
      }
    } else if (
      basecompany.length > 0 &&
      basecompany[0].targetGroups.length === 1
    ) {
      //single

      return basecompany[0].targetGroups[0].metrics.map(
        (m: TargetMetric, indx: number) => {
          let metricBlockData = {
            subheader: m.metricName,
            metricTooltip: m.metricTooltip ?? [],
            companies: data.map((td: TargetData, ind: number) => {
              return {
                companyName: td.companyData.companyName,
                metricValue:
                  td.targetGroups.length > 0
                    ? td.targetGroups[0].metrics.filter(
                        (tm: TargetMetric) => tm.metricKey === m.metricKey
                      )[0].metricValue ?? "*"
                    : "*",
                isVertical: false,
                metricType:
                  td.targetGroups.length > 0
                    ? td.targetGroups[0].metrics.filter(
                        (tm: TargetMetric) => tm.metricKey === m.metricKey
                      )[0].metricType ?? 1
                    : 1,
              };
            }),
          };
          return getWhiteBoxPeer(metricBlockData, indx, false, true);
        }
      );
    }
  };

  return (
    <div className={`${BLOCK}`}>
      {currentInsightView === INSIGHT_BENCHMARK_TYPE.COMPANY
        ? data[0].targetGroups.length === 0
          ? null
          : data[0].targetGroups.length > 1
          ? getMultipleView(data[0].targetGroups)
          : getGroupedMetricsSingleView(data[0].targetGroups[0], 0)
        : null}

      {currentInsightView !== INSIGHT_BENCHMARK_TYPE.COMPANY &&
        getMultiplePeerView()}
      {hasEmptyValues && (
        <div className={`${BLOCK}__no-data-available`}>
          <FormattedMessage id="no.data.available" />
        </div>
      )}
    </div>
  );
};

export default TargetsTileCharts;
