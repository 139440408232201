import React, { useState, useEffect, useRef } from "react";
import Modal from "../shared/modal/modal";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { useSelector, useDispatch } from "react-redux";
import {
  searchRenameError,
  searchRenameSearchSuccess,
  saveNewSearch,
  resetSearchToSave,
} from "services/searchlist/searchlist.service";
import classNames from "classnames";
import { addToastMessage } from "services/commons.service";
import Button from "components/shared/button/button";
type props = {
  setShowModal: (value: boolean) => void;
  saveSearch: (name: string, description?: string) => void;
};

const SaveSearch = ({ setShowModal, saveSearch }: props) => {
  const BLOCK = "RenameShortlist";
  const [counts, setCounts] = useState({ name: 0, description: 0 });
  const [disabled, setDisabled] = useState(true);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const [values, setValues] = useState({ name: "", description: "" });
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(false);
  const searchlist = useSelector((state: RootStore) => state.searchlist);
  useEffect(() => {
    nameInputRef.current!.focus();
  }, []);

  useEffect(() => {
    if (searchlist.errorRename) {
      setErrorMessage(true);
      nameInputRef.current!.focus();
      if (searchlist.searchToSave) {
        setValues({
          ...values,
          name: searchlist.searchToSave.name,
          description: searchlist.searchToSave.description,
        });

        setCounts({
          ...counts,
          name: searchlist.searchToSave.name.length,
          description: searchlist.searchToSave.description.length,
        });
      }
      dispatch(searchRenameError(false));
      dispatch(resetSearchToSave());
      dispatch(saveNewSearch(true));
      dispatch(searchRenameSearchSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchlist.errorRename, dispatch]);

  useEffect(() => {
    if (searchlist.renameSearchSuccess) {
      dispatch(saveNewSearch(false));
      setErrorMessage(false);
      setShowModal(false);
      dispatch(searchRenameSearchSuccess(false));

      dispatch(
        addToastMessage({
          description: <FormattedMessage id="save.search-query.success" />,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchlist.renameSearchSuccess, dispatch, setShowModal]);

  const onChangeHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCounts({ ...counts, [event.target.name]: event.target.value.length });
    setValues({ ...values, [event.target.name]: event.target.value });
    if (event.target.name === "name") {
      setDisabled(event.target.value.trim().length === 0);
    }
    setErrorMessage(false);
  };
  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      saveSearchHandler();
    }
  };

  const saveSearchHandler = () => {
    if (nameInputRef.current!.value.trim().length === 0) {
      return;
    }
    saveSearch(nameInputRef.current!.value, descriptionInputRef.current!.value);
  };
  return (
    <Modal
      show={true}
      header="searchlist.save.title"
      handleClose={() => {
        setShowModal(false);
      }}
      config={false}
    >
      <div className={`${BLOCK} ${BLOCK}--saveSearchlist`} data-testid={`${BLOCK}--saveSearchlist`}>
        <span
          className={classNames(`${BLOCK}__name--saveSearchList`, {
            [`${BLOCK}__name`]: !errorMessage,
            [`${BLOCK}__nameError`]: errorMessage,
          })}
        >
          <FormattedMessage id="searchlist.save.name" />
        </span>
        <span
          className={classNames({
            [`${BLOCK}__input`]: !errorMessage,
            [`${BLOCK}__inputError`]: errorMessage,
            [`${BLOCK}__input--saveSearchList`]: !errorMessage,
            [`${BLOCK}__inputError--saveSearchList`]: errorMessage,
          })}
        >
          <input
            type="text"
            ref={nameInputRef}
            maxLength={200}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={values.name}
            name="name"
            data-testid="nameInput"
            placeholder="Enter Search name"
            className={classNames({
              [`${BLOCK}__input--saveSearchList`]: !errorMessage,
              [`${BLOCK}__inputError--saveSearchList`]: errorMessage,
            })}
          />
        </span>

        {errorMessage ? (
          <span
            className={`${BLOCK}__contentError ${BLOCK}__contentError--saveSearchList`}
            data-testid="saveSearch-ErrorMessage"
          >
            <FormattedMessage id="searchlist.report.error" />
          </span>
        ) : (
          <span
            className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}
          >
            {counts.name}
            <FormattedMessage id="shortlist.save.input.character" />
          </span>
        )}

        <span
          className={classNames(
            `${BLOCK}__name--saveSearchList ${BLOCK}__name ${BLOCK}__description-label`
          )}
        >
          <FormattedMessage id="searchlist.save.description" />
        </span>
        <span
          className={classNames(
            `${BLOCK}__input ${BLOCK}__input--saveSearchList`
          )}
        >
          <textarea
            ref={descriptionInputRef}
            maxLength={2000}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={values.description}
            titdata-testidle="descriptionTextArea"
            placeholder="Enter description"
            className={classNames(`${BLOCK}__description`)}
            name="description"
          />
        </span>
        <span className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}>
          {counts.description}
          <FormattedMessage id="searchlist.save.description.character" />
        </span>
        <div className={`${BLOCK}__options ${BLOCK}__options--saveSearchList`}>
          <Button
            className={`button-secondary`}
            onClick={() => setShowModal(false)}
            formattedText={`shortlist.save.button.cancel`}
            dataTest="cancel-btn"
          />
          <Button
            className={`button-primary`}
            dataTest="save-new-search-btn"
            disabled={disabled}
            formattedText={`shortlist.save.button.save`}
            onClick={saveSearchHandler}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SaveSearch;
