import classNames from "classnames";
import Icon from "components/shared/icon/icon";
import { useDispatch } from "react-redux";
import { showTooltip } from "services/commons.service";
import FormattedMessage from "../formatted-message/formatted-message";
import { TooltipPosition } from "../tooltip/tooltip";
type Props = {
  label?: any;
  value: boolean;
  onChange: any;
  indeterminate?: boolean;
  item?: any;
  disabled?: boolean;
  type?: string;
  radio?: boolean;
  tooltip?: string | object;
};

const Checkbox = ({
  label,
  value,
  onChange,
  indeterminate,
  item,
  disabled,
  type,
  radio,
  tooltip,
}: Props) => {
  const BLOCK = "checkbox";
  const dispatch = useDispatch();

  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("span");
    /* istanbul ignore if */
    if (!element) {
      return;
    }

    dispatch(
      showTooltip({
        children: (
          <div>
            <FormattedMessage id={children} />
          </div>
        ),
        position: TooltipPosition.TileTopLeft,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  return (
    <>
      <input
        className={BLOCK}
        type={radio ? "radio" : "checkbox"}
        checked={value}
        onChange={
          !item
            ? onChange
            : () =>
                onChange(
                  type === "insights"
                    ? item.insightsCategoryId
                    : item.fieldId
                    ? item.fieldId
                    : type === "export"
                    ? item
                    : item.fieldGroupId,
                  type === "insights"
                    ? "insights"
                    : item.fieldId
                    ? "field"
                    : type === "export"
                    ? type
                    : "fieldGroup"
                )
        }
        disabled={disabled}
        data-test="checkbox-item"
        data-testid="checkbox-item"
      />

      {indeterminate && (
        <div className={`${BLOCK}__indeterminate`}>
          <Icon name="minus" height={15} width={12} />
        </div>
      )}
      <span
        className={classNames(`${BLOCK}__label`, {
          [`${BLOCK}__label-disabled`]: type === "export" || disabled,
        })}
        onMouseEnter={(e) => {
          if (tooltip && tooltip !== "") handleMouseEnter(e, tooltip);
        }}
        onMouseLeave={(e) => {
          handleMouseLeave(e);
        }}
        onClick={
          !item
            ? onChange
            : !disabled
            ? () =>
                onChange(
                  type === "insights"
                    ? item.insightsCategoryId
                    : item.fieldId
                    ? item.fieldId
                    : type === "export"
                    ? item
                    : item.fieldGroupId,
                  type === "insights"
                    ? "insights"
                    : item.fieldId
                    ? "field"
                    : type === "export"
                    ? type
                    : "fieldGroup"
                )
            : () => {}
        }
      >
        {type === "insights" || type === "export"
          ? label
            ? label
            : item.insightsCategoryName
          : !item
          ? label
          : item.displayName
          ? item.displayName
          : item.fieldGroupDisplayName}
      </span>
    </>
  );
};

export default Checkbox;
