import { PublishedSearchPayload } from "services/published-searchlist/published-searchlist.model";
import api from "../../services/api.service";
import { Search } from "./searchlist.model";

export const saveSearchlist = (payload: PublishedSearchPayload) => {
  return api.put(`/search`, payload).then(({ data }) => data);
};

export const deleteSavedSearch = (payload: Search) => {
  return api
    .delete(`/search?savedSearchId=${payload.savedSearchId}`)
    .then(({ data }) => data);
};

export const shareSearchlist = (payload: Search) => {
  return api
    .post(`/search/${payload.savedSearchId}/share`, payload)
    .then(({ data }) => data);
};

export const getUsersList = (payload: any) => {
  return api.post(`/users/search`, payload).then(({ data }) => data);
};

// Get shared search count
export const getCount = () => {
  return api.get(`/search/shared/newCount`).then(({ data }) => data);
};

export const fetchSavedSearchList = (payload: any) =>
  api.post(`/search/list`, payload).then(({ data }) => data);

export const executeSavedSearch = (savedSearchId: any) =>
  api.post(`/search/${savedSearchId}/execute `).then(({ data }) => data);

export const fetchSavedSearch = (savedSearchId: any) =>
  api.get(`/search/${savedSearchId}`).then(({ data }) => data);

export const getAllTopics = () => {
  return api.get(`/topics`).then(({ data }) => data);
};

export const getNewTopic = (payload: any) => {
  return api.put(`/topics`, payload).then(({ data }) => data);
};

export const editTopic = (payload: any) => {
  return api.post(`/topics`, payload).then(({ data }) => data);
};
