import React, { useState, useRef, useEffect } from "react";
import Modal from "../shared/modal/modal";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { useSelector, useDispatch } from "react-redux";
import {
  shortlistRenameError,
  saveNewShortList,
} from "services/shortlist/shortlist.service";

import Button from "components/shared/button/button";

type props = {
  setShowModal: (value: boolean) => void;
  saveShortlist: (name: string) => void;
};

const SaveShortlist = ({ setShowModal, saveShortlist }: props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [disabled, setDisabled] = useState(0);
  const BLOCK = "RenameShortlist";
  const [count, setCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(false);
  const shortListState = useSelector((state: RootStore) => state.shortlist);
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  // Put focus on input tag
  useEffect(() => {
    inputRef.current!.focus();
  }, []);

  useEffect(() => {
    inputRef.current!.focus();
  }, [shortListState.errorRename]);

  useEffect(() => {
    if (shortListState.errorRename) {
      setErrorMessage(true);
      dispatch(shortlistRenameError());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortListState.errorRename]);

  useEffect(() => {
    if (shortListState.results && shortListState.results.shortlistName) {
      setShowModal(false);
      setErrorMessage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortListState.results]);

  useEffect(() => {
    if (shortListState.saveNewShortList) {
      setShowModal(false);
      setErrorMessage(false);
      dispatch(saveNewShortList(false));
    }
  }, [shortListState.saveNewShortList, dispatch, setShowModal]);

  useEffect(() => {
    setDisabled(inputRef.current!.value.trim().length);
  }, [inputRef.current?.value]);

  // Count the number of characters input by the user
  const onInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCount(event.target.value.length);
    setValue(event.target.value);
  };

  const saveInputHandler = () => {
    if (inputRef.current!.value.trim().length === 0) {
      return;
    }
    saveShortlist(inputRef.current!.value);
    //setShowModal(false);
  };

  // Automatically call saveInputHandler on Enter
  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      saveInputHandler();
    }
  };

  return (
    <Modal
      show={true}
      header="shortlist.save.title"
      handleClose={() => {
        setShowModal(false);
      }}
      config={false}
    >
      <div className={BLOCK} data-testid="save-shortlist-component">
        <span
          className={!errorMessage ? `${BLOCK}__name` : `${BLOCK}__nameError`}
        >
          <FormattedMessage id="shortlist.save.name" />
        </span>
        <span
          className={!errorMessage ? `${BLOCK}__input` : `${BLOCK}__inputError`}
        >
          <input
            type="text"
            data-test="input-save-shortList"
            data-testid="input-save-shortList"
            ref={inputRef}
            maxLength={200}
            onChange={onInputHandler}
            onKeyDown={onEnter}
            value={value}
          />
        </span>
        <span className={`${BLOCK}__content`}>
          {count}
          <FormattedMessage id="shortlist.save.input.character" />
        </span>
        {errorMessage ? (
          <span className={`${BLOCK}__contentError`}>
            <FormattedMessage id="shortlist.report.error" />
          </span>
        ) : (
          ""
        )}

        <div className={`${BLOCK}__options`}>
          <Button
            className={`button-secondary`}
            onClick={() => setShowModal(false)}
            formattedText={"shortlist.save.button.cancel"}
            dataTest="cancel-btn"
          />
          <Button
            className={`button-primary`}
            disabled={disabled === 0}
            formattedText="shortlist.save.button.save"
            dataTest="save-shortlist-button"
            onClick={saveInputHandler}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SaveShortlist;
