import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import { TooltipPosition } from "components/shared/tooltip/tooltip";
import Parser from "html-react-parser";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showTooltip } from "services/commons.service";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import { RootStore } from "services/store.service";
import TileEmptyContent from "../tile-empty-content";
import { mapTabularChartData, useTileContext } from "../tile.context";

const ReportingBoundary = () => {
  const {
    metadata,
    dataset,
    metadata: { benchmarkType },
    isTableViewActive,
  } = useTileContext();

  const BLOCK = "TabularTile";
  const dispatch = useDispatch();
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const tabularChartData = mapTabularChartData(metadata, dataset, true);
  if (
    dataset.every((d: InsightData) =>
      d.metrics.every((m: InsightMetricData) => !m.metricValue)
    )
  )
    return (
      <div>
        <TileEmptyContent />
      </div>
    );

  const isCompanyView = benchmarkType !== 6 && benchmarkType !== 5;
  let hasEmptyValue = false;

  const handleMouseEnter = (
    e: any,
    children: any,
    position: TooltipPosition = TooltipPosition.TileBottomRight,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("div");
    if (!element) {
      return;
    }
    dispatch(
      showTooltip({
        children: <div className={`${BLOCK}__tooltip`}>{Parser(children)}</div>,
        position: position,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;

    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  const getFormattedValue = (value: any) => {
    return value
      ? !isNaN(value)
        ? value > 1
          ? value
          : value > 0
          ? "Yes"
          : "No"
        : value
      : "*";
  };
  const getMetricData = (tabularData: any) => {
    return [
      tabularData.reduce(
        (data: any[], current: InsightData) => {
          let currentGroup: any = [];
          current.metrics.forEach((m: InsightMetricData) => {
            if (!isCompanyView) {
              let metricValues: String[] = [];
              metricValues = !m.metricValue ? ["*"] : [m.metricValue];
              currentGroup = [
                ...currentGroup,
                {
                  isHeader: false,
                  metricValuesTooltip: [current.companyName],
                  metricDescription: false,
                  metricValues: [getFormattedValue(m.metricValue)],
                  metricTitle: [current.companyName],
                },
              ];
              return [...data, ...currentGroup];
            }
            if (!current.isBaseCompany) {
              let tempValue = data.find((x) => x.metricTitle === m.metricName);
              tempValue.metricValues = [
                tempValue.metricValues,
                getFormattedValue(m.metricValue),
              ];
              tempValue.metricValuesTooltip = [
                tempValue.metricValuesTooltip,
                current.companyName,
              ];
              return [data];
            }
            currentGroup = [
              ...currentGroup,
              {
                isHeader: false,
                metricValuesTooltip: [current.companyName],
                metricDescription: m.metricTooltip,
                metricValues: [getFormattedValue(m.metricValue)],
                metricTitle: m.metricName,
              },
            ];
          });

          return [...data, ...currentGroup];
        },
        [
          {
            isHeader: true,
            metricValuesTooltip: [],
            metricDescription: [],
            metricValues: [],
            metricTitle: "",
          },
        ]
      ),
    ];
  };
  const [metricData] = getMetricData(dataset);
  hasEmptyValue = metricData.find((x: any) => x.metricValues.includes("*"));

  return (
    <div
      className={classNames(`${BLOCK}__content`)}
      data-testid="reporting-boundary-container"
    >
      <div
        className={classNames(`${BLOCK}__metrics`, {
          [`${BLOCK}__metrics-reporting-boundary`]: true,
        })}
      >
        {!isCompanyView ? (
          <div
            className={classNames(`${BLOCK}__metric-row`, {
              [`${BLOCK}__metric-row-reporting-subtitle`]: true,
            })}
          >
            <span></span>
            {true ? (
              <span className="">
                <Popover
                  metricClassName=""
                  buttonClassName={`${BLOCK}__metric-title-reporting`}
                  displayText={tabularChartData.metrics[0].metric}
                  content={
                    tabularChartData.metrics[0].description
                      ? tabularChartData.metrics[0].description
                      : []
                  }
                />
              </span>
            ) : (
              <span
                onMouseEnter={(e) => {
                  handleMouseEnter(
                    e,
                    tabularChartData.metrics[0].description,
                    TooltipPosition.TileBottomRight
                  );
                }}
                onMouseLeave={handleMouseLeave}
              >
                {tabularChartData.metrics[0].metric}
              </span>
            )}
          </div>
        ) : null}

        {metricData.map((metric: any, metricIndex: number) => {
          return (
            <div
              key={`metric-row-${metricIndex}`}
              className={classNames(`${BLOCK}__metric-row`, {})}
            >
              <div
                className={classNames(`${BLOCK}__metric-title-wrapper`, {
                  [`${BLOCK}__metric-title-wrapper-height`]: !metric.isHeader,
                  [`${BLOCK}__metric-title-wrapper--visible`]: true,
                })}
                key={metricIndex}
              >
                <div className={classNames(`${BLOCK}__metric-title`, {})}>
                  {isCompanyView ? (
                    <Popover
                      metricClassName={``}
                      buttonClassName={`${BLOCK}__metric-title-reporting`}
                      displayText={metric.metricTitle}
                      content={
                        metric.metricDescription ? metric.metricDescription : []
                      }
                    />
                  ) : (
                    <span
                      onMouseEnter={(e) => {
                        metric.metricDescription &&
                          isCompanyView &&
                          handleMouseEnter(
                            e,
                            metric.metricDescription,
                            TooltipPosition.TileBottomRight
                          );
                      }}
                      onMouseLeave={handleMouseLeave}
                    >
                      {metric.metricTitle}
                    </span>
                  )}
                </div>
              </div>
              {metric.metricValues.map((value: any, index: number) => (
                <Fragment key={`metric-value-${index}`}>
                  <div
                    className={classNames(`${BLOCK}__metric-value-wrapper`, {
                      [`${BLOCK}__metric-value-wrapper-header`]:
                        metricIndex === 0,
                      [`${BLOCK}__metric-value-wrapper-horizontal`]: true,
                      [`${BLOCK}__metric-value-wrapper-reporting-boundary`]:
                        true,
                    })}
                  >
                    <div
                      className={classNames(
                        `${BLOCK}__metric-value ${BLOCK}__metric-value-reporting-boundary`,
                        {
                          [`${BLOCK}__metric-value-${value}`]:
                            value && isTableViewActive,
                          [`${BLOCK}__metric-value-with-icon`]:
                            metric.metricValues.length < 2 &&
                            value &&
                            value.length > 1 &&
                            !tabularChartData.isDetailView,
                          [`${BLOCK}__metric-value-${value}`]:
                            value && !isTableViewActive,
                          [`multiple`]:
                            metric.metricValues.length > 1 ||
                            tabularChartData.isDetailView,
                          [`${BLOCK}__metric-value-reporting-boundary--gray`]:
                            isTableViewActive,
                        }
                      )}
                      key={metric.metricTitle}
                    >
                      <span>{value ? value : ""}</span>
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          );
        })}
      </div>

      {hasEmptyValue && (
        <span className={`${BLOCK}__rating-legend`}>
          <FormattedMessage id="no.data.available" />
        </span>
      )}
    </div>
  );
};

export default ReportingBoundary;
