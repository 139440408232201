import Icon from "components/shared/icon/icon";
import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { TooltipPosition } from "components/shared/tooltip/tooltip";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { showTooltip } from "services/commons.service";
import ResizeGridRow from "./resize-grid-row";
import Parser from "html-react-parser";
import classNames from "classnames";
import { patchUserPreference } from "services/peer-benchmark/peer-benchmark.service";
import {
  UserPreference,
  fieldColumnWidthObject,
} from "services/peer-benchmark/peer-benchmark.model";

type BenchmarkGridViewRowProps = {
  categoryRowMetric: any;
  indexForCategory: number;
  expandAllMetrics: boolean;
  rowItemsCompany: any;
  removeCellOrRow: Function;
  allCategoryRowMetrics: any[];
  setOpenCategory: Function;
  openCategory: any[];
  userPreferences: Partial<UserPreference>;
  setUserPreferences: Function;
};

const CDPBenchmarkGridViewRow = ({
  allCategoryRowMetrics,
  categoryRowMetric,
  indexForCategory,
  expandAllMetrics,
  rowItemsCompany,
  removeCellOrRow,
  setOpenCategory,
  openCategory,
  userPreferences,
  setUserPreferences,
}: BenchmarkGridViewRowProps) => {
  const BLOCK = "cdp-benchmark-grid";
  const [rowHeight, setRowHeight] = useState<{ [index: number]: number }>({});
  const [originalHeight, setOriginalHeight] = useState<{
    [index: number]: number;
  }>({});
  const [expandMetric, setExpandMetric] = useState<boolean>(
    expandAllMetrics || openCategory?.includes(indexForCategory)
  );
  const [isRowResize, setIsRowResize] = useState<boolean>(false);
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const dispatch = useDispatch();

  useEffect(() => {
    setExpandMetric(expandAllMetrics);
  }, [expandAllMetrics]);

  useEffect(() => {
    if (openCategory.includes(indexForCategory)) {
      setExpandMetric(true);
    } else {
      setExpandMetric(false);
    }
  }, [openCategory]);

  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("span");
    if (!element) {
      return;
    }

    dispatch(
      showTooltip({
        children: children,
        position: TooltipPosition.top,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };
  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  const checkForLink = (textValue: string) => {
    const links = textValue && textValue.match(/<a(.*?)>/g);
    let value;
    if (links && links.length > 0) {
      value = textValue.replace(
        /<a\s+[^>]*href=(["'])(.*?)\1[^>]*>/gi,
        '<a href="$2" target="_blank">$2</a>'
      );
    }

    return links && links.length > 0 && value ? Parser(value) : textValue;
  };

  const savePreferenceForRowHeight = (rowId: number, size: number) => {
    let fieldColumnWidth: fieldColumnWidthObject[] = [];
    if (
      userPreferences.userPreferenceValue?.fieldColumnWidths &&
      userPreferences.userPreferenceValue?.fieldColumnWidths?.length > 0
    ) {
      let filtered =
        userPreferences.userPreferenceValue?.fieldColumnWidths.find(
          (field: fieldColumnWidthObject) => field.fieldId === rowId
        );

      if (filtered) {
        fieldColumnWidth =
          userPreferences.userPreferenceValue?.fieldColumnWidths?.map(
            (field: fieldColumnWidthObject) => {
              if (field.fieldId === rowId) {
                return {
                  ...field,
                  height: size,
                };
              } else {
                return field;
              }
            }
          );
      } else {
        fieldColumnWidth = [
          ...userPreferences.userPreferenceValue?.fieldColumnWidths,
          ...[{ fieldId: rowId, height: size }],
        ];
      }
    } else {
      fieldColumnWidth = [
        {
          fieldId: rowId,
          height: size,
        },
      ];
    }
    const newUserPreference: any = {
      ...userPreferences,
      userPreferenceValue: {
        ...userPreferences.userPreferenceValue,
        fieldColumnWidths: fieldColumnWidth ?? [],
      },
    };
    dispatch(patchUserPreference(newUserPreference));
    setUserPreferences((userPref: UserPreference) => {
      return {
        ...userPref,
        userPreferenceValue: {
          ...userPref.userPreferenceValue,
          fieldColumnWidths: fieldColumnWidth,
        },
      };
    });
  };

  const getCompanyMetricValues = (
    fields: any,
    metricField: any,
    index: number,
    rowIndex: number
  ) => {
    const field = fields.filter(
      (companyfield: any) => companyfield.fieldId === metricField.fieldId
    );

    const fieldValue =
      field.length > 1
        ? field.map((fieldValueData: any, indexForField: number) => (
            <span
              key={`${BLOCK}--${indexForField}`}
              className={classNames(`${BLOCK}__rowItem-multipleItem`, {
                [`${BLOCK}__rowItem-firstmultipleItem`]: indexForField !== 0,
              })}
            >
              {fieldValueData.fieldValue && fieldValueData.fieldValue !== ""
                ? checkForLink(fieldValueData.fieldValue)
                : "*"}
            </span>
          ))
        : field.length > 0
        ? field[0]?.fieldValue && field[0]?.fieldValue !== ""
          ? checkForLink(field[0]?.fieldValue)
          : "*"
        : "*";
    return (
      <td
        key={`row-data-${index}`}
        className={classNames(`${BLOCK}__rowItem`, {
          [`${`${BLOCK}__rowItem-multiple`}`]: field.length > 1,
        })}
      >
        <span
          style={{
            ...(rowHeight && rowHeight[rowIndex] > 0
              ? {
                  height: `${rowHeight[rowIndex]}px`,
                  WebkitLineClamp: Math.floor(rowHeight[rowIndex] / 20),
                }
              : originalHeight &&
                originalHeight[rowIndex] &&
                metricField?.height &&
                metricField?.height !== 0
              ? {
                  height: `${metricField?.height}px`,
                  WebkitLineClamp: Math.floor(metricField?.height / 20),
                }
              : null),
            display: "-webkit-box",
            overflow: "hidden",
            lineHeight: "20px",
            WebkitBoxOrient: "vertical",
            wordBreak: "break-word",
          }}
          {...(fieldValue !== "*" &&
          ((rowHeight &&
            originalHeight &&
            rowHeight[rowIndex] < originalHeight[rowIndex]) ||
            (originalHeight &&
              metricField?.height &&
              metricField?.height !== 0 &&
              metricField?.height < originalHeight[rowIndex]))
            ? {
                onMouseEnter: (e) => {
                  handleMouseEnter(
                    e,
                    fieldValue,
                    `${BLOCK}__info-icon-tooltip `
                  );
                },
                onMouseLeave: handleMouseLeave,
              }
            : {})}
        >
          {fieldValue}
        </span>
      </td>
    );
  };

  return (
    <Fragment key={`category-${indexForCategory}`}>
      <tr className={`${BLOCK}__rowItem `} data-test={`${BLOCK}__rowItem`}>
        <td
          className={`${BLOCK}__rowItem--td ${BLOCK}__rowItem--metricCategory`}
        >
          <div className={`${BLOCK}-category-row`}>
            <button
              className={`${BLOCK}-category-expand-button`}
              data-test={`${BLOCK}-category-expand-button`}
              onClick={() => {
                setExpandMetric(!expandMetric);
                if (!expandMetric && !openCategory.includes(indexForCategory)) {
                  setOpenCategory(
                    Array.from(new Set([...openCategory, indexForCategory]))
                  );
                  dispatch(
                    patchUserPreference({
                      ...userPreferences,
                      userPreferenceValue: {
                        ...userPreferences.userPreferenceValue,
                        expandedMetricCategories: Array.from(
                          new Set([...openCategory, indexForCategory])
                        ),
                      },
                    })
                  );

                  setUserPreferences((userPref: UserPreference) => {
                    return {
                      ...userPref,
                      userPreferenceValue: {
                        ...userPref.userPreferenceValue,
                        expandedMetricCategories: Array.from(
                          new Set([...openCategory, indexForCategory])
                        ),
                      },
                    };
                  });
                } else {
                  setOpenCategory(
                    openCategory?.filter(
                      (category) => category !== indexForCategory
                    )
                  );
                  dispatch(
                    patchUserPreference({
                      ...userPreferences,
                      userPreferenceValue: {
                        ...userPreferences.userPreferenceValue,
                        expandedMetricCategories: openCategory?.filter(
                          (category) => category !== indexForCategory
                        ),
                        isExpanded: false,
                      },
                    })
                  );
                  setUserPreferences((userPref: UserPreference) => {
                    return {
                      ...userPref,
                      userPreferenceValue: {
                        ...userPref.userPreferenceValue,
                        expandedMetricCategories: openCategory?.filter(
                          (category) => category !== indexForCategory
                        ),
                      },
                    };
                  });
                }
              }}
            >
              <Icon
                name={
                  expandMetric || openCategory?.includes(indexForCategory)
                    ? "caret-down"
                    : "caret-right"
                }
                width={11}
                height={11}
              />
              <span className={`${BLOCK}-categoryName`}>
                {categoryRowMetric?.displayName}
              </span>
            </button>
          </div>
        </td>
        {rowItemsCompany.map((_: any, i: number) =>
          i > 0 ? (
            <td
              key={`${BLOCK}-${i}`}
              className={`${BLOCK}__rowItem--metricCategory`}
            ></td>
          ) : null
        )}
        <td
          className={`${BLOCK}__rowItem--metricCategory ${BLOCK}__rowItem--count`}
        >
          <span className={`${BLOCK}-categoryBox`}>
            <FormattedMessage
              id={"peer-benchmarks.metrics.category.header"}
              values={{ count: categoryRowMetric.fields.length }}
            ></FormattedMessage>
          </span>
        </td>
      </tr>
      {(expandMetric || openCategory?.includes(indexForCategory)) &&
        categoryRowMetric.fields.length > 0 &&
        categoryRowMetric.fields.map((field: any, index: number) => (
          <Fragment key={`fields-${index}`}>
            <ResizeGridRow
              className={BLOCK}
              setSize={(size: number) => {
                setRowHeight((rw) => ({ ...rw, ...{ [field.fieldId]: size } }));
                savePreferenceForRowHeight(field.fieldId, size);
              }}
              setOriginalSize={(size: number) =>
                setOriginalHeight((rw) => ({
                  ...rw,
                  ...{ [field.fieldId]: size },
                }))
              }
              rowContent={
                <>
                  <td
                    className={`${BLOCK}__rowItem--tdMetric`}
                    data-test={`${BLOCK}__rowItem-td`}
                  >
                    <div className={`${BLOCK}__rowMetric`}>
                      {(categoryRowMetric.fields.length > 1 ||
                        allCategoryRowMetrics.length > 1) && (
                        <button
                          className={`${BLOCK}__rowItem--transpose-remove-button`}
                          onClick={() =>
                            removeCellOrRow
                              ? removeCellOrRow(field.fieldName)
                              : null
                          }
                        >
                          <Icon name="cross" width={16} height={16} />
                        </button>
                      )}
                      <div
                        className={`${BLOCK}__rowItem--box`}
                        style={{
                          ...(rowHeight && rowHeight[field.fieldId] > 0
                            ? {
                                height: `${rowHeight[field.fieldId]}px`,
                                WebkitLineClamp: Math.floor(
                                  rowHeight[field.fieldId] / 20
                                ),
                              }
                            : originalHeight &&
                              originalHeight[field.fieldId] &&
                              field?.height &&
                              field?.height !== 0
                            ? {
                                height: `${field?.height}px`,
                                WebkitLineClamp: Math.floor(field?.height / 20),
                              }
                            : null),
                          display: "-webkit-box",
                          overflow: "hidden",
                          lineHeight: "20px",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {field.displayName}
                      </div>
                      {field.description && (
                        <span
                          className={`${BLOCK}__info-icon ${BLOCK}__info-icon--transpose`}
                          onMouseEnter={(e) => {
                            handleMouseEnter(
                              e,
                              field.description,
                              `${BLOCK}__info-icon-tooltip `
                            );
                          }}
                          onMouseLeave={handleMouseLeave}
                        >
                          <Icon name="information" width={25} height={25} />
                        </span>
                      )}
                    </div>
                  </td>
                  {rowItemsCompany &&
                    rowItemsCompany.length > 0 &&
                    rowItemsCompany.map((company: any, j: number) =>
                      getCompanyMetricValues(
                        company.fields,
                        field,
                        j,
                        field.fieldId
                      )
                    )}
                </>
              }
              setIsRowResize={setIsRowResize}
              height={
                userPreferences?.userPreferenceValue?.fieldColumnWidths.filter(
                  (fieldColumnWidthObj: fieldColumnWidthObject) =>
                    fieldColumnWidthObj.fieldId === field.fieldId
                )[0]?.height
              }
            />
          </Fragment>
        ))}
    </Fragment>
  );
};

export default CDPBenchmarkGridViewRow;
