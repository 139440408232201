import { Dispatch } from "redux";
import { createAction } from "../../utils/redux.utils";
import * as api from "./peer-benchmark.api";
import * as commonApi from "../commons.api";
import { PeerBenchmarkModel, PeerBenchmarkState } from "./peer-benchmark.model";
import history from "../../utils/history";
import {
  BENCHMARK_TYPE,
  DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD,
  FILTER_OPTIONS,
  PEER_BENCHMARK_TABS,
  USER_PREFERENCES,
} from "utils/constants";
import {
  setSharedWith,
  shortlistSharePop,
} from "services/saved-shortlist/saved-shortlist.service";
import { GlobalCompanyGroup } from "services/insights/insights.model";
import { getKeyByValue } from "utils/functions";
import { CompanyFilter } from "services/search/filters.model";

export const ACTIONS = {
  PEER_BENCHMARK_ERROR: "ESG/UI/PEER_BENCHMARK_ERROR",
  PEER_BENCHMARK_LOADING: "ESG/UI/PEER_BENCHMARK_LOADING",
  FETCH_METRICS: "ESG/UI/FETCH_METRICS",
  SAVE_PEER_BENCHMARK: "ES/UI/SAVE_PEER_BENCHMARK",
  PEER_BENCHMARK_LOADING_FILTER: "ESG/UI/PEER_BENCHMARK_LOADING_FILTER",
  PEER_BENCHMARK_LOAD_COMPANY_FILTER:
    "ESG/UI/PEER_BENCHMARK_LOAD_COMPANY_FILTER",
  PEER_BENCHMARK_LOAD_PEER_COMPANIES:
    "ESG/UI/PEER_BENCHMARK_LOAD_PEER_COMPANIES",
  PEER_BENCHMARK_SET_COMPANY_FILTER:
    "ESG/UI/ PEER_BENCHMARK_SET_COMPANY_FILTER",
  PEER_BENCHMARK_SET_PEER_COMPANY_FILTER:
    "ESG/UI/ PEER_BENCHMARK_SET_PEER_COMPANY_FILTER",
  SAVE_BEENCHMARK_SUCCESS: "ES/UI/SAVE_BEENCHMAR_SUCCESS",
  SAVED_BEENCHMARK_ID: "ES/UI/SAVED_BEENCHMARK_ID",
  CURRENT_SAVED_BENCHMARK: "ES/UI/CURRENT_SAVED_BENCHMARK",
  RESET_SAVED_BEENCHMARK_ID: "ES/UI/RESET_SAVED_BEENCHMAR_ID",
  RESET_CURRENT_SAVED_BENCHMARK: "ES/UI/RESET_CURRENT_SAVED_BENCHMARK",
  PEER_BENCHMARK_TO_SAVE: "ES/UI/PEER_BENCHMARK_TO_SAVE",
  ERROR_RENAME: "ES/UI/ERROR_RENAME",
  RENAME_SUCCESS: "ES/UI/RENAME_SUCCESS",
  //Need to change source to peerbanchmark
  PEER_BENCHMARK_LOAD_SUGGESTED_PEER_COMPANIES:
    "ESG/UI/PEER_BENCHMARK_LOAD_SUGGESTED_PEER_COMPANIES",
  SEARCH_ERROR: "ESG/UI/SEARCH_ERROR",
  GET_SAVED_PEER_BENCHMARKS: "ESG/UI/GET_SAVED_PEERBENCHMARKS",
  PEER_BENCHMARK_SET_SELECTED_ITEMS: "ESG/UI/PEER_BENCHMARK_SET_SELECTED_ITEMS",
  PEER_BENCHMAKR_RESET_SELECTED_ITEMS:
    "ESG/UI/PEER_BENCHMARK_RESET_SELECTED_ITEM",
  SET_CURRENT_VALUES_EDIT: "ES/UI/SET_CURRENT_VALUES_EDIT",
  PEER_BENCHMAKR_DELETE_SUCCESS: "ESG/UI/PEER_BENCHMARK_DELETE_SUCCESS",
  PEER_BENCHMARK_SHARE_SUCCESS: "ESG/UI/PEER_BENCHMARK_SHARE_SUCCESS",
  PEER_BENCHMARK_SHAVE_EDIT_DATA: "ESG/UI/ PEER_BENCHMARK_SHAVE_EDIT_DATA",
  PEER_BENCHMARK_DISPLAY_TRACK: "ESG/UI/PEER_BENCHMARK_DISPLAY_TRACK",
  PEER_BENCHMARK_EXPORT_TRACK: "ESG/UI/PEER_BENCHMARK_EXPORT_TRACK",
  PEER_BENCHMARK_SHARE_TRACK: "ESG/UI/PEER_BENCHMARK_SHARE_TRACK",
  PEER_BENCHMARK_SET_CURRENT_BENCHMARK_DATA:
    "ESG/UI/PEER_BENCHMARK_SET_CURRENT_BENCHMARK_DATA",
  PEER_BENCHMARK_EDIT_SUCCESS: "ESG/UI/PEER_BENCHMARK_EDIT_SUCCESS",
  PEER_BENCHMARK_EDIT_RENAME_ERROR: "ESG/UI/PEER_BENCHMARK_EDIT_RENAME_ERROR",
  PEER_BENCHMARK_IS_FILTERING: "ESG/UI/PEER_BENCHMARK_IS_FILTERING",
  PEER_BENCHMARK_REMOVE_SUCCESS: "ESG/UI/PEER_BENCHMARK_REMOVE_SUCCESS",
  PEER_BENCHMARK_EDIT_PEER_SUCCESS: "ESG/UI/PEER_BENCHMARK_EDIT_PEER_SUCCESS",
  PEER_BENCHMARK_SET_SAVED_USER_PREFERENCE:
    "ESG/UI/PEER_BENCHMARK_SET_SAVED_USER_PREFERENCE",
  PEER_BENCHMARK_EDIT_RENAME_PEER_ERROR:
    "ESG/UI/PEER_BENCHMARK_EDIT_RENAME_PEER_ERROR",
  PEER_BENCHMARK_SET_SELECTED_PEER_GROUP_ITEMS:
    "ESG/UI/PEER_BENCHMARK_SET_SELECTED_PEER_GROUP_ITEMS",
  PEER_BENCHMARK_SET_EXPAND_ALL_METRICS:
    "ESG/UI/PEER_BENCHMARK_SET_EXPAND_ALL_METRICS",
  PEER_BENCHMARK_SET_COLLAPSE_ALL_METRICS:
    "ESG/UI/PEER_BENCHMARK_SET_COLLAPSE_ALL_METRICS",
  PEER_BENCHMARK_SET_HAS_METRIC_OPEN:
    "ESG/UI/PEER_BENCHMARK_SET_HAS_METRIC_OPEN",
  PEER_BENCHMARK_SET_GLOBAL_COMPANY_MAPPING_FILTER:
    "ESG/UI/PEER_BENCHMARK_SET_GLOBAL_COMPANY_MAPPING_FILTER",
};

export const setGlobalCompanyMappingsFilter = createAction(
  ACTIONS.PEER_BENCHMARK_SET_GLOBAL_COMPANY_MAPPING_FILTER
);

export const isFilteringPeerBenchMark = createAction(
  ACTIONS.PEER_BENCHMARK_IS_FILTERING
);
export const peerBenchMarkError = createAction(ACTIONS.PEER_BENCHMARK_ERROR);
export const peerBenchMarkLoading = createAction(
  ACTIONS.PEER_BENCHMARK_LOADING
);
export const peerBenchMarkRemoveSuccess = createAction(
  ACTIONS.PEER_BENCHMARK_REMOVE_SUCCESS
);

export const settingSavedUserPreference = createAction(
  ACTIONS.PEER_BENCHMARK_SET_SAVED_USER_PREFERENCE
);

export const sharePeerBenchmarkSuccess = createAction(
  ACTIONS.PEER_BENCHMARK_SHARE_SUCCESS
);

export const saveEditPeerBnechmarkData = createAction(
  ACTIONS.PEER_BENCHMARK_SHAVE_EDIT_DATA
);

export const deletePeerBenchmarkSuccess = createAction(
  ACTIONS.PEER_BENCHMAKR_DELETE_SUCCESS
);

export const getSavedPeerBenchmarks = createAction(
  ACTIONS.GET_SAVED_PEER_BENCHMARKS
);
export const searchLoadingFilter = createAction(
  ACTIONS.PEER_BENCHMARK_LOADING_FILTER
);
export const searchLoadCompanyFilter = createAction(
  ACTIONS.PEER_BENCHMARK_LOAD_COMPANY_FILTER
);
export const loadPeerCompanies = createAction(
  ACTIONS.PEER_BENCHMARK_LOAD_PEER_COMPANIES
);
export const setCompanyFilter = createAction(
  ACTIONS.PEER_BENCHMARK_SET_COMPANY_FILTER
);
export const searchSetPeerCompanyFilter = createAction(
  ACTIONS.PEER_BENCHMARK_SET_PEER_COMPANY_FILTER
);
export const fetchMetrics = createAction(ACTIONS.FETCH_METRICS);
export const saveBenchmarkSuccess = createAction(
  ACTIONS.SAVE_BEENCHMARK_SUCCESS
);
export const savedBenchmarkID = createAction(ACTIONS.SAVED_BEENCHMARK_ID);
export const currentSavedBenchmark = createAction(
  ACTIONS.CURRENT_SAVED_BENCHMARK
);
export const resetSavedBenchmarkID = createAction(
  ACTIONS.RESET_SAVED_BEENCHMARK_ID
);
export const resetCurrentSavedBenchmark = createAction(
  ACTIONS.RESET_CURRENT_SAVED_BENCHMARK
);
export const errorRename = createAction(ACTIONS.ERROR_RENAME);
export const renameSuccess = createAction(ACTIONS.RENAME_SUCCESS);

export const peerBenchmarkToSave = createAction(ACTIONS.PEER_BENCHMARK_TO_SAVE);

export const updateSelectedItems = createAction(
  ACTIONS.PEER_BENCHMARK_SET_SELECTED_ITEMS
);

export const resetSelectedItems = createAction(
  ACTIONS.PEER_BENCHMAKR_RESET_SELECTED_ITEMS
);

export const loadSuggestedPeerCompanies = createAction(
  ACTIONS.PEER_BENCHMARK_LOAD_SUGGESTED_PEER_COMPANIES
);

export const setSelectedValuesForEdit = createAction(
  ACTIONS.SET_CURRENT_VALUES_EDIT
);

export const savePeerBenchmarkTrack = createAction(ACTIONS.SAVE_PEER_BENCHMARK);

export const displayPeerBenchmarkTrack = createAction(
  ACTIONS.PEER_BENCHMARK_DISPLAY_TRACK
);

export const exportPeerBenchmarkTrack = createAction(
  ACTIONS.PEER_BENCHMARK_EXPORT_TRACK
);

export const sharePeerBenchmarkTrack = createAction(
  ACTIONS.PEER_BENCHMARK_SHARE_TRACK
);

export const updateCurrentBenchmarkData = createAction(
  ACTIONS.PEER_BENCHMARK_SET_CURRENT_BENCHMARK_DATA
);

export const errorEditRename = createAction(
  ACTIONS.PEER_BENCHMARK_EDIT_RENAME_ERROR
);
export const renameEditSuccess = createAction(
  ACTIONS.PEER_BENCHMARK_EDIT_SUCCESS
);

export const errorEditPeerRename = createAction(
  ACTIONS.PEER_BENCHMARK_EDIT_RENAME_PEER_ERROR
);
export const renameEditPeerSuccess = createAction(
  ACTIONS.PEER_BENCHMARK_EDIT_PEER_SUCCESS
);

export const updateSelectedPeerGroupItems = createAction(
  ACTIONS.PEER_BENCHMARK_SET_SELECTED_PEER_GROUP_ITEMS
);

export const setExpandAllMetrics = createAction(
  ACTIONS.PEER_BENCHMARK_SET_EXPAND_ALL_METRICS
);

export const setCollapseAllMetrics = createAction(
  ACTIONS.PEER_BENCHMARK_SET_COLLAPSE_ALL_METRICS
);

export const setHasMetricOpen = createAction(
  ACTIONS.PEER_BENCHMARK_SET_HAS_METRIC_OPEN
);

const buildMetric = (metrics: any) => {
  //NEW BACKEND STRUCTURE TREATMENT
  let tmpMetricCategories = metrics.filter((m: any) => !m.fieldGroupId);
  let tmpFieldGroups = metrics.filter((fg: any) => fg.fieldGroupId);
  if (tmpFieldGroups.length > 0)
    tmpFieldGroups.forEach((fg: any) => {
      let currentMetricCategoryIndex = tmpMetricCategories.findIndex(
        (m: any) => m.metricCategorydId === fg.metricCategorydId
      );
      if (currentMetricCategoryIndex !== -1) {
        tmpMetricCategories[currentMetricCategoryIndex].fieldGroups.push(fg);
      }
    });
  return tmpMetricCategories;
};

export const getSavedUserPreference =
  (benchmarkId: number) => async (dispatch: Dispatch<any>) => {
    try {
      const userPreference = await commonApi.getUserPreference(benchmarkId, USER_PREFERENCES.PEER_BENCHMARK);
      dispatch(settingSavedUserPreference(userPreference.data));
    } catch {
      dispatch(settingSavedUserPreference({ referenceId: benchmarkId }));
      dispatch(peerBenchMarkError(true));
    }
  };

export const patchUserPreference =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      const userPreference = await commonApi.updateUserPreference(payload);
    } catch {
      dispatch(peerBenchMarkError(true));
    }
  };

export const loadPeerBenchMarkMetrics =
  () => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(peerBenchMarkLoading(true));
      const msciMetrics = await api.fetchAllMetrics(BENCHMARK_TYPE.MSCI);
      const cdpMetrics = await api.fetchAllMetrics(BENCHMARK_TYPE.CDP);

      dispatch(
        fetchMetrics([
          {
            PeerbenchmarkTabName: PEER_BENCHMARK_TABS.MSCI,
            PeerbenchmarkId: BENCHMARK_TYPE.MSCI,
            PeerbenchmarkCategories: buildMetric(msciMetrics.data),
          },
          {
            PeerbenchmarkTabName: PEER_BENCHMARK_TABS.CDP,
            PeerbenchmarkId: BENCHMARK_TYPE.CDP,
            PeerbenchmarkCategories: buildMetric(cdpMetrics.data),
          },
        ])
      );
      dispatch(peerBenchMarkLoading(false));
    } catch (e) {
      dispatch(peerBenchMarkLoading(false));
      dispatch(peerBenchMarkError(true));
    }
  };

export const saveNewPeerBenchmark =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(peerBenchMarkLoading(true));
      dispatch(
        savePeerBenchmarkTrack(
          getKeyByValue(BENCHMARK_TYPE, payload.benchmarkType)
        )
      );
      const res = await api.savePeerBenchmark(payload);
      history.push(`/peerbenchmark/${res.data}`);
      dispatch(savedBenchmarkID(res.data));
      dispatch(saveBenchmarkSuccess(true));
      dispatch(peerBenchMarkError(false));
    } catch (e) {
      dispatch(peerBenchMarkError(true));
      dispatch(peerBenchMarkLoading(false));
    }
  };

export const loadCurrentBenchmark =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(peerBenchMarkLoading(true));
      const res = await api.getCurrentPeerBenchmark(payload);
      dispatch(displayPeerBenchmarkTrack(true));
      dispatch(currentSavedBenchmark(res.data));
      dispatch(peerBenchMarkError(false));
      dispatch(peerBenchMarkLoading(false));
    } catch (e) {
      dispatch(peerBenchMarkError(true));
      dispatch(peerBenchMarkLoading(false));
    }
  };

export const changePeerBenchmarkPeriod =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(peerBenchMarkLoading(true));
      const res = await api.selectPeerBenchmarkPeriod(payload);
      dispatch(updateCurrentBenchmarkData(res.data));
      dispatch(loadCurrentBenchmark(payload));
      dispatch(peerBenchMarkError(false));
      dispatch(peerBenchMarkLoading(false));
    } catch {
      dispatch(peerBenchMarkError(true));
      dispatch(peerBenchMarkLoading(false));
    }
  };

export const loadCurrentBenchmarkData =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      const res = await api.getBenchmarkMetadata(payload);
      dispatch(updateCurrentBenchmarkData(res.data));
    } catch (e) {}
  };

export const editPeerBenchmark =
  (payload: any, type: string = "edit") =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(peerBenchMarkLoading(true));
      dispatch(savePeerBenchmarkTrack(true));
      const res = await api.editPeerBenchmark(payload);
      dispatch(savedBenchmarkID(res.data));
      if (type === "editName") {
        dispatch(renameSuccess(true));
        dispatch(peerBenchMarkLoading(false));
      } else {
        dispatch(renameEditSuccess(true));
        dispatch(saveBenchmarkSuccess(true));
        dispatch(peerBenchMarkError(false));
      }
    } catch (e) {
      dispatch(peerBenchMarkError(true));
      dispatch(peerBenchMarkLoading(false));
      if (type === "editName") {
        dispatch(errorRename(true));
        dispatch(renameSuccess(false));
      } else {
        dispatch(errorEditRename(true));
      }
    }
  };

export const handleEditPeerBenchmark =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      await api.editPeerBenchmark(payload);
      dispatch(renameEditPeerSuccess(true));
    } catch (e) {
      dispatch(errorEditPeerRename(true));
    }
  };

export const loadCompanyFilter =
  (searchText: string) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = searchText ? await api.fetchDocumentFilter(searchText) : [];
      dispatch(searchLoadCompanyFilter(res.data));
    } catch (e) {
      dispatch(peerBenchMarkError(true));
      dispatch(peerBenchMarkLoading(false));
    }
  };

export const getPeerCompanyFilter =
  (searchText: string) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = searchText ? await api.fetchDocumentFilter(searchText) : [];
      dispatch(loadPeerCompanies(res.data));
    } catch (e) {
      dispatch(peerBenchMarkError(true));
      dispatch(peerBenchMarkLoading(false));
    }
  };

export const removePeerBenchmarkItems =
  (benchmarkId: number, payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(peerBenchMarkLoading(true));
      await api.savePeerBenchmarkData(benchmarkId, payload);
      dispatch(
        loadCurrentBenchmark({
          benchmarkId: benchmarkId,
          benchmarkTypeId: payload?.benchmark?.benchmarkType,
          selectedReportedYear: payload?.benchmark?.selectedReportedYear,
          selectedQuarter: payload?.benchmark?.selectedQuarter,
        })
      );
      dispatch(peerBenchMarkRemoveSuccess(true));
      dispatch(getSavedUserPreference(benchmarkId));
    } catch (e) {
      dispatch(peerBenchMarkLoading(false));
    }
  };

export const loadSavedPeerbenchmarks =
  (payloadParam?: any) => async (dispatch: Dispatch<any>) => {
    try {
      const payload = payloadParam
        ? payloadParam
        : DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD;
      dispatch(peerBenchMarkLoading(true));
      const res = await api.fetchSavedPeerbenchmarks(payload);
      dispatch(getSavedPeerBenchmarks(res.data));
      dispatch(peerBenchMarkLoading(false));
    } catch (e) {
      dispatch(peerBenchMarkLoading(false));
    }
  };
export const sharePeerBenchmark =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(peerBenchMarkLoading(true));
      await api.sharePeerBenchmark(payload);
      dispatch(sharePeerBenchmarkTrack(true));
      dispatch(setSharedWith([]));
      dispatch(shortlistSharePop(null));
      dispatch(loadSavedPeerbenchmarks(DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD));
      dispatch(peerBenchMarkLoading(true));
      dispatch(sharePeerBenchmarkSuccess(true));
    } catch (e) {
      //dispatch(savedShortlistLoading(false));
      dispatch(sharePeerBenchmarkSuccess(true));
    }
  };

export const deletePeerBenchmark =
  (peerBenchmarkId: number) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(peerBenchMarkLoading(true));
      await api.deletePeerBenchmark(peerBenchmarkId);
      dispatch(loadSavedPeerbenchmarks(DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD));
      dispatch(peerBenchMarkLoading(true));
      dispatch(deletePeerBenchmarkSuccess(true));
    } catch (e) {
      dispatch(peerBenchMarkLoading(false));
    }
  };

export const loadSuggestedPeerCompanyFilter =
  (cikNumber: number) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = cikNumber ? await api.fetchSuggestedPeers(cikNumber) : [];
      dispatch(loadSuggestedPeerCompanies(res.data));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.COMPANY));
    }
  };

export const clearPeerBenchmarkFilter =
  (benchmarkId: number, payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(peerBenchMarkLoading(true));
      await api.savePeerBenchmarkData(benchmarkId, payload);
      dispatch(renameEditSuccess(true));
      dispatch(isFilteringPeerBenchMark(""));
    } catch (e) {
      dispatch(peerBenchMarkLoading(false));
    }
  };

export const setExpandAllMetricsValue =
  (payload: boolean) => async (dispatch: Dispatch<any>) => {
    dispatch(setExpandAllMetrics(payload));
  };

export const setCollapseAllMetricsValue =
  (payload: boolean) => async (dispatch: Dispatch<any>) => {
    dispatch(setCollapseAllMetrics(payload));
  };

export const setHasMetricOpenValue =
  (payload: boolean) => async (dispatch: Dispatch<any>) => {
    dispatch(setHasMetricOpen(payload));
  };

export const searchError = createAction(ACTIONS.SEARCH_ERROR);

export const setGlobalMappingFilter =
  (globalMappingFilter: Partial<GlobalCompanyGroup>[]) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setGlobalCompanyMappingsFilter(globalMappingFilter));
    } catch (e) {}
  };

export const setPeerCompanyFilter =
  (companyFilter: CompanyFilter[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchSetPeerCompanyFilter(companyFilter));
    } catch (e) {}
  };

export const setBaselineCompanyFilter =
  (companyFilter: CompanyFilter[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setCompanyFilter(companyFilter));
    } catch (e) {}
  };

export const getGlobalPeerCompanyFilter =
  (searchText: string, isSECFiler: boolean = true) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = searchText
        ? await api.fetchGlobalCompaniesFilter(searchText, isSECFiler)
        : [];
      dispatch(loadPeerCompanies(res.data.globalCompanies));
    } catch (e) {
      dispatch(peerBenchMarkError(true));
      dispatch(peerBenchMarkLoading(false));
      dispatch(loadPeerCompanies([]));
    }
  };

export const getGlobalCompanyFilter =
  (searchText: string, isSECFiler: boolean = true) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = searchText
        ? await api.fetchGlobalCompaniesFilter(searchText, isSECFiler)
        : [];
      dispatch(searchLoadCompanyFilter(res.data.globalCompanies));
    } catch (e) {
      dispatch(peerBenchMarkError(true));
      dispatch(peerBenchMarkLoading(false));
      dispatch(searchLoadCompanyFilter([]));
    }
  };

const peerBenchMarkModel = new PeerBenchmarkModel();

const peerBenchMarkReducer = (
  state: PeerBenchmarkState = peerBenchMarkModel.initialState(),
  action: any
): PeerBenchmarkState => {
  switch (action.type) {
    case ACTIONS.PEER_BENCHMARK_ERROR:
      return peerBenchMarkModel.setError(action.payload);
    case ACTIONS.PEER_BENCHMARK_LOADING:
      return peerBenchMarkModel.setLoading(action.payload);
    case ACTIONS.FETCH_METRICS:
      return peerBenchMarkModel.setMetrics(action.payload);
    case ACTIONS.PEER_BENCHMARK_LOAD_COMPANY_FILTER:
      return peerBenchMarkModel.setBaselineCompanyOptions(action.payload);
    case ACTIONS.PEER_BENCHMARK_LOADING_FILTER:
      return peerBenchMarkModel.setLoadingFilter(action.payload);
    case ACTIONS.PEER_BENCHMARK_LOAD_PEER_COMPANIES:
      return peerBenchMarkModel.setPeerCompanyFilterOptions(action.payload);
    case ACTIONS.PEER_BENCHMARK_SET_COMPANY_FILTER:
      return peerBenchMarkModel.setCompanyFilter(action.payload);
    case ACTIONS.PEER_BENCHMARK_SET_PEER_COMPANY_FILTER:
      return peerBenchMarkModel.setPeerCompanyFilter(action.payload);
    case ACTIONS.SAVE_BEENCHMARK_SUCCESS:
      return peerBenchMarkModel.setSaveNewBenchmarkSuccess(action.payload);
    case ACTIONS.SAVED_BEENCHMARK_ID:
      return peerBenchMarkModel.setSavedBenchmarkID(action.payload);
    case ACTIONS.CURRENT_SAVED_BENCHMARK:
      return peerBenchMarkModel.setCurrentSavedBenchmark(action.payload);
    case ACTIONS.RESET_SAVED_BEENCHMARK_ID:
      return peerBenchMarkModel.resetSavedBenchmarkID();
    case ACTIONS.RESET_CURRENT_SAVED_BENCHMARK:
      return peerBenchMarkModel.resetCurrentSavedBenchmark();
    case ACTIONS.PEER_BENCHMARK_TO_SAVE:
      return peerBenchMarkModel.setPeerBenchmarkToSave(action.payload);
    case ACTIONS.ERROR_RENAME:
      return peerBenchMarkModel.setErrorRename(action.payload);
    case ACTIONS.RENAME_SUCCESS:
      return peerBenchMarkModel.setRenameSuccess(action.payload);
    case ACTIONS.PEER_BENCHMARK_LOAD_SUGGESTED_PEER_COMPANIES:
      return peerBenchMarkModel.loadSuggestedPeerCompanyFilterOptions(
        action.payload
      );
    case ACTIONS.GET_SAVED_PEER_BENCHMARKS:
      return peerBenchMarkModel.setSavedBenchmark(action.payload);
    case ACTIONS.PEER_BENCHMARK_SET_SELECTED_ITEMS:
      return peerBenchMarkModel.setSelectedItems(action.payload);
    case ACTIONS.PEER_BENCHMAKR_RESET_SELECTED_ITEMS:
      return peerBenchMarkModel.resetSelectedItems();
    case ACTIONS.PEER_BENCHMAKR_DELETE_SUCCESS:
      return peerBenchMarkModel.setDeletePeerBenchmarkSuccess(action.payload);
    case ACTIONS.PEER_BENCHMARK_SHARE_SUCCESS:
      return peerBenchMarkModel.setSharePeerBenchmarkSuccess(action.payload);
    case ACTIONS.PEER_BENCHMARK_SHAVE_EDIT_DATA:
      return peerBenchMarkModel.setEditPeerBenchmarkData(action.payload);
    case ACTIONS.PEER_BENCHMARK_SET_CURRENT_BENCHMARK_DATA:
      return peerBenchMarkModel.setCurrentBenchmarkData(action.payload);
    case ACTIONS.PEER_BENCHMARK_EDIT_RENAME_ERROR:
      return peerBenchMarkModel.setErrorEditRename(action.payload);
    case ACTIONS.PEER_BENCHMARK_EDIT_SUCCESS:
      return peerBenchMarkModel.setEditBenchmarkSuccess(action.payload);
    case ACTIONS.PEER_BENCHMARK_IS_FILTERING:
      return peerBenchMarkModel.setCurrentFilter(action.payload);
    case ACTIONS.PEER_BENCHMARK_REMOVE_SUCCESS:
      return peerBenchMarkModel.setRemoveSuccess(action.payload);
    case ACTIONS.PEER_BENCHMARK_EDIT_RENAME_PEER_ERROR:
      return peerBenchMarkModel.setErrorEditPeerRename(action.payload);
    case ACTIONS.PEER_BENCHMARK_EDIT_PEER_SUCCESS:
      return peerBenchMarkModel.setEditBenchmarkPeerSuccess(action.payload);
    case ACTIONS.PEER_BENCHMARK_SET_SELECTED_PEER_GROUP_ITEMS:
      return peerBenchMarkModel.setSelectedPeerGroupItems(action.payload);
    case ACTIONS.PEER_BENCHMARK_SET_EXPAND_ALL_METRICS:
      return peerBenchMarkModel.setExpandAllMetrics(action.payload);
    case ACTIONS.PEER_BENCHMARK_SET_COLLAPSE_ALL_METRICS:
      return peerBenchMarkModel.setCollapseAllMetrics(action.payload);
    case ACTIONS.PEER_BENCHMARK_SET_HAS_METRIC_OPEN:
      return peerBenchMarkModel.setHasMetricOpen(action.payload);
    case ACTIONS.PEER_BENCHMARK_SET_SAVED_USER_PREFERENCE:
      return peerBenchMarkModel.setsavedUserPreference(action.payload);
    case ACTIONS.PEER_BENCHMARK_SET_GLOBAL_COMPANY_MAPPING_FILTER:
      return peerBenchMarkModel.setGlobalCompanyMappingsFilter(action.payload);
    default:
      return state;
  }
};

export default peerBenchMarkReducer;
