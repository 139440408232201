import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { ReportingMetric } from "components/visualizations/charts/reporting.tile";
import { Fragment, useEffect, useState } from "react";
import { INSIGHT_BENCHMARK_TYPE, NO_DATA_AVAILABLE_MSG } from "utils/constants";
import { useTileContext } from "../tile.context";

type MetricValueObj = {
  metric: string;
  tooltip?: string;
  value: string | null;
};

type MetricData = {
  companyName: string;
  globalCompanyId: number;
  isBaseCompany?: boolean;
  metricData: MetricValueObj[] | null;
  sectionId: number;
};

type MetricDataTableView = {
  companyName: string;
  isBaseCompany?: boolean;
  metricValue: string | null;
};

const ReportingBoundaryClimateTile = () => {
  const {
    dataset,
    isTableViewActive,
    metadata: { benchmarkMetadata, benchmarkType, isDetailedView },
    associatedMetrics,
  } = useTileContext();

  const BLOCK = "reportingBoundaryClimate";
  const [mappedData, setMappedData] = useState<any>();
  const [noDataToDisplay, setNoDataToDisplay] = useState<boolean>(true);
  const [showNoDataAvail, setShowNoDataAvail] = useState<boolean>(false);

  useEffect(() => {
    setShowNoDataAvail(false);
    setNoDataToDisplay(true);
    if (
      dataset &&
      dataset.length > 0 &&
      benchmarkMetadata &&
      !isTableViewActive &&
      !isDetailedView
    ) {
      let combinedData: MetricData[] = [];
      for (let i = 0; i < dataset.length; i++) {
        let currentValue = dataset[i].metrics[0].metricValue;
        let metricArray: any = [];
        if (currentValue !== null && dataset[i].isBaseCompany) {
          setNoDataToDisplay(false);
          const groupValues = associatedMetrics[0].groupMetricsExcpectedValue;
          groupValues.map((currentMetric: string) => {
            metricArray = [
              ...metricArray,
              {
                metric: currentMetric,
                tooltip: undefined,
                value: currentMetric === currentValue ? "yes" : null,
              },
            ];
          });
        } else if (currentValue !== null && !dataset[i].isBaseCompany) {
          setNoDataToDisplay(false);
          metricArray = [
            ...metricArray,
            {
              metric: currentValue,
              tooltip: undefined,
              value: currentValue,
            },
          ];
        } else {
          metricArray = null;
        }

        combinedData = [
          ...combinedData,
          {
            companyName: dataset[i].companyName,
            globalCompanyId: dataset[i].globalCompanyId,
            isBaseCompany: dataset[i].isBaseCompany,
            metricData: metricArray,
            sectionId:
              dataset[i].companyName === "Peers" ||
              dataset[i].companyName === "Industry"
                ? 0
                : 1,
          },
        ];
      }
      setMappedData(combinedData);
    } else if (
      dataset &&
      dataset.length > 0 &&
      benchmarkMetadata &&
      (isTableViewActive || isDetailedView)
    ) {
      let combinedData: MetricDataTableView[] = [];
      for (let i = 0; i < dataset.length; i++) {
        let currentValue = dataset[i].metrics[0].metricValue;
        if (currentValue !== null) setNoDataToDisplay(false);
        combinedData = [
          ...combinedData,
          {
            companyName: dataset[i].companyName,
            isBaseCompany: dataset[i].isBaseCompany,
            metricValue: currentValue,
          },
        ];
      }
      setMappedData(combinedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTableViewActive, isDetailedView, benchmarkType, dataset]);

  const renderRowTableView = (data: MetricDataTableView, index: number) => {
    let finalValue: string;
    if (data.metricValue === null) {
      finalValue = "*";
      if (!showNoDataAvail) setShowNoDataAvail(true);
    } else finalValue = data.metricValue;

    return (
      <div
        className={classNames(`${BLOCK}__tableViewRow`, {
          [`${BLOCK}__tableViewRow--topBorder`]: index === 0,
        })}
      >
        {benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY && (
          <span className={classNames(`${BLOCK}__tableViewRow--companyName`)}>
            {data.companyName}
          </span>
        )}
        <span className={classNames(`${BLOCK}__tableViewRow--metricValue`)}>
          {finalValue}
        </span>
      </div>
    );
  };

  const renderDetailedPeerView = (data: MetricDataTableView, index: number) => {
    let finalValue: string;
    if (data.metricValue === null) {
      finalValue = NO_DATA_AVAILABLE_MSG;
      if (!showNoDataAvail) setShowNoDataAvail(true);
    } else finalValue = data.metricValue;

    return (
      <div className={classNames(`${BLOCK}__detailedPeerViewRowValues`, {})}>
        <div
          className={classNames(
            `${BLOCK}__detailedPeerViewRowValues--companyName`
          )}
        >
          {data.companyName}
        </div>
        <div
          className={classNames(
            `${BLOCK}__detailedPeerViewRowValues--valueContainer`,
            {}
          )}
        >
          <span
            className={classNames(
              `${BLOCK}__detailedPeerViewRowValues--metricValue`,
              {}
            )}
          >
            {finalValue}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={classNames(`${BLOCK}`)} data-testid={`${BLOCK}`}>
      {!isTableViewActive && !isDetailedView ? (
        <div className={classNames(`${BLOCK}__companyViewContainer`)}>
          {mappedData && !noDataToDisplay ? (
            mappedData.map((data: MetricData, indexData: number) => (
              <div
                key={`${indexData}__d`}
                className={classNames(`${BLOCK}__rowContainer`)}
              >
                {(benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK ||
                  benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY) && (
                  <span
                    className={classNames(
                      `${BLOCK}__rowContainer--companyName`
                    )}
                  >
                    {data.companyName}
                  </span>
                )}
                <div
                  className={classNames(
                    `${BLOCK}__section-data ${BLOCK}__section-data--${
                      data.sectionId === 1 && data.globalCompanyId < 0
                        ? 0
                        : data.sectionId
                    }`
                  )}
                >
                  {data.metricData !== null &&
                    data.metricData?.map(
                      (metric: MetricValueObj, indexMetric: number) => (
                        <Fragment
                          key={`section-metric-${indexData}-${indexMetric}`}
                        >
                          <ReportingMetric
                            metric={metric}
                            sectionId={data.sectionId}
                            isBaseCompany={data.isBaseCompany}
                            globalCompanyId={data.globalCompanyId}
                          />
                        </Fragment>
                      )
                    )}
                </div>
                {data.metricData === null && (
                  <span className={classNames(`${BLOCK}__noData`, {})}>
                    <FormattedMessage id="target.tile.no.data" />
                  </span>
                )}
              </div>
            ))
          ) : (
            <span className={classNames(`${BLOCK}__noData`, {})}>
              <FormattedMessage id="target.tile.no.data" />
            </span>
          )}
        </div>
      ) : isTableViewActive ? (
        <div className={classNames(`${BLOCK}__tableViewContainer`)}>
          {mappedData && !noDataToDisplay ? (
            <div className={classNames(`${BLOCK}__tableView`)}>
              {mappedData.map(
                (data: MetricDataTableView, indexData: number) => (
                  <div
                    className={classNames(`${BLOCK}__tableViewRowContainer`)}
                    key={`${BLOCK}-${indexData}`}
                  >
                    {renderRowTableView(data, indexData)}
                  </div>
                )
              )}
              {showNoDataAvail && (
                <span className={`${BLOCK}__noData--footer`}>
                  <FormattedMessage id="no.data.available" />
                </span>
              )}
            </div>
          ) : (
            <span className={classNames(`${BLOCK}__noData`, {})}>
              <FormattedMessage id="target.tile.no.data" />
            </span>
          )}
        </div>
      ) : (
        isDetailedView && (
          <div className={classNames(`${BLOCK}__detailedPeerViewContainer`)}>
            {mappedData && !noDataToDisplay ? (
              <div>
                <div
                  className={classNames(`${BLOCK}__detailedPeerViewWrapper`)}
                >
                  {mappedData.map(
                    (data: MetricDataTableView, indexData: number) => (
                      <div
                        className={classNames(`${BLOCK}__detailedPeerViewRow`)}
                        key={`${BLOCK}-${indexData}`}
                      >
                        {renderDetailedPeerView(data, indexData)}
                      </div>
                    )
                  )}
                </div>
                {showNoDataAvail && (
                  <span className={`${BLOCK}__noData--footer`}>
                    <FormattedMessage id="no.data.available" />
                  </span>
                )}
              </div>
            ) : (
              <span className={classNames(`${BLOCK}__noData`, {})}>
                <FormattedMessage id="target.tile.no.data" />
              </span>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default ReportingBoundaryClimateTile;
