import classNames from "classnames";
import Button from "../button/button";
import FormattedMessage from "../formatted-message/formatted-message";
import Tooltip, { TooltipPosition } from "../tooltip/tooltip";

type Props = {
  showPanel?: boolean;
  className?: string;
  headerText?: string;
  secondHeaderText?: string;
  children?: JSX.Element;
  dataTest?: string;
  handleClose: Function;
};

const SidePanel = ({
  dataTest,
  showPanel,
  className,
  children,
  headerText,
  secondHeaderText,
  handleClose,
}: Props) => {
  const BLOCK = "SidePanel";
  return showPanel ? (
    <div
      className={classNames(`${BLOCK}`, className)}
      data-testid={dataTest}
      data-test={dataTest}
    >
      <div className={`${BLOCK}__header`}>
        <div>
          <FormattedMessage id={`${headerText}`} />
        </div>
        <div className={`${BLOCK}__close`}>
          <Button
            className={`${BLOCK}__cross`}
            iconName="cross"
            iconHeight={24}
            iconWidth={24}
            onClick={handleClose}
            dataTest="cross-btn"
          />
          <Tooltip position={TooltipPosition.left}>
            <FormattedMessage id={`document-viewer.close.tooltip`} />
          </Tooltip>
        </div>
      </div>
      {secondHeaderText && (
        <div className={`${BLOCK}__secondHeader`}>
          <FormattedMessage id={`${secondHeaderText}`} />
        </div>
      )}
      <div className={`${BLOCK}__body`}>{children}</div>
    </div>
  ) : null;
};

export default SidePanel;
