import React, { useState, useEffect, useRef } from "react";
import Modal from "../shared/modal/modal";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { useSelector, useDispatch } from "react-redux";
import {
  searchRenameError,
  searchRenameSearchSuccess,
  saveNewSearch,
  resetSearchToSave,
  setEditSavedSearchPopUp,
  resetSavedSearch,
  setRefreshSavedSearchesList,
  editSavedSearch,
} from "services/searchlist/searchlist.service";
import classNames from "classnames";
import { addToastMessage } from "services/commons.service";
type props = {
  setShowModal: (value: boolean) => void;
  editSearch: (name: string, description?: string) => void;
};

const EditSearch = ({ setShowModal, editSearch }: props) => {
  const BLOCK = "RenameShortlist";
  const [counts, setCounts] = useState({ name: 0, description: 0 });
  const [disabled, setDisabled] = useState(true);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const [values, setValues] = useState({ name: "", description: "" });
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(false);
  const searchlist = useSelector((state: RootStore) => state.searchlist);
  useEffect(() => {
    nameInputRef.current!.focus();
    if (searchlist.savedSearch) {
      setValues({
        ...values,
        name: searchlist.savedSearch.name ? searchlist.savedSearch.name : "",
        description: searchlist.savedSearch.description
          ? searchlist.savedSearch.description
          : "",
      });
      setCounts({
        ...counts,
        name: searchlist.savedSearch.name
          ? searchlist.savedSearch.name.length
          : 0,
        description: searchlist.savedSearch.description
          ? searchlist.savedSearch.description.length
          : 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchlist.errorRename) {
      setErrorMessage(true);
      nameInputRef.current!.focus();
      if (searchlist.searchToSave) {
        setValues({
          ...values,
          name: searchlist.searchToSave.name!,
          description: searchlist.searchToSave.description!,
        });

        setCounts({
          ...counts,
          name: searchlist.searchToSave.name!.length,
          description: searchlist.searchToSave.description!.length,
        });
      }
      dispatch(searchRenameError(false));
      dispatch(resetSearchToSave());
      dispatch(saveNewSearch(true));
      dispatch(searchRenameSearchSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchlist.errorRename, dispatch]);

  useEffect(() => {
    if (searchlist.renameSearchSuccess) {
      dispatch(saveNewSearch(false));
      setErrorMessage(false);
      setShowModal(false);
      dispatch(setEditSavedSearchPopUp(false));
      dispatch(searchRenameSearchSuccess(false));

      dispatch(
        addToastMessage({
          description: <FormattedMessage id="edit.search-query.success" />,
        })
      );
      dispatch(editSavedSearch(true));
      dispatch(setRefreshSavedSearchesList(true));
      dispatch(resetSavedSearch(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchlist.renameSearchSuccess, dispatch, setShowModal]);

  const trimStartFunc = (value: any) => {
    if (!value) return value;
    return value.replace(/^\s+/g, "");
  };
  const onChangeHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCounts({
      ...counts,
      [event.target.name]: trimStartFunc(event.target.value).length,
    });
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "name") {
      setDisabled(event.target.value.trim().length === 0);
    }
    if (
      (searchlist.savedSearch.name === nameInputRef.current!.value.trim() &&
        searchlist.savedSearch.description ===
          descriptionInputRef.current!.value.trim()) ||
      nameInputRef.current!.value.trim().length === 0
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setErrorMessage(false);
  };
  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      saveSearchHandler();
    }
  };

  const saveSearchHandler = () => {
    if (nameInputRef.current!.value.trim().length === 0 || disabled) {
      return;
    }
    editSearch(
      trimStartFunc(nameInputRef.current!.value).trim(),
      descriptionInputRef.current!.value.trim()
    );
  };
  return (
    <Modal
      show={true}
      header="searchlist.edit.title"
      handleClose={() => {
        setShowModal(false);
        dispatch(setEditSavedSearchPopUp(false));
      }}
      config={false}
    >
      <div className={`${BLOCK} ${BLOCK}--saveSearchlist`} data-testid={`${BLOCK}--saveSearchlist`}>
        <span
          className={classNames(`${BLOCK}__name--saveSearchList`, {
            [`${BLOCK}__name`]: !errorMessage,
            [`${BLOCK}__nameError`]: errorMessage,
          })}
        >
          <FormattedMessage id="searchlist.save.name" />
        </span>
        <span
          className={classNames({
            [`${BLOCK}__input`]: !errorMessage,
            [`${BLOCK}__inputError`]: errorMessage,
            [`${BLOCK}__input--saveSearchList`]: !errorMessage,
            [`${BLOCK}__inputError--saveSearchList`]: errorMessage,
          })}
        >
          <input
            type="text"
            ref={nameInputRef}
            maxLength={200}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={values.name}
            name="name"
            data-testid="nameInput"
            placeholder="Enter Search name"
            className={classNames({
              [`${BLOCK}__input--saveSearchList`]: !errorMessage,
              [`${BLOCK}__inputError--saveSearchList`]: errorMessage,
            })}
          />
        </span>

        {errorMessage ? (
          <span
            className={`${BLOCK}__contentError ${BLOCK}__contentError--saveSearchList`}
            data-testid={`saveSearchListError`}
          >
            <FormattedMessage id="searchlist.report.error" />
          </span>
        ) : (
          <span
            className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}
          >
            {counts.name}
            <FormattedMessage id="shortlist.save.input.character" />
          </span>
        )}

        <span
          className={classNames(
            `${BLOCK}__name--saveSearchList ${BLOCK}__name ${BLOCK}__description-label`
          )}
        >
          <FormattedMessage id="searchlist.save.description" />
        </span>
        <span
          className={classNames(
            `${BLOCK}__input ${BLOCK}__input--saveSearchList`
          )}
        >
          <textarea
            ref={descriptionInputRef}
            maxLength={2000}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={values.description}
            data-testid="descriptionTextArea"
            placeholder="Enter description"
            className={classNames(`${BLOCK}__description`)}
            name="description"
          />
        </span>
        <span className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}>
          {counts.description}
          <FormattedMessage id="searchlist.save.description.character" />
        </span>
        <div className={`${BLOCK}__options ${BLOCK}__options--saveSearchList`}>
          <button
            className={`primary-outline SaveSearchList__btn`}
            onClick={() => {
              setShowModal(false);
              dispatch(setEditSavedSearchPopUp(false));
            }}
            data-testid="primaryOutlineBtn"
          >
            <FormattedMessage id="shortlist.save.button.cancel" />
          </button>
          <button
            onClick={saveSearchHandler}
            className={classNames(`${BLOCK}__continue SaveSearchList__btn`, {
              primary: !disabled,
              "primary-disabled": disabled,
            })}
            data-test="edit-saved-search-btn"
            data-testid="edit-saved-search-btn"
          >
            <FormattedMessage id="shortlist.save.button.save" />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditSearch;
