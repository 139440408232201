import FormattedMessage from "components/shared/formatted-message/formatted-message";
import MetricsAccordion from "./metrics-accordion";
import Icon from "components/shared/icon/icon";
import { useEffect, useState, useMemo, useRef } from "react";
import DropdownContainer from "components/shared/dropdown-container/dropdown-container";
import { CompanyFilter } from "services/search/filters.model";
import {
  APP_SETTING,
  BenchmarkActionTypes,
  BENCHMARK_TYPE,
  DEFAULT_PEER_GROUP_LIST_PAYLOAD,
  DEFAULT_SELECTED_FIELD_PEER_BENCHMARK,
  FILTER_OPTIONS,
  PEER_GROUP_CREATION_PAGE,
  DEFAULT_METRICS_PAYLOAD,
  PEER_BENCHMARK_TABS,
  COMPANY_IDENTIFIER,
  COMPANY_TYPE,
  COUNTRY_CODE,
  SOURCE_PAGE,
} from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { debounce } from "lodash";
import {
  editPeerBenchmark,
  getPeerCompanyFilter,
  loadCompanyFilter,
  loadSuggestedPeerCompanyFilter,
  loadPeerCompanies,
  loadSavedPeerbenchmarks,
  peerBenchmarkToSave,
  resetSelectedItems,
  saveNewPeerBenchmark,
  searchLoadCompanyFilter,
  updateSelectedItems,
  updateSelectedPeerGroupItems,
  setExpandAllMetricsValue,
  setCollapseAllMetricsValue,
  setHasMetricOpenValue,
  loadPeerBenchMarkMetrics,
  setGlobalMappingFilter,
  getGlobalCompanyFilter,
  getGlobalPeerCompanyFilter,
  setGlobalCompanyMappingsFilter,
  setCompanyFilter,
  setPeerCompanyFilter,
} from "services/peer-benchmark/peer-benchmark.service";
import classNames from "classnames";
import PeerCompanies from "components/shared/peer-companies/peer-companies";
import SuggestedPeers from "components/shared/suggested-peers/suggested-peers";
import {
  BenchmarkCompanies,
  MetricFieldGroup,
  PeerBenchmarkState,
  PeerBenchmarkToSave,
  PeerbenchmarkMetric,
  SelectedField,
} from "services/peer-benchmark/peer-benchmark.model";
import moment from "moment";
import SavedPeerModal from "components/shared/save-peer-modal/save-peer-modal";
import {
  addToastMessage,
  loadPeerBenchmarkMetricsSelectionMaxCount,
} from "services/commons.service";
import {
  loadSavedPeerGroupList,
  savePeerGroup,
  setPeerGroupSavedSuccess,
} from "services/insights/insights.service";
import {
  baselineCompanyForPeerGroupPayload,
  CompanyGroupCompany,
  GlobalCompanyGroup,
  GlobalCompanyMapping,
  HybridCompany,
  PeerGroup,
  peerGroupCompanies,
  savePeerGroupNamePayload,
} from "services/insights/insights.model";
import Button from "components/shared/button/button";
import TabbedContent, {
  TabbedContentItemProps,
} from "components/shared/tabbed-content/tabbed-content";
import GlobalSearchModal from "components/shared/global-search/global-search-modal";
import ConfirmationModal from "components/shared/confirmation-modal/confirmation-modal";
import {
  deleteSavedGrouping,
  fetchGlobalCompaniesFilter,
  fetchSavedGroupings,
  saveCompanyGrouping,
} from "services/peer-benchmark/peer-benchmark.api";
import { FormattedMessageString } from "utils/functions";
import {
  isAnyGlobalCompanyInGlobalGroups,
  isCompanyInGlobalGroup,
} from "components/shared/global-search/global-search-dropdown";

type props = {
  handleCreateNew: (state: boolean) => void;
  editView: boolean;
  peerBenchmarkData: any;
};
const CreateNewView = ({
  handleCreateNew,
  editView,
  peerBenchmarkData,
}: props) => {
  const BLOCK = "create-new";
  const [suggestedPeers, setSuggestedPeers] = useState<boolean>(false);
  const [savedPeers, setSavedPeers] = useState<boolean>(false);
  const [msciSearchInput, setMsciSearchInput] = useState<string>("");
  const [cdpSearchInput, setCdpSearchInput] = useState<string>("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [copiedSelectedMetrics, setCopiedSelectedMetrics] = useState<
    SelectedField[]
  >([]);
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState<boolean>(true);
  const peerBenchmarkState = useSelector(
    (state: RootStore) => state.peerBenchmarkState
  );
  const insightsState = useSelector((state: RootStore) => state.insights);
  const commonsState = useSelector((state: RootStore) => state.commons);
  const [selectedPeerCompany, setSelectedPeerCompany] = useState<any[]>([]);
  const [selectedMetrics, setSelectedMetrics] = useState<SelectedField[]>([]);
  const [selectedCheckboxPeerGroup, setSelectedCheckboxPeerGroup] = useState<
    any[]
  >([]);
  const [peerGroups, setPeerGroups] = useState<PeerGroup[]>([]);
  const [peerGroupsLoaded, setPeerGroupsLoaded] = useState<boolean>(false);
  const [companiesUnselected, setCompaniesUnselected] = useState<number[]>([]);
  const [isBaseCompanySuggested, setIsBaseCompanySuggested] =
    useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(
    editView ? (peerBenchmarkData?.benchmarkType === 2 ? 0 : 1) : 0
  );
  const [noResults, setNoResults] = useState<boolean>(false);
  const [metricsSelectionMaxCount, setMetricsSelectionMaxCount] =
    useState<number>(-1);

  const [
    metricsSelectionExceedConfiguredLimit,
    setMetricsSelectionExceedConfiguredLimit,
  ] = useState<boolean>(false);

  //Global search implementation
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showGlobalSearch, setShowGlobalSearch] = useState<boolean>(false);
  const [selectionType, setSelectionType] = useState<string>("");
  const [
    isConfirmationModalTriggerByDropdown,
    setIsConfirmationModalTriggerByDropdown,
  ] = useState<boolean>(false);
  const [changeNonSecCompany, setChangeNonSecCompany] =
    useState<boolean>(false);
  const [selectedGlobalPeerCompanies, setSelectedGlobalPeerCompanies] =
    useState<Partial<GlobalCompanyGroup>[]>([]);
  const [localSelectedBaseline, setLocalSelectedBaseline] =
    useState<CompanyFilter | null>(null);
  const [resetMetrics, setResetMetrics] = useState<boolean>(false);

  const [peerOrder, setPeerOrder] = useState<number>(1);

  const handleTabChange = (index: number) => {
    if (editView) return;
    index === 0 ? setMsciSearchInput("") : setCdpSearchInput("");
    dispatch(setExpandAllMetricsValue(false));
    dispatch(setCollapseAllMetricsValue(false));
    dispatch(setHasMetricOpenValue(false));
    setSelectedMetrics([]);
    setCurrentTab(index);
    setMetricsSelectionExceedConfiguredLimit(false);
    setMetricsSelectionMaxCount(-1);
  };

  useEffect(() => {
    if (!peerBenchmarkState.metrics) {
      dispatch(loadPeerBenchMarkMetrics());
      dispatch(
        loadPeerBenchmarkMetricsSelectionMaxCount(
          APP_SETTING.PeerBenchmarkMetricsSelectionMaxCount
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLabelWrapper = (label: string, type: string) => {
    return (
      <div className={`${BLOCK}__dropdown-label-wrapper`}>
        <div className={`${BLOCK}__dropdown-label`}>
          <FormattedMessage id={label} />
        </div>
        <Button
          className={classNames(`${BLOCK}__non-sec-btn`)}
          formattedText="non.sec.filer.company"
          onClick={() => {
            if (type === COMPANY_TYPE.BASELINE) {
              setSelectionType(COMPANY_TYPE.BASELINE);
              if (
                peerBenchmarkState.companyFilter.length > 0 &&
                peerBenchmarkState.companyFilter[0].countryCode ===
                  COUNTRY_CODE.US
              ) {
                setShowConfirmationModal(true);
              } else {
                setShowGlobalSearch(true);
              }
            } else {
              setShowGlobalSearch(true);
              setSelectionType(COMPANY_TYPE.PEER);
            }
          }}
          dataTest="non-filer-btn"
          iconName={"external-link"}
          iconWidth={24}
          iconHeight={24}
        />
      </div>
    );
  };

  const dropdownHeaders: {
    label: string;
    placeholder: string;
    values: CompanyFilter[];
    options: CompanyFilter[];
    loading: FILTER_OPTIONS[];
    textValue: string;
    type: string;
    showOnEmpty?: boolean;
    labelWrapper?: any;
    beforeSelectBaselineCompany?: any;
    changeNonSecCompany?: boolean;
    localSelectedBaseline?: CompanyFilter | null;
    setLocalSelectedBaseline?: any;
  }[] = [
    {
      label: "select.benchmark.company",
      placeholder: "filter.company.placeholder",
      values: peerBenchmarkState.companyFilter,
      options: peerBenchmarkState.companyFilterOptions,
      loading: peerBenchmarkState.loadingFilter,
      textValue:
        peerBenchmarkState.companyFilter.length > 0
          ? peerBenchmarkState.companyFilter[0].companyName
          : "",
      type: "baseline",
      showOnEmpty: false,
      labelWrapper: getLabelWrapper(
        "select.benchmark.company",
        COMPANY_TYPE.BASELINE
      ),
      beforeSelectBaselineCompany: () => {
        setShowConfirmationModal(true);
        setIsConfirmationModalTriggerByDropdown(true);
      },
      changeNonSecCompany: changeNonSecCompany,
      localSelectedBaseline: localSelectedBaseline,
      setLocalSelectedBaseline: setLocalSelectedBaseline,
    },
    {
      label: "select.benchmark.peers",
      placeholder: "filter.company.placeholder",
      values: peerBenchmarkState.peerCompanyFilter,
      options:
        peerBenchmarkState.companyFilter.length > 0
          ? peerBenchmarkState.peerCompanyFilterOptions?.filter(
              (peerCompany: CompanyFilter) =>
                peerCompany.globalCompanyId !==
                peerBenchmarkState.companyFilter[0].globalCompanyId
            )
          : peerBenchmarkState.peerCompanyFilterOptions,
      loading: peerBenchmarkState.loadingFilter,
      textValue: peerBenchmarkState.peerCompanyFilter[
        peerBenchmarkState.peerCompanyFilter.length - 1
      ]?.companyName
        ? peerBenchmarkState.peerCompanyFilter[
            peerBenchmarkState.peerCompanyFilter.length - 1
          ].companyName
        : "",
      type: "peers",
      showOnEmpty: false,
      labelWrapper: getLabelWrapper("select.insights.peers", COMPANY_TYPE.PEER),
    },
  ];

  const dropdownHeadersForSavePeerModal: {
    label: string;
    placeholder: string;
    values: CompanyFilter[];
    options: CompanyFilter[];
    loading: FILTER_OPTIONS[];
    textValue: string;
    type: string;
    limit?: string;
    showOnEmpty?: boolean;
  }[] = [
    {
      label: "peer.group.add.more",
      placeholder: "filter.company.placeholder",
      values: selectedPeerCompany,
      options:
        peerBenchmarkState.companyFilter.length > 0
          ? peerBenchmarkState.peerCompanyFilterOptions?.filter(
              (peerCompany: CompanyFilter) =>
                peerCompany.globalCompanyId !==
                peerBenchmarkState.companyFilter[0].globalCompanyId
            )
          : peerBenchmarkState.peerCompanyFilterOptions,
      loading: peerBenchmarkState.loadingFilter,
      textValue: peerBenchmarkState.peerCompanyFilter[
        peerBenchmarkState.peerCompanyFilter.length - 1
      ]?.companyName
        ? peerBenchmarkState.peerCompanyFilter[
            peerBenchmarkState.peerCompanyFilter.length - 1
          ].companyName
        : "",
      type: "peers",
      showOnEmpty: false,
    },
  ];

  const tabItems: TabbedContentItemProps[] = [
    {
      text: "msci.label",
      tabId: `${PEER_BENCHMARK_TABS.MSCI}-peerbenchmark-tab`,
      tabPanelId: `${PEER_BENCHMARK_TABS.MSCI}-peerbenchmark-tab-panel`,
      className: `${BLOCK}__tabbed-content-item`,
    },
    {
      text: "cdp.label",
      tabId: `${PEER_BENCHMARK_TABS.CDP}-peerbenchmark-tab`,
      tabPanelId: `${PEER_BENCHMARK_TABS.CDP}-peerbenchmark-tab-panel`,
      className: `${BLOCK}__tabbed-content-item`,
    },
  ];

  const tabItemsDetails = [
    {
      peerbenchmarkTabName: PEER_BENCHMARK_TABS.MSCI,
      peerbenchmarkId: BENCHMARK_TYPE.MSCI,
      searchAction: setMsciSearchInput,
      searchValue: msciSearchInput,
    },
    {
      peerbenchmarkTabName: PEER_BENCHMARK_TABS.CDP,
      peerbenchmarkId: BENCHMARK_TYPE.CDP,
      searchAction: setCdpSearchInput,
      searchValue: cdpSearchInput,
    },
  ];
  useEffect(
    () => () => {
      if (!editView) {
        dispatch(resetSelectedItems());
        dispatch(updateSelectedPeerGroupItems([]));
        setLocalSelectedBaseline(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    dispatch(loadSavedPeerGroupList(DEFAULT_PEER_GROUP_LIST_PAYLOAD));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (insightsState.savedPeerGroupList && !peerGroupsLoaded) {
      setPeerGroups(insightsState.savedPeerGroupList);
      setPeerGroupsLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.savedPeerGroupList]);

  useEffect(() => {
    if (
      editView &&
      selectedMetrics.length > 0 &&
      copiedSelectedMetrics.length === 0
    ) {
      setCopiedSelectedMetrics(selectedMetrics);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMetrics]);

  useEffect(() => {
    if (insightsState.insightPeerGroupSuccess) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id={"save.peer.group.success"} />,
        })
      );
      dispatch(setPeerGroupSavedSuccess(false));
      dispatch(loadSavedPeerGroupList(DEFAULT_PEER_GROUP_LIST_PAYLOAD));
      setPeerGroupsLoaded(false);
      setSavedPeers(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.insightPeerGroupSuccess]);

  useEffect(() => {
    if (editView) {
      let globalBaseCompany =
        peerBenchmarkData.globalCompanyMappings &&
        peerBenchmarkData.globalCompanyMappings.length > 0 &&
        peerBenchmarkData.globalCompanyMappings.find(
          (g: Partial<GlobalCompanyGroup>) => g.isBaseMapping
        )
          ? peerBenchmarkData.globalCompanyMappings
              .find((g: Partial<GlobalCompanyGroup>) => g.isBaseMapping)
              .globalCompanyMappingDetails.find(
                (m: GlobalCompanyMapping) => m.isDefault
              )
          : undefined;

      let baselineCompany = globalBaseCompany
        ? [globalBaseCompany]
        : peerBenchmarkData.benchmarkCompanies.filter(
            (company: any) => company.isBaseCompany === true
          );

      if (baselineCompany.length > 0 && !globalBaseCompany) {
        dispatch(loadSuggestedPeerCompanyFilter(baselineCompany[0].cikNumber));
      }

      let peerCompanies = peerBenchmarkData.benchmarkCompanies.filter(
        (company: any) =>
          company.isBaseCompany === false &&
          (!peerBenchmarkData.globalCompanyMappings ||
            (peerBenchmarkData.globalCompanyMappings &&
              isCompanyInGlobalGroup(
                peerBenchmarkData.globalCompanyMappings ?? [],
                company.globalCompanyId ?? 0
              ) === 0))
      );

      let baselineCompanyUpdated = {
        ...baselineCompany[0],
        companyName: baselineCompany[0]?.globalCompany?.companyName,
        companyId: baselineCompany[0]?.globalCompany?.companyId,
        isSuggestedPeerCompany: false,
        countryCode: baselineCompany[0]?.globalCompany?.countryCode ?? "",
      };
      let setPeerCompanies: any = [];
      peerCompanies.forEach((peer: any) => {
        if (
          peerBenchmarkState?.suggestedPeerCompanyFilter?.filter(
            (peers: any) => peers.globalCompanyId === peer.globalCompanyId
          ).length > 0
        ) {
          setPeerCompanies.push({ ...peer, isSuggestedPeerCompany: true });
        } else {
          setPeerCompanies.push({ ...peer, isSuggestedPeerCompany: false });
        }
      });

      const finalPeerCompanies = setPeerCompanies.map((company: any) => {
        return {
          ...company,
          companyName: company.globalCompany.companyName,
          companyId: company.globalCompany.companyId,
          isSuggestedPeerCompany: company.isSuggestedPeerCompany,
        };
      });

      dispatch(setCompanyFilter([baselineCompanyUpdated]));
      setLocalSelectedBaseline(baselineCompanyUpdated);
      dispatch(setPeerCompanyFilter(finalPeerCompanies));

      dispatch(
        setGlobalCompanyMappingsFilter(
          [
            ...peerBenchmarkData.globalCompanyMappings.map(
              (gcg: GlobalCompanyGroup) => {
                let defaultCompany = gcg.globalCompanyMappingDetails.find(
                  (m: GlobalCompanyMapping) => m.isDefault
                );
                let defaultCompanyInCompanies =
                  peerBenchmarkData.benchmarkCompanies.find(
                    (icf: any) =>
                      defaultCompany &&
                      icf.globalCompanyId === defaultCompany.globalCompanyId
                  );

                return {
                  ...gcg,
                  displayOrder:
                    defaultCompanyInCompanies &&
                    defaultCompanyInCompanies.displayOrder
                      ? defaultCompanyInCompanies.displayOrder
                      : 0,
                };
              }
            ),
          ].sort((a: GlobalCompanyGroup, b: GlobalCompanyGroup) => {
            return (a.displayOrder ?? 0) > (b.displayOrder ?? 0)
              ? 1
              : (a.displayOrder ?? 0) < (b.displayOrder ?? 0)
              ? -1
              : 0;
          })
        )
      );

      setPeerOrder(
        peerBenchmarkData.benchmarkCompanies &&
          peerBenchmarkData.benchmarkCompanies.length
          ? peerBenchmarkData.benchmarkCompanies.length + 1
          : 1
      );
      let companiesAdded = finalPeerCompanies.map(
        (comp: any) => comp.globalCompanyId ?? 0
      );
      // Peer group selection
      let tmpSelectedCheckboxPeerGroup = [...selectedCheckboxPeerGroup];
      insightsState.savedPeerGroupList
        ?.filter(
          (pg: PeerGroup) =>
            !selectedCheckboxPeerGroup.includes(pg.companyGroupId)
        )
        .forEach((pgp: PeerGroup) => {
          if (
            pgp.companyGroupCompanies.every((comp: CompanyGroupCompany) =>
              companiesAdded.includes(comp.globalCompanyId ?? 0)
            )
          ) {
            tmpSelectedCheckboxPeerGroup.push(pgp.companyGroupId);
          }
        });
      setSelectedCheckboxPeerGroup(tmpSelectedCheckboxPeerGroup);

      dispatch(
        updateSelectedItems(
          finalPeerCompanies.map((company: any) => company.globalCompanyId ?? 0)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editView]);

  useEffect(() => {
    if (
      editView &&
      peerBenchmarkState.companyFilter.length > 0 &&
      selectedMetrics.length > 0 &&
      copiedSelectedMetrics.length > 0
    ) {
      let noDifference = true;
      //DETERMINE IF BASE COMPANY IS DIFFERENT
      let initialGlobalBaseCompany =
        peerBenchmarkData.globalCompanyMappings &&
        peerBenchmarkData.globalCompanyMappings.length
          ? peerBenchmarkData.globalCompanyMappings.find(
              (g: Partial<GlobalCompanyGroup>) => g.isBaseMapping
            )
          : undefined;

      let baselineCompany = initialGlobalBaseCompany
        ? undefined
        : peerBenchmarkData.benchmarkCompanies.find(
            (company: any) => company.isBaseCompany === true
          );

      if (
        (initialGlobalBaseCompany &&
          (!peerBenchmarkState.globalCompanyMappingsFilter.find(
            (g: Partial<GlobalCompanyGroup>) => g.isBaseMapping
          ) ||
            (peerBenchmarkState.globalCompanyMappingsFilter.find(
              (g: Partial<GlobalCompanyGroup>) => g.isBaseMapping
            ) &&
              peerBenchmarkState.globalCompanyMappingsFilter.find(
                (g: Partial<GlobalCompanyGroup>) => g.isBaseMapping
              )?.globalCompanyMappingId !==
                initialGlobalBaseCompany.globalCompanyMappingId))) ||
        (peerBenchmarkState.companyFilter &&
          peerBenchmarkState.companyFilter.length > 0 &&
          baselineCompany &&
          baselineCompany.globalCompanyId !==
            peerBenchmarkState.companyFilter[0].globalCompanyId)
      ) {
        noDifference = false;
      }

      //DETERMINE IF PEER COMPANIES ARE DIFFERENT
      let preSelectedCompanies = peerBenchmarkData.benchmarkCompanies.filter(
        (company: any) =>
          company.isBaseCompany === false &&
          (!peerBenchmarkData.globalCompanyMappings ||
            (peerBenchmarkData.globalCompanyMappings &&
              isCompanyInGlobalGroup(
                peerBenchmarkData.globalCompanyMappings ?? [],
                company.globalCompanyId ?? 0
              ) === 0))
      );
      if (
        peerBenchmarkState.peerCompanyFilter.length !==
        preSelectedCompanies.length
      ) {
        noDifference = false;
      } else {
        if (
          preSelectedCompanies.length > 0 &&
          peerBenchmarkState.peerCompanyFilter.length > 0 &&
          preSelectedCompanies.some(
            (preSelectedPeer: any) =>
              peerBenchmarkState.peerCompanyFilter.filter(
                (pc: CompanyFilter) =>
                  preSelectedPeer.globalCompanyId === pc.globalCompanyId
              ).length === 0
          )
        ) {
          noDifference = false;
        }
      }

      //DETERMINE IF GLOBAL MAPPINGS (JUST PEERS) ARE DIFFERENT
      if (
        peerBenchmarkData.globalCompanyMappings &&
        peerBenchmarkData.globalCompanyMappings.length
      ) {
        const preSelectedMappings =
          peerBenchmarkData.globalCompanyMappings.filter(
            (g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping
          );

        if (
          preSelectedMappings.length !==
          peerBenchmarkState.globalCompanyMappingsFilter.filter(
            (g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping
          ).length
        ) {
          noDifference = false;
        } else {
          if (
            preSelectedMappings.length &&
            preSelectedMappings.some(
              (psm: Partial<GlobalCompanyGroup>) =>
                peerBenchmarkState.globalCompanyMappingsFilter.filter(
                  (g: Partial<GlobalCompanyGroup>) =>
                    g.globalCompanyMappingId === psm.globalCompanyMappingId
                ).length === 0
            )
          ) {
            noDifference = false;
          }
        }
      }
      if (
        (!peerBenchmarkData.globalCompanyMappings ||
          peerBenchmarkData.globalCompanyMappings.length === 0) &&
        peerBenchmarkState.globalCompanyMappingsFilter.filter(
          (g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping
        ).length > 0
      ) {
        noDifference = false;
      }

      if (selectedMetrics.length !== copiedSelectedMetrics.length) {
        //change in the number of selected metrics
        noDifference = false;
      } else {
        for (let i = 0; i < selectedMetrics.length; i++) {
          const currentMetric = selectedMetrics[i];

          const filteredMetric = copiedSelectedMetrics.filter(
            (metric: any) => metric.fieldName === currentMetric.fieldName
          );

          if (filteredMetric.length === 0) {
            //means the current metric was not preselected and thus a change has been made
            noDifference = false;
            break;
          }
        }
      }
      if (noDifference) {
        setChangesMade(false);
      } else {
        setChangesMade(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    peerBenchmarkState.peerCompanyFilter,
    peerBenchmarkState.companyFilter,
    copiedSelectedMetrics,
    selectedMetrics,
    peerBenchmarkState.globalCompanyMappingsFilter,
  ]);

  const selectCompany = (
    companyFilter: CompanyFilter,
    type: any,
    fromGlobalSearch: boolean = false
  ) => {
    if (type === "peers") {
      let tmpPeerOrder = peerOrder;
      dispatch(
        setPeerCompanyFilter([
          ...peerBenchmarkState.peerCompanyFilter,
          { ...companyFilter, displayOrder: tmpPeerOrder++ },
        ])
      );
      dispatch(
        updateSelectedItems([
          ...peerBenchmarkState.selectedItems,
          companyFilter.globalCompanyId,
        ])
      );

      setPeerOrder(tmpPeerOrder);
    } else {
      dispatch(setCompanyFilter([companyFilter]));
      setChangeNonSecCompany(false);
      if (!fromGlobalSearch) {
        dispatch(loadSuggestedPeerCompanyFilter(companyFilter.cikNumber));
        dispatch(
          setGlobalMappingFilter(
            peerBenchmarkState.globalCompanyMappingsFilter.filter(
              (item: Partial<GlobalCompanyGroup>) => !item.isBaseMapping
            )
          )
        );
      }
    }
  };

  const handleUnselectCompany = (
    companyFilter: CompanyFilter,
    type: string
  ) => {
    if (type === "peers") {
      dispatch(
        setPeerCompanyFilter(
          peerBenchmarkState.peerCompanyFilter.filter(
            ({ globalCompanyId }) =>
              globalCompanyId !== companyFilter.globalCompanyId
          )
        )
      );
      dispatch(
        updateSelectedItems(
          peerBenchmarkState.selectedItems.filter(
            (item: number) => item !== companyFilter.globalCompanyId
          )
        )
      );
    } else {
      dispatch(setCompanyFilter([]));
      dispatch(
        setGlobalMappingFilter(
          peerBenchmarkState.globalCompanyMappingsFilter.filter(
            (item: Partial<GlobalCompanyGroup>) => !item.isBaseMapping
          )
        )
      );
    }
  };

  const companiesUnselectedHandler = (punseletedCompanies: number[]) => {
    let tmpCompaniesUnselected = [...companiesUnselected].concat(
      punseletedCompanies.filter(
        (comp: number) => !companiesUnselected.includes(comp)
      )
    );

    setCompaniesUnselected(tmpCompaniesUnselected);
  };

  const addSuggestedPeers = (
    selectedChekbox: number[],
    selectedPeerGroups: number[]
  ) => {
    let tmpIsBaseCompanySuggested = false;
    let peerCompanies: CompanyFilter[] = [
      ...peerBenchmarkState.peerCompanyFilter,
    ];
    let tmpGlobalCompanyMappings: Partial<GlobalCompanyGroup>[] = [
      ...peerBenchmarkState.globalCompanyMappingsFilter,
    ];
    peerCompanies = peerCompanies.filter(
      (pcf: CompanyFilter) =>
        !companiesUnselected.includes(pcf.globalCompanyId ?? 0)
    );

    for (
      let i = 0;
      i < peerBenchmarkState.suggestedPeerCompanyFilter.length;
      i++
    ) {
      const { globalCompanyId } =
        peerBenchmarkState.suggestedPeerCompanyFilter[i];
      if (
        !selectedChekbox.includes(globalCompanyId ?? 0) &&
        !!peerCompanies.find(
          (peerComp) => peerComp.globalCompanyId === globalCompanyId
        )
      ) {
        peerCompanies = peerCompanies.filter(
          (peer) => peer.globalCompanyId !== globalCompanyId
        );
      }
    }
    let tmpPeerOrder = peerOrder;
    selectedChekbox.forEach((globalCompanyId) => {
      if (
        !peerCompanies.find(
          (peerComp) => peerComp.globalCompanyId === globalCompanyId
        )
      ) {
        const company = peerBenchmarkState.suggestedPeerCompanyFilter.find(
          (peerComp: any) => peerComp.globalCompanyId === globalCompanyId
        );
        if (company) {
          company.isSuggestedPeerCompany = true;
          peerCompanies.push({ ...company, displayOrder: tmpPeerOrder++ });
        }
      }
    });

    if (selectedPeerGroups && selectedPeerGroups.length) {
      setSelectedCheckboxPeerGroup(selectedPeerGroups);
      let tmpPeerGroupSelection = insightsState.savedPeerGroupList.filter(
        (group: PeerGroup) => selectedPeerGroups.includes(group.companyGroupId)
      );

      tmpPeerGroupSelection.forEach((pg: PeerGroup) => {
        pg.companyGroupCompanies.forEach(
          (companyGroup: CompanyGroupCompany) => {
            if (
              !peerCompanies.find(
                (companyFilter: CompanyFilter) =>
                  companyFilter.globalCompanyId === companyGroup.globalCompanyId
              ) &&
              companyGroup.globalCompanyId !==
                peerBenchmarkState.companyFilter[0].globalCompanyId
            ) {
              peerCompanies.push({
                companyName: companyGroup?.company?.companyName ?? "",
                companyId: companyGroup?.company?.companyId ?? 0,
                externalCompanyId:
                  companyGroup?.company?.externalCompanyId ?? 0,
                cikNumber: companyGroup.cikNumber,
                ticker: companyGroup?.company?.ticker ?? "",
                isSuggestedPeerCompany: true,
                globalCompanyId: companyGroup.globalCompanyId ?? 0,
                displayOrder: tmpPeerOrder++,
              });
            }
            if (
              companyGroup.globalCompanyId ===
              peerBenchmarkState.companyFilter[0].globalCompanyId
            ) {
              tmpIsBaseCompanySuggested = true;
            }
          }
        );

        if (pg.globalCompanyMappings) {
          pg.globalCompanyMappings
            .filter((g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping)
            .forEach((g: Partial<GlobalCompanyGroup>) => {
              if (
                !isAnyGlobalCompanyInGlobalGroups(
                  tmpGlobalCompanyMappings,
                  g
                ) ||
                tmpGlobalCompanyMappings.length === 0
              ) {
                tmpGlobalCompanyMappings.push({
                  ...g,
                  displayOrder: tmpPeerOrder++,
                });
              }
            });
        }
      });
    }
    if (isBaseCompanySuggested !== tmpIsBaseCompanySuggested)
      setIsBaseCompanySuggested(tmpIsBaseCompanySuggested);
    dispatch(setPeerCompanyFilter(peerCompanies));
    if (tmpGlobalCompanyMappings.length) {
      dispatch(setGlobalCompanyMappingsFilter(tmpGlobalCompanyMappings));
    }
    setSuggestedPeers(false);

    if (tmpPeerOrder > peerOrder) setPeerOrder(tmpPeerOrder);
  };

  const unselectCompany = (companyFilter: CompanyFilter, type: string) => {
    dispatch(
      setPeerCompanyFilter(
        peerBenchmarkState.peerCompanyFilter.filter(
          ({ globalCompanyId }) =>
            globalCompanyId !== companyFilter.globalCompanyId
        )
      )
    );

    dispatch(
      loadPeerCompanies(
        peerBenchmarkState.peerCompanyFilterOptions.filter(
          ({ globalCompanyId }) =>
            globalCompanyId !== companyFilter.globalCompanyId
        )
      )
    );
    dispatch(
      updateSelectedItems(
        peerBenchmarkState.selectedItems.filter(
          (item: number) => item !== companyFilter.globalCompanyId
        )
      )
    );
    if (peerBenchmarkState.selectedPeerGroupItems.length > 0) {
      let tmpSelectedCheckboxPeerGroup = insightsState.savedPeerGroupList
        .filter((pg: PeerGroup) =>
          peerBenchmarkState.selectedPeerGroupItems.includes(pg.companyGroupId)
        )
        .filter((pg: PeerGroup) =>
          pg.companyGroupCompanies.every(
            (cgc: CompanyGroupCompany) =>
              cgc.globalCompanyId !== companyFilter.globalCompanyId
          )
        )
        .map((pg: PeerGroup) => pg.companyGroupId);
      setSelectedCheckboxPeerGroup(tmpSelectedCheckboxPeerGroup);
      dispatch(updateSelectedPeerGroupItems(tmpSelectedCheckboxPeerGroup));
    }
  };

  const unSelectCompanyFromFilter = (pcikNumberCompanies: number[] = []) => {
    dispatch(
      setPeerCompanyFilter(
        peerBenchmarkState.peerCompanyFilter.filter(
          ({ globalCompanyId }) =>
            !pcikNumberCompanies.includes(globalCompanyId ?? 0)
        )
      )
    );
    dispatch(
      loadPeerCompanies(
        peerBenchmarkState.peerCompanyFilterOptions.filter(
          ({ globalCompanyId }) =>
            !pcikNumberCompanies.includes(globalCompanyId ?? 0)
        )
      )
    );
  };

  const debounceChangeHandler = useMemo(() => {
    return debounce((e: any, type: any) => {
      if (type === "baseline") {
        dispatch(getGlobalCompanyFilter(e.target.value));
      } else {
        dispatch(getGlobalPeerCompanyFilter(e.target.value));
      }
    }, 300);
  }, [dispatch]);
  const SavePeerGroupHandler = (peerGroupName: string) => {
    let displayOrder = 1;
    let baselineGlobalCompany =
      peerBenchmarkState.globalCompanyMappingsFilter.find(
        (g: Partial<GlobalCompanyGroup>) => g.isBaseMapping
      );

    let baselineCompany: baselineCompanyForPeerGroupPayload = {
      cikNumber: peerBenchmarkState.companyFilter[0]
        ? peerBenchmarkState.companyFilter[0].cikNumber
        : "",
      company: peerBenchmarkState.companyFilter[0] ?? "",
    };

    let companyGroupCompanies: peerGroupCompanies[] = [];

    selectedPeerCompany.forEach((company) => {
      let peerCompany: any = {
        cikNumber: company.cikNumber,
        displayOrder: company.displayOrder ?? displayOrder++,
        isSuggestedPeerCompany:
          company.isSuggestedPeerCompany === true ? true : false,
        company: company,
        globalCompanyId: company.globalCompanyId,
      };
      companyGroupCompanies.push(peerCompany);
    });

    let payload: savePeerGroupNamePayload = {
      companyGroupName: peerGroupName,
      companyGroupCompanies,
      globalCompanyMappings: selectedGlobalPeerCompanies.length
        ? selectedGlobalPeerCompanies.map((g: Partial<GlobalCompanyGroup>) => ({
            globalCompanyMappingId: g.globalCompanyMappingId ?? 0,
            isBaseMapping: g.isBaseMapping,
          }))
        : [],
    };

    if (!baselineGlobalCompany)
      payload = {
        ...payload,
        baselineCompanyCikNumber: peerBenchmarkState.companyFilter[0]
          ? peerBenchmarkState.companyFilter[0].cikNumber
          : "",
        baselineCompany,
      };
    else {
      if (payload.globalCompanyMappings)
        payload.globalCompanyMappings.splice(0, 0, {
          globalCompanyMappingId: baselineGlobalCompany?.globalCompanyMappingId,
          isBaseMapping: baselineGlobalCompany.isBaseMapping,
        });
      else
        payload.globalCompanyMappings = [
          {
            globalCompanyMappingId:
              baselineGlobalCompany?.globalCompanyMappingId,
            isBaseMapping: baselineGlobalCompany.isBaseMapping,
          },
        ];
    }
    dispatch(savePeerGroup(payload, PEER_GROUP_CREATION_PAGE.PEER_BENCHMARK));
  };

  useEffect(() => {
    //Remove currentTab for implementing MSCI metrics constraint
    if (
      currentTab === 1 &&
      commonsState.peerBenchmarkMetricsSelectionMaxCount &&
      commonsState.peerBenchmarkMetricsSelectionMaxCount.length > currentTab &&
      commonsState.peerBenchmarkMetricsSelectionMaxCount[currentTab][
        tabItemsDetails[currentTab].peerbenchmarkTabName
      ]
    ) {
      setMetricsSelectionMaxCount(
        commonsState.peerBenchmarkMetricsSelectionMaxCount[currentTab][
          tabItemsDetails[currentTab].peerbenchmarkTabName
        ]
      );
    }
  }, [commonsState.peerBenchmarkMetricsSelectionMaxCount && currentTab]);

  useEffect(() => {
    if (
      peerBenchmarkState.companyFilter.length > 0 &&
      selectedMetrics.length > 0 &&
      ((editView && changesMade) || !editView)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    //Remove currentTab for implementing MSCI metrics constraint
    if (
      currentTab === 1 &&
      commonsState.peerBenchmarkMetricsSelectionMaxCount &&
      commonsState.peerBenchmarkMetricsSelectionMaxCount.length > currentTab &&
      commonsState.peerBenchmarkMetricsSelectionMaxCount[currentTab][
        tabItemsDetails[currentTab].peerbenchmarkTabName
      ] &&
      metricsSelectionMaxCount !== -1
    ) {
      setMetricsSelectionExceedConfiguredLimit(
        selectedMetrics.length >= metricsSelectionMaxCount
      );
    }
  }, [selectedMetrics, peerBenchmarkState.companyFilter, changesMade]);

  useEffect(() => {
    setSelectedPeerCompany(peerBenchmarkState.peerCompanyFilter);
    setSelectedGlobalPeerCompanies(
      peerBenchmarkState.globalCompanyMappingsFilter
        ? peerBenchmarkState.globalCompanyMappingsFilter.filter(
            (g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping
          )
        : []
    );
  }, [
    peerBenchmarkState.peerCompanyFilter,
    savedPeers,
    peerBenchmarkState.globalCompanyMappingsFilter,
  ]);

  const getBenchmarkCompaniesPayload = (
    tmpCompany: CompanyFilter,
    isBaseCompany: boolean,
    displayOrder: number,
    isSuggestedPeerCompany?: boolean
  ) => {
    return {
      cikNumber: tmpCompany.cikNumber,
      isBaseCompany: isBaseCompany,
      displayOrder: displayOrder,
      isSuggestedPeerCompany:
        tmpCompany.isSuggestedPeerCompany === true ? true : false,
      globalCompany: {
        companyName: tmpCompany.companyName,
        cikNumber: tmpCompany.cikNumber,
        globalCompanyId: tmpCompany.globalCompanyId,
      },
      globalCompanyId: tmpCompany.globalCompanyId,
    };
  };
  const handleSaveNewBenchmark = (peerbenchmarkId: number) => {
    let actualMetrics: SelectedField[] = [
      DEFAULT_SELECTED_FIELD_PEER_BENCHMARK,
    ];
    let baselineCompany = getBenchmarkCompaniesPayload(
      peerBenchmarkState.companyFilter[0],
      true,
      0,
      false
    );

    let actualBenchmarkCompanies: BenchmarkCompanies[] = [];
    if (
      peerBenchmarkState.globalCompanyMappingsFilter.length === 0 ||
      (peerBenchmarkState.globalCompanyMappingsFilter &&
        peerBenchmarkState.globalCompanyMappingsFilter.filter(
          (g: Partial<GlobalCompanyGroup>) => g.isBaseMapping
        ).length === 0)
    )
      actualBenchmarkCompanies.push(baselineCompany);

    let peerDisplayOrderValues: any[] =
      peerBenchmarkState.globalCompanyMappingsFilter
        .filter((gc: Partial<GlobalCompanyGroup>) => !gc.isBaseMapping)
        .map((gc: Partial<GlobalCompanyGroup>) => gc.displayOrder)
        .concat(
          peerBenchmarkState.peerCompanyFilter.map(
            (pc: CompanyFilter) => pc.displayOrder
          )
        )
        .sort();

    let sortedDisplayOrder = peerDisplayOrderValues.sort(
      (a: number, b: number) => a - b
    );

    actualBenchmarkCompanies = actualBenchmarkCompanies.concat(
      peerBenchmarkState.peerCompanyFilter.map(
        (peer: CompanyFilter, i: number) =>
          getBenchmarkCompaniesPayload(
            peer,
            false,
            sortedDisplayOrder.indexOf(peer.displayOrder) + 1 ?? i + 1,
            peer.isSuggestedPeerCompany
          )
      )
    );

    const currentOtherIndicatorIndex = peerBenchmarkState.metrics
      .filter(
        (peerType: PeerbenchmarkMetric) =>
          peerType.PeerbenchmarkId === peerbenchmarkId
      )[0]
      .PeerbenchmarkCategories.findIndex(
        (metric: any) => metric.fieldGroups.length > 0
      );

    let fieldGroupNames: {
      fieldGroupName: string;
      isAllGroup: boolean;
      accessed: boolean;
    }[] = [];
    if (currentOtherIndicatorIndex !== -1) {
      fieldGroupNames = peerBenchmarkState.metrics
        .filter(
          (peerType: PeerbenchmarkMetric) =>
            peerType.PeerbenchmarkId === peerbenchmarkId
        )[0]
        .PeerbenchmarkCategories[currentOtherIndicatorIndex].fieldGroups.map(
          (fg: MetricFieldGroup) => {
            return {
              fieldGroupName: fg.fieldGroupName,
              isAllGroup:
                selectedMetrics.filter(
                  (selectedField: SelectedField, i: number) =>
                    selectedField.fieldGroupName === fg.fieldGroupName
                ).length === fg.fields.length,
              accessed: false,
            };
          }
        );
    }

    let newDisplayOrder = 1;
    let filterSelectedFields: SelectedField[] = selectedMetrics.filter(
      (selectedField: SelectedField, i: number) => {
        if (selectedField.fieldGroupName === null) {
          newDisplayOrder += 1;
          selectedField.displayOrder = newDisplayOrder;
          return selectedField;
        } else {
          let fieldGroup = fieldGroupNames?.find(
            (fgn: { fieldGroupName: string; isAllGroup: boolean }) =>
              fgn.fieldGroupName === selectedField.fieldGroupName
          );
          if (fieldGroup && fieldGroup.isAllGroup && !fieldGroup.accessed) {
            fieldGroupNames[
              fieldGroupNames.findIndex(
                (fgn: { fieldGroupName: string; isAllGroup: boolean }) =>
                  fgn.fieldGroupName === selectedField.fieldGroupName
              )
            ].accessed = true;
            newDisplayOrder += 1;
            selectedField.fieldName = null;
            selectedField.displayOrder = newDisplayOrder;
            return selectedField;
          } else if (fieldGroup && !fieldGroup.isAllGroup) {
            newDisplayOrder += 1;
            selectedField.fieldGroupName = null;
            selectedField.displayOrder = newDisplayOrder;
            return selectedField;
          }
        }
        return null;
      }
    );

    let tmpGlobalCompanyMappings: {
      globalCompanyMappingId: number;
      isBaseMapping: boolean;
    }[] =
      peerBenchmarkState.globalCompanyMappingsFilter &&
      peerBenchmarkState.globalCompanyMappingsFilter.length
        ? peerBenchmarkState.globalCompanyMappingsFilter.map(
            (globalComp: Partial<GlobalCompanyGroup>) => {
              return {
                globalCompanyMappingId: globalComp.globalCompanyMappingId ?? 1,
                isBaseMapping: globalComp.isBaseMapping ?? false,
                displayOrder:
                  peerDisplayOrderValues.indexOf(globalComp.displayOrder) + 1 ??
                  0,
              };
            }
          )
        : [];

    const payload: PeerBenchmarkToSave = {
      benchmarkTitle: getNewBenchmarkTitle(
        peerBenchmarkState.companyFilter[0].companyName
      ),
      benchmarkType: peerbenchmarkId,
      benchmarkCompanies: actualBenchmarkCompanies,
      metrics:
        currentTab === 0
          ? actualMetrics.concat(filterSelectedFields)
          : filterSelectedFields,
      globalCompanyMappings: tmpGlobalCompanyMappings,
    };

    if (editView) {
      const updatedPayload = {
        benchmark: {
          ...peerBenchmarkData,
          benchmarkCompanies: actualBenchmarkCompanies,
          metrics:
            currentTab === 0
              ? actualMetrics.concat(filterSelectedFields)
              : filterSelectedFields,
          globalCompanyMappings: tmpGlobalCompanyMappings,
        },

        trackingType: BenchmarkActionTypes.Edit_Benchmark_Peer,
      };
      dispatch(editPeerBenchmark(updatedPayload));
    } else {
      dispatch(peerBenchmarkToSave(payload));
      dispatch(saveNewPeerBenchmark(payload));
    }
  };

  const getNewBenchmarkTitle = (companyName: string) => {
    let today = moment.utc(Date.now()).format("YYYY-MM-DD_hh_mm_ss");
    return `${companyName}_${today}`;
  };

  const getPeerbenchmarkTab = (index: number) => {
    return (
      <div
        role="tabpanel"
        id={tabItems[index].tabPanelId}
        aria-labelledby={tabItems[index].tabId}
        className={`${BLOCK}__content--rightSide`}
        key={`${index}__tab`}
      >
        <div className={`${BLOCK}__metrics-header-container`}>
          <span className={`${BLOCK}__metrics-header`}>
            {selectedMetrics.length === 0 ? (
              <FormattedMessage id="peer-benchmarks.select.metrics.header" />
            ) : (
              <FormattedMessage
                id="peerbenchmark.selected.metrics"
                values={{ total: selectedMetrics.length }}
              />
            )}
            {currentTab === 1 && (
              <span className={`${BLOCK}__metrics-header--gray`}>
                <FormattedMessage
                  id="peerbenchmark.selected.metrics.max"
                  values={{ maxCount: metricsSelectionMaxCount }}
                />
              </span>
            )}
          </span>
          <div className={`${BLOCK}__metrics--search-options-wrapper`}>
            <div className={classNames(`${BLOCK}__metrics--searchBar`)}>
              <textarea
                ref={textareaRef}
                value={tabItemsDetails[index].searchValue}
                className={`${BLOCK}__input`}
                data-test="input-text-area"
                onChange={(e) => {
                  tabItemsDetails[index].searchAction(e.target.value);
                }}
                placeholder={"Search for metrics"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    tabItemsDetails[index].searchAction(
                      tabItemsDetails[index].searchValue
                    );
                  }
                }}
                data-testid="search-input"
              />
              {tabItemsDetails[index].searchValue.length !== 0 ? (
                <span
                  onClick={() => tabItemsDetails[index].searchAction("")}
                  data-testid="reset-search-input"
                >
                  <Icon
                    className={classNames(`${BLOCK}__search-icon`)}
                    name="cross"
                    height={24}
                  />
                </span>
              ) : (
                <Icon
                  className={classNames(`${BLOCK}__search-icon`)}
                  name="search"
                  height={24}
                />
              )}
            </div>

            <div
              className={classNames(`${BLOCK}__metrics--search`, {
                [`${BLOCK}__metrics--search-no-metrics`]:
                  !peerBenchmarkState.metrics ||
                  peerBenchmarkState.metrics[index].PeerbenchmarkCategories
                    .length === 0,
              })}
            >
              {
                <Button
                  dataTest="expand-collapse-btn"
                  className={`text-button ${BLOCK}__expand-collapse-btn`}
                  disabled={noResults}
                  formattedText={
                    !peerBenchmarkState.expandAllMetrics &&
                    !peerBenchmarkState.hasMetricOpen
                      ? "peer-benchmarks.button.expand.all"
                      : "peer-benchmarks.button.collapse.all"
                  }
                  onClick={() => {
                    if (!noResults) {
                      if (
                        !peerBenchmarkState.expandAllMetrics &&
                        !peerBenchmarkState.hasMetricOpen
                      ) {
                        dispatch(setExpandAllMetricsValue(true));
                        dispatch(setCollapseAllMetricsValue(false));
                        dispatch(setHasMetricOpenValue(true));
                      } else if (peerBenchmarkState.hasMetricOpen) {
                        dispatch(setCollapseAllMetricsValue(true));
                        dispatch(setExpandAllMetricsValue(false));
                        dispatch(setHasMetricOpenValue(false));
                      }
                    }
                  }}
                  iconName={
                    !peerBenchmarkState.expandAllMetrics &&
                    !peerBenchmarkState.hasMetricOpen
                      ? "expandAll"
                      : "collapseAll"
                  }
                />
              }
            </div>
          </div>
        </div>

        {peerBenchmarkState.metrics && peerBenchmarkState.metrics[index] && (
          <MetricsAccordion
            peerBenchmarkMetrics={
              peerBenchmarkState.metrics && peerBenchmarkState.metrics[index]
                ? peerBenchmarkState.metrics[index].PeerbenchmarkCategories
                : []
            }
            handleSelectedMetrics={setSelectedMetrics}
            searchInputVal={tabItemsDetails[index].searchValue}
            editView={editView}
            preSelectedMetrics={
              peerBenchmarkData ? peerBenchmarkData.metrics : []
            }
            selectedMetricsFromParent={selectedMetrics}
            setNoResults={setNoResults}
            disabledMetrics={
              metricsSelectionMaxCount !== -1 &&
              metricsSelectionExceedConfiguredLimit
            }
            maxCount={metricsSelectionMaxCount}
            index={index}
            resetMetrics={resetMetrics}
            setResetMetrics={setResetMetrics}
          />
        )}
      </div>
    );
  };

  const handleConfirmation = () => {
    if (!isConfirmationModalTriggerByDropdown) {
      setShowGlobalSearch(true);
    } else {
      setChangeNonSecCompany(true);
    }
    setIsConfirmationModalTriggerByDropdown(false);
    setShowConfirmationModal(false);
  };

  const getCustomConfirmationHeader = () => (
    <span className={`${BLOCK}__confirmation-header`}>
      <Icon name="warning-circle-yellow" width={24} height={24} />
      <FormattedMessage id="non.sec.confirmation.header" />
    </span>
  );

  const getCustomConfirmationContent = () => (
    <span className={`${BLOCK}__confirmation-content`}>
      <FormattedMessage
        id={
          peerBenchmarkState.companyFilter.length > 0 &&
          peerBenchmarkState.companyFilter[0].countryCode === COUNTRY_CODE.US
            ? "sec.confirmation.msg"
            : "non.sec.confirmation.msg"
        }
      />
    </span>
  );

  const unselectGlobalCompany = (
    globalCompanyGroup: GlobalCompanyGroup,
    type: string
  ) => {
    if (
      peerBenchmarkState.globalCompanyMappingsFilter &&
      peerBenchmarkState.globalCompanyMappingsFilter.find(
        (g: Partial<GlobalCompanyGroup>) =>
          g.globalCompanyMappingId ===
            globalCompanyGroup.globalCompanyMappingId && g.isBaseMapping
      )
    )
      dispatch(setCompanyFilter([]));

    dispatch(
      setGlobalMappingFilter(
        peerBenchmarkState.globalCompanyMappingsFilter.filter(
          (item: Partial<GlobalCompanyGroup>) =>
            item.globalCompanyMappingId !==
            globalCompanyGroup.globalCompanyMappingId
        )
      )
    );
    if (peerBenchmarkState.selectedPeerGroupItems.length > 0) {
      let tmpSelectedCheckboxPeerGroup = insightsState.savedPeerGroupList
        .filter((pg: PeerGroup) =>
          peerBenchmarkState.selectedPeerGroupItems.includes(pg.companyGroupId)
        )
        .filter(
          (pg: PeerGroup) =>
            pg.globalCompanyMappings &&
            pg.globalCompanyMappings.every(
              (gc: Partial<GlobalCompanyGroup>) =>
                gc.globalCompanyMappingId !==
                globalCompanyGroup.globalCompanyMappingId
            )
        )
        .map((pg: PeerGroup) => pg.companyGroupId);
      setSelectedCheckboxPeerGroup(tmpSelectedCheckboxPeerGroup);
      dispatch(updateSelectedPeerGroupItems(tmpSelectedCheckboxPeerGroup));
    }
  };

  return (
    <div className={BLOCK} data-testid="create-new-container">
      <div className={`${BLOCK}__body`}>
        <div className={`${BLOCK}__content`}>
          <div className={`${BLOCK}__content--leftSide`}>
            <div className={`${BLOCK}__content--leftSide--baseline--dropdown`}>
              <DropdownContainer
                BLOCK={BLOCK}
                dropdownLabel={dropdownHeaders}
                debounceChangeHandler={debounceChangeHandler}
                selectCompany={selectCompany}
                unselectCompany={handleUnselectCompany}
                fromPeerBenchmark={true}
                disabled={peerBenchmarkState.companyFilter.length <= 0}
                editView={editView}
              />
            </div>
            <div className={`${BLOCK}__suggested-peers-section`}>
              <button
                className={classNames(`${BLOCK}__suggested-peers`, {
                  [`${BLOCK}__suggested-peers--disabled`]:
                    peerBenchmarkState.companyFilter.length === 0 ||
                    (peerBenchmarkState.companyFilter.length &&
                      peerBenchmarkState.companyFilter[0].countryCode !==
                        COUNTRY_CODE.US) ||
                    peerBenchmarkState.globalCompanyMappingsFilter.find(
                      (gpm: Partial<GlobalCompanyGroup>) => gpm.isBaseMapping
                    ),
                })}
                onClick={() => {
                  if (
                    peerBenchmarkState.companyFilter.length > 0 &&
                    peerBenchmarkState.companyFilter[0].countryCode ===
                      COUNTRY_CODE.US &&
                    !peerBenchmarkState.globalCompanyMappingsFilter.find(
                      (gpm: Partial<GlobalCompanyGroup>) => gpm.isBaseMapping
                    )
                  ) {
                    setCompaniesUnselected([]);
                    setSuggestedPeers(true);
                    dispatch(loadPeerCompanies([]));
                  }
                }}
                data-testid="suggested-peers-btn"
              >
                <Icon
                  name="external-link"
                  height={24}
                  width={24}
                  className="icon"
                />
                <FormattedMessage id="suggested.peers" />
              </button>
            </div>
            <div className={`${BLOCK}__peerCompanies-container`}>
              <PeerCompanies
                BLOCK={BLOCK}
                peerCompanies={peerBenchmarkState.peerCompanyFilter}
                globalPeerCompanies={
                  peerBenchmarkState.globalCompanyMappingsFilter
                }
                unselectCompany={unselectCompany}
                unselectGlobalCompanyMapping={unselectGlobalCompany}
                hybridCompanies={peerBenchmarkState.peerCompanyFilter
                  .map(
                    (
                      cf: CompanyFilter,
                      cfIndex: number
                    ): Partial<HybridCompany> => ({
                      displayOrder: cf.displayOrder ?? cfIndex,
                      company: cf,
                    })
                  )
                  .concat(
                    peerBenchmarkState.globalCompanyMappingsFilter
                      .filter(
                        (gc: Partial<GlobalCompanyGroup>) => !gc.isBaseMapping
                      )
                      .map(
                        (
                          gc: Partial<GlobalCompanyGroup>,
                          gcIndex: number
                        ): Partial<HybridCompany> => ({
                          displayOrder: gc.displayOrder ?? gcIndex,
                          globalCompanyGroup: gc,
                        })
                      )
                  )}
              />
            </div>
            <div className={`${BLOCK}__suggested-peers-section`}>
              <button
                className={classNames(`${BLOCK}__suggested-peers`, {
                  [`${BLOCK}__suggested-peers--disabled`]:
                    peerBenchmarkState.peerCompanyFilter.length === 0 &&
                    peerBenchmarkState.globalCompanyMappingsFilter &&
                    peerBenchmarkState.globalCompanyMappingsFilter.filter(
                      (g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping
                    ).length === 0,
                })}
                onClick={() => {
                  if (
                    peerBenchmarkState.peerCompanyFilter.length > 0 ||
                    (peerBenchmarkState.globalCompanyMappingsFilter &&
                      peerBenchmarkState.globalCompanyMappingsFilter.filter(
                        (g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping
                      ).length > 0)
                  ) {
                    setSavedPeers(true);
                  }
                }}
                data-testid="suggested-peers-link"
              >
                <Icon
                  name="plus24"
                  height={24}
                  width={24}
                  className="icon"
                  data-testid={
                    peerBenchmarkState.peerCompanyFilter.length > 0
                      ? "benchmark-suggested-peers-link-enabled"
                      : "benchmark-suggested-peers-link-disabled"
                  }
                />

                <FormattedMessage id="save.peer.group" />
              </button>
            </div>
          </div>
          <div className={`${BLOCK}__content--rightSide-container`}>
            <div className={`${BLOCK}__guidance-container`}>
              <div
                className={classNames(`${BLOCK}__guidance-wrapper`, {
                  [`${BLOCK}__guidance-wrapper--warning`]:
                    metricsSelectionExceedConfiguredLimit &&
                    metricsSelectionMaxCount !== -1,
                })}
              >
                {metricsSelectionExceedConfiguredLimit &&
                metricsSelectionMaxCount !== -1 ? (
                  <Icon name="warning-circle-yellow" width={24} height={24} />
                ) : (
                  <Icon name="guidance" width={24} height={24} />
                )}
                <span className={`${BLOCK}__guidance-text`}>
                  {metricsSelectionExceedConfiguredLimit &&
                  metricsSelectionMaxCount !== -1 ? (
                    <FormattedMessage
                      id="peerbenchmark.metrics.limit.warning"
                      values={{
                        maxCount: metricsSelectionMaxCount ?? 0,
                      }}
                    />
                  ) : (
                    <FormattedMessage id="peerbenchmark.metrics.limit.legend" />
                  )}
                </span>
              </div>
            </div>

            <div className={`${BLOCK}__content--rightSide-wrapper`}>
              <div className={`${BLOCK}__content--rightSide-header`}>
                <TabbedContent
                  className={`${BLOCK}__tabbed-content`}
                  onChange={handleTabChange}
                  tabItems={tabItems}
                  selectedIndex={currentTab}
                  data-test="tab-content"
                  isPeerBenchmarkEdit={editView}
                />
              </div>
              {peerBenchmarkState.metrics && getPeerbenchmarkTab(currentTab)}
            </div>
            <div className={`${BLOCK}__options-container`}>
              <div>
                <Button
                  className={classNames(`${BLOCK}__reset-btn`, {
                    [`${BLOCK}__reset-btn--disabled`]:
                      (!editView && selectedMetrics.length === 0) ||
                      (editView &&
                        (JSON.stringify(copiedSelectedMetrics) ===
                          JSON.stringify(selectedMetrics) ||
                          (copiedSelectedMetrics.length ===
                            selectedMetrics.length &&
                            selectedMetrics.every((sm: any) =>
                              copiedSelectedMetrics.find(
                                (cm: any) => cm.fieldName === sm.fieldName
                              )
                            )))),
                  })}
                  onClick={() => {
                    if (
                      (selectedMetrics.length !== 0 && !editView) ||
                      (editView &&
                        ((copiedSelectedMetrics.length ===
                          selectedMetrics.length &&
                          selectedMetrics.some(
                            (sm: any) =>
                              !copiedSelectedMetrics.find(
                                (cm: any) => cm.fieldName === sm.fieldName
                              )
                          )) ||
                          copiedSelectedMetrics.length !==
                            selectedMetrics.length))
                    ) {
                      if (editView) {
                        setResetMetrics(true);
                      } else {
                        setSelectedMetrics([]);
                      }
                    }
                  }}
                  dataTest="reset-btn"
                  formattedText="reset"
                  iconName="eraser"
                  disabled={
                    (!editView && selectedMetrics.length === 0) ||
                    (editView &&
                      (JSON.stringify(copiedSelectedMetrics) ===
                        JSON.stringify(selectedMetrics) ||
                        (copiedSelectedMetrics.length ===
                          selectedMetrics.length &&
                          selectedMetrics.every((sm: any) =>
                            copiedSelectedMetrics.find(
                              (cm: any) => cm.fieldName === sm.fieldName
                            )
                          ))))
                  }
                />
              </div>

              <div className={`${BLOCK}__options`}>
                <Button
                  className={`SaveSearchList__btn button-secondary`}
                  onClick={() => {
                    handleCreateNew(false);
                    dispatch(setCompanyFilter([]));
                    dispatch(setPeerCompanyFilter([]));
                    setSelectedMetrics([]);
                    dispatch(loadPeerCompanies([]));
                    dispatch(searchLoadCompanyFilter([]));
                    dispatch(peerBenchmarkToSave(null));
                    dispatch(loadSavedPeerbenchmarks());
                    !editView && dispatch(setGlobalCompanyMappingsFilter([]));
                  }}
                  dataTest="create-new-btn"
                  formattedText="peer-benchmarks.button.cancel"
                />

                <Button
                  className={`button-primary`}
                  onClick={() => {
                    handleSaveNewBenchmark(
                      tabItemsDetails[currentTab].peerbenchmarkId
                    );
                  }}
                  dataTest="apply-btn"
                  disabled={disabled}
                  formattedText="peer-benchmarks.button.apply"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {suggestedPeers && (
        <SuggestedPeers
          BLOCK={BLOCK}
          setShowModal={setSuggestedPeers}
          addSelected={addSuggestedPeers}
          selectedItems={peerBenchmarkState.selectedItems}
          dispatchFunction={updateSelectedItems}
          dispatchFunctionPeerGroups={updateSelectedPeerGroupItems}
          suggestedPeerCompanies={peerBenchmarkState.suggestedPeerCompanyFilter}
          selectedBaselineComp={{
            companyName: peerBenchmarkState.companyFilter[0].companyName
              ? peerBenchmarkState.companyFilter[0].companyName
              : "",
            cikNumber: peerBenchmarkState.companyFilter[0].cikNumber
              ? peerBenchmarkState.companyFilter[0].cikNumber
              : 0,
            companyId: peerBenchmarkState.companyFilter[0].companyId
              ? peerBenchmarkState.companyFilter[0].companyId
              : 0,
            countryCode: peerBenchmarkState.companyFilter[0].companyId
              ? peerBenchmarkState.companyFilter[0].countryCode
              : undefined,
            globalCompanyMapping:
              peerBenchmarkState.globalCompanyMappingsFilter.find(
                (gm: Partial<GlobalCompanyGroup>) => gm.isBaseMapping
              ),
          }}
          selectedCheckboxPeerGroupItems={
            peerBenchmarkState.selectedPeerGroupItems
          }
          peerGroups={insightsState.savedPeerGroupList}
          page={PEER_GROUP_CREATION_PAGE.PEER_BENCHMARK}
          unSelectCompanyFromFilter={unSelectCompanyFromFilter}
          setCompaniesUnselected={companiesUnselectedHandler}
          identifier={COMPANY_IDENTIFIER.GLOBAL_COMPANY_ID}
          debounceFunction={debounceChangeHandler}
          companyFilterOptions={
            peerBenchmarkState.companyFilter.length > 0
              ? peerBenchmarkState.peerCompanyFilterOptions?.filter(
                  (peerCompany: CompanyFilter) =>
                    peerCompany.globalCompanyId !==
                    peerBenchmarkState.companyFilter[0].globalCompanyId
                )
              : peerBenchmarkState.peerCompanyFilterOptions
          }
        />
      )}
      {savedPeers && (
        <SavedPeerModal
          setShowModal={setSavedPeers}
          dropdownHeadersForSavePeerModal={dropdownHeadersForSavePeerModal}
          handleUnselectCompany={handleUnselectCompany}
          isdispatchFunction={true}
          dispatchFunction={setPeerCompanyFilter}
          setSelectedPeerCompany={setSelectedPeerCompany}
          setSelectedGlobalPeerCompanies={setSelectedGlobalPeerCompanies}
          peerCompanyFilter={selectedPeerCompany}
          globalPeerCompanies={selectedGlobalPeerCompanies}
          savePeerGroupHandler={SavePeerGroupHandler}
          debounceChangeHandler={debounceChangeHandler}
          peerOrder={peerOrder}
          setPeerOrder={setPeerOrder}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          show={true}
          handleCloseModal={() => {
            setShowConfirmationModal(false);
            setLocalSelectedBaseline(
              peerBenchmarkState.companyFilter &&
                peerBenchmarkState.companyFilter.length > 0
                ? peerBenchmarkState.companyFilter[0]
                : null
            );
            setIsConfirmationModalTriggerByDropdown(false);
          }}
          handleConfirm={handleConfirmation}
          customTitle={getCustomConfirmationHeader()}
          content={getCustomConfirmationContent()}
          customClass=""
        />
      )}
      {showGlobalSearch && (
        <GlobalSearchModal
          show={showGlobalSearch}
          handleClose={() => {
            setShowGlobalSearch(false);
            setIsConfirmationModalTriggerByDropdown(false);
          }}
          selectionType={selectionType}
          selectCompany={selectCompany}
          unselectCompany={handleUnselectCompany}
          peersSelected={peerBenchmarkState.peerCompanyFilter ?? []}
          globalBaseSelected={
            peerBenchmarkState.globalCompanyMappingsFilter &&
            peerBenchmarkState.globalCompanyMappingsFilter.length > 0
              ? peerBenchmarkState.globalCompanyMappingsFilter.find(
                  (v: Partial<GlobalCompanyGroup>) => v.isBaseMapping
                )
              : undefined
          }
          globalPeersSelected={
            peerBenchmarkState.globalCompanyMappingsFilter &&
            peerBenchmarkState.globalCompanyMappingsFilter.length
              ? peerBenchmarkState.globalCompanyMappingsFilter.filter(
                  (v: Partial<GlobalCompanyGroup>) => !v.isBaseMapping
                )
              : []
          }
          unselectGlobalCompany={unselectGlobalCompany}
          globalCompanyMappingsFilter={
            peerBenchmarkState.globalCompanyMappingsFilter
          }
          fetchSavedGroupings={fetchSavedGroupings}
          fetchGlobalCompaniesFilter={fetchGlobalCompaniesFilter}
          deleteSavedGrouping={deleteSavedGrouping}
          guidanceCustomText={FormattedMessageString(
            "company.benchmarking.data"
          )}
          saveCompanyGrouping={saveCompanyGrouping}
          setGlobalCompanyMappingsFilter={setGlobalCompanyMappingsFilter}
          triggeredBy={SOURCE_PAGE.PEER_BENCHMARK}
          peerOrder={peerOrder}
          setPeerOrder={setPeerOrder}
        />
      )}
    </div>
  );
};

export default CreateNewView;
