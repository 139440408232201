import classNames from "classnames";
import Button from "components/shared/button/button";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { PAGES } from "components/whatsNewModal/useWhatsNewModal";
import WhatsNewModal from "components/whatsNewModal/whatsNewModal";
import emptyimg from "images/saved.shortlist-empty.png";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  currentSavedInsightDashboard,
  loadSavedInsightsList,
  resetInsightTab,
  savedInsightDashboardId,
  showCreateNewTab,
} from "services/insights/insights.service";
import { RootStore } from "services/store.service";
import {
  DEFAULT_SAVED_INSIGHTS_LIST_PAYLOAD,
  OVERLAY_INSIGHTS,
} from "utils/constants";
import CreateNew from "./create-new";
import SavedInsightsList from "./saved-insights-list";
import { useLocation } from "react-router-dom";
import useAppInsightsCustomMetric from "appinsights/app-insights-custom-metrics";

const Insights = () => {
  const BLOCK = "insights";
  const dispatch = useDispatch();
  const insightsState = useSelector((state: RootStore) => state.insights);
  const commonState = useSelector((state: RootStore) => state.commons);
  const [insightSharedType, setInsightSharedType] = useState<string>("");
  const [createNewTab, setCreateNewTab] = useState<boolean>(false);
  const location = useLocation();

  // App insights custom metric
  useAppInsightsCustomMetric(location.pathname);

  useEffect(() => {
    if (!isEmpty(commonState.currentUser)) {
      dispatch(loadSavedInsightsList(DEFAULT_SAVED_INSIGHTS_LIST_PAYLOAD));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonState.currentUser]);

  const insightType = [
    {
      id: "self",
      name: "insights.self.header",
      onClick: () => {
        if (insightSharedType === "self") {
          setInsightSharedType("");
        } else {
          setInsightSharedType("self");
        }
      },
    },
    {
      id: "shared",
      name: "insights.shared.header",
      onClick: () => {
        if (insightSharedType === "shared") {
          setInsightSharedType("");
        } else {
          setInsightSharedType("shared");
        }
      },
    },
  ];

  return (
    <div className={`${BLOCK}`} data-testid="insights-container">
      <header className={`${BLOCK}__header`}>
        <div data-testid="header-text">
          <div className={`${BLOCK}__Mainheader`}>
            <FormattedMessage
              id="insights.title.count"
              values={{
                count:
                  insightsState.savedInsights &&
                  insightsState.savedInsights.length
                    ? insightsState.savedInsights.length
                    : 0,
              }}
            />
            <div className={`${BLOCK}__typeHeader`}>
              {insightType.map((type: any, index: number) => {
                return (
                  <div
                    key={`${BLOCK}-${index}`}
                    data-testid="tab"
                    onClick={() => {
                      type.onClick();
                    }}
                    className={classNames(`${BLOCK}__type`, {
                      [`${BLOCK}__type--selected`]:
                        insightSharedType === type.id,
                    })}
                  >
                    <FormattedMessage
                      id={type.name}
                      values={{
                        count:
                          insightsState.savedInsights &&
                          insightsState.savedInsights.length
                            ? insightsState.savedInsights.filter((insight) =>
                                type.id === "shared"
                                  ? insight.sharedBy &&
                                    insight.sharedBy?.userId !== null
                                  : type.id === "self"
                                  ? !insight.sharedBy
                                  : true
                              ).length
                            : 0,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {insightsState.savedInsights &&
          insightsState.savedInsights.length > 0 && (
            <Button
              className={`${BLOCK}__createInsights-button button-primary`}
              dataTest="create-insight-btn"
              formattedText="insights.tab.create.dashboard"
              iconName="add-plus"
              iconHeight={24}
              iconWidth={24}
              onClick={() => {
                dispatch(currentSavedInsightDashboard(null));
                dispatch(savedInsightDashboardId(-1));
                dispatch(showCreateNewTab(true));
                dispatch(resetInsightTab());
                setCreateNewTab(true);
              }}
            />
          )}
        {createNewTab && (
          <CreateNew
            BLOCK={`${BLOCK}__create-new`}
            setCreateNewTab={setCreateNewTab}
          />
        )}
      </header>
      {insightsState.savedInsights &&
        insightsState.savedInsights.length > 0 &&
        !insightsState.showCurrentInsightDashboard && (
          <SavedInsightsList insightSharedType={insightSharedType} />
        )}
      {insightsState.savedInsights &&
        insightsState.savedInsights.length === 0 &&
        !insightsState.showCurrentInsightDashboard && (
          <>
            <div className={`${BLOCK}__empty`} data-testid="empty-insights">
              <div className={`${BLOCK}__img`}>
                <img
                  data-testid="empty-img"
                  className={""}
                  src={emptyimg}
                  alt={emptyimg}
                />
              </div>
              <div className={`${BLOCK}__empty-text`}>
                <FormattedMessage id="insights.empty" />
              </div>
              <div className={`${BLOCK}__empty-redirect`}>
                <FormattedMessage id="insights.redirect.part.1" />
                &nbsp;
                <button
                  className={`my-searches__link ${BLOCK}__link`}
                  onClick={() => {
                    dispatch(resetInsightTab());
                    dispatch(showCreateNewTab(true));
                    dispatch(currentSavedInsightDashboard(null));
                    dispatch(savedInsightDashboardId(-1));
                    setCreateNewTab(true);
                  }}
                  data-testid="create-new-link"
                >
                  <FormattedMessage id="insights.redirect.link" />
                </button>
                &nbsp;
                <FormattedMessage id="insights.redirect.part.2" />
              </div>
            </div>
          </>
        )}
      <WhatsNewModal
        slides={OVERLAY_INSIGHTS}
        slide={OVERLAY_INSIGHTS[0]}
        page={PAGES.insight}
      />
    </div>
  );
};
export default Insights;
