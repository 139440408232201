import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showTooltip } from "services/commons.service";
import { RootStore } from "services/store.service";
import Parser from "html-react-parser";
import { NEUTRAL, INSIGHT_BENCHMARK_ID_ARRAY } from "utils/constants";

type Props = {
  data: any;
  currentInsightView: number;
  isDetailedView: boolean;
  benchmarkTileType: number;
};
const BLOCK = "reportingDisclosure";

export const ReportingMetric = ({
  metric,
  sectionId,
  isBaseCompany,
  globalCompanyId,
  isReportingBoundary,
  benchmarkTileType,
  sectionheader,
  metricName,
  currentInsightView,
}: any) => {
  const metricRef = useRef(null);

  return sectionId === 4 &&
    benchmarkTileType === 48 &&
    currentInsightView === 4 ? (
    <div key={`Verification-${sectionId}`} className={`${BLOCK}__Verification`}>
      <div className={`${BLOCK}__Verification-tile`}>
        <div className={`${BLOCK}__Verification-value`}>
          <div className={`${BLOCK}__Verification-text`}>{metricName}</div>
        </div>
        <div
          className={`${BLOCK}__Verification-metric-container ${BLOCK}__Verification-metric-container-center`}
        >
          {metric.value}
        </div>
      </div>
    </div>
  ) : (
    <div
      className={classNames(
        `${BLOCK}__metric-wrapper`,
        `${BLOCK}__metric-wrapper--${
          (sectionId === 1 || sectionId === 0 || sectionId === 3) &&
          !INSIGHT_BENCHMARK_ID_ARRAY.includes(globalCompanyId)
            ? 1
            : sectionId
        }`,
        {
          [`${BLOCK}__metric-wrapper--${metric.value?.toLowerCase()}`]:
            sectionId === 1 && globalCompanyId < 0
              ? true
              : sectionId === 0 && benchmarkTileType === 48
              ? true
              : [0, 1, 3].includes(sectionId),
        }
      )}
    >
      {sectionId === 4 ||
      sectionId === 1 ||
      sectionId === 3 ||
      isReportingBoundary ? (
        <div ref={metricRef}>
          <Popover
            displayText={
              [0, 1, 3].includes(sectionId) ? metric.metric : metric.value
            }
            metricClassName={classNames(
              `${BLOCK}__metric ${BLOCK}__metric-wrapper-lbl`,
              {
                [`general-neutral-value--color`]:
                  metric.value &&
                  metric.value.toLowerCase() === "neutral" &&
                  ![0, 1, 3].includes(sectionId),
                [`${BLOCK}__metric-wrapper--${metric.value?.toLowerCase()}-lbl`]:
                  sectionId === 1 && globalCompanyId < 0
                    ? false
                    : [0, 1, 3].includes(sectionId),
                [`${BLOCK}__metric-wrapper--${
                  sectionId === 1 &&
                  !INSIGHT_BENCHMARK_ID_ARRAY.includes(globalCompanyId)
                    ? 0
                    : sectionId
                }`]: !isReportingBoundary,
                [`${BLOCK}__metric--no-padding`]: isReportingBoundary,
              }
            )}
            content={metric.tooltip ?? []}
          />
        </div>
      ) : (
        <div
          ref={metricRef}
          className={classNames(`${BLOCK}__metric`, {
            [`general-neutral-value--color`]:
              metric.value &&
              metric.value.toLowerCase() === "neutral" &&
              ![2, 4].includes(sectionId),
          })}
        >
          {[1].includes(sectionId)
            ? sectionId === 1 &&
              globalCompanyId !== -98 &&
              globalCompanyId !== -99
              ? metric.metric
              : metric.value
            : (sectionId === 0 || sectionId === 3) &&
              globalCompanyId !== -98 &&
              globalCompanyId !== -99
            ? metric.metric
            : metric.value}
        </div>
      )}

      {metric.value && metric.value.toLowerCase() === "neutral" ? (
        <>
          <Tooltip position={TooltipPosition.bottom}>
            <FormattedMessage id="insights.tabular.neutral" />
          </Tooltip>
        </>
      ) : null}
      {(sectionId === 1 && globalCompanyId < 0
        ? false
        : [1, 3, 4].includes(sectionId)) &&
      metric.value &&
      metric.value.toLowerCase() === "neutral" ? (
        <>
          <span
            className={classNames(`${BLOCK}__neutral-label`, {
              [`general-neutral-value--color`]:
                metric.value && metric.value.toLowerCase() === "neutral",
            })}
          >
            Neutral
          </span>
          <Tooltip position={TooltipPosition.bottom}>
            <FormattedMessage id="insights.tabular.neutral" />
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

const ReportingDisclosureTile = ({
  data,
  currentInsightView,
  isDetailedView,
  benchmarkTileType,
}: Props) => {
  const dispatch = useDispatch();
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("span");
    if (!element) {
      return;
    }

    dispatch(
      showTooltip({
        children: <div className={`${BLOCK}__tooltip`}>{Parser(children)}</div>,
        position: TooltipPosition.TileBottomRight,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };
  let NoDataAvailable = data.every((companyData: any) =>
    companyData?.data?.every((metricData: any) =>
      metricData?.metrics?.every((metric: any) => metric.value === null)
    )
  );

  let showNoDataFootnote = false;
  const renderVerificationSection = (sectionData: any, sectionId: number) => {
    sectionData.data.map((companyData: any, i: number) => {
      const noDataCompany = companyData.metrics.reduce(
        (acc: any, curr: any) => acc && curr.value === null,
        true
      );
      if (!showNoDataFootnote && noDataCompany) showNoDataFootnote = true;
    });

    return (
      <div>
        <span
          className={`${BLOCK}__section-name-container`}
          style={{ display: "inline-block" }}
        >
          <Popover
            displayText={sectionData.sectionheader}
            metricClassName={`${BLOCK}__section-name`}
            content={sectionData.groupTooltip ?? []}
          />
        </span>
        {sectionData &&
          sectionData.metricName.length &&
          sectionData.metricName.map((metricData: string, ind: number) => (
            <div
              key={`Verification--${ind}`}
              className={`${BLOCK}__Verification`}
            >
              <div className={`${BLOCK}__Verification-tile`}>
                <div className={`${BLOCK}__Verification-value`}>
                  <div className={`${BLOCK}__Verification-text`}>
                    {metricData}
                  </div>
                </div>
                <div className={`${BLOCK}__Verification-metric-container`}>
                  {sectionData.data.map((data: any, i: number) => (
                    <div
                      key={`Verification-container-${i}`}
                      className={`${BLOCK}__Verification-metric-data-container`}
                    >
                      <div className={`${BLOCK}__Verification-metric-name`}>
                        {data.companyName}
                      </div>
                      <div className={`${BLOCK}__Verification-metric-data`}>
                        {data.metrics[ind]?.value
                          ? data.metrics[ind]?.value
                          : "*"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  const renderDetailedViewRow = (
    metrics: any,
    noData: boolean,
    companyName: string,
    sectionId: number,
    globalCompanyId: number
  ) => {
    let actualMetricsArray: any = [];
    let noMetricToShow = "*";

    if (sectionId === 2) {
      if (!noData)
        actualMetricsArray = [
          {
            value: metrics[0].value,
            tooltip: metrics[0].tooltip,
          },
        ];
    } else {
      for (let i = 0; i < metrics.length; i++) {
        const currMetric = metrics[i];
        if (currMetric.value) {
          actualMetricsArray = [
            ...actualMetricsArray,
            {
              value: !INSIGHT_BENCHMARK_ID_ARRAY.includes(globalCompanyId)
                ? currMetric.metric
                : currMetric.value,
              tooltip: currMetric.tooltip,
            },
          ];
        }
      }
    }

    return (
      <div
        className={classNames(`${BLOCK}__section-data--detailedView--row`, {})}
      >
        <span
          className={classNames(
            `${BLOCK}__section-data--detailedView--row--companyName`,
            {}
          )}
        >
          {companyName}
        </span>
        <span
          className={classNames(
            `${BLOCK}__section-data--detailedView--row--metricsContainer`,
            {}
          )}
          onMouseEnter={(e) =>
            actualMetricsArray[0]?.value?.toLowerCase() === "neutral"
              ? handleMouseEnter(e, NEUTRAL)
              : null
          }
          onMouseLeave={handleMouseLeave}
        >
          {noData && actualMetricsArray.length === 0 ? (
            <span
              className={classNames(
                `${BLOCK}__section-data--detailedView--row--text`,
                {}
              )}
            >
              {noMetricToShow}
            </span>
          ) : (
            actualMetricsArray.map((metric: any) => {
              return (
                <Popover
                  displayText={metric.value}
                  content={metric.tooltip ?? []}
                  metricClassName={classNames(
                    `${BLOCK}__section-data--detailedView--row--text`,
                    {
                      [`${BLOCK}__section-data--detailedView--row--text--paddingBot`]:
                        true,
                      [`${BLOCK}__section-data--detailedView--row--text--date`]:
                        sectionId === 2,
                    }
                  )}
                />
              );
            })
          )}
        </span>
      </div>
    );
  };

  const renderSection = (sectionData: any, sectionId: number) => {
    return (
      <div
        className={classNames(`${BLOCK}__section`, {
          [`${BLOCK}__section--horizontal`]: isDetailedView,
        })}
      >
        <span className={`${BLOCK}__section-name-container`}>
          <Popover
            displayText={sectionData.groupName}
            metricClassName={`${BLOCK}__section-name`}
            content={sectionData.groupTooltip}
          />
        </span>
        {sectionData.data.reduce(
          (acc: boolean, curr: any) =>
            acc &&
            curr.metrics.reduce(
              (acc: any, curr: any) => acc && curr.value === null,
              true
            ),
          true
        ) ? (
          <div className={`${BLOCK}__no-data`}>
            <FormattedMessage id="insights.speedometer.no-data" />
          </div>
        ) : (
          sectionData.data.map((companyData: any, i: number) => {
            const noDataCompany = companyData.metrics.reduce(
              (acc: any, curr: any) => acc && curr.value === null,
              true
            );
            if (!showNoDataFootnote && noDataCompany) showNoDataFootnote = true;
            const metrics =
              sectionId === 2 && companyData.metrics.length > 1
                ? [
                    {
                      ...companyData.metrics[0],
                      value: `${companyData.metrics[0].value} - ${companyData.metrics[1].value}`,
                    },
                  ]
                : companyData.metrics;
            return (
              <div
                key={`section-data-${i}`}
                className={`${BLOCK}__company-row`}
              >
                {isDetailedView && (
                  <div
                    className={classNames(
                      `${BLOCK}__section-data--detailedView`,
                      {}
                    )}
                  >
                    {renderDetailedViewRow(
                      metrics,
                      noDataCompany,
                      companyData.companyName,
                      sectionId,
                      companyData.globalCompanyId
                    )}
                  </div>
                )}

                {[1, 2, 3].includes(sectionId) &&
                  (currentInsightView === 5 || currentInsightView === 6) &&
                  !isDetailedView && (
                    <div
                      className={classNames(
                        `${BLOCK}__section-data--detailedView`,
                        {}
                      )}
                    >
                      {renderDetailedViewRow(
                        metrics,
                        noDataCompany,
                        companyData.companyName,
                        sectionId,
                        companyData.globalCompanyId
                      )}
                    </div>
                  )}

                {currentInsightView !== 4 &&
                !isDetailedView &&
                !(
                  [1, 2, 3].includes(sectionId) &&
                  (currentInsightView === 5 || currentInsightView === 6)
                ) ? (
                  <span className={`${BLOCK}__company-name`}>
                    {companyData.companyName} {noDataCompany ? "*" : null}
                  </span>
                ) : null}
                {noDataCompany ||
                isDetailedView ||
                ([1, 2, 3].includes(sectionId) &&
                  (currentInsightView === 5 ||
                    currentInsightView === 6)) ? null : (
                  <div
                    className={`${BLOCK}__section-data ${BLOCK}__section-data--${
                      sectionId === 1 &&
                      !INSIGHT_BENCHMARK_ID_ARRAY.includes(
                        companyData.globalCompanyId
                      )
                        ? 0
                        : sectionId
                    }`}
                  >
                    {metrics.map((metric: any, index: number) => (
                      <Fragment key={`section-metric-${i}-${index}`}>
                        <ReportingMetric
                          metric={metric}
                          sectionId={sectionId}
                          isBaseCompany={i === 0}
                          globalCompanyId={companyData.globalCompanyId}
                          benchmarkTileType={benchmarkTileType}
                          sectionheader={sectionData.sectionheader}
                          metricName={sectionData.metricName[index]}
                          currentInsightView={currentInsightView}
                        />
                      </Fragment>
                    ))}
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    );
  };

  return NoDataAvailable ? (
    <div className={`${BLOCK}__no-data`}>
      <span className="speedometer__no-data" style={{ fontWeight: "bold" }}>
        <FormattedMessage id="insights.cdp.no-data" />
      </span>
    </div>
  ) : (
    <div
      className={classNames(BLOCK, {
        [`${BLOCK}--horizontal`]: isDetailedView,
      })}
      data-test="reporting-disclosure-chart"
      data-testid="reporting-disclosure-chart"
    >
      {data.map((d: any, i: number) => (
        <Fragment key={`section-data-${i}`}>
          {i === 4 ? renderVerificationSection(d, i) : renderSection(d, i)}
        </Fragment>
      ))}
      {showNoDataFootnote ? (
        <span className={`${BLOCK}__no-data-footnote`}>
          <FormattedMessage id="no.data.available" />
        </span>
      ) : null}
    </div>
  );
};

export default ReportingDisclosureTile;
