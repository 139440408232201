import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchEmptyState from "images/search.empty-state.png";
import SearchNotFound from "images/search.not-found.svg";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import TabbedContent from "components/shared/tabbed-content/tabbed-content";
import EsgSearchResults from "./esg.search.results";
import SecSearchResults from "./sec.search.results";
import { RootStore } from "services/store.service";
import Icon from "components/shared/icon/icon";
import { sendExportItem } from "services/download/download.service";
import { useLocation } from "react-router";
import history from "utils/history";
import SaveSearch from "components/searchlist-actions/save-search";
import classNames from "classnames";
import { saveSearch } from "services/searchlist/searchlist.service";
import {
  setSelectedDisclosure,
  setSelectedReport,
} from "services/document-viewer/document-viewer.service";
import { setShowFilters } from "services/search/filters.service";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import Compare from "components/compare/compare";
import { comparisonPath } from "utils/constants";
import { addtoComparison } from "services/saved-comparisons/saved-comparisons.service";
import { setShowRequestUploadPanel } from "services/search/search.results.service";
import Button from "components/shared/button/button";

type props = {
  BLOCK: string;
};

const SearchResults = ({ BLOCK }: props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchResults = useSelector((state: RootStore) => state.searchResults);
  const filters = useSelector((state: RootStore) => state.filters);
  const documentViewerState = useSelector(
    (state: RootStore) => state.documentViewer
  );
  const comparisonState = useSelector(
    (state: RootStore) => state.comparisonState
  );
  const downloadState = useSelector((state: RootStore) => state.download);
  const [currentTab, setCurrentTab] = useState(0);
  const [showCompareModal, setCompareModal] = useState<boolean>(false);
  const esgTabPanelId = `${BLOCK}__esg-report-tabpanel`;
  const secTabPanelId = `${BLOCK}__sec-report-tabpanel`;
  const searchlist = useSelector((state: RootStore) => state.searchlist);
  const getDisabledSaveSearchBtn = () => {
    if (searchResults) {
      if (searchlist && searchlist.saveNewSearch) {
        setDisabledSaveSearch(false);
      } else {
        setDisabledSaveSearch(true);
      }
    }
  };

  const [disabledSaveSearch, setDisabledSaveSearch] = useState(true);
  const [totalResults, setTotalResults] = useState(0);

  const selectedItems = comparisonState.selectedList;

  const tabItems = [
    {
      text: "search.results.esg-reports",
      tabId: `${BLOCK}__esg-report-tab`,
      tabPanelId: esgTabPanelId,
      hash: "#esg",
    },
    {
      text: "search.results.sec-reports",
      tabId: `${BLOCK}__sec-report-tab`,
      tabPanelId: secTabPanelId,
      hash: "#sec",
    },
  ];
  const [showSaveSearch, setShowSaveSearch] = useState(false);

  const submitCompare = (selected: any) => {
    if (selected.comparisonId)
      dispatch(
        addtoComparison(comparisonState.selectedList, selected.comparisonId)
      );
    history.push(
      `${comparisonPath}${
        selected.comparisonId ? `/${selected.comparisonId}` : ""
      }`
    );
  };

  const onSaveSearchHandler = (name: string, description?: string) => {
    if (!disabledSaveSearch) {
      let payload = {
        savedSearchId: 0,
        name: name,
        description: description,
        searchCriteria: {
          shortlistId: 0,
          contentText: filters.contentValue,
          formTypeNames: filters.documentTypeFilter.map(
            ({ formTypeName }) => formTypeName
          ),
          years: filters.periodFilter.map(({ periodYear }) => periodYear),
          companyIds: filters.companyFilter.map(({ cikNumber }) => cikNumber),
          industryIds: filters.industryFilter.map(
            ({ industryId }) => industryId
          ),
          auditorIds: filters.auditorFilter.map(({ auditorId }) => auditorId),
          externalAuditorIds: filters.auditorFilter.map(
            ({ externalAuditorId }) => externalAuditorId
          ),
          governingBoardIds: filters.governingBoardFilter.map(
            ({ governingBoardId }) => governingBoardId
          ),
          ivaCompanyRatings:
            filters.esgRatingFilter.length !== 0
              ? filters.esgRatingFilter.map(
                  ({ ivaCompanyRating }) => ivaCompanyRating
                )
              : [],
          pageInfo: {},
          externalCompanyIds:
          filters.companyFilter.map(
            ({ externalCompanyId }) => externalCompanyId
          ).filter(
            externalCompanyId => externalCompanyId
            ),
        },
      };
      dispatch(saveSearch(payload));
    }
  };

  useEffect(() => {
    const newTab = tabItems.findIndex((tab) => tab.hash === location.hash);
    setCurrentTab(newTab >= 0 ? newTab : 0);
    getDisabledSaveSearchBtn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  useEffect(() => {
    getDisabledSaveSearchBtn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults, searchlist?.saveNewSearch]);
  const handleTabChange = (tabbedIndex: number) => {
    history.push(
      `${location.pathname}${tabItems[tabbedIndex].hash}`,
      location.state
    );
  };

  useEffect(() => {
    setTotalResults(getTotalResultsCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults]);

  const searchExportHandler = () => {
    const payload = {
      searchCriteria: {
        ...searchResults.searchPayloadESG,
        externalCompanyIds: searchResults.searchPayloadSEC.companyIds,
        externalIndustryIds: searchResults.searchPayloadSEC.industryIds,
        externalAuditorIds: searchResults.searchPayloadSEC.auditorIds,
        pageInfo: {
          pageNumber: 1,
          pageSize: 30,
          sortBy: searchResults.esgResults[0].pageInfo.sortBy
            ? searchResults.esgResults[0].pageInfo.sortBy
            : "ReportYear",
          sortOrder: searchResults.esgResults[0].pageInfo.sortOrder
            ? searchResults.esgResults[0].pageInfo.sortOrder
            : "desc",
          externalSortBy:
            searchResults.secResults !== null &&
            searchResults.secResults[0].pageInfo.sortBy !== null
              ? searchResults.secResults[0].pageInfo.sortBy
              : "PeriodName",
          externalSortOrder:
            searchResults.secResults !== null &&
            searchResults.secResults[0].pageInfo.sortOrder !== null
              ? searchResults.secResults[0].pageInfo.sortOrder
              : "desc",
        },
      },

      exportReferenceId: 0,
      exportFileName: "Search Results",
      exportType: 4,
      exportFormat: 0,
      downloadTabOpen: downloadState.showDownloads,
    };

    dispatch(sendExportItem(payload));
  };
  const renderEmptyResults = (
    imageSrc: string,
    imageAlt: string,
    title: string,
    text: string,
    showUploadBtn: boolean = false
  ) => {
    return (
      <div
        className={`${BLOCK}__results ${BLOCK}__results-section`}
        data-test="empty-result"
      >
        <div className={`${BLOCK}__empty`}>
          <img
            className={`${BLOCK}__empty-image`}
            src={imageSrc}
            alt={imageAlt}
          />
          <span className={`${BLOCK}__empty-title`}>
            <FormattedMessage id={title} />
          </span>
          <span className={`${BLOCK}__empty-message`}>
            <p>
              <FormattedMessage id={text} />
            </p>
          </span>
          {showUploadBtn ? (
            <div>
              <button
                onClick={() => {
                  dispatch(setShowRequestUploadPanel(true));
                }}
                className={`${BLOCK}__upload-btn`}
              >
                <FormattedMessage id="search.request.upload.btn.first" />
              </button>
              <span className={`${BLOCK}__empty-message`}>
                <FormattedMessage id="search.request.upload.btn.second" />
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const getTotalResultsCount = () => {
    let totalResultsCount = 0;

    if (
      searchResults &&
      !searchResults.esgLoading &&
      searchResults.esgResults &&
      searchResults.esgResults.length > 0 &&
      searchResults.esgResults[0].pageInfo &&
      searchResults.esgResults[0].pageInfo.totalRecordCount
    ) {
      totalResultsCount +=
        searchResults.esgResults[0].pageInfo.totalRecordCount;
    }

    if (
      searchResults &&
      !searchResults.secLoading &&
      searchResults.secResults &&
      searchResults.secResults.length > 0 &&
      searchResults.secResults[0].pageInfo &&
      searchResults.secResults[0].pageInfo.totalRecordCount
    ) {
      totalResultsCount +=
        searchResults.secResults[0].pageInfo.totalRecordCount;
    }

    return totalResultsCount;
  };

  const backToSearch = () => {
    dispatch(setSelectedDisclosure(null));
    dispatch(setSelectedReport(null));
    dispatch(setShowFilters(true));
  };

  return (
    <div
      className={`${BLOCK}__results-content`}
      data-test="search-result-component"
    >
      {searchResults &&
      (searchResults.esgResults ||
        searchResults.secResults ||
        searchResults.esgLoading ||
        searchResults.secLoading) ? (
        <div
          className={`${BLOCK}__results-section ${BLOCK}__results-section--filled`}
        >
          <div className={`${BLOCK}__total-results`}>
            <FormattedMessage
              id={
                totalResults === 1
                  ? "search.results.total.singular"
                  : "search.results.total.plural"
              }
              values={{
                count: Intl.NumberFormat("en-US").format(totalResults),
              }}
              data-test="result-count"
            />
            <div className={`${BLOCK}__results-actions`}>
              {documentViewerState.selectedReport ||
              documentViewerState.selectedDisclosure ? (
                <button
                  id="btn-backToSearch"
                  className={classNames(
                    `${BLOCK}__export-button ${BLOCK}__back-to-search`
                  )}
                  style={{ display: "flex", flexDirection: "row" }}
                  onClick={() => {
                    backToSearch();
                  }}
                >
                  <div>
                    <FormattedMessage id="search.return.to.search" />
                  </div>
                </button>
              ) : (
                <div></div>
              )}

              <div className={`${BLOCK}__compare-button`}>
                <Button
                  className={`text-button`}
                  disabled={selectedItems?.length === 0}
                  formattedText="search.compare.button"
                  onClick={() => setCompareModal(true)}
                />
                <Tooltip position={TooltipPosition.top}>
                  <FormattedMessage id="search.compare.button.tooltip" />
                </Tooltip>
              </div>

              <Button
                className={`${BLOCK}__save-search-button button-secondary`}
                disabled={disabledSaveSearch}
                dataTest="save-search-btn"
                formattedText="save.search.btn"
                onClick={() => setShowSaveSearch(true)}
              />
              <Button
                className={`${BLOCK}__export-button button-secondary`}
                dataTest="export-search-btn"
                disabled={totalResults === 0}
                iconName="download-blue"
                iconHeight={16}
                iconWidth={15}
                onClick={totalResults ? searchExportHandler : undefined}
                formattedText="search.export.grid"
              />
            </div>
          </div>
          {!documentViewerState.fullscreen && (
            <div
              className={`${BLOCK}__tabs`}
              data-testid="tabbed-content-search-results"
            >
              <TabbedContent
                tabItems={tabItems}
                onChange={handleTabChange}
                selectedIndex={currentTab}
              />
            </div>
          )}

          <div
            className={`${BLOCK}__tab-panel ${
              currentTab < tabItems.length &&
              tabItems[currentTab].tabPanelId === esgTabPanelId
                ? `${BLOCK}__tab-panel--selected`
                : ""
            }`}
            data-test="tab-panels"
            role="tabpanel"
            id={esgTabPanelId}
            aria-labelledby={`${BLOCK}__esg-report-tab`}
          >
            {searchResults.esgLoading ? (
              <Icon
                name={"loading"}
                width={50}
                height={50}
                className="loading-icon"
                data-test="loading-icon"
              />
            ) : searchResults.esgResults &&
              searchResults.esgResults.length > 0 &&
              searchResults.esgResults[0].pageInfo &&
              searchResults.esgResults[0].pageInfo.totalRecordCount > 0 ? (
              <EsgSearchResults BLOCK={BLOCK} />
            ) : (
              renderEmptyResults(
                SearchNotFound,
                "Search not found",
                "search.no-results.title",
                "search.no-results.message.second",
                true
              )
            )}
          </div>

          <div
            className={`${BLOCK}__tab-panel ${
              currentTab < tabItems.length &&
              tabItems[currentTab].tabPanelId === secTabPanelId
                ? `${BLOCK}__tab-panel--selected`
                : ""
            }`}
            role="tabpanel"
            id={secTabPanelId}
            aria-labelledby={`${BLOCK}__sec-report-tab`}
          >
            {searchResults.secLoading ? (
              <Icon
                name={"loading"}
                width={50}
                height={50}
                className="loading-icon"
              />
            ) : searchResults.secResults &&
              searchResults.secResults.length > 0 &&
              searchResults.secResults[0].pageInfo &&
              searchResults.secResults[0].pageInfo.totalRecordCount > 0 ? (
              <SecSearchResults BLOCK={BLOCK} />
            ) : (
              renderEmptyResults(
                SearchNotFound,
                "Search not found",
                "search.no-results.title",
                "search.no-results.message.first"
              )
            )}
          </div>
        </div>
      ) : (
        renderEmptyResults(
          SearchEmptyState,
          "Search empty state",
          "search.empty-state.title",
          "search.empty-state.message.first"
        )
      )}
      {showSaveSearch && (
        <SaveSearch
          setShowModal={setShowSaveSearch}
          saveSearch={onSaveSearchHandler}
        />
      )}
      {showCompareModal && (
        <Compare
          setShowCompareModal={setCompareModal}
          submitCompare={submitCompare}
        />
      )}
    </div>
  );
};

export default SearchResults;
