import classNames from "classnames";
import Icon from "components/shared/icon/icon";
import React, { useEffect, useRef, useState } from "react";
import { dropdownHeight } from "utils/constants";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import Parser from "html-react-parser";
import { escapeRegExp } from "lodash";
import { getHighlightedText } from "utils/functions";

type ObjectKeys = {
  name: string;
  id: string;
  externalId: string;
  ticker?: string;
  valueText?: string;
  children?: string;
  childName?: string;
  childId?: string;
  childExternalId?: string;
};

type Props = {
  className?: string;
  label?: string | object;
  showLabel?: boolean;
  placeholder: string | object;
  loading: boolean;
  options: any[];
  values: any[];
  handleChange?: any;
  handleSelect: any;
  handleUnselect?: any;
  handleGroupUnselect?: any;
  handleChangeValue?: any;
  objectKeys: ObjectKeys;
  contentStyle?: boolean;
  textValue?: string;
  displayFunction?: any;
  handleOnEnter?: any;
  mapFiles?: boolean;
  hideValues?: boolean;
  showAllValues?: any;
  fieldMissing?: boolean;
  showOnEmpty?: boolean;
  companyFilter?: boolean;
};

const DropDown = ({
  className,
  label,
  showLabel = true,
  placeholder,
  loading,
  options,
  values,
  handleChange,
  handleSelect,
  handleUnselect,
  handleGroupUnselect,
  handleChangeValue,
  objectKeys,
  contentStyle,
  displayFunction,
  textValue,
  handleOnEnter,
  mapFiles,
  hideValues,
  showAllValues,
  fieldMissing,
  showOnEmpty = true,
  companyFilter,
}: Props) => {
  const [showOptions, toggleOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputFilter, setInputFilter] = useState("");
  const [input, setInput] = useState(false);
  const [groupedValues, setGroupedValues] = useState<any>({});
  const optionsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const inputSizeRef = useRef<HTMLDivElement>(null);
  const BLOCK = "drop-down";

  const handleLocalSelect = (option: any) => {
    if (contentStyle) {
      const newValue = `${
        inputFilter.length > 0 ? inputFilter + " or " : inputFilter
      }${
        option[objectKeys.valueText ? objectKeys.valueText : objectKeys.name]
      }`;
      setInputFilter(
        option["parentId"] === 1 ? option[objectKeys.name] : newValue
      );
      if (handleChangeValue)
        handleChangeValue(
          option["parentId"] === 1 ? option[objectKeys.name] : newValue
        );
      handleSelect(option);
    } else {
      if (!mapFiles) {
        if (objectKeys.children) {
          if (groupedValues[option.parentId]) {
            groupedValues[option.parentId].count += 1;
            setGroupedValues(groupedValues);
          } else {
            let parentOption = options.find(
              (opt) => opt[objectKeys.id] === option.parentId
            );
            if (!parentOption) {
              parentOption = options[option.parentId];
            }
            groupedValues[option.parentId] = {
              ...parentOption,
              count: 1,
            };
            setGroupedValues(groupedValues);
          }
        }
        handleSelect(option);
        if (!companyFilter) setInputFilter("");

        if (!showOnEmpty && !companyFilter) {
          toggleOptions(false);
        }

        setFilteredOptions(options);
      } else {
        //call map files handle select function
        handleSelect(option);
      }
    }
  };

  const handleLocalUnselect = (option: any) => {
    if (mapFiles) {
      handleUnselect(option);
    } else {
      if (contentStyle) {
        let newValue = inputFilter
          .replace(
            ` or ${
              option[
                objectKeys.valueText ? objectKeys.valueText : objectKeys.name
              ]
            }`,
            ""
          )
          .replace(
            option[
              objectKeys.valueText ? objectKeys.valueText : objectKeys.name
            ],
            ""
          );
        if (newValue.startsWith(" or ")) {
          newValue = newValue.replace(" or ", "");
        } else if (newValue.startsWith("or ")) {
          newValue = newValue.replace("or ", "");
        }
        setInputFilter(newValue);
        if (handleChangeValue) handleChangeValue(newValue);
      }
      if (objectKeys.children && groupedValues[option.parentId]) {
        groupedValues[option.parentId].count -= 1;
        setGroupedValues(groupedValues);
      }
      handleUnselect(option);
    }
  };

  useEffect(() => {
    setFilteredOptions(options ? options : []);
  }, [options]);

  useEffect(() => {
    setInputFilter(textValue ? textValue : "");
  }, [textValue]);

  useEffect(() => {
    if (showOptions && optionsRef.current) {
      optionsRef.current.focus();
      const dropdownElement = optionsRef.current?.parentElement;
      const scrollView = optionsRef.current?.parentElement?.parentElement;
      if (dropdownElement && scrollView) {
        const dropdownBottomOffset = dropdownElement.offsetTop + dropdownHeight;
        const offsetToScroll =
          dropdownBottomOffset > scrollView.clientHeight
            ? dropdownBottomOffset - scrollView.clientHeight
            : 0;
        setTimeout(() => {
          scrollView.scrollTo({ top: offsetToScroll, behavior: "smooth" });
        }, 200); // wait for the transition to finish
      }
    }

    if (!showOptions && companyFilter) setInputFilter("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions, values]);

  useEffect(() => {
    if (!loading && filteredOptions.length === 0 && inputFilter !== "") {
      setInput(true);
    } else {
      setInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFilter, loading, filteredOptions.length]);

  useEffect(() => {
    if (!showOnEmpty && inputFilter === "" && filteredOptions.length === 0) {
      toggleOptions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnEmpty, inputFilter, filteredOptions.length]);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (input) {
        setInputFilter("");
        setInput(false);
      }

      return showOptions || event.target.nodeName !== "svg"
        ? toggleOptions(
            ((!showOnEmpty && inputFilter !== "") || showOnEmpty) &&
              wrapperRef.current !== null &&
              wrapperRef.current.contains(event.target)
          )
        : null;
    };

    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);

    textareaRef.current?.setAttribute(
      "style",
      `width: ${inputSizeRef.current?.clientWidth}px;
      height: ${inputSizeRef.current?.clientHeight}px`
    );
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, options, input, inputFilter, showOptions]);

  const highlightOption = (option: string) => {
    if (inputFilter !== "" && option && !contentStyle) {
      return getHighlightedText(option, inputFilter);
    }
    return option;
  };

  const getOption = (
    option: any,
    optionKeys: ObjectKeys,
    parentId?: number
  ) => {
    const isSelected = contentStyle
      ? inputFilter.includes(
          option[objectKeys.valueText ? objectKeys.valueText : objectKeys.name]
        )
      : values.findIndex(
          (value) =>
            value[optionKeys.id] === option[optionKeys.id] &&
            value[optionKeys.externalId] === option[optionKeys.externalId]
        ) >= 0;
    return (
      <button
        className={classNames(`${BLOCK}__option`, {
          [`${BLOCK}__option--selected`]: isSelected,
        })}
        key={`${optionKeys.id}-option-${option[optionKeys.id]}-${
          option[optionKeys.externalId]
        }`}
        onClick={() =>
          isSelected
            ? handleLocalUnselect({ ...option, parentId })
            : handleLocalSelect({ ...option, parentId })
        }
        data-test="select-option-button"
        data-testid="select-option-button"
      >
        <table>
          <tbody>
            <tr>
              {optionKeys.ticker && option[optionKeys.ticker] && (
                <td className={`${BLOCK}__ticker`}>
                  {Parser(
                    highlightOption(option[optionKeys.ticker]?.toString()).toString()
                  )}
                </td>
              )}
              <td className={`${BLOCK}__option-value`} data-testid="option-value">
                {Parser(
                  highlightOption(
                    displayFunction
                      ? displayFunction(option)
                      : option[optionKeys.name]?.toString()
                  ).toString()
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {isSelected && <Icon name="tick" height={20} />}
      </button>
    );
  };

  const handleFilterChange = (e: any) => {
    const value = e.target.value;
    setInputFilter(value);

    if (!showOnEmpty) {
      toggleOptions(value ? true : false);
    }

    if (handleChangeValue) handleChangeValue(value);

    if (value) {
      if (handleChange) {
        handleChange(e);
        return;
      }

      if (objectKeys.children) {
        setFilteredOptions(
          options.reduce((filtered, option) => {
            filtered.push({
              ...option,
              [objectKeys.children || ""]: option[
                objectKeys.children || ""
              ].filter((child: any) =>
                child[objectKeys.childName || ""]
                  .toLowerCase()
                  .includes(value.toLowerCase())
              ),
            });
            let newFilter = filtered.filter((child: any) => {
              if (child.formTypes) {
                return child.formTypes.length !== 0;
              } else if (child.sectors) {
                return child.sectors.length !== 0;
              }
              return true;
            });
            return newFilter;
          }, [])
        );
      } else {
        setFilteredOptions(
          options.filter((option) =>
            option[objectKeys.name]?.toString().toLowerCase().includes(value.toLowerCase())
          )
        );
      }
    } else {
      setFilteredOptions(handleChange ? [] : options);
    }
  };

  const unselectChildren = (group: any) => {
    handleGroupUnselect(group.children);
    groupedValues[group.parentId].count = 0;
    setGroupedValues(groupedValues);
  };

  useEffect(() => {
    if (values.length === 0) {
      setGroupedValues({});
    }
  }, [values]);

  const groupValues = () => {
    let newValues = values;

    if (mapFiles) {
      if (hideValues) {
        const firstTwoValuesOnly = values.slice(0, 2);
        return firstTwoValuesOnly;
      }
      return newValues;
    }

    if (objectKeys.children && !contentStyle) {
      if (Object.keys(groupedValues).length === 0) {
        values.forEach((value) => {
          if (objectKeys.children) {
            if (groupedValues[value.parentId]) {
              groupedValues[value.parentId].count += 1;
              setGroupedValues(groupedValues);
            } else {
              let parentOption = options.find(
                (opt) => opt[objectKeys.id] === value.parentId
              );
              if (!parentOption) {
                parentOption = options[value.parentId];
              }
              groupedValues[value.parentId] = {
                ...parentOption,
                count: 1,
              };
              setGroupedValues(groupedValues);
            }
          }
        });
      }
      const childrenKey = objectKeys.children;
      Object.keys(groupedValues).forEach((id: any) => {
        if (groupedValues[id].count === groupedValues[id][childrenKey].length) {
          newValues = newValues.filter(
            (value) => String(value.parentId) !== id
          );
          newValues.push({
            [objectKeys.childName || ""]: groupedValues[id][objectKeys.name],
            children: groupedValues[id][childrenKey],
            parentId: id,
          });
        }
      });
    }

    return newValues;
  };

  const trimContent = (value: string) => {
    if (value.length < 16) {
      return value;
    }
    return (
      <div className={`${BLOCK}__value-tooltip`}>
        {value.slice(0, 16)}
        {"..."}
        <Tooltip position={TooltipPosition.top}>
          <div>{`${value}`}</div>
        </Tooltip>
      </div>
    );
  };

  return (
    <div
      ref={wrapperRef}
      id={`${BLOCK}-${objectKeys.id}`}
      className={`${BLOCK} ${className}`}
      data-test="drop-down"
      data-testid="drop-down"
    >
      <div className={`${BLOCK}__label`} data-test="drop-down">
        {showLabel && (label as React.ReactNode)}
      </div>
      <div
        className={classNames(`${BLOCK}__outline`, {
          [`${BLOCK}__outline--not-empty`]: values && values.length,
          [`${BLOCK}__outline--missing`]: fieldMissing,
        })}
      >
        <div className={`${BLOCK}__values`} data-test="values" data-testid="dropdown-values">
          {values &&
            groupValues().map((value: any, index: number) =>
              contentStyle ? null : (
                <div
                  className={`${BLOCK}__value`}
                  key={`value-${value[objectKeys.id]}-${index}`}
                >
                  {/* For MapFiles, trim is required to fit inside the dropdown */}
                  {objectKeys.childName
                    ? !mapFiles
                      ? value[objectKeys.childName]
                      : trimContent(
                          value[objectKeys.childName]
                            ? value[objectKeys.childName]
                            : ""
                        )
                    : !mapFiles
                    ? value[objectKeys.name]
                    : trimContent(value[objectKeys.name])
                    ? value[objectKeys.name]
                    : ""}
                  <button
                    className={`${BLOCK}__remove`}
                    data-test="unselect-option"
                    data-testid="unselect-option"
                    onClick={() =>
                      value.children
                        ? unselectChildren(value)
                        : handleLocalUnselect(value)
                    }
                  >
                    <Icon name="cross" height={18} />
                  </button>
                </div>
              )
            )}
          {hideValues && (
            <span
              className={`${BLOCK}__hiddenChips`}
              onClick={() => showAllValues(!hideValues)}
            >
              {`+${values.length - 2}`}
            </span>
          )}
          {values.length > 2 && mapFiles && !hideValues && (
            <span
              className={`${BLOCK}__hiddenChips--showLess`}
              onClick={() => showAllValues(true)}
            >
              <FormattedMessage id="map-files-show-less" />
            </span>
          )}

          <textarea
            ref={textareaRef}
            value={inputFilter}
            className={classNames(`${BLOCK}__input`, {
              [`${BLOCK}__input--not-empty`]: values && values.length,
            })}
            data-testid="input-text-area"
            onChange={handleFilterChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleOnEnter({});
              }
            }}
          />
          <div ref={inputSizeRef} className={`${BLOCK}__input-size`}>
            {inputFilter}
          </div>
        </div>
        {!values?.length && inputFilter === "" && (
          <div className={`${BLOCK}__placeholder`}>
            {placeholder as React.ReactNode}
          </div>
        )}
        {filteredOptions.length > 0 && showOnEmpty && (
          <button
            className={classNames(`${BLOCK}__chevron-button`, {
              [`${BLOCK}__chevron-button--not-empty`]: values && values.length,
            })}
            data-test="chevron-button"
            onClick={() => toggleOptions(!showOptions)}
            data-testid="chevron-button"
          >
            <Icon
              className={classNames(`${BLOCK}__chevron-icon`, {
                [`${BLOCK}__chevron-icon--open`]: showOptions,
                [`${BLOCK}__chevron-icon--close`]: !showOptions,
              })}
              name="chevron-down"
              height={24}
            />
          </button>
        )}
      </div>

      <div
        ref={optionsRef}
        className={classNames(
          `${BLOCK}__options`,
          {
            [`${BLOCK}__options--show`]: showOptions,
            [`${BLOCK}__options--hide`]: !showOptions,
          },
          {
            [`${BLOCK}__options--map`]: mapFiles,
          }
        )}
        data-testid="drop-down-options"
      >
        {loading || filteredOptions.length === 0 ? (
          <button className={`${BLOCK}__option`} data-testid="loading">
            {loading && (
              <Icon
                name="loading"
                width={20}
                height={20}
                data-test="loading"
                className={classNames(`${BLOCK}__loading`, {
                  "loading-icon": loading,
                })}
              />
            )}
            {!loading && inputFilter !== "" && filteredOptions.length === 0 && (
              <div>
                <FormattedMessage id="filter.no.matches" />
              </div>
            )}
          </button>
        ) : (
          filteredOptions.map((option: any) =>
            objectKeys.children ? (
              <React.Fragment key={`fragment-${option[objectKeys.name]}`}>
                <div
                  className={`${BLOCK}__option-category`}
                  key={option[objectKeys.id]}
                  data-test="option-category"
                >
                  {option[objectKeys.name]}
                </div>
                {option[objectKeys.children].map(
                  (childOption: any, index: any) =>
                    getOption(
                      childOption,
                      {
                        externalId: objectKeys.childExternalId
                          ? objectKeys.childExternalId
                          : "",
                        id: objectKeys.childId ? objectKeys.childId : "",
                        name: objectKeys.childName ? objectKeys.childName : "",
                      },
                      option[objectKeys.id] || index
                    )
                )}
              </React.Fragment>
            ) : (
              getOption(option, objectKeys)
            )
          )
        )}
      </div>
    </div>
  );
};

export default DropDown;
