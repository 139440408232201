import React, { useState, useRef, useEffect } from "react";
import Modal from "../shared/modal/modal";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import Button from "components/shared/button/button";

type Props = {
  setShowFeedbackModal: (value: boolean) => void;
  submitFeedback: ({
    rating,
    feedback,
  }: {
    feedback: string;
    rating: number;
  }) => void;
};

const Feedback = ({ setShowFeedbackModal, submitFeedback }: Props) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const BLOCK = "Feedback";
  const [count, setCount] = useState(0);
  const [disabled, setDisabled] = useState(0);

  // Put focus on input tag
  useEffect(() => {
    inputRef.current!.focus();
  }, []);

  useEffect(() => {
    setDisabled(inputRef.current!.value.trim().length);
  }, [inputRef.current?.value]);

  // Count the number of characters input by the user
  const onInputHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCount(event.target.value.length);
  };

  const saveInputHandler = () => {
    if (inputRef.current!.value.trim().length === 0) {
      return;
    }
    submitFeedback({
      feedback: inputRef.current!.value,
      rating: 5,
    });
    setShowFeedbackModal(false);
  };

  // Automatically call saveInputHandler on Enter
  const onEnter = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13 || event.key === "Enter") {
      saveInputHandler();
    }
  };

  return (
    <Modal
      show={true}
      header="feedback.title"
      handleClose={() => {
        setShowFeedbackModal(false);
      }}
      config={false}
    >
      <div className={BLOCK} data-test="component-feedback">
        <span className={`${BLOCK}__name`}>
          <FormattedMessage id="feedback.message" />
        </span>
        <span className={`${BLOCK}__input`}>
          <textarea
            ref={inputRef}
            data-testid="input-textarea"
            data-test="input-textarea"
            maxLength={1000}
            onChange={onInputHandler}
            onKeyDown={onEnter}
          />
        </span>
        <span className={`${BLOCK}__content`}>
          {count}
          <FormattedMessage id="feedback.input.character" />
        </span>
        <div className={`${BLOCK}__options`}>
          <Button
            className={`${BLOCK}__options-cancel button-secondary`}
            onClick={() => setShowFeedbackModal(false)}
            dataTest="cancel-button"
            formattedText="shortlist.edit.button.cancel"
          />
          <Button
            className={`${BLOCK}__options-send button-primary`}
            onClick={() => saveInputHandler()}
            dataTest="save-feedback"
            formattedText="feedback.save.button"
            disabled={disabled === 0}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Feedback;
