import { Dispatch } from "redux";
import * as api from "./powerbi.api";
import { createAction } from "../../utils/redux.utils";
import { Bookmark, PowerbiModel, PowerbiState } from "./powerbi.model";
import { verifySignatureAndGetDecodedJwt } from "utils/functions";

export const ACTIONS = {
  POWERBI_LOADING: "ESG/UI/POWERBI_LOADING",
  POWERBI_SET_ACCESS_TOKEN: "ESG/UI/SET_ACCESS_TOKEN",
  POWERBI_SET_EMBED_TOKEN: "ESG/UI/SET_EMBED_TOKEN",
  POWERBI_SET_EMBED_URL: "ESG/UI/SET_EMBED_URL",
  POWERBI_GET_BOOKMARK: "ESG/UI/POWERBI_GET_BOOKMARK",
  POWERBI_ESRS_DASHBOARD_ACCESSED: "ESG/UI/POWERBI_ESRS_DASHBOARD_ACCESSED",
};

export const settingAccessToken = createAction(
  ACTIONS.POWERBI_SET_ACCESS_TOKEN
);

export const getPowerbiBookmark = createAction(ACTIONS.POWERBI_GET_BOOKMARK);

export const settingEmbedInfo = createAction(ACTIONS.POWERBI_SET_EMBED_TOKEN);

export const settingEmbedURL = createAction(ACTIONS.POWERBI_SET_EMBED_URL);

export const esrsDashboardAccessed = createAction(
  ACTIONS.POWERBI_ESRS_DASHBOARD_ACCESSED
);

export const setAccessTokenpowerbi =
  (refreshToken: boolean) => async (dispatch: Dispatch<any>) => {
    try {
      const res = await api.getAccessTokenForPowerBI(refreshToken);
      const accessToken = verifySignatureAndGetDecodedJwt(res.data);
      dispatch(settingAccessToken(accessToken));
    } catch (e) {
      console.log("error");
    }
  };

export const getHumancapitalBookmark =
  (dashboardType: number) => async (dispatch: Dispatch<any>) => {
    try {
      const res = await api.getHumanCapitalBookmark(dashboardType);
      dispatch(getPowerbiBookmark(res.data));
    } catch (e) {
      console.log("error");
    }
  };

export const saveBookmark =
  (bookmarkPayload: Bookmark) => async (dispatch: Dispatch<any>) => {
    try {
      const res = await api.saveHumanCapitalBookmark(bookmarkPayload);
      dispatch(getPowerbiBookmark(bookmarkPayload));
    } catch (e) {
      console.log("error");
    }
  };

export const getEmbedTokenpowerbi =
  (workSpaceId: string, reportId: string, payload: any, headers: any) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const res = await api.getEmbedToken(
        workSpaceId,
        reportId,
        payload,
        headers
      );
      dispatch(settingEmbedInfo(res));
    } catch (e) {
      console.log("error");
    }
  };

export const getEmbedURLpowerbi =
  (workSpaceId: string, reportId: string, headers: any) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const res = await api.getEmbedURL(workSpaceId, reportId, headers);
      dispatch(settingEmbedURL(res.embedUrl));
    } catch (e) {
      console.log("error");
    }
  };

const powerbiModel = new PowerbiModel();

const powerbiReducer = (
  state: PowerbiState = powerbiModel.initialState(),
  action: any
): PowerbiState => {
  switch (action.type) {
    case ACTIONS.POWERBI_SET_ACCESS_TOKEN:
      return powerbiModel.setAccessTokenForPowerBI(action.payload);
    case ACTIONS.POWERBI_SET_EMBED_TOKEN:
      return powerbiModel.setEmbedTokenForPowerBI(action.payload);
    case ACTIONS.POWERBI_SET_EMBED_URL:
      return powerbiModel.setEmbedUrlForPowerBI(action.payload);
    case ACTIONS.POWERBI_GET_BOOKMARK:
      return powerbiModel.setPowerbiBookmark(action.payload);
    case ACTIONS.POWERBI_ESRS_DASHBOARD_ACCESSED:
      return powerbiModel.setIsEsrsDashboardAccessed(action.payload);
    default:
      return state;
  }
};

export default powerbiReducer;
