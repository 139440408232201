import { ChangeEventHandler } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import classNames from "classnames";
import { FormattedMessageString } from "utils/functions";

type Props = {
  id: string;
  value: string;
  onChange: ChangeEventHandler;
  label?: string;
  placeholder?: string;
  assertiveText?: string;
  disabled?: boolean;
  error?: boolean;
};

const Textfield = ({
  id,
  value,
  onChange,
  label,
  placeholder,
  assertiveText,
  disabled,
  error,
}: Props) => {
  const BLOCK = "textfield";
  return (
    <div className={BLOCK} data-testid={id}>
      {label ? (
        <span className={`${BLOCK}__label`}>
          <FormattedMessage id={label} />
        </span>
      ) : null}
      <input
        id={`input-${id}`}
        disabled={disabled}
        title={FormattedMessageString(label ?? "")}
        className={classNames(`${BLOCK}__input`, {
          [`${BLOCK}__input--error`]: error,
        })}
        placeholder={placeholder}
        type="text"
        value={value}
        onChange={onChange}
      />
      {assertiveText ? (
        <div
          className={classNames(`${BLOCK}__assertive-text`, {
            [`${BLOCK}__assertive-text--error`]: error,
          })}
        >
          <FormattedMessage id={assertiveText} />
        </div>
      ) : null}
    </div>
  );
};

export default Textfield;
