import { MouseEventHandler, useEffect, useState } from "react";
import Icon from "components/shared/icon/icon";
import classNames from "classnames";
import { updateNotification } from "services/notification/notification.service";
import { useDispatch } from "react-redux";
import FormattedMessage from "../formatted-message/formatted-message";
import history from "utils/history";
import { NotificationData } from "services/notification/notification.model";
import { loadsavedShortlist } from "services/saved-shortlist/saved-shortlist.service";
import { loadSavedSearchList } from "services/searchlist/searchlist.service";
import { loadSavedComparisonsList } from "services/saved-comparisons/saved-comparisons.service";
import {
  DEFAULT_SAVED_SEARCHES_PAYLOAD,
  DEFAULT_SHORTLISTS_PAYLOAD,
  NOTIFICATION_TYPES,
  NOTIFICATION_STATUS,
  DEFAULT_SAVED_COMPARISONS_PAYLOAD,
  TAG_NOTIFICATION_ACTIONS,
} from "utils/constants";
import Parser from "html-react-parser";

type Props = {
  show: boolean;
  handleClose?: MouseEventHandler;
  header: string;
  children?: JSX.Element;
  config?: boolean;
  iconName?: string;
  notif: NotificationData;
  setShowShared?: (value: boolean) => void;
  BLOCK2: string;
};

const Notification = ({
  show,
  handleClose,
  header,
  children,
  config = true,
  iconName,
  notif,
  setShowShared,
  BLOCK2,
}: Props) => {
  const BLOCK = "notificationESG";
  const dispatch = useDispatch();
  const [showState, setShowState] = useState(show);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowState(false);
      const payload = {
        notificationId: notif.notificationId,
        notificationStatus: 2,
      };
      dispatch(updateNotification(payload));
    }, 62000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gotoSavedList = (notification: NotificationData) => () => {
    const payload = {
      notificationId: notification.notificationId,
      notificationStatus: NOTIFICATION_STATUS.CLOSED,
    };
    if (notif.notificationType === NOTIFICATION_TYPES.SHARE_SHORTLIST) {
      history.push("/shortlist");
      dispatch(loadsavedShortlist(DEFAULT_SHORTLISTS_PAYLOAD));
    } else if (
      notif.notificationType === NOTIFICATION_TYPES.SHARE_SEARCH ||
      notif.notificationType === NOTIFICATION_TYPES.TO_BE_PURGED_SHARE
    ) {
      history.push("/mysearches");
      dispatch(loadSavedSearchList(DEFAULT_SAVED_SEARCHES_PAYLOAD));
    } else if (
      [
        NOTIFICATION_TYPES.SHARE_COMPARE,
        NOTIFICATION_TYPES.SHARED_COMPARISON_FOR_COLLABORATION,
        TAG_NOTIFICATION_ACTIONS.ROL_CHANGEG,
        NOTIFICATION_TYPES.AUTO_DELETE_COMPARE,
      ].includes(notif.notificationType)
    ) {
      history.push(`/comparison/${notification.referenceId}`);
      dispatch(loadSavedComparisonsList(DEFAULT_SAVED_COMPARISONS_PAYLOAD));
    } else if (
      notif.notificationType === NOTIFICATION_TYPES.SHARED_INSIGHT ||
      notif.notificationType === NOTIFICATION_TYPES.AUTO_DELETE_INSIGHT
    ) {
      history.push(`/insights/${notification.referenceId}`, {
        insightDetail: {
          sharedBy: true,
        },
      });
    } else if (
      [
        TAG_NOTIFICATION_ACTIONS.USER_REMOVED,
        TAG_NOTIFICATION_ACTIONS.CONTENT_BENCHMARK_DELETED,
      ].includes(notif.notificationType)
    ) {
      history.push(`/comparisons`);
      dispatch(loadSavedComparisonsList(DEFAULT_SAVED_COMPARISONS_PAYLOAD));
    } else if (
      notif.notificationType === NOTIFICATION_TYPES.SHARED_PEER_BENCHMARK
    ) {
      history.push(`/peerbenchmark/${notification.referenceId}`);
    }

    setShowState(false);
    dispatch(updateNotification(payload));
  };

  const highlightOption = (option: string) => {
    // Logic to show color based on number of days
    // Currently not working, so only being used to remove the number of days
    if (option.includes("7 days")) {
      const replace = new RegExp(`7 days.`, "i");
      return option.split(replace).join("");
    } else if (option.includes("3 days.")) {
      const replace = new RegExp(`3 days.`, "i");
      return option.split(replace).join("");
    } else if (option.includes("1 day.")) {
      const replace = new RegExp(`1 day.`, "i");
      return option.split(replace).join("");
    }
    return option;
  };

  const highlightDataInMiddleofText = (option: string) => {
    // Logic to show color based on number of days
    // if number of days are present in middle of the notification text.
    if (option.includes("7 days")) {
      const replace = new RegExp(`7 days`, "i");
      return option.replace(
        replace,
        `<span style="color:#ed8b00">7 days</span>`
      );
    } else if (option.includes("3 days")) {
      const replace = new RegExp(`3 days`, "i");
      return option.replace(
        replace,
        `<span style="color:#da291c">3 days</span>`
      );
    } else if (option.includes("1 day")) {
      const replace = new RegExp(`1 day`, "i");
      return option.replace(
        replace,
        `<span style="color:#da291c">1 day</span>`
      );
    }
    return option;
  };

  const viewOptionBtn = (id: number) => {
    if (id === NOTIFICATION_TYPES.SHARE_SHORTLIST) {
      return "notification.button.viewshortlist";
    } else if (id === NOTIFICATION_TYPES.SHARE_SEARCH) {
      return "notification.button.viewsearch";
    } else if (
      id === NOTIFICATION_TYPES.SHARED_INSIGHT ||
      id === NOTIFICATION_TYPES.AUTO_DELETE_INSIGHT
    ) {
      return "notification.button.insight";
    } else if (id === NOTIFICATION_TYPES.SHARED_PEER_BENCHMARK) {
      return "notification.button.peerbenchmark";
    } else if (
      [
        TAG_NOTIFICATION_ACTIONS.USER_REMOVED,
        TAG_NOTIFICATION_ACTIONS.CONTENT_BENCHMARK_DELETED,
      ].includes(id)
    ) {
      return "notification.button.compare-list";
    } else {
      return "notification.button.compare";
    }
  };

  return (
    <>
      {showState && (
        <div
          className={classNames(BLOCK, { [`${BLOCK}--show`]: showState })}
          onClick={config ? handleClose : () => {}}
          data-test={"notification-handle-close"}
          data-testid={"notification-handle-close"}
        >
          <div
            className={classNames(`${BLOCK}__content`, {
              [`${BLOCK}__content--show`]: showState,
            })}
          >
            <div className={`${BLOCK}__header`}>
              <Icon
                name={iconName ? iconName : "warning-circle-blue"}
                width={20}
                height={20}
              />
              <h1>{header}</h1>
            </div>
            <div className={`${BLOCK}__body`}>
              {notif.notificationType ===
              NOTIFICATION_TYPES.TO_BE_PURGED_SHARE ? (
                <div className={BLOCK2}>
                  <span className={`${BLOCK2}__content`}>
                    <span>
                      {Parser(highlightOption(notif.notificationBody))}
                    </span>
                    {notif.notificationBody.includes("7 days") && (
                      <span style={{ color: "#ed8b00" }}>{"7 days."}</span>
                    )}
                    {notif.notificationBody.includes("3 days") && (
                      <span style={{ color: "#da291c" }}>{"3 days."}</span>
                    )}
                    {notif.notificationBody.includes("1 day") && (
                      <span style={{ color: "#da291c" }}>{"1 day."}</span>
                    )}
                  </span>
                  <div className={`${BLOCK2}__options`}>
                    <button
                      className={`primary-outline`}
                      data-test="cancel-button"
                      data-testid="auto-delete-cancel-button"
                      onClick={() => {
                        setShowState(false);
                        const payload = {
                          notificationId: notif.notificationId,
                          notificationStatus: 2,
                        };
                        dispatch(updateNotification(payload));
                      }}
                    >
                      <FormattedMessage id="button.close" />
                    </button>
                    <button
                      className={`${BLOCK2}__continue primary`}
                      onClick={gotoSavedList(notif)}
                    >
                      <FormattedMessage id="notification.button.viewsearch" />
                    </button>
                  </div>
                </div>
              ) : notif.notificationType ==
                NOTIFICATION_TYPES.AUTO_DELETE_COMPARE ? (
                <div className={BLOCK2}>
                  <span className={`${BLOCK2}__content`}>
                    <span>
                      {Parser(
                        highlightDataInMiddleofText(notif.notificationBody)
                      )}
                    </span>
                  </span>
                  <div className={`${BLOCK2}__options`}>
                    <button
                      className={`primary-outline`}
                      data-test="cancel-button"
                      data-testid="auto-delete-cancel-button"
                      onClick={() => {
                        setShowState(false);
                        const payload = {
                          notificationId: notif.notificationId,
                          notificationStatus: 2,
                        };
                        dispatch(updateNotification(payload));
                      }}
                    >
                      <FormattedMessage id="button.close" />
                    </button>
                    <button
                      className={`${BLOCK2}__continue primary`}
                      data-test="notification-share-compare-button"
                      data-testid="notification-share-compare-button"
                      onClick={gotoSavedList(notif)}
                    >
                      <FormattedMessage
                        id={viewOptionBtn(notif.notificationType)}
                      />
                    </button>
                  </div>
                </div>
              ) : (
                <div className={BLOCK2}>
                  <span className={`${BLOCK2}__content`}>
                    {notif.notificationBody}
                  </span>
                  <div className={`${BLOCK2}__options`}>
                    <button
                      className={`primary-outline`}
                      data-test="cancel-button"
                      data-testid="cancel-button"
                      onClick={() => {
                        setShowState(false);
                        const payload = {
                          notificationId: notif.notificationId,
                          notificationStatus: NOTIFICATION_STATUS.CLOSED,
                        };
                        dispatch(updateNotification(payload));
                      }}
                    >
                      <FormattedMessage id="button.close" />
                    </button>
                    <button
                      className={`${BLOCK2}__continue primary`}
                      onClick={gotoSavedList(notif)}
                      data-test="notification-share-search-button"
                      data-testid="notification-share-search-button"
                    >
                      <FormattedMessage
                        id={viewOptionBtn(notif.notificationType)}
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
