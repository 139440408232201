import { useEffect } from "react";

type Props = {
  page: string;
};

const PdfViewer = ({ page }: Props) => {
  useEffect(() => {
    window.location.href = `${process.env.PUBLIC_URL}/assets/${page}.pdf`;
  }, [page]);

  return (
    <iframe
      className="iframe"
      title={`${page} Document`}
      src={`${process.env.PUBLIC_URL}/assets/${page}.pdf`}
    />
  );
};

export default PdfViewer;
