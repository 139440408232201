import React, { useState, useEffect, useRef } from "react";
import Modal from "../shared/modal/modal";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { addToastMessage } from "services/commons.service";
import {
  loadDocumentTypeFilter,
  loadGoverningBoardFilter,
} from "services/search/filters.service";
import { FILTER_OPTIONS } from "utils/constants";
import DropDown from "components/search/filters/drop-down";
import {
  FormTypeFilter,
  GoverningBoardFilter,
} from "services/search/filters.model";

import {
  editFileError,
  editFilePopUp,
  editFileSuccess,
  fileToEdit,
  refreshFilesList,
} from "services/manage-files/manage-files.service";
import Icon from "components/shared/icon/icon";
import Button from "components/shared/button/button";

type props = {
  setShowModal: (value: boolean) => void;
  editFile: (
    cikNumber: number,
    reportTitle: string,
    formType: FormTypeFilter,
    governingBoards: GoverningBoardFilter[],
    reportYears: { periodYear: number }[]
  ) => void;
};

const EditFile = ({ setShowModal, editFile }: props) => {
  const BLOCK = "edit-file";
  const [counts, setCounts] = useState({ title: 0 });
  const [disabled, setDisabled] = useState(true);
  const cikNumberInputRef = useRef<HTMLInputElement>(null);
  const [values, setValues] = useState<{
    company: string;
    cikNumber: string;
    title: string;
    formTypeFilter: FormTypeFilter[];
    esgFramework: GoverningBoardFilter[];
    reportYears: { periodYear: number}[];
  }>({
    company: "",
    cikNumber: "",
    title: "",
    formTypeFilter: [],
    esgFramework: [],
    reportYears: [],
  });
  const [errors, setErrors] = useState({
    cikNumber: false,
    title: false,
    formTypeFilter: false,
    invalidCikNumber: false,
    duplicatedReport: false,
  });
  const getYears = () => {
    let today = new Date().getFullYear();
    let years: { periodYear: number }[] = [];
    for (let i = today - 5; i <= today + 5; i++) {
      years.push({ periodYear: i });
    }

    if (manageFilesState.fileToEdit)
      manageFilesState.fileToEdit.reportYears.forEach((y: number) => {
        if (!years.find((p) => p.periodYear.toString() === y.toString())) {
          years.push({ periodYear: y });
        }
      });
    years.sort(
      (a: { periodYear: number }, b: { periodYear: number }) =>
      (a.periodYear - b.periodYear)
    );
    return years;
  };
  const dispatch = useDispatch();
  //const [errorMessage, setErrorMessage] = useState(false);
  const filtersState = useSelector((state: RootStore) => state.filters);
  const manageFilesState = useSelector((state: RootStore) => state.manageFiles);
  const trimStartFunc = (value: any) => {
    if (!value) return value;
    return value.replace(/^\s+/g, "");
  };

  useEffect(() => {
    if (filtersState.documentTypeFilterOptions.length === 0)
      dispatch(loadDocumentTypeFilter());
    if (filtersState.governingBoardFilterOptions.length === 0)
      dispatch(loadGoverningBoardFilter());

    if (manageFilesState.fileToEdit) {
      setValues({
        ...values,
        company: manageFilesState.fileToEdit.reportCompany.companyName,
        title: manageFilesState.fileToEdit.reportTitle,
        cikNumber:
          manageFilesState.fileToEdit.reportCompany.cikNumber.toString(),
        formTypeFilter: [manageFilesState.fileToEdit.reportFormType] || [],
        esgFramework: manageFilesState.fileToEdit.governingBoards || [],

        reportYears:
          manageFilesState.fileToEdit.reportYears.map((year) => {
            return { periodYear: year };
          }) || [],
      });
      setCounts({
        ...counts,
        title: manageFilesState.fileToEdit.reportTitle.length,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (manageFilesState.editFileError) {
      setDisabled(true);
      if (manageFilesState.fileToEdit) {
        cikNumberInputRef.current!.focus();
        setValues({
          ...values,
          company: manageFilesState.fileToEdit.reportCompany.companyName,
          title: manageFilesState.fileToEdit.reportTitle,
          cikNumber:
            manageFilesState.fileToEdit.reportCompany.cikNumber.toString(),
          formTypeFilter: [manageFilesState.fileToEdit.reportFormType] || [],
          esgFramework: manageFilesState.fileToEdit.governingBoards || [],
          reportYears:
            manageFilesState.fileToEdit.reportYears.map((year) => {
              return { periodYear: year };
            }) || [],
        });
        setCounts({
          ...counts,
          title: manageFilesState.fileToEdit.reportTitle.length,
        });
        setErrors({
          ...errors,
          invalidCikNumber: manageFilesState.fileToEdit.invalidCikNumber,
          cikNumber: manageFilesState.fileToEdit.invalidCikNumber,
          duplicatedReport: manageFilesState.fileToEdit.duplicatedReport,
          title: manageFilesState.fileToEdit.duplicatedReport,
        });

        dispatch(
          fileToEdit({
            ...manageFilesState.fileToEdit,
            duplicatedReport: false,
            invalidCikNumber: false,
          })
        );
      }

      dispatch(editFileError(false));
      dispatch(editFileSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageFilesState.editFileError]);
  useEffect(() => {
    if (manageFilesState.editFileSuccess) {
      dispatch(editFilePopUp(false));
      //setErrorMessage(false);
      setShowModal(false);
      dispatch(editFileSuccess(false));
      dispatch(refreshFilesList(true));
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="edit.file.success" />,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageFilesState.editFileSuccess]);

  const selectDocumentType = (formType: FormTypeFilter) => {
    let tmpDocumentType: FormTypeFilter[] = [formType];
    setValues({ ...values, formTypeFilter: tmpDocumentType });
    setErrors({ ...errors, formTypeFilter: false });
  };

  const unselectDocumentType = (formType?: FormTypeFilter) => {
    let tmpDocumentType: FormTypeFilter[] = [];
    setValues({ ...values, formTypeFilter: tmpDocumentType });
    setErrors({ ...errors, formTypeFilter: true });
  };

  const selectPeriod = (year: any) => {
    let tmpYears: any[] = [...values.reportYears];
    tmpYears.push(year);
    setValues({ ...values, reportYears: tmpYears });
  };

  const unselectPeriod = (year: any) => {
    let tmpReportYears = values.reportYears.filter(
      (y) => y.periodYear !== year.periodYear
    );
    setValues({ ...values, reportYears: tmpReportYears });
  };

  const selectGoverningBoard = (governingBoard: GoverningBoardFilter) => {
    let tmpGoverningBoard: any[] = [...values.esgFramework];
    tmpGoverningBoard.push(governingBoard);
    setValues({ ...values, esgFramework: tmpGoverningBoard });
  };

  const unselectGoverningBoard = (governingBoard: GoverningBoardFilter) => {
    let tmpReportYears = values.esgFramework.filter(
      (y) => y.governingBoardId !== governingBoard.governingBoardId
    );
    setValues({ ...values, esgFramework: tmpReportYears });
  };

  const onChangeHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (event.target.name === "cikNumber" && isNaN(event.target.value as any)) {
      return;
    }
    if (event.target.name === "title") {
      setCounts({
        ...counts,
        [event.target.name]: trimStartFunc(event.target.value).length,
      });
    }
    setErrors({ ...errors, cikNumber: false, title: false });

    if (trimStartFunc(event.target.value).length === 0) {
      setErrors({ ...errors, [event.target.name]: true });
    }

    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleEditFile = () => {
    editFile(
      parseInt(values.cikNumber),
      trimStartFunc(values.title).trim(),
      values.formTypeFilter[0],
      values.esgFramework,
      values.reportYears
    );
  };

  useEffect(() => {
    validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const validateFields = () => {
    if (values.formTypeFilter.length === 0) {
      setDisabled(true);
      return;
    }
    if (values.title.length === 0) {
      setDisabled(true);
      return;
    }
    if (values.cikNumber.toString().length === 0) {
      setDisabled(true);
      return;
    }
    let originalValues = {
      ...values,
      company: manageFilesState.fileToEdit.reportCompany.companyName,
      title: manageFilesState.fileToEdit.reportTitle,
      cikNumber: manageFilesState.fileToEdit.reportCompany.cikNumber.toString(),
      formTypeFilter: [manageFilesState.fileToEdit.reportFormType][0]
        .formTypeId,
      esgFramework:
        manageFilesState.fileToEdit.governingBoards.sort((a, b) => {
          return a.governingBoardId - b.governingBoardId;
        }) || [],
      reportYears:
        manageFilesState.fileToEdit.reportYears
          .map((year) => {
            return { periodYear: year };
          })
          .sort((a, b) => {
            return (a.periodYear - b.periodYear);
          }) || [],
    };
    let currentValues = {
      ...values,
      title: values.title.trim(),
      formTypeFilter: values.formTypeFilter[0].formTypeId,
      esgFramework: values.esgFramework.sort((a, b) => {
        return a.governingBoardId - b.governingBoardId;
      }),
      reportYears: values.reportYears.sort((a, b) => {
        return (a.periodYear - b.periodYear);
      }),
    };

    if (JSON.stringify(originalValues) === JSON.stringify(currentValues)) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  };

  const getDocmentType = (documentTypeFilterOptions: any) => {
    let documentTypeOptions = documentTypeFilterOptions.filter(
      (document: any) => document.documentCategoryId === 1
    );
    return documentTypeOptions;
  };
  return (
    <Modal
      show={true}
      header="edit.file.edit.title"
      handleClose={() => {
        dispatch(editFilePopUp(false));
        setShowModal(false);
      }}
      config={false}
    >
      <div className={`${BLOCK}__container`}>
        {errors.invalidCikNumber && (
          <div
            className={`${BLOCK}__top-errors`}
            data-testid="invalid-cik-number-error"
          >
            <div className={`${BLOCK}__top-errors-icon`}>
              <Icon width={24} height={24} name="warning-white-mark" />
            </div>
            <span className={`${BLOCK}__top-errors-msg`}>
              <FormattedMessage id="edit.file.ivalid.cik.error" />
            </span>
          </div>
        )}
        {errors.duplicatedReport && (
          <div
            className={`${BLOCK}__top-errors`}
            data-testid="duplicate-report-error"
          >
            <div className={`${BLOCK}__top-errors-icon`}>
              <Icon width={24} height={24} name="warning-white-mark" />
            </div>
            <span className={`${BLOCK}__top-errors-msg`}>
              <FormattedMessage id="edit.file.duplicate.report.error" />
            </span>
          </div>
        )}

        <div className={`${BLOCK}__fields`}>
          <div className={`${BLOCK}__field-wrapper`}>
            <span className={`${BLOCK}__field-label`}>
              <FormattedMessage id="filter.company" />
            </span>
            <input
              className={`${BLOCK}__field-input ${BLOCK}__field-input--disabled`}
              disabled={true}
              value={values.company || ""}
              name="campany"
              type="text"
            />
          </div>
          <div className={`${BLOCK}__field-wrapper`}>
            <span className={`${BLOCK}__field-label`}>
              <FormattedMessage id="manage-files.cik.no" />
            </span>
            <input
              className={classNames(`${BLOCK}__field-input`, {
                [`${BLOCK}__field-input--error`]: errors.cikNumber,
              })}
              data-testid="cik-number-input"
              value={values.cikNumber || ""}
              name="cikNumber"
              onChange={onChangeHandler}
              ref={cikNumberInputRef}
            />
          </div>
          <div className={`${BLOCK}__field-wrapper`}>
            <span className={`${BLOCK}__field-label`}>
              <FormattedMessage id="search.results.title" />
            </span>
            <input
              className={classNames(`${BLOCK}__field-input`, {
                [`${BLOCK}__field-input--error`]: errors.title,
              })}
              data-testid="title-input"
              value={values.title || ""}
              name="title"
              type="text"
              maxLength={200}
              onChange={onChangeHandler}
            />
            <span className={`${BLOCK}__field-count`}>
              {counts.title}
              <FormattedMessage id="shortlist.save.input.character" />
            </span>
          </div>

          <div className={`${BLOCK}__field-wrapper`}>
            <DropDown
              className={classNames(`${BLOCK}__field-drop-down`, {
                [`${BLOCK}__field-drop-down--error`]: errors.formTypeFilter,
              })}
              label={<FormattedMessage id="filter.document-type" />}
              placeholder={
                <FormattedMessage id="filter.document-type.placeholder" />
              }
              loading={
                filtersState.loadingFilter.filter(
                  (filter) => filter === FILTER_OPTIONS.DOCUMENT_TYPE
                ).length > 0
              }
              objectKeys={{
                name: "documentCategoryName",
                id: "documentCategoryId",
                externalId: "documentCategoryId",
                children: "formTypes",
                childName: "formTypeName",
                childId: "formTypeId",
              }}
              values={values.formTypeFilter}
              options={getDocmentType(filtersState.documentTypeFilterOptions)}
              handleSelect={selectDocumentType}
              handleUnselect={unselectDocumentType}
              handleGroupUnselect={unselectDocumentType}
              mapFiles={true}
            />
          </div>
          <div className={`${BLOCK}__field-wrapper`}>
            <DropDown
              className={`${BLOCK}__field-drop-down`}
              label={<FormattedMessage id="edit.file.esg.framework" />}
              placeholder={
                <FormattedMessage id="edit.file.esg.framework.placeholder" />
              }
              loading={
                filtersState.loadingFilter.filter(
                  (filter) => filter === FILTER_OPTIONS.GOVERNING_BOARD
                ).length > 0
              }
              objectKeys={{
                name: "governingBoardName",
                id: "governingBoardId",
                externalId: "governingBoardId",
              }}
              values={values.esgFramework}
              options={filtersState.governingBoardFilterOptions}
              handleSelect={selectGoverningBoard}
              handleUnselect={unselectGoverningBoard}
            />
          </div>
          <div className={`${BLOCK}__field-wrapper`}>
            <DropDown
              className={`${BLOCK}__field-drop-down`}
              label={<FormattedMessage id="edit.file.report.year" />}
              placeholder={<FormattedMessage id="filter.period.placeholder" />}
              loading={false}
              objectKeys={{
                name: "periodYear",
                id: "periodYear",
                externalId: "periodYear",
              }}
              values={values.reportYears}
              options={getYears()}
              handleSelect={selectPeriod}
              handleUnselect={unselectPeriod}
              handleGroupUnselect={unselectPeriod}
            />
          </div>
        </div>
        <div className={`${BLOCK}__options`}>
          <Button
            className={`button-secondary`}
            dataTest="cancel-btn"
            formattedText="shortlist.save.button.cancel"
            onClick={() => {
              setShowModal(false);
              dispatch(editFilePopUp(false));
            }}
          />

          <Button
            className={`button-primary`}
            dataTest="edit-saved-search-btn"
            formattedText="shortlist.save.button.save"
            disabled={disabled}
            onClick={handleEditFile}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditFile;
