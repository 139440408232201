import classNames from "classnames";
import Icon from "components/shared/icon/icon";
import { useEffect, useState } from "react";
import {
  talkingPointCollection,
  talkingPointHeaders,
  talkingPoints,
} from "services/insights/insights.model";
import { sanitize } from "dompurify";
import Parser from "html-react-parser";
type props = {
  BLOCK?: string;
  tpData: talkingPointCollection[];
};
const TalkingPointsAccordion = ({ BLOCK, tpData }: props) => {
  BLOCK = "talking-points-accordion";
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  useEffect(() => {
    if (tpData && tpData.length > 0) {
      setSelectedItems([...selectedItems, tpData[0].benchmarkTileType]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tpData]);

  const setHyperLinks = (data: string) => {
    const links = data && data.match(/<link>(.*?)<\/link>/g);
    return links
      ? links.reduce((prev, curr) => {
          const relativeLink: string = curr
            .replace("<link>", "")
            .startsWith("http")
            ? ""
            : "//";
          return prev.replace(
            /<link>(.*?)<\/link>/g,
            `<a target="_blank" href="${relativeLink}$1">$1</a>`
          );
        }, data)
      : data;
  };

  const renderAccordionItems = (currentTP: talkingPointCollection[]) => {
    return tpData?.map((item: talkingPointCollection, index: number) => {
      const handleSelectedItems = () => {
        if (!selectedItems.includes(item.benchmarkTileType)) {
          setSelectedItems((selectedItems) => [
            ...selectedItems,
            item.benchmarkTileType,
          ]);
        } else {
          const itemIndex = selectedItems.indexOf(item.benchmarkTileType);
          setSelectedItems((selectedItems) => [
            ...selectedItems.slice(0, itemIndex),
            ...selectedItems.slice(itemIndex + 1),
          ]);
        }
      };
      return (
        <div
          className={`${BLOCK}__item`}
          key={`${item.benchmarkTileType}__${index}`}
        >
          <span className={`${BLOCK}__item-header`}>
            <button
              className={`${BLOCK}__item-button`}
              onClick={() => {
                handleSelectedItems();
              }}
              data-testid="accordion-btn"
            >
              <Icon
                name={
                  selectedItems.includes(item.benchmarkTileType)
                    ? "caret-down"
                    : "caret-right"
                }
                width={11}
                height={11}
              />
              {`${item.benchmarkTitle}`}
            </button>
          </span>
          <div
            className={classNames(`${BLOCK}__item-collapse`, {
              [`${BLOCK}__item-collapse--hide`]: !selectedItems.includes(
                item.benchmarkTileType
              ),
            })}
            data-testid="collapsible-container"
          >
            <div className={`${BLOCK}__item-body`}>
              {item.talkingPointHeaders?.map(
                (header: talkingPointHeaders, index: number) => {
                  return (
                    <div key={`${header.talkingPointHeaderId}__${index}`}>
                      <span style={{ fontWeight: "bold" }}>
                        {header.talkingPointHeaderText}
                      </span>
                      <ul>
                        {header.talkingPoints?.map(
                          (points: talkingPoints, index: number) => {
                            return (
                              <li key={`${points.talkingPointId}__${index}`}>
                                {Parser(
                                  sanitize(
                                    setHyperLinks(points.talkingPointText)
                                  )
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div className={`${BLOCK}`} data-testid="accordion-container">
      {renderAccordionItems(tpData)}
    </div>
  );
};
export default TalkingPointsAccordion;
