import { BaseModel } from "utils/redux.utils";
import { CompanyFilter } from "services/search/filters.model";
import { FILTER_OPTIONS } from "utils/constants";
import { SuggestPeerCompanyFilter } from "services/dashboard/dashboard.model";
import { UserObj } from "services/searchlist/searchlist.model";
import { GlobalCompanyGroup } from "services/insights/insights.model";

export interface PeerBenchmarkState {
  error: boolean;
  loading: boolean;
  peerBenchmarkToSave: PeerBenchmarkToSave;
  companyFilterOptions: CompanyFilter[];
  peerCompanyFilterOptions: CompanyFilter[];
  companyFilter: CompanyFilter[];
  peerCompanyFilter: CompanyFilter[];
  loadingFilter: FILTER_OPTIONS[];
  metrics: PeerbenchmarkMetric[];
  saveNewBenchmarkSuccess: boolean;
  savedBenchmarkID: number;
  currentSavedBenchmark: any;
  errorRename: boolean;
  renameSuccess: boolean;
  suggestedPeerCompanyFilter: SuggestPeerCompanyFilter[];
  savedBenchmark: SavedPeerBenchMarkList;
  selectedItems: number[];
  sharePeerBenchmarkSuccess: boolean;
  deletePeerBenchmarkSuccess: boolean;
  editPeerBenchmarkData: any;
  currentBenchMarkData: any;
  editBenchmarkSuccess: boolean;
  errorEditRename: boolean;
  currentFilter: string;
  removeSuccess: boolean;
  editPeerBenchmarkSuccess: boolean;
  errorPeerEditRename: boolean;
  selectedPeerGroupItems: number[];
  expandAllMetrics: boolean;
  collapseAllMetrics: boolean;
  hasMetricOpen: boolean;
  globalCompanyMappingsFilter: Partial<GlobalCompanyGroup>[];
  savedUserPreference: Partial<UserPreference>;
}

export class PeerBenchmarkModel extends BaseModel {
  constructor() {
    super({
      error: false,
      loading: false,
      peerBenchmarkToSave: null,
      metrics: null,
      loadingFilter: [],
      companyFilterOptions: [],
      peerCompanyFilterOptions: [],
      companyFilter: [],
      peerCompanyFilter: [],
      saveNewBenchmarkSuccess: false,
      savedBenchmarkID: -1,
      currentSavedBenchmark: null,
      errorRename: false,
      renameSuccess: false,
      suggestedPeerCompanyFilter: [],
      savedBenchmark: { pageInfo: null, benchmarks: [] },
      selectedItems: [],
      sharePeerBenchmarkSuccess: false,
      deletePeerBenchmarkSuccess: false,
      editPeerBenchmarkData: null,
      currentBenchMarkData: null,
      editBenchmarkSuccess: false,
      errorEditRename: false,
      currentFilter: "",
      removeSuccess: false,
      selectedPeerGroupItems: [],
      expandAllMetrics: false,
      collapseAllMetrics: false,
      hasMetricOpen: false,
      globalCompanyMappingsFilter: [],
      savedUserPreference: null,
    });
  }
  setError = (error: boolean): PeerBenchmarkState => this.merge({ error });
  setLoading = (loading: boolean): PeerBenchmarkState =>
    this.merge({ loading });
  setPeerBenchmarkToSave = (
    peerBenchmarkToSave: PeerBenchmarkToSave
  ): PeerBenchmarkState => this.merge({ peerBenchmarkToSave });
  setMetrics = (metrics: PeerbenchmarkMetric[]): PeerBenchmarkState => {
    return this.merge({ metrics });
  };
  setLoadingFilter = (loadingFilter: FILTER_OPTIONS): PeerBenchmarkState =>
    this.merge({ loadingFilter: [...this.state.loadingFilter, loadingFilter] });
  setBaselineCompanyOptions = (
    companyFilterOptions: CompanyFilter[]
  ): PeerBenchmarkState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      companyFilterOptions,
    });

  loadSuggestedPeerCompanyFilterOptions = (
    suggestedPeerCompanyFilter: SuggestPeerCompanyFilter[]
  ): PeerBenchmarkState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      suggestedPeerCompanyFilter,
    });

  setPeerCompanyFilterOptions = (
    peerCompanyFilterOptions: CompanyFilter[]
  ): PeerBenchmarkState => {
    return this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      peerCompanyFilterOptions,
    });
  };

  setCompanyFilter = (companyFilter: CompanyFilter[]): PeerBenchmarkState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      companyFilter,
      peerCompanyFilter:
        companyFilter.length !== 0
          ? this.state.peerCompanyFilter.filter(
              (company: CompanyFilter) =>
                company.cikNumber !== companyFilter[0].cikNumber
            )
          : this.state.peerCompanyFilter,
      selectedItems:
        companyFilter.length !== 0
          ? this.state.selectedItems.filter(
              (item: number) => item !== companyFilter[0].cikNumber
            )
          : this.state.selectedItems,
    });

  setPeerCompanyFilter = (
    peerCompanyFilter: CompanyFilter[]
  ): PeerBenchmarkState => {
    return this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      peerCompanyFilter: peerCompanyFilter,
    });
  };

  setSaveNewBenchmarkSuccess = (
    saveNewBenchmarkSuccess: boolean
  ): PeerBenchmarkState => this.merge({ saveNewBenchmarkSuccess });
  setSavedBenchmarkID = (savedBenchmarkID: number): PeerBenchmarkState =>
    this.merge({ savedBenchmarkID });
  setSavedBenchmark = (
    savedBenchmark: SavedPeerBenchMarkList
  ): PeerBenchmarkState => this.merge({ savedBenchmark });
  setCurrentSavedBenchmark = (currentSavedBenchmark: any): PeerBenchmarkState =>
    this.merge({
      currentSavedBenchmark,
    });

  resetSavedBenchmarkID = (): PeerBenchmarkState =>
    this.merge({ savedBenchmarkID: -1 });
  setsavedUserPreference = (savedUserPreference: UserPreference) =>
    this.merge({ savedUserPreference });
  resetCurrentSavedBenchmark = (): PeerBenchmarkState =>
    this.merge({ currentSavedBenchmark: null });
  setErrorRename = (errorRename: boolean): PeerBenchmarkState =>
    this.merge({ errorRename });
  setRenameSuccess = (renameSuccess: boolean): PeerBenchmarkState =>
    this.merge({ renameSuccess });

  setSelectedItems = (selectedItems: number[]): PeerBenchmarkState =>
    this.merge({ selectedItems });

  resetSelectedItems = (): PeerBenchmarkState =>
    this.merge({ selectedItems: [] });

  setSharePeerBenchmarkSuccess = (
    sharePeerBenchmarkSuccess: boolean
  ): PeerBenchmarkState => this.merge({ sharePeerBenchmarkSuccess });

  setDeletePeerBenchmarkSuccess = (
    deletePeerBenchmarkSuccess: boolean
  ): PeerBenchmarkState => this.merge({ deletePeerBenchmarkSuccess });

  setEditPeerBenchmarkData = (editPeerBenchmarkData: any): PeerBenchmarkState =>
    this.merge({ editPeerBenchmarkData: editPeerBenchmarkData });

  setCurrentBenchmarkData = (currentBenchMarkData: any): PeerBenchmarkState =>
    this.merge({ currentBenchMarkData });

  setErrorEditRename = (errorEditRename: boolean): PeerBenchmarkState =>
    this.merge({ errorEditRename });
  setEditBenchmarkSuccess = (
    editBenchmarkSuccess: boolean
  ): PeerBenchmarkState => this.merge({ editBenchmarkSuccess });

  setCurrentFilter = (currentFilter: string): PeerBenchmarkState =>
    this.merge({ currentFilter });
  setErrorEditPeerRename = (errorPeerEditRename: boolean): PeerBenchmarkState =>
    this.merge({ errorPeerEditRename });
  setEditBenchmarkPeerSuccess = (
    editPeerBenchmarkSuccess: boolean
  ): PeerBenchmarkState => this.merge({ editPeerBenchmarkSuccess });

  setRemoveSuccess = (removeSuccess: boolean): PeerBenchmarkState =>
    this.merge({ removeSuccess });
  setSelectedPeerGroupItems = (
    selectedPeerGroupItems: number[]
  ): PeerBenchmarkState => this.merge({ selectedPeerGroupItems });

  setExpandAllMetrics = (expandAllMetrics: boolean): PeerBenchmarkState =>
    this.merge({ expandAllMetrics });
  setCollapseAllMetrics = (collapseAllMetrics: boolean): PeerBenchmarkState =>
    this.merge({ collapseAllMetrics });

  setHasMetricOpen = (hasMetricOpen: boolean): PeerBenchmarkState =>
    this.merge({ hasMetricOpen });

  setGlobalCompanyMappingsFilter = (
    globalCompanyMappingsFilter: Partial<GlobalCompanyGroup>[]
  ): PeerBenchmarkState => {
    return this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.GLOBAL_COMPANY
      ),
      globalCompanyMappingsFilter: globalCompanyMappingsFilter,
    });
  };
}

export type MetricFromResponse = {
  metricCategoryFieldId: number;
  metricCategory: MetricCategory;
  fieldGroup: MetricFieldGroup;
  field: MetricField;
  displayOrder: number;
};

export type MetricCategory = {
  metricCategoryId: number;
  metricCategoryName: string;
  displayName: string;
  displayOrder: number;
  fields: any;
  fieldGroups: any;
};

export type PeerbenchmarkMetric = {
  PeerbenchmarkTabName: string;
  PeerbenchmarkId: number;
  PeerbenchmarkCategories: Metric[];
};

export type Metric = {
  metricCategoryId: number;
  metricCategoryName: string;
  displayName: string;
  displayOrder: number;
  fields: MetricField[];
  fieldGroups: MetricFieldGroup[];
};

export type MetricField = {
  fieldId: number;
  fieldName: string;
  vendorFieldName: string;
  description: string;
  displayName: string;
  displayOrder: number;
  dataType: any;
  dataFormat: any;
  vendorId: number;
  fieldValue: MetricFieldValue;
};

export type MetricFieldValue = {
  cikNumber: number;
  fieldId: number;
  fieldValue?: any;
  createdDate: string;
  dataFetchID: number;
};

export type MetricFieldGroup = {
  fieldGroupId: number;
  fieldGroupName: string;
  displayName: string;
  displayOrder: number;
  fields: MetricField[];
  selectAllGroup?: boolean;
};

export type AccordionMetric = {
  metricCategoryId: number;
  metricCategoryName: string;
  displayName: string;
  displayOrder: number;
  fields?: AccordionMetricField[];
  fieldGroups?: AccordionMetricFieldGroup[];
  selectAll: boolean;
  selectedFields: number;
};
export type AccordionMetricField = {
  fieldId: number;
  fieldName: string;
  vendorFieldName: string;
  description: string;
  displayName: string;
  displayOrder: number;
  dataType: any;
  dataFormat: any;
  vendorId: number;
  fieldValue: MetricFieldValue;
  checked: boolean;
};
export type AccordionMetricFieldGroup = {
  fieldGroupId: number;
  fieldGroupName: string;
  displayName: string;
  displayOrder: number;
  fields?: AccordionMetricField[];
  selectAllGroup: boolean;
  collapse: boolean;
  selectedFields: number;
};

export type PeerBenchmarkToSave = {
  benchmarkTitle: string;
  benchmarkType: number;
  benchmarkCompanies: BenchmarkCompanies[];
  metrics: SelectedField[];
  globalCompanyMappings?: Partial<GlobalCompanyGroup>[];
};

export type companiesDisplayOrderObject = {
  globalCompanyId: number;
  displayOrder: number;
};

export type fieldDisplayOrderObject = {
  fieldId: number;
  displayOrder: number;
};

export type companiesColumnWidthObject = {
  globalCompanyId: number;
  width?: number;
  height?: number;
};

export type fieldColumnWidthObject = {
  fieldId: number;
  width?: number;
  height?: number;
};

export type userPreferenceObject = {
  isExpanded: boolean;
  isTranspose: boolean;
  companiesDisplayOrder: companiesDisplayOrderObject[];
  fieldDisplayOrder: fieldDisplayOrderObject[];
  expandedMetricCategories: number[];
  companiesColumnWidths: companiesColumnWidthObject[];
  fieldColumnWidths: fieldColumnWidthObject[];
  columnHeaderWidth: number;
};

export type UserPreference = {
  userPreferenceId: number;
  userId: number;
  referenceId: number;
  userPreferenceValue: userPreferenceObject;
  userPreferenceType: number;
  createdDate: string;
  lastUpdatedDate: string;
};

export type BenchmarkCompanies = {
  cikNumber: number;
  isBaseCompany: boolean;
  displayOrder: number;
};

export type SelectedField = {
  fieldName: string | null;
  fieldGroupName: string | null;
  displayOrder: number;
};

export type PeerBenchMarkPageInfo = {
  totalRecordCount: number;
  pageNumber: number;
  pageSize: number;
  totalPageCount: number;
  sortBy: string;
  sortOrder: string;
  externalSortBy: string;
  externalSortOrder: string;
  filterBy: string;
  filterText: string;
};

export type SavedPeerBenchMarkList = {
  pageInfo: PeerBenchMarkPageInfo;
  benchmarks: SavedBenchmark[];
};

export type SavedBenchmark = {
  benchmarkId: number;
  benchmarkTitle: string;
  benchmarkType: number;
  displayOrder: number;
  description: string;
  benchmarkMetadata: any;
  createdBy: number;
  sharedBy: UserObj;
  sharedWith: UserObj[];
  sharedDate: string;
  sourceBenchmarkId: number;
  lastAccessedDate: string;
  lastUpdatedDate: string;
  remainingDays: number;
  benchmarkCompanies: any[];
  metrics: any[];
};
