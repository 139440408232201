import MultipleColumnChart from "components/visualizations/charts/multiple-column.chart";
import SpeedometerChart from "components/visualizations/charts/speedometer.chart";
import { useSelector } from "react-redux";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import { RootStore } from "services/store.service";
import { getInsightsDetailPeerViewColorCode, mod } from "utils/functions";
import {
  mapSpeedometerChartData,
  mapTableChart,
  useTileContext,
} from "../tile.context";
import TableChart from "components/visualizations/charts/table-chart";
import DetailPeerViewLegend from "./DetailPeerViewLegend";
import TileEmptyContent from "../tile-empty-content";

const PillarScore = () => {
  const {
    index,
    metadata,
    dataset,
    originalDataset,
    associatedMetrics,
    associatedMetricsDisplayNames,
    metadata: { benchmarkType, isDetailedView },
    isTableViewActive,
  } = useTileContext();
  const commonsState = useSelector((state: RootStore) => state.commons);
  const BLOCK = "pillarScore";
  const getLegendColor = (index: number) => {
    const colorCodes = metadata.benchmarkMetadata.colorCodes
      ? metadata.benchmarkMetadata.colorCodes
      : commonsState.dashboardColorCodes;

    return colorCodes[mod(index, colorCodes.length)];
  };

  const getLegendTooltip = (
    ds: InsightData,
    currentMetric: InsightMetricData
  ) => {
    return `${ds.companyName + " ("} ${
      currentMetric.metricValue ? currentMetric.metricValue + " )" : "*)"
    } `;
  };

  const mapMultipleChartData = () => {
    return metadata.benchmarkMetadata.axis.secondary
      ? metadata.benchmarkMetadata.axis.secondary.map(
          (axis: any, i: number) => ({
            groupName: axis,
            groupPrefix: metadata.benchmarkMetadata.valueLimitProperties
              ? metadata.benchmarkMetadata.valueLimitProperties[i]
                  .fieldValueSuffix ?? ""
              : "",
            description: metadata.associatedMetricsTooltips
              ? metadata.associatedMetricsTooltips.filter(
                  (metrics: any) => metrics.associatedMetricDisplayName === axis
                )
              : [],
            separateBars: true,
            legends: dataset.reduce((prev: any[], ds: InsightData) => {
              let currentMetric = ds.metrics.filter(
                (m: InsightMetricData) =>
                  m.metricKey.toLowerCase() ===
                  metadata.benchmarkMetadata.associatedMetrics[i].toLowerCase()
              )[0];

              return [
                ...prev,
                {
                  legendData: currentMetric.metricValue,
                  legendOrder: prev.length,
                  legendTooltip: getLegendTooltip(ds, currentMetric),
                  legendValue: ds.companyName,
                  legendColor: getInsightsDetailPeerViewColorCode(
                    commonsState.InsightsDetailedPeerViewColorCodes,
                    ds.displayOrder!
                  ),
                },
              ];
            }, []),
          })
        )
      : [];
  };

  const tileProps = {
    name:
      metadata.benchmarkMetadata.benchmarkValue?.charAt(0).toUpperCase() +
      metadata.benchmarkMetadata.benchmarkValue?.slice(1),
    data: isDetailedView
      ? mapMultipleChartData()
      : mapSpeedometerChartData(
          metadata.benchmarkMetadata,
          originalDataset,
          dataset
        ),
    index: index,
    axis: metadata.benchmarkMetadata.axis,
  };
  const getMultipleChart = () => {
    return (
      <div>
        <DetailPeerViewLegend
          dataset={dataset}
          isDetailedView={isDetailedView}
          colorCodes={
            isDetailedView
              ? commonsState.InsightsDetailedPeerViewColorCodes
              : []
          }
        />
        <MultipleColumnChart {...tileProps} />
      </div>
    );
  };

  if (
    dataset.every((d: InsightData) =>
      d.metrics.every((m: InsightMetricData) => !m.metricValue)
    )
  ) {
    return <TileEmptyContent />;
  }

  return !isTableViewActive && !isDetailedView ? (
    <>
      {metadata.benchmarkMetadata.benchmarkSubTitle ? (
        <span className={`${BLOCK}__header__sub-text`}>
          {metadata.benchmarkMetadata.benchmarkSubTitle}
        </span>
      ) : null}
      <SpeedometerChart {...tileProps} />
    </>
  ) : isTableViewActive ? (
    <div className={`${BLOCK}__wrapper`}>
      {metadata.benchmarkMetadata.benchmarkSubTitle ? (
        <span className={`${BLOCK}__header__sub-text`}>
          {metadata.benchmarkMetadata.benchmarkSubTitle}
        </span>
      ) : null}
      <TableChart
        data={mapTableChart(dataset, metadata, false, false, true)}
        currentInsightView={benchmarkType}
      />
    </div>
  ) : (
    getMultipleChart()
  );
};

export default PillarScore;
