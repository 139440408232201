import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import DocumentLink from "../shared/document-link/document-link";

const Footer = () => {
  const commons = useSelector((state: RootStore) => state.commons);
  const [valuesForFooter, setValuesForFooter] = useState({});
  const [FormattedMessageID, setFormattedMessageID] = useState("");
  const BLOCK = "footer";
  const privacy = (
    <DocumentLink
      key="footer-privacy"
      className={`${BLOCK}__link`}
      disable={false}
      documentUrl={`${process.env.PUBLIC_URL}/assets/privacy.pdf`}
      target="_blank"
      linkBody={<FormattedMessage id="footer.privacy" />}
    />
  );
  const notices = (
    <DocumentLink
      key="footer-notices"
      className={`${BLOCK}__link`}
      disable={false}
      documentUrl={`${process.env.PUBLIC_URL}/assets/notices.pdf`}
      target="_blank"
      linkBody={<FormattedMessage id="footer.notices" />}
    />
  );
  const deloitteUrl = (
    <DocumentLink
      key="footer-deloiteURL"
      className={`${BLOCK}__link`}
      disable={false}
      documentUrl="https://www.deloitte.com/about"
      target="_blank"
      linkBody={<FormattedMessage id="footer.about" />}
    />
  );

  const openCookiePrivacyCenter = () => {
    document.getElementById("ot-sdk-btn")?.click();
  };

  const cookies = (
    <u className={`${BLOCK}__link`}>
      <a
        onClick={openCookiePrivacyCenter}
        rel="noopener noreferrer"
        target="_blank"
      >
        <FormattedMessage id="footer.cookies" />
      </a>
    </u>
  );

  const values = {
    date: new Date().getFullYear(),
    deloitteUrl,
    privacy,
    cookies,
    notices,
  };

  useEffect(() => {
    if (
      commons.termsOfUse &&
      commons.termsOfUse?.applicationSettingValue &&
      commons.termsOfUse.applicationSettingValue !== ""
    ) {
      const termsOfUse = (
        <DocumentLink
          key="footer-termsOfUse"
          className={`${BLOCK}__link`}
          disable={false}
          documentUrl={commons.termsOfUse.applicationSettingValue}
          target="_blank"
          linkBody={<FormattedMessage id="footer.terms-of-use" />}
        />
      );
      const valuesWithTermOfUser = {
        date: new Date().getFullYear(),
        termsOfUse,
        deloitteUrl,
        privacy,
        cookies,
        notices,
      };
      setValuesForFooter(valuesWithTermOfUser);
      setFormattedMessageID("footer.message.first");
    } else {
      setValuesForFooter(values);
      setFormattedMessageID("footer.message.without.terms");
    }
  }, [commons.termsOfUse]);

  return (
    <div className={`${BLOCK}`}>
      <p>
        <FormattedMessage id={FormattedMessageID} values={valuesForFooter} />
        <br />
        <FormattedMessage id="footer.message.last" values={{ deloitteUrl }} />
      </p>
    </div>
  );
};

export default Footer;
