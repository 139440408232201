import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Modal, { ModalWidth } from "components/shared/modal/modal";
import { useEffect, useState } from "react";
import {
  ACCESS_MODULES_URL_MAPPING,
  homepage,
  unauthorizedPath,
} from "utils/constants";
import socket, { rooms } from "utils/socket";
import history from "utils/history";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { getKeyByValue } from "utils/functions";
import { isEmpty } from "lodash";

const AccessDeniedModal = () => {
  const BLOCK = "access-denied";

  const commonState = useSelector((state: RootStore) => state.commons);
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [timeDelayInSeconds, setTimeDelayInSeconds] = useState(0);

  const handleModuleAccessRevoke = (payload: any) => {
    if (
      commonState.currentUser.memberFirmCode === payload.data.memberFirmCode
    ) {
      if (
        payload.data.applicationFeatureIds.some((x: any) =>
          location.pathname.includes(
            getKeyByValue(ACCESS_MODULES_URL_MAPPING, x) as string
          )
        )
      ) {
        setShowModal(true);
        setTimeDelayInSeconds(payload.data.timeDelayInSeconds);
      }
    }
  };

  useEffect(() => {
    socket.join(rooms.moduleAccess());
    return () => {
      socket.leave(rooms.moduleAccess());
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(commonState.currentUser) && location.pathname !== homepage) {
      socket.on("module.access.revoke", handleModuleAccessRevoke);

      return () => {
        socket.off("module.access.revoke", handleModuleAccessRevoke);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonState.currentUser, location]);

  useEffect(() => {
    if (showModal && !!timeDelayInSeconds) {
      let accessDeniedModalTimeOut = setTimeout(() => {
        setShowModal(false);
        history.push(unauthorizedPath);
      }, timeDelayInSeconds * 1000);

      return () => clearTimeout(accessDeniedModalTimeOut);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, timeDelayInSeconds]);

  return (
    <Modal
      show={showModal}
      header={"access-denied.title"}
      config={false}
      data-testid="access-denied"
      modalWidth={ModalWidth.small}
    >
      <div className={`${BLOCK}`} data-testid="access-denied">
        <div className={`${BLOCK}__content-wrapper`}>
          <FormattedMessage id="access-denied.message.1" />
          <br />
          <FormattedMessage id="access-denied.message.2" />
        </div>
      </div>
    </Modal>
  );
};
export default AccessDeniedModal;
