import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToastMessage, showTooltip } from "services/commons.service";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import moment from "moment";
import {
  GlobalCompanyGroup,
  GlobalCompanyMapping,
  InsightCategory,
  InsightDashboardCompany,
  InsightsDashboard,
  InsightsSharePayload,
} from "services/insights/insights.model";
import {
  deleteSavedInsight,
  deleteSavedInsightPopUP,
  insightsShareTrack,
  loadSavedInsightsList,
  refreshSavedInsightsList,
  resetInsightTab,
  shareInsightsToUsers,
} from "services/insights/insights.service";
import DeleteModal from "components/shared/delete-modal/delete-modal";
import {
  DASHBOARD_CATEGORIES,
  insightsPath,
  DEFAULT_SAVED_INSIGHTS_LIST_PAYLOAD,
  shareItemType,
} from "utils/constants";
import { getMarketCap } from "utils/functions";
import history from "utils/history";
import { RootStore } from "services/store.service";
import ShareToUsersModal from "components/shared/share-to-users-modal/share-to-users-modal";
import classNames from "classnames";
import Parser from "html-react-parser";

type props = {
  insight: InsightsDashboard;
};
const SavedInsightTile = ({ insight }: props) => {
  const BLOCK = "savedInsightTile";
  const dispatch = useDispatch();
  const [showDeleteInsightsModal, setShowDeleteInsightsModal] =
    useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [userInitials, setUserInitials] = useState<string>("");
  const savedInsightsListState = useSelector(
    (state: RootStore) => state.insights
  );
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);

  const baseCompany: InsightDashboardCompany | undefined =
    insight.globalCompanyMappings && insight.globalCompanyMappings.length
      ? insight.insightsCompanies?.find((company: InsightDashboardCompany) => {
          let baseMapping = insight.globalCompanyMappings.find(
            (g: GlobalCompanyGroup) => g.isBaseMapping
          );

          return baseMapping &&
            baseMapping.globalCompanyMappingDetails.find(
              (m: GlobalCompanyMapping) => m.isDefault
            )
            ? baseMapping.globalCompanyMappingDetails.find(
                (m: GlobalCompanyMapping) => m.isDefault
              )?.globalCompanyId === company.globalCompanyId &&
                company.isBaseCompany
            : company.isBaseCompany;
        })
      : insight.insightsCompanies?.find(
          (company: InsightDashboardCompany) => company.isBaseCompany
        );

  useEffect(() => {
    if (
      savedInsightsListState.refreshSavedInsightsList &&
      !savedInsightsListState.deleteSavedInsightPopUP
    ) {
      dispatch(loadSavedInsightsList(DEFAULT_SAVED_INSIGHTS_LIST_PAYLOAD));
      dispatch(refreshSavedInsightsList(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedInsightsListState.refreshSavedInsightsList, dispatch]);

  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("span");
    if (!element) {
      return;
    }

    dispatch(
      showTooltip({
        children: Parser(children),
        position: TooltipPosition.progressBarTop,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  useEffect(() => {
    if (
      insight.sharedBy &&
      insight.sharedBy.firstName &&
      insight.sharedBy.lastName
    ) {
      let userIcon =
        insight.sharedBy?.firstName?.charAt(0) +
        insight.sharedBy?.lastName?.charAt(0);
      setUserInitials(userIcon);
    }
  }, [dispatch, insight]);

  const shareInsightHandler = (payload: InsightsSharePayload["sharedWith"]) => {
    dispatch(
      shareInsightsToUsers({
        insightsName: insight.insightsName,
        insightsId: insight.insightsId,
        sharedWith: payload,
        sourceinsightsId: insight.insightsId,
      })
    );
    setShowShareModal(false);
  };

  const actions = [
    {
      name: "share",
      onClick: (e: any) => {
        e.stopPropagation();
        setShowShareModal(true);
      },
    },
    {
      name: "remove1",
      onClick: (e: any) => {
        e.stopPropagation();
        dispatch(deleteSavedInsightPopUP(true));
        setShowDeleteInsightsModal(true);
      },
    },
  ];

  const deleteInsightHandle = (insightId: number) => {
    dispatch(
      deleteSavedInsight(
        insightId,
        insight.insightsCategories[0].insightsCategoryName
      )
    );
    dispatch(
      addToastMessage({
        description: <FormattedMessage id="insight.delete.success" />,
      })
    );
    setShowDeleteInsightsModal(false);
  };
  const getCategory = (insightCategories: InsightCategory[]) => {
    const tmpCategory = insightCategories.find(
      (insightCategory: InsightCategory) => insightCategory.displayOrder === 0
    );

    return tmpCategory && tmpCategory.insightsCategoryName
      ? tmpCategory.insightsCategoryName
      : "";
  };

  const getInsightsName = (insightsName: any) => {
    return (
      <>
        {insightsName.length > 20
          ? `${insightsName.slice(0, 20)}...`
          : insightsName}
        {insightsName.length > 20 && (
          <Tooltip position={TooltipPosition.bottom}>{insightsName}</Tooltip>
        )}
      </>
    );
  };

  const getCategoryIcon = (insightsCategories: InsightCategory[]) => {
    let category: any;
    if (insightsCategories.length > 0) {
      category = DASHBOARD_CATEGORIES.find(
        (item: any) =>
          insightsCategories[0].insightsCategoryId === item.insightsCategoryId
      );
    } else {
      category = null;
    }
    return category ? category.icon : "graph-circle";
  };

  const getCategoryColor = (insightsCategories: InsightCategory[]) => {
    let category: any;

    if (insightsCategories.length > 0) {
      category = DASHBOARD_CATEGORIES.find(
        (item: any) =>
          insightsCategories[0].insightsCategoryId === item.insightsCategoryId
      );
    } else {
      category = null;
    }
    return category ? category.color : "#E3F2E5";
  };

  return (
    <div
      className={`${BLOCK}`}
      style={{
        border:
          insight.lastAccessedDate === null && insight.sharedBy
            ? `2px solid #43B02A`
            : "",
      }}
      data-testid="saved-insight-tile"
    >
      <div
        onClick={() => {
          history.push(`${insightsPath}/${insight.insightsId}`, {
            insightDetail: {
              sharedBy: insight.sharedBy ? true : false,
            },
          });
        }}
        data-testid="insight-tile-clickable"
      >
        <div
          className={`${BLOCK}__header`}
          style={{
            background: `linear-gradient(180.75deg, ${getCategoryColor(
              insight.insightsCategories
            )} 0.64%, rgba(238, 245, 248, 0) 135.65%)`,
          }}
        >
          <div className={`${BLOCK}__header-left`}>
            <span className={`${BLOCK}__name`}>
              {getInsightsName(insight.insightsName)}
            </span>
            <span className={`${BLOCK}__company`}>
              {baseCompany && baseCompany?.globalCompany?.companyName
                ? baseCompany?.globalCompany?.companyName
                : ""}
            </span>
          </div>
          <div className={`${BLOCK}__header-right`}>
            <div className={`${BLOCK}__icons`}>
              {actions.map((actionIcon: any, index: number) => {
                return (
                  <div
                    className={`${BLOCK}__icon`}
                    onClick={actionIcon.onClick}
                    key={`${BLOCK}__${index}`}
                    data-testid={`action-icon-${actionIcon.name}`}
                  >
                    <Icon
                      name={`${actionIcon.name}`}
                      height={30}
                      width={30}
                    ></Icon>
                  </div>
                );
              })}
            </div>
            <Icon
              name={getCategoryIcon(insight.insightsCategories)}
              width={103}
              height={72}
            />
          </div>
        </div>
        <div className={`${BLOCK}__body`}>
          <div className={`${BLOCK}__body-left`}>
            <span className={`${BLOCK}__text`}>
              <FormattedMessage id="insights.tile.ticker" />
              <span className={`${BLOCK}__text ${BLOCK}__text--bold`}>
                {baseCompany && baseCompany?.globalCompany?.ticker
                  ? baseCompany?.globalCompany?.ticker
                  : ""}
              </span>
            </span>
            <span className={`${BLOCK}__text`}>
              <FormattedMessage id="insights.tile.industry" />
              {baseCompany && baseCompany?.globalCompany?.industry?.industryName
                ? baseCompany?.globalCompany?.industry?.industryName
                : ""}
            </span>
            <span className={`${BLOCK}__text`}>
              <FormattedMessage id="insights.tile.market.cap" />
              <span className={`${BLOCK}__marketCap`}>
                {baseCompany &&
                  baseCompany?.globalCompany?.mrfyMarketCap &&
                  `${getMarketCap(baseCompany?.globalCompany?.mrfyMarketCap)}`}
              </span>
            </span>
          </div>
          <div className={`${BLOCK}__body-right`}>
            <span className={`${BLOCK}__category`}>
              {getCategory(insight.insightsCategories)}
            </span>
            {insight.remainingDays && (
              <div className={`${BLOCK}__categoryDiv`}>
                <Icon name="bulb" width={24} />
                <span
                  className={classNames(`${BLOCK}__remainingDays`, {
                    [`${BLOCK}__remainingDays--red`]:
                      insight.remainingDays > 0 && insight.remainingDays < 8,
                  })}
                >
                  {"  "} {insight.remainingDays}{" "}
                  <FormattedMessage id="insights.remaining.days" />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`${BLOCK}__footer`}>
        <div className={`${BLOCK}__footer-text`}>
          <Icon name="clock" width={24} />
          <span className={`${BLOCK}__text`}>
            {moment(insight.lastUpdatedDate).format("MM/DD/YYYY")}
          </span>
        </div>
        {userInitials && (
          <div className={`${BLOCK}__sharedBy`}>
            <span className={`${BLOCK}__text`}>
              <FormattedMessage id="my-searches-shared-by" />
            </span>
            {"    "}
            <span
              className={`${BLOCK}__user-avtar`}
              onMouseEnter={(e) => {
                handleMouseEnter(
                  e,
                  insight.sharedBy?.firstName + " " + insight.sharedBy?.lastName
                );
              }}
              onMouseLeave={(e) => {
                handleMouseLeave(e);
              }}
              data-testid="user-avatar"
            >
              {userInitials}
            </span>
          </div>
        )}

        {showDeleteInsightsModal && (
          <DeleteModal
            title="insights.delete.title"
            message={
              <>
                <FormattedMessage id="insights.delete.subtitle" />
                <span
                  style={{ fontWeight: 600 }}
                >{` "${insight.insightsName}"`}</span>
                ?
              </>
            }
            handleCloseModal={() => {
              dispatch(resetInsightTab());
              dispatch(deleteSavedInsightPopUP(false));
              setShowDeleteInsightsModal(false);
            }}
            deleteItem={() => deleteInsightHandle(insight.insightsId)}
          />
        )}
        {showShareModal && (
          <ShareToUsersModal
            setShowShare={setShowShareModal}
            shareSearch={() => {}}
            setShowSearchModal={() => {}}
            shareShortlist={() => {}}
            shareInsights={shareInsightHandler}
            shareCompare={() => {}}
            shareType={shareItemType.insights}
          />
        )}
      </div>
    </div>
  );
};

export default SavedInsightTile;
