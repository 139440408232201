import { Dispatch } from "redux";
import {
  FILE_REPORT_STATUS_ERRORS,
  UPLOAD_STATUS,
  DEFAULT_ESG_REPORT_PAYLOAD,
  PROGRESS_BAR_DIVISOR,
} from "utils/constants";
import { mod } from "utils/functions";
import { createAction } from "utils/redux.utils";

import * as api from "./manage-files.api";
import { ManageFilesModel, ManageFiles } from "./manage-files.model";

export const ACTIONS = {
  MANAGE_FILES_REPORTS: "ESG/UI/MANAGE_FILES_REPORTS",
  MANAGE_FILES_LOADING: "ESG/UI/MANAGE_FILES_LOADING",
  MAP_FILES_LOADING: "ESG/UI/MAP_FILES_LOADING",
  MANAGE_FILES_PENDING_ITEMS: "ESG/UI/MANAGE_FILES_PENDING_ITEMS",
  MANAGE_FILES_REPORTS_NEXT_PAGE: "ESG/UI/MANAGE_FILES_REPORTS_NEXT_PAGE",
  MANAGE_FILES_REPORTS_NEXT_PAGE_LOADING:
    "ESG/UI/MANAGE_FILES_REPORTS_NEXT_PAGE_LOADING",
  SET_FILES_TO_UPLOAD: "ESG/UI/SET_FILES_TO_UPLOAD",
  UPDATE_UPLOADS_PROGRESS: "ESG/UI/UPDATE_UPLOADS_PROGRESS",
  UPDATE_UPLOADS_ERRORS: "ESG/UI/UPDATE_UPLOADS_ERRORS",
  MANAGE_FILES_DELETE: "ESG/UI/MANAGE_FILES_DELETE",
  FILES_DELETE_SUCCESS: "ESG/UI/FILES_DELETE_SUCCESS",
  FILES_DELETE_ERROR: "ESG/UI/FILES_DELETE_ERROR",
  FILE_TO_EDIT: "ESG/UI/FILE_TO_EDIT",
  EDITED_FILE_RESULT: "ESG/UI/SAVED_FILE_RESULT",
  EDIT_FILE_ERROR: "ESG/UI/EDIT_FILE_ERROR",
  EDIT_FILE_SUCCESS: "ESG/UI/EDIT_FILE_SUCCESS",
  EDIT_FILE_POP_UP: "ESG/UI/EDIT_FILE_POP_UP",
  REFRESH_FILES_LIST: "ESG/UI/REFRESH_FILES_LIST",
  RESET_FILE_TO_EDIT: "ESG/UI/RESET_FILE_TO_EDIT",
  RESET_EDITED_FILE_RESULT: "ESG/UI/RESET_EDITED_FILE_RESULT",
  UPDATE_REPORT_STATUS: "ESG/UI/UPDATE_REPORT_STATUS",
  MAP_FILES_DELETE_ROW: "ESG/UI/MAP_FILES_DELETE_ROW",
  UPDATE_PENDING_ITEM: "ESG/UI/UPDATE_PENDING_ITEM",
  PROCESS_FILES_COMPLETE: "ESG/UI/PROCESS_FILES_COMPLETE",
  IS_FILTERING_REPORTS: "ESG/UI/IS_FILTERING_REPORTS",
  MAINTENANCE_MESSAGES_LOADING: "ESG/UI/MAINTENANCE_MESSAGES_LOADING",
  MAINTENANCE_MESSAGES_ITEMS: "ESG/UI/MAINTENANCE_MESSAGES_ITEMS",
  FETCH_REGIONS: "ESG/UI/FETCH_REGIONS",
  FETCH_TIMEZONES: "ESG/UI/FETCH_TIMEZONES",
  POST_MESSAGE_SUCCESS: "ESG/UI/POST_MESSAGE_SUCCESS",
  POST_MESSAGE_ERROR: "ESG/UI/POST_MESSAGE_ERROR",
  SET_ACTIVE_MAINTENANCE_MESSAGES: "ESG/UI/SET_ACTIVE_MAINTENANCE_MESSAGES",
  SET_CACHED_ACTIVE_MAINTENANCE_MESSAGES:
    "ESG/UI/SET_CACHED_ACTIVE_MAINTENANCE_MESSAGES",
  DELETE_MAINTENANCE_MESSAGE_SUCCESS:
    "ESG/UI/DELETE_MAINTENANCE_MESSAGE_SUCCESS",
};

export const isFilteringReports = createAction(ACTIONS.IS_FILTERING_REPORTS);
export const updateReportStatus = createAction(ACTIONS.UPDATE_REPORT_STATUS);

export const manageFilesLoadReports = createAction(
  ACTIONS.MANAGE_FILES_REPORTS
);

export const fetchRegions = createAction(ACTIONS.FETCH_REGIONS);

export const fetchTimezones = createAction(ACTIONS.FETCH_TIMEZONES);

export const setPostMaintenanceSuccess = createAction(
  ACTIONS.POST_MESSAGE_SUCCESS
);

export const settingActiveMaintenanceMessages = createAction(
  ACTIONS.SET_ACTIVE_MAINTENANCE_MESSAGES
);

export const settingCachedActiveMaintenanceMessages = createAction(
  ACTIONS.SET_CACHED_ACTIVE_MAINTENANCE_MESSAGES
);

export const setPostMaintenanceError = createAction(ACTIONS.POST_MESSAGE_ERROR);

export const setDeleteMaintenanceMessage = createAction(
  ACTIONS.DELETE_MAINTENANCE_MESSAGE_SUCCESS
);

export const manageFilesPendingItems = createAction(
  ACTIONS.MANAGE_FILES_PENDING_ITEMS
);

export const manageFilesLoading = createAction(ACTIONS.MANAGE_FILES_LOADING);

export const mapFilesLoading = createAction(ACTIONS.MAP_FILES_LOADING);

export const manageFilesLoadReportsNextPage = createAction(
  ACTIONS.MANAGE_FILES_REPORTS_NEXT_PAGE
);
export const manageFilesReportsNextPageLoading = createAction(
  ACTIONS.MANAGE_FILES_REPORTS_NEXT_PAGE_LOADING
);
export const setFilesToUpload = createAction(ACTIONS.SET_FILES_TO_UPLOAD);
export const updateUploadsProgress = createAction(
  ACTIONS.UPDATE_UPLOADS_PROGRESS
);
export const updateUploadsErrors = createAction(ACTIONS.UPDATE_UPLOADS_ERRORS);
export const manageFilesDelete = createAction(ACTIONS.MANAGE_FILES_DELETE);
export const fileDeleteSuccess = createAction(ACTIONS.FILES_DELETE_SUCCESS);
export const fileDeleteError = createAction(ACTIONS.FILES_DELETE_ERROR);
export const fileToEdit = createAction(ACTIONS.FILE_TO_EDIT);
export const editedFileResult = createAction(ACTIONS.EDITED_FILE_RESULT);
export const editFileError = createAction(ACTIONS.EDIT_FILE_ERROR);
export const editFileSuccess = createAction(ACTIONS.EDIT_FILE_SUCCESS);
export const editFilePopUp = createAction(ACTIONS.EDIT_FILE_POP_UP);
export const refreshFilesList = createAction(ACTIONS.REFRESH_FILES_LIST);
export const resetFileToEdit = createAction(ACTIONS.RESET_FILE_TO_EDIT);
export const resetEditedFileResult = createAction(
  ACTIONS.RESET_EDITED_FILE_RESULT
);
export const mapFilesDeleteRow = createAction(ACTIONS.MAP_FILES_DELETE_ROW);
export const updatePendingItemRow = createAction(ACTIONS.UPDATE_PENDING_ITEM);
export const processFilesCompleteStatus = createAction(
  ACTIONS.PROCESS_FILES_COMPLETE
);

export const loadManageFilesReports =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(manageFilesLoading(true));

      const reports = await api.fetchESGReports(payload);
      dispatch(manageFilesLoadReports(reports.data));
      dispatch(manageFilesLoading(false));
    } catch (e) {
      dispatch(manageFilesLoading(false));
    }
  };

export const loadRegions = () => async (dispatch: Dispatch<any>) => {
  try {
    const regions = await api.getRegions();
    dispatch(fetchRegions(regions.data));
    dispatch(manageFilesLoading(false));
  } catch (e) {
    dispatch(manageFilesLoading(false));
  }
};

export const loadTimezones = () => async (dispatch: Dispatch<any>) => {
  try {
    const timezones = await api.getTimezones();
    dispatch(fetchTimezones(timezones.data));
  } catch (e) {}
};

export const sortManageFilesReports =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      const reports = await api.fetchESGReports(payload);
      dispatch(manageFilesLoadReports(reports.data));
    } catch (e) {}
  };

export const fetchPendingItems = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(mapFilesLoading(true));
    const res = await api.getPendingItems();
    dispatch(manageFilesPendingItems(res.data));
    dispatch(mapFilesLoading(false));
  } catch (e) {
    dispatch(mapFilesLoading(false));
  }
};

export const loadManageFilesReportsNextPage =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(manageFilesReportsNextPageLoading(true));
      const reports = await api.fetchESGReports(payload);
      dispatch(manageFilesLoadReportsNextPage(reports.data));
      dispatch(manageFilesReportsNextPageLoading(false));
    } catch (e) {
      dispatch(manageFilesReportsNextPageLoading(false));
    }
  };

export const updateReportState =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    dispatch(updateReportStatus(payload));
  };

export const deleteManageFilesReports =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(manageFilesLoading(true));
      await api.deleteESGReports(payload);
      dispatch(fileDeleteSuccess(true));
      dispatch(loadManageFilesReports(DEFAULT_ESG_REPORT_PAYLOAD));
      dispatch(manageFilesLoading(false));
    } catch (e) {
      dispatch(fileDeleteError(true));
      dispatch(manageFilesLoading(false));
    }
  };

export const editManageFilesReport =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      let updPayload = {
        reportId: payload.reportId,
        reportTitle: payload.reportTitle,
        reportFileName: payload.reportFileName,
        reportFormType: {
          formTypeId: payload.reportFormType.formTypeId,
        },
        reportCompany: {
          cikNumber: payload.reportCompany.cikNumber,
        },
        governingBoards: payload.governingBoards.map((g: any) => {
          return { governingBoardId: g.governingBoardId };
        }),
        reportYears: payload.reportYears,
        reportstatus: payload.reportstatus,
      };
      const response = await api.editESGReport(updPayload);
      dispatch(editedFileResult(response.data));
      dispatch(resetFileToEdit());
      dispatch(editFileSuccess(true));
    } catch (e: any) {
      if (e.response) {
        if (
          e.response.status >= 400 &&
          e.response.status < 500 &&
          e.response.data.messages.length > 0
        ) {
          let isInvalidCikNumber = e.response.data.messages.find(
            (m: any) => m.code === "Invalid.CIK"
          );
          let isDuplicateName = e.response.data.messages.find(
            (m: any) => m.code === "Duplicate.Name"
          );

          if (isInvalidCikNumber) {
            let tmpPayload = { ...payload, invalidCikNumber: true };
            dispatch(fileToEdit(tmpPayload));
          } else if (isDuplicateName) {
            let tmpPayload = { ...payload, duplicatedReport: true };
            dispatch(fileToEdit(tmpPayload));
          }
        } else {
          dispatch(fileToEdit(payload));
        }
      }

      dispatch(editFileError(true));
    }
  };

const options = (fileIndex: number, dispatch: Dispatch<any>) => {
  return {
    fileIndex: fileIndex,
    onUploadProgress: (event: any) => {
      const { loaded, total } = event;
      let fileProgress = Math.round((100 * loaded) / total);
      let progressStatus = UPLOAD_STATUS.UPLOADING;
      if (fileProgress === 100) progressStatus = UPLOAD_STATUS.UPLOADED;
      let payload = {
        progress: fileProgress,
        progressStatus: progressStatus,
        fileIndex: fileIndex,
      };
      if (mod(fileProgress, PROGRESS_BAR_DIVISOR) === 0)
        dispatch(updateUploadsProgress(payload));
    },
  };
};

export const uploadFileReport =
  (payload: any, fileIndex: number) => async (dispatch: Dispatch<any>) => {
    try {
      await api.uploadFile(payload, options(fileIndex, dispatch));
    } catch (e: any) {
      let progressStatus = UPLOAD_STATUS.FAILED;
      let payload = {
        progress: 100,
        progressStatus: progressStatus,
        fileIndex: fileIndex,
      };
      dispatch(updateUploadsProgress(payload));
      if (e.response.status >= 400 && e.response.status < 500) {
        let errorText = FILE_REPORT_STATUS_ERRORS.find((e) => {
          if (e.reportStatus === 20) return e;
          return true;
        });
        //dispatch(updateUploadsErrors({fileIndex: fileIndex, messages: e.response.data.messages || [errorText]}))
        dispatch(
          updateUploadsErrors({
            fileIndex: fileIndex,
            messages: [errorText] || [],
          })
        );
      }
    }
  };

export const processMapFiles =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(manageFilesLoading(true));
      dispatch(processFilesCompleteStatus(false));
      await api.processMapFiles(payload);

      // Changing to new tab on process done
      dispatch(processFilesCompleteStatus(true));
      // const res = await api.getPendingItems();
      // dispatch(manageFilesPendingItems(res.data));
      // dispatch(manageFilesLoading(false));
    } catch (e) {
      dispatch(manageFilesLoading(false));
    }
  };

export const cancelMapFilesProcess =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(manageFilesLoading(true));
      await api.cancelMapFiles(payload);
      const res = await api.getPendingItems();
      dispatch(manageFilesPendingItems(res.data));
      dispatch(manageFilesLoading(false));
    } catch (e) {
      dispatch(manageFilesLoading(false));
    }
  };

export const maintenancemessagesLoading = createAction(
  ACTIONS.MAINTENANCE_MESSAGES_LOADING
);
export const maintenancemessagesItems = createAction(
  ACTIONS.MAINTENANCE_MESSAGES_ITEMS
);

export const loadMaintenanceMessages =
  () => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(maintenancemessagesLoading(true));

      const res = await api.fetchMaintenanceMessages();
      dispatch(maintenancemessagesItems(res.data));
      dispatch(maintenancemessagesLoading(false));
    } catch (e) {
      dispatch(maintenancemessagesLoading(false));
    }
  };

export const saveMaintenanceMessage =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      await api.postMaintenanceMessage(payload);
      dispatch(setPostMaintenanceSuccess(true));
    } catch (e) {
      dispatch(setPostMaintenanceError(false));
    }
  };

export const setActiveMaintenanceMessages =
  () => async (dispatch: Dispatch<any>) => {
    try {
      const res = await api.getMaintenanceMessage();
      dispatch(settingActiveMaintenanceMessages(res.data));
    } catch (e) {
      dispatch(setPostMaintenanceError(true));
    }
  };

export const deleteMaintenanceMessage =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      await api.deleteMaintenanceMessage(payload);
      dispatch(setDeleteMaintenanceMessage(true));
    } catch (e) {
      dispatch(setPostMaintenanceError(false));
    }
  };

const manageFilesModel = new ManageFilesModel();

const manageFilesReducer = (
  state: ManageFiles = manageFilesModel.initialState(),
  action: any
): ManageFiles => {
  switch (action.type) {
    case ACTIONS.MANAGE_FILES_LOADING:
      return manageFilesModel.setLoading(action.payload);
    case ACTIONS.MAP_FILES_LOADING:
      return manageFilesModel.setMapFilesLoading(action.payload);
    case ACTIONS.MANAGE_FILES_REPORTS:
      return manageFilesModel.setESGReports(action.payload);
    case ACTIONS.MANAGE_FILES_PENDING_ITEMS:
      return manageFilesModel.setPendingItems(action.payload);
    case ACTIONS.MANAGE_FILES_REPORTS_NEXT_PAGE:
      return manageFilesModel.setESGReportsNextPage(action.payload);
    case ACTIONS.MANAGE_FILES_REPORTS_NEXT_PAGE_LOADING:
      return manageFilesModel.setNextPageLoading(action.payload);
    case ACTIONS.MANAGE_FILES_DELETE:
      return manageFilesModel.setESGReports(action.payload);
    case ACTIONS.FILES_DELETE_SUCCESS:
      return manageFilesModel.setDeleteSuccess(action.payload);
    case ACTIONS.FILES_DELETE_ERROR:
      return manageFilesModel.setDeleteError(action.payload);
    case ACTIONS.SET_FILES_TO_UPLOAD:
      return manageFilesModel.setUploads(action.payload);
    case ACTIONS.UPDATE_UPLOADS_PROGRESS:
      return manageFilesModel.updateUploadsProgress(action.payload);
    case ACTIONS.UPDATE_UPLOADS_ERRORS:
      return manageFilesModel.updateUploadsErrors(action.payload);
    case ACTIONS.FILE_TO_EDIT:
      return manageFilesModel.setFileToEdit(action.payload);
    case ACTIONS.EDITED_FILE_RESULT:
      return manageFilesModel.setEditedFileResult(action.payload);
    case ACTIONS.EDIT_FILE_ERROR:
      return manageFilesModel.setEditFileError(action.payload);
    case ACTIONS.EDIT_FILE_SUCCESS:
      return manageFilesModel.setEditFileSuccess(action.payload);
    case ACTIONS.EDIT_FILE_POP_UP:
      return manageFilesModel.seteditFilePopUp(action.payload);
    case ACTIONS.REFRESH_FILES_LIST:
      return manageFilesModel.setRefreshFilesList(action.payload);
    case ACTIONS.RESET_FILE_TO_EDIT:
      return manageFilesModel.resetFileToEdit();
    case ACTIONS.RESET_EDITED_FILE_RESULT:
      return manageFilesModel.resetEditedFileResult();
    case ACTIONS.UPDATE_REPORT_STATUS:
      return manageFilesModel.setReportStatus(action.payload);
    case ACTIONS.MAP_FILES_DELETE_ROW:
      return manageFilesModel.setDeletedRows(action.payload);
    case ACTIONS.UPDATE_PENDING_ITEM:
      return manageFilesModel.setUpdatedPendingItems(action.payload);
    case ACTIONS.PROCESS_FILES_COMPLETE:
      return manageFilesModel.setProcessFilesComplete(action.payload);
    case ACTIONS.IS_FILTERING_REPORTS:
      return manageFilesModel.setCurrentFilter(action.payload);
    case ACTIONS.MAINTENANCE_MESSAGES_ITEMS:
      return manageFilesModel.setMaintenanceMessagesResult(action.payload);
    case ACTIONS.MAINTENANCE_MESSAGES_LOADING:
      return manageFilesModel.setMaintenanceLoading(action.payload);
    case ACTIONS.FETCH_REGIONS:
      return manageFilesModel.setRegions(action.payload);
    case ACTIONS.FETCH_TIMEZONES:
      return manageFilesModel.setTimezones(action.payload);
    case ACTIONS.POST_MESSAGE_SUCCESS:
      return manageFilesModel.setPostMessageSuccess(action.payload);
    case ACTIONS.POST_MESSAGE_ERROR:
      return manageFilesModel.setPostMessageError(action.payload);
    case ACTIONS.SET_ACTIVE_MAINTENANCE_MESSAGES:
      return manageFilesModel.setActiveMaintenanceMessages(action.payload);
    case ACTIONS.SET_CACHED_ACTIVE_MAINTENANCE_MESSAGES:
      return manageFilesModel.setCachedActiveMaintenanceMessages(
        action.payload
      );
    case ACTIONS.DELETE_MAINTENANCE_MESSAGE_SUCCESS:
      return manageFilesModel.setDeleteMaintenanceMessageSuccess(
        action.payload
      );

    default:
      return state;
  }
};

export default manageFilesReducer;
