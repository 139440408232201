import ProgressBar from "./progress-bar";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import { Fragment } from "react";
import Popover from "components/shared/popover/popover";

type Props = {
  data: any;
};

const ScoreChart = ({ data }: Props) => {
  const caompanyName =
    data?.companyName !== "" ? data?.companyName?.companyName : "";

  const isDetailedView = data.isDetailView;

  const noDataAvailable = isDetailedView
    ? data?.data?.reduce((acc: any, curr: any) => {
        if (curr.data.filter((item: any) => item.fieldValue === "*").length > 0)
          return [
            ...acc,
            curr.data.filter((item: any) => item.fieldValue === "*"),
          ];
        return acc;
      }, [])
    : data?.data?.filter(
        (item: any) => item.fieldValue === "*" || item.avgScore === "*"
      );

  return (
    <div
      className={"DashboardTile__scoreContainer"}
      data-testid="score-container"
    >
      {isDetailedView ? (
        <>
          {data?.data?.length > 0 ? (
            data?.data?.map((elem: any, i: number) => {
              return (
                <div key={i}>
                  <div className={"DashboardTile__groupDeatail"}>
                    <span className={""}>
                      <Popover
                        displayText={`${elem.fieldName}`}
                        content={elem.description ?? []}
                        metricClassName={"DashboardTile__companyDetailed"}
                      />
                    </span>
                  </div>
                  {elem.data.map((item: any, index: number) => (
                    <Fragment key={`score-detailed-${index}`}>
                      <div className={"DashboardTile__scoreDetail"}>
                        <span className={"DashboardTile__scoreValueDetail"}>
                          {item.fieldValue !== "" ? item.fieldValue : ""}
                        </span>
                        <span
                          className={"DashboardTile__scoreName"}
                          data-testid="score-name"
                        >
                          {item.companyName}
                        </span>
                      </div>

                      <ProgressBar
                        mainWidth={item.baseWidth}
                        baseWidth={""}
                        mainTooltip={`${item.fieldValue} ${item.companyName}`}
                        baseTooltip={""}
                        colors={data.colors}
                        gradient={data.gradient}
                      />
                    </Fragment>
                  ))}
                </div>
              );
            })
          ) : (
            <div className={"DashboardTile__noData"}>
              {" "}
              <FormattedMessage id="insights.cdp.no-data" />
            </div>
          )}
          {noDataAvailable?.length > 0 ? (
            <div className={"DashboardTile__noDataAny"}>
              {" "}
              <FormattedMessage id="no.data.available" />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          {caompanyName !== "" &&
            data.benchmarkType !== "" &&
            data.benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY && (
              <div className={"DashboardTile__groupDeatail"}>
                <span
                  className={"DashboardTile__companyDetails"}
                >{`${caompanyName}  /`}</span>
                <span className={"DashboardTile__industryDetails"}>
                  {" "}
                  <FormattedMessage id="industry.average" />
                </span>
              </div>
            )}

          {caompanyName !== "" &&
            data.benchmarkType !== "" &&
            data.benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK && (
              <div className={"DashboardTile__groupDeatail"}>
                <span
                  className={"DashboardTile__companyDetails"}
                >{`${caompanyName}  /`}</span>
                <span className={"DashboardTile__industryDetails"}>
                  {" "}
                  <FormattedMessage id="majority.peer" />
                </span>
              </div>
            )}

          {data?.data?.length > 0 ? (
            data?.data?.map((item: any, i: number) => (
              <Fragment key={`score-detail-${i}`}>
                <div className={"DashboardTile__scoreDetail"}>
                  {data.benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY ||
                  data.benchmarkType ===
                    INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK ? (
                    <div className={"DashboardTile__scoreholder"}>
                      <span className={"DashboardTile__scoreholder--base"}>
                        {item.fieldValue}
                      </span>
                      <span className={"DashboardTile__scoreholder--others"}>
                        {"/" + item.avgScore}
                      </span>
                    </div>
                  ) : (
                    <span className={"DashboardTile__scoreValue"}>
                      {item.fieldValue !== "" ? item.fieldValue : ""}
                    </span>
                  )}
                  <span data-testid="score-name">
                    <Popover
                      metricClassName="DashboardTile__scoreName"
                      displayText={item.fieldName}
                      content={item.description}
                    />
                  </span>
                </div>
                <ProgressBar
                  mainWidth={item.feildWidth}
                  baseWidth={item.baseWidth !== "" ? item.baseWidth : ""}
                  mainTooltip={item.tooltip}
                  baseTooltip={item.baseTooltip}
                  colors={data.colors}
                  gradient={data.gradient}
                  tileType={data.tileType}
                />
              </Fragment>
            ))
          ) : (
            <div className={"DashboardTile__noData"}>
              {" "}
              <FormattedMessage id="insights.cdp.no-data" />
            </div>
          )}
          {noDataAvailable?.length > 0 ? (
            <div className={"DashboardTile__noDataAny"}>
              {" "}
              <FormattedMessage id="no.data.available" />
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default ScoreChart;
