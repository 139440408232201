import { useTileContext } from "./tile.context";
import classNames from "classnames";
import Popover from "components/shared/popover/popover";

const TileFooter = () => {
  const {
    BLOCK,
    isTableViewActive,
    metadata: {
      benchmarkMetadata: { source, sourceTooltip },
      benchmarkTileType,
    },
    footerChild,
  } = useTileContext();

  return (
    <div
      className={classNames(`${BLOCK}__footer`, {
        [`${BLOCK}__footer--target`]: benchmarkTileType === 37,
        [`${BLOCK}__footer--target--tableView`]:
          benchmarkTileType === 37 && isTableViewActive,
        [`${BLOCK}__footer--space-between`]: footerChild,
      })}
      data-testid="tile-footer"
    >
      {footerChild}
      {source && (
        <div className={`data-source`}>
          <Popover
            displayText={source.join("")}
            metricClassName={`data-source`}
            content={
              sourceTooltip &&
              sourceTooltip.length > 0 &&
              sourceTooltip[0] !== ""
                ? [
                    {
                      associatedMetricTooltipText:
                        sourceTooltip && sourceTooltip.join(" <br/><br/>"),
                      displayOrder: 1,
                      associatedMetric: "",
                      associatedMetricDisplayName: "",
                      tooltipType: 0,
                    },
                  ]
                : []
            }
            hideTabs={true}
          />
        </div>
      )}
    </div>
  );
};

export default TileFooter;
