import Button from "components/shared/button/button";
import { MouseEventHandler } from "react";
import FormattedMessage from "../shared/formatted-message/formatted-message";

type Props = {
  handleClose?: MouseEventHandler;
  handleSave?: MouseEventHandler;
  continueLabel?: string;
};

const ReportModal = ({ handleClose, handleSave, continueLabel }: Props) => {
  const BLOCK = "ReportModal";
  return (
    <div
      className={BLOCK}
      data-test="component-report-modal"
      data-testid="component-report-modal"
    >
      <div className={`${BLOCK}__continue-message`}>
        <FormattedMessage id="unsaved.report.continue.message" />
      </div>
      <div className={`${BLOCK}__options`}>
        <Button
          className={`button-secondary`}
          dataTest="continue-button"
          onClick={handleClose}
          formattedText={
            continueLabel ? continueLabel : "unsaved.report.continue"
          }
        />
        <Button
          className={`button-primary`}
          dataTest="save-button"
          onClick={handleSave}
          formattedText="unsaved.report.save"
        />
      </div>
    </div>
  );
};

export default ReportModal;
