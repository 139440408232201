import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import { useState, useEffect, Fragment, useRef } from "react";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import {
  BENCHMARK_METADATA_PATH,
  GHG_INTESITY_UNIT_TOOLTIP,
  INSIGHT_BENCHMARK_IDS,
  INSIGHT_BENCHMARK_TYPE,
} from "utils/constants";
import ProgressBar from "./progress-bar";
import Icon from "../../../components/shared/icon/icon";
import { max, min } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import { updateTileMetaDataForExtraChartMetrics } from "services/insights/insights.service";

export type GHGIntensityMetric = {
  unit: string;
  value: string;
  metricLabel: string;
  metricTooltip: AssociatedMetricTooltips[];
  companyName: string;
  unitLabel: string;
  unitTooltip?: AssociatedMetricTooltips[];
  minValue?: string | null;
  maxValue?: string | null;
};
export type GHGIntensityCompanyData = {
  globalCompanyId: number;
  companyName: string;
  unitTooltip?: AssociatedMetricTooltips[];
  metrics: GHGIntensityMetric[];
};
type props = {
  isDetailedView: boolean;
  currentInsightView: number;
  isTableViewActive: boolean;
  isESRS: boolean;
  data: GHGIntensityCompanyData[];
  defaultUnits: string[];
  expandAllUnits: boolean;
  detailedTableData: GHGIntensityCompanyData[];
  exporting: boolean;
  benchmarkTileType: number;
};

type PeerIndustryUpdatedValueObj = {
  unitLabel: string;
  BASELINE_COMPANY: string;
  values: {
    companyValue: string | null;
    peerOrIndustryAvg: string | null;
  };
  minValue: string | null;
  maxValue: string | null;
  indexInArray: number;
  // metricTooltip: AssociatedMetricTooltips[];
  unitTooltip?: AssociatedMetricTooltips[];
};

const GHGIntensityChart = ({
  isDetailedView,
  currentInsightView,
  isTableViewActive,
  isESRS,
  data,
  defaultUnits,
  expandAllUnits,
  detailedTableData,
  exporting,
  benchmarkTileType,
}: props) => {
  const BLOCK = "ghgIntensity";
  const BASELINE_COMPANY = data[0]?.companyName;
  const PEER_AVG_VALUE = "Peers";
  const INDUSTRY_AVG_VALUE = "Industry";
  const [hasEmptyValues, setHasEmptyValues] = useState<boolean>(false);
  const [hideBaseLegend, setHideBaseLegend] = useState<boolean>(false);
  const [hidePeerLegend, setHidePeerLegend] = useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [showBaseNoDataMsg, setShowBaseNoDataMsg] = useState<boolean>(false);
  const [showPeerOrIndustryNoDataMsg, setShowPeerOrIndustryNoDataMsg] =
    useState<boolean>(false);
  const [peerIndustryUpdatedData, setPeerIndustryUpdatedData] = useState<
    PeerIndustryUpdatedValueObj[]
  >([]);
  const [isExpandedView, setIsExpandedView] = useState<boolean>(expandAllUnits);
  const [remainingUnitsCount, setremainingUnitsCount] = useState<number>(0);
  const insightState = useSelector((state: RootStore) => state.insights);
  const dispatch = useDispatch();

  const showRangeChart =
    (!isTableViewActive &&
      currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
      !isDetailedView) ||
    (!isTableViewActive &&
      currentInsightView === INSIGHT_BENCHMARK_TYPE.INDUSTRY); //to determine whether or not to show range chart
  let peerAverageDataNotAvailable: boolean = false; //Peer Average - No data available
  let baseAverageDataNotAvailable: boolean = false; //baseline Average - No data available
  let singularPeerAverageWithNoRange: boolean = false; //Single Peer average value with no range
  let singlePeerCompanyValue: boolean = false; //When only one peer company has values and baseline also has no value

  const getFormattedNumber = (num: any, useGrouping: boolean = true) => {
    const numberFormatter1 = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 1,
      useGrouping,
    });
    const numberFormatter2 = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 3,
      useGrouping,
    });

    return Number.isNaN(num) || num === "*"
      ? num
      : +num < 1
      ? numberFormatter2.format(+num)
      : numberFormatter1.format(+num);
  };

  const handleExpandCollapse = () => {
    setIsExpandedView((prev) => !prev);
    dispatch(
      updateTileMetaDataForExtraChartMetrics(
        BENCHMARK_METADATA_PATH.ALL_UNITS_EXPANDED,
        {
          expandAllUnits: !isExpandedView,
        },
        insightState.currentSavedInsightDashboard.insightsId,
        benchmarkTileType
      )
    );
    if (buttonRef.current && isExpandedView && isTableViewActive) {
      buttonRef.current.scrollIntoView();
    }
  };

  const getMetricBlock = (
    companyName: string,
    metric: GHGIntensityMetric,
    ind: number
  ) => {
    if (!hasEmptyValues && (!metric.value || metric.value === "*")) {
      setHasEmptyValues(true);
    }
    return (
      <div
        key={`${ind}__metric`}
        className={classNames(`${BLOCK}__metric-block`, {
          [`${BLOCK}__metric-block-detailed-view`]: isDetailedView,
          [`${BLOCK}__metric-block-esrs`]: isESRS,
        })}
      >
        {isDetailedView ? (
          //COMPANY NAME
          <div
            className={classNames(
              `${BLOCK}__metric-unit ${BLOCK}__metric-unit--bold ${BLOCK}__metric-unit--static-height`
            )}
          >
            {companyName}
          </div>
        ) : (
          <div className={`${BLOCK}__metric-wrapper`}>
            <div className={``}>
              <Popover
                displayText={metric.metricLabel}
                content={metric.metricTooltip ?? []}
                metricClassName={`${BLOCK}__metric-label`}
              />
            </div>
            <div className={`${BLOCK}__metric-value`}>
              {getFormattedNumber(metric.value)}
            </div>
          </div>
        )}

        {isDetailedView ? (
          <div className={`${BLOCK}__metric-wrapper`}>
            <div className={``}>
              <Popover
                displayText={metric.metricLabel}
                content={metric.metricTooltip ?? []}
                metricClassName={`${BLOCK}__metric-label`}
              />
            </div>
            <div className={`${BLOCK}__metric-value`}>
              {getFormattedNumber(metric.value)}
            </div>
          </div>
        ) : (
          //UNIT
          <div className={`${BLOCK}__metric-unit--wrapper`}>
            <div className={`${BLOCK}__metric-unit`}>
              <Popover
                displayText={metric.unitLabel}
                content={metric.unitTooltip ?? []}
                metricClassName={``}
                buttonClassName={`${BLOCK}__metric-unit`}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const getDetailedBlock = (
    detailedData: GHGIntensityCompanyData,
    i: number
  ) => {
    return (
      <div key={`${i}__db`} className={`${BLOCK}__detailed-view-container`}>
        <span className={`${BLOCK}__detailed-view-subtitle`}>
          <Popover
            displayText={detailedData.companyName}
            content={detailedData.unitTooltip ?? []}
            metricClassName={`${BLOCK}__detailed-view-subtitle`}
          />
        </span>

        <div className={`${BLOCK}__detailed-view-wrapper`}>
          {detailedData.metrics.map((m: GHGIntensityMetric, ind: number) =>
            getMetricBlock(m.companyName, m, ind)
          )}
        </div>
      </div>
    );
  };

  const getCompanyMetrics = (comp: GHGIntensityCompanyData, i: number) => {
    return (
      <div className={`${BLOCK}__container`} key={`${i}__cm`}>
        {currentInsightView !== INSIGHT_BENCHMARK_TYPE.COMPANY && (
          <span className={`${BLOCK}__subtitle`}>{comp.companyName}</span>
        )}
        {comp.metrics.length > 0 ? (
          comp.metrics.map((m: GHGIntensityMetric, ind: number) =>
            getMetricBlock(comp.companyName, m, ind)
          )
        ) : (
          <span className={`${BLOCK}__label`}>
            <FormattedMessage id="insight.no.data.available" />
          </span>
        )}
      </div>
    );
  };

  const getIndustryDefaultMetricsforTableView = (
    cd: GHGIntensityCompanyData
  ) => {
    return defaultUnits.map((defaultUnit: string) => {
      const filteredMetric = cd.metrics.filter(
        (metric: GHGIntensityMetric) =>
          metric.unitLabel.toLowerCase().trim() ===
          defaultUnit.toLocaleLowerCase().trim()
      );

      if (filteredMetric && filteredMetric.length > 0) {
        return filteredMetric[0];
      } else {
        return {
          unit: defaultUnit,
          unitLabel: defaultUnit,
          unitTooltip:
            cd.metrics && cd.metrics.length > 0
              ? cd.metrics[0].unitTooltip
              : data[0].metrics[0].unitTooltip,
          metricLabel:
            cd.metrics && cd.metrics.length > 0
              ? cd.metrics[0].metricLabel
              : data[0].metrics[0].metricLabel,
          metricTooltip:
            cd.metrics && cd.metrics.length > 0
              ? cd.metrics[0].metricTooltip
              : data[0].metrics[0].metricTooltip,
          value: "*",
          minValue: "0",
          maxValue: "0",
          companyName: cd.companyName,
        };
      }
    });
  };

  const getRemainingMetricsForIndustryTable = (cd: GHGIntensityCompanyData) => {
    let remainingIndustryMetricData: GHGIntensityMetric[] = [];
    cd.metrics.forEach((metric: GHGIntensityMetric) => {
      let metricIndex = defaultUnits
        .map((defaultUnit) => defaultUnit.toLocaleLowerCase().trim())
        .indexOf(metric.unitLabel.toLowerCase().trim());
      if (metricIndex === -1) {
        remainingIndustryMetricData.push(metric);
      }
    });
    return remainingIndustryMetricData;
  };

  const getCompanyMetricsTableView = (
    cd: GHGIntensityCompanyData,
    ind: number
  ) => {
    let remainingData = 0;
    let emptyValues = false;
    if (cd.globalCompanyId === INSIGHT_BENCHMARK_IDS.INDUSTRY) {
      const defaultMetrics: GHGIntensityMetric[] =
        getIndustryDefaultMetricsforTableView(cd);

      const remainingIndustryMetricData =
        getRemainingMetricsForIndustryTable(cd);

      remainingData = remainingIndustryMetricData.length;
      cd.metrics = isExpandedView
        ? [...defaultMetrics, ...remainingIndustryMetricData]
        : defaultMetrics;
      emptyValues = cd.metrics.every((metric) => metric.value === "*");
    }
    return (
      <Fragment key={`${ind}-fragment`}>
        <div className={`${BLOCK}__table-group`} key={`${ind}__cmtv`}>
          {currentInsightView ===
          INSIGHT_BENCHMARK_TYPE.COMPANY ? null : currentInsightView ===
            INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK ? (
            <span
              className={`${BLOCK}__detailed-view-subtitle ${BLOCK}__table-view-subtitle `}
            >
              <Popover
                displayText={cd.companyName}
                content={cd.unitTooltip ?? []}
                metricClassName={`${BLOCK}__metric-label`}
              />
            </span>
          ) : (
            <span className={`${BLOCK}__table-view-subtitle-2`}>
              {cd.companyName}{" "}
              {cd.metrics.every((metric) => metric.value === "*") && ind !== 1
                ? " *"
                : ""}
            </span>
          )}
          {!emptyValues && (
            <div className={`${BLOCK}__table-view-wrapper`}>
              {cd.metrics.map((m: GHGIntensityMetric, i: number) =>
                getTableRowGroup(m.companyName, m, i)
              )}
            </div>
          )}
          {emptyValues && (
            <div className={`${BLOCK}__container`}>
              <span className={`${BLOCK}__label`}>
                <FormattedMessage id="insight.no.data.available" />
              </span>
            </div>
          )}
        </div>
        {cd.globalCompanyId === INSIGHT_BENCHMARK_IDS.INDUSTRY &&
          remainingData > 0 && (
            <button
              className={`${BLOCK}__collapsedExpandedButton`}
              type="button"
              onClick={handleExpandCollapse}
            >
              {isExpandedView ? (
                <span className={`${BLOCK}__collapsedExpandedButtonContent`}>
                  <FormattedMessage id="ghhIntensity.expandCollapse.showLess" />
                  <Icon name="chevron-up" height={25} width={25} />
                </span>
              ) : (
                <span className={`${BLOCK}__collapsedExpandedButtonContent`}>
                  <FormattedMessage
                    id="ghhIntensity.expandCollapse.showMore"
                    values={{ count: remainingData }}
                  />
                  <Icon name="chevron-down" height={25} width={25} />
                </span>
              )}
            </button>
          )}
      </Fragment>
    );
  };

  const getTableRowGroup = (
    companyName: string,
    metric: GHGIntensityMetric,
    ind: number
  ) => {
    if (!hasEmptyValues && (!metric.value || metric.value === "*"))
      setHasEmptyValues(true);
    return (
      <div
        key={`${ind}__trg`}
        className={classNames(`${BLOCK}__table-block`, {
          [`${BLOCK}__table-block--top-border`]:
            currentInsightView !== INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
            ind === 0,
          [`${BLOCK}__table-block-esrs`]: isESRS,
        })}
      >
        {currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK ? (
          //COMPANY NAME
          <div
            className={classNames(
              `${BLOCK}__metric-unit ${BLOCK}__metric-unit--half-horizontal-padding`,
              {
                //add styling when there are value for the metric
                [`${BLOCK}__metric-unit--bold`]: true,
              }
            )}
          >
            {companyName}
          </div>
        ) : (
          <div
            className={`${BLOCK}__metric-wrapper ${BLOCK}__metric-wrapper--no-horizontal-padding `}
          >
            <div className={``}>
              <Popover
                displayText={metric.metricLabel}
                content={metric.metricTooltip ?? []}
                metricClassName={`${BLOCK}__metric-label ${BLOCK}__table-view-metric-label--bold`}
              />
            </div>
            <div
              className={`${BLOCK}__metric-value ${BLOCK}__metric-value--black`}
            >
              {getFormattedNumber(metric.value)}
            </div>
          </div>
        )}

        {currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK ? (
          <div
            className={`${BLOCK}__metric-wrapper ${BLOCK}__metric-wrapper--half-horizontal-padding `}
          >
            <div className={``}>
              <Popover
                displayText={metric.metricLabel}
                content={metric.metricTooltip ?? []}
                metricClassName={`${BLOCK}__metric-label ${BLOCK}__table-view-metric-label`}
              />
            </div>
            <div
              className={`${BLOCK}__metric-value ${BLOCK}__metric-value--black`}
            >
              {getFormattedNumber(metric.value)}
            </div>
          </div>
        ) : (
          //UNIT
          <div
            className={`${BLOCK}__metric-unit ${BLOCK}__metric-unit--no-horizontal-padding`}
          >
            <div className={`${BLOCK}__metric-unit--wrapper`}>
              <Popover
                displayText={metric.unitLabel}
                content={metric.unitTooltip ?? []}
                metricClassName={``}
                buttonClassName={`${BLOCK}__metric-unit`}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  const calculateTickMarkPos = (
    baseAvg: any,
    peerOrIndustryAvg: any,
    minimum: any,
    range: any,
    isBaseScore: boolean
  ): number | null => {
    let scorePercent: number | null;
    let basePercent = ((baseAvg - minimum) / range) * 100;
    let peerPercent = ((peerOrIndustryAvg - minimum) / range) * 100;
    if (
      isBaseScore &&
      baseAvg !== "*" &&
      baseAvg !== null &&
      baseAvg !== peerOrIndustryAvg
    )
      scorePercent = basePercent;
    else if (
      !isBaseScore &&
      peerOrIndustryAvg !== "*" &&
      peerOrIndustryAvg !== null &&
      baseAvg !== peerOrIndustryAvg
    )
      scorePercent = peerPercent;
    else if (baseAvg === peerOrIndustryAvg)
      scorePercent =
        isBaseScore && baseAvg !== minimum
          ? basePercent - 1
          : isBaseScore && baseAvg == minimum
          ? basePercent
          : !isBaseScore && peerOrIndustryAvg !== minimum
          ? peerPercent
          : peerPercent + 1;
    else scorePercent = null;

    return scorePercent;
  };
  const renderPeerIndustryView = (
    metric: PeerIndustryUpdatedValueObj,
    index: number
  ) => {
    let minimum: number | null = metric.minValue
      ? getFormattedNumber(metric?.minValue, false)
      : null;
    let maximum: number | null = metric.maxValue
      ? getFormattedNumber(metric?.maxValue, false)
      : null;
    let baseAvg: number | string | null = metric.values.companyValue
      ? getFormattedNumber(metric?.values.companyValue, false)
      : null;
    let peerOrIndustryAvg: number | string | null = metric.values
      .peerOrIndustryAvg
      ? getFormattedNumber(metric?.values.peerOrIndustryAvg, false)
      : null;
    let range: number | null = maximum && minimum ? maximum - minimum : null;
    peerAverageDataNotAvailable =
      (baseAvg !== null || baseAvg !== "*") &&
      (peerOrIndustryAvg == null || peerOrIndustryAvg === "*")
        ? true
        : false; //Peer Average - No data available
    baseAverageDataNotAvailable =
      (baseAvg == null || baseAvg === "*") &&
      (peerOrIndustryAvg !== null || peerOrIndustryAvg !== "*")
        ? true
        : false; //baseline Average - No data available
    singularPeerAverageWithNoRange = range === 0 ? true : false; //Single Peer average value with no baseline data and no range
    singlePeerCompanyValue =
      baseAvg == null && peerOrIndustryAvg === "*" ? true : false;

    const showProgressBar =
      metric.values.peerOrIndustryAvg === null ||
      singularPeerAverageWithNoRange ||
      singlePeerCompanyValue
        ? false
        : true;

    return (
      <div key={`DashboardTile__GhgIntensityGroup-${index}`}>
        <div className={"DashboardTile__GhgIntensityGroup"}>
          <span className={""}>
            <Popover
              displayText={
                (baseAverageDataNotAvailable || peerAverageDataNotAvailable) &&
                !singlePeerCompanyValue &&
                !singularPeerAverageWithNoRange
                  ? `${metric.unitLabel} *`
                  : `${metric.unitLabel}`
              }
              content={metric.unitTooltip ?? []}
              metricClassName={"DashboardTile__companyDetailed"}
            />
          </span>
        </div>

        {showProgressBar ? (
          <Fragment key={"unique"}>
            <div className={"DashboardTile__GhgIntensityScoreWrapper"}>
              {exporting && (
                <div className={"DashboardTile__ghgIntensityTooltip"}>
                  {baseAvg && (
                    <span>
                      {metric.BASELINE_COMPANY}:{" "}
                      <b>{getFormattedNumber(baseAvg)}</b>
                    </span>
                  )}

                  {peerOrIndustryAvg && peerOrIndustryAvg !== "*" && (
                    <span>
                      {currentInsightView ===
                      INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK
                        ? PEER_AVG_VALUE
                        : INDUSTRY_AVG_VALUE}
                      : <b>{getFormattedNumber(peerOrIndustryAvg)}</b>
                    </span>
                  )}
                </div>
              )}
              <ProgressBar
                mainWidth={100}
                baseWidth={100}
                mainTooltip={
                  <>
                    <b>Range</b>
                    <br />
                    Minimum: <b>{getFormattedNumber(minimum)}</b>
                    <br />
                    Maximum: <b>{getFormattedNumber(maximum)}</b>
                  </>
                }
                baseTooltip={
                  <>
                    {metric.BASELINE_COMPANY}:{" "}
                    <b>{getFormattedNumber(baseAvg)}</b>
                  </>
                }
                peerOrIndustryTooltip={
                  currentInsightView ===
                  INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK ? (
                    <>
                      {PEER_AVG_VALUE}:{" "}
                      <b>{getFormattedNumber(peerOrIndustryAvg)}</b>
                    </>
                  ) : (
                    <>
                      {INDUSTRY_AVG_VALUE}:{" "}
                      <b>{getFormattedNumber(peerOrIndustryAvg)}</b>
                    </>
                  )
                }
                colors={["#007680", "#9DD4CF"]}
                gradient={true}
                tileType={50}
                baseScorePos={calculateTickMarkPos(
                  baseAvg,
                  peerOrIndustryAvg,
                  minimum,
                  range,
                  true
                )}
                peerScorePos={calculateTickMarkPos(
                  baseAvg,
                  peerOrIndustryAvg,
                  minimum,
                  range,
                  false
                )}
                exporting={exporting}
              />
              <div className={"DashboardTile__scoreMinMax"}>
                <span>{getFormattedNumber(minimum)}</span>
                <span>{getFormattedNumber(maximum)}</span>
              </div>
            </div>
          </Fragment>
        ) : (
          <div>
            {peerAverageDataNotAvailable &&
              !singlePeerCompanyValue &&
              !singularPeerAverageWithNoRange && (
                <div className={"DashboardTile__scoreNoData"}>
                  <span>
                    <span className="DashboardTile__scoreNoData-nonNumeric">
                      {metric.BASELINE_COMPANY}:
                    </span>{" "}
                    {getFormattedNumber(baseAvg)}
                  </span>
                  <span>
                    {" "}
                    <span className="DashboardTile__scoreNoData-nonNumeric">
                      {currentInsightView === 5
                        ? PEER_AVG_VALUE
                        : INDUSTRY_AVG_VALUE}
                    </span>{" "}
                    No data
                  </span>
                </div>
              )}
            {(baseAverageDataNotAvailable || singlePeerCompanyValue) &&
              !singularPeerAverageWithNoRange && (
                <div className={"DashboardTile__scoreNoData"}>
                  <span>
                    <span className="DashboardTile__scoreNoData-nonNumeric">
                      {metric.BASELINE_COMPANY}:
                    </span>{" "}
                    No data
                  </span>
                  <span>
                    <span className="DashboardTile__scoreNoData-nonNumeric">
                      {currentInsightView === 5
                        ? PEER_AVG_VALUE
                        : INDUSTRY_AVG_VALUE}{" "}
                      :{" "}
                    </span>
                    {getFormattedNumber(peerOrIndustryAvg)}
                  </span>
                </div>
              )}
            {singularPeerAverageWithNoRange && (
              <div className={"DashboardTile__scoreNoData"}>
                <span>
                  <span className="DashboardTile__scoreNoData-nonNumeric">
                    {metric.BASELINE_COMPANY}:
                  </span>{" "}
                  {baseAvg ? getFormattedNumber(baseAvg) : "No data"}
                </span>
                <span>
                  <span className="DashboardTile__scoreNoData-nonNumeric">
                    {currentInsightView === 5
                      ? PEER_AVG_VALUE
                      : INDUSTRY_AVG_VALUE}{" "}
                    :{" "}
                  </span>
                  {peerOrIndustryAvg
                    ? getFormattedNumber(peerOrIndustryAvg)
                    : "No data"}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const getCollapsedIndustryViewData = (
    updatedValues: PeerIndustryUpdatedValueObj[]
  ) => {
    return defaultUnits.map((defaultKeyword) => {
      return updatedValues?.filter(
        (obj: PeerIndustryUpdatedValueObj) =>
          obj.unitLabel.toLowerCase().trim() ===
          defaultKeyword.toLocaleLowerCase().trim()
      )[0];
    });
  };

  const getRemainingIndustryViewData = (
    updatedValues: PeerIndustryUpdatedValueObj[]
  ) => {
    return updatedValues.filter(
      (obj) =>
        !defaultUnits?.some(
          (keyword) =>
            obj.unitLabel.toLowerCase().trim() ===
            keyword.toLocaleLowerCase().trim()
        )
    );
  };

  const sortIndustryViewData = (
    updatedValues: PeerIndustryUpdatedValueObj[]
  ) => {
    const hasBothValues = updatedValues.filter(
      (obj) => obj.values.companyValue && obj.values.peerOrIndustryAvg
    );
    const hasOnlyBaseValues = updatedValues.filter(
      (obj) => obj.values.companyValue && !obj.values.peerOrIndustryAvg
    );
    const hasOnlyIndustryValues = updatedValues.filter(
      (obj) => !obj.values.companyValue && obj.values.peerOrIndustryAvg
    );
    const hasNoValues = updatedValues.filter(
      (obj) => !obj.values.companyValue && !obj.values.peerOrIndustryAvg
    );
    return [
      ...hasBothValues,
      ...hasOnlyBaseValues,
      ...hasOnlyIndustryValues,
      ...hasNoValues,
    ];
  };

  const addDefaultUnits = (updatedValues: PeerIndustryUpdatedValueObj[]) => {
    const baseCompany = updatedValues[0]?.BASELINE_COMPANY;
    const indexInArray = updatedValues.length;
    const unitTooltip = updatedValues[0]?.unitTooltip;
    const actualUnits = new Set(
      updatedValues.map((unit) => unit.unitLabel.toLowerCase())
    );
    const missingDefaultUnits = defaultUnits.filter(
      (unit) => !actualUnits.has(unit.toLowerCase())
    );
    const updatedValuesWithDefaultUnits = missingDefaultUnits.map(
      (unit, index) => ({
        BASELINE_COMPANY: baseCompany,
        unitTooltip,
        unitLabel: unit,
        values: {
          companyValue: null,
          peerOrIndustryAvg: null,
        },
        minValue: "0",
        maxValue: "0",
        indexInArray: indexInArray + index,
      })
    );
    return [...updatedValues, ...updatedValuesWithDefaultUnits];
  };

  useEffect(() => {
    if (showRangeChart) {
      let updatedValues: PeerIndustryUpdatedValueObj[] = [];
      let currIndexMetric = 0;
      data.map((companyData: GHGIntensityCompanyData, indexData: number) => {
        companyData.metrics.map((metric: GHGIntensityMetric, i: number) => {
          const currUnitLabel = metric.unitLabel;
          const currUnit = updatedValues.filter(
            (metric: PeerIndustryUpdatedValueObj) =>
              metric.unitLabel === currUnitLabel
          );
          if (currUnit.length === 0) {
            //means the current unit does not exist in the array
            let finalValue: PeerIndustryUpdatedValueObj = {
              BASELINE_COMPANY: data[0].companyName,
              unitLabel: metric.unitLabel,
              values: {
                companyValue: indexData === 0 ? metric.value : null,
                peerOrIndustryAvg: indexData === 1 ? metric.value : null,
              },
              minValue:
                indexData === 1 && metric.minValue ? metric.minValue : null,
              maxValue:
                indexData === 1 && metric.maxValue ? metric.maxValue : null,
              indexInArray: currIndexMetric,
              unitTooltip: metric.unitTooltip
                ? metric.unitTooltip.filter(
                    (tooltip: AssociatedMetricTooltips) =>
                      tooltip.associatedMetricGroupName ===
                      GHG_INTESITY_UNIT_TOOLTIP
                  )
                : [],
            };

            updatedValues = [...updatedValues, finalValue];
            currIndexMetric += 1;
          } else {
            const indexReq = currUnit[0].indexInArray;
            updatedValues[indexReq].values.peerOrIndustryAvg = metric.value
              ? metric.value
              : null;
            updatedValues[indexReq].minValue = metric.minValue
              ? metric.minValue
              : null;
            updatedValues[indexReq].maxValue = metric.maxValue
              ? metric.maxValue
              : null;
          }
        });
      });
      if (
        currentInsightView === INSIGHT_BENCHMARK_TYPE.INDUSTRY &&
        !isTableViewActive
      ) {
        const industryViewData = addDefaultUnits(updatedValues);
        const collapsedIndustryViewData =
          getCollapsedIndustryViewData(industryViewData);
        const remainingIndustryViewData =
          getRemainingIndustryViewData(industryViewData);
        setremainingUnitsCount(
          industryViewData.length - collapsedIndustryViewData.length
        );
        updatedValues =
          industryViewData.length - collapsedIndustryViewData.length > 0 &&
          !isExpandedView
            ? collapsedIndustryViewData
            : [
                ...collapsedIndustryViewData,
                ...sortIndustryViewData(remainingIndustryViewData),
              ];
      }
      setPeerIndustryUpdatedData(updatedValues);
    }
  }, [data, isExpandedView]);

  useEffect(() => {
    setHasEmptyValues(false);
  }, [isDetailedView, isTableViewActive]);
  useEffect(() => {
    // Update hideBaseLegend state based on the condition
    setHideBaseLegend(
      peerIndustryUpdatedData.every(
        (obj: PeerIndustryUpdatedValueObj) =>
          obj.values.companyValue == null || obj.values.companyValue === "*"
      )
    );

    // Update hidePeerLegend state based on the condition
    setHidePeerLegend(
      peerIndustryUpdatedData.every(
        (obj: PeerIndustryUpdatedValueObj) =>
          obj.values.peerOrIndustryAvg == null ||
          obj.values.peerOrIndustryAvg === "*"
      )
    );

    //determine whether or not to show the no data available message for base
    setShowBaseNoDataMsg(
      peerIndustryUpdatedData.some(
        (obj: PeerIndustryUpdatedValueObj) =>
          obj.values.companyValue == null || obj.values.companyValue === "*"
      )
    );

    //determine whether or not to show the no data available message for peers/industry
    setShowPeerOrIndustryNoDataMsg(
      peerIndustryUpdatedData.some(
        (obj: PeerIndustryUpdatedValueObj) =>
          obj.values.peerOrIndustryAvg == null ||
          obj.values.peerOrIndustryAvg === "*"
      )
    );

    if (
      currentInsightView === INSIGHT_BENCHMARK_TYPE.INDUSTRY &&
      !isTableViewActive
    ) {
      setHasEmptyValues(peerIndustryUpdatedData.length ? false : true);
    }
  }, [peerIndustryUpdatedData]);

  return (
    <div
      className={`${BLOCK}`}
      data-testid={`${BLOCK}`}
      ref={buttonRef}
      tabIndex={0}
    >
      {showRangeChart ? (
        <>
          {
            <Fragment key={`legend-GHGintensity`}>
              <div className={`DashboardTile__legend`} key={"Legend metric"}>
                {[
                  { id: 1, colors: ["#00A3E0", "#00ABAB"] },
                  { id: 2, colors: ["#3D2074", "#EA078E"] },
                ].map((legend: any, index) => {
                  return (
                    legend && (
                      <div
                        className={`DashboardTile__legend__metric`}
                        key={`Legend-${index}`}
                      >
                        {index === 0 && !hideBaseLegend && (
                          <div
                            className={`DashboardTile__legend__bar`}
                            key={`Legend-Bar-${index}`}
                          >
                            <ProgressBar
                              mainWidth={100}
                              baseWidth={""}
                              mainTooltip={""}
                              baseTooltip={""}
                              colors={legend.colors}
                              gradient={true}
                              exporting={exporting}
                            />
                          </div>
                        )}
                        {index === 1 && !hidePeerLegend && (
                          <div
                            className={`DashboardTile__legend__bar`}
                            key={`Legend-Bar-${index}`}
                          >
                            <ProgressBar
                              mainWidth={100}
                              baseWidth={""}
                              mainTooltip={""}
                              baseTooltip={""}
                              colors={legend.colors}
                              gradient={true}
                              exporting={exporting}
                            />
                          </div>
                        )}
                        {index === 0 ? (
                          <span>
                            {BASELINE_COMPANY}
                            {hideBaseLegend && "*"}
                          </span>
                        ) : index === 1 ? (
                          currentInsightView === 5 ? (
                            <span>{PEER_AVG_VALUE}</span>
                          ) : (
                            <span>
                              {INDUSTRY_AVG_VALUE}
                              {hidePeerLegend && "*"}
                            </span>
                          )
                        ) : undefined}
                      </div>
                    )
                  );
                })}
              </div>
            </Fragment>
          }
          {peerIndustryUpdatedData &&
            peerIndustryUpdatedData.length > 0 &&
            peerIndustryUpdatedData.map(
              (metric: PeerIndustryUpdatedValueObj, index: number) => {
                return renderPeerIndustryView(metric, index);
              }
            )}

          {hasEmptyValues && (
            <div className={`${BLOCK}__container`}>
              <span className={`${BLOCK}__label`}>
                <FormattedMessage id="insight.no.data.available" />
              </span>
            </div>
          )}
          {currentInsightView === INSIGHT_BENCHMARK_TYPE.INDUSTRY &&
            remainingUnitsCount > 0 &&
            !isTableViewActive && (
              <button
                className={`${BLOCK}__collapsedExpandedButton`}
                type="button"
                onClick={handleExpandCollapse}
              >
                {isExpandedView ? (
                  <span className={`${BLOCK}__collapsedExpandedButtonContent`}>
                    <FormattedMessage id="ghhIntensity.expandCollapse.showLess" />
                    <Icon name="chevron-up" height={25} width={25} />
                  </span>
                ) : (
                  <span className={`${BLOCK}__collapsedExpandedButtonContent`}>
                    <FormattedMessage
                      id="ghhIntensity.expandCollapse.showMore"
                      values={{ count: remainingUnitsCount }}
                    />
                    <Icon name="chevron-down" height={25} width={25} />
                  </span>
                )}
              </button>
            )}
          <div className="DashboardTile__noDataAny DashboardTile__noDataAny--multiple">
            {showBaseNoDataMsg && (
              <FormattedMessage
                id="cdp.no.data"
                values={{ type: BASELINE_COMPANY }}
              />
            )}
            {showPeerOrIndustryNoDataMsg && (
              <div>
                {currentInsightView === 5 ? (
                  <FormattedMessage
                    id="cdp.no.data"
                    values={{ type: PEER_AVG_VALUE }}
                  />
                ) : (
                  <FormattedMessage
                    id="cdp.no.data"
                    values={{ type: INDUSTRY_AVG_VALUE }}
                  />
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {isTableViewActive
            ? null
            : isDetailedView
            ? detailedTableData.map((dt: GHGIntensityCompanyData, i: number) =>
                getDetailedBlock(dt, i)
              )
            : data.map((d: GHGIntensityCompanyData, i: number) =>
                getCompanyMetrics(d, i)
              )}
          {isTableViewActive
            ? currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK
              ? detailedTableData.map(
                  (dt: GHGIntensityCompanyData, i: number) =>
                    getCompanyMetricsTableView(dt, i)
                )
              : data.map((d: GHGIntensityCompanyData, i: number) =>
                  getCompanyMetricsTableView(d, i)
                )
            : null}
          {hasEmptyValues &&
            currentInsightView !== INSIGHT_BENCHMARK_TYPE.COMPANY && (
              <div className={`${BLOCK}__no-data-available`}>
                <FormattedMessage id="no.data.available" />
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default GHGIntensityChart;
