import { mapTableChart, useTileContext } from "../tile.context";
import TileEmptyContent from "../tile-empty-content";
import {
  InsightData,
  InsightMetricData,
  InsightCompanyFlat,
} from "services/insights/insights.model";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import classNames from "classnames";
import {
  dollarFormatter,
  handleMouseEnter,
  handleMouseLeave,
  isNumeric,
} from "utils/functions";
import {
  INSIGHT_BENCHMARK_IDS,
  INSIGHT_BENCHMARK_ID_ARRAY,
  INSIGHT_BENCHMARK_TYPE,
  NEUTRAL_VALUES,
} from "utils/constants";
import { useState, useEffect, memo } from "react";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";
import { useDispatch } from "react-redux";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import Popover from "components/shared/popover/popover";

type MetricsGrouped = {
  groupName: string;
  groupDisplayName: string;
  metrics: InsightMetricData[];
};

type ClimateRelatedDisclosuresAndMetricsData = InsightCompanyFlat & {
  groups: MetricsGrouped[];
};

const PREFIX = "Risk";
const QUESTION_NO_APPLICABLE = "Question not applicable";
const BLANK_VALUES_KEYWORDS = [
  "question not applicable",
  "entity deemed question not applicable",
];
const METRICS_COMBINED = [
  "PotentialFinancialImpactOfRiskMinimumCustom",
  "PotentialFinancialImpactOfRiskMaximumCustom",
];
const CURRENCY_KEYWORDS = [
  "C0_4_Select_the_currency_used_for_all_financial_information_disclosed_throughout_your_response",
  "Currency denominator",
];

const ENTITY_DEEMED_QUESTION = "Entity deemed question not applicable";

const VALUE_SEPARATOR = "##VALUE##";

const ClimateRelatedDisclosuresAndMetrics = () => {
  const {
    dataset,
    metadata,
    originalDataset,
    filters,
    metadata: {
      benchmarkType,
      isDetailedView,
      benchmarkMetadata,
      benchmarkMetadata: { associatedMetrics, associatedMetricsDisplayNames },
      associatedMetricsTooltips,
      benchmarkTileType,
    },
    isTableViewActive,
    setManualHScale,
    manualHScale,
  } = useTileContext();
  const keywordForFirstMetric = "RiskTypeCustom";
  const BLOCK = "climateRelatedDisclosuresAndMetrics";
  const keywrodForRiskNumber = "RiskNumber";
  const dispatch = useDispatch();
  //TO DO: UNCOMMENT WHEN BACKEND CHANGES ARE DONE

  const datasetToTake =
    benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK && !isDetailedView
      ? originalDataset.filter(
          (dataset) =>
            !INSIGHT_BENCHMARK_ID_ARRAY.includes(dataset.globalCompanyId)
        )
      : dataset.filter(
          (data) => data.globalCompanyId !== INSIGHT_BENCHMARK_IDS.INDUSTRY
        );

  const mapData = () => {
    let hasBaselineCompanyCurrency = false;

    let tmpData = datasetToTake.map((d: InsightData) => {
      //Mapping the values
      let companyGroups: MetricsGrouped[] = d.metrics.reduce(
        (compGroups: MetricsGrouped[], current: InsightMetricData) => {
          //Get metric values for each risk
          let values = current.metricValue
            ? current.metricValue.split(VALUE_SEPARATOR)
            : [];

          if (
            d.isBaseCompany &&
            CURRENCY_KEYWORDS.find((ck: string) =>
              current.metricKey.includes(ck)
            ) &&
            values.length > 0 &&
            values[0] &&
            values[0] !== ""
          )
            hasBaselineCompanyCurrency = true;

          if (compGroups.length > 0) {
            //Add the metrics to each risk
            compGroups.forEach((cg: MetricsGrouped, cgIndex: number) => {
              cg.metrics = [
                ...cg.metrics,
                {
                  metricKey: current.metricKey,
                  metricName:
                    associatedMetricsDisplayNames[
                      associatedMetrics.findIndex(
                        (am: string, amIndex: number) =>
                          am === current.metricKey
                      )
                    ] ?? "",

                  metricValue:
                    CURRENCY_KEYWORDS.find((ck: string) =>
                      current.metricKey.includes(ck)
                    ) && values.length > 0
                      ? values[0]
                      : values[cgIndex] &&
                        !BLANK_VALUES_KEYWORDS.includes(
                          values[cgIndex].toLowerCase()
                        ) &&
                        values[cgIndex] !== ""
                      ? values[cgIndex]
                      : null,
                },
              ];
            });
          } else {
            if (values.length) {
              //Build the risks
              return values.map((v: string, vIndex: number) => ({
                groupName: current.metricKey,
                groupDisplayName: v,
                metrics: [],
              }));
            }
          }
          return compGroups;
        },
        []
      );

      companyGroups = companyGroups.filter(
        (cg: MetricsGrouped) =>
          cg.metrics.find(
            (m: InsightMetricData) =>
              m.metricKey.includes(keywordForFirstMetric) &&
              m.metricValue &&
              !BLANK_VALUES_KEYWORDS.includes(m.metricValue.toLowerCase())
          ) &&
          cg.metrics.some(
            (m: InsightMetricData) =>
              m.metricValue &&
              !m.metricKey.includes(keywordForFirstMetric) &&
              !BLANK_VALUES_KEYWORDS.includes(m.metricValue.toLowerCase()) &&
              !CURRENCY_KEYWORDS.includes(m.metricKey)
          )
      );

      //Remove null values
      companyGroups.forEach((cg: MetricsGrouped, cgIndex: number) => {
        cg.groupDisplayName = `${PREFIX} ${cgIndex + 1}`;
        cg.metrics = cg.metrics.filter(
          (m: InsightMetricData) =>
            (m.metricValue &&
              !BLANK_VALUES_KEYWORDS.includes(m.metricValue.toLowerCase())) ||
            METRICS_COMBINED.find((mc: string) => m.metricKey.includes(mc))
        );

        //Change value of metrics to combine if one of them is null
        if (
          cg.metrics.filter(
            (m: InsightMetricData) =>
              METRICS_COMBINED.find((mc: string) => m.metricKey.includes(mc)) &&
              m.metricValue &&
              !BLANK_VALUES_KEYWORDS.includes(m.metricValue.toLowerCase())
          ).length > 0
        ) {
          cg.metrics.forEach((m: InsightMetricData) => {
            if (
              METRICS_COMBINED.find((mc: string) => m.metricKey.includes(mc)) &&
              (!m.metricValue ||
                BLANK_VALUES_KEYWORDS.includes(m.metricValue.toLowerCase()))
            ) {
              m.metricValue = "*";
              if (!hasEmptyValues) hasEmptyValues = true;
            }
          });
        } else {
          //Remove metrics to combine if both have null values
          cg.metrics = cg.metrics.filter(
            (m: InsightMetricData) =>
              !METRICS_COMBINED.find((mc: string) => m.metricKey.includes(mc))
          );
        }

        //Merge metrics
        cg.metrics = cg.metrics.reduce(
          (metrics: InsightMetricData[], current: InsightMetricData) => {
            if (
              CURRENCY_KEYWORDS.find((ck: string) =>
                current.metricKey.includes(ck)
              )
            )
              return [...metrics];
            const currencyMetric = cg.metrics.find(
              (companyMetric: InsightMetricData) =>
                CURRENCY_KEYWORDS.find((ck: string) =>
                  companyMetric.metricKey.includes(ck)
                )
            );
            let currentIndex = -1;
            let pushedMetric = metrics.find(
              (me: InsightMetricData, meIndex: number) => {
                if (
                  METRICS_COMBINED.find((mc: string) =>
                    me.metricKey.includes(mc)
                  )
                ) {
                  currentIndex = meIndex;
                  return true;
                }
                return false;
              }
            );

            //find metric to combine
            if (
              METRICS_COMBINED.find((mc: string) =>
                current.metricKey.includes(mc)
              )
            ) {
              //find if one of the metric to be combined have been pushed
              if (currentIndex !== -1 && pushedMetric) {
                metrics[currentIndex] = {
                  ...pushedMetric,
                  metricValue: `${pushedMetric.metricValue ?? ""} / ${
                    isNumeric(current.metricValue)
                      ? new Intl.NumberFormat("en-US").format(
                          Math.round(current.metricValue)
                        )
                      : current.metricValue
                  } ${
                    currencyMetric &&
                    current.metricValue &&
                    current.metricValue !== "*"
                      ? currencyMetric.metricValue
                      : ""
                  } `,
                };
                return [...metrics];
              } else {
                return [
                  ...metrics,
                  {
                    ...current,
                    metricValue:
                      current.metricValue && current.metricValue !== "*"
                        ? `${
                            isNumeric(current.metricValue)
                              ? new Intl.NumberFormat("en-US").format(
                                  Math.round(current.metricValue)
                                )
                              : current.metricValue
                          } ${currencyMetric ? currencyMetric.metricValue : ""}`
                        : current.metricValue,
                  },
                ];
              }
            }

            return [
              ...metrics,
              {
                ...current,
                metricValue:
                  !current.metricKey.includes(keywordForFirstMetric) &&
                  isNumeric(current.metricValue) &&
                  currencyMetric
                    ? `${new Intl.NumberFormat("en-US").format(
                        Math.round(current.metricValue)
                      )} ${currencyMetric.metricValue ?? ""}`
                    : current.metricValue,
              },
            ];
          },
          []
        );
      });
      return {
        ...d,
        groups: companyGroups,
      };
    });

    //Filter risk based on baseline company risks
    if (benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY) {
      if (
        tmpData.find(
          (td: ClimateRelatedDisclosuresAndMetricsData) => td.isBaseCompany
        ) &&
        hasBaselineCompanyCurrency
      ) {
        let baselineRisks = tmpData.find(
          (td: ClimateRelatedDisclosuresAndMetricsData) => td.isBaseCompany
        )?.groups;
        if (baselineRisks?.length) {
          let riskTypes = baselineRisks.map((r: MetricsGrouped) =>
            r.metrics.find((m: InsightMetricData) =>
              m.metricKey.includes(keywordForFirstMetric)
            )
              ? r.metrics.find((m: InsightMetricData) =>
                  m.metricKey.includes(keywordForFirstMetric)
                )?.metricValue
              : ""
          );
          tmpData.forEach((td: ClimateRelatedDisclosuresAndMetricsData) => {
            if (!td.isBaseCompany) {
              td.groups = td.groups.filter(
                (mg: MetricsGrouped) =>
                  baselineRisks?.length &&
                  mg.metrics.find(
                    (m: InsightMetricData) =>
                      m.metricKey.includes(keywordForFirstMetric) &&
                      riskTypes.includes(m.metricValue)
                  )
              );

              td.groups.forEach((cg: MetricsGrouped, cgIndex: number) => {
                cg.groupDisplayName = `${PREFIX} ${cgIndex + 1}`;
              });
            }
          });
        }
      } else if (!hasBaselineCompanyCurrency) {
        //If baseline company does not have currency denominator, remove industry data
        tmpData = tmpData.filter(
          (td: ClimateRelatedDisclosuresAndMetricsData) => td.isBaseCompany
        );
      }
    }

    return tmpData;
  };

  useEffect(() => {
    //SET NUMBER OF COLUMNS IN ORDER TO ADJUST WIDTH OF THE TILE
    if (
      !(
        datasetToTake.length === 0 ||
        (datasetToTake.length > 0 &&
          datasetToTake.every(
            (d: InsightData) =>
              d.metrics.every(
                (m: InsightMetricData) =>
                  !m.metricValue ||
                  m.metricKey.includes(keywordForFirstMetric) ||
                  (m.metricValue &&
                    BLANK_VALUES_KEYWORDS.includes(
                      m.metricValue.toLowerCase()
                    )) ||
                  CURRENCY_KEYWORDS.find((ck: string) =>
                    m.metricKey.includes(ck)
                  ) ||
                  m.metricKey.includes(keywrodForRiskNumber)
              ) && d.globalCompanyId !== INSIGHT_BENCHMARK_IDS.PEER
          ))
      )
    ) {
      const tmpMaxNumberOfRisks = Math.max(
        ...mapData().map(
          (md: ClimateRelatedDisclosuresAndMetricsData) => md.groups.length ?? 0
        )
      );
      if (tmpMaxNumberOfRisks > 0 && tmpMaxNumberOfRisks < 4)
        setManualHScale(tmpMaxNumberOfRisks);
    }
  }, []);

  if (
    datasetToTake.length === 0 ||
    (datasetToTake.length > 0 &&
      datasetToTake.every((d: InsightData) =>
        d.metrics.every(
          (m: InsightMetricData) =>
            !m.metricValue ||
            m.metricKey.includes(keywordForFirstMetric) ||
            (m.metricValue &&
              BLANK_VALUES_KEYWORDS.includes(m.metricValue.toLowerCase())) ||
            CURRENCY_KEYWORDS.find((ck: string) => m.metricKey.includes(ck)) ||
            m.metricKey.includes(keywrodForRiskNumber)
        )
      ))
  )
    return <TileEmptyContent />;

  let hasEmptyValues =
    datasetToTake.length > 0 &&
    datasetToTake
      .filter(
        (dataset: InsightData) =>
          dataset.globalCompanyId !== INSIGHT_BENCHMARK_IDS.PEER
      )
      .some((d: InsightData) =>
        d.metrics.every(
          (m: InsightMetricData) =>
            !m.metricValue ||
            m.metricKey.includes(keywordForFirstMetric) ||
            (m.metricValue &&
              BLANK_VALUES_KEYWORDS.includes(m.metricValue.toLowerCase())) ||
            CURRENCY_KEYWORDS.find((ck: string) => m.metricKey.includes(ck))
        )
      );

  const getGraph = () => {
    return (
      <>
        {mapData().map(
          (md: ClimateRelatedDisclosuresAndMetricsData, mdIndex: number) => (
            <div
              className={`${BLOCK}__company-data-container`}
              key={`${mdIndex}-company`}
              data-testid={`${BLOCK}__Graph-container`}
            >
              {benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY ? (
                <span className={`${BLOCK}__company-name`}>
                  {`${md.companyName}${md.groups.length > 0 ? "" : " *"}`}
                </span>
              ) : null}
              <div className={`${BLOCK}__wrapper`}>
                {md.groups.map((g: MetricsGrouped, gIndex: number) =>
                  getRiskBox(g, gIndex, md.groups.length === 1)
                )}
              </div>
            </div>
          )
        )}
      </>
    );
  };

  const getTable = () => {
    return (
      <>
        {mapData().map(
          (md: ClimateRelatedDisclosuresAndMetricsData, mdIndex: number) => (
            <div
              className={`${BLOCK}__company-data-container`}
              key={`${mdIndex}-company`}
              data-testid={`${BLOCK}__tablecontainer`}
            >
              {benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY ? (
                <span
                  className={`${BLOCK}__company-name ${BLOCK}__company-name--table`}
                >
                  {`${md.companyName}${md.groups.length > 0 ? "" : " *"}`}
                </span>
              ) : null}
              <div className={`${BLOCK}__wrapper ${BLOCK}__wrapper--table`}>
                {md.groups.map((g: MetricsGrouped, gIndex: number) => (
                  <div
                    className={`${BLOCK}__group-wrapper ${BLOCK}__group-wrapper--table`}
                    key={`${g.groupDisplayName}-${gIndex}-risk`}
                    style={{
                      width:
                        manualHScale === -1
                          ? ""
                          : `calc(( 100% - ${
                              manualHScale < 4 ? 1.5 * (manualHScale - 1) : 4.5
                            }rem ) / ${manualHScale < 4 ? manualHScale : 4})`,
                    }}
                  >
                    <table className={`${BLOCK}__group-table`}>
                      <tbody>
                        {g.metrics.map(
                          (
                            gMetric: InsightMetricData,
                            gMetricIndex: number
                          ) => (
                            <tr key={`${gMetricIndex}-${mdIndex}-metric`}>
                              <td>
                                <Popover
                                  displayText={gMetric.metricName}
                                  content={
                                    associatedMetricsTooltips?.length > 0
                                      ? associatedMetricsTooltips?.filter(
                                          (
                                            associatedMetricsTooltip: AssociatedMetricTooltips
                                          ) =>
                                            associatedMetricsTooltip.associatedMetric ===
                                            gMetric.metricKey
                                        )
                                      : []
                                  }
                                  metricClassName={``}
                                  buttonClassName={`${BLOCK}__table-metric`}
                                />
                              </td>
                              <td>{gMetric.metricValue ?? "*"}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </>
    );
  };

  const getRiskBox = (
    group: MetricsGrouped,
    groupIndex: number,
    isSingleRisk: boolean = false
  ) => {
    return (
      <div
        className={classNames(`${BLOCK}__group-wrapper`)}
        key={`${group.groupDisplayName}-${groupIndex}-risk`}
        style={{
          width:
            manualHScale === -1
              ? ""
              : `calc(( 100% - ${
                  manualHScale < 4 ? manualHScale - 1 : 3
                }rem ) / ${manualHScale < 4 ? manualHScale : 4})`,
        }}
      >
        {isSingleRisk ? null : (
          <span className={`${BLOCK}__group-title`}>
            {group.groupDisplayName}
          </span>
        )}

        <div className={`${BLOCK}__group-box`}>
          {group.metrics.map((m: InsightMetricData, metricIndex: number) => (
            <div
              className={`${BLOCK}__group-metric`}
              key={`${groupIndex}-${metricIndex}-submetric`}
            >
              <Popover
                displayText={m.metricName}
                content={
                  associatedMetricsTooltips?.length > 0
                    ? associatedMetricsTooltips?.filter(
                        (associatedMetricsTooltip: AssociatedMetricTooltips) =>
                          associatedMetricsTooltip.associatedMetric ===
                          m.metricKey
                      )
                    : []
                }
                metricClassName={`${BLOCK}__group-metric-text`}
              />
              <span
                className={classNames(`${BLOCK}__group-metric-value`, {
                  [`${BLOCK}__group-metric-value--gray`]: !m.metricValue,
                })}
                onMouseEnter={(e: any) => {
                  if (
                    NEUTRAL_VALUES.find(
                      (nv: string) =>
                        m.metricValue && m.metricValue.toLowerCase() === nv
                    )
                  )
                    handleMouseEnter(
                      e,
                      "span",
                      <div>
                        <FormattedMessage id="insights.tabular.neutral" />
                      </div>,
                      "",
                      dispatch,
                      null,
                      MainTooltipPosition.BottomMiddle
                    );
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e, dispatch, false);
                }}
              >
                {m.metricValue ?? "*"}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={`${BLOCK}__container`} data-testid={`${BLOCK}__container`}>
      {isTableViewActive ? getTable() : getGraph()}
      {hasEmptyValues && (
        <div className={`${BLOCK}__no-data-section`}>
          <span>
            <FormattedMessage id="no.data.available" />
          </span>
        </div>
      )}
    </div>
  );
};

export default ClimateRelatedDisclosuresAndMetrics;
