import { Dispatch } from "redux";
import { createAction } from "utils/redux.utils";
import { TagsModel, TagsState } from "./tags.model";
import * as api from "./tags.api";

export const ACTIONS = {
  CURRENT_SAVED_TAGS_LIST: "ESG/UI/CURRENT_SAVED_TAGS_LIST",
  TAGS_LOADING: "ESG/UI/TAGS_LOADING",
  SHOW_TAGS_PANEL: "ESG/UI/SHOW_TAGS_PANEL",
  CREATE_NEW_TAG_TRACK: "ESG/UI/CREATE_NEW_TAG_TRACK",
  DELETE_TAG_TRACK: "ESG/UI/DELETE_TAG_TRACK",
  CREATE_TAG_COMMENT_TRACK: "ESG/UI/CREATE_TAG_COMMENT_TRACK",
  DELETE_TAG_COMMENT_TRACK: "ESG/UI/DELETE_TAG_COMMENT_TRACK",
  HIDE_TAGS_TRACK: "ESG/UI/HIDE_TAGS_TRACK",
  SHOW_TAGS_TRACK: "ESG/UI/SHOW_TAGS_TRACK",
  COMPARISONT_ITEM_ID_TO_UPDATE: "ESG/UI/COMPARISONT_ITEM_ID_TO_UPDATE",
};

export const tagsLoading = createAction(ACTIONS.TAGS_LOADING);
export const comparisonItemReferencedIdToUpdate = createAction(
  ACTIONS.COMPARISONT_ITEM_ID_TO_UPDATE
);

//Omniture tracking
export const createNewTagTrack = createAction(ACTIONS.CREATE_NEW_TAG_TRACK);
export const deleteTagTrack = createAction(ACTIONS.DELETE_TAG_TRACK);
export const createTagCommentTrack = createAction(
  ACTIONS.CREATE_TAG_COMMENT_TRACK
);
export const deleteTagCommentTrack = createAction(
  ACTIONS.DELETE_TAG_COMMENT_TRACK
);
export const hideTagsTrack = createAction(ACTIONS.HIDE_TAGS_TRACK);
export const showTagsTrack = createAction(ACTIONS.SHOW_TAGS_TRACK);

export const getComparisonItemTagsList = async (referencedId: number) => {
  try {
    const response = await api.getTagsList(referencedId);
    return response.data;
  } catch (e) {}
};
export const deleteTag =
  (tagDetailId: any, referencedId: number) =>
  async (dispatch: Dispatch<any>) => {
    try {
      await api.deleteTag(tagDetailId);
      dispatch(comparisonItemReferencedIdToUpdate(referencedId));
      dispatch(deleteTagTrack(true));
    } catch (e) {}
  };

const tagsModel = new TagsModel();

const tagsReducer = (
  state: TagsState = tagsModel.initialState(),
  action: any
): TagsState => {
  switch (action.type) {
    case ACTIONS.TAGS_LOADING:
      return tagsModel.setLoading(action.payload);
    case ACTIONS.COMPARISONT_ITEM_ID_TO_UPDATE:
      return tagsModel.setComparisonItemIdToUpdate(action.payload);
    default:
      return state;
  }
};

export default tagsReducer;
