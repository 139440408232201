import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import MainTooltip from "./main-tooltip";

const MainTooltipContainer = () => {
  const BLOCK = "tooltip-overlay";
  const mainTooltip = useSelector(
    (store: RootStore) => store.commons.mainTooltip
  );

  return (
    <div className={`${BLOCK}`}>
      {mainTooltip.position && (
        <div className={`${BLOCK}__not-empty`}>
          <MainTooltip
            position={mainTooltip.position}
            elementDimensions={mainTooltip.elementDimensions}
            executeMouseLeaveEvent={mainTooltip.executeMouseLeaveEvent}
          >
            <>{mainTooltip.children}</>
          </MainTooltip>
        </div>
      )}
    </div>
  );
};

export default MainTooltipContainer;
