import { useState, useRef } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  loadEsgSearchResultsNextPage,
  loadEsgSearchResultsSort,
  searchEsgGovernanceMetricsTrack,
  searchEsgRatingsTrack,
} from "services/search/search.results.service";
import {
  createShortlist,
  removeShortlistItem,
} from "../../services/shortlist/shortlist.service";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import Table from "../shared/table/table";
import DocumentViewer from "components/document-viewer/document-viewer";
import { setSelectedReport } from "services/document-viewer/document-viewer.service";
import { setShowFilters } from "services/search/filters.service";
import classNames from "classnames";
import {
  comparisonItemTypes,
  ESGsortResults,
  ScrollOffset,
  SORT_ORDERS,
} from "utils/constants";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { setComparisonsSelectedList } from "services/saved-comparisons/saved-comparisons.service";

type props = {
  BLOCK: string;
};

const EsgSearchResults = ({ BLOCK }: props) => {
  const searchResults = useSelector((state: RootStore) => state.searchResults);
  const documentViewerState = useSelector(
    (state: RootStore) => state.documentViewer
  );
  const shortlistResults = useSelector((state: RootStore) => state.shortlist);
  const dispatch = useDispatch();
  const [sortInfo, setSortInfo] = useState<any>({
    sortOrder: ESGsortResults.sortOrder,
    sortBy: ESGsortResults.sortBy,
  });
  const tableContainerRef = useRef<any>(null);
  const comparisonState = useSelector(
    (state: RootStore) => state.comparisonState
  );

  const selectedESGReportIds = comparisonState.selectedList
    .filter((report) => report.reportId)
    .map((report) => report.reportId);
  const combinedESGReports = searchResults.esgResults.reduce(
    (prev: any, curr: any) => [...prev, ...curr.reports],
    []
  );

  const openReport = (report: any) => {
    dispatch(setShowFilters(false));
    dispatch(setSelectedReport(null));
    /* istanbul ignore next */
    if (
      !(
        documentViewerState.selectedReport &&
        documentViewerState.selectedReport.reportId === report.reportId
      )
    ) {
      dispatch(setSelectedReport(report));
    }
  };

  const openExternalReport = (url: any, binaryFetchType: number) => {
    dispatch(
      binaryFetchType
        ? searchEsgRatingsTrack(true)
        : searchEsgGovernanceMetricsTrack(true)
    );
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const getTableItems = (showKeywords: boolean) => {
    let reports: any[] = [];
    searchResults.esgResults.forEach((report) => {
      report.reports.forEach((report) =>
        reports.push({
          report: report,
          icon: searchResults.esgShortlists.includes(report.reportId),
        })
      );
    });
    return reports.map((report) => {
      let hits = 0;
      let hitsColumn = [];

      if (report.report.noOfHits) {
        hits = report.report.noOfHits.length;
      }

      if (hits > 0 && showKeywords) {
        hitsColumn.push({
          renderContent: () => (
            <FormattedMessage
              id={
                hits > 1
                  ? "search.results.table.keywords.plural"
                  : "search.results.table.keywords.singular"
              }
              values={{ count: hits }}
            />
          ),
          onClick: () => {
            openReport(report.report);
          },
        });
      }

      const payload = {
        shortlistId: 0,
        esgReport: {
          reportId: null,
        },
        sectionItem: {
          filingId: null,
          sectionId: null,
        },
        shortlistItemType: 0,
        contentKeywords: "",
        displayOrder: 0,
      };

      return {
        id: report.report.reportId,
        key: `esg-report-${report.report.reportId}`,
        columnItems: [
          {
            checkbox: {
              onChange: (selectedList: any) => selectESGreports(selectedList),
            },
            type: "esg",
          },
          {
            wrapperClassName: `${BLOCK}__column-icon`,
            icon: report.icon ? "remove" : "add",
            iconSize: 24,
            iconTooltip: (
              <Tooltip position={TooltipPosition.right}>
                <FormattedMessage
                  id={`shortlist.tooltip.${report.icon ? "remove" : "add"}`}
                />
              </Tooltip>
            ),
            type: "esg",
            onClick: (): void => {
              if (!report.icon) {
                dispatch(
                  createShortlist({
                    ...payload,
                    esgReport: report.report,
                    reportId: report.report.reportId,
                    shortlistId:
                      shortlistResults.results !== null &&
                      shortlistResults.results.shortlistId !== 0
                        ? shortlistResults.results.shortlistId
                        : 0,
                    contentKeywords: `${
                      searchResults.searchPayloadESG.contentText
                    }${searchResults.esgResults?.[0].highlightKeywords?.reduce(
                      (prev, curr: any) =>
                        prev.concat(` or ${curr.keywordTextContent}`),
                      ""
                    )}`,
                  })
                );
              } else {
                dispatch(
                  removeShortlistItem({
                    ...payload,
                    esgReport: report.report,
                    reportId: report.report.reportId,
                    shortlistId:
                      shortlistResults.results !== null &&
                      shortlistResults.results.shortlistId !== 0
                        ? shortlistResults.results.shortlistId
                        : 0,
                    contentKeywords: `${
                      searchResults.searchPayloadESG.contentText
                    }${searchResults.esgResults?.[0].highlightKeywords?.reduce(
                      (prev, curr: any) =>
                        prev.concat(` or ${curr.keywordTextContent}`),
                      ""
                    )}`,
                  })
                );
              }
            },
          },
          {
            text: report.report.companyName,
            wrapperClassName: `${BLOCK}__column-company-name`,
            onClick: () => openReport(report.report),
          },
          {
            text: report.report.reportTitle,
            icon: "pdf",
            type: "esg",
            iconSize: 16,
            wrapperClassName: `${BLOCK}__column-report-title`,
            onClick: () => openReport(report.report),
          },
          {
            text: report.report.reportCompany
              ? report.report.reportCompany.ivaCompanyRating
                ? report.report.reportCompany.ivaCompanyRating
                : ""
              : "",
            wrapperClassName: report.report.reportCompany
              .ivaCompanyRatingReportUrl
              ? `${BLOCK}__column-report-rating`
              : `${BLOCK}__column-report-rating-disable`,
            onClick: () => {
              if (report.report.reportCompany.ivaCompanyRatingReportUrl)
                openExternalReport(
                  report.report.reportCompany.ivaCompanyRatingReportUrl,
                  1
                );
            },
            textTooltip: report.report.reportCompany
              .ivaCompanyRatingReportUrl && (
              <Tooltip position={TooltipPosition.top}>
                <span>
                  <FormattedMessage id={`search.esg.rating.report`} />
                </span>
              </Tooltip>
            ),
          },
          {
            text: report.report.reportCompany
              ? report.report.reportCompany.corpGovernanceScore
                ? report.report.reportCompany.corpGovernanceScore.toFixed(1)
                : ""
              : "",
            wrapperClassName: report.report.reportCompany
              .corpGovernanceReportUrl
              ? `${BLOCK}__column-report-score`
              : `${BLOCK}__column-report-score-disable`,
            onClick: () => {
              if (report.report.reportCompany.corpGovernanceReportUrl)
                openExternalReport(
                  report.report.reportCompany.corpGovernanceReportUrl,
                  0
                );
            },
            textTooltip: report.report.reportCompany
              .corpGovernanceReportUrl && (
              <Tooltip position={TooltipPosition.top}>
                <span>
                  <FormattedMessage id={`search.esg.rating.report`} />
                </span>
              </Tooltip>
            ),
          },
          {
            wrapperClassName: `${BLOCK}__column-document-type`,
            text: report.report.formTypeName,
            onClick: () => openReport(report.report),
          },
          {
            wrapperClassName: `${BLOCK}__column-period`,
            renderContent: () => getReportYears(report.report.reportYears),
            onClick: () => openReport(report.report),
          },
          ...hitsColumn,
        ],
      };
    });
  };

  const getReportYears = (years: number[]) => {
    let yearsFullText: any =
      years.length > 0
        ? years
            .map((y) => y.toString())
            .reduce((prev, current, index) =>
              index === 0 ? `${current}` : `${prev},${current}`
            )
        : "";
    return (
      <div className={`${BLOCK}__period-years-cell`}>
        <span className={classNames(`${BLOCK}__period-years-cell-text`)}>
          {years.length > 2
            ? `${yearsFullText.slice(0, 9)} +${years.length - 2}`
            : yearsFullText}

          {years.length > 2 && (
            <Tooltip position={TooltipPosition.top}>
              {yearsFullText.slice(10, yearsFullText.length)}
            </Tooltip>
          )}
        </span>
      </div>
    );
  };

  const getTableHeaders = (showKeywords: boolean) => {
    const keywords = [];
    if (showKeywords) {
      keywords.push({
        text: "search.results.keywords",
        isFormattedText: true,
      });
    }

    return [
      {
        checkbox: {
          onChange: (selectedList: any) => {
            let tempCurrentSelected = [...comparisonState.selectedList];
            if (selectedList.length === 0) {
              tempCurrentSelected = tempCurrentSelected.filter(
                (item) => !item.reportId
              );
            }

            selectESGreports(selectedList, tempCurrentSelected);
          },
          value:
            selectedESGReportIds.length > 0 &&
            combinedESGReports.every((report) =>
              selectedESGReportIds.includes(report.reportId)
            ),
        },
        type: "esg",
      },
      {
        icon: "add-all",
        iconSize: 24,
        type: "esg",
      },
      {
        text: "search.results.company",
        isFormattedText: true,
        sortId: "CompanyName",
      },
      {
        text: "search.results.title",
        isFormattedText: true,
        sortId: "ReportTitle",
      },
      {
        text: "search.results.esg-rating",
        isFormattedText: true,
        sortId: "IvaCompanyRating",
      },
      {
        text: "search.results.governance-metrics",
        isFormattedText: true,
        sortId: "CorpGovernanceScore",
      },
      {
        text: "search.results.document-type",
        isFormattedText: true,
        sortId: "FormTypeName",
      },
      {
        text: "search.results.period",
        isFormattedText: true,
        sortId: "ReportYear",
      },
      ...keywords,
    ];
  };

  const onScroll = (e: any) => {
    if (
      e.target.scrollHeight <=
        e.target.scrollTop + e.target.clientHeight + ScrollOffset &&
      !searchResults.esgNextPageLoading &&
      searchResults.esgResults &&
      searchResults.esgResults.length > 0
    ) {
      const pageInfo = searchResults.esgResults[0].pageInfo;
      const currentPageNumber =
        searchResults.esgResults[searchResults.esgResults.length - 1].pageInfo
          .pageNumber;
      if (currentPageNumber < pageInfo.totalPageCount) {
        fetchNextPage(currentPageNumber + 1);
      }
    }
  };

  const fetchNextPage = (pageNumber: number) => {
    const ESGpayload = {
      ...searchResults.searchPayloadESG,
      shortlistId: shortlistResults.results
        ? shortlistResults.results.shortlistId
        : 0,
      pageInfo: {
        pageNumber: pageNumber,
        pageSize: 30,
        sortBy: sortInfo.sortBy,
        sortOrder: sortInfo.sortOrder,
      },
    };

    dispatch(loadEsgSearchResultsNextPage(ESGpayload));
  };

  const mapSelectedItems = (item: any) =>
    item.id && !item.mapped
      ? {
          reportId: item.id,
          reportTitle: item.columnItems ? item.columnItems[3].text : "",
          comparisonItemType: comparisonItemTypes.ESG,
          contentKeywords: `${
            searchResults.searchPayloadESG.contentText
          }${searchResults.esgResults?.[0].highlightKeywords?.reduce(
            (prev, curr: any) => prev.concat(` or ${curr.keywordTextContent}`),
            ""
          )}`,
        }
      : item;

  const selectESGreports = (selectedList: any, currentSelected?: any) => {
    const mappedSelectedList = selectedList.map(mapSelectedItems);
    const newSelectedList = currentSelected
      ? [...mappedSelectedList, ...currentSelected]
      : mappedSelectedList;
    dispatch(setComparisonsSelectedList(newSelectedList));
  };

  const onSort = (sortId: string) => {
    let newSortOrder = sortInfo.sortOrder;

    if (sortId === sortInfo.sortBy) {
      newSortOrder =
        sortInfo.sortOrder === SORT_ORDERS.DESC
          ? SORT_ORDERS.ASC
          : SORT_ORDERS.DESC;
    } else {
      newSortOrder = SORT_ORDERS.DESC;
    }

    const ESGpayload = {
      ...searchResults.searchPayloadESG,
      shortlistId: shortlistResults.results
        ? shortlistResults.results.shortlistId
        : 0,
      pageInfo: {
        pageNumber: 1,
        pageSize: 30,
        sortBy: sortId,
        sortOrder: newSortOrder,
      },
    };

    setSortInfo({ sortBy: sortId, sortOrder: newSortOrder });
    dispatch(loadEsgSearchResultsSort(ESGpayload));
  };

  const showKeywords =
    searchResults.searchPayloadESG &&
    searchResults.searchPayloadESG.contentText &&
    searchResults.searchPayloadESG.contentText.trim()
      ? true
      : false;

  const tableItems = getTableItems(showKeywords);
  const tableHeaders = getTableHeaders(showKeywords);

  return (
    <>
      {!documentViewerState.fullscreen && (
        <div
          className={classNames(`${BLOCK}__results-container`, {
            [`${BLOCK}__results-container--show-document-viewer`]:
              documentViewerState.selectedReport,
          })}
          data-test="component-esg-search-results"
        >
          <div
            className={`${BLOCK}__results-summary`}
            data-test={`${BLOCK}__results-summary`}
          >
            <FormattedMessage
              id="search.results.table.title"
              values={{
                count: Intl.NumberFormat("en-US").format(
                  searchResults.esgResults.length > 0 &&
                    searchResults.esgResults[0].pageInfo
                    ? searchResults.esgResults[0].pageInfo.totalRecordCount
                    : 0
                ),
              }}
            />
          </div>

          <div
            className={`${BLOCK}__table-wrapper`}
            onScroll={onScroll}
            ref={tableContainerRef}
            data-test={`${BLOCK}__table-wrapper`}
            data-testid="table-wrapper"
          >
            <Table
              headerItems={tableHeaders}
              rowItems={tableItems}
              showSpinner={searchResults.esgNextPageLoading}
              selectedRow={documentViewerState.selectedReport?.reportId}
              sortBy={sortInfo.sortBy}
              sortOrder={sortInfo.sortOrder}
              onSort={onSort}
              className={`${BLOCK}__table`}
              defaultSelectedItems={comparisonState.selectedList.map(
                (item: any) =>
                  item.comparisonItemType === comparisonItemTypes.ESG
                    ? {
                        id: item.reportId,
                        mapped: true,
                        ...item,
                      }
                    : item
              )}
              showRequestUploadMsg={true}
            />
          </div>
        </div>
      )}
      {documentViewerState.selectedReport && (
        <DocumentViewer report={documentViewerState.selectedReport} />
      )}
    </>
  );
};

export default EsgSearchResults;
