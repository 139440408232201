import TileEmptyContent from "components/insight-tile/tile-empty-content";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  INSIGHT_BENCHMARK_TYPE,
  costChartPeerIndustryMetricsType,
  costChartMetricDataDetailedType,
} from "utils/constants";
import classNames from "classnames";
import Popover from "components/shared/popover/popover";
import Parser from "html-react-parser";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";

type Props = {
  data:
    | costChartPeerIndustryMetricsType[]
    | MetricData[]
    | costChartMetricDataDetailedType[]
    | any;
  currentInsightView?: number;
  isDetailedView?: boolean;
};

type MetricData = {
  metricKey: string;
  metricName: string;
  metricValue: string;
  metricTooltip?: AssociatedMetricTooltips[] | undefined;
};

const CostChart = ({ data, currentInsightView, isDetailedView }: Props) => {
  const BLOCK = "cost-chart";
  const hasMissingValue =
    currentInsightView === INSIGHT_BENCHMARK_TYPE.COMPANY
      ? data.some((metric: MetricData) => metric.metricValue === "*")
      : (currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
          isDetailedView !== true) ||
        currentInsightView === INSIGHT_BENCHMARK_TYPE.INDUSTRY
      ? data.some(
          (metric: costChartPeerIndustryMetricsType) =>
            metric.metricValueBase === "*" ||
            metric.metricValuePeerIndustry === "*"
        )
      : data.some(
          (metric: costChartMetricDataDetailedType) =>
            metric.metricValueDirect === "*" ||
            metric.metricValueIndirect === "*"
        );
  const hasNodata =
    currentInsightView === INSIGHT_BENCHMARK_TYPE.COMPANY
      ? data.every((metric: MetricData) => metric.metricValue === "*")
      : (currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
          isDetailedView !== true) ||
        currentInsightView === INSIGHT_BENCHMARK_TYPE.INDUSTRY
      ? data.every(
          (metric: costChartPeerIndustryMetricsType) =>
            metric.metricValueBase === "*" &&
            metric.metricValuePeerIndustry === "*"
        )
      : data.every(
          (metric: costChartMetricDataDetailedType) =>
            metric.metricValueDirect === "*" &&
            metric.metricValueIndirect === "*"
        );
  if (hasNodata || data.length === 0) {
    return (     
        <TileEmptyContent />
    );
  }
  return (
    <div className={`${BLOCK}__wrapper`}>
      {currentInsightView === INSIGHT_BENCHMARK_TYPE.COMPANY ? (
        <div className={`${BLOCK}`}>
          {data.map((metricData: MetricData, index: number) => (
            <div className={`${BLOCK}__container`} key={index}>
              <div className={`${BLOCK}__container-metricName`}>
                <Popover
                  metricClassName={classNames(`${BLOCK}__container-metricName`)}
                  displayText={
                    <span>{Parser(metricData.metricName ?? "")}</span>
                  }
                  content={
                    metricData.metricTooltip &&
                    metricData.metricTooltip.length > 0
                      ? metricData.metricTooltip.filter(
                          (tooltip: AssociatedMetricTooltips) =>
                            tooltip.associatedMetric === metricData.metricKey
                        )
                      : []
                  }
                  buttonClassName={`${BLOCK}__container-metricName`}
                />
              </div>
              <div className={`${BLOCK}__container-metricValue`}>
                {metricData.metricValue}
              </div>
            </div>
          ))}
        </div>
      ) : (currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
          isDetailedView === false) ||
        currentInsightView === INSIGHT_BENCHMARK_TYPE.INDUSTRY ? (
        <div className={`${BLOCK}`}>
          {data.map((item: costChartPeerIndustryMetricsType, i: number) => (
            <div className={`${BLOCK}__container__peerIndustry`} key={i}>
              <Popover
                metricClassName={classNames(
                  `${BLOCK}__container__peerIndustry-metricName`
                )}
                displayText={<span>{Parser(item.metricName ?? "")}</span>}
                content={
                  item.metricTooltip && item.metricTooltip.length > 0
                    ? item.metricTooltip.filter(
                        (tooltip: AssociatedMetricTooltips) =>
                          tooltip.associatedMetric === item.metricKey
                      )
                    : []
                }
                buttonClassName={`${BLOCK}__container__peerIndustry-metricName`}
              />
              <div className={`${BLOCK}__container__peerIndustry-valueChart`}>
                <div
                  className={`${BLOCK}__container__peerIndustry-valueChart-name`}
                >
                  {item.baseCompanyName}
                </div>
                <div
                  className={`${BLOCK}__container__peerIndustry-valueChart-metricValue`}
                >
                  {item.metricValueBase}
                </div>
              </div>
              <div className={`${BLOCK}__container__peerIndustry-valueChart`}>
                <div
                  className={`${BLOCK}__container__peerIndustry-valueChart-name`}
                >
                  {item.peerIndustry}
                </div>
                <div
                  className={`${BLOCK}__container__peerIndustry-valueChart-metricValue`}
                >
                  {item.metricValuePeerIndustry}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={`${BLOCK}`}>
          <div className={`${BLOCK}__container__detailedPeer--grid-container`}>
            <div
              className={`${BLOCK}__container__detailedPeer--grid-container--item`}
            >
              {/* empty div used to place metric names correctly in the grid */}
            </div>
            <Popover
              metricClassName={classNames(
                `${BLOCK}__container__detailedPeer--grid-container--item`
              )}
              displayText={
                <span>{Parser(data[0].metricNameDirect ?? "")}</span>
              }
              content={data[0].metricTooltipDirect ?? []}
              buttonClassName={`${BLOCK}__container__detailedPeer--grid-container--item`}
            />
            <Popover
              metricClassName={classNames(
                `${BLOCK}__container__detailedPeer--grid-container--item`
              )}
              displayText={
                <span>{Parser(data[0].metricNameIndirect ?? "")}</span>
              }
              content={data[0].metricTooltipIndirect ?? []}
              buttonClassName={`${BLOCK}__container__detailedPeer--grid-container--item`}
            />
          </div>
          {data.map((item: costChartMetricDataDetailedType, i: number) => (
            <div
              className={`${BLOCK}__container__detailedPeer--grid-container`}
              key={i}
            >
              <div
                className={`${BLOCK}__container__detailedPeer--grid-container--item`}
              >
                {item.companyName}
              </div>
              <div
                className={`${BLOCK}__container__detailedPeer--grid-container--value`}
              >
                {item.metricValueDirect}
              </div>
              <div
                className={`${BLOCK}__container__detailedPeer--grid-container--value`}
              >
                {item.metricValueIndirect}
              </div>
            </div>
          ))}
        </div>
      )}
      {hasMissingValue && (
        <div className={`${BLOCK}__empty-values`}>
          <FormattedMessage id="no.data.available" />
        </div>
      )}
    </div>
  );
};

export default CostChart;
