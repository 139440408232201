import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Checkbox from "components/shared/checkbox/checkbox";
import Icon from "components/shared/icon/icon";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentViewerESGLayout,
  getReport,
  setFullscreen,
  setSelectedReport,
} from "services/document-viewer/document-viewer.service";
import {
  createShortlist,
  removeShortlistItem,
} from "services/shortlist/shortlist.service";
import { sendExportItem } from "services/download/download.service";
import { RootStore } from "services/store.service";
import Page from "./page";
import classNames from "classnames";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { mod } from "utils/functions";
import DocumentControls from "./document-controls";
import ComparisonNote from "components/notes/comparison-notes";
import {
  ACCESS_MODULES,
  comparisonItemTypes,
  comparisonPath,
  DEFAULT_ESG_EXPORT_PAYLOAD,
  reportPath,
  unauthorizedPath,
} from "utils/constants";
import { useLocation } from "react-router";
import { EsgSearchResultsReport } from "services/search/search.results.model";
import history from "utils/history";
import {
  ESGReportKeyword,
  ESGReportLayout,
} from "services/document-viewer/document-viewer.model";
import Compare from "components/compare/compare";
import {
  addtoComparison,
  setComparisonsSelectedList,
} from "services/saved-comparisons/saved-comparisons.service";
import PageSelector from "./page-selector";
import { ComparisonUncheckKeyword } from "services/saved-comparisons/saved-comparisons.model";
import { hexToRgbA } from "./overlay.helper";
import Button from "components/shared/button/button";
import {
  comparisonItemReferencedIdToUpdate,
  getComparisonItemTagsList,
  hideTagsTrack,
  showTagsTrack,
} from "services/tags/tags.service";
import { useTagContext } from "components/tags/tag.context";
import { TagCountDetail, TagMetadata } from "services/tags/tags.model";
import socket, { rooms } from "utils/socket";
import { fetchComparisonItems } from "services/saved-comparisons/saved-comparisons.api";
import DeleteModal from "components/shared/delete-modal/delete-modal";

type Props = {
  report: Partial<EsgSearchResultsReport> | null;
  fromShortlist?: boolean;
  fromComparison?: boolean;
  comparisonIsSaved?: boolean;
  isBaseReport?: boolean;
  isVisibleInComparison?: boolean;
  commentNoteId?: number;
  comparisonItemId?: number;
  showSaveComparison?: (value: boolean) => void;
  deleteFromComparison?: (value: number) => void;
  reportIndex?: number;
  handleCheckboxChange?: Function;
  comparisonSplit?: number;
  setReportList?: (value: any) => void;
  comparisonId?: number;
  actionsRef?: React.RefObject<HTMLDivElement>;
  comparisonKeywords?: string | null;
};

const DocumentViewer = ({
  report,
  fromShortlist,
  fromComparison,
  comparisonIsSaved,
  isBaseReport,
  isVisibleInComparison,
  commentNoteId,
  comparisonItemId,
  showSaveComparison,
  deleteFromComparison,
  reportIndex,
  handleCheckboxChange,
  comparisonSplit,
  setReportList,
  comparisonId,
  actionsRef,
  comparisonKeywords,
}: Props) => {
  const location = useLocation();
  const [currentReport, setCurrentReport] = useState(report);
  const [showCompareModal, setCompareModal] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState(0.25);
  const [rotation, setRotation] = useState(0);
  const [showHighlights, toggleShowHighlights] = useState(true);
  const [currentHightlight, setCurrentHightlight] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [scrollToHightlightPage, setScrollToHightlightPage] =
    useState<number>(0);
  const dispatch = useDispatch();
  const documentViewerState = useSelector(
    (state: RootStore) => state.documentViewer
  );
  const shortlistResults = useSelector((state: RootStore) => state.shortlist);
  const searchResults = useSelector((state: RootStore) => state.searchResults);
  const comparisonState = useSelector(
    (state: RootStore) => state.comparisonState
  );
  const [showDeleteComparisonModal, setShowDeleteComparisonModal] =
    useState<boolean>(false);
  const downloadState = useSelector((state: RootStore) => state.download);
  const [isInShortlist, setIsInShortlist] = useState<boolean>(false);
  const [showComparisonNote, setshowComparisonNote] = useState<boolean>(false);
  const [showCommentBlue, setCommentBlue] = useState<boolean>(false);
  const [documentTagList, setDocumentTagList] = useState<TagMetadata[]>([]);
  const documentRef = useRef(null);
  const BLOCK = "document-viewer";
  const uniqueHighlights: string[] = [];
  const reportRegex = new RegExp(`^${reportPath}`);
  const [fromURL, setFromURL] = useState<boolean>(
    reportRegex.test(location.pathname)
  );

  const constructPageId = () => {
    let suffix = "-";
    if (fromShortlist) {
      suffix = "-shortlist-";
    } else if (fromComparison && currentReport) {
      suffix = `-compare-${currentReport!.reportId}`;
    }
    return `ESG-document-page-${suffix}`;
  };
  const pageId = constructPageId();

  const [ESGLayout, setESGLayout] = useState<ESGReportLayout[]>([]);
  const [ESGKeywords, setESGKeywords] = useState<ESGReportKeyword[]>([]);
  const [loadingLayout, setLoadingLayout] = useState<boolean>(true);

  const [esgReports, setEsgReports] = useState<EsgSearchResultsReport[]>([]);
  const [prevReportInd, setPrevReportInd] = useState<number>(-1);
  const [nextReportInd, setNextReportInd] = useState<number>(-1);
  const [totalResults, setTotalResults] = useState<number>(0);
  const {
    comparisonItemIdToUpdate,
    setComparisonItemIdToUpdate,
    setTagPanelMaxMargin,
    setShowTagPanel,
    setCurrentTagsList,
    setSelectedTag,
    selectedTag,
    showAllTags,
    setShowAllTags,
    tagCountDetail,
    setTagCountDetail,
    setReferenceId,
  } = useTagContext();

  const tagsState = useSelector((state: RootStore) => state.tags);
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );

  const fetchDocumentTagList = (referenceId: number) => {
    getComparisonItemTagsList(referenceId).then((data: any) => {
      let tmpCurrentReport = JSON.parse(JSON.stringify(currentReport));
      if (data.length > 0) {
        setTagCountDetail((prevTagCountDetail: TagCountDetail[]) => {
          if (prevTagCountDetail.length > 0) {
            let indexForExistingReportTag = prevTagCountDetail?.findIndex(
              (prevTagDetail: TagCountDetail) =>
                prevTagDetail.referenceId === referenceId
            );
            if (indexForExistingReportTag !== -1) {
              prevTagCountDetail[indexForExistingReportTag].count++;
              return [...prevTagCountDetail];
            } else {
              return [
                ...prevTagCountDetail,
                ...[
                  {
                    count: data.length,
                    referenceId: referenceId,
                  },
                ],
              ];
            }
          } else {
            return [
              {
                count: data.length,
                referenceId: referenceId,
              },
            ];
          }
        });
      } else setShowTagPanel(false);
      tmpCurrentReport.tagsList = data;
      setCurrentReport(tmpCurrentReport);
      setDocumentTagList(refactorTagList(data));
      setCurrentTagsList && setCurrentTagsList(data);
      dispatch(comparisonItemReferencedIdToUpdate(-1));
    });
    setComparisonItemIdToUpdate(-1);
  };
  useEffect(() => {
    if (
      (comparisonItemId &&
        comparisonItemIdToUpdate !== 1 &&
        comparisonItemIdToUpdate === comparisonItemId) ||
      (tagsState.comparisonItemIdToUpdate !== -1 &&
        tagsState.comparisonItemIdToUpdate === comparisonItemId)
    ) {
      fetchDocumentTagList(comparisonItemId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonItemIdToUpdate, tagsState.comparisonItemIdToUpdate]);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.accessibleFeatures &&
      currentUser.accessibleFeatures.length > 0 &&
      !currentUser.accessibleFeatures.some(
        (feature) =>
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_All ||
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_Search ||
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_Comparison
      )
    ) {
      history.push(unauthorizedPath);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, currentUser]);

  useEffect(() => {
    setCommentBlue(commentNoteId !== 0 && commentNoteId !== undefined);
    if (!currentReport?.reportTitle) {
      const reportId = currentReport?.reportId
        ? currentReport.reportId
        : parseInt(location.pathname.replace(reportPath, ""));
      getReport(reportId).then((r) => setCurrentReport(r.data[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    adjustDocumentZoom(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonSplit]);

  useEffect(() => {
    setFromURL(reportRegex.test(location.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    setCommentBlue(commentNoteId !== 0 && commentNoteId !== undefined);
    if (comparisonItemId && fromComparison) {
      getComparisonItemTagsList(comparisonItemId).then((data: any) => {
        if (data.length > 0) {
          setTagCountDetail((prevTagCountDetail: TagCountDetail[]) => {
            if (prevTagCountDetail.length > 0) {
              let indexForExistingReportTag = prevTagCountDetail?.findIndex(
                (prevTagDetail: TagCountDetail) =>
                  prevTagDetail.referenceId === comparisonItemId
              );
              if (indexForExistingReportTag !== -1) {
                prevTagCountDetail[indexForExistingReportTag].count++;
                return [...prevTagCountDetail];
              } else {
                return [
                  {
                    count: data.length,
                    referenceId: comparisonItemId,
                  },
                ];
              }
            } else {
              return [
                ...prevTagCountDetail,
                ...[
                  {
                    count: data.length,
                    referenceId: comparisonItemId,
                  },
                ],
              ];
            }
          });
        }
        let tmpCurrentReport = JSON.parse(JSON.stringify(report));

        tmpCurrentReport.tagsList = data;
        setDocumentTagList(refactorTagList(data));
        setCurrentReport(tmpCurrentReport);
      });
    } else {
      setCurrentReport(report);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const refactorTagList = (taglistResponse: any[]): TagMetadata[] => {
    return taglistResponse?.reduce(
      (acc, curr) => [
        ...acc,
        ...curr.tagDetails.map((c: any) => ({
          ...c.tagMetadata,
          userDetails: c.userDetail,
          tagId: c.tagId,
          tagDetailId: c.tagDetailId,
          date: c.lastUpdatedDate,
        })),
      ],
      []
    );
  };

  useEffect(() => {
    if (currentReport?.reportId && !fromComparison) {
      setCurrentPageAndScroll(1);
      getDocumentViewerESGLayout(
        currentReport?.reportId,
        comparisonKeywords
          ? comparisonKeywords
          : fromShortlist
          ? currentReport.keywords && currentReport.keywords.length > 0
            ? currentReport.keywords[0].keywordTextContent
            : ""
          : fromComparison
          ? currentReport.contentKeywords
          : `${
              searchResults.searchPayloadESG.contentText
            }${searchResults.esgResults?.[0].highlightKeywords?.reduce(
              (prev, curr: any) =>
                prev.concat(` or ${curr.keywordTextContent}`),
              ""
            )}`
      ).then((layout) => {
        setESGLayout(layout.ESGLayout);
        setESGKeywords(layout.ESGKeywords);
        if (
          fromComparison &&
          isVisibleInComparison &&
          !isBaseReport &&
          layout.ESGKeywords.length > 0
        )
          setTagPanelMaxMargin(true);
        else if (
          fromComparison &&
          isVisibleInComparison &&
          !isBaseReport &&
          layout.ESGKeywords.length === 0
        )
          setTagPanelMaxMargin(false);
        setLoadingLayout(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport?.reportId, comparisonKeywords]);

  useEffect(() => {
    if (currentReport?.reportId && fromComparison) {
      setCurrentPageAndScroll(1);
      getDocumentViewerESGLayout(
        currentReport?.reportId,
        comparisonKeywords
          ? comparisonKeywords
          : fromShortlist
          ? currentReport.keywords && currentReport.keywords.length > 0
            ? currentReport.keywords[0].keywordTextContent
            : ""
          : fromComparison
          ? currentReport.contentKeywords
          : `${
              searchResults.searchPayloadESG.contentText
            }${searchResults.esgResults?.[0].highlightKeywords?.reduce(
              (prev, curr: any) =>
                prev.concat(` or ${curr.keywordTextContent}`),
              ""
            )}`
      ).then((layout) => {
        setESGLayout(layout.ESGLayout);
        setESGKeywords(layout.ESGKeywords);
        if (
          fromComparison &&
          isVisibleInComparison &&
          !isBaseReport &&
          layout.ESGKeywords.length > 0
        )
          setTagPanelMaxMargin(true);
        else if (
          fromComparison &&
          isVisibleInComparison &&
          !isBaseReport &&
          layout.ESGKeywords.length === 0
        )
          setTagPanelMaxMargin(false);
        setLoadingLayout(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport?.reportId, report, comparisonKeywords]);

  useEffect(() => {
    if (
      fromComparison &&
      isVisibleInComparison &&
      !isBaseReport &&
      ESGKeywords.length > 0
    )
      setTagPanelMaxMargin(true);
    else if (
      fromComparison &&
      isVisibleInComparison &&
      !isBaseReport &&
      ESGKeywords.length === 0
    )
      setTagPanelMaxMargin(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisibleInComparison]);

  useEffect(() => {
    if (isVisibleInComparison && fromComparison) scrollTo(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisibleInComparison]);

  useEffect(() => {
    if (ESGKeywords.length > 0) {
      const page = ESGKeywords[0].pageNumber;
      setScrollToHightlightPage(page);
      setCurrentPageAndScroll(page);
      setCurrentHightlight(0);
      adjustDocumentZoom(page);
    } else {
      adjustDocumentZoom(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ESGKeywords]);

  useEffect(() => {
    if (selectedTag && selectedTag?.referenceId === comparisonItemId) {
      const page = selectedTag!.page;
      setScrollToHightlightPage(page);
      setCurrentPageAndScroll(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag]);

  const updateTagList = (payload: any) => {
    if (payload.data.referenceId === comparisonItemId)
      fetchDocumentTagList(payload.data.referenceId);
  };

  useEffect(() => {
    if (comparisonItemId) {
      socket.on("tag.event.status", updateTagList);
      socket.join(rooms.tags(comparisonItemId));
    }
    return () => {
      dispatch(setFullscreen(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonItemId]);

  const updateNotes = (payload: any) => {
    if (payload.data.referenceId === comparisonItemId) {
      fetchComparisonItems(comparisonId!, {
        params: { execute: true },
      }).then((response: any) => {
        setReportList!(response.data.comparisonItems);
      });
    }
  };

  useEffect(() => {
    if (comparisonItemId) {
      socket.on("commentnote.update", updateNotes);
      socket.join(rooms.commentNote(comparisonItemId));

      return () => {
        socket.leave(rooms.commentNote(comparisonItemId));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonItemId]);

  useEffect(() => {
    setIsInShortlist(false);

    if (handleCheckboxChange) {
      toggleShowHighlights(
        !comparisonState.uncheckedKeywordReports ||
          (comparisonState.uncheckedKeywordReports &&
            comparisonState.uncheckedKeywordReports.comparisionItemIds &&
            !comparisonState.uncheckedKeywordReports.comparisionItemIds.some(
              (uncheckedKeyword: ComparisonUncheckKeyword) =>
                currentReport?.reportId === uncheckedKeyword.id
            ))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport]);

  useEffect(() => {
    if (
      currentReport &&
      currentReport.reportId &&
      searchResults.esgShortlists.includes(currentReport.reportId)
    ) {
      setIsInShortlist(true);
    } else if (isInShortlist) {
      setIsInShortlist(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults.esgShortlists, currentReport]);

  useEffect(() => {
    setTimeout(() => {
      scrollTo(currentPage);
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomLevel]);

  useEffect(() => {
    if (!fromURL && searchResults.esgResults) {
      //this functions adds to the list of esg reports when more loaded
      const newReports =
        searchResults.esgResults[searchResults.esgResults.length - 1].reports;
      setEsgReports(esgReports.concat(newReports));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults.esgResults?.length]);

  useEffect(() => {
    //this function will set the next and prev currentReport ind when selected currentReport changes
    if (!fromURL) {
      const currentReportID = currentReport?.reportId;
      const indexOfCurrentReport = esgReports.findIndex(
        (currentReport) => currentReport.reportId === currentReportID
      );

      if (esgReports.length - 1 !== indexOfCurrentReport) {
        //means there is a next report
        setNextReportInd(indexOfCurrentReport + 1);
      } else {
        //no next report
        setNextReportInd(-1);
      }

      if (indexOfCurrentReport - 1 >= 0) {
        //means there is a prev currentReport
        setPrevReportInd(indexOfCurrentReport - 1);
      } else {
        setPrevReportInd(-1);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport, esgReports.length]);

  useEffect(() => {
    //sets the total number of results
    if (!fromURL) {
      setTotalResults(esgReports.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esgReports.length]);

  const adjustDocumentZoom = (page: number) => {
    if (ESGLayout.length) {
      const container = documentRef.current as any;
      const documentWidth = ESGLayout.find(
        ({ pageNumber }) => pageNumber === page
      );
      if (documentWidth && container && container.clientWidth > 0)
        setZoomLevel((container.clientWidth - 64) / documentWidth.width);
    }
  };

  const payload = {
    shortlistId: 0,
    esgReport: {
      reportId: null,
    },
    sectionItem: {
      filingId: null,
      sectionId: null,
    },
    reportId: null,
    shortlistItemType: 0,
    contentKeywords: "",
    displayOrder: 0,
  };

  const onAddToShortlistHandler = () => {
    if (isInShortlist || fromShortlist) {
      dispatch(
        removeShortlistItem({
          ...payload,
          esgReport: currentReport,
          reportId: currentReport?.reportId,
          shortlistId:
            shortlistResults.results !== null &&
            shortlistResults.results.shortlistId !== 0
              ? shortlistResults.results.shortlistId
              : 0,
          contentKeywords:
            currentReport &&
            currentReport.keywords &&
            currentReport.keywords.length > 0
              ? currentReport.keywords[0].keywordTextContent
              : `${
                  searchResults.searchPayloadESG.contentText
                }${searchResults.esgResults?.[0].highlightKeywords?.reduce(
                  (prev, curr: any) =>
                    prev.concat(` or ${curr.keywordTextContent}`),
                  ""
                )}`,
        })
      );
      if (fromShortlist) dispatch(setSelectedReport(null));
    } else {
      dispatch(
        createShortlist({
          ...payload,
          esgReport: currentReport!,
          reportId: currentReport!.reportId,
          shortlistId:
            shortlistResults.results !== null &&
            shortlistResults.results.shortlistId !== 0
              ? shortlistResults.results.shortlistId
              : 0,
          contentKeywords:
            currentReport &&
            currentReport.keywords &&
            currentReport.keywords.length > 0
              ? currentReport.keywords[0].keywordTextContent
              : `${
                  searchResults.searchPayloadESG.contentText
                }${searchResults.esgResults?.[0].highlightKeywords?.reduce(
                  (prev, curr: any) =>
                    prev.concat(` or ${curr.keywordTextContent}`),
                  ""
                )}`,
        })
      );
    }
  };

  const setCurrentPageAndScroll = (page: number) => {
    if (page <= ESGLayout.length) {
      setCurrentPage(page);
      scrollTo(page);
    }
  };

  const scrollTo = (page: number) =>
    document.getElementById(`${pageId}${page}`)?.scrollIntoView();

  const scrollToHighlight = (offset: number) => {
    let nexthightlight, nextPage, currentPage;
    let current = currentHightlight;
    let dept = 0;
    do {
      nexthightlight = mod(current + offset, ESGKeywords.length);
      nextPage = ESGKeywords[nexthightlight].pageNumber;
      currentPage = ESGKeywords[current].pageNumber;
      dept++;
      current = nexthightlight;
    } while (nextPage === currentPage && dept < ESGKeywords.length);
    setCurrentPageAndScroll(nextPage);
    setCurrentHightlight(nexthightlight);
    setScrollToHightlightPage(nextPage);
  };

  const downloadESG = (e: any) => {
    e.preventDefault();
    if (currentReport) {
      dispatch(
        sendExportItem({
          ...DEFAULT_ESG_EXPORT_PAYLOAD,
          exportFileName: currentReport.reportTitle,
          exportReferenceId: currentReport.reportId,
          searchCriteria: {
            contentText: showHighlights
              ? currentReport &&
                currentReport.keywords &&
                currentReport.keywords.length > 0
                ? currentReport.keywords[0].keywordTextContent
                : `${
                    searchResults.searchPayloadESG.contentText
                  }${searchResults.esgResults?.[0].highlightKeywords?.reduce(
                    (prev, curr: any) =>
                      prev.concat(` or ${curr.keywordTextContent}`),
                    ""
                  )}`
              : "",
          },
          downloadTabOpen: downloadState.showDownloads,
        })
      );
    }
  };

  const openExternalReport = (url: any) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const goToNextReport = () => {
    const nextReport = esgReports[nextReportInd];
    setCurrentReport(nextReport);
    documentViewerState.fullscreen = true;
    dispatch(setFullscreen(true));
  };

  const goToPrevReport = () => {
    const prevReport = esgReports[prevReportInd];
    setCurrentReport(prevReport);
    documentViewerState.fullscreen = true;
    dispatch(setFullscreen(true));
  };

  const openExternalTab = (reportID: number | undefined) => {
    const url = `${process.env.PUBLIC_URL}${reportPath}${reportID}`;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const mapSelectedItems = (item: any) => ({
    reportId: item.reportId,
    reportTitle: item.reportTitle,
    comparisonItemType: comparisonItemTypes.ESG,
    contentKeywords: fromShortlist
      ? item.keywords && item.keywords.length > 0
        ? item.keywords[0].keywordTextContent
        : ""
      : fromComparison
      ? item.contentKeywords
      : `${
          searchResults.searchPayloadESG.contentText
        }${searchResults.esgResults?.[0].highlightKeywords?.reduce(
          (prev, curr: any) => prev.concat(` or ${curr.keywordTextContent}`),
          ""
        )}`,
  });

  const handleClose = () => {
    if (fromComparison && deleteFromComparison && reportIndex) {
      setTagCountDetail((prevTagCountDetail: TagCountDetail[]) => {
        if (prevTagCountDetail.length > 0) {
          return prevTagCountDetail.filter(
            (tagDetail: TagCountDetail) =>
              tagDetail.referenceId !== comparisonItemId
          );
        }
      });
      deleteFromComparison(reportIndex);
    } else {
      dispatch(setSelectedReport(null));
      if (fromURL) history.push("/search");
    }
    if (fromComparison) {
      setShowTagPanel(false);
      setCurrentTagsList([]);
    }
  };

  const submitCompare = (selected: any) => {
    if (currentReport) {
      if (selected.comparisonId)
        dispatch(
          addtoComparison(
            [mapSelectedItems(currentReport)],
            selected.comparisonId
          )
        );
      history.push(
        `${comparisonPath}${
          selected.comparisonId ? `/${selected.comparisonId}` : ""
        }`
      );
    }
  };

  const pageCount = new Set(ESGKeywords.map(({ pageNumber }) => pageNumber));
  return (
    <>
      {currentUser && (
        <div
          className={classNames(BLOCK, {
            [`${BLOCK}--fullscreen-esg`]:
              documentViewerState.fullscreen || fromURL,
            [`${BLOCK}--comparison`]: fromComparison,
          })}
          data-testid="document-viewer"
        >
          <div>
            <div className={`${BLOCK}__title`}>
              {isBaseReport && (
                <Icon
                  className="base-star"
                  name="star-filled"
                  width={18}
                  height={18}
                />
              )}
              <Icon name="pdf" width={18} height={18} />
              <span
                className={classNames(`${BLOCK}__document-name`, {
                  [`${BLOCK}__document-name-hidden`]: fromComparison,
                })}
              >
                {currentReport?.reportTitle}
              </span>
              {!fromURL ? (
                <div className={`${BLOCK}__actions`} ref={actionsRef}>
                  {fromComparison && (
                    <div
                      className={classNames(
                        `${BLOCK}__action ${BLOCK}__action__showall-tags`,
                        {
                          [`${BLOCK}__action__showall-tags--disabled`]:
                            (currentReport?.tagsList &&
                              currentReport.tagsList.length === 0) ||
                            !comparisonIsSaved,
                        }
                      )}
                    >
                      <Button
                        id="btn-externalTab"
                        dataTest="btn-externalTab"
                        className={classNames(`${BLOCK}__showall-tags`, {
                          [`${BLOCK}__showall-tags__toggle-on`]:
                            comparisonIsSaved &&
                            currentReport?.tagsList &&
                            currentReport.tagsList.length > 0 &&
                            comparisonItemId &&
                            !showAllTags.includes(comparisonItemId) &&
                            !(selectedTag?.referenceId === comparisonItemId),
                        })}
                        onClick={() => {
                          if (
                            comparisonIsSaved &&
                            currentReport?.tagsList &&
                            currentReport.tagsList.length > 0 &&
                            comparisonItemId &&
                            !showAllTags.includes(comparisonItemId) &&
                            !(selectedTag?.referenceId === comparisonItemId)
                          )
                            dispatch(hideTagsTrack(true));
                          else dispatch(showTagsTrack(true));

                          selectedTag
                            ? setSelectedTag(null)
                            : setShowAllTags(
                                comparisonItemId &&
                                  showAllTags.includes(comparisonItemId)
                                  ? showAllTags.filter(
                                      (item: any) => item !== comparisonItemId
                                    )
                                  : [...showAllTags, comparisonItemId]
                              );
                        }}
                        disabled={
                          !currentReport?.tagsList ||
                          currentReport.tagsList.length === 0
                        }
                      >
                        {currentReport?.tagsList &&
                          currentReport.tagsList.length !== 0 && (
                            <div
                              className={classNames(`${BLOCK}__toggle-circle`, {
                                [`${BLOCK}__toggle-circle-on`]:
                                  comparisonItemId &&
                                  !showAllTags.includes(comparisonItemId) &&
                                  !(
                                    selectedTag?.referenceId ===
                                    comparisonItemId
                                  ),
                              })}
                            ></div>
                          )}
                      </Button>
                      <FormattedMessage
                        id="tags.showall.toggle"
                        values={{
                          tags:
                            (currentReport && currentReport.tagsList?.length) ||
                            0,
                        }}
                      />
                    </div>
                  )}
                  {fromComparison && (
                    <div
                      className={classNames(`${BLOCK}__action`, {
                        [`${BLOCK}__action--left-tooltip`]:
                          fromComparison || documentViewerState.fullscreen,
                      })}
                    >
                      <Button
                        id="btn-externalTab"
                        dataTest="btn-externalTab"
                        className=""
                        onClick={() => {
                          setShowTagPanel && setShowTagPanel(true);
                          setReferenceId(comparisonItemId);
                          setCurrentTagsList &&
                            setCurrentTagsList(
                              currentReport && currentReport.tagsList
                                ? currentReport.tagsList
                                : []
                            );
                        }}
                        iconName="tag"
                        iconHeight={24}
                        iconWidth={24}
                        disabled={
                          !currentReport?.tagsList ||
                          currentReport.tagsList.length === 0
                        }
                      />
                      <Tooltip
                        position={
                          fromComparison || documentViewerState.fullscreen
                            ? TooltipPosition.left
                            : TooltipPosition.right
                        }
                      >
                        <FormattedMessage
                          id={
                            !currentReport?.tagsList ||
                            currentReport.tagsList.length === 0
                              ? "document-viewer.empty.tags.tooltip"
                              : "document-viewer.view.tags.tooltip"
                          }
                        />
                      </Tooltip>
                    </div>
                  )}
                  {!fromComparison && (
                    <>
                      <Button
                        id="btn-addToShortlist"
                        className={`${BLOCK}__action text-button`}
                        formattedText={
                          isInShortlist || fromShortlist
                            ? "document-viewer.remove-from-short-list"
                            : "document-viewer.add-to-short-list"
                        }
                        onClick={onAddToShortlistHandler}
                        iconName={
                          isInShortlist || fromShortlist ? "remove" : "add"
                        }
                        dataTest="btn-addToShortlist"
                      />
                      <div className={`${BLOCK}__action`}>
                        <Button
                          id="btn-download"
                          onClick={downloadESG}
                          className=""
                          iconName="download"
                          dataTest="btn-download"
                        />
                        <Tooltip position={TooltipPosition.right}>
                          <FormattedMessage id="document-viewer.download.tooltip" />
                        </Tooltip>
                      </div>
                    </>
                  )}

                  {!fromShortlist && !(fromComparison && !comparisonIsSaved) ? (
                    <PageSelector
                      BLOCK={BLOCK}
                      fromComparison={!!fromComparison}
                      report={currentReport!}
                    />
                  ) : null}

                  <div
                    className={classNames(`${BLOCK}__action`, {
                      [`${BLOCK}__action--left-tooltip`]:
                        fromComparison || documentViewerState.fullscreen,
                    })}
                  >
                    <Button
                      id="btn-externalTab"
                      className=""
                      onClick={() => openExternalTab(currentReport?.reportId)}
                      iconName="external-tab"
                      iconHeight={24}
                      iconWidth={24}
                      dataTest="btn-externalTab"
                    />
                    <Tooltip
                      position={
                        fromComparison || documentViewerState.fullscreen
                          ? TooltipPosition.left
                          : TooltipPosition.right
                      }
                    >
                      <FormattedMessage id="document-viewer.external-tab.tooltip" />
                    </Tooltip>
                  </div>

                  {documentViewerState.fullscreen || fromShortlist === true ? (
                    <>
                      <div
                        className={`${BLOCK}__action ${BLOCK}__action--left-tooltip`}
                      >
                        <Button
                          id="btn-compare"
                          className=""
                          onClick={() => {
                            if (currentReport)
                              dispatch(
                                setComparisonsSelectedList([
                                  mapSelectedItems(currentReport),
                                ])
                              );
                            setCompareModal(true);
                          }}
                          iconName="compare"
                          dataTest="btn-compare"
                        />

                        <Tooltip position={TooltipPosition.left}>
                          <FormattedMessage id="document-viewer.compare.tooltip" />
                        </Tooltip>
                      </div>
                      {fromShortlist && (
                        <div
                          className={classNames(`${BLOCK}__action`, {
                            [`${BLOCK}__action--left-tooltip`]: fromComparison,
                          })}
                        >
                          <Button
                            id="btn-close"
                            className=""
                            onClick={() => {
                              dispatch(setSelectedReport(null));
                              if (fromURL) history.push("/search");
                            }}
                            iconName="cross"
                            dataTest="btn-close"
                          />
                          <Tooltip position={TooltipPosition.left}>
                            <FormattedMessage id="document-viewer.close.tooltip" />
                          </Tooltip>
                        </div>
                      )}
                      {!fromShortlist && (
                        <div className={`${BLOCK}__halfpreview`}>
                          <Button
                            className=""
                            iconName="decrease"
                            onClick={() => dispatch(setFullscreen(false))}
                          />
                          <Tooltip position={TooltipPosition.left}>
                            <FormattedMessage
                              id={`document-viewer.half-preview.tooltip`}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {!fromShortlist && !fromComparison && (
                        <div className={`${BLOCK}__action`}>
                          <Button
                            id="btn-fullscreen"
                            className=""
                            iconName="increase"
                            onClick={() => dispatch(setFullscreen(true))}
                            dataTest="btn-fullscreen"
                          />
                          <Tooltip position={TooltipPosition.right}>
                            <FormattedMessage
                              id={`document-viewer.full-preview.tooltip`}
                            />
                          </Tooltip>
                        </div>
                      )}
                      {fromComparison && (
                        <div
                          className={`${BLOCK}__action ${BLOCK}__action--left-tooltip ${BLOCK}__shorlits-note-button`}
                        >
                          <Button
                            className={``}
                            onClick={() => {
                              if (comparisonIsSaved) {
                                setshowComparisonNote(true);
                              } else {
                                if (showSaveComparison)
                                  showSaveComparison(true);
                              }
                              setShowTagPanel(false);
                              setCurrentTagsList([]);
                            }}
                            iconName={
                              showCommentBlue
                                ? "commenting2"
                                : "commenting-plus"
                            }
                          />
                          <Tooltip position={TooltipPosition.left}>
                            <FormattedMessage
                              id={
                                !commentNoteId || commentNoteId === 0
                                  ? "note.add.button"
                                  : "note.view.button"
                              }
                            />
                          </Tooltip>
                        </div>
                      )}
                      {(!fromComparison ||
                        (fromComparison && !isBaseReport)) && (
                        <div
                          className={classNames(`${BLOCK}__action`, {
                            [`${BLOCK}__action--left-tooltip`]: fromComparison,
                          })}
                        >
                          <Button
                            id="btn-close"
                            className=""
                            onClick={
                              fromComparison
                                ? () => setShowDeleteComparisonModal(true)
                                : handleClose
                            }
                            iconName={fromComparison ? "remove1" : "cross"}
                            dataTest="docviewer-btn-close"
                          />
                          <Tooltip position={TooltipPosition.left}>
                            <FormattedMessage
                              id={`document-viewer.${
                                fromComparison ? "delete-report" : "close"
                              }.tooltip`}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className={`${BLOCK}__metrics`}>
              {!fromComparison &&
                currentReport &&
                currentReport.reportCompany &&
                currentReport.reportCompany.ivaCompanyRating !== null &&
                currentReport.reportCompany.ivaCompanyRating !== undefined &&
                currentReport.reportCompany.ivaCompanyRating!.length !== 0 && (
                  <>
                    {" "}
                    <FormattedMessage id="document-viewer.esg.rating" />
                    <div data-testid="esgValDoc">
                      <span className={`${BLOCK}__metrics__link`}>
                        {currentReport &&
                        currentReport.reportCompany &&
                        currentReport.reportCompany.ivaCompanyRating
                          ? currentReport!.reportCompany?.ivaCompanyRating
                          : ""}
                        {currentReport!.reportCompany
                          ?.ivaCompanyRatingReportUrl && (
                          <span
                            className={`${BLOCK}__metrics__icon`}
                            onClick={() =>
                              openExternalReport(
                                currentReport!.reportCompany
                                  ?.ivaCompanyRatingReportUrl
                              )
                            }
                          >
                            <Icon
                              name="external-link-single"
                              width={20}
                              height={20}
                            />
                          </span>
                        )}
                      </span>
                    </div>
                  </>
                )}
              {!fromComparison &&
                currentReport &&
                currentReport.reportCompany &&
                currentReport.reportCompany.corpGovernanceScore !== null && (
                  <>
                    <FormattedMessage id="document-viewer.governance.score" />
                    <div data-testid="govScoreDoc">
                      <span className={`${BLOCK}__metrics__link`}>
                        {currentReport.reportCompany.corpGovernanceScore
                          ? currentReport.reportCompany.corpGovernanceScore.toFixed(
                              1
                            ) //toString()
                          : ""}
                        {currentReport.reportCompany
                          ?.corpGovernanceReportUrl && (
                          <span
                            className={`${BLOCK}__metrics__icon`}
                            data-testid="open-external"
                            onClick={() => {
                              openExternalReport(
                                currentReport.reportCompany
                                  ?.corpGovernanceReportUrl
                              );
                            }}
                          >
                            <Icon
                              name="external-link-single"
                              width={20}
                              height={20}
                            />
                          </span>
                        )}
                      </span>
                    </div>
                  </>
                )}
            </div>
          </div>
          {ESGKeywords?.length > 0 && (
            <div className={`${BLOCK}__keywords`}>
              <Checkbox
                value={showHighlights}
                onChange={() => {
                  if (handleCheckboxChange) {
                    handleCheckboxChange(currentReport?.reportId);
                  }

                  toggleShowHighlights(!showHighlights);
                }}
              />
              <span className={`${BLOCK}__keyword-label`}>
                <FormattedMessage id="document-viewer.highlight-keywords" />
              </span>
              <div className={`${BLOCK}__highlights`} data-testid={`${BLOCK}__highlights`}>
                {!loadingLayout &&
                  ESGKeywords.map((highlight, index) => {
                    if (
                      uniqueHighlights.includes(highlight.keywordTextContent)
                    ) {
                      return null;
                    }

                    uniqueHighlights.push(highlight.keywordTextContent);
                    return (
                      <span
                        key={`highlight-${index}`}
                        className={`${BLOCK}__highlight`}
                        style={{
                          backgroundColor: highlight.colorCode!
                            ? hexToRgbA(highlight.colorCode!, "ESG")
                            : hexToRgbA("default"),
                        }}
                        onClick={() => {
                          setScrollToHightlightPage(highlight.pageNumber);
                          setCurrentPageAndScroll(highlight.pageNumber);
                          setCurrentHightlight(index);
                        }}
                      >
                        {highlight.keywordTextContent}
                      </span>
                    );
                  })}
              </div>
              <div className={`${BLOCK}__controls`}>
                <FormattedMessage
                  id={`search.results.table.keywords.${
                    (currentReport ? pageCount.size > 1 : true)
                      ? "plural"
                      : "singular"
                  }`}
                  values={{
                    count: pageCount.size,
                  }}
                />
                <Button
                  id="prev-highlight"
                  className={`${BLOCK}__control-action`}
                  onClick={() => scrollToHighlight(-1)}
                  iconName="chevron-left"
                  dataTest="prev-highlight"
                />
                <Button
                  id="next-highlight"
                  className={`${BLOCK}__control-action`}
                  onClick={() => scrollToHighlight(1)}
                  iconName="chevron-right"
                  dataTest="next-highlight"
                />
              </div>
            </div>
          )}

          {currentReport &&
          currentReport.esgReport &&
          comparisonState.loadingPages[currentReport.esgReport.reportId] ? (
            <Icon name="loading" className="loading-icon" width={50} />
          ) : (
            <>
              <div
                className={`${BLOCK}__document`}
                ref={documentRef}
                onScroll={(e: any) => {
                  const documentViewerCenter =
                    e.target.scrollTop + e.target.clientHeight / 2;
                  const visibleChild: any = Array.from(
                    e.target.firstChild.children
                  ).find(
                    (c: any) =>
                      c.offsetTop + c.offsetHeight >= documentViewerCenter
                  );
                  if (visibleChild && visibleChild.id) {
                    const newPage = visibleChild.id.replace(pageId, "");
                    if (newPage !== currentPage) setCurrentPage(newPage);
                  }
                }}
              >
                <div className={`${BLOCK}__pages`}>
                  {documentViewerState.fullscreen &&
                    totalResults > 1 &&
                    prevReportInd !== -1 && (
                      <div className={`${BLOCK}__leftArrow`}>
                        <Button
                          className=""
                          dataTest="document-viewer__leftArrow"
                          iconName="chevron-left"
                          iconHeight={50}
                          iconWidth={50}
                          onClick={() => goToPrevReport()}
                        />
                        <Tooltip position={TooltipPosition.right}>
                          <FormattedMessage id="document-viewer.prev-report.tooltip" />
                        </Tooltip>
                      </div>
                    )}
                  {documentViewerState.fullscreen &&
                    totalResults > 1 &&
                    nextReportInd !== -1 && (
                      <div className={`${BLOCK}__rightArrow`}>
                        <Button
                          className=""
                          dataTest="document-viewer__rightArrow"
                          iconName="chevron-right"
                          iconHeight={50}
                          iconWidth={50}
                          onClick={() => goToNextReport()}
                        />
                        <Tooltip position={TooltipPosition.left}>
                          <FormattedMessage id="document-viewer.next-report.tooltip" />
                        </Tooltip>
                      </div>
                    )}
                  {ESGLayout.map((metadata, index) => (
                    <Page
                      key={`document-viewer-page-${
                        currentReport?.reportId || 0
                      }-${index}`}
                      BLOCK={BLOCK}
                      pageId={pageId}
                      reportId={currentReport?.reportId || 0}
                      metadata={metadata}
                      zoomLevel={zoomLevel}
                      rotation={rotation}
                      highlights={
                        ESGKeywords
                          ? ESGKeywords.filter(
                              ({ pageNumber }) =>
                                pageNumber === metadata.pageNumber
                            )
                          : []
                      }
                      pageTags={documentTagList?.filter(
                        ({ page }) => metadata.pageNumber === page
                      )}
                      tagsList={currentReport?.tagsList ?? []}
                      showHighlights={showHighlights}
                      changePageFunction={setCurrentPage}
                      scrollToHightlightPage={scrollToHightlightPage}
                      setScrollToHightlightPage={setScrollToHightlightPage}
                      comparisonItemId={comparisonItemId ?? 0}
                      documentRef={documentRef}
                    />
                  ))}
                </div>
              </div>
              <DocumentControls
                currentPage={currentPage}
                changePageFunction={setCurrentPageAndScroll}
                zoom={zoomLevel}
                changeZoomFunction={(zoom: number) => setZoomLevel(zoom)}
                numPages={ESGLayout?.length}
                onRotate={() => setRotation((rotation - 90) % 360)}
              />
            </>
          )}
          {showCompareModal && (
            <Compare
              setShowCompareModal={setCompareModal}
              submitCompare={submitCompare}
            />
          )}
          {showComparisonNote && (
            <ComparisonNote
              setShowNote={setshowComparisonNote}
              comparisonNote={commentNoteId}
              setCommentBlue={setCommentBlue}
              comparisonItemId={comparisonItemId}
            />
          )}
          {showDeleteComparisonModal && (
            <DeleteModal
              title="comparison.remove-report.title"
              message={
                <>
                  <FormattedMessage id="comparison.remove.message" />
                  <span
                    style={{ fontWeight: 600 }}
                  >{` "${currentReport?.reportTitle}" `}</span>
                  <FormattedMessage id="comparison.remove-report.message" />
                </>
              }
              isRemove={true}
              handleCloseModal={() => {
                setShowDeleteComparisonModal(false);
              }}
              deleteItem={handleClose}
            />
          )}
        </div>
      )}
    </>
  );
};

export default DocumentViewer;
