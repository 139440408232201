import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import Popover from "components/shared/popover/popover";
import { Fragment } from "react";
import {
  AssociatedMetricTooltips,
  Metadata,
} from "services/dashboard/dashboard.model";
import { INSIGHT_BENCHMARK_TYPE, typeOfTimeHorizon } from "utils/constants";

type Props = {
  data: {
    metrics: Metadata;
    companyName: any;
    colors?: any;
    gradient?: any;
    benchmarkType: any;
    data: any[];
    allTooltips: AssociatedMetricTooltips[];
    isDetailView?: boolean;
    isTableView?: boolean;
    visData: any;
  };
};

const TimeChart = ({ data }: Props) => {
  const BLOCK = `TimeChart`;
  const isTableView = data.isTableView;
  const actualData = data.isTableView ? data.visData : data;
  const checkForText = (fieldValue: any) =>
    fieldValue
      ? isNaN(fieldValue.split("-")[0]) && isNaN(fieldValue.split("-")[1])
      : false;

  const checkForRepeatedAsterisk = (fieldValue: any) => {
    return fieldValue
      ? (fieldValue.split("-")[0]?.trim() === "*" &&
          fieldValue.split("-")[1]?.trim() === "*") ??
          false
      : false;
  };
  const getCircularGraphic = (name: any) => {
    let index = actualData.metrics.associatedMetricsDisplayNames.indexOf(
      name[0]
    );
    const iconName = (typeOfTimeHorizon as any)[index];
    return iconName;
  };

  const getAssociatedMetricTooltip = (displayName: string) =>
    actualData.allTooltips.filter(
      (associatedMetricsTooltip: AssociatedMetricTooltips) =>
        associatedMetricsTooltip.associatedMetricDisplayName === displayName
    );
  const noDataAvailable = actualData?.data?.filter((item: any) => {
    return (
      item.fieldValue === "*" ||
      item.fieldValue
        .split("-")
        .map((value: any) => value.trim())
        .includes("*")
    );
  });

  const renderHorizontalTable = () => {
    const labels = [
      ...new Set(actualData.data.map((item: any) => item.companyName)),
    ];

    return (
      <>
        <table
          className={classNames(`${BLOCK}__table_PeerIndustry`)}
          data-test="industry-table"
        >
          <thead>
            <tr>
              <td></td>
              {labels &&
                labels?.map((label: any, i: number) => {
                  return (
                    <td key={`td-${i}`}>
                      <span className={`${BLOCK}__label`}>{label}</span>
                    </td>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {actualData.metrics.associatedMetricsDisplayNames.map(
              (metric: string, i: number) => (
                <tr key={`data-row=${i}`}>
                  <td
                    key={`data-label-${i}`}
                    className={`${BLOCK}__tableCompanyName`}
                  >
                    <span
                      key={`data-metric-${i}`}
                      className={`${BLOCK}__metricLeft-container`}
                    >
                      <Popover
                        displayText={metric}
                        metricClassName={`${BLOCK}__metric--12`}
                        content={getAssociatedMetricTooltip(metric)}
                        buttonClassName={`${BLOCK}__label-button`}
                      />
                    </span>
                  </td>
                  {actualData.data
                    .filter((data: any) => data.fieldName === metric)
                    .map((data: any, index: number) => {
                      return (
                        <td key={`tablevalue-${index}`}>
                          <span
                            className={`${BLOCK}__tableFieldValue-industry`}
                          >
                            {data?.fieldValue
                              ? checkForRepeatedAsterisk(data.fieldValue)
                                ? "*"
                                : checkForText(data.fieldValue)
                                ? data.fieldValue.split("-")[0]
                                : data.fieldValue
                              : "*"}
                          </span>
                          {!checkForText(data?.fieldValue) && (
                            <span>{"  "} years</span>
                          )}
                        </td>
                      );
                    })}
                </tr>
              )
            )}
          </tbody>
        </table>
      </>
    );
  };

  const renderVerticalTable = () => {
    const labels = [
      ...new Set(actualData.data.map((item: any) => item.companyName)),
    ];

    return (
      <>
        <table
          className={classNames(`${BLOCK}__table_PeerIndustry`)}
          data-test="peer-benchmark-table"
        >
          <thead>
            <tr>
              <td></td>
              {actualData.metrics.associatedMetricsDisplayNames.map(
                (metric: string, i: number) => (
                  <td key={`data-label-${i}`} className={`${BLOCK}__label`}>
                    <span key={`data-metric-${i}`}>
                      <Popover
                        displayText={metric}
                        content={getAssociatedMetricTooltip(metric)}
                        metricClassName={""}
                        buttonClassName={`${BLOCK}__label-button`}
                      />
                    </span>
                  </td>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {labels.map((companyName: any, i: number) => {
              return (
                <tr key={`table-${i}`}>
                  <td className={`${BLOCK}__tableCompanyName`}>
                    {companyName}
                  </td>
                  {actualData.data
                    .filter((data: any) => data.companyName === companyName)
                    .map((data: any, index: number) => {
                      return (
                        <td key={`data-${index}`}>
                          <span className={`${BLOCK}__tableFieldValue`}>
                            {data?.fieldValue
                              ? checkForRepeatedAsterisk(data.fieldValue)
                                ? "*"
                                : checkForText(data.fieldValue)
                                ? data.fieldValue.split("-")[0]
                                : data.fieldValue
                              : "*"}
                          </span>
                          {!checkForText(data.fieldValue) && (
                            <span>{"  "} years</span>
                          )}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <Fragment>
      <div className={`${BLOCK}`} data-test="TimeChart">
        <div
          className={classNames(`${BLOCK}__chartContainer`, {
            [`${BLOCK}__table_PeerIndustry`]:
              isTableView &&
              (actualData.benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY ||
                actualData.benchmarkType ===
                  INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK),
            [`${BLOCK}__metric_column`]:
              isTableView &&
              !(
                actualData.benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY ||
                actualData.benchmarkType ===
                  INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK
              ),
            [`${BLOCK}__chartForPeer`]:
              actualData.benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY ||
              actualData.benchmarkType ===
                INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK,
            [`${BLOCK}__chartContainer--wide`]:
              isTableView &&
              data.benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY &&
              actualData?.data?.length !== 0,
          })}
          data-testid="score-container"
        >
          <>
            {actualData?.data?.length > 0 ? (
              <>
                {actualData.benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY ||
                actualData.benchmarkType ===
                  INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK ? (
                  isTableView ? (
                    actualData.benchmarkType ===
                    INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK ? (
                      renderVerticalTable()
                    ) : (
                      renderHorizontalTable()
                    )
                  ) : (
                    <div className={`${BLOCK}__PeerContainer`}>
                      {actualData.metrics.associatedMetricsDisplayNames.map(
                        (metric: string, i: number) => {
                          return (
                            <div
                              className={`${BLOCK}__metricView`}
                              key={`${BLOCK}-${i}`}
                            >
                              <div className={`${BLOCK}__metricTitle`}>
                                <span
                                  className={`${BLOCK}__metricLeft-container`}
                                >
                                  <Popover
                                    displayText={metric ? metric : ""}
                                    metricClassName={`${BLOCK}__metric--12 ${BLOCK}__metricName`}
                                    content={getAssociatedMetricTooltip(metric)}
                                    buttonClassName={`${BLOCK}__metric-button`}
                                  />
                                </span>
                              </div>
                              {actualData.data
                                .filter(
                                  (data: any) => data.fieldName === metric
                                )
                                .map((data: any, index: number) => {
                                  return (
                                    <div
                                      className={classNames(
                                        `${BLOCK}__metricsForPeers`,
                                        {
                                          [`${BLOCK}__metricsForPeers-fieldValue`]:
                                            data.fieldValue === "*" ||
                                            checkForRepeatedAsterisk(
                                              data.fieldValue
                                            ),
                                        }
                                      )}
                                      key={`metrics-${index}`}
                                    >
                                      <span className={`${BLOCK}__metricName`}>
                                        {data.companyName
                                          ? data.companyName
                                          : ""}
                                      </span>
                                      <span
                                        className={classNames(
                                          `${BLOCK}__fieldPeerValues`,
                                          {
                                            [`${BLOCK}__fieldTextValuesRight`]:
                                              checkForText(data.fieldValue) &&
                                              data.fieldValue !== "*" &&
                                              !checkForRepeatedAsterisk(
                                                data.fieldValue
                                              ),
                                            [`${BLOCK}__noData`]:
                                              data.fieldValue === "*" ||
                                              checkForRepeatedAsterisk(
                                                data.fieldValue
                                              ),
                                          }
                                        )}
                                      >
                                        {data.fieldValue
                                          ? checkForRepeatedAsterisk(
                                              data.fieldValue
                                            )
                                            ? "*"
                                            : checkForText(data.fieldValue)
                                            ? data.fieldValue.split("-")[0]
                                            : data.fieldValue
                                          : "*"}
                                        {!checkForText(data.fieldValue) && (
                                          <span
                                            className={`${BLOCK}__metricUnit`}
                                          >
                                            {" "}
                                            years
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        }
                      )}
                    </div>
                  )
                ) : (
                  <>
                    {actualData?.data.map((item: any, i: number) => (
                      <Fragment key={`score-detail-${i}`}>
                        <div
                          className={classNames(`${BLOCK}__metric_row`, {
                            [`${BLOCK}__metricTextRow`]:
                              !isTableView &&
                              isNaN(item.fieldValue.split("-")[0]) &&
                              item.fieldValue !== "*",
                            [`${BLOCK}__metricTextRowTable`]:
                              isTableView &&
                              isNaN(item.fieldValue.split("-")[0]),
                            [`${BLOCK}__metric_row--company`]:
                              isTableView &&
                              data.benchmarkType ===
                                INSIGHT_BENCHMARK_TYPE.COMPANY,
                          })}
                        >
                          <span
                            className={classNames(`${BLOCK}__metrics`, {
                              [`${BLOCK}__metricLeft`]: !isTableView,
                            })}
                            data-testid="score-name"
                          >
                            <span
                              className={classNames({
                                [`${BLOCK}__metricLeft-container`]:
                                  !isTableView,
                              })}
                            >
                              <Popover
                                displayText={item.fieldName}
                                content={
                                  item.associatedMetricsTooltips &&
                                  item.associatedMetricsTooltips
                                    ? item.associatedMetricsTooltips
                                    : []
                                }
                                metricClassName={""}
                              />
                            </span>
                          </span>

                          <div
                            className={classNames(`${BLOCK}__metricsValues`, {
                              [`${BLOCK}__metricsTableValues`]: isTableView,
                              [`${BLOCK}__NoDataField`]:
                                item?.fieldValue === "*",
                            })}
                          >
                            {!isTableView &&
                              !isNaN(item.fieldValue.split("-")[0]) && (
                                <div className={`${BLOCK}__circularIcon`}>
                                  <Icon
                                    name={getCircularGraphic(item.fieldName)}
                                    width={24}
                                    height={24}
                                  />
                                </div>
                              )}
                            <span
                              className={classNames(`${BLOCK}__fieldValues`, {
                                [`${BLOCK}__fieldTextValues`]: isNaN(
                                  item?.fieldValue?.split("-")[0]
                                ),
                              })}
                            >
                              {"   "}
                              {item.fieldValue
                                ? checkForRepeatedAsterisk(item.fieldValue)
                                  ? "*"
                                  : checkForText(item.fieldValue)
                                  ? item.fieldValue.split("-")[0]
                                  : item.fieldValue
                                : "*"}
                            </span>
                          </div>
                          {!isNaN(item.fieldValue.split("-")[0]) && (
                            <span className={`${BLOCK}__metricUnit`}>
                              years
                            </span>
                          )}
                        </div>
                      </Fragment>
                    ))}
                  </>
                )}
              </>
            ) : (
              <div className={"DashboardTile__noData"} data-test="No_data">
                <FormattedMessage id="insights.cdp.no-data" />
              </div>
            )}
          </>
        </div>
      </div>
      {noDataAvailable?.length > 0 ? (
        <div className={"DashboardTile__noDataAny"}>
          {" "}
          <FormattedMessage id="no.data.available" />
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default TimeChart;
