import { Fragment, useMemo } from "react";
import { BarRounded } from "@visx/shape";
import { Group } from "@visx/group";
import { AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { scaleBand, scaleLinear } from "@visx/scale";
import { withTooltip, defaultStyles } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";

import { ScaleSVG } from "@visx/responsive";
import {
  lightColor,
  MAX_WIDTH_STACKED_BAR_PERCENTAGE,
  CUSTOM_SPACING_STACKED_BAR__PERCENTAGE,
  grey,
  darkGrey,
} from "utils/constants";
import {
  AssociatedMetricTooltips,
  GroupedLegends,
  Legend,
} from "services/dashboard/dashboard.model";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import { getMarginBasedOnMax } from "utils/functions";

const axisBaseOffset = -0.25;
const axisOffsetMultiplier = -0.45;

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 50,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
  fontSize: 15,
};

type Props = {
  data: Legend[] | GroupedLegends[];
  name: string;
  width?: number;
  height?: number;
  axis?: { primary: string[]; secondary: string[] };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  showSpaceWhenEmpty?: boolean;
  benchmarkTileType?: number;
};

type GraphProps = {
  data: Legend[] | GroupedLegends[];
  name: string;
  width: number;
  height: number;
  axis?: { primary: string[]; secondary: string[] };
  margin?: { top: number; right: number; bottom: number; left: number };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  showSpaceWhenEmpty?: boolean;
  benchmarkTileType?: number;
};

const StackedBarChart = ({
  data,
  name,
  width,
  height,
  margin = { top: 15, right: 15, bottom: 85, left: 55 },
  showSpaceWhenEmpty = false,
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
  benchmarkTileType,
}: GraphProps &
  WithTooltipProvidedProps<{
    data: Legend | string | AssociatedMetricTooltips;
    index: number;
  }>) => {
  const dataset = data as GroupedLegends[];

  const datasetTotals = dataset.map((group) => ({
    total: group.separateBars
      ? group.legends.reduce((acc, curr) => Math.max(acc, curr.legendData), 0)
      : group.legends.reduce((acc, curr) => acc + curr.legendData, 0),
    groupName: group.groupName,
  }));

  const metricLegendPopover: {
    metric: string;
    tooltip: AssociatedMetricTooltips[];
    style: any;
  }[] = [];

  const metricValue: {
    value: number;
    style: any;
  }[] = [];

  const missingMetric: {
    value: number;
    style: any;
  }[] = [];

  let tooltipTimeout: number;
  margin.left = getMarginBasedOnMax(
    datasetTotals.reduce((acc, curr) => Math.max(acc, curr.total), 0)
  );
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: dataset.map((d) => d.groupName),
        padding: 0.3,
      }),
    [xMax, dataset]
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        nice: true,
        clamp: true,
        domain: [0, 10],
      }),
    [yMax]
  );

  const sectionWidth = (xMax - xScale.bandwidth()) / dataset.length;
  const sectionSpacing = xMax / dataset.length - sectionWidth;
  const maxValue = datasetTotals.reduce(
    (acc, curr) => Math.max(acc, curr.total),
    0
  );
  const valueRadius =
    sectionWidth / (dataset[0] && dataset[0].legends.length > 1 ? 10 : 5);
  const paddingTop = yMax - yScale(maxValue) + valueRadius * 2.5 - yMax;

  margin.top = margin.top + (paddingTop > 0 ? paddingTop : 0);

  return (
    <div className="limit-size" style={{ position: "relative" }}>
      <ScaleSVG width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <GridRows
            scale={yScale}
            width={xMax}
            height={yMax}
            stroke={lightColor}
            strokeDasharray="5,5"
          />
          <AxisLeft
            strokeWidth={0}
            scale={yScale}
            stroke={grey}
            tickStroke={lightColor}
            tickFormat={(tick: any) =>
              parseInt(tick.toFixed()).toLocaleString()
            }
            tickComponent={(tickProps) => {
              const valueLength = tickProps.formattedValue
                ? tickProps.formattedValue.length
                : 1;
              const dx = axisBaseOffset + valueLength * axisOffsetMultiplier;

              return (
                <text
                  fontFamily="Open Sans"
                  fontSize={12}
                  textAnchor="start"
                  dx={`${dx}em`}
                  dy={tickProps.dy}
                  x={tickProps.x}
                  y={tickProps.y}
                >
                  <tspan x={tickProps.x} dy="0.4em">
                    {tickProps.formattedValue}
                  </tspan>
                </text>
              );
            }}
          />
          {dataset.map((group, i) => {
            const total: any = datasetTotals.find(
              ({ groupName }) => groupName === group.groupName
            );
            let sectionXpos =
              i * (sectionSpacing + sectionWidth) + sectionSpacing / 2;

            const separateBarWidth = sectionWidth / (group.legends.length + 1);
            const separateBarSpacing = separateBarWidth / group.legends.length;
            let offsetY = -1;
            let offsetJ = -1;

            let maxWidth = sectionWidth;

            if (
              (sectionWidth * 100) / xMax > MAX_WIDTH_STACKED_BAR_PERCENTAGE &&
              !benchmarkTileType &&
              !group.separateBars
            ) {
              maxWidth = xMax * (MAX_WIDTH_STACKED_BAR_PERCENTAGE / 100);
              let spacing =
                xMax * (CUSTOM_SPACING_STACKED_BAR__PERCENTAGE / 100);
              let spacingQuantity = dataset.length - 1;
              let totalWidth =
                spacing * spacingQuantity + maxWidth * dataset.length;

              if (totalWidth < xMax - xScale.padding() * 2) {
                let startingPoint = xScale.padding() + (xMax - totalWidth) / 2;
                sectionXpos = startingPoint + i * (maxWidth + spacing);
              } else maxWidth = sectionWidth;
            }

            if (Array.isArray(group.description)) {
              metricLegendPopover.push({
                metric: group.groupName,
                tooltip: group.description,
                style: {
                  fontSize: Math.min(maxWidth / 6, 14),
                  width: sectionWidth + 8,
                  left: sectionXpos + margin.left - 4,
                  top: yMax + 5 + margin.top,
                },
              });

              group.legends.forEach((g: Legend, i: number) => {
                metricValue.push({
                  value: g.legendData ? g.legendData : null,
                  style: {
                    width: sectionWidth + 8,
                    left:
                      group.legends.length < 2
                        ? sectionXpos + margin.left - 4
                        : i === 1
                        ? sectionXpos + margin.left + 30
                        : sectionXpos + margin.left - 38,

                    top: g.legendData
                      ? margin.top - 45 + yScale(g.legendData ?? 0.1) ?? 0
                      : 190,
                  },
                });

                missingMetric.push({
                  value: g.legendData ? g.legendData : null,
                  style: {
                    width: sectionWidth + 8,
                    left:
                      group.legends.length < 2
                        ? sectionXpos + margin.left - 4
                        : i === 1
                        ? sectionXpos + margin.left + 30
                        : sectionXpos + margin.left - 38,
                    top: g.legendData
                      ? margin.top - 45 + yScale(g.legendData ?? 0.1) ?? 0
                      : 235,
                  },
                });
              });
            }

            return (
              <Fragment key={i}>
                {group.legends
                  .sort((d1, d2) => d1.legendOrder - d2.legendOrder)
                  .map((data, j) => {
                    let barX = sectionXpos;
                    let barWidth = maxWidth;

                    if (group.separateBars && group.legends.length > 1) {
                      barWidth =
                        separateBarWidth +
                        // Change the thickness of the bar based on the order
                        (j === 0
                          ? separateBarSpacing
                          : separateBarSpacing * -0.5);
                      offsetJ +=
                        parseFloat(data.legendData) > 0 || showSpaceWhenEmpty
                          ? 1
                          : 0;
                      barX =
                        sectionXpos +
                        offsetJ * (barWidth + separateBarSpacing) +
                        separateBarSpacing / 2 +
                        // Account the spacing for the new thickness
                        (j === 0 ? 0 : separateBarSpacing);
                      offsetY = 0;
                    }

                    let barHeight: number =
                      yMax -
                      yScale(
                        !data.legendData
                          ? 1
                          : data.legendData > 0
                          ? data.legendData
                          : 0.05
                      );
                    const barY = yMax - barHeight - offsetY;

                    offsetY += barHeight;

                    const mouseEvents = {
                      fillOpacity:
                        tooltipOpen && tooltipData?.index !== i * 10000 + j
                          ? 0.4
                          : 1,
                      onMouseLeave: () =>
                        (tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 100)),
                      onMouseMove: () => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        showTooltip({
                          tooltipData: {
                            data,
                            index: i * 10000 + j,
                          },
                          tooltipTop: barY - 50,
                          tooltipLeft: barX + barWidth / 2,
                        });
                      },
                    };

                    return barX >= 0 &&
                      barY >= 0 &&
                      barWidth >= 0 &&
                      barHeight >= 0 ? (
                      <Fragment key={`bar-${i}-${j}`}>
                        <pattern
                          id="GradientRepeat"
                          patternUnits="userSpaceOnUse"
                          width="8"
                          height="8"
                          patternTransform="rotate(135)"
                        >
                          <line
                            x1="0"
                            y="0"
                            x2="0"
                            y2="8"
                            stroke="#BBBCBC"
                            strokeWidth="1"
                          />
                        </pattern>
                        <BarRounded
                          top
                          radius={data.legendData ? 0 : 3}
                          key={`bar-${data.legendValue}-${i * 10000 + j}`}
                          x={barX}
                          y={barY}
                          width={barWidth}
                          height={barHeight}
                          stroke={data.legendData ? "unset" : darkGrey}
                          fill={
                            data.legendData
                              ? data.legendColor
                              : "url(#GradientRepeat)"
                          }
                          {...mouseEvents}
                        />

                        {/* Show metric value in circle */}
                        <circle
                          r={valueRadius}
                          cx={barX + barWidth / 2}
                          cy={barY - valueRadius * 1.65}
                          fill={data.legendData ? data.legendColor : darkGrey}
                          {...(data.legendData ? mouseEvents : null)}
                        />
                        <text
                          fill="#fff"
                          style={{
                            transform: "translate(-50%, -25%)",
                            transformBox: "fill-box",
                            fontWeight: "600",
                          }}
                          fontSize={`${valueRadius}px`}
                          x={barX + barWidth / 2}
                          y={barY - valueRadius}
                          {...(data.legendData ? mouseEvents : null)}
                        >
                          {data.legendData ? data.legendData : "*"}
                        </text>
                      </Fragment>
                    ) : null;
                  })}
              </Fragment>
            );
          })}
        </Group>
      </ScaleSVG>

      {/* Grouped legends using the popover component */}
      {metricLegendPopover.map((metric, index) => (
        <div
          className={`bar-chart-metric-label`}
          style={metric.style}
          key={`metric-label-${index}`}
        >
          <Popover
            displayText={metric.metric}
            content={metric.tooltip}
            metricClassName=""
            buttonClassName="button-parent-font"
          />
        </div>
      ))}
    </div>
  );
};

const BarGraph = ({
  data,
  name,
  axis,
  width = 1000,
  height = 1000,
  handleGraphElementClicked,
  showSpaceWhenEmpty,
  benchmarkTileType,
}: Props) => {
  const dataset = data as GroupedLegends[];
  const hasMissingValues = dataset.some((group: GroupedLegends) =>
    group.legends.some((data: any) => data.legendData === null)
  );
  const StackedBar = withTooltip<
    GraphProps,
    { data: Legend | string | AssociatedMetricTooltips; index: number }
  >(StackedBarChart);

  return (
    <>
      <div>
        {StackedBar({
          data,
          name,
          width,
          height,
          showSpaceWhenEmpty,
          benchmarkTileType,
        })}
      </div>
      <div className={"bar-graph-no-data"}>
        {hasMissingValues && (
          <div className={"DashboardTile__noDataAny"}>
            <FormattedMessage id="no.data.available" />
          </div>
        )}
      </div>
    </>
  );
};

export default BarGraph;
