import TrendChart from "components/visualizations/charts/trend.chart";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import {
  ESG_IVA_RATING_TILE_PEER_INDUSTRY,
  ESG_RATING_TILE,
  INSIGHT_BENCHMARK_TYPE,
  IVA_RATING_TREND,
} from "utils/constants";
import { mapTableChart, useTileContext } from "../tile.context";
import {
  convertDateFormatToMMYYY,
  handleMouseEnter,
  handleMouseLeave,
} from "utils/functions";
import { RootStore } from "services/store.service";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";
import TableChart from "components/visualizations/charts/table-chart";

const MSCIESGRating = () => {
  const {
    metadata,
    dataset,
    isTableViewActive,
    associatedMetrics,
    associatedMetricsDisplayNames,
    metadata: { isDetailedView, benchmarkType },
  } = useTileContext();
  const BLOCK = "msciESGRating";
  const dispatch = useDispatch();
  const mainTooltip = useSelector(
    (store: RootStore) => store.commons.mainTooltip
  );
  const mapTrendChartData = () => {
    let tmpData: any = [];
    dataset.forEach((d: InsightData) => {
      d.metrics.forEach((m: InsightMetricData) => {
        tmpData.push({
          companyName: d.companyName,
          description: m.metricDescription,
          fieldName: m.metricKey,
          fieldValue: m.metricValue,
          fieldValueIcon: m.metricValueIcon,
          isBaseCompany: d.isBaseCompany,
          tooltip: metadata.associatedMetricsTooltips.filter(
            (associatedMetricTooltips: AssociatedMetricTooltips) =>
              associatedMetricTooltips.associatedMetric === m.metricKey
          ),
          cik: d.globalCompanyId,
        });
      });
    });

    return {
      metrics: associatedMetrics,
      data: tmpData,
      isDetailedView: isDetailedView,
    };
  };

  const getCommaSeparatedData = (data: string) => (
    <span className={`${BLOCK}__data`}>
      {data &&
        data.split(",").map((item: any, index: any) => (
          <span key={`data-${index}`} className={`${BLOCK}__item`}>
            {item}
          </span>
        ))}
    </span>
  );

  const trendData = mapTrendChartData();
  const tileProps =
    benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY && isTableViewActive
      ? {
          associatedMetricsTooltip: metadata.associatedMetricsTooltips,
          associatedMetrics: associatedMetrics,
          isDetailedView: isDetailedView,
          axis: metadata.benchmarkMetadata.axis,
          data: {
            isTrendTableView: isTableViewActive,
            associatedMetricsDisplayNames: associatedMetricsDisplayNames,
            visData: trendData,
          },
        }
      : {
          associatedMetricsTooltip: metadata.associatedMetricsTooltips,
          associatedMetrics: associatedMetrics,
          isDetailedView: isDetailedView,
          data: trendData,
          axis: metadata.benchmarkMetadata.axis,
        };

  const getMSCIScoreTrend = (data: any) =>
    // eslint-disable-next-line
    data == 0
      ? IVA_RATING_TREND.NO_CHANGE
      : data > 0
      ? IVA_RATING_TREND.INCREASE
      : IVA_RATING_TREND.DECREASE;

  const getCustomValue = (label: any, value: any, d: any, i: number) => {
    let fieldValue =
      label.id === ESG_IVA_RATING_TILE_PEER_INDUSTRY.IVA_RATING_TREND && value
        ? getMSCIScoreTrend(value)
        : (label.id === ESG_IVA_RATING_TILE_PEER_INDUSTRY.MSCI_Risks ||
            label.id === ESG_IVA_RATING_TILE_PEER_INDUSTRY.MSCI_Strengths) &&
          value
        ? getCommaSeparatedData(value)
        : value;

    return label.id === ESG_IVA_RATING_TILE_PEER_INDUSTRY.IVA_COMPANY_RATING ? (
      <span
        className={classNames(`tableChart__text--top`)}
        onMouseEnter={(e) =>
          d.fieldValueToolTip &&
          handleMouseEnter(
            e,
            "span",
            d.fieldValueToolTip,
            "",
            dispatch,
            null,
            MainTooltipPosition.BottomMiddle
          )
        }
        onMouseLeave={(e) => {
          handleMouseLeave(
            e,
            dispatch,
            mainTooltip.isOverTooltip ? mainTooltip.isOverTooltip : false
          );
        }}
        key={`data-data-${i}`}
      >
        <span
          onMouseEnter={(e) =>
            d.fieldValueToolTip &&
            handleMouseEnter(
              e,
              "td",
              d.fieldValueToolTip,
              "",
              dispatch,
              null,
              MainTooltipPosition.BottomMiddle
            )
          }
          onMouseLeave={(e) => {
            handleMouseLeave(
              e,
              dispatch,
              mainTooltip.isOverTooltip ? mainTooltip.isOverTooltip : false
            );
          }}
          className={classNames(`tableChart__text--top`)}
        >
          {fieldValue ? fieldValue : "*"}
        </span>
      </span>
    ) : fieldValue ? (
      fieldValue
    ) : (
      "*"
    );
  };

  return (
    <div className={`${BLOCK}__container`}>
      <div className={`${BLOCK}__header__text__date`}>
        {trendData &&
        trendData.data.length > 0 &&
        trendData.data.filter(
          (item: any) => item.fieldName === ESG_RATING_TILE.IVA_RATING_DATE
        )[0]?.fieldValue
          ? convertDateFormatToMMYYY(
              new Date(
                trendData.data.filter(
                  (item: any) =>
                    item.fieldName === ESG_RATING_TILE.IVA_RATING_DATE
                )[0]?.fieldValue
              )
            ) + ", CCC - AAA"
          : ""}
      </div>
      {!isTableViewActive ||
      benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY ? (
        <TrendChart {...tileProps} />
      ) : (
        <TableChart
          data={mapTableChart(dataset, metadata)}
          currentInsightView={benchmarkType}
          getCustomValue={getCustomValue}
          leftAlignment={true}
          customTableClassName={`${BLOCK}__table`}
        />
      )}
    </div>
  );
};

export default MSCIESGRating;
