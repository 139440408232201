import { showTooltip } from "services/commons.service";
import { TooltipPosition } from "components/shared/tooltip/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";

type Props = {
  mainTooltip: any;
  peerTooltip: any;
  colors: any;
  gradient: boolean;
  isDetailedView?: boolean;
  tileType?: number;
  value?: number;
  peerIndValue: number;
} & GHGProps;

type GHGProps = {
  peerOrIndustryTooltip?: any;
  baseScorePos?: any;
  peerScorePos?: any;
};

const Scope3ProgressBar = ({
  mainTooltip,
  peerTooltip,
  peerIndValue,
  colors,
  isDetailedView,
  value,
  baseScorePos,
  peerScorePos,
}: Props) => {
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const dispatch = useDispatch();
  const colorCode = isDetailedView ? colors.split(",") : colors;

  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    position: TooltipPosition = TooltipPosition.TileBottomRight,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("div");
    if (!element) {
      return;
    }

    dispatch(
      showTooltip({
        children: children,
        position: position,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  const ParentdivForScope3 = {
    height: 1,
    width: "99.5%",
    border: "1px solid #E0E0E0",
    backgroundColor: "#E0E0E0",
    borderRadius: 2,
  };

  const ChilddivForScope3 = {
    height: 10,
    width:
      baseScorePos && baseScorePos < 0.5
        ? `${baseScorePos + (0.5 - baseScorePos)}%`
        : `${baseScorePos}%`,
    background: value
      ? `linear-gradient(270deg, ${colorCode[0]} 0%, ${colorCode[1]} 103.97%)`
      : "#E0E0E0",
    borderRadius: 4,
    top: "-4px",
  };

  //To show baseline company tick mark on chart

  const scope3Score = {
    height: "20px",
    width: "8px",
    background: "#9DD4CF",
    boxShadow: "inset 0px 0px 1px #002254, inset 0px 0px 5px #00ABAB",
    borderRadius: "3px",
    left: peerScorePos === 100 ? `${peerScorePos - 0.5}%` : `${peerScorePos}%`,
    top: "calc(50% - 20px/2)",
  };

  return (
    <div style={ParentdivForScope3} className={"DashboardTile__parentdiv"}>
      <div
        style={ChilddivForScope3}
        className={"DashboardTile__child"}
        onMouseEnter={(e) => {
          handleMouseEnter(e, mainTooltip, "DashboardTile__tooltip");
        }}
        onMouseLeave={handleMouseLeave}
      ></div>

      {/* Here the acceptable values are numbers */}
      {peerIndValue !== null ? (
        <div>
          <div
            style={scope3Score}
            className={"DashboardTile__score"}
            onMouseEnter={(e) => {
              if (peerTooltip)
                handleMouseEnter(e, peerTooltip, "DashboardTile__tooltip");
            }}
            onMouseLeave={handleMouseLeave}
          ></div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Scope3ProgressBar;
