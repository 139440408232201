import updateDataLayer from "./data-layer";
import TRACKING_ACTIONS from "./actions";
import track from "./track";

/** A redux middleware component to see if we're tracking that action
 * and send to Adobe for tracking
 */

let DEFER: { type: any; payload: any }[] = [];

export const AnalyticsMiddleware =
  (store: { getState: () => any }) =>
  (next: (arg0: any) => any) =>
  (action: { type: string; payload: any }) => {
    const returnValue = next(action);
    const state = store.getState();

    //Don't track un authenticated routes, to be set properly when authentication is set up completely with specific content of path string
    if (
      action.type === "@@router/LOCATION_CHANGE" &&
      action.payload.location.pathname.includes("authsuccess")
    ) {
      return returnValue;
    }

    // Always wait until user data is loaded, before tracking, since we need to send user info for every track
    if (!state.commons.currentUser) {
      if (TRACKING_ACTIONS[action.type]) {
        DEFER.push({ type: action.type, payload: action.payload });
      }
      return returnValue;
    }

    if (DEFER.length > 0) {
      DEFER.forEach((event) => {
        updateDataLayer(event.type, event.payload, state);
        track(event.type, event.payload, state);
      });
      DEFER = [];
    }

    if (TRACKING_ACTIONS[action.type] && action.payload) {
      updateDataLayer(action.type, action.payload, state);
      track(action.type, action.payload, state);
    }
    return returnValue;
  };
