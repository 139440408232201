import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import { useTileContext } from "../tile.context";
import TileEmptyContent from "../tile-empty-content";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import classNames from "classnames";
import Popover from "components/shared/popover/popover";
import { isNumeric } from "utils/functions";
import { Fragment } from "react";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";

const Waste = () => {
  const {
    dataset,
    metadata,
    metadata: {
      benchmarkType,
      isDetailedView,
      benchmarkMetadata,
      associatedMetricGroupTooltips,
    },
    isTableViewActive,
    associatedMetrics,
    associatedMetricsDisplayNames,
  } = useTileContext();

  const WASTE_UNITS: { ABSOLUTE: string; INTENSITY: string } = {
    ABSOLUTE: "t",
    INTENSITY: "t/$M",
  };

  const COLORS = {
    lightYellow: "#fffbea",
    lightBlue: "#f1f8fb",
    legendYellow: "#ffe785",
    legendBlue: "#a3cee0",
  };

  const legendColors = ["#ffe785", "#a3cee0"];
  if (
    dataset.every((d: InsightData) =>
      d.metrics.every((m: InsightMetricData) => !m.metricValue)
    )
  )
    return <TileEmptyContent />;

  const BLOCK = "waste";
  const hasEmptyValues = dataset.some((d: InsightData) =>
    d.metrics.some((m: InsightMetricData) => !m.metricValue)
  );

  const legendsToShow = [
    { legendName: "Waste Landfill", color: COLORS.legendYellow },
    { legendName: "Waste Incineration", color: COLORS.legendBlue },
  ];

  const legendsDisplayNames = associatedMetricsDisplayNames?.map(
    (displayNames, index: number) => {
      return {
        legendName: displayNames.groupValues[index],
        color: legendColors[index],
      };
    }
  );

  const legends =
    legendsDisplayNames && legendsDisplayNames.length > 0
      ? legendsDisplayNames
      : legendsToShow;

  const backgroundColor =
    benchmarkMetadata?.colorCodes && benchmarkMetadata?.colorCodes.length > 0
      ? benchmarkMetadata?.colorCodes
      : ["#FFFBEA", "#F1F8FB"];

  const getFormattedNumber = (num: any) => {
    const numberFormatter1 = new Intl.NumberFormat("en", {
      maximumFractionDigits: 2,
      useGrouping: true,
    });

    return Number.isNaN(num) || num === "*"
      ? num
      : numberFormatter1.format(+num);
  };
  const mapData = () => {
    //NOTE: METIC VALUE COULD HAVE 1 VALUE WHEN THERE IS NOT VALUES ASSOCIATED
    return dataset.map((d: InsightData) => {
      let tmpMetrics = associatedMetrics.map(
        (am: { groupName: string; groupValues: string[] }, amIndex: number) => {
          let tmpUnit =
            (Object.keys(WASTE_UNITS).find((k) =>
              am.groupName.toLowerCase().includes(k.toLowerCase())
            ) as keyof { ABSOLUTE: string; INTENSITY: string }) ?? "ABSOLUTE";

          return {
            metricKey: am.groupName,
            metricName: associatedMetricsDisplayNames[amIndex].groupName ?? "",
            metricValue: am.groupValues.map((gv: string) => {
              let currentMetricValue = d.metrics.find(
                (m: InsightMetricData) => m.metricKey === gv
              );

              return currentMetricValue &&
                currentMetricValue?.metricValue &&
                isNumeric(currentMetricValue?.metricValue)
                ? getFormattedNumber(currentMetricValue?.metricValue)
                : "*";
            }),
            metricTooltip: associatedMetricGroupTooltips
              ? associatedMetricGroupTooltips.filter(
                  ({ associatedMetricGroupName }: any) =>
                    associatedMetricGroupName ===
                      associatedMetricsDisplayNames[amIndex].groupName ?? ""
                )
              : [],
            metricUnit: WASTE_UNITS[tmpUnit],
          };
        }
      );
      return {
        ...d,
        metrics: tmpMetrics,
      };
    });
  };

  const graphic = (
    data: InsightData[],
    legends: { legendName: string; color: string }[]
  ) => {
    let BLOCK1 = "wasteChart";
    return (
      <div className={`${BLOCK1}__container`}>
        {!isDetailedView && (
          <div className={`${BLOCK1}__legend-section`}>
            {legends.map(
              (l: { legendName: string; color: string }, i: number) => (
                <div className={`${BLOCK1}__legend`} key={`legend-${i}`}>
                  <div
                    className={`${BLOCK1}__legend-icon`}
                    style={{ backgroundColor: l.color }}
                  ></div>
                  <span className={`${BLOCK1}__legend-text`}>
                    {l.legendName}
                  </span>
                </div>
              )
            )}
          </div>
        )}

        <div className={`${BLOCK1}__graphic-section`}>
          <div className={`${BLOCK1}__graphic-section-header`}>
            {benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY ? (
              <div
                className={classNames(`${BLOCK1}__graphic-section-col-3`)}
              ></div>
            ) : null}
            <div
              className={classNames(
                `${BLOCK1}__graphic-section-metrics-wrapper`,
                {
                  [`${BLOCK1}__graphic-section-metrics-wrapper-col-2`]:
                    benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY,
                }
              )}
            >
              {data[0].metrics.map((m: InsightMetricData, mIndex: number) => (
                <div
                  className={classNames(`${BLOCK1}__metric-name-container `, {
                    [`${BLOCK1}__metric-name-container-3-col`]:
                      benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY,
                  })}
                  key={`${mIndex}-metric-header`}
                >
                  <Popover
                    metricClassName={`${BLOCK1}__metric-name`}
                    displayText={m.metricName}
                    content={
                      associatedMetricGroupTooltips.length > 0
                        ? associatedMetricGroupTooltips.filter(
                            (tooltip: AssociatedMetricTooltips) =>
                              tooltip.associatedMetricGroupName === m.metricName
                          )
                        : []
                    }
                    buttonClassName={`${BLOCK}__popover-container`}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className={`${BLOCK1}__graphic-section-body`}>
            {isDetailedView && (
              <div className={`${BLOCK1}__legendDetailedPeer-section`}>
                <div
                  className={classNames(`${BLOCK1}__legendDetailedPeer-col-3`)}
                ></div>
                <div className={`${BLOCK1}__legendDetailedPeer-container`}>
                  <div
                    className={`${BLOCK1}__legendDetailedPeer-container-metric`}
                  >
                    {legends.map(
                      (l: { legendName: string; color: string }, i: number) => (
                        <div
                          className={`${BLOCK1}__legendDetailedPeer`}
                          key={`legend-${i}`}
                        >
                          <span
                            className={`${BLOCK1}__legendDetailedPeer-text`}
                          >
                            {l.legendName}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                  <div
                    className={`${BLOCK1}__legendDetailedPeer-container-metric`}
                  >
                    {legends.map(
                      (l: { legendName: string; color: string }, i: number) => (
                        <div
                          className={`${BLOCK1}__legendDetailedPeer`}
                          key={`legend-${i}`}
                        >
                          <span
                            className={`${BLOCK1}__legendDetailedPeer-text`}
                          >
                            {l.legendName}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            )}

            {data.map((d: InsightData, dIndex: number) => (
              <div
                className={classNames(
                  `${BLOCK1}__graphic-section-company-data`,
                  {
                    [`${BLOCK1}__graphic-section-company-data--dashed`]:
                      benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY,
                  }
                )}
                key={`${dIndex}-company-data`}
              >
                {benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY ? null : (
                  <div className={`${BLOCK1}__graphic-section-col-3`}>
                    <span>{d.companyName}</span>
                  </div>
                )}
                <div
                  className={classNames(
                    `${BLOCK1}__graphic-section-metrics-wrapper`,
                    {
                      [`${BLOCK1}__graphic-section-metrics-wrapper-col-2`]:
                        benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY,
                    }
                  )}
                >
                  {d.metrics.map((m: InsightMetricData, mIndex: number) => (
                    <div
                      className={classNames(
                        `${BLOCK1}__graphic-section-metric`,
                        {
                          [`${BLOCK1}__graphic-section-metric-empty`]:
                            m.metricValue.length === 1,
                          [`${BLOCK1}__graphic-section-metric-col-3`]:
                            m.metricValue.length === 1,
                        }
                      )}
                      key={`${mIndex}-${dIndex}-metric`}
                    >
                      {m.metricValue.map(
                        (v: string, submetricIndex: number) => (
                          <div
                            className={classNames(`${BLOCK1}__submetric`, {
                              [`${BLOCK1}__submetric-color-2`]:
                                submetricIndex > 0,
                              [`${BLOCK1}__submetric-empty`]:
                                m.metricValue.length === 1,
                            })}
                            style={{
                              backgroundColor: backgroundColor[submetricIndex],
                            }}
                            key={`${mIndex}-${submetricIndex}-subvalue`}
                          >
                            <span
                              className={classNames(`${BLOCK1}__metric-value`, {
                                [`${BLOCK1}__metric-value-empty`]:
                                  m.metricValue.length === 1,
                              })}
                            >
                              {v}
                            </span>
                            {m.metricValue.length > 1 && (
                              <span className={`${BLOCK1}__metric-unit`}>
                                {m.metricUnit && v !== "*" ? m.metricUnit : ""}
                              </span>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const table = (
    data: InsightData[],
    legends: { legendName: string; color: string }[]
  ) => {
    let BLOCK2 = "wasteTable";
    let companyNames = data.map((d: InsightData) => d.companyName);
    return (
      <>
        {benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY ? (
          <div className={`${BLOCK2}__container`}>
            {data.map((d: InsightData, dIndex: number) => (
              <Fragment key={`${dIndex}-company`}>
                {d.metrics.map((m: InsightMetricData, mIndex: number) => (
                  <div
                    className={`${BLOCK2}__company-section`}
                    key={`${dIndex}-${mIndex}-metric`}
                  >
                    <span className={`${BLOCK2}__submetric-container `}>
                      <Popover
                        displayText={m.metricName}
                        content={
                          associatedMetricGroupTooltips.length > 0
                            ? associatedMetricGroupTooltips.filter(
                                (tooltip: AssociatedMetricTooltips) =>
                                  tooltip.associatedMetricGroupName ===
                                  m.metricName
                              )
                            : []
                        }
                        metricClassName={`${BLOCK2}__submetric-text ${BLOCK2}__submetric-text--bold `}
                        buttonClassName={`${BLOCK}__popover-container`}
                      />
                    </span>
                    {legends.map(
                      (
                        l: { legendName: string; color: string },
                        legendInd: number
                      ) => (
                        <div
                          className={`${BLOCK2}__submetric-container`}
                          key={`${legendInd}-legend`}
                        >
                          <span className={`${BLOCK2}__submetric-text`}>
                            {l.legendName}
                          </span>
                          <span
                            className={`${BLOCK2}__submetric-text ${BLOCK2}__submetric-text--bold`}
                          >
                            {m.metricValue[legendInd] &&
                            m.metricValue[legendInd] !== "*"
                              ? `${m.metricValue[legendInd]} ${
                                  m.metricUnit ?? ""
                                }`
                              : "*"}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                ))}
              </Fragment>
            ))}
          </div>
        ) : (
          <div
            className={classNames(
              `${BLOCK2}__container ${BLOCK2}__container--horizontal`,
              {
                [`${BLOCK2}__container--vertical`]:
                  benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY,
              }
            )}
          >
            {data[0].metrics.map((m: InsightMetricData, mIndex: number) => (
              <div
                className={`${BLOCK2}__metric-col`}
                key={`${mIndex}-metricName`}
              >
                <div
                  className={`${BLOCK2}__metric-text ${BLOCK2}__metric-text--padding `}
                >
                  <Popover
                    metricClassName={`${BLOCK2}__metric-text`}
                    displayText={m.metricName}
                    content={
                      associatedMetricGroupTooltips.length > 0
                        ? associatedMetricGroupTooltips.filter(
                            (tooltip: AssociatedMetricTooltips) =>
                              tooltip.associatedMetricGroupName === m.metricName
                          )
                        : []
                    }
                    buttonClassName={`${BLOCK}__popover-container`}
                  />
                </div>

                {benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK ? (
                  <Fragment>
                    <div className={`${BLOCK2}__legend-container`}>
                      <div className={`${BLOCK2}__legend-text`}></div>
                      {legends.map(
                        (l: { legendName: string; color: string }) => (
                          <div className={`${BLOCK2}__legend-text`}>
                            {l.legendName}
                          </div>
                        )
                      )}
                    </div>
                    {data.map((d: InsightData, dIndex: number) => (
                      <div
                        className={`${BLOCK2}__company-section ${BLOCK2}__company-section--dashed ${BLOCK2}__company-section--align-center`}
                        key={`${dIndex}-data`}
                      >
                        <span
                          className={`${BLOCK2}__submetric-text ${BLOCK2}__submetric-text--w-30`}
                        >
                          {d.companyName}
                        </span>
                        {d.metrics.find(
                          (compMetric: InsightMetricData) =>
                            m.metricKey === compMetric.metricKey
                        )
                          ? d.metrics
                              .find(
                                (compMetric: InsightMetricData) =>
                                  m.metricKey === compMetric.metricKey
                              )
                              ?.metricValue.map((v: string, vIndex: number) => (
                                <span
                                  className={`${BLOCK2}__submetric-text ${BLOCK2}__submetric-text--bold ${BLOCK2}__submetric-text--center ${BLOCK2}__submetric-text--w-30`}
                                  key={`${vIndex}-${dIndex}-value`}
                                >
                                  {v !== "*" ? `${v} ${m.metricUnit}` : v}
                                </span>
                              ))
                          : null}
                      </div>
                    ))}
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className={`${BLOCK2}__legendIndustry-container`}>
                      <div
                        className={`${BLOCK2}__legendIndustry-text ${BLOCK2}-centerAligned`}
                      ></div>
                      {companyNames.map((companyName: string, i: number) => (
                        <Fragment>
                          <div
                            className={`${BLOCK2}__legendIndustry-text ${BLOCK2}-centerAligned ${BLOCK2}-lightColoredWeight`}
                            key={`${BLOCK2}-${i}`}
                          >
                            {companyName}
                          </div>
                        </Fragment>
                      ))}
                    </div>
                    {legends.map((legend, legendIndex: number) => (
                      <div className={`${BLOCK2}__legendIndustry-container`}>
                        <div
                          className={`${BLOCK2}__legendIndustry-text ${BLOCK2}-leftAligned ${BLOCK2}-lightColoredWeight`}
                        >
                          {legend.legendName}
                        </div>

                        {data.map((dMetrics, datIndex) => (
                          <Fragment>
                            <div
                              className={`${BLOCK2}__legendIndustry-text ${BLOCK2}-centerAligned ${BLOCK2}-darkColoredWeight`}
                              key={`text-${datIndex}`}
                            >
                              {dMetrics?.metrics[mIndex]?.metricValue[
                                legendIndex
                              ] &&
                              dMetrics?.metrics[mIndex]?.metricValue[
                                legendIndex
                              ] !== "*"
                                ? `${dMetrics?.metrics[mIndex]?.metricValue[legendIndex]} ${m.metricUnit}`
                                : `${dMetrics?.metrics[mIndex]?.metricValue[legendIndex]}`}
                            </div>
                          </Fragment>
                        ))}
                      </div>
                    ))}
                  </Fragment>
                )}
              </div>
            ))}
          </div>
        )}
      </>
    );
  };
  return (
    <div className={`${BLOCK}__container`} data-testid={`${BLOCK}__container`}>
      {isTableViewActive
        ? table(mapData(), legends)
        : graphic(mapData(), legends)}
      {hasEmptyValues && (
        <div className={`${BLOCK}__no-data-section`}>
          <span>
            <FormattedMessage id="no.data.available" />
          </span>
        </div>
      )}
    </div>
  );
};

export default Waste;
