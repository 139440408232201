import { Dispatch } from "react";
import { createAction } from "utils/redux.utils";
import {
  MemberFirm,
  ModuleAccessList,
  ModuleAccessModel,
  ModuleAccessState,
} from "./module-access.model";
import * as api from "./module-access.api";

export const ACTIONS = {
  MODULE_ACCESS_LOADING: "MODULE_ACCESS_LOADING",
  MODULE_ACCESS_RESULTS: "MODULE_ACCESS_RESULTS",
  API_ERROR: "API_ERROR",
};

export const moduleAccessLoading = createAction(ACTIONS.MODULE_ACCESS_LOADING);
export const moduleAccessList = createAction(ACTIONS.MODULE_ACCESS_RESULTS);
export const apiError = createAction(ACTIONS.API_ERROR);

export const loadModuleAccessData = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(moduleAccessLoading(true));
    const res = await api.getAppModuleAccessList();
    dispatch(moduleAccessList(res.data));
    dispatch(moduleAccessLoading(false));
  } catch (e) {
    dispatch(moduleAccessLoading(false));
  }
};

export const updateModuleAccessToMemberFirm =
  (memberFirm: MemberFirm, module: ModuleAccessList, isEnable: boolean) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(moduleAccessLoading(true));
      let updatedMemberFirmAccess = module.memberFirmLevelAccessibleFeatures
        .filter((x) => x.memberFirmCode === memberFirm.memberFirmCode)
        .map((x) => ({ ...x, isEnabled: isEnable }));

      const res = await api.updateModuleAccess(updatedMemberFirmAccess);
      if (res.data) {
        dispatch(loadModuleAccessData());
      } else {
        dispatch(moduleAccessLoading(false));
      }
    } catch (e) {
      dispatch(moduleAccessLoading(false));
    }
  };

const moduleAccessModel = new ModuleAccessModel();
const moduleAccessReducer = (
  state: ModuleAccessState = moduleAccessModel.initialState(),
  action: { type: string; payload: any }
): ModuleAccessState => {
  switch (action.type) {
    case ACTIONS.MODULE_ACCESS_LOADING:
      return moduleAccessModel.setLoading(action.payload);
    case ACTIONS.MODULE_ACCESS_RESULTS:
      return moduleAccessModel.setResults(action.payload);
    default:
      return state;
  }
};

export default moduleAccessReducer;
