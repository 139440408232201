import SavedShortListTable from "./saved-shortlist.table";
import emptyimg from "images/saved.shortlist-empty.png";
import { RootStore } from "services/store.service";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { loadsavedShortlist } from "services/saved-shortlist/saved-shortlist.service";
import { Link, useLocation } from "react-router-dom";
import {
  ACCESS_MODULES,
  DEFAULT_SHORTLISTS_PAYLOAD,
  unauthorizedPath,
} from "utils/constants";
import history from "utils/history";
import Icon from "components/shared/icon/icon";
import { submitFeedback } from "services/download/download.api";
import Feedback from "../feedback/feedback";
import Button from "components/shared/button/button";
import useAppInsightsCustomMetric from "appinsights/app-insights-custom-metrics";

const SavedShortList = () => {
  const dispatch = useDispatch();
  const savedShortListState = useSelector(
    (state: RootStore) => state.savedShorList
  );
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const location = useLocation();

  // App insights custom metric
  useAppInsightsCustomMetric(location.pathname);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.accessibleFeatures &&
      currentUser.accessibleFeatures.length > 0 &&
      !currentUser.accessibleFeatures.some(
        (feature) =>
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_All ||
          feature.applicationFeatureId ===
            ACCESS_MODULES.Feature_ESG_SavedShortlist
      )
    ) {
      history.push(unauthorizedPath);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, currentUser]);

  useEffect(() => {
    dispatch(loadsavedShortlist(DEFAULT_SHORTLISTS_PAYLOAD));
  }, [dispatch]);

  const BLOCK = "saved-shortlist";

  const submitFeedbackHandler = ({
    feedback,
    rating,
  }: {
    feedback: string;
    rating: number;
  }) => {
    /*istanbul ignore next*/
    submitFeedback({ feedback, rating }).then();
  };

  const SavedShorList =
    savedShortListState.SavedShortListFetch &&
    savedShortListState.SavedShortListFetch.shortlists
      ? savedShortListState.SavedShortListFetch.shortlists
      : [];
  return (
    <>
      {currentUser && (
        <div className={`${BLOCK}`} data-test="component-saved-shortlist">
          {savedShortListState.loading && (
            <div className={`${BLOCK}__empty-container`}>
              <Icon
                name={"loading"}
                width={50}
                height={50}
                className="loading-icon"
              />
            </div>
          )}
          {!savedShortListState.loading && (
            <>
              {!savedShortListState.loading && SavedShorList?.length > 0 && (
                <>
                  <header
                    className={`${BLOCK}__header`}
                    data-test="component-saved-shortlist-header"
                  >
                    <div
                      data-test="header-text"
                      style={{ width: "50%", display: "inherit" }}
                    >
                      <FormattedMessage id="saved-shortlist.header" />
                    </div>
                    <div className={`${BLOCK}__feedback-div`}>
                      <div className={`${BLOCK}__feedback-section`}>
                        <Button
                          className={`${BLOCK}__feedback-button button-primary-small`}
                          dataTest="feedback-button"
                          iconName="commenting"
                          iconHeight={24}
                          iconWidth={24}
                          formattedText="submit.feedback"
                          onClick={() => setShowFeedbackModal(true)}
                        />
                      </div>
                      {showFeedbackModal && (
                        <Feedback
                          data-test="feedback-button-modal"
                          setShowFeedbackModal={setShowFeedbackModal}
                          submitFeedback={submitFeedbackHandler}
                        />
                      )}
                    </div>
                  </header>
                  <div className={`${BLOCK}__table-wrapper`}>
                    <SavedShortListTable />
                  </div>
                </>
              )}
              {!savedShortListState.loading && SavedShorList?.length === 0 && (
                <>
                  <div className={`${BLOCK}__feedback-div`}>
                    <div className={`${BLOCK}__feedback-section`}>
                      <Button
                        className={`${BLOCK}__feedback-button button-primary-small`}
                        dataTest="feedback-button"
                        iconName="commenting"
                        iconHeight={24}
                        iconWidth={24}
                        formattedText="submit.feedback"
                        onClick={() => setShowFeedbackModal(true)}
                      />
                    </div>
                    {showFeedbackModal && (
                      <Feedback
                        data-test="feedback-button-modal"
                        setShowFeedbackModal={setShowFeedbackModal}
                        submitFeedback={submitFeedbackHandler}
                      />
                    )}
                  </div>
                  <div className={`${BLOCK}__empty`}>
                    <div className={`${BLOCK}__img`}>
                      <img className={""} src={emptyimg} alt={emptyimg} />
                    </div>
                    <div className={`${BLOCK}__emptytext`}>
                      <FormattedMessage id="saved-shortlist.emptySavedShortlist" />
                    </div>
                    <div className={`${BLOCK}__emptyredirect`}>
                      <FormattedMessage id="saved-shortlist.emptyredirect" />
                      &nbsp;
                      <Link className={`${BLOCK}__link`} to={"/search"}>
                        <FormattedMessage id="saved-shortlist.emptyredirect.link" />
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SavedShortList;
