import { Fragment, useEffect, useRef, useState } from "react";
import { overlay } from "utils/constants";
import {
  determineWord,
  getMinDistance,
  getSecondIndex,
  getText,
  isWordBreak,
  processCharacterRangeAsTextLines,
  renderKeywords,
  renderSelection,
  renderTag,
} from "./overlay.helper";
import {
  fetchDocumentViewerESGPageBinary,
  fetchDocumentViewerESGPageOCR,
} from "services/document-viewer/document-viewer.api";
import { setCurrentSelectionPage } from "services/document-viewer/document-viewer.service";
import { throttle } from "lodash";
import { addToastMessage } from "services/commons.service";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import OverlayMenu from "./overlay.menu";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useTagContext } from "components/tags/tag.context";
import { Tag, TagMetadata, TagSelected } from "services/tags/tags.model";

import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import moment from "moment";

const tagIconSize = 80;

declare global {
  interface Window {
    mousedown: boolean;
  }
}
window.mousedown = window.mousedown || false;

type Props = {
  BLOCK: string;
  zoomLevel: number;
  highlights: any[];
  showHighlights: boolean;
  scrollToHightlightPage: number;
  setScrollToHightlightPage: any;
  documentRef: any;
  metadata: any;
  pageImage: any;
  setPageImage: any;
  setLoading: any;
  reportId: number;
  selection: any;
  setSelection: any;
  comparisonItemId: number;
  pageTags: TagMetadata[];
  tagsList: Tag[];
};

const Overlay = ({
  BLOCK,
  zoomLevel,
  highlights,
  showHighlights,
  scrollToHightlightPage,
  setScrollToHightlightPage,
  documentRef,
  metadata,
  pageImage,
  setPageImage,
  setLoading,
  reportId,
  selection,
  setSelection,
  comparisonItemId,
  pageTags,
  tagsList,
}: Props) => {
  const dispatch = useDispatch();
  const documentViewerState = useSelector(
    (state: RootStore) => state.documentViewer
  );
  const overlayCanvas = useRef(null);
  const [pageOCR, setPageOCR] = useState<any>();
  const [copyMenuPosition, setCopyMenuPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [currentSelection, setCurrentSelectionState] = useState<any>(null);
  const [tagIconList, setIconList] = useState<any[]>([]);
  const [tagIconListGrouped, setTagIconListGrouped] = useState<any[]>([]);
  const [showOverlappingTags, setShowOverlappingTags] = useState<number>(-1);
  const {
    setShowCreateTag,
    setTagSelection,
    setReferenceId,
    setTagReportId,
    setTagCreated,
    tagCreated,
    tagSelection,
    comparisonId,
    setShowContinueModal,
    setShowTagPanel,
    setSelectedTag,
    setCurrentTagsList,
    selectedTag,
    showAllTags,
  } = useTagContext();

  /**
   * Scrolls to the first highlight of the page
   */
  useEffect(() => {
    if (
      pageOCR &&
      documentRef &&
      documentRef.current &&
      scrollToHightlightPage === metadata.pageNumber &&
      highlights.length > 0
    ) {
      const highlight = highlights.find(
        ({ pageNumber }) => pageNumber === scrollToHightlightPage
      );
      const firstChar = pageOCR[0].characters.find(
        ({ i }: any) => i === highlight.locations[0].offset[0]
      );
      if (firstChar)
        documentRef.current.scrollTo({
          top: documentRef.current.scrollTop + firstChar.y1 * zoomLevel,
          left: firstChar.x1 * zoomLevel,
        });
      setScrollToHightlightPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToHightlightPage, documentRef, pageOCR, zoomLevel]);

  useEffect(() => {
    if (
      currentSelection !== null &&
      documentViewerState.currentSelectionPage !== metadata.pageNumber
    ) {
      setCurrentSelectionState(null);
      setSelection(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentViewerState.currentSelectionPage]);

  /**
   * Fetches page image and OCR information of current page
   */
  const loadPageComponent = () => {
    if (!pageImage) {
      fetchDocumentViewerESGPageBinary(reportId, metadata.pageNumber).then(
        (response) => {
          setPageImage(URL.createObjectURL(new Blob([response])));
          setLoading(false);
        }
      );
      fetchDocumentViewerESGPageOCR(reportId, metadata.pageNumber).then(
        (response) => {
          setPageOCR(response.data);
        }
      );
    }
  };

  /**
   * Loads the default page with highlights
   */
  useEffect(() => {
    if (metadata.pageNumber === scrollToHightlightPage) loadPageComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToHightlightPage]);

  /**
   * Loads the page that is currently in view and fetches the OCR information
   */
  useEffect(() => {
    const abortController = new AbortController();
    const canvas = overlayCanvas.current as any;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadPageComponent();
        }
      },
      { threshold: [0, 1] }
    );

    if (canvas) observer.observe(canvas);

    return () => {
      if (canvas) observer.unobserve(canvas!);
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageImage]);

  /**
   * Binds click event when we have tags on a particular page
   */
  useEffect(() => {
    const canvas = overlayCanvas.current as any;
    if (canvas) {
      canvas.addEventListener("click", handleCanvasClick);
      setTagIconListGrouped(
        tagIconList.reduce((acc, curr) => {
          if (!acc) {
            return [
              {
                id: curr.id,
                page: curr.page,
                posY: curr.posY,
                tagList: [curr],
              },
            ];
          }
          let currentGroup = acc.find(
            (g: any, i: number) => g.page === curr.page && g.posY === curr.posY
          );
          if (currentGroup) {
            currentGroup.tagList.push(curr);
            return acc;
          }
          return [
            ...acc,
            {
              id: curr.id,
              page: curr.page,
              posY: curr.posY,
              tagList: [curr],
            },
          ];
        }, [])
      );
    }
    return () => {
      if (canvas) canvas.removeEventListener("click", handleCanvasClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagIconList]);

  /**
   * Determine which tag was clicked
   */
  const handleCanvasClick = (e: MouseEvent) => {
    const canvas = (overlayCanvas.current as any).getBoundingClientRect();
    const mousePosX = e.clientX - canvas.left;
    const mousePosY = e.clientY - canvas.top;

    const clickedTag = tagIconList.filter(
      (tag) =>
        tag.linesData.filter(
          (line: any) =>
            mousePosX > line.x1 * zoomLevel &&
            mousePosX < line.x2 * zoomLevel &&
            mousePosY > line.y1 * zoomLevel &&
            mousePosY < line.y2 * zoomLevel
        ).length > 0
    );

    clickedTag.length ? selectTagAction(clickedTag[0]) : setSelectedTag(null);
  };

  useEffect(() => {
    if (tagSelection === null && tagCreated) {
      setSelection(null);
      setTagCreated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagSelection, tagCreated]);

  /**
   * Highlights the keywords used in the search criteria
   */
  useEffect(() => {
    highlightSelectedText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHighlights, pageOCR, highlights, metadata, selection]);

  const renderHighlight = (
    selectionToHighlight: any,
    characters: any[],
    isTag: boolean = false
  ) => {
    let firstIndex = characters.findIndex(
      (ch: any) => ch.i === selectionToHighlight.firstIndex
    );
    let secondIndex = characters.findIndex(
      (ch: any) => ch.i === selectionToHighlight.lastIndex
    );

    if (characters[0] && characters[0].u.length) {
      secondIndex = getSecondIndex(characters, selectionToHighlight.lastIndex);
      secondIndex = secondIndex === -2 ? -1 : secondIndex;
    }
    //These statements account for selections over the page break
    if (firstIndex === -1 && secondIndex !== -1)
      firstIndex =
        selectionToHighlight.firstIndex < selectionToHighlight.lastIndex
          ? 0
          : characters.length - 1;
    if (firstIndex !== -1 && secondIndex === -1)
      secondIndex =
        selectionToHighlight.firstIndex < selectionToHighlight.lastIndex
          ? characters.length - 1
          : 0;

    if (firstIndex !== -1 && secondIndex !== -1) {
      const lesser = firstIndex <= secondIndex ? firstIndex : secondIndex;
      const greater = firstIndex <= secondIndex ? secondIndex : firstIndex;
      const characterRange = characters.slice(lesser, greater + 1);
      const lines = processCharacterRangeAsTextLines(characterRange);

      const canvas = overlayCanvas.current;
      if (isTag) {
        renderTag(lines, canvas);
        setIconList((it) => [
          ...it,
          {
            ...getTagIconPos(it, lines),
            page: selectionToHighlight.page,
            id: selectionToHighlight.tagId,
            linesData: lines,
            userInitials:
              selectionToHighlight.userDetails &&
              selectionToHighlight.userDetails.firstName &&
              selectionToHighlight.userDetails.lastName
                ? `${selectionToHighlight.userDetails.firstName.charAt(
                    0
                  )}${selectionToHighlight.userDetails.lastName.charAt(0)}`
                : "N/A",
            userFirstName:
              selectionToHighlight.userDetails &&
              selectionToHighlight.userDetails.firstName
                ? selectionToHighlight.userDetails.firstName
                : "N/A",
            userLastName:
              selectionToHighlight.userDetails &&
              selectionToHighlight.userDetails.lastName
                ? selectionToHighlight.userDetails.lastName
                : "N/A",
            tagDetailId: selectionToHighlight.tagDetailId,
          },
        ]);
      } else renderSelection(lines, canvas);
    }
  };

  const highlightSelectedText = () => {
    if (!pageOCR) return;
    const canvas: any = overlayCanvas.current;
    const context = canvas.getContext("2d");
    const characters = pageOCR[0].characters;

    context.canvas.width = metadata.width;
    context.canvas.height = metadata.height;
    setIconList([]);

    if (selection) {
      renderHighlight(selection, characters);
    }

    // Render tags
    if (pageTags && !showAllTags.includes(comparisonItemId)) {
      (selectedTag?.referenceId === comparisonItemId
        ? pageTags?.filter(
            (tag: TagMetadata) =>
              selectedTag?.tagId === tag.tagId &&
              (selectedTag.tagDetailId
                ? selectedTag.tagDetailId === tag.tagDetailId
                : true)
          )
        : pageTags
      )
        .sort((t1, t2) => moment(t1.date).diff(moment(t2.date)))
        .forEach((tag) => {
          renderHighlight(tag, characters, true);
        });
    }
    renderKeywords(highlights, showHighlights, canvas, characters, metadata);
  };

  const getMouseCoordinates = (e: any) => {
    const canvas: any = overlayCanvas.current;

    return {
      x: (e.clientX - canvas.getBoundingClientRect().left) / zoomLevel,
      y: (e.clientY - canvas.getBoundingClientRect().top) / zoomLevel,
    };
  };

  /**
   * Parses the selection to be used in the highlights and gets the text for copy/paste function
   */
  const setCurrentSelection = (_currentSelection: any) => {
    setCurrentSelectionState(_currentSelection);
    const { startingWord, endingWord } = _currentSelection;
    var f, l, p, offset;

    if (startingWord) {
      if (endingWord) {
        const direction =
          startingWord.start.character.i < endingWord.start.character.i;
        f = direction
          ? startingWord.start.character.i
          : endingWord.start.character.i;
        l = direction
          ? endingWord.end.character.i
          : startingWord.end.character.i;
        offset = direction
          ? endingWord.end.character.u.length
          : startingWord.end.character.u.length;
        p = direction ? startingWord.start.page : endingWord.start.page;
      } else {
        f = startingWord.start.character.i;
        l = startingWord.end.character.i;
        offset = startingWord.end.character.u.length;
        p = startingWord.start.page;
      }

      setSelection({
        firstIndex: f,
        lastIndex: l + (offset ? offset - 1 : 0),
        text: getText(f, l, pageOCR[0].characters),
        page: p,
      });
    }
  };

  const startSelection = (e: any) => {
    setCurrentSelectionState(null);
    dispatch(setCurrentSelectionPage(null));
    setSelection(null);
    renderSelection([], overlayCanvas.current);
    const coordinates = getMouseCoordinates(e);
    window.mousedown = true;

    const char = getClosestWord(coordinates.x, coordinates.y);
    const word = determineWord(char, pageOCR[0]);

    setCurrentSelection({
      startingWord: word && !isWordBreak(word) ? word : null,
      endingWord: null,
    });
    dispatch(setCurrentSelectionPage(metadata.pageNumber));
  };

  const getClosestWord = (x: number, y: number) => {
    var closest: {
      min: number | null;
      character: any;
      page: number | null;
    } = {
      min: null,
      page: null,
      character: null,
    };

    if (!pageOCR) {
      return;
    }

    const characters = pageOCR[0].characters;
    characters.forEach((character: any, i: number) => {
      const isInside =
        character.x1 < x &&
        x < character.x2 &&
        character.y1 < y &&
        y < character.y2;
      var distance = 0;

      if (!isInside) {
        distance = getMinDistance(character, { x, y });
      }

      if (
        (closest.min === null || distance < closest.min) &&
        distance < overlay.MAX_SELECTION_OFFSET
      ) {
        closest = {
          min: distance,
          character: character,
          page: metadata.pageNumber,
        };
      }
    });

    return closest;
  };

  const endSelection = (e: any) => {
    window.mousedown = false;
    if (!selection) return;
    const lastWord = pageOCR[0].characters.find(
      (c: any) => c.i === selection.lastIndex
    );
    setCopyMenuPosition({
      x: lastWord.x2 * zoomLevel,
      y: lastWord.y2 * zoomLevel,
    });
  };

  const highlightSelection = (e: any) => {
    const coordinates = getMouseCoordinates(e);
    mouseMove(coordinates);
  };

  const mouseMove = throttle((coordinates) => {
    if (window.mousedown) {
      var char = getClosestWord(coordinates.x, coordinates.y);
      var word = determineWord(char, pageOCR[0]);

      if (!word || isWordBreak(word)) {
        return;
      }

      const startDefined =
        currentSelection && currentSelection.startingWord !== null;
      setCurrentSelection({
        startingWord: startDefined ? currentSelection.startingWord : word,
        endingWord: startDefined ? word : null,
      });
    }
  }, 50);

  const copySelection = () => {
    navigator.clipboard.writeText(selection.text);
    dispatch(
      addToastMessage({
        description: <FormattedMessage id="overlay.copied-message" />,
      })
    );
  };
  const handleCtrlC = (e: any) => {
    const charCode = String.fromCharCode(e.which).toLocaleLowerCase();
    if ((e.ctrlKey || e.metaKey) && charCode === "c" && !!selection) {
      copySelection();
    }
  };

  const getCopyMenuPosition = () => {
    const overlaySize = { width: 170, height: 110 };
    const overlayReference = overlayCanvas.current as any;
    const pageReference = overlayReference?.parentElement;
    const parentContainer =
      pageReference.parentElement?.parentElement?.parentElement;
    const pageTop = parentContainer?.scrollTop - pageReference?.offsetTop;
    return parentContainer
      ? {
          x:
            copyMenuPosition.x + overlaySize.width >
            parentContainer.offsetWidth + parentContainer.scrollLeft
              ? parentContainer.offsetWidth +
                parentContainer.scrollLeft -
                overlaySize.width
              : copyMenuPosition.x,
          y:
            copyMenuPosition.y + overlaySize.height >
            parentContainer.offsetHeight + pageTop
              ? parentContainer.offsetHeight + pageTop - overlaySize.height
              : copyMenuPosition.y,
        }
      : copyMenuPosition;
  };

  const getTagIconPos = (prevTagIconList: any[], lines: any[]) => {
    let posYtmp = lines[0].y1;
    const overlappingIcon = prevTagIconList.filter(
      ({ posY }) =>
        posY <= posYtmp + tagIconSize && posY >= posYtmp - tagIconSize
    );

    return {
      posY: overlappingIcon.length ? overlappingIcon[0].posY : posYtmp,
      posX: 20 * zoomLevel * overlappingIcon.length + 1,
    };
  };

  const selectTagAction = (tag: any) => {
    setShowTagPanel(true);

    setCurrentTagsList(tagsList ? tagsList : []);
    setSelectedTag((tid: TagSelected) =>
      tid?.tagId === tag.id
        ? null
        : {
            tagId: tag.id,
            referenceId: comparisonItemId,
            page: tag.page,
            tagDetailId: tag.tagDetailId,
          }
    );
  };

  const multipleUserIcons = (tagGroup: any) => {
    const iconsToShow: number = 1;
    let moreIcon = null;
    if (tagGroup.tagList.length > iconsToShow) {
      moreIcon = (
        <span
          key={`${tagGroup.id}__group`}
          className={`${BLOCK}__overlay__user-icon ${BLOCK}__overlay__more-users-icon`}
          style={{
            width: `${tagIconSize * zoomLevel}px`,
            height: `${tagIconSize * zoomLevel}px`,
            position: "absolute",
            borderRadius: "100%",
            left: `${
              tagGroup.tagList[0].posX +
              (tagIconSize * zoomLevel) / 4 +
              20 * zoomLevel
            }px`,
            top: `${(tagGroup.tagList[0].posY - 5) * zoomLevel}px`,
            fontSize: `${tagIconSize * zoomLevel * 0.4}px`,
          }}
          onMouseEnter={() => {
            setShowOverlappingTags(tagGroup.tagList[0].tagDetailId);
          }}
          onMouseLeave={() => {
            setShowOverlappingTags(-1);
          }}
        >
          +{tagGroup.tagList.length - iconsToShow}
          {tagGroup.tagList[0].tagDetailId === showOverlappingTags && (
            <Tooltip position={TooltipPosition.right}>
              {tagGroup.tagList.slice(iconsToShow).map((u: any) => (
                <span
                  className={`${BLOCK}__overlay__user-name`}
                  onClick={() => {
                    setShowOverlappingTags(-1);
                    selectTagAction(u);
                  }}
                >{`${u.userFirstName} ${u.userLastName}`}</span>
              ))}
            </Tooltip>
          )}
        </span>
      );
    }
    return <>{moreIcon}</>;
  };

  return (
    <>
      <canvas
        onMouseDown={pageOCR ? startSelection : () => {}}
        onMouseUp={pageOCR ? endSelection : () => {}}
        onMouseMove={pageOCR ? highlightSelection : () => {}}
        onKeyDown={handleCtrlC}
        contentEditable={true}
        ref={overlayCanvas}
        className={`${BLOCK}__overlay`}
        data-testid={`${BLOCK}__overlay`}
      ></canvas>
      {!showAllTags.includes(comparisonItemId)
        ? (selectedTag?.referenceId === comparisonItemId
            ? tagIconListGrouped.filter(
                (tagIcon: any) => selectedTag?.tagId === tagIcon.id
              )
            : tagIconListGrouped
          ).map((tagGroup, i) =>
            metadata.pageNumber === tagGroup.page ? (
              <Fragment key={`tag-icon-${i}`}>
                <span
                  className={`tag-card__owner-icon tag-card__nameIcon tag-card__nameIcon ${BLOCK}__overlay-user-icon--tooltip`}
                  onClick={() => selectTagAction(tagGroup.tagList[0])}
                  key={`tag-${i}`}
                  style={{
                    width: `${tagIconSize * zoomLevel}px`,
                    height: `${tagIconSize * zoomLevel}px`,
                    position: "absolute",
                    borderRadius: "100%",
                    left: `${tagGroup.tagList[0].posX - 20 * zoomLevel}px`,
                    top: `${(tagGroup.tagList[0].posY - 5) * zoomLevel}px`,
                    fontSize: `${tagIconSize * zoomLevel * 0.4}px`,
                  }}
                >
                  {tagGroup.tagList[0].userInitials}
                  {
                    <Tooltip position={TooltipPosition.top}>
                      <>
                        {`${tagGroup.tagList[0].userFirstName} ${tagGroup.tagList[0].userLastName}`}
                      </>
                    </Tooltip>
                  }
                </span>
                {multipleUserIcons(tagGroup)}
              </Fragment>
            ) : null
          )
        : null}
      {!!selection ? (
        <OverlayMenu
          copySelection={copySelection}
          createTag={() => {
            setTagSelection(selection);
            comparisonItemId > 0
              ? setReferenceId(comparisonItemId)
              : setTagReportId(reportId);
            comparisonId > 0
              ? setShowCreateTag(true)
              : setShowContinueModal(true);
          }}
          BLOCK={BLOCK}
          position={getCopyMenuPosition()}
          show={!!selection && !window.mousedown}
          showTagOption={comparisonItemId > 0}
        />
      ) : null}
    </>
  );
};

export default Overlay;
