import TileEmptyContent from "components/insight-tile/tile-empty-content";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import {
  CARBON_CREDIT_NO_AVG_AVAILABLE_MESSAGE,
  EXCEPTIONAL_CARBON_CREDITS_METRIC_KEY,
} from "utils/constants";
import { checkAllValueMissing, getFormattedNumber } from "utils/functions";

type props = {
  chartData: any;
  associatedMetricsTooltip: AssociatedMetricTooltips[];
  isDetailedView: boolean;
};

const CarbonCreditChart = ({
  chartData,
  associatedMetricsTooltip,
  isDetailedView,
}: props) => {
  const BLOCK = "carbon-credit";
  const checkAllMetricValueMissing = chartData.every((metricData: any) =>
    metricData.metricsDetail.every((detail: any) =>
      detail.every((data: string) => data === "")
    )
  );
  if (checkAllMetricValueMissing) {
    return (
      <div>
        <TileEmptyContent />
      </div>
    );
  }
  /*When all the selected peers  have a blank value for 'Credit Origination' 
  or 'Credit Purchased', but have all other metric values, then within the 
  'Peer/Industry' section, will see:
  Message 'Refer to detailed peer view for additional details'*/

  const noAvgAvailable = (metricDetails: string[][]): boolean => {
    return metricDetails.some((metric: string[]) =>
      metric.some(
        (data: string) =>
          data.toLowerCase() ===
          CARBON_CREDIT_NO_AVG_AVAILABLE_MESSAGE.toLowerCase()
      )
    );
  };

  const getMetricTooltips = (metric: any, dataIndex: number, index: number) => {
    return associatedMetricsTooltip && associatedMetricsTooltip.length > 0
      ? associatedMetricsTooltip.filter(
          (tooltip: AssociatedMetricTooltips) =>
            tooltip.associatedMetric ===
            (dataIndex === 1 && index === 3 && !isDetailedView
              ? EXCEPTIONAL_CARBON_CREDITS_METRIC_KEY
              : metric.metricKey)
        )
      : [];
  };

  return (
    <div data-testid="carbon-credit-chart">
      {chartData &&
        chartData.map((data: any, index: number) => (
          <div
            key={`${index}__chart`}
            className={`${BLOCK}__wrapper`}
            data-testid={`${BLOCK}`}
          >
            {chartData.length > 1 && (
              <div className={`${BLOCK}__groupName`}>{data.groupName}</div>
            )}
            {!checkAllValueMissing(data?.metricsDetail) ? (
              <div className={`${BLOCK}`}>
                <div className={`${BLOCK}__header`}>
                  {data &&
                    data.metrics.map((metric: any, ind: number) => (
                      <div key={`${ind}__chartMetric`}>
                        <Popover
                          metricClassName={`${BLOCK}__header-name`}
                          displayText={
                            index === 1 && ind === 3 && !isDetailedView ? (
                              <FormattedMessage
                                id="carbon.credit.peer.industry.credit.retiered"
                                values={{ metricName: metric.metricName }}
                              />
                            ) : (
                              metric.metricName
                            )
                          }
                          content={getMetricTooltips(metric, index, ind)}
                        />
                      </div>
                    ))}
                </div>
                <div className={`${BLOCK}__header-border`}></div>

                {!noAvgAvailable(data.metricsDetail) &&
                  data.metricsDetail.map((metrics: any, i: number) => (
                    <div
                      key={`${i}__chartMetricDetail`}
                      className={`${BLOCK}__header`}
                    >
                      {metrics &&
                        metrics.map((metric: any, ind: number) => (
                          <div
                            key={`${ind}__chartMetricValue`}
                            className={`${BLOCK}__header-name`}
                          >
                            {metric
                              ? !isNaN(Number(metric))
                                ? getFormattedNumber(metric, 0, 1, true)
                                : metric
                              : ""}
                          </div>
                        ))}
                    </div>
                  ))}
                {noAvgAvailable(data.metricsDetail) && (
                  <div
                    className={`${BLOCK}__noAvgAvailable`}
                    data-testid="no-avg-available"
                  >
                    {CARBON_CREDIT_NO_AVG_AVAILABLE_MESSAGE}
                  </div>
                )}
              </div>
            ) : (
              <TileEmptyContent />
            )}
          </div>
        ))}
    </div>
  );
};

export default CarbonCreditChart;
