import ProgressBar from "./progress-bar";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { Fragment } from "react";
import classNames from "classnames";
import { METRIC_VALUES } from "utils/constants";
import Popover from "components/shared/popover/popover";
import Parser from "html-react-parser";
import TileEmptyContent from "components/insight-tile/tile-empty-content";
import Icon from "components/shared/icon/icon";

type Props = {
  data: any;
};

export type StructuredData = {
  isHeader: boolean;
  metricTitle: any;
  metricDescription: any;
  metricValues: any[];
};

const EnergyUndertaken = ({ data }: Props) => {
  const BLOCK = "EnergyUndertaken-tile";
  const isCompanyView = data.tileView !== 5 && data.tileView !== 6;
  const isDetailedView = data.isDetailedView;
  let hasNoData =
    data.data && data.data.length
      ? data.data.reduce(
          (acc: boolean, { companyName, globalCompanyId, ...d }: any) =>
            acc && Object.values(d).every((v) => v === null),
          true
        )
      : true;

  if (hasNoData) return <TileEmptyContent />;

  const hasEmptyValues = data.data.some((d: any) =>
    Object.values(d).some((v) => v === null || v === "*")
  );

  const getData = () => {
    let nonNumericData: StructuredData[] = [
      {
        isHeader: true,
        metricTitle: "",
        metricDescription: "",
        metricValues: [],
      },
    ];
    data.metrics.forEach((metric: any, index: number) => {
      const metricKey = metric.metricKey || metric.metric;
      let metricValues: any[] = [];
      let metricValuesTooltip: any[] = [];
      data.data.forEach((dataVal: any) => {
        const metricValue = dataVal[metricKey];
        const isYesOrNo =
          index !== data.metrics.length - 1
            ? (METRIC_VALUES as any)[metricValue]
            : undefined;
        if (index === 0) {
          nonNumericData[0].metricValues = [
            ...nonNumericData[0].metricValues,
            dataVal.companyName,
          ];
          metricValues.push(metricValue);
          metricValuesTooltip.push(dataVal.companyName);
        } else if (!metricValue) {
          metricValues.push("");
          metricValuesTooltip.push(dataVal.companyName);
        } else {
          hasNoData = false;
          metricValues.push(isYesOrNo || metricValue);
          metricValuesTooltip.push(dataVal.companyName);
        }
      });

      const metricDetails = {
        isHeader: false,
        metricTitle: metric.metric || "*",
        metricDescription: metric.description,
        metricValues: metricValues,
        metricValuesTooltip: metricValuesTooltip,
      };
      if (
        isNaN(metricValues[0]) ||
        (isNaN(metricValues[0]) && index !== data.metrics.length - 1) ||
        !hasNoData
      ) {
        nonNumericData.push(metricDetails);
      }
    });
    return [nonNumericData];
  };

  const getDetailedData = () => {
    const metricDetails: StructuredData[] = data.data.reduce(
      (prev: any, curr: any) => {
        curr = {
          isHeader: false,
          metricTitle: curr.companyName || "*",
          metricDescription: "",
          metricValues: data.metrics.map((metric: any) => {
            return curr[metric.metricKey];
          }),
          metricValuesTooltip: data.metrics.map((metric: any) => {
            return metric.metric;
          }),
        };

        return [...prev, curr];
      },
      [
        {
          isHeader: true,
          metricTitle: "",
          metricDescription: data.metrics.map((metric: any) => {
            return metric.description;
          }),
          metricValues: data.metrics.map((metric: any) => {
            return metric.metric;
          }),
        },
      ]
    );

    return [metricDetails];
  };

  const getMetricValueColor = (value: string) => {
    if (value.toLowerCase() === "yes") {
      return [data.colors[0], "tick-circle-green"];
    } else if (value.toLowerCase() === "no") {
      return [data.colors[1], "warning-circle-orange"];
    } else {
      return [];
    }
  };

  const renderDetailedView = () => {
    return (
      <div className={`${BLOCK}__detailedViewWrapper`} data-testid={`${BLOCK}`}>
        <div className={`${BLOCK}__detailedView`}>
          {nonNumericData.map((metric: any, metricIndex: number) => {
            return (
              <div
                className={classNames(`${BLOCK}__detailedView-column`, {
                  [`${BLOCK}__detailedView-column--metricFieldWrapper`]:
                    metric.isHeader,
                })}
                key={`metric-column-${metricIndex}`}
              >
                <div
                  key={`span-${metricIndex}`}
                  className={`${BLOCK}__metric__header ${BLOCK}__metric__header__detailed`}
                >
                  <Popover
                    displayText={
                      <span className={`${BLOCK}__metric--12 `}>
                        {Parser(metric.metricTitle ?? "")}
                      </span>
                    }
                    content={
                      metric.metricDescription ? metric.metricDescription : []
                    }
                    metricClassName={`${BLOCK}__metric--left`}
                  />
                </div>
                {metric.metricValues.map((value: any, index: number) => (
                  <div
                    className={`${BLOCK}__metric__detailed`}
                    key={`${value}-${index}`}
                  >
                    {metric.isHeader && (
                      <span
                        key={`span-${index}`}
                        className={`${BLOCK}__metric--no-margin`}
                      >
                        <Popover
                          content={
                            metric.metricDescription
                              ? metric.metricDescription[index]
                              : []
                          }
                          displayText={
                            <span
                              className={`${BLOCK}__metric--12 ${BLOCK}__metric--no-margin`}
                            >
                              {Parser(value ?? "")}
                            </span>
                          }
                          metricClassName={classNames(
                            `${BLOCK}__metric--no-margin`,
                            {
                              [`${BLOCK}__detailedView-column--metricField`]:
                                metric.isHeader,
                            }
                          )}
                          buttonClassName={`${BLOCK}__metric-button`}
                        />
                      </span>
                    )}
                    {!metric.isHeader && (
                      <Fragment key={`metric-value-${index}`}>
                        {value ? (
                          <div className={`${BLOCK}__metric__value`}>
                            <span
                              className={`${BLOCK}__metric-text`}
                              style={{ color: getMetricValueColor(value)[0] }}
                            >
                              {value}
                            </span>
                            <Icon
                              name={getMetricValueColor(value)[1]}
                              width={24}
                              height={24}
                            />
                          </div>
                        ) : (
                          <span className={`${BLOCK}__metric__value`}>*</span>
                        )}
                      </Fragment>
                    )}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        {hasEmptyValues && (
          <div className={`${BLOCK}__no-data-section`}>
            <span>
              <FormattedMessage id="no.data.available" />
            </span>
          </div>
        )}
      </div>
    );
  };

  const [nonNumericData] = isDetailedView ? getDetailedData() : getData();
  return isDetailedView ? (
    renderDetailedView()
  ) : (
    <div className={`${BLOCK}__content`} data-testid={`${BLOCK}`}>
      {data?.data?.length > 0 && (
        <>
          <Fragment key={"content"}>
            {nonNumericData.map((metric: any, metricIndex: number) => {
              return (
                <div
                  key={`metric-row-${metricIndex}`}
                  className={classNames(`${BLOCK}__company-row`, {
                    [`${BLOCK}__company-row__header`]: metric.isHeader,
                    [`${BLOCK}__company-row__detailed`]: isDetailedView,
                  })}
                >
                  <div
                    className={classNames(`${BLOCK}__metric`, {
                      [`${BLOCK}__metric__title__detailed`]: isDetailedView,
                    })}
                    key={metricIndex}
                  >
                    <span
                      key={`span-${metricIndex}`}
                      className={`${BLOCK}__metric-container`}
                    >
                      <Popover
                        displayText={
                          <span className={`${BLOCK}__metric--12 `}>
                            {Parser(metric.metricTitle ?? "")}
                          </span>
                        }
                        content={
                          metric.metricDescription
                            ? metric.metricDescription
                            : []
                        }
                        metricClassName={`${BLOCK}__metric--left`}
                      />
                    </span>
                  </div>
                  {metric.metricValues.map((value: any, index: number) => (
                    <div
                      className={classNames({
                        [`${BLOCK}__metric`]: isCompanyView,
                        [`${BLOCK}__metric__notCompany`]:
                          !isCompanyView && !isDetailedView,
                        [`${BLOCK}__metric__header`]:
                          metric.isHeader && !isDetailedView,
                        [`${BLOCK}__metric__header ${BLOCK}__metric__header__detailed`]:
                          metric.isHeader && isDetailedView,
                        [`${BLOCK}__metric__detailed`]: isDetailedView,
                      })}
                      key={`${value}-${index}`}
                    >
                      {!isCompanyView && metric.isHeader && (
                        <span
                          key={`span-${index}`}
                          className={`${BLOCK}__metric--no-margin`}
                        >
                          <Popover
                            content={
                              metric.metricDescription
                                ? metric.metricDescription[index]
                                : []
                            }
                            displayText={
                              <span
                                className={`${BLOCK}__metric--12 ${BLOCK}__metric--no-margin`}
                              >
                                {Parser(value ?? "")}
                              </span>
                            }
                            metricClassName={`${BLOCK}__metric--no-margin`}
                            buttonClassName={`${BLOCK}__metric-button`}
                          />
                        </span>
                      )}
                      {!metric.isHeader && (
                        <Fragment key={`metric-value-${index}`}>
                          <ProgressBar
                            mainWidth={100}
                            baseWidth={""}
                            mainTooltip={
                              value ? (
                                value.toLowerCase() === "neutral" ? (
                                  <FormattedMessage id="insights.relevant.risks.neutral" />
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )
                            }
                            baseTooltip={""}
                            colors={
                              value
                                ? value.toLowerCase() === "yes"
                                  ? [data.colors[0], data.colors[1]]
                                  : value.toLowerCase() === "no"
                                  ? [data.colors[2], data.colors[3]]
                                  : value.toLowerCase() === "neutral" && [
                                      data.colors[4],
                                      data.colors[5],
                                    ]
                                : [data.colors[6], data.colors[6]]
                            }
                            gradient={true}
                            tileType={data.tileType}
                            value={value}
                          />
                        </Fragment>
                      )}
                    </div>
                  ))}
                </div>
              );
            })}
          </Fragment>
          <Fragment key={`legend`}>
            <div className={`${BLOCK}__legend`} key={"Legend metric"}>
              {[
                "No Data Available",
                !isCompanyView && !isDetailedView && "Neutral",
                "Yes",
                "No",
              ].map((legend: any) => {
                return (
                  legend && (
                    <div
                      className={`${BLOCK}__legend__metric`}
                      key={`Legend-${legend}`}
                    >
                      <div
                        className={`${BLOCK}__legend__bar`}
                        key={`Legend-Bar-${legend}`}
                      >
                        <ProgressBar
                          mainWidth={100}
                          baseWidth={""}
                          mainTooltip={""}
                          baseTooltip={""}
                          colors={
                            legend.toLowerCase() === "yes"
                              ? [data.colors[0], data.colors[1]]
                              : legend.toLowerCase() === "no"
                              ? [data.colors[2], data.colors[3]]
                              : legend.toLowerCase() === "neutral"
                              ? [data.colors[4], data.colors[5]]
                              : [data.colors[6], data.colors[6]]
                          }
                          gradient={true}
                        />
                      </div>
                      <span>{legend}</span>
                    </div>
                  )
                );
              })}
            </div>
          </Fragment>
        </>
      )}
    </div>
  );
};

export default EnergyUndertaken;
