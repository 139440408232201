import { BaseModel, errorModel } from "utils/redux.utils";

export type ModuleAccessState = {
  error?: errorModel;
  loading: boolean;
  results: ModuleAccessList[];
  apiError?: boolean;
};

export type ModuleAccessList = {
  applicationModuleId: number;
  applicationModuleName: string;
  memberFirmLevelAccessibleFeatures: ApplicationAccess[];
};

export type ApplicationFeature = {
  applicationFeatureId: number;
  applicationFeatureName: string;
};

export type ApplicationAccess = {
  applicationAccessId: number;
  containerCode: string;
  memberFirmCode: string;
  organizationCode: string;
  applicationFeatures: ApplicationFeature[];
  isDefault: boolean;
  isEnabled: boolean;
};

export type MemberFirm = {
  memberFirmCode: string;
  isDefault: boolean;
  isEnabled: boolean;
};

export class ModuleAccessModel extends BaseModel {
  constructor() {
    super({
      error: null,
      loading: false,
      results: [],
      apiError: false,
    });
  }

  setLoading = (loading: boolean): ModuleAccessState => this.merge({ loading });
  setResults = (results: ModuleAccessList[]): ModuleAccessState =>
    this.merge({ results });
}
