import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Icon from "components/shared/icon/icon";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import Parser from "html-react-parser";

type ObjectKeys = {
  name: string;
  id: string;
  items?: string;
};

type Props = {
  className: string;
  label: string | object;
  createNewLabel: object;
  showLabel?: boolean;
  placeholder: string | object;
  loading: boolean;
  options: any[];
  values: any[];
  handleChange?: any;
  handleSelect?: any;
  handleUnselect?: any;
  objectKeys: ObjectKeys;
  textValue?: string;
  displayFunction?: any;
  handleOnEnter?: any;
  onSelectCreateNew?: any;
  inputMaxLength?: number;
};

const DropDown = ({
  className,
  label,
  createNewLabel,
  showLabel = true,
  placeholder,
  loading,
  options,
  values,
  handleChange,
  handleSelect,
  handleUnselect,
  objectKeys,
  textValue,
  handleOnEnter,
  onSelectCreateNew,
  inputMaxLength,
}: Props) => {
  const [showOptions, toggleOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(
    options && options.length ? options : []
  );
  const [inputFilter, setInputFilter] = useState("");
  const wrapperRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const outlineRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const inputSizeRef = useRef<HTMLDivElement>(null);
  const BLOCK = "dropdown";

  const handleLocalSelect = (option: any) => {
    handleSelect(option);
    setInputFilter(option[objectKeys.name]);
    toggleOptions(!showOptions);
  };
  const handleNewComparison = (option: any) => {
    handleSelect(option);
    setInputFilter(option[objectKeys.name]);
  };

  useEffect(() => {
    setFilteredOptions(options && options.length ? options : []);
  }, [options]);

  useEffect(() => {
    setInputFilter(textValue ? textValue : "");
    if (textValue === "")
      setFilteredOptions(options && options.length ? options : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue]);

  useEffect(() => {
    if (!loading && filteredOptions.length === 0) {
      toggleOptions(false);
    }

    if (!loading && inputFilter.trim().length > 1) {
      toggleOptions(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, filteredOptions.length]);

  useEffect(() => {
    if (inputFilter === "") {
      toggleOptions(false);
    }
  }, [inputFilter]);

  useEffect(() => {
    const handleClick = (event: any) => {
      return showOptions || event.target.nodeName !== "svg"
        ? toggleOptions(
            (outlineRef.current !== null &&
              outlineRef.current.contains(event.target)) ||
              (optionsRef.current != null &&
                optionsRef.current.contains(event.target))
          )
        : null;
    };

    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlineRef, options, inputFilter, showOptions]);

  const highlightOption = (option: string) => {
    const newOption = option.length > 35 ? option.slice(0, 35) + "..." : option;
    if (inputFilter !== "" && newOption) {
      const finalStr = inputFilter.replace(
        // eslint-disable-next-line
        /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
        "\\$&"
      );
      const replace = new RegExp(`(${finalStr})`, "i");

      return newOption
        .split(replace)
        .map((subtext) =>
          replace.test(subtext) ? `<b>${subtext}</b>` : subtext
        )
        .join("");
    }

    return newOption;
  };

  const getOption = (
    option: any,
    optionKeys: ObjectKeys,
    parentId?: number
  ) => (
    <button
      className={classNames(`${BLOCK}__option`)}
      key={`${optionKeys.id}-option-${option[optionKeys.id]}`}
      onClick={() => handleLocalSelect({ ...option, parentId })}
      data-test="select-option-button"
      data-testid="select-option-button"
    >
      <div className={`${BLOCK}__option-container`}>
        <>
          <span className={`${BLOCK}__option-value`}>
            {Parser(highlightOption(`${option[optionKeys.name]}`).toString())}
          </span>
          <span>
            {option[optionKeys.name] && option[optionKeys.name].length > 35 ? (
              <Tooltip position={TooltipPosition.right}>
                {option[optionKeys.name]}
              </Tooltip>
            ) : (
              ""
            )}
          </span>
        </>
      </div>
      {optionKeys.items ? (
        <div className={`${BLOCK}__option-items`}>
          <span>{option[optionKeys.items]} </span>
          <Icon name="document1" width={15} height={15} />
        </div>
      ) : null}
    </button>
  );

  const handleFilterChange = (e: any) => {
    const value = e.target.value;
    setInputFilter(value);
    toggleOptions(value ? true : false);
    handleChange(e);
    setFilteredOptions(
      options.filter((option) =>
        value
          ? option[objectKeys.name].toLowerCase().includes(value.toLowerCase())
          : true
      )
    );

    if (handleUnselect) handleUnselect();
  };

  return (
    <div
      ref={wrapperRef}
      id={`${BLOCK}-${objectKeys.id}`}
      className={`${BLOCK} ${className}`}
      data-test="drop-down"
      data-testid="drop-down"
    >
      <div className={`${BLOCK}__label`} data-test="drop-down">
        {showLabel && (label as React.ReactNode)}
      </div>
      <div ref={outlineRef} className={`${BLOCK}__outline`}>
        <textarea
          name="name"
          maxLength={inputMaxLength}
          ref={textareaRef}
          value={inputFilter}
          className={`${BLOCK}__input`}
          data-test="input-text-area"
          data-testid="input-text-area-dropdown-share"
          onChange={handleFilterChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleOnEnter();
            }
          }}
        />

        <div ref={inputSizeRef} className={`${BLOCK}__input-size`}>
          {inputFilter}
        </div>
        {inputFilter === "" && (
          <div className={`${BLOCK}__placeholder`}>
            {placeholder as React.ReactNode}
          </div>
        )}
        <button
          className={classNames(`${BLOCK}__chevron-button`, {
            [`${BLOCK}__chevron-button--not-empty`]: values && values.length,
          })}
          data-test="chevron-button"
          data-testid="chevron-button"
          onClick={() => toggleOptions(!showOptions)}
        >
          <Icon
            className={classNames(`${BLOCK}__chevron-icon`, {
              [`${BLOCK}__chevron-icon--open`]: showOptions,
              [`${BLOCK}__chevron-icon--close`]: !showOptions,
            })}
            name="chevron-down"
            height={24}
          />
        </button>
      </div>
      <div
        ref={optionsRef}
        className={classNames(`${BLOCK}__options`, {
          [`${BLOCK}__options--show`]: showOptions,
          [`${BLOCK}__options--hide`]: !showOptions,
        })}
        data-test="drop-down-options"
      >
        <div>
          {!loading && filteredOptions.length
            ? filteredOptions.map((option: any) =>
                getOption(option, objectKeys)
              )
            : []}
          <button
            className={`${BLOCK}__compare-button`}
            onClick={() => {
              onSelectCreateNew
                ? onSelectCreateNew()
                : handleNewComparison({
                    [objectKeys.name]: "Create new benchmark",
                    [objectKeys.id]: 0,
                    ...(objectKeys.items ? { [objectKeys.items]: 0 } : {}),
                  });

              toggleOptions(!showOptions);
            }}
            data-testid="create-new-btn"
          >
            <div className={`${BLOCK}__compare-button-new`}>
              <Icon name="plus" width={15} height={15} />
              <span>{createNewLabel as React.ReactNode}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DropDown;
