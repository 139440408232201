import Button from "components/shared/button/button";
import Checkbox from "components/shared/checkbox/checkbox";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import Modal, { ModalWidth } from "components/shared/modal/modal";
import TabbedContent from "components/shared/tabbed-content/tabbed-content";
import { useEffect, useRef, useState } from "react";
import { COMPANY_TYPE, GLOBAL_SEARCH_TABS, SOURCE_PAGE } from "utils/constants";
import GlobalSearchDropdown, {
  isAnyGlobalCompanyInGlobalGroups,
} from "./global-search-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import Dropdown from "components/shared/dropdown/dropdown";
import {
  addToastMessage,
  globalCompanyGroupingTrack,
} from "services/commons.service";
import {
  loadCompanyFilter,
  setGlobalMappingFilter,
} from "services/insights/insights.service";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";
import SavedGroupings from "./global-search.saved";
import {
  GlobalCompany,
  GlobalCompanyGroup,
  GlobalCompanyMapping,
} from "services/insights/insights.model";
import { CompanyFilter } from "services/search/filters.model";
import classNames from "classnames";
import Tooltip, { TooltipPosition } from "../tooltip/tooltip";

type Props = {
  show: boolean;
  handleClose: any;
  selectionType: string;
  selectCompany: any;
  unselectCompany: any;
  peersSelected: CompanyFilter[];
  globalPeersSelected: Partial<GlobalCompanyGroup>[];
  limit?: number;
  globalBaseSelected?: Partial<GlobalCompanyGroup>;
  unselectGlobalCompany: Function;
  globalCompanyMappingsFilter: Partial<GlobalCompanyGroup>[];
  fetchSavedGroupings: any;
  fetchGlobalCompaniesFilter: any;
  deleteSavedGrouping?: any;
  guidanceCustomText: string;
  saveCompanyGrouping: any;
  setGlobalCompanyMappingsFilter: any;
  triggeredBy?: string;
  peerOrder?: number;
  setPeerOrder?: Function;
};

const GlobalSearchModal = ({
  show,
  handleClose,
  selectionType,
  selectCompany,
  unselectCompany,
  peersSelected,
  globalPeersSelected,
  limit,
  globalBaseSelected,
  unselectGlobalCompany,
  globalCompanyMappingsFilter,
  fetchSavedGroupings,
  fetchGlobalCompaniesFilter,
  deleteSavedGrouping,
  guidanceCustomText,
  saveCompanyGrouping,
  setGlobalCompanyMappingsFilter,
  triggeredBy,
  peerOrder,
  setPeerOrder,
}: Props) => {
  const BLOCK = "global-search-modal";

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [saveMapping, setSaveMapping] = useState<boolean>(false);
  const tabbedContentRef = useRef<HTMLDivElement>(null);
  const [selectedItems, setSelectedItems] = useState<GlobalCompanyGroup[]>([]);
  const dispatch = useDispatch();
  const handleTabChange = (ind: number) => {
    setCurrentTab(ind);
  };
  const [expandGuidance, setExpandGuidance] = useState<boolean>(true);

  const tabItems = [
    {
      text: "group",
      tabId: `${GLOBAL_SEARCH_TABS.GROUP}-global-search-tab`,
      tabPanelId: `${GLOBAL_SEARCH_TABS.GROUP}-global-search-tab-panel`,
      className: `${BLOCK}__tabbed-content-item`,
    },
    {
      text: "saved.groupings",
      tabId: `${GLOBAL_SEARCH_TABS.SAVED_GROUPINGS}-global-search-tab`,
      tabPanelId: `${GLOBAL_SEARCH_TABS.SAVED_GROUPINGS}-global-search-tab-panel`,
      className: `${BLOCK}__tabbed-content-item`,
    },
  ];

  const getCompanyDropdown = (company: GlobalCompanyGroup, i: number) => {
    return (
      <div
        className={`${BLOCK}__company-name-selected`}
        key={`company-item-${i}`}
      >
        <Dropdown
          parentBlock={`${BLOCK}__company-name-section`}
          data={company.globalCompanyMappingDetails.reduce(
            (companies: GlobalCompany[], current: GlobalCompanyMapping) =>
              companies.filter(
                (c) =>
                  c.companyName.toLowerCase() ===
                  current.globalCompany.companyName.toLowerCase()
              ).length
                ? companies
                : [...companies, current.globalCompany],
            []
          )}
          keys={{
            displayName: "companyName",
            id: "globalCompanyId",
          }}
          onChange={(item: GlobalCompany) => {
            handleOnChangeDefaultNameCheckbox(item, company, i);
          }}
          selected={company.globalCompanyMappingDetails[0].globalCompany}
        />
        <div className={`${BLOCK}__company-name-section-cross-container`}>
          <Button
            className=""
            onClick={() => {
              removeDefaultNameCompany(company, true, i);
            }}
            dataTest="remove-default"
          >
            <Icon name={"cross"} width={25} height={24} />
          </Button>
          <Tooltip position={TooltipPosition.left}>
            <FormattedMessage id="global.search.remove.selected.grouping.msg" />
          </Tooltip>
        </div>
      </div>
    );
  };

  const handleOnChangeDefaultNameCheckbox = (
    company: GlobalCompany,
    companyGroup: GlobalCompanyGroup,
    companyGroupIndex: number
  ) => {
    let newItem = { ...companyGroup };

    let tmpSelectedItems = [...selectedItems];
    const companyMapping = companyGroup.globalCompanyMappingDetails.find(
      (cd) => cd.globalCompanyId === company.globalCompanyId
    );
    if (companyMapping) {
      newItem.globalCompanyMappingDetails = [
        { ...companyMapping, isDefault: true, displayOrder: 0 },
        ...newItem.globalCompanyMappingDetails
          .filter((c) => c.globalCompanyId !== company.globalCompanyId)
          .map((gcm: GlobalCompanyMapping, gcmIndex: number) => ({
            ...gcm,
            isDefault: false,
            displayOrder: gcmIndex + 1,
          })),
      ].sort((a: GlobalCompanyMapping, b: GlobalCompanyMapping) => {
        if (a.displayOrder > b.displayOrder) return 1;
        if (a.displayOrder < b.displayOrder) return -1;
        return 0;
      });
      newItem.globalCompanyMappingName =
        companyMapping.globalCompany.companyName ?? "";

      tmpSelectedItems.splice(companyGroupIndex, 1, newItem);
      setSelectedItems(tmpSelectedItems);
    }
  };

  const removeDefaultNameCompany = (
    company: GlobalCompanyGroup,
    showToastMessage: boolean = true,
    companyIndex: number
  ) => {
    let tmpSelectedItems = [...selectedItems];
    let currentIndex =
      companyIndex === -1
        ? selectedItems.findIndex(
            (item: GlobalCompanyGroup) =>
              item.globalCompanyMappingId === company.globalCompanyMappingId
          )
        : companyIndex;

    if (currentIndex !== -1) {
      tmpSelectedItems.splice(currentIndex, 1);
      setSelectedItems(tmpSelectedItems);
    }
    if (showToastMessage) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage
              id={
                selectionType === COMPANY_TYPE.BASELINE
                  ? "global.search.remove.company.msg"
                  : "global.search.remove.peer.msg"
              }
            />
          ),
        })
      );
    }
  };

  const handleDeleteSavedMapping = (
    globalCompanyGroup: GlobalCompanyGroup,
    globalCompanyGroupIndex: number
  ) => {
    removeDefaultNameCompany(
      globalCompanyGroup,
      false,
      globalCompanyGroupIndex
    );
    unselectGlobalCompany(globalCompanyGroup);
  };

  const getTabContent = (tabIndex: number) => (
    <div
      className={`${BLOCK}__tabbed-content-container`}
      ref={tabbedContentRef}
    >
      {tabIndex === 0 ? (
        <div className={`${BLOCK}__search-section`}>
          <GlobalSearchDropdown
            label={
              selectionType === COMPANY_TYPE.BASELINE
                ? "global.search.search.baseline.company"
                : "global.search.search.peer"
            }
            placeholder="enter.company.name"
            block={BLOCK}
            selectionType={selectionType}
            selectCompany={selectCompany}
            unselectCompany={unselectCompany}
            tabbedContentRef={tabbedContentRef}
            setSelectedItemsFromParent={setSelectedItems}
            disableInput={
              selectionType === COMPANY_TYPE.BASELINE
                ? selectedItems.length > 0
                : limit
                ? (peersSelected.length ? peersSelected.length : 0) +
                    (globalPeersSelected.length
                      ? globalPeersSelected.length
                      : 0) +
                    (selectedItems ? selectedItems.length : 0) >=
                  limit
                : false
            }
            selectedItemsFromParent={selectedItems}
            peersSelected={peersSelected}
            globalPeersSelected={globalPeersSelected.filter(
              (v: Partial<GlobalCompanyGroup>) => !v.isBaseMapping
            )}
            limit={limit}
            globalBaseSelected={globalBaseSelected}
            fetchGlobalCompaniesFilter={fetchGlobalCompaniesFilter}
            triggeredBy={triggeredBy}
          />
          {selectedItems.length > 0 && (
            <div className={`${BLOCK}__company-name-section`}>
              <span className={`${BLOCK}__company-name-label`}>
                <FormattedMessage
                  id={
                    selectionType === COMPANY_TYPE.BASELINE
                      ? "global.default.company.name.label"
                      : "global.default.peer.name.label"
                  }
                />
              </span>
              <div className={`${BLOCK}__selected-company`}>
                {selectedItems.map(
                  (item: GlobalCompanyGroup, companyIndex: number) =>
                    getCompanyDropdown(item, companyIndex)
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <SavedGroupings
          selectionType={selectionType}
          selectGrouping={setSelectedItems}
          selectedGroups={selectedItems}
          peersSelected={peersSelected}
          globalPeersSelected={globalPeersSelected}
          limit={limit}
          globalBaseSelected={globalBaseSelected}
          handleDeleteSavedMapping={handleDeleteSavedMapping}
          fetchSavedGroupings={fetchSavedGroupings}
          deleteSavedGrouping={deleteSavedGrouping}
          triggeredBy={triggeredBy}
        />
      )}
    </div>
  );

  useEffect(() => {
    dispatch(loadCompanyFilter([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCollapsableGuidance = () => {
    return (
      <div className={`${BLOCK}__guidance`}>
        <Button
          className={`${BLOCK}__guidance-btn`}
          onClick={() => {
            setExpandGuidance(!expandGuidance);
          }}
          dataTest="expand-btn"
        >
          <span className={`${BLOCK}__guidance-header`}>
            <Icon name="guidance" width={24} height={24} />
            <FormattedMessage id="guidance" />
          </span>

          <Icon
            name={"chevron-down"}
            className={classNames(`${BLOCK}__guidance-chevron`, {
              [`${BLOCK}__guidance-chevron--open`]: expandGuidance,
              [`${BLOCK}__guidance-chevron--close`]: !expandGuidance,
            })}
            width={24}
            height={24}
          />
        </Button>
        {expandGuidance ? (
          <div
            className={`${BLOCK}__guidance-text-wrapper`}
            data-testid="guidance-text"
          >
            <ul className={`${BLOCK}__guidance-text-list`}>
              <li className={`${BLOCK}__guidance-text-list-item`}>
                <FormattedMessage id="global.search.guidance.1" />
              </li>
              <ul>
                <li className={`${BLOCK}__guidance-text-list-item`}>
                  <FormattedMessage id="global.search.guidance.2" />
                </li>
              </ul>

              <li className={`${BLOCK}__guidance-text-list-item`}>
                <FormattedMessage
                  id="global.search.guidance.3"
                  values={{ guidanceCustomText: guidanceCustomText }}
                />
              </li>
            </ul>
            <strong>
              <FormattedMessage id="note" />
            </strong>

            <ul className={`${BLOCK}__guidance-text-list`}>
              <li className={`${BLOCK}__guidance-text-list-item`}>
                <FormattedMessage id="global.search.guidance.note.1" />
              </li>
              <li className={`${BLOCK}__guidance-text-list-item`}>
                <FormattedMessage id="global.search.guidance.note.2" />
              </li>
              <li className={`${BLOCK}__guidance-text-list-item`}>
                <FormattedMessage id="global.search.guidance.note.3" />
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <Modal
      show={true}
      handleClose={handleClose}
      header={"global.search.header"}
      modalWidth={ModalWidth.xlarge}
      config={false}
    >
      <div className={`${BLOCK}__container`} data-testid={`${BLOCK}-container`}>
        {getCollapsableGuidance()}
        {selectionType === COMPANY_TYPE.PEER &&
        limit &&
        (peersSelected.length ? peersSelected.length : 0) +
          (globalPeersSelected.length ? globalPeersSelected.length : 0) +
          (selectedItems ? selectedItems.length : 0) >=
          limit ? (
          <div
            className={classNames(`${BLOCK}__warning-text`, {
              [`${BLOCK}__error-text`]:
                (peersSelected.length ? peersSelected.length : 0) +
                  (globalPeersSelected.length
                    ? globalPeersSelected.length
                    : 0) +
                  (selectedItems ? selectedItems.length : 0) >
                limit,
            })}
          >
            <Icon
              name={
                (peersSelected.length ? peersSelected.length : 0) +
                  (globalPeersSelected.length
                    ? globalPeersSelected.length
                    : 0) +
                  (selectedItems ? selectedItems.length : 0) ===
                limit
                  ? "warning-circle-yellow"
                  : "error"
              }
              width={24}
              height={24}
            />
            <FormattedMessage
              id={
                (peersSelected.length ? peersSelected.length : 0) +
                  (globalPeersSelected.length
                    ? globalPeersSelected.length
                    : 0) +
                  (selectedItems ? selectedItems.length : 0) ===
                limit
                  ? "global.search.peers.warning.msg"
                  : "global.search.peers.error.msg"
              }
              values={{ limit: limit }}
            />
          </div>
        ) : null}
        <div>
          <TabbedContent
            className={`${BLOCK}__tabbed-content`}
            onChange={handleTabChange}
            tabItems={tabItems}
            selectedIndex={currentTab}
            data-test="tab-content"
          />
        </div>
        {getTabContent(currentTab)}
        <div className={`${BLOCK}__options`}>
          <div className={`${BLOCK}__options-left`}>
            <Checkbox
              label={<FormattedMessage id="save.grouping" />}
              value={saveMapping}
              onChange={() => {
                setSaveMapping(!saveMapping);
              }}
              disabled={
                selectedItems.length === 0 ||
                selectedItems[0].globalCompanyMappingId > 0
              }
              isCheckboxBlue={saveMapping}
              isGlobalSearch={true}
            />
          </div>
          <div className={`${BLOCK}__options-right`}>
            <Button
              className={"button-secondary"}
              formattedText="cancel"
              onClick={handleClose}
              dataTest="cancel-btn"
            />
            <Button
              className={"button-primary"}
              formattedText={
                selectionType === COMPANY_TYPE.BASELINE
                  ? "add.as.baseline"
                  : "add.as.peer"
              }
              onClick={() => {
                if (selectionType === COMPANY_TYPE.BASELINE) {
                  selectCompany(
                    selectedItems[0].globalCompanyMappingDetails[0]
                      .globalCompany,
                    selectionType,
                    true
                  );
                }

                let tmpSavedGroups: Partial<GlobalCompanyGroup>[] = [];
                const executePromises = async () => {
                  for (let i = 0; i < selectedItems.length; i++) {
                    if (selectedItems[i].globalCompanyMappingId > 0) {
                      tmpSavedGroups.push({
                        ...selectedItems[i],
                        isBaseMapping: selectionType === COMPANY_TYPE.BASELINE,
                      });
                    } else {
                      let payload = {
                        globalCompanyMappingName:
                          selectedItems[i].globalCompanyMappingDetails[0]
                            .globalCompany.companyName,
                        globalCompanyMappingDetails: selectedItems[
                          i
                        ].globalCompanyMappingDetails.map(
                          (c: GlobalCompanyMapping, i: number) => ({
                            globalCompanyId: c.globalCompanyId,
                            isDefault: i === 0,
                            displayOrder: i,
                          })
                        ),
                        isSaved: saveMapping,
                        isBaseMapping: selectionType === COMPANY_TYPE.BASELINE,
                      };

                      const res = await saveCompanyGrouping(payload);
                      tmpSavedGroups.push({
                        ...selectedItems[i],
                        globalCompanyMappingId: res.data,
                        isBaseMapping: selectionType === COMPANY_TYPE.BASELINE,
                      });
                      if (saveMapping) {
                        dispatch(
                          addToastMessage({
                            description: (
                              <FormattedMessage id="global.search.save.grouping.msg" />
                            ),
                          })
                        );
                      }
                    }

                    setTimeout(
                      () =>
                        dispatch(
                          globalCompanyGroupingTrack({
                            company: {
                              companyInfo: {
                                companyVendorList: selectedItems[
                                  i
                                ].globalCompanyMappingDetails
                                  .map(
                                    (c: GlobalCompanyMapping) =>
                                      `${c.globalCompany.companyName} ${
                                        c.globalCompany.vendorCollection
                                          ? `(${c.globalCompany.vendorCollection
                                              .map((v) => v.vendorName)
                                              .join("-")})`
                                          : ``
                                      }`
                                  )
                                  .join("|"),
                              },
                            },
                          })
                        ),
                      200
                    );
                  }
                  let globalMaps =
                    selectionType === COMPANY_TYPE.BASELINE
                      ? globalCompanyMappingsFilter.filter(
                          (gcmf: Partial<GlobalCompanyGroup>) =>
                            !gcmf.isBaseMapping
                        )
                      : globalCompanyMappingsFilter;

                  let tmpGlobalMappings = [
                    ...globalMaps,
                    ...tmpSavedGroups.map(
                      (tsg: Partial<GlobalCompanyGroup>) => ({
                        ...tsg,
                        displayOrder: undefined,
                      })
                    ),
                  ];
                  let tmpPeerOrder = peerOrder ?? 1;
                  tmpGlobalMappings = tmpGlobalMappings.reduce(
                    (
                      prev: Partial<GlobalCompanyGroup>[],
                      current: Partial<GlobalCompanyGroup>
                    ) => {
                      let existingMappingIndex = prev.findIndex(
                        (existing: Partial<GlobalCompanyGroup>) =>
                          isAnyGlobalCompanyInGlobalGroups([existing], current)
                      );
                      if (existingMappingIndex !== -1) {
                        if (
                          selectionType === COMPANY_TYPE.BASELINE &&
                          !prev[existingMappingIndex].isBaseMapping
                        ) {
                          prev[existingMappingIndex] = {
                            ...current,
                            displayOrder: 0,
                          };
                        }
                        return [...prev];
                      }

                      return [
                        ...prev,
                        {
                          ...current,
                          displayOrder:
                            selectionType === COMPANY_TYPE.BASELINE
                              ? 0
                              : current.displayOrder ?? tmpPeerOrder++,
                        },
                      ];
                    },
                    []
                  );

                  if (
                    selectionType !== COMPANY_TYPE.BASELINE &&
                    setPeerOrder &&
                    tmpPeerOrder > (peerOrder ?? 1)
                  )
                    setPeerOrder(tmpPeerOrder);
                  dispatch(
                    setGlobalCompanyMappingsFilter([...tmpGlobalMappings])
                  );
                };
                executePromises();

                handleClose();
              }}
              dataTest="add-btn"
              disabled={selectedItems.length === 0}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GlobalSearchModal;
