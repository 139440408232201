import classNames from "classnames";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDownloads,
  showDownloadTab,
} from "services/download/download.service";
import {
  ACCESS_MODULES,
  MENU_ITEMS,
  Roles,
  USER_MANAGEMENT,
} from "utils/constants";
import MenuItem from "./menu-item";
import MenuButton from "./menu-button";
import Icon from "../shared/icon/icon";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import SaveShortlist from "components/shortlist-actions/save-shortlist";
import {
  resetShortList,
  saveShortlist,
  saveShortlistSuccess,
} from "services/shortlist/shortlist.service";
import Modal from "components/shared/modal/modal";
import ReportModal from "components/search/report.modal";
import history from "utils/history";
import {
  deleteShortlist,
  shareShortlistSuccess,
} from "services/saved-shortlist/saved-shortlist.service";
import {
  contentComparisonShareCollab,
  shareCompareSuccess,
} from "services/saved-comparisons/saved-comparisons.service";
import { useLocation } from "react-router";
import { logout } from "utils/constants";
import { getSavedShortlistCount } from "services/saved-shortlist/saved-shortlist.service";
import {
  getSharedSearchCount,
  shareSearchSuccess,
} from "services/searchlist/searchlist.service";
import { getComparisonCount } from "services/saved-comparisons/saved-comparisons.service";
import { addToastMessage } from "services/commons.service";
import { publishedsearchlistUnpublishToaster } from "services/published-searchlist/published-searchlist.service";
import { Role } from "services/commons.model";
import { getSavedInsightCount } from "services/insights/insights.service";
import { isEmpty } from "lodash";

const SideMenu = () => {
  const BLOCK = "SideMenu";
  const dispatch = useDispatch();
  const location = useLocation();
  const downloadState = useSelector((state: RootStore) => state.download);
  const shortListState = useSelector((state: RootStore) => state.shortlist);
  const savedShortlistState = useSelector(
    (state: RootStore) => state.savedShorList
  );
  const sharedSearchlistState = useSelector(
    (state: RootStore) => state.searchlist
  );
  const savedShortListState = useSelector(
    (state: RootStore) => state.savedShorList
  );
  const savedComparisonsListState = useSelector(
    (state: RootStore) => state.comparisonState
  );
  const publishedSearchlistState = useSelector(
    (state: RootStore) => state.publishedSearchlist
  );
  const insightsState = useSelector((state: RootStore) => state.insights);
  const [isOpen, toggleOpen] = useState(false);
  const [showSave, setShowSave] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [pathname, setPathname] = useState("");

  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );
  const isSuperAdmin: boolean =
    currentUser?.adminRole?.adminRoleId === Roles.superAdmin;
  const isTechnicalAdmin: boolean = currentUser?.isTechnicalAdmin;
  const userAccess =
    currentUser &&
    currentUser.accessibleFeatures &&
    currentUser.accessibleFeatures.length > 0 &&
    currentUser.accessibleFeatures.map((item) => item.applicationFeatureId);

  const hasFeatureAccess = (module: any) =>
    userAccess && userAccess.includes(module);

  useEffect(() => {
    if (location.pathname !== logout && !isEmpty(currentUser) && userAccess) {
      if (hasFeatureAccess(ACCESS_MODULES.Feature_ESG_All)) {
        dispatch(getSavedInsightCount());
        dispatch(getDownloads());
        dispatch(getSavedShortlistCount());
        dispatch(getSharedSearchCount());
        dispatch(getComparisonCount());
      } else {
        if (hasFeatureAccess(ACCESS_MODULES.Feature_ESG_Insights)) {
          dispatch(getSavedInsightCount());
        }
        if (
          hasFeatureAccess(ACCESS_MODULES.Feature_ESG_Workspace_Downloads) ||
          hasFeatureAccess(ACCESS_MODULES.Feature_ESG_Research_Downloads)
        ) {
          dispatch(getDownloads());
        }
        if (hasFeatureAccess(ACCESS_MODULES.Feature_ESG_Search)) {
          dispatch(getSharedSearchCount());
        }
        if (hasFeatureAccess(ACCESS_MODULES.Feature_ESG_SavedShortlist)) {
          dispatch(getSavedShortlistCount());
        }
        if (hasFeatureAccess(ACCESS_MODULES.Feature_ESG_Comparison)) {
          dispatch(getComparisonCount());
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (shortListState.saveShortList) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="shortlist.save" />,
        })
      );

      dispatch(saveShortlistSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortListState.saveShortList]);

  useEffect(() => {
    if (
      savedShortListState.shareSuccess &&
      sharedSearchlistState.shareSearchSuccess
    ) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id={"share.search.toaster.message"} />,
        })
      );
      dispatch(shareShortlistSuccess(false));
      dispatch(shareSearchSuccess(false));
    } else if (savedShortListState.shareSuccess) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="shortlist.share.success" />,
        })
      );
      dispatch(shareShortlistSuccess(false));
    } else if (savedComparisonsListState.shareSuccess) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage
              id={
                savedComparisonsListState.isCollab
                  ? "compare.share.success.collab"
                  : "compare.share.success"
              }
            />
          ),
        })
      );
      dispatch(contentComparisonShareCollab(false));
      dispatch(shareCompareSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    savedShortListState.shareSuccess,
    savedComparisonsListState.shareSuccess,
  ]);

  useEffect(() => {
    if (publishedSearchlistState.unpublishToaster) {
      dispatch(publishedsearchlistUnpublishToaster(false));
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage id="unpublish.search.toaster.message" />
          ),
        })
      );
    }
  }, [publishedSearchlistState.unpublishToaster, dispatch]);

  const downloadCount = downloadState.downloads?.filter(
    (download) => download.exportState === 3
  ).length;

  const sharedSearchCount = sharedSearchlistState.sharedSearchCount;
  const savedShortlistCount = savedShortlistState.savedShortlistCount;
  const savedComparisonCount = savedComparisonsListState.savedComparisonCount;
  const sharedInsightCount = insightsState.insightsCount;

  const onSaveShortlistHandler = (shortlistName: string) => {
    // Null check is not required, already checked in opening the Modal for start-new
    dispatch(
      saveShortlist(
        shortListState.results.shortlistId !== undefined
          ? shortListState.results.shortlistId
          : 0,
        shortlistName
      )
    );
    history.push(pathname, {
      shortlistId:
        shortListState.results.shortlistId !== undefined
          ? shortListState.results.shortlistId
          : 0,
    });
    setShowSave(false);
  };

  const handleStartNew = () => {
    dispatch(deleteShortlist(0));
    dispatch(resetShortList());
    setShowModal(false);
    history.push(pathname, {
      shortlistId: 0,
    });
  };

  return (
    <>
      <div className={classNames(BLOCK, { [`${BLOCK}--open`]: isOpen })}>
        <MenuButton
          BLOCK={BLOCK}
          onClick={() => toggleOpen(!isOpen)}
          isMenuOpen={isOpen}
        />
        {userAccess &&
          (userAccess.includes(ACCESS_MODULES.Feature_ESG_All) ||
            userAccess.includes(ACCESS_MODULES.Feature_ESG_Insights)) && (
            <MenuItem
              BLOCK={BLOCK}
              icon={MENU_ITEMS.workspace.icon}
              path={MENU_ITEMS.workspace.path}
              isMenuOpen={isOpen}
              sharedSearchCount={sharedInsightCount}
              setPathname={setPathname}
              setShowModal={setShowModal}
            />
          )}
        {userAccess &&
          (userAccess.includes(ACCESS_MODULES.Feature_ESG_All) ||
            userAccess.includes(ACCESS_MODULES.Feature_ESG_Search)) && (
            <MenuItem
              BLOCK={BLOCK}
              icon={MENU_ITEMS.search.icon}
              path={MENU_ITEMS.search.path}
              isMenuOpen={isOpen}
            />
          )}
        {userAccess &&
          (userAccess.includes(ACCESS_MODULES.Feature_ESG_All) ||
            userAccess.includes(ACCESS_MODULES.Feature_ESG_SavedSearch)) && (
            <MenuItem
              BLOCK={BLOCK}
              icon={MENU_ITEMS.mysearches.icon}
              path={{
                pathname: MENU_ITEMS.mysearches.path,
                state: {
                  fromSideMenu: true,
                },
              }}
              isMenuOpen={isOpen}
              sharedSearchCount={sharedSearchCount}
              setPathname={setPathname}
              setShowModal={setShowModal}
            />
          )}
        {userAccess &&
          (userAccess.includes(ACCESS_MODULES.Feature_ESG_All) ||
            userAccess.includes(ACCESS_MODULES.Feature_ESG_SavedShortlist)) && (
            <div
              className={`${BLOCK}__menu__item`}
              data-test="side-menu-saved-shortlist"
              data-testid="side-menu-saved-shortlist"
            >
              <MenuItem
                BLOCK={BLOCK}
                icon={MENU_ITEMS.saved.icon}
                path={MENU_ITEMS.saved.path}
                isMenuOpen={isOpen}
                setPathname={setPathname}
                setShowModal={setShowModal}
              />
              {savedShortlistCount !== 0 && (
                <span className={`${BLOCK}__menu-item__menu__item__icon`}>
                  <span
                    className={`${BLOCK}__menu-item__menu__item__icon-content`}
                  >
                    {isNaN(savedShortlistCount)
                      ? 0
                      : savedShortlistCount.toString()}
                  </span>
                </span>
              )}
            </div>
          )}
        {userAccess &&
          (userAccess.includes(ACCESS_MODULES.Feature_ESG_All) ||
            userAccess.includes(ACCESS_MODULES.Feature_ESG_Comparison)) && (
            <div className={`${BLOCK}__menu__item`}>
              <MenuItem
                BLOCK={BLOCK}
                icon={MENU_ITEMS.comparisons.icon}
                path={MENU_ITEMS.comparisons.path}
                isMenuOpen={isOpen}
                setPathname={setPathname}
                setShowModal={setShowModal}
              />
              {savedComparisonCount !== 0 && (
                <span className={`${BLOCK}__menu-item__menu__item__icon`}>
                  <span
                    className={`${BLOCK}__menu-item__menu__item__icon-content`}
                  >
                    {isNaN(savedComparisonCount)
                      ? 0
                      : savedComparisonCount.toString()}
                  </span>
                </span>
              )}
            </div>
          )}
        {userAccess &&
          (userAccess.includes(ACCESS_MODULES.Feature_ESG_All) ||
            userAccess.includes(
              ACCESS_MODULES.Feature_ESG_Workspace_Downloads
            ) ||
            userAccess.includes(
              ACCESS_MODULES.Feature_ESG_Research_Downloads
            )) && (
            <div
              className={classNames(`${BLOCK}__menu-item`)}
              onClick={() => {
                if (!downloadState.showDownloads) {
                  dispatch(getDownloads());
                  dispatch(showDownloadTab(!downloadState.showDownloads));
                } else dispatch(showDownloadTab(!downloadState.showDownloads));
              }}
              data-test="side-menu-downloads"
              data-testid="side-menu-downloads"
            >
              {downloadCount !== 0 && (
                <span className={`${BLOCK}__menu-item__icon`}>
                  <span className={`${BLOCK}__menu-item__icon-content`}>
                    {isNaN(downloadCount) ? 0 : downloadCount.toString()}
                  </span>
                </span>
              )}
              <Icon name={MENU_ITEMS.downloads.icon} height={24} />

              <div
                className={classNames(`${BLOCK}__menu-label`, {
                  [`${BLOCK}__menu-label--show`]: isOpen,
                })}
              >
                <FormattedMessage id={`side-menu.menu-item.downloads`} />
              </div>
              {!isOpen && (
                <Tooltip position={TooltipPosition.right}>
                  <FormattedMessage id={`side-menu.menu-item.downloads`} />
                </Tooltip>
              )}
            </div>
          )}
        {isSuperAdmin && (
          <MenuItem
            BLOCK={BLOCK}
            icon={MENU_ITEMS.manageFiles.icon}
            path={USER_MANAGEMENT}
            isMenuOpen={isOpen}
            setPathname={setPathname}
            setShowModal={setShowModal}
          />
        )}
        {!isSuperAdmin && isTechnicalAdmin && (
          <MenuItem
            BLOCK={BLOCK}
            icon={MENU_ITEMS.manageFiles.icon}
            path={MENU_ITEMS.manageAdfPipeline.path}
            isMenuOpen={isOpen}
            setPathname={setPathname}
            setShowModal={setShowModal}
          />
        )}
      </div>
      {showSave && (
        <SaveShortlist
          setShowModal={setShowSave}
          saveShortlist={onSaveShortlistHandler}
        />
      )}
      {showModal && (
        <Modal
          show={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
          header="seaechresult.unsaved.report"
          config={false}
        >
          <ReportModal
            handleClose={handleStartNew}
            handleSave={() => {
              setShowModal(false);
              setShowSave(true);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default SideMenu;
