import Button from "components/shared/button/button";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Modal from "components/shared/modal/modal";
import { TAG_NOTIFICATION_ACTIONS } from "utils/constants";
import history from "utils/history";
import { useTagContext } from "./tag.context";

const TagNotification = () => {
  const BLOCK = "TagNotification";
  const { notificationTypeId, showNotificationModal } = useTagContext();

  const handleCloseContentBenchmark = () => {
    history.push("/comparisons");
  };

  return (
    <Modal
      show={showNotificationModal}
      header={"tags.notification.header"}
      config={false}
    >
      <div className={`${BLOCK}`} data-testid="tag-notification">
        <span className={`${BLOCK}__message`}>
          <FormattedMessage
            id={`tags.notification.action-${notificationTypeId}`}
          />
        </span>
        <div className={`${BLOCK}__actions`}>
          {notificationTypeId === TAG_NOTIFICATION_ACTIONS.ROL_CHANGEG && (
            <Button
              className={`button-secondary`}
              formattedText={"tags.notification.actions.close"}
              onClick={() => handleCloseContentBenchmark()}
              dataTest="close-notification-btn"
            />
          )}
          <Button
            className={`button-primary`}
            formattedText={"tags.notification.actions.ok"}
            onClick={() =>
              notificationTypeId === TAG_NOTIFICATION_ACTIONS.ROL_CHANGEG
                ? window.location.reload()
                : handleCloseContentBenchmark()
            }
            dataTest="ok-notification-btn"
          />
        </div>
      </div>
    </Modal>
  );
};

export default TagNotification;
