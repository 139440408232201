import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import {
  showEditBenchmark,
  showSidedashboardTab,
  updateVisualizationsMetadata,
} from "services/dashboard/dashboard.service";
import { setShowDownloadTab } from "services/download/download.service";
import {
  chartViewTileInsightTrack,
  companyfilterInsightTrack,
  currentSavedInsightDashboard,
  setInsightsPreferences,
  setInsightTileToExport,
  setShowExportPanel,
  showEdit,
  tableViewTileInsightTrack,
} from "services/insights/insights.service";
import { RootStore } from "services/store.service";
import {
  BENCHMARK_METADATA_PATH,
  INSIGHT_BENCHMARK_TYPE,
  INSIGHT_DASHBOARD_FILTER_LABELS,
  MERGE_TOOLTIP_TITLE,
  UPDATE_VALUE_TYPE,
} from "utils/constants";
import { useClickOutside } from "utils/functions";
import { filterDataset, useTileContext } from "./tile.context";
import DropDown from "components/search/filters/drop-down";
import { InsightData } from "services/insights/insights.model";
import Button from "components/shared/button/button";
import {
  AssociatedMetricTooltips,
  Visualization,
} from "services/dashboard/dashboard.model";
import Popover from "components/shared/popover/popover";

const TileHeader = ({
  toggleDetailView,
  setTableViewSelected,
  isESRS,
}: {
  toggleDetailView: Function;
  setTableViewSelected: Function;
  isESRS?: boolean;
}) => {
  const {
    BLOCK,
    index,
    metadata,
    metadata: {
      benchmarkMetadata: { bechmarkStyleOptions, benchmarkTitleTooltip },
      benchmarkId,
      benchmarkType,
      benchmarkTitle,
      benchmarkTileType,
      isDetailedView,
      associatedMetricsTooltips,
    },
    isTableViewActive,
    isPieViewActive,
    setIsTableViewActive,
    originalDataset,
    filters,
    setDataset,
  } = useTileContext();
  const dispatch = useDispatch();
  const insightsState = useSelector((state: RootStore) => state.insights);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const filterRef = useRef(null);
  useClickOutside(filterRef, () => setShowFilter(false));
  const mergeTooltips = MERGE_TOOLTIP_TITLE.includes(benchmarkTileType);

  const handleTileExportAction = (e: any) => {
    dispatch(setShowDownloadTab(false));
    dispatch(showEdit(false));

    dispatch(
      setInsightTileToExport({
        exportFileName: `${insightsState.currentSavedInsightDashboard.insightsName}_${benchmarkTitle}`,
        exportReferenceId:
          insightsState.currentSavedInsightDashboard.insightsId,
        // exportBenchmarkId: metadata.benchmarkId,
        // FOR FILTERING PEER BENCHMARK METADATA BENCHMARK IDS FROM DETAILED BENCHMARK IDS..
        // SO THIS IS THE FIX FOR THE DEFECT.
        exportBenchmarkId:
          benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK
            ? insightsState.currentSavedInsightDashboard.insightsCategories[0].benchmarks.filter(
                (categories) =>
                  categories.benchmarkTileType === benchmarkTileType &&
                  !categories.isDetailedView
              )[0].benchmarkId
            : benchmarkId,
        index: index,
        tileName: `${benchmarkTitle}`,
      })
    );
    dispatch(setShowExportPanel(true));
  };

  const detailedViewOption = (
    <div className={`${BLOCK}__action`}>
      <span
        className={`has-tooltip`}
        onClick={() => toggleDetailView(isDetailedView)}
        data-testid="detailed-view-toggle"
      >
        <Icon
          name={isDetailedView ? "arrow-double-left" : "arrow-double-right"}
          width={24}
          height={24}
        />
        <Tooltip position={TooltipPosition.top}>
          <FormattedMessage
            id={`graph.tile.${isDetailedView ? "peer-view" : "detailed-view"}`}
          />
        </Tooltip>
      </span>
    </div>
  );

  const filterTileOption = (
    <div className={`${BLOCK}__action ${BLOCK}__action--white`}>
      <span
        className={`has-tooltip`}
        onClick={() => {
          setShowFilter((showFilterToggle) => !showFilterToggle);
          dispatch(setShowExportPanel(false));
          dispatch(showSidedashboardTab(false));
          dispatch(showEditBenchmark(false));
        }}
        data-testid="filters"
      >
        <Icon name="filter1" width={24} height={24} />
        <Tooltip position={TooltipPosition.top}>
          <FormattedMessage id="insight.tooltip.filter" />
        </Tooltip>
      </span>
      <div
        className={classNames(`${BLOCK}__filterInsight`, {
          [`${BLOCK}__filterInsight--show`]: showFilter,
        })}
        ref={filterRef}
      >
        <TileFilters setShowFilter={setShowFilter} />
      </div>
    </div>
  );

  const toggleTileViewOption = (
    <div
      className={`${BLOCK}__action ${BLOCK}__action--white`}
      onClick={() => {
        dispatch(
          (isTableViewActive
            ? chartViewTileInsightTrack
            : tableViewTileInsightTrack)({
            name: benchmarkTitle,
          })
        );

        let currentInsightDashboardCopy = JSON.parse(
          JSON.stringify(insightsState.currentSavedInsightDashboard)
        );

        currentInsightDashboardCopy.insightsCategories[0].benchmarks[
          currentInsightDashboardCopy.insightsCategories[0].benchmarks.findIndex(
            (b: Visualization) => b.benchmarkId === metadata.benchmarkId
          )
        ].benchmarkMetadata.selectedStyleOption =
          currentInsightDashboardCopy.insightsCategories[0].benchmarks.filter(
            (b: Visualization) => b.benchmarkId === metadata.benchmarkId
          )[0].benchmarkMetadata.bechmarkStyleOptions[
            isTableViewActive ? 0 : 1
          ];

        dispatch(currentSavedInsightDashboard(currentInsightDashboardCopy));

        setDataset(
          filterDataset(
            filters,
            originalDataset,
            benchmarkType,
            !!isDetailedView,
            !isTableViewActive,
            benchmarkTileType
          )
        );

        dispatch(
          updateVisualizationsMetadata(
            {
              ...metadata,
              benchmarkMetadata: {
                ...metadata.benchmarkMetadata,
                filters,
              },
            },
            {
              updateValue: bechmarkStyleOptions[isTableViewActive ? 0 : 1],
              type: UPDATE_VALUE_TYPE.GRAPH_OPTION,
            }
          )
        );
        setIsTableViewActive((tableViewActive: number) => !tableViewActive);

        if (setTableViewSelected)
          setTableViewSelected((tableViewSelected: number | null) =>
            tableViewSelected !== undefined && tableViewSelected !== null
              ? tableViewSelected + (isTableViewActive ? -1 : 1)
              : null
          );
      }}
      data-testid="toggle"
    >
      <span className={`has-tooltip`}>
        <Icon
          name={isTableViewActive ? "graph" : "table"}
          width={24}
          height={24}
        />
        <Tooltip position={TooltipPosition.top}>
          <FormattedMessage
            id="insight.tooltip.chart"
            values={{
              chartType: isTableViewActive ? "Chart" : "Table",
            }}
          />
        </Tooltip>
      </span>
    </div>
  );

  const titleTooltip = (
    benchmarkTitleTooltip
      ? [
          {
            associatedMetricTooltipText: benchmarkTitleTooltip,
            displayOrder: 1,
            associatedMetricDisplayName: "",
            associatedMetric: "",
            tooltipType: 0,
          },
        ]
      : []
  ).concat(
    mergeTooltips
      ? associatedMetricsTooltips.reduce(
          (
            list: AssociatedMetricTooltips[],
            tooltip: AssociatedMetricTooltips
          ) =>
            list.filter((m) => m.tooltipType === tooltip.tooltipType).length
              ? list
              : [...list, tooltip],
          []
        )
      : []
  );

  return (
    <div className={classNames(`${BLOCK}__header`)} data-testid="tile-header">
      <span
        className={`${BLOCK}__title-container`}
        id={`chart-tile-title-${index}`}
      >
        <div className={`${BLOCK}__title-tile`}>
          <Popover
            displayText={benchmarkTitle}
            buttonClassName={"popover__popup-metric-title"}
            metricClassName={classNames(`${BLOCK}__header__title`, {
              [`${BLOCK}__header__title-upstream`]: benchmarkTileType === 47,
              [`${BLOCK}__header__title-cost`]: benchmarkTileType === 71,
              [`${BLOCK}__header__title--wrap`]: isESRS,
            })}
            content={titleTooltip}
            hideTabs={titleTooltip.length === 1}
          />
        </div>
      </span>

      {benchmarkTileType === 21 ? null : (
        <div className={`${BLOCK}__tile-actions`}>
          {benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
          !isTableViewActive
            ? detailedViewOption
            : null}
          <div
            className={classNames(`${BLOCK}__hidden-menu`, {
              [`${BLOCK}__hidden-menu--show`]: showFilter,
            })}
          >
            <span className={`insights-icon`}>
              <Icon name={"menu2"} width={23} height={23} />
            </span>

            <div className={`${BLOCK}__actions insights-options`}>
              <div
                className={`${BLOCK}__action ${BLOCK}__action--white`}
                onClick={handleTileExportAction}
                data-testid="download-btn"
              >
                <span>
                  <Icon name={"download"} width={23} height={23} />
                </span>
              </div>

              {/* Show filter for detailed view, GHG emission total AND Scope3UpstreamDownstream */}
              {(isDetailedView ||
                ((benchmarkTileType === 5 || benchmarkTileType === 86) && !isPieViewActive) ||
                benchmarkTileType === 47) &&
              !isTableViewActive
                ? filterTileOption
                : null}
              {toggleTileViewOption}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TileHeader;

const TileFilters = ({
  setShowFilter,
}: {
  setShowFilter: (value: boolean) => any;
}) => {
  const {
    BLOCK,
    dataset,
    metadata: {
      benchmarkId,
      benchmarkTitle,
      isDetailedView,
      benchmarkTileType,
    },
    setDataset,
    isPieViewActive,
    originalDataset,
    setFilters,
  } = useTileContext();
  const dispatch = useDispatch();
  const insightsState = useSelector((state: RootStore) => state.insights);

  const [filteredDataset, setFilteredDataset] =
    useState<InsightData[]>(dataset);

  const FILTERS_BLOCK = `${BLOCK}__filters`;

  const applyFilter = () => {
    const filtersAppliedTmp = [];

    if (benchmarkTileType === 5 || benchmarkTileType === 47 || benchmarkTileType === 86)
      filtersAppliedTmp.push({
        label: INSIGHT_DASHBOARD_FILTER_LABELS.SCOPE,
        values:
          filteredDataset.length && filteredDataset[0].metrics.length
            ? filteredDataset[0].metrics.map((c) => c.metricKey)
            : [],
        columnName: "",
      });

    if (isDetailedView) {
      filtersAppliedTmp.push({
        label: INSIGHT_DASHBOARD_FILTER_LABELS.COMPANIES,
        values:
          filteredDataset.length < originalDataset.length
            ? filteredDataset.map((c) => c.globalCompanyId)
            : [],
        columnName: "",
      });

      dispatch(
        companyfilterInsightTrack({
          filters:
            filteredDataset.length < originalDataset.length
              ? filteredDataset.map((c) => c.companyName).join(", ")
              : "",
          name: benchmarkTitle,
        })
      );
    }

    setDataset(
      originalDataset
        .filter(
          (d: any) =>
            filteredDataset.filter(
              (c) => c.globalCompanyId === d.globalCompanyId
            ).length
        )
        .map((d) => ({
          ...d,
          metrics: d.metrics.filter((m) =>
            filteredDataset[0].metrics
              .map((c) => c.metricKey)
              .includes(m.metricKey)
          ),
        }))
    );

    const payload = {
      benchmarkMetadataList: [
        {
          benchmarkId: benchmarkId,
          benchmarkMetadataPath: BENCHMARK_METADATA_PATH.FILTERS,
          benchmarkMetadataValue: {
            filters: filtersAppliedTmp,
          },
        },
      ],
    };
    setFilters(filtersAppliedTmp);

    let currentInsightDashboardCopy = JSON.parse(
      JSON.stringify(insightsState.currentSavedInsightDashboard)
    );
    currentInsightDashboardCopy.insightsCategories[0].benchmarks[
      currentInsightDashboardCopy.insightsCategories[0].benchmarks.findIndex(
        (b: Visualization) =>
          b.benchmarkId === payload.benchmarkMetadataList[0].benchmarkId
      )
    ].benchmarkMetadata.filters =
      payload.benchmarkMetadataList[0].benchmarkMetadataValue.filters;
    dispatch(currentSavedInsightDashboard(currentInsightDashboardCopy));

    dispatch(
      setInsightsPreferences(
        insightsState.currentSavedInsightDashboard.insightsId,
        payload
      )
    );
    setShowFilter(false);
  };

  const enableReset =
    !(
      dataset.length === filteredDataset.length &&
      dataset.every((d) =>
        filteredDataset
          .map((fd) => fd.globalCompanyId)
          .includes(d.globalCompanyId)
      )
    ) ||
    !(
      dataset.length > 0 &&
      filteredDataset.length > 0 &&
      dataset[0].metrics.length === filteredDataset[0].metrics.length &&
      dataset[0].metrics.every((d) =>
        filteredDataset[0].metrics
          .map((fd) => fd.metricKey)
          .includes(d.metricKey)
      )
    );

  return (
    <div className={`${FILTERS_BLOCK}`} data-testid="filter_modal">
      <div className={`${FILTERS_BLOCK}__header`}>
        <h1 className={`${FILTERS_BLOCK}__header--h1--title`}>
          <FormattedMessage id="search.filter" />
        </h1>
        <div data-testid="close_filter">
          <Button
            className={`${FILTERS_BLOCK}__close`}
            onClick={() => setShowFilter(false)}
            iconName="cross"
            iconHeight={24}
            iconWidth={24}
          />
        </div>
      </div>
      <div className={`${FILTERS_BLOCK}__container`}>
        {/* Metric level filter for GHG emission total and Absolute scope3*/}
        {(benchmarkTileType === 5 || benchmarkTileType === 47 || benchmarkTileType === 86) &&
        !isPieViewActive ? (
          <DropDown
            className={`Filter-tile__drop-down`}
            label={INSIGHT_DASHBOARD_FILTER_LABELS.SCOPE}
            placeholder={INSIGHT_DASHBOARD_FILTER_LABELS.SCOPE}
            loading={false}
            objectKeys={{
              name: "metricName",
              id: "metricKey",
              externalId: "metricKey",
            }}
            values={filteredDataset.length ? filteredDataset[0].metrics : []}
            options={originalDataset.length ? originalDataset[0].metrics : []}
            handleSelect={(v: any) =>
              setFilteredDataset(
                filteredDataset.map((d) => ({
                  ...d,
                  metrics: [...d.metrics, v],
                }))
              )
            }
            handleUnselect={(v: any) =>
              setFilteredDataset(
                filteredDataset.map((d) => ({
                  ...d,
                  metrics: d.metrics.filter((m) => m.metricKey !== v.metricKey),
                }))
              )
            }
          />
        ) : null}

        {/* Companies filter for detailed view */}
        {isDetailedView ? (
          <DropDown
            className={`Filter-tile__drop-down`}
            label={
              <FormattedMessage id="insights.peer-benchmark.detailed-view.filter.label" />
            }
            placeholder={
              <FormattedMessage id="insights.peer-benchmark.detailed-view.filter.label" />
            }
            loading={false}
            objectKeys={{
              name: "companyName",
              id: "globalCompanyId",
              externalId: "globalCompanyId",
            }}
            values={filteredDataset}
            options={originalDataset.filter(
              ({ globalCompanyId }) =>
                globalCompanyId !== -98 && globalCompanyId !== -99
            )}
            handleSelect={(v: any) =>
              setFilteredDataset([...filteredDataset, v])
            }
            handleUnselect={(v: any) =>
              setFilteredDataset(
                filteredDataset.filter(
                  ({ globalCompanyId }) => globalCompanyId !== v.globalCompanyId
                )
              )
            }
          />
        ) : null}

        <div className={`${FILTERS_BLOCK}__options`}>
          <button
            disabled={!enableReset}
            className={
              !enableReset
                ? `${FILTERS_BLOCK}__reset-disabled`
                : `${FILTERS_BLOCK}__reset`
            }
            onClick={() => {
              setFilteredDataset(
                dataset.filter(({ globalCompanyId }) => globalCompanyId > 0)
              );
            }}
          >
            <FormattedMessage id="reset" />
          </button>

          <Button
            className={`button-primary`}
            disabled={filteredDataset.length === 0}
            onClick={() => applyFilter()}
            formattedText="apply"
          />
        </div>
      </div>
    </div>
  );
};
