import api from "services/api.service";
import { User } from "services/commons.model";

export const getAdminUsersByRole = (adminRoleId: number) => {
  return api.get(`admin/${adminRoleId}/list`).then(({ data }) => data);
};

export const getUsers = (searchText: string) => {
  return api
    .post("users/search", {
      contentText: searchText,
      pageInfo: {
        pageNumber: 1,
        totalRecordCount: 25,
      },
    })
    .then(({ data }) => {
      if (data && data.data != null) return data.data;
      return [];
    })
    .catch((error) => {
      return [];
    });
};

export const addAdminUser = (payload: any) => {
  return api.post("admin", payload).then(({ data }) => data);
};

export const deleteAdminUser = (userId: number, adminRoleId: number) => {
  return api
    .delete(`admin/${adminRoleId}/user/${userId}`)
    .then(({ data }) => data);
};
