import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDisclosureStatements,
  loadDocumentViewerSECStatements,
  setFullscreen,
  setSelectedDisclosure,
  setSelectedDisclosureElementId,
} from "services/document-viewer/document-viewer.service";
import {
  createShortlist,
  removeShortlistItem,
} from "services/shortlist/shortlist.service";
import { sendExportItem } from "services/download/download.service";
import { RootStore } from "services/store.service";
import classNames from "classnames";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import Checkbox from "components/shared/checkbox/checkbox";
import ComparisonNote from "components/notes/comparison-notes";
import {
  comparisonItemTypes,
  comparisonPath,
  DEFAULT_SEC_EXPORT_PAYLOAD,
} from "../../utils/constants";
import { SecSearchResultsReport } from "services/search/search.results.model";
import { SECDisclosureStatements } from "services/document-viewer/document-viewer.model";
import Compare from "components/compare/compare";
import history from "utils/history";
import {
  addtoComparison,
  setComparisonsSelectedList,
} from "services/saved-comparisons/saved-comparisons.service";
import { ComparisonUncheckKeyword } from "services/saved-comparisons/saved-comparisons.model";
import { hexToRgbA } from "./overlay.helper";
import Button from "components/shared/button/button";
import { useTagContext } from "components/tags/tag.context";
import Parser from "html-react-parser";
import socket, { rooms } from "utils/socket";
import { fetchComparisonItems } from "services/saved-comparisons/saved-comparisons.api";
import DeleteModal from "components/shared/delete-modal/delete-modal";

type Props = {
  report: Partial<SecSearchResultsReport> | null;
  fromShortlist?: boolean;
  fromComparison?: boolean;
  comparisonIsSaved?: boolean;
  isBaseReport?: boolean;
  isVisibleInComparison?: boolean;
  commentNoteId?: number;
  comparisonItemId?: number;
  showSaveComparison?: (value: boolean) => void;
  deleteFromComparison?: (value: number) => void;
  reportIndex?: number;
  handleCheckboxChange?: Function;
  setReportList?: (value: any) => void;
  comparisonId?: number;
  actionsRef?: React.RefObject<HTMLDivElement>;
};

const DocumentViewerSEC = ({
  report,
  fromShortlist,
  fromComparison,
  comparisonIsSaved,
  isBaseReport,
  isVisibleInComparison,
  commentNoteId,
  comparisonItemId,
  showSaveComparison,
  deleteFromComparison,
  reportIndex,
  handleCheckboxChange,
  setReportList,
  comparisonId,
  actionsRef,
}: Props) => {
  const dispatch = useDispatch();
  const [showHighlights, toggleShowHighlights] = useState(true);
  const [showCompareModal, setCompareModal] = useState<boolean>(false);
  const [showComparisonNote, setshowComparisonNote] = useState<boolean>(false);
  const [showDeleteComparisonModal, setShowDeleteComparisonModal] =
    useState<boolean>(false);
  const [showCommentBlue, setCommentBlue] = useState<boolean>(false);
  const [currentDisclosure, setCurrentDisclosure] =
    useState<Partial<SecSearchResultsReport>>();
  const [loadingStatements, setLoadingStatements] = useState<boolean>(true);
  const [SECStatements, setSECStatements] = useState<SECDisclosureStatements>();
  const documentViewerState = useSelector(
    (state: RootStore) => state.documentViewer
  );
  const downloadState = useSelector((state: RootStore) => state.download);
  const shortlistResults = useSelector((state: RootStore) => state.shortlist);
  const searchResults = useSelector((state: RootStore) => state.searchResults);
  const comparisonState = useSelector(
    (state: RootStore) => state.comparisonState
  );
  const [isInShortlist, setIsInShortlist] = useState<boolean>(false);
  const BLOCK = "document-viewer";
  const uniqueHighlights: string[] = [];

  const { setTagPanelMaxMargin, setShowTagPanel, setCurrentTagsList } =
    useTagContext();

  useEffect(() => {
    setCommentBlue(commentNoteId !== 0 && commentNoteId !== undefined);
    if (report) setCurrentDisclosure(report);
    return () => {
      dispatch(setFullscreen(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCommentBlue(commentNoteId !== 0 && commentNoteId !== undefined);
    if (report) setCurrentDisclosure(report);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  useEffect(() => {
    if (!fromComparison) setSECStatements(documentViewerState.SECStatements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentViewerState.SECStatements]);

  useEffect(() => {
    if (currentDisclosure && currentDisclosure.elementId) {
      if (fromComparison) {
        setLoadingStatements(true);
        getDisclosureStatements(
          currentDisclosure.elementId,
          currentDisclosure.contentKeywords
            ? currentDisclosure.contentKeywords
            : ""
        ).then((statements) => {
          setSECStatements(statements.data);
          setLoadingStatements(false);
          if (
            statements.data.keywords &&
            statements.data.keywords?.length > 0 &&
            isVisibleInComparison &&
            !isBaseReport
          )
            setTagPanelMaxMargin(true);
          else if (
            statements.data.keywords &&
            statements.data.keywords?.length === 0 &&
            isVisibleInComparison &&
            !isBaseReport
          )
            setTagPanelMaxMargin(false);
        });
      } else {
        dispatch(
          loadDocumentViewerSECStatements(
            currentDisclosure.elementId,
            currentDisclosure.contentKeywords
              ? currentDisclosure.contentKeywords
              : ""
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDisclosure?.elementId]);

  useEffect(() => {
    if (
      fromComparison &&
      SECStatements &&
      SECStatements.keywords &&
      SECStatements.keywords?.length > 0 &&
      isVisibleInComparison &&
      !isBaseReport
    )
      setTagPanelMaxMargin(true);
    else if (
      fromComparison &&
      SECStatements &&
      SECStatements.keywords &&
      SECStatements.keywords?.length === 0 &&
      isVisibleInComparison &&
      !isBaseReport
    )
      setTagPanelMaxMargin(false);
  }, [isVisibleInComparison]);

  const updateNotes = (payload: any) => {
    if (payload.data.referenceId === comparisonItemId) {
      fetchComparisonItems(comparisonId!, {
        params: { execute: true },
      }).then((response: any) => {
        setReportList!(response.data.comparisonItems);
      });
    }
  };

  useEffect(() => {
    if (comparisonItemId) {
      socket.on("commentnote.update", updateNotes);
      socket.join(rooms.commentNote(comparisonItemId));

      return () => {
        socket.leave(rooms.commentNote(comparisonItemId));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonItemId]);

  const payload = {
    shortlistId: 0,
    esgReport: {
      reportId: null,
    },
    sectionItem: {
      filingId: null,
      sectionId: null,
      elementId: null,
    },
    sectionId: null,
    shortlistItemType: 1,
    contentKeywords: "",
    displayOrder: 0,
  };

  const onAddToShortlistHandler = () => {
    if (isInShortlist || fromShortlist) {
      dispatch(
        removeShortlistItem({
          ...payload,
          sectionItem: SECStatements,
          sectionId:
            SECStatements && SECStatements.elementId
              ? SECStatements.elementId
              : 0,
          shortlistId:
            shortlistResults.results &&
            shortlistResults.results.shortlistId !== 0
              ? shortlistResults.results.shortlistId
              : 0,
          contentKeywords:
            searchResults.searchPayloadSEC.contentText.length !== 0
              ? searchResults.searchPayloadSEC.contentText
              : "",
          location: "toc",
        })
      );
      if (fromShortlist) dispatch(setSelectedDisclosure(null));
    } else {
      dispatch(
        createShortlist({
          ...payload,
          sectionItem: SECStatements,
          sectionId:
            SECStatements && SECStatements.elementId
              ? SECStatements.elementId
              : 0,
          shortlistId:
            shortlistResults.results !== null &&
            shortlistResults.results.shortlistId !== 0
              ? shortlistResults.results.shortlistId
              : 0,
          contentKeywords:
            searchResults.searchPayloadSEC.contentText.length !== 0
              ? searchResults.searchPayloadSEC.contentText
              : "",
          location: "toc",
        })
      );
    }
  };

  useEffect(() => {
    setIsInShortlist(false);
  }, []);

  useEffect(() => {
    if (
      searchResults.tocShortlists.length !== 0 &&
      SECStatements &&
      SECStatements.elementId &&
      searchResults.tocShortlists.includes(SECStatements.elementId)
    ) {
      setIsInShortlist(true);
    } else if (isInShortlist) setIsInShortlist(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults.tocShortlists, searchResults.secShortlists, SECStatements]);

  useEffect(() => {
    setIsInShortlist(false);

    if (handleCheckboxChange) {
      toggleShowHighlights(
        !comparisonState.uncheckedKeywordReports ||
          (comparisonState.uncheckedKeywordReports &&
            comparisonState.uncheckedKeywordReports.comparisionItemIds &&
            !comparisonState.uncheckedKeywordReports.comparisionItemIds.some(
              (uncheckedKeyword: ComparisonUncheckKeyword) =>
                report?.elementId === uncheckedKeyword.id
            ))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDisclosure]);

  const getBreadcrumbs = (): any[] => {
    const path: any = [];
    if (!searchResults.tocResults) return path;
    let currentSections: any[] = searchResults.tocResults.data.sections;
    SECStatements?.documentSectionPath.forEach((element, index) => {
      const currentLevel = currentSections.find(
        (section) => section.id === element.sectionId
      );
      currentSections = currentLevel?.childSections || [];

      if (currentLevel) {
        path.push({
          elementId: currentLevel.elementId,
          sectionName: element.sectionName,
          isClickable: index + 1 < SECStatements.documentSectionPath.length,
        });
      }
    });
    return path;
  };

  const downloadSEC = (e: any) => {
    e.preventDefault();
    if (currentDisclosure && SECStatements) {
      dispatch(
        sendExportItem({
          ...DEFAULT_SEC_EXPORT_PAYLOAD,
          exportFileName: `${SECStatements.companyName}_${SECStatements.title}`,
          exportReferenceId: SECStatements.elementId,
          searchCriteria: {
            contentText:
              searchResults.searchPayloadSEC.contentText.length !== 0 &&
              showHighlights
                ? searchResults.searchPayloadSEC.contentText
                : "",
          },
          downloadTabOpen: downloadState.showDownloads,
        })
      );
    }
  };

  const mapSelectedItems = (item: any) => ({
    sectionId: item.elementId,
    filingId: item.elementId,
    sectionName: item.companyName,
    comparisonItemType: comparisonItemTypes.SEC,
    contentKeywords: item.contentKeywords
      ? item.contentKeywords
      : searchResults.searchPayloadESG.contentText,
  });

  const submitCompare = (selected: any) => {
    if (currentDisclosure) {
      if (selected.comparisonId)
        dispatch(
          addtoComparison(
            [mapSelectedItems(currentDisclosure)],
            selected.comparisonId
          )
        );
      history.push(
        `${comparisonPath}${
          selected.comparisonId ? `/${selected.comparisonId}` : ""
        }`
      );
    }
  };

  const handleClose = () => {
    if (fromComparison && deleteFromComparison && reportIndex) {
      deleteFromComparison(reportIndex);
      setShowTagPanel(false);
      setCurrentTagsList([]);
    } else {
      dispatch(setSelectedDisclosure(null));
    }
  };

  return (
    <div
      className={classNames(BLOCK, {
        [`${BLOCK}--fullscreen`]: documentViewerState.fullscreen,
        [`${BLOCK}--comparison`]: fromComparison,
      })}
      data-testid="document-viewer"
    >
      <div className={`${BLOCK}__company`}>
        <span className={`${BLOCK}__company-title`}>
          {isBaseReport && (
            <Icon
              className="base-star"
              name="star-filled"
              width={18}
              height={18}
            />
          )}
          <span
            className={classNames(`${BLOCK}__company-name`, {
              [`${BLOCK}__company-name-hidden`]: fromComparison,
            })}
          >
            {SECStatements?.companyName}
            <span className={`${BLOCK}__word-score`}>
              <FormattedMessage
                id="document-viewer.subtitle"
                values={{
                  wordCount: Intl.NumberFormat("en-US").format(
                    SECStatements ? SECStatements.wordCount : 0
                  ),
                  readabilityScore:
                    SECStatements?.fleschKincaidReadabilitySentence,
                }}
              />
            </span>
          </span>
        </span>
        <div className={`${BLOCK}__actions`}>
          {fromComparison ? (
            <div className={`${BLOCK}__comparison`} ref={actionsRef}>
              <div
                className={`${BLOCK}__action ${BLOCK}__action--left-tooltip ${BLOCK}__shorlits-note-button`}
              >
                <Button
                  className={``}
                  dataTest="commenting"
                  onClick={() => {
                    if (comparisonIsSaved) {
                      setshowComparisonNote(true);
                    } else {
                      if (showSaveComparison) showSaveComparison(true);
                    }
                    if (fromComparison) {
                      setShowTagPanel(false);
                      setCurrentTagsList([]);
                    }
                  }}
                  iconName={showCommentBlue ? "commenting2" : "commenting-plus"}
                />
                <Tooltip position={TooltipPosition.left}>
                  <FormattedMessage
                    id={
                      !commentNoteId || commentNoteId === 0
                        ? "note.add.button"
                        : "note.view.button"
                    }
                  />
                </Tooltip>
              </div>

              {!isBaseReport && (
                <div
                  className={`${BLOCK}__action ${BLOCK}__action--left-tooltip`}
                >
                  <Button
                    id="btn-close"
                    dataTest="delete-report"
                    className=""
                    onClick={() => {
                      setShowDeleteComparisonModal(true);
                    }}
                    iconName="remove1"
                  />
                  <Tooltip position={TooltipPosition.left}>
                    <FormattedMessage id="document-viewer.delete-report.tooltip" />
                  </Tooltip>
                </div>
              )}
            </div>
          ) : (
            <>
              <Button
                id="btn-addToShortlist"
                data-test="addToShortlist"
                className={`${BLOCK}__action text-button`}
                formattedText={
                  isInShortlist || fromShortlist
                    ? "document-viewer.remove-from-short-list"
                    : "document-viewer.add-to-short-list"
                }
                onClick={onAddToShortlistHandler}
                iconName={isInShortlist || fromShortlist ? "remove" : "add"}
                dataTest="btn-addToShortlist"
              />
              <div className={`${BLOCK}__action`}>
                <Button
                  id="btn-download"
                  dataTest="btn-download"
                  onClick={downloadSEC}
                  className=""
                  iconName="download"
                />
                <Tooltip position={TooltipPosition.right}>
                  <FormattedMessage id="document-viewer.download.tooltip" />
                </Tooltip>
              </div>
              {documentViewerState.fullscreen || fromShortlist === true ? (
                <>
                  <div
                    className={`${BLOCK}__action ${BLOCK}__action--left-tooltip`}
                  >
                    <Button
                      id="btn-compare"
                      className=""
                      onClick={() => {
                        if (currentDisclosure)
                          dispatch(
                            setComparisonsSelectedList([
                              mapSelectedItems(currentDisclosure),
                            ])
                          );
                        setCompareModal(true);
                      }}
                      iconName="compare"
                      dataTest="btn-compare"
                    />

                    <Tooltip position={TooltipPosition.left}>
                      <FormattedMessage id="document-viewer.compare.tooltip" />
                    </Tooltip>
                  </div>
                  {fromShortlist && (
                    <div className={`${BLOCK}__action`}>
                      <Button
                        id="btn-close"
                        dataTest="btn-close-fromShortlist"
                        className=""
                        onClick={() => dispatch(setSelectedDisclosure(null))}
                        iconName="cross"
                      />
                      <Tooltip position={TooltipPosition.left}>
                        <FormattedMessage id="document-viewer.close.tooltip" />
                      </Tooltip>
                    </div>
                  )}
                  {!fromShortlist && (
                    <div className={`${BLOCK}__action`}>
                      <Button
                        className=""
                        iconName="decrease"
                        dataTest="full-screen"
                        onClick={() => dispatch(setFullscreen(false))}
                      />
                      <Tooltip position={TooltipPosition.left}>
                        <FormattedMessage
                          id={`document-viewer.half-preview.tooltip`}
                        />
                      </Tooltip>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {!fromShortlist && (
                    <div className={`${BLOCK}__action`}>
                      <Button
                        id="btn-fullscreen"
                        dataTest="btn-fullscreen"
                        className=""
                        iconName="increase"
                        onClick={() => dispatch(setFullscreen(true))}
                      />
                      <Tooltip position={TooltipPosition.right}>
                        <FormattedMessage
                          id={`document-viewer.full-preview.tooltip`}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {!fromComparison && (
                    <div
                      className={classNames(`${BLOCK}__action`, {
                        [`${BLOCK}__action--left-tooltip`]: fromComparison,
                      })}
                    >
                      <Button
                        id="btn-close"
                        dataTest="btn-close"
                        className=""
                        onClick={handleClose}
                        iconName="cross"
                      />
                      <Tooltip position={TooltipPosition.left}>
                        <FormattedMessage id="document-viewer.close.tooltip" />
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {SECStatements && SECStatements.keywords?.length > 0 && (
        <div className={`${BLOCK}__keywords`}>
          <Checkbox
            value={showHighlights}
            onChange={() => {
              if (handleCheckboxChange) {
                handleCheckboxChange(report?.elementId);
              }

              toggleShowHighlights(!showHighlights);
            }}
          />
          <span className={`${BLOCK}__keyword-label`}>
            <FormattedMessage id="document-viewer.highlight-keywords" />
          </span>
          <div className={`${BLOCK}__highlights`}>
            {SECStatements &&
              SECStatements.keywords.map((highlight, index) => {
                if (uniqueHighlights.includes(highlight.keywordTextContent)) {
                  return null;
                }

                uniqueHighlights.push(highlight.keywordTextContent);
                return (
                  <span
                    key={`highlight-${index}`}
                    className={`${BLOCK}__highlight`}
                    style={{
                      backgroundColor: highlight.colorCode!
                        ? hexToRgbA(highlight.colorCode!)
                        : hexToRgbA("default"),
                    }}
                  >
                    {highlight.keywordTextContent}
                  </span>
                );
              })}
          </div>
        </div>
      )}
      {(!fromShortlist && !fromComparison && searchResults.tocLoading) ||
      (!fromComparison && documentViewerState.loading) ||
      (fromComparison && loadingStatements) ? (
        <Icon
          name={"loading"}
          width={50}
          height={50}
          className="loading-icon"
        />
      ) : (
        <>
          <div className={`${BLOCK}__breadcrumbs`} data-testid={`${BLOCK}__breadcrumbs`}>
            {getBreadcrumbs().map((path, index) =>
              path.isClickable ? (
                <span
                  key={`breadcrumbm-${index}`}
                  onClick={() => {
                    dispatch(setSelectedDisclosureElementId(path.elementId));
                  }}
                >
                  {path.sectionName} {" > "}
                </span>
              ) : (
                path.sectionName
              )
            )}
          </div>
          <div className={`${BLOCK}__html`} data-testid={`${BLOCK}__html`}>
            <div className={`${BLOCK}__html-content`}>
              {Parser(
                SECStatements && SECStatements.html
                  ? showHighlights
                    ? SECStatements.html.toString()
                    : SECStatements.html.replaceAll(
                        "class='highlight'",
                        "class='highlight-none'"
                      )
                  : ""
              )}
            </div>
          </div>
        </>
      )}
      {showCompareModal && (
        <Compare
          setShowCompareModal={setCompareModal}
          submitCompare={submitCompare}
        />
      )}
      {showComparisonNote && (
        <ComparisonNote
          setShowNote={setshowComparisonNote}
          comparisonNote={commentNoteId}
          setCommentBlue={setCommentBlue}
          comparisonItemId={comparisonItemId}
        />
      )}
      {showDeleteComparisonModal && (
        <DeleteModal
          title="comparison.remove-report.title"
          message={
            <>
              <FormattedMessage id="comparison.remove.message" />
              <span
                style={{ fontWeight: 600 }}
              >{` "${currentDisclosure?.sectionName}" `}</span>
              <FormattedMessage id="comparison.remove-report.message" />
            </>
          }
          isRemove={true}
          handleCloseModal={() => {
            setShowDeleteComparisonModal(false);
          }}
          deleteItem={handleClose}
        />
      )}
    </div>
  );
};

export default DocumentViewerSEC;
