import BarChart from "components/visualizations/charts/bar.chart";
import { useDispatch, useSelector } from "react-redux";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import { RootStore } from "services/store.service";
import {
  getInsightsDetailPeerViewColorCode,
  isNumeric,
  mod,
} from "utils/functions";
import { useTileContext } from "../tile.context";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { DatasetLegend } from "components/visualizations/dataset-structure";
import {
  AssociatedMetricTooltips,
  GroupedLegends,
  Visualization,
} from "services/dashboard/dashboard.model";
import MultipleBarChart from "components/visualizations/charts/multiple-bar.chart";
import {
  BENCHMARK_TILE_TYPES,
  ESG_IVA_RATING_TILE_PEER_INDUSTRY,
  FILTER_METRICS,
  FILTEROPTION,
  HIDEMTETRICS,
  INSIGHT_BENCHMARK_TYPE,
  NOCALC_PERIOD,
  ghgEmissionFilteroptions,
  peerColorCodes,
  BENCHMARK_METADATA_PATH,
  GRAPH_OPTIONS,
} from "utils/constants";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import { useEffect, useState } from "react";
import moment from "moment";
import TableChart from "components/visualizations/charts/table-chart";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import Icon from "components/shared/icon/icon";
import classNames from "classnames";
import FilterType from "components/shared/dropdown/filter-type";
import GhgEmissionPieChart from "components/visualizations/charts/ghg-emission-pie.chart";
import {
  currentSavedInsightDashboard,
  updateTileMetaDataForExtraChartMetrics,
} from "services/insights/insights.service";
import DetailPeerViewLegend from "./DetailPeerViewLegend";
import TileEmptyContent from "../tile-empty-content";

const GHGEmissionTotals = () => {
  const {
    index,
    BLOCK,
    dataset,
    isTableViewActive,
    isPieViewActive,
    setIsPieViewActive,
    metadata,
    response,
    metadata: {
      benchmarkType,
      benchmarkTitle,
      benchmarkTileType,
      isDetailedView,
      associatedMetricGroupTooltips,
      benchmarkMetadata: {
        colorCodes,
        selectedGraphOption,
        associatedMetricsGroup,
        selectedMetricOption,
      },
    },
    setFooterChild,
    exporting,
  } = useTileContext();

  const commonsState = useSelector((state: RootStore) => state.commons);
  const insightState = useSelector((state: RootStore) => state.insights);
  const dispatch = useDispatch();
  const [selectedFilterOption, setSelectedFilterOption] = useState<string>(
    (selectedMetricOption &&
      ghgEmissionFilteroptions.find(
        (option: string) => option.toLowerCase() === selectedMetricOption
      )) ??
      selectedMetricOption
  );

  const filterDataset = (datasetToFilter: InsightData[]) => {
    const baseline = datasetToFilter.find((d) => d.isBaseCompany);
    if (!baseline) return datasetToFilter;
    return datasetToFilter.map((d) => ({
      ...d,
      metrics: d.metrics.filter(
        (m) => m.metricKey !== FILTER_METRICS.ESTIMATED_SCOPE
      ),
    }));
  };

  const filteredDataset = filterDataset(dataset);
  const noData = filteredDataset?.every((data: InsightData) => {
    return data.metrics.every(
      (metric: InsightMetricData) => metric.metricValue === null
    );
  });

  useEffect(() => {
    if (
      benchmarkTileType === BENCHMARK_TILE_TYPES.GHG_EMISSIONS_TOTALS ||
      benchmarkTileType === BENCHMARK_TILE_TYPES.GHG_EMISSIONS_TOTALS_ESRS
    )
      setFooterChild(calculationPeriod);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTableViewActive, isDetailedView]);

  const handleOptionChange = (e: any) => {
    if (benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK) {
      let currentInsightDashboardCopy = JSON.parse(
        JSON.stringify(insightState.currentSavedInsightDashboard)
      );
      currentInsightDashboardCopy.insightsCategories[0].benchmarks
        .filter((b: Visualization) => b.benchmarkTileType === benchmarkTileType)
        .map(
          (b: Visualization) => (b.benchmarkMetadata.selectedMetricOption = e)
        );
      dispatch(currentSavedInsightDashboard(currentInsightDashboardCopy));
    }
    dispatch(
      updateTileMetaDataForExtraChartMetrics(
        BENCHMARK_METADATA_PATH.SELECTED_METRIC_OPTION,
        {
          selectedMetricOption: e.toLowerCase(),
        },
        insightState.currentSavedInsightDashboard.insightsId,
        metadata.benchmarkTileType
      )
    );
    setSelectedFilterOption(e);
  };

  const handleChartSelection = (PieViewActive: boolean) => {
    if (benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK) {
      let currentInsightDashboardCopy = JSON.parse(
        JSON.stringify(insightState.currentSavedInsightDashboard)
      );
      currentInsightDashboardCopy.insightsCategories[0].benchmarks
        .filter((b: Visualization) => b.benchmarkTileType === benchmarkTileType)
        .map(
          (b: Visualization) =>
            (b.benchmarkMetadata.selectedChartOption = PieViewActive
              ? GRAPH_OPTIONS.pie
              : GRAPH_OPTIONS.column)
        );
      dispatch(currentSavedInsightDashboard(currentInsightDashboardCopy));
    }
    setIsPieViewActive(PieViewActive);
    dispatch(
      updateTileMetaDataForExtraChartMetrics(
        BENCHMARK_METADATA_PATH.SELECTED_CHART_OPTION,
        {
          selectedChartOption: PieViewActive
            ? GRAPH_OPTIONS.pie
            : GRAPH_OPTIONS.column,
        },
        insightState.currentSavedInsightDashboard.insightsId,
        metadata.benchmarkTileType
      )
    );
  };

  const getLegendColor = (i: number) => {
    return colorCodes[
      mod(
        i,
        (colorCodes ? colorCodes : commonsState.dashboardColorCodes).length
      )
    ];
  };

  const getLegendColorPie = (i: number) => {
    const colorCodesToFilter =
      benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
        ? colorCodes
        : peerColorCodes;
    const filteredColorCodes = colorCodesToFilter.filter(
      (color: string, i: number) =>
        i !== 1 &&
        i !== (selectedFilterOption === FILTEROPTION.LOCATION_BASED ? 2 : 3)
    );
    return filteredColorCodes[
      mod(
        i,
        (filteredColorCodes
          ? filteredColorCodes
          : commonsState.dashboardColorCodes
        ).length
      )
    ];
  };

  const getValueTooltip = (i: number, j: number) => {
    let value: any = filteredDataset[j].metrics[i].metricValue;
    value = Math.round(value); //Rounding off
    value = new Intl.NumberFormat().format(value); //Displaying Number in International format
    value = filteredDataset[j].companyName + " (" + value + ") ";
    if (
      benchmarkTileType === BENCHMARK_TILE_TYPES.ABSOLUTE_SCOPE3_EMISSIONS ||
      benchmarkTileType === BENCHMARK_TILE_TYPES.GHG_EMISSIONS_TOTALS ||
      benchmarkTileType === BENCHMARK_TILE_TYPES.GHG_EMISSIONS_TOTALS_ESRS
    )
      return value;
    else return filteredDataset[j].metrics[i].metricValueTooltip ?? "";
  };

  const mapBarChartData = (): GroupedLegends[] => {
    return filteredDataset.length
      ? filteredDataset[0].metrics.map(
          (metric: InsightMetricData, i: number) => ({
            groupName: metric.metricName,
            separateBars: true,
            description: metric.metricTooltip,
            legends: filteredDataset.map((data: InsightData, j) => ({
              legendValue: data.companyName,
              legendOrder: j,
              legendHideData: true,
              legendTooltip: getValueTooltip(i, j),
              legendColor: isDetailedView
                ? getInsightsDetailPeerViewColorCode(
                    commonsState.InsightsDetailedPeerViewColorCodes,
                    data.displayOrder!
                  )
                : getLegendColor(
                    benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY ? i : j
                  ),
              legendData: data.metrics[i].metricValue,
            })),
          })
        )
      : [];
  };

  const getLegendPercent = (
    metricValue: string,
    filteredMetrics: InsightData[],
    globalCompanyId: number
  ) => {
    if (metricValue === null || metricValue === "") {
      return "";
    }

    let totalValue = 0;
    let filteredComp = filteredMetrics.filter(
      (comp: InsightData) => comp.globalCompanyId === globalCompanyId
    );
    filteredComp[0].metrics.forEach((metric: InsightMetricData) => {
      if (metric.metricValue !== null) {
        totalValue = totalValue + parseInt(metric.metricValue);
      }
    });

    const metricPercent = ((parseInt(metricValue) / totalValue) * 100).toFixed(
      2
    );
    return `${metricPercent}%`;
  };

  const mapPieChartData = (): GroupedLegends[] => {
    const hiddenIndex = ghgEmissionFilteroptions.filter(
      (option: any) => option !== selectedFilterOption
    )[0];
    const filteredMetrics = filteredDataset.map((data: InsightData) => ({
      ...data,
      metrics: data.metrics.filter(
        (metric: InsightMetricData) =>
          metric.metricKey.toLowerCase() !==
          (HIDEMTETRICS as any)[hiddenIndex].toLowerCase()
      ),
    }));

    return filteredDataset.length
      ? filteredMetrics.map((data: InsightData, i: number) => ({
          groupName: data.companyName,
          legends: data.metrics.map((metric: InsightMetricData, j) => ({
            legendValue: metric.metricName,
            legendOrder: j,
            legendHideData: true,
            legendTooltip: metric.metricTooltip,
            legendColor: getLegendColorPie(j),
            legendData: metric.metricValue,
            legendDataPercent: getLegendPercent(
              metric.metricValue,
              filteredMetrics,
              data.globalCompanyId
            ),
          })),
        }))
      : [];
  };

  const mapPieTableChart = (
    filteredDataset: InsightData[],
    metadata: Visualization,
    isLeftAlinged: boolean = false,
    hideToolip: boolean = false,
    numberFormat: boolean = false
  ) => {
    const hiddenIndex = ghgEmissionFilteroptions.filter(
      (option: any) => option !== selectedFilterOption
    )[0];

    const filteredMetrics = filteredDataset.map((data: InsightData) => ({
      ...data,
      metrics: numberFormat
        ? data.metrics.filter(
            (metric: InsightMetricData) =>
              metric.metricKey.toLowerCase() !==
              (HIDEMTETRICS as any)[hiddenIndex].toLowerCase()
          )
        : data.metrics,
    }));

    let tmpResponse = filteredMetrics.reduce(
      (data: any[], current: InsightData) => {
        let currentGroup: any = {
          globalCompanyId: current.globalCompanyId,
          header: current.companyName,
        };
        current.metrics.forEach((m: InsightMetricData) => {
          const sufix = metadata.benchmarkMetadata.valueLimitProperties?.find(
            (vl: any) => vl.associatedMetricRef === m.metricKey
          );
          let metricProp =
            m.metricKey?.charAt(0).toLowerCase() + m.metricKey?.slice(1);
          let metricValue: any;
          if (m?.metricValue === null) {
            metricValue = null;
          } else
            metricValue =
              m?.metricValue + (sufix ? sufix.fieldValueSuffix : "");
          currentGroup = {
            ...currentGroup,
            [metricProp]:
              numberFormat && isNumeric(metricValue)
                ? getLegendPercent(
                    metricValue,
                    filteredMetrics,
                    currentGroup.globalCompanyId
                  )
                : metricValue
                ? Math.round(parseFloat(metricValue)).toLocaleString()
                : "*",
          };
        });

        return [
          ...data,
          {
            ...currentGroup,
          },
        ];
      },
      []
    );

    const filteredMetric = filteredMetrics[0].metrics.map((m) => m.metricKey);
    const filteredMetricDisplayNames = filteredMetrics[0].metrics.map(
      (m) => m.metricName
    );

    return {
      MSCIESGRatingView:
        metadata.benchmarkTileType === BENCHMARK_TILE_TYPES.MSCI_ESG_Rating,
      isLeftAlinged,
      tileType: metadata.benchmarkTileType,
      labels: filteredMetric
        .map((metric: string, i: number) => ({
          label: metadata ? filteredMetricDisplayNames[i] : metric,
          id: metric?.charAt(0).toLowerCase() + metric?.slice(1),
          tooltip: hideToolip
            ? []
            : metadata.associatedMetricsTooltips?.filter(
                (t: AssociatedMetricTooltips) => t.associatedMetric === metric
              ) ?? [],
        }))
        .filter(
          ({ id }: { id: string }) =>
            id !== ESG_IVA_RATING_TILE_PEER_INDUSTRY.IVA_RATING_DATE
        ),
      response: tmpResponse,
    };
  };

  const hasDate =
    response &&
    response.length > 0 &&
    associatedMetricsGroup &&
    associatedMetricsGroup.length > 0 &&
    associatedMetricsGroup[0]?.groupValues &&
    associatedMetricsGroup[0]?.groupValues.length > 0 &&
    associatedMetricsGroup[0]?.groupValues.length > 1 &&
    // the metric value can be a valid date, null or a string deemoing not applicable
    // we need to check if there is a value and if that value is a valid date
    moment(
      response.find(
        (m) =>
          m.fieldName === associatedMetricsGroup[0]?.groupValues[0] &&
          m.isBaseCompany
      )?.fieldValue ?? ""
    ).isValid() &&
    moment(
      response.find(
        (m) =>
          m.fieldName === associatedMetricsGroup[0]?.groupValues[1] &&
          m.isBaseCompany
      )?.fieldValue ?? ""
    ).isValid();

  const tileProps = {
    name: benchmarkTitle,
    index: index,
    data: mapBarChartData(),
    showSpaceWhenEmpty: true,
    exporting,
  };

  //TO DO: verify tile behavior
  const calculationPeriod = (
    <div>
      <span className="DashboardTile__dataset-info-ghg">
        <>
          <Popover
            displayText={
              (associatedMetricsGroup &&
                `${associatedMetricsGroup[0]?.groupName}:`) ??
              ""
            }
            metricClassName={`DashboardTile__dataset-info-ghg-font`}
            content={
              associatedMetricGroupTooltips
                ? associatedMetricGroupTooltips.filter(
                    (tooltip: AssociatedMetricTooltips) =>
                      tooltip.associatedMetricGroupName ===
                      associatedMetricsGroup[0]?.groupName
                  )
                : []
            }
          />

          {hasDate ? (
            response[0][associatedMetricsGroup[0]?.groupValues[0]] ===
            NOCALC_PERIOD ? (
              <span className="DashboardTile__dataset-info-period-value">
                {NOCALC_PERIOD}
              </span>
            ) : (
              <span className="DashboardTile__dataset-info-period-value">
                {` ${moment(
                  response.find(
                    (m) =>
                      m.fieldName ===
                        associatedMetricsGroup[0]?.groupValues[0] &&
                      m.isBaseCompany
                  ).fieldValue
                ).format("MM/DD/YY")}   -
                 ${moment(
                   response.find(
                     (m) =>
                       m.fieldName ===
                         associatedMetricsGroup[0]?.groupValues[1] &&
                       m.isBaseCompany
                   ).fieldValue
                 ).format("MM/DD/YY")}`}
              </span>
            )
          ) : (
            <>{"*"}</>
          )}
        </>
      </span>
    </div>
  );

  const toggleChartView = () => {
    return (
      <>
        {!exporting && (
          <div
            className={`insight-dashboard__ghgEmissionFilter`}
            data-testid="insight-dashboard__ghgEmissionFilter"
          >
            <div className={`insight-dashboard__toggle-view`}>
              <button
                type="button"
                className={classNames(`insight-dashboard__toggle`, {
                  [`insight-dashboard__toggle--active`]:
                    !isPieViewActive && !isTableViewActive,
                  [`insight-dashboard__button--disabled`]: isTableViewActive,
                })}
                onClick={() => handleChartSelection(false)}
                disabled={isTableViewActive}
                data-testid="bar-chart-btn"
              >
                <Tooltip position={TooltipPosition.bottom}>
                  <FormattedMessage
                    id="insight.tooltip.chart"
                    values={{
                      chartType: " Bar-chart",
                    }}
                  />
                </Tooltip>
                <Icon
                  className="insight-dashboard__toggle--disabled"
                  name={"bar-chart"}
                  width={20}
                  height={20}
                />
              </button>
              <button
                type="button"
                className={classNames(`insight-dashboard__toggle`, {
                  [`insight-dashboard__toggle--active`]:
                    isPieViewActive && !isTableViewActive,
                  [`insight-dashboard__button--disabled`]: isTableViewActive,
                })}
                onClick={() => handleChartSelection(true)}
                disabled={isTableViewActive}
                data-testid="pie-chart-btn"
              >
                <Tooltip position={TooltipPosition.bottom}>
                  <FormattedMessage
                    id="insight.tooltip.chart"
                    values={{
                      chartType: "Pie-chart",
                    }}
                  />
                </Tooltip>
                <Icon name={"graph"} width={20} height={20} />
              </button>
            </div>
            {isPieViewActive && (
              <div>
                <FilterType
                  values={selectedFilterOption}
                  options={ghgEmissionFilteroptions}
                  handleChange={handleOptionChange}
                  loading={false}
                  handleSelect={handleOptionChange}
                  handleUnselect={handleOptionChange}
                  key={`dropdown--insights.select.categories1`}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  if (
    dataset.every((d: InsightData) =>
      d.metrics.every((m: InsightMetricData) => !m.metricValue)
    )
  ) {
    return <TileEmptyContent />;
  }
  return isTableViewActive ? (
    <>
      {(benchmarkTileType === BENCHMARK_TILE_TYPES.GHG_EMISSIONS_TOTALS ||
        benchmarkTileType === BENCHMARK_TILE_TYPES.GHG_EMISSIONS_TOTALS_ESRS) &&
        !noData &&
        toggleChartView()}
      <TableChart
        data={mapPieTableChart(
          filteredDataset,
          metadata,
          false,
          false,
          isPieViewActive
        )}
        currentInsightView={metadata.benchmarkType}
        hasDate={
          (benchmarkTileType === BENCHMARK_TILE_TYPES.GHG_EMISSIONS_TOTALS ||
            benchmarkTileType ===
              BENCHMARK_TILE_TYPES.GHG_EMISSIONS_TOTALS_ESRS) &&
          !hasDate
            ? !hasDate
            : undefined
        }
      />
    </>
  ) : (
    <>
      {(benchmarkTileType === BENCHMARK_TILE_TYPES.GHG_EMISSIONS_TOTALS ||
        benchmarkTileType === BENCHMARK_TILE_TYPES.GHG_EMISSIONS_TOTALS_ESRS) &&
        !noData &&
        toggleChartView()}
      {!isDetailedView && (
        <DatasetLegend
          dataset={tileProps.data.length ? tileProps.data[0].legends : []}
          BLOCK={BLOCK}
          groupedData={tileProps.data}
          selectedGraphOption={selectedGraphOption}
          custom={true}
          insight={true}
          hideNoData={true}
          hideLegend={
            metadata.benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY ||
            isPieViewActive
          }
          addAsterisk={isDetailedView ? true : false}
        />
      )}
      {isDetailedView ? (
        <>
          {isPieViewActive ? (
            <div style={{ width: "100%" }}>
              <GhgEmissionPieChart
                data={mapPieChartData()}
                index={0}
                isDetailedView={isDetailedView}
                benchmarkType={benchmarkType}
              />
            </div>
          ) : (
            <>
              <DetailPeerViewLegend
                dataset={dataset}
                isDetailedView={isDetailedView}
                colorCodes={
                  isDetailedView
                    ? commonsState.InsightsDetailedPeerViewColorCodes
                    : []
                }
              />
              <MultipleBarChart {...tileProps} />
            </>
          )}
        </>
      ) : (
        <>
          {isPieViewActive && !noData ? (
            <div style={{ width: "100%" }} data-testid="ghg-mission-PieChart">
              <GhgEmissionPieChart
                data={mapPieChartData()}
                index={0}
                benchmarkType={benchmarkType}
                hasNoDate={!hasDate}
              />
            </div>
          ) : (
            <>
              <div style={{ width: "100%" }}>
                <ParentSize>
                  {({ width: visWidth, height: visHeight }) => (
                    <BarChart
                      {...tileProps}
                      width={visWidth}
                      height={visHeight}
                    />
                  )}
                </ParentSize>
              </div>

              <DatasetLegend
                dataset={tileProps.data.length ? tileProps.data[0].legends : []}
                BLOCK={BLOCK}
                groupedData={tileProps.data}
                selectedGraphOption={selectedGraphOption}
                custom={true}
                insight={true}
                hideNoData={false}
                addAsterisk={false}
                hideCompanyInfo={true}
              />
            </>
          )}
        </>
      )}
      {!hasDate &&
        (benchmarkTileType === BENCHMARK_TILE_TYPES.GHG_EMISSIONS_TOTALS ||
          benchmarkTileType ===
            BENCHMARK_TILE_TYPES.GHG_EMISSIONS_TOTALS_ESRS) &&
        !noData && (
          <div className={"DashboardTile__noDataAny"}>
            <FormattedMessage id="no.data.available" />
          </div>
        )}
    </>
  );
};

export default GHGEmissionTotals;
