import { useEffect, useRef, useState } from "react";
import Icon from "components/shared/icon/icon";
import FormattedMessage from "../formatted-message/formatted-message";
import DateRangePicker from "../date-range-picker/date-range-picker";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { updateCalendar } from "services/commons.service";
import Button from "../button/button";

type Props = {
  handleClose: Function;
  handleApply: Function;
  headerId: string;
  children?: JSX.Element;
  config?: boolean;
  iconName?: string;
  currentFilterText?: string;
  placeholder?: string;
  dateRange?: boolean;
};

const FilterPopup = ({
  handleClose,
  handleApply,
  headerId,
  config = true,
  iconName,
  currentFilterText,
  placeholder,
  dateRange,
}: Props) => {
  const BLOCK = "filter-popup";
  const [inputFilter, setInputFilter] = useState(
    currentFilterText ? currentFilterText : ""
  );
  const filterPopUpRef = useRef<any>(null);

  const [apply, setApply] = useState(false);
  const dispatch = useDispatch();

  const [state, setState] = useState<any[]>([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        filterPopUpRef.current &&
        !filterPopUpRef.current.contains(event.target)
      ) {
        handleClose();
        dispatch(updateCalendar(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPopUpRef]);

  const commonsState = useSelector((state: RootStore) => state.commons);

  useEffect(() => {
    if (dateRange && inputFilter.length >= 21) {
      const startDate = inputFilter.trim().slice(0, 10);
      const endDate = inputFilter.trim().slice(-10);
      const isEndDateAfter = moment(endDate).isAfter(startDate);
      const isValidStartDate = moment(
        startDate,
        ["MM/DD/YYYY"],
        true
      ).isValid();
      const isValidEndDate = moment(endDate, ["MM/DD/YYYY"], true).isValid();
      if (isValidStartDate && isValidEndDate && isEndDateAfter) {
        setState([
          {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            key: "selection",
          },
        ]);
        setApply(true);
      } else {
        setApply(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFilter]);

  useEffect(() => {
    if (dateRange && state[0].endDate && state[0].startDate) {
      const endDate = moment(state[0].endDate).format("MM/DD/YYYY");
      const startDate = moment(state[0].startDate).format("MM/DD/YYYY");
      const dateRanges = startDate + " - " + endDate;

      if (dateRanges && dateRange) {
        setInputFilter(dateRanges);
      }
    } else if (dateRange && !state[0].endDate) {
      setInputFilter("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return (
    <>
      <div
        className={`${BLOCK} ${BLOCK}--show`}
        data-test={"notification-handle-close"}
        data-testid={"notification-handle-close"}
        ref={filterPopUpRef}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={`${BLOCK}__content ${BLOCK}__content--show`}>
          <div className={`${BLOCK}__header`}>
            <h1>
              <FormattedMessage id={`${headerId}.filter`} />
            </h1>
            <Button
              className={`${BLOCK}__close`}
              iconName={`${iconName ? iconName : "warning-circle-blue"}`}
              iconHeight={20}
              iconWidth={20}
              onClick={(e: any) => {
                e.stopPropagation();
                handleClose();
              }}
              dataTest="close-btn"
            />
          </div>
          <div className={`${BLOCK}__body`}>
            {dateRange && (
              <span>
                {" "}
                <FormattedMessage id="date.range" />
              </span>
            )}
            <div className={`${BLOCK}__outline`}>
              <input
                autoFocus
                type="text"
                value={inputFilter}
                className={`${BLOCK}__input`}
                placeholder={dateRange ? "mm/dd/yyyy - mm/dd/yyyy" : ""}
                onChange={(e) => setInputFilter(e.target.value)}
              />
              <div className={`${BLOCK}__input-size`}>{inputFilter}</div>
              {inputFilter === "" && (
                <div className={`${BLOCK}__placeholder`}>{placeholder}</div>
              )}
              {dateRange && (
                <span
                  onClick={() => {
                    dispatch(updateCalendar(true));
                  }}
                  data-test="Calendar-icon"
                >
                  <Icon name={"calendar"} width={20} height={20} />
                </span>
              )}
            </div>

            <div className={`${BLOCK}__options`}>
              <Button
                  className={`button-secondary`}
                  disabled={(currentFilterText?.trimStart() === "" && inputFilter?.trimStart() === "") || currentFilterText === inputFilter}
                  data-test="reset-button"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setInputFilter(currentFilterText ?? "");
                    setApply(false);
                  }}
                  formattedText="reset"
              />
              <Button
                className={`button-primary`}
                disabled={!dateRange ? ((currentFilterText?.trimStart() === "" && inputFilter?.trimStart() === "") || currentFilterText === inputFilter) : !apply}
                dataTest="notification-share-search-button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleApply(e, inputFilter.trim());
                  setInputFilter("");
                }}
                formattedText="apply"
              />
            </div>
          </div>
        </div>
        {commonsState.showCalendar && (
          <>
            <DateRangePicker
              BLOCK={BLOCK}
              setState={setState}
              state={state}
              apply={apply}
              setApply={setApply}
              data-test="Date-range-Calender"
            />
          </>
        )}
      </div>
    </>
  );
};

export default FilterPopup;
