import RiskAndOpportunitiesTableView from "components/visualizations/charts/risk.opportunities.table";
import RiskAndOpportunitiesTile from "components/visualizations/charts/risk.opportunities.tile";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import {
  EXCEPTION_FOR_RISKS_OPPS_TILE,
  INSIGHT_BENCHMARK_TYPE,
} from "utils/constants";
import { useTileContext } from "../tile.context";

const RiskAndOpportunities = () => {
  const {
    dataset,
    isTableViewActive,
    metadata: {
      benchmarkMetadata,
      benchmarkType,
      isDetailedView,
      associatedMetricGroupTooltips,
    },
    associatedMetrics,
    associatedMetricsDisplayNames,
  } = useTileContext();
  const getRiskOppTileMetricValue = (dataValue: any) => {
    if (!dataValue) return null;
    return dataValue.metricValue;
  };

  const getRisksTileMetrics = (
    metric: any,
    metricId: number,
    globalCompanyId: number
  ) => {
    let flagForNoOpportunities = false;
    const metricList = metric.groupValues;
    const filteredMetricList = metricList.filter(
      (metricName: string, i: number) =>
        metricName !==
        EXCEPTION_FOR_RISKS_OPPS_TILE.ReportedIdentifiedOpportunitiesFlag
    );
    const flaggedMetric = dataset
      ?.find((d: any) => d.globalCompanyId === globalCompanyId)
      ?.metrics.find(
        (m) =>
          m.metricKey ===
          EXCEPTION_FOR_RISKS_OPPS_TILE.ReportedIdentifiedOpportunitiesFlag
      );

    if (flaggedMetric?.metricValue === "Yes" && metricId === 0) {
      if (
        filteredMetricList.every((metricName: string) => {
          let data = dataset
            ?.find((d: any) => d.globalCompanyId === globalCompanyId)
            ?.metrics.find((m) => m.metricKey === metricName);
          if (data) {
            return data?.metricValue === null;
          } else {
            return true;
          }
        })
      ) {
        flagForNoOpportunities = true;
      }
    }

    return filteredMetricList.map((metricName: string, i: number) => {
      const datasetMetric = dataset
        ?.find((d: any) => d.globalCompanyId === globalCompanyId)
        ?.metrics.find((m) => m.metricKey === metricName);
      if (
        flagForNoOpportunities &&
        datasetMetric?.metricName ===
          EXCEPTION_FOR_RISKS_OPPS_TILE.NO_OPPORTUNITIES_AVAILABLE
      ) {
        return {
          metric: datasetMetric?.metricName,
          tooltip: datasetMetric?.metricTooltip,
          value: "Yes",
        };
      }
      return {
        metric: datasetMetric?.metricName,
        tooltip: datasetMetric?.metricTooltip,
        value: getRiskOppTileMetricValue(datasetMetric),
      };
    });
  };

  const mapRiskTileData = () =>
    associatedMetrics.map((metric: any, i: number) => ({
      groupName: metric.groupName,
      groupTooltip: associatedMetricGroupTooltips?.filter(
        ({ associatedMetricGroupName }: AssociatedMetricTooltips) =>
          metric.groupName === associatedMetricGroupName
      ),
      data: dataset.map((c: any) => ({
        globalCompanyId: c.globalCompanyId,
        companyName: c.companyName,
        metrics: getRisksTileMetrics(metric, i, c.globalCompanyId),
      })),
    }));

  const mapRiskOppTableData = () => {
    return {
      labels: benchmarkMetadata.associatedMetricsTooltip.map(
        (metric: any, i: number) => ({
          label: metric.groupName,
          tooltip: associatedMetricGroupTooltips?.filter(
            ({ associatedMetricGroupName }: AssociatedMetricTooltips) =>
              metric.groupName === associatedMetricGroupName
          ),
          id: metric.groupName,
          groupValues: metric.groupValues,
          groupMetric: (associatedMetricsDisplayNames[i] as any).groupValues,
        })
      ),
      response: dataset.map((c: any) => ({
        header: c.companyName,
        globalCompanyId: c.globalCompanyId,
        companyName: c.companyName,
        data: associatedMetrics.map((metric: any, i: number) => {
          return {
            groupName: metric.groupName,
            metrics: getRisksTileMetrics(metric, i, c.globalCompanyId),
          };
        }),
      })),
    };
  };

  const tileProps = {
    data: mapRiskTileData(),
    metricDisplayNames: associatedMetricsDisplayNames,
    colorCodes: benchmarkMetadata.colorCodes,
    currentInsightView: benchmarkType,
    isDetailedView,
    isTableViewActive,
  };

  return isTableViewActive &&
    benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY ? (
    <RiskAndOpportunitiesTableView
      data={
        benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY
          ? {
              data: tileProps.data,
              metricGroupValues: associatedMetrics,
              metricGroupDisplayNames: associatedMetricsDisplayNames,
            }
          : mapRiskOppTableData()
      }
      currentInsightView={benchmarkType}
    />
  ) : (
    <RiskAndOpportunitiesTile {...tileProps} />
  );
};

export default RiskAndOpportunities;
