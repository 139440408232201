import { useRef } from "react";
import { throttle } from "lodash";

const useDnDScrolling = (dashboardContainerRef: any) => {
  const isScrolling = useRef(false);
  const cancel = useRef(false);
  const scrollDown = () => {
    dashboardContainerRef.current.scrollTop += 6;

    const { offsetHeight, scrollTop, scrollHeight } =
      dashboardContainerRef.current;

    const isScrollEnd = offsetHeight + scrollTop >= scrollHeight;

    if (isScrolling.current && !isScrollEnd) {
      window.requestAnimationFrame(scrollDown);
    }
  };

  const scrollUp = () => {
    dashboardContainerRef.current.scrollTop -= 6;

    if (isScrolling.current && dashboardContainerRef.current.scrollTop > 0) {
      window.requestAnimationFrame(scrollUp);
    }
  };

  const onDragOver = (event: any) => {
    const isMouseOnTop = event.clientY < 250;
    const isMouseOnDown = window.innerHeight - event.clientY < 200;

    if ((isMouseOnTop || isMouseOnDown) && !cancel.current) {
      isScrolling.current = true;
    }

    if (isScrolling.current) {
      if (isMouseOnTop) {
        window.requestAnimationFrame(scrollUp);
      }
      if (isMouseOnDown) {
        window.requestAnimationFrame(scrollDown);
      }
    }
    if (!isMouseOnTop && !isMouseOnDown) {
      isScrolling.current = false;
    }

    event.stopPropagation();
  };

  const throttleOnDragOver = throttle(onDragOver, 300);

  const addEventListenerForWindow = () => {
    window.addEventListener("dragover", throttleOnDragOver, false);
    isScrolling.current = true;
    cancel.current = false;
  };

  const removeEventListenerForWindow = () => {
    window.removeEventListener("dragover", throttleOnDragOver, false);
    isScrolling.current = false;
    cancel.current = true;
  };

  return {
    addEventListenerForWindow,
    removeEventListenerForWindow,
  };
};

export default useDnDScrolling;
