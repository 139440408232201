import { useEffect } from "react";
import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { FILE_FORMATS, UPLOAD_STATUS } from "utils/constants";
import Icon from "components/shared/icon/icon";
import { ErrorMessage } from "services/manage-files/manage-files.model";
type props = {
  BLOCK: string;
  item: any;
  index: number;
};

const UploadItem = ({ BLOCK, item, index }: props) => {
  
  useEffect(() => {}, [item.progress]);

  
  const getTypeIcon = (pathName: string) => {
    let name = "";
    let fileType = pathName.split(".").pop()?.toUpperCase();
    name = FILE_FORMATS.find((f) => f.label === fileType)?.icon || "pdf";
    return (
      <div className={`${BLOCK}__file-type-icon`}>
        <Icon width={15} height={16} name={name} />
      </div>
    );
  };

  const getProgressFileStatus = () => {
    return `upload.files.${item.progressStatus}`;
  };

  const getFileName = () => {
    let tmpName = item.file.name;
    return (
      <div
        className={classNames({
          [`${BLOCK}__file-name-wrapper`]: index > 2,
          [`${BLOCK}__file-name-wrapper--bottom`]: index <= 2,
        })}
      >
        <span className={`${BLOCK}__file-name`}>
          {tmpName.length > 45 ? `${tmpName.slice(0, 45)}...` : tmpName}
          {tmpName.length > 45 && (
            <Tooltip
              position={
                index > 2 ? TooltipPosition.top : TooltipPosition.bottom
              }
            >
              {tmpName}
            </Tooltip>
          )}
        </span>
        &nbsp;
        <span className={`${BLOCK}__file-name`}>
          <FormattedMessage id={getProgressFileStatus()} />
        </span>
      </div>
    );
  };

  return (
    <div
      key={`${item.file.size}-${index}`}
      className={`${BLOCK}__file-container`}
    >
      <div className={`${BLOCK}__file`}>
        <div className={`${BLOCK}__file-data`}>
          {item.file.name && getTypeIcon(item.file.name)}
          {getFileName()}
        </div>
        <div
          className={classNames(`${BLOCK}__file-status-icon`, {
            [`${BLOCK}__file-status-icon--failed`]:
              item.progressStatus === UPLOAD_STATUS.FAILED,
          })}
        >
          {item.progressStatus === UPLOAD_STATUS.UPLOADED && (
            <Icon name="tick" width={24} height={24} />
          )}
          {item.progressStatus === UPLOAD_STATUS.FAILED && (
            <Icon name="cross" width={24} height={24} />
          )}
        </div>
      </div>
      <div
        style={{ width: `${item.progress}%` }}
        className={classNames(`${BLOCK}__progress-bar`, {
          [`${BLOCK}__progress-bar--failed`]:
            item.progressStatus === UPLOAD_STATUS.FAILED,
          [`${BLOCK}__progress-bar--uploaded`]:
            item.progressStatus === UPLOAD_STATUS.UPLOADED,
          [`${BLOCK}__progress-bar--uploading`]:
            item.progressStatus === UPLOAD_STATUS.UPLOADING,
        })}
      ></div>
      {item.errorMessages.length > 0 && (
        <div className={`${BLOCK}__file-errors-messages-wrapper`}>
          {item.errorMessages.map((message: ErrorMessage, index: number) => (
            <span key={index} className={`${BLOCK}__file-error-message`}>
              {message.text}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default UploadItem;
