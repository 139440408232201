import HorizontalGraduatedChart from "components/visualizations/charts/horizontal.graduated.chart";
import { mapTableChart, useTileContext } from "../tile.context";
import {
  getFormattedNumber,
  getInsightsDetailPeerViewColorCode,
} from "utils/functions";
import TileEmptyContent from "../tile-empty-content";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import TableChart from "components/visualizations/charts/table-chart";
import {
  INSIGHT_BENCHMARK_TYPE,
  INSIGHT_BENCHMARK_ID_ARRAY,
} from "utils/constants";
import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import HorizontalInsightsBarChart from "components/visualizations/charts/horizontal-insights-bar.chart";
import { isNumeric } from "utils/functions";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import DetailPeerViewLegend from "./DetailPeerViewLegend";

const WorkforceRelatedScore = () => {
  const {
    dataset,
    metadata,
    metadata: {
      benchmarkType,
      isDetailedView,
      benchmarkMetadata,
      benchmarkTileType,
    },
    isTableViewActive,
  } = useTileContext();

  const BLOCK = "workforceRelatedScore";
  const conditionalColorCodes = benchmarkMetadata?.conditionalColorCodes;
  const colorCodes = benchmarkMetadata.colorCodes;
  const primaryAxisValues = benchmarkMetadata?.axis?.primary;
  const commonsState = useSelector((state: RootStore) => state.commons);
  if (
    dataset.every((d: InsightData) =>
      d.metrics.every((m: InsightMetricData) => !m.metricValue)
    )
  )
    return <TileEmptyContent />;

  const hasEmptyValues = dataset.some((d: InsightData) =>
    d.metrics.some(
      (m: InsightMetricData) => m.metricValue === null || m.metricValue === "*"
    )
  );

  const noData = dataset.every((d: InsightData) =>
    d.metrics.every(
      (m: InsightMetricData) => m.metricValue === null || m.metricValue === "*"
    )
  );

  const mapDetailedViewData = () => {
    return dataset.length > 0
      ? dataset.map((data: InsightData, i: number) => ({
          legendValue: data.companyName,
          legendOrder: i,
          legendColor: getInsightsDetailPeerViewColorCode(
            commonsState.InsightsDetailedPeerViewColorCodes,
            data.displayOrder!
          ),
          legendData: isNumeric(parseFloat(data.metrics[0].metricValue))
            ? parseFloat(data.metrics[0].metricValue)
            : null,
        }))
      : [];
  };

  const mapChartData = () => {
    return dataset.map((d: InsightData, i: number) => ({
      ...d,
      metrics: d.metrics.map((m: InsightMetricData) => ({
        ...m,
        metricValue:
          m.metricValue === null
            ? m.metricValue
            : getFormattedNumber(m.metricValue, 0, 1, true),
        metricValueColor: INSIGHT_BENCHMARK_ID_ARRAY.includes(d.globalCompanyId)
          ? conditionalColorCodes
          : colorCodes[1],
      })),
    }));
  };

  const tileChartProps = {
    benchmarkType,
    benchmarkTileType,
    benchmarkSubTitle: benchmarkMetadata?.benchmarkSubTitle,
    gradientBackgroundValue: `var(--gradient-2, linear-gradient(270deg, ${
      colorCodes[0].split(",")[1]
    } 0%, ${colorCodes[0].split(",")[0]} 100%))`,
    data: mapChartData(),
    legendgradientBackground: `linear-gradient(270deg, ${
      colorCodes[0].split(",")[1]
    } 0%, ${colorCodes[0].split(",")[0]} 100%)`,
  };

  const showBenchmarkSubTitle = () => {
    return (
      <span className={`${BLOCK}__legend-text`}>
        {benchmarkMetadata.benchmarkSubTitle}
      </span>
    );
  };

  return (
    <div className={`${BLOCK}__container`} data-testid={`${BLOCK}__container`}>
      {isDetailedView && !isTableViewActive ? (
        <div className={`${BLOCK}__detailedView`}>
          <div
            className={classNames(
              `${BLOCK}__table-wrapper ${BLOCK}__table-wrapper--company-peer`
            )}
          >
            {!noData && showBenchmarkSubTitle()}
            {!noData && (
              <DetailPeerViewLegend
                dataset={dataset}
                isDetailedView={isDetailedView}
                colorCodes={
                  isDetailedView
                    ? commonsState.InsightsDetailedPeerViewColorCodes
                    : []
                }
              />
            )}
            {noData ? (
              <div className="main-tile-empty__container">
                <span className="main-tile-empty__label">
                  <FormattedMessage id="insights.cdp.no-data" />
                </span>
              </div>
            ) : (
              <ParentSize
                className={classNames(`${BLOCK}__content`)}
                debounceTime={100}
              >
                {({ width: visWidth, height: visHeight }) => {
                  const barChartProps = {
                    name: `${BLOCK}-DetailedPeerView`,
                    axis: { primary: primaryAxisValues, secondary: [] },
                    width: visWidth,
                    height: visHeight,
                    descSort: false,
                    handleGraphElementClicked: () => {},
                    data: mapDetailedViewData(),
                  };
                  return <HorizontalInsightsBarChart {...barChartProps} />;
                }}
              </ParentSize>
            )}
          </div>
          {hasEmptyValues && (
            <div className={`${BLOCK}__no-data-section`}>
              <span>
                <FormattedMessage id="no.data.available" />
              </span>
            </div>
          )}
        </div>
      ) : isTableViewActive ? (
        <div
          className={classNames(
            `${BLOCK}__table-wrapper ${BLOCK}__table-wrapper--company-peer`
          )}
        >
          {showBenchmarkSubTitle()}
          <TableChart
            data={mapTableChart(dataset, metadata)}
            currentInsightView={benchmarkType}
            hideTableHeader={benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY}
            horizontalTable={benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY}
            rightAlignment={true}
          />
        </div>
      ) : (
        <HorizontalGraduatedChart {...tileChartProps} />
      )}
    </div>
  );
};

export default WorkforceRelatedScore;
