import CustomBenchmarkHeader from "./side-custom-benchmark-header";
import CheckboxContainer from "../shared/checkbox-container/checkbox-container";
import ActionContainer from "../shared/action-container/actions-container";
import {
  applyCustomBenchmark,
  createCustomBenchmarkTrackButton,
  createCustomBenchmarkTrackTile,
  fetchCheckboxData,
  getPeerCompanyFilter,
  loadEditBenchmarkData,
  loadPeerCompanies,
  loadSuggestedPeerCompanyFilter,
  resetBenchmark,
  setPeerCompanyFilter,
  setSelectedCheckboxGroupItemIds,
  setSelectedCheckboxItemIds,
  showEditBenchmark,
  showSidedashboardTab,
  updateSelectedItems,
  updateSelectedPeerGroupItems,
} from "services/dashboard/dashboard.service";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { debounce } from "lodash";
import {
  loadCompanyFilter,
  setCompanyFilter,
} from "services/dashboard/dashboard.service";
import {
  BenchmarkActionTypes,
  COMPANY_IDENTIFIER,
  DEFAULT_PEER_GROUP_LIST_PAYLOAD,
  FILTER_OPTIONS,
  PEER_GROUP_CREATION_PAGE,
  SIDE_CUSTOM_TYPE_EDIT,
  SIDE_CUSTOM_TYPE_SAVE,
} from "utils/constants";
import { CompanyFilter } from "services/search/filters.model";
import DropdownContainer from "components/shared/dropdown-container/dropdown-container";
import PeerCompanies from "components/shared/peer-companies/peer-companies";
import Icon from "components/shared/icon/icon";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import SuggestedPeers from "components/shared/suggested-peers/suggested-peers";
import classNames from "classnames";
import {
  BenchmarkCompanies,
  CustomMetricsData,
  FieldGroupMetricData,
} from "services/dashboard/dashboard.model";
import moment from "moment";
import SavedPeerModal from "components/shared/save-peer-modal/save-peer-modal";
import {
  baselineCompanyForPeerGroupPayload,
  CompanyGroupCompany,
  PeerGroup,
  peerGroupCompanies,
  savePeerGroupNamePayload,
} from "services/insights/insights.model";
import {
  loadSavedPeerGroupList,
  savePeerGroup,
  setPeerGroupSavedSuccess,
} from "services/insights/insights.service";
import { addToastMessage } from "services/commons.service";

type props = {
  BLOCK: string;
  type?: string;
  fromTile?: boolean;
};

const SideCustomBenchmark = ({ BLOCK, type, fromTile }: props) => {
  const [disabledButtonOne, setDisabledButtonOne] = useState<boolean>(true);
  const [disbaledButtonTwo, setDisabledButtonTwo] = useState<boolean>(true);
  const [savedPeers, setSavedPeers] = useState<boolean>(false);
  const [selectedPeerCompany, setSelectedPeerCompany] = useState<
    CompanyFilter[]
  >([]);
  const dispatch = useDispatch();
  const dashboardState = useSelector((state: RootStore) => state.dashboard);
  const insightsState = useSelector((state: RootStore) => state.insights);
  const [suggestedPeers, setSuggestedPeers] = useState<boolean>(false);
  const [benchMarkName, setBenchmarkName] = useState<string>("");
  const [, setSelectedCheckboxPeerGroup] = useState<any[]>([]);
  const [, setPeerGroups] = useState<PeerGroup[]>([]);
  const [peerGroupsLoaded, setPeerGroupsLoaded] = useState<boolean>(false);
  const [companiesUnselected, setCompaniesUnselected] = useState<number[]>([]);

  const [isBaseCompanySuggested, setIsBaseCompanySuggested] =
    useState<boolean>(false);

  const trimStartFunc = (value: any) => {
    if (!value) return value;
    return value.replace(/^\s+/g, "");
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBenchmarkName(event.target.value);
  };

  useEffect(() => {
    dispatch(loadSavedPeerGroupList(DEFAULT_PEER_GROUP_LIST_PAYLOAD));
    dispatch(updateSelectedPeerGroupItems([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (insightsState.savedPeerGroupList && !peerGroupsLoaded) {
      setPeerGroups(insightsState.savedPeerGroupList);
      setPeerGroupsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.savedPeerGroupList]);

  useEffect(() => {
    // Fetch Checkbox data at initial render of the component
    if (!dashboardState.editBenchmarkData) {
      dispatch(fetchCheckboxData());
      // dispatch(resetBenchmark(SIDE_CUSTOM_TYPE_SAVE));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    // Enabling/Disabling Button 1
    if (dashboardState.showSideDashboard) {
      if (
        (dashboardState.selectedCheckboxIds &&
          dashboardState.selectedCheckboxIds.length !== 0) ||
        (dashboardState.selectedGroupCheckboxIds &&
          dashboardState.selectedGroupCheckboxIds.length !== 0) ||
        (dashboardState.companyFilter &&
          dashboardState.companyFilter.length !== 0) ||
        (dashboardState.peerCompanyFilter &&
          dashboardState.peerCompanyFilter.length !== 0)
      ) {
        setDisabledButtonOne(false);
      } else if (!disabledButtonOne) {
        setDisabledButtonOne(true);
      }

      // Enabling/Disbaling Button 2
      if (
        ((dashboardState.selectedCheckboxIds &&
          dashboardState.selectedCheckboxIds.length !== 0) ||
          (dashboardState.selectedGroupCheckboxIds &&
            dashboardState.selectedGroupCheckboxIds.length !== 0)) &&
        dashboardState.companyFilter &&
        dashboardState.companyFilter.length !== 0 &&
        dashboardState.peerCompanyFilter &&
        dashboardState.peerCompanyFilter.length !== 0
      ) {
        setDisabledButtonTwo(false);
      } else {
        setDisabledButtonTwo(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dashboardState.selectedCheckboxIds,
    dashboardState.selectedGroupCheckboxIds,
    dashboardState.companyFilter,
    dashboardState.peerCompanyFilter,
  ]);

  const compareArrays = (arrayOne: number[], arrayTwo: number[]) => {
    let flag = true;
    arrayOne.forEach((item) => {
      if (!arrayTwo.includes(item)) {
        flag = false;
      }
    });

    return flag;
  };

  useEffect(() => {
    if (dashboardState.showEditBenchmarkTab) {
      if (
        benchMarkName &&
        dashboardState.companyFilter &&
        dashboardState.companyFilter.length !== 0 &&
        dashboardState.peerCompanyFilter &&
        dashboardState.peerCompanyFilter.length !== 0
      ) {
        setDisabledButtonTwo(false);
      } else if (!disbaledButtonTwo) {
        setDisabledButtonTwo(true);
      }

      if (
        (benchMarkName && benchMarkName.length) ||
        (dashboardState.companyFilter &&
          dashboardState.companyFilter.length !== 0) ||
        (dashboardState.peerCompanyFilter &&
          dashboardState.peerCompanyFilter.length !== 0)
      ) {
        setDisabledButtonOne(false);
      }
      //  else {
      //     // setDisabledButtonOne(true);
      // }
    }

    if (
      benchMarkName &&
      dashboardState.editBenchmarkData &&
      dashboardState.editBenchmarkData.benchmarkTitle &&
      benchMarkName === dashboardState.editBenchmarkData.benchmarkTitle &&
      dashboardState.companyFilter &&
      dashboardState.companyFilter.length &&
      dashboardState.editBenchmarkData.companyFilter &&
      dashboardState.companyFilter[0].cikNumber ===
        dashboardState.editBenchmarkData.companyFilter.cikNumber &&
      dashboardState.peerCompanyFilter &&
      dashboardState.peerCompanyFilter.length &&
      dashboardState.editBenchmarkData.peerCompanyFilter &&
      dashboardState.peerCompanyFilter.length ===
        dashboardState.editBenchmarkData.peerCompanyFilter.length &&
      compareArrays(
        dashboardState.peerCompanyFilter.map(
          (comp: CompanyFilter) => comp.cikNumber
        ),
        dashboardState.editBenchmarkData.peerCompanyFilter.map(
          (comp: { cikNumber: any }) => comp.cikNumber
        )
      )
    ) {
      setDisabledButtonOne(true);
      setDisabledButtonTwo(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    benchMarkName,
    dashboardState.showEditBenchmarkTab,
    dashboardState.selectedCheckboxIds,
    dashboardState.selectedGroupCheckboxIds,
    dashboardState.companyFilter,
    dashboardState.peerCompanyFilter,
  ]);

  useEffect(() => {
    if (
      dashboardState.editBenchmarkData &&
      dashboardState.editBenchmarkData.benchmarkTitle
    ) {
      setBenchmarkName(dashboardState.editBenchmarkData.benchmarkTitle);
    }
  }, [dashboardState.editBenchmarkData]);

  useEffect(() => {
    setSelectedPeerCompany(dashboardState.peerCompanyFilter);
  }, [dashboardState.peerCompanyFilter, savedPeers]);

  const applyBenchmarkHandler = () => {
    let displayOrder = 1;
    let benchmarkCompanies: BenchmarkCompanies[] = [];
    let baseCompany: BenchmarkCompanies = {
      cikNumber: dashboardState.companyFilter[0].cikNumber,
      isBaseCompany: true,
      displayOrder: displayOrder,
      globalCompanyId: dashboardState.companyFilter[0].globalCompanyId,
      isSuggestedPeerCompany: false,
      globalCompany: {
        cikNumber: dashboardState.companyFilter[0].cikNumber,
        companyName: dashboardState.companyFilter[0].companyName,
        globalCompanyId: dashboardState.companyFilter[0].globalCompanyId,
      },
    };

    benchmarkCompanies.push(baseCompany);
    dashboardState.peerCompanyFilter.forEach((company) => {
      let peerCompany: BenchmarkCompanies = {
        cikNumber: company.cikNumber,
        isBaseCompany: false,
        globalCompanyId: company.globalCompanyId,
        displayOrder: ++displayOrder,
        isSuggestedPeerCompany:
          company.isSuggestedPeerCompany === true ? true : false,
        globalCompany: {
          cikNumber: company.cikNumber,
          companyName: company.companyName,
          globalCompanyId: company.globalCompanyId,
        },
      };
      benchmarkCompanies.push(peerCompany);
    });

    // Setting up metrics payload
    let metricsDataPayload: {
      fieldName: string | null;
      fieldGroupName: string | null;
      displayOrder: number;
      displayName: string;
    }[] = [];
    displayOrder = 0;
    dashboardState.selectedCheckboxIds.forEach((id) => {
      let metricItem = dashboardState.checkboxdata.map(
        (item: CustomMetricsData) => {
          if (item.fields && item.fields.length > 0) {
            return item.fields.filter((field: any) => field.fieldId === id)
              .length > 0
              ? item.fields.filter((field: any) => field.fieldId === id)[0]
              : null;
          } else {
            return false;
          }
        }
      );
      if (metricItem && metricItem.length > 0) {
        metricsDataPayload.push({
          fieldName: metricItem[0]?.fieldName,
          fieldGroupName: null,
          displayOrder: ++displayOrder,
          displayName: `${metricItem[0]?.displayName}`,
        });
      }
    });
    dashboardState.selectedGroupCheckboxIds.forEach((id) => {
      let metricItem = dashboardState.checkboxdata.map(
        (item: CustomMetricsData) => {
          if (item.fieldGroups && item.fieldGroups.length > 0) {
            return item.fieldGroups.filter(
              (fieldGroup: FieldGroupMetricData) =>
                fieldGroup.fieldGroupId === id
            ).length > 0
              ? item.fieldGroups.filter(
                  (fieldGroup: FieldGroupMetricData) =>
                    fieldGroup.fieldGroupId === id
                )[0]
              : null;
          }
          return false;
        }
      );
      if (metricItem && metricItem.length > 0) {
        metricsDataPayload.push({
          fieldName: null,
          fieldGroupName: metricItem[0]?.fieldGroupName,
          displayOrder: ++displayOrder,
          displayName: `${metricItem[0]?.displayName}`,
        });
      }
    });

    if (dashboardState.showEditBenchmarkTab) {
      let payload = {
        benchmark: {
          benchmarkId: dashboardState.editBenchmarkData.benchmarkId,
          benchmarkTitle: trimStartFunc(benchMarkName),
          benchmarkType: 1,
          benchmarkCompanies,
          metrics: metricsDataPayload,
          benchmarkMetadata: dashboardState.editBenchmarkData.benchmarkMetadata,
          description: dashboardState.editBenchmarkData.description,
        },
        trackingType: BenchmarkActionTypes.Edit_Benchmark_Custom,
      };

      dispatch(applyCustomBenchmark(payload, (type = "edit")));
      dispatch(showEditBenchmark(false));
    } else {
      let payload = {
        benchmarkTitle: `${dashboardState.companyFilter[0].companyName} ${moment
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")}`,
        benchmarkType: 1,
        benchmarkCompanies,
        metrics: metricsDataPayload,
      };
      dispatch(applyCustomBenchmark(payload, (type = "save")));
      dispatch(showSidedashboardTab(false));
      dispatch(resetBenchmark(SIDE_CUSTOM_TYPE_SAVE));
      dispatch(
        fromTile
          ? createCustomBenchmarkTrackTile(true)
          : createCustomBenchmarkTrackButton(true)
      );
    }
  };

  const resetBenchmarkHandler = () => {
    if (type && type === "edit") {
      dispatch(loadEditBenchmarkData(dashboardState.editBenchmarkData));
      setBenchmarkName(dashboardState.editBenchmarkData.benchmarkTitle);
    } else {
      dispatch(
        resetBenchmark(
          type && type === "edit"
            ? SIDE_CUSTOM_TYPE_EDIT
            : SIDE_CUSTOM_TYPE_SAVE
        )
      );
      setBenchmarkName("");
    }
    dispatch(updateSelectedPeerGroupItems([]));
  };

  const companiesUnselectedHandler = (punseletedCompanies: number[]) => {
    let tmpCompaniesUnselected = [...companiesUnselected].concat(
      punseletedCompanies.filter(
        (comp: number) => !companiesUnselected.includes(comp)
      )
    );

    setCompaniesUnselected(tmpCompaniesUnselected);
  };

  const addSelected = (
    selectedChekbox: number[],
    selectedPeerGroups: number[]
  ) => {
    let tmpIsBaseCompanySuggested = false;
    let peerCompanies: CompanyFilter[] = [...dashboardState.peerCompanyFilter];

    peerCompanies = peerCompanies.filter(
      (pcf: CompanyFilter) => !companiesUnselected.includes(pcf.cikNumber)
    );

    for (let i = 0; i < dashboardState.suggestedPeerCompanyFilter.length; i++) {
      const { cikNumber } = dashboardState.suggestedPeerCompanyFilter[i];
      if (
        !selectedChekbox.includes(cikNumber) &&
        !!peerCompanies.find((peerComp) => peerComp.cikNumber === cikNumber)
      ) {
        peerCompanies = peerCompanies.filter(
          (peer) => peer.cikNumber !== cikNumber
        );
      }
    }

    selectedChekbox.forEach((cikNumber) => {
      if (!peerCompanies.find((peerComp) => peerComp.cikNumber === cikNumber)) {
        const company = dashboardState.suggestedPeerCompanyFilter.find(
          (peerComp) => peerComp.cikNumber === cikNumber
        );
        if (company) {
          company.isSuggestedPeerCompany = true;
          peerCompanies.push(company);
        }
      }
    });
    if (selectedPeerGroups && selectedPeerGroups.length) {
      setSelectedCheckboxPeerGroup(selectedPeerGroups);
      let tmpPeerGroupSelection = insightsState.savedPeerGroupList.filter(
        (group: PeerGroup) => selectedPeerGroups.includes(group.companyGroupId)
      );

      tmpPeerGroupSelection.forEach((pg: PeerGroup) => {
        pg.companyGroupCompanies.forEach(
          (companyGroup: CompanyGroupCompany) => {
            if (
              !peerCompanies.find(
                (companyFilter: CompanyFilter) =>
                  companyFilter.cikNumber === companyGroup.cikNumber
              ) &&
              companyGroup.cikNumber !==
                dashboardState.companyFilter[0].cikNumber
            ) {
              peerCompanies.push({
                companyName: companyGroup.company?.companyName ?? "",
                companyId: companyGroup.company?.companyId ?? 0,
                externalCompanyId: companyGroup.company?.externalCompanyId ?? 0,
                cikNumber: companyGroup.cikNumber,
                ticker: companyGroup.company?.ticker ?? "",
                isSuggestedPeerCompany: true,
                globalCompanyId: companyGroup?.company?.globalCompanyId,
              });
            }
            if (
              companyGroup.cikNumber ===
              dashboardState.companyFilter[0].cikNumber
            ) {
              tmpIsBaseCompanySuggested = true;
            }
          }
        );
      });
    }

    if (isBaseCompanySuggested !== tmpIsBaseCompanySuggested)
      setIsBaseCompanySuggested(tmpIsBaseCompanySuggested);
    dispatch(setPeerCompanyFilter(peerCompanies));

    setSuggestedPeers(false);
  };

  const dropdownHeaders: {
    label: string;
    placeholder: string;
    values: CompanyFilter[];
    options: CompanyFilter[];
    loading: FILTER_OPTIONS[];
    textValue: string;
    type: string;
    showOnEmpty: boolean;
  }[] = [
    {
      label: "select.benchmark.company",
      placeholder: "filter.company.placeholder",
      values: dashboardState.companyFilter,
      options: dashboardState.companyFilterOptions,
      loading: dashboardState.loadingFilter,
      textValue: dashboardState.companyFilter[0]?.companyName
        ? dashboardState.companyFilter[0].companyName
        : "",
      type: "baseline",
      showOnEmpty: false,
    },
    {
      label: "select.benchmark.peers",
      placeholder: "filter.company.placeholder",
      values: dashboardState.peerCompanyFilter,
      options:
        dashboardState.companyFilter.length > 0
          ? dashboardState.peerCompanyFilterOptions?.filter(
              (peerCompany: CompanyFilter) =>
                peerCompany.cikNumber !==
                dashboardState.companyFilter[0].cikNumber
            )
          : dashboardState.peerCompanyFilterOptions,
      loading: dashboardState.loadingFilter,
      textValue: "",
      type: "peers",
      showOnEmpty: false,
    },
  ];

  const dropdownHeadersForSavePeerModal: {
    label: string;
    placeholder: string;
    values: CompanyFilter[];
    options: CompanyFilter[];
    loading: FILTER_OPTIONS[];
    textValue: string;
    type: string;
    showOnEmpty?: boolean;
  }[] = [
    {
      label: "peer.group.add.more",
      placeholder: "filter.company.placeholder",
      values: selectedPeerCompany,
      options:
        dashboardState.companyFilter.length > 0
          ? dashboardState.peerCompanyFilterOptions?.filter(
              (peerCompany: CompanyFilter) =>
                peerCompany.cikNumber !==
                dashboardState.companyFilter[0].cikNumber
            )
          : dashboardState.peerCompanyFilterOptions,
      loading: dashboardState.loadingFilter,
      textValue: "",
      type: "peers",
      showOnEmpty: false,
    },
  ];

  const debounceChangeHandler = useMemo(() => {
    return debounce((e: any, type: any) => {
      if (type === "baseline") {
        dispatch(loadCompanyFilter(e.target.value));
      } else {
        dispatch(getPeerCompanyFilter(e.target.value));
      }
    }, 300);
  }, [dispatch]);

  const selectCompany = (companyFilter: CompanyFilter, type: any) => {
    if (type === "peers") {
      dispatch(
        setPeerCompanyFilter([
          ...dashboardState.peerCompanyFilter,
          companyFilter,
        ])
      );
      dispatch(
        updateSelectedItems([
          ...dashboardState.selectedItems,
          companyFilter.cikNumber,
        ])
      );
    } else {
      dispatch(setCompanyFilter([companyFilter]));
      dispatch(loadSuggestedPeerCompanyFilter(companyFilter.cikNumber));
    }
  };

  const unselectCompany = (companyFilter: CompanyFilter, type: string) => {
    dispatch(
      setPeerCompanyFilter(
        dashboardState.peerCompanyFilter.filter(
          ({ cikNumber }) => cikNumber !== companyFilter.cikNumber
        )
      )
    );

    dispatch(
      loadPeerCompanies(
        dashboardState.peerCompanyFilterOptions.filter(
          ({ cikNumber }) => cikNumber !== companyFilter.cikNumber
        )
      )
    );
    dispatch(
      updateSelectedItems(
        dashboardState.selectedItems.filter(
          (item: number) => item !== companyFilter.cikNumber
        )
      )
    );

    if (dashboardState.selectedPeerGroupItems.length > 0) {
      let tmpSelectedCheckboxPeerGroup = insightsState.savedPeerGroupList
        .filter((pg: PeerGroup) =>
          dashboardState.selectedPeerGroupItems.includes(pg.companyGroupId)
        )
        .filter((pg: PeerGroup) =>
          pg.companyGroupCompanies.every(
            (cgc: CompanyGroupCompany) =>
              cgc.cikNumber !== companyFilter.cikNumber
          )
        )
        .map((pg: PeerGroup) => pg.companyGroupId);
      setSelectedCheckboxPeerGroup(tmpSelectedCheckboxPeerGroup);
      dispatch(updateSelectedPeerGroupItems(tmpSelectedCheckboxPeerGroup));
    }
  };

  const unSelectCompanyFromFilter = (pcikNumberCompanies: number[] = []) => {
    dispatch(
      setPeerCompanyFilter(
        dashboardState.peerCompanyFilter.filter(
          ({ cikNumber }) => !pcikNumberCompanies.includes(cikNumber)
        )
      )
    );

    dispatch(
      loadPeerCompanies(
        dashboardState.peerCompanyFilterOptions.filter(
          ({ cikNumber }) => !pcikNumberCompanies.includes(cikNumber)
        )
      )
    );
  };

  useEffect(() => {
    if (insightsState.insightPeerGroupSuccess) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id={"save.peer.group.success"} />,
        })
      );
      dispatch(setPeerGroupSavedSuccess(false));
      dispatch(loadSavedPeerGroupList(DEFAULT_PEER_GROUP_LIST_PAYLOAD));
      setPeerGroupsLoaded(false);
      setSavedPeers(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.insightPeerGroupSuccess]);

  const SavePeerGroupHandler = (peerGroupName: string) => {
    let displayOrder = 0;
    let baselineCompany: baselineCompanyForPeerGroupPayload = {
      cikNumber: dashboardState.companyFilter[0]
        ? dashboardState.companyFilter[0].cikNumber
        : "",
      company: dashboardState.companyFilter[0] ?? "",
    };

    let companyGroupCompanies: peerGroupCompanies[] = [];

    selectedPeerCompany.forEach((company) => {
      let peerCompany: any = {
        cikNumber: company.cikNumber,
        displayOrder: ++displayOrder,
        isSuggestedPeerCompany:
          company.isSuggestedPeerCompany === true ? true : false,
        company: company,
        globalCompanyId: company.globalCompanyId,
      };
      companyGroupCompanies.push(peerCompany);
    });

    let payload: savePeerGroupNamePayload = {
      companyGroupName: peerGroupName,
      baselineCompanyCikNumber: dashboardState.companyFilter[0]
        ? dashboardState.companyFilter[0].cikNumber
        : "",
      baselineCompany,
      companyGroupCompanies,
    };
    dispatch(savePeerGroup(payload, PEER_GROUP_CREATION_PAGE.DASHBOARD));
  };

  const handleUnselectCompany = (
    companyFilter: CompanyFilter,
    type: string
  ) => {
    if (type === "peers") {
      dispatch(
        setPeerCompanyFilter(
          dashboardState.peerCompanyFilter.filter(
            ({ cikNumber }) => cikNumber !== companyFilter.cikNumber
          )
        )
      );
      dispatch(
        updateSelectedItems(
          dashboardState.selectedItems.filter(
            (item: number) => item !== companyFilter.cikNumber
          )
        )
      );
    } else {
      //changed to this since the filter can only have one selection anyways and that is what the drop down component was doing
      dispatch(setCompanyFilter([]));
    }
  };

  return (
    <div className={`${BLOCK}`} data-test="side-cutom-benchmark">
      <div className={`${BLOCK}__header-section`}>
        <CustomBenchmarkHeader BLOCK={`${BLOCK}__header`} type={type} />
      </div>
      <div className={`${BLOCK}__body`}>
        {type === "edit" && (
          <div
            className={`${BLOCK}__edit-benchmark`}
            data-testid="edit-benchmark-name"
          >
            <div
              className={`${BLOCK}__edit-benchmark--label`}
              data-test="component-custom-benchmark-header"
            >
              <FormattedMessage id="edit.benchmark.name" />
            </div>
            <div
              className={`${BLOCK}__edit-benchmark--input`}
              data-test="benchmark-edit-dropdown"
            >
              <input
                type="text"
                maxLength={200}
                onChange={onChangeHandler}
                value={benchMarkName}
                name="benchMarkName"
                placeholder="Enter benchmark name"
                data-testid="edit-benchmark"
              />
            </div>
          </div>
        )}

        <div
          className={`${BLOCK}__dropdown`}
          data-test="benchmark-baseline-company-dropdown"
          data-testid="benchmark-baseline-company-dropdown"
        >
          <DropdownContainer
            BLOCK={BLOCK}
            dropdownLabel={dropdownHeaders}
            debounceChangeHandler={debounceChangeHandler}
            selectCompany={selectCompany}
            unselectCompany={handleUnselectCompany}
          />
        </div>
        <div className={`${BLOCK}__suggested-peers-section`}>
          <button
            className={classNames(`${BLOCK}__suggested-peers`, {
              [`${BLOCK}__suggested-peers--disabled`]:
                dashboardState.companyFilter.length === 0,
            })}
            onClick={() => {
              if (dashboardState.companyFilter.length > 0) {
                setCompaniesUnselected([]);
                setSuggestedPeers(true);
              }
            }}
            data-test="benchmark-suggested-peers-link"
            data-testid="suggested-peers-btn"
          >
            <Icon
              name="external-link"
              height={24}
              width={24}
              className="icon"
              data-testid={
                dashboardState.companyFilter.length > 0
                  ? "benchmark-suggested-peers-link-enabled"
                  : "benchmark-suggested-peers-link-disabled"
              }
            />
            <FormattedMessage id="suggested.peers" />
          </button>
        </div>
        <div className={`${BLOCK}__peerCompanies-container`}>
          <PeerCompanies
            BLOCK={BLOCK}
            peerCompanies={dashboardState.peerCompanyFilter}
            unselectCompany={unselectCompany}
          />
        </div>
        <div className={`${BLOCK}__suggested-peers-section`}>
          <button
            className={classNames(`${BLOCK}__suggested-peers`, {
              [`${BLOCK}__suggested-peers--disabled`]:
                dashboardState.peerCompanyFilter.length === 0,
            })}
            onClick={() => {
              if (dashboardState.peerCompanyFilter.length > 0) {
                setSavedPeers(true);
              }
            }}
            data-testid="suggested-peers-link"
          >
            <Icon
              name="plus24"
              height={24}
              width={24}
              className="icon"
              data-testid={
                dashboardState.peerCompanyFilter.length > 0
                  ? "benchmark-suggested-peers-link-enabled"
                  : "benchmark-suggested-peers-link-disabled"
              }
            />

            <FormattedMessage id="save.peer.group" />
          </button>
        </div>
        <div>
          {!dashboardState.sideDashboardLoading && (
            <CheckboxContainer
              header={"custom.benchmark.checkbox.header"}
              dispatchFunction={setSelectedCheckboxItemIds}
              dispatchFunctionTwo={setSelectedCheckboxGroupItemIds}
              reducerState={dashboardState.selectedCheckboxIds}
              reducerStateTwo={dashboardState.selectedGroupCheckboxIds}
              checkboxData={
                dashboardState.checkboxdata.length === 0
                  ? []
                  : dashboardState.checkboxdata
              }
              type={type}
              data-test="benchmark-select-metrics"
            />
          )}
        </div>
      </div>
      <div data-testid="side-custom-benchmark-panel-buttons">
        <ActionContainer
          disabled={{
            // Both the buttons are dependent on the same conditions
            actionButtonOneState: disabledButtonOne,
            actionButtonTwoState: disbaledButtonTwo,
          }}
          actionButtonOne={{
            handleActionButton: resetBenchmarkHandler,
            actionButtonText: "custom.benchmark.actions.reset",
            actionButtonType: "button",
          }}
          actionButtonTwo={{
            handleActionButton: applyBenchmarkHandler,
            actionButtonText: "custom.benchmark.actions.apply",
            actionButtonType: "primary",
          }}
        />
      </div>
      {suggestedPeers && (
        <SuggestedPeers
          BLOCK={BLOCK}
          setShowModal={setSuggestedPeers}
          addSelected={addSelected}
          selectedItems={dashboardState.selectedItems}
          dispatchFunction={updateSelectedItems}
          dispatchFunctionPeerGroups={updateSelectedPeerGroupItems}
          suggestedPeerCompanies={dashboardState.suggestedPeerCompanyFilter}
          selectedBaselineComp={{
            companyName: dashboardState.companyFilter[0].companyName
              ? dashboardState.companyFilter[0].companyName
              : "",
            cikNumber: dashboardState.companyFilter[0].cikNumber
              ? dashboardState.companyFilter[0].cikNumber
              : 0,
            companyId: dashboardState.companyFilter[0].companyId
              ? dashboardState.companyFilter[0].companyId
              : 0,
          }}
          selectedCheckboxPeerGroupItems={dashboardState.selectedPeerGroupItems}
          peerGroups={insightsState.savedPeerGroupList}
          page={PEER_GROUP_CREATION_PAGE.DASHBOARD}
          unSelectCompanyFromFilter={unSelectCompanyFromFilter}
          setCompaniesUnselected={companiesUnselectedHandler}
          identifier={COMPANY_IDENTIFIER.CIK_NUMBER}
        />
      )}
      {savedPeers && (
        <SavedPeerModal
          setShowModal={setSavedPeers}
          dropdownHeadersForSavePeerModal={dropdownHeadersForSavePeerModal}
          handleUnselectCompany={handleUnselectCompany}
          isdispatchFunction={true}
          dispatchFunction={setPeerCompanyFilter}
          peerCompanyFilter={selectedPeerCompany}
          setSelectedPeerCompany={setSelectedPeerCompany}
          savePeerGroupHandler={SavePeerGroupHandler}
          debounceChangeHandler={debounceChangeHandler}
        />
      )}
    </div>
  );
};

export default SideCustomBenchmark;
