import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Modal from "components/shared/modal/modal";
import DateTimeRangePicker from "components/shared/date-time-range-picker/date-time-range-picker";
import FilterType from "components/shared/dropdown/filter-type";
import ActionsContainer from "components/shared/action-container/actions-container";
import { RootStore } from "services/store.service";
import { saveMaintenanceMessage } from "services/manage-files/manage-files.service";
import RegionType from "components/shared/dropdown/region-dropdown";
import { Timezone } from "services/manage-files/manage-files.model";
import { MaintenanceMessage } from "services/manage-files/manage-files.model";
import moment from "moment";
import { MAX_CHARACTER_ALLOWED } from "utils/constants";

type props = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  setShowEditPopUp?: (value: boolean) => void;
  savedMessage?: MaintenanceMessage;
};

const MaintenanceMessageModal = ({
  showModal,
  setShowModal,
  setShowEditPopUp,
  savedMessage,
}: props) => {
  const BLOCK = "maintenance-modal";
  const dispatch = useDispatch();
  const manageFilesState = useSelector((state: RootStore) => state.manageFiles);
  const [messageText, setMessageText] = useState<string>("");
  const [selectedRegion, setSelectedRegion] = useState<any>("");
  const [selectedTimezone, setSelectedTimezone] = useState<any>("");
  const [startDate, setStartDate] = useState(
    savedMessage ? new Date(savedMessage.startDate) : new Date()
  );
  const [invalidDate, setInvalidDate] = useState<boolean>(false);
  const [endDate, setEndDate] = useState(startDate);
  const [disableButton, setDisableButton] = useState(true);
  const [validationError, setValidationError] = useState<boolean>(false);
  const [onChangeActivatedForDatePicker, setOnChangeActivatedForDatePicker] =
    useState(false);
  const DISABLE_INPUTS = savedMessage?.status == 1 ? BLOCK + "__disabled" : "";

  useEffect(() => {
    if (savedMessage) {
      setSelectedRegion(savedMessage.region);
      setMessageText(savedMessage.maintenanceMessageText);
      setStartDate(new Date(savedMessage.startDate));
      setEndDate(new Date(savedMessage.endDate));
      setSelectedTimezone(savedMessage.timezone.timezoneName);
    }
  }, [savedMessage]);

  useEffect(() => {
    if (
      new Date(startDate) >= new Date(endDate) &&
      onChangeActivatedForDatePicker
    ) {
      setValidationError(true);
    } else {
      setValidationError(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (
      showModal &&
      !savedMessage &&
      messageText.trim() !== "" &&
      messageText.trimStart().length <=
        MAX_CHARACTER_ALLOWED.FOR_MAINTENANCE_MESSAGE &&
      selectedRegion !== "" &&
      selectedTimezone !== "" &&
      !validationError &&
      new Date(startDate) < new Date(endDate) &&
      !invalidDate
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }

    if (savedMessage) {
      if (
        messageText.trim() !== "" &&
        messageText.trimStart().length <=
          MAX_CHARACTER_ALLOWED.FOR_MAINTENANCE_MESSAGE &&
        selectedRegion !== "" &&
        selectedRegion !== "" &&
        selectedTimezone !== "" &&
        !validationError &&
        !invalidDate &&
        new Date(startDate) < new Date(endDate) &&
        (messageText.trim() !== savedMessage.maintenanceMessageText.trim() ||
          selectedRegion.regionId !== savedMessage.region.regionId ||
          selectedTimezone !== savedMessage.timezone.timezoneName ||
          !moment(startDate).isSame(savedMessage.startDate) ||
          !moment(endDate).isSame(savedMessage.endDate))
      ) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
  }, [
    savedMessage,
    messageText,
    startDate,
    endDate,
    validationError,
    selectedRegion,
    selectedTimezone,
    invalidDate,
  ]);

  const AddMaintenanceMessage = () => {
    if (!disableButton) {
      dispatch(
        saveMaintenanceMessage({
          MaintenanceMessageId: savedMessage
            ? savedMessage.maintenanceMessageId
            : 0,
          Region: selectedRegion,
          MaintenanceMessageText: messageText.trim(),
          StartDate: moment(startDate.toString()).format("YYYY-MM-DDTHH:mm"),
          EndDate: moment(endDate.toString()).format("YYYY-MM-DDTHH:mm"),
          Timezone: manageFilesState.timezones.filter(
            (timezone: Timezone) => timezone?.timezoneName === selectedTimezone
          )[0],
        })
      );
      setShowEditPopUp && setShowEditPopUp(false);
      setShowModal(false);
    }
  };

  return (
    <Modal
      show={true}
      header={
        savedMessage
          ? "manage-files.edit.maintenance-messages"
          : "manage-files.add.maintenance-messages"
      }
      handleClose={() => {
        setShowModal(false);
        setShowEditPopUp && setShowEditPopUp(false);
      }}
      config={false}
    >
      <div className={`${BLOCK} ${BLOCK}--disclaimer`}>
        <div className={`${BLOCK}__input-container ${BLOCK}__input ${DISABLE_INPUTS}`} data-testid="region-type">
          <div className={`${BLOCK}__label`}>
            <FormattedMessage id={"manage-files.maintenance.message.region"} />
          </div>
          {manageFilesState?.regions && (
            <RegionType
              loading={false}
              options={manageFilesState?.regions}
              values={selectedRegion}
              handleSelect={(e: any) => {
                setSelectedRegion(e);
              }}
              classForChevron={`${BLOCK}__chevron`}
              classForOutline={`${BLOCK}__outline`}
              module={"region"}
            />
          )}
        </div>
        <div className={`${BLOCK}__input-container ${BLOCK}__input `}>
          <div className={`${BLOCK}__label`}>
            <FormattedMessage id="manage-files.maintenance.message.text" />
          </div>
          <div className={`${BLOCK}__input`}>
            <textarea
              maxLength={
                MAX_CHARACTER_ALLOWED.FOR_MAINTENANCE_MESSAGE +
                messageText.search(/\S/)
              }
              className={`${BLOCK}__message-input`}
              onChange={(event) => setMessageText(event.target.value)}
              value={messageText}
              name="message"
              placeholder="Type message"
              autoComplete="off"
              data-testid="type-message"
            />
          </div>
          <div className={`${BLOCK}__character-count`}>
            {messageText.trimStart().length}{" "}
            <FormattedMessage id="maintenance.messages.limit.characters" />
          </div>
        </div>

        <div className={`${BLOCK}__input-container ${BLOCK}__input`}>
          <DateTimeRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setOnChangeActivatedForDatePicker={
              setOnChangeActivatedForDatePicker
            }
            savedMessage={savedMessage}
            setInvalidDate={setInvalidDate}
          />
          {validationError && (
            <span
              className={`${BLOCK}__contentError`}
              data-testid="validation-error"
            >
              <FormattedMessage id="manage-files.maintenance.message.validation" />
            </span>
          )}
        </div>

        <div className={`${BLOCK}__input-container ${BLOCK}__input ${DISABLE_INPUTS}`} data-testid="time-zone">
          <div className={`${BLOCK}__label`}>
            <span>
              <FormattedMessage
                id={"manage-files.maintenance.message.timezone"}
              />
            </span>
          </div>
          {manageFilesState?.timezones && (
            <FilterType
              loading={false}
              options={manageFilesState?.timezones?.map(
                (timezone: any) => timezone.timezoneName
              )}
              values={selectedTimezone}
              handleSelect={(e: any) => {
                setSelectedTimezone(e);
              }}
              classForChevron={`${BLOCK}__chevron`}
              classForOutline={`${BLOCK}__outlineForTimezone`}
              module={"maintenance-modal"}
              placeholder={<FormattedMessage id={"select"} />}
              isEnabled={true}
            ></FilterType>
          )}
        </div>
        <div className={`${BLOCK}__action-container`}>
          <ActionsContainer
            disabled={{
              actionButtonOneState: false,
              actionButtonTwoState: disableButton,
            }}
            actionButtonOne={{
              handleActionButton: () => {
                setShowModal(false);
                setShowEditPopUp && setShowEditPopUp(false);
              },
              actionButtonText: "share.any.button.cancel",
              actionButtonType: "button",
            }}
            actionButtonTwo={{
              handleActionButton: AddMaintenanceMessage,
              actionButtonText: savedMessage ? "save" : "add",
              actionButtonType: "primary",
            }}
            className={`${BLOCK}__action-container`}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MaintenanceMessageModal;
