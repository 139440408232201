import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { AnalyticsMiddleware } from "adobe-analytics/middleware";

import commons from "services/commons.service";
import filters from "services/search/filters.service";
import searchResults from "services/search/search.results.service";
import documentViewer from "services/document-viewer/document-viewer.service";
import savedShorList from "services/saved-shortlist/saved-shortlist.service";
import shortlist from "services/shortlist/shortlist.service";
import searchlist from "services/searchlist/searchlist.service";
import publishedSearchlist from "services/published-searchlist/published-searchlist.service";
import download from "services/download/download.service";
import notification from "services/notification/notification.service";
import note from "services/note/note.service";
import comparisonState from "services/saved-comparisons/saved-comparisons.service";
import manageFiles from "services/manage-files/manage-files.service";
import dashboard from "./dashboard/dashboard.service";
import peerBenchmarkState from "./peer-benchmark/peer-benchmark.service";
import insights from "./insights/insights.service";
import powerbi from "./powerbi-dashboard/powerbi.service";
import {
  connectRouter,
  routerMiddleware,
} from "connected-react-router-for-react18";
import history from "utils/history";
import tags from "services/tags/tags.service";
import userManagement from "./user-management/user-management.service";
import moduleAccess from "./module-access/module-access.service";

const RootReducer = combineReducers({
  commons,
  filters,
  searchResults,
  documentViewer,
  savedShorList,
  shortlist,
  searchlist,
  publishedSearchlist,
  download,
  notification,
  note,
  comparisonState,
  manageFiles,
  dashboard,
  peerBenchmarkState,
  insights,
  powerbi,
  router: connectRouter(history),
  tags,
  userManagement,
  moduleAccess,
});

const Store = createStore(
  RootReducer,
  composeWithDevTools(
    applyMiddleware(AnalyticsMiddleware, routerMiddleware(history), thunk)
  )
);

export type RootStore = ReturnType<typeof RootReducer>;
export default Store;
