import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { lightColor } from "utils/constants";
import { useDispatch } from "react-redux";
import { isNumeric, handleMouseEnter, handleMouseLeave } from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";

const WaterDrop = ({
  value,
  minValue,
  maxValue,
  footer,
  colorCodes,
  companyName,
}: {
  value: number;
  minValue: number;
  maxValue: number;
  footer: string;
  colorCodes: any;
  companyName: string | undefined;
}) => {
  const BLOCK = "weater-stress";
  const dispatch = useDispatch();
  return (
    <>
      <div className={`${BLOCK}__content`} data-testid={BLOCK}>
        <div className={`${BLOCK}__container`}>
          <div className={BLOCK} style={{ clipPath: `url(#dropPath)` }}>
            <div
              className={`${BLOCK}__border`}
              style={{ clipPath: `url(#dropPath)` }}
              data-testid={`${BLOCK}__border`}
              onMouseEnter={(e: any) => {
                if (isNumeric(value) && (value === 0 || value === 0.0)) {
                  handleMouseEnter(
                    e,
                    "div",
                    <div>{companyName + " (" + value.toFixed(1) + ")"}</div>,
                    "",
                    dispatch,
                    null,
                    MainTooltipPosition.TopMiddle
                  );
                }
              }}
              onMouseLeave={(e: any) => {
                handleMouseLeave(e, dispatch, false);
              }}
            >
              {isNumeric(value) ? (
                <div
                  className={`${BLOCK}__data`}
                  style={{
                    clipPath: value >= maxValue ? "" : `url(#wavePath)`,
                    height: `${(value / maxValue) * 100}%`,
                    background: `linear-gradient(90deg, ${colorCodes[0]} 0%, ${colorCodes[1]} 100%)`,
                  }}
                  data-testid={`${BLOCK}__data`}
                  onMouseEnter={(e: any) => {
                    handleMouseEnter(
                      e,
                      "div",
                      <div>{companyName + " (" + value.toFixed(1) + ")"}</div>,
                      "",
                      dispatch,
                      null,
                      MainTooltipPosition.TopMiddle
                    );
                  }}
                  onMouseLeave={(e: any) => {
                    handleMouseLeave(e, dispatch, false);
                  }}
                />
              ) : (
                <div
                  className={`${BLOCK}__data`}
                  style={{
                    height: "100%",
                    background: lightColor,
                  }}
                  data-testid={`${BLOCK}__noData`}
                  onMouseEnter={(e: any) => {
                    handleMouseEnter(
                      e,
                      "div",
                      <div>{companyName + " (*)"}</div>,
                      "",
                      dispatch,
                      null,
                      MainTooltipPosition.TopMiddle
                    );
                  }}
                  onMouseLeave={(e: any) => {
                    handleMouseLeave(e, dispatch, false);
                  }}
                />
              )}
            </div>
          </div>
          <svg width="0" height="0">
            <defs>
              <clipPath id="wavePath" clipPathUnits="objectBoundingBox">
                <path
                  d={`M 0 0.1 Q 0.25 -0.1 0.5 0.1 Q 0.75 0.3 1 0 L 1 1 H 0 Z`}
                />
              </clipPath>
            </defs>
          </svg>
          <svg width="0" height="0">
            <defs>
              <clipPath id="dropPath" clipPathUnits="objectBoundingBox">
                <path d="M 0.8928 0.6146 C 0.9332 0.8419 0.7173 1 0.5009 1 C 0.2844 1 0.0777 0.8429 0.1089 0.6146 C 0.1348 0.425 0.4543 0.0058 0.5009 0.0058 C 0.5378 0.0058 0.8558 0.4065 0.8928 0.6146 Z" />
              </clipPath>
            </defs>
          </svg>
          <span className={`${BLOCK}__value`}>
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 500 100"
              preserveAspectRatio="xMinYmin meet"
            >
              <text
                x={
                  isNumeric(value) ? (value >= maxValue ? "5%" : "20%") : "35%"
                }
                y="150%"
              >
                {isNumeric(value) ? value.toFixed(1) : "*"}
              </text>
            </svg>
          </span>
        </div>
        {footer ? <div className={`${BLOCK}__footer`} data-testid={`${BLOCK}__footer`}>{footer}</div> : null}
      </div>
    </>
  );
};

export default WaterDrop;
