import TileEmptyContent from "components/insight-tile/tile-empty-content";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import {
  EXCEPTIONAL_CARBON_CREDITS_METRIC_KEY,
  INSIGHT_BENCHMARK_TYPE,
} from "utils/constants";
import { getFormattedNumber } from "utils/functions";

type props = {
  tableData: any;
  benchmarkType: number;
  associatedMetricsTooltip: AssociatedMetricTooltips[];
};

const CarbonCreditTable = ({
  tableData,
  benchmarkType,
  associatedMetricsTooltip,
}: props) => {
  const BLOCK = "carbon-credit";
  const checkAllMetricValueMissing = tableData.every((metricData: any) =>
    metricData.metricsDetail.every((detail: any) =>
      detail.every((data: string) => data === "")
    )
  );
  if (checkAllMetricValueMissing) {
    return (
      <div>
        <TileEmptyContent />
      </div>
    );
  }
  const checkAllValueMissing = (metric: any) => {
    if (metric.every((value: string) => value.trim() === "")) {
      return true;
    } else {
      return false;
    }
  };

  const getMetricTooltips = (data: any, dataIndex: number, index: number) => {
    return associatedMetricsTooltip && associatedMetricsTooltip.length > 0
      ? associatedMetricsTooltip.filter(
          (tooltip: AssociatedMetricTooltips) =>
            tooltip.associatedMetric ===
            (dataIndex === 1 && index === 3
              ? EXCEPTIONAL_CARBON_CREDITS_METRIC_KEY
              : data.metrics[index].metricKey)
        )
      : [];
  };

  const getCompanyView = () => {
    return (
      <div data-testid="carbon-credit-table-company-view">
        {tableData &&
          tableData[0].metricsDetail.map((metrics: any, ind: number) => (
            <div
              key={`${ind}__compTable`}
              className={`${BLOCK}__wrapper`}
              data-testid={`${BLOCK}__table`}
            >
              {metrics.map((value: any, index: number) => (
                <div
                  key={`${index}__compMetrics`}
                  className={`${BLOCK}__companyTable-wrapper`}
                >
                  <div className={``}>
                    <Popover
                      metricClassName={`${BLOCK}__companyTable-metricName`}
                      displayText={tableData[0]?.metrics[index]?.metricName}
                      content={
                        associatedMetricsTooltip &&
                        associatedMetricsTooltip.length > 0
                          ? associatedMetricsTooltip.filter(
                              (tooltip: AssociatedMetricTooltips) =>
                                tooltip.associatedMetric ===
                                tableData[0]?.metrics[index]?.metricKey
                            )
                          : []
                      }
                    />
                  </div>
                  <div className={`${BLOCK}__companyTable-metricValue`}>
                    {value
                      ? !isNaN(Number(value))
                        ? getFormattedNumber(value, 0, 1, true)
                        : value
                      : ""}
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    );
  };

  const getPeerView = () => {
    return (
      <div data-testid="carbon-credit-table-peer-view">
        <div
          className={`${BLOCK}__peerTable`}
          data-testid={`${BLOCK}__peerTable`}
        >
          {tableData &&
            tableData.map((data: any, index: number) => (
              <div key={`${index}__peerTable`}>
                <div className={`${BLOCK}__peerTable-groupName`}>
                  {data.groupName}
                </div>
                {data.metricsDetail.length > 0 &&
                  data.metricsDetail.map((metrics: any, ind: number) =>
                    !checkAllValueMissing(metrics) ? (
                      <div
                        key={`${ind}__peerTablle-value`}
                        className={`${BLOCK}__wrapper`}
                      >
                        {metrics.map((value: any, index: number) => (
                          <div
                            key={`${index}__peerTable-metric`}
                            className={`${BLOCK}__companyTable-wrapper`}
                          >
                            <div>
                              <Popover
                                metricClassName={`${BLOCK}__companyTable-metricName`}
                                displayText={data.metrics[index].metricName}
                                content={
                                  associatedMetricsTooltip &&
                                  associatedMetricsTooltip.length > 0
                                    ? associatedMetricsTooltip.filter(
                                        (tooltip: AssociatedMetricTooltips) =>
                                          tooltip.associatedMetric ===
                                          data.metrics[index].metricKey
                                      )
                                    : []
                                }
                              />
                            </div>
                            <div
                              className={`${BLOCK}__companyTable-metricValue`}
                            >
                              {value
                                ? !isNaN(Number(value))
                                  ? getFormattedNumber(value, 0, 1, true)
                                  : value
                                : ""}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <TileEmptyContent />
                    )
                  )}
              </div>
            ))}
        </div>
      </div>
    );
  };

  const getIndustryView = () => {
    return (
      <div data-testid="carbon-credit-table-industry-view">
        <div
          className={`${BLOCK}__indTable`}
          data-testid={`${BLOCK}__peerTable`}
        >
          {tableData &&
            tableData.map((data: any, dataIndex: number) => (
              <div key={`${dataIndex}__peerTable`}>
                <div className={`${BLOCK}__indTable-groupName`}>
                  {data.groupName}
                </div>
                {data.metricsDetail.length > 0 &&
                  data.metricsDetail.map((metrics: any, ind: number) =>
                    !checkAllValueMissing(metrics) ? (
                      <div
                        key={`${ind}__peerTablle-value`}
                        className={`${BLOCK}__wrapper`}
                      >
                        {metrics.map((value: any, index: number) => (
                          <div
                            key={`${index}__peerTable-metric`}
                            className={`${BLOCK}__indTable-wrapper`}
                          >
                            <div>
                              <Popover
                                metricClassName={`${BLOCK}__companyTable-metricName`}
                                displayText={
                                  dataIndex === 1 && index === 3 ? (
                                    <FormattedMessage
                                      id="carbon.credit.peer.industry.credit.retiered"
                                      values={{
                                        metricName:
                                          data.metrics[index].metricName,
                                      }}
                                    />
                                  ) : (
                                    data.metrics[index].metricName
                                  )
                                }
                                content={getMetricTooltips(
                                  data,
                                  dataIndex,
                                  index
                                )}
                              />
                            </div>
                            <div
                              className={`${BLOCK}__companyTable-metricValue`}
                            >
                              {value
                                ? !isNaN(Number(value))
                                  ? getFormattedNumber(value, 0, 1, true)
                                  : value
                                : ""}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <TileEmptyContent />
                    )
                  )}
              </div>
            ))}
        </div>
      </div>
    );
  };

  return benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
    ? getCompanyView()
    : benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK
    ? getPeerView()
    : getIndustryView();
};

export default CarbonCreditTable;
