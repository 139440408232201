import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";

type props = {
  disabled: {
    actionButtonOneState: boolean;
    actionButtonTwoState?: boolean;
  };
  actionButtonOne: {
    handleActionButton: () => void;
    actionButtonText: string;
    actionButtonType: string;
  };
  actionButtonTwo?: {
    handleActionButton: () => void;
    actionButtonText: string;
    actionButtonType: string;
  };
  className?: string;
};

const ActionsContainer = ({
  disabled,
  actionButtonOne,
  actionButtonTwo,
  className,
}: props) => {
  const BLOCK = "action-container";

  return (
    <div className={`${BLOCK}__actions ${className}`} data-test="actions">
      <button
        // Use classnames to define the type of button, and provide styling to it as such
        className={classNames(
          {
            [`${actionButtonOne.actionButtonType} ${BLOCK}__actions-disabled`]:
              disabled.actionButtonOneState,
          },
          {
            [`${actionButtonOne.actionButtonType}`]:
              !disabled.actionButtonOneState,
          },
          {
            [`${BLOCK}__invisible-button`]:
              actionButtonOne.actionButtonType === "button" &&
              !disabled.actionButtonOneState,
          }
        )}
        data-test="reset-button"
        data-testid="reset-button"
        onClick={() =>
          !disabled.actionButtonOneState
            ? actionButtonOne.handleActionButton()
            : () => {}
        }
      >
        <FormattedMessage id={actionButtonOne.actionButtonText} />
      </button>
      {/* The second button is optional as per the requirement of the user */}
      {actionButtonTwo && (
        <button
          className={classNames(
            {
              [`${actionButtonTwo.actionButtonType}-disabled`]:
                disabled.actionButtonTwoState,
            },
            {
              [`${actionButtonTwo.actionButtonType}`]:
                !disabled.actionButtonTwoState,
            }
          )}
          onClick={() =>
            !disabled.actionButtonTwoState
              ? actionButtonTwo.handleActionButton()
              : () => {}
          }
          data-testid="apply-button"
          data-test={actionButtonTwo.actionButtonText}
        >
          <FormattedMessage id={actionButtonTwo.actionButtonText} />
        </button>
      )}
    </div>
  );
};

export default ActionsContainer;
