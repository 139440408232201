import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "components/shared/icon/icon";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import DeleteModal from "components/shared/delete-modal/delete-modal";
import RenameShortlist from "../shortlist-actions/rename-shortlist";
import ShareToUsersModal from "components/shared/share-to-users-modal/share-to-users-modal";
import { RootStore } from "services/store.service";
import {
  deleteShortlist,
  renameShortlist,
  shareShortlist,
  loadsavedShortlistSort,
  renameSavedShortlistSuccess,
  deleteShortlistPop,
} from "services/saved-shortlist/saved-shortlist.service";
import { resetShortList } from "services/shortlist/shortlist.service";
import {
  SORT_ORDERS,
  DEFAULT_SHORTLISTS_PAYLOAD,
  shareItemType,
} from "utils/constants";
import SavedShortListTableRow from "./saved-shortlist.table-row";
import { addToastMessage } from "services/commons.service";

const tableHeaders = [
  {
    text: "saved-shortlist.name",
    isFormattedText: true,
    sortId: "shortlistname",
  },
  {
    text: "saved-shortlist.date-created",
    isFormattedText: true,
    sortId: "createddate",
  },
  {
    text: "saved-shortlist.last-saved",
    isFormattedText: true,
    sortId: "lastupdateddate",
  },
  {
    text: "saved-shortlist.items",
    isFormattedText: true,
  },
  {
    text: "saved-shortlist.shared-by",
    isFormattedText: true,
  },
  {
    text: "saved-shortlist.shared-with",
    isFormattedText: true,
  },
  {
    text: "saved-shortlist.date-shared",
    isFormattedText: true,
  },
  {
    text: "saved-shortlist.actions",
    isFormattedText: true,
  },
];

const SavedShortListTable = () => {
  const BLOCK = "saved-shortlist-table";
  const dispatch = useDispatch();
  const savedShortlist = useSelector((state: RootStore) => state.savedShorList);
  const savedSearchListState = useSelector(
    (state: RootStore) => state.searchlist
  );
  const shortlistState = useSelector((state: RootStore) => state.shortlist);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showShare, setShowShare] = useState<boolean>(false);
  const [sortInfo, setSortInfo] = useState<any>({
    sortOrder: null,
    sortBy: null,
  });

  let shortlist =
    savedShortlist.SavedShortListFetch &&
    savedShortlist.SavedShortListFetch.shortlists
      ? savedShortlist.SavedShortListFetch.shortlists.filter(
          ({ isCurrent }: any) => !isCurrent
        )
      : [];

  shortlist = shortlist.filter(
    (shortlist: any) => shortlist.shortlistName !== null
  );

  useEffect(() => {
    if (!savedShortlist.showDelete && savedShortlist.deleteShortlist !== null) {
      setShowDelete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedShortlist.deleteShortlist]);

  useEffect(() => {
    if (savedShortlist.savedShortListRename) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="shortlist.save" />,
        })
      );
      dispatch(renameSavedShortlistSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedShortlist.savedShortListRename]);

  useEffect(() => {
    if (!savedShortlist.showRename && savedShortlist.renameShortlist !== null) {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedShortlist.renameShortlist]);

  useEffect(() => {
    /*istanbul ignore next*/
    if (
      !savedSearchListState.currentShareSearch &&
      !savedShortlist.showShare &&
      savedShortlist.shareShortlist !== null
    ) {
      setShowShare(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedShortlist.shareShortlist]);

  // useEffect(() => {
  //   if (savedShortlist.shareSuccess) {
  //     setShowShare(false);
  //     dispatch(
  //       addToastMessage({
  //         description: <FormattedMessage id={"shortlist.share.success"} />,
  //       })
  //     );
  //     dispatch(shareShortlistSuccess(false));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [savedShortlist.shareSuccess]);

  const renameShortlistHandler = ({
    shortlistName,
    shortlistId,
  }: {
    shortlistName: string;
    shortlistId: number;
  }) => {
    /*istanbul ignore next*/
    dispatch(
      renameShortlist({
        shortlistName,
        shortlistId,
        type: "saved",
      })
    );
  };

  const shareShortlistHandler = ({
    shortlistName,
    shortlistId,
    sharedWith,
    sourceShortlistId,
  }: {
    shortlistName: string;
    shortlistId: number;
    sharedWith: any[];
    sourceShortlistId: number;
  }) => {
    /*istanbul ignore next*/
    dispatch(
      shareShortlist({
        shortlistName,
        shortlistId,
        sharedWith,
        sourceShortlistId,
      })
    );
  };

  const deleteShortlistHandler = (shortlistId: number) => {
    /*istanbul ignore next*/
    dispatch(deleteShortlist(shortlistId));

    if (
      shortlistState.results &&
      shortlistState.results.shortlistId ===
        savedShortlist.deleteShortlist.shortlistId
    ) {
      dispatch(resetShortList());
    }
    dispatch(
      addToastMessage({
        description: <FormattedMessage id="shortlist.delete" />,
      })
    );
  };

  // useEffect(() => {
  //   if(shortlistResults.deleteShortlist === null && !shortlistResults.loading){
  //     dispatch(loadsavedShortlist())
  //   }
  // }, [shortlistResults.deleteShortlist])

  const onSort = (sortId: string) => {
    let newSortOrder = sortInfo.sortOrder;

    if (sortId === sortInfo.sortBy) {
      newSortOrder =
        sortInfo.sortOrder === SORT_ORDERS.DESC
          ? SORT_ORDERS.ASC
          : SORT_ORDERS.DESC;
    } else {
      newSortOrder = SORT_ORDERS.DESC;
    }

    const payload = {
      ...DEFAULT_SHORTLISTS_PAYLOAD,
      sortBy: sortId,
      sortOrder: newSortOrder,
    };
    setSortInfo({ sortBy: sortId, sortOrder: newSortOrder });
    dispatch(loadsavedShortlistSort(payload));
  };

  const renderHeaderItemContents = (item: any) => {
    let icon;

    const contents = (
      <>
        {item.text && (
          <div className={`${BLOCK}__header-text`}>
            {
              /*istanbul ignore next*/ item.isFormattedText ? (
                <FormattedMessage id={item.text} />
              ) : (
                item.text
              )
            }
          </div>
        )}
      </>
    );

    if (item.sortId) {
      const className =
        sortInfo.sortBy === item.sortId
          ? `${BLOCK}__header-inner-content ${BLOCK}__header-button ${BLOCK}__header-button--sorted`
          : `${BLOCK}__header-inner-content ${BLOCK}__header-button`;
      if (
        sortInfo.sortBy === item.sortId &&
        sortInfo.sortOrder &&
        sortInfo.sortOrder === "asc"
      ) {
        icon = (
          <Icon
            name="sort-ascending"
            width={16}
            height={16}
            className={`${BLOCK}__sort-icon`}
          />
        );
      } else if (
        sortInfo.sortBy === item.sortId &&
        sortInfo.sortOrder &&
        sortInfo.sortOrder === "desc"
      ) {
        icon = (
          <Icon
            name="sort-descending"
            width={16}
            height={16}
            className={`${BLOCK}__sort-icon`}
          />
        );
      }

      return (
        <button
          className={className}
          onClick={() => onSort(item.sortId)}
          data-test="sort-table-button"
        >
          {icon}
          {contents}
        </button>
      );
    } else {
      return <div className={`${BLOCK}__header-inner-content`}>{contents}</div>;
    }
  };

  return (
    <div className={`${BLOCK}__container`}>
      <table className={`${BLOCK}`}>
        <thead>
          <tr>
            <td className={`${BLOCK}__header`}>
              <FormattedMessage
                id="saved-shortlist.count"
                values={{ count: shortlist?.length }}
              />
            </td>
          </tr>
          <tr className={`${BLOCK}__heading-text`}>
            {tableHeaders.map((item, index) => (
              <th key={`${index}__head`}>{renderHeaderItemContents(item)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {shortlist?.map((item: any, index: number) => (
            <SavedShortListTableRow
              block={BLOCK}
              shortlist={item}
              key={`${index}_${item.shortlistId}__body`}
            />
          ))}
        </tbody>
      </table>

      {showModal && (
        <RenameShortlist
          setShowModal={setShowModal}
          renameShortlist={renameShortlistHandler}
        />
      )}
      {showDelete && (
        <DeleteModal
          title="shortlist.delete.title"
          message={
            <>
              <FormattedMessage id="shortlist.delete.name" />
              <span
                style={{ fontWeight: 600 }}
              >{` "${savedShortlist.deleteShortlist.shortlistName}"`}</span>
              {"?"}
            </>
          }
          deleteItem={() => {
            deleteShortlistHandler(savedShortlist.deleteShortlist.shortlistId);
            setShowDelete(false);
          }}
          handleCloseModal={() => {
            setShowDelete(false);
            dispatch(deleteShortlistPop(null));
          }}
        />
      )}
      {showShare && (
        <ShareToUsersModal
          setShowShare={setShowShare}
          shareShortlist={shareShortlistHandler}
          setShowSearchModal={() => {}}
          shareSearch={() => {}}
          shareCompare={() => {}}
          shareType={shareItemType.shortlist}
        />
      )}
    </div>
  );
};

export default SavedShortListTable;
