import FormattedMessage from "../shared/formatted-message/formatted-message";
import { RootStore } from "../../services/store.service";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Icon from "../../components/shared/icon/icon";
import { Link } from "react-router-dom";
import { fetchPublishedSearch } from "services/published-searchlist/published-searchlist.service";
import PublishedSearchesTable from "./published-searches.table";
import SearchEmptyState from "images/search.empty-state.png";

const PublishedSearches = () => {
  const BLOCK = "published-searches";
  const dispatch = useDispatch();
  const publishedSearchlistState = useSelector(
    (state: RootStore) => state.publishedSearchlist
  );

  useEffect(() => {
    dispatch(fetchPublishedSearch());
  }, [dispatch]);

  return (
    <div className={`${BLOCK}`} data-test="my-published-searches-component">
      <div className={`${BLOCK}`}>
        {publishedSearchlistState.loading && (
          <div className={`${BLOCK}__empty-container`}>
            <Icon
              name={"loading"}
              width={50}
              height={50}
              className="loading-icon"
              data-test="loading-icon"
            />
          </div>
        )}
        {!publishedSearchlistState.loading &&
          publishedSearchlistState.results.length > 0 && (
            <div className={`${BLOCK}__content`}>
              <header className={`${BLOCK}__header`}></header>

              <PublishedSearchesTable />
            </div>
          )}
        {!publishedSearchlistState.loading &&
          publishedSearchlistState.results.length === 0 && (
            <div className={`${BLOCK}__empty`} data-test="empty-search-list">
              <div className={`${BLOCK}__img`}>
                <img
                  className={""}
                  src={SearchEmptyState}
                  alt={SearchEmptyState}
                />
              </div>
              <div className={`${BLOCK}__emptytext`}>
                <FormattedMessage id="published.searches.empty" />
              </div>
              <div className={`${BLOCK}__emptyredirect`}>
                <FormattedMessage id="publishes.searches.empty.prefix" />
                &nbsp;
                <Link className={`${BLOCK}__link`} to={"/mysearches"}>
                  <FormattedMessage id="publishes.searches.empty.message" />
                </Link>
                &nbsp;
                <FormattedMessage id="publishes.searches.empty.postfix" />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default PublishedSearches;
