import api from "../api.service";
import { companyPagination } from "utils/constants";
import { AxiosRequestConfig } from "axios";

export const fetchSuggestedPeers = (cikNumber: number) =>
  api.get(`company/${cikNumber}/suggestedPeers`).then(({ data }) => data);

export const fetchSavedInsightsList = (payload: any) =>
  api.get(`insights`, payload).then(({ data }) => data);

export const getCount = () => {
  return api.get(`insights/shared/newCount`).then(({ data }) => data);
};
export const fetchTalkingPoints = (payload: any) =>
  api.post(`talkingpoints`, payload).then(({ data }) => data);

export const getSavedInsight = (
  insightDashboardId: number,
  tabId: number,
  config?: AxiosRequestConfig
) =>
  api
    .get(`insights/${insightDashboardId}/benchmarkType/${tabId}`, config)
    .then(({ data }) => data);

export const getFeatureSettings = () =>
  api.get(`featuresettings`).then(({ data }) => data);

export const fetchSavedInsights = (payload: any) =>
  api.get(`insightsdashboards/list`, payload).then(({ data }) => data);

export const deleteInsight = (insightsDashboardId: number) =>
  api.delete(`insights/${insightsDashboardId}`);

export const updateFilterMetadata = (payload: any, insightId: number) =>
  api.patch(`insights/${insightId}`, payload).then(({ data }) => data.data);

export const updateTileMetadata = (
  payload: any,
  insightId: number,
  BenchmarkTileType: number
) =>
  api
    .patch(
      `insights/${insightId}/BenchmarkTileType/${BenchmarkTileType}`,
      payload
    )
    .then(({ data }) => data.data);

export const shareInsights = (payload: any) =>
  api.post(`insights/share`, payload).then(({ data }) => data);

export const fetchDocumentFilter = (searchText: string) =>
  api
    .post(`search/companies`, {
      contentText: searchText,
      pageInfo: companyPagination,
      searchCompanies: "msci,sec,cdp,snp",
    })
    .then(({ data }) => data);

export const fetchGlobalCompaniesFilter = (
  searchText: string,
  isSECFiler: boolean
) =>
  api
    .post(`globalcompanies/list`, {
      contentText: searchText,
      pageInfo: companyPagination,
      searchCompanies: "msci,sec,cdp,snp",
      isSECFiler: isSECFiler,
    })
    .then(({ data }) => data);

export const fetchCustomBenchmarkMetics = () =>
  api.get(`insightscategories`).then(({ data }) => data);

export const createInsight = (payload: any) =>
  api.post(`insights`, payload).then(({ data }) => data.data);

export const editInsight = (payload: any) =>
  api.put(`insights`, payload).then(({ data }) => data.data);

export const savePeerGroupName = (payload: any) =>
  api.post(`companygroups`, payload).then(({ data }) => data.data);

export const editInsightDashboardView = (payload: any, insightsId: number) =>
  api
    .patch(`insights/${insightsId}/editView`, payload)
    .then(({ data }) => data);

export const saveAdditionalInfo = (payload: any) =>
  api.post(`additionalinformation`, payload).then(({ data }) => data.data);

export const fetchAdditionalInfo = (
  referenceId: number,
  additionalInformationTypeId: number
) =>
  api
    .get(
      `additionalinformation/${referenceId}/additionalInformationType/${additionalInformationTypeId}`
    )
    .then(({ data }) => data);

export const updateAdditionalInfo = (payload: any) =>
  api.put(`additionalinformation`, payload).then(({ data }) => data.data);

export const saveInsightsPreferences = (insightId: number, payload: any) =>
  api.patch(`insights/${insightId}`, payload).then(({ data }) => data.data);

export const getTilesDetail = (
  insightDashboardId: number,
  insightsCategoryId: number,
  benchmarkType: number
) =>
  api
    .get(
      `insights/${insightDashboardId}/insightsCategoryId/${insightsCategoryId}/benchmarkType/${benchmarkType}`
    )
    .then(({ data }) => data);

export const setSelectedDetailedViewBenchmarkIds = (
  insightDashboardId: number,
  payload: any
) =>
  api
    .patch(`insights/${insightDashboardId}/detailedpeerview`, payload)
    .then(({ data }) => data);

export const getPeerGroupList = (payload: any) =>
  api.post(`companygroups/list`, payload).then(({ data }) => data);

export const editSavedPeerGroup = (payload: any) =>
  api.put(`companygroups`, payload).then(({ data }) => data);

export const deleteSavedPeerGroup = (peerGroupId: number) =>
  api.delete(`companygroups/${peerGroupId}`);

export const savepeerGroupDataTracking = (payload: any) =>
  api.post(`trackings`, payload).then(({ data }) => data);

export const saveCompanyGrouping = (payload: any) =>
  api.post(`globalcompanymappings`, payload).then(({ data }) => data);

export const fetchSavedGroupings = () =>
  api.get(`globalcompanymappings`).then(({ data }) => data);

export const deleteSavedGrouping = (globalcompanyMappingId: number) =>
  api.delete(`globalcompanymappings/${globalcompanyMappingId}`);

export const refreshInsightsData = (insightId: number) =>
  api.patch(`insights/${insightId}/datarefresh`).then(({ data }) => data);

export const fetchEsgReportsForInsights = (payload: any) => {
  return api.post(`insights/esgReports`, payload).then(({ data }) => data);
};

export const fetchSecReportsForInsights = (payload: any) => {
  return api.post(`insights/secReports`, payload).then(({ data }) => data);
};
