import { useState, useEffect, useRef } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  loadSecSearchResultsNextPage,
  loadTOCResults,
  loadSecSearchResultsSort,
} from "services/search/search.results.service";
import {
  createShortlist,
  removeShortlistItem,
} from "../../services/shortlist/shortlist.service";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import Table from "../shared/table/table";
import classNames from "classnames";
import { setShowFilters } from "services/search/filters.service";
import { setSelectedDisclosure } from "services/document-viewer/document-viewer.service";
import DocumentViewerSEC from "components/document-viewer/document-viewer.sec";
import {
  comparisonItemTypes,
  ScrollOffset,
  SECsortResults,
  SORT_ORDERS,
} from "utils/constants";
import TabbedContent from "components/shared/tabbed-content/tabbed-content";
import TableOfContents from "components/table-of-contents/table-of-contents";
import { setComparisonsSelectedList } from "services/saved-comparisons/saved-comparisons.service";

type props = {
  BLOCK: string;
};

const SecSearchResults = ({ BLOCK }: props) => {
  const searchResults = useSelector((state: RootStore) => state.searchResults);
  const documentViewerState = useSelector(
    (state: RootStore) => state.documentViewer
  );
  const shortlistResults = useSelector((state: RootStore) => state.shortlist);
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);
  // To show/hide TOC component
  const [showTOC, setShowTOC] = useState<boolean>(false);
  const [sortInfo, setSortInfo] = useState<any>({
    sortOrder: SECsortResults.sortOrder,
    sortBy: SECsortResults.sortBy,
  });

  const tableContainerRef = useRef<any>(null);

  const comparisonState = useSelector(
    (state: RootStore) => state.comparisonState
  );

  const selectedSECSectionId = comparisonState.selectedList
    .filter((filings) => filings.sectionId)
    .map((filings) => filings.sectionId);

  const combinedSECFilings = searchResults.secResults.reduce(
    (prev: any, curr: any) => [...prev, ...curr.secFilings],
    []
  );
  useEffect(() => {
    if (!searchResults.tocLoading) {
      setShowTOC(true);
      if (searchResults.searchPayloadSEC.contentText.length === 0) {
        setCurrentTab(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults.tocLoading]);

  useEffect(() => {
    if (!documentViewerState.selectedDisclosure) {
      setShowTOC(false);
      setCurrentTab(0);
    }
  }, [documentViewerState.selectedDisclosure]);

  const getTableItems = (showDocSection: boolean, offset: number) => {
    let reports: any[] = [];
    searchResults.secResults.forEach((list) => {
      list.secFilings.forEach((sections) =>
        reports.push({
          report: sections,
          icon: searchResults.secShortlists.includes(sections.elementId),
        })
      );
    });
    return reports.map((reportList, i) => {
      let parentDocSections = "";
      let mainDocSection = "";
      let report = reportList.report;

      // construct subtitle string out of previous document sections
      if (report.documentSection && report.documentSection.length > 0) {
        report.documentSection.forEach((filingItem: any, index: number) => {
          if (index === report.documentSection.length - 1) {
            mainDocSection = filingItem.sectionName;
          } else {
            parentDocSections =
              parentDocSections + filingItem.sectionName + " ";

            if (index < report.documentSection.length - 2) {
              parentDocSections = parentDocSections + "> ";
            }
          }
        });
      }

      let periodText = "";
      if (report.year && report.period) {
        periodText = `${report.year}, ${report.period}`;
      } else if (report.year) {
        periodText = report.year;
      } else if (report.period) {
        periodText = report.period;
      }

      //show document section if there are content keywords
      const documentSection = [];

      if (showDocSection) {
        documentSection.push({
          subTitle: parentDocSections,
          text: mainDocSection,
          iconSize: 16,
          wrapperClassName: `${BLOCK}__column-report-title`,
          onClick: () => {
            dispatch(setShowFilters(false));
            dispatch(setSelectedDisclosure(report));
            dispatch(
              loadTOCResults({
                filingId: report.filingId,
                contentKeywords: searchResults.searchPayloadSEC.contentText,
                sectionId: report.elementId ? report.elementId : null,
                shortlistId: shortlistResults.results
                  ? shortlistResults.results.shortlistId
                  : 0,
              })
            );
          },
        });
      }

      const payload = {
        shortlistId: 0,
        esgReport: {
          reportId: null,
        },
        sectionItem: {
          filingId: null,
          sectionId: null,
          elementId: null,
        },
        reportId: null,
        sectionId: null,
        shortlistItemType: 1,
        contentKeywords: "",
        displayOrder: 0,
      };

      return {
        id: report.elementId ? report.elementId : report.filingId,
        key: `sec-disclosure-${
          report.elementId ? report.elementId : report.filingId
        }-${offset + i}`,
        columnItems: [
          {
            checkbox: {
              onChange: (selectedList: any) => {
                return selectSECreports(selectedList);
              },
            },
            type: "sec",
          },
          {
            icon: reportList.icon ? "remove" : "add",
            iconSize: 24,
            type: "sec",
            className: `${BLOCK}__column-icon`,
            onClick: () => {
              if (searchResults.searchPayloadSEC.contentText.length === 0) {
                return;
              }
              if (!reportList.icon) {
                dispatch(
                  createShortlist({
                    ...payload,
                    sectionItem: report,
                    sectionId: report.elementId,
                    shortlistId:
                      shortlistResults.results !== null &&
                      shortlistResults.results.shortlistId !== 0
                        ? shortlistResults.results.shortlistId
                        : 0,
                    contentKeywords: searchResults.searchPayloadSEC.contentText,
                  })
                );
              } else {
                dispatch(
                  removeShortlistItem({
                    ...payload,
                    sectionItem: report,
                    sectionId: report.elementId,
                    shortlistId:
                      /* istanbul ignore next */
                      shortlistResults.results !== null &&
                      shortlistResults.results.shortlistId !== 0
                        ? shortlistResults.results.shortlistId
                        : 0,
                    contentKeywords: searchResults.searchPayloadSEC.contentText,
                  })
                );
              }
            },
          },
          {
            text: report.companyName,
            wrapperClassName: `${BLOCK}__column-company-name-sec`,
            onClick: () => {
              dispatch(setShowFilters(false));
              dispatch(setSelectedDisclosure(report));
              dispatch(
                loadTOCResults({
                  filingId: report.filingId,
                  contentKeywords: searchResults.searchPayloadSEC.contentText,
                  sectionId: report.elementId ? report.elementId : null,
                  shortlistId: shortlistResults.results
                    ? shortlistResults.results.shortlistId
                    : 0,
                })
              );
            },
          },
          ...documentSection,
          {
            wrapperClassName: `${BLOCK}__column-document-type-sec`,
            text: report.formType,
            onClick: () => {
              dispatch(setShowFilters(false));
              dispatch(setSelectedDisclosure(report));
              dispatch(
                loadTOCResults({
                  filingId: report.filingId,
                  contentKeywords: searchResults.searchPayloadSEC.contentText,
                  sectionId: report.elementId ? report.elementId : null,
                  shortlistId: shortlistResults.results
                    ? shortlistResults.results.shortlistId
                    : 0,
                })
              );
            },
          },
          {
            wrapperClassName: `${BLOCK}__column-period-sec`,
            text: periodText,
            onClick: () => {
              dispatch(setShowFilters(false));
              dispatch(setSelectedDisclosure(report));
              dispatch(
                loadTOCResults({
                  filingId: report.filingId,
                  contentKeywords: searchResults.searchPayloadSEC.contentText,
                  sectionId: report.elementId ? report.elementId : null,
                  shortlistId: shortlistResults.results
                    ? shortlistResults.results.shortlistId
                    : 0,
                })
              );
            },
          },
        ],
      };
    });
  };

  const getTableHeaders = (showDocSection: boolean) => {
    const documentSection = [];
    if (showDocSection) {
      documentSection.push({
        text: "search.results.document-section",
        isFormattedText: true,
        sortId: "DocumentsectionName",
      });
    }

    return [
      {
        checkbox: {
          onChange: (selectedList: any) => {
            let tempCurrentSelected = [...comparisonState.selectedList];
            if (selectedList.length === 0) {
              tempCurrentSelected = tempCurrentSelected.filter(
                (item) => !item.sectionId
              );
            }

            return selectSECreports(selectedList, tempCurrentSelected);
          },
          value:
            selectedSECSectionId.length > 0 &&
            combinedSECFilings.every((filings) =>
              selectedSECSectionId.includes(filings.elementId)
            ),
        },
        type: "sec",
      },
      {
        icon: "add-all",
        iconSize: 24,
        className: `${BLOCK}__column-icon`,
        type: "sec",
      },
      {
        text: "search.results.company",
        isFormattedText: true,
        sortId: "CompanyName",
      },
      ...documentSection,
      {
        text: "search.results.document-type",
        isFormattedText: true,
        sortId: "DocumenttypeName",
      },
      {
        text: "search.results.period",
        isFormattedText: true,
        sortId: "PeriodName",
      },
    ];
  };

  const onScroll = (e: any) => {
    // check for the reaching the bottom of the container before requesting a new page
    if (
      e.target.scrollHeight <=
        e.target.scrollTop + e.target.clientHeight + ScrollOffset &&
      !searchResults.secNextPageLoading &&
      searchResults.secResults &&
      searchResults.secResults.length > 0
    ) {
      const pageInfo = searchResults.secResults[0].pageInfo;
      const currentPageNumber =
        searchResults.secResults[searchResults.secResults.length - 1].pageInfo
          .pageNumber;
      /* istanbul ignore next */
      if (currentPageNumber < pageInfo.totalPageCount)
        fetchNextPage(currentPageNumber + 1);
    }
  };

  const fetchNextPage = (pageNumber: number) => {
    const payload = {
      ...searchResults.searchPayloadSEC,
      shortlistId: shortlistResults.results
        ? shortlistResults.results.shortlistId
        : 0,
      governingBoardIds: [],
      pageInfo: {
        pageNumber: pageNumber,
        pageSize: 30,
        sortBy: sortInfo.sortBy,
        sortOrder: sortInfo.sortOrder,
      },
    };

    dispatch(loadSecSearchResultsNextPage(payload));
  };

  const mapSelectedItems = (item: any) =>
    item.id && !item.mapped
      ? {
          sectionId: item.id,
          filingId: item.id,
          sectionName: item.columnItems[2].text,
          reportTitle: item.columnItems[3].text,
          comparisonItemType: comparisonItemTypes.SEC,
          contentKeywords: searchResults.searchPayloadESG.contentText,
        }
      : item;

  const selectSECreports = (selectedList: any, currentSelected?: any) => {
    const mappedSelectedList = selectedList.map(mapSelectedItems);
    const newSelectedList = currentSelected
      ? [...mappedSelectedList, ...currentSelected]
      : mappedSelectedList;
    dispatch(setComparisonsSelectedList(newSelectedList));
  };

  const onSort = (sortId: string) => {
    let newSortOrder = sortInfo.sortOrder;

    if (sortId === sortInfo.sortBy) {
      newSortOrder =
        sortInfo.sortOrder === SORT_ORDERS.DESC
          ? SORT_ORDERS.ASC
          : SORT_ORDERS.DESC;
    } else {
      newSortOrder = SORT_ORDERS.DESC;
    }

    const payload = {
      ...searchResults.searchPayloadSEC,
      shortlistId: shortlistResults.results
        ? shortlistResults.results.shortlistId
        : 0,
      governingBoardIds: [],
      pageInfo: {
        pageNumber: 1,
        pageSize: 30,
        sortBy: sortId,
        sortOrder: newSortOrder,
      },
    };

    setSortInfo({ sortBy: sortId, sortOrder: newSortOrder });
    dispatch(loadSecSearchResultsSort(payload));
  };

  const showDocSection =
    searchResults.searchPayloadSEC &&
    searchResults.searchPayloadSEC.contentText &&
    searchResults.searchPayloadSEC.contentText.trim()
      ? true
      : false;
  const tableItems = getTableItems(showDocSection, 0);
  const tableHeaders = getTableHeaders(showDocSection);

  return (
    <>
      {!documentViewerState.fullscreen && (
        <div
          className={classNames(`${BLOCK}__results-container`, {
            [`${BLOCK}__results-container--show-document-viewer`]:
              documentViewerState.selectedDisclosure,
          })}
          data-test="component-sec-search-results"
        >
          {documentViewerState.selectedDisclosure ? (
            <div data-test={"sec-search-results-tabbed-content"}>
              <TabbedContent
                tabItems={[
                  {
                    text: "search.results.table.title.sec",
                    tabId: `${BLOCK}__esg-reports-tab`,
                    tabPanelId: `${BLOCK}__esg-reports-tabpanel`,
                    variable: Intl.NumberFormat("en-US").format(
                      searchResults.secResults.length > 0 &&
                        searchResults.secResults[0].pageInfo
                        ? searchResults.secResults[0].pageInfo.totalRecordCount
                        : 0
                    ),
                  },
                  {
                    text: "toc.content",
                    tabId: `${BLOCK}__esg-toc-tab`,
                    tabPanelId: `${BLOCK}__esg-toc-tabpanel`,
                  },
                ]}
                onChange={(
                  tabbedIndex: number,
                  tabbedId: string,
                  e: React.SyntheticEvent
                ) => {
                  /*istanbul ignore next*/
                  setCurrentTab(tabbedIndex);
                }}
                selectedIndex={currentTab}
              />
            </div>
          ) : (
            <div
              className={`${BLOCK}__results-summary`}
              data-test={`${BLOCK}__results-summary`}
            >
              <FormattedMessage
                id="search.results.table.title"
                values={{
                  count: Intl.NumberFormat("en-US").format(
                    searchResults.secResults.length > 0 &&
                      searchResults.secResults[0].pageInfo
                      ? searchResults.secResults[0].pageInfo.totalRecordCount
                      : 0
                  ),
                }}
              />
            </div>
          )}
          <div
            className={classNames(
              `${BLOCK}__table-wrapper`,
              `${BLOCK}__tab-panel`,
              {
                [`${BLOCK}__tab-panel--selected`]: currentTab === 0,
              }
            )}
            onScroll={onScroll}
            aria-labelledby={`${BLOCK}__esg-reports-tabpanel`}
            role="tabpanel"
            ref={tableContainerRef}
            data-test={`${BLOCK}__table-wrapper`}
          >
            <Table
              headerItems={tableHeaders}
              rowItems={tableItems}
              showSpinner={searchResults.secNextPageLoading}
              selectedRow={
                documentViewerState.selectedDisclosure?.elementId
                  ? documentViewerState.selectedDisclosure?.elementId
                  : documentViewerState.selectedDisclosure?.filingId
              }
              sortBy={sortInfo.sortBy}
              sortOrder={sortInfo.sortOrder}
              onSort={onSort}
              className={`${BLOCK}__table`}
              defaultSelectedItems={comparisonState.selectedList.map(
                (item: any) =>
                  item.comparisonItemType === comparisonItemTypes.SEC
                    ? {
                        id: item.filingId,
                        mapped: true,
                        ...item,
                      }
                    : item
              )}
            />
          </div>
          <div
            className={classNames(`${BLOCK}__tab-panel`, {
              [`${BLOCK}__tab-panel--selected`]: currentTab === 1,
            })}
            aria-labelledby={`${BLOCK}__esg-toc-tabpanel`}
            role="tabpanel"
            data-test={`${BLOCK}__esg-toc-tabpanel`}
          >
            {showTOC && <TableOfContents />}
          </div>
        </div>
      )}
      {documentViewerState.selectedDisclosure && (
        <DocumentViewerSEC report={documentViewerState.selectedDisclosure} />
      )}
    </>
  );
};

export default SecSearchResults;
