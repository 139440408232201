import { withTooltip } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import Popover from "components/shared/popover/popover";
import { TooltipPosition } from "components/shared/tooltip/tooltip";
import Parser from "html-react-parser";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showTooltip } from "services/commons.service";
import {
  AssociatedMetricTooltips,
  Legend,
} from "services/dashboard/dashboard.model";
import { RootStore } from "services/store.service";
import { ESG_RATING_TILE, IVA_RATING_TREND } from "utils/constants";

export type Props = {
  data: any;
  width?: number;
  height?: number;
  showControls?: boolean;
  margin?: { top: number; right: number; bottom: number; left: number };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  axis?: { primary: string[]; secondary: string[] };
  associatedMetricsTooltip: any;
  associatedMetrics: any;
  isDetailedView: boolean;
};

const TrendChart = ({
  data,
  axis,
  associatedMetricsTooltip,
  associatedMetrics,
  isDetailedView,
}: Props & WithTooltipProvidedProps<Legend>) => {
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const dispatch = useDispatch();
  let tabs: any = [0, 1];
  const BLOCK = "trend-tile";
  const chartViewData = (data: any) => {
    if (!data) {
      return {
        companyName: "",
        isNotCompany: false,
        esgRatingCompanyTileEmpty: true,
        esgRatingPeersTileEmpty: true,
        risk: "",
        strength: "",
        companyRating: "",
        companyTrendRating: "",
      };
    }

    const baseCompanyName = data
      .filter((item: any) => item.isBaseCompany)
      .reduce((prev: any, curr: any) => curr.companyName, "");

    const peersName = new Set(
      data
        .filter((item: any) => !item.isBaseCompany)
        .map((item: any) => item.companyName)
    );
    let companyName: string[] = data.map((item: any) => item.companyName)
      ? isDetailedView && baseCompanyName === ""
        ? [...peersName]
        : [baseCompanyName, ...peersName]
      : [];

    const isNotCompanyView = data.some((item: any) => !item.isBaseCompany);
    const esgRatingCompanyTileEmpty =
      data.filter(
        (item: any) =>
          item.isBaseCompany &&
          item.fieldValue &&
          (item.fieldName === ESG_RATING_TILE.IVA_RATING_TREND ||
            item.fieldName === ESG_RATING_TILE.IVA_COMPANY_RATING ||
            item.fieldName === ESG_RATING_TILE.MSCI_Risks ||
            item.fieldName === ESG_RATING_TILE.MSCI_Strengths)
      ).length === 0;
    const esgRatingPeersTileEmpty =
      data.filter(
        (item: any) =>
          !item.isBaseCompany &&
          item.fieldValue &&
          (item.fieldName === ESG_RATING_TILE.IVA_RATING_TREND ||
            item.fieldName === ESG_RATING_TILE.IVA_COMPANY_RATING ||
            item.fieldName === ESG_RATING_TILE.MSCI_Risks ||
            item.fieldName === ESG_RATING_TILE.MSCI_Strengths)
      ).length === 0;
    const risk: any =
      data.filter((item: any) => item.fieldName === ESG_RATING_TILE.MSCI_Risks)
        ?.length > 0
        ? !isDetailedView
          ? [
              data
                .filter(
                  (item: any) =>
                    item.fieldName === ESG_RATING_TILE.MSCI_Risks &&
                    item.isBaseCompany
                )
                .map((item: any) => {
                  return item.fieldValue?.split(",");
                })[0],
              ...data
                .filter(
                  (item: any) =>
                    item.fieldName === ESG_RATING_TILE.MSCI_Risks &&
                    !item.isBaseCompany
                )
                .map((item: any) => {
                  return item.fieldValue?.split(",");
                }),
            ]
          : [
              ...data
                .filter(
                  (item: any) => item.fieldName === ESG_RATING_TILE.MSCI_Risks
                )
                .map((item: any) => {
                  return item.fieldValue?.split(",");
                }),
            ]
        : [];
    const strength: any =
      data.filter(
        (item: any) => item.fieldName === ESG_RATING_TILE.MSCI_Strengths
      )?.length > 0
        ? !isDetailedView
          ? [
              data
                .filter(
                  (item: any) =>
                    item.fieldName === ESG_RATING_TILE.MSCI_Strengths &&
                    item.isBaseCompany
                )
                .map((item: any) => {
                  return item.fieldValue?.split(",");
                })[0],
              ...data
                .filter(
                  (item: any) =>
                    item.fieldName === ESG_RATING_TILE.MSCI_Strengths &&
                    !item.isBaseCompany
                )
                .map((item: any) => {
                  return item.fieldValue?.split(",");
                }),
            ]
          : [
              ...data
                .filter(
                  (item: any) =>
                    item.fieldName === ESG_RATING_TILE.MSCI_Strengths
                )
                .map((item: any) => {
                  return item.fieldValue?.split(",");
                }),
            ]
        : [];

    const companyRating =
      data.filter(
        (item: any) => item.fieldName === ESG_RATING_TILE.IVA_COMPANY_RATING
      )?.length > 0
        ? data.filter(
            (item: any) =>
              item.fieldName === ESG_RATING_TILE.IVA_COMPANY_RATING &&
              item.isBaseCompany
          ).length > 0
          ? [
              ...(new Map(
                data
                  .filter(
                    (item: any) =>
                      item.fieldName === ESG_RATING_TILE.IVA_COMPANY_RATING &&
                      item.isBaseCompany
                  )
                  .map((obj: any) => [`${obj.cik}`, obj])
              ).values() as any),
              ...(new Map(
                data
                  .filter(
                    (item: any) =>
                      item.fieldName === ESG_RATING_TILE.IVA_COMPANY_RATING &&
                      !item.isBaseCompany
                  )
                  .map((obj: any) => [`${obj.cik}`, obj])
              ).values() as any),
            ]
          : [
              ...(new Map(
                data
                  .filter(
                    (item: any) =>
                      item.fieldName === ESG_RATING_TILE.IVA_COMPANY_RATING &&
                      !item.isBaseCompany
                  )
                  .map((obj: any) => [`${obj.cik}`, obj])
              ).values() as any),
            ]
        : "";

    const companyTrendRating =
      data.filter(
        (item: any) => item.fieldName === ESG_RATING_TILE.IVA_RATING_TREND
      )?.length > 0
        ? data.filter(
            (item: any) =>
              item.fieldName === ESG_RATING_TILE.IVA_RATING_TREND &&
              item.isBaseCompany
          ).length > 0
          ? [
              ...(new Map(
                data
                  .filter(
                    (item: any) =>
                      item.fieldName === ESG_RATING_TILE.IVA_RATING_TREND &&
                      item.isBaseCompany
                  )
                  .map((obj: any) => [`${obj.cik}`, obj])
              ).values() as any),
              ...(new Map(
                data
                  .filter(
                    (item: any) =>
                      item.fieldName === ESG_RATING_TILE.IVA_RATING_TREND &&
                      !item.isBaseCompany
                  )
                  .map((obj: any) => [`${obj.cik}`, obj])
              ).values() as any),
            ]
          : [
              ...(new Map(
                data
                  .filter(
                    (item: any) =>
                      item.fieldName === ESG_RATING_TILE.IVA_RATING_TREND &&
                      !item.isBaseCompany
                  )
                  .map((obj: any) => [`${obj.cik}`, obj])
              ).values() as any),
            ]
        : "";

    tabs =
      isNotCompanyView &&
      typeof companyName === "object" &&
      companyName.map((company: any, index: number) => index);

    return {
      companyName,
      isNotCompanyView,
      esgRatingCompanyTileEmpty,
      esgRatingPeersTileEmpty,
      risk,
      strength,
      companyRating,
      companyTrendRating,
    };
  };

  const tileData = data.isTrendTableView
    ? chartViewData(data.visData.data)
    : chartViewData(data.data);

  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("span");
    if (!element) {
      return;
    }

    dispatch(
      showTooltip({
        children: (
          <div
          // dangerouslySetInnerHTML={{
          //   __html: sanitize(children, { ADD_ATTR: ["target"] }),
          // }}
          />
        ),
        position: TooltipPosition.top,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  const setHyperLinks = (data: string) => {
    const links = data && data.match(/<link>(.*?)<\/link>/g);

    return links
      ? links.reduce((prev, curr) => {
          const relativeLink: string = curr
            .replace("<link>", "")
            .startsWith("http")
            ? ""
            : "//";
          return prev.replace(
            /<link>(.*?)<\/link>/g,
            `<a target="_blank" href="${relativeLink}$1">$1</a>`
          );
        }, data)
      : data;
  };
  const chartView = () => {
    return !tileData.isNotCompanyView ? (
      !tileData.esgRatingCompanyTileEmpty ? (
        <>
          <div className={`${BLOCK}`}>
            <div className={`${BLOCK}-top`}>
              <div className={`${BLOCK}-rating-content`}>
                {data &&
                data.data.filter(
                  (item: any) =>
                    item.fieldName === ESG_RATING_TILE.IVA_COMPANY_RATING
                )[0] ? (
                  <span
                    className={`${BLOCK}-rating ${BLOCK}-rating__${
                      data.data.filter(
                        (item: any) =>
                          item.fieldName === ESG_RATING_TILE.IVA_COMPANY_RATING
                      )[0]?.fieldValue || ""
                    }`}
                  >
                    <div className={`${BLOCK}__container`}>
                      <Popover
                        metricClassName={`${BLOCK}-rating ${BLOCK}-rating__${
                          data.data.filter(
                            (item: any) =>
                              item.fieldName ===
                              ESG_RATING_TILE.IVA_COMPANY_RATING
                          )[0]?.fieldValue || ""
                        }`}
                        buttonClassName={`${BLOCK}-rating ${BLOCK}-rating__${
                          data.data.filter(
                            (item: any) =>
                              item.fieldName ===
                              ESG_RATING_TILE.IVA_COMPANY_RATING
                          )[0]?.fieldValue || ""
                        }`}
                        displayText={
                          data.data.filter(
                            (item: any) =>
                              item.fieldName ===
                              ESG_RATING_TILE.IVA_COMPANY_RATING
                          )[0]?.fieldValue || (
                            <span className={`${BLOCK}-empty-rating`}>
                              <FormattedMessage id="insights.esg.no-data-rating" />
                            </span>
                          )
                        }
                        content={
                          associatedMetricsTooltip.length > 0
                            ? associatedMetricsTooltip.filter(
                                (
                                  associatedMetricTooltips: AssociatedMetricTooltips
                                ) =>
                                  associatedMetricTooltips?.associatedMetric ===
                                  ESG_RATING_TILE.IVA_COMPANY_RATING
                              )
                            : []
                        }
                      />
                    </div>
                  </span>
                ) : (
                  <span className={`${BLOCK}-empty-rating`}>
                    <FormattedMessage id="insights.esg.no-data-rating" />
                  </span>
                )}
                <span className={`trend-tile__icon`}>
                  <div className={`${BLOCK}__container`}>
                    <Popover
                      metricClassName={`${BLOCK}-details--name`}
                      displayText={Parser(
                        data &&
                          data.data.filter(
                            (item: any) =>
                              item.fieldName ===
                              ESG_RATING_TILE.IVA_RATING_TREND
                          )[0] &&
                          data.data.filter(
                            (item: any) =>
                              item.fieldName ===
                                ESG_RATING_TILE.IVA_RATING_TREND &&
                              data.metrics.includes(
                                ESG_RATING_TILE.IVA_RATING_TREND
                              )
                          )[0]?.fieldValueIcon
                          ? data.data.filter(
                              (item: any) =>
                                item.fieldName ===
                                  ESG_RATING_TILE.IVA_RATING_TREND &&
                                data.metrics.includes(
                                  ESG_RATING_TILE.IVA_RATING_TREND
                                )
                            )[0]?.fieldValueIcon
                          : ""
                      )}
                      content={
                        associatedMetricsTooltip.length > 0
                          ? associatedMetricsTooltip.filter(
                              (
                                associatedMetricTooltips: AssociatedMetricTooltips
                              ) =>
                                associatedMetricTooltips?.associatedMetric ===
                                ESG_RATING_TILE.IVA_RATING_TREND
                            )
                          : []
                      }
                    />
                  </div>
                </span>
                <div className={`${BLOCK}-details`}>
                  {axis && (
                    <span className={`${BLOCK}-details--name-fit-content`}>
                      <Popover
                        metricClassName={`${BLOCK}-details--name`}
                        displayText={axis.secondary[0]}
                        content={
                          associatedMetricsTooltip.length > 0
                            ? associatedMetricsTooltip.filter(
                                (
                                  associatedMetricTooltips: AssociatedMetricTooltips
                                ) =>
                                  associatedMetricTooltips?.associatedMetricDisplayName ===
                                  axis.secondary[0]
                              )
                            : []
                        }
                      />
                    </span>
                  )}
                </div>
              </div>
              <Icon name="trend-icon" width={100} height={62} />
            </div>
            <div className={`${BLOCK}-data`}>
              {axis &&
                axis.secondary.map((trend, index) => {
                  return trend === "MSCI Strengths" ? (
                    <span
                      key={`data ${index}`}
                      className={`${BLOCK}-data-title`}
                    >
                      <span className={`${BLOCK}-details--name-fit-content`}>
                        <Popover
                          metricClassName={`${BLOCK}-details--name`}
                          displayText={trend}
                          content={
                            associatedMetricsTooltip.length > 0
                              ? associatedMetricsTooltip.filter(
                                  (
                                    associatedMetricTooltips: AssociatedMetricTooltips
                                  ) =>
                                    associatedMetricTooltips?.associatedMetricDisplayName ===
                                    trend
                                )
                              : []
                          }
                        />
                      </span>

                      <span>
                        {tileData.strength[0] ? (
                          tileData.strength[0].map((item: any, index: any) => (
                            <span
                              key={`strength ${index}`}
                              className={`${BLOCK}-data-item`}
                            >
                              {item}
                            </span>
                          ))
                        ) : (
                          <span
                            className={`${BLOCK}-empty-rating ${BLOCK}-empty-rating-data`}
                          >
                            <FormattedMessage id="insights.esg.no-data" />
                          </span>
                        )}
                      </span>
                    </span>
                  ) : (
                    ""
                  );
                })}
              {axis &&
                axis.secondary.map((trend, index) => {
                  return trend === "MSCI Risks" ? (
                    <span
                      className={`${BLOCK}-data-title`}
                      key={`data ${index}`}
                    >
                      <span className={`${BLOCK}-details--name-fit-content`}>
                        <Popover
                          metricClassName={`${BLOCK}-details--name`}
                          displayText={trend}
                          content={
                            associatedMetricsTooltip.length > 0
                              ? associatedMetricsTooltip.filter(
                                  (
                                    associatedMetricTooltips: AssociatedMetricTooltips
                                  ) =>
                                    associatedMetricTooltips?.associatedMetricDisplayName ===
                                    trend
                                )
                              : []
                          }
                        />
                      </span>

                      <span>
                        {tileData.risk[0] ? (
                          tileData.risk[0].map((item: any, index: any) => (
                            <span
                              key={`risk ${index}`}
                              className={`${BLOCK}-data-item`}
                            >
                              {item}
                            </span>
                          ))
                        ) : (
                          <span
                            className={`${BLOCK}-empty-rating ${BLOCK}-empty-rating-data`}
                          >
                            <FormattedMessage id="insights.esg.no-data" />
                          </span>
                        )}
                      </span>
                    </span>
                  ) : (
                    ""
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        <span className={`${BLOCK}-empty-tile`}>
          <FormattedMessage id="insights.esg.no-data" />
        </span>
      )
    ) : !(
        tileData.esgRatingPeersTileEmpty && tileData.esgRatingCompanyTileEmpty
      ) ? (
      <div
        className={classNames(``, { [`${BLOCK}-wrap`]: !data.isDetailedView })}
        data-testid="wrapper"
      >
        {tabs.map((tab: any, tabIndex: number) => {
          return (
            <Fragment key={`${BLOCK}-${tabIndex}`}>
              <div className={`${BLOCK} ${BLOCK}-peers`}>
                <span className={`${BLOCK}-company`}>
                  {tileData.companyName[tab] as React.ReactNode}
                </span>
                <div
                  className={classNames(`${BLOCK}-top-peer`, {
                    [`${BLOCK}-top-detailed`]: data.isDetailedView,
                  })}
                >
                  <div className={`${BLOCK}-main-content-peers`}>
                    <div
                      className={`${BLOCK}-rating-content ${BLOCK}-rating-content-peers`}
                    >
                      {tileData.companyRating[tab] ? (
                        <div className={`${BLOCK}__containerForRating`}>
                          {tileData.companyRating[tab]?.fieldValue ? (
                            <Popover
                              metricClassName={`${BLOCK}-rating ${BLOCK}-rating__${
                                tileData.companyRating[tab]?.fieldValue || ""
                              }`}
                              buttonClassName={`${BLOCK}__container`}
                              displayText={
                                tileData.companyRating[tab]?.fieldValue
                              }
                              content={
                                associatedMetricsTooltip.length > 0
                                  ? associatedMetricsTooltip.filter(
                                      (
                                        associatedMetricTooltips: AssociatedMetricTooltips
                                      ) =>
                                        associatedMetricTooltips?.associatedMetric ===
                                        tileData.companyRating[tab]?.fieldName
                                    )
                                  : []
                              }
                            />
                          ) : (
                            <span className={`${BLOCK}-empty-rating`}>
                              <FormattedMessage id="insights.esg.no-data-rating" />
                            </span>
                          )}
                        </div>
                      ) : (
                        <span className={`${BLOCK}-empty-rating`}>
                          <FormattedMessage id="insights.esg.no-data-rating" />
                        </span>
                      )}
                      {tileData.companyRating[tab]?.cik > 0 ? (
                        <span className={`trend-tile__icon`}>
                          <div className={`${BLOCK}__container`}>
                            <Popover
                              metricClassName={`${BLOCK}-details--name`}
                              displayText={Parser(
                                data &&
                                  tileData.companyTrendRating[tab] &&
                                  data.metrics.includes(
                                    ESG_RATING_TILE.IVA_RATING_TREND
                                  ) &&
                                  tileData.companyTrendRating[tab]
                                    ?.fieldValueIcon
                                  ? tileData.companyTrendRating[tab]
                                      ?.fieldValueIcon
                                  : ""
                              )}
                              content={
                                associatedMetricsTooltip.length > 0
                                  ? associatedMetricsTooltip.filter(
                                      (
                                        associatedMetricTooltips: AssociatedMetricTooltips
                                      ) =>
                                        associatedMetricTooltips?.associatedMetric ===
                                        ESG_RATING_TILE.IVA_RATING_TREND
                                    )
                                  : []
                              }
                            />
                          </div>
                        </span>
                      ) : null}
                      <div className={`${BLOCK}-details`}>
                        {axis && (
                          <span
                            className={`${BLOCK}-details--name-fit-content`}
                          >
                            <Popover
                              metricClassName={`${BLOCK}-details--name`}
                              displayText={axis.secondary[0]}
                              content={
                                associatedMetricsTooltip.length > 0
                                  ? associatedMetricsTooltip.filter(
                                      (
                                        associatedMetricTooltips: AssociatedMetricTooltips
                                      ) =>
                                        associatedMetricTooltips?.associatedMetricDisplayName ===
                                        axis.secondary[0]
                                    )
                                  : []
                              }
                            />
                          </span>
                        )}
                      </div>
                    </div>
                    <Icon name="trend-icon" width={100} height={62} />
                  </div>
                  {axis && (
                    <div
                      className={classNames(`${BLOCK}-data-peers`, {
                        [`${BLOCK}-data-peers-detailed`]: data.isDetailedView,
                      })}
                    >
                      {axis.secondary.map((trend, index) => {
                        return trend === "MSCI Strengths" ? (
                          <span
                            key={`data ${index}`}
                            className={`${BLOCK}-data-title`}
                          >
                            <span
                              className={`${BLOCK}-details--name-fit-content`}
                            >
                              <Popover
                                metricClassName={`${BLOCK}-details--name`}
                                displayText={trend}
                                content={
                                  associatedMetricsTooltip.length > 0
                                    ? associatedMetricsTooltip.filter(
                                        (
                                          associatedMetricTooltips: AssociatedMetricTooltips
                                        ) =>
                                          associatedMetricTooltips?.associatedMetricDisplayName ===
                                          trend
                                      )
                                    : []
                                }
                              />
                            </span>
                            <span>
                              {tileData.strength[tab] ? (
                                tileData.strength[tab].map(
                                  (item: any, index: any) => (
                                    <span
                                      key={`strength ${index}`}
                                      className={classNames(
                                        `${BLOCK}-data-item`,
                                        {
                                          [`${BLOCK}-data-item-detailed`]:
                                            data.isDetailedView,
                                        }
                                      )}
                                    >
                                      {item}
                                    </span>
                                  )
                                )
                              ) : (
                                <span
                                  className={`${BLOCK}-empty-rating ${BLOCK}-empty-rating-data`}
                                >
                                  <FormattedMessage id="insights.esg.no-data" />
                                </span>
                              )}
                            </span>
                          </span>
                        ) : (
                          ""
                        );
                      })}
                      {axis.secondary.map((trend, index) => {
                        return trend === "MSCI Risks" ? (
                          <span
                            className={`${BLOCK}-data-title`}
                            key={`data ${index}`}
                          >
                            <span
                              className={`${BLOCK}-details--name-fit-content`}
                            >
                              <Popover
                                metricClassName={`${BLOCK}-details--name`}
                                displayText={trend}
                                content={
                                  associatedMetricsTooltip.length > 0
                                    ? associatedMetricsTooltip.filter(
                                        (
                                          associatedMetricTooltips: AssociatedMetricTooltips
                                        ) =>
                                          associatedMetricTooltips?.associatedMetricDisplayName ===
                                          trend
                                      )
                                    : []
                                }
                              />
                            </span>

                            <span>
                              {tileData.risk[tab] ? (
                                tileData.risk[tab].map(
                                  (item: any, index: any) => (
                                    <span
                                      key={`risk ${index}`}
                                      className={classNames(
                                        `${BLOCK}-data-item`,
                                        {
                                          [`${BLOCK}-data-item-detailed`]:
                                            data.isDetailedView,
                                        }
                                      )}
                                    >
                                      {item}
                                    </span>
                                  )
                                )
                              ) : (
                                <span
                                  className={`${BLOCK}-empty-rating ${BLOCK}-empty-rating-data`}
                                >
                                  <FormattedMessage id="insights.esg.no-data" />
                                </span>
                              )}
                            </span>
                          </span>
                        ) : (
                          ""
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    ) : (
      <span className={`${BLOCK}-empty-tile`}>{"No data available"}</span>
    );
  };

  const tableViewForCompany = () => {
    const tableData = data.visData;
    const ivaRatingTrend = tableData.data.filter(
      (item: any) => item.fieldName === ESG_RATING_TILE.IVA_RATING_TREND
    )[0]?.fieldValue;

    return !tileData.isNotCompanyView ? (
      !tileData.esgRatingCompanyTileEmpty ? (
        <>
          <div className={`${BLOCK}__table`}>
            <div className={`${BLOCK}__table-rating-content`}>
              <div className={`${BLOCK}__row`}>
                <span>
                  {data &&
                    data.associatedMetricsDisplayNames.map(
                      (trend: any, index: number) => {
                        return trend === "MSCI Score" ? (
                          <span>
                            {" "}
                            <Popover
                              metricClassName={`${BLOCK}__table-data--16`}
                              displayText={trend}
                              content={
                                associatedMetricsTooltip.length > 0
                                  ? associatedMetricsTooltip.filter(
                                      (
                                        associatedMetricTooltips: AssociatedMetricTooltips
                                      ) =>
                                        associatedMetricTooltips?.associatedMetricDisplayName ===
                                        trend
                                    )
                                  : []
                              }
                            />
                          </span>
                        ) : (
                          ""
                        );
                      }
                    )}
                </span>
                <span>
                  {tableData &&
                  tableData.data.filter(
                    (item: any) =>
                      item.fieldName === ESG_RATING_TILE.IVA_COMPANY_RATING
                  )[0] ? (
                    <span className={`${BLOCK}__table-fieldValue`}>
                      {tableData.data.filter(
                        (item: any) =>
                          item.fieldName === ESG_RATING_TILE.IVA_COMPANY_RATING
                      )[0]?.fieldValue || (
                        <span className={`${BLOCK}-empty-rating-table`}>
                          <FormattedMessage id="insights.esg.no-data-rating" />
                        </span>
                      )}{" "}
                    </span>
                  ) : (
                    <span className={`${BLOCK}-empty-rating`}>
                      <FormattedMessage id="insights.esg.no-data-rating" />
                    </span>
                  )}
                </span>
              </div>
              {data.visData.metrics.includes(
                ESG_RATING_TILE.IVA_RATING_TREND
              ) && (
                <div className={`${BLOCK}__row`}>
                  <span>
                    {data &&
                      data.associatedMetricsDisplayNames.map(
                        (trend: any, index: number) => {
                          return trend === "MSCI Score Trend" ? (
                            <span>
                              {" "}
                              <Popover
                                metricClassName={`${BLOCK}__table-data--16`}
                                displayText={trend}
                                content={
                                  associatedMetricsTooltip.length > 0
                                    ? associatedMetricsTooltip.filter(
                                        (
                                          associatedMetricTooltips: AssociatedMetricTooltips
                                        ) =>
                                          associatedMetricTooltips?.associatedMetricDisplayName ===
                                          trend
                                      )
                                    : []
                                }
                              />
                            </span>
                          ) : (
                            ""
                          );
                        }
                      )}
                  </span>
                  <span>
                    <span className={`${BLOCK}__table-fieldValue`}>
                      {tableData &&
                      tableData.data.filter(
                        (item: any) =>
                          item.fieldName === ESG_RATING_TILE.IVA_RATING_TREND
                      )[0]
                        ? // eslint-disable-next-line
                          ivaRatingTrend == 0
                          ? IVA_RATING_TREND.NO_CHANGE
                          : ivaRatingTrend > 0
                          ? IVA_RATING_TREND.INCREASE
                          : IVA_RATING_TREND.DECREASE
                        : ""}
                    </span>
                  </span>
                </div>
              )}
            </div>

            <div>
              <div className={`${BLOCK}__table-data`}>
                {axis &&
                  axis.secondary.map((trend, index) => {
                    return trend === "MSCI Strengths" ? (
                      <span
                        key={`data ${index}`}
                        className={`${BLOCK}-data-title`}
                      >
                        <span className={`${BLOCK}__table-data--margin-bottom`}>
                          <Popover
                            metricClassName={`${BLOCK}__table-data--gray`}
                            displayText={trend}
                            content={
                              associatedMetricsTooltip.length > 0
                                ? associatedMetricsTooltip.filter(
                                    (
                                      associatedMetricTooltips: AssociatedMetricTooltips
                                    ) =>
                                      associatedMetricTooltips?.associatedMetricDisplayName ===
                                      trend
                                  )
                                : []
                            }
                          />
                        </span>

                        <span>
                          {tileData.strength[0] ? (
                            tileData.strength[0].map(
                              (item: any, index: any) => (
                                <span
                                  key={`strength ${index}`}
                                  className={`${BLOCK}-data-item`}
                                >
                                  {item}
                                </span>
                              )
                            )
                          ) : (
                            <span
                              className={`${BLOCK}-empty-rating ${BLOCK}-empty-rating-data`}
                            >
                              <FormattedMessage id="insights.esg.no-data" />
                            </span>
                          )}
                        </span>
                      </span>
                    ) : (
                      ""
                    );
                  })}
                {axis &&
                  axis.secondary.map((trend, index) => {
                    return trend === "MSCI Risks" ? (
                      <span
                        className={`${BLOCK}-data-title`}
                        key={`data ${index}`}
                      >
                        <span className={`${BLOCK}__table-data--margin-bottom`}>
                          <Popover
                            metricClassName={`${BLOCK}__table-data--gray`}
                            displayText={trend}
                            content={
                              associatedMetricsTooltip.length > 0
                                ? associatedMetricsTooltip.filter(
                                    (
                                      associatedMetricTooltips: AssociatedMetricTooltips
                                    ) =>
                                      associatedMetricTooltips?.associatedMetricDisplayName ===
                                      trend
                                  )
                                : []
                            }
                          />
                        </span>

                        <span>
                          {tileData.risk[0] ? (
                            tileData.risk[0].map((item: any, index: any) => (
                              <span
                                key={`risk ${index}`}
                                className={`${BLOCK}-data-item`}
                              >
                                {item}
                              </span>
                            ))
                          ) : (
                            <span
                              className={`${BLOCK}-empty-rating ${BLOCK}-empty-rating-data`}
                            >
                              <FormattedMessage id="insights.esg.no-data" />
                            </span>
                          )}
                        </span>
                      </span>
                    ) : (
                      ""
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <span className={`${BLOCK}-empty-tile`}>{"No data available"}</span>
      )
    ) : !(
        tileData.esgRatingPeersTileEmpty && tileData.esgRatingCompanyTileEmpty
      ) ? (
      <div className={`${BLOCK}-wrap`} data-testid="wrapper">
        {tabs.map((tab: any, tabIndex: number) => {
          return (
            <Fragment key={`${BLOCK}-${tabIndex}`}>
              <div className={`${BLOCK} ${BLOCK}-peers`}>
                <span className={`${BLOCK}-company`}>
                  {tileData.companyName[tab] as React.ReactNode}
                </span>
                <div className={`${BLOCK}-top`}>
                  <div
                    className={`${BLOCK}-rating-content ${BLOCK}-rating-content-peers`}
                  >
                    {tileData.companyRating[tab] ? (
                      <span
                        onMouseEnter={(e) => {
                          if (
                            tileData.companyRating[tab]?.tooltip &&
                            tileData.companyRating[tab]?.tooltip !== ""
                          )
                            handleMouseEnter(
                              e,
                              tileData.companyRating[tab]?.tooltip || ""
                            );
                        }}
                        onMouseLeave={(e) => {
                          handleMouseLeave(e);
                        }}
                        className={`${BLOCK}-rating ${BLOCK}-rating__${
                          tileData.companyRating[tab]?.fieldValue || ""
                        }`}
                      >
                        {tileData.companyRating[tab]?.fieldValue || (
                          <span className={`${BLOCK}-empty-rating`}>
                            <FormattedMessage id="insights.esg.no-data-rating" />
                          </span>
                        )}{" "}
                      </span>
                    ) : (
                      <span className={`${BLOCK}-empty-rating`}>
                        <FormattedMessage id="insights.esg.no-data-rating" />
                      </span>
                    )}
                    <span
                      className={`trend-tile__icon`}
                      onMouseEnter={(e) => {
                        handleMouseEnter(
                          e,
                          setHyperLinks(associatedMetricsTooltip[1])
                        );
                      }}
                      onMouseLeave={(e) => {
                        handleMouseLeave(e);
                      }}
                    />
                    <div className={`${BLOCK}-details`}>
                      {axis && (
                        <span className={`${BLOCK}-details--name-fit-content`}>
                          <Popover
                            metricClassName={`${BLOCK}-details--name`}
                            displayText={axis.secondary[0]}
                            content={
                              associatedMetricsTooltip.length > 0
                                ? associatedMetricsTooltip.filter(
                                    (
                                      associatedMetricTooltips: AssociatedMetricTooltips
                                    ) =>
                                      associatedMetricTooltips?.associatedMetricDisplayName ===
                                      axis.secondary[0]
                                  )
                                : []
                            }
                          />
                        </span>
                      )}
                    </div>
                  </div>
                  <Icon name="trend-icon" width={100} height={62} />
                </div>
                <div className={`${BLOCK}-data-peers`}>
                  {axis &&
                    axis.secondary.map((trend, index) => {
                      return trend === "MSCI Strengths" ? (
                        <span
                          key={`${index}__stren`}
                          className={`${BLOCK}-details--name-fit-content`}
                        >
                          <Popover
                            metricClassName={`${BLOCK}-details--name`}
                            displayText={trend}
                            content={
                              associatedMetricsTooltip.length > 0
                                ? associatedMetricsTooltip.filter(
                                    (
                                      associatedMetricTooltips: AssociatedMetricTooltips
                                    ) =>
                                      associatedMetricTooltips?.associatedMetricDisplayName ===
                                      axis.secondary[0]
                                  )
                                : []
                            }
                          />
                        </span>
                      ) : (
                        ""
                      );
                    })}
                  {axis &&
                    axis.secondary.map((trend, index) => {
                      return trend === "MSCI Risks" ? (
                        <span
                          className={`${BLOCK}-data-title `}
                          key={`data ${index}`}
                        >
                          <span
                            key={`${index}__stren`}
                            className={`${BLOCK}-details--name-fit-content`}
                          >
                            <Popover
                              metricClassName={`${BLOCK}-details--name`}
                              displayText={trend}
                              content={
                                associatedMetricsTooltip.length > 0
                                  ? associatedMetricsTooltip.filter(
                                      (
                                        associatedMetricTooltips: AssociatedMetricTooltips
                                      ) =>
                                        associatedMetricTooltips?.associatedMetricDisplayName ===
                                        axis.secondary[0]
                                    )
                                  : []
                              }
                            />
                          </span>

                          <span>
                            {tileData.risk[tab] ? (
                              tileData.risk[tab].map(
                                (item: any, index: any) => (
                                  <span
                                    key={`risk ${index}`}
                                    className={`${BLOCK}-data-item `}
                                  >
                                    {item}
                                  </span>
                                )
                              )
                            ) : (
                              <span
                                className={`${BLOCK}-empty-rating ${BLOCK}-empty-rating-data`}
                              >
                                <FormattedMessage id="insights.esg.no-data" />
                              </span>
                            )}
                          </span>
                        </span>
                      ) : (
                        ""
                      );
                    })}
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    ) : (
      <span className={`${BLOCK}-empty-tile`}>{"No data available"}</span>
    );
  };

  return (
    <Fragment>
      {data.isTrendTableView ? tableViewForCompany() : chartView()}
    </Fragment>
  );
};

export default withTooltip<Props, Legend>(TrendChart);
