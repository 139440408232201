import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import { useDispatch } from "react-redux";
import { isNumeric, handleMouseEnter, handleMouseLeave } from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";

type Props = {
  benchmarkType: number;
  benchmarkSubTitle: string; //legend
  data: InsightData[];
  gradientBackgroundValue: string;
  legendgradientBackground?: string;
};
const HorizontalGraduatedChart = ({
  benchmarkType,
  benchmarkSubTitle,
  data,
  gradientBackgroundValue,
  legendgradientBackground,
}: Props) => {
  const BLOCK = "horizontalGraduatedChart";
  const dispatch = useDispatch();
  if (data.length === 0) return <div></div>;
  const hasEmptyValues = data.some((d: InsightData) =>
    d.metrics.some(
      (m: InsightMetricData) => m.metricValue === null || m.metricValue === "*"
    )
  );
  const getRelativeWidth = (value: any) => {
    return isNumeric(value) ? +value * 10 : 0;
  };
  return (
    <div className={`${BLOCK}__container`} data-testid={`${BLOCK}__container`}>
      <div className={`${BLOCK}__legend-section`}>
        <span className={`${BLOCK}__legend-text`}>{benchmarkSubTitle}</span>
        {benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY ? (
          <div className={`${BLOCK}__legend-companies`}>
            {data.map((id: InsightData, companyInd: number) => (
              <div
                key={`${companyInd}-company-legend`}
                className={`${BLOCK}__legend-company-block`}
              >
                {id.metrics[0].metricValue === null ? null : companyInd == 0 &&
                  legendgradientBackground ? (
                  <div
                    className={`${BLOCK}__legend-company-pill`}
                    style={{
                      background: legendgradientBackground,
                    }}
                  ></div>
                ) : (
                  <div
                    className={`${BLOCK}__legend-company-pill`}
                    style={{
                      backgroundColor: `${id.metrics[0].metricValueColor}`,
                    }}
                  ></div>
                )}
                <span>{`${id.companyName}${
                  id.metrics[0].metricValue === null ? " *" : ""
                }`}</span>
              </div>
            ))}
          </div>
        ) : null}
      </div>

      <div className={`${BLOCK}__wrapper`}>
        <div className={`${BLOCK}__ruler-section ${BLOCK}__ruler-section--top`}>
          <div
            className={`${BLOCK}__number-section ${BLOCK}__number-section--top`}
          >
            {benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY ? null : data[1]
                .metrics[0].metricValue === null ? null : (
              <div
                className={`${BLOCK}__circle ${BLOCK}__numeric`}
                style={{
                  backgroundColor: `${data[1].metrics[0].metricValueColor}`,
                  left: `calc( ${getRelativeWidth(
                    data[1].metrics[0].metricValue
                  )}% - 1rem )`,
                }}
                data-testid={"peer-numeric"}
                onMouseEnter={(e: any) => {
                  handleMouseEnter(
                    e,
                    "div",
                    <div>
                      {data[1].companyName +
                        " (" +
                        (data[1].metrics[0].metricValue ?? "*") +
                        ")"}
                    </div>,
                    "",
                    dispatch,
                    null,
                    MainTooltipPosition.TopRight
                  );
                }}
                onMouseLeave={(e: any) => {
                  handleMouseLeave(e, dispatch, false);
                }}
              >
                {data[1].metrics[0].metricValue}
              </div>
            )}
          </div>
          {(benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY &&
            data[0].metrics[0].metricValue === null) ||
          (benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY &&
            data[1].metrics[0].metricValue === null) ? null : (
            <div
              className={`${BLOCK}__arrow-down-wrapper`}
              style={{
                left:
                  benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
                    ? ["0", "0.0"].includes(data[0].metrics[0].metricValue)
                      ? `-11px`
                      : `calc( ${getRelativeWidth(
                          data[0].metrics[0].metricValue
                        )}% - 11px )`
                    : ["0", "0.0"].includes(data[1].metrics[0].metricValue)
                    ? `-11px`
                    : `calc( ${getRelativeWidth(
                        data[1].metrics[0].metricValue
                      )}% - 11px )`,
              }}
            >
              <div className={`${BLOCK}__arrow-down-container`}>
                <div
                  className={`${BLOCK}__arrow-down`}
                  style={{
                    borderTopColor:
                      benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
                        ? `${data[0].metrics[0].metricValueColor}`
                        : `${data[1].metrics[0].metricValueColor}`,
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
        <div className={`${BLOCK}__bar-section`}>
          <div
            className={`${BLOCK}__bar`}
            style={{
              background: `${gradientBackgroundValue}`,
              width: `${getRelativeWidth(data[0].metrics[0].metricValue)}%`,
            }}
            data-testid={"company-bar"}
            onMouseEnter={(e: any) => {
              handleMouseEnter(
                e,
                "div",
                <div>
                  {data[0].companyName +
                    " (" +
                    (data[0].metrics[0].metricValue ?? "*") +
                    ")"}
                </div>,
                "",
                dispatch,
                null,
                MainTooltipPosition.TopRight
              );
            }}
            onMouseLeave={(e: any) => {
              handleMouseLeave(e, dispatch, false);
            }}
          ></div>
        </div>
        <div className={`${BLOCK}__ruler-section`}>
          <div className={`${BLOCK}__ruler`}>
            <div className={`${BLOCK}__ruler-measure`}></div>
            <div className={`${BLOCK}__ruler-measure`}></div>
            <div className={`${BLOCK}__ruler-measure`}></div>
            <div className={`${BLOCK}__ruler-measure`}></div>
            <div className={`${BLOCK}__ruler-measure`}></div>
            <div className={`${BLOCK}__ruler-measure`}></div>
            <div className={`${BLOCK}__ruler-measure`}></div>
            <div className={`${BLOCK}__ruler-measure`}></div>
            <div className={`${BLOCK}__ruler-measure`}></div>
            <div className={`${BLOCK}__ruler-measure`}></div>
            <div className={`${BLOCK}__ruler-measure`}></div>
            {data[0].metrics[0].metricValue === null ? null : (
              <div
                className={`${BLOCK}__circle ${BLOCK}__numeric ${BLOCK}__circle--bottom`}
                style={{
                  backgroundColor: `${data[0].metrics[0].metricValueColor}`,
                  left: `calc( ${getRelativeWidth(
                    data[0].metrics[0].metricValue
                  )}% - 1rem )`,
                }}
                data-testid={"company-numeric"}
                onMouseEnter={(e: any) => {
                  handleMouseEnter(
                    e,
                    "div",
                    <div>
                      {data[0].companyName +
                        " (" +
                        (data[0].metrics[0].metricValue ?? "*") +
                        ")"}
                    </div>,
                    "",
                    dispatch,
                    null,
                    MainTooltipPosition.TopRight
                  );
                }}
                onMouseLeave={(e: any) => {
                  handleMouseLeave(e, dispatch, false);
                }}
              >
                {data[0].metrics[0].metricValue}
              </div>
            )}
          </div>
          <div className={`${BLOCK}__number-section`}>
            <div className={`${BLOCK}__number`}>0</div>
            <div className={`${BLOCK}__number`}>5</div>
            <div className={`${BLOCK}__number`}>10</div>
          </div>
          {benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY ||
          data[0].metrics[0].metricValue === null ? null : (
            <div
              className={`${BLOCK}__arrow-up-wrapper`}
              style={{
                left: ["0", "0.0"].includes(data[0].metrics[0].metricValue)
                  ? `-11px`
                  : `calc( ${getRelativeWidth(
                      data[0].metrics[0].metricValue
                    )}% - 11px )`,
              }}
            >
              <div className={`${BLOCK}__arrow-up-container`}>
                <div
                  className={`${BLOCK}__arrow-up`}
                  style={{
                    borderTopColor: `${data[0].metrics[0].metricValueColor}`,
                    borderBottom: `14.4px solid ${data[0].metrics[0].metricValueColor}`,
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </div>

      {hasEmptyValues && (
        <div className={`${BLOCK}__no-data-section`}>
          <span>
            <FormattedMessage id="no.data.available" />
          </span>
        </div>
      )}
    </div>
  );
};

export default HorizontalGraduatedChart;
