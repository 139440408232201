import Icon from "components/shared/icon/icon";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import React, { useEffect, useState, useRef } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import DropDown from "../../components/search/filters/drop-down";
import { RootStore } from "services/store.service";
import { useSelector } from "react-redux";
import {
  FILTER_OPTIONS,
  REPORT_STATUS_ERRORS,
  REPORT_STATUS_ID,
} from "utils/constants";
import { PendingItem } from "services/manage-files/manage-files.model";
import classNames from "classnames";
import { FormTypeFilter } from "services/search/filters.model";

type RowProps = {
  index: number;
  rowItem: any;
  removeRow: (row: any, index: number, isMissingData: boolean) => void;
  searchState: any;
  periodYearOptions: any;
  reportTypeOptions: any;
  esgFrameworksOptions: any;
  addIndex: any;
  currentIndexesMissing: any;
  handleChange: (item: PendingItem, index: number) => void;
};

const MapfilesTableRow = ({
  index,
  rowItem,
  removeRow,
  searchState,
  periodYearOptions,
  reportTypeOptions,
  esgFrameworksOptions,
  addIndex,
  currentIndexesMissing,
  handleChange,
}: RowProps) => {
  const BLOCK = "mapfiles-table-row";
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const cikNumber = rowItem.reportCompany.cikNumber;
  const [cikNumberStr, setCikNumberStr] = useState<string>(
    cikNumber && cikNumber > 0 ? `${cikNumber}` : ""
  );
  // const [requiredFieldsMissing, setRequiredFieldsMissing] =
  //   useState<boolean>(false);
  const [documentType, setDocumentType] = useState<FormTypeFilter[]>([]);
  const [esgFrameworks, setEsgFrameworks] = useState<any>([]);
  const [reportYears, setReportYears] = useState<{ periodYear: string }[]>([]);
  const [hideAllFrameworks, setHideAllFrameworks] = useState<boolean>(false);
  const [localMetaDataMissing, setLocalMetaDataMissing] =
    useState<boolean>(false);
  const [operationFailedError, setOperationFailedError] =
    useState<boolean>(false);
  const [hideAllYears, setHideAllYears] = useState<boolean>(false);
  const manageFilesState = useSelector((state: RootStore) => state.manageFiles);
  const indexFound = manageFilesState.updatedPendingItems.findIndex(
    (item) => item.reportId === rowItem.reportId
  );

  useEffect(() => {
    if (esgFrameworks.length > 2) {
      setHideAllFrameworks(true);
    } else {
      setHideAllFrameworks(false);
    }
  }, [esgFrameworks]);

  useEffect(() => {
    if (reportYears.length > 2) {
      setHideAllYears(true);
    } else {
      setHideAllYears(false);
    }
  }, [reportYears]);

  useEffect(() => {
    const esgFrameWorksList = rowItem.governingBoards.filter(
      (framework: any) => framework.governingBoardName !== null
    );
    const esgFramWorksFinalList = esgFrameWorksList.map((framework: any) => {
      return {
        governingBoardId: framework.governingBoardId,
        governingBoardName:
          framework.governingBoardCode ||
          esgFrameworksOptions.find(
            (f: any) => f.governingBoardId === framework.governingBoardId
          )?.governingBoardCode ||
          framework.governingBoardName,
      };
    });
    let tmpReportYears =
      rowItem.reportYears.map((y: number) => {
        return { periodYear: y.toString() };
      }) || [];
    setReportYears(tmpReportYears);

    const documentType =
      rowItem.reportFormType && rowItem.reportFormType.formTypeId !== 0
        ? [rowItem.reportFormType]
        : [];
    setDocumentType(documentType);

    setEsgFrameworks(esgFramWorksFinalList);

    const cikNumber =
      rowItem.reportCompany && rowItem.reportCompany.cikNumber
        ? rowItem.reportCompany.cikNumber
        : "";
    setCikNumberStr(cikNumber);

    const reportStatus = rowItem.reportStatus;
    if (
      reportStatus >= REPORT_STATUS_ERRORS.FILE_NOT_FOUND &&
      reportStatus <= REPORT_STATUS_ERRORS.UNSUPPORTED_FORMAT &&
      reportStatus !== REPORT_STATUS_ERRORS.METADATA_MISSING
    ) {
      setOperationFailedError(true);
    } else if (reportStatus === REPORT_STATUS_ERRORS.METADATA_MISSING) {
      setLocalMetaDataMissing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [renderFirstTime, setRenderFirstTime] = useState(true);

  useEffect(() => {
    let isMissingDta = currentIndexesMissing.find(
      (file: any) => file.index === index
    );
    if (
      (cikNumberStr.length === 0 || documentType.length === 0) &&
      (rowItem.reportStatus <= REPORT_STATUS_ERRORS.METADATA_MISSING ||
        rowItem.reportStatus > REPORT_STATUS_ERRORS.UNSUPPORTED_FORMAT) &&
      rowItem.reportStatus !== REPORT_STATUS_ERRORS.FILE_NOT_FOUND &&
      rowItem.reportStatus !== REPORT_STATUS_ERRORS.INVALID_CIK_NUMBER
    ) {
      let currentReport = manageFilesState.deletedRows.find(
        (dr: number) => dr === rowItem.reportId
      );
      if (currentReport && !renderFirstTime) {
        const filteredList = currentIndexesMissing.filter(
          (report: any) => report.index !== index
        );
        addIndex(filteredList);
      } else {
        if (!isMissingDta && !renderFirstTime) {
          addIndex([
            ...currentIndexesMissing,
            {
              index: index,
              metaDataMissing: true,
            },
          ]);
        }
      }

      setLocalMetaDataMissing(true);
    } else if (cikNumberStr.length !== 0 && documentType.length !== 0) {
      if (isMissingDta && !renderFirstTime) {
        const filteredList = currentIndexesMissing.filter(
          (report: any) => report.index !== index
        );

        addIndex(filteredList);
      }
      if (rowItem.reportStatus === REPORT_STATUS_ERRORS.METADATA_MISSING) {
        let updatedItem = manageFilesState.updatedPendingItems[indexFound];
        updatedItem.reportStatus = REPORT_STATUS_ID.PRELOADED;
        handleChange(updatedItem, indexFound);
      }

      setLocalMetaDataMissing(false);
    }
    if (renderFirstTime) {
      setRenderFirstTime(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cikNumberStr, documentType, manageFilesState.deletedRows]);

  // // No use as of now, the change is being sent to parent component, and directly updating the pendingItems state
  // const dispatchToReducer = (updatedItem: PendingItem) => {
  //   let updatedItems = [...manageFilesState.updatedPendingItems];
  //   updatedItems[indexFound] = updatedItem;

  //   dispatch(updatePendingItemRow(updatedItems));
  // };

  const handleFilterChange = (e: any) => {
    const value = e.target.value;
    const re = /^[0-9, ]+$/;

    if (value === "" || re.test(value)) {
      setCikNumberStr(value);

      let updatedItem = manageFilesState.updatedPendingItems[indexFound];
      updatedItem.reportCompany!.cikNumber = value;

      handleChange(updatedItem, indexFound);
      //dispatchToReducer(updatedItem);
    }
  };

  const handleSelectDocumentType = (option: any) => {
    let tmpDocumentType: FormTypeFilter[] = [option];
    setDocumentType(tmpDocumentType);

    let updatedItem = manageFilesState.updatedPendingItems[indexFound];
    updatedItem.reportFormType = option;

    handleChange(updatedItem, indexFound);
    //dispatchToReducer(updatedItem);
  };

  const unselectDocumentType = (formType?: FormTypeFilter) => {
    let tmpDocumentType: FormTypeFilter[] = [];
    setDocumentType(tmpDocumentType);
  };

  const handleSelectFramework = (option: any) => {
    const newESGFrameworksList = [
      ...esgFrameworks,
      {
        governingBoardId: option.governingBoardId,
        governingBoardName:
          option.governingBoardCode ||
          esgFrameworksOptions.find(
            (f: any) => f.governingBoardId === option.governingBoardId
          )?.governingBoardCode ||
          option.governingBoardName,
      },
    ];

    setEsgFrameworks(newESGFrameworksList);

    let updatedItem = manageFilesState.updatedPendingItems[indexFound];
    updatedItem.governingBoards = newESGFrameworksList;

    handleChange(updatedItem, indexFound);
    //dispatchToReducer(updatedItem);
  };

  const handleUnselectFramework = (option: any) => {
    const newESGFrameworksList = esgFrameworks.filter(
      (framework: any) => framework.governingBoardId !== option.governingBoardId
    );
    setEsgFrameworks(newESGFrameworksList);

    let updatedItem = manageFilesState.updatedPendingItems[indexFound];
    updatedItem.governingBoards = newESGFrameworksList;

    handleChange(updatedItem, indexFound);
    //dispatchToReducer(updatedItem);
  };

  const getDocumentType = (documentTypeFilterOptions: any) => {
    let documentTypeOptions = documentTypeFilterOptions.filter(
      (document: any) => {
        return document.documentCategoryId === 1;
      }
    );
    return documentTypeOptions;
  };

  const getYears = () => {
    let today = new Date().getFullYear();
    let years: { periodYear: string }[] = [];
    for (let i = today - 5; i <= today + 5; i++) {
      years.push({ periodYear: i.toString() });
    }
    rowItem.reportYears.forEach((y: number) => {
      if (!years.find((p) => p.periodYear === y.toString())) {
        years.push({ periodYear: y.toString() });
      }
    });

    years.sort(
      (a: { periodYear: string }, b: { periodYear: string }) =>
        parseInt(a.periodYear) - parseInt(b.periodYear)
    );
    return years;
  };

  const selectPeriod = (year: any) => {
    let tmpYears: any[] = [...reportYears];
    tmpYears.push({ periodYear: year.periodYear });
    setReportYears(tmpYears);
    let updatedItem = manageFilesState.updatedPendingItems[indexFound];
    updatedItem.reportYears = tmpYears.map((y: { periodYear: string }) =>
      parseInt(y.periodYear)
    );

    handleChange(updatedItem, indexFound);
  };

  const unselectPeriod = (year: any) => {
    let tmpReportYears = reportYears.filter(
      (y: { periodYear: string }) => y.periodYear !== year.periodYear
    );
    setReportYears(tmpReportYears);
    let updatedItem = manageFilesState.updatedPendingItems[indexFound];
    updatedItem.reportYears = tmpReportYears.map((y: { periodYear: string }) =>
      parseInt(y.periodYear)
    );

    handleChange(updatedItem, indexFound);
  };

  return (
    <tr
      className={classNames(`${BLOCK}__row`, {
        [`${BLOCK}__row--missing`]: localMetaDataMissing,
      })}
      key={`${index}_${rowItem.reportId}__body`}
    >
      <td
        className={`${BLOCK}__row--title`}
        key={`${index}_${rowItem.reportId}__row--title`}
      >
        <span
          className={`${BLOCK}__row--title--text ${BLOCK}__hover-tooltip ${BLOCK}__row--title--tooltip`}
        >
          {rowItem.reportTitle && rowItem.reportTitle.length > 25
            ? rowItem.reportFileName.slice(0, 25) +
              "..." +
              rowItem.reportFileName.slice(-3)
            : rowItem.reportFileName}

          {rowItem.reportTitle && rowItem.reportTitle.length > 25 ? (
            <Tooltip position={TooltipPosition.right}>
              {rowItem.reportFileName}
            </Tooltip>
          ) : (
            ""
          )}
        </span>
      </td>
      {!operationFailedError && (
        <React.Fragment key={`${index}_${rowItem.reportId}__row--cik__div`}>
          <td
            className={`${BLOCK}__row--cik`}
            key={`${index}_${rowItem.reportId}__row--cik`}
          >
            <div>
              <textarea
                key={index}
                id={`${index}`}
                ref={textareaRef}
                value={cikNumberStr}
                className={classNames(`${BLOCK}__input`, {
                  [`${BLOCK}__input--missing`]: cikNumberStr.length === 0,
                })}
                onChange={handleFilterChange}
                placeholder={cikNumberStr === "" ? "Enter CIK No" : ""}
                data-testid="text-area"
              />
              {cikNumberStr.length === 0 && (
                <div className={`${BLOCK}__missingField`}>
                  <FormattedMessage id="map-files-missing-metadata" />
                </div>
              )}
            </div>
          </td>
          <td
            className={`${BLOCK}__row--reportType`}
            key={`${index}_${rowItem.reportId}__row--reportType`}
          >
            <DropDown
              className={`${BLOCK}__dropdown--report`}
              placeholder={
                <FormattedMessage id="map-files-missing-document-type" />
              }
              loading={
                searchState.loadingFilter.filter(
                  (filter: any) => filter === FILTER_OPTIONS.DOCUMENT_TYPE
                ).length > 0
              }
              objectKeys={{
                name: "documentCategoryName",
                id: "documentCategoryId",
                externalId: "documentCategoryId",
                children: "formTypes",
                childName: "formTypeName",
                childId: "formTypeId",
              }}
              values={documentType}
              options={getDocumentType(reportTypeOptions[0])}
              handleSelect={handleSelectDocumentType}
              handleUnselect={unselectDocumentType}
              handleGroupUnselect={unselectDocumentType}
              mapFiles={true}
              fieldMissing={documentType.length === 0}
            />
            {documentType.length === 0 && (
              <div className={`${BLOCK}__missingField`}>
                <FormattedMessage id="map-files-missing-metadata" />
              </div>
            )}
          </td>
          <td
            className={`${BLOCK}__row--esgFrameWork`}
            key={`${index}_${rowItem.reportId}__row--esgFrameWork`}
          >
            <DropDown
              className={`${BLOCK}__dropdown--framework`}
              placeholder={
                <FormattedMessage id="map-files-missing-esgFramework-type" />
              }
              loading={
                searchState.loadingFilter.filter(
                  (filter: any) => filter === FILTER_OPTIONS.DOCUMENT_TYPE
                ).length > 0
              }
              objectKeys={{
                name: "governingBoardName",
                id: "governingBoardId",
                externalId: "governingBoardId",
              }}
              values={esgFrameworks}
              options={esgFrameworksOptions}
              handleSelect={handleSelectFramework}
              handleUnselect={handleUnselectFramework}
              mapFiles={true}
              hideValues={hideAllFrameworks}
              showAllValues={setHideAllFrameworks}
            />
          </td>
          <td
            className={`${BLOCK}__row--reportYear`}
            key={`${index}_${rowItem.reportId}__row--reportYear`}
          >
            <DropDown
              className={`${BLOCK}__dropdown--year`}
              placeholder={<FormattedMessage id="map-files-missing-year" />}
              loading={
                searchState.loadingFilter.filter(
                  (filter: any) => filter === FILTER_OPTIONS.PERIOD
                ).length > 0
              }
              objectKeys={{
                name: "periodYear",
                id: "periodYear",
                externalId: "periodYear",
              }}
              values={reportYears}
              options={getYears()}
              handleSelect={selectPeriod}
              handleUnselect={unselectPeriod}
              handleGroupUnselect={unselectPeriod}
              hideValues={hideAllYears}
              showAllValues={setHideAllYears}
              mapFiles={true}
            />
          </td>
        </React.Fragment>
      )}
      {operationFailedError && (
        <td
          colSpan={5}
          className={`${BLOCK}__row--errorMessage`}
          key={`${index}_${rowItem.reportId}__row--errorMessage`}
        >
          <Icon
            name={"warning-diamond"}
            height={15}
            width={15}
            className={`${BLOCK}__row--errorMessage--icon`}
          />
          <span className={`${BLOCK}__row--errorMessage--title`}>
            <FormattedMessage id={`map-files-error-${rowItem.reportStatus}`} />
          </span>
        </td>
      )}
      <td
        className={classNames(`${BLOCK}__row--removeIcon`, {
          [`${BLOCK}__row--removeIcon--red`]:
            rowItem.reportStatus >= REPORT_STATUS_ERRORS.INVALID_CIK_NUMBER &&
            rowItem.reportStatus <= REPORT_STATUS_ERRORS.UNSUPPORTED_FORMAT &&
            rowItem.reportStatus !== REPORT_STATUS_ERRORS.METADATA_MISSING,
        })}
        key={`${index}_${rowItem.reportId}__row--removeIcon`}
      >
        <span
          className={classNames({
            [`${BLOCK}__row--removeIcon--red__inner`]:
              (rowItem.reportStatus >=
                REPORT_STATUS_ERRORS.INVALID_CIK_NUMBER &&
                rowItem.reportStatus <=
                  REPORT_STATUS_ERRORS.UNSUPPORTED_FORMAT &&
                rowItem.reportStatus !==
                  REPORT_STATUS_ERRORS.METADATA_MISSING) ||
              rowItem.reportStatus === REPORT_STATUS_ERRORS.FILE_NOT_FOUND,
          })}
          onClick={() => {
            removeRow(rowItem, index, localMetaDataMissing);
          }}
          data-testid="delete-row"
        >
          {(rowItem.reportStatus >= REPORT_STATUS_ERRORS.FILE_NOT_FOUND &&
            rowItem.reportStatus <= REPORT_STATUS_ERRORS.UNSUPPORTED_FORMAT &&
            rowItem.reportStatus !== REPORT_STATUS_ERRORS.METADATA_MISSING) ||
          localMetaDataMissing ? (
            <Icon
              name={
                manageFilesState.deletedRows.includes(rowItem.reportId)
                  ? "plus"
                  : "remove-red"
              }
              height={15}
              width={15}
            />
          ) : (
            <Icon
              name={
                manageFilesState.deletedRows.includes(rowItem.reportId)
                  ? "plus"
                  : "remove1"
              }
              height={15}
              width={15}
            />
          )}
        </span>
      </td>
    </tr>
  );
};

export default MapfilesTableRow;
