import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { CdpScope3CategoriesStatus } from "services/insights/insights.model";
import TileEmptyContent from "../tile-empty-content";
import Popover from "components/shared/popover/popover";
import Parser from "html-react-parser";
import { NEUTRAL_VALUES, statusColors } from "utils/constants";
import { RootStore } from "services/store.service";
import { Scope3CategoriesColorCodes } from "services/commons.model";
import { useDispatch, useSelector } from "react-redux";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";

type Props = {
  categoryStatusData?: CompanyReportedRelevance;
  benchmarkType: number;
  peerIndStatusData?: any;
  associatedMetricGroupTooltips?: AssociatedMetricTooltips[];
};

type CompanyReportedRelevance = {
  data: CdpScope3CategoriesStatus[];
  categoryName: string;
};

type Label = {
  id: string;
  label: string;
  tooltip: any;
};

const CDPScope3CategoriesStatus = ({
  categoryStatusData,
  benchmarkType,
  peerIndStatusData,
  associatedMetricGroupTooltips,
}: Props) => {
  const BLOCK = "cdpScope3CategoriesStatus";
  const commonsState = useSelector((state: RootStore) => state.commons);
  const dispatch = useDispatch();
  const getNeutralTooltip = (value: any) =>
    value && NEUTRAL_VALUES.includes(value) ? (
      <FormattedMessage id="insights.tabular.neutral" />
    ) : null;

  const mainTooltip = useSelector(
    (store: RootStore) => store.commons.mainTooltip
  );
  const hasNoData = peerIndStatusData?.labels?.reduce(
    (acc: any, l: any) =>
      acc &&
      peerIndStatusData.response.filter(
        (d: any) =>
          d[l.id] === null ||
          d[l.id] === undefined ||
          d[l.id] === "*" ||
          d[l.id] === ""
      ).length === peerIndStatusData.response.length,
    true
  );

  const getDot = (value: string) => {
    const categoryColors: Scope3CategoriesColorCodes[] =
      commonsState.scope3CategoriesColorCodes?.length > 0
        ? commonsState.scope3CategoriesColorCodes
        : statusColors;
    const color = categoryColors.find(
      (item: Scope3CategoriesColorCodes) => item.fieldValue === value
    );
    const colorCode = color ? color.colorCode : "";

    const dotStyle = {
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: `${colorCode}`,
    };

    return <div style={dotStyle}> </div>;
  };

  const companyViewStatus = () => {
    return (
      <div className={`${BLOCK}__category`} data-test="company-relevance">
        <div className={`${BLOCK}__category-container`}>
          {categoryStatusData &&
            categoryStatusData.data.map(
              (categories: CdpScope3CategoriesStatus, ind: number) => (
                <div
                  key={`category-status-${ind}`}
                  className={`${BLOCK}__category-detail`}
                >
                  <span className={`${BLOCK}__category-detail--name`}>
                    {categories.categoryName}
                  </span>
                  <div className={`${BLOCK}__category-detail--value`}>
                    <span className={`${BLOCK}__category-detail--legendDot`}>
                      {categories.categoryValue &&
                        getDot(categories.categoryValue)}
                    </span>
                    <span
                      className={classNames(``, {
                        [`${BLOCK}__category-detail--noData`]:
                          categories.categoryValue === "",
                      })}
                    >
                      {categories.categoryValue ? (
                        categories.categoryValue
                      ) : (
                        <FormattedMessage id="no.data" />
                      )}
                    </span>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    );
  };

  const peerIndustryViewStatus = () => {
    const BLOCK = "cdpScope3CategoriesStatusPeer";
    return (
      <>
        <table className={`${BLOCK}__table`} data-test="peer-ind-relevance">
          {peerIndStatusData.response.length > 1 && (
            <thead className={`${BLOCK}__table-header`}>
              {/* Values & companies */}
              <tr>
                <th>
                  {" "}
                  <FormattedMessage id={"all.categories"} />
                </th>
                {peerIndStatusData.response.map((comp: any, i: number) => (
                  <th
                    key={`table-header-${i}`}
                    className={classNames(`${BLOCK}__table-header-label`)}
                  >
                    {comp.header}
                  </th>
                ))}
              </tr>
            </thead>
          )}

          <tbody
            className={classNames(`${BLOCK}__table-body`, {
              [` ${BLOCK}__table-body-cell--border-top`]:
                peerIndStatusData.length === 1,
            })}
          >
            {/* Metrics */}
            {peerIndStatusData.labels.map((l: Label, ind: number) => (
              <tr key={`table-body-${ind}`}>
                <td
                  style={{
                    width: `calc( (100%) / ${
                      peerIndStatusData.response.length + 1
                    } )`,
                  }}
                >
                  <span className={`${BLOCK}__fit-content`}>
                    <Popover
                      metricClassName={classNames(
                        `${BLOCK}__table-body-value ${BLOCK}__table-body-value--left`
                      )}
                      displayText={<span>{Parser(l.label ?? "")}</span>}
                      content={l.tooltip ? l.tooltip : []}
                      buttonClassName={`${BLOCK}__label-button ${BLOCK}__text--line-height`}
                    />
                  </span>
                </td>
                {peerIndStatusData.response.map((d: any, index: number) => (
                  <td
                    className={classNames(
                      `${BLOCK}__table-body-value ${BLOCK}__table-body-value--bold`
                    )}
                    key={`table-data-${index}`}
                  >
                    <div className={`${BLOCK}__table-body-value--container`}>
                      <span>
                        {d[l.id] && d[l.id] !== "" && getDot(d[l.id])}
                      </span>
                      <span
                        data-test="neutral-tooltip"
                        onMouseEnter={(e) => {
                          let tmpTooltip = getNeutralTooltip(d[l.id]);
                          if (tmpTooltip)
                            handleMouseEnter(
                              e,
                              "span",
                              <div>{tmpTooltip}</div>,
                              "",
                              dispatch,
                              null,
                              MainTooltipPosition.BottomMiddle
                            );
                        }}
                        onMouseLeave={(e) => {
                          let tmpTooltip = getNeutralTooltip(d[l.id]);

                          if (tmpTooltip)
                            handleMouseLeave(
                              e,
                              dispatch,
                              mainTooltip.isOverTooltip
                                ? mainTooltip.isOverTooltip
                                : false
                            );
                        }}
                      >
                        {d[l.id] && d[l.id] !== "" ? (
                          d[l.id]
                        ) : (
                          <FormattedMessage id="no.data" />
                        )}
                      </span>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };
  return (
    <div className={`${BLOCK}`}>
      {benchmarkType === 4 ? (
        <div className={`${BLOCK}__metricWrapper`}>
          <Popover
            metricClassName={``}
            buttonClassName={`${BLOCK}__heading`}
            displayText={
              categoryStatusData?.categoryName
                ? categoryStatusData.categoryName
                : ""
            }
            content={
              associatedMetricGroupTooltips
                ? associatedMetricGroupTooltips.filter(
                    (tooltip: AssociatedMetricTooltips) =>
                      tooltip.associatedMetricGroupName ===
                      categoryStatusData?.categoryName
                  )
                : []
            }
          />
        </div>
      ) : (
        <div className={`cdpScope3PeerInd__emissions`}>
          <Popover
            metricClassName={``}
            buttonClassName="cdpScope3PeerInd__categoryName"
            displayText={peerIndStatusData.categoryName}
            content={
              associatedMetricGroupTooltips
                ? associatedMetricGroupTooltips.filter(
                    (tooltip: AssociatedMetricTooltips) =>
                      tooltip.associatedMetricGroupName ===
                      peerIndStatusData.categoryName
                  )
                : []
            }
          />
        </div>
      )}
      {benchmarkType === 4 ? (
        categoryStatusData && categoryStatusData?.data?.length > 0 ? (
          companyViewStatus()
        ) : (
          <TileEmptyContent />
        )
      ) : !hasNoData ? (
        peerIndustryViewStatus()
      ) : (
        <TileEmptyContent />
      )}
    </div>
  );
};

export default CDPScope3CategoriesStatus;
