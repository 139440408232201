import classNames from "classnames";
import Checkbox from "components/shared/checkbox/checkbox";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { RefObject, useCallback, useState, useRef } from "react";
import { CompanyFilter } from "services/search/filters.model";
import {
  FormattedMessageString,
  getHighlightedText,
  getVendorCollection,
  useClickOutside,
} from "utils/functions";
import { debounce } from "lodash";
import { COMPANY_TYPE, SOURCE_PAGE, VENDOR_DETAILS } from "utils/constants";
import Button from "components/shared/button/button";
import {
  GlobalCompanyGroup,
  GlobalCompanyMapping,
} from "services/insights/insights.model";
import Icon from "components/shared/icon/icon";
import Parser from "html-react-parser";

type Props = {
  label: string;
  placeholder: string;
  block: string;
  selectionType: string;
  selectCompany: any;
  unselectCompany: any;
  tabbedContentRef: RefObject<HTMLDivElement>;
  setSelectedItemsFromParent: any;
  disableInput: boolean;
  selectedItemsFromParent: GlobalCompanyGroup[];
  peersSelected: CompanyFilter[];
  globalPeersSelected: Partial<GlobalCompanyGroup>[];
  globalBaseSelected?: Partial<GlobalCompanyGroup>;
  limit?: number;
  fetchGlobalCompaniesFilter: any;
  triggeredBy?: string;
};

export const sortCompaniesByVendor = (companiesGroup: CompanyFilter[]) => {
  companiesGroup.sort((a: CompanyFilter, b: CompanyFilter) => {
    let aVendor =
      a.vendorCollection && a.vendorCollection[0]
        ? a.vendorCollection[0]
        : null;
    let bVendor =
      b.vendorCollection && b.vendorCollection[0]
        ? b.vendorCollection[0]
        : null;
    if (aVendor && bVendor) {
      if (aVendor.priority < bVendor.priority) return -1;
      if (aVendor.priority > bVendor.priority) return 1;
    }
    return 0;
  });
};

export const isAnyGlobalCompanyInGlobalGroups = (
  globalGroups: Partial<GlobalCompanyGroup>[],
  companyGroup: Partial<GlobalCompanyGroup>
) => {
  return globalGroups.filter((group: Partial<GlobalCompanyGroup>) => {
    return (
      group.globalCompanyMappingDetails &&
      group.globalCompanyMappingDetails.filter((gcm: GlobalCompanyMapping) => {
        return (
          companyGroup.globalCompanyMappingDetails &&
          companyGroup.globalCompanyMappingDetails.find(
            (globalComp: GlobalCompanyMapping) =>
              globalComp.globalCompanyId === gcm.globalCompanyId
          )
        );
      }).length
    );
  }).length;
};

export const isCompanyInGlobalGroup = (
  globalGroups: Partial<GlobalCompanyGroup>[],
  globalCompanyId: number
) => {
  return globalGroups.filter((group: Partial<GlobalCompanyGroup>) => {
    return (
      group.globalCompanyMappingDetails &&
      group.globalCompanyMappingDetails.filter((gcm: GlobalCompanyMapping) => {
        return gcm.globalCompanyId === globalCompanyId;
      }).length
    );
  }).length;
};
const GlobalSearchDropdown = ({
  label,
  placeholder,
  block,
  selectionType,
  unselectCompany,
  selectCompany,
  tabbedContentRef,
  setSelectedItemsFromParent,
  disableInput,
  selectedItemsFromParent,
  peersSelected,
  globalPeersSelected,
  limit,
  globalBaseSelected,
  fetchGlobalCompaniesFilter,
  triggeredBy,
}: Props) => {
  const MATCHES_LIMIT = 25;
  const SUGGESTED_GROUPING_MATCHES_LIMIT = 5;
  const [inputValue, setInputValue] = useState<any>("");
  const [selectedItems, setSelectedItems] = useState<CompanyFilter[]>([]);
  const [selectedCompanyGroup, setSelectedCompanyGroup] = useState<
    GlobalCompanyGroup[]
  >([]);
  const [values, setValues] = useState<CompanyFilter[]>([]);
  const [suggestedGroupings, setSuggestedGroupings] = useState<
    GlobalCompanyGroup[]
  >([]);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => {
    setShowOptions(false);
  });

  const getProcessedValues = (
    tmpValues: CompanyFilter[],
    pselectedItems: CompanyFilter[]
  ) => {
    let tmpGroupedResults = tmpValues.map((company: CompanyFilter) => {
      let vendors = getVendorCollection(company);
      return {
        ...company,
        isSelected: false,
        vendorCollection: vendors,
        disabled: false,
        fromGlobalSearch: true,
      };
    });

    if (pselectedItems.length > 0) {
      // Check for previous selection
      let selectedVendors: {
        priority: number;
        vendorId: number;
        vendorName: string;
        color: string;
      }[] = [];
      let tmpSelectedItems: CompanyFilter[] = [];
      tmpGroupedResults.forEach((cf: CompanyFilter) => {
        if (
          pselectedItems.filter(
            (si: CompanyFilter) =>
              si.globalCompanyId === cf.globalCompanyId &&
              si.companyName === cf.companyName &&
              JSON.stringify(si.vendorCollection) ===
                JSON.stringify(cf.vendorCollection)
          ).length &&
          ((cf.vendorCollection &&
            cf.vendorCollection.every(
              (vc: {
                priority: number;
                vendorId: number;
                vendorName: string;
                color: string;
              }) => !selectedVendors.includes(vc)
            )) ||
            !selectedVendors.length)
        ) {
          selectedVendors = cf.vendorCollection
            ? selectedVendors.concat(cf.vendorCollection)
            : selectedVendors;
          cf.isSelected = true;
          tmpSelectedItems.push(cf);
        }
      });

      tmpGroupedResults.forEach((cf: CompanyFilter) => {
        if (
          cf.vendorCollection &&
          cf.vendorCollection.some(
            (vc: {
              priority: number;
              vendorId: number;
              vendorName: string;
              color: string;
            }) => selectedVendors.includes(vc)
          ) &&
          !cf.isSelected
        ) {
          cf.disabled = true;
        }
      });
      setSelectedItems(tmpSelectedItems);
    }

    return tmpGroupedResults.slice(0, MATCHES_LIMIT);
  };

  const getSuggestedGroupingsData = (suggestedGroups: GlobalCompanyGroup[]) => {
    let tmpSuggestedGrouping = [...suggestedGroups];

    tmpSuggestedGrouping.forEach((sg: GlobalCompanyGroup) => {
      sg.globalCompanyMappingDetails = sg.globalCompanyMappingDetails.map(
        (gcm: GlobalCompanyMapping) => ({
          ...gcm,
          globalCompany: {
            ...gcm.globalCompany,
            vendorCollection: getVendorCollection(gcm.globalCompany),
          },
        })
      );
      sg.globalCompanyMappingDetails.sort(
        (a: GlobalCompanyMapping, b: GlobalCompanyMapping) => {
          let aVendor =
            a.globalCompany.vendorCollection &&
            a.globalCompany.vendorCollection[0]
              ? a.globalCompany.vendorCollection[0]
              : null;
          let bVendor =
            b.globalCompany.vendorCollection &&
            b.globalCompany.vendorCollection[0]
              ? b.globalCompany.vendorCollection[0]
              : null;
          if (aVendor && bVendor) {
            if (aVendor.priority < bVendor.priority) return -1;
            if (aVendor.priority > bVendor.priority) return 1;
          }
          return 0;
        }
      );
    });
    tmpSuggestedGrouping.sort(
      (a: GlobalCompanyGroup, b: GlobalCompanyGroup) => {
        let aDefaultCompany = a.globalCompanyMappingDetails.find(
          (gcm: GlobalCompanyMapping) => gcm.isDefault
        );
        let bDefaultCompany = b.globalCompanyMappingDetails.find(
          (gcm: GlobalCompanyMapping) => gcm.isDefault
        );

        if (aDefaultCompany && bDefaultCompany) {
          if (
            !aDefaultCompany?.globalCompany.ticker &&
            bDefaultCompany?.globalCompany.ticker
          )
            return 1;
          if (
            aDefaultCompany?.globalCompany.ticker &&
            !bDefaultCompany?.globalCompany.ticker
          )
            return -1;
          if (
            aDefaultCompany?.globalCompany.ticker &&
            bDefaultCompany?.globalCompany.ticker &&
            aDefaultCompany?.globalCompany.ticker.toUpperCase() <
              bDefaultCompany?.globalCompany.ticker.toUpperCase()
          )
            return -1;
          if (
            aDefaultCompany?.globalCompany.ticker &&
            bDefaultCompany?.globalCompany.ticker &&
            aDefaultCompany?.globalCompany.ticker.toUpperCase() >
              bDefaultCompany?.globalCompany.ticker.toUpperCase()
          )
            return 1;

          if (
            aDefaultCompany?.globalCompany.ticker &&
            bDefaultCompany?.globalCompany.ticker &&
            aDefaultCompany?.globalCompany.ticker.toUpperCase() ===
              bDefaultCompany?.globalCompany.ticker.toUpperCase()
          ) {
            if (
              aDefaultCompany.globalCompany.companyName.toUpperCase() <
              bDefaultCompany.globalCompany.companyName.toUpperCase()
            )
              return -1;
            if (
              aDefaultCompany.globalCompany.companyName.toUpperCase() >
              bDefaultCompany.globalCompany.companyName.toUpperCase()
            )
              return 1;

            if (
              aDefaultCompany &&
              bDefaultCompany &&
              aDefaultCompany.globalCompany.companyName.toUpperCase() ===
                bDefaultCompany.globalCompany.companyName.toUpperCase() &&
              aDefaultCompany.globalCompany.vendorCollection &&
              bDefaultCompany.globalCompany.vendorCollection
            ) {
              let aVendorPriority =
                aDefaultCompany.globalCompany.vendorCollection[0];
              let bVendorPriority =
                bDefaultCompany.globalCompany.vendorCollection[0];
              if (aVendorPriority && bVendorPriority) {
                if (aVendorPriority.priority < bVendorPriority.priority)
                  return -1;
                if (aVendorPriority.priority > bVendorPriority.priority)
                  return 1;
              }
            }
          }
        }
        return 0;
      }
    );

    return tmpSuggestedGrouping.slice(0, SUGGESTED_GROUPING_MATCHES_LIMIT);
  };

  const getVendorPill = (
    vendor: {
      priority: number;
      vendorId: number;
      vendorName: string;
      color: string;
    },
    i: number,
    companyIndex: number
  ) => {
    if (vendor.vendorId === 2) return;
    return (
      <div
        key={`pill-${i}-${companyIndex}`}
        className={`${block}__pill`}
        style={{
          borderColor: `${vendor.color}`,
          color: `${vendor.color}`,
        }}
        data-testid="check-pill"
      >
        {vendor.vendorName}
      </div>
    );
  };

  const mappingIncludesCompany = (company: CompanyFilter): boolean => {
    if (
      isCompanyInGlobalGroup(
        globalPeersSelected,
        company.globalCompanyId ?? 0
      ) ||
      (globalBaseSelected &&
        isCompanyInGlobalGroup(
          [globalBaseSelected],
          company.globalCompanyId ?? 0
        )) ||
      (selectionType === COMPANY_TYPE.PEER &&
        ((globalBaseSelected &&
          isCompanyInGlobalGroup(
            [globalBaseSelected],
            company.globalCompanyId ?? 0
          )) ||
          (globalPeersSelected &&
            globalPeersSelected.length &&
            isCompanyInGlobalGroup(
              globalPeersSelected,
              company.globalCompanyId ?? 0
            )) ||
          isCompanyInGlobalGroup(
            selectedCompanyGroup,
            company.globalCompanyId ?? 0
          ) ||
          selectedItems.filter(
            (si: CompanyFilter) =>
              si.globalCompanyId === company.globalCompanyId
          ).length ||
          isCompanyInGlobalGroup(
            selectedItemsFromParent,
            company.globalCompanyId ?? 0
          )))
    )
      return true;

    return false;
  };

  const getDropdownOptionItem = (company: CompanyFilter, ind: number) => {
    return (
      <div
        key={`option-${ind}`}
        className={classNames(`${block}__dropdown-options-item`, {
          [`${block}__dropdown-options-item--hide`]:
            mappingIncludesCompany(company) && !company.isSelected,
        })}
      >
        <div
          className={`${block}__dropdown-options-item-name-wrapper`}
          key={`option-container-${ind}`}
        >
          <div className={`${block}__dropdown-options-item-checkbox-wrapper`}>
            <Checkbox
              label={
                inputValue !== "" && company.ticker ? <>{company.ticker}</> : ""
              }
              value={
                company.isSelected !== undefined ? company.isSelected : false
              }
              onChange={() => {
                handleCheckboxOnChange(company);
              }}
              disabled={company.disabled ?? false}
              isCheckboxBlue={
                company.isSelected !== undefined ? company.isSelected : false
              }
              isGlobalSearch={true}
            />
          </div>

          <span className={`${block}__dropdown-options-item-name`}>
            {Parser(
              getHighlightedText(company.companyName, inputValue).toString()
            )}
          </span>
        </div>
        <div className={`${block}__dropdown-options-item-vendor-wrapper`}>
          {company.vendorCollection &&
            company.vendorCollection.map(
              (
                vendor: {
                  priority: number;
                  vendorId: number;
                  vendorName: string;
                  color: string;
                },
                i: number
              ) => getVendorPill(vendor, i, ind)
            )}
        </div>
      </div>
    );
  };

  const debounceChangeHandler = useCallback(
    debounce((value, tmpSelectedItems) => {
      if (value !== "") {
        fetchGlobalCompaniesFilter(value, false).then((res: any) => {
          setValues(
            res.data.globalCompanies && res.data.globalCompanies.length
              ? getProcessedValues(res.data.globalCompanies, tmpSelectedItems)
              : []
          );
          setSuggestedGroupings(
            res.data.globalCompanyMappings &&
              res.data.globalCompanyMappings.length
              ? getSuggestedGroupingsData(
                  res.data.globalCompanyMappings.filter(
                    (g: GlobalCompanyGroup) => g.isSaved
                  )
                )
              : []
          );
          setSelectedCompanyGroup([]);
        });
      }
    }, 1000),
    []
  );

  const inputOnchange = (e: any) => {
    setInputValue(e.target.value);
    setValues([]);
    setSuggestedGroupings([]);

    debounceChangeHandler(e.target.value, selectedItems);
  };

  const getOptionsHeight = () => {
    if (tabbedContentRef && tabbedContentRef.current) {
      let content = tabbedContentRef.current.getBoundingClientRect();
      return `${content.height}px`;
    }
    return "unset";
  };

  const handleCheckboxOnChange = (company: CompanyFilter) => {
    setSelectedCompanyGroup([]);
    let tmpSelectedItems = [...selectedItems];
    if (company.isSelected) {
      let selectedVendors = tmpSelectedItems.reduce(
        (
          prev: {
            priority: number;
            vendorId: number;
            vendorName: string;
            color: string;
          }[],
          item: CompanyFilter
        ) =>
          item.vendorCollection &&
          JSON.stringify(item) !== JSON.stringify(company)
            ? prev.concat(item.vendorCollection)
            : prev,
        []
      );
      //unselect
      setValues(
        values.map((d: CompanyFilter) =>
          JSON.stringify(d) === JSON.stringify(company)
            ? { ...d, isSelected: false, disabled: false }
            : d.isSelected
            ? d
            : d.vendorCollection &&
              d.vendorCollection.every(
                (vc: {
                  priority: number;
                  vendorId: number;
                  vendorName: string;
                  color: string;
                }) => !selectedVendors.includes(vc)
              )
            ? { ...d, disabled: false }
            : { ...d, disabled: true }
        )
      );
      setSelectedItems(
        selectedItems.filter(
          (cf: CompanyFilter) => JSON.stringify(cf) !== JSON.stringify(company)
        )
      );
    } else {
      //select
      tmpSelectedItems.push({ ...company, isSelected: true });

      let selectedVendors = tmpSelectedItems.reduce(
        (
          prev: {
            priority: number;
            vendorId: number;
            vendorName: string;
            color: string;
          }[],
          item: CompanyFilter
        ) =>
          item.vendorCollection ? prev.concat(item.vendorCollection) : prev,
        []
      );
      setValues(
        values.map((d: CompanyFilter) =>
          JSON.stringify(d) === JSON.stringify(company)
            ? { ...d, isSelected: true }
            : d.vendorCollection &&
              d.vendorCollection.some(
                (vc: {
                  priority: number;
                  vendorId: number;
                  vendorName: string;
                  color: string;
                }) => selectedVendors.includes(vc)
              ) &&
              !d.isSelected
            ? { ...d, disabled: true }
            : { ...d, disabled: false }
        )
      );
      setSelectedItems(tmpSelectedItems);
    }
  };

  const someCompanyInGlobalGroup = (
    companyGroup: Partial<GlobalCompanyGroup>
  ) => {
    return (
      (globalBaseSelected &&
        globalBaseSelected.globalCompanyMappingId ===
          companyGroup.globalCompanyMappingId) ||
      (globalBaseSelected &&
        globalBaseSelected.globalCompanyMappingDetails &&
        isAnyGlobalCompanyInGlobalGroups([globalBaseSelected], companyGroup)) ||
      (globalPeersSelected &&
        globalPeersSelected.length &&
        isAnyGlobalCompanyInGlobalGroups(globalPeersSelected, companyGroup)) ||
      isAnyGlobalCompanyInGlobalGroups(selectedCompanyGroup, companyGroup) ||
      selectedItems.filter(
        (si: CompanyFilter) =>
          companyGroup.globalCompanyMappingDetails &&
          companyGroup.globalCompanyMappingDetails.filter(
            (gcm: GlobalCompanyMapping) =>
              gcm.globalCompanyId === si.globalCompanyId
          ).length
      ).length ||
      isAnyGlobalCompanyInGlobalGroups(selectedItemsFromParent, companyGroup)
    );
  };

  const getSuggestedGroupingOption = (
    companyGroup: GlobalCompanyGroup,
    ind: number
  ) => {
    let contentText = inputValue;
    let defaultCompany = companyGroup.globalCompanyMappingDetails.find(
      (gcm: GlobalCompanyMapping) => gcm.isDefault
    );

    let globalCompanies: string =
      contentText === ""
        ? ""
        : companyGroup.globalCompanyMappingDetails
            .map((curr: GlobalCompanyMapping, currIndex: number) => {
              let currVendors =
                curr.globalCompany.vendorCollection &&
                curr.globalCompany.vendorCollection.length
                  ? curr.globalCompany.vendorCollection.map(
                      (v: {
                        priority: number;
                        vendorId: number;
                        vendorName: string;
                        color: string;
                      }) => v.vendorName
                    )
                  : null;

              let tmpText = currVendors
                ? `${getHighlightedText(
                    curr.globalCompany.companyName,
                    contentText
                  )} ${
                    currVendors
                      ? `(${currVendors.join(", ")})${
                          currIndex ===
                          companyGroup.globalCompanyMappingDetails.length - 1
                            ? ""
                            : ", "
                        }`
                      : ""
                  }`
                : "";
              return tmpText;
            })
            .join("");

    return (
      <div
        key={`option-${ind}`}
        className={classNames(`${block}__dropdown-options-item`, {
          [`${block}__dropdown-options-item--hide`]:
            someCompanyInGlobalGroup(companyGroup) &&
            !selectedCompanyGroup.includes(companyGroup),
        })}
      >
        <div
          className={`${block}__dropdown-options-item-name-wrapper`}
          key={`option-container-${ind}`}
        >
          <div className={`${block}__dropdown-options-item-checkbox-wrapper`}>
            <Checkbox
              label={
                contentText !== "" && defaultCompany?.globalCompany.ticker ? (
                  <>{defaultCompany?.globalCompany?.ticker}</>
                ) : (
                  ""
                )
              }
              value={selectedCompanyGroup.includes(companyGroup)}
              onChange={() => {
                setValues((v) =>
                  v.map((val) => ({
                    ...val,
                    isSelected: false,
                    disabled: !(
                      selectedCompanyGroup.length === 1 &&
                      selectedCompanyGroup[0].globalCompanyMappingId ===
                        companyGroup.globalCompanyMappingId
                    ),
                  }))
                );
                setSelectedCompanyGroup((l) =>
                  l.includes(companyGroup)
                    ? l.filter(
                        (g) =>
                          g.globalCompanyMappingId !==
                          companyGroup.globalCompanyMappingId
                      )
                    : [...l, companyGroup]
                );
              }}
              disabled={
                selectionType === COMPANY_TYPE.BASELINE
                  ? (selectedCompanyGroup.length > 0 &&
                      !selectedCompanyGroup.includes(companyGroup)) ||
                    globalPeersSelected.filter(
                      (gps: Partial<GlobalCompanyGroup>) =>
                        gps.globalCompanyMappingId ===
                        companyGroup.globalCompanyMappingId
                    ).length > 0
                  : (selectedItemsFromParent.length > 0 &&
                      selectedItemsFromParent.filter(
                        (group: GlobalCompanyGroup) =>
                          group.globalCompanyMappingId ===
                          companyGroup.globalCompanyMappingId
                      ).length > 0) ||
                    (limit
                      ? peersSelected.length +
                          globalPeersSelected.length +
                          selectedItemsFromParent.length +
                          selectedCompanyGroup.length +
                          selectedItems.length >=
                          limit && !selectedCompanyGroup.includes(companyGroup)
                      : false)
              }
              isCheckboxBlue={selectedCompanyGroup.includes(companyGroup)}
              isGlobalSearch={true}
            />
          </div>

          <span className={`${block}__dropdown-options-item-name`}>
            {!defaultCompany
              ? ""
              : Parser(
                  getHighlightedText(
                    defaultCompany?.globalCompany.companyName,
                    contentText
                  ).toString() + ` (${globalCompanies})`
                )}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${block}__dropdown-container`}
      data-testid={`${block}__dropdown-container`}
    >
      <span className={`${block}__dropdown-label`}>
        <FormattedMessage id={label} />
      </span>

      <div className={`${block}__dropdown`} ref={dropdownRef}>
        <div className={`${block}__dropdown-search-box`}>
          <input
            placeholder={`${FormattedMessageString(placeholder)}`}
            className={classNames(`${block}__dropdown-textarea`, {
              [`${block}__dropdown-textarea-placeholder`]: !inputValue,
            })}
            value={inputValue}
            onChange={(e) => {
              inputOnchange(e);
            }}
            disabled={disableInput}
            onClick={() => {
              setShowOptions(true);
            }}
            data-testid="search-box"
          />
          <div className={`${block}__dropdown-textarea-cross-btn`}>
            <Button
              className=""
              onClick={() => {
                setValues([]);
                setSuggestedGroupings([]);
                setInputValue("");
                setShowOptions(false);
              }}
              dataTest="cross-search-box-btn"
            >
              <Icon name={"cross"} width={25} height={24} />
            </Button>
          </div>
          {selectionType === COMPANY_TYPE.PEER && limit ? (
            <span className={`${block}__dropdown-search-box-limit-legend`}>
              <FormattedMessage
                id="global.search.peers.qty.msg"
                values={{
                  qty:
                    (selectedItemsFromParent
                      ? selectedItemsFromParent.length
                      : 0) +
                    (peersSelected ? peersSelected.length : 0) +
                    (globalPeersSelected ? globalPeersSelected.length : 0),
                  limit: limit,
                }}
              />
            </span>
          ) : null}
        </div>
        {((values.length === 0 && suggestedGroupings.length === 0) ||
          (values.every((c) => mappingIncludesCompany(c) && !c.isSelected) &&
            suggestedGroupings.every(
              (companyGroup) =>
                someCompanyInGlobalGroup(companyGroup) &&
                !selectedCompanyGroup.includes(companyGroup)
            ))) &&
        inputValue &&
        inputValue !== "" ? (
          <div
            className={`${block}__dropdown-options`}
            style={{ maxHeight: getOptionsHeight() }}
          >
            <div className={`${block}__dropdown-options-item`}>
              <div className={`${block}__dropdown-options-item-name-wrapper`}>
                <span className={`${block}__dropdown-options-item-name`}>
                  <FormattedMessage id={"global.search.no.matches.msg"} />
                </span>
              </div>
            </div>
          </div>
        ) : null}

        {showOptions &&
        (values.length || suggestedGroupings.length) &&
        (values.some((c) => !(mappingIncludesCompany(c) && !c.isSelected)) ||
          suggestedGroupings.some(
            (companyGroup) =>
              !(
                someCompanyInGlobalGroup(companyGroup) &&
                !selectedCompanyGroup.includes(companyGroup)
              )
          )) ? (
          <div
            className={`${block}__dropdown-options`}
            style={{ maxHeight: getOptionsHeight() }}
          >
            {suggestedGroupings.length &&
            suggestedGroupings.some(
              (companyGroup) =>
                !(
                  someCompanyInGlobalGroup(companyGroup) &&
                  !selectedCompanyGroup.includes(companyGroup)
                )
            ) ? (
              <div className={`${block}__dropdown-options-suggested-map`}>
                <span
                  className={`${block}__dropdown-options-suggested-map-label`}
                >
                  <FormattedMessage id="suggested.mappings" />
                </span>

                {suggestedGroupings.length
                  ? suggestedGroupings.map(
                      (item: GlobalCompanyGroup, i: number) =>
                        getSuggestedGroupingOption(item, i)
                    )
                  : null}
              </div>
            ) : null}
            <div className={`${block}__dropdown-options-wrapper`}>
              {values.length
                ? values.map((item: CompanyFilter, i: number) =>
                    getDropdownOptionItem(item, i)
                  )
                : null}
            </div>
            <div className={`${block}__dropdown-actions-wrapper`}>
              <Button
                className={classNames(`${block}__dropdown-reset-btn`, {
                  [`${block}__dropdown-reset-btn-enabled`]:
                    selectedItems?.length > 0,
                })}
                formattedText="reset"
                disabled={
                  selectedItems?.length === 0 &&
                  selectedCompanyGroup.length === 0
                }
                onClick={() => {
                  setSelectedItems([]);
                  setSelectedCompanyGroup([]);
                  setValues(
                    values.map((d: CompanyFilter) => ({
                      ...d,
                      disabled: false,
                      isSelected: false,
                    }))
                  );
                  setSuggestedGroupings(
                    suggestedGroupings.map((g: GlobalCompanyGroup) => ({
                      ...g,
                      disabled: false,
                      isSelected: false,
                    }))
                  );
                }}
                dataTest="reset-btn"
              />
              <Button
                className={`button-primary`}
                formattedText="confirm"
                disabled={
                  selectedItems?.length === 0 &&
                  selectedCompanyGroup.length === 0
                }
                onClick={() => {
                  setInputValue("");
                  if (selectedCompanyGroup.length) {
                    setSelectedItemsFromParent(
                      (parentValue: GlobalCompanyGroup[]) => [
                        ...parentValue,
                        ...selectedCompanyGroup,
                      ]
                    );
                  } else {
                    let tmpSelectedItems = [...selectedItems];
                    sortCompaniesByVendor(tmpSelectedItems);
                    let newSelectedItem = {
                      globalCompanyMappingId: 0,
                      globalCompanyMappingName: tmpSelectedItems[0].companyName,
                      globalCompanyMappingDetails: tmpSelectedItems.reduce(
                        (
                          companies: Partial<GlobalCompanyMapping>[],
                          comp: any,
                          i: number
                        ) => {
                          const subcompanies = comp.subCompanies
                            ? comp.subCompanies.map((c: any) => ({
                                displayOrder: i,
                                isDefault: false,
                                globalCompanyId: c.globalCompanyId,
                                globalCompany: c,
                              }))
                            : [];
                          return [
                            ...companies,
                            {
                              displayOrder: i,
                              isDefault: i === 0,
                              globalCompanyId: comp.globalCompanyId,
                              globalCompany: comp,
                            },
                            ...subcompanies,
                          ];
                        },
                        []
                      ),
                    };
                    setSelectedItemsFromParent([
                      ...selectedItemsFromParent,
                      newSelectedItem,
                    ]);
                  }

                  setSelectedItems([]);
                  setValues([]);
                  setSuggestedGroupings([]);
                }}
                dataTest="confirm-btn"
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default GlobalSearchDropdown;
