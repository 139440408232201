import React, { useState, useEffect, useRef } from "react";
import Modal from "../modal/modal";
import FormattedMessage from "../formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { addToastMessage } from "services/commons.service";
import {
  deleteNote,
  noteDeleteSuccess,
  noteSaveSuccess,
  updateNote,
} from "services/note/note.service";
import Icon from "../icon/icon";
import { saveNote } from "services/note/note.service";
import Button from "../button/button";
type props = {
  setShowModal: (value: boolean) => void;
  setCommentBlue: (value: boolean) => void;
  headerNote: string;
  inputNote: string;
  referenceId: number;
  noteExist: boolean;
  noteId?: number;
  commentNoteReferenceType: number;
};

const Note = ({
  setShowModal,
  setCommentBlue,
  headerNote,
  inputNote,
  referenceId,
  noteId,
  noteExist,
  commentNoteReferenceType,
}: props) => {
  const BLOCK = "Note";
  const [counts, setCounts] = useState({ note: 0 });
  const [disabled, setDisabled] = useState(true);
  const noteInputRef = useRef<HTMLTextAreaElement>(null);
  const [values, setValues] = useState({ note: "" });
  const dispatch = useDispatch();
  const noteState = useSelector((state: RootStore) => state.note);

  useEffect(() => {
    if (noteState.NoteFetch) {
      setValues({
        ...values,
        note: noteState.NoteFetch.commentNotes
          ? noteState.NoteFetch.commentNotes
          : "",
      });
      setCounts({
        ...counts,
        note: noteState.NoteFetch.commentNotes
          ? noteState.NoteFetch.commentNotes.length
          : 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteState.NoteFetch]);

  useEffect(() => {
    if (noteState.saveSuccess) {
      setShowModal(false);
      dispatch(noteSaveSuccess(false));
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="note.edit.saved" />,
        })
      );
      setCommentBlue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteState.saveSuccess, dispatch, setShowModal]);

  useEffect(() => {
    if (noteState.deleteSuccess) {
      setShowModal(false);
      dispatch(noteDeleteSuccess(false));
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="note.edit.delete" />,
        })
      );
      setCommentBlue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteState.deleteSuccess, dispatch, setShowModal]);

  const trimStartFunc = (value: any) => {
    if (!value) return value;
    return value.replace(/^\s+/g, "");
  };
  const onChangeHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCounts({
      ...counts,
      [event.target.name]: trimStartFunc(event.target.value).length,
    });
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    if (
      noteState.NoteFetch.commentNotes === noteInputRef.current!.value.trim() ||
      noteInputRef.current!.value.trim().length === 0
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      saveNoteHandler();
    }
  };

  const saveNoteHandler = () => {
    if (trimStartFunc(noteInputRef.current!.value).trim().length === 0) {
      return;
    }
    if (!noteExist) {
      dispatch(
        saveNote({
          referenceId: referenceId,
          commentNotes: trimStartFunc(values.note).trim(),
          commentNoteReferenceType: commentNoteReferenceType,
        })
      );
    } else {
      dispatch(
        updateNote({
          commentNoteId: noteId,
          referenceId: referenceId,
          commentNotes: trimStartFunc(values.note).trim(),
          commentNoteReferenceType: commentNoteReferenceType,
        })
      );
    }
    // editNote(noteInputRef.current!.value.trim());
  };

  const deleteNoteHandler = () => {
    dispatch(
      deleteNote({
        commentId: noteId,
      })
    );
  };

  return (
    <Modal
      show={true}
      header={headerNote}
      handleClose={() => {
        setShowModal(false);
      }}
      config={false}
      data-test="note-component"
    >
      <div className={`${BLOCK} ${BLOCK}__saveNote`}>
        <span
          className={classNames(
            `${BLOCK}__name__saveNote ${BLOCK}__name ${BLOCK}__description-label`
          )}
          data-test="save-note-component"
        >
          <FormattedMessage id={inputNote} />
        </span>
        <span
          className={classNames(`${BLOCK}__input ${BLOCK}__input__saveNote`)}
        >
          <textarea
            ref={noteInputRef}
            maxLength={2000}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={values.note}
            placeholder="Enter note"
            className={classNames(`${BLOCK}__note`)}
            name="note"
          />
        </span>
        <span className={`${BLOCK}__content ${BLOCK}__content__saveNote`}>
          {counts.note}
          <FormattedMessage id="note.save.character" />
        </span>
        <div
          className={classNames(
            `${BLOCK}__options ${BLOCK}__options__saveNote`,
            {
              [`${BLOCK}__options-no-delete`]: !noteExist,
            }
          )}
        >
          {noteExist && (
            <div className={`${BLOCK}__options-start`}>
              <button
                className={`${BLOCK}__delete-note-button`}
                onClick={deleteNoteHandler}
              >
                <Icon name="remove3" height={16} width={14} />
                <div>
                  <FormattedMessage id="note.delete.button" />
                </div>
              </button>
            </div>
          )}

          <div className={`${BLOCK}__options-end`}>
            <Button
              className={`button-secondary`}
              dataTest="note-cancel-btn"
              onClick={() => setShowModal(false)}
              formattedText={"shortlist.save.button.cancel"}
            />

            <Button
              className={`button-primary`}
              onClick={saveNoteHandler}
              disabled={disabled}
              dataTest="note-add.btn"
              formattedText={`${
                noteExist ? "shortlist.save.button.save" : "note.add.button"
              }`}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Note;
