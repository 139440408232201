import ReportingTile from "components/visualizations/charts/reporting.tile";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import {
  INSIGHT_BENCHMARK_TYPE,
  METRIC_ORDER,
  NEUTRAL_VALUES,
  getCommaSeparatedValues,
} from "utils/constants";
import { useTileContext } from "../tile.context";
import ReportingTableChart from "components/visualizations/charts/reporting-table-chart";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import {
  handleMouseEnter,
  handleMouseLeave,
  setHyperLinks,
} from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import TableChart from "components/visualizations/charts/table-chart";
import Popover from "components/shared/popover/popover";
import classNames from "classnames";

const Reporting = () => {
  const {
    response,
    dataset,
    isTableViewActive,
    metadata: {
      benchmarkMetadata,
      benchmarkType,
      isDetailedView,
      associatedMetricGroupTooltips,
      associatedMetricsTooltips,
    },
    associatedMetrics,
  } = useTileContext();

  const getReportingTileMetricValue = (
    dataValue: any,
    metricId: number,
    globalCompanyId: number
  ) => {
    if (!dataValue) return null;
    if (metricId === METRIC_ORDER.reporting_boundary && globalCompanyId > 0)
      return "yes";

    return dataValue.metricValue;
  };

  const dispatch = useDispatch();
  const mainTooltip = useSelector(
    (store: RootStore) => store.commons.mainTooltip
  );
  const getReportingTileMetrics = (
    metric: any,
    metricId: number,
    globalCompanyId: number
  ) => {
    const metricList =
      globalCompanyId > 0 &&
      metric.groupMetricsExcpectedValue &&
      metric.groupMetricsExcpectedValue.length
        ? metric.groupMetricsExcpectedValue
        : metric.groupValues;

    return metricList.map((metricName: string, i: number) => {
      const datasetMetric = dataset
        .find((d) => d.globalCompanyId === globalCompanyId)
        ?.metrics.find(
          (m) =>
            (metricId === METRIC_ORDER.reporting_boundary && globalCompanyId > 0
              ? m.metricValue
              : m.metricKey) === metricName
        );
      return {
        metric: metricName,
        tooltip: datasetMetric?.metricTooltip,
        value: getReportingTileMetricValue(
          datasetMetric,
          metricId,
          globalCompanyId
        ),
      };
    });
  };

  const mapReporingTileData = () =>
    associatedMetrics.map((metric: any, i: number) => ({
      groupName: metric.groupName,
      groupTooltip: associatedMetricGroupTooltips?.filter(
        ({ associatedMetricGroupName }: AssociatedMetricTooltips) =>
          metric.groupName === associatedMetricGroupName
      ),
      data: dataset
        .filter((c: any) =>
          i === METRIC_ORDER.reporting_period ? c.globalCompanyId > 0 : true
        )
        .map((c: any) => ({
          globalCompanyId: c.globalCompanyId,
          companyName: c.companyName,
          metrics: getReportingTileMetrics(metric, i, c.globalCompanyId),
        })),
    }));

  const getReportingTableMetricValue = (
    metric: any,
    company: any,
    data: any[],
    sectionId: number
  ) => {
    let metricValue = data.filter(
      (d: any) =>
        d.globalCompanyId === company.globalCompanyId &&
        metric.groupValues.includes(d.fieldName) &&
        (sectionId === 4 ? d.fieldValue && d.fieldValue !== null : true) &&
        (sectionId === 2 ? d.fieldValue && d.fieldValue !== null : true)
    );
    metricValue = metricValue.length
      ? metricValue.map((d: any) => {
          if (!d.fieldValue) return null;
          switch (sectionId) {
            case 4:
              return d.fieldValue.toLowerCase() === "no"
                ? d.fieldValue.toLowerCase()
                : d.fieldName;
            default:
              return d.fieldValue;
          }
        })
      : [];

    metricValue =
      metricValue.length && metricValue.every((m) => m === "no")
        ? ["-"]
        : metricValue.filter((m) => m !== "no");

    return metricValue.length
      ? metricValue.join(sectionId === 2 ? " - " : ",")
      : null;
  };

  const mapReporingTableData = () => ({
    MSCIESGRatingView: false,
    isReportingTile: true,
    labels: benchmarkMetadata.associatedMetricsTooltip.map(
      (metric: any, i: number) => ({
        label: metric.groupName,
        tooltip: associatedMetricGroupTooltips?.filter(
          ({ associatedMetricGroupName }: AssociatedMetricTooltips) =>
            metric.groupName === associatedMetricGroupName
        ),
        id: metric.groupName,
        groupValues: metric.groupValues,
        groupMetric: (associatedMetrics[i] as any).groupValues,
      })
    ),
    response: dataset.map((c: any) => ({
      header: c.companyName,
      globalCompanyId: c.globalCompanyId,
      companyName: c.companyName,
      ...Object.fromEntries(
        associatedMetrics.map((metric: any, i: number) => {
          return [
            metric.groupName,
            getReportingTableMetricValue(metric, c, response, i),
          ];
        })
      ),
    })),
    tileType: 31,
  });

  const tileProps = {
    data: mapReporingTileData(),
    currentInsightView: benchmarkType,
    isDetailedView,
  };

  const neutralTooltipContent = (value: any) =>
    value &&
    (value === "-" ||
      NEUTRAL_VALUES.includes(value) ||
      value.includes("(Neutral)")) ? (
      <>
        {value === "-" ? (
          <>None</>
        ) : (
          <FormattedMessage id="insights.tabular.neutral" />
        )}
      </>
    ) : null;

  const getCustomValueForTableView = (l: any, value: any) => {
    let values = getCommaSeparatedValues(value);
    let ReportingMetricMapping: any[] = [];
    ReportingMetricMapping = values as any;
    return value &&
      (value.toString() === "-" ||
        NEUTRAL_VALUES.includes(value) ||
        value.includes("(Neutral)")) ? (
      <span
        className={`tableChart__fit-content`}
        onMouseEnter={(e) => {
          let tmpTooltip = neutralTooltipContent(value);
          if (tmpTooltip)
            handleMouseEnter(
              e,
              "span",
              <div>{tmpTooltip}</div>,
              "",
              dispatch,
              null,
              MainTooltipPosition.RightMiddle
            );
        }}
        onMouseLeave={(e) => {
          let tmpTooltip = neutralTooltipContent(value);
          if (tmpTooltip)
            handleMouseLeave(
              e,
              dispatch,
              mainTooltip.isOverTooltip ? mainTooltip.isOverTooltip : false
            );
        }}
      >
        {value}
      </span>
    ) : (
      <>
        {!value && "*"}
        {ReportingMetricMapping &&
          ReportingMetricMapping.length > 0 &&
          ReportingMetricMapping?.map((metric: any, index: number) => {
            const metricValueTooltipIndex = l.groupMetric?.indexOf(metric);

            if (index !== ReportingMetricMapping.length - 1) {
              return (
                <span
                  key={index}
                  onMouseEnter={(e) =>
                    l?.groupValues[metricValueTooltipIndex] &&
                    handleMouseEnter(
                      e,
                      "span",
                      setHyperLinks(l?.groupValues[metricValueTooltipIndex]),
                      "",
                      dispatch,
                      null,
                      MainTooltipPosition.BottomMiddle
                    )
                  }
                  onMouseLeave={(e) => {
                    handleMouseLeave(
                      e,
                      dispatch,
                      mainTooltip.isOverTooltip
                        ? mainTooltip.isOverTooltip
                        : false
                    );
                  }}
                >
                  <span className={`tableChart__fit-content-reporting`}>
                    <Popover
                      metricClassName={classNames(
                        `tableChart__table-body-value tableChart__table-body-value--bold`
                      )}
                      displayText={metric + ","}
                      content={
                        associatedMetricsTooltips
                          ? associatedMetricsTooltips.filter(
                              (tooltip: AssociatedMetricTooltips) =>
                                tooltip.associatedMetric === metric
                            )
                          : []
                      }
                      buttonClassName={``}
                    />
                  </span>
                </span>
              );
            } else {
              return (
                <span
                  key={index}
                  onMouseEnter={(e) =>
                    l?.groupValues[metricValueTooltipIndex] &&
                    handleMouseEnter(
                      e,
                      "span",
                      setHyperLinks(l?.groupValues[metricValueTooltipIndex]),
                      "",
                      dispatch,
                      null,
                      MainTooltipPosition.BottomMiddle
                    )
                  }
                  onMouseLeave={(e) => {
                    handleMouseLeave(
                      e,
                      dispatch,
                      mainTooltip.isOverTooltip
                        ? mainTooltip.isOverTooltip
                        : false
                    );
                  }}
                >
                  <span className={`tableChart__fit-content`}>
                    <Popover
                      metricClassName={classNames(
                        `tableChart__table-body-value tableChart__table-body-value--bold tableChart__table-body-value--left`
                      )}
                      displayText={metric}
                      content={
                        associatedMetricsTooltips
                          ? associatedMetricsTooltips.filter(
                              (tooltip: AssociatedMetricTooltips) =>
                                tooltip.associatedMetric === metric
                            )
                          : []
                      }
                      buttonClassName={``}
                    />
                  </span>
                </span>
              );
            }
          })}
      </>
    );
  };

  return isTableViewActive ? (
    benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY ? (
      <ReportingTableChart
        data={tileProps.data}
        metricGroupValues={associatedMetrics}
        associatedMetricsTooltips={associatedMetricsTooltips}
      />
    ) : (
      <TableChart
        data={mapReporingTableData()}
        currentInsightView={benchmarkType}
        leftAlignment={true}
        renderNeutralTooltip={true}
        customNeutralTooltipContent={neutralTooltipContent}
        getCustomValue={getCustomValueForTableView}
      />
    )
  ) : (
    <ReportingTile {...tileProps} />
  );
};

export default Reporting;
