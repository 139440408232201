import { useEffect } from "react";
import Note from "../shared/note/note";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import { loadNote } from "services/note/note.service";
import { NOTE_TYPES } from "utils/constants";

type props = {
  setShowNote: (value: boolean) => void;
  setCommentBlue: (value: boolean) => void;
  noteId?: number;
};

const ShortlistNotes = ({ setShowNote, setCommentBlue, noteId }: props) => {
  //const BLOCK = "Note";
  const shortListState = useSelector((state: RootStore) => state.shortlist);
  const noteState = useSelector((state: RootStore) => state.note);
  const dispatch = useDispatch();
  const headerAddNote = "shortlist.note.header";
  const headerEditNote = "shortlist.note.edit.header";
  const inputNote = "shortlist.note.input";

  useEffect(() => {
    const Payload = {
      commentId: noteId,
    };
    dispatch(loadNote(Payload));
  }, [noteId, dispatch]);
  return (
    <>
      {!noteState.loading && !noteState.NoteFetch.commentNoteId ? (
        <div data-test="note-add-component">
          <Note
            setShowModal={setShowNote}
            setCommentBlue={setCommentBlue}
            headerNote={headerAddNote}
            inputNote={inputNote}
            referenceId={shortListState.results.shortlistId}
            noteExist={false}
            commentNoteReferenceType={NOTE_TYPES.SHORTLIST}
          />
        </div>
      ) : (
        !noteState.loading &&
        noteState.NoteFetch.commentNoteId &&
        noteState.NoteFetch.commentNotes && (
          <div data-test="note-component">
            <Note
              setShowModal={setShowNote}
              setCommentBlue={setCommentBlue}
              headerNote={headerEditNote}
              inputNote={inputNote}
              referenceId={shortListState.results.shortlistId}
              noteId={noteState.NoteFetch.commentNoteId}
              noteExist={true}
              commentNoteReferenceType={NOTE_TYPES.SHORTLIST}
            />
          </div>
        )
      )}
    </>
  );
};

export default ShortlistNotes;
