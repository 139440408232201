import React, { useState, useEffect, useRef } from "react";
import Modal from "../shared/modal/modal";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { addToastMessage } from "services/commons.service";
import {
  errorRename,
  resetComparisonToSave,
  saveComparisonSuccess,
  refreshComparisonsList,
  resetSavedComparison,
  editComparisonPopUp,
} from "services/saved-comparisons/saved-comparisons.service";
import Button from "components/shared/button/button";
type props = {
  setShowModal: (value: boolean) => void;
  editComparison: (name: string, description: string) => void;
};

const EditComparison = ({ setShowModal, editComparison }: props) => {
  const BLOCK = "RenameShortlist";
  const [counts, setCounts] = useState({ name: 0, description: 0 });
  const [disabled, setDisabled] = useState(true);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const [values, setValues] = useState({ name: "", description: "" });
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(false);
  const comparisonsState = useSelector(
    (state: RootStore) => state.comparisonState
  );

  useEffect(() => {
    nameInputRef.current!.focus();
    if (comparisonsState.savedComparison) {
      setValues({
        ...values,
        name: comparisonsState.savedComparison.comparisonName
          ? comparisonsState.savedComparison.comparisonName
          : "",
        description: comparisonsState.savedComparison.comparisonDescription
          ? comparisonsState.savedComparison.comparisonDescription
          : "",
      });
      setCounts({
        ...counts,
        name: comparisonsState.savedComparison.comparisonName
          ? comparisonsState.savedComparison.comparisonName.length
          : 0,
        description: comparisonsState.savedComparison.comparisonDescription
          ? comparisonsState.savedComparison.comparisonDescription.length
          : 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (comparisonsState.errorRename) {
      setErrorMessage(true);
      setDisabled(true);
      nameInputRef.current!.focus();
      if (comparisonsState.comparisonToSave) {
        setValues({
          ...values,
          name: comparisonsState.comparisonToSave.comparisonName,
          description: comparisonsState.comparisonToSave.comparisonDescription,
        });

        setCounts({
          ...counts,
          name: comparisonsState.comparisonToSave.comparisonName.length,
          description:
            comparisonsState.comparisonToSave.comparisonDescription.length,
        });
      }
      dispatch(errorRename(false));
      dispatch(resetComparisonToSave());
      dispatch(saveComparisonSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonsState.errorRename, dispatch]);

  useEffect(() => {
    if (comparisonsState.saveComparisonSuccess) {
      setErrorMessage(false);
      setShowModal(false);
      dispatch(saveComparisonSuccess(false));

      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage
              id="save.comparison.success"
              values={{
                name: comparisonsState.savedComparisonResult.comparisonName,
              }}
            />
          ),
        })
      );
      dispatch(editComparisonPopUp(false));
      dispatch(refreshComparisonsList(true));
      dispatch(resetSavedComparison(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonsState.saveComparisonSuccess, dispatch]);

  const trimStartFunc = (value: any) => {
    if (!value) return value;
    return value.replace(/^\s+/g, "");
  };
  const onChangeHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCounts({
      ...counts,
      [event.target.name]: trimStartFunc(event.target.value).length,
    });
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "name") {
      setDisabled(event.target.value.trim().length === 0);
    }
    if (
      (comparisonsState.savedComparison.comparisonName ===
        nameInputRef.current!.value.trim() &&
        (comparisonsState.savedComparison.comparisonDescription ===
          descriptionInputRef.current!.value.trim() ||
          (!comparisonsState.savedComparison.comparisonDescription &&
            descriptionInputRef.current!.value.trim() === ""))) ||
      nameInputRef.current!.value.trim().length === 0
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setErrorMessage(false);
  };
  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      saveComparisonHandler();
    }
  };

  const saveComparisonHandler = () => {
    if (nameInputRef.current!.value.trim().length === 0 || disabled) {
      return;
    }
    editComparison(
      trimStartFunc(nameInputRef.current!.value).trim(),
      descriptionInputRef.current!.value.trim()
    );
  };

  return (
    <Modal
      show={true}
      header="comparison.edit.title"
      handleClose={() => {
        dispatch(resetSavedComparison());
        dispatch(editComparisonPopUp(false));
        setShowModal(false);
      }}
      config={false}
    >
      <div className={`${BLOCK} ${BLOCK}--saveSearchlist`} data-testid="RenameShortlist">
        <span
          className={classNames(`${BLOCK}__name--saveSearchList`, {
            [`${BLOCK}__name`]: !errorMessage,
            [`${BLOCK}__nameError`]: errorMessage,
          })}
        >
          <FormattedMessage id="comparison.save.as.name" />
        </span>
        <span
          className={classNames({
            [`${BLOCK}__input`]: !errorMessage,
            [`${BLOCK}__inputError`]: errorMessage,
            [`${BLOCK}__input--saveSearchList`]: !errorMessage,
            [`${BLOCK}__inputError--saveSearchList`]: errorMessage,
          })}
        >
          <input
            type="text"
            ref={nameInputRef}
            maxLength={200}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={values.name}
            name="name"
            data-testid="nameInput"
            placeholder="Enter Search name"
            className={classNames({
              [`${BLOCK}__input--saveSearchList`]: !errorMessage,
              [`${BLOCK}__inputError--saveSearchList`]: errorMessage,
            })}
          />
        </span>

        {errorMessage ? (
          <span
            className={`${BLOCK}__contentError ${BLOCK}__contentError--saveSearchList`}
            data-testid="errorMessage"
          >
            <FormattedMessage id="comparison.edit.report.error" />
          </span>
        ) : (
          <span
            className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}
          >
            {counts.name}
            <FormattedMessage id="shortlist.save.input.character" />
          </span>
        )}

        <span
          className={classNames(
            `${BLOCK}__name--saveSearchList ${BLOCK}__name ${BLOCK}__description-label`
          )}
        >
          <FormattedMessage id="searchlist.save.description" />
        </span>
        <span
          className={classNames(
            `${BLOCK}__input ${BLOCK}__input--saveSearchList`
          )}
        >
          <textarea
            ref={descriptionInputRef}
            maxLength={2000}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={values.description}
            data-testid="descriptionTextArea"
            placeholder="Enter description"
            className={classNames(`${BLOCK}__description`)}
            name="description"
          />
        </span>
        <span className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}>
          {counts.description}
          <FormattedMessage id="comparison.description.character.limit" />
        </span>
        <div className={`${BLOCK}__options ${BLOCK}__options--saveSearchList`}>
          <Button
            className={`button-secondary`}
            dataTest="cancel-btn"
            formattedText="shortlist.save.button.cancel"
            onClick={() => {
              dispatch(resetSavedComparison());
              dispatch(editComparisonPopUp(false));
              setShowModal(false);
            }}
          />
          <Button
            className={`button-primary`}
            dataTest="edit-saved-search-btn"
            disabled={disabled}
            formattedText="shortlist.save.button.save"
            onClick={saveComparisonHandler}
          />
        </div>
      </div>
    </Modal>
  );
};
export default EditComparison;
