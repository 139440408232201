import React, { Fragment, useMemo } from "react";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { AxisBottom } from "@visx/axis";
import { GridColumns, GridRows } from "@visx/grid";
import { scaleBand, scaleLinear } from "@visx/scale";
import { ScaleSVG } from "@visx/responsive";
import {
  CUSTOM_SPACING_PERCENTAGE,
  lightColor,
  MAX_WIDTH_PERCENTAGE,
  subTextColor,
  textColor,
} from "utils/constants";
import { defaultStyles, withTooltip } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { GroupedLegends, Legend } from "services/dashboard/dashboard.model";
import { isInteger } from "lodash";
import { Text } from "@visx/text";
import TileEmptyContent from "components/insight-tile/tile-empty-content";
import { getTooltipData } from "utils/functions";

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 50,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
  fontSize: 15,
};

type Props = {
  data: Legend[] | GroupedLegends[];
  name: string;
  axis?: { primary: string[]; secondary: string[] };
  width?: number;
  height?: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  descSort?: boolean;
};

type GraphProps = {
  data: Legend[] | GroupedLegends[];
  name: string;
  width: number;
  height: number;
  axis?: { primary: string[]; secondary: string[] };
  margin?: { top: number; right: number; bottom: number; left: number };
  onClickSegment?: <T>(arg: T) => any;
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  descSort?: boolean;
};

const UngoupedHorizontalBarChart = ({
  data,
  name,
  axis,
  width = 1000,
  height = 1000,
  margin = { top: 5, right: 15, bottom: 25, left: 5 },
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
  handleGraphElementClicked,
  descSort,
}: GraphProps & WithTooltipProvidedProps<Legend>) => {
  let tooltipTimeout: number;
  const dataset = (data as Legend[]).filter((d) => d.legendData > 0);
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const xMaxLegend = axis?.primary.length
    ? axis?.primary.length
    : Math.max(...dataset.map((d) => d.legendData ?? 0));

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax],
        round: true,
        nice: true,
        domain: [0, xMaxLegend],
      }),
    [xMax, xMaxLegend]
  );

  const yScale = useMemo(
    () =>
      scaleBand<string>({
        range: [yMax, 0],
        round: true,
        domain: dataset.map((d) => d.legendValue),
        padding: 0.3,
      }),
    [yMax, dataset]
  );
  return (
    <div className="limit-size" style={{ position: "relative" }}>
      <ScaleSVG width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <GridColumns
            scale={xScale}
            width={xMax}
            height={yMax}
            stroke={lightColor}
          />
          <AxisBottom
            strokeWidth={1}
            scale={xScale}
            stroke={lightColor}
            tickStroke={lightColor}
            top={yMax}
            tickValues={xScale.ticks().filter(isInteger)}
            tickFormat={(tick: any) =>
              axis?.primary.length && tick <= axis.primary.length
                ? axis.primary[tick - 1]
                : tick.toFixed()
            }
            tickComponent={(tickProps) => {
              return (
                <text
                  fontFamily="Open Sans"
                  fontSize={12}
                  textAnchor="middle"
                  dx={tickProps.dx}
                  dy={tickProps.dy}
                  x={tickProps.x}
                  y={tickProps.y}
                >
                  <tspan x={tickProps.x} dy={tickProps.dy}>
                    {tickProps.formattedValue}
                  </tspan>
                </text>
              );
            }}
          />
          {dataset
            .sort(
              descSort
                ? (d1, d2) =>
                    d1.legendValue > d2.legendValue
                      ? -1
                      : d1.legendValue < d2.legendValue
                      ? 1
                      : 0
                : (d1, d2) => d1.legendOrder - d2.legendOrder
            )
            .map((data, i) => {
              const barWidth = xScale(data.legendData ?? 0.1) ?? 0;
              const barHeight = yScale.bandwidth();
              const barX = 0;
              let barY = yScale(data.legendValue) || 0;
              let maxHeight = barHeight;

              if (
                (barHeight * 100) / yMax > MAX_WIDTH_PERCENTAGE &&
                data.legendValue
              ) {
                let bars = dataset.filter((d: any) => data.legendValue);
                maxHeight = yMax * (MAX_WIDTH_PERCENTAGE / 100);
                let spacing = yMax * (CUSTOM_SPACING_PERCENTAGE / 100);
                let spacingQuantity = bars.length - 1;
                let totalWidth =
                  spacing * spacingQuantity + maxHeight * bars.length;

                if (totalWidth < yMax - yScale.padding() * 2) {
                  let startingPoint =
                    yScale.padding() + (yMax - totalWidth) / 2;
                  barY = startingPoint + i * (maxHeight + spacing);
                } else maxHeight = barHeight;
              }

              return (
                <Bar
                  key={`bar-${data.legendValue}-${i}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={maxHeight}
                  style={handleGraphElementClicked ? { cursor: "pointer" } : {}}
                  onClick={() =>
                    handleGraphElementClicked
                      ? handleGraphElementClicked(data)
                      : null
                  }
                  fillOpacity={
                    tooltipOpen && tooltipData?.legendValue !== data.legendValue
                      ? 0.4
                      : 1
                  }
                  fill={data.legendColor}
                  onMouseLeave={() =>
                    (tooltipTimeout = window.setTimeout(() => {
                      hideTooltip();
                    }, 100))
                  }
                  onMouseMove={() => {
                    if (tooltipTimeout) clearTimeout(tooltipTimeout);
                    showTooltip({
                      tooltipData: data,
                      tooltipTop: barY,
                      tooltipLeft: barWidth,
                    });
                  }}
                />
              );
            })}
        </Group>
      </ScaleSVG>
      {tooltipOpen && tooltipData && (
        <div
          style={{
            ...tooltipStyles,
            top: tooltipTop,
            left: tooltipLeft,
            position: "absolute",
          }}
        >
          <strong>
            {
              (tooltipData.legendTooltip
                ? tooltipData.legendTooltip
                : tooltipData.legendValue) as React.ReactNode
            }
          </strong>{" "}
          ({getTooltipData(axis, tooltipData)})
        </div>
      )}
    </div>
  );
};

const StackedHorizontalBarChart = ({
  data,
  name,
  axis,
  width,
  height,
  margin = { top: 5, right: 40, bottom: 25, left: 40 },
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
}: GraphProps & WithTooltipProvidedProps<{ data: Legend; index: number }>) => {
  const dataset = data as GroupedLegends[];
  const datasetTotals = dataset.map((group) => ({
    total: group.separateBars
      ? group.legends.reduce((acc, curr) => Math.max(acc, curr.legendData), 0)
      : group.legends.reduce((acc, curr) => acc + curr.legendData, 0),
    groupName: group.groupName,
  }));
  let tooltipTimeout: number;
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax],
        round: true,
        nice: true,
        domain: [0, Math.max(...datasetTotals.map((d) => d.total ?? 0))],
      }),
    [xMax, datasetTotals]
  );

  const yScale = useMemo(
    () =>
      scaleBand<string>({
        range: [yMax, 0],
        round: true,
        domain: dataset.map((d) => d.groupName),
        padding: 0.3,
      }),
    [yMax, dataset]
  );
  const allLegendDataIsNull = dataset.every((group) => {
    return group.legends.every((legend) => {
      return legend.legendData === null;
    });
  });

  if (!allLegendDataIsNull) {
    return (
      <div className="limit-size" style={{ position: "relative" }}>
        <ScaleSVG width={width} height={height}>
          <Group left={margin.left} top={margin.top}>
            <GridColumns
              scale={xScale}
              width={xMax}
              height={yMax}
              stroke={lightColor}
            />
            {dataset && dataset[0].separateBars ? (
              <GridRows
                offset={
                  -(height - margin.top - margin.bottom) / (dataset.length * 2)
                }
                scale={yScale}
                width={xMax}
                height={yMax}
                stroke={lightColor}
              />
            ) : null}
            <AxisBottom
              strokeWidth={1}
              scale={xScale}
              stroke={lightColor}
              tickStroke={lightColor}
              top={yMax}
              tickValues={xScale.ticks().filter(isInteger)}
              tickFormat={(tick: any) => tick.toFixed()}
              tickComponent={(tickProps) => {
                return (
                  <text
                    fontFamily="Open Sans"
                    fontSize={12}
                    textAnchor="middle"
                    dx={tickProps.dx}
                    dy={tickProps.dy}
                    x={tickProps.x}
                    y={tickProps.y}
                  >
                    <tspan x={tickProps.x} dy={tickProps.dy}>
                      {tickProps.formattedValue}
                    </tspan>
                  </text>
                );
              }}
            />
            {dataset.map((group, i) => {
              const total: any = datasetTotals.find(
                ({ groupName }) => groupName === group.groupName
              );
              const barHeight_tmp =
                (yMax - yScale.bandwidth()) / dataset.length;
              const barSpacing = group.separateBars
                ? barHeight_tmp / (group.legends.length * 2)
                : 0;
              const spacing = yMax / dataset.length - barHeight_tmp;
              let barY_tmp =
                (dataset.length - 1 - i) * (spacing + barHeight_tmp) +
                spacing / 2;
              let offsetX = 0;
              let offsetJ = -1;

              let maxHeight = barHeight_tmp;

              if (
                (barHeight_tmp * 100) / yMax > MAX_WIDTH_PERCENTAGE &&
                !group.separateBars
              ) {
                maxHeight = yMax * (MAX_WIDTH_PERCENTAGE / 100);
                let spacing = yMax * (CUSTOM_SPACING_PERCENTAGE / 100);
                let spacingQuantity = dataset.length - 1;
                let totalWidth =
                  spacing * spacingQuantity + maxHeight * dataset.length;

                if (totalWidth < yMax - yScale.padding() * 2) {
                  let startingPoint =
                    yScale.padding() + (yMax - totalWidth) / 2;
                  barY_tmp = startingPoint + i * (maxHeight + spacing);
                } else maxHeight = barHeight_tmp;
              }

              return (
                <Fragment key={i}>
                  {group.legends
                    .sort((d1, d2) => d1.legendOrder - d2.legendOrder)
                    .map((data, j) => {
                      let barY = barY_tmp;
                      let barHeight = maxHeight;
                      if (group.separateBars) {
                        offsetJ += parseFloat(data.legendData) > 0 ? 1 : 0;
                        barHeight =
                          (barHeight - barSpacing) / group.legends.length;
                        barY =
                          barY_tmp + offsetJ * (barHeight + barSpacing / 2);
                        offsetX = 0;
                      }
                      const barWidth = xScale(data.legendData ?? 0) ?? 0;
                      const barX = offsetX;

                      offsetX += barWidth;

                      return barX >= 0 &&
                        barY >= 0 &&
                        barWidth >= 0 &&
                        barHeight >= 0 ? (
                        <Bar
                          key={`bar-${data.legendValue}-${i * j}`}
                          x={barX}
                          y={barY}
                          width={barWidth}
                          height={group.separateBars ? barHeight : maxHeight}
                          fillOpacity={
                            tooltipOpen && tooltipData?.index !== i * 10000 + j
                              ? 0.4
                              : 1
                          }
                          fill={data.legendColor}
                          onMouseLeave={() =>
                            (tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 100))
                          }
                          onMouseMove={() => {
                            if (tooltipTimeout) clearTimeout(tooltipTimeout);
                            showTooltip({
                              tooltipData: {
                                data,
                                index: i * 10000 + j,
                              },
                              tooltipTop: barY,
                              tooltipLeft: barX + barWidth,
                            });
                          }}
                        />
                      ) : null;
                    })}
                  <Text
                    textAnchor="middle"
                    verticalAnchor="middle"
                    fill={
                      tooltipData &&
                      (tooltipData?.index < i * 10000 ||
                        tooltipData?.index >= (i + 1) * 10000)
                        ? subTextColor
                        : textColor
                    }
                    fontSize={12}
                    dx={-15}
                    dy={barY_tmp + maxHeight / 2}
                    fontWeight="600"
                    angle={group.separateBars ? 90 : 0}
                  >
                    {group.separateBars ? group.groupName : i + 1}
                  </Text>
                  <Text
                    textAnchor="middle"
                    verticalAnchor="middle"
                    fill={textColor}
                    fontSize={14}
                    dx={offsetX + 16}
                    dy={barY_tmp + maxHeight / 2}
                    fontWeight="600"
                  >
                    {group.separateBars ? null : total.total}
                  </Text>
                </Fragment>
              );
            })}
          </Group>
        </ScaleSVG>
        {tooltipOpen && tooltipData && (
          <div
            style={{
              ...tooltipStyles,
              top: tooltipTop,
              left: tooltipLeft,
              position: "absolute",
            }}
          >
            <strong>
              {
                (tooltipData.data.legendTooltip
                  ? tooltipData.data.legendTooltip
                  : tooltipData.data.legendValue) as React.ReactNode
              }
            </strong>{" "}
            ({tooltipData.data.legendData ?? 0})
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        className="limit-size"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TileEmptyContent />
      </div>
    );
  }
};

const HorizontalBarChart = ({
  data,
  name,
  axis,
  width = 1000,
  height = 1000,
  handleGraphElementClicked,
  descSort,
}: Props) => {
  const Bar = withTooltip<GraphProps, Legend>(UngoupedHorizontalBarChart);
  const StackedBar = withTooltip<GraphProps, { data: Legend; index: number }>(
    StackedHorizontalBarChart
  );
  return data && data.length && (data[0] as GroupedLegends).groupName
    ? StackedBar({ data, name, axis, width, height })
    : Bar({
        data,
        name,
        axis,
        width,
        height,
        handleGraphElementClicked,
        descSort,
      });
};

export default HorizontalBarChart;
