import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { ChangeEvent, Fragment } from "react";
import { CompanyFilter } from "services/search/filters.model";
import { FILTER_OPTIONS } from "utils/constants";
import DropDown from "components/dashboard/benchmark-dropdown";

type props = {
  BLOCK: string;
  dropdownLabel: {
    label: string;
    placeholder: string;
    values: CompanyFilter[];
    options: CompanyFilter[];
    loading: FILTER_OPTIONS[];
    textValue: string;
    type: string;
    fromPeerBenchmark?: boolean;
    limit?: string;
    showOnEmpty?: boolean;
    additionalText?: string;
    labelWrapper?: any;
    count?: number;
    additionalContent?: any;
    disabled?: boolean;
    beforeSelectBaselineCompany?: any;
    changeNonSecCompany?: boolean;
    localSelectedBaseline?: CompanyFilter | null;
    setLocalSelectedBaseline?: any;
  }[];
  debounceChangeHandler: (
    event: ChangeEvent<HTMLInputElement>,
    type: string
  ) => void;
  selectCompany: any;
  unselectCompany: any;
  fromPeerBenchmark?: boolean;
  disabled?: boolean;
  editView?: boolean;
  fromInsight?: boolean;
};

const DropdownContainer = ({
  BLOCK,
  dropdownLabel,
  debounceChangeHandler,
  selectCompany,
  unselectCompany,
  fromPeerBenchmark,
  disabled,
  editView,
  fromInsight,
}: props) => {
  return (
    <>
      {dropdownLabel.map((dropdown, ind: number) => (
        <Fragment key={`dropdown-${ind}`}>
          <DropDown
            className={`${BLOCK}__dropdown`}
            label={
              dropdown.labelWrapper ? (
                dropdown.labelWrapper
              ) : (
                <div className={`${BLOCK}__dropdown-label`}>
                  <FormattedMessage id={dropdown.label} />
                </div>
              )
            }
            placeholder={
              <FormattedMessage
                id={dropdown.placeholder}
                values={{
                  count: dropdown.limit,
                }}
              />
            }
            showAdditionalText={dropdown.additionalText ? true : false}
            additionalText={
              <div className={`${BLOCK}__dropdown-label-additionalText`}>
                <FormattedMessage
                  id={dropdown.additionalText ? dropdown.additionalText : ""}
                  values={{
                    count: dropdown.limit,
                    qty: dropdown.count,
                  }}
                />
              </div>
            }
            objectKeys={{
              name: "companyName",
              ticker: "ticker",
              id: "companyId",
              externalId: "externalCompanyId",
            }}
            values={dropdown.values}
            options={dropdown.options}
            handleChange={debounceChangeHandler}
            loading={
              dropdown.loading.filter(
                (filter) => filter === FILTER_OPTIONS.COMPANY
              ).length > 0
            }
            handleSelect={selectCompany}
            handleUnselect={unselectCompany}
            textValue={dropdown.textValue}
            type={dropdown.type}
            key={`dropdown--${dropdown.label}`}
            fromPeerBenchmark={fromPeerBenchmark}
            disabled={fromInsight ? dropdown.disabled : disabled}
            editView={editView}
            showOnEmpty={dropdown.showOnEmpty}
            fromInsight={fromInsight}
            exceededLimit={
              dropdown.limit && dropdown.count ? dropdown.count === 8 : false
            }
            beforeSelectBaselineCompany={dropdown.beforeSelectBaselineCompany}
            changeNonSecCompany={dropdown.changeNonSecCompany}
            localSelectedBaseline={dropdown.localSelectedBaseline}
            setLocalSelectedBaseline={dropdown.setLocalSelectedBaseline}
          />
          {dropdown.additionalContent && fromInsight
            ? dropdown.additionalContent
            : null}
        </Fragment>
      ))}
    </>
  );
};

export default DropdownContainer;
