import FormattedMessage from "components/shared/formatted-message/formatted-message";

const TileEmptyContent = () => {
  const BLOCK = "main-tile-empty";

  return (
    <div className={`${BLOCK}__container`} data-testid="tile-empty-content">
      <span className={`${BLOCK}__label`}>
        <FormattedMessage id="insight.no.data.available" />
      </span>
    </div>
  );
};

export default TileEmptyContent;
