import { useEffect, useRef, useState } from "react";
import Modal from "../shared/modal/modal";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import { RootStore } from "../../services/store.service";
import { useDispatch, useSelector } from "react-redux";
import {
  searchRenameError,
  searchRenameSearchSuccess,
  saveNewSearch,
  resetSearchToSave,
  setEditSavedSearchPopUp,
  resetSavedSearch,
  setRefreshSavedSearchesList,
  editSavedSearch,
  getTopics,
  topicNameError,
} from "../../services/searchlist/searchlist.service";
import classNames from "classnames";
import { addToastMessage } from "../../services/commons.service";
import DropDown from "./dropdown";
import { publishSearchType } from "../../utils/constants";
import Icon from "components/shared/icon/icon";
import Button from "components/shared/button/button";

type props = {
  setShowModal: (value: boolean) => void;
  publishSearch: (
    value: {
      name: string;
      description: string;
    },
    selectedTopic: {
      topicId: string;
      topicName: string;
      topicDescription: string;
    }
  ) => void;
  type: number;
};

const PublishSearch = ({ setShowModal, publishSearch, type }: props) => {
  const BLOCK = "PublishSearch";
  const [counts, setCounts] = useState({ name: 0, description: 0 });
  const [countTopic, setCountTopic] = useState({
    topicName: 0,
    topicDescription: 0,
  });
  const [disabled, setDisabled] = useState(true);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const topicNameRef = useRef<HTMLInputElement>(null);
  const topicDescriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const [values, setValues] = useState({ name: "", description: "" });
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(false);
  const [topicError, setTopicError] = useState(false);
  const searchlist = useSelector((state: RootStore) => state.searchlist);
  const [selectedTopic, setSelected] = useState<{
    topicId: any;
    topicName: string;
    topicDescription: string;
  }>({
    topicId: "",
    topicName: "",
    topicDescription: "",
  });
  const [editTopic, setEditTopic] = useState<boolean>(false);
  const [previousTopic, setPreviousTopic] = useState<{
    topicId: any;
    topicName: string;
    topicDescription: string;
  }>({
    topicId: "",
    topicName: "",
    topicDescription: "",
  });
  const [newTopic, setNewTopic] = useState(false);

  useEffect(() => {
    if (newTopic && !editTopic) {
      setSelected({ topicId: "", topicName: "", topicDescription: "" });
      setCountTopic({ topicName: 0, topicDescription: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTopic]);

  useEffect(() => {
    if (
      nameInputRef.current!.value.trim().length === 0 ||
      selectedTopic.topicName.trim().length === 0
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selectedTopic, values]);

  // Use Effects and Handlers will change based on requirement for Publish
  useEffect(() => {
    const handleSelected = (selectedOption: any) => {
      setSelected({
        ...selectedTopic,
        topicId: selectedOption.topicId,
        topicName: selectedOption.topicName,
        topicDescription: selectedOption.topicDescription,
      });
      setCountTopic({
        ...countTopic,
        topicDescription: selectedOption.topicDescription.trim().length,
      });
    };

    nameInputRef.current!.focus();
    if (searchlist.savedSearch) {
      setValues({
        ...values,
        name: searchlist.savedSearch.name ? searchlist.savedSearch.name : "",
        description: searchlist.savedSearch.description
          ? searchlist.savedSearch.description
          : "",
      });
      setCounts({
        ...counts,
        name: searchlist.savedSearch.name
          ? searchlist.savedSearch.name.length
          : 0,
        description: searchlist.savedSearch.description
          ? searchlist.savedSearch.description.length
          : 0,
      });

      if (searchlist.savedSearch.topic!) {
        handleSelected(searchlist.savedSearch.topic!);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //To get all the topoics
  useEffect(() => {
    dispatch(getTopics());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchlist.errorRename) {
      setErrorMessage(true);
      nameInputRef.current!.focus();
      if (searchlist.searchToSave) {
        setValues({
          ...values,
          name: searchlist.searchToSave.name!
            ? searchlist.searchToSave.name!
            : "",
          description: searchlist.searchToSave.description!
            ? searchlist.searchToSave.description!
            : "",
        });

        setCounts({
          ...counts,
          name: searchlist.searchToSave.name!
            ? searchlist.searchToSave.name!.length
            : 0,
          description: searchlist.searchToSave.description!
            ? searchlist.searchToSave.description!.length
            : 0,
        });
      }
      dispatch(searchRenameError(false));
      dispatch(resetSearchToSave());
      dispatch(saveNewSearch(true));
      dispatch(searchRenameSearchSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchlist.errorRename, dispatch]);

  useEffect(() => {
    if (searchlist.topicNameError) {
      setTopicError(true);
      dispatch(topicNameError(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchlist.topicNameError, dispatch]);

  useEffect(() => {
    if (searchlist.renameSearchSuccess) {
      dispatch(saveNewSearch(false));
      setErrorMessage(false);
      setShowModal(false);
      dispatch(setEditSavedSearchPopUp(false));
      dispatch(searchRenameSearchSuccess(false));
      if (searchlist.savedSearch.hasPublished) {
        dispatch(
          addToastMessage({
            description: <FormattedMessage id="edit.search-query.success" />,
          })
        );
      } else {
        dispatch(
          addToastMessage({
            description: <FormattedMessage id="saved.searches.published" />,
          })
        );
      }

      dispatch(editSavedSearch(true));
      dispatch(setRefreshSavedSearchesList(true));
      dispatch(resetSavedSearch(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchlist.renameSearchSuccess, dispatch, setShowModal]);

  useEffect(() => {
    if (
      values.name.trim() === searchlist.savedSearch.name!.trim() &&
      values.description.trim() ===
        searchlist.savedSearch.description!.trim() &&
      searchlist.savedSearch.topic! &&
      searchlist.savedSearch.topic.topicName!.trim() ===
        selectedTopic.topicName.trim() &&
      searchlist.savedSearch.topic.topicDescription!.trim() ===
        selectedTopic.topicDescription.trim()
    ) {
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, selectedTopic]);

  useEffect(() => {
    if (newTopic) {
      topicNameRef.current!.focus();
    }
  }, [newTopic]);

  const trimStartFunc = (value: any) => {
    if (!value) return value;
    return value.replace(/^\s+/g, "");
  };

  const onChangeHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCounts({
      ...counts,
      [event.target.name]: trimStartFunc(event.target.value).length,
    });
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setCountTopic({
      ...countTopic,
      [event.target.name]: trimStartFunc(event.target.value).length,
    });
    setSelected({
      ...selectedTopic,
      [event.target.name]: trimStartFunc(event.target.value),
    });

    setErrorMessage(false);
    setTopicError(false);
  };
  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      saveSearchHandler();
    }
  };

  const handleSelected = (selectedOption: any) => {
    setSelected({
      ...selectedTopic,
      topicId: selectedOption.topicId,
      topicName: selectedOption.topicName,
      topicDescription: selectedOption.topicDescription,
    });
    setCountTopic({
      ...countTopic,
      topicDescription: trimStartFunc(selectedOption.topicDescription).length,
    });
  };

  const saveSearchHandler = () => {
    if (nameInputRef.current!.value.trim().length === 0 || disabled) {
      return;
    }
    // To change based on Publish
    publishSearch(values, selectedTopic);
  };

  const editTopicHandler = () => {
    // Setting the values for Edit Click Effects
    setEditTopic(!editTopic);
    if (!editTopic) {
      // Edit Current Topic
      setCountTopic({
        ...countTopic,
        topicName: selectedTopic.topicName.trim().length,
      });
      setNewTopic(true);
      setPreviousTopic(selectedTopic);
    } else {
      // Cancel Edit Topic
      setNewTopic(false);
      setSelected(previousTopic);
    }
  };

  return (
    <Modal
      show={true}
      header={
        type === publishSearchType.savedSearches
          ? searchlist.savedSearch.hasPublished
            ? "publish.searchlist.popup.edit.header"
            : "publish.searchlist.popup.saved.header"
          : "publish.searchlist.popup.published.header"
      }
      handleClose={() => {
        setShowModal(false);
        dispatch(setEditSavedSearchPopUp(false));
      }}
      config={false}
      data-testid="publish-search-modal"
    >
      <div className={`${BLOCK} ${BLOCK}--saveSearchlist`} data-testid={`${BLOCK}--saveSearchlist`}>
        {/* Search Name Portion */}
        <span
          className={classNames(`${BLOCK}__name--saveSearchList`, {
            [`${BLOCK}__name`]: !errorMessage,
            [`${BLOCK}__nameError`]: errorMessage,
          })}
        >
          <FormattedMessage id="publish.searchlist.popup.name" />
        </span>
        <span
          className={classNames({
            [`${BLOCK}__input`]: !errorMessage,
            [`${BLOCK}__inputError`]: errorMessage,
            [`${BLOCK}__input--saveSearchList`]: !errorMessage,
            [`${BLOCK}__inputError--saveSearchList`]: errorMessage,
          })}
        >
          <input
            type="text"
            ref={nameInputRef}
            maxLength={200}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={values.name}
            name="name"
            data-testid="nameInput"
            placeholder="Enter name"
            className={classNames({
              [`${BLOCK}__input--saveSearchList`]: !errorMessage,
              [`${BLOCK}__inputError--saveSearchList`]: errorMessage,
            })}
          />
        </span>

        {errorMessage ? (
          <span
            className={`${BLOCK}__contentError ${BLOCK}__contentError--saveSearchList`}
            data-testid="publishSearch-errorMessage"
          >
            <FormattedMessage id="publish.searchlist.name.error" />
          </span>
        ) : (
          <span
            className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}
          >
            {counts.name}
            <FormattedMessage id="publish.searchlist.popup.name.character.limit" />
          </span>
        )}

        <span
          className={classNames(
            `${BLOCK}__name--saveSearchList ${BLOCK}__name ${BLOCK}__description-label`
          )}
        >
          <FormattedMessage id="publish.searchlist.popup.description" />
        </span>
        <span
          className={classNames(
            `${BLOCK}__input ${BLOCK}__input--saveSearchList`
          )}
        >
          <textarea
            ref={descriptionInputRef}
            maxLength={2000}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={values.description}
            data-testid="descriptionTextArea"
            data-test="descriptionTextArea"
            placeholder="Enter description"
            className={classNames(`${BLOCK}__description`)}
            name="description"
          />
        </span>
        <span className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}>
          {counts.description}
          <FormattedMessage id="publish.searchlist.popup.save.description.character.limit" />
        </span>
        {/* Topic Name Portion */}
        {!newTopic && (
          <>
            <span className={`${BLOCK}__name`}>
              <FormattedMessage id="publish.searchlist.popup.topic" />
              {selectedTopic.topicId && selectedTopic.topicId !== 0 && (
                <span className={`${BLOCK}__name__compare`}>
                  <button
                    className={`${BLOCK}__name__compare--button`}
                    onClick={editTopicHandler}
                    data-test="publish-search-edit-button"
                    data-testid="publish-search-edit-button"
                  >
                    <FormattedMessage id="publish.searchlist.popup.edit" />
                  </button>
                </span>
              )}
            </span>

            <span>
              {/* Dropdown Component */}
              <DropDown
                className={`${BLOCK}__dropdown`}
                placeholder={<FormattedMessage id="compare.notselected" />}
                values={selectedTopic}
                objectKeys={{
                  name: "topicName",
                  id: "topicId",
                }}
                options={searchlist.topics}
                loading={searchlist.loading}
                handleSelect={handleSelected}
                newTopicHandler={setNewTopic}
                textValue={selectedTopic.topicName}
              />
            </span>
          </>
        )}
        {newTopic && (
          <>
            <span
              className={classNames(`${BLOCK}__name--saveSearchList`, {
                [`${BLOCK}__name`]: !topicError,
                [`${BLOCK}__topicNameError`]: topicError,
              })}
            >
              {!editTopic ? (
                <FormattedMessage id="new.topic" />
              ) : (
                <FormattedMessage id="publish.searchlist.popup.topic" />
              )}
              {selectedTopic.topicId && selectedTopic.topicId !== 0 && (
                <span className={`${BLOCK}__name__compare`}>
                  <button
                    className={`${BLOCK}__name__compare--button`}
                    onClick={editTopicHandler}
                    data-test="publish-search-edit-button"
                    data-testid="publish-search-edit-button"
                  >
                    <FormattedMessage id="publish.searchlist.popup.cancel" />
                  </button>
                </span>
              )}
            </span>
            <span
              className={classNames({
                [`${BLOCK}__input`]: !topicError,
                [`${BLOCK}__topicInputError`]: topicError,
                [`${BLOCK}__input--saveSearchList`]: !topicError,
                [`${BLOCK}__topicInputError--saveSearchList`]: topicError,
              })}
            >
              <input
                type="text"
                maxLength={200}
                onChange={onChangeHandler}
                onKeyDown={onEnter}
                value={selectedTopic.topicName}
                ref={topicNameRef}
                name="topicName"
                data-testid="nameInput"
                placeholder="Enter name"
                className={classNames({
                  [`${BLOCK}__input--saveSearchList`]: !topicError,
                  [`${BLOCK}__inputError--saveSearchList`]: topicError,
                })}
              />
            </span>
            {topicError ? (
              <span
                className={`${BLOCK}__contentError ${BLOCK}__contentError--saveSearchList`}
              >
                <FormattedMessage id="publish.searchlist.topic.error" />
              </span>
            ) : (
              <span
                className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}
              >
                {countTopic.topicName}
                <FormattedMessage id="publish.searchlist.popup.name.character.limit" />
              </span>
            )}
          </>
        )}

        <span
          className={classNames(
            `${BLOCK}__name--saveSearchList ${BLOCK}__name ${BLOCK}__description-label`
          )}
        >
          <FormattedMessage id="publish.searchlist.popup.description" />
        </span>
        <span
          className={classNames(
            `${BLOCK}__input ${BLOCK}__input--saveSearchList`
          )}
        >
          <textarea
            ref={topicDescriptionInputRef}
            maxLength={2000}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={selectedTopic.topicDescription}
            data-testid="descriptionTextArea"
            placeholder="Enter description"
            className={classNames(`${BLOCK}__description`)}
            name="topicDescription"
          />
        </span>
        <span className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}>
          {countTopic.topicDescription}
          <FormattedMessage id="publish.searchlist.popup.save.description.character.limit" />
        </span>
        {newTopic && !editTopic && (
          <div className={`${BLOCK}__cancel-topic-section`}>
            <button
              className={`${BLOCK}__cancel-topic`}
              data-testid="cancel-new-topic-btn"
              onClick={() => {
                setNewTopic(false);
                setSelected({
                  topicId: "",
                  topicName: "",
                  topicDescription: "",
                });
              }}
            >
              <Icon name="cross-blue" height={12} width={12} className="icon" />
              <FormattedMessage id="cancel.topic.creation" />
            </button>
          </div>
        )}

        <div className={`${BLOCK}__options ${BLOCK}__options--saveSearchList`}>
          <Button
            className={`button-secondary`}
            formattedText="publish.searchlist.popup.cancel"
            onClick={() => {
              setShowModal(false);
              dispatch(setEditSavedSearchPopUp(false));
            }}
            dataTest="cancel-btn"
          />

          <Button
            className={`button-primary`}
            disabled={disabled}
            dataTest="publish-saved-search-btn"
            formattedText={`${
              type === publishSearchType.savedSearches
                ? searchlist.savedSearch.hasPublished
                  ? "publish.searchlist.popup.edit.publish"
                  : "publish.searchlist.popup.saved.publish"
                : "publish.searchlist.popup.published.publish"
            }`}
            onClick={saveSearchHandler}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PublishSearch;
