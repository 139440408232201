import TimeChart from "components/visualizations/charts/time.chart";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import { useTileContext } from "../tile.context";

const TimeHorizons = () => {
  const {
    dataset,
    metadata: {
      benchmarkMetadata,
      benchmarkType,
      isDetailedView,
      associatedMetricsTooltips,
    },
    isTableViewActive,
    associatedMetricsDisplayNames,
  } = useTileContext();
  const FROM = "From";
  const TO = "To";
  const mapTimeHorizons = () => {
    let tmpData: any[] = [];

    if (
      dataset &&
      dataset?.every((data: any) =>
        data.metrics?.every(
          (elem: any) =>
            elem?.metricValue === null ||
            elem?.metricValue === "" ||
            elem?.metricValue === "*"
        )
      )
    ) {
      return tmpData;
    }

    associatedMetricsDisplayNames.forEach((am: string, i: number) => {
      let keyToSearch = am.replace("-", "");
      dataset.forEach((ds: InsightData) => {
        let timeValues = ds.metrics.filter(
          (m: InsightMetricData) =>
            m.metricKey.startsWith(keyToSearch) &&
            (m.metricKey.includes(FROM) || m.metricKey.includes(TO))
        );
        tmpData.push({
          companyName: ds.companyName,
          description:
            benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY ? [am] : am,
          associatedMetricsTooltips: associatedMetricsTooltips.filter(
            (associatedMetricsTooltip: AssociatedMetricTooltips) =>
              associatedMetricsTooltip.associatedMetricDisplayName === am
          ),
          fieldName:
            benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY ? [am] : am,
          fieldValue: timeValues.every(
            (tv: InsightMetricData) => tv.metricValue === "" || !tv.metricValue
          )
            ? "*"
            : `${
                timeValues[0].metricValue !== "" && timeValues[0].metricValue
                  ? timeValues[0].metricValue
                  : "*"
              } - ${
                timeValues[1].metricValue !== "" && timeValues[1].metricValue
                  ? timeValues[1].metricValue
                  : "*"
              }`,
        });
      });
    });

    return tmpData;
  };
  const tileProps = {
    companyName: dataset.filter((ds: InsightData) => ds.isBaseCompany)[0],
    metrics: benchmarkMetadata,
    data: mapTimeHorizons(),
    filteredCompanies: [],
    isDetailView: isDetailedView,
    isTableView: isTableViewActive,
    allTooltips: associatedMetricsTooltips,
    benchmarkType: benchmarkType,
    visData: {
      data: mapTimeHorizons(),
      benchmarkType: benchmarkType,
      metrics: benchmarkMetadata,
      allTooltips: associatedMetricsTooltips,
    },
  };
  return <TimeChart data={tileProps} />;
};

export default TimeHorizons;
