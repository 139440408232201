import { createAction } from "utils/redux.utils";
import {
  UserManagementModel,
  UserManagementState,
} from "./user-management.model";
import { Dispatch } from "redux";
import * as api from "./user-management.api";
import { Roles } from "utils/constants";

export const ACTIONS = {
  SUPER_ADMIN_LOADING: "SUPER_ADMIN_LOADING",
  SUPER_ADMIN_RESULTS: "SUPER_ADMIN_RESULTS",
  USER_LIST_DATA: "USER_LIST_RESULTS",
  ADD_SUPER_ADMIN_SUCCESS: "ADD_SUPER_ADMIN_SUCCESS",
  API_ERROR: "API_ERROR",
  DELETE_SUPER_ADMIN_SUCCESS: "DELETE_SUPER_ADMIN_SUCCESS",
};

export const superAdminLoading = createAction(ACTIONS.SUPER_ADMIN_LOADING);
export const superAdminResults = createAction(ACTIONS.SUPER_ADMIN_RESULTS);
export const userListData = createAction(ACTIONS.USER_LIST_DATA);
export const addSuperAdminSuccess = createAction(
  ACTIONS.ADD_SUPER_ADMIN_SUCCESS
);
export const apiError = createAction(ACTIONS.API_ERROR);
export const deleteSuperAdminSuccess = createAction(
  ACTIONS.DELETE_SUPER_ADMIN_SUCCESS
);

export const loadSuperAdmins = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(superAdminLoading(true));
    const res = await api.getAdminUsersByRole(Roles.superAdmin);
    dispatch(superAdminResults(res.data));
    dispatch(superAdminLoading(false));
  } catch (e) {
    dispatch(superAdminLoading(false));
  }
};

export const loadUsers =
  (searchText: string, currentUserId: number) =>
  async (dispatch: Dispatch<any>) => {
    try {
      const res = searchText ? await api.getUsers(searchText) : [];
      dispatch(userListData({ userListData: res, currentUserId }));
    } catch (e) {}
  };

export const saveSuperAdmin =
  (userId: any) => async (dispatch: Dispatch<any>) => {
    try {
      const res = await api.addAdminUser({
        userIds: [userId],
        adminRoleId: Roles.superAdmin,
      });
      if (res.data) {
        dispatch(addSuperAdminSuccess(true));
      } else {
        dispatch(apiError(true));
      }
    } catch (e) {
      dispatch(apiError(true));
    }
  };

export const deleteSuperAdmin =
  (userId: any) => async (dispatch: Dispatch<any>) => {
    try {
      const res = await api.deleteAdminUser(userId, Roles.superAdmin);
      if (res.data) {
        dispatch(deleteSuperAdminSuccess(true));
      } else {
        dispatch(apiError(true));
      }
    } catch (e) {
      dispatch(apiError(true));
    }
  };

const userManagementModel = new UserManagementModel();
const userManagementReducer = (
  state: UserManagementState = userManagementModel.initialState(),
  action: any
): UserManagementState => {
  switch (action.type) {
    case ACTIONS.SUPER_ADMIN_LOADING:
      return userManagementModel.setLoading(action.payload);
    case ACTIONS.SUPER_ADMIN_RESULTS:
      return userManagementModel.setResults(action.payload);
    case ACTIONS.USER_LIST_DATA:
      return userManagementModel.setUserListData(action.payload);
    case ACTIONS.ADD_SUPER_ADMIN_SUCCESS:
      return userManagementModel.setAddSuperAdminSuccess(action.payload);
    case ACTIONS.API_ERROR:
      return userManagementModel.setApiError(action.payload);
    case ACTIONS.DELETE_SUPER_ADMIN_SUCCESS:
      return userManagementModel.setDeleteSuperAdminSuccess(action.payload);
    default:
      return state;
  }
};

export default userManagementReducer;
