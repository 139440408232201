import classNames from "classnames";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";
import { useDispatch } from "react-redux";
import { INSIGHT_BENCHMARK_TYPE, lightColor, textColor } from "utils/constants";
import {
  isNumeric,
  mod,
  handleMouseEnter,
  handleMouseLeave,
} from "utils/functions";

const BatteryChart = ({
  value,
  minValue,
  maxValue,
  footer,
  isBaseCompany,
  benchmarkType,
  colorCodes,
  companyTooltip,
}: {
  value: number;
  minValue: number;
  maxValue: number;
  footer: string;
  isBaseCompany: boolean;
  benchmarkType: number;
  colorCodes: any;
  companyTooltip?: string | undefined;
}) => {
  const BLOCK = "battery-chart";
  const dispatch = useDispatch();
  const floorValue = Math.floor(value);
  const decimalValue = parseFloat(mod(value, 1).toFixed(1));
  const batteryColorCode = isBaseCompany ? colorCodes[0] : colorCodes[1];
  const valueColorCode = isNumeric(value)
    ? isBaseCompany
      ? colorCodes[2]
      : colorCodes[1]
    : lightColor;
  const valuePosition =
    benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
      ? "right"
      : isBaseCompany
      ? "left"
      : "right";

  return (
    <>
      <div
        className={classNames(
          `${BLOCK}__content`,
          benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY
            ? `${BLOCK}__content--${valuePosition}`
            : ""
        )}
        data-testid={BLOCK}
      >
        <div className={`${BLOCK}__container`}>
          <div className={BLOCK} style={{ clipPath: `url(#battery)` }}>
            <div
              className={`${BLOCK}__border`}
              style={{ clipPath: `url(#battery)` }}
            >
              <div className={`${BLOCK}__data-container`}>
                {Array.from({ length: maxValue }, (_, i) => (
                  <div
                    key={`data-block-${i}`}
                    className={`${BLOCK}__data`}
                    style={{
                      height: `${100 / maxValue}%`,
                      background: isNumeric(value)
                        ? i >= floorValue && i <= value
                          ? `linear-gradient(90deg, ${batteryColorCode} ${
                              decimalValue * 100
                            }%, #FFF ${decimalValue * 100}%)`
                          : i < floorValue
                          ? batteryColorCode
                          : ""
                        : lightColor,
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <svg width="0" height="0">
            <defs>
              <clipPath id="battery" clipPathUnits="objectBoundingBox">
                <path d="M 0.3365 0.9702 C 0.3365 0.9858 0.3528 0.9984 0.3731 0.9984 L 0.6295 0.9984 C 0.6496 0.9984 0.666 0.9858 0.666 0.9702 L 0.666 0.0582 C 0.666 0.0427 0.6496 0.0301 0.6295 0.0301 L 0.5562 0.0301 C 0.5562 0.03 0.5562 0.0299 0.5562 0.0298 L 0.5562 0.0157 C 0.5562 0.0079 0.548 0.0016 0.5378 0.0016 L 0.4692 0.0016 C 0.4591 0.0016 0.4509 0.0079 0.4509 0.0157 L 0.4509 0.0298 C 0.4509 0.0299 0.4509 0.03 0.4509 0.0301 L 0.3731 0.0301 C 0.3528 0.0301 0.3365 0.0427 0.3365 0.0582 L 0.3365 0.9702 Z" />
              </clipPath>
            </defs>
          </svg>
          <div>
            <span
              style={{
                top: `${
                  ((maxValue - floorValue + (decimalValue <= 0.01 ? 1 : 0)) /
                    maxValue) *
                    100 +
                  (value < 0.05 ? -10 : floorValue / 1.3)
                }%`,
                background: valueColorCode,
              }}
              className={classNames(
                `${BLOCK}__value`,
                valuePosition ? `${BLOCK}__value--${valuePosition}` : ""
              )}
            >
              <span
                className={classNames(
                  `${BLOCK}__join-line`,
                  valuePosition ? `${BLOCK}__join-line--${valuePosition}` : ""
                )}
              ></span>
              <span
                className={classNames(
                  `${BLOCK}__value-indicator`,
                  valuePosition
                    ? `${BLOCK}__value-indicator--${valuePosition}`
                    : ""
                )}
                style={{ background: valueColorCode }}
                data-testid={`${BLOCK}__value-indicator`}
              ></span>
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 500 100"
                preserveAspectRatio="xMinYmin meet"
                data-testid={`${BLOCK}__value-svg`}
                onMouseEnter={(e: any) => {
                  handleMouseEnter(
                    e,
                    "span",
                    <div>
                      {companyTooltip +
                        " (" +
                        (isNumeric(value) ? value.toFixed(1) : "*") +
                        ")"}
                    </div>,
                    "",
                    dispatch,
                    null,
                    MainTooltipPosition.TopRight
                  );
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e, dispatch, false);
                }}
              >
                {isNumeric(value) ? (
                  <text x={value >= maxValue ? "0%" : "15%"} y="130%">
                    {value.toFixed(1)}
                  </text>
                ) : (
                  <text x="35%" y="160%" style={{ fill: textColor }}>
                    *
                  </text>
                )}
              </svg>
            </span>
          </div>
        </div>
        {footer ? <div className={`${BLOCK}__footer`} data-testid={`${BLOCK}__footer`}>{footer}</div> : null}
      </div>
    </>
  );
};

export default BatteryChart;
