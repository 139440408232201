import { TableColumnItem } from "./table";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "../checkbox/checkbox";
import Icon from "../icon/icon";
import FormattedMessage from "../formatted-message/formatted-message";
import { showTooltip } from "services/commons.service";
import { TooltipPosition } from "../tooltip/tooltip";
import { RootStore } from "services/store.service";
import Button from "../button/button";
import { useLocation } from "react-router-dom";

const TableCell = ({
  id,
  icon,
  iconSize,
  iconTooltip,
  text,
  textTooltip,
  subTitle,
  isFormattedText,
  renderContent,
  className,
  onClick,
  type,
  wrapperClassName,
  hidden,
  checkbox,
  noteId,
  commentNodeID,
  filterContent,
  updateSelected,
  isSelected,
  setShowNotes,
  tableColumnClassName,
  tableRowClassName,
}: TableColumnItem) => {
  const BLOCK = "Table";
  const dispatch = useDispatch();
  const cellPadding = 35;
  const [delay, setDelay] = useState<any>(null);
  const commonsState = useSelector((state: RootStore) => state.commons);
  const location = useLocation();

  useEffect(() => {
    handleMouseLeave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleMouseOver = (e: any) => {
    setDelay(
      setTimeout(() => {
        const tdElement = e.target.closest("td");
        const valueElement = tdElement
          ? tdElement.querySelector(`.compact`)
          : null;
        if (!valueElement || !tdElement) {
          return;
        }

        if (
          tdElement.getBoundingClientRect().width - cellPadding <=
          valueElement.getBoundingClientRect().width
        ) {
          const toolTipState = {
            children: <>{text || valueElement.innerText}</>,
            position:
              className && className.includes("__column-company--source")
                ? TooltipPosition.tableTopLeft
                : TooltipPosition.topRight,
            className: className,
            customPosition: true,
            arrowPosition: {
              top: `${10}px`,
            },
            elementDimensions: valueElement.getBoundingClientRect(),
            executeMouseLeaveEvent: true,
          };
          dispatch(showTooltip(toolTipState));
        }
      }, 400)
    );
  };

  const handleMouseLeave = () => {
    clearTimeout(delay);

    if (
      commonsState &&
      commonsState.toolTip.elementDimensions &&
      !commonsState.toolTip.isOverTooltip
    ) {
      dispatch(
        showTooltip({
          children: null,
          customPosition: null,
          position: null,
          arrowPosition: null,
          elementDimensions: null,
          executeMouseLeaveEvent: false,
          isOverTooltip: null,
        })
      );
    }
  };

  return (
    <>
      {hidden ? null : (
        <td
          className={classNames(`${BLOCK}__column`, {
            [`${BLOCK}__clickable`]: onClick,
            [`${wrapperClassName}`]: wrapperClassName,
          })}
          onClick={onClick}
          data-test={className ? className : ""}
          onMouseEnter={(e: any) =>
            type !== "Peer-Benchmark" && handleMouseOver(e)
          }
          onMouseLeave={handleMouseLeave}
          data-testid="table-cell"
        >
          <div
            className={classNames(`${BLOCK}__column-content`, {
              [`${BLOCK}__text-icon`]: text && icon,
              [`${className}`]: className,
            })}
          >
            {renderContent ? (
              renderContent()
            ) : (
              <>
                {checkbox &&
                  (type === "shortlist" ||
                    type === "esg" ||
                    (type === "sec" && filterContent)) && (
                    <div className={`${BLOCK}__checkbox-container`}>
                      {
                        <Checkbox
                          value={isSelected || false}
                          onChange={() => {
                            const updatedRows = updateSelected
                              ? updateSelected()
                              : [];
                            checkbox.onChange(updatedRows);
                          }}
                        />
                      }
                    </div>
                  )}
                {noteId && type === "shortlist" && (
                  <Button
                    className={`${BLOCK}__shortlist-note-button`}
                    dataTest="shortlist-note-button"
                    onClick={() => {
                      setShowNotes &&
                        setShowNotes(() => [
                          {
                            id: id,
                            show: true,
                            noteId: commentNodeID,
                          },
                        ]);
                    }}
                    iconName={commentNodeID ? "commenting2" : "commenting-plus"}
                  />
                )}
                {icon &&
                  (type === "esg" || (type === "sec" && filterContent)) && (
                    <Icon
                      name={icon}
                      height={iconSize}
                      width={iconSize}
                      className={text ? `${BLOCK}__content-icon` : ""}
                    />
                  )}
                {iconTooltip}
                <div className={`${BLOCK}__text-container`}>
                  {subTitle && (
                    <div className={`${BLOCK}__column-subtitle`}>
                      {isFormattedText ? (
                        <FormattedMessage id={subTitle} />
                      ) : (
                        subTitle
                      )}
                    </div>
                  )}
                  {text && (
                    <div
                      className={`${BLOCK}__column-text compact ${tableRowClassName} ${tableColumnClassName}`}
                    >
                      {textTooltip}
                      {isFormattedText ? (
                        <div className={`${BLOCK}__column-text compact`}>
                          <FormattedMessage id={text} />
                        </div>
                      ) : (
                        text
                      )}
                    </div>
                  )}
                </div>
                {icon && type === "shortlist" && (
                  <Icon
                    name={icon}
                    height={iconSize}
                    width={iconSize}
                    className={text ? `${BLOCK}__content-icon` : ""}
                  />
                )}
              </>
            )}
          </div>
        </td>
      )}
    </>
  );
};

export default TableCell;
