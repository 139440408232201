import Icon from "components/shared/icon/icon";
import { useEffect, useState } from "react";

type Props = {
  changePageFunction: any;
  changeZoomFunction: any;
  currentPage: number;
  numPages: number;
  zoom: number;
  onRotate: any;
};

const DocumentControls = ({
  changePageFunction,
  changeZoomFunction,
  currentPage,
  numPages,
  zoom,
  onRotate,
}: Props) => {
  const [page, setPage] = useState(currentPage);
  const [zoomLevel, setZoomLevel] = useState(zoom);
  const zoomLevels = [0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 4.0];

  useEffect(() => {
    setZoomLevel(zoom);
  }, [zoom]);

  useEffect(() => {
    if (currentPage !== page) setPage(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const pageTextChange = (e: any) => {
    if (/^(\s*|\d+)$/.test(e.target.value)) {
      setPage(e.target.value);
      if (e.target.value > 0) changePageFunction(e.target.value);
    }
  };

  const zoomTextChange = (e: any) => {
    if (/^(\s*|\d+)$/.test(e.target.value)) {
      setZoomLevel(e.target.value / 100);
      if (e.target.value > 0) changeZoomFunction(e.target.value / 100);
    }
  };

  const pageKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      if (0 < page && page <= numPages) {
        changePageFunction(page);
      }
    } else if (e.keyCode === 27) {
      resetPage();
    }
  };

  const zoomKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      if (
        zoomLevels[0] <= zoomLevel &&
        zoomLevel <= zoomLevels[zoomLevels.length - 1]
      ) {
        changeZoomFunction(zoomLevel);
      } else {
        resetZoom();
      }
    } else if (e.keyCode === 27) {
      resetZoom();
    }
  };

  const resetPage = () => {
    setPage(currentPage);
  };

  const resetZoom = () => {
    setZoomLevel(zoom);
  };

  const previousZoomLevel = () => {
    var rzoom = zoomLevels.slice().reverse();
    var index = rzoom.findIndex((n) => n < zoomLevel);
    if (index !== -1) {
      changeZoomFunction(rzoom[index]);
      setZoomLevel(rzoom[index]);
    }
  };

  const nextZoomLevel = () => {
    var index = zoomLevels.findIndex((n) => n > zoomLevel);
    if (index !== -1) {
      changeZoomFunction(zoomLevels[index]);
      setZoomLevel(zoomLevels[index]);
    }
  };

  return numPages && numPages > 0 && currentPage ? (
    <div className="document-controls" data-testid="document-controls">
      <div className="page-controls">
        <button
          id="previous-page-button"
          disabled={currentPage <= 1}
          onClick={() => {
            const newPage = parseInt(`${currentPage}`) - 1;
            changePageFunction(newPage);
            setPage(newPage);
          }}
          data-testid="previous-page-button"
        >
          <Icon name="chevron-left" height={20} width={20} />
        </button>
        <input
          id="currentPage"
          type="text"
          onBlur={resetPage}
          onChange={pageTextChange}
          onKeyDown={pageKeyDown}
          value={page}
          data-testid="currentPage"
        />
        <span className="page-count">/ {numPages}</span>
        <button
          id="next-page-button"
          disabled={currentPage >= numPages || numPages <= 1}
          onClick={() => {
            const newPage = parseInt(`${currentPage}`) + 1;
            changePageFunction(newPage);
            setPage(newPage);
          }}
          data-testid="next-page-button"
        >
          <Icon name="chevron-right" height={20} width={20} />
        </button>
      </div>

      <div className="zoom-controls">
        <button
          id="zoom-out-button"
          disabled={zoomLevel / 100 === zoomLevels[0]}
          onClick={previousZoomLevel}
          data-testid="zoom-out-button"
        >
          <Icon name="chevron-minus" height={12} width={12} />
        </button>
        <input
          id="currentZoom"
          type="text"
          onBlur={resetZoom}
          onChange={zoomTextChange}
          onKeyDown={zoomKeyDown}
          value={zoomLevel > 0 ? Math.floor(zoomLevel * 100) : ""}
          data-testid="currentZoom"
        />
        %
        <button
          id="zoom-in-button"
          disabled={zoomLevel / 100 === zoomLevels[zoomLevels.length - 1]}
          onClick={nextZoomLevel}
          data-testid="zoom-in-button"
        >
          <Icon name="chevron-plus" height={12} width={12} />
        </button>
      </div>

      <div className="zoom-controls">
        <button onClick={onRotate}>
          <Icon name="rotate" width={20} height={20} />
        </button>
      </div>
    </div>
  ) : null;
};

export default DocumentControls;
