import { Fragment, useMemo } from "react";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { scaleBand, scaleLinear } from "@visx/scale";
import { withTooltip, defaultStyles } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";

import { ScaleSVG } from "@visx/responsive";
import { lightColor, subTextColor } from "utils/constants";
import { Legend } from "services/dashboard/dashboard.model";
import { isInteger } from "lodash";
import { Text } from "@visx/text";
import { LinearGradient } from "@visx/gradient";
import FormattedMessage from "components/shared/formatted-message/formatted-message";

const axisBaseOffset = -0.25;
const axisOffsetMultiplier = -0.45;

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 50,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
  fontSize: 15,
};

type Gradient = {
  colorCodes: string[];
  grouped: boolean;
  gradientId: string;
};

type Props = {
  data: Legend[];
  name: string;
  width?: number;
  height?: number;
  axis?: { primary: string[]; secondary: string[] };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  gradient?: Gradient;
  bottomText?: boolean;
};

type GraphProps = {
  data: Legend[];
  name: string;
  width: number;
  height: number;
  axis?: { primary: string[]; secondary: string[] };
  margin?: { top: number; right: number; bottom: number; left: number };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  gradient?: Gradient;
  bottomText?: boolean;
};

const renderGradient = (id: string, colors: string[]) => {
  let tmpColors = [...colors].reverse();
  return tmpColors.map(
    (color: string, index: number) =>
      index < colors.length - 1 && (
        <LinearGradient
          key={`linear-gradient-${index}`}
          id={`${id}-${index + 1}`}
          from={color}
          to={tmpColors[0]}
        />
      )
  );
};

const getGroupedGradientIndex = (
  maxValue: number,
  value: number,
  count: number
) => {
  const percentage = (value * 100) / maxValue;
  const range = 100 / (count - 1);
  for (let i = 1; i < count; i++) {
    if (i * range - range <= percentage && i * range > percentage) {
      return i;
    }
  }
  return 5;
};

const UngoupedBarChart = ({
  data,
  axis,
  name,
  width,
  height,
  margin = { top: 5, right: 15, bottom: 20, left: 68 },
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
  handleGraphElementClicked,
  gradient,
  bottomText,
}: GraphProps & WithTooltipProvidedProps<Legend>) => {
  const dataset = (data as Legend[]).filter((d) => d.legendData > 0);
  let tooltipTimeout: number;
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const yMaxLegend = axis?.primary.length
    ? axis?.primary.length
    : Math.max(...dataset.map((d) => d.legendData ?? 0));

  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: dataset.map((d) => d.legendValue),
        padding: 0.3,
      }),
    [xMax, dataset]
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        nice: true,
        clamp: true,
        domain: [0, yMaxLegend],
      }),
    [yMax, yMaxLegend]
  );

  return (
    <div
      className="limit-size vertical-bar-chart__container"
      style={{ position: "relative" }}
    >
      <ScaleSVG width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <GridRows
            scale={yScale}
            width={xMax}
            height={yMax}
            stroke={lightColor}
          />
          <AxisLeft
            strokeWidth={1}
            scale={yScale}
            stroke={lightColor}
            tickStroke={lightColor}
            tickValues={yScale.ticks().filter(isInteger)}
            tickFormat={(tick: any) =>
              parseInt(tick.toFixed()).toLocaleString()
            }
            tickComponent={(tickProps) => {
              const valueLength = tickProps.formattedValue
                ? tickProps.formattedValue.length
                : 1;

              const dx = axisBaseOffset + valueLength * axisOffsetMultiplier;

              return (
                <text
                  fontFamily="Open Sans"
                  fontSize={12}
                  textAnchor="start"
                  dx={`${dx}em`}
                  dy={tickProps.dy}
                  x={tickProps.x}
                  y={tickProps.y}
                >
                  <tspan x={tickProps.x} dy="0.4em">
                    {tickProps.formattedValue}
                  </tspan>
                </text>
              );
            }}
          />
          {dataset
            .sort((d1, d2) => d1.legendOrder - d2.legendOrder)
            .map((data, i) => {
              const barWidth = xScale.bandwidth();

              let barHeight: any =
                data.legendData > 0
                  ? yMax - (yScale(data.legendData ?? 0.1) ?? 0)
                  : 0;

              barHeight =
                barHeight <= 1 && parseFloat(data.legendData) > 0
                  ? 1
                  : barHeight;
              const barX: any = xScale(data.legendValue) || 0;
              const barY = yMax - barHeight;

              const sectionSpacing =
                (xMax - barWidth * dataset.length) / (dataset.length + 1);

              const sectionWidth = sectionSpacing + barWidth;

              const sectionXpos = (i + 1) * sectionWidth - barWidth;

              return barX >= 0 &&
                barY >= 0 &&
                barWidth >= 0 &&
                barHeight >= 0 ? (
                <Fragment key={`vertical-bar-${i}`}>
                  <Bar
                    data-test={`bar-${data.legendData}`}
                    key={`bar-${data.legendValue}-${i}`}
                    x={barX}
                    y={barY}
                    width={barWidth}
                    height={barHeight}
                    style={
                      handleGraphElementClicked ? { cursor: "pointer" } : {}
                    }
                    fillOpacity={
                      tooltipOpen &&
                      tooltipData?.legendValue !== data.legendValue
                        ? 0.4
                        : 1
                    }
                    fill={
                      gradient
                        ? `url(#${
                            gradient.gradientId
                          }-${getGroupedGradientIndex(
                            yMaxLegend,
                            data.legendData,
                            gradient.colorCodes.length
                          )})`
                        : data.legendColor
                    }
                    onMouseLeave={() =>
                      (tooltipTimeout = window.setTimeout(() => {
                        hideTooltip();
                      }, 100))
                    }
                    onClick={() =>
                      handleGraphElementClicked
                        ? handleGraphElementClicked(data)
                        : null
                    }
                    onMouseMove={() => {
                      if (tooltipTimeout) clearTimeout(tooltipTimeout);
                      showTooltip({
                        tooltipData: data,
                        tooltipTop: barY,
                        tooltipLeft: barX,
                      });
                    }}
                  ></Bar>
                  {/* TO DO: ADJUST TEXT POSITION IN AXIS X */}
                  {bottomText && (
                    <Text
                      textAnchor="middle"
                      verticalAnchor="start"
                      fill={subTextColor}
                      fontSize={12}
                      letterSpacing={-0.5}
                      width={sectionWidth}
                      x={sectionXpos + barWidth / 2}
                      dy={yMax + 10}
                      fontWeight="600"
                    >
                      {data.legendValue}
                    </Text>
                  )}
                </Fragment>
              ) : null;
            })}

          {gradient &&
            gradient.grouped &&
            renderGradient(gradient.gradientId, gradient.colorCodes)}
        </Group>
      </ScaleSVG>
      {tooltipOpen && tooltipData && (
        <div
          style={{
            ...tooltipStyles,
            top: tooltipTop,
            left: tooltipLeft,
            position: "absolute",
          }}
        >
          <strong>
            {tooltipData.legendTooltip &&
              (tooltipData.legendTooltip as React.ReactNode)}{" "}
            {parseInt(tooltipData.legendData ?? 0).toLocaleString()}
          </strong>
        </div>
      )}
    </div>
  );
};

const VerticalBarChart = ({
  data,
  name,
  axis,
  width = 1000,
  height = 1000,
  handleGraphElementClicked,
  gradient = undefined,
  bottomText = false,
}: Props) => {
  if (data.length === 0)
    return (
      <div className="cdpScope3CategoriesReported__no-data">
        <FormattedMessage id="insights.speedometer.no-data" />
      </div>
    );

  const Bar = withTooltip<GraphProps, Legend>(UngoupedBarChart);

  return Bar({
    data,
    name,
    axis,
    width,
    height,
    handleGraphElementClicked,
    gradient,
    bottomText,
  });
};

export default VerticalBarChart;
