import Button from "components/shared/button/button";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import SidePanel from "components/shared/side-panel/side-panel";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToastMessage } from "services/commons.service";
import {
  saveRequestUploadESGReport,
  setRequestUploadReportSuccess,
  setShowRequestUploadPanel,
} from "services/search/search.results.service";
import { RootStore } from "services/store.service";

const RequestUploadPanel = () => {
  const BLOCK = "RequestUploadPanel";
  const dispatch = useDispatch();
  const searchResultsState = useSelector(
    (state: RootStore) => state.searchResults
  );
  const [counts, setCounts] = useState({
    cikNumber: 0,
    companyName: 0,
    additionalDetails: 0,
  });
  const [values, setValues] = useState({
    cikNumber: "",
    companyName: "",
    additionalDetails: "",
  });
  const [disabled, setDisabled] = useState(true);
  const trimStartFunc = (value: any) => {
    if (!value) return value;
    return value.replace(/^\s+/g, "");
  };
  const onChangeHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCounts({
      ...counts,
      [event.target.name]: trimStartFunc(event.target.value).length,
    });
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "companyName") {
      setDisabled(event.target.value.trim().length === 0);
    }
  };

  const saveRequestUploadHandler = () => {
    const payload = {
      cikNumber: values.cikNumber,
      companyName: values.companyName,
      additionalDetails: values.additionalDetails,
    };

    dispatch(saveRequestUploadESGReport(payload));
  };

  useEffect(() => {
    if (searchResultsState.requestUploadReportSuccess) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage id="search.request.upload.success.msg" />
          ),
        })
      );
      dispatch(setShowRequestUploadPanel(false));
      dispatch(setRequestUploadReportSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResultsState.requestUploadReportSuccess]);

  return (
    <SidePanel
      showPanel={true}
      className={`${BLOCK}`}
      headerText="search.request.panel.title"
      handleClose={() => dispatch(setShowRequestUploadPanel(false))}
    >
      <>
        <div className={`${BLOCK}__body`}>
          <div className={`${BLOCK}__field-wrapper`}>
            <span className={`${BLOCK}__field-label`}>
              <FormattedMessage id="search.request.panel.cik.no" />
            </span>
            <input
              className={`${BLOCK}__field`}
              type="text"
              placeholder="Enter CIK Number"
              maxLength={100}
              onChange={onChangeHandler}
              value={values.cikNumber}
              name="cikNumber"
              autoComplete="off"
              data-test="cikField"
            />
            <span className={`${BLOCK}__character-limit`}>
              {counts.cikNumber}
              <FormattedMessage id="search.cik.company.character.limit" />
            </span>
          </div>
          <div className={`${BLOCK}__field-wrapper`}>
            <span className={`${BLOCK}__field-label`}>
              <FormattedMessage id="search.request.panel.company.name" />
            </span>
            <input
              className={`${BLOCK}__field`}
              type="text"
              placeholder="Enter Company Name"
              maxLength={100}
              onChange={onChangeHandler}
              value={values.companyName}
              name="companyName"
              autoComplete="off"
              data-test="companyField"
            />
            <span className={`${BLOCK}__character-limit`}>
              {counts.companyName}
              <FormattedMessage id="search.cik.company.character.limit" />
            </span>
          </div>
          <div className={`${BLOCK}__field-wrapper`}>
            <span className={`${BLOCK}__field-label`}>
              <FormattedMessage id="search.request.panel.additional.details" />
            </span>
            <textarea
              className={`${BLOCK}__field-textarea`}
              placeholder="Enter URL link(s) of report(s) or/and more details about report(s)."
              maxLength={2000}
              onChange={onChangeHandler}
              value={values.additionalDetails}
              name="additionalDetails"
              autoComplete="off"
              data-test="additionalField"
            />
            <span className={`${BLOCK}__character-limit`}>
              {counts.additionalDetails}
              <FormattedMessage id="search.additional.details.character.limit" />
            </span>
          </div>
        </div>
        <div className={`${BLOCK}__footer`}>
          <Button
            className={`button-secondary`}
            data-test="cancel-btn"
            onClick={() => dispatch(setShowRequestUploadPanel(false))}
            formattedText={`search.request.panel.cancel.btn`}
          />
          <Button
            className={`button-primary`}
            data-test="submit-btn"
            disabled={disabled}
            onClick={saveRequestUploadHandler}
            formattedText={`search.submit.request.btn`}
          />
        </div>
      </>
    </SidePanel>
  );
};

export default RequestUploadPanel;
