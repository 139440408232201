import TRACKING_ACTIONS from "./actions";
import updateDataLayer from "./data-layer";

declare global {
  interface Window {
    _satellite: any;
  }
}

// Main satellite function that updates the digital data layer and calls the DCRs
const satelliteTrack = (eventType: string, action: any, state: any) => {
  // ensure the variable is set
  if (
    window._satellite &&
    window._satellite.track &&
    window.localStorage.getItem("userID") &&
    window.digitalData?.user[0]?.profile[0]?.profileInfo?.userName
  ) {
    window._satellite.track(TRACKING_ACTIONS[eventType].dcr);
  } else {
    setTimeout(() => {
      updateDataLayer(eventType, action, state);
      satelliteTrack(eventType, action, state);
    }, 1000);
  }
};

const track = (args: any, action?: any, state?: any) => {
  satelliteTrack(args, action, state);
};

export default track;
