import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { CdpScope3CategoriesStatus } from "services/insights/insights.model";
import TileEmptyContent from "../tile-empty-content";
import { INSIGHT_BENCHMARK_TYPE, NEUTRAL_VALUES } from "utils/constants";
import Popover from "components/shared/popover/popover";
import Parser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";

type Label = {
  id: string;
  label: string;
  tooltip?: any;
};

type TableData = {
  labels: Label[];
  response: any;
  tileType: number;
  categoryName: string;
};
type Props = {
  reportedEmission: CompanyReportedRelevance;
  reportedRelevance: CompanyReportedRelevance;
  reportedEmissionPeerInd: TableData;
  reportedRelevancePeerInd: TableData;
  benchmarkType: number;
  isDetailedView: boolean;
  isTableViewActive: boolean;
  associatedMetricGroupTooltips?: AssociatedMetricTooltips[];
};

type CompanyReportedRelevance = {
  data: CdpScope3CategoriesStatus[];
  categoryName: string;
};

const CDPScope3Table = ({
  reportedEmission,
  reportedRelevance,
  benchmarkType,
  reportedEmissionPeerInd,
  reportedRelevancePeerInd,
  isDetailedView,
  isTableViewActive,
  associatedMetricGroupTooltips,
}: Props) => {
  const BLOCK = "cdpScope3CategoriesStatus";
  const mainTooltip = useSelector(
    (store: RootStore) => store.commons.mainTooltip
  );
  const dispatch = useDispatch();

  const getNeutralTooltip = (value: any) =>
    value && NEUTRAL_VALUES.includes(value) ? (
      <FormattedMessage id="insights.tabular.neutral" />
    ) : null;

  const hasAllMissingData = (data: TableData) => {
    const hasNoData = data?.labels?.reduce(
      (acc: any, l: any) =>
        acc &&
        data.response.filter(
          (d: any) =>
            d[l.id] === null ||
            d[l.id] === undefined ||
            d[l.id] === "*" ||
            d[l.id] === ""
        ).length === data.response.length,
      true
    );
    return hasNoData;
  };

  const hasSomeMissingData = (data: TableData) => {
    const hasMissingData = data?.labels?.reduce(
      (acc: any, l: any) =>
        acc ||
        data.response.filter(
          (d: any) =>
            d[l.id] === null ||
            d[l.id] === undefined ||
            d[l.id] === "*" ||
            d[l.id] === ""
        ).length > 0,
      false
    );
    return hasMissingData;
  };

  const hasNoData =
    hasAllMissingData(reportedEmissionPeerInd) &&
    hasAllMissingData(reportedRelevancePeerInd);

  const hasMissingData =
    (!hasAllMissingData(reportedEmissionPeerInd) &&
      hasSomeMissingData(reportedEmissionPeerInd)) ||
    (!hasAllMissingData(reportedRelevancePeerInd) &&
      hasSomeMissingData(reportedRelevancePeerInd));

  const renderCompanyView = (
    data: CdpScope3CategoriesStatus[],
    relevance?: boolean
  ) => {
    return (
      <div className={`${BLOCK}__category`}>
        <div className={`${BLOCK}__category-container`}>
          {data.map((categories: CdpScope3CategoriesStatus, ind: number) => (
            <div
              key={`category-status-${ind}`}
              className={`${BLOCK}__category-detail`}
            >
              <span className={`${BLOCK}__category-detail--name`}>
                {categories.categoryName}
              </span>
              <div className={`${BLOCK}__category-detail--value`}>
                <span
                  className={classNames(``, {
                    [`${BLOCK}__category-detail--noData`]:
                      categories.categoryValue === "",
                  })}
                >
                  {categories.categoryValue ? (
                    !relevance ? (
                      new Intl.NumberFormat("en-US").format(
                        parseInt(categories.categoryValue)
                      )
                    ) : (
                      categories.categoryValue
                    )
                  ) : (
                    <FormattedMessage id="no.data" />
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const getCompanyTable = () => {
    return (
      <div className={`${BLOCK}`} data-test="company-table">
        <Popover
          metricClassName={``}
          buttonClassName={`${BLOCK}__heading`}
          displayText={reportedEmission.categoryName}
          content={
            associatedMetricGroupTooltips
              ? associatedMetricGroupTooltips.filter(
                  (tooltip: AssociatedMetricTooltips) =>
                    tooltip.associatedMetricGroupName ===
                    reportedEmission.categoryName
                )
              : []
          }
        />

        {reportedEmission?.data.length > 0 ? (
          renderCompanyView(reportedEmission.data)
        ) : (
          <TileEmptyContent />
        )}
        <Popover
          metricClassName={``}
          buttonClassName={`${BLOCK}__heading`}
          displayText={reportedRelevance.categoryName}
          content={
            associatedMetricGroupTooltips
              ? associatedMetricGroupTooltips.filter(
                  (tooltip: AssociatedMetricTooltips) =>
                    tooltip.associatedMetricGroupName ===
                    reportedRelevance.categoryName
                )
              : []
          }
        />
        {reportedRelevance.data?.length > 0 ? (
          renderCompanyView(reportedRelevance.data, true)
        ) : (
          <TileEmptyContent />
        )}
      </div>
    );
  };
  const renderPeerIndustryView = (
    peerIndData: TableData,
    relevance?: boolean
  ) => {
    const BLOCK = "cdpScope3CategoriesStatusPeer";
    return (
      <>
        <table
          className={classNames(`${BLOCK}__table`, {
            [` ${BLOCK}__table-detailView`]:
              isDetailedView && !isTableViewActive,
          })}
        >
          {peerIndData.response.length > 1 && (
            <thead
              className={classNames(`${BLOCK}__table-header`, {
                [` ${BLOCK}__table-header--detailView`]:
                  isDetailedView && !isTableViewActive,
              })}
            >
              {/* Values & companies */}
              <tr>
                <th>
                  {" "}
                  <FormattedMessage
                    id={
                      relevance
                        ? "all.categories.scope3"
                        : isTableViewActive
                        ? "insights.categories"
                        : "reported.categories"
                    }
                  />
                </th>
                {peerIndData.response.map((comp: any, i: number) => (
                  <th
                    key={`table-header-${i}`}
                    className={classNames(`${BLOCK}__table-header-label`)}
                  >
                    {comp.header}
                  </th>
                ))}
              </tr>
            </thead>
          )}

          <tbody
            className={classNames(`${BLOCK}__table-body`, {
              [` ${BLOCK}__table-body-cell--border-top`]:
                peerIndData.response.length === 1,
              [` ${BLOCK}__table-body-detailView`]:
                isDetailedView && !isTableViewActive,
            })}
          >
            {/* Metrics */}
            {peerIndData.labels.map((l: Label, ind: number) => (
              <tr key={`table-body-${ind}`}>
                <td
                  style={{
                    width: `calc( (100%) / ${
                      peerIndData.response.length + 1
                    } )`,
                  }}
                >
                  <span className={`${BLOCK}__fit-content`}>
                    <Popover
                      metricClassName={classNames(
                        `${BLOCK}__table-body-value ${BLOCK}__table-body-value--left`
                      )}
                      displayText={<span>{Parser(l.label ?? "")}</span>}
                      content={l.tooltip ? l.tooltip : []}
                      buttonClassName={`${BLOCK}__label-button ${BLOCK}__text--line-height`}
                    />
                  </span>
                </td>
                {peerIndData.response.map((d: any, index: number) => (
                  <td
                    className={classNames(
                      `${BLOCK}__table-body-value ${BLOCK}__table-body-value--bold`
                    )}
                    key={`table-data-${index}`}
                  >
                    <div className={`${BLOCK}__table-body-value--container`}>
                      <span
                        data-test="neutral-tooltip"
                        onMouseEnter={(e) => {
                          let tmpTooltip = getNeutralTooltip(d[l.id]);
                          if (tmpTooltip)
                            handleMouseEnter(
                              e,
                              "span",
                              <div>{tmpTooltip}</div>,
                              "",
                              dispatch,
                              null,
                              MainTooltipPosition.BottomMiddle
                            );
                        }}
                        onMouseLeave={(e) => {
                          let tmpTooltip = getNeutralTooltip(d[l.id]);

                          if (tmpTooltip)
                            handleMouseLeave(
                              e,
                              dispatch,
                              mainTooltip.isOverTooltip
                                ? mainTooltip.isOverTooltip
                                : false
                            );
                        }}
                      >
                        {d[l.id] && d[l.id] !== ""
                          ? relevance
                            ? d[l.id]
                            : new Intl.NumberFormat("en-US").format(
                                parseInt(d[l.id])
                              )
                          : "*"}
                      </span>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };
  const getPeerIndTable = () => {
    return (
      <div className={`${BLOCK}`} data-test="peer-ind-table">
        <div className={`cdpScope3CategoriesStatus__metricWrapper`}>
          <Popover
            metricClassName={``}
            buttonClassName={`${BLOCK}__heading`}
            displayText={reportedEmissionPeerInd.categoryName}
            content={
              associatedMetricGroupTooltips
                ? associatedMetricGroupTooltips.filter(
                    (tooltip: AssociatedMetricTooltips) =>
                      tooltip.associatedMetricGroupName ===
                      reportedEmissionPeerInd.categoryName
                  )
                : []
            }
          />
        </div>

        {reportedEmissionPeerInd?.response.length > 0 &&
        !hasAllMissingData(reportedEmissionPeerInd) ? (
          renderPeerIndustryView(reportedEmissionPeerInd)
        ) : (
          <TileEmptyContent />
        )}

        <div className={`cdpScope3CategoriesStatus__metricWrapper`}>
          <Popover
            metricClassName={``}
            buttonClassName={`${BLOCK}__heading`}
            displayText={reportedRelevancePeerInd.categoryName}
            content={
              associatedMetricGroupTooltips
                ? associatedMetricGroupTooltips.filter(
                    (tooltip: AssociatedMetricTooltips) =>
                      tooltip.associatedMetricGroupName ===
                      reportedRelevancePeerInd.categoryName
                  )
                : []
            }
          />
        </div>
        {reportedRelevancePeerInd?.response.length > 0 &&
        !hasAllMissingData(reportedRelevancePeerInd) ? (
          renderPeerIndustryView(reportedRelevancePeerInd, true)
        ) : (
          <TileEmptyContent />
        )}
        {hasMissingData && !hasNoData && (
          <div className={"DashboardTile__noDataAny"}>
            <FormattedMessage id="no.data.available" />
          </div>
        )}
      </div>
    );
  };
  return benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
    ? getCompanyTable()
    : getPeerIndTable();
};

export default CDPScope3Table;
