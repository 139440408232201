import { Group } from "@visx/group";
import React, { Fragment, useMemo } from "react";
import { scaleBand, scaleLinear } from "@visx/scale";
import { ScaleSVG } from "@visx/responsive";
import { GroupedLegends, Legend } from "services/dashboard/dashboard.model";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { defaultStyles, withTooltip } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { GridRows } from "@visx/grid";
import { darkGrey, lightColor } from "utils/constants";
import { AxisLeft } from "@visx/axis";
import { isInteger } from "lodash";
import { Bar } from "@visx/shape";
import { getMarginBasedOnMax, isNumeric } from "utils/functions";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import classNames from "classnames";
import Popover from "components/shared/popover/popover";

const axisBaseOffset = -0.25;
const axisOffsetMultiplier = -0.45;

const tooltipStyles = {
  ...defaultStyles,
  zIndex: 2,
  minWidth: 50,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
  fontSize: 15,
  width: "fit-content",
};

type Props = {
  data: Legend[] | GroupedLegends[];
  name: string;
  index: number;
  width?: number;
  height?: number;
  axis?: { primary: string[]; secondary: string[] };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  verticalOrientation?: boolean;
  customChartContainerClass?: string;
  customBarContainerClass?: string;
  dashedline?: boolean;
  yAxisMaxValue?: number;
  noDataAvailableDisplayForCompany?: boolean;
};

type GraphProps = {
  data: Legend[] | GroupedLegends[];
  name: string;
  width: number;
  height: number;
  axis?: { primary: string[]; secondary: string[] };
  margin?: { top: number; right: number; bottom: number; left: number };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  showSpaceWhenEmpty?: boolean;
  prefix: string;
  dashedline?: boolean;
  yAxisMaxValue?: number;
};

const IndividualChart = ({
  data,
  axis,
  name,
  width,
  height,
  margin = { top: 10, right: 15, bottom: 5, left: 40 },
  prefix = "",
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
  handleGraphElementClicked,
  dashedline,
  yAxisMaxValue,
}: GraphProps & WithTooltipProvidedProps<Legend>) => {
  const dataset = data as Legend[];
  let tooltipTimeout: number;
  margin.left = getMarginBasedOnMax(
    Math.max(
      ...dataset.map(({ legendData }) =>
        legendData && isNumeric(legendData) ? parseFloat(legendData) : 0
      ),
      0
    )
  );
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const yMaxLegend = yAxisMaxValue
    ? yAxisMaxValue
    : Math.max(...dataset.map((d) => d.legendData ?? 0));
  const topOffset = yMaxLegend > 0 ? 0 : height / 2 - 16;

  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: dataset.map((d) => d.legendValue),
        padding: 0.3,
      }),
    [xMax, dataset]
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        nice: true,
        clamp: true,
        domain: [0, yMaxLegend],
      }),
    [yMax, yMaxLegend]
  );

  return (
    <div style={{ position: "relative" }}>
      <ScaleSVG width={width} height={height}>
        <Group left={margin.left + (prefix ? 15 : 1)} top={margin.top}>
          <GridRows
            scale={yScale}
            width={xMax}
            height={yMax}
            stroke={lightColor}
            top={topOffset}
            {...(dashedline ? { strokeDasharray: "5,5" } : {})}
          />
          <AxisLeft
            strokeWidth={1}
            scale={yScale}
            stroke={lightColor}
            tickStroke={lightColor}
            top={topOffset}
            hideAxisLine={true}
            hideTicks={true}
            tickValues={yScale
              .ticks()
              .filter(isInteger)
              .filter((v) => v >= 0)}
            tickFormat={(tick: any) =>
              parseInt(tick.toFixed()).toLocaleString() + prefix
            }
            tickComponent={(tickProps) => {
              const valueLength = tickProps.formattedValue
                ? tickProps.formattedValue.length
                : 1;

              const dx = axisBaseOffset + valueLength * axisOffsetMultiplier;

              return (
                <text
                  fontFamily="Open Sans"
                  fontSize={12}
                  textAnchor="start"
                  dx={`${dx}em`}
                  dy={tickProps.dy}
                  x={tickProps.x}
                  y={tickProps.y}
                >
                  <tspan x={tickProps.x} dy="0.4em">
                    {tickProps.formattedValue}
                  </tspan>
                </text>
              );
            }}
          />
          {dataset
            .sort((d1, d2) => d1.legendOrder - d2.legendOrder)
            .map((data, i) => {
              const barHeightTmp: number = !isNumeric(data.legendData)
                ? yMax - yScale(yMaxLegend / 10)
                : isNumeric(data.legendData) && parseFloat(data.legendData) >= 0
                ? yMax -
                  (yScale(
                    data.legendData > 0 ? data.legendData : yMaxLegend / 100
                  ) ?? 0)
                : 0;

              const barHeight =
                barHeightTmp <= 1 && parseFloat(data.legendData) > 0
                  ? 1
                  : barHeightTmp;
              const barWidth = xScale.bandwidth();
              const barX: number = xScale(dataset[i].legendValue) || 0;
              const barY: number = yMax - barHeight;
              return barX >= 0 &&
                barY >= 0 &&
                barWidth >= 0 &&
                barHeight >= 0 ? (
                <Fragment key={`data-bar-${i}`}>
                  <pattern
                    id="GradientRepeat"
                    patternUnits="userSpaceOnUse"
                    width="8"
                    height="8"
                    patternTransform="rotate(135)"
                  >
                    <line
                      x1="0"
                      y="0"
                      x2="0"
                      y2="8"
                      stroke="#BBBCBC"
                      strokeWidth="1"
                    />
                  </pattern>

                  <Bar
                    key={`bar-${data.legendValue}-${i}`}
                    x={barX}
                    y={barY}
                    width={barWidth}
                    height={barHeight}
                    style={
                      handleGraphElementClicked ? { cursor: "pointer" } : {}
                    }
                    stroke={data.legendData ? "unset" : darkGrey}
                    fillOpacity={
                      tooltipOpen &&
                      tooltipData?.legendValue !== data.legendValue
                        ? 0.4
                        : 1
                    }
                    fill={
                      data.legendData
                        ? data.legendColor
                        : "url(#GradientRepeat)"
                    }
                    onMouseLeave={() =>
                      (tooltipTimeout = window.setTimeout(() => {
                        hideTooltip();
                      }, 100))
                    }
                    onClick={() =>
                      handleGraphElementClicked
                        ? handleGraphElementClicked(data)
                        : null
                    }
                    onMouseMove={() => {
                      if (tooltipTimeout) clearTimeout(tooltipTimeout);
                      showTooltip({
                        tooltipData: data,
                        tooltipTop: barY,
                        tooltipLeft: barX,
                      });
                    }}
                  ></Bar>
                </Fragment>
              ) : null;
            })}
        </Group>
      </ScaleSVG>
      {tooltipOpen && tooltipData && (
        <div
          style={{
            ...tooltipStyles,
            top: tooltipTop! + topOffset,
            left: tooltipLeft,
            position: "absolute",
          }}
        >
          <strong>
            {
              (tooltipData.legendTooltip
                ? tooltipData.legendTooltip
                : tooltipData.legendValue) as React.ReactNode
            }
          </strong>
        </div>
      )}
    </div>
  );
};

const CustomMultipleBarChart = ({
  data,
  name,
  axis,
  width = 1000,
  height = 1000,
  handleGraphElementClicked,
  verticalOrientation,
  customChartContainerClass,
  customBarContainerClass,
  dashedline,
  yAxisMaxValue,
  noDataAvailableDisplayForCompany,
}: Props) => {
  const dataset = data as GroupedLegends[];
  const noData = dataset.reduce(
    (acc, group) =>
      acc &&
      group.legends.reduce(
        (acc, curr) => acc && curr.legendData === null,
        true
      ),
    true
  );
  if (noData)
    return (
      <div className="speedometer__no-data">
        <FormattedMessage id="insights.speedometer.no-data" />
      </div>
    );
  const Bar = withTooltip<GraphProps, Legend>(IndividualChart);
  return (
    <div
      className={`multiple-chart ${customChartContainerClass}`}
      style={{
        flexDirection: verticalOrientation ? "column" : "row",
        height: verticalOrientation ? "100%" : "unset",
      }}
    >
      {dataset.map((group, index) => {
        const hasData = group.legends.reduce(
          (acc, curr) => acc || curr.legendData !== null,
          false
        );
        return (
          <div
            className={classNames("multiple-chart__bar", {
              "multiple-chart__bar--vertical": verticalOrientation,
              [`${customBarContainerClass}`]:
                customBarContainerClass && customBarContainerClass !== "",
            })}
            key={`multiple-chart-${index}`}
          >
            {hasData ? (
              <ParentSize debounceTime={0}>
                {({ width: visWidth, height: visHeight }: any) =>
                  Bar({
                    data: group.legends,
                    name,
                    axis,
                    width: visWidth,
                    height: visHeight,
                    handleGraphElementClicked,
                    prefix: group.groupPrefix ?? "",
                    dashedline: dashedline ?? false,
                    yAxisMaxValue: yAxisMaxValue,
                  })
                }
              </ParentSize>
            ) : (
              <div className="speedometer__no-data">
                <FormattedMessage id="insights.speedometer.no-data" />
              </div>
            )}
            {/* commenting to remove label
             <span className="multiple-chart__bar-label-container">
              <Popover
                metricClassName="multiple-chart__bar-label"
                displayText={group.groupName}
                content={
                  group.description && Array.isArray(group.description)
                    ? group.description
                    : []
                }
              />
            </span> */}
            {hasData &&
            group.legends.reduce(
              (acc, curr) =>
                acc || !curr.legendData || curr.legendData === null,
              false
            ) ? (
              noDataAvailableDisplayForCompany ? (
                <span className="DashboardTile__noDataAny">
                  <FormattedMessage id="no.data.available" />
                </span>
              ) : (
                <span className="DashboardTile__dataset-info">
                  <FormattedMessage
                    id="multiple-chart.no-data"
                    values={{
                      metric: group.groupName,
                      companies: group.legends
                        .filter((d) => !d.legendData)
                        .map((d) => d.legendValue)
                        .join("; "),
                    }}
                  />
                </span>
              )
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default CustomMultipleBarChart;
