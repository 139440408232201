import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import SidePanel from "components/shared/side-panel/side-panel";
import { useEffect, useState } from "react";
import Button from "components/shared/button/button";
import classNames from "classnames";
import { saveComparisonSearchKeywords } from "services/saved-comparisons/saved-comparisons.api";

type props = {
  BLOCK: string;
  setShowSearchPanel: Function;
  handleSearchKeyword: Function;
  defaultSearchKeyword: string;
  comparisonId: number;
};

const SearchPanel = ({
  BLOCK,
  setShowSearchPanel,
  handleSearchKeyword,
  defaultSearchKeyword,
  comparisonId,
}: props) => {
  const [error, setError] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");

  useEffect(() => {
    handleTextareaChange(removeDuplicateKeywords(defaultSearchKeyword));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSearchKeyword]);

  const searchKeyword = () => {
    const textToSearch = removeDuplicateKeywords(textareaValue);
    if (textToSearch)
      saveComparisonSearchKeywords({
        comparisonId,
        contentKeywords: textToSearch,
      }).then((response) => {
        if (response.data) {
           handleSearchKeyword(textToSearch);
        }
        setShowSearchPanel(false);
      });
  };

  const resetSearch = () => {
    saveComparisonSearchKeywords({
      comparisonId,
      contentKeywords: null,
    }).then((response) => {
      if (response.data) {
        handleSearchKeyword(null);
        handleTextareaChange(removeDuplicateKeywords(defaultSearchKeyword));
      }
    });
  };

  const removeDuplicateKeywords = (value: string) =>
    [...new Set(value.split(" or "))].join(" or ");

  const validateKeyword = (value: string) =>
    setError(!/^"[\w\d\s-]+"( or "[\w\d\s-]+")*$/gim.test(value));

  const handleTextareaChange = (value: string) => {
    // validateKeyword(value);
    setTextareaValue(value);
  };

  return (
    <SidePanel
      showPanel={true}
      className={`${BLOCK}`}
      headerText="compare.search.tooltip"
      handleClose={() => {
        setShowSearchPanel(false);
      }}
    >
      <>
        <div className={`${BLOCK}__body`} data-testid={`${BLOCK}__body`}>
          <textarea
            value={textareaValue}
            className={classNames(`${BLOCK}-textbox`, {
              [`${BLOCK}-textbox--error`]: error && textareaValue !== "",
            })}
            onChange={(e) => handleTextareaChange(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                searchKeyword();
              }
            }}
            data-testid={"textarea"}
          />
          <Icon
            className={`${BLOCK}__icon`}
            name={"search"}
            width={24}
            height={24}
          />

          {error && textareaValue !== "" ? (
            <span className={`${BLOCK}__error-message`}>
              <FormattedMessage id="comparison.search-keyword.error" />
            </span>
          ) : null}

          <div>
            <div className={`dashboard__edit-dashboard__actions`}>
              <Button
                className="text-button"
                onClick={resetSearch}
                iconName="eraser"
                iconHeight={24}
                iconWidth={24}
                children={
                  <FormattedMessage id="custom.benchmark.actions.reset" />
                }
                dataTest="resetBtn"
              />
              <Button
                disabled={error}
                className="button-primary"
                onClick={searchKeyword}
                children={<FormattedMessage id="search.tab.search" />}
                dataTest="searchBtn"
              />
            </div>
          </div>
        </div>
      </>
    </SidePanel>
  );
};

export default SearchPanel;
