import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  cancelMapFilesProcess,
  fetchPendingItems,
  mapFilesDeleteRow,
  processFilesCompleteStatus,
  processMapFiles,
} from "services/manage-files/manage-files.service";
import {
  loadDocumentTypeFilter,
  setLoaded,
  loadGoverningBoardFilter,
  loadPeriodFilter,
} from "services/search/filters.service";
import Icon from "components/shared/icon/icon";
import {
  DELETE_MAP_FILES_STATUS,
  PRE_UPLOAD_STATUS,
  REPORT_STATUS_ERRORS,
} from "utils/constants";
import { PendingItem } from "services/manage-files/manage-files.model";
import emptyimg from "images/saved.shortlist-empty.png";
import MapFilesTable from "./table-mapfiles";
import Button from "components/shared/button/button";

type MapFilesProps = {
  handleTabChange: (value: number) => void;
};

const Mapfiles = ({ handleTabChange }: MapFilesProps) => {
  const BLOCK = "map-files";
  const [pendingItems, setPendingItems] = useState<PendingItem[]>([]);
  const dispatch = useDispatch();
  const manageFilesState = useSelector((state: RootStore) => state.manageFiles);
  const searchState = useSelector((state: RootStore) => state.filters);
  const [periodYearsOptions, setPeriodYearsOptions] = useState<any>();
  const [reportTypeOptions, setReportTypeOptions] = useState<any>();
  const [esgFrameworksOptions, setEsgFrameworksOptions] = useState<any>();
  const [metaDataMissing, setMetaDataMissing] = useState<boolean>(false);
  const [metaDataMissingReports, setMetaDataMissingReports] = useState<any>([]);
  const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchPendingItems());
    if (manageFilesState.pendingItems) {
      setPendingItems(manageFilesState.pendingItems);
    }
    if (!searchState.loaded) {
      const abortController = new AbortController();
      dispatch(loadGoverningBoardFilter());
      dispatch(loadPeriodFilter());
      dispatch(loadDocumentTypeFilter());
      dispatch(setLoaded());

      return () => {
        abortController.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (metaDataMissing && metaDataMissingReports.length === 0) {
      setMetaDataMissing(false);
    } else if (!metaDataMissing && metaDataMissingReports.length > 0) {
      setMetaDataMissing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaDataMissingReports.length]);

  useEffect(() => {
    setPendingItems(manageFilesState.pendingItems);
  }, [manageFilesState.pendingItems]);

  useEffect(() => {
    setPeriodYearsOptions(searchState.periodFilterOptions);
  }, [searchState.periodFilterOptions]);

  useEffect(() => {
    setReportTypeOptions([searchState.documentTypeFilterOptions]);
  }, [searchState.documentTypeFilterOptions]);

  useEffect(() => {
    setEsgFrameworksOptions(searchState.governingBoardFilterOptions);
  }, [searchState.governingBoardFilterOptions]);

  useEffect(() => {
    if (manageFilesState.processFilesComplete) {
      dispatch(processFilesCompleteStatus(false));
      handleTabChange(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageFilesState.processFilesComplete]);

  useEffect(() => {
    if (
      searchState.documentTypeFilterOptions.length > 0 &&
      searchState.governingBoardFilterOptions.length > 0 &&
      searchState.periodFilterOptions.length > 0 &&
      searchState.loaded
    ) {
      setAllDataLoaded(true);
    }
  }, [searchState]);

  const tableHeaders = [
    {
      text: "map.files.header.reportName",
      isFormattedText: true,
      sortId: "FileTitle",
    },
    {
      text: "map.files.header.CIK",
      isFormattedText: true,
      sortId: "FileCIKNumber",
    },
    {
      text: "map.files.header.doument",
      isFormattedText: true,
      sortId: "FileDocumentType",
    },
    {
      text: "map.files.header.framework",
      isFormattedText: true,
      sortId: "FileESGFramework",
    },
    {
      text: "map.files.header.year",
      isFormattedText: true,
      sortId: "FileReportYear",
    },
    {
      text: "",
      isFormattedText: true,
      sortId: "FileDeleteAction",
    },
  ];

  // Sets/unsets the reportIds in an array in reducer based on which final status will be sent
  const handleRemoveRow = (row: any, index: number, isMissingData: boolean) => {
    const indexFound = manageFilesState.deletedRows.findIndex(
      (reportId: any) => row.reportId === reportId
    );

    if (indexFound === -1) {
      dispatch(
        mapFilesDeleteRow([...manageFilesState.deletedRows, row.reportId])
      );
      const filteredList = metaDataMissingReports.filter(
        (report: any) => report.index !== index
      );
      setMetaDataMissingReports(filteredList);
    } else {
      dispatch(
        mapFilesDeleteRow(
          manageFilesState.deletedRows.filter(
            (reportId: any) => row.reportId !== reportId
          )
        )
      );
      if (isMissingData) {
        setMetaDataMissingReports([
          ...metaDataMissingReports,
          {
            index: index,
            metaDataMissing: true,
          },
        ]);
      }
    }
  };

  const [executeMetaDataMissingMap, setExecuteMetaDataMissingMap] =
    useState(true);
  useEffect(() => {
    if (pendingItems && pendingItems.length > 0 && executeMetaDataMissingMap) {
      let tmpMetaDataMissingReports: any = [];
      pendingItems.forEach((pi: any, index: number) => {
        let cikNumberStr =
          pi.reportCompany.cikNumber && pi.reportCompany.cikNumber > 0
            ? `${pi.reportCompany.cikNumber}`
            : "";

        let documentType =
          pi.reportFormType && pi.reportFormType.formTypeId !== 0
            ? [pi.reportFormType]
            : [];
        if (
          (cikNumberStr.length === 0 || documentType.length === 0) &&
          (pi.reportStatus <= REPORT_STATUS_ERRORS.METADATA_MISSING ||
            pi.reportStatus > REPORT_STATUS_ERRORS.UNSUPPORTED_FORMAT) &&
          pi.reportStatus !== REPORT_STATUS_ERRORS.FILE_NOT_FOUND &&
          pi.reportStatus !== REPORT_STATUS_ERRORS.INVALID_CIK_NUMBER
        ) {
          tmpMetaDataMissingReports.push({
            index: index,
            metaDataMissing: true,
          });
        }
      });
      setMetaDataMissingReports(tmpMetaDataMissingReports);
      setExecuteMetaDataMissingMap(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingItems]);

  const handleCancel = () => {
    let cancelPayload: any[] = [];
    pendingItems.forEach((item: any) => {
      cancelPayload.push({
        ...item,
        reportStatus: DELETE_MAP_FILES_STATUS,
      });
    });
    dispatch(cancelMapFilesProcess({ reports: cancelPayload }));
    handleTabChange(0);
  };

  const handleSave = () => {
    const pendingItemsToProcessList = pendingItems.map((item: any) => {
      return {
        ...item,
        reportStatus: manageFilesState.deletedRows.includes(item.reportId)
          ? DELETE_MAP_FILES_STATUS
          : item.reportStatus <= 4 || item.reportStatus > 10
          ? PRE_UPLOAD_STATUS
          : item.reportStatus,
      };
    });

    const processingPayload = {
      reports: pendingItemsToProcessList,
    };

    dispatch(processMapFiles(processingPayload));
  };

  const handleChange = (updatedItem: PendingItem, indexFound: number) => {
    let updatedItems = [...manageFilesState.updatedPendingItems];
    updatedItems[indexFound] = updatedItem;
    setPendingItems(updatedItems);
  };

  return (
    <div className={BLOCK}>
      {manageFilesState.mapFilesLoading && (
        <div className={`${BLOCK}__loading`}>
          <Icon
            name="loading"
            height={50}
            width={50}
            className="loading-icon"
          />
        </div>
      )}
      {!manageFilesState.mapFilesLoading &&
        manageFilesState.pendingItems &&
        manageFilesState.pendingItems.length !== 0 &&
        allDataLoaded && (
          <>
            <div className={`${BLOCK}__header`}>
              <div className={`${BLOCK}__header--actions`}>
                <Button
                  className={`button-secondary`}
                  onClick={handleCancel}
                  formattedText="share.any.button.cancel"
                />
                <Button
                  className={`button-primary`}
                  disabled={metaDataMissing}
                  formattedText="map.files.process.btn"
                  onClick={handleSave}
                />
              </div>
            </div>
            <div className={`${BLOCK}__table--container`}>
              <MapFilesTable
                // className={`${BLOCK}__table`}
                headerItems={tableHeaders}
                rowItems={pendingItems}
                handleRemoveRow={handleRemoveRow}
                searchState={searchState}
                periodYearsOptions={periodYearsOptions}
                reportTypeOptions={reportTypeOptions}
                esgFrameworksOptions={esgFrameworksOptions}
                setMetaDataMissingReports={setMetaDataMissingReports}
                metaDataMissingReports={metaDataMissingReports}
                handleChange={handleChange}
                metaDataMissing={metaDataMissing}
              />
            </div>
          </>
        )}
      {!manageFilesState.mapFilesLoading &&
        manageFilesState.pendingItems &&
        manageFilesState.pendingItems.length === 0 && (
          <div className={`${BLOCK}__empty`} data-test="empty-search-list">
            <div className={`${BLOCK}__img`}>
              <img className={""} src={emptyimg} alt={emptyimg} />
            </div>
            <div className={`${BLOCK}__emptytext`}>
              <FormattedMessage id="map.files.tab.empty" />
            </div>
            <div className={`${BLOCK}__emptyredirect`}>
              <FormattedMessage id="map.files-empty-redirect" />
              &nbsp;
              <span
                onClick={() => handleTabChange(0)}
                className={`${BLOCK}__link`}
              >
                <FormattedMessage id="map.files-empty-redirect-link" />
              </span>
            </div>
          </div>
        )}
    </div>
  );
};

export default Mapfiles;
