import { Dispatch } from "redux";
import { createAction } from "utils/redux.utils";

import {
  FiltersState,
  FiltersModel,
  CompanyFilter,
  IndustryFilter,
  GoverningBoardFilter,
  FormTypeFilter,
  PopularSearchFilter,
  AuditorFilter,
  PeriodFilter,
  ESGRatingFilter,
  SuggestedPeerCompaniesByCompanyFilter,
} from "./filters.model";
import * as api from "./search.api";
import { FILTER_OPTIONS } from "utils/constants";
import { findDocumentTypeFilterOption } from "services/search/filters.model";

/*
 * Actions
 */

export const ACTIONS = {
  SEARCH_ERROR: "ESG/UI/SEARCH_ERROR",
  SEARCH_LOADED: "ESG/UI/SEARCH_LOADED",
  SEARCH_SHOW_FILTERS: "ESG/UI/SEARCH_SHOW_FILTERS",
  SEARCH_LOADING_FILTER: "ESG/UI/SEARCH_LOADING_FILTER",
  SEARCH_LOAD_COMPANY_FILTER: "ESG/UI/SEARCH_LOAD_COMPANY_FILTER",
  SEARCH_SET_COMPANY_FILTER: "ESG/UI/SEARCH_SET_COMPANY_FILTER",
  SEARCH_LOAD_CONTENT_FILTER: "ESG/UI/SEARCH_LOAD_CONTENT_FILTER",
  SEARCH_SET_CONTENT_FILTER: "ESG/UI/SEARCH_SET_CONTENT_FILTER",
  SEARCH_SET_CONTENT_VALUE: "ESG/UI/SEARCH_SET_CONTENT_VALUE",
  SEARCH_LOAD_DOCUMENT_TYPE_FILTER: "ESG/UI/SEARCH_LOAD_DOCUMENT_TYPE_FILTER",
  SEARCH_SET_DOCUMENT_TYPE_FILTER: "ESG/UI/SEARCH_SET_DOCUMENT_TYPE_FILTER",
  SEARCH_LOAD_PERIOD_FILTER: "ESG/UI/SEARCH_LOAD_PERIOD_FILTER",
  SEARCH_SET_PERIOD_FILTER: "ESG/UI/SEARCH_SET_PERIOD_FILTER",
  SEARCH_LOAD_INDUSTRY_FILTER: "ESG/UI/SEARCH_LOAD_INDUSTRY_FILTER",
  SEARCH_SET_INDUSTRY_FILTER: "ESG/UI/SEARCH_SET_INDUSTRY_FILTER",
  SEARCH_LOAD_AUDITOR_FILTER: "ESG/UI/SEARCH_LOAD_AUDITOR_FILTER",
  SEARCH_SET_AUDITOR_FILTER: "ESG/UI/SEARCH_SET_AUDITOR_FILTER",
  SEARCH_LOAD_ESG_RATING_FILTER: "ESG/UI/SEARCH_LOAD_ESG_RATING_FILTER",
  SEARCH_SET_ESG_RATING_FILTER: "ESG/UI/SEARCH_SET_ESG_RATING_FILTER",
  SEARCH_LOAD_GOVERNING_BOARD_FILTER:
    "ESG/UI/SEARCH_LOAD_GOVERNING_BOARD_FILTER",
  SEARCH_SET_GOVERNING_BOARD_FILTER: "ESG/UI/SEARCH_SET_GOVERNING_BOARD_FILTER",
  SEARCH_RESET_FILTER: "ESG/UI/SEARCH_RESET_FILTER",
  SEARCH_LOAD_SUGGESTED_PEER_COMPANIES_BY_COMPANY_FILTER:
    "ESG/UI/SEARCH_LOAD_SUGGESTED_PEER_COMPANIES_BY_COMPANY_FILTER",
  SEARCH_SET_SELECTED_PEER_GROUP_ITEMS: "ESG/UI/SEARCH_SET_SELECTED_PEER_GROUP_ITEMS",
  SEARCH_SET_SELECTED_ITEMS_MULTIPLE: "ESG/UI/SEARCH_SET_SELECTED_ITEMS_MULTIPLE",
};

export const searchError = createAction(ACTIONS.SEARCH_ERROR);
export const searchLoaded = createAction(ACTIONS.SEARCH_LOADED);
export const searchShowFilters = createAction(ACTIONS.SEARCH_SHOW_FILTERS);
export const searchLoadingFilter = createAction(ACTIONS.SEARCH_LOADING_FILTER);
export const searchLoadCompanyFilter = createAction(
  ACTIONS.SEARCH_LOAD_COMPANY_FILTER
);
export const searchSetCompanyFilter = createAction(
  ACTIONS.SEARCH_SET_COMPANY_FILTER
);
export const searchLoadContentFilter = createAction(
  ACTIONS.SEARCH_LOAD_CONTENT_FILTER
);
export const searchSetContentFilter = createAction(
  ACTIONS.SEARCH_SET_CONTENT_FILTER
);
export const searchSetContentValue = createAction(
  ACTIONS.SEARCH_SET_CONTENT_VALUE
);
export const searchLoadDocumentTypeFilter = createAction(
  ACTIONS.SEARCH_LOAD_DOCUMENT_TYPE_FILTER
);
export const searchSetDocumentTypeFilter = createAction(
  ACTIONS.SEARCH_SET_DOCUMENT_TYPE_FILTER
);
export const searchLoadPeriodFilter = createAction(
  ACTIONS.SEARCH_LOAD_PERIOD_FILTER
);
export const searchSetPeriodFilter = createAction(
  ACTIONS.SEARCH_SET_PERIOD_FILTER
);
export const searchLoadIndustryFilter = createAction(
  ACTIONS.SEARCH_LOAD_INDUSTRY_FILTER
);
export const searchSetIndustryFilter = createAction(
  ACTIONS.SEARCH_SET_INDUSTRY_FILTER
);
export const searchLoadAuditorFilter = createAction(
  ACTIONS.SEARCH_LOAD_AUDITOR_FILTER
);
export const searchSetAuditorFilter = createAction(
  ACTIONS.SEARCH_SET_AUDITOR_FILTER
);
export const searchLoadGoverningBoardFilter = createAction(
  ACTIONS.SEARCH_LOAD_GOVERNING_BOARD_FILTER
);
export const searchSetGoverningBoardFilter = createAction(
  ACTIONS.SEARCH_SET_GOVERNING_BOARD_FILTER
);
export const searchLoadESGRatingFilter = createAction(
  ACTIONS.SEARCH_LOAD_ESG_RATING_FILTER
);
export const searchSetESGRatingFilter = createAction(
  ACTIONS.SEARCH_SET_ESG_RATING_FILTER
);

export const loadSuggestedPeerCompaniesByCompanyFilter = createAction(
  ACTIONS.SEARCH_LOAD_SUGGESTED_PEER_COMPANIES_BY_COMPANY_FILTER
);

export const updateSelectedPeerGroupItems = createAction(ACTIONS.SEARCH_SET_SELECTED_PEER_GROUP_ITEMS);
export const updateSelectedItemsMultiple = createAction(ACTIONS.SEARCH_SET_SELECTED_ITEMS_MULTIPLE);


export const resetFilter = createAction(ACTIONS.SEARCH_RESET_FILTER);
export const setLoaded = () => async (dispatch: Dispatch<any>) => {
  dispatch(searchLoaded(true));
};

export const setShowFilters =
  (showFilters: boolean) => async (dispatch: Dispatch<any>) => {
    dispatch(searchShowFilters(showFilters));
  };

export const loadCompanyFilter =
  (searchText: string) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = searchText ? await api.fetchDocumentFilter(searchText) : [];
      dispatch(searchLoadCompanyFilter(res.data));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.COMPANY));
    }
  };

export const loadCompanyIdFilter =
  (companyFilter: CompanyFilter) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      dispatch(searchLoadCompanyFilter([companyFilter]));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.COMPANY));
    }
  };

export const setCompanyFilter =
  (companyFilter: CompanyFilter[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchSetCompanyFilter(companyFilter));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.COMPANY));
    }
  };

export const loadContentFilter =
  (textContent: string) => async (dispatch: Dispatch<any>) => {
    try {
      const payload = {
        contentText: textContent,
        pageInfo: { totalRecordCount: 10 },
      };
      dispatch(searchLoadingFilter(FILTER_OPTIONS.CONTENT));
      const res = await api.fetchContentFilter(payload);
      dispatch(searchLoadContentFilter(res.data));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.CONTENT));
    }
  };

export const setContentFilter =
  (companyFilter: PopularSearchFilter[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchSetContentFilter(companyFilter));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.CONTENT));
    }
  };

export const setContentValue =
  (companyFilter: string) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchSetContentValue(companyFilter));
    } catch (e) {
      dispatch(searchError());
    }
  };

export const loadDocumentTypeFilter =
  (documentType?: string) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.DOCUMENT_TYPE));
      const res = await api.fetchDocumentTypeFilter();
      dispatch(searchLoadDocumentTypeFilter(res.data));

      if (documentType) {
        const documentTypeFilterOption = findDocumentTypeFilterOption(
          res.data,
          documentType
        );
        if (documentTypeFilterOption)
          dispatch(setDocumentTypeFilter([documentTypeFilterOption]));
      }
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.DOCUMENT_TYPE));
    }
  };

export const loadMultipleDocumentTypeFilter =
  (documentTypes?: string[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.DOCUMENT_TYPE));
      const res = await api.fetchDocumentTypeFilter();
      dispatch(searchLoadDocumentTypeFilter(res.data));
      let tmpDocumentTypeFilterOptions: FormTypeFilter[] = [];

      if (documentTypes) {
        documentTypes.forEach((document: string) => {
          const documentTypeFilterOption = findDocumentTypeFilterOption(
            res.data,
            document
          );
          if (documentTypeFilterOption)
            tmpDocumentTypeFilterOptions.push(documentTypeFilterOption);
        });
      }
      dispatch(setDocumentTypeFilter(tmpDocumentTypeFilterOptions));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.DOCUMENT_TYPE));
    }
  };

export const setDocumentTypeFilter =
  (companyFilter: FormTypeFilter[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.DOCUMENT_TYPE));
      dispatch(searchSetDocumentTypeFilter(companyFilter));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.DOCUMENT_TYPE));
    }
  };

export const loadPeriodFilter =
  (periodYear?: string) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.PERIOD));
      const res = await api.fetchPeriodFilter();
      const mappedData = res.data.map((year: number, index: number) => ({
        periodId: index,
        periodYear: year.toString(),
      }));
      dispatch(searchLoadPeriodFilter(mappedData));
      if (periodYear && periodYear !== "")
        dispatch(
          searchSetPeriodFilter(
            mappedData.filter((period: any) => period.periodYear === periodYear)
          )
        );
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.PERIOD));
    }
  };

export const setPeriodFilter =
  (periodFilter: PeriodFilter[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.PERIOD));
      dispatch(searchSetPeriodFilter(periodFilter));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.PERIOD));
    }
  };

export const loadIndustryFilter = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(searchLoadingFilter(FILTER_OPTIONS.INDUSTRY));
    const res = await api.fetchIndustryFilter();
    dispatch(searchLoadIndustryFilter(res.data));
  } catch (e) {
    dispatch(searchError(FILTER_OPTIONS.INDUSTRY));
  }
};

export const setIndustryFilter =
  (industryFilter: IndustryFilter[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.INDUSTRY));
      dispatch(searchSetIndustryFilter(industryFilter));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.INDUSTRY));
    }
  };

export const loadAuditorFilter = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(searchLoadingFilter(FILTER_OPTIONS.AUDITOR));
    const res = await api.fetchAuditorFilter();
    dispatch(searchLoadAuditorFilter(res.data));
  } catch (e) {
    dispatch(searchError(FILTER_OPTIONS.AUDITOR));
  }
};

export const setAuditorFilter =
  (auditorFilter: AuditorFilter[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.AUDITOR));
      dispatch(searchSetAuditorFilter(auditorFilter));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.AUDITOR));
    }
  };

export const loadGoverningBoardFilter =
  () => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.GOVERNING_BOARD));
      const res = await api.fetchGoverningBoardFilter();
      dispatch(searchLoadGoverningBoardFilter(res.data));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.GOVERNING_BOARD));
    }
  };

export const setGoverningBoardFilter =
  (companyFilter: GoverningBoardFilter[]) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.GOVERNING_BOARD));
      dispatch(searchSetGoverningBoardFilter(companyFilter));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.GOVERNING_BOARD));
    }
  };

export const loadESGRatingFilter = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(searchLoadingFilter(FILTER_OPTIONS.ESG_RATING));
    const res = await api.fetchESGRatingFilter();
    dispatch(searchLoadESGRatingFilter(res.data));
  } catch (e) {
    dispatch(searchError(FILTER_OPTIONS.ESG_RATING));
  }
};

export const setESGRatingFilter =
  (esgRatingFilter: ESGRatingFilter[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.ESG_RATING));
      dispatch(searchSetESGRatingFilter(esgRatingFilter));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.ESG_RATING));
    }
  };
/*
 * Reducer
 */

export const fetchSuggestedPeerCompaniesByCompanyFilter =
  (companies: SuggestedPeerCompaniesByCompanyFilter[]) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.SUGGESTED_PEER));
      let suggestedPeerCompaniesByCompanyFilter: SuggestedPeerCompaniesByCompanyFilter[] =
        [];
      for await (const company of companies) {
        const res = company.cikNumber
          ? await api.fetchSuggestedPeers(company.cikNumber)
          : { data: [] };
        company.suggestedPeerCompanyFilter = res.data.map((item: any) => ({
          ...item,
          checked: false,
        }));
        suggestedPeerCompaniesByCompanyFilter.push(company);
      }

      dispatch(
        loadSuggestedPeerCompaniesByCompanyFilter(
          suggestedPeerCompaniesByCompanyFilter
        )
      );
    } catch (e) {}
  };

const filtersModel = new FiltersModel();

const filtersReducer = (
  state: FiltersState = filtersModel.initialState(),
  action: any
): FiltersState => {
  switch (action.type) {
    case ACTIONS.SEARCH_ERROR:
      return filtersModel.setError(
        { code: 404, message: "Error" },
        action.payload
      );
    case ACTIONS.SEARCH_LOADED:
      return filtersModel.setLoaded(action.payload);
    case ACTIONS.SEARCH_SHOW_FILTERS:
      return filtersModel.setShowFilters(action.payload);
    case ACTIONS.SEARCH_LOADING_FILTER:
      return filtersModel.setLoadingFilter(action.payload);
    case ACTIONS.SEARCH_LOAD_COMPANY_FILTER:
      return filtersModel.loadCompanyFilterOptions(action.payload);
    case ACTIONS.SEARCH_SET_COMPANY_FILTER:
      return filtersModel.setCompanyFilter(action.payload);
    case ACTIONS.SEARCH_LOAD_CONTENT_FILTER:
      return filtersModel.loadContentFilterOptions(action.payload);
    case ACTIONS.SEARCH_SET_CONTENT_FILTER:
      return filtersModel.setContentFilter(action.payload);
    case ACTIONS.SEARCH_SET_CONTENT_VALUE:
      return filtersModel.setContentValue(action.payload);
    case ACTIONS.SEARCH_LOAD_DOCUMENT_TYPE_FILTER:
      return filtersModel.loadDocumentTypesFilterOptions(action.payload);
    case ACTIONS.SEARCH_SET_DOCUMENT_TYPE_FILTER:
      return filtersModel.setDocumentTypesFilter(action.payload);
    case ACTIONS.SEARCH_LOAD_PERIOD_FILTER:
      return filtersModel.loadPeriodFilterOptions(action.payload);
    case ACTIONS.SEARCH_SET_PERIOD_FILTER:
      return filtersModel.setPeriodFilter(action.payload);
    case ACTIONS.SEARCH_LOAD_INDUSTRY_FILTER:
      return filtersModel.loadIndustryFilterOptions(action.payload);
    case ACTIONS.SEARCH_SET_INDUSTRY_FILTER:
      return filtersModel.setIndustryFilter(action.payload);
    case ACTIONS.SEARCH_LOAD_AUDITOR_FILTER:
      return filtersModel.loadAuditorFilterOptions(action.payload);
    case ACTIONS.SEARCH_SET_AUDITOR_FILTER:
      return filtersModel.setAuditorFilter(action.payload);
    case ACTIONS.SEARCH_LOAD_GOVERNING_BOARD_FILTER:
      return filtersModel.loadGoverningBoardFilterOptions(action.payload);
    case ACTIONS.SEARCH_SET_GOVERNING_BOARD_FILTER:
      return filtersModel.setGoverningBoardFilter(action.payload);
    case ACTIONS.SEARCH_LOAD_ESG_RATING_FILTER:
      return filtersModel.loadESGRatingFilterOptions(action.payload);
    case ACTIONS.SEARCH_SET_ESG_RATING_FILTER:
      return filtersModel.setESGRatingFilter(action.payload);
    case ACTIONS.SEARCH_RESET_FILTER:
      return filtersModel.resetFilter();
    case ACTIONS.SEARCH_LOAD_SUGGESTED_PEER_COMPANIES_BY_COMPANY_FILTER:
      return filtersModel.loadSuggestedPeerCompanyByCompanyFilterOptions(
        action.payload
      );
    case ACTIONS.SEARCH_SET_SELECTED_PEER_GROUP_ITEMS:
      return filtersModel.setSelectedPeerGroupItems(action.payload);
    case ACTIONS.SEARCH_SET_SELECTED_ITEMS_MULTIPLE:
      return filtersModel.setSelectedItemsMultiple(action.payload);
    default:
      return state;
  }
};

export default filtersReducer;
