import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { ACCESS_MODULES, insightsPath, searchPath } from "utils/constants";
import history from "utils/history";

const Home = () => {
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );
  const hasInsightAccess =
    currentUser &&
    currentUser.accessibleFeatures &&
    currentUser.accessibleFeatures.length > 0 &&
    currentUser.accessibleFeatures.some(
      (feature) =>
        feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_All ||
        feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_Insights
    );
  useEffect(() => {
    if (
      currentUser &&
      currentUser.accessibleFeatures &&
      currentUser.accessibleFeatures.length > 0
    )
      history.push(hasInsightAccess ? insightsPath : searchPath);
  }, [currentUser]);

  return <div data-testid="home-container"></div>;
};

export default Home;
