import React, { useState, useEffect, useRef } from "react";
import Modal from "../shared/modal/modal";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { addToastMessage } from "services/commons.service";
import {
  executeNewComparisonTrack,
  resetComparisonToSave,
  saveComparisonError,
  saveComparisonSuccess,
} from "services/saved-comparisons/saved-comparisons.service";
import { comparisonNameLimit } from "utils/constants";
import Button from "components/shared/button/button";

type props = {
  setShowModal: (value: boolean) => void;
  saveComparison: (name: string, description: string) => void;
  updateComparisonDetails: (
    savedComparisonId: number,
    savedComparisonName: string,
    comparisonItems: [],
    currCompariosn: []
  ) => void;
  comparisonId: number;
};

const SaveComparison = ({
  setShowModal,
  saveComparison,
  updateComparisonDetails,
  comparisonId,
}: props) => {
  const BLOCK = "RenameShortlist";
  const [counts, setCounts] = useState({ name: 0, description: 0 });
  const [disabled, setDisabled] = useState(true);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const [values, setValues] = useState({ name: "", description: "" });
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(false);
  const comparisonState = useSelector(
    (state: RootStore) => state.comparisonState
  );

  useEffect(() => {
    nameInputRef.current!.focus();
  }, []);
  useEffect(() => {
    if (comparisonState.saveComparisonError) {
      setErrorMessage(true);
      nameInputRef.current!.focus();
      if (comparisonState.comparisonToSave) {
        setValues({
          ...values,
          name: comparisonState.comparisonToSave.comparisonName,
          description: comparisonState.comparisonToSave.comparisonDescription,
        });
        setCounts({
          ...counts,
          name: comparisonState.comparisonToSave.comparisonName.length,
          description:
            comparisonState.comparisonToSave.comparisonDescription.length,
        });
      }
      dispatch(saveComparisonError(false));
      dispatch(resetComparisonToSave());
      dispatch(saveComparisonSuccess(false));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonState.saveComparisonError, dispatch]);

  useEffect(() => {
    if (comparisonState.saveComparisonSuccess) {
      updateComparisonDetails(
        comparisonState.savedComparisonResult.comparisonId,
        comparisonState.savedComparisonResult.comparisonName,
        comparisonState.savedComparisonResult.comparisonItems,
        comparisonState.savedComparisonResult
      );
      dispatch(executeNewComparisonTrack(true));
      setErrorMessage(false);
      setShowModal(false);
      dispatch(saveComparisonSuccess(false));

      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage
              id="save.comparison.success"
              values={{
                name: comparisonState.savedComparisonResult.comparisonName,
              }}
            />
          ),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonState.saveComparisonSuccess, dispatch, setShowModal]);

  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      saveComparisonHandler();
    }
  };
  const onChangeHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCounts({ ...counts, [event.target.name]: event.target.value.length });
    setValues({ ...values, [event.target.name]: event.target.value });
    if (event.target.name === "name") {
      setDisabled(event.target.value.trim().length === 0);
    }
    setErrorMessage(false);
  };

  const saveComparisonHandler = () => {
    if (nameInputRef.current!.value.trim().length === 0) {
      return;
    }
    saveComparison(
      nameInputRef.current!.value,
      descriptionInputRef.current!.value
    );
  };

  return (
    <Modal
      show={true}
      header={
        comparisonId === 0
          ? "comparison.save.title"
          : "comparison.save.as.title"
      }
      handleClose={() => {
        setShowModal(false);
      }}
      config={false}
    >
      <div
        className={`${BLOCK} ${BLOCK}--saveSearchlist`}
        data-testid="save-comparison-container"
      >
        <span
          className={classNames(`${BLOCK}__name--saveSearchList`, {
            [`${BLOCK}__name`]: !errorMessage,
            [`${BLOCK}__nameError`]: errorMessage,
          })}
        >
          <FormattedMessage id={"comparison.save.as.name"} />
        </span>
        <span
          className={classNames({
            [`${BLOCK}__input`]: !errorMessage,
            [`${BLOCK}__inputError`]: errorMessage,
            [`${BLOCK}__input--saveSearchList`]: !errorMessage,
            [`${BLOCK}__inputError--saveSearchList`]: errorMessage,
          })}
        >
          <input
            type="text"
            ref={nameInputRef}
            maxLength={comparisonNameLimit}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={values.name}
            name="name"
            data-testid="nameInput"
            placeholder={"Enter a name"}
            className={classNames({
              [`${BLOCK}__input--saveSearchList`]: !errorMessage,
              [`${BLOCK}__inputError--saveSearchList`]: errorMessage,
            })}
          />
        </span>

        {errorMessage ? (
          <span
            className={`${BLOCK}__contentError ${BLOCK}__contentError--saveSearchList`}
            data-testid="errorMessage"
          >
            <FormattedMessage id="comparison.report.error" />
          </span>
        ) : (
          <span
            className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}
          >
            {counts.name}
            <FormattedMessage id="shortlist.save.input.character" />
          </span>
        )}

        <span
          className={classNames(
            `${BLOCK}__name--saveSearchList ${BLOCK}__name ${BLOCK}__description-label`
          )}
        >
          <FormattedMessage id="searchlist.save.description" />
        </span>
        <span
          className={classNames(
            `${BLOCK}__input ${BLOCK}__input--saveSearchList`
          )}
        >
          <textarea
            ref={descriptionInputRef}
            maxLength={2000}
            onChange={onChangeHandler}
            onKeyDown={onEnter}
            value={values.description}
            data-testid="descriptionTextArea"
            placeholder="Enter description"
            className={classNames(`${BLOCK}__description`)}
            name="description"
          />
        </span>
        <span className={`${BLOCK}__content ${BLOCK}__content--saveSearchList`}>
          {counts.description}
          <FormattedMessage id="comparison.description.character.limit" />
        </span>
        <div className={`${BLOCK}__options ${BLOCK}__options--saveSearchList`}>
          <Button
            className={`button-secondary-small`}
            onClick={() => setShowModal(false)}
            dataTest="cancel.btn"
            formattedText="shortlist.save.button.cancel"
          />
          <Button
            className={`button-primary`}
            onClick={saveComparisonHandler}
            dataTest="save-new-search-btn"
            disabled={disabled}
            formattedText="shortlist.save.button.save"
          />
        </div>
      </div>
    </Modal>
  );
};

export default SaveComparison;
