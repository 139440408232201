import api from "services/api.service";
import { companyPagination } from "utils/constants";

export const fetchDocumentFilter = (searchText: string) =>
  api
    .post(`search/companies`, {
      contentText: searchText,
      pageInfo: companyPagination,
    })
    .then(({ data }) => data);

export const fetchCompany = (companyId: number) =>
  api.get(`company/${companyId}`).then(({ data }) => data);

export const fetchContentFilter = (payload: any) =>
  api.post(`search/popularSearches`, payload).then(({ data }) => data);

export const fetchDocumentTypeFilter = () =>
  api.get(`search/documentTypes`).then(({ data }) => data);

export const fetchIndustryFilter = () =>
  api.get(`search/industries`).then(({ data }) => data);

export const fetchPeriodFilter = () =>
  api.get(`search/years`).then(({ data }) => data);

export const fetchAuditorFilter = () =>
  api.get(`search/auditors`).then(({ data }) => data);

export const fetchGoverningBoardFilter = () =>
  api.get(`search/governingBoards`).then(({ data }) => data);

export const fetchESGRatingFilter = () =>
  api.get(`search/esgRatings`).then(({ data }) => data);

export const fetchEsgReportsResults = (payload: any) => {
  return api.post(`search/esg`, payload).then(({ data }) => data);
};

export const fetchSecReportsResults = (payload: any) => {
  return api.post(`reports/secDisclosures`, payload).then(({ data }) => data);
};

// Fetch TOC Results
export const fetchTOCResults = (payload: any) => {
  return api.post(`search/toc`, payload).then(({ data }) => data);
};

// Fetch binary for esg rating
export const fetchESGRatingBinary = (payload: any) => {
  return api
    .get(`company/${payload}/esgRating/binary`, { responseType: "blob" })
    .then((res) => {
      const file = new Blob([res.data], { type: res.headers["content-type"] });
      const fileURL = URL.createObjectURL(file);

      return fileURL;
    });
};

// Fetch binary for governance score
export const fetchGovernanceScoreBinary = (payload: any) => {
  return api
    .get(`company/${payload}/governanceMetric/binary`, { responseType: "blob" })
    .then((res) => {
      const file = new Blob([res.data], { type: res.headers["content-type"] });
      const fileURL = URL.createObjectURL(file);

      return fileURL;
    });
};

export const saveRequestUploadReport = (payload: any) => {
  return api.post(`reportrequest`, payload).then(({ data }) => data);
};

export const fetchSuggestedPeers = (cikNumber: number) =>
  api.get(`company/${cikNumber}/suggestedPeers`).then(({ data }) => data);
