import { Bar, Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { Fragment, useEffect, useRef } from "react";
import { lightColor, subTextColor, textColor } from "utils/constants";
import { ScaleSVG } from "@visx/responsive";
import {
  SpeedometerChartDataset,
  SpeedometerTileDataset,
} from "services/dashboard/dashboard.model";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { defaultStyles, withTooltip } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { isNumeric, mod, formatArcTooltipData } from "utils/functions";
import { LinearGradient, RadialGradient } from "@visx/gradient";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import fitty from "fitty";
import classNames from "classnames";
import Popover from "components/shared/popover/popover";

const tooltipStyles = {
  ...defaultStyles,
  zIndex: 2,
  minWidth: 150,
  maxWidth: 150,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
  fontSize: 15,
  width: "fit-content",
};

type Props = {
  data: SpeedometerTileDataset | SpeedometerTileDataset[];
  name?: string;
  index: number;
  size?: number;
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  graphCount?: number;
  benchmarkTileType?: number;
  verticalOrientation?: boolean;
};

const Speedometer = ({
  data,
  name,
  size,
  index,
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
  handleGraphElementClicked,
  graphCount = 1,
}: Props & WithTooltipProvidedProps<SpeedometerChartDataset>) => {
  let tooltipTimeout: number;
  const dataset = data as SpeedometerTileDataset;
  const radius = size ? size / 2 : 75;
  const containerRef = useRef(null);
  const textRef: any = useRef();

  useEffect(() => {
    if (textRef && textRef.current)
      fitty(textRef.current, { minSize: radius / 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textRef.current, radius]);

  const renderGradient = (id: string, color: string[], radial?: boolean) => {
    const Gradient = radial ? RadialGradient : LinearGradient;
    switch (color.length) {
      case 1:
        return <Gradient id={id} from={color[0]} to={color[0]} />;
      case 2:
        return <Gradient id={id} from={color[0]} to={color[1]} />;
      default:
        return <Gradient id={id} from="#E6E6E6" to="#E6E6E6" />;
    }
  };

  return (
    <div ref={containerRef}>
      <ScaleSVG width={radius * 2.1} height={radius * 2.1}>
        <Group top={radius * 1.05} left={radius * 1.05}>
          <Pie
            data={dataset.speedometerValues}
            pieSort={(d1, d2) => d2.order - d1.order}
            pieValue={(d) => dataset.limit}
            startAngle={2.3}
            endAngle={-2.3}
            outerRadius={radius * 0.905}
            innerRadius={radius * 0.895}
          >
            {(pie) =>
              pie.arcs.map((arc, i) => (
                <g key={`speedometer-bg-${dataset.title}-${i}`}>
                  <path d={pie.path(arc) || undefined} fill="#E0E0E0" />
                </g>
              ))
            }
          </Pie>
          <Pie
            data={dataset.speedometerValues}
            pieSort={(d1, d2) => d2.order - d1.order}
            pieValue={(d) => d.value}
            startAngle={2.3}
            endAngle={-2.3}
            outerRadius={radius * 0.96}
            innerRadius={radius * 0.84}
            cornerRadius={15}
          >
            {(pie) =>
              pie.arcs.map((arc, i) =>
                arc.data.color?.length ? (
                  <g
                    key={`speedometer-arc-${dataset.title}-${i}`}
                    onMouseLeave={() =>
                      (tooltipTimeout = window.setTimeout(() => {
                        hideTooltip();
                      }, 100))
                    }
                    onMouseMove={(e) => {
                      const container = (
                        containerRef.current as any
                      ).getBoundingClientRect();
                      if (tooltipTimeout) clearTimeout(tooltipTimeout);
                      showTooltip({
                        tooltipData: formatArcTooltipData(arc.data),
                        tooltipTop: e.clientY - container.y,
                        tooltipLeft: e.clientX - container.x,
                      });
                    }}
                  >
                    {renderGradient(
                      `${arc.data.id}-gradient-${i}`,
                      arc.data.color
                    )}
                    <path
                      d={pie.path(arc) || undefined}
                      fill={`url(#${arc.data.id}-gradient-${i})`}
                    />
                  </g>
                ) : null
              )
            }
          </Pie>

          <Pie
            data={dataset.speedometerAverage}
            pieSort={(d1, d2) => d2.order - d1.order}
            pieValue={(d) => d.value}
            startAngle={2.3}
            endAngle={-2.3}
            outerRadius={radius * 1.01}
            innerRadius={radius * 0.79}
            cornerRadius={15}
          >
            {(pie) =>
              isNumeric(dataset.value)
                ? pie.arcs.map((arc, i) =>
                    arc.data.color?.length ? (
                      <Fragment key={`speedometer-avg-${dataset.title}-${i}`}>
                        {renderGradient(
                          `${arc.data.id}-${i}`,
                          arc.data.color,
                          true
                        )}
                        <g
                          onMouseLeave={() =>
                            (tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 100))
                          }
                          onMouseMove={(e) => {
                            const container = (
                              containerRef.current as any
                            ).getBoundingClientRect();
                            if (tooltipTimeout) clearTimeout(tooltipTimeout);
                            showTooltip({
                              tooltipData: formatArcTooltipData(arc.data),
                              tooltipTop: e.clientY - container.y,
                              tooltipLeft: e.clientX - container.x,
                            });
                          }}
                        >
                          <path
                            d={pie.path(arc) || undefined}
                            fill={`url(#${arc.data.id}-${i})`}
                          />
                        </g>
                      </Fragment>
                    ) : null
                  )
                : null
            }
          </Pie>
          {dataset.speedometerAverage.length && dataset.subLabel ? (
            <>
              <Text
                textAnchor="middle"
                fill={textColor}
                fontSize="2rem"
                fontWeight={400}
                dy={-radius / 10}
                dx={-radius / 2.5}
              >
                {isNumeric(dataset.avgValue)
                  ? dataset.label.split(" ")[0] + dataset.valueSufix
                  : "*"}
              </Text>

              <Bar
                x={-radius / 1.5}
                y={0}
                width={radius * 1.4}
                height={2}
                fill={lightColor}
              />

              <Text
                textAnchor="middle"
                fill={textColor}
                fontSize="1.75rem"
                fontWeight={400}
                dy={radius / 3.3}
                dx={-radius / 2.5}
              >
                {isNumeric(dataset.value)
                  ? dataset.subLabel.split(" ")[0] + dataset.valueSufix
                  : "*"}
              </Text>
              <Text
                verticalAnchor="start"
                textAnchor="start"
                fill={subTextColor}
                fontSize="0.75rem"
                width={radius}
                dy={radius / 8}
                lineHeight={18}
              >
                {dataset.subLabel.split(" ").splice(1).join(" ")}
              </Text>
            </>
          ) : (
            <>
              <Text
                textAnchor="middle"
                fill={textColor}
                fontSize="3rem"
                fontWeight={400}
              >
                {`${dataset.label}${dataset.valueSufix}`}
              </Text>

              <Text
                textAnchor="middle"
                verticalAnchor="start"
                fill={subTextColor}
                width={radius * 2}
                fontSize="0.75rem"
                dy={radius / 6}
              >
                {dataset.subLabel}
              </Text>
            </>
          )}
        </Group>
      </ScaleSVG>

      {dataset.speedometerAverage.length && dataset.subLabel ? (
        <div
          className="speedometer__company-name"
          style={{
            width: radius * 0.7,
            bottom: radius * 1.1,
            left: radius * 0.9,
            fontSize: "0.79rem",
            fontWeight: 600,
          }}
        >
          <span
            ref={textRef}
            className={classNames(`speedometer__company-text`, {
              [`speedometer__company-text__long`]:
                dataset.label.split(" ").filter((item) => item.length > 10)
                  .length > 0 || dataset.label.split(" ").length > 6,
            })}
          >
            {dataset.label.split(" ").splice(1).join(" ")}
          </span>
        </div>
      ) : null}

      {tooltipOpen && tooltipData && tooltipData.tooltip && (
        <div
          style={{
            ...tooltipStyles,
            top: tooltipTop,
            left: mod(index + 1, graphCount) === 0 ? "unset" : tooltipLeft,
            right:
              mod(index + 1, graphCount) === 0
                ? `calc(100% - ${tooltipLeft}px)`
                : "unset",
            position: "absolute",
          }}
        >
          {tooltipData.tooltip}
        </div>
      )}
    </div>
  );
};

const SpeedometerChart = ({
  data,
  name,
  size = 200,
  handleGraphElementClicked,
  verticalOrientation,
  benchmarkTileType,
}: Props) => {
  const dataset = data as SpeedometerTileDataset[];
  const showNodata =
    dataset &&
    dataset.every((elem: any) => isNaN(elem.avgValue) && isNaN(elem.value));

  const getSpeedometerLabel = (data: any) => (
    <div
      className={classNames("speedometer__label", {
        "speedometer__label--vertical": verticalOrientation,
      })}
    >
      <Popover
        metricClassName={"speedometer__metricLabel"}
        displayText={data.title}
        content={data.tooltip}
      />
    </div>
  );

  return (dataset?.length > 0 &&
    dataset[0]?.speedometerAverage?.length > 0 &&
    !showNodata) ||
    dataset?.reduce(
      (acc: boolean, curr) => acc || isNumeric(curr.value),
      false
    ) ? (
    <div
      className="speedometer"
      style={{
        flexDirection: verticalOrientation ? "column" : "row",
      }}
    >
      {dataset.map((data, index) => (
        <Fragment key={index}>
          {!verticalOrientation && benchmarkTileType === 10 && index > 0 ? (
            <div className="speedometer__separator"></div>
          ) : null}
          <div
            className={classNames("speedometer__pie", {
              "speedometer__pie--vertical": verticalOrientation,
            })}
            key={`speedometer-${index}`}
            style={{
              maxWidth: verticalOrientation
                ? `14rem`
                : `calc( (90% - ${dataset.length * 2}rem) / ${
                    dataset.length
                  }) `,
            }}
          >
            <>
              {verticalOrientation ? getSpeedometerLabel(data) : null}
              {data.speedometerAverage.length > 0 || isNumeric(data.value) ? (
                <ParentSize debounceTime={10}>
                  {({ width: visWidth, height: visHeight }) =>
                    withTooltip<Props, SpeedometerChartDataset>(Speedometer)({
                      data,
                      name,
                      size: Math.min(visWidth, visHeight),
                      index,
                      handleGraphElementClicked,
                      graphCount: dataset.length,
                    })
                  }
                </ParentSize>
              ) : (
                <div className="speedometer__no-data-2">
                  <FormattedMessage id="insights.speedometer.no-data" />
                </div>
              )}
              {!verticalOrientation ? getSpeedometerLabel(data) : null}
            </>
          </div>
        </Fragment>
      ))}
      {dataset.length > 0 &&
      dataset[0].speedometerAverage.length > 0 &&
      dataset.reduce(
        (acc, curr) =>
          acc || !isNumeric(curr.avgValue) || !isNumeric(curr.value),
        false
      ) ? (
        <div className="speedometer__no-data-indicator">
          * <FormattedMessage id="insights.speedometer.no-data" />
        </div>
      ) : null}
    </div>
  ) : (
    <div className="speedometer__no-data">
      <FormattedMessage id="insights.speedometer.no-data" />
    </div>
  );
};

export default SpeedometerChart;
