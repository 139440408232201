import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { useRef } from "react";
import { ScaleSVG } from "@visx/responsive";
import { GroupedLegends, Legend } from "services/dashboard/dashboard.model";
import { defaultStyles, withTooltip } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { mod } from "utils/functions";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import classNames from "classnames";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import ParentSize from "@visx/responsive/lib/components/ParentSize";

const tooltipStyles = {
  ...defaultStyles,
  zIndex: 2,
  minWidth: 50,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
  fontSize: 15,
  width: "max-content",
};

const getDot = (color: string) => {
  const dotStyle = {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: `${color}`,
  };

  return <div style={dotStyle}> </div>;
};

type Props = {
  data: Legend[] | GroupedLegends[];
  index: number;
  className?: string;
  height?: any;
  size?: number;
  hasNoDate?: boolean;
  benchmarkType?: number;
  isDetailedView?: boolean;
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
};

const IndividualChart = ({
  data,
  size,
  className,
  index,
  height,
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  isDetailedView,
  hideTooltip,
  showTooltip,
  handleGraphElementClicked,
}: Props & WithTooltipProvidedProps<Legend>) => {
  let tooltipTimeout: number;
  const radius = size ? size / 2 : 75;
  const dataset = (data as Legend[]).filter((d) => d.legendData > 0);
  const radiusWidth = isDetailedView ? 90 : 115;
  const containerRef = useRef(null);
  return (
    <div
      ref={containerRef}
      className={className ? className : "ghg-pie-chart"}
      data-testid="individual-pie-chart"
    >
      <ScaleSVG width={size} height={size}>
        <Group top={radius} left={radius}>
          <Pie
            data={dataset}
            pieSort={(d1, d2) => d1.legendOrder - d2.legendOrder}
            pieValue={(data) => data.legendData ?? 0.1}
            outerRadius={radius}
            innerRadius={({ data }) => {
              const innerRadius =
                radiusWidth /
                (tooltipOpen &&
                tooltipData &&
                tooltipData.legendValue === data.legendValue
                  ? 4.5
                  : 4.5);
              return radius - innerRadius;
            }}
            cx={radius}
            cy={radius}
          >
            {(pie) =>
              pie.arcs.map((arc, i) => (
                <g
                  key={`${arc.data.legendValue}-${i}`}
                  onMouseLeave={() =>
                    (tooltipTimeout = window.setTimeout(() => {
                      hideTooltip();
                    }, 100))
                  }
                  onMouseMove={(e) => {
                    const container = (
                      containerRef.current as any
                    ).getBoundingClientRect();
                    if (tooltipTimeout) clearTimeout(tooltipTimeout);
                    showTooltip({
                      tooltipData: arc.data,
                      tooltipTop: e.clientY - container.y,
                      tooltipLeft: e.clientX - container.x,
                    });
                  }}
                  style={handleGraphElementClicked ? { cursor: "pointer" } : {}}
                  onClick={() =>
                    handleGraphElementClicked
                      ? handleGraphElementClicked(arc.data)
                      : null
                  }
                >
                  <path
                    d={pie.path(arc) || undefined}
                    fill={arc.data.legendColor}
                  />
                </g>
              ))
            }
          </Pie>
        </Group>
      </ScaleSVG>
      {tooltipOpen && tooltipData && (
        <div
          style={{
            ...tooltipStyles,
            top: tooltipTop,
            left: mod(index + 1, 4) === 0 ? "unset" : tooltipLeft,
            right:
              mod(index + 1, 4) === 0
                ? `calc(100% - ${tooltipLeft}px)`
                : "unset",
            position: "absolute",
          }}
        >
          {`${tooltipData.legendValue ?? ""} `}
          <strong>
            {tooltipData.legendDataPercent ? tooltipData.legendDataPercent : ""}
          </strong>{" "}
        </div>
      )}
    </div>
  );
};

const GhgEmissionPieChart = ({
  data,
  size = 130,
  handleGraphElementClicked,
  benchmarkType,
  hasNoDate,
  isDetailedView,
}: Props) => {
  const BLOCK = "main-tile";
  const flatData = data as GroupedLegends[];
  const dataset = flatData.flatMap((data: GroupedLegends) => data.legends);

  const noData = (data as GroupedLegends[]).every((group: GroupedLegends) => {
    return group.legends.every((legend: Legend) => legend.legendData === null);
  });

  const hasMissingData = (data as GroupedLegends[]).some(
    (group: GroupedLegends) => {
      return group.legends.some((legend: Legend) => legend.legendData === null);
    }
  );

  const companyView = (dataset: Legend[], size: number) => {
    const Pie = withTooltip<Props, Legend>(IndividualChart);
    return (
      <>
        <div
          className={`${BLOCK}__ghgEmissionPieChart`}
          data-testid="company-view"
        >
          {!noData && (
            <>
              {Pie({
                data: dataset,
                index: 0,
                size,
                handleGraphElementClicked,
              })}

              <div className={`${BLOCK}__ghgEmissionPieChart-container`}>
                {dataset.map((category: any, index: number) => (
                  <div
                    className={`${BLOCK}__ghgEmissionPieChart-container-category`}
                    key={`container-category${index}`}
                  >
                    <div
                      className={`${BLOCK}__ghgEmissionPieChart-container-detail`}
                    >
                      {category.legendDataPercent
                        ? getDot(category.legendColor)
                        : ""}

                      <Popover
                        metricClassName={`${BLOCK}__ghgEmissionPieChart-container-detail`}
                        displayText={category.legendValue}
                        content={category.legendTooltip ?? []}
                      />
                    </div>
                    <div
                      className={`${BLOCK}__ghgEmissionPieChart-container-value`}
                    >
                      {category.legendDataPercent
                        ? category.legendDataPercent
                        : "*"}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {noData && (
            <div className="speedometer__no-data speedometer__no-data--multiple-bar-chart">
              <FormattedMessage id="insights.speedometer.no-data" />
            </div>
          )}
        </div>
        {hasMissingData && !noData && !hasNoDate && (
          <div className={"DashboardTile__noDataAny"}>
            <FormattedMessage id="no.data.available" />
          </div>
        )}
      </>
    );
  };

  const peerIndustryView = ({
    data,
    size = 120,
    handleGraphElementClicked,
  }: Props) => {
    const dataset = data as GroupedLegends[];

    const hasAllmissingData = (group: GroupedLegends): boolean => {
      return group.legends.every((data: Legend) => data.legendData === null);
    };
    const hasSomeMissingData = (group: GroupedLegends): boolean => {
      return group.legends.some((data: Legend) => data.legendData === null);
    };
    let showNodata: boolean = false;

    !noData &&
      dataset.forEach((data: GroupedLegends) => {
        if (!hasAllmissingData(data) && hasSomeMissingData(data)) {
          showNodata = true;
        }
      });
    const Pie = withTooltip<Props, Legend>(IndividualChart);
    return (
      <>
        <div
          className={`${BLOCK}__ghgEmissionPieChartPeer`}
          data-testid="peer-view"
        >
          {!noData &&
            dataset.map((group, index) => (
              <div
                className={`${BLOCK}__ghgEmissionPieChartPeer-group`}
                key={`ghgEmissionPieChartPeer-${index}`}
              >
                <div
                  className={classNames(
                    `${BLOCK}__ghgEmissionPieChartPeer-groupName`
                  )}
                >
                  {group.groupName}
                </div>

                <div
                  className={`${BLOCK}__ghgEmissionPieChartPeer-chartContainer`}
                >
                  {Pie({
                    data: group.legends,
                    index,
                    className: `${BLOCK}__ghgEmissionPieChartPeer-peerChart`,
                    size,
                    handleGraphElementClicked,
                  })}
                  {!hasAllmissingData(group) ? (
                    <div
                      className={`${BLOCK}__ghgEmissionPieChartPeer-container`}
                    >
                      {group.legends.map((category: any, index: number) => (
                        <div
                          className={`${BLOCK}__ghgEmissionPieChartPeer-container-category`}
                          key={`container-category${index}`}
                        >
                          <div
                            className={`${BLOCK}__ghgEmissionPieChartPeer-container-detail`}
                          >
                            <span
                              className={`${BLOCK}__ghgEmissionPieChartPeer-legendDot`}
                            >
                              {category.legendDataPercent
                                ? getDot(category.legendColor)
                                : ""}
                            </span>
                            <div
                              className={`${BLOCK}__ghgEmissionPieChartPeer-container-metricWrapper`}
                            >
                              <Popover
                                metricClassName={`${BLOCK}__ghgEmissionPieChartPeer-container-metricName`}
                                displayText={category.legendValue}
                                content={category.legendTooltip ?? []}
                              />
                            </div>
                          </div>
                          <div
                            className={`${BLOCK}__ghgEmissionPieChartPeer-container-value`}
                          >
                            {category.legendDataPercent
                              ? category.legendDataPercent
                              : "*"}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={`${BLOCK}__ghgEmissionPieChartPeer-noData`}>
                      <FormattedMessage id="insights.speedometer.no-data" />
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        {noData && (
          <div className="speedometer__no-data speedometer__no-data--multiple-bar-chart">
            <FormattedMessage id="insights.speedometer.no-data" />
          </div>
        )}
        {hasMissingData && !noData && showNodata && !hasNoDate && (
          <div className={"DashboardTile__noDataAny"}>
            <FormattedMessage id="no.data.available" />
          </div>
        )}
      </>
    );
  };

  const detailedPeerView = ({
    data,
    size,
    handleGraphElementClicked,
  }: Props) => {
    const dataset = data as GroupedLegends[];

    const filteredDataset = dataset.filter(
      (data: GroupedLegends) =>
        !data.legends.every((data: Legend) => data.legendData === null)
    );
    const hasAllmissingData = (group: GroupedLegends): boolean => {
      return group.legends.every((data: Legend) => data.legendData === null);
    };

    const hasSomeMissingData = (group: GroupedLegends): boolean => {
      return group.legends.some((data: Legend) => data.legendData === null);
    };
    let showNodata: boolean = false;
    let companyHavingNoData: string[] = [];

    !noData &&
      dataset.forEach((data: GroupedLegends) => {
        if (!hasAllmissingData(data) && hasSomeMissingData(data)) {
          showNodata = true;
        } else if (hasAllmissingData(data)) {
          companyHavingNoData.push(data.groupName);
        }
      });

    const noDataCompanies = companyHavingNoData.join(", ");

    const Pie = withTooltip<Props, Legend>(IndividualChart);
    return (
      <>
        <div
          className={`${BLOCK}__ghgEmissionDetailPeer`}
          data-testid="detail-peer-view"
        >
          {!noData &&
            filteredDataset.map((group, index) => (
              <div key={`ghgEmissionPieChartPeer-${index}`}>
                {!hasAllmissingData(group) ? (
                  <ParentSize debounceTime={10}>
                    {({ width: visWidth, height: visHeight }) => (
                      <>
                        {Pie({
                          data: group.legends,
                          index,
                          className: `${BLOCK}__ghgEmissionDetailPeer-detailPeer`,
                          size,
                          isDetailedView,
                          handleGraphElementClicked,
                        })}
                      </>
                    )}
                  </ParentSize>
                ) : (
                  ""
                )}

                <div
                  className={classNames(
                    `${BLOCK}__ghgEmissionDetailPeer-groupName`
                  )}
                >
                  {group.groupName}
                </div>
              </div>
            ))}
        </div>
        <div className={`${BLOCK}__ghgEmissionDetailPeer-legend`}>
          {dataset.length > 0 &&
            dataset[0].legends.map((data: any, index: number) => (
              <div
                className={`${BLOCK}__ghgEmissionDetailPeer-legend-container`}
                key={`ghgEmissionDetailPeer-${index}`}
              >
                <span className={`${BLOCK}__ghgEmissionDetailPeer-legend-dot`}>
                  {getDot(data.legendColor)}
                </span>
                <span
                  className={`${BLOCK}__ghgEmissionDetailPeer-metricWrapper`}
                >
                  <Popover
                    metricClassName={`${BLOCK}__ghgEmissionPieChartPeer-container-metricName`}
                    displayText={data.legendValue}
                    content={data.legendTooltip ?? []}
                  />
                </span>
              </div>
            ))}
        </div>
        {companyHavingNoData.length > 0 && (
          <div className={`${BLOCK}__ghgEmissionDetailPeer-noDataMessage`}>
            <FormattedMessage
              id="ghg.emission.no.dada.available.message"
              values={{ noDataCompanies: noDataCompanies }}
            />
          </div>
        )}
      </>
    );
  };
  return benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
    ? companyView(dataset, size)
    : isDetailedView
    ? detailedPeerView({ data, index: 0, size: 120, handleGraphElementClicked })
    : peerIndustryView({ data, index: 0, size, handleGraphElementClicked });
};

export default GhgEmissionPieChart;
