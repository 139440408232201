import Icon from "components/shared/icon/icon";
import {
  GlobalCompanyGroup,
  HybridCompany,
} from "services/insights/insights.model";
import { CompanyFilter } from "services/search/filters.model";

type props = {
  BLOCK: string;
  peerCompanies: CompanyFilter[];
  unselectCompany: any;
  unselectGlobalCompanyMapping?: any;
  globalPeerCompanies?: Partial<GlobalCompanyGroup>[];
  hybridCompanies?: Partial<HybridCompany>[];
};

const PeerCompanies = ({
  BLOCK,
  peerCompanies,
  unselectCompany,
  unselectGlobalCompanyMapping,
  globalPeerCompanies,
  hybridCompanies,
}: props) => {
  const getPeerCompany = (company: CompanyFilter, index: number) => {
    return (
      <div
        className={`${BLOCK}__peerCompanies`}
        key={`peer-companies-${company.cikNumber}--${index}`}
        data-test="peer-companies"
      >
        <span>{company.companyName}</span>
        <button
          className={`${BLOCK}__remove`}
          data-test="unselect-option"
          onClick={() => unselectCompany(company, "peers")}
          data-testid="unselect-option"
        >
          <Icon name="cross" height={18} />
        </button>
      </div>
    );
  };

  const getGlobalGroup = (
    company: Partial<GlobalCompanyGroup>,
    ind: number
  ) => {
    return (
      <div
        className={`${BLOCK}__peerCompanies`}
        key={`peer-companies-${company.globalCompanyMappingId}--${ind}`}
        data-test="peer-companies"
      >
        <span>{company.globalCompanyMappingName}</span>
        <button
          className={`${BLOCK}__remove`}
          data-test="unselect-option"
          onClick={() => unselectGlobalCompanyMapping(company, "peers")}
          data-testid="unselect-option"
        >
          <Icon name="cross" height={18} />
        </button>
      </div>
    );
  };

  return (
    <>
      {hybridCompanies && hybridCompanies?.length > 0 ? (
        <>
          {hybridCompanies
            .sort((a: Partial<HybridCompany>, b: Partial<HybridCompany>) =>
              a.displayOrder &&
              b.displayOrder &&
              a.displayOrder > b.displayOrder
                ? 1
                : a.displayOrder === b.displayOrder
                ? 0
                : -1
            )
            .map((hc: Partial<HybridCompany>, hcIndex: number) =>
              hc.company
                ? getPeerCompany(hc.company, hcIndex)
                : hc.globalCompanyGroup
                ? getGlobalGroup(hc.globalCompanyGroup, hcIndex)
                : null
            )}
        </>
      ) : (
        <>
          {peerCompanies.length > 0 &&
            peerCompanies.map((company: CompanyFilter, index: number) => (
              <div
                className={`${BLOCK}__peerCompanies`}
                key={`peer-companies-${company.cikNumber}--${index}`}
                data-test="peer-companies"
              >
                <span>{company.companyName}</span>
                <button
                  className={`${BLOCK}__remove`}
                  data-test="unselect-option"
                  onClick={() => unselectCompany(company, "peers")}
                  data-testid="unselect-option"
                >
                  <Icon name="cross" height={18} />
                </button>
              </div>
            ))}

          {globalPeerCompanies &&
            globalPeerCompanies.length > 0 &&
            globalPeerCompanies
              .filter((g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping)
              .map((company: Partial<GlobalCompanyGroup>, ind: number) => (
                <div
                  className={`${BLOCK}__peerCompanies`}
                  key={`peer-companies-${company.globalCompanyMappingId}--${ind}`}
                  data-test="peer-companies"
                >
                  <span>{company.globalCompanyMappingName}</span>
                  <button
                    className={`${BLOCK}__remove`}
                    data-test="unselect-option-global"
                    onClick={() =>
                      unselectGlobalCompanyMapping(company, "peers")
                    }
                    data-testid="unselect-option"
                  >
                    <Icon name="cross" height={18} />
                  </button>
                </div>
              ))}
        </>
      )}
    </>
  );
};

export default PeerCompanies;
