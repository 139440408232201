import { useEffect } from "react";
import { TOAST_OPTIONS } from "utils/constants";
import Icon from "../icon/icon";

type Props = {
  BLOCK: string;
  toast: any;
  index: number;
  deleteToast: (listItemIndex: number) => void
};

const Toast = ({ BLOCK, toast, index, deleteToast }: Props) => {
  useEffect(() => {
    setTimeout(() => {
      deleteToast(0);
    }, TOAST_OPTIONS.dismissTime);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      key={index}
      className={`${BLOCK}__notification ${TOAST_OPTIONS.position}`}
    >
      <div>
        <p className={`${BLOCK}__message`}>{toast.description}</p>
      </div>
      <button className={`${BLOCK}__icon`} onClick={() => deleteToast(index)}>
        <Icon name="cross" height={20} />
      </button>
    </div>
  );
};

export default Toast;
