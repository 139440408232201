import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { MouseEventHandler, useEffect, useRef } from "react";
import Icon from "components/shared/icon/icon";
import classNames from "classnames";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";

export enum ModalWidth {
  small = "m-sm",
  large = "m-lg",
  xlarge = "m-xlg",
}

type Props = {
  show: boolean;
  handleClose?: MouseEventHandler;
  headerParams?: any;
  header?: any;
  children: JSX.Element;
  config?: boolean;
  fullscreen?: boolean;
  hideBackgroud?: boolean;
  wrapperClassName?: string;
  suggestedPeers?: boolean;
  tooltipSuggestedPeers?: string;
  customHeader?: JSX.Element;
  modalWidth?: ModalWidth;
};

const Modal = ({
  show,
  handleClose,
  headerParams,
  header,
  children,
  config = true,
  fullscreen = false,
  hideBackgroud,
  wrapperClassName,
  suggestedPeers,
  tooltipSuggestedPeers,
  customHeader,
  modalWidth,
}: Props) => {
  const BLOCK = "modalESG";
  const contentRef = useRef(null);

  useEffect(() => {
    const handleCloseOnEscape = (e: any) =>
      handleClose && (e.charCode || e.keyCode) === 27 ? handleClose(e) : null;

    document.body.addEventListener("keydown", handleCloseOnEscape);
    return () => {
      document.body.removeEventListener("keydown", handleCloseOnEscape);
    };
  }, [handleClose]);

  return (
    <div
      className={classNames(BLOCK, {
        [`${BLOCK}--show`]: show,
      })}
      onClick={config ? handleClose : () => {}}
      data-testid="component-modal-show"
    >
      <div
        ref={contentRef}
        className={classNames(`${BLOCK}__content`, {
          [`${BLOCK}__content--show`]: show,
          [`${BLOCK}__content--fullscreen`]: fullscreen,
          [`${BLOCK}__content--showBackground`]: !hideBackgroud,
          [`${wrapperClassName}`]: wrapperClassName,
          [`${BLOCK}__${modalWidth}`]: modalWidth,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        {header ? (
          <div className={`${BLOCK}__header`} data-test="modal-header">
            <h1 className={`${BLOCK}__header--h1--title`}>
              {headerParams ? (
                <FormattedMessage id={header} values={headerParams} />
              ) : (
                <FormattedMessage id={header} />
              )}
              {suggestedPeers && (
                <div className={`${BLOCK}__header--information`}>
                  <Icon name="information" height={24} width={24} />
                  <Tooltip position={TooltipPosition.bottom}>
                    <>{tooltipSuggestedPeers}</>
                  </Tooltip>
                </div>
              )}
            </h1>
            {handleClose && (
              <button
                className={`${BLOCK}__close`}
                data-testid="close-modal"
                onClick={handleClose}
              >
                <Icon name="cross" height={24} />
              </button>
            )}
          </div>
        ) : null}
        {!header && customHeader ? (
          <div className={`${BLOCK}__header`} data-test="modal-header">
            {customHeader}
            {handleClose && (
              <button
                className={`${BLOCK}__close`}
                data-testid="close-modal"
                onClick={handleClose}
              >
                <Icon name="cross" height={24} />
              </button>
            )}
          </div>
        ) : null}
        {fullscreen ? (
          children
        ) : (
          <div
            className={classNames(`${BLOCK}__body`, {
              [`${BLOCK}__body--hideBorder`]: hideBackgroud,
            })}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
