import { User } from "services/commons.model";
import { BaseModel, errorModel } from "utils/redux.utils";

export interface SavedShortListState {
  error?: errorModel;
  loading: boolean;
  SavedShortListFetch: SavedShortList;
  showDelete: boolean;
  showRename: boolean;
  showShare: boolean;
  deleteShortlist: any;
  renameShortlist: any;
  shareShortlist: any;
  sharedWithOptions: User[];
  sharedWith: User[];
  searchShareLoading: boolean;
  sortLoading: boolean;
  errorRename: boolean;
  renameSuccess: boolean;
  savedShortListRename: boolean;
  shareSuccess: boolean;
  savedShortlistCount: number;
}

export class SavedShortListModel extends BaseModel {
  constructor() {
    super({
      loading: false,
      SavedShortListFetch: [],
      showDelete: false,
      showRename: false,
      showShare: false,
      deleteShortlist: null,
      renameShortlist: null,
      shareShortlist: null,
      sharedWithOptions: [],
      sharedWith: [],
      searchShareLoading: false,
      sortLoading: false,
      errorRename: false,
      renameSuccess: false,
      savedShortListRename: false,
      shareSuccess: false,
      savedShortlistCount: 0,
    });
  }
  setError = (error: errorModel): SavedShortListState => this.merge({ error });
  setErrorRename = (): SavedShortListState =>
    this.merge({ errorRename: !this.state.errorRename });
  setRenameSuccess = (renameSuccess: boolean): SavedShortListState =>
    this.merge({ renameSuccess });
  setSavedRenameSuccess = (
    savedShortListRename: boolean
  ): SavedShortListState => this.merge({ savedShortListRename });
  setShareSuccess = (shareSuccess: boolean): SavedShortListState =>
    this.merge({ shareSuccess });
  setLoading = (loading: boolean): SavedShortListState =>
    this.merge({ loading });
  setSavedShortList = (
    SavedShortListFetch: SavedShortList[]
  ): SavedShortListState => this.merge({ SavedShortListFetch, loading: false });

  setDeleteLoading = (showDelete: boolean): SavedShortListState =>
    this.merge({ showDelete });

  setRenameLoading = (showRename: boolean): SavedShortListState =>
    this.merge({ showRename });

  setShareLoading = (showShare: boolean): SavedShortListState =>
    this.merge({ showShare });

  setDeleteItem = (deleteShortlist: any): SavedShortListState =>
    this.merge({ deleteShortlist });

  setRenameItem = (renameShortlist: any): SavedShortListState => {
    return this.merge({ renameShortlist });
  };

  setShareItem = (shareShortlist: any): SavedShortListState =>
    this.merge({ shareShortlist });

  loadSharedWithOptions = ({
    sharedWithOptions,
    currentUserId,
  }: {
    sharedWithOptions: User[];
    currentUserId: number;
  }): SavedShortListState =>
    this.merge({
      sharedWithOptions: sharedWithOptions.filter(
        (option: User) => option.userId !== currentUserId
      ),
    });

  setSearchLoading = (searchLoading: boolean): SavedShortListState => {
    return this.merge({ searchShareLoading: searchLoading });
  };

  searchSetSharedWith = (sharedWith: User[]): SavedShortListState =>
    this.merge({ sharedWith });

  setSortLoading = (sortLoading: boolean): SavedShortListState =>
    this.merge({ sortLoading });

  setSavedShortlistCount = (savedShortlistCount: number): SavedShortListState =>
    this.merge({ savedShortlistCount });
}
export type savedShortListPageInfo = {
  totalRecordCount: number;
  pageNumber: number;
  pageSize: number;
  totalPageCount: number;
  sortBy: string;
  sortOrder: string;
};

export type SavedShortList = {
  pageInfo: savedShortListPageInfo;
  shortlists: [];
};
