import api from "../../services/api.service";

// Get Downloads
export const getDownloadsList = () => {
  return api.get(`exports`).then(({ data }) => data);
};

// Get Binary for download
export const getDownloadBinary = (exportDetailId: number) => {
  return api
    .get(`exports/${exportDetailId}/binary`, { responseType: "blob" })
    .then((res) => {
      const file = new Blob([res.data], { type: res.headers["content-type"] });
      const fileURL = URL.createObjectURL(file);

      return fileURL;
    });
};

// Post call for Export
export const exportItem = (payload: any) => {
  return api.post(`exports`, payload).then(({ data }) => data);
};

export const exportMappingTemplate = (payload: any) =>
  api
    .get(`exports/${payload}/templatebinary`, { responseType: "blob" })
    .then((res) => {
      const file = new Blob([res.data], { type: "application/octet-stream" });
      const fileURL = URL.createObjectURL(file);
      return fileURL;
    });

export const exportChartItem = (payload: any) => {
  return api.post(`exports/create`, payload).then(({ data }) => data);
};

//Post call for search result grid
export const searchExport = (payload: any) => {
  return api
    .post(`search/export`, payload, {
      responseType: "blob",
    })
    .then((res) => {
      const file = new Blob([res.data], { type: res.headers["content-type"] });
      const fileURL = URL.createObjectURL(file);

      return fileURL;
    });
};

export const submitFeedback = (payload: any) => {
  return api.post(`userRatings`, payload).then(({ data }) => data);
};

//Get Binary raw download
export const getDownloadRawBinary = (exportDetailId: number) => {
  return api
    .get(`report/${exportDetailId}/binary/raw`, { responseType: "blob" })
    .then((res) => {
      const file = new Blob([res.data], { type: res.headers["content-type"] });
      const fileURL = URL.createObjectURL(file);

      return fileURL;
    });
};
