import FormattedMessage from "components/shared/formatted-message/formatted-message";
import SavedSearchesTable from "./saved-searches.table";
import { RootStore } from "services/store.service";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadSavedSearchList } from "services/searchlist/searchlist.service";
import Icon from "components/shared/icon/icon";
import { Link, useLocation } from "react-router-dom";
import emptyimg from "images/saved.shortlist-empty.png";
import { DEFAULT_SAVED_SEARCHES_PAYLOAD, Roles } from "utils/constants";
import Feedback from "../feedback/feedback";
import { submitFeedback } from "services/download/download.api";
import Button from "components/shared/button/button";
import useAppInsightsCustomMetric from "appinsights/app-insights-custom-metrics";

const MySearches = () => {
  const BLOCK = "my-searches";
  const dispatch = useDispatch();
  const savedSearchListState = useSelector(
    (state: RootStore) => state.searchlist
  );
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );
  const isSuperAdmin: boolean =
    currentUser?.adminRole?.adminRoleId === Roles.superAdmin;

  const location = useLocation();
  // App insights custom metric
  useAppInsightsCustomMetric(location.pathname);

  useEffect(() => {
    dispatch(loadSavedSearchList(DEFAULT_SAVED_SEARCHES_PAYLOAD));
  }, [dispatch]);

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const submitFeedbackHandler = ({
    feedback,
    rating,
  }: {
    feedback: string;
    rating: number;
  }) => {
    submitFeedback({ feedback, rating }).then();
  };

  return (
    <div className={`${BLOCK}`} data-test="my-searches-component">
      {savedSearchListState.savedSeachListLoading && (
        <div className={`${BLOCK}__empty-container`}>
          <Icon
            name={"loading"}
            width={50}
            height={50}
            className="loading-icon"
            data-test="loading-icon"
          />
        </div>
      )}
      {!savedSearchListState.savedSeachListLoading &&
        savedSearchListState.savedSearchList.savedSearches?.length > 0 && (
          <div className={`${BLOCK}__content`}>
            <header
              className={
                isSuperAdmin ? `${BLOCK}__header` : `${BLOCK}__notAdmin-header`
              }
            >
              {isSuperAdmin ? (
                <div></div>
              ) : (
                <div style={{ width: "50%", display: "inherit" }}>
                  <FormattedMessage id="my-searches-header" />
                </div>
              )}
              {!isSuperAdmin && (
                <div
                  className={
                    isSuperAdmin
                      ? `${BLOCK}__feedback-div`
                      : `${BLOCK}__notAdmin-feedback-div `
                  }
                >
                  <div className={`${BLOCK}__feedback-section`}>
                    <Button
                      className={`${BLOCK}__feedback-button button-primary-small`}
                      dataTest="feedback-button"
                      iconName="commenting"
                      iconHeight={24}
                      iconWidth={24}
                      formattedText="submit.feedback"
                      onClick={() => setShowFeedbackModal(true)}
                    />
                  </div>
                  {showFeedbackModal && (
                    <Feedback
                      data-test="feedback-button-modal"
                      setShowFeedbackModal={setShowFeedbackModal}
                      submitFeedback={submitFeedbackHandler}
                    />
                  )}
                </div>
              )}
            </header>
            <SavedSearchesTable />
          </div>
        )}
      {!savedSearchListState.savedSeachListLoading &&
        savedSearchListState.savedSearchList.savedSearches?.length === 0 && (
          <>
            {!isSuperAdmin && (
              <div className={`${BLOCK}__feedback-div`}>
                <div className={`${BLOCK}__feedback-section`}>
                  <Button
                    className={`${BLOCK}__feedback-button button-primary-small`}
                    dataTest="feedback-button"
                    iconName="commenting"
                    iconHeight={24}
                    iconWidth={24}
                    formattedText="submit.feedback"
                    onClick={() => setShowFeedbackModal(true)}
                  />
                </div>
                {showFeedbackModal && (
                  <Feedback
                    data-test="feedback-button-modal"
                    setShowFeedbackModal={setShowFeedbackModal}
                    submitFeedback={submitFeedbackHandler}
                  />
                )}
              </div>
            )}
            <div className={`${BLOCK}__empty`} data-test="empty-search-list">
              <div className={`${BLOCK}__img`}>
                <img className={""} src={emptyimg} alt={emptyimg} />
              </div>
              <div className={`${BLOCK}__emptytext`}>
                <FormattedMessage id="my-searches.empty" />
              </div>
              <div className={`${BLOCK}__emptyredirect`}>
                <FormattedMessage id="my-searches-empty-redirect" />
                &nbsp;
                <Link className={`${BLOCK}__link`} to={"/search"}>
                  <FormattedMessage id="my-searches-empty-redirect-link" />
                </Link>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default MySearches;
