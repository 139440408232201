import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useEffect, useState } from "react";
interface Map {
  [key: string]: string;
}

const PUBLIC_URL_ENV = process.env.PUBLIC_URL;
const CookieNotice = () => {
  const BLOCK = "cookie-notice";
  const [cookieNoticeData, setCookieNoticeData] = useState<any>({});
  const [cookieNoticeMetadata, setCookieNoticeMetadata] = useState<any>({});
  const HIDDEN_PREFIX = "hidden.";
  const fetchCookieData = async () => {
    try {
      await fetch("cookie.notice.metadata.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((jsonRes) => {
          setCookieNoticeMetadata(jsonRes);
        });
      await fetch("cookie.notice.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((jsonRes) => {
          setCookieNoticeData(jsonRes);
        });
    } catch (e) {}
  };

  useEffect(() => {
    fetchCookieData();
  }, []);

  const COOKIE_VARIABLES: Map = {
    PUBLIC_URL_LINK: PUBLIC_URL_ENV,
  };

  const getContentText = (content: any, variables: any) => {
    if (variables) {
      content = content.map((val: string) => {
        if (variables.hasOwnProperty(val)) {
          if (val in cookieNoticeData) {
            return getHtmlElement(val);
          } else if (COOKIE_VARIABLES.hasOwnProperty(val)) {
            return COOKIE_VARIABLES[`${val}`];
          }
          return variables[val];
        }

        return val;
      });
    }

    return content.filter((c: any) => c !== "");
  };

  const getHtmlProps = (properties: any = null) => {
    let props = {};
    if (properties) {
      for (const [key, value] of Object.entries(properties)) {
        if (
          typeof value !== "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          let stringVal = (value as string).split(/\{(.*?)\}/i);
          let newValue = getContentText(stringVal, properties.variables);
          props = { ...props, [key]: newValue };
        }
      }
    }
    return props;
  };

  const getHtmlElement = (jsonKey: string) => {
    let key = jsonKey in cookieNoticeData ? jsonKey : "default";
    const metadata =
      jsonKey in cookieNoticeMetadata
        ? cookieNoticeMetadata[`${jsonKey}`]
        : null;
    let content = (cookieNoticeData as any)[key].split(/\{(.*?)\}/i);
    key = key.replace(HIDDEN_PREFIX, "");
    const Tag = key.split(".")[0] as keyof JSX.IntrinsicElements;
    content = getContentText(
      content,
      metadata && metadata.hasOwnProperty("variables")
        ? metadata.variables
        : null
    );
    let children =
      metadata && metadata.hasOwnProperty("children")
        ? metadata.children
        : null;

    return (
      <Tag {...getHtmlProps(metadata)}>
        {content}
        {children && children.map((child: string) => getHtmlElement(child))}
      </Tag>
    );
  };

  const getHtml = () => {
    let currentHtml: any = [];
    {
      Object.keys(cookieNoticeData).forEach((key, index) => {
        if (!key.startsWith(HIDDEN_PREFIX)) {
          currentHtml.push(getHtmlElement(key));
        }
      });
    }
    return currentHtml.length === 0 ? null : currentHtml;
  };
  return (
    <>
      {Object.keys(cookieNoticeData).length > 0 && (
        <div className={`${BLOCK}__content`}>{getHtml()}</div>
      )}
    </>
  );
};

export default CookieNotice;
