import { mapTabularChartData, useTileContext } from "../tile.context";
import { INSIGHT_PEER_COMPANY } from "utils/constants";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import classNames from "classnames";
import { Fragment } from "react";
import Popover from "components/shared/popover/popover";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import TileEmptyContent from "../tile-empty-content";

const ReferencedFrameworks = () => {
  const {
    metadata,
    isTableViewActive,
    dataset,
    associatedMetrics,
    metadata: { benchmarkType },
  } = useTileContext();
  const BLOCK = "TabularTile";
  const tabularData = mapTabularChartData(metadata, dataset);
  const isCompanyView = benchmarkType !== 6 && benchmarkType !== 5;
  let hasEmptyValue = false;
  const noPeer =
    tabularData &&
    tabularData.data.filter(
      (d: any) => d.companyName !== INSIGHT_PEER_COMPANY.companyName
    );

  const dataToUse = isTableViewActive ? noPeer : tabularData.data;
  let hasNoData =
    tabularData && dataToUse.length
      ? dataToUse.reduce(
          (acc: boolean, { companyName, globalCompanyId, ...d }: any) =>
            acc && Object.values(d).every((v: any) => v === "*"),
          true
        )
      : true;

  if (hasNoData)
    return (
      <div className={`${BLOCK}__no-data`}>
        <TileEmptyContent />
      </div>
    );
  const getFormattedValue = (value: any) => {
    return value
      ? !isNaN(value)
        ? value > 1
          ? value
          : value > 0
          ? "Yes"
          : "No"
        : value
      : "*";
  };
  const getMetricData = (tabularData: any) => {
    return [
      tabularData.reduce(
        (data: any[], current: InsightData) => {
          let currentGroup: any = [];

          current.metrics.forEach((m: InsightMetricData) => {
            if (!current.isBaseCompany) {
              let tempValue = data.find((x) => x.metricTitle === m.metricName);

              if (tempValue) {
                tempValue.metricValues = [
                  tempValue.metricValues,
                  getFormattedValue(m.metricValue),
                ];
                tempValue.metricValuesTooltip = [
                  tempValue.metricValuesTooltip,
                  current.companyName,
                ];
                return [data];
              }
            }
            currentGroup = [
              ...currentGroup,
              {
                isHeader: false,
                metricValuesTooltip: [current.companyName],
                metricDescription: m.metricTooltip,
                metricValues: [getFormattedValue(m.metricValue)],
                metricTitle: m.metricName,
              },
            ];
          });

          return [...data, ...currentGroup];
        },
        [
          {
            isHeader: true,
            metricValuesTooltip: [],
            metricDescription: [],
            metricValues: [],
            metricTitle: "",
          },
        ]
      ),
    ];
  };
  const [metricData] = getMetricData(dataset);
  hasEmptyValue = metricData.find((x: any) =>
    x.metricValues.some((value: any) => value.includes("*"))
  );
  return (
    <>
      <div
        className={classNames(`${BLOCK}__content`, {})}
        data-testid={`${BLOCK}`}
      >
        {!hasNoData && (
          <div
            className={classNames(
              `${BLOCK}__metrics ${BLOCK}--framework__metrics`
            )}
          >
            {dataToUse.map((d: any, index: number) => {
              const noCompanyData =
                Object.keys(d).filter(
                  (key: any) =>
                    associatedMetrics.includes(key) && d[key] === "*"
                ).length ===
                tabularData.metrics.filter((m) => m.metric !== "").length;

              return (
                <div
                  key={`metric-row-${index}`}
                  className={`${BLOCK}--framework__company-row`}
                >
                  {isCompanyView ? null : (
                    <div className={`${BLOCK}--framework__company-data`}>
                      {d.companyName}
                      {noCompanyData ? "*" : ""}
                    </div>
                  )}
                  <div
                    className={`${BLOCK}__metric-row ${BLOCK}--framework__metric-row`}
                  >
                    {!noCompanyData &&
                      tabularData.metrics
                        .filter((m) => m.metric !== "")
                        .map((metric: any, metricIndex: number) => {
                          const metricValue =
                            d[metric.metricKey]?.toLowerCase();
                          return (
                            <Fragment key={`metric-value-${metricIndex}`}>
                              <div
                                className={classNames(
                                  `${BLOCK}__metric-value-wrapper ${BLOCK}--framework__metric-value-wrapper ${BLOCK}--framework__metric-value-wrapper--${metricValue}`
                                )}
                              >
                                <div
                                  className={classNames(
                                    `${BLOCK}__metric-value multiple ${BLOCK}--framework__metric-value ${BLOCK}--framework__metric-value--${metricValue} ${BLOCK}--framework__metric-value--no-padding`
                                  )}
                                >
                                  <span
                                    className={`${BLOCK}--framework__metric-value-container`}
                                  >
                                    <Popover
                                      metricClassName={`${BLOCK}--framework__metric-value--fs-12`}
                                      displayText={metric.metric}
                                      content={
                                        metricValue !== "neutral" &&
                                        metric.description &&
                                        metric.description.length
                                          ? metric.description
                                          : []
                                      }
                                      buttonClassName={`${BLOCK}__label-button ${BLOCK}--framework__metric-value  ${BLOCK}--framework__metric-value--${metricValue} `}
                                    />
                                  </span>

                                  {metricValue === "neutral" ? (
                                    <span
                                      className={`${BLOCK}--framework__neutral-label`}
                                    >
                                      {metricValue?.charAt(0).toUpperCase() +
                                        metricValue?.slice(1)}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {metricValue === "neutral" && (
                                  <Tooltip position={TooltipPosition.bottom}>
                                    <FormattedMessage id="insights.tabular.neutral" />
                                  </Tooltip>
                                )}
                              </div>
                            </Fragment>
                          );
                        })}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {!hasNoData && hasEmptyValue && (
          <span className={`${BLOCK}__rating-legend`}>
            <FormattedMessage id="no.data.available" />
          </span>
        )}
      </div>
    </>
  );
};

export default ReferencedFrameworks;
