import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";

type TabbedContentProps = {
  onChange: any;
  className?: string;
  tabItems: TabbedContentItemProps[];
  selectedIndex: number;
  tabbedContentRef?: any;
  isPopover?: boolean;
  isPeerBenchmarkEdit?: boolean;
};

export type TabbedContentItemProps = {
  text: string;
  tabId: string;
  tabPanelId: string;
  variable?: any;
  isRegularText?: boolean;
  className?: string;
  renderleftContent?: any;
};

const TabbedContent = ({
  className,
  onChange,
  tabItems,
  selectedIndex,
  tabbedContentRef,
  isPopover,
  isPeerBenchmarkEdit,
}: TabbedContentProps) => {
  const BLOCK = "tabbed-content";
  const shortlistResults = useSelector(
    (state: RootStore) => state.shortlist.results
  );
  const insightState = useSelector((state: RootStore) => state.insights);

  let shortlistCount = 0;
  if (shortlistResults !== null) {
    shortlistCount =
      shortlistCount +
      (shortlistResults.shortlistItems !== null
        ? shortlistResults.pageInfo.totalRecordCount
        : 0);
  }
  const tabbedContentReference = tabbedContentRef
    ? { ref: tabbedContentRef }
    : {};

  return (
    <div
      className={`${BLOCK} ${className ? className : ""}`}
      role="tablist"
      {...tabbedContentReference}
    >
      {tabItems.map((item, index) => (
        <button
          role="tab"
          data-test="tab-change-button"
          data-testid={item.tabId}
          aria-selected={selectedIndex === index}
          aria-controls={item.tabPanelId}
          className={`${BLOCK}__item ${
            selectedIndex === index ? BLOCK + "__item--selected" : ""
          } ${item.className ? item.className : ""} ${
            selectedIndex === index && isPopover
              ? BLOCK + "__item--selectedPopover"
              : ""
          }${
            isPeerBenchmarkEdit && selectedIndex !== index
              ? BLOCK + "__item--disabled"
              : ""
          } ${
            item.className && selectedIndex === index
              ? `${item.className}--selected`
              : ""
          }`}
          onClick={(e) => onChange(index, item.tabPanelId, e)}
          key={item.tabPanelId}
          style={
            item.tabId === "short-list-view--tab" ||
            item.tabId === "insightsPath-view--tab"
              ? { display: "flex", flexDirection: "row", textAlign: "center" }
              : {}
          }
          id={item.tabId}
          disabled={isPeerBenchmarkEdit && selectedIndex !== index}
        >
          {item.renderleftContent ? item.renderleftContent : null}
          {item.variable ? `${item.variable} ` : ""}

          {item.isRegularText ? item.text : <FormattedMessage id={item.text} />}
          {"   "}

          {item.tabId === "short-list-view--tab" && shortlistCount !== 0 && (
            <span
              className={`${BLOCK}__item__shortlist-icon`}
              data-test="tabbed-content-count"
            >
              <span className={`${BLOCK}__item__shortlist-icon-content`}>
                {shortlistCount.toString()}
              </span>
            </span>
          )}
          {item.tabId === "insightsPath-view--tab" &&
            insightState.insightsCount !== 0 && (
              <span
                className={`${BLOCK}__item__insight-icon`}
                data-test="tabbed-content-count"
              >
                <span className={`${BLOCK}__item__shortlist-icon-content`}>
                  {insightState.insightsCount.toString()}
                </span>
              </span>
            )}
        </button>
      ))}
    </div>
  );
};

export default TabbedContent;
