import EnergyChart from "components/visualizations/charts/energy.chart";
import { Legend } from "services/dashboard/dashboard.model";
import { InsightCompanyFlat } from "services/insights/insights.model";
import TileEmptyContent from "../tile-empty-content";
import { useTileContext } from "../tile.context";
import { mapCompanies, mapEnergySpendData } from "./EnergyConsumptionTotals";

const PercentageEnergySpend = () => {
  const {
    metadata,
    dataset,
    originalDataset,
    metadata: { benchmarkType, isDetailedView },
    isTableViewActive,
  } = useTileContext();
  // REMOVE WHEN ENERGY CHART IS REFACTORED

  if (mapEnergySpendData(dataset).every((d: Legend) => d.legendValue === "")) {
    return (
      <div>
        <TileEmptyContent />
      </div>
    );
  }
  const companies = mapCompanies(originalDataset);

  const tileProps = {
    data: mapEnergySpendData(dataset),
    benchmarkTileType: metadata.benchmarkTileType,
    isDetailedView: isDetailedView,
    isTableView: isTableViewActive,
    currentInsightView: benchmarkType,
    baselineCompany: {
      company: {
        companyName: companies.filter(
          (c: InsightCompanyFlat) => c.isBaseCompany
        )[0].companyName,
      },
    },
  };

  return <EnergyChart {...tileProps} />;
};

export default PercentageEnergySpend;
