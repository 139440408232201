import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TOAST_OPTIONS } from "utils/constants";
import { removeToastMessage } from "services/commons.service";
import Toast from "./toast";
import { RootStore } from "services/store.service";
import { ToastMessage } from "services/commons.model";

const ToastContainer = () => {
  const BLOCK = "toast";
  const dispatch = useDispatch();
  const commons = useSelector((state: RootStore) => state.commons);
  const [list, setList] = useState<ToastMessage[]>([]);

  useEffect(() => {
    setList(commons.toastMessages);

    // eslint-disable-next-line
  }, [commons.toastMessages]);

  const deleteToast = (listItemIndex: number) => {
    dispatch(removeToastMessage(listItemIndex));
  };

  return (
    <>
      <div className={`${BLOCK}__container ${TOAST_OPTIONS.position}`} data-testid="toast-container">
        {list.map((toast, i) => (
          <Toast
            BLOCK={BLOCK}
            toast={toast}
            index={i}
            deleteToast={deleteToast}
            key={i}
          />
        ))}
      </div>
    </>
  );
};

export default ToastContainer;
