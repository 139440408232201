import WaterIntensityChart from "components/visualizations/charts/water-intensity.chart";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import TileEmptyContent from "../tile-empty-content";
import { mapTableChart, useTileContext } from "../tile.context";
import { INSIGHT_BENCHMARK_TYPE, UNITS } from "utils/constants";
import TableChart from "components/visualizations/charts/table-chart";
import classNames from "classnames";

const WaterIntensityTile = () => {
  const {
    metadata,
    dataset,
    metadata: { benchmarkType, isDetailedView },
    isTableViewActive,
  } = useTileContext();

  const BLOCK = "waterIntensityTile";

  if (
    dataset.length === 0 ||
    dataset.every((d: InsightData) =>
      d.metrics.every((m: InsightMetricData) => !m.metricValue)
    )
  )
    return <TileEmptyContent />;

   const getMetricValue = (value: any) => {
     let metricValue = Number(value).toFixed(1) as any;
     return !isNaN(metricValue)
       ? new Intl.NumberFormat().format(metricValue)
       : value;
   };

   const mapData = () =>
     dataset.map((d: InsightData) => ({
       ...d,
       metrics: d.metrics.map((m: InsightMetricData) => ({
         ...m,
         metricValue: m.metricValue
           ? isTableViewActive &&
             benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY
             ? `${
                 !isNaN(m.metricValue)
                   ? getMetricValue(m.metricValue) + " " + UNITS.WATER_INTENSITY
                   : Number(m.metricValue).toFixed(1)
               }`
             : Number(m.metricValue).toFixed(1)
           : "*",
         metricTooltip: m.metricTooltip ? m.metricTooltip : [],
         metricUnit: UNITS.WATER_INTENSITY,
       })),
     }));

  const tileProps = {
    data: mapData(),
    isDetailedView,
    isTableViewActive,
    benchmarkType,
  };

  return (
    <div
      className={`${BLOCK}__container`}
      data-testid={`${BLOCK}__container`}
    >
      {isTableViewActive && benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY ? (
        <div
          className={classNames(
            `${BLOCK}__table-wrapper ${BLOCK}__table-wrapper--company-peer`,
            {
              [`${BLOCK}__industry-table`]:
                benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY,
            }
          )}
        >
          <TableChart
            data={mapTableChart(mapData(), metadata)}
            currentInsightView={benchmarkType}
            hideTableHeader={benchmarkType !== INSIGHT_BENCHMARK_TYPE.COMPANY}
            rightAlignment={true}
            horizontalTable={benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY}
          />
        </div>
      ) : (
        <div
          className={classNames({
            [`${BLOCK}__peerChart-Content`]: !isDetailedView,
            [`${BLOCK}__detailChart-Content`]:
              benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
              isDetailedView,
          })}>
          <WaterIntensityChart {...tileProps} />
        </div>
      )}
    </div>
  );
};

export default WaterIntensityTile;
