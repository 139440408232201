import { useEffect, useLayoutEffect, useState } from "react";
import {
  AssociatedMetricTooltips,
  Visualization,
  VisualizationFilter,
} from "services/dashboard/dashboard.model";
import {
  GlobalCompanyGroup,
  GlobalCompanyMapping,
  InsightCompanyFlat,
  InsightData,
} from "services/insights/insights.model";
import {
  BENCHMARK_TILE_TYPES,
  GRAPH_OPTIONS,
  INSIGHT_BENCHMARK_TYPE,
  INSIGHT_INDUSTRY_COMPANY,
  INSIGHT_PEER_COMPANY,
  MERGE_TOOLTIP_TITLE,
  ESRS_DEFAULT_ROW_TILE_GROUPS,
  ESRS_WORKFORCE_ROW_TILE_GROUPS_COMPANY_VIEWS,
  ESRS_WORKFORCE_ROW_TILE_GROUPS_NON_COMPANY_VIEWS,
  EXCEPTION_FOR_SnP_METRICS_FILTER,
} from "utils/constants";
import {
  fetchVisualizationDataGet,
  fetchVisualizationDataPost,
} from "services/dashboard/dashboard.api";
import TileContext, {
  cleanCompanies,
  filterDataset,
  responseFunctions,
} from "./tile.context";
import TileFooter from "./tile.footer";
import TileHeader from "./tile.header";
import classNames from "classnames";
import useElementSize from "utils/useElementSize";
import PillarScore from "./tiles/PillarScore";
import CDPScope3CategoriesReported from "./tiles/CDPScope3CategoriesReported";
import GHGEmissionTotals from "./tiles/GHGEmissionTotals";
import GovernanceDetails from "./tiles/GovernanceDetails";
import RenewableEnergy from "./tiles/RenewableEnergy";
import Climate from "./tiles/Climate";
import MSCIESGRating from "./tiles/MSCIESGRating";
import Carbon from "./tiles/Carbon";
import Links from "./tiles/Links";
import OtherSustainabilityRankings from "./tiles/OtherSustainabilityRankings";
import AddAdditionalInformation from "./tiles/AddAdditionalInformation";
import RelevantRisks from "./tiles/RelevantRisks";
import ClimateRelatedTabular from "./tiles/ClimateRelatedTabular";
import ReportingBoundary from "./tiles/ReportingBoundary";
import TimeHorizons from "./tiles/TimeHorizons";
import Reporting from "./tiles/Reporting";
import EnergyRelatedActivitiesUndertaken from "./tiles/EnergyRelatedActivitiesUndertaken";
import PercentageEnergySpend from "./tiles/PercentageEnergySpend";
import ReferencedFrameworks from "./tiles/ReferencedFrameworks";
import { getLegendTooltip } from "utils/functions";
import TileEmptyContent from "./tile-empty-content";
import Verification from "./tiles/Verification";
import EnergyConsumptionTotals from "./tiles/EnergyConsumptionTotals";
import TargetTile from "./tiles/TargetTile";
import ReportingBoundaryClimateTile from "./tiles/ReportingBoundaryClimateTile";
import GHGIntensity from "./tiles/GHGIntensity";
import WaterTileComponent from "./tiles/WaterTile";
import TargetsTile from "./tiles/TargetsTile";
import RiskAndOpportunities from "./tiles/RiskAndOpportunities";
import ReportingDisclosure from "./tiles/Reporting-Disclosure-GHG";
import GhgTarget from "./tiles/GhgTarget";
import OpportinitiesRenewableEnergyComponent from "./tiles/OpportinitiesRenewableEnergy";
import WaterStressComponent from "./tiles/WaterStress";
import Waste from "./tiles/Waste";
import ProductSafetyAndQualityGradient from "./tiles/ProductSafetyAndQualityGradient";
import DirectIndirectCost from "./tiles/DirectIndirectCost";
import WaterIntensityTile from "./tiles/WaterIntensity";
import ToxicEmissionsWasteScore from "./tiles/ToxicEmissionsWasteScore";
import CircularBarChartComponent from "./tiles/CircularBarChartTile";
import RawMaterialAndSourcingScore from "./tiles/RawMaterialAndSourcingScore";
import SupplyChainLaborScore from "./tiles/SupplyChainLaborScore";
import BusinessEthicsAndFraudScore from "./tiles/BusinessEthicsAndFraudScore";
import OwnWorkforce from "./tiles/OwnWorkforce";
import BiodiversityManagement from "./tiles/BiodiversityManagement";
import ClimateRelatedDisclosuresAndMetrics from "./tiles/ClimateRelatedDisclosuresAndMetrics";
import { isEmpty } from "lodash";
import ClimateRelatedIncentive from "./tiles/ClimateRelatedIncentive";
import EnergyConsumption from "./tiles/EnergyConsumption";
import CarbonCredit from "./tiles/CarbonCredit";
import WorkforceRelatedScore from "./tiles/WorkforceRelatedScore";

const MainTile = ({
  metadata,
  companies,
  index,
  toggleDetailView,
  setTableViewSelected,
  selectedInsightView,
  exportAllLoading,
  selectedExport,
  dataPayload,
  globalCompanyMappings,
  currentTabId,
  isESRS,
  toggleClicked,
  setToggleClicked,
  dataResponse,
  setDataResponse,
}: {
  index: number;
  metadata: Visualization;
  companies: InsightCompanyFlat[];
  toggleDetailView: Function;
  setTableViewSelected: Function;
  selectedInsightView: number | null;
  exportAllLoading: boolean;
  selectedExport: string;
  dataPayload: any;
  globalCompanyMappings: GlobalCompanyGroup[];
  currentTabId: number;
  isESRS?: boolean;
  toggleClicked: boolean;
  setToggleClicked: Function;
  dataResponse: any;
  setDataResponse: Function;
}) => {
  const BLOCK = "main-tile";
  const tiles: any = {
    1: PillarScore,
    3: CDPScope3CategoriesReported,
    4: ReferencedFrameworks,
    5: GHGEmissionTotals,
    6: MSCIESGRating,
    7: GovernanceDetails,
    8: GovernanceDetails,
    10: RenewableEnergy,
    11: RenewableEnergy,
    12: Climate,
    13: MSCIESGRating,
    14: Carbon,
    21: Links,
    23: OtherSustainabilityRankings,
    25: AddAdditionalInformation,
    26: RelevantRisks,
    27: ClimateRelatedTabular,
    28: ReportingBoundary,
    29: TimeHorizons,
    30: EnergyConsumptionTotals,
    31: Reporting,
    32: EnergyRelatedActivitiesUndertaken,
    33: PercentageEnergySpend,
    34: GovernanceDetails,
    37: TargetsTile,
    38: TargetTile,
    39: Verification,
    36: Verification,
    40: GovernanceDetails,
    41: TargetTile,
    42: TargetTile,
    43: TargetTile,
    44: TargetTile,
    45: TargetTile,
    46: GHGEmissionTotals,
    47: GHGEmissionTotals, // AbsoluteScope3UpstreamDownstream
    48: ReportingDisclosure,
    49: ReportingBoundaryClimateTile,
    50: GHGIntensity,
    51: WaterTileComponent,
    52: RiskAndOpportunities,
    53: GhgTarget,
    54: ClimateRelatedTabular, // Scenario Analysis,
    55: ClimateRelatedTabular, //ESRS Climate Related Management
    61: CircularBarChartComponent, // Corporate Governance Theme Score
    62: CircularBarChartComponent, // Social Opportunities Theme Score
    63: OpportinitiesRenewableEnergyComponent,
    64: WaterStressComponent,
    65: WaterIntensityTile,
    66: RawMaterialAndSourcingScore,
    67: BusinessEthicsAndFraudScore,
    68: ToxicEmissionsWasteScore,
    69: Waste,
    70: ProductSafetyAndQualityGradient,
    71: DirectIndirectCost,
    72: ClimateRelatedTabular, //Climate Related Value chain engagement
    73: GovernanceDetails, // ESRS
    74: OwnWorkforce,
    75: CircularBarChartComponent, // Biodiversity and Land Use Score
    76: SupplyChainLaborScore,
    77: BiodiversityManagement,
    78: ClimateRelatedDisclosuresAndMetrics,
    79: ClimateRelatedTabular, // Climate-related risk management
    80: CarbonCredit,
    81: ClimateRelatedIncentive,
    82: EnergyConsumption,
    83: WorkforceRelatedScore, //Health and Safety Score
    84: WorkforceRelatedScore, //Labor Management Score
    85: WorkforceRelatedScore, //Human Capital Development Score
    86: GHGEmissionTotals, //GHG Emission Totals ESRS
  };

  const Component = tiles[metadata.benchmarkTileType];

  const [loading, setLoading] = useState<boolean>(true);
  const [isTableViewActive, setIsTableViewActive] = useState<boolean>(
    metadata.benchmarkMetadata.bechmarkStyleOptions.indexOf(
      metadata.benchmarkMetadata.selectedStyleOption
    ) !== 0
  );

  const [isPieViewActive, setIsPieViewActive] = useState<boolean>(
    metadata.benchmarkMetadata?.selectedChartOption === GRAPH_OPTIONS.pie ??
      false
  );

  const [tileRef, { height }] = useElementSize([isTableViewActive, loading]);
  const [filters, setFilters] = useState<VisualizationFilter[]>(
    metadata.benchmarkMetadata.filters
  );
  const [dataset, setDataset] = useState<InsightData[]>([]);
  const [originalDataset, setOriginalDataset] = useState<InsightData[]>([]);
  const [response, setResponse] = useState<any[]>([]);
  const [detailedDataForResp, setdetailedDataForResp] = useState<any[]>([]);
  const [associatedMetrics, setAssociatedMetrics] = useState<any[]>(
    metadata.benchmarkMetadata.associatedMetrics
  );
  const [associatedMetricsDisplayNames, setAssociatedMetricsDisplayNames] =
    useState<any[]>(metadata.benchmarkMetadata.associatedMetricsDisplayNames);

  const [footerChild, setFooterChild] = useState<any>(null);
  const [manualHScale, setManualHScale] = useState<number>(-1);

  const ESRS_ROW_TILE_GROUPLIST = [
    ...ESRS_DEFAULT_ROW_TILE_GROUPS,
    currentTabId === INSIGHT_BENCHMARK_TYPE.COMPANY && !isTableViewActive
      ? ESRS_WORKFORCE_ROW_TILE_GROUPS_COMPANY_VIEWS
      : ESRS_WORKFORCE_ROW_TILE_GROUPS_NON_COMPANY_VIEWS,
  ];
  const fixedHeightRowIndex = ESRS_ROW_TILE_GROUPLIST.findIndex((rows) =>
    rows.includes(metadata.benchmarkTileType)
  );

  useEffect(() => {
    const abortController = new AbortController();
    if (
      !toggleClicked &&
      isEmpty(dataResponse) &&
      !dataResponse[metadata.benchmarkTileType]
    ) {
      fetchChartAPIData();
    } else {
      fetchFromStore();
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (detailedDataForResp.length > 0) {
      setDataset(detailedDataForResp);
      setLoading(false);
    }
  }, [detailedDataForResp]);

  useEffect(() => {
    if (!loading) setLoading(true);
    if (
      currentTabId === INSIGHT_BENCHMARK_TYPE.COMPANY ||
      currentTabId === INSIGHT_BENCHMARK_TYPE.INDUSTRY
    ) {
      setDataResponse([]);
      setdetailedDataForResp([]);
    }
  }, [currentTabId]);

  // Change to table/chart view when changing parent toggle
  useEffect(() => {
    if (selectedInsightView !== null) {
      setIsTableViewActive(selectedInsightView !== 0);
      setDataset(
        filterDataset(
          filters.length ? filters : [],
          originalDataset,
          metadata.benchmarkType,
          !!metadata.isDetailedView,
          selectedInsightView !== 0,
          metadata.benchmarkTileType
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInsightView]);

  // Change to chart view when exporting
  useEffect(() => {
    const tableViewActiveStatus = exportAllLoading
      ? false
      : metadata.benchmarkMetadata.bechmarkStyleOptions.indexOf(
          metadata.benchmarkMetadata.selectedStyleOption
        ) !== 0;
    setIsTableViewActive(tableViewActiveStatus);
    // AS WE ARE REMOVING SNP METRICS ONLY FOR PPT EXPORT
    if (exportAllLoading && selectedExport === "ppt") {
      if (
        metadata.benchmarkMetadata.snpMetrics &&
        metadata.benchmarkMetadata.snpMetrics.length > 0
      ) {
        //Filter metrics
        let tmpAssociatedMetricsDisplayNames = associatedMetricsDisplayNames;
        let tmpAssociatedMetrics = associatedMetrics;
        if (
          associatedMetrics.length > 0 &&
          typeof associatedMetrics[0] === "string"
        ) {
          let amIndexes: number[] = [];
          tmpAssociatedMetrics = associatedMetrics.filter(
            (am: any, i: number) => {
              if (
                metadata.benchmarkMetadata.snpMetrics &&
                !metadata.benchmarkMetadata.snpMetrics.includes(am)
              )
                amIndexes.push(i);
              return (
                metadata.benchmarkMetadata.snpMetrics &&
                !metadata.benchmarkMetadata.snpMetrics.includes(am)
              );
            }
          );
          //Filter display names
          amIndexes.length > 0 &&
            tmpAssociatedMetricsDisplayNames.filter((amdn: any, ind: number) =>
              amIndexes.includes(ind)
            );
        }

        if (
          tmpAssociatedMetrics.length > 0 &&
          typeof tmpAssociatedMetrics[0] === "object"
        ) {
          //filter metrics for grouped values
          let amIndexes: { groupName: string; indexes: number[] }[] = [];
          if (
            !EXCEPTION_FOR_SnP_METRICS_FILTER.includes(
              metadata.benchmarkTileType
            )
          ) {
            tmpAssociatedMetrics.forEach(
              (m: any) =>
                Object.hasOwn(m, "groupValues") &&
                (m.groupValues = m.groupValues.filter(
                  (gv: any, gvindex: number) => {
                    if (
                      metadata.benchmarkMetadata.snpMetrics &&
                      !metadata.benchmarkMetadata.snpMetrics.includes(gv)
                    ) {
                      let currIndex =
                        amIndexes.length === 0
                          ? -1
                          : amIndexes.findIndex(
                              (v) => v.groupName === m.groupName
                            );
                      if (currIndex !== -1)
                        amIndexes[currIndex].indexes.push(gvindex);
                      else
                        amIndexes.push({
                          groupName: m.groupName,
                          indexes: [gvindex],
                        });
                    }

                    return (
                      metadata.benchmarkMetadata.snpMetrics &&
                      !metadata.benchmarkMetadata.snpMetrics.includes(gv)
                    );
                  }
                ))
            );
          }

          //Filter display names for grouped metrics
          if (amIndexes.length > 0) {
            //filter groups first
            tmpAssociatedMetricsDisplayNames =
              tmpAssociatedMetricsDisplayNames.filter(
                (amdn: any) =>
                  amIndexes.findIndex((v) => v.groupName === amdn.groupName) !==
                  -1
              );
            //filter metrics inside each group
            tmpAssociatedMetricsDisplayNames.forEach((amdn: any) => {
              amdn.groupValues = amdn.groupValues.filter(
                (gv: any, i: number) => {
                  let currIndex = amIndexes.findIndex(
                    (v) => v.groupName === amdn.groupName
                  );
                  return (
                    currIndex !== -1 && amIndexes[currIndex].indexes.includes(i)
                  );
                }
              );
            });
          }
        }
        setAssociatedMetrics(tmpAssociatedMetrics);
        setAssociatedMetricsDisplayNames(tmpAssociatedMetricsDisplayNames);
      }

      setDataset(
        filterDataset(
          filters.length ? filters : [],
          metadata.benchmarkMetadata.snpMetrics &&
            metadata.benchmarkMetadata.snpMetrics.length > 0
            ? originalDataset.map((dataset) => {
                return {
                  ...dataset,
                  metrics: dataset.metrics.filter(
                    (metric: any) =>
                      !metadata.benchmarkMetadata.snpMetrics?.includes(
                        metric.metricKey
                      )
                  ),
                };
              })
            : originalDataset,
          metadata.benchmarkType,
          !!metadata.isDetailedView,
          tableViewActiveStatus,
          metadata.benchmarkTileType
        )
      );
    } else {
      //Reset associated metrics and associated metrics display names when metrics have been filtered
      if (
        metadata.benchmarkMetadata.snpMetrics &&
        metadata.benchmarkMetadata.snpMetrics.length > 0
      ) {
        setAssociatedMetrics(metadata.benchmarkMetadata.associatedMetrics);
        setAssociatedMetricsDisplayNames(
          metadata.benchmarkMetadata.associatedMetricsDisplayNames
        );
      }

      setDataset(
        filterDataset(
          filters.length ? filters : [],
          originalDataset,
          metadata.benchmarkType,
          !!metadata.isDetailedView,
          tableViewActiveStatus,
          metadata.benchmarkTileType
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportAllLoading]);

  // Logic to set fixed height for all tiles in a row based on largest tile
  useLayoutEffect(() => {
    if (fixedHeightRowIndex !== -1) {
      for (let index = 0; index < ESRS_ROW_TILE_GROUPLIST.length; index++) {
        let maxTableHeight = 0;
        let maxChartHeight = 0;
        const fixedHeightChartTiles = document.querySelectorAll(
          `.${BLOCK}-fixedHeightTile--chartView--${index}`
        );
        const fixedHeightTableTiles = document.querySelectorAll(
          `.${BLOCK}-fixedHeightTile--tableView--${index}`
        );
        if (fixedHeightChartTiles.length > 0) {
          fixedHeightChartTiles.forEach((tile: any) => {
            tile.style.minHeight = "fit-content";
            maxChartHeight = Math.max(maxChartHeight, tile.offsetHeight);
          });
          fixedHeightChartTiles.forEach((tile: any) => {
            tile.style.minHeight = `${maxChartHeight}px`;
          });
        }
        if (fixedHeightTableTiles.length > 0) {
          fixedHeightTableTiles.forEach((tile: any) => {
            tile.style.minHeight = "fit-content";
            maxTableHeight = Math.max(maxTableHeight, tile.offsetHeight);
          });
          fixedHeightTableTiles.forEach((tile: any) => {
            tile.style.minHeight = `${maxTableHeight}px`;
          });
        }
      }
    }
  }, [loading, isTableViewActive]);

  const fetchFromStore = () => {
    const tileResponse = dataResponse[metadata.benchmarkTileType] ?? [];
    setResponse(tileResponse);
    const detailedData = cleanResponse(tileResponse, responseFunctions);

    setOriginalDataset(detailedData);
    const filteredDataset = filterDataset(
      filters.length ? filters : [],
      detailedData,
      metadata.benchmarkType,
      !!metadata.isDetailedView,
      isTableViewActive,
      metadata.benchmarkTileType
    );

    if (filterDataset.length > 0) {
      setdetailedDataForResp(filteredDataset);
      setDataset(filteredDataset);
    }

    if (toggleClicked) {
      setToggleClicked(false);
    }
  };

  const fetchChartAPIData = async () => {
    let dataApi = metadata.benchmarkMetadata.api.endPoint;

    const fetchVisualizationData = metadata.benchmarkMetadata.api.payload
      ? metadata.benchmarkTileType ===
        BENCHMARK_TILE_TYPES.ADD_ADITIONAL_INFORMATION
        ? fetchVisualizationDataGet
        : fetchVisualizationDataPost
      : fetchVisualizationDataGet;

    const cleanData =
      dataApi && !dataApi.includes("{")
        ? await fetchVisualizationData(dataApi, dataPayload).then(
            (response) => {
              // Map the response based on companies collection and associated metrics
              setResponse(response);
              if (
                metadata.benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK
              ) {
                setDataResponse((prevResponse: any) => {
                  return {
                    ...prevResponse,
                    [metadata.benchmarkTileType]: response,
                  };
                });
              }

              return cleanResponse(response, responseFunctions);
            }
          )
        : [];

    setOriginalDataset(cleanData);
    const filteredDataset = filterDataset(
      filters.length ? filters : [],
      cleanData,
      metadata.benchmarkType,
      !!metadata.isDetailedView,
      isTableViewActive,
      metadata.benchmarkTileType
    );
    setDataset(filteredDataset);
    setLoading(false);
  };

  // Used only for reporting tile
  const flatteMetric = (metricKey: string) =>
    (metadata.benchmarkMetadata as any)[metricKey]
      ? (metadata.benchmarkMetadata as any)[metricKey].reduce(
          (acc: string[], curr: any) => [...acc, ...(curr.groupValues ?? [])],
          []
        )
      : [];

  /*
   * Constructs the base data object used in each tile's mapping
   */
  const cleanResponse = (
    response: any[],
    responseFunctions: { searchMetric: any; getMetricFromResponse: any }
  ): InsightData[] => {
    // The list of companies is based on parent prop because API
    // response may omit a company when there is no data for a given metric
    const companyList = [
      ...cleanCompanies(globalCompanyMappings, companies),
      {
        companyName: INSIGHT_PEER_COMPANY.companyName,
        globalCompanyId: INSIGHT_PEER_COMPANY.globalCompanyId,
        externalCompanyId: INSIGHT_PEER_COMPANY.globalCompanyId,
      },
      {
        companyName: INSIGHT_INDUSTRY_COMPANY.companyName,
        globalCompanyId: INSIGHT_INDUSTRY_COMPANY.globalCompanyId,
        externalCompanyId: INSIGHT_INDUSTRY_COMPANY.globalCompanyId,
      },
    ];

    // Flatten the grouped values in reporting tile
    let [
      associatedMetrics,
      associatedMetricsDisplayNames,
      associatedMetricsValueTooltip,
      associatedMetricsTooltip,
    ] =
      metadata.benchmarkTileType === 31 ||
      metadata.benchmarkTileType === 37 ||
      metadata.benchmarkTileType === 49 ||
      metadata.benchmarkTileType === 48 ||
      metadata.benchmarkTileType === 52 ||
      metadata.benchmarkTileType === 53 ||
      metadata.benchmarkTileType === 3 ||
      metadata.benchmarkTileType === 69
        ? [
            flatteMetric("associatedMetrics"),
            flatteMetric("associatedMetricsDisplayNames"),
            flatteMetric("associatedMetricsValueTooltip"),
            flatteMetric("associatedMetricsTooltip"),
          ]
        : [
            metadata.benchmarkMetadata.associatedMetrics,
            metadata.benchmarkMetadata.associatedMetricsDisplayNames,
            metadata.benchmarkMetadata.associatedMetricsValueTooltip,
            metadata.benchmarkMetadata.associatedMetricsTooltip,
          ];

    if (
      metadata.benchmarkTileType === BENCHMARK_TILE_TYPES.MSCI_ESG_Rating ||
      metadata.benchmarkTileType ===
        BENCHMARK_TILE_TYPES.MSCI_ESG_Rating_Climate
    )
      associatedMetrics = [
        ...associatedMetrics,
        ...Array.from(
          new Set(
            response
              .filter((res: any) => !associatedMetrics.includes(res.fieldName))
              .map((res: any) => res.fieldName)
          )
        ),
      ];

    // Add the metric information from the response to the respective company
    return companyList.map((companyData: InsightCompanyFlat) => {
      const companyMetrics = associatedMetrics.map(
        (metricKey: string, index: number) => {
          const associatedMetric = metricKey;
          let responseValue = response.find((responseData): any =>
            responseFunctions.searchMetric(
              responseData,
              companyData.globalCompanyId,
              associatedMetric
            )
          );

          let companyMetricData = responseFunctions.getMetricFromResponse(
            responseValue,
            associatedMetric
          );

          if (companyData.globalCompanyMapping) {
            let globalCompanyIds =
              companyData.globalCompanyMapping &&
              companyData.globalCompanyMapping.globalCompanyMappingDetails &&
              companyData.globalCompanyMapping.globalCompanyMappingDetails
                .length > 0
                ? companyData.globalCompanyMapping.globalCompanyMappingDetails.map(
                    (d: GlobalCompanyMapping) =>
                      d.globalCompanyId
                        ? d.globalCompanyId
                        : d.globalCompany.globalCompanyId
                  )
                : [companyData.globalCompanyId];

            let responseValuesArray = globalCompanyIds.map(
              (globalCompId: number) =>
                response.find((responseData: any) =>
                  responseFunctions.searchMetric(
                    responseData,
                    globalCompId,
                    associatedMetric
                  )
                )
            );
            let companyMetricDataArray = responseValuesArray.map(
              (resValue: any) =>
                responseFunctions.getMetricFromResponse(
                  resValue,
                  associatedMetric
                )
            );
            if (
              companyMetricDataArray.some((md: any) => md.value) &&
              companyMetricDataArray.find((md: any) => md.value)
            )
              companyMetricData = companyMetricDataArray.find(
                (md: any) => md.value
              );
          }

          const metricName = associatedMetricsDisplayNames
            ? associatedMetricsDisplayNames[index] ?? ""
            : "";

          return {
            metricKey: associatedMetric,
            metricName: metricName,
            metricTooltip: MERGE_TOOLTIP_TITLE.includes(
              metadata.benchmarkTileType
            )
              ? []
              : metadata.associatedMetricsTooltips
              ? metadata.associatedMetricsTooltips.filter(
                  (t: AssociatedMetricTooltips) =>
                    t.associatedMetric.toLocaleLowerCase() ===
                    associatedMetric.toLowerCase()
                )
              : associatedMetricsTooltip
              ? associatedMetricsTooltip[index] ?? null
              : null,
            metricValue: companyMetricData ? companyMetricData.value : null,
            metricMinValue: companyMetricData.metricMinValue ?? null,
            metricMaxValue: companyMetricData.metricMaxValue ?? null,
            isRound: companyMetricData?.isRound,
            metricValueIcon: companyMetricData.metricValueIcon,
            metricValueTooltip:
              associatedMetricsValueTooltip && responseValue
                ? getLegendTooltip(
                    responseValue,
                    associatedMetricsValueTooltip[index] ?? ""
                  )
                : companyMetricData.metricValueTooltip,
            metricDescription: companyMetricData.metricDescription,
          };
        }
      );
      return {
        ...companyData,
        metrics: companyMetrics,
      };
    });
  };

  const getGraphHScale = () => {
    const styleIndex = isTableViewActive ? 1 : 0;
    return typeof metadata.benchmarkMetadata.hScale === "number"
      ? metadata.benchmarkMetadata.hScale
      : metadata.benchmarkMetadata.hScale[styleIndex] || 1;
  };

  return loading ? (
    <div className={`${BLOCK} ${BLOCK}--loading`}>
      <div className={`${BLOCK}__loader`} />
    </div>
  ) : (
    <TileContext.Provider
      value={{
        BLOCK,
        index,
        metadata,
        originalDataset,
        dataset,
        setDataset,
        isTableViewActive,
        setIsTableViewActive,
        isPieViewActive,
        setIsPieViewActive,
        filters,
        setFilters,
        response,
        associatedMetrics,
        associatedMetricsDisplayNames,
        footerChild,
        setFooterChild,
        exporting: exportAllLoading,
        manualHScale,
        setManualHScale,
      }}
    >
      <div
        className={classNames(
          `${BLOCK} ${BLOCK}__span--${
            manualHScale > 0 && manualHScale < getGraphHScale()
              ? manualHScale
              : getGraphHScale()
          }`,
          {
            [`${BLOCK}--loaded`]: !loading,
            [`${BLOCK}-fixedHeightTile--chartView--${fixedHeightRowIndex}`]:
              !isTableViewActive && fixedHeightRowIndex !== -1,
            [`${BLOCK}-fixedHeightTile--tableView--${fixedHeightRowIndex}`]:
              isTableViewActive && fixedHeightRowIndex !== -1,
            [`${BLOCK}__linkInfo`]:
              metadata.benchmarkTileType === 25 ||
              metadata.benchmarkTileType === 21,
            ...(!isTableViewActive
              ? {
                  [`${BLOCK}--blue`]: metadata.benchmarkTileType === 31,
                  [`${BLOCK}--wave`]: metadata.benchmarkTileType === 31,
                  [`${BLOCK}--green`]: metadata.benchmarkTileType === 52,
                  [`${BLOCK}--top-lighting`]: metadata.benchmarkTileType === 33,
                  [`${BLOCK}--water-droplet-bg`]:
                    metadata.benchmarkTileType === 65,
                  [`${BLOCK}--targetEmissionsYear-background`]:
                    metadata.benchmarkTileType === 38,
                  [`${BLOCK}--below-emission`]:
                    metadata.benchmarkTileType === 41,
                  [`${BLOCK}--company-emission`]:
                    metadata.benchmarkTileType === 42,
                  [`${BLOCK}--aligned-emission`]:
                    metadata.benchmarkTileType === 43,
                  [`${BLOCK}--aligned-gap`]: metadata.benchmarkTileType === 44,
                  [`${BLOCK}--climate-strategy`]:
                    metadata.benchmarkTileType === 45,
                  [`${BLOCK}--climate-reportingBoundary`]:
                    metadata.benchmarkTileType === 49,
                  [`${BLOCK}--targets-waves`]:
                    !isTableViewActive && metadata.benchmarkTileType === 37,
                  [`${BLOCK}--reportingDisclosure`]:
                    !isTableViewActive && metadata.benchmarkTileType === 48,
                  [`${BLOCK}--directIndirectCost`]:
                    !isTableViewActive && metadata.benchmarkTileType === 71,
                }
              : null),
            [`${BLOCK}--reportingDisclosure-tableview`]:
              isTableViewActive &&
              metadata.benchmarkTileType === 48 &&
              metadata.benchmarkType === 5,
            [`${BLOCK}--no-padding`]: metadata.benchmarkTileType === 25,
            [`${BLOCK}--blue`]: metadata.benchmarkTileType === 4,
            [`${BLOCK}--hide-sp-source`]:
              metadata.benchmarkMetadata &&
              metadata.benchmarkMetadata.snpMetrics &&
              metadata.benchmarkMetadata.associatedMetrics.every((m: any) =>
                m?.groupValues && m?.groupValues?.length > 0
                  ? m?.groupValues?.every(
                      (y: string) =>
                        metadata.benchmarkMetadata.snpMetrics &&
                        metadata.benchmarkMetadata.snpMetrics.includes(y)
                    )
                  : metadata.benchmarkMetadata.snpMetrics &&
                    metadata.benchmarkMetadata.snpMetrics.includes(m)
              ),
            [`${BLOCK}--risk-waves`]:
              !isTableViewActive && metadata.benchmarkTileType === 78,
          }
        )}
        // WE ARE CREATING IMAGE OF EACH TILE BASED ON THE INDEX NUMBER.
        id={`benchmark-graph-${index}`}
        style={{
          ...(fixedHeightRowIndex === -1 && height
            ? { gridRowEnd: `span ${height + 16}` }
            : null),
        }}
        ref={tileRef}
        data-testid="main-tile"
      >
        {metadata.benchmarkTileType !== 25 && (
          <TileHeader
            isESRS={isESRS}
            toggleDetailView={toggleDetailView}
            setTableViewSelected={setTableViewSelected}
          />
        )}
        <div
          className={classNames(`${BLOCK}__content`, {
            [`${BLOCK}__content--no-padding`]:
              metadata.benchmarkTileType === 25,
            [`${BLOCK}__content--no-padding-up`]:
              metadata.benchmarkTileType ===
                BENCHMARK_TILE_TYPES.BUSINESS_ETHICS_AND_FRAUD_SCORE ||
              metadata.benchmarkTileType ===
                BENCHMARK_TILE_TYPES.TOXIC_EMISSIONS_WASTE_SCORE ||
              metadata.benchmarkTileType ===
                BENCHMARK_TILE_TYPES.PRODUCT_SAFETY_AND_QUALITY_SCORE ||
              metadata.benchmarkTileType ===
                BENCHMARK_TILE_TYPES.RAW_MATERIAL_AND_SOURCING_SCORE ||
              metadata.benchmarkTileType ===
                BENCHMARK_TILE_TYPES.SUPPLY_CHAIN_LABOR_SCORE ||
              metadata.benchmarkTileType ===
                BENCHMARK_TILE_TYPES.SOCIAL_OPPORTINITIES_THEME_SCORE ||
              metadata.benchmarkTileType ===
                BENCHMARK_TILE_TYPES.CORPORATE_GOVERNANCE_THEME_SCORE ||
              metadata.benchmarkTileType ===
                BENCHMARK_TILE_TYPES.BIODIVERSITY_AND_LAND_USE_SCORE,
          })}
          style={{
            ...(fixedHeightRowIndex !== -1 ? { display: "flex" } : null),
          }}
        >
          {metadata.benchmarkTileType === 21 ||
          (dataset.length && Component) ? (
            <Component />
          ) : (
            <TileEmptyContent />
          )}
        </div>
        <TileFooter />
      </div>
    </TileContext.Provider>
  );
};

export default MainTile;
