import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import JoditEditor from "jodit-react";
import Parser from "html-react-parser";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addInformationTileTrack,
  saveAdditionalInfo,
  setAdditionalInformation,
  setEditor,
  setInformation,
  updateAdditionalInfo,
} from "services/insights/insights.service";
import { RootStore } from "services/store.service";
import { useTileContext } from "../tile.context";
import { editorConfig } from "utils/constants";

const AddAdditionalInformation = () => {
  const { index, metadata, response } = useTileContext();
  const BLOCK = "addAdditionalInformation";
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const insightsState = useSelector((state: RootStore) => state.insights);
  const [inputDisabled, setInputDisabled] = useState<boolean>(false);
  const [inputText, setInputText] = useState<any>("");
  const [editData, setEditData] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    mapAddInfoData();

    return () => {
      dispatch(setAdditionalInformation([]));
      dispatch(setInformation(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const mapAddInfoData = () => {
    if (response.length > 0) {
      dispatch(setAdditionalInformation(response));
      dispatch(setInformation(true));
    } else {
      dispatch(setAdditionalInformation([]));
      dispatch(setInformation(false));
    }
  };

  const editableInput = () => (
    <div className={`${BLOCK}__input`} onClick={() => setInputDisabled(false)}>
      <input
        type="text"
        ref={inputRef}
        placeholder="Add title"
        onKeyDown={onEnter}
        disabled={inputDisabled}
      />
    </div>
  );

  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      setInputDisabled(true);
    }
  };

  const editor = useRef(null);

  const cancelHandler = () => {
    const popUp = document.querySelectorAll("[role=popup]") as any;
    popUp.forEach((element: any) => {
      element.style.display = "none";
    });
    dispatch(setEditor(false));
    setInputText("");
  };

  function extractContent(text: any) {
    var span = document.createElement("span");
    span.innerHTML = text;
    return span.textContent || span.innerText;
  }

  const onChangeHandler = (text: any) => {
    const getText = extractContent(text);
    if (getText.length >= 0) {
      setInputText(text);
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    if (insightsState.showEditor) {
      setDisabled(true);
    }
  }, [insightsState.showEditor]);

  useEffect(() => {
    if (editData.length > 0) {
      setInputText(editData[0]?.additionalInformationText);
    }
  }, [editData]);

  useEffect(() => {
    if (insightsState.additionalInfo) {
      setEditData(insightsState.additionalInfo);
    }
    if (insightsState.additionalInfo.length > 0) {
      dispatch(setInformation(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.additionalInfo]);

  const saveInfoHandler = () => {
    const payload = {
      referenceId: insightsState.savedInsightDashboardId,
      additionalInformationType: 0,
      additionalInformationText: inputText,
    };

    if (editData?.length > 0) {
      const editPayload = {
        additionalInformationId: editData[0].additionalInformationId,
        referenceId: insightsState.savedInsightDashboardId,
        additionalInformationType: 0,
        additionalInformationText: inputText,
      };
      dispatch(updateAdditionalInfo(editPayload));
    } else {
      dispatch(saveAdditionalInfo(payload));
    }

    const popUp = document.querySelectorAll("[role=popup]") as any;

    popUp.forEach((element: any) => {
      element.style.display = "none";
    });

    dispatch(setEditor(false));
  };

  return (
    <div
      className={classNames(`${BLOCK}__wrapper`, {
        [`${BLOCK}__wrapper--blue-dashed`]:
          !insightsState.additionalInfo.length,
      })}
      data-testid="additional-information-container"
    >
      {insightsState.showInformation && (
        <div className={`${BLOCK}__header-wrapper`}>
          {insightsState.showInformation && (
            <span
              className={`${BLOCK}__header__text`}
              id={`chart-tile-title-${index}`}
            >
              {metadata.benchmarkTitle
                ? metadata.benchmarkTitle
                : editableInput}
            </span>
          )}

          <span
            className={`${BLOCK}__editInfo`}
            onClick={() => dispatch(setEditor(true))}
          >
            <Icon name="pencil" width={24} height={24} />
          </span>
        </div>
      )}

      <div
        className={classNames(`${BLOCK}__new-tile`, {
          [`${BLOCK}__new-tile--show-info`]: editData.length > 0,
        })}
        data-testid="newTileContainer"
      >
        {!insightsState.showEditor && !insightsState.additionalInfo.length && (
          <div
            className={`${BLOCK}__insights-add-new-info`}
            onClick={() => {
              dispatch(addInformationTileTrack(true));
              dispatch(setEditor(true));
            }}
            data-testid="addNewEditor"
          >
            <Icon name="plus" height={15} width={15} />
            <div className={`${BLOCK}__insights-add-new-info--text`}>
              <FormattedMessage id="add.additional.info" />
            </div>
          </div>
        )}

        {insightsState.showEditor && (
          <div className={`${BLOCK}__editor`} data-testid="editor">
            <JoditEditor
              ref={editor}
              onChange={(content) => onChangeHandler(content)}
              config={editorConfig}
              value={
                editData?.length > 0
                  ? editData[0].additionalInformationText
                  : inputText
              }
            />
            <div className={`${BLOCK}__options`}>
              <button
                className={`primary-outline`}
                data-test="cancel-button"
                onClick={() => {
                  cancelHandler();
                }}
                data-testid="close-suggested-company-btn"
              >
                <FormattedMessage id="shortlist.edit.button.cancel" />
              </button>
              {!disabled && (
                <button
                  className={`${BLOCK}__continue primary`}
                  onClick={saveInfoHandler}
                  data-testid="add-suggested-company-btn"
                >
                  <FormattedMessage id="save.additional.info" />
                </button>
              )}
              {disabled && (
                <button className={`${BLOCK}__continue primary-disabled`}>
                  <FormattedMessage id="save.additional.info" />
                </button>
              )}
            </div>
          </div>
        )}

        {insightsState.additionalInfo.length > 0 &&
          !insightsState.showEditor && (
            <div
              className={`${BLOCK}__add-container`}
              data-testid="addContainer"
            >
              {insightsState.additionalInfo.map((item: any, index: number) => (
                <div
                  className={`${BLOCK}__add-container--body`}
                  key={`${index}__add-info--body`}
                >
                  {Parser(item.additionalInformationText ?? "")}
                </div>
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default AddAdditionalInformation;
