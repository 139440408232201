import { BaseModel, errorModel } from "../../utils/redux.utils";

export interface SavedComparisonsListState {
  error?: errorModel;
  selectedList: ComparisonItem[];
  loading: boolean;
  loadingPages: any;
  currentFilter: string;
  savedComparisonsFetch: SavedComparisonsList;
  savedComparisonListLoading: boolean;
  nextPageLoading: boolean;
  showItemsTooltip: boolean;
  shareSuccess: boolean;
  comparisonToSave: ComparisonToSavePayload;
  savedComparisonResult: any;
  saveComparisonSuccess: boolean;
  saveComparisonError: boolean;
  saveComparisonPopUp: boolean;
  uncheckedKeywordReports: UncheckedKeywordReports;
  savedComparison: any;
  refreshComparisonsList: boolean;
  errorRename: boolean;
  editComparisonPopUp: boolean;
  deleteComparisonPopUP: boolean;
  executeAddToComparisonTrack: boolean;
  triggerPageAddToComparisonTrack: string;
  triggerPageNewComparisonTrack: string;
  executeNewComparisonTrack: string;
  savedComparisonCount: number;
  isCollab: boolean;
}
export class SavedComparisonsListModel extends BaseModel {
  constructor() {
    super({
      loadingPages: {},
      loading: false,
      nextpageLoading: false,
      savedComparisonsFetch: null,
      sortLoading: false,
      showItemsTooltip: false,
      shareSuccess: false,
      selectedList: [],
      comparisonToSave: null,
      savedComparisonResult: null,
      saveComparisonSuccess: false,
      saveComparisonError: false,
      saveComparisonPopUp: false,
      savedComparison: null,
      refreshComparisonsList: false,
      errorRename: false,
      editComparisonPopUp: false,
      deleteComparisonPopUP: false,
      executeAddToComparisonTrack: false,
      triggerPageAddToComparisonTrack: "",
      triggerPageNewComparisonTrack: "",
      executeNewComparisonTrack: false,
      savedComparisonCount: 0,
      isCollab: false,
    });
  }
  setError = (error: errorModel): SavedComparisonsListState =>
    this.merge({ error });

  setSelected = (selectedList: ComparisonItem[]): SavedComparisonsListState =>
    this.merge({ selectedList });

  setLoading = (loading: boolean): SavedComparisonsListState =>
    this.merge({ loading });

  setLoadingPages = (loadingPages: any): SavedComparisonsListState =>
    this.merge({
      loadingPages: { ...this.state.loadingPages, ...loadingPages },
    });

  setSavedComparisonsList = (
    savedComparisonsFetch: SavedComparisonsList
  ): SavedComparisonsListState =>
    this.merge({ savedComparisonsFetch, loading: false });

  setComparisonList = (
    savedComparisonsFetch: SavedComparisonsList
  ): SavedComparisonsListState => {
    const totalComparisons = [
      ...this.state.savedComparisonsFetch.comparisons,
      ...savedComparisonsFetch.comparisons,
    ];

    savedComparisonsFetch.comparisons = totalComparisons;

    return this.merge({ savedComparisonsFetch, nextPageLoading: false });
  };

  setNextPageLoading = (nextPageLoading: boolean): SavedComparisonsListState =>
    this.merge({ nextPageLoading: nextPageLoading });

  setSortLoading = (sortLoading: boolean): SavedComparisonsListState =>
    this.merge({ sortLoading });

  setComparisonItems = (comparison: Comparison): SavedComparisonsListState => {
    let tmpSavedComparisonsFetch = this.state.savedComparisonsFetch;
    let comparisonIndex = tmpSavedComparisonsFetch.comparisons.findIndex(
      (oldComparison: any) =>
        oldComparison.comparisonId === comparison.comparisonId
    );
    tmpSavedComparisonsFetch.comparisons[comparisonIndex].comparisonItems =
      comparison.comparisonItems;
    return this.merge({ savedComparisonsFetch: tmpSavedComparisonsFetch });
  };
  setShowItemsTooltip = (
    showItemsTooltip: boolean
  ): SavedComparisonsListState => this.merge({ showItemsTooltip });

  setShareSuccess = (shareSuccess: boolean): SavedComparisonsListState =>
    this.merge({ shareSuccess });
  setComparisonToSave = (
    comparisonToSave: ComparisonToSavePayload
  ): SavedComparisonsListState => this.merge({ comparisonToSave });
  setSavedComparisonResult = (
    savedComparisonResult: any
  ): SavedComparisonsListState => this.merge({ savedComparisonResult });
  setSaveComparisonSuccess = (
    saveComparisonSuccess: boolean
  ): SavedComparisonsListState => this.merge({ saveComparisonSuccess });
  setSaveComparisonError = (
    saveComparisonError: boolean
  ): SavedComparisonsListState => this.merge({ saveComparisonError });
  setSaveComparisonPopUp = (
    saveComparisonPopUp: boolean
  ): SavedComparisonsListState => this.merge({ saveComparisonPopUp });
  resetComparisonToSave = (): SavedComparisonsListState =>
    this.merge({ comparisonToSave: null });
  resetSavedComparisonResult = (): SavedComparisonsListState =>
    this.merge({ savedComparisonResult: null });
  resetSelectedList = (): SavedComparisonsListState =>
    this.merge({ selectedList: [] });
  setSavedComparison = (savedComparison: any): SavedComparisonsListState =>
    this.merge({ savedComparison: savedComparison });
  resetSavedComparison = (): SavedComparisonsListState =>
    this.merge({ savedComparison: null });
  setRefreshComparisonsList = (
    refreshComparisonsList: boolean
  ): SavedComparisonsListState => this.merge({ refreshComparisonsList });
  setErrorRename = (errorRename: boolean): SavedComparisonsListState =>
    this.merge({ errorRename });
  setEditComparisonPopUp = (
    editComparisonPopUp: boolean
  ): SavedComparisonsListState => this.merge({ editComparisonPopUp });
  setDeleteComparisonPopUp = (
    deleteComparisonPopUP: boolean
  ): SavedComparisonsListState => this.merge({ deleteComparisonPopUP });
  setCurrentFilter = (currentFilter: string): SavedComparisonsListState =>
    this.merge({ currentFilter });
  setUncheckedKeywordReports = (
    uncheckedKeywordReports: UncheckedKeywordReports
  ) => {
    return this.merge({ uncheckedKeywordReports });
  };
  setExecuteAddToComparisonTrack = (
    executeAddToComparisonTrack: boolean
  ): SavedComparisonsListState => this.merge({ executeAddToComparisonTrack });
  setTriggerPageAddToComparisonTrack = (
    triggerPageAddToComparisonTrack: string
  ): SavedComparisonsListState =>
    this.merge({ triggerPageAddToComparisonTrack });
  setTriggerPageNewComparisonTrack = (
    triggerPageNewComparisonTrack: string
  ): SavedComparisonsListState => this.merge({ triggerPageNewComparisonTrack });
  setExecuteNewComparisonTrack = (
    executeNewComparisonTrack: boolean
  ): SavedComparisonsListState => this.merge({ executeNewComparisonTrack });
  cleanUpNewComparisonTrack = (): SavedComparisonsListState =>
    this.merge({
      triggerPageNewComparisonTrack: "",
      executeNewComparisonTrack: false,
    });
  setsavedComparisonCount = (
    savedComparisonCount: number
  ): SavedComparisonsListState => this.merge({ savedComparisonCount });
  setIsCollab = (isCollab: boolean): SavedComparisonsListState =>
    this.merge({ isCollab });
}

export type SavedComparisonsList = {
  pageInfo: SavedComparisonsPageInfo;
  comparisons: Comparison[];
  sharedComparisons: Comparison[];
};

export type UncheckedKeywordReports = {
  comparisionItemIds: ComparisonUncheckKeyword[];
};

export type ComparisonUncheckKeyword = {
  id: number;
  comparisonItemId: number;
};

export type Comparison = {
  comparisonId: number;
  comparisonName: string;
  comparisonDescription?: string;
  sourceComparisonId: any;
  isSaved: boolean;
  comparisonItems: ComparisonItem[];
  comparisonItemCount: number;
  createdBy: number;
  sharedBy: any;
  sharedWith: any;
  sharedDate: string;
  lastAccessedDate: string;
  createdDate: string;
  lastUpdatedDate: string;
};

export type ComparisonItem = {
  comparisonItemId: number;
  comparisonId: number;
  reportId: number;
  reportTitle: string;
  sectionId: number;
  sectionName: string;
  comparisonItemType: number;
  displayOrder: number;
  isBaseDocument: boolean;
  contentKeywords: any;
  createdDate: string;
  lastUpdatedDate: string;
};

export type SavedComparisonsPageInfo = {
  totalPageCount: number;
  totalRecordCount: number;
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
  filterBy: string;
  filterText: string;
  externalSortBy: any;
  externalSortOrder: any;
  startDate: string;
  endDate: string;
};

export type GetComparisonsPayload = {
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
  filterBy: string;
  filterText: string;
};

export type ESGReport = {
  reportId: number;
  reportTitle: any;
  reportFileName: any;
  reportYear: number;
  reportStatus: number;
  sourceOperationTypeId: any;
  processedDate: any;
  externalDocumentId: any;
  containerCode: any;
  memberFirmCode: any;
  keywords: any;
  reportFormType: {
    documentCategoryId: number;
    formTypeId: number;
    formTypeName: any;
    formTypeNumber: any;
    loadType: number;
    amendedFormType: any;
    parentId: any;
    displayOrder: number;
  };
  formTypeId: number;
  formTypeName: any;
  reportCompany: {
    companyId: number;
    companyName: any;
    cikNumber: number;
    ticker: any;
    industry: {
      industryName: any;
      sectors: any;
      industryId: number;
    };
    auditor: {
      auditorId: number;
      auditorName: any;
      auditorNumber: number;
      externalAuditorId: number;
    };
    auditorId: number;
    externalCompanyId: number;
    mrStockPriceDate: any;
    mrFinancialsDate: any;
    mrfyStockPriceDate: any;
    mrfyFinancialsDate: any;
    mrMarketCap: number;
    mrRevenue: number;
    mrfyMarketCap: number;
    mrfyRevenue: number;
    industryIdOverriden: boolean;
    isNameSetManually: boolean;
    isTickerSetManually: boolean;
    isAuditorSetManually: boolean;
    isForeignPrivateIssuer: boolean;
    parentCompanyID: number;
  };
  companyId: number;
  companyName: any;
  noOfHits: any;
};

export type SectionItem = {
  id: number;
  name: any;
  isKeywordExists: boolean;
  childSections: any;
  isItemPartOfShortlist: boolean;
  hasContent: boolean;
  disclosure: boolean;
  policy: boolean;
  filingSecLink: any;
  sectionId: number;
  elementId: number;
  companyName: any;
  subsidiaries: any;
  title: any;
  category: any;
  documentSectionPath: any;
  form: any;
  periodFocus: any;
  year: any;
  auditor: any;
  filingDate: any;
  filingId: number;
  wordCount: number;
  fleschKincaidReadabilitySentence: any;
  filingType: any;
  communicationId: number;
  topicsList: any;
  topicCount: number;
  isCamSection: boolean;
};

export type ComparisonToSavePayload = {
  comparisonId: number;
  comparisonName: string;
  comparisonDescription: string;
  comparisonItems: ComparisonItemToSave[];
};

export type ComparisonItemToSave = {
  esgReport: any;
  sectionItem: any;
  comparisonType: number;
  displayOrder: number;
  isBaseDocument: boolean;
  contentKeywords: any;
};
