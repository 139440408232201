import FormattedMessage from "components/shared/formatted-message/formatted-message";
import DashboardTile from "components/visualizations/graph-tile-dashboard";
import Icon from "components/shared/icon/icon";
import { useSelector, useDispatch } from "react-redux";
import {
  applyBenchmark,
  editDashboardView,
  executeScrollDuplicatedTile,
  exportDashboardTrackExcel,
  exportDashboardTrackPDF,
  focusBenchmark,
  loadEditBenchmarkData,
  loadVisualizations,
  refreshDashboard,
  resetBenchmark,
  showEditBenchmark,
  showSidedashboardTab,
} from "services/dashboard/dashboard.service";
import { RootStore } from "services/store.service";
import SideCustomBenchmark from "./side-custom-benchmark";
import { useEffect, useState, useRef } from "react";
import { showTooltip } from "services/commons.service";
import { TooltipPosition } from "components/shared/tooltip/tooltip";
import {
  EditBenchMarkData,
  Visualization,
} from "services/dashboard/dashboard.model";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classNames from "classnames";
import {
  CHART_TYPES,
  DEFAULT_DASHBOARD_EXPORT_PAYLOAD,
  SIDE_CUSTOM_TYPE_SAVE,
  CHART_EXPORT_TYPE_UI,
  DASHBOARD_PDF_ALL_EPXORT_TITLE,
  OVERLAY_DASHBOARD,
  APP_SETTING,
} from "utils/constants";
import {
  sendExportItem,
  setShowDownloadTab,
} from "services/download/download.service";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useClickOutside } from "utils/functions";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import WhatsNewModal from "components/whatsNewModal/whatsNewModal";
import { PAGES } from "components/whatsNewModal/useWhatsNewModal";
import useDnDScrolling from "utils/useDnDScrolling";
import Modal from "components/shared/modal/modal";
import DisclaimerModal from "components/shared/disclaimer-modal/disclaimer-modal";
import Button from "components/shared/button/button";
import SidePanel from "components/shared/side-panel/side-panel";
import { useLocation } from "react-router-dom";
import useAppInsightsCustomMetric from "appinsights/app-insights-custom-metrics";

type props = {
  workspaceRef: any;
};
const Dashboard = ({ workspaceRef }: props) => {
  const BLOCK = "dashboard";
  const dispatch = useDispatch();
  const dashboardState = useSelector((state: RootStore) => state.dashboard);
  const commonsState = useSelector((state: RootStore) => state.commons);
  const [editDashboard, setEditDashboard] = useState<boolean>(false);
  const [tempVisualizationsState, setTempVisualizationsState] = useState<
    Visualization[]
  >([]);
  const custombenchmarkEndRef = useRef<null | any>(null);
  const [showExportOptions, setShowExportOptions] = useState<boolean>(false);

  const [doPdfExportAll, setDoPdfExportAll] = useState<boolean>(false);
  const downloadState = useSelector((state: RootStore) => state.download);
  const [loadedTiles, setLoadedTiles] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [exportAllLoading, setExportAllLoading] = useState<boolean>(false);
  const [createFromTile, setCreateFromTile] = useState<boolean>(false);
  const [showDisclarimerModal, setShowDisclarimerModal] =
    useState<boolean>(false);
  const [exportType, setExportType] = useState<string>("");
  const [isAnyTileFullScreen, setIsAnyTileFullScreen] =
    useState<boolean>(false);
  const [editedVisualizationsState, setEditedVisualizationsState] = useState<
    Visualization[]
  >([]);
  const location = useLocation();

  // App insights custom metric
  useAppInsightsCustomMetric(location.pathname);

  const exportOptions = [
    {
      type: "xls",
      icon: "xls",
      text: "Export Excel",
      onClick: () => {
        if (
          !commonsState.dashboardExportDisclaimer[
            APP_SETTING.DashboardExportDisclaimer
          ]
        ) {
          downloadHandler("xls");
        } else {
          setShowDisclarimerModal(true);
        }
        setExportType("xls");
        setShowExportOptions(false);
        dispatch(exportDashboardTrackExcel(true));
      },
    },
    {
      type: "pdf",
      icon: "pdf-2",
      text: "Export PDF",
      onClick: () => {
        if (
          !commonsState.dashboardExportDisclaimer[
            APP_SETTING.DashboardExportDisclaimer
          ]
        ) {
          downloadHandler("pdf");
        } else {
          setShowDisclarimerModal(true);
        }
        setExportType("pdf");
        setShowExportOptions(false);
        dispatch(exportDashboardTrackPDF(true));
      },
    },
  ];

  const exportHandler = () => {
    if (exportType === "pdf") {
      setDoPdfExportAll(true);
    } else if (exportType === "xls") {
      dispatch(
        sendExportItem({
          ...DEFAULT_DASHBOARD_EXPORT_PAYLOAD,
        })
      );
    }
  };
  const downloadHandler = (downloadType: string) => {
    if (downloadType === "pdf") {
      setDoPdfExportAll(true);
    } else if (downloadType === "xls") {
      dispatch(
        sendExportItem({
          ...DEFAULT_DASHBOARD_EXPORT_PAYLOAD,
        })
      );
    }
  };

  const currentGraphFocusRef = useRef<null | any>(null);

  const scrollToBottom = () => {
    custombenchmarkEndRef.current?.scrollIntoView({
      block: "end",
      behavior: "smooth",
      inline: "nearest",
    });
  };

  const exportAllMenuRef = useRef(null);
  useClickOutside(exportAllMenuRef, () => {
    setShowExportOptions(false);
  });

  const [existingVisualizualitions, setExistingVisualizations] = useState<
    Number[]
  >([]);

  const [idRecentlyDuplicated, setIdRecentlyDuplicated] = useState<number>(-1);

  const scrollToGraph = () => {
    if (currentGraphFocusRef.current) {
      currentGraphFocusRef.current.scrollIntoView(true);
      dispatch(executeScrollDuplicatedTile(false));
      const newStateCopy = JSON.parse(
        JSON.stringify(
          [...dashboardState.visualizations.benchmarks].sort(
            (a: Visualization, b: Visualization) =>
              a.displayOrder - b.displayOrder
          )
        )
      );
      setExistingVisualizations(
        newStateCopy.map((vis: Visualization) => vis.benchmarkId)
      );
      setIdRecentlyDuplicated(-1);
      currentGraphFocusRef.current = null;
    }
  };

  useEffect(() => {
    if (doPdfExportAll && !showExportOptions) {
      exportPdf(0, tempVisualizationsState.length);
      setExportAllLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doPdfExportAll]);

  useEffect(() => {
    if (tempVisualizationsState.length > 0) {
      const currentState = [];

      for (let i = 0; i < tempVisualizationsState.length; i++) {
        currentState.push(
          !tempVisualizationsState[i].benchmarkMetadata ||
            tempVisualizationsState[i].isHidden
        );
      }
      setLoadedTiles(currentState);
    }
  }, [tempVisualizationsState]);

  useEffect(() => {
    let disabledCurrent = false;
    for (let i = 0; i < loadedTiles.length; i++) {
      if (!loadedTiles[i] && !tempVisualizationsState[i].isHidden) {
        disabledCurrent = true;
        break;
      }
    }
    setDisabled(disabledCurrent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedTiles]);

  useEffect(() => {
    dispatch(loadVisualizations());

    return () => {
      dispatch(showSidedashboardTab(false));
      dispatch(showEditBenchmark(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dashboardState.applyBenchmark) {
      scrollToBottom();
      dispatch(applyBenchmark(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardState.applyBenchmark]);

  useEffect(() => {
    if (dashboardState.refreshDashboard) {
      dispatch(loadVisualizations());
      dispatch(refreshDashboard(false));
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardState.refreshDashboard]);

  useEffect(() => {
    if (dashboardState.visualizations.benchmarks) {
      const newStateCopy = JSON.parse(
        JSON.stringify(
          [...dashboardState.visualizations.benchmarks].sort(
            (a: Visualization, b: Visualization) =>
              a.displayOrder - b.displayOrder
          )
        )
      );
      setTempVisualizationsState(newStateCopy);
      if (!dashboardState.executeScrollDuplicatedTile) {
        setExistingVisualizations(
          newStateCopy.map((vis: Visualization) => vis.benchmarkId)
        );
      } else {
        let newVisualization: Visualization[] = newStateCopy.filter(
          (vis: Visualization) =>
            !existingVisualizualitions.includes(vis.benchmarkId || -1)
        );
        setIdRecentlyDuplicated(
          newVisualization[0].benchmarkId ? newVisualization[0].benchmarkId : -1
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardState.visualizations]);

  useEffect(() => {
    if (idRecentlyDuplicated !== -1 && currentGraphFocusRef.current)
      scrollToGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idRecentlyDuplicated]);

  useEffect(() => {
    if (dashboardState.benchmarkToFocus > 0) {
      const graph = document.getElementById(
        `benchmark-graph-${dashboardState.benchmarkToFocus}`
      );
      if (graph) {
        graph.scrollIntoView();
        dispatch(focusBenchmark(null));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardState]);

  const handleMouseEnter = (e: any) => {
    const titleElement = e.target.closest("div");
    const textElement = titleElement
      ? titleElement.querySelector("span")
      : null;

    if (!titleElement || !textElement) {
      return;
    }

    if (
      textElement.getBoundingClientRect().width >
      titleElement.getBoundingClientRect().width
    ) {
      dispatch(
        showTooltip({
          children: <>{textElement.textContent}</>,
          position: TooltipPosition.top,
          customPosition: true,
          elementDimensions: titleElement.getBoundingClientRect(),
        })
      );
    }
  };

  const handleMouseLeave = (e: any) => {
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
      })
    );
  };

  const handleEditBenchmark = (payload: EditBenchMarkData) => {
    dispatch(loadEditBenchmarkData(payload));
  };

  const handleApplyEditDashboard = () => {
    const payload = {
      benchmarkProperties: [] as any,
    };

    editedVisualizationsState.forEach((vis: Visualization) => {
      payload.benchmarkProperties.push({
        benchmarkId: vis.benchmarkId,
        isHidden: vis.isHidden,
        isCustom: vis.predefinedBenchmarkId ? false : true,
        displayOrder: vis.displayOrder,
      });
    });

    dispatch(editDashboardView(payload));
    setEditDashboard(false);
  };

  const handleResetEditDashboard = () => {
    const newStateCopy = JSON.parse(
      JSON.stringify(
        [...dashboardState.visualizations.benchmarks].sort(
          (a: Visualization, b: Visualization) =>
            a.displayOrder - b.displayOrder
        )
      )
    );

    setEditedVisualizationsState(newStateCopy);
  };

  const isEditActionsDisabled = () => {
    const dashboardStateHiddenValues = [
      ...dashboardState.visualizations.benchmarks,
    ]
      .sort(
        (a: Visualization, b: Visualization) => a.displayOrder - b.displayOrder
      )
      .map((vis: Visualization) => ({
        benchmarkId: vis.benchmarkId,
        isHidden: vis.isHidden,
        displayOrder: vis.displayOrder,
      }));

    const tempEditStateHiddenValues = editedVisualizationsState.map(
      (vis: Visualization) => ({
        benchmarkId: vis.benchmarkId,
        isHidden: vis.isHidden,
        displayOrder: vis.displayOrder,
      })
    );

    return (
      JSON.stringify(dashboardStateHiddenValues) ===
      JSON.stringify(tempEditStateHiddenValues)
    );
  };

  const handleDragNDrop = (draggedItem: any) => {
    const tmpVisualizationsCopy = [...editedVisualizationsState];
    const srcInd = draggedItem.source.index;
    const destInd = draggedItem.destination.index;
    if (srcInd !== destInd) {
      tmpVisualizationsCopy.splice(
        destInd,
        0,
        tmpVisualizationsCopy.splice(srcInd, 1)[0]
      );
      tmpVisualizationsCopy.forEach((vis: Visualization, i: number) => {
        vis.displayOrder = i + 1;
      });
      setEditedVisualizationsState(tmpVisualizationsCopy);
    }
  };

  const handleDragNDropCharts = (
    dragIndex: number,
    hoverIndex: number,
    isDropped: boolean
  ) => {
    const tmpVisualizationsCopy = [...tempVisualizationsState];
    tmpVisualizationsCopy.splice(
      hoverIndex,
      0,
      tmpVisualizationsCopy.splice(dragIndex, 1)[0]
    );
    tmpVisualizationsCopy.forEach((vis: Visualization, i: number) => {
      vis.displayOrder = i + 1;
    });

    if (isDropped) {
      const payload = {
        benchmarkProperties: [] as any,
      };

      tmpVisualizationsCopy.forEach((vis: Visualization) => {
        payload.benchmarkProperties.push({
          benchmarkId: vis.benchmarkId,
          isHidden: vis.isHidden,
          isCustom: vis.predefinedBenchmarkId ? false : true,
          displayOrder: vis.displayOrder,
        });
      });

      dispatch(editDashboardView(payload));
    }
  };

  const exportPdf = async (
    start: number,
    end: number,
    titleIndTile?: string
  ) => {
    const pdf = new jsPDF("p", "mm", "a4");
    let blob: any;

    let tilesShown = 0;

    if (!titleIndTile) {
      tempVisualizationsState.forEach((tile: any) => {
        if (!tile.isHidden) {
          tilesShown += 1;
        }
      });
    }

    for (let i = start; i < end; i++) {
      if (tempVisualizationsState[i].isHidden) {
        continue;
      }

      const element = document.querySelector(
        `#chart-tile-data-${i}`
      ) as HTMLElement;
      const elementTitle = document.querySelector(
        `#chart-tile-title-${i}`
      ) as HTMLElement;
      const elementPlaceholder = document.querySelector(
        `#placeholders-${i}`
      ) as HTMLElement;

      const title = elementTitle.innerText;

      const placeholders = elementPlaceholder
        ? elementPlaceholder.innerText
        : undefined;

      // eslint-disable-next-line no-loop-func
      await html2canvas(element, { scale: 2 }).then((canvas: any) => {
        const imgData = canvas.toDataURL("image/png");

        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();

        let widthRatio = width / canvas.width;
        let heightRatio = height / canvas.height;

        let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        let fontSize = 15;

        pdf.setFontSize(fontSize);

        let finalWidth = canvas.width * ratio;
        let finalHeight = canvas.height * ratio;

        const spaceLeft = height - finalHeight;

        let xPos = (width - finalWidth) / 2;
        let yPos = 0;

        let xPosText = 10;

        var splitText = pdf.splitTextToSize(title, 180);
        let yPosText = splitText.length === 1 ? 15 : 10;

        pdf.text(splitText, xPosText, yPosText);

        if (placeholders) {
          let xPosPlaceholders = 5;
          let splitPlaceholders = pdf.splitTextToSize(placeholders, 180);
          let yPosPlaceholders = splitPlaceholders.length === 1 ? 30 : 35;
          pdf.text(splitPlaceholders, xPosPlaceholders, yPosPlaceholders);
        }

        let padding = placeholders
          ? splitText.length === 1
            ? 20
            : 25
          : splitText.length === 1
          ? 10
          : 15;

        if (spaceLeft > fontSize + padding) {
          yPos += fontSize + padding;
        } else {
          finalHeight -= fontSize + padding;
          finalWidth -= fontSize + padding;
          yPos += fontSize + padding;
        }

        pdf.addImage(
          imgData,
          "JPEG",
          xPos,
          yPos,
          finalWidth,
          finalHeight,
          "",
          "FAST"
        );
        if (tilesShown > 1) {
          pdf.addPage();
          tilesShown -= 1;
        }
      });
    }
    blob = pdf.output("blob");

    let formData = new FormData();

    formData.append(
      "exportFileName",
      titleIndTile ? titleIndTile : DASHBOARD_PDF_ALL_EPXORT_TITLE
    );
    formData.append("exportFormat", `${1}`);
    formData.append("exportType", `${CHART_EXPORT_TYPE_UI}`);

    formData.append("file", blob);

    setDoPdfExportAll(false);
    setExportAllLoading(false);
    dispatch(
      sendExportItem({
        payload: formData,
        chartItem: true,
        downloadTabOpen: downloadState.showDownloads,
      })
    );
  };

  const { addEventListenerForWindow, removeEventListenerForWindow } =
    useDnDScrolling(workspaceRef);

  useEffect(() => {
    if (!isAnyTileFullScreen) {
      document.addEventListener("dragstart", addEventListenerForWindow, false);
      document.addEventListener("dragend", removeEventListenerForWindow, false);
      return () => {
        document.removeEventListener(
          "dragstart",
          addEventListenerForWindow,
          false
        );
        document.removeEventListener(
          "dragend",
          removeEventListenerForWindow,
          false
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyTileFullScreen]);

  return (
    <div className={`${BLOCK}`} data-testid="dashboard-component">
      <header
        className={`${BLOCK}__header`}
        data-test="component-dashboard-header"
      >
        <div
          data-testid="header-text"
          style={{ width: "50%", display: "inherit" }}
        >
          <FormattedMessage id="workspace.tab.dashboard" />
        </div>
        <div className={`${BLOCK}__benchmark-actions`}>
          <div className={`${BLOCK}__benchmark-section`}>
            <Button
              className={`icon-text-button`}
              dataTest="edit-view-button"
              formattedText="dashboard.edit.view"
              iconName="edit"
              iconHeight={24}
              iconWidth={24}
              onClick={() => {
                setEditDashboard(!editDashboard);
                let tempVisualizationsStateCopy = JSON.parse(
                  JSON.stringify(
                    [...dashboardState.visualizations.benchmarks].sort(
                      (a: Visualization, b: Visualization) =>
                        a.displayOrder - b.displayOrder
                    )
                  )
                );
                setEditedVisualizationsState(tempVisualizationsStateCopy);
              }}
            />
            <div className={`${BLOCK}__export-all`} ref={exportAllMenuRef}>
              <Button
                className={classNames(
                  `${BLOCK}__export-all-button button-secondary`
                )}
                dataTest="export-all-dropdown-button"
                disabled={disabled}
                formattedText="dashboard.export.all"
                iconName="caret-down"
                iconAfter={true}
                iconHeight={16}
                iconWidth={10}
                onClick={() => setShowExportOptions((prev) => !prev)}
              />
              <div
                className={classNames(`${BLOCK}__export-all-options`, {
                  [`${BLOCK}__export-all-options--show`]: showExportOptions,
                })}
                data-testid="export-all-dropdown-options"
              >
                {exportOptions.map((exportOption, i) => (
                  <span
                    key={i}
                    onClick={exportOption.onClick}
                    className={classNames(`${BLOCK}__export-all-option`)}
                  >
                    <Icon name={exportOption.icon} width={24} height={24} />
                    <span className={`${BLOCK}__export-all-option--text`}>
                      {exportOption.text}
                    </span>
                  </span>
                ))}
              </div>
            </div>

            <Button
              className={`${BLOCK}__benchmark-button button-primary`}
              dataTest="custom-benchmark-button"
              formattedText="dashboard.tab.custom.benchmark.button"
              onClick={() => {
                setCreateFromTile(false);
                dispatch(showSidedashboardTab(true));
                dispatch(showEditBenchmark(false));
                dispatch(resetBenchmark(SIDE_CUSTOM_TYPE_SAVE));
              }}
              iconName="add-plus"
              iconHeight={24}
              iconWidth={24}
            />
          </div>
          {editDashboard && (
            <SidePanel
              showPanel={true}
              className={`${BLOCK}__edit-dashboard-view`}
              handleClose={() => {
                handleResetEditDashboard();
                setEditDashboard(false);
              }}
              headerText="dashboard.edit.view.header"
              secondHeaderText="dashboard.edit.view.heading"
            >
              <>
                <div className={`${BLOCK}__current-benchmarks`}>
                  <DragDropContext
                    onDragEnd={(param) => {
                      handleDragNDrop(param);
                    }}
                  >
                    <Droppable droppableId="droppable-1">
                      {(provided, snapshot) => (
                        <div
                          className={`${BLOCK}__current-benchmarks-charts-list`}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {editedVisualizationsState.map((vis, i) => {
                            return (
                              <Draggable
                                key={i}
                                draggableId={`draggable-${i}`}
                                index={i}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className={classNames(
                                      `${BLOCK}__benchmark-show-wrapper`,
                                      {
                                        [`${BLOCK}__benchmark-show-wrapper-hidden`]:
                                          vis.isHidden,
                                      }
                                    )}
                                    key={vis.benchmarkId}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      cursor: "pointer",
                                      backgroundColor: snapshot.isDragging
                                        ? "#F1F8FB"
                                        : "transparent",
                                    }}
                                  >
                                    <div
                                      className={`${BLOCK}__benchmark-show-title`}
                                    >
                                      <Icon
                                        name="chart"
                                        width={15}
                                        height={15}
                                      />
                                      <div
                                        className={`${BLOCK}__benchmark-show-title-text`}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                      >
                                        <span>{vis.benchmarkTitle}</span>
                                      </div>
                                    </div>
                                    {editedVisualizationsState.length > 1 && (
                                      <input
                                        type="checkbox"
                                        checked={!vis.isHidden}
                                        onChange={() => {
                                          const visibleChartCount =
                                            editedVisualizationsState.filter(
                                              (vis: Visualization) =>
                                                !vis.isHidden
                                            ).length;

                                          vis.isHidden =
                                            !vis.isHidden &&
                                            visibleChartCount === 1
                                              ? false
                                              : !vis.isHidden;

                                          setEditedVisualizationsState([
                                            ...editedVisualizationsState,
                                          ]);
                                        }}
                                      />
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <div className={`${BLOCK}__edit-dashboard__actions`}>
                  <button
                    className={classNames(
                      `${BLOCK}__edit-dashboard-reset-button`,
                      {
                        [`${BLOCK}__edit-dashboard-reset-disabled-button`]:
                          isEditActionsDisabled(),
                      }
                    )}
                    onClick={handleResetEditDashboard}
                    disabled={isEditActionsDisabled()}
                  >
                    <FormattedMessage id="custom.benchmark.actions.reset" />
                  </button>
                  <button
                    className={classNames(
                      `${BLOCK}__edit-dashboard-apply-button`,
                      {
                        [`${BLOCK}__edit-dashboard-disabled-button`]:
                          isEditActionsDisabled(),
                      }
                    )}
                    onClick={handleApplyEditDashboard}
                    disabled={isEditActionsDisabled()}
                  >
                    <FormattedMessage id="custom.benchmark.actions.apply" />
                  </button>
                </div>
              </>
            </SidePanel>
          )}
        </div>
      </header>
      <DndProvider backend={HTML5Backend}>
        <div className={`${BLOCK}__content`}>
          {tempVisualizationsState?.map((vis, i) =>
            vis.benchmarkMetadata &&
            !vis.isHidden &&
            vis.benchmarkType === CHART_TYPES.predefined ? (
              <DashboardTile
                key={`dashboard-tile-${vis.benchmarkId}`}
                metadata={vis}
                index={i}
                handleDragNDropCharts={handleDragNDropCharts}
                currentGraphFocusRef={
                  vis.benchmarkId === idRecentlyDuplicated
                    ? currentGraphFocusRef
                    : undefined
                }
                exportPDFTile={exportPdf}
                setLoadedTiles={setLoadedTiles}
                loadedTiles={loadedTiles}
                setTileFullScreen={setIsAnyTileFullScreen}
              />
            ) : (
              vis.benchmarkMetadata &&
              !vis.isHidden &&
              vis.benchmarkType === CHART_TYPES.custom && (
                <DashboardTile
                  key={`dashboard-benchmark-tile-${vis.benchmarkId}`}
                  metadata={vis}
                  custom={true}
                  index={i}
                  handleEditBenchmark={handleEditBenchmark}
                  handleDragNDropCharts={handleDragNDropCharts}
                  currentGraphFocusRef={
                    vis.benchmarkId === idRecentlyDuplicated
                      ? currentGraphFocusRef
                      : undefined
                  }
                  exportPDFTile={exportPdf}
                  setLoadedTiles={setLoadedTiles}
                  loadedTiles={loadedTiles}
                  setTileFullScreen={setIsAnyTileFullScreen}
                />
              )
            )
          )}
          <div
            ref={custombenchmarkEndRef}
            className="DashboardTile DashboardTile__new-tile"
          >
            <div
              className={`${BLOCK}__benchmark-add-new`}
              onClick={() => {
                setCreateFromTile(true);
                dispatch(setShowDownloadTab(false));
                dispatch(showSidedashboardTab(true));
                dispatch(showEditBenchmark(false));
                dispatch(resetBenchmark(SIDE_CUSTOM_TYPE_SAVE));
              }}
            >
              <button
                className={`${BLOCK}__benchmark-add-new--tile`}
                data-testid="add-new-button"
              >
                <Icon name="add-plus" height={24} width={24} />
              </button>
              <div className={`${BLOCK}__benchmark-add-new--text`}>
                <FormattedMessage id="dashboard.add.new.benchmark.tile.button" />
              </div>
            </div>
          </div>
        </div>
      </DndProvider>
      {OVERLAY_DASHBOARD.length ? (
        <WhatsNewModal
          slides={OVERLAY_DASHBOARD}
          slide={OVERLAY_DASHBOARD[0]}
          page={PAGES.dashboard}
        />
      ) : null}
      {exportAllLoading && (
        <div className={`${BLOCK}__exportAll-icon`}>
          <Modal show={true} hideBackgroud={true}>
            <Icon
              name={"loading"}
              height={50}
              width={50}
              className="loading-icon"
              data-testid="loading-icon"
            />
          </Modal>
        </div>
      )}
      {showDisclarimerModal && (
        <DisclaimerModal
          title={"dashboard.export.disclaimer"}
          namePlaceholder={"dashboard.disclaimer.message"}
          handleCloseModal={() => setShowDisclarimerModal(false)}
          exportDiclaimer={exportHandler}
        />
      )}
      {dashboardState.showSideDashboard && (
        <SideCustomBenchmark
          BLOCK={`${BLOCK}__custom-benchmark`}
          fromTile={createFromTile}
        />
      )}
      {dashboardState.showEditBenchmarkTab && (
        <SideCustomBenchmark BLOCK={`${BLOCK}__custom-benchmark`} type="edit" />
      )}
    </div>
  );
};

export default Dashboard;
