import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { mapTableChart, useTileContext } from "../tile.context";
import {
  INSIGHT_BENCHMARK_TYPE,
  SEPARATORS,
  BENCHMARK_TILE_TYPES,
  NEUTRAL,
  NEUTRAL_VALUES,
} from "utils/constants";
import Popover from "components/shared/popover/popover";
import TableChart from "components/visualizations/charts/table-chart";
import TileEmptyContent from "../tile-empty-content";
import { useEffect } from "react";
import Parser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";

const ClimateRelatedTabular = () => {
  const {
    metadata,
    dataset,
    metadata: { benchmarkType, benchmarkTileType },
    isTableViewActive,
  } = useTileContext();
  const BLOCK = "climateRelatedTopics";

  useEffect(() => {
    if (
      benchmarkTileType === BENCHMARK_TILE_TYPES.SCENARIO_ANALYSIS ||
      benchmarkTileType === BENCHMARK_TILE_TYPES.CLIMATE_RISK_MANAGEMENT
    )
      dataset.map((d) =>
        d.metrics.map((m, metricIndex) =>
          m.metricValue
            ? (m.metricValue = m.metricValue
                .split(SEPARATORS.FIELD)
                .map((f: string) =>
                  f
                    .split(SEPARATORS.VALUE)
                    .map((v, i) =>
                      i > 0 && metricIndex === 0 ? `<span>${v}</span>` : v
                    )
                    .join("<br />")
                )
                .join("<br/><br/>"))
            : null
        )
      );
  }, [dataset, benchmarkTileType]);
  return isTableViewActive &&
    (benchmarkTileType ===
      BENCHMARK_TILE_TYPES.CLIMATE_RELATED_MANAGEMENT_ESRS ||
      (benchmarkTileType !==
        BENCHMARK_TILE_TYPES.CLIMATE_RELATED_MANAGEMENT_ESRS &&
        benchmarkType !== INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK) ||
      benchmarkTileType ===
        BENCHMARK_TILE_TYPES.CLIMATE_RELATED_VALUE_CHAIN_ENGAGEMENT ||
      benchmarkTileType === BENCHMARK_TILE_TYPES.CLIMATE_RISK_MANAGEMENT) ? (
    <div className={`${BLOCK}__container`}>
      <TableChart
        data={mapTableChart(dataset, metadata, true)}
        currentInsightView={benchmarkType}
        leftAlignment={true}
        renderNeutralTooltip={true}
      />
    </div>
  ) : (
    <ClimateTabular />
  );
};

const ClimateTabular = () => {
  const {
    dataset,
    isTableViewActive,
    metadata: { isDetailedView, benchmarkType, benchmarkTileType },
  } = useTileContext();
  const BLOCK = "climate-tabular";

  const noDataCount = dataset.reduce(
    (count, curr) => ({
      [curr.globalCompanyId]: curr.metrics.reduce(
        (t, c) => t + (c.metricValue ? 0 : 1),
        0
      ),
      ...count,
    }),
    {}
  );
  const hasNoData = Object.values(noDataCount).reduce(
    (sum: number, value: any) => sum + value,
    0
  );
  const dispatch = useDispatch();
  const mainTooltip = useSelector(
    (store: RootStore) => store.commons.mainTooltip
  );
  if (
    dataset.length === 0 ||
    dataset[0].metrics.length === 0 ||
    hasNoData === dataset.length * dataset[0].metrics.length
  )
    return <TileEmptyContent />;

  return (
    <div className={`${BLOCK}__wrapper`}>
      <div
        className={classNames(BLOCK, {
          [`${BLOCK}--table`]: isTableViewActive,
        })}
        data-testid={`${BLOCK}`}
      >
        {dataset[0]?.metrics.map((metric, index) => (
          <div
            key={`climate-metric-${index}`}
            className={classNames(`${BLOCK}__metric`, {
              [`${BLOCK}__metric--table`]: isTableViewActive,
              [`${BLOCK}__metric-width-5`]:
                benchmarkTileType ===
                  BENCHMARK_TILE_TYPES.CLIMATE_RISK_MANAGEMENT &&
                isDetailedView,
              [`${BLOCK}__metric-width-3`]:
                benchmarkTileType ===
                BENCHMARK_TILE_TYPES.CLIMATE_RELATED_MANAGEMENT_ESRS,
              [`${BLOCK}__metric-width-1`]:
                (benchmarkTileType ===
                  BENCHMARK_TILE_TYPES.CLIMATE_RELATED_VALUE_CHAIN_ENGAGEMENT &&
                  !isDetailedView) ||
                (benchmarkTileType ===
                  BENCHMARK_TILE_TYPES.CLIMATE_RISK_MANAGEMENT &&
                  (benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK ||
                    benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY) &&
                  !isDetailedView),
              [`${BLOCK}__metric--main`]:
                index === 0 &&
                benchmarkTileType ===
                  BENCHMARK_TILE_TYPES.CLIMATE_RISK_MANAGEMENT &&
                benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY,
            })}
          >
            <div
              className={classNames(`${BLOCK}__metric-title`, {
                [`${BLOCK}__metric-title--table`]: isTableViewActive,
                [`${BLOCK}__metric-title-regular`]:
                  benchmarkTileType ===
                    BENCHMARK_TILE_TYPES.CLIMATE_RELATED_MANAGEMENT_ESRS ||
                  BENCHMARK_TILE_TYPES.CLIMATE_RELATED_VALUE_CHAIN_ENGAGEMENT,
              })}
            >
              <span className={`${BLOCK}__metric-span`}>
                <Popover
                  displayText={metric.metricName}
                  content={
                    typeof metric.metricTooltip === "string"
                      ? []
                      : metric.metricTooltip ?? []
                  }
                  metricClassName=""
                />
              </span>
            </div>
            <div
              className={classNames(`${BLOCK}__data-container`, {
                [`${BLOCK}__data-container--detailed`]: isDetailedView,
                [`${BLOCK}__data-container--table`]: isTableViewActive,
              })}
            >
              {dataset.map((d) =>
                d.metrics.map((m, i) =>
                  index === i ? (
                    <div
                      className={classNames(`${BLOCK}__data`, {
                        [`${BLOCK}__data--detailed`]:
                          isDetailedView &&
                          (benchmarkTileType !==
                            BENCHMARK_TILE_TYPES.CLIMATE_RELATED_VALUE_CHAIN_ENGAGEMENT ||
                            benchmarkTileType !==
                              BENCHMARK_TILE_TYPES.CLIMATE_RELATED_MANAGEMENT_ESRS),
                        [`${BLOCK}__data--detailed-long`]:
                          isDetailedView &&
                          ([
                            BENCHMARK_TILE_TYPES.CLIMATE_RELATED_VALUE_CHAIN_ENGAGEMENT,
                            BENCHMARK_TILE_TYPES.CLIMATE_RELATED_MANAGEMENT_ESRS,
                            BENCHMARK_TILE_TYPES.SCENARIO_ANALYSIS,
                          ].includes(benchmarkTileType)),
                        [`${BLOCK}__data--table`]: isTableViewActive,
                      })}
                      key={`metric-data-${i}`}
                    >
                      {dataset.length > 1 ? <span>{d.companyName}</span> : null}
                      <div
                        className={classNames(`${BLOCK}__metric-data`, {
                          [`${BLOCK}__metric-data--table`]: isTableViewActive,
                          [`${BLOCK}__metric-data--crrm-1`]:
                            benchmarkTileType ===
                              BENCHMARK_TILE_TYPES.CLIMATE_RISK_MANAGEMENT &&
                            i === 0,
                          [`${BLOCK}__metric-data--negative`]:
                            ["no", "not yet"].includes(
                              m.metricValue?.toLocaleLowerCase() ?? ""
                            ) ||
                            m.metricValue
                              ?.toLocaleLowerCase()
                              .startsWith("no,"),
                        })}
                        onMouseEnter={(e) => {
                          if (
                            m.metricValue &&
                            NEUTRAL_VALUES.some((value) =>
                              m.metricValue.includes(value)
                            )
                          )
                            handleMouseEnter(e, "div", NEUTRAL, "", dispatch);
                        }}
                        onMouseLeave={(e) => {
                          handleMouseLeave(
                            e,
                            dispatch,
                            mainTooltip.isOverTooltip
                              ? mainTooltip.isOverTooltip
                              : false
                          );
                        }}
                      >
                        {m.metricValue ? Parser(m.metricValue) : "*"}
                      </div>
                    </div>
                  ) : null
                )
              )}
            </div>
          </div>
        ))}
      </div>
      {hasNoData > 0 ? (
        <span className={`${BLOCK}__no-data-section`}>
          <FormattedMessage id="no.data.available" />
        </span>
      ) : null}
    </div>
  );
};

export default ClimateRelatedTabular;
