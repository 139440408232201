import { Dispatch } from "redux";
import { createAction } from "../../utils/redux.utils";
import * as api from "./saved-comparisons.api";
import {
  setSharedWith,
  savedShortlistLoading,
  shortlistSharePop,
} from "services/saved-shortlist/saved-shortlist.service";
import {
  ComparisonUncheckKeyword,
  SavedComparisonsListModel,
  SavedComparisonsListState,
} from "services/saved-comparisons/saved-comparisons.model";
import {
  DEFAULT_SAVED_COMPARISONS_PAYLOAD,
  pageSelectorSource,
} from "utils/constants";
import history from "utils/history";

export const ACTIONS = {
  SAVED_COMPARISONS_ERROR: "ESG/UI/SAVED_COMPARISONS_ERROR",
  SAVED_COMPARISONS_LOADING: "ESG/UI/SAVED_COMPARISONS_LOADING",
  LOADING_PAGES: "ESG/UI/LOADING_PAGES",
  SAVED_COMPARISONS_NEXT_PAGE_LOADING:
    "ESG/UI/SAVED_COMPARISONS_NEXT_PAGE_LOADING",
  SAVED_COMPARISONS_FETCH: "ESG/UI/SAVED_COMPARISONS_FETCH",
  SAVED_COMPARISONS_NEXT_PAGE: "ESG/UI/SAVED_COMPARISONS_NEXT_PAGE",
  SAVED_COMPARISONS_SORT_LOADING: "ESG/UI/SAVED_COMPARISONS_SORT_LOADING",
  SAVED_COMPARISONS_SORT_FETCH: "ESG/UI/SAVED_COMPARISONS_SORT_FETCH",
  COMPARISON_ITEMS_FETCH: "ESG/UI/COMPARISON_ITEMS_FETCH",
  SHOW_ITEMS_TOOLTIP: "ESG/UI/SHOW_ITEMS_TOOLTIP",
  SHARE_COMPARISONS_SUCCESS: "ESG/UI/SHARE_COMPARISONS_SUCCESS",
  COMPARISONS_SET_SELECTED_LIST: "ESG/UI/COMPARISONS_SET_SELECTED_LIST",
  COMPARISONS_NEW_SAVE: "ESG/UI/COMPARISONS_NEW_SAVE",
  COMPARISONS_ADD_TOCOMPARISON: "ESG/UI/COMPARISONS_ADD_TOCOMPARISON",
  COMPARISON_TO_SAVE: "ESG/UI/COMPARISON_TO_SAVE",
  SAVED_COMPARISON_RESULT: "ESG/UI/SAVED_COMPARISON_RESULT",
  SAVE_COMPARISON_SUCCESS: "ESG/UI/SAVE_COMPARISON_SUCCESS",
  SAVE_COMPARISON_ERROR: "ESG/UI/SAVE_COMPARISON_ERROR",
  SAVE_COMPARISON_POP_UP: "ESG/UI/SAVE_COMPARISON_POP_UP",
  RESET_COMPARISON_TO_SAVE: "ESG/UI/RESET_COMPARISON_TO_SAVE",
  RESET_SAVED_COMPARISON_RESULT: "ESG/UI/RESET_SAVED_COMPARISON_RESULT",
  RESET_SELECTED_LIST: "ESG/UI/RESET_SELECTED_LIST",
  SHARE_COMPARISON_TRACK: "ESG/UI/SHARE_COMPARISON_TRACK",
  SAVED_COMPARISON: "ESG/UI/SAVED_COMPARISON",
  RESET_SAVED_COMPARISON: "ESG/UI/RESET_SAVED_COMPARISON",
  REFRESH_COMPARISONS_LIST: "ESG/UI/REFRESH_COMPARISONS_LIST",
  ERROR_RENAME: "ESG/UI/ERROR_RENAME",
  EDIT_COMPARISON_POP_UP: "ESG/UI/EDIT_COMPARISON_POP_UP",
  DELETE_COMPARISON_POP_UP: "ESG/UI/DELETE_COMPARISON_POP_UP",
  COMPARISON_FILTERING: "ESG/UI/COMPARISON_FILTERING",
  NEW_COMPARISON_TRACK: "ESG/UI/NEW_COMPARISON_TRACK",
  ADD_TO_COMPARISON_TRACK: "ESG/UI/ADD_TO_COMPARISON_TRACK",
  UNCHECK_KEYWORD_REPORT: "ESG/UI/UNCHECK_KEYWORD_REPORT",
  EXECUTE_ADD_TO_COMPARISON_TRACK: "ESG/UI/EXECUTE_ADD_TO_COMPARISON_TRACK",
  TRIGGER_PAGE_ADD_TO_COMPARISON_TRACK:
    "ESG/UI/TRIGGER_PAGE_ADD_TO_COMPARISON_TRACK",
  TRIGGER_PAGE_NEW_COMPARISON_TRACK: "ESG/UI/TRIGGER_PAGE_NEW_COMPARISON_TRACK",
  EXECUTE_NEW_COMPARISON_TRACK: "ESG/UI/EXECUTE_NEW_COMPARISON_TRACK",
  SAVED_COMPARISONS_COUNT: "ESG/UI/SAVED_COMPARISONS_COUNT",
  CONTENT_COMPARISON_EXCEL_EXPORT_TRACK:
    "ESG/UI/CONTENT_COMPARISON_EXCEL_EXPORT_TRACK",
  CONTENT_COMPARISON_COLABORATION_SHARE_TRACK:
    "ESG/UI/CONTENT_COMPARISON_COLABORATION_SHARE_TRACK",
  SHARED_COMPARISON_COLLAB: "SHARED_COMPARISON_COLLAB",
};

export const savedComparisonsGet = createAction(
  ACTIONS.SAVED_COMPARISONS_FETCH
);
export const savedComparisonsError = createAction(
  ACTIONS.SAVED_COMPARISONS_ERROR
);
export const setSavedComparisonList = createAction(
  ACTIONS.SAVED_COMPARISONS_NEXT_PAGE
);
export const savedComparisonsLoading = createAction(
  ACTIONS.SAVED_COMPARISONS_LOADING
);
export const loadingPages = createAction(ACTIONS.LOADING_PAGES);
export const savedComparisonNextPageLoading = createAction(
  ACTIONS.SAVED_COMPARISONS_NEXT_PAGE_LOADING
);
export const savedComparisonsSortLoading = createAction(
  ACTIONS.SAVED_COMPARISONS_SORT_LOADING
);
export const savedComparisonsSortGet = createAction(
  ACTIONS.SAVED_COMPARISONS_SORT_FETCH
);
export const comparisonFilter = createAction(ACTIONS.COMPARISON_FILTERING);
export const comparisonItemsGet = createAction(ACTIONS.COMPARISON_ITEMS_FETCH);
export const showItemsTooltip = createAction(ACTIONS.SHOW_ITEMS_TOOLTIP);
export const shareCompareSuccess = createAction(
  ACTIONS.SHARE_COMPARISONS_SUCCESS
);
export const comparisonsSetSelectedList = createAction(
  ACTIONS.COMPARISONS_SET_SELECTED_LIST
);
export const comparisonsNewSave = createAction(ACTIONS.COMPARISONS_NEW_SAVE);
export const comparisonsAddToComparison = createAction(
  ACTIONS.COMPARISONS_ADD_TOCOMPARISON
);
export const comparisonToSave = createAction(ACTIONS.COMPARISON_TO_SAVE);
export const savedComparisonResult = createAction(
  ACTIONS.SAVED_COMPARISON_RESULT
);
export const saveComparisonSuccess = createAction(
  ACTIONS.SAVE_COMPARISON_SUCCESS
);
export const saveComparisonError = createAction(ACTIONS.SAVE_COMPARISON_ERROR);
export const saveComparisonPopUp = createAction(ACTIONS.SAVE_COMPARISON_POP_UP);
export const resetComparisonToSave = createAction(
  ACTIONS.RESET_COMPARISON_TO_SAVE
);
export const resetSavedComparisonResult = createAction(
  ACTIONS.RESET_SAVED_COMPARISON_RESULT
);
export const resetSelectedList = createAction(ACTIONS.RESET_SELECTED_LIST);

export const shareComparisonTrack = createAction(
  ACTIONS.SHARE_COMPARISON_TRACK
);
export const savedComparison = createAction(ACTIONS.SAVED_COMPARISON);
export const resetSavedComparison = createAction(
  ACTIONS.RESET_SAVED_COMPARISON
);
export const refreshComparisonsList = createAction(
  ACTIONS.REFRESH_COMPARISONS_LIST
);
export const errorRename = createAction(ACTIONS.ERROR_RENAME);
export const editComparisonPopUp = createAction(ACTIONS.EDIT_COMPARISON_POP_UP);
export const deleteComparisonPopUp = createAction(
  ACTIONS.DELETE_COMPARISON_POP_UP
);
export const newComparisonTrack = createAction(ACTIONS.NEW_COMPARISON_TRACK);
export const addToComparisonTrack = createAction(
  ACTIONS.ADD_TO_COMPARISON_TRACK
);
export const uncheckKeywordReport = createAction(
  ACTIONS.UNCHECK_KEYWORD_REPORT
);
export const executeAddToComparisonTrack = createAction(
  ACTIONS.EXECUTE_ADD_TO_COMPARISON_TRACK
);
export const triggerPageAddToComparisonTrack = createAction(
  ACTIONS.TRIGGER_PAGE_ADD_TO_COMPARISON_TRACK
);
export const triggerPageNewComparisonTrack = createAction(
  ACTIONS.TRIGGER_PAGE_NEW_COMPARISON_TRACK
);
export const executeNewComparisonTrack = createAction(
  ACTIONS.EXECUTE_NEW_COMPARISON_TRACK
);

export const savedComparisonCount = createAction(
  ACTIONS.SAVED_COMPARISONS_COUNT
);

export const contentComparisonExcelExportTrack = createAction(
  ACTIONS.CONTENT_COMPARISON_EXCEL_EXPORT_TRACK
);

export const contentComparisonShareTrack = createAction(
  ACTIONS.CONTENT_COMPARISON_COLABORATION_SHARE_TRACK
);

export const contentComparisonShareCollab = createAction(
  ACTIONS.SHARED_COMPARISON_COLLAB
);

export const loadSavedComparisonsList =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedComparisonsLoading(true));
      const res = await api.fetchComparisons(payload);
      dispatch(savedComparisonsGet(res.data));
      dispatch(savedComparisonsLoading(false));
    } catch (e) {
      dispatch(savedComparisonsLoading(false));
      dispatch(savedComparisonsError(e));
    }
  };

export const loadSavedComparisonsListNextPage =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedComparisonNextPageLoading(true));
      const res = await api.fetchComparisons(payload);
      dispatch(setSavedComparisonList(res.data));
      dispatch(savedComparisonNextPageLoading(false));
    } catch (e) {
      dispatch(savedComparisonNextPageLoading(false));
    }
  };
export const loadComparisonItems =
  (comparisonId: number) => async (dispatch: Dispatch<any>) => {
    try {
      const res = await api.fetchComparisonItems(comparisonId);
      dispatch(comparisonItemsGet(res.data));
      dispatch(showItemsTooltip(true));
    } catch (e) {
      dispatch(savedComparisonsError(e));
    }
  };

export const viewSpecifiedPages =
  (report: any, pageSelection: string | null, comparisonId: number) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(loadingPages({ [report.reportId]: true }));
      const comparisonItems = await api.selectPages(comparisonId, {
        comparisonId,
        comparisonItemId: report.comparisonItemId,
        comparisonItemType: report.comparisonItemType,
        esgReport: {
          reportId: report.reportId,
          baseReportId:
            report.esgReport &&
            report.esgReport.baseReportId &&
            report.esgReport.reportType !== pageSelectorSource.SHORTLIST
              ? report.esgReport.baseReportId
              : report.reportId,
          reportType: pageSelectorSource.COMPARISON,
          pageSelection,
        },
        sectionItem: {
          sectionId: 0,
        },
        contentKeywords: report.contentKeywords,
      });
      dispatch(
        comparisonsSetSelectedList(comparisonItems.data.comparisonItems)
      );
      dispatch(loadingPages({ [report.reportId]: false }));
    } catch (e) {
      dispatch(savedComparisonsError(e));
      dispatch(loadingPages({ [report.reportId]: false }));
    }
  };

export const loadsavedComparisonsSortList =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedComparisonsSortLoading(true));
      const res = await api.fetchComparisons(payload);
      dispatch(savedComparisonsSortGet(res.data));
      dispatch(savedComparisonsSortLoading(false));
    } catch (e) {
      dispatch(savedComparisonsSortLoading(false));
    }
  };

export const filterComparisons =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedComparisonsLoading(true));
      const res = await api.fetchComparisons(payload);
      dispatch(savedComparisonsGet(res.data));
      dispatch(comparisonFilter(""));
      dispatch(savedComparisonsLoading(false));
    } catch (e) {
      dispatch(savedComparisonsLoading(false));
      dispatch(savedComparisonsError(e));
    }
  };

export const shareCompareList =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedShortlistLoading(true));
      await api.shareCompare({
        comparisonName: payload.comparisonName,
        comparisonId: payload.comparisonID,
        SharedWith: payload.sharedWith,
        sourceComparisonId: payload.sourceComparisonId,
        includeNotes: payload.includeNotes,
        includeTags: payload.includeTags,
        IsCollaboration: payload.IsCollaboration,
      });
      dispatch(contentComparisonShareCollab(payload.IsCollaboration));
      dispatch(setSharedWith([]));
      dispatch(shortlistSharePop(null));
      dispatch(savedShortlistLoading(false));
      dispatch(refreshComparisonsList(true));
      dispatch(shareCompareSuccess(true));

      if (payload.IsCollaboration)
        dispatch(
          contentComparisonShareTrack(
            `Share Content benchmark - ${
              payload.sharedWith ? payload.sharedWith.length : 0
            }`
          )
        );
      else {
        let shareTrackPayload = `Compare share | ${payload.comparisonName}`;
        dispatch(shareComparisonTrack(shareTrackPayload));
      }
    } catch (e) {
      dispatch(savedShortlistLoading(false));
      dispatch(shareCompareSuccess(false));
    }
  };
export const setComparisonsSelectedList =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(comparisonsSetSelectedList(payload));
    } catch (e) {
      console.log("error");
    }
  };

export const deleteEsgReportFromCompare =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      await api.deleteESGReport(payload);
    } catch (e) {
      console.log("error ", e);
    }
  };

export const deleteSECReportFromCompare =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      await api.deleteSECReport(payload);
    } catch (e) {
      console.log("error ", e);
    }
  };

export const autoSaveComparisonList =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      await api.autoSaveComparisonsList(payload);
    } catch (e) {
      console.log("error on auto save");
    }
  };

export const newComparison =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedComparisonsSortLoading(true));
      const res = await api.newComparisons(payload);
      dispatch(savedComparisonsSortGet(res.data));
      dispatch(savedComparisonsSortLoading(false));
    } catch (e) {
      dispatch(savedComparisonsSortLoading(false));
    }
  };

export const addtoComparison =
  (reportList: any[], comparisonId: number) =>
  async (dispatch: Dispatch<any>) => {
    try {
      let tmpComparisonItems = reportList.map((report, index) => {
        return {
          esgReport: {
            reportId: report.comparisonItemType === 0 ? report.reportId : 0,
            reportTitle:
              report.comparisonItemType === 0 ? report.reportTitle : "",
          },
          sectionItem: {
            sectionId: report.comparisonItemType === 0 ? 0 : report.sectionId,
            sectionName:
              report.comparisonItemType === 0 ? "" : report.reportTitle,
          },
          comparisonItemType: report.comparisonItemType,
          contentKeywords: report.contentKeywords,
        };
      });
      let payload = {
        comparisonId: comparisonId,
        comparisonItems: tmpComparisonItems,
      };
      dispatch(savedComparisonsSortLoading(true));
      const res = await api.addtoComparisons(payload);
      dispatch(savedComparisonsSortGet(res.data));
      dispatch(savedComparisonsSortLoading(false));
    } catch (e) {
      dispatch(savedComparisonsSortLoading(false));
    }
  };

export const saveComparison =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedComparisonsLoading(true));
      const res = await api.saveComparisonWithItems(payload);
      dispatch(savedComparisonResult(res.data));
      dispatch(resetComparisonToSave());
      dispatch(saveComparisonSuccess(true));
      dispatch(savedComparisonsLoading(false));
      dispatch(setComparisonsSelectedList(res.data.comparisonItems));
      history.push(`/comparison/${res.data.comparisonId}`);
    } catch (e) {
      dispatch(comparisonToSave(payload));
      dispatch(savedComparisonsLoading(false));
      dispatch(saveComparisonError(true));
    }
  };

export const saveAsComparison =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedComparisonsLoading(true));
      const res = await api.saveAsComparisonWithItems(payload);
      dispatch(savedComparisonResult(res.data));
      dispatch(resetComparisonToSave());
      dispatch(saveComparisonSuccess(true));
      dispatch(savedComparisonsLoading(false));
    } catch (e) {
      dispatch(comparisonToSave(payload));
      dispatch(savedComparisonsLoading(false));
      dispatch(saveComparisonError(true));
    }
  };

export const editComparison =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      //dispatch(savedComparisonsLoading(true));
      const res = await api.saveComparisonWithItems(payload);
      dispatch(savedComparisonResult(res.data));
      dispatch(resetSavedComparison());
      dispatch(saveComparisonSuccess(true));
      //dispatch(savedComparisonsLoading(false));
    } catch (e) {
      dispatch(errorRename(true));
      dispatch(comparisonToSave(payload));
      //dispatch(savedComparisonsLoading(false));
    }
  };

export const deleteComparison =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(savedComparisonsLoading(true));
      await api.deleteComparison(payload);
      dispatch(deleteComparisonPopUp(false));
      dispatch(resetSavedComparison());
      dispatch(savedComparisonsLoading(false));
      dispatch(refreshComparisonsList(true));
    } catch (e) {
      dispatch(savedComparisonsLoading(false));
    }
  };

export const toggleUncheckedKeyword =
  (id: number, comparisonItemId: number, currentUnchecked: any) =>
  async (dispatch: Dispatch<any>) => {
    currentUnchecked = currentUnchecked
      ? currentUnchecked
      : {
          comparisionItemIds: [],
        };
    const hasId = currentUnchecked.comparisionItemIds.some(
      (uncheckedId: ComparisonUncheckKeyword) => uncheckedId.id === id
    );

    if (hasId) {
      currentUnchecked.comparisionItemIds =
        currentUnchecked.comparisionItemIds.filter(
          (uncheckedId: ComparisonUncheckKeyword) => uncheckedId.id !== id
        );
    } else {
      currentUnchecked.comparisionItemIds.push({
        id: id,
        comparisonItemId: comparisonItemId,
      });
    }
    dispatch(uncheckKeywordReport(currentUnchecked));
  };

export const getComparisonCount = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(savedComparisonsLoading(true));
    const res = await api.getCount();
    dispatch(savedComparisonCount(res.data));
    dispatch(loadSavedComparisonsList(DEFAULT_SAVED_COMPARISONS_PAYLOAD));
    dispatch(savedComparisonsLoading(false));
  } catch (e) {
    dispatch(savedComparisonsLoading(false));
  }
};

const savedComparisonsListModel = new SavedComparisonsListModel();

const savedComparisonsReducer = (
  state: SavedComparisonsListState = savedComparisonsListModel.initialState(),
  action: any
): SavedComparisonsListState => {
  switch (action.type) {
    case ACTIONS.SAVED_COMPARISONS_ERROR:
      return savedComparisonsListModel.setError(action.payload);
    case ACTIONS.SAVED_COMPARISONS_LOADING:
      return savedComparisonsListModel.setLoading(action.payload);
    case ACTIONS.LOADING_PAGES:
      return savedComparisonsListModel.setLoadingPages(action.payload);
    case ACTIONS.SAVED_COMPARISONS_NEXT_PAGE_LOADING:
      return savedComparisonsListModel.setNextPageLoading(action.payload);
    case ACTIONS.SAVED_COMPARISONS_NEXT_PAGE:
      return savedComparisonsListModel.setComparisonList(action.payload);
    case ACTIONS.SAVED_COMPARISONS_FETCH:
      return savedComparisonsListModel.setSavedComparisonsList(action.payload);
    case ACTIONS.SAVED_COMPARISONS_SORT_LOADING:
      return savedComparisonsListModel.setSortLoading(action.payload);
    case ACTIONS.SAVED_COMPARISONS_SORT_FETCH:
      return savedComparisonsListModel.setSavedComparisonsList(action.payload);
    case ACTIONS.COMPARISON_ITEMS_FETCH:
      return savedComparisonsListModel.setComparisonItems(action.payload);
    case ACTIONS.SHOW_ITEMS_TOOLTIP:
      return savedComparisonsListModel.setShowItemsTooltip(action.payload);
    case ACTIONS.SHARE_COMPARISONS_SUCCESS:
      return savedComparisonsListModel.setShareSuccess(action.payload);
    case ACTIONS.COMPARISONS_SET_SELECTED_LIST:
      return savedComparisonsListModel.setSelected(action.payload);
    case ACTIONS.COMPARISONS_NEW_SAVE:
      return savedComparisonsListModel.setSavedComparisonsList(action.payload);
    case ACTIONS.COMPARISONS_ADD_TOCOMPARISON:
      return savedComparisonsListModel.setSavedComparisonsList(action.payload);
    case ACTIONS.COMPARISON_TO_SAVE:
      return savedComparisonsListModel.setComparisonToSave(action.payload);
    case ACTIONS.SAVED_COMPARISON_RESULT:
      return savedComparisonsListModel.setSavedComparisonResult(action.payload);
    case ACTIONS.SAVE_COMPARISON_SUCCESS:
      return savedComparisonsListModel.setSaveComparisonSuccess(action.payload);
    case ACTIONS.SAVE_COMPARISON_ERROR:
      return savedComparisonsListModel.setSaveComparisonError(action.payload);
    case ACTIONS.SAVE_COMPARISON_POP_UP:
      return savedComparisonsListModel.setSaveComparisonPopUp(action.payload);
    case ACTIONS.RESET_COMPARISON_TO_SAVE:
      return savedComparisonsListModel.resetComparisonToSave();
    case ACTIONS.RESET_SAVED_COMPARISON_RESULT:
      return savedComparisonsListModel.resetSavedComparisonResult();
    case ACTIONS.RESET_SELECTED_LIST:
      return savedComparisonsListModel.resetSelectedList();
    case ACTIONS.SAVED_COMPARISON:
      return savedComparisonsListModel.setSavedComparison(action.payload);
    case ACTIONS.RESET_SAVED_COMPARISON:
      return savedComparisonsListModel.resetSavedComparison();
    case ACTIONS.REFRESH_COMPARISONS_LIST:
      return savedComparisonsListModel.setRefreshComparisonsList(
        action.payload
      );
    case ACTIONS.ERROR_RENAME:
      return savedComparisonsListModel.setErrorRename(action.payload);
    case ACTIONS.EDIT_COMPARISON_POP_UP:
      return savedComparisonsListModel.setEditComparisonPopUp(action.payload);
    case ACTIONS.DELETE_COMPARISON_POP_UP:
      return savedComparisonsListModel.setDeleteComparisonPopUp(action.payload);
    case ACTIONS.COMPARISON_FILTERING:
      return savedComparisonsListModel.setCurrentFilter(action.payload);
    case ACTIONS.UNCHECK_KEYWORD_REPORT:
      return savedComparisonsListModel.setUncheckedKeywordReports(
        action.payload
      );
    case ACTIONS.EXECUTE_ADD_TO_COMPARISON_TRACK:
      return savedComparisonsListModel.setExecuteAddToComparisonTrack(
        action.payload
      );
    case ACTIONS.TRIGGER_PAGE_ADD_TO_COMPARISON_TRACK:
      return savedComparisonsListModel.setTriggerPageAddToComparisonTrack(
        action.payload
      );
    case ACTIONS.TRIGGER_PAGE_NEW_COMPARISON_TRACK:
      return savedComparisonsListModel.setTriggerPageNewComparisonTrack(
        action.payload
      );
    case ACTIONS.EXECUTE_NEW_COMPARISON_TRACK:
      return savedComparisonsListModel.setExecuteNewComparisonTrack(
        action.payload
      );
    case ACTIONS.NEW_COMPARISON_TRACK:
      return savedComparisonsListModel.cleanUpNewComparisonTrack();

    case ACTIONS.SAVED_COMPARISONS_COUNT:
      return savedComparisonsListModel.setsavedComparisonCount(action.payload);

    case ACTIONS.SHARED_COMPARISON_COLLAB:
      return savedComparisonsListModel.setIsCollab(action.payload);

    default:
      return state;
  }
};

export default savedComparisonsReducer;
