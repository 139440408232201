import Icon from "components/shared/icon/icon";

type Props = {
  filterText: string;
  handleRemove: any;
};

const ActiveFilterTag = ({ filterText, handleRemove }: Props) => {
  const BLOCK = "active-filter-tag";
  return (
    <div className={`${BLOCK}`} data-testid="activeFilter-Tag">
      <span className={`${BLOCK}__filter-icon`}>
        <Icon name="filter" height={14} width={14} />
      </span>
      <span>{filterText}</span>
      <button
        className={`${BLOCK}__remove`}
        onClick={() => handleRemove()}
        data-testid="remove-filter"
      >
        <Icon name="cross" height={22} />
      </button>
    </div>
  );
};

export default ActiveFilterTag;
