import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import { TooltipPosition } from "components/shared/tooltip/tooltip";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showTooltip } from "services/commons.service";
import { RootStore } from "services/store.service";
import { INSIGHT_BENCHMARK_TYPE, NEUTRAL } from "utils/constants";
import Parser from "html-react-parser";

type Props = {
  data: any;
  peerData: any;
  metadata: any;
};

const VerificationChart = ({ data, peerData, metadata }: Props) => {
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const dispatch = useDispatch();
  const BLOCK = "verification";

  //Check if every metrics has no value and it is null
  const noCompData =
    data &&
    data?.metrics?.reduce(
      (acc: any, metric: any) =>
        acc &&
        data?.data?.filter(
          (res: any) =>
            res[metric.metricKey] === null ||
            res[metric.metricKey] === undefined ||
            res[metric.metricKey] === ""
        ).length === data?.data?.length,
      true
    );

  //Check if any metrics have no value and it is null
  const missingCompData =
    data &&
    data?.metrics?.reduce(
      (acc: any, metric: any) =>
        acc ||
        data?.data?.filter(
          (res: any) =>
            res[metric.metricKey] === null ||
            res[metric.metricKey] === undefined ||
            res[metric.metricKey] === ""
        ).length > 0,
      false
    );

  //Check if every metrics has no value in peer and Industry view
  const hasNoPeerIndData =
    peerData &&
    peerData?.labels?.reduce(
      (acc: any, metric: any) =>
        acc &&
        peerData.response.filter(
          (res: any) =>
            res[metric.id] === null ||
            res[metric.id] === undefined ||
            res[metric.id] === ""
        ).length === peerData.response.length,
      true
    );

  //Check if any metrics have no value and it is null in Peer and Industry view
  const hasMissingPeerIndData =
    peerData &&
    peerData?.labels?.reduce(
      (acc: any, metric: any) =>
        acc ||
        peerData.response.filter(
          (res: any) =>
            res[metric.id] === null ||
            res[metric.id] === undefined ||
            res[metric.id] === ""
        ).length > 0,
      false
    );

  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("div");
    if (!element) {
      return;
    }

    dispatch(
      showTooltip({
        children: Parser(children),
        position: TooltipPosition.top,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  // We are using renderPeerIndView for showing Peer and Industry data in chart view
  const renderPeerIndView = () => {
    return (
      <>
        {peerData.response.length > 1 ? (
          <table className={`${BLOCK}__table`}>
            <thead className={`${BLOCK}__row`}>
              <tr>
                <td></td>
                {peerData.response.map((d: any, i: number) => (
                  <td key={`data-header-${i}`}>{d.header}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {peerData.labels.map((l: any, i: any) => (
                <tr key={`data-row-${i}`}>
                  <td
                    className={`${BLOCK}__table-metric-td`}
                    key={`data-metric-${i}`}
                  >
                    <div key={`data-label-${i}`}>
                      <Popover
                        displayText={l.label}
                        content={l.tooltip}
                        metricClassName={`${BLOCK}__table-metric`}
                      />
                    </div>
                  </td>
                  {peerData.response.map((res: any, j: any) => (
                    <td
                      className={`${BLOCK}__table-data`}
                      key={`table-data-${j}`}
                    >
                      <div
                        className={`${BLOCK}__metric-values`}
                        onMouseEnter={(e) => {
                          if (
                            (res[l.id] && res[l.id] === "Neutral") ||
                            res[l.id] === "neutral"
                          )
                            handleMouseEnter(e, NEUTRAL);
                        }}
                        onMouseLeave={(e) => {
                          handleMouseLeave(e);
                        }}
                      >
                        {res[l.id] ? res[l.id] : "*"}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </>
    );
  };
  const renderDetailPeerChart = () => {
    return (
      <>
        {peerData.response.length > 1 ? (
          <table className={`${BLOCK}__table`}>
            <thead className={`${BLOCK}__row`}>
              <tr>
                <td></td>
                {peerData.labels.map((d: any, i: number) => (
                  <td key={`data-header-${i}`}>
                    <div className={``} key={`data-value-${i}`}>
                      {" "}
                      <Popover
                        displayText={d.label}
                        metricClassName={`${BLOCK}__table-detailViewHeader`}
                        content={d.tooltip ?? []}
                        buttonClassName={`${BLOCK}__table-detailViewHeader-btn`}
                      />
                    </div>
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {peerData.response.map((l: any, i: any) => (
                <tr>
                  <td
                    className={`${BLOCK}__table-metric`}
                    key={`data-metric-${i}`}
                  >
                    <div
                      className={`${BLOCK}__table-detailViewMetric`}
                      key={`data-header-${i}`}
                    >
                      {l.header}
                    </div>
                  </td>
                  {peerData.labels.map((res: any, j: any) => (
                    <td
                      className={`${BLOCK}__table-data`}
                      key={`table-data-${j}`}
                    >
                      {l[res.id] ? l[res.id] : "*"}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </>
    );
  };
  // We are using companyView for showing company data in chart view
  const companyView = () => {
    return (
      <div>
        {data.metrics.map((metric: any, i: number) => (
          <Fragment key={`chart-view-${i}`}>
            <div
              className={`${BLOCK}__chart-container`}
              key={`chart-container-${i}`}
            >
              <div
                className={`${BLOCK}__chart-container-metric-wrapper`}
                key={`data-metric-${i}`}
              >
                <Popover
                  displayText={metric.metric}
                  content={metric.description ?? []}
                  metricClassName={`${BLOCK}__chart-container-metric`}
                />
              </div>
              <div
                className={`${BLOCK}__chart-container-metric-value`}
                key={`data-value-${i}`}
              >
                {data?.data[0][metric.metricKey]
                  ? data.data[0][metric.metricKey]
                  : "*"}{" "}
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    );
  };

  return (
    <div>
      {noCompData || hasNoPeerIndData ? (
        <div className={"DashboardTile__noData"}>
          {" "}
          <FormattedMessage id="insights.cdp.no-data" />
        </div>
      ) : metadata.benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY ? (
        companyView()
      ) : metadata.isDetailedView ? (
        renderDetailPeerChart()
      ) : (
        renderPeerIndView()
      )}
      {(missingCompData || hasMissingPeerIndData) &&
      !noCompData &&
      !hasNoPeerIndData ? (
        <div className={"DashboardTile__noDataAny"}>
          {" "}
          <FormattedMessage id="no.data.available" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default VerificationChart;
