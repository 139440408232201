import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";

type Props = {
  label?: any;
  value: boolean;
  onChange: any;
  disabled?: boolean;
  tooltip?: any;
};

const Toggle = ({ label, value, onChange, disabled, tooltip }: Props) => {
  const BLOCK = "toggle";
  return (
    <div className={BLOCK} data-testid="toggle-container">
      <label className={`${BLOCK}__toggle`}>
        {tooltip && (
          <Tooltip position={TooltipPosition.progressBarTop}>{tooltip}</Tooltip>
        )}
        <input
          className={BLOCK}
          type="checkbox"
          checked={value}
          onChange={onChange}
          disabled={disabled}
          data-test="checkbox-item"
          data-testid="checkbox-item"
        />
        <div className="slider"></div>
      </label>
      <span className={`${BLOCK}__label`} onClick={onChange}>
        {label}
      </span>
    </div>
  );
};

export default Toggle;
