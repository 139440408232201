import { Dispatch } from "redux";
import { createAction } from "utils/redux.utils";
import { NoteModel, NoteState } from "./note.model";
import * as api from "./note.api";

/*
 * Actions
 */

export const ACTIONS = {
  NOTE_ERROR: "ESG/UI/NOTE_ERROR",
  NOTE_LOADING: "ESG/UI/NOTE_LOADING",
  NOTE_FETCH: "ESG/UI/NOTE_FETCH",
  NOTE_SAVE_SUCCESS: "ESG/UI/NOTE_SAVE_SUCCESS",
  NOTE_SAVE_ERROR: "ESG/UI/NOTE_SAVE_ERROR",
  NOTE_DELETE_SUCCESS: "ESG/UI/NOTE_DELETE_SUCCESS",
  NOTE_DELETE_ERROR: "ESG/UI/NOTE_DELETE_ERROR",
};

export const noteError = createAction(ACTIONS.NOTE_ERROR);
export const noteLoading = createAction(ACTIONS.NOTE_LOADING);
export const noteGet = createAction(ACTIONS.NOTE_FETCH);
export const noteSaveSuccess = createAction(ACTIONS.NOTE_SAVE_SUCCESS);
export const noteSaveError = createAction(ACTIONS.NOTE_SAVE_ERROR);
export const noteDeleteSuccess = createAction(ACTIONS.NOTE_DELETE_SUCCESS);
export const noteDeleteError = createAction(ACTIONS.NOTE_DELETE_ERROR);

export const loadNote = (Payload: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(noteLoading(true));
    const res = await api.fetchNote(Payload);
    dispatch(noteGet(res.data));
    dispatch(noteLoading(false));
  } catch (e) {
    dispatch(noteLoading(false));
    dispatch(noteError(e));
  }
};

export const saveNote = (Payload: any) => async (dispatch: Dispatch<any>) => {
  try {
    const res = await api.saveNote(Payload);
    dispatch(noteSaveSuccess(true));
    dispatch(noteGet(res.data));
  } catch (e) {
    dispatch(noteSaveError(true));
    dispatch(noteError(e));
  }
};

export const updateNote = (Payload: any) => async (dispatch: Dispatch<any>) => {
  try {
    const res = await api.editNote(Payload);
    dispatch(noteSaveSuccess(true));
    dispatch(noteGet(res.data));
  } catch (e) {
    dispatch(noteSaveError(true));
    dispatch(noteError(e));
  }
};

export const deleteNote = (Payload: any) => async (dispatch: Dispatch<any>) => {
  try {
    await api.deleteNote(Payload);
    dispatch(noteDeleteSuccess(true));
    dispatch(noteGet([]));
  } catch (e) {
    dispatch(noteDeleteError(true));
    dispatch(noteError(e));
  }
};

/*
 * Reducer
 */

const noteModel = new NoteModel();

const noteReducer = (
  state: NoteState = noteModel.initialState(),
  action: any
): NoteState => {
  switch (action.type) {
    case ACTIONS.NOTE_ERROR:
      return noteModel.setError(action.payload);
    case ACTIONS.NOTE_LOADING:
      return noteModel.setLoading(action.payload);
    case ACTIONS.NOTE_FETCH:
      return noteModel.setNote(action.payload);
    case ACTIONS.NOTE_SAVE_SUCCESS:
      return noteModel.setSaveSuccess(action.payload);
    case ACTIONS.NOTE_SAVE_ERROR:
      return noteModel.setSaveError(action.payload);
    case ACTIONS.NOTE_DELETE_SUCCESS:
      return noteModel.setDeleteSuccess(action.payload);
    case ACTIONS.NOTE_DELETE_ERROR:
      return noteModel.setDeleteError(action.payload);
    default:
      return state;
  }
};

export default noteReducer;
