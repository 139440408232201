import { AxiosRequestConfig } from "axios";
import api from "../api.service";

export const fetchComparisons = (payload: any) =>
  api.post(`comparisons/list`, payload).then(({ data }) => data);

export const newComparisons = (payload: any) =>
  api.post(`comparisons`, payload).then(({ data }) => data);

export const shareCompare = (payload: {
  comparisonName: string;
  comparisonId: number;
  SharedWith: any;
  sourceComparisonId: number;
  includeNotes: boolean;
  includeTags: boolean;
  IsCollaboration: number;
}) => {
  return api.post(`/comparisons/share`, payload).then(({ data }) => data);
};

export const addtoComparisons = (payload: any) =>
  api.put(`comparisons`, payload).then(({ data }) => data);

export const fetchComparisonItems = (
  comparisonId: number,
  config?: AxiosRequestConfig
) =>
  api.get(`comparisons/${comparisonId}/items`, config).then(({ data }) => data);

export const saveComparisonWithItems = (payload: any) =>
  api.post(`comparisons`, payload).then(({ data }) => data);

export const saveAsComparisonWithItems = (payload: any) =>
  api.post(`comparisons/saveAs`, payload).then(({ data }) => data);

export const autoSaveComparisonsList = (payload: any) => {
  return api.put(`/comparisons`, payload).then(({ data }) => data);
};

export const deleteESGReport = (payload: any) => {
  api.delete(
    `/comparisons/${payload.comparisonId}/comparisonItem/esg/${payload.reportId}`
  );
};

export const deleteSECReport = (payload: any) => {
  api.delete(
    `/comparisons/${payload.comparisonId}/comparisonItem/sec/${payload.sectionId}`
  );
};

export const deleteComparison = (comparisonId: number) =>
  api.delete(`comparisons?comparisonId=${comparisonId}`);

export const selectPages = (comparisonId: number, payload: any) =>
  api
    .put(`comparisons/${comparisonId}/selectPages`, payload)
    .then(({ data }) => data);

export const getCount = () => {
  return api.get(`/comparisons/shared/newCount`).then(({ data }) => data);
};

export const saveComparisonSearchKeywords = (payload: {
  comparisonId: number;
  contentKeywords: string | null;
}) => api.patch(`comparisons/keywords`, payload).then(({ data }) => data);