import {
  EsgSearchResultsReport,
  SecSearchResultsDocumentSection,
  SecSearchResultsReport,
} from "services/search/search.results.model";
import { BaseModel, errorModel } from "utils/redux.utils";

export interface DocumentViewerState {
  error?: errorModel;
  loading: boolean;
  SECStatements: SECDisclosureStatements;
  selectedReport: EsgSearchResultsReport | null;
  selectedDisclosure: SecSearchResultsReport | null;
  fullscreen: boolean;
  selectedTOCTab: number;
  currentSelectionPage: any;
}

export class DocumentViewerModel extends BaseModel {
  constructor() {
    super({
      loading: false,
      SECStatements: null,
      selectedReport: null,
      selectedDisclosure: null,
      setFullscreen: false,
      selectedTOCTab: null,
      currentSelectionPage: null,
    });
  }
  setError = (error: errorModel): DocumentViewerState => this.merge({ error });
  setLoading = (): DocumentViewerState => this.merge({ loading: true });

  setDocumentViewerSECStatements = (
    SECStatements: SECDisclosureStatements
  ): DocumentViewerState => this.merge({ SECStatements, loading: false });

  setSelectedReport = (selectedReport: EsgSearchResultsReport) =>
    this.merge({ selectedReport });

  setSelectedDisclosure = (selectedDisclosure: SecSearchResultsReport) =>
    this.merge({ selectedDisclosure });

  setDisclosureElementId = (elementId: number) =>
    this.merge({
      selectedTOCTab: elementId,
    });

  setFullscreen = (fullscreen: boolean) => this.merge({ fullscreen });

  setCurrentSelectionPage = (currentSelectionPage: any) =>
    this.merge({ currentSelectionPage });
}

export type ESGReportLayout = {
  range: {
    start: number;
    end: number;
  };
  width: number;
  height: number;
  dpiX: number;
  dpiY: number;
  pageNumber: number;
};

export type ESGReportKeyword = {
  keywordTextContent: string;
  pageNumber: number;
  locations: {
    offset: number[];
    indicatorPosition: any;
  }[];
  // For setting up color for each keyword for highlighting
  colorCode?: string;
};

export type SECDisclosureStatements = {
  html: string;
  filingSecLink: string;
  fleschKincaidReadabilitySentence: number;
  keywords: any[];
  wordCount: number;
  filingId: number;
  elementId: number;
  companyName: string;
  title: string;
  formType: string;
  year: number;
  documentSectionPath: SecSearchResultsDocumentSection[];
};
