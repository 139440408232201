import classNames from "classnames";
import Icon from "components/shared/icon/icon";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { useRef, useState } from "react";
import { comparisonItemTypes, reportNameLimit } from "utils/constants";
import { useClickOutside } from "utils/functions";

type Props = {
  BLOCK: string;
  selectedIndex: number;
  options: any[];
  onSelectReport: any;
  hideComponent: boolean;
  indexListToHide: number[];
  customWidth?: number;
};

const ReportSelector = ({
  BLOCK,
  selectedIndex,
  options,
  onSelectReport,
  hideComponent,
  indexListToHide,
  customWidth,
}: Props) => {
  const reportMenuRef = useRef(null);
  const [showReportSelector, setShowReportSelector] = useState<boolean>(false);
  useClickOutside(reportMenuRef, () => setShowReportSelector(false));
  const reportName =
    options[selectedIndex] && options[selectedIndex].comparisonItemType
      ? options[selectedIndex].sectionName ||
        options[selectedIndex]?.reportTitle
      : options[selectedIndex]?.reportTitle;

  return (
    <div
      ref={reportMenuRef}
      className={classNames(
        `${BLOCK}__report-dropdown`,
        {
          [`${BLOCK}__report-dropdown--hidden`]: hideComponent,
          [`${BLOCK}__report-dropdown--sec`]:
            options[selectedIndex]?.comparisonItemType ===
            comparisonItemTypes.SEC,
        },
        {
          [`${BLOCK}__report-dropdown--esg`]:
            options[selectedIndex]?.comparisonItemType ===
            comparisonItemTypes.ESG,
        }
      )}
      style={{
        width: customWidth ? `calc( 100% - ${customWidth}px)` : "90%",
      }}
    >
      <div
        className={classNames(`${BLOCK}__base-report-title`, {
          [`${BLOCK}__base-report-title--sec`]:
            options[selectedIndex]?.comparisonItemType ===
            comparisonItemTypes.SEC,
        })}
        onClick={() => setShowReportSelector(!showReportSelector)}
        data-testid="base-report-title"
      >
        <span className={`${BLOCK}__selector-title`}>
          {reportName}
          {reportName && reportName.length > reportNameLimit ? (
            <Tooltip position={TooltipPosition.bottom}>
              <>{reportName}</>
            </Tooltip>
          ) : null}
        </span>
        <Icon
          className={classNames(`${BLOCK}__dropdown-icon`, {
            [`${BLOCK}__dropdown-icon--open`]: showReportSelector,
          })}
          name={"chevron-down"}
          width={24}
          height={24}
        />
      </div>
      <div
        className={classNames(`${BLOCK}__hidden-menu`, {
          [`${BLOCK}__hidden-menu--open`]: showReportSelector,
        })}
      >
        {options.map((report, index) =>
          !indexListToHide.includes(index) ? (
            <button
              key={`report-option-${index}`}
              className={classNames(`${BLOCK}__menu-item`, {
                [`${BLOCK}__menu-item--active`]: index === selectedIndex,
              })}
              onClick={() => {
                onSelectReport(index);
                setShowReportSelector(false);
              }}
              data-testid="menu-item"
            >
              <span className={`${BLOCK}__menu-item-title`}>
                {report.comparisonItemType
                  ? report.sectionName || report.reportTitle
                  : report.reportTitle}
              </span>

              {index === selectedIndex && <Icon name="tick" width={24} />}
            </button>
          ) : null
        )}
      </div>
    </div>
  );
};

export default ReportSelector;
