import { useRef, useEffect, useState } from "react";

const ResizeGridRow = ({
  className,
  rowContent,
  setSize,
  setOriginalSize,
  height,
  setIsRowResize,
  maxheightLimit,
}: {
  className: string;
  rowContent: JSX.Element;
  setSize: Function;
  setOriginalSize: Function;
  height?: number;
  setIsRowResize: Function;
  maxheightLimit?: number;
}) => {
  const rowRef = useRef(null);
  const BLOCK = "benchmark-grid-row";

  const [initialHeight, setInitialHeight] = useState<number | null>(null);
  const [rowHeight, setRowHeight] = useState<number>(0);
  const [rowResize, setRowResize] = useState<number | null>(null);

  useEffect(() => {
    if (!rowResize) return;
    const onMouseUpHandler = (e: any) => {
      const heightLimit = initialHeight ? initialHeight : 30;
      const diff = e.clientY - rowResize;
      setRowResize(null);

      setRowHeight((w) => {
        let newSize = w + diff;
        if (newSize < 30) return 30;
        if (newSize > heightLimit * 2) return heightLimit * 2;
        if (maxheightLimit && newSize > maxheightLimit)
          newSize = diff + maxheightLimit;

        setSize(newSize);
        return newSize;
      });
      setIsRowResize(true);
    };
    window.addEventListener("mouseup", onMouseUpHandler);

    return () => window.removeEventListener("mouseup", onMouseUpHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowResize]);

  useEffect(() => {
    let headerRefSize = rowRef ? (rowRef as any).current.offsetHeight : null;
    headerRefSize =
      maxheightLimit && headerRefSize > maxheightLimit
        ? maxheightLimit
        : headerRefSize;
    setInitialHeight(headerRefSize);
    setOriginalSize(headerRefSize);
    setRowHeight(headerRefSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <tr
      ref={rowRef}
      style={{ height: `${height && height !== 0 ? height : rowHeight}px` }}
      className={`${className}__rowItem ${BLOCK}__rowItem`}
      data-testid={`${className}__rowItem`}
    >
      {rowContent}
      <td>
        <span
          onMouseDown={(e) => setRowResize((size) => (size ? size : e.clientY))}
          className={`${BLOCK}__row-resize-handler`}
          data-testid={`${BLOCK}__label`}
        ></span>
      </td>
    </tr>
  );
};

export default ResizeGridRow;
