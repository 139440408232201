import { useEffect } from "react";
import SavedInsightTile from "./saved-insight-tile";
import { RootStore } from "services/store.service";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_SAVED_INSIGHTS_LIST_PAYLOAD } from "utils/constants";
import emptyimg from "images/saved.shortlist-empty.png";
import {
  getSavedInsightCount,
  loadSavedInsightsList,
  shareInsightSuccess,
} from "services/insights/insights.service";
import { InsightsDashboard } from "services/insights/insights.model";
import { addToastMessage } from "services/commons.service";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import classNames from "classnames";
type props = {
  insightSharedType: string;
};
const SavedInsightsList = ({ insightSharedType }: props) => {
  const BLOCK = "savedInsightsList";
  const insightsState = useSelector((state: RootStore) => state.insights);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSavedInsightsList(DEFAULT_SAVED_INSIGHTS_LIST_PAYLOAD));
    dispatch(getSavedInsightCount());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (insightsState.shareInsightsSuccess) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="insight.share.toaster.message" />,
        })
      );

      dispatch(shareInsightSuccess(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.shareInsightsSuccess]);

  return (
    <div
      className={classNames(`${BLOCK}`, {
        [`${BLOCK}__empty-container`]:
          insightSharedType === "shared" &&
          insightsState.savedInsights.filter(
            (insight) => insight.sharedBy && insight.sharedBy?.userId
          ).length === 0,
      })}
      data-testid="saved-insights-container"
    >
      {insightsState.savedInsights &&
        insightsState.savedInsights
          .filter((insight) =>
            insightSharedType === "shared"
              ? insight.sharedBy && insight.sharedBy?.userId
              : insightSharedType === "self"
              ? insight.sharedBy === null
              : true
          )
          .map((insight: InsightsDashboard, index: number) => (
            <SavedInsightTile
              insight={insight}
              key={`${index}__${insight.insightsId}`}
            />
          ))}

      {insightSharedType === "shared" &&
        insightsState.savedInsights.filter(
          (insight) => insight.sharedBy && insight.sharedBy?.userId
        ).length === 0 && (
          <div className={`${BLOCK}__empty`} data-testid="empty-insights">
            <div className={`${BLOCK}__img`}>
              <img
                data-testid="empty-img"
                className={""}
                src={emptyimg}
                alt={emptyimg}
              />
            </div>
            <FormattedMessage id="insight.no.shared.message" />
          </div>
        )}
    </div>
  );
};

export default SavedInsightsList;
