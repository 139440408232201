import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Fragment, useState } from "react";
import MainTile from "components/insight-tile/main-tile";
import { flattenInsightCompany } from "components/insight-tile/tile.context";
import { isNumber } from "lodash";
import {
  InsightCategory,
  InsightsDashboard,
} from "services/insights/insights.model";
import { DashboardFunctions, DashboardVariables } from "./insight-dashboard";
import { Visualization } from "services/dashboard/dashboard.model";

type Props = {
  BLOCK: string;
  currentDashboard: InsightsDashboard;
  dashboardVariables: DashboardVariables;
  dashboardFunctions: DashboardFunctions;
  filterExpression: (vis: Visualization, category: InsightCategory) => boolean;
};

const InsightDashboardMainLayout = ({
  currentDashboard,
  BLOCK,
  dashboardVariables,
  dashboardFunctions,
  filterExpression,
}: Props) => {
  const [response, setResponse] = useState<any>({});

  let visIndex = 0;
  return (
    <DndProvider backend={HTML5Backend}>
      <div className={`${BLOCK}__content`} data-testid={`${BLOCK}__content`}>
        {currentDashboard.insightsCategories?.map((category) =>
          category.benchmarks
            .filter((vis) => filterExpression(vis, category))
            .map((vis) => {
              const tile = (
                <MainTile
                  key={`dashboard-tile-${vis.benchmarkId}`}
                  index={visIndex}
                  metadata={vis}
                  currentTabId={dashboardVariables.currentTabId}
                  dataPayload={{
                    ...(vis.benchmarkMetadata.api.payload ?? {}),
                    ...(currentDashboard
                      ? {
                          selectedReportedYear:
                            currentDashboard.selectedReportedYear,
                          selectedQuarter: currentDashboard.selectedQuarter,
                        }
                      : {}),
                  }}
                  companies={currentDashboard.insightsCompanies.map(
                    flattenInsightCompany
                  )}
                  selectedExport={dashboardVariables.selectedExport}
                  toggleDetailView={() =>
                    dashboardFunctions.toggleDetailView(
                      vis,
                      category.benchmarks.find(
                        (b) =>
                          b.benchmarkTileType === vis.benchmarkTileType &&
                          b.isDetailedView
                      )!.benchmarkId
                    )
                  }
                  dataResponse={response}
                  setDataResponse={setResponse}
                  toggleClicked={dashboardVariables.toggleClicked}
                  setToggleClicked={dashboardFunctions.setToggleClicked}
                  selectedInsightView={dashboardVariables.selectedView}
                  setTableViewSelected={dashboardFunctions.setTableViewSelected}
                  exportAllLoading={
                    isNumber(dashboardVariables.exportAllLoading)
                      ? dashboardVariables.exportAllLoading === visIndex + 1
                      : dashboardVariables.exportAllLoading
                  }
                  globalCompanyMappings={currentDashboard.globalCompanyMappings}
                />
              );
              visIndex++;
              return (
                <Fragment key={`insights-tile-${vis.benchmarkId}`}>
                  {tile}
                </Fragment>
              );
            })
        )}
      </div>
    </DndProvider>
  );
};

export default InsightDashboardMainLayout;
