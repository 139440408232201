import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import {
  loadShortlistResultsSort,
  removeShortlistItem,
  loadMoreShortlists,
  filterReports,
  toggleSelectShortlist,
  shortlistFilter,
  filterReportsByType,
} from "services/shortlist/shortlist.service";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Table from "components/shared/table/table";
import ActiveFilterTag from "components/shared/active-filter-tag/active-filter-tag";
import {
  setSelectedDisclosure,
  setSelectedReport,
} from "services/document-viewer/document-viewer.service";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { sendExportItem } from "services/download/download.service";
import Icon from "components/shared/icon/icon";
import {
  DEFAULT_ESG_EXPORT_PAYLOAD,
  DEFAULT_SEC_EXPORT_PAYLOAD,
  DEFAULT_ESG_REMOVE_PAYLOAD,
  DEFAULT_SEC_REMOVE_PAYLOAD,
  ScrollOffset,
  shortlistItemType,
  shortlistTableFilter,
  SORT_ORDERS,
} from "../../utils/constants";
import classNames from "classnames";
import { setComparisonsSelectedList } from "services/saved-comparisons/saved-comparisons.service";
import { showTooltip } from "services/commons.service";

type Props = {
  BLOCK: string;
  setCompareModal: Function;
};

const ShortlistTable = ({ BLOCK, setCompareModal }: Props) => {
  const shortlistState = useSelector((state: RootStore) => state.shortlist);
  const dispatch = useDispatch();

  const combinedReports =
    !shortlistState.loading && shortlistState.results.activeShortlistItems
      ? shortlistState.results.activeShortlistItems
      : [];
  const searchResults = useSelector((state: RootStore) => state.searchResults);
  const downloadState = useSelector((state: RootStore) => state.download);
  const mapSelectedItems = (item: any) => {
    if (item[0].shortlistItemType === shortlistItemType.ESG) {
      return {
        reportId: item[0].reportId,
        reportTitle: item[0].shortlistItemTitle,
        comparisonItemType: item[0].shortlistItemType,
        contentKeywords: item[0].contentKeywords,
      };
    } else {
      return {
        sectionId: item[0].elementId,
        filingId: item[0].filingId,
        sectionName: item[0].shortlistItemTitle,
        reportTitle: item[0].shortlistItemTitle,
        comparisonItemType: item[0].shortlistItemType,
        contentKeywords: searchResults.searchPayloadSEC.contentText,
      };
    }
  };

  const selectShortlist = (selectedList: number[]) => {
    const selectedShortlist = selectedList.map((id) =>
      shortlistState.results.shortlistItems.filter(
        (item) => item.shortlistItemId === id
      )
    );

    shortlistState.results.selectedItems = selectedList;
    dispatch(toggleSelectShortlist(shortlistState.results));
    dispatch(
      setComparisonsSelectedList(selectedShortlist.map(mapSelectedItems))
    );
  };

  const openExternalReport = (url: any) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const tableHeaders = [
    {
      checkbox: {
        onChange: (shortlists?: any) => {
          selectShortlist(shortlists.map((shortlist: any) => shortlist.id));
        },
        value:
          shortlistState.results.pageInfo.totalSelectedCount ===
            shortlistState.results.pageInfo.totalFilteredCount ||
          shortlistState.results.pageInfo.totalSelectedCount ===
            shortlistState.results.shortlistItems.length,
        indeterminate:
          shortlistState.results.activeShortlistItems.length > 0 &&
          shortlistState.results.pageInfo.totalSelectedCount !== 0 &&
          shortlistState.results.pageInfo.totalSelectedCount <
            shortlistState.results.activeShortlistItems.length,
      },
      className: `${BLOCK}__column-checkbox`,
      type: "shortlist",
    },
    {
      className: `${BLOCK}__column-note`,
      type: "shortlist",
    },
    {
      text: "search.results.company",
      isFormattedText: true,
      filterId: "companyName",
      filterPosition: "right",
      sortId: "CompanyName",
    },
    {
      text: "search.results.title-document-section",
      isFormattedText: true,
      filterId: "shortlistItemTitle",
      filterPosition: "right",
      sortId: "ShortlistItemTitle",
    },
    {
      text: "",
      isFormattedText: true,
    },
    {
      text: "shortlist.esg-rating",
      isFormattedText: true,
      sortId: "EsgReport.reportCompany.ivaCompanyRating",
      filterId: "esgReport.reportCompany.ivaCompanyRating",
      filterPosition: "right",
    },
    {
      text: "shortlist.governance-metrics",
      isFormattedText: true,
      sortId: "EsgReport.reportCompany.corpGovernanceScore",
      filterId: "esgReport.reportCompany.corpGovernanceScore",
      filterPosition: "left",
    },
    {
      text: "search.results.document-type",
      isFormattedText: true,
      filterId: "shortlistItemDocumentType",
      filterPosition: "left",
      sortId: "ShortlistItemDocumentType",
    },
    {
      text: "search.results.period",
      isFormattedText: true,
      filterId: "shortlistItemPeriod",
      filterPosition: "left",
    },
    {
      wrapperClassName: `${BLOCK}__column-icon ${BLOCK}__download-icon`,
    },
    {
      wrapperClassName: `${BLOCK}__column-icon`,
    },
  ];

  const filterOptions = {
    activeFilter: shortlistState.results.pageInfo.filterBy,
    currentFilterText: shortlistState.results.pageInfo.filterText,
    currentFilter: shortlistState.currentFilter,
    handleClose: () => {
      dispatch(shortlistFilter(""));
    },
    setFilterPop: (filter: string) => {
      dispatch(shortlistFilter(filter));
    },
    handleApply: (filterText: string, filterType: string) => {
      dispatch(filterReports(filterType, filterText, shortlistState.results));
    },
  };

  const openDocumentViewer = (report: any) => {
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        executeMouseLeaveEvent: false,
        isOverTooltip: null,
      })
    );
    dispatch(setSelectedReport(report.esgReport));
  };

  const openSECDocumentViewer = (report: any) => {
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        executeMouseLeaveEvent: false,
        isOverTooltip: null,
      })
    );
    dispatch(setSelectedDisclosure(report));
  };

  const downloadESG = (report: any) => {
    if (report) {
      dispatch(
        sendExportItem({
          ...DEFAULT_ESG_EXPORT_PAYLOAD,
          exportFileName: report.esgReport.reportTitle,
          exportReferenceId: report.esgReport.reportId,
          searchCriteria: {
            shortlistId:
              shortlistState.results !== null &&
              shortlistState.results.shortlistId !== 0
                ? shortlistState.results.shortlistId
                : 0,
            contentText: "",
          },
          downloadTabOpen: downloadState.showDownloads,
        })
      );
    }
  };

  const downloadSEC = (disclosure: any) => {
    if (disclosure) {
      dispatch(
        sendExportItem({
          ...DEFAULT_SEC_EXPORT_PAYLOAD,
          exportFileName: `${disclosure.companyName}_${
            disclosure.documentSection
              ? disclosure.documentSection[0].sectionName
              : "section"
          }`,
          exportReferenceId: disclosure.elementId,
          searchCriteria: {
            shortlistId:
              shortlistState.results !== null &&
              shortlistState.results.shortlistId !== 0
                ? shortlistState.results.shortlistId
                : 0,
            contentText: "",
          },
          downloadTabOpen: downloadState.showDownloads,
        })
      );
    }
  };

  const getTableItems = (): any => {
    return combinedReports.map((report: any, index: number) => {
      if (report.shortlistItemType === shortlistItemType.ESG) {
        return {
          id: report.shortlistItemId,
          key: `esg-shortlist-${report.shortlistItemId}-${index}`,
          columnItems: [
            {
              checkbox: {
                onChange: (shortlists: any) => {
                  selectShortlist(
                    shortlists.map((shortlist: any) => shortlist.id)
                  );
                },
              },
              className: `${BLOCK}__column-checkbox`,
              type: "shortlist",
            },
            {
              noteId: report.shortlistItemId,
              commentNodeID: report.commentNoteId,
              className: `${BLOCK}__column-note`,
              type: "shortlist",
            },
            {
              text: report.companyName,
              className: `${BLOCK}__column-company-name`,
              onClick: () => openDocumentViewer(report),
            },
            {
              text: report.shortlistItemTitle,
              type: "shortlist",
              className: `${BLOCK}__column-report-title`,
              onClick: () => openDocumentViewer(report),
              sortId: "ShortlistItemTitle",
            },
            {
              text: "",
              type: "shortlist",
              className: `${BLOCK}__column-pages-icon`,
              onClick: () => openDocumentViewer(report),
              icon: report.esgReport?.pageSelection ? "pages" : "",
              iconTooltip: report.esgReport?.pageSelection ? (
                <Tooltip position={TooltipPosition.right}>
                  <>
                    {report.esgReport?.pageSelection}{" "}
                    <FormattedMessage id="shortlist.tooltip.selected-pages" />
                  </>
                </Tooltip>
              ) : null,
              iconSize: 15,
            },

            {
              text: report.esgReport.reportCompany
                ? report.esgReport.reportCompany.ivaCompanyRating
                  ? report.esgReport.reportCompany.ivaCompanyRating
                  : ""
                : "",

              wrapperClassName: report.esgReport.reportCompany
                .ivaCompanyRatingReportUrl
                ? `${BLOCK}__column-report-ratings`
                : `${BLOCK}__column-report-ratings__disable`,

              onClick: () => {
                if (report.esgReport.reportCompany.ivaCompanyRatingReportUrl)
                  openExternalReport(
                    report.esgReport.reportCompany.ivaCompanyRatingReportUrl
                  );
              },
              textTooltip: report.esgReport.reportCompany
                .ivaCompanyRatingReportUrl && (
                <Tooltip position={TooltipPosition.top}>
                  <span>
                    <FormattedMessage id={`search.esg.rating.report`} />
                  </span>
                </Tooltip>
              ),
            },
            {
              text: report.esgReport.reportCompany
                ? report.esgReport.reportCompany.corpGovernanceScore
                  ? report.esgReport.reportCompany.corpGovernanceScore.toFixed(
                      1
                    )
                  : ""
                : "",
              wrapperClassName: report.esgReport.reportCompany
                .corpGovernanceReportUrl
                ? `${BLOCK}__column-report-governance`
                : `${BLOCK}__column-report-governance__disable`,
              onClick: () => {
                if (report.esgReport.reportCompany.corpGovernanceReportUrl)
                  openExternalReport(
                    report.esgReport.reportCompany.corpGovernanceReportUrl
                  );
              },
              textTooltip: report.esgReport.reportCompany
                .corpGovernanceReportUrl && (
                <Tooltip position={TooltipPosition.top}>
                  <span>
                    <FormattedMessage id={`search.esg.rating.report`} />
                  </span>
                </Tooltip>
              ),
            },
            {
              wrapperClassName: `${BLOCK}__column-document-type`,
              text: report.shortlistItemDocumentType,
              sortId: "ShortlistItemDocumentType",
            },
            {
              wrapperClassName: `${BLOCK}__column-report-period`,
              renderContent: () =>
                getReportYears(report.esgReport.reportYears || []),
            },
            {
              icon: "download",
              iconSize: 24,
              iconTooltip: (
                <Tooltip position={TooltipPosition.left}>
                  <FormattedMessage id={`shortlist.tooltip.download`} />
                </Tooltip>
              ),
              type: "esg",
              wrapperClassName: `${BLOCK}__column-icon ${BLOCK}__column-icon-download`,
              onClick: () => downloadESG(report),
            },
            {
              icon: "remove",
              iconTooltip: (
                <Tooltip position={TooltipPosition.left}>
                  <FormattedMessage id={`shortlist.tooltip.remove`} />
                </Tooltip>
              ),
              type: "esg",
              iconSize: 24,
              wrapperClassName: `${BLOCK}__column-icon ${BLOCK}__column-icon-remove`,
              onClick: () => {
                dispatch(
                  removeShortlistItem({
                    ...DEFAULT_ESG_REMOVE_PAYLOAD,
                    pageInfo: shortlistState.results.pageInfo,
                    esgReport: report,
                    reportId: report.reportId,
                    shortlistId:
                      shortlistState.results !== null &&
                      shortlistState.results.shortlistId !== 0
                        ? shortlistState.results.shortlistId
                        : 0,
                  })
                );
              },
            },
          ],
        };
      } else {
        let parentDocSections = "";
        let mainDocSection = "";

        // construct subtitle string out of previous document sections
        if (report.sectionItems && report.sectionItems.length > 0) {
          report.sectionItems.forEach((filingItem: any, index: number) => {
            if (index === report.sectionItems.length - 1) {
              mainDocSection = filingItem.sectionName;
            } else {
              parentDocSections =
                parentDocSections + filingItem.sectionName + " ";

              if (index < report.sectionItems.length - 2) {
                parentDocSections = parentDocSections + "> ";
              }
            }
          });
        }
        return {
          id: report.shortlistItemId,
          key: `sec-shortlist-${report.elementId ? report.elementId : index}`,
          columnItems: [
            {
              checkbox: {
                onChange: (shortlists: any) => {
                  selectShortlist(
                    shortlists.map((shortlist: any) => shortlist.id)
                  );
                },
              },
              className: `${BLOCK}__column-checkbox`,
              type: "shortlist",
            },
            {
              noteId: report.shortlistItemId,
              commentNodeID: report.commentNoteId,
              className: `${BLOCK}__column-note`,
              type: "shortlist",
            },
            {
              text: report.companyName,
              className: `${BLOCK}__column-company-name`,
              onClick: () => openSECDocumentViewer(report),
            },
            {
              subTitle: parentDocSections,
              text: mainDocSection,
              className: `${BLOCK}__column-report-title`,
              onClick: () => openSECDocumentViewer(report),
            },
            {
              text: "",
            },
            {
              text: "",
            },
            {
              text: "",
            },
            {
              text: report.shortlistItemDocumentType,
            },
            {
              text: report.shortlistItemPeriod,
              className: `${BLOCK}__column-report-period`,
            },
            {
              icon: "download",
              iconSize: 24,
              iconTooltip: (
                <Tooltip position={TooltipPosition.left}>
                  <FormattedMessage id={`shortlist.tooltip.download`} />
                </Tooltip>
              ),
              type: "esg",
              wrapperClassName: `${BLOCK}__column-icon ${BLOCK}__column-icon-download`,
              onClick: () => downloadSEC(report),
            },
            {
              icon: "remove",
              iconTooltip: (
                <Tooltip position={TooltipPosition.left}>
                  <FormattedMessage id={`shortlist.tooltip.remove`} />
                </Tooltip>
              ),
              type: "esg",
              iconSize: 24,
              wrapperClassName: `${BLOCK}__column-icon ${BLOCK}__column-icon-remove`,
              onClick: () => {
                dispatch(
                  removeShortlistItem({
                    ...DEFAULT_SEC_REMOVE_PAYLOAD,
                    pageInfo: shortlistState.results.pageInfo,
                    sectionItem: report,
                    sectionId:
                      report !== null && report.sectionId !== null
                        ? report.sectionId
                        : 0,
                    shortlistId:
                      shortlistState.results !== null &&
                      shortlistState.results.shortlistId !== 0
                        ? shortlistState.results.shortlistId
                        : 0,
                    location: "toc",
                  })
                );
              },
            },
          ],
        };
      }
    });
  };
  const getReportYears = (years: number[]) => {
    let yearsFullText: any =
      years.length > 0
        ? years
            .map((y) => y.toString())
            .reduce((prev, current, index) =>
              index === 0 ? `${current}` : `${prev},${current}`
            )
        : "";
    return (
      <div className={`${BLOCK}__period-years-cell`}>
        <span className={classNames(`${BLOCK}__period-years-cell-text`)}>
          {years.length > 2
            ? `${yearsFullText.slice(0, 9)} +${years.length - 2}`
            : yearsFullText}

          {years.length > 2 && (
            <Tooltip position={TooltipPosition.top}>
              {yearsFullText.slice(10, yearsFullText.length)}
            </Tooltip>
          )}
        </span>
      </div>
    );
  };
  const onScroll = (e: any) => {
    if (
      e.target.scrollHeight <=
      e.target.scrollTop + e.target.clientHeight + ScrollOffset
    ) {
      dispatch(loadMoreShortlists(shortlistState.results));
    }
  };

  const getFilterName = (type: string) => {
    return (shortlistTableFilter as any)[type];
  };

  const exportGridPdfHandler = () => {
    let actualSortBy = "";
    const sortBy = shortlistState.results.pageInfo.sortBy?.split(".");
    actualSortBy = sortBy && sortBy[sortBy.length - 1];
    actualSortBy =
      actualSortBy &&
      actualSortBy.charAt(0).toUpperCase() + actualSortBy.slice(1);
    const payload = {
      exportReferenceId: shortlistState.results.shortlistId,
      exportFileName:
        shortlistState.results.shortlistName &&
        shortlistState.results.shortlistName.length > 0
          ? shortlistState.results.shortlistName
          : "",
      exportType: 3,
      exportFormat: 1,
      searchCriteria: {
        contentText: "",
        // Taking pageInfo direct from the reducer to incorporate current sorting info
        pageInfo: { ...shortlistState.results.pageInfo, sortBy: actualSortBy },
      },
      itemIds:
        shortlistState.results.selectedItems &&
        shortlistState.results.selectedItems.length > 0
          ? shortlistState.results.selectedItems
          : [],
      downloadTabOpen: downloadState.showDownloads,
    };

    dispatch(sendExportItem(payload));
  };

  const onSort = (sortId: string) => {
    let newSortOrder = shortlistState.results.pageInfo.sortOrder;

    if (sortId === shortlistState.results.pageInfo.sortBy) {
      newSortOrder =
        newSortOrder === SORT_ORDERS.DESC ? SORT_ORDERS.ASC : SORT_ORDERS.DESC;
    } else {
      newSortOrder = SORT_ORDERS.DESC;
    }

    dispatch(
      loadShortlistResultsSort(
        sortId,
        newSortOrder,
        sortId.charAt(0).toLowerCase() + sortId.slice(1),
        shortlistState.results
      )
    );
  };

  const getFilterResultsCount = () => {
    const hasSelectedItems = shortlistState.results.pageInfo.totalSelectedCount;

    if (hasSelectedItems) {
      return shortlistState.results.pageInfo.totalSelectedCount;
    }

    return shortlistState.results.pageInfo.totalFilteredCount;
  };

  return (
    <div className={`${BLOCK}__results-container`}>
      <div className={`${BLOCK}__results-summary`}>
        <div className={`${BLOCK}__results-summary-left`}>
          <div className={`${BLOCK}__results-count`}>
            <FormattedMessage
              id={
                shortlistState.results.pageInfo &&
                shortlistState.results.pageInfo.totalSelectedCount
                  ? "shortlists.items.selected"
                  : "shortlists.items"
              }
              values={{
                count: getFilterResultsCount(),
              }}
            />
          </div>

          {shortlistState.results.pageInfo &&
            shortlistState.results.pageInfo.filterBy &&
            shortlistState.results.pageInfo.filterText &&
            shortlistState.results.pageInfo.filterBy !==
              "shortlistItemType" && (
              <ActiveFilterTag
                filterText={`${getFilterName(
                  shortlistState.results.pageInfo.filterBy
                )}: ${shortlistState.results.pageInfo.filterText}`}
                handleRemove={() =>
                  dispatch(filterReports("", "", shortlistState.results))
                }
              />
            )}
          {!shortlistState.results.hasSameReportTypes && (
            <>
              <button
                className={classNames(`${BLOCK}__results-esg-filter`, {
                  [`${BLOCK}__results-esg-filter--selected`]:
                    shortlistState.results.pageInfo &&
                    shortlistState.results.pageInfo.currentFilterType ===
                      shortlistItemType.ESG,
                })}
                onClick={() =>
                  dispatch(
                    filterReportsByType(
                      shortlistItemType.ESG,
                      shortlistState.results
                    )
                  )
                }
                data-test="esg-report"
              >
                <FormattedMessage id="shortlists.esg.reports" />
              </button>

              <button
                className={classNames(`${BLOCK}__results-sec-filter`, {
                  [`${BLOCK}__results-sec-filter--selected`]:
                    shortlistState.results.pageInfo &&
                    shortlistState.results.pageInfo.currentFilterType ===
                      shortlistItemType.SEC,
                })}
                onClick={() =>
                  dispatch(
                    filterReportsByType(
                      shortlistItemType.SEC,
                      shortlistState.results
                    )
                  )
                }
                data-test="sec-report"
              >
                <FormattedMessage id="shortlists.sec.disclosures" />
              </button>
            </>
          )}
        </div>
        <div className={`${BLOCK}__results-right`}>
          <button
            className={
              shortlistState.results.pageInfo.totalSelectedCount !== 0
                ? `${BLOCK}__compare-button`
                : `${BLOCK}__compare-button ${BLOCK}__compare-button--disabled`
            }
            style={{ marginRight: "10px" }}
            disabled={shortlistState.results.pageInfo.totalSelectedCount === 0}
            onClick={() => {
              setCompareModal(true);
            }}
            data-test="compare-shortlist-button"
          >
            <div style={{ marginLeft: "0.75rem" }}>
              <FormattedMessage id="search.compare.button" />
            </div>
            {shortlistState.results.pageInfo.totalSelectedCount < 1 && (
              <Tooltip position={TooltipPosition.top}>
                <FormattedMessage id="search.compare.button.tooltip" />
              </Tooltip>
            )}
          </button>

          <div
            className={classNames(`${BLOCK}__results-export`, {
              [`${BLOCK}__results-export-disabled`]:
                !shortlistState.results.shortlistName,
            })}
          >
            <button
              className={classNames(`${BLOCK}__results-export-button`, {
                [`${BLOCK}__results-export-disabled`]:
                  !shortlistState.results.shortlistName,
              })}
              onClick={exportGridPdfHandler}
              disabled={!shortlistState.results.shortlistName}
              data-test="export-shortlist-button-table"
            >
              <Icon
                name="pdf"
                className={`${BLOCK}__results-export-icon`}
                width={18}
                height={18}
              />
              <FormattedMessage
                id={
                  shortlistState.results.pageInfo &&
                  shortlistState.results.pageInfo.totalSelectedCount
                    ? "shortlists.export.selected"
                    : "shortlists.export.all"
                }
              />
              {!shortlistState.results.shortlistName && (
                <Tooltip position={TooltipPosition.top}>
                  <FormattedMessage id="shortlist.save.before.export" />
                </Tooltip>
              )}
            </button>
          </div>
        </div>
      </div>
      <div
        className={classNames(`${BLOCK}__table-wrapper`, {
          [`${BLOCK}__table-wrapper-overflow`]: combinedReports.length < 4,
        })}
        onScroll={onScroll}
        data-testid={"table-wrapper"}
      >
        <Table
          headerItems={tableHeaders}
          rowItems={getTableItems()}
          className={`${BLOCK}__table`}
          sortBy={shortlistState.results.pageInfo.sortBy}
          sortOrder={shortlistState.results.pageInfo.sortOrder}
          defaultSelectedItems={
            shortlistState.results.selectedItems
              ? shortlistState.results.selectedItems.map((id: number) => ({
                  id: id,
                }))
              : []
          }
          onSort={onSort}
          filter={filterOptions}
          data-test="shortlist-table"
          data-testid="shortlist-table"
        />
      </div>
    </div>
  );
};

export default ShortlistTable;
