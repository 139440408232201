import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Scope3ProgressBar from "components/visualizations/charts/Scope3ProgressBar";
import ProgressBar from "components/visualizations/charts/progress-bar";
import { Fragment } from "react";
import { useLayoutEffect, useRef, useState } from "react";
import TileEmptyContent from "../tile-empty-content";
import Popover from "components/shared/popover/popover";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
type Props = {
  dataObj: DataObj;
  benchmarkType: number;
  baseCompany: string;
  associatedMetricGroupTooltips?: AssociatedMetricTooltips[];
};

type DataObj = {
  dataset: PeerIndData[];
  dataRange: number[];
  categoryName: string;
};

type PeerIndData = {
  baseCompPosition: number;
  baseCompanyTooltip: string;
  baseCompanyValue: string | number;
  metricKey: string;
  metricName: string;
  peerCompanyTooltip: string;
  peerIndPosition: number;
  peerIndValue: string | number;
};

const CDPScope3PeerIndChart = ({
  dataObj,
  benchmarkType,
  baseCompany,
  associatedMetricGroupTooltips,
}: Props) => {
  const ref: any = useRef(null);
  const [height, setHeight] = useState(0);
  const offsetTop = 40;
  const offsetHeight = 20;

  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  }, []);

  const BLOCK = "cdpScope3PeerInd";
  const currentInsightView = benchmarkType;

  const rangeSeparator = {
    content: '""',
    top: -height + offsetTop,
    height: height - offsetHeight,
    width: "2px",
    borderLeft: "1px dashed #E0E0E0",
    left: "1px",
    zIndex: -1,
  };

  const peerIndMissingAllvalues = dataObj.dataset.every(
    (metricData: PeerIndData) =>
      metricData.peerIndValue === null || metricData.peerIndValue === ""
  );
  const peerIndMissingSomevalues = dataObj.dataset.some(
    (metricData: PeerIndData) =>
      metricData.peerIndValue === null || metricData.peerIndValue === ""
  );
  const getPeerIndChart = () => {
    return (
      <div className={`${BLOCK}`} data-test="peer-ind-chart">
        <div className={`${BLOCK}__titleContainer`}>
          <div className={`${BLOCK}__title`}>
            {" "}
            <span className={`${BLOCK}__title-baseCompany`}>{baseCompany}</span>
          </div>
          <div className={`${BLOCK}__title`}>
            <span className={`${BLOCK}__title-emissions`}>
              <FormattedMessage id="scope3.emissions" />
            </span>
          </div>
          <div className={`${BLOCK}__title`}>
            <Fragment key={`legend-scope3`}>
              <div className={`DashboardTile__legend`} key={"Legend metric"}>
                {[
                  { id: 1, colors: ["#005C83", "#00A3E0"] },
                  { id: 2, colors: ["#00ABAB", "#9DD4CF"] },
                  { id: 3, colors: ["#E0E0E0", "#E0E0E0"] },
                ].map((legend: any, index) => {
                  return (
                    legend && (
                      <div
                        className={`DashboardTile__legend__metric`}
                        key={`Legend-${index}`}
                      >
                        <div
                          className={`DashboardTile__legend__bar`}
                          key={`Legend-Bar-${index}`}
                        >
                          <ProgressBar
                            mainWidth={100}
                            baseWidth={""}
                            mainTooltip={""}
                            baseTooltip={""}
                            colors={legend.colors}
                            gradient={true}
                          />
                        </div>
                        {index === 0 ? (
                          <span>{baseCompany}</span>
                        ) : index === 1 ? (
                          currentInsightView === 5 ? (
                            <span>
                              {" "}
                              <FormattedMessage id="peer" />
                              {peerIndMissingAllvalues ? "*" : ""}
                            </span>
                          ) : (
                            <span>
                              {" "}
                              <FormattedMessage id="insight.tab.industry" />
                              {peerIndMissingAllvalues ? "*" : ""}
                            </span>
                          )
                        ) : index === 2 ? (
                          <span>{"No data"}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    )
                  );
                })}
              </div>
            </Fragment>
          </div>
        </div>
        <div ref={ref} className={`${BLOCK}__chartSection`}>
          {dataObj.dataset.map((metricData: PeerIndData, index: number) => (
            <Fragment key={`__chartSection${index}`}>
              <div
                key={`chartSection${index}`}
                className={`${BLOCK}__chartSection-metricName`}
              >
                {metricData.metricName}
                {!peerIndMissingAllvalues && metricData.peerIndValue === null
                  ? "*"
                  : ""}
              </div>

              <div
                key={`chartSection-wrapper${index}`}
                className={`${BLOCK}__chartSection-chartWrapper`}
              >
                <Scope3ProgressBar
                  mainTooltip={metricData.baseCompanyTooltip}
                  peerTooltip={metricData.peerCompanyTooltip}
                  colors={["#005C83", "#00A3E0"]}
                  gradient={true}
                  value={
                    typeof metricData.baseCompanyValue === "string"
                      ? parseInt(metricData.baseCompanyValue)
                      : metricData.baseCompanyValue
                  }
                  baseScorePos={
                    metricData.baseCompPosition
                      ? metricData.baseCompPosition
                      : 100
                  }
                  peerScorePos={
                    metricData.peerIndValue ? metricData.peerIndPosition : null
                  }
                  peerIndValue={
                    typeof metricData.peerIndValue === "string"
                      ? parseInt(metricData.peerIndValue)
                      : metricData.peerIndValue
                  }
                  tileType={3}
                />
                <div
                  key={`chartSection-dashed${index}`}
                  className={`${BLOCK}__chartSection-dashedRange`}
                >
                  {index === dataObj.dataset.length - 1 &&
                    Array.from({ length: 6 }, (value, index) => index).map(
                      (e) => {
                        return (
                          <span
                            className={`${BLOCK}__chartSection-dashedSeparator`}
                            style={rangeSeparator}
                            key={`range-${e}`}
                          ></span>
                        );
                      }
                    )}
                </div>

                <div
                  key={`chartSection-rangeData${index}`}
                  className={`${BLOCK}__chartSection-rangeData`}
                >
                  {index === dataObj.dataset.length - 1 &&
                    dataObj?.dataRange?.map((e) => {
                      return (
                        <span key={`range-${e}`}>
                          {new Intl.NumberFormat("en-US").format(e)}
                        </span>
                      );
                    })}
                </div>
              </div>
            </Fragment>
          ))}
        </div>
        {(peerIndMissingAllvalues || peerIndMissingSomevalues) && (
          <div className={"DashboardTile__noDataAny"}>
            <FormattedMessage
              id="peerindustry.no.data"
              values={{
                peerInd: currentInsightView === 5 ? "Peer" : "Industry",
              }}
            />
          </div>
        )}
      </div>
    );
  };
  return (
    <div>
      <div className={`${BLOCK}__emissions`}>
        <Popover
          metricClassName={`cdpScope3PeerInd__categoryName`}
          displayText={dataObj.categoryName}
          content={
            associatedMetricGroupTooltips
              ? associatedMetricGroupTooltips.filter(
                  (tooltip: AssociatedMetricTooltips) =>
                    tooltip.associatedMetricGroupName === dataObj.categoryName
                )
              : []
          }
        />
      </div>
      {dataObj.dataset && dataObj.dataset.length > 0 ? (
        getPeerIndChart()
      ) : (
        <TileEmptyContent />
      )}
    </div>
  );
};

export default CDPScope3PeerIndChart;
