import { useEffect, useRef, useState } from "react";
import Parser from "html-react-parser";
import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import { User } from "services/commons.model";

type ObjectKeys = {
  name: string;
  id: string;
  externalId: string;
  email: string;
  firstName: string;
  lastName: string;
  memberFirmCode: string;
  countryName: string;
};

type Props = {
  className: string;
  label: string | object;
  showLabel?: boolean;
  placeholder: string | object;
  loading: boolean;
  options: any[];
  disableValues: any[];
  selectedValue: any;
  handleChange: any;
  handleSelect: any;
  objectKeys: ObjectKeys;
  textValue?: string;
  displayFunction?: any;
  handleOnEnter?: any;
};

const UserDropdown = ({
  className,
  label,
  showLabel = true,
  placeholder,
  loading,
  options,
  disableValues,
  selectedValue,
  handleChange,
  handleSelect,
  objectKeys,
  textValue,
  handleOnEnter,
}: Props) => {
  const BLOCK = "user-dropdown";

  const [showOptions, toggleOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputFilter, setInputFilter] = useState("");
  const [selectedOption, setSelectedOption] = useState<User | null>(null);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const outlineRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const inputSizeRef = useRef<HTMLDivElement>(null);

  const handleLocalSelect = (option: any) => {
    handleSelect(option);
    setInputFilter("");
    setFilteredOptions([]);
    setSelectedOption(option);
  };

  useEffect(() => {
    setInputFilter(textValue ? textValue : "");
  }, [textValue]);

  useEffect(() => {
    const currValues = disableValues.reduce(
      (curr: any, prev: any) => ({
        ...curr,
        [prev[objectKeys.id]]: prev[objectKeys.id],
      }),
      {}
    );

    /**
     * Setting isExsiting Flag to Users who are already an admin, to gray out in dropdown list
     */
    setFilteredOptions(
      options
        ? options.map((option: any) => {
            return {
              ...option,
              isExistingAdmin: !!currValues[option[objectKeys.id]],
            };
          })
        : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (!loading && filteredOptions.length === 0) {
      toggleOptions(false);
    }

    if (!loading && inputFilter.trim().length > 1) {
      toggleOptions(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, filteredOptions.length]);

  useEffect(() => {
    if (inputFilter === "") {
      toggleOptions(false);
    }
  }, [inputFilter]);

  useEffect(() => {
    const handleClick = (event: any) => {
      return showOptions
        ? toggleOptions(
            (outlineRef.current !== null &&
              outlineRef.current.contains(event.target)) ||
              (optionsRef.current != null &&
                optionsRef.current.contains(event.target) &&
                filteredOptions.length !== 0)
          )
        : null;
    };

    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlineRef, options, inputFilter, showOptions]);

  const highlightOption = (option: string) => {
    if (inputFilter !== "" && option) {
      const finalStr = inputFilter.replace(
        // eslint-disable-next-line
        /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
        "\\$&"
      );
      const replace = new RegExp(`(${finalStr})`, "i");
      return option
        .split(replace)
        .map((subtext) =>
          replace.test(subtext) ? `<b>${subtext}</b>` : subtext
        )
        .join("");
    }
    return option;
  };

  const getOption = (
    option: any,
    optionKeys: ObjectKeys,
    parentId?: number
  ) => {
    return (
      <button
        className={classNames(`${BLOCK}__option`, {
          disabled: option.isExistingAdmin,
        })}
        key={`${optionKeys.id}-option-${option[optionKeys.id]}-${
          option[optionKeys.externalId]
        }`}
        onClick={() => handleLocalSelect({ ...option, parentId })}
        data-test="select-option-button"
        data-testid="select-option-button"
        disabled={option.isExistingAdmin}
      >
        <div className={`${BLOCK}__option-container`}>
          <span className={`${BLOCK}__option-value`}>
            {Parser(
              highlightOption(
                `${option[optionKeys.lastName]}, ${
                  option[optionKeys.firstName]
                }`
              ).toString()
            )}
          </span>
          <span className={`${BLOCK}__option-secondary-value`}>
            <span>
              {Parser(highlightOption(option[optionKeys.email]).toString())}
            </span>
            {` | ${option[optionKeys.memberFirmCode]} | ${
              option[optionKeys.countryName]
            }`}
          </span>
        </div>
      </button>
    );
  };

  const handleFilterChange = (e: any) => {
    const value = e.target.value;
    setInputFilter(value);
    setSelectedOption(null);

    if (value) {
      handleChange(e);
    } else {
      setFilteredOptions([]);
    }
  };

  return (
    <div
      ref={wrapperRef}
      id={`${BLOCK}-${objectKeys.id}`}
      className={`${BLOCK} ${className}`}
      data-testid="drop-down"
    >
      <div className={`${BLOCK}__label`} data-testid="drop-down-label">
        {showLabel && (label as React.ReactNode)}
      </div>
      <div
        ref={outlineRef}
        className={classNames(`${BLOCK}__outline`, {
          [`${BLOCK}__outline-disabled`]: !!selectedOption,
        })}
      >
        <textarea
          ref={textareaRef}
          value={inputFilter}
          className={classNames(`${BLOCK}__input`, {
            [`${BLOCK}__input-disabled`]: !!selectedOption,
          })}
          data-testid="input-text-area"
          onChange={handleFilterChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleOnEnter();
            }
          }}
        />
        <div ref={inputSizeRef} className={`${BLOCK}__input-size`}>
          {inputFilter}
        </div>
        {inputFilter === "" && (
          <div className={`${BLOCK}__placeholder`}>
            {placeholder as React.ReactNode}
          </div>
        )}
      </div>
      <div
        ref={optionsRef}
        className={classNames(`${BLOCK}__options`, {
          [`${BLOCK}__options--show`]: showOptions,
          [`${BLOCK}__options--hide`]: !showOptions,
        })}
        data-testid="drop-down-options"
      >
        {loading || filteredOptions.length === 0 ? (
          <button className={`${BLOCK}__option ${BLOCK}__unclickable`}>
            {loading && (
              <Icon
                name="loading"
                width={20}
                height={20}
                data-testid="loading"
                className={classNames(`${BLOCK}__loading`, {
                  "loading-icon": loading,
                })}
              />
            )}
            {!loading && inputFilter !== "" && filteredOptions.length === 0 && (
              <div>
                <FormattedMessage id="shortlists.notfound" />
              </div>
            )}
          </button>
        ) : (
          filteredOptions.map((option: any) => getOption(option, objectKeys))
        )}
      </div>
      <div className={`${BLOCK}__selectedValue`} data-testid="selected-value">
        {selectedOption && (
          <div className={`${BLOCK}__value`}>
            {`${selectedOption?.lastName}, ${selectedOption?.firstName}`}
            <button
              className={`${BLOCK}__remove`}
              data-testid="unselect-option"
              onClick={() => setSelectedOption(null)}
            >
              <Icon name="cross" height={20} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDropdown;
