import classNames from "classnames";
import DropDown from "components/search/filters/drop-down";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Modal from "components/shared/modal/modal";
import Table, { TableRowItem } from "components/shared/table/table";
import { debounce } from "lodash";
import { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToastMessage } from "services/commons.service";
import { SuggestPeerCompanyFilter } from "services/dashboard/dashboard.model";
import {
  CompanyGroupCompany,
  GlobalCompanyGroup,
  GlobalCompanyMapping,
  GlobalPeerGroup,
  PeerGroup,
} from "services/insights/insights.model";
import {
  deletePeerGroup,
  editPeerGroupServ,
  getGlobalCompanyFilter,
  loadSavedPeerGroupList,
  peerGroupSelectionTracking,
  refreshSavedPeerGroupsList,
  setduplicatePeerGroupError,
} from "services/insights/insights.service";
import {
  CompanyFilter,
  SelectedCheckboxMultiple,
  SuggestedPeerCompaniesByCompanyFilter,
  SuggestPeerCompanyFilterMultiple,
} from "services/search/filters.model";
import {
  loadCompanyFilter,
  searchLoadCompanyFilter,
} from "services/search/filters.service";
import { RootStore } from "services/store.service";
import {
  DEFAULT_PEER_GROUP_LIST_PAYLOAD,
  TRACKING_IDS,
  VENDOR_DETAILS,
  UNAVAILABLE,
  PEER_GROUP_CREATION_PAGE,
} from "utils/constants";
import { dollarFormatter } from "utils/functions";
import Button from "../button/button";
import DeleteModal from "../delete-modal/delete-modal";
import Icon from "../icon/icon";
import TabbedContent from "../tabbed-content/tabbed-content";
import Tooltip, { TooltipPosition } from "../tooltip/tooltip";

type props = {
  BLOCK: string;
  setShowModal: (value: boolean) => void;
  addSelected: (
    selectedCheckbox: number[],
    selectedPeerGroups: number[]
  ) => void;
  selectedItems: number[];
  dispatchFunction: (selectedItems: number[]) => void;
  dispatchFunctionPeerGroups: (selectedPeerGroupItems: number[]) => void;
  dispatchFunctionMultiple?: (
    selectedPeerGroupItems: SelectedCheckboxMultiple[]
  ) => void;
  suggestedPeerCompanies: SuggestPeerCompanyFilter[];
  selectedBaselineComp: {
    companyName: string;
    cikNumber: number;
    companyId: number;
    countryCode?: string;
    globalCompanyMapping?: Partial<GlobalCompanyGroup>;
  };
  isMultiple?: boolean;
  suggestedPeerCompaniesByCompany?: SuggestedPeerCompaniesByCompanyFilter[];
  selectedItemsMultiple?: SelectedCheckboxMultiple[];
  selectItemsMultiple?: (
    selectedCheckboxMultiple: SelectedCheckboxMultiple[],
    selectedPeerGroups: number[]
  ) => void;
  unSelectCompanyFromFilter?: (pcikNumber: number[]) => void;
  selectedCheckboxPeerGroupItems?: number[];
  peerGroups: PeerGroup[] | GlobalPeerGroup[];
  page: string;
  setCompaniesUnselected: (punselectedItems: number[]) => void;
  identifier: string;
  debounceFunction?: any;
  companyFilterOptions?: CompanyFilter[];
};

const SuggestedPeers = ({
  BLOCK,
  setShowModal,
  addSelected,
  selectedItems,
  suggestedPeerCompanies,
  dispatchFunction,
  dispatchFunctionMultiple,
  dispatchFunctionPeerGroups,
  selectedBaselineComp,
  isMultiple,
  suggestedPeerCompaniesByCompany,
  selectedItemsMultiple,
  selectItemsMultiple,
  unSelectCompanyFromFilter,
  selectedCheckboxPeerGroupItems,
  peerGroups,
  page,
  setCompaniesUnselected,
  identifier,
  debounceFunction,
  companyFilterOptions,
}: props) => {
  const [disabled, setDisabled] = useState(0);
  const dispatch = useDispatch();
  const [selectedCheckbox, setSelectedCheckbox] = useState<any[]>([]);
  const [selectedCheckboxMultiple, setSelectedCheckboxMultiple] = useState<
    SelectedCheckboxMultiple[]
  >([]);
  const [selectedCheckboxPeerGroup, setSelectedCheckboxPeerGroup] = useState<
    any[]
  >([]);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const modalRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const [editPeerGroup, setEditPeerGroup] = useState<number>(-1);
  const BLOCK_SUGGESTED = "suggested-peers";
  const [peerGroupName, setPeerGroupName] = useState<string>("");
  const insightsState = useSelector((state: RootStore) => state.insights);
  const [peerCompanyFilterEdition, setPeerCompanyFilterEdition] = useState<
    CompanyFilter[]
  >([]);
  const searchState = useSelector((state: RootStore) => state.filters);
  const [emptyName, setEmptyName] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [deletePeerGroupId, setDeletePeerGroupId] = useState<number>(-1);
  const [isStandardDeletion, setIsStandardDeletion] = useState<boolean>(true);
  const commons = useSelector((state: RootStore) => state.commons);

  useEffect(() => {
    if (insightsState.insightPeerGroupSuccess) {
      setEmptyName(false);
      setEditPeerGroup(-1);
      setPeerGroupName("");
      setPeerCompanyFilterEdition([]);
      dispatch(refreshSavedPeerGroupsList(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.insightPeerGroupSuccess, dispatch]);

  useEffect(() => {
    if (!debounceFunction) dispatch(searchLoadCompanyFilter([]));
  }, []);

  useEffect(() => {
    if (insightsState.refreshSavedPeerGroupsList) {
      dispatch(loadSavedPeerGroupList(DEFAULT_PEER_GROUP_LIST_PAYLOAD));
      dispatch(refreshSavedPeerGroupsList(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.refreshSavedPeerGroupsList]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPeerGroupName(event.target.value);
    if (event.target.value.length === 0) setEmptyName(true);
    else setEmptyName(false);

    dispatch(setduplicatePeerGroupError(false));
  };

  const saveHandler = (peerGroup: PeerGroup) => {
    let companyGroupCompanies: any[] = [];
    peerCompanyFilterEdition
      .filter((cg: CompanyFilter) => !cg.globalCompanyMapping)
      .forEach((company, i) => {
        let peerCompany: any = {
          cikNumber: company.cikNumber,
          displayOrder: i + 1,
          company: {
            cikNumber: company.cikNumber,
            companyId: company.companyId,
            companyName: company.companyName,
            globalCompanyId: company.globalCompanyId ?? 0,
          },
          globalCompanyId: company.globalCompanyId ?? 0,
        };
        companyGroupCompanies.push(peerCompany);
      });

    let payload: any = {
      companyGroupId: editPeerGroup || peerGroup.companyGroupId,
      companyGroupName: peerGroupName,
      companyGroupCompanies: companyGroupCompanies,
      globalCompanyMappings: peerCompanyFilterEdition
        .filter((cg: CompanyFilter) => cg.globalCompanyMapping)
        .map((gcm: CompanyFilter) => ({
          globalCompanyMappingId: gcm.globalCompanyMapping
            ? gcm.globalCompanyMapping.globalCompanyMappingId
            : 0,
          isBaseMapping: gcm.globalCompanyMapping
            ? gcm.globalCompanyMapping.isBaseMapping
            : false,
        })),
    };
    if (selectedBaselineComp.globalCompanyMapping) {
      if (payload.globalCompanyMappings)
        payload.globalCompanyMappings.splice(0, 0, {
          globalCompanyMappingId:
            selectedBaselineComp.globalCompanyMapping.globalCompanyMappingId,
          isBaseMapping:
            selectedBaselineComp.globalCompanyMapping.isBaseMapping,
        });
      else
        payload.globalCompanyMappings = [
          {
            globalCompanyMappingId:
              selectedBaselineComp.globalCompanyMapping.globalCompanyMappingId,
            isBaseMapping:
              selectedBaselineComp.globalCompanyMapping.isBaseMapping,
          },
        ];
    } else {
      payload = {
        ...payload,
        baselineCompanyCikNumber: isMultiple
          ? null
          : selectedBaselineComp.cikNumber,
        baselineCompany: {
          cikNumber: isMultiple ? null : selectedBaselineComp.cikNumber,
          companyId: isMultiple ? null : selectedBaselineComp.companyId,
          companyName: isMultiple ? null : selectedBaselineComp.companyName,
        },
      };
    }

    dispatch(editPeerGroupServ(payload, page));
  };

  const deletePeerGroupHandler = (peerGroupId: number) => {
    selectPeerGroupHandler(
      selectedCheckboxPeerGroup.filter(
        (groupId: number) => groupId !== peerGroupId
      ),
      true
    );
    dispatch(deletePeerGroup(peerGroupId, page));
    setShowDelete(false);
    setDeletePeerGroupId(-1);
    setPeerGroupName("");
    if (!isStandardDeletion) {
      setEmptyName(false);
      setEditPeerGroup(-1);
      setPeerGroupName("");
      setPeerCompanyFilterEdition([]);
    }
    setIsStandardDeletion(false);

    dispatch(
      addToastMessage({
        description: (
          <FormattedMessage id="peer.groups.successfully.deleted.msg" />
        ),
      })
    );
  };

  const unselectCompany = (companyFilter: CompanyFilter, type: string) => {
    let unDeletedPeerCompany = peerCompanyFilterEdition.filter(
      (item: any) =>
        item[identifier] !== companyFilter[identifier as keyof CompanyFilter]
    );

    if (unDeletedPeerCompany.length === 0) {
      setShowDelete(true);
      setIsStandardDeletion(false);
    } else {
      setPeerCompanyFilterEdition(unDeletedPeerCompany);
    }
  };

  const selectCompany = (companyFilter: CompanyFilter, type: string) => {
    setPeerCompanyFilterEdition([...peerCompanyFilterEdition, companyFilter]);
  };

  const getTabItems = () => {
    const savedPeerGroupTabItem = {
      text: "suggested.peer.saved.group",
      tabId: "dashboard-view--tab",
      tabPanelId: "dashboard-view--tab-panel",
      className: `${BLOCK}__tab-item ${BLOCK}__tab-item--padding-top`,
      renderleftContent: isMultiple ? (
        <span className={`${BLOCK}__tab-vertical-line`}></span>
      ) : undefined,
    };

    const tmpSuggestedPeerCompaniesByCompany = suggestedPeerCompaniesByCompany
      ? suggestedPeerCompaniesByCompany?.map(({ companyName }, index) => ({
          text: companyName,
          tabId: "search-view--tab",
          tabPanelId: `search-view--tab-panel__${index}`,
          isRegularText: true,
          className: `${BLOCK}__tab-item--padding-top`,
        }))
      : [];

    return isMultiple
      ? [...tmpSuggestedPeerCompaniesByCompany, savedPeerGroupTabItem]
      : [
          {
            text: "suggested.peers",
            tabId: "insightsSuggestedPath-view--tab",
            tabPanelId: "insightsPath-view--tab-panel",
            className: `${BLOCK}__tab-item--padding-top`,
          },
          savedPeerGroupTabItem,
        ];
  };
  const tabItems = getTabItems();

  const updateSelectedCheckboxMultiple = () => {
    let copySelectedItemsMultiple = selectedItemsMultiple
      ? JSON.parse(JSON.stringify(selectedItemsMultiple))
      : [];

    let allSuggestedSelected = Array.from(
      new Set(
        [...copySelectedItemsMultiple]
          ?.map(({ selectedCompanies }) => selectedCompanies)
          .reduce((prev, curr) => prev.concat(curr), [])
          .concat(
            suggestedPeerCompaniesByCompany?.map(
              (sg: SuggestedPeerCompaniesByCompanyFilter) =>
                sg[identifier as keyof SuggestedPeerCompaniesByCompanyFilter]
            )
          )
      )
    );

    let tmpSelectedCheckboxMultiple: SelectedCheckboxMultiple[] =
      selectedItemsMultiple
        ? JSON.parse(JSON.stringify(selectedItemsMultiple))
        : [];
    let currentIndex: number = -1;
    suggestedPeerCompaniesByCompany?.forEach(
      (companyGroup: SuggestedPeerCompaniesByCompanyFilter) => {
        // FILTER ALL THE COMPANIES IN CURRENT SELECTION
        let tmpCompaniesSelection =
          companyGroup.suggestedPeerCompanyFilter.filter(
            (suggestedPeer: SuggestPeerCompanyFilterMultiple) =>
              allSuggestedSelected.includes(
                suggestedPeer[
                  identifier as keyof SuggestPeerCompanyFilterMultiple
                ]
              )
          );
        if (tmpCompaniesSelection.length === 0) return;

        let currentCompanyExists = tmpSelectedCheckboxMultiple?.find(
          (scm, i) => {
            currentIndex =
              scm.parentCikNumber ===
              companyGroup[
                identifier as keyof SuggestedPeerCompaniesByCompanyFilter
              ]
                ? i
                : -1;
            return (
              scm.parentCikNumber ===
              companyGroup[
                identifier as keyof SuggestedPeerCompaniesByCompanyFilter
              ]
            );
          }
        );

        // ADD FOR THE FIRST TIME
        if (!currentCompanyExists) {
          tmpSelectedCheckboxMultiple?.push({
            parentCikNumber: companyGroup.cikNumber,
            selectedCompanies: tmpCompaniesSelection.map(
              (cs: SuggestPeerCompanyFilterMultiple) =>
                cs[identifier as keyof SuggestPeerCompanyFilterMultiple]
            ),
          });
        } else {
          tmpSelectedCheckboxMultiple[currentIndex].selectedCompanies =
            tmpCompaniesSelection.map(
              (cs: SuggestPeerCompanyFilterMultiple) =>
                cs[identifier as keyof SuggestPeerCompanyFilterMultiple]
            );
        }
      }
    );
    setSelectedCheckboxMultiple(tmpSelectedCheckboxMultiple);
  };

  useEffect(() => {
    dispatch(loadSavedPeerGroupList(DEFAULT_PEER_GROUP_LIST_PAYLOAD));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedItems && selectedItems.length && !isMultiple) {
      setSelectedCheckbox(selectedItems);
    }
    if (
      isMultiple &&
      selectedItemsMultiple &&
      selectedItemsMultiple.length > 0
    ) {
      updateSelectedCheckboxMultiple();
    }
    if (
      selectedCheckboxPeerGroupItems &&
      selectedCheckboxPeerGroupItems.length > 0
    ) {
      setSelectedCheckboxPeerGroup(selectedCheckboxPeerGroupItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTableHeaders = (
    suggestedPeerByCompany:
      | SuggestedPeerCompaniesByCompanyFilter
      | undefined = undefined
  ) => {
    let tmpSelectedCheckboxMultiple: any[] = [];
    if (suggestedPeerByCompany) {
      tmpSelectedCheckboxMultiple =
        selectedCheckboxMultiple.find(
          (scm) =>
            scm.parentCikNumber ===
            suggestedPeerByCompany[
              identifier as keyof SuggestedPeerCompaniesByCompanyFilter
            ]
        )?.selectedCompanies || [];
    }
    return [
      {
        checkbox: {
          onChange:
            isMultiple && suggestedPeerByCompany && selectItemsMultiple
              ? (company: any) => {
                  selectSuggestedPeerMultiple(
                    company.map((company: any) => company.id),
                    suggestedPeerByCompany[
                      identifier as keyof SuggestedPeerCompaniesByCompanyFilter
                    ] as number,
                    true
                  );
                }
              : (company: any) => {
                  selectSuggestedPeer(
                    company.map((company: any) => company.id)
                  );
                },
          value:
            isMultiple && suggestedPeerByCompany
              ? suggestedPeerByCompany?.suggestedPeerCompanyFilter.length > 0 &&
                suggestedPeerByCompany?.suggestedPeerCompanyFilter.every(
                  (company: any) =>
                    tmpSelectedCheckboxMultiple.includes(
                      company[
                        identifier as keyof SuggestPeerCompanyFilterMultiple
                      ]
                    )
                )
              : suggestedPeerCompanies?.length > 0 &&
                suggestedPeerCompanies?.every((company: any) =>
                  selectedCheckbox.includes(
                    company[identifier as keyof SuggestPeerCompanyFilter]
                  )
                ),
        },
        type: "esg",
      },

      {
        text: "suggested.peer.ticker",
        isFormattedText: true,
      },
      {
        text: "suggested.peer.company.name",
        isFormattedText: true,
      },
      {
        text: "suggested.peer.esg.rating",
        isFormattedText: true,
      },
      {
        text: "suggested.peer.governance.metrics",
        isFormattedText: true,
      },
      {
        text: "suggested.peer.annual.revenue",
        isFormattedText: true,
      },
      {
        text: "suggested.peer.market.cap",
        isFormattedText: true,
      },
      {
        text: "suggested.peer.total.assets",
        isFormattedText: true,
      },
      {
        text: "suggested.peer.auditor",
        isFormattedText: true,
      },
      {
        text: "",
      },
    ];
  };

  const getTableHeadersPeerGroup = () => {
    return [
      {
        type: "esg",
      },
      {
        text: "suggested.peer.name",
        isFormattedText: true,
      },
      {
        text: "suggested.peer.list.companies",
        isFormattedText: true,
      },
      {
        type: "esg",
      },
    ];
  };

  const compareArrays = (arrayOne: number[], arrayTwo: number[]) => {
    let flag = true;
    arrayOne.forEach((item) => {
      if (!arrayTwo.includes(item)) {
        flag = false;
      }
    });

    return flag;
  };

  const compareSelectedItems = (selectedCheckbox: any[]) => {
    // Step 1 : Out of all items in suggested peers, how many are part of selected items from beggining
    // Step 2 : suggetsed peers, how many are part of internal state
    // first arrat is qual to second array
    let tempOption: number[] = [];
    suggestedPeerCompanies?.forEach((item: any) => {
      if (
        selectedItems.includes(
          item[identifier as keyof SuggestPeerCompanyFilter]
        )
      ) {
        tempOption.push(item[identifier as keyof SuggestPeerCompanyFilter]);
      }
    });
    let tempOptionTwo: number[] = [];
    suggestedPeerCompanies?.forEach((item: any) => {
      if (
        selectedCheckbox.includes(
          item[identifier as keyof SuggestPeerCompanyFilter]
        )
      ) {
        tempOptionTwo.push(item[identifier as keyof SuggestPeerCompanyFilter]);
      }
    });
    if (
      (tempOption.length > tempOptionTwo.length
        ? compareArrays(tempOption, tempOptionTwo)
        : compareArrays(tempOptionTwo, tempOption) &&
          selectedCheckboxPeerGroupItems &&
          (selectedCheckboxPeerGroupItems?.length >
          selectedCheckboxPeerGroup.length
            ? compareArrays(
                selectedCheckboxPeerGroupItems,
                selectedCheckboxPeerGroup
              )
            : compareArrays(
                selectedCheckboxPeerGroup,
                selectedCheckboxPeerGroupItems
              ))) ||
      ((selectedCheckbox.length === 0 ||
        selectedCheckbox.every(
          (compCik: number) =>
            !suggestedPeerCompanies
              .map(
                (sgp: SuggestPeerCompanyFilter) =>
                  sgp[identifier as keyof SuggestPeerCompanyFilter]
              )
              .includes(compCik)
        )) &&
        selectedCheckboxPeerGroup.length === 0)
    ) {
      setDisabled(0);
    } else {
      setDisabled(1);
    }
  };

  useEffect(() => {
    if (!isMultiple) {
      compareSelectedItems(selectedCheckbox);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCheckbox, selectedCheckboxPeerGroup]);

  const selectSuggestedPeer = (selectedCompanies: number[]) => {
    let tmpSelectedCheckboxPeerGroup = [...selectedCheckboxPeerGroup];
    if (
      selectedCheckbox.length > selectedCompanies.length &&
      peerGroups &&
      peerGroups.length
    ) {
      // REMOVE
      let companiesToRemove = selectedCheckbox.filter(
        (companyCikNumber) => !selectedCompanies.includes(companyCikNumber)
      );

      peerGroups
        ?.filter((pg: PeerGroup) =>
          selectedCheckboxPeerGroup.includes(pg.companyGroupId)
        )
        .forEach((pgp: PeerGroup) => {
          if (
            pgp.companyGroupCompanies.find((comp: CompanyGroupCompany) =>
              companiesToRemove.includes(
                comp[identifier as keyof CompanyGroupCompany]
              )
            )
          ) {
            tmpSelectedCheckboxPeerGroup = tmpSelectedCheckboxPeerGroup.filter(
              (chGroup: number) => chGroup !== pgp.companyGroupId
            );
          }
        });
    } else if (peerGroups && peerGroups.length) {
      // ADD
      tmpSelectedCheckboxPeerGroup = tmpSelectedCheckboxPeerGroup.concat(
        peerGroups
          ?.filter((pg: PeerGroup) =>
            pg.companyGroupCompanies.length === 0
              ? false
              : pg.companyGroupCompanies.every((cmp: CompanyGroupCompany) =>
                  selectedCompanies.includes(
                    cmp[identifier as keyof CompanyGroupCompany] as number
                  )
                ) && !selectedCheckboxPeerGroup.includes(pg.companyGroupId)
          )
          .map((pgToAdd: PeerGroup) => pgToAdd.companyGroupId)
      );
    }
    setSelectedCheckboxPeerGroup(tmpSelectedCheckboxPeerGroup);
    setSelectedCheckbox(selectedCompanies);
  };

  const selectSuggestedPeerMultiple = (
    pselectedCompanies: number[],
    parentCikNumber: number,
    isSelectAll: boolean = false
  ) => {
    let tmpSelectedCheckboxMultiple = [...selectedCheckboxMultiple];
    let currentIndex = -1;

    let companiesToRemove = [...selectedCheckboxMultiple]
      .find(
        (comp: SelectedCheckboxMultiple) =>
          comp.parentCikNumber === parentCikNumber
      )
      ?.selectedCompanies.filter(
        (companyCikNumber) => !pselectedCompanies.includes(companyCikNumber)
      );
    let isRemoving = false;
    // SEARCH IF THE SELECTION IS IN ANOTHER BASE COMPANIES
    suggestedPeerCompaniesByCompany?.forEach(
      (companyGroup: SuggestedPeerCompaniesByCompanyFilter) => {
        if (companiesToRemove && companiesToRemove.length > 0)
          isRemoving = true;
        else isRemoving = false;

        // FILTER ALL THE COMPANIES IN CURRENT SELECTION

        let tmpCompaniesSelection =
          companyGroup.suggestedPeerCompanyFilter.filter(
            (suggestedPeer: SuggestPeerCompanyFilterMultiple) =>
              pselectedCompanies.includes(
                suggestedPeer[
                  identifier as keyof SuggestPeerCompanyFilterMultiple
                ] as number
              )
          );
        if (
          tmpCompaniesSelection.length === 0 &&
          (companyGroup[
            identifier as keyof SuggestedPeerCompaniesByCompanyFilter
          ] as number) !== parentCikNumber
        )
          return;

        let currentCompanyExists = tmpSelectedCheckboxMultiple.find(
          (scm, i) => {
            currentIndex =
              scm.parentCikNumber ===
              (companyGroup[
                identifier as keyof SuggestedPeerCompaniesByCompanyFilter
              ] as number)
                ? i
                : -1;
            return (
              scm.parentCikNumber ===
              (companyGroup[
                identifier as keyof SuggestedPeerCompaniesByCompanyFilter
              ] as number)
            );
          }
        );

        // ADD FOR THE FIRST TIME
        if (!currentCompanyExists && !isRemoving) {
          tmpSelectedCheckboxMultiple.push({
            parentCikNumber: parentCikNumber,
            selectedCompanies:
              (companyGroup[
                identifier as keyof SuggestedPeerCompaniesByCompanyFilter
              ] as number) === parentCikNumber
                ? pselectedCompanies
                : tmpCompaniesSelection.map(
                    (cs: SuggestPeerCompanyFilterMultiple) =>
                      cs[identifier as keyof SuggestPeerCompanyFilterMultiple]
                  ),
          });
        } else {
          tmpSelectedCheckboxMultiple[currentIndex].selectedCompanies =
            companyGroup[
              identifier as keyof SuggestedPeerCompaniesByCompanyFilter
            ] === parentCikNumber
              ? pselectedCompanies
              : isRemoving && companiesToRemove && companiesToRemove.length > 0
              ? tmpSelectedCheckboxMultiple[
                  currentIndex
                ].selectedCompanies.filter(
                  (item) => !companiesToRemove?.includes(item)
                )
              : Array.from(
                  new Set(
                    tmpSelectedCheckboxMultiple[
                      currentIndex
                    ].selectedCompanies.concat(
                      tmpCompaniesSelection.map(
                        (cs: SuggestPeerCompanyFilterMultiple) =>
                          cs[
                            identifier as keyof SuggestPeerCompanyFilterMultiple
                          ]
                      )
                    )
                  )
                );
        }
      }
    );

    // UNSELECT PEER GROUPS CONTAINING COMPANIES TO REMOVE
    if (
      companiesToRemove &&
      companiesToRemove?.length > 0 &&
      unSelectCompanyFromFilter
    ) {
      let cikNumberToRemove = companiesToRemove[0];
      let tmpSelectedCheckboxPeerGroup = [...selectedCheckboxPeerGroup];

      peerGroups
        ?.filter((pg: PeerGroup) =>
          selectedCheckboxPeerGroup.includes(pg.companyGroupId)
        )
        .forEach((pgp: PeerGroup) => {
          if (
            pgp.companyGroupCompanies.find(
              (comp: CompanyGroupCompany) =>
                comp[identifier as keyof CompanyGroupCompany] ===
                cikNumberToRemove
            )
          ) {
            tmpSelectedCheckboxPeerGroup = tmpSelectedCheckboxPeerGroup.filter(
              (chGroup: number) => chGroup !== pgp.companyGroupId
            );
          }
        });

      setSelectedCheckboxPeerGroup(tmpSelectedCheckboxPeerGroup);
      setCompaniesUnselected([companiesToRemove[0]]);
    } else {
      // SELECT PEER GROUPS CONTAINING SELECTED COMPANIES
      let tmpSelectedCheckboxPeerGroup = [...selectedCheckboxPeerGroup];

      peerGroups
        ?.filter(
          (pg: PeerGroup) =>
            !selectedCheckboxPeerGroup.includes(pg.companyGroupId)
        )
        .forEach((pgp: PeerGroup) => {
          if (
            pgp.companyGroupCompanies.length &&
            pgp.companyGroupCompanies.every((comp: CompanyGroupCompany) =>
              pselectedCompanies.includes(
                comp[identifier as keyof CompanyGroupCompany] as number
              )
            )
          ) {
            tmpSelectedCheckboxPeerGroup.push(pgp.companyGroupId);
          }
        });
      setSelectedCheckboxPeerGroup(tmpSelectedCheckboxPeerGroup);
    }
    setSelectedCheckboxMultiple(tmpSelectedCheckboxMultiple);
  };

  useEffect(() => {
    if (isMultiple) {
      setDisabled(
        (selectedCheckboxMultiple.every(
          (scm: SelectedCheckboxMultiple) => scm.selectedCompanies.length === 0
        ) &&
          selectedCheckboxPeerGroup.length === 0) ||
          (JSON.stringify(selectedCheckboxPeerGroupItems) ===
            JSON.stringify(selectedCheckboxPeerGroup) &&
            JSON.stringify(selectedItemsMultiple) ===
              JSON.stringify(selectedCheckboxMultiple))
          ? 0
          : 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCheckboxMultiple, selectedCheckboxPeerGroup]);

  const getDataSources = (company: any) => {
    let sources: string[] = [];
    if (company.isCDPCompany) {
      sources.push(VENDOR_DETAILS.cdp.vendorName);
    }
    if (company.isSNPCompany) {
      sources.push(VENDOR_DETAILS.snp.vendorName);
    }
    if (company.isMSCICompany) {
      sources.push(VENDOR_DETAILS.msci.vendorName);
    }
    return sources.length > 0 ? sources.join(", ") : UNAVAILABLE;
  };
  const getTableItems = (
    psuggestedPeerCompanies:
      | SuggestPeerCompanyFilter[]
      | SuggestedPeerCompaniesByCompanyFilter[],
    parentCikNumber: number | undefined = undefined
  ): any => {
    return psuggestedPeerCompanies?.map((company: any) => {
      return {
        id: company[identifier],
        key: `suggested-peers-${company[identifier]}`,
        columnItems: [
          {
            checkbox: {
              onChange:
                isMultiple && selectItemsMultiple && parentCikNumber
                  ? (company: any) => {
                      selectSuggestedPeerMultiple(
                        company.map((company: any) => company.id),
                        parentCikNumber
                      );
                    }
                  : (company: any) => {
                      selectSuggestedPeer(
                        company.map((company: any) => company.id)
                      );
                    },
            },
            className: `${BLOCK}__column-checkbox`,
            type: "shortlist",
          },
          {
            text: company.ticker ? company.ticker : "",
            className: `${BLOCK}__column-company--ticker`,
          },
          {
            text: company.companyName ? company.companyName : "",
            className: `${BLOCK}__column-company--name`,
          },
          {
            text: company.ivaCompanyRating ? company.ivaCompanyRating : "",
            className: `${BLOCK}__column-company`,
          },
          {
            text: company.corpGovernanceScore
              ? company.corpGovernanceScore.toFixed(1)
              : "",
            className: `${BLOCK}__column-company--metrics`,
          },
          {
            text: company.mrfyRevenue
              ? dollarFormatter.format(company.mrfyRevenue)
              : "",
            className: `${BLOCK}__column-company`,
          },
          {
            text: company.mrMarketCap
              ? dollarFormatter.format(company.mrMarketCap)
              : "",
            className: `${BLOCK}__column-company`,
          },
          {
            text: company.totalAssets
              ? dollarFormatter.format(company.totalAssets)
              : "",
            className: `${BLOCK}__column-company`,
          },
          {
            text: company.auditor.auditorName
              ? company.auditor.auditorName
              : "",
            className: `${BLOCK}__column-company--auditor`,
          },
          (page === PEER_GROUP_CREATION_PAGE.INSIGHT ||
            page === PEER_GROUP_CREATION_PAGE.DASHBOARD ||
            page === PEER_GROUP_CREATION_PAGE.PEER_BENCHMARK) && {
            text: getDataSources(company),
            className: `${BLOCK}__column-company--source`,
          },
        ],
      };
    });
  };

  const selectPeerGroupHandler = (
    peerGroupParam: any,
    removeCompanies: boolean = false
  ) => {
    let tmpSelectedCheckboxPeerGroup = [...selectedCheckboxPeerGroup];

    if (peerGroups && peerGroups.length > 0) {
      if (tmpSelectedCheckboxPeerGroup.length > peerGroupParam.length) {
        // REMOVE
        let peerGroupToRemove = peerGroups.find(
          (pg: PeerGroup) =>
            pg.companyGroupId ===
            tmpSelectedCheckboxPeerGroup.find(
              (groupId: number) => !peerGroupParam.includes(groupId)
            )
        );
        let companiesToRemove = peerGroupToRemove?.companyGroupCompanies.map(
          (c: CompanyGroupCompany) =>
            c[identifier as keyof CompanyGroupCompany] as number
        );

        let companiesToRemoveFromPeers: number[] = [];

        companiesToRemove?.forEach((companyCikNumber: number) => {
          if (
            !(
              (isMultiple &&
                selectedCheckboxMultiple.find((scm: SelectedCheckboxMultiple) =>
                  scm.selectedCompanies.includes(companyCikNumber)
                )) ||
              (!isMultiple &&
                selectedCheckbox.find(
                  (compCikNumber: number) => compCikNumber === companyCikNumber
                )) ||
              peerGroups
                .filter((pg: PeerGroup) =>
                  peerGroupParam.includes(pg.companyGroupId)
                )
                .find((pg: PeerGroup) =>
                  pg.companyGroupCompanies.find(
                    (cmp: CompanyGroupCompany) =>
                      (cmp[
                        identifier as keyof CompanyGroupCompany
                      ] as number) === companyCikNumber
                  )
                )
            )
          ) {
            companiesToRemoveFromPeers.push(companyCikNumber);
          }
        });
        if (
          companiesToRemoveFromPeers.length > 0 &&
          unSelectCompanyFromFilter
        ) {
          if (removeCompanies) {
            unSelectCompanyFromFilter(companiesToRemoveFromPeers);
          } else {
            setCompaniesUnselected(companiesToRemoveFromPeers);
          }
        }
      }
    }
    setSelectedCheckboxPeerGroup(peerGroupParam);
  };

  const debounceChangeHandler = useMemo(() => {
    return debounce((e: any) => {
      dispatch(loadCompanyFilter(e.target.value));
    }, 300);
  }, [dispatch]);

  const editablePeerGroupRow = (peerGroup: PeerGroup) => {
    return {
      id: peerGroup.companyGroupId,
      key: `suggested-peers-group-${peerGroup.companyGroupId}`,
      columnItems: [
        {
          checkbox: {
            onChange: (peerGroupParam: any) => {
              selectPeerGroupHandler(peerGroupParam.map((g: any) => g.id));
            },
          },
          className: `${BLOCK}__column-checkbox`,
          type: "shortlist",
        },
        {
          wrapperClassName: `${BLOCK_SUGGESTED}__column-group-name-wrapper`,
          className: `${BLOCK_SUGGESTED}__column-group-name`,
          renderContent: () => (
            <div
              className={classNames(`${BLOCK_SUGGESTED}__group-input`, {
                [`${BLOCK_SUGGESTED}__group-input--error`]:
                  emptyName || insightsState.duplicatePeerGroupName,
              })}
            >
              <input
                type="text"
                maxLength={200}
                onChange={onChangeHandler}
                value={peerGroupName}
                name="peerGroupName"
                placeholder="Enter name"
                autoComplete="off"
                data-testid="Insight-name"
              />
              {emptyName || insightsState.duplicatePeerGroupName ? (
                <span className={`${BLOCK_SUGGESTED}__error-name`}>
                  <FormattedMessage
                    id={
                      emptyName
                        ? "peer.groups.empty.name"
                        : "peer.groups.duplicated.name"
                    }
                  />
                </span>
              ) : null}
            </div>
          ),
        },
        {
          wrapperClassName: `${BLOCK_SUGGESTED}__column-companies-wrapper`,
          className: `${BLOCK_SUGGESTED}__column-companies`,
          renderContent: () => (
            <DropDown
              className={`${BLOCK_SUGGESTED}__drop-down`}
              placeholder={""}
              loading={false}
              objectKeys={{
                name: "companyName",
                ticker: "ticker",
                id: "globalCompanyId",
                externalId: "externalCompanyId",
              }}
              values={peerCompanyFilterEdition}
              options={
                companyFilterOptions
                  ? companyFilterOptions
                  : searchState.companyFilterOptions
              }
              handleChange={
                debounceFunction ? debounceFunction : debounceChangeHandler
              }
              handleSelect={selectCompany}
              handleUnselect={unselectCompany}
            />
          ),
        },
      ],
      className: `${BLOCK_SUGGESTED}__group-row ${BLOCK_SUGGESTED}__group-row--${
        editPeerGroup === peerGroup.companyGroupId ? "active" : ""
      }`,
    } as TableRowItem;
  };

  const getTableItemsPeerGroup = (): any => {
    return peerGroups?.map((peerGroup: PeerGroup, i: number) => {
      const actions = [
        {
          name: "edit",
          iconName:
            editPeerGroup === peerGroup.companyGroupId ? "save" : "edit",
          onClick: () => {
            if (editPeerGroup === peerGroup.companyGroupId && !emptyName) {
              saveHandler(peerGroup);
            } else {
              setPeerGroupName(peerGroup.companyGroupName);
              // TODO: ADD DISPLAY ORDER LOGIC WHEN BACKEND CHANGES ARE DONE
              let tmpPerCompanyFilterEdition =
                peerGroup.companyGroupCompanies.map(
                  (comp: CompanyGroupCompany) => ({
                    companyName: comp?.company?.companyName ?? "",
                    companyId: comp.company?.companyId ?? 0,
                    externalCompanyId: comp.company?.externalCompanyId ?? 0,
                    cikNumber: comp.cikNumber,
                    ticker: comp.company?.ticker ?? "",
                    isSuggestedPeerCompan: true,
                    globalCompanyId:
                      comp.globalCompanyId ??
                      comp.company?.globalCompanyId ??
                      0,
                  })
                );

              let peerGroupGlobalCompanyMappings =
                peerGroup.globalCompanyMappings
                  ? peerGroup.globalCompanyMappings
                      .filter(
                        (m: Partial<GlobalCompanyGroup>) => !m.isBaseMapping
                      )
                      .map((m: Partial<GlobalCompanyGroup>) => {
                        let defaultCompany =
                          m.globalCompanyMappingDetails &&
                          m.globalCompanyMappingDetails.length
                            ? m.globalCompanyMappingDetails.find(
                                (d: GlobalCompanyMapping) => d.isDefault
                              )
                            : null;
                        return {
                          companyName: m.globalCompanyMappingName ?? "",
                          companyId: defaultCompany
                            ? defaultCompany.globalCompanyId
                            : 0,
                          externalCompanyId:
                            defaultCompany &&
                            defaultCompany?.globalCompany.externalCompanyId
                              ? defaultCompany?.globalCompany.externalCompanyId
                              : 0,
                          cikNumber:
                            defaultCompany &&
                            defaultCompany?.globalCompany.cikNumber
                              ? defaultCompany?.globalCompany.cikNumber
                              : 0,
                          ticker:
                            defaultCompany &&
                            defaultCompany?.globalCompany.ticker
                              ? defaultCompany?.globalCompany.ticker
                              : "",
                          isSuggestedPeerCompan: true,
                          globalCompanyId: defaultCompany
                            ? defaultCompany.globalCompanyId
                            : 0,
                          globalCompanyMapping: m,
                        };
                      })
                  : [];

              setPeerCompanyFilterEdition([
                ...tmpPerCompanyFilterEdition,
                ...peerGroupGlobalCompanyMappings,
              ]);
              setEditPeerGroup(peerGroup.companyGroupId);
            }
          },
          id: editPeerGroup === peerGroup.companyGroupId ? "save" : "edit",
        },
        {
          name: "delete",
          iconName:
            editPeerGroup === peerGroup.companyGroupId ? "cross" : "remove1",
          onClick: () => {
            if (editPeerGroup === peerGroup.companyGroupId) {
              setEmptyName(false);
              setEditPeerGroup(-1);
              setPeerGroupName("");
              setPeerCompanyFilterEdition([]);
            } else {
              setDeletePeerGroupId(peerGroup.companyGroupId);
              setPeerGroupName(peerGroup.companyGroupName);
              setShowDelete(true);
              setIsStandardDeletion(true);
            }
          },
          id: editPeerGroup === peerGroup.companyGroupId ? "cancel" : "delete",
        },
      ];
      let tmpCompanies = peerGroup.companyGroupCompanies.map(
        (val: CompanyGroupCompany) => val.company?.companyName
      );
      if (
        peerGroup.globalCompanyMappings &&
        peerGroup.globalCompanyMappings.length
      )
        tmpCompanies = tmpCompanies.concat(
          peerGroup.globalCompanyMappings
            .filter((g: Partial<GlobalCompanyGroup>) => !g.isBaseMapping)
            .map((g: Partial<GlobalCompanyGroup>) => g.globalCompanyMappingName)
        );

      peerGroup.companyGroupCompanies
        .map((val: CompanyGroupCompany) => val.company?.companyName)
        .join(", ");
      const tableRows =
        editPeerGroup === peerGroup.companyGroupId
          ? editablePeerGroupRow(peerGroup)
          : ({
              id: peerGroup.companyGroupId,
              key: `suggested-peers-group-${i}`,
              columnItems: [
                {
                  checkbox: {
                    onChange: (peerGroupParam: any) => {
                      selectPeerGroupHandler(
                        peerGroupParam.map((g: any) => g.id)
                      );
                    },
                  },
                  className: `${BLOCK}__column-checkbox`,
                  type: "shortlist",
                },
                {
                  wrapperClassName: `${BLOCK_SUGGESTED}__column-group-name-wrapper`,
                  text: peerGroup.companyGroupName,
                  className: ``,
                },
                {
                  wrapperClassName: `${BLOCK_SUGGESTED}__column-companies-wrapper`,
                  text: tmpCompanies.join(", "),
                  className: `${BLOCK_SUGGESTED}__column-company--name`,
                },
              ],
              className: `${BLOCK_SUGGESTED}__group-row ${BLOCK_SUGGESTED}__group-row--${
                editPeerGroup === peerGroup.companyGroupId ? "active" : ""
              }`,
            } as TableRowItem);

      const allActions = {
        wrapperClassName: `${BLOCK_SUGGESTED}__actions-wrapper ${BLOCK_SUGGESTED}__actions-wrapper--${
          editPeerGroup === peerGroup.companyGroupId ? "middle" : ""
        }`,
        className: `${BLOCK}__column-content  ${BLOCK}__actions ${BLOCK_SUGGESTED}__actions ${BLOCK_SUGGESTED}__actions--${
          editPeerGroup === peerGroup.companyGroupId ? "active" : ""
        }`,
        onClick: (e: any) => {
          e.stopPropagation();
        },
        renderContent: () =>
          actions.map((action) => (
            <button
              data-testid={`${action.id}-search-button`}
              key={action.name}
              className={`${BLOCK}__action-icon ${BLOCK}__${action.name} ${BLOCK_SUGGESTED}__action-icon`}
              onClick={(e: any) => {
                action.onClick();
              }}
            >
              <Tooltip position={TooltipPosition.top}>
                <FormattedMessage id={action.id} />
              </Tooltip>
              <Icon name={action.iconName} height={24} width={24} />
            </button>
          )),
      };
      tableRows.columnItems.push(allActions);
      return tableRows;
    });
  };

  const getTableByCompany = (currentTab: number) => {
    const currentCompany = suggestedPeerCompaniesByCompany?.find(
      (company: SuggestedPeerCompaniesByCompanyFilter, index: number) =>
        index === currentTab
    );

    return (
      <Table
        headerItems={getTableHeaders(currentCompany)}
        rowItems={getTableItems(
          currentCompany ? currentCompany?.suggestedPeerCompanyFilter : [],
          currentCompany
            ? (currentCompany[
                identifier as keyof SuggestedPeerCompaniesByCompanyFilter
              ] as number)
            : 0
        )}
        defaultSelectedItems={
          selectedCheckboxMultiple
            ? selectedCheckboxMultiple
                .find(
                  (item: SelectedCheckboxMultiple) =>
                    item.parentCikNumber ===
                    (currentCompany
                      ? (currentCompany[
                          identifier as keyof SuggestedPeerCompaniesByCompanyFilter
                        ] as number)
                      : 0)
                )
                ?.selectedCompanies.map((id: any) => ({
                  id: id,
                }))
            : []
        }
        tableRef={tableRef}
        maxHeight
      />
    );
  };

  const addCompanyHandler = () => {
    if (disabled) {
      if (isMultiple && selectItemsMultiple && dispatchFunctionMultiple) {
        selectItemsMultiple(
          selectedCheckboxMultiple,
          selectedCheckboxPeerGroup
        );
        dispatch(dispatchFunctionMultiple(selectedCheckboxMultiple));
        setShowModal(false);
      } else {
        addSelected(selectedCheckbox, selectedCheckboxPeerGroup);
        dispatch(dispatchFunction(selectedCheckbox));
      }
      dispatch(dispatchFunctionPeerGroups(selectedCheckboxPeerGroup));

      if (selectedCheckboxPeerGroup.length > 0) {
        let tmpPeerGroupsSelected = peerGroups
          .filter((pg: PeerGroup) =>
            selectedCheckboxPeerGroup.includes(pg.companyGroupId)
          )
          .map((pgc: PeerGroup) => ({
            companyGroupId: pgc.companyGroupId,
            companyGroupName: pgc.companyGroupName,
            baselineCompanyCikNumber: pgc.baselineCompanyCikNumber,
            baselineCompany: {
              cikNumber: pgc.baselineCompany?.cikNumber,
              companyName: pgc.baselineCompany?.companyName,
              ticker: pgc.baselineCompany?.ticker,
              companyId: pgc.baselineCompany?.companyId,
              externalCompanyId: pgc.baselineCompany?.externalCompanyId,
              industry: {
                industryId: pgc.baselineCompany?.industry
                  ? pgc.baselineCompany?.industry.industryId
                  : 0,
                industryName: pgc.baselineCompany?.industryName ?? "",
              },
              globalCompanyId: pgc.baselineCompany?.globalCompanyId ?? 0,
            },
            createdBy: pgc.createdBy,
            companyGroupCompanies: pgc.companyGroupCompanies.map(
              (company: CompanyGroupCompany) => ({
                companyGroupId: company.companyGroupId,
                cikNumber: company.cikNumber,
                displayOrder: company.displayOrder,
                isSuggestedPeerCompany: true,
                company: {
                  cikNumber: company.company?.cikNumber ?? 0,
                  companyName: company.company?.companyName ?? "",
                  ticker: company.company?.ticker ?? "",
                  companyId: company.company?.companyId ?? 0,
                  externalCompanyId: company.company?.externalCompanyId ?? 0,
                  industry: {
                    industryId: company.company?.industry.industryId ?? 0,
                    industryName: company.company?.industry.industryName ?? "",
                  },
                  globalCompanyId: company.company?.globalCompanyId ?? 0,
                },
                globalCompanyId: company.company?.globalCompanyId ?? 0,
              })
            ),
          }));

        let payload = {
          trackingType: TRACKING_IDS.ADD_PEER_GROUP,
          referenceId: null,
          trackingData: {
            companyGroups: [...tmpPeerGroupsSelected],
          },
        };
        dispatch(peerGroupSelectionTracking(payload));
      }
    } else {
      return;
    }
  };

  const cancelHandler = () => {
    setShowModal(false);
  };

  const handleTabChange = (index: number) => {
    setCurrentTab(index);
  };

  return (
    <div>
      <Modal
        show={true}
        header={isMultiple ? "suggested.peers" : "suggested.peers.for"}
        headerParams={{ companyName: selectedBaselineComp.companyName }}
        handleClose={() => {
          setShowModal(false);
        }}
        suggestedPeers={true}
        tooltipSuggestedPeers={commons.appSettings.PotentialPeersPopUpTooltip}
        config={false}
        wrapperClassName={`${BLOCK_SUGGESTED}__modal-wrapper ${BLOCK_SUGGESTED}__modal--${
          showDelete ? "border" : ""
        } ${page}--${BLOCK_SUGGESTED}--lowerMargin
        `}
      >
        <>
          <div
            className={`${BLOCK}__suggested-peers-modal ${BLOCK_SUGGESTED}__modal`}
            data-testid="suggested-peers-modal"
          >
            <TabbedContent
              tabItems={tabItems}
              selectedIndex={currentTab}
              onChange={handleTabChange}
              data-test="tab-content"
              className={`${BLOCK}__tabbed-content`}
              tabbedContentRef={modalRef}
            />

            {!isMultiple && currentTab === 0 && (
              <Table
                headerItems={getTableHeaders()}
                rowItems={getTableItems(suggestedPeerCompanies)}
                defaultSelectedItems={
                  selectedCheckbox
                    ? selectedCheckbox.map((id: any) => ({
                        id: id,
                      }))
                    : []
                }
                maxHeight
                tableRef={tableRef}
              />
            )}

            {isMultiple &&
              currentTab !== suggestedPeerCompaniesByCompany?.length &&
              getTableByCompany(currentTab)}

            {(isMultiple &&
              currentTab === suggestedPeerCompaniesByCompany?.length &&
              peerGroups &&
              peerGroups.length > 0) ||
            (!isMultiple &&
              currentTab === 1 &&
              peerGroups &&
              peerGroups.length > 0) ? (
              <>
                <Table
                  headerItems={getTableHeadersPeerGroup()}
                  rowItems={getTableItemsPeerGroup()}
                  defaultSelectedItems={
                    selectedCheckboxPeerGroup
                      ? selectedCheckboxPeerGroup.map((id: any) => ({
                          id: id,
                        }))
                      : []
                  }
                  maxHeight
                />
              </>
            ) : (isMultiple &&
                currentTab !== suggestedPeerCompaniesByCompany?.length) ||
              (!isMultiple && currentTab === 0) ? null : (
              <div className={`${BLOCK}__peer-group-empty`}>
                <FormattedMessage id="peer.groups.not.data" />
              </div>
            )}
          </div>
          <div className={`${BLOCK_SUGGESTED}__options`}>
            <Button
              className={`button-secondary`}
              dataTest="cancel-button"
              onClick={cancelHandler}
              formattedText="shortlist.edit.button.cancel"
            />
            <Button
              className={`button-primary`}
              dataTest="add-suggested-company-btn"
              disabled={disabled === 0}
              onClick={addCompanyHandler}
              formattedText="add"
            />
          </div>
          {showDelete && (
            <DeleteModal
              title="peer.groups.delete.modal.title"
              message={
                <>
                  <FormattedMessage
                    id={
                      isStandardDeletion
                        ? "peer.groups.delete.modal.msg"
                        : "peer.groups.delete.modal.msg.company"
                    }
                  />
                  <span
                    style={{ fontWeight: 600 }}
                  >{` "${peerGroupName}"`}</span>
                  {"?"}
                </>
              }
              deleteItem={() => {
                deletePeerGroupHandler(
                  deletePeerGroupId && deletePeerGroupId !== -1
                    ? deletePeerGroupId
                    : editPeerGroup
                );
                setShowDelete(false);
              }}
              handleCloseModal={() => {
                setShowDelete(false);
              }}
            />
          )}
        </>
      </Modal>
    </div>
  );
};
export default SuggestedPeers;
