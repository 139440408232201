import classNames from "classnames";
import { InsightData } from "services/insights/insights.model";
import { getInsightsDetailPeerViewColorCode } from "utils/functions";
type props = {
  dataset: InsightData[];
  isDetailedView: boolean;
  colorCodes: string[];
};
const DetailPeerViewLegend = ({
  dataset,
  isDetailedView,
  colorCodes,
}: props) => {
  return (
    <div className={`chart__legend`} data-testid="detail-peer-legend">
      {dataset.length > 0
        ? dataset.map((company, i) => {
            const hasData = company.metrics.some(
              (metric) =>
                metric.metricValue !== null &&
                metric.metricValue !== undefined &&
                metric.metricValue !== ""
            );
            return (
              <div
                key={`bar-chart-legend-${i}`}
                className={`chart__legend-value`}
              >
                {hasData ? (
                  <span
                    style={{
                      backgroundColor: getInsightsDetailPeerViewColorCode(
                        colorCodes,
                        company.displayOrder!
                      ),
                    }}
                    className={classNames("chart__legend-indicator", {
                      "chart__legend-indicator--detailed": isDetailedView,
                    })}
                  />
                ) : (
                  <span
                    className={classNames(
                      "chart__legend-indicator",
                      "chart__legend-null-indicator",
                      {
                        "chart__legend-indicator--detailed": isDetailedView,
                      }
                    )}
                  />
                )}
                <span>
                  {company.companyName}
                  {hasData ? null : " *"}
                </span>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default DetailPeerViewLegend;
