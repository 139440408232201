import { Dispatch } from "redux";
import { createAction } from "utils/redux.utils";

import { SearchResultsState, SearchResultsModel } from "./search.results.model";
import * as api from "./search.api";

export const ACTIONS = {
  SEARCH_ESG_REPORTS: "ESG/UI/SEARCH_ESG_REPORTS",
  SEARCH_ESG_REPORTS_NEXT_PAGE: "ESG/UI/SEARCH_ESG_REPORTS_NEXT_PAGE",
  SEARCH_SEC_REPORTS: "ESG/UI/SEARCH_SEC_REPORTS",
  SEARCH_SEC_REPORTS_NEXT_PAGE: "ESG/UI/SEARCH_SEC_REPORTS_NEXT_PAGE",
  SEARCH_ESG_REPORTS_LOADING: "ESG/UI/SEARCH_ESG_REPORTS_LOADING",
  SEARCH_SEC_REPORTS_LOADING: "ESG/UI/SEARCH_SEC_REPORTS_LOADING",
  ESG_REPORTS_NEXT_PAGE_LOADING: "ESG/UI/ESG_REPORTS_NEXT_PAGE_LOADING",
  SEC_REPORTS_NEXT_PAGE_LOADING: "ESG/UI/SEC_REPORTS_NEXT_PAGE_LOADING",
  SEARCH_TOC_REPORTS: "ESG/UI/SEARCH_TOC_REPORTS",
  SEARCH_TOC_LOADING: "ESG/UI/SEARCH_TOC_LOADING",
  ESG_REPORTS_SORT_LOADING: "ESG/UI/ESG_REPORTS_SORT_LOADING",
  SEARCH_ESG_REPORTS_SORT: "ESG/UI/SEARCH_ESG_REPORTS_SORT",
  SEC_REPORTS_SORT_LOADING: "ESG/UI/SEC_REPORTS_SORT_LOADING",
  SEARCH_SEC_REPORTS_SORT: "ESG/UI/SEARCH_SEC_REPORTS_SORT",
  RESET_SEARCH_RESULT: "ESG/UI/RESET_SEARCH_RESULT",
  ESG_REPORTS_SHORTLIST: "ESG/UI/ESG_REPORTS_SHORTLIST",
  SEC_REPORTS_SHORTLIST: "ESG/UI/SEC_REPORTS_SHORTLIST",
  TOC_REPORTS_SHORTLIST: "ESG/UI/TOC_REPORTS_SHORTLIST",
  ESG_SHORTLIST: "ESG/UI/ESG_SHORTLIST",
  SEC_SHORTLIST: "ESG/UI/SEC_SHORTLIST",
  SEC_SEARCH_SET_SELECTED_TOC: "ESG/UI/SEC_SEARCH_SET_SELECTED_TOC",
  SEARCH_ESG_REPORTS_TRACK: "ESG/UI/SEARCH_ESG_REPORTS_TRACK",
  SEARCH_SEC_REPORTS_TRACK: "ESG/UI/SEARCH_SEC_REPORTS_TRACK",
  SEARCH_ESG_RATINGS_TRACK: "ESG/UI/SEARCH_ESG_RATINGS_TRACK",
  SEARCH_ESG_GOVERNANCE_METRICS_TRACK:
    "ESG/UI/SEARCH_ESG_GOVERNANCE_METRICS_TRACK",
  SEARCH_PAYLOAD_ESG: "ESG/UI/SEARCH_PAYLOAD_ESG",
  SEARCH_PAYLOAD_SEC: "ESG/UI/SEARCH_PAYLOAD_SEC",
  SEARCH_SHOW_REQUEST_UPLOAD_PANEL: "ESG/UI/SEARCH_SHOW_REQUEST_UPLOAD_PANEL",
  SEARCH_REQUEST_UPLOAD_REPORT_SUCCESS: "ESG/UI/SEARCH_REQUEST_UPLOAD_REPORT_SUCCESS"
};

export const searchEsgReports = createAction(ACTIONS.SEARCH_ESG_REPORTS);

export const searchEsgReportsNextPage = createAction(
  ACTIONS.SEARCH_ESG_REPORTS_NEXT_PAGE
);

export const searchSecReports = createAction(ACTIONS.SEARCH_SEC_REPORTS);

export const searchSecReportsNextPage = createAction(
  ACTIONS.SEARCH_SEC_REPORTS_NEXT_PAGE
);

export const searchEsgReportsLoading = createAction(
  ACTIONS.SEARCH_ESG_REPORTS_LOADING
);

export const searchSecReportsLoading = createAction(
  ACTIONS.SEARCH_SEC_REPORTS_LOADING
);

export const esgReportsNextPageLoading = createAction(
  ACTIONS.ESG_REPORTS_NEXT_PAGE_LOADING
);

export const secReportsNextPageLoading = createAction(
  ACTIONS.SEC_REPORTS_NEXT_PAGE_LOADING
);

export const searchTOCLoading = createAction(ACTIONS.SEARCH_TOC_LOADING);

export const searchTOCReports = createAction(ACTIONS.SEARCH_TOC_REPORTS);

export const esgReportsSortLoading = createAction(
  ACTIONS.ESG_REPORTS_SORT_LOADING
);

export const searchEsgReportsSort = createAction(
  ACTIONS.SEARCH_ESG_REPORTS_SORT
);

export const secReportsSortLoading = createAction(
  ACTIONS.SEC_REPORTS_SORT_LOADING
);

export const searchSecReportsSort = createAction(
  ACTIONS.SEARCH_SEC_REPORTS_SORT
);
export const resetSearchResult = createAction(ACTIONS.RESET_SEARCH_RESULT);

export const esgShortlistChange = createAction(ACTIONS.ESG_REPORTS_SHORTLIST);

export const secShortlistChange = createAction(ACTIONS.SEC_REPORTS_SHORTLIST);

export const tocShortlistChange = createAction(ACTIONS.TOC_REPORTS_SHORTLIST);

export const esgShortlist = createAction(ACTIONS.ESG_SHORTLIST);
export const secShortlist = createAction(ACTIONS.SEC_SHORTLIST);
export const searchSetSelectedTOCSection = createAction(
  ACTIONS.SEC_SEARCH_SET_SELECTED_TOC
);
export const searchEsgReportsTrack = createAction(
  ACTIONS.SEARCH_ESG_REPORTS_TRACK
);
export const searchEsgRatingsTrack = createAction(
  ACTIONS.SEARCH_ESG_RATINGS_TRACK
);
export const searchEsgGovernanceMetricsTrack = createAction(
  ACTIONS.SEARCH_ESG_GOVERNANCE_METRICS_TRACK
);
export const searchSecReportsTrack = createAction(
  ACTIONS.SEARCH_SEC_REPORTS_TRACK
);
export const serachPayloadESG = createAction(ACTIONS.SEARCH_PAYLOAD_ESG);
export const searchPayloadSEC = createAction(ACTIONS.SEARCH_PAYLOAD_SEC);

export const setShowRequestUploadPanel = createAction(ACTIONS.SEARCH_SHOW_REQUEST_UPLOAD_PANEL)

export const setRequestUploadReportSuccess = createAction(ACTIONS.SEARCH_REQUEST_UPLOAD_REPORT_SUCCESS);
export const loadEsgSearchResults =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      const { pageInfo, shortlistId, ...searchPayload } = payload;

      dispatch(searchEsgReportsLoading(true));
      const res = await api.fetchEsgReportsResults(payload);
      dispatch(serachPayloadESG(searchPayload));
      dispatch(searchEsgReportsTrack(payload));
      dispatch(searchEsgReports(res.data));
      dispatch(searchEsgReportsLoading(false));
    } catch (e) {
      dispatch(searchEsgReportsLoading(false));
    }
  };

export const loadESGShortlistItemIds =
  (shortListItemIds: number[]) => async (dispatch: Dispatch<any>) =>
    dispatch(esgShortlist(shortListItemIds));

export const loadSECShortlistItemIds =
  (shortListItemIds: number[]) => async (dispatch: Dispatch<any>) =>
    dispatch(secShortlist(shortListItemIds));

export const loadEsgSearchResultsNextPage =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(esgReportsNextPageLoading(true));
      const res = await api.fetchEsgReportsResults(payload);
      dispatch(searchEsgReportsNextPage(res.data));
    } catch (e) {
      dispatch(esgReportsNextPageLoading(false));
    }
  };

export const loadEsgSearchResultsSort =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(esgReportsSortLoading(true));
      const res = await api.fetchEsgReportsResults(payload);
     
      dispatch(searchEsgReportsSort(res.data));
    } catch (e) {
      dispatch(esgReportsSortLoading(false));
    }
  };

export const loadSecSearchResults =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      const { pageInfo, shortlistId, ...searchPayload } = payload;

      dispatch(searchSecReportsLoading(true));
      const res = await api.fetchSecReportsResults(payload);
      dispatch(searchPayloadSEC(searchPayload));
      dispatch(searchSecReportsTrack(payload));
      dispatch(searchSecReports(res.data));
    } catch (e) {
      dispatch(searchSecReportsLoading(false));
    }
  };

export const loadSecSearchResultsNextPage =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(secReportsNextPageLoading(true));
      const res = await api.fetchSecReportsResults(payload);
      dispatch(searchSecReportsNextPage(res.data));
    } catch (e) {
      dispatch(secReportsNextPageLoading(false));
    }
  };

export const loadSecSearchResultsSort =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(secReportsSortLoading(true));
      const res = await api.fetchSecReportsResults(payload);
      dispatch(searchSecReportsSort(res.data));
    } catch (e) {
      dispatch(secReportsSortLoading(false));
    }
  };

export const loadTOCResults =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchTOCLoading(true));
      const res = await api.fetchTOCResults({
        filingId: payload.filingId,
        contentKeywords: payload.contentKeywords,
        shortlistId: payload.shortlistId,
      });
      dispatch(
        searchTOCReports({
          res: res,
          sectionId: payload.sectionId,
        })
      );
      dispatch(searchTOCLoading(false));
    } catch (e) {
      dispatch(searchTOCLoading(false));
    }
  };

export const setSelectedTOCSection =
  (selectedSection: any) => async (dispatch: Dispatch<any>) => {
    dispatch(searchSetSelectedTOCSection(selectedSection));
  };

export const changeEsgSearchResults =
  (reportId: number) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(esgShortlistChange(reportId));
    } catch (e) {}
  };

export const changeSecSearchResults =
  (sectionId: number) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(secShortlistChange(sectionId));
    } catch (e) {}
  };

export const changeTocSearchResults =
  (sectionId: number) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(tocShortlistChange(sectionId));
    } catch (e) {}
  };

  export const saveRequestUploadESGReport =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
  
      await api.saveRequestUploadReport(payload);
      dispatch(setRequestUploadReportSuccess(true));
    } catch (e) {
      dispatch(setRequestUploadReportSuccess(false));
    }
  };

const searchResultsModel = new SearchResultsModel();

const searchResultsReducer = (
  state: SearchResultsState = searchResultsModel.initialState(),
  action: any
): SearchResultsState => {
  switch (action.type) {
    case ACTIONS.SEARCH_ESG_REPORTS:
      return searchResultsModel.setEsgResults(action.payload);
    case ACTIONS.SEARCH_ESG_REPORTS_NEXT_PAGE:
      return searchResultsModel.appendEsgResults(action.payload);
    case ACTIONS.SEARCH_SEC_REPORTS:
      return searchResultsModel.setSecResults(action.payload);
    case ACTIONS.SEARCH_SEC_REPORTS_NEXT_PAGE:
      return searchResultsModel.appendSecResults(action.payload);
    case ACTIONS.SEARCH_ESG_REPORTS_LOADING:
      return searchResultsModel.setEsgLoading(action.payload);
    case ACTIONS.SEARCH_SEC_REPORTS_LOADING:
      return searchResultsModel.setSecLoading(action.payload);
    case ACTIONS.ESG_REPORTS_NEXT_PAGE_LOADING:
      return searchResultsModel.setEsgNextPageLoading(action.payload);
    case ACTIONS.SEC_REPORTS_NEXT_PAGE_LOADING:
      return searchResultsModel.setSecNextPageLoading(action.payload);
    case ACTIONS.SEARCH_TOC_LOADING:
      return searchResultsModel.setTOCLoading(action.payload);
    case ACTIONS.SEARCH_TOC_REPORTS:
      return searchResultsModel.setTOCResults(action.payload);
    case ACTIONS.ESG_REPORTS_SORT_LOADING:
      return searchResultsModel.setEsgSortLoading(action.payload);
    case ACTIONS.SEARCH_ESG_REPORTS_SORT:
      return searchResultsModel.setEsgResults(action.payload);
    case ACTIONS.SEC_REPORTS_SORT_LOADING:
      return searchResultsModel.setSecSortLoading(action.payload);
    case ACTIONS.SEARCH_SEC_REPORTS_SORT:
      return searchResultsModel.setSecResults(action.payload);
    case ACTIONS.RESET_SEARCH_RESULT:
      return searchResultsModel.resetSearch();
    case ACTIONS.ESG_REPORTS_SHORTLIST:
      return searchResultsModel.setEsgShortlist(action.payload);
    case ACTIONS.SEC_REPORTS_SHORTLIST:
      return searchResultsModel.setSecShortlist(action.payload);
    case ACTIONS.TOC_REPORTS_SHORTLIST:
      return searchResultsModel.setTocShortlist(action.payload);
    case ACTIONS.ESG_SHORTLIST:
      return searchResultsModel.setESGShortlistItemIds(action.payload);
    case ACTIONS.SEC_SHORTLIST:
      return searchResultsModel.setSECShortlistItemIds(action.payload);
    case ACTIONS.SEC_SEARCH_SET_SELECTED_TOC:
      return searchResultsModel.setSelectedTOCSection(action.payload);
    case ACTIONS.SEARCH_PAYLOAD_ESG:
      return searchResultsModel.setSearchPayloadESG(action.payload);
    case ACTIONS.SEARCH_PAYLOAD_SEC:
      return searchResultsModel.setSearchPayloadSEC(action.payload);
    case ACTIONS.SEARCH_ESG_RATINGS_TRACK:
      return searchResultsModel.setEsgRatingTrack(action.payload);
    case ACTIONS.SEARCH_ESG_GOVERNANCE_METRICS_TRACK:
      return searchResultsModel.setEsgGocernanceTrack(action.payload);
    case ACTIONS.SEARCH_SHOW_REQUEST_UPLOAD_PANEL:
      return searchResultsModel.setShowRequestUploadPanel(action.payload);
    case ACTIONS.SEARCH_REQUEST_UPLOAD_REPORT_SUCCESS:
      return searchResultsModel.setRequestUploadReportSuccess(action.payload);
    default:
      return state;
  }
};

export default searchResultsReducer;
