import { useDispatch, useSelector } from "react-redux";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  resetBenchmark,
  showEditBenchmark,
  showSidedashboardTab,
} from "services/dashboard/dashboard.service";
import { RootStore } from "services/store.service";
import { SIDE_CUSTOM_TYPE_SAVE } from "utils/constants";
import {
  showCreateNewTab,
  showEditTab,
  showTalkingPointsTab,
  showExportPanel,
  setInsightTileToExport,
} from "services/insights/insights.service";
import Button from "components/shared/button/button";

type Props = {
  BLOCK: string;
  type?: string;
  edit?: boolean;
};

const CustomBenchmarkHeader = ({ BLOCK, type, edit }: Props) => {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state: RootStore) => state.dashboard);
  const title =
    dashboardState.editBenchmarkData &&
    dashboardState.editBenchmarkData.benchmarkTitle
      ? dashboardState.editBenchmarkData.benchmarkTitle
      : "";

  return (
    <>
      <div
        className={`${BLOCK}__content`}
        data-test={`${BLOCK}__content`}
        data-testid={`${BLOCK}__content`}
      >
        {type === "edit" ? (
          <FormattedMessage id="edit.benchmark" values={{ title: title }} />
        ) : type === "insights" ? (
          edit ? (
            <FormattedMessage id="insights.edit" />
          ) : (
            <FormattedMessage id="insights.create.new" />
          )
        ) : type === "talkingPoints" ? (
          <FormattedMessage id="talking.points.header" />
        ) : type === "exportAll" ? (
          <FormattedMessage id="dashboard.export.panel" />
        ) : (
          <FormattedMessage id="dashboard.tab.custom.benchmark.button" />
        )}
      </div>

      <Button
        className={`${BLOCK}__cross`}
        dataTest={`${BLOCK}__cross`}
        onClick={() => {
          dispatch(resetBenchmark(SIDE_CUSTOM_TYPE_SAVE));
          type === "edit" ? (
            dispatch(showEditBenchmark(false))
          ) : type === "insights" ? (
            <>
              {dispatch(showCreateNewTab(false))}
              {dispatch(showEditTab(false))}
            </>
          ) : type === "talkingPoints" ? (
            dispatch(showTalkingPointsTab(false))
          ) : type === "exportAll" ? (
            <>
              {dispatch(showExportPanel(false))}
              {dispatch(setInsightTileToExport(null))}
            </>
          ) : (
            dispatch(showSidedashboardTab(false))
          );
        }}
        iconName="cross"
        iconHeight={24}
        iconWidth={24}
      />
    </>
  );
};

export default CustomBenchmarkHeader;
