/* eslint-disable eqeqeq */
import PieChart from "./charts/pie.chart";
import BarChart from "./charts/bar.chart";
import ScatterChart from "./charts/scatter-dot.chart";
import BubbleChart from "./charts/bubble.chart";
import LineChart from "./charts/line.chart";
import HorizontalBarChart from "./charts/horizontal-bar.chart";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  CUSTOM_CHART_EXPORT_TYPE,
  DEFAULT_VISUALIZATION_EXCEL_EXPORT_PAYLOAD,
  EXPORT_FORMAT,
  GRAPH_OPTIONS,
  PREDEFINED_CHART_EXPORT_TYPE,
  textColor,
  CHART_TYPES,
  SIDE_CUSTOM_TYPE_SAVE,
  UPDATE_VALUE_TYPE,
  APP_SETTING,
  PERIOD,
} from "utils/constants";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import Icon from "components/shared/icon/icon";
import classNames from "classnames";
import { DatasetLegend } from "./dataset-structure";
import {
  EditBenchMarkData,
  GroupedLegends,
  Legend,
  Visualization,
} from "services/dashboard/dashboard.model";
import {
  fetchVisualizationDataGet,
  fetchVisualizationDataPost,
} from "services/dashboard/dashboard.api";
import { BubbleChartDataset } from "services/dashboard/dashboard.model";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import Modal from "components/shared/modal/modal";
import Filters from "./filters-graph";
import {
  removeCustomBenchmark,
  duplicateSavedCustomBenchmark,
  applyBenchmark,
  resetBenchmark,
  updateVisualizationsMetadata,
  showSidedashboardTab,
  showEditBenchmark,
  filterGraphTrack,
  changeGraphStyleTrack,
  maximizeGraphTrack,
  exportGraphTrackExcel,
  exportGraphTrackPdf,
} from "services/dashboard/dashboard.service";
import { fetchIndustryFilter } from "services/dashboard/dashboard.api";
import Dropdown from "components/shared/dropdown/dropdown";
import { isNumber } from "lodash";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import DisclaimerModal from "components/shared/disclaimer-modal/disclaimer-modal";
import {
  sendExportItem,
  setShowDownloadTab,
} from "services/download/download.service";
import { useDrag, useDrop } from "react-dnd";
import { useClickOutside, mod, isNumeric, normalizeKey } from "utils/functions";
import DeleteModal from "components/shared/delete-modal/delete-modal";
import Parser from "html-react-parser";
import { isDataView } from "util/types";

export type Props = {
  metadata: Visualization;
  custom?: boolean;
  handleEditBenchmark?: (payload: EditBenchMarkData) => void;
  index: number;
  handleDragNDropCharts?: (
    dragIndex: number,
    hoverIndex: number,
    isDropped: boolean
  ) => void;
  currentGraphFocusRef?: React.RefObject<HTMLInputElement>;
  exportPDFTile: (start: number, end: number, title?: string) => void;
  setLoadedTiles: any;
  loadedTiles: any;
  setTileFullScreen?: any;
};

type Placeholder = {
  displayName: string;
  placeholder: string;
  api: () => Promise<any>;
  selected: any;
  data: any[];
};

type Sector = {
  industryId: number;
  externalIndustryId: string;
  sector: string;
};

type Industry = {
  industryId: number;
  industryName: string;
  sectors: Sector[];
};

const SINGLE_LEGEND_GRAPHS = [GRAPH_OPTIONS.line, GRAPH_OPTIONS.bubble];

type TemplateReplacer = {
  [key: string]: (textToReplace: string, data: any) => string;
};

const getReplaceFunction = (fieldValueName: string) => {
  const replaceFunctionByFieldValueName: TemplateReplacer = {
    reportYear: (template, data) =>
      template.replace("{reportYear}", data?.legendValue),
  };

  return replaceFunctionByFieldValueName?.[fieldValueName];
};

const DashboardTile = ({
  metadata,
  custom,
  handleEditBenchmark,
  index,
  handleDragNDropCharts,
  currentGraphFocusRef,
  exportPDFTile,
  setLoadedTiles,
  loadedTiles,
  setTileFullScreen,
}: Props) => {
  const BLOCK = "DashboardTile";
  const dispatch = useDispatch();
  const graphOptions: any[] = [
    { type: GRAPH_OPTIONS.column, icon: "bar", graph: BarChart },
    {
      type: GRAPH_OPTIONS.bar,
      icon: "bar-horizontal",
      graph: HorizontalBarChart,
    },
    { type: GRAPH_OPTIONS.pie, icon: "pie", graph: PieChart },
    { type: GRAPH_OPTIONS.donut, icon: "pie", graph: PieChart },
    { type: GRAPH_OPTIONS.bubble, icon: "scatter", graph: BubbleChart },
    { type: GRAPH_OPTIONS.scatter, icon: "scatter", graph: ScatterChart },
    { type: GRAPH_OPTIONS.line, icon: "line", graph: LineChart },
  ];
  const exportOptions = [
    {
      type: "xls",
      icon: "xls",
      text: "Export Excel",
    },
    {
      type: "pdf",
      icon: "pdf-2",
      text: "Export PDF",
    },
  ];
  const [loading, setLoading] = useState<boolean>(true);
  const [showFullScreen, setShowFullScreen] = useState<boolean>(false);
  const [showGraphOptions, setShowGraphOptions] = useState<boolean>(false);
  const [showExportOptions, setShowExportOptions] = useState<boolean>(false);
  const [selectedExportOption, setSelectedExportOption] = useState<string>("");
  const [responseSize, setResponseSize] = useState<number>(0);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [originalGroupedDataset, setOriginalGroupedDataset] = useState<any>([]);
  const [originalDataset, setOriginalDataset] = useState<any>([]);
  const [placeholderData, setPlaceholderData] = useState<Placeholder[]>([
    {
      displayName: "industryName",
      placeholder: "industryId",
      api: fetchIndustryFilter,
      selected: null,
      data: [],
    },
  ]);
  const [selectedGraphOption, setSelectedGraphOption] = useState<string>(
    metadata.benchmarkMetadata.bechmarkStyleOptions[0]
  );
  const [dataset, setDataset] = useState<
    Legend[] | BubbleChartDataset[] | null
  >(null);
  const [groupedDataset, setGroupedDataset] = useState<GroupedLegends[] | null>(
    null
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputDisabled, setInputDisabled] = useState<boolean>(false);
  const graphOptionsRef = useRef(null);
  const exportOptionsRef = useRef(null);
  useClickOutside(exportOptionsRef, () => setShowExportOptions(false));
  useClickOutside(graphOptionsRef, () => setShowGraphOptions(false));
  useClickOutside(inputRef, () => setInputDisabled(true));
  const filterRef = useRef(null);
  useClickOutside(filterRef, () => setShowFilter(false));
  const commonsState = useSelector((state: RootStore) => state.commons);
  const dashboardState = useSelector((state: RootStore) => state.dashboard);
  const downloadState = useSelector((state: RootStore) => state.download);
  const isBubbleChart = selectedGraphOption === GRAPH_OPTIONS.bubble;
  const graphTileRef = useRef<HTMLElement>(null);
  const [enableDrag, setEnableDrag] = useState<boolean>(false);
  const subMenuRef = useRef(null);
  const [hasData, setHasData] = useState(true);
  useClickOutside(subMenuRef, () => {
    setShowSubMenu(false);
  });

  const [, dragRef] = useDrag(
    () => ({
      type: "graph",
      item: () => {
        if (enableDrag) return { metadata, graphTileRef };
      },
      collect: (monitor) => {
        return {
          opacity: monitor.isDragging() ? 0.4 : 1,
          isDragging: monitor.isDragging(),
        };
      },
    }),
    [metadata.displayOrder, enableDrag]
  );

  const [{ isOver }, dropRef] = useDrop({
    accept: "graph",
    drop: (item: any, monitor: any) => {
      const indexDrop = metadata.displayOrder - 1;
      const indexDrag = item.metadata.displayOrder - 1;
      if (handleDragNDropCharts !== undefined && indexDrop !== indexDrag) {
        handleDragNDropCharts(indexDrag, indexDrop, true);
      }
    },
    collect: (monitor) => ({ isOver: monitor.isOver() }),
  });

  const dragDropRef: any = dragRef(dropRef(graphTileRef));

  const [isPredefinedPeriod, setIsPredefinedPeriod] = useState<boolean>(false);

  // For Edit Flow
  const [chartData, setChartData] = useState([]);
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  const [doPdfExport, setDoPdfExport] = useState<boolean>(false);
  const [showDisclarimerModal, setShowDisclarimerModal] =
    useState<boolean>(false);

  const isGrouped =
    metadata.benchmarkMetadata.groupProperties !== null &&
    metadata.benchmarkMetadata.groupProperties.length > 0;
  const benchmarkValue =
    metadata.benchmarkMetadata.benchmarkValue?.charAt(0).toUpperCase() +
    metadata.benchmarkMetadata.benchmarkValue?.slice(1);
  const groupValue = isGrouped
    ? metadata.benchmarkMetadata.groupProperties[0].charAt(0).toUpperCase() +
      metadata.benchmarkMetadata.groupProperties[0].slice(1)
    : metadata.benchmarkMetadata.filters.length
    ? metadata.benchmarkMetadata.filters[0].label
    : "Filter";

  const loadPlaceholder = (p: Placeholder) => {
    p.api().then(({ data }) => {
      if (data && data.length)
        setPlaceholderData([
          ...placeholderData.filter(
            ({ placeholder }) => placeholder !== p.placeholder
          ),
          {
            ...p,
            data,
            selected: metadata.benchmarkMetadata.api.selectedIndustry
              ? metadata.benchmarkMetadata.api.selectedIndustry
              : data[0],
          },
        ]);
    });
  };

  const getElementSearchUrl = (
    fieldValueNames: string[],
    template: string,
    data: any
  ) =>
    fieldValueNames.reduce((template, fieldValueName) => {
      const updatedTemplate = getReplaceFunction(fieldValueName)?.(
        template,
        data
      );

      return updatedTemplate || template;
    }, template);

  const searchGraphElement = (data: any) => {
    const fieldValueNames = metadata.benchmarkMetadata.fieldValueNames;
    const template = metadata.benchmarkMetadata.actionUrl;
    const searchUrl = getElementSearchUrl(fieldValueNames, template, data);

    if (!template || searchUrl === "") return;

    window.open(searchUrl, "_blank");
  };

  const [showDeleteCustomBenchmarkModal, setShowDeleteCustomBenchmarkModal] =
    useState<boolean>(false);

  const deleteCustomBenchMark = () => {
    dispatch(
      removeCustomBenchmark(
        metadata.predefinedBenchmarkId
          ? metadata.predefinedBenchmarkId
          : metadata.benchmarkId || 0
      )
    );
    setShowDeleteCustomBenchmarkModal(false);
    dispatch(showSidedashboardTab(false));
    dispatch(showEditBenchmark(false));
  };

  const duplicateCustomBenchmarkHanler = () => {
    const { benchmarkId } = metadata;
    dispatch(duplicateSavedCustomBenchmark(benchmarkId));
    dispatch(showSidedashboardTab(false));
    dispatch(showEditBenchmark(false));
    setShowSubMenu(false);
  };

  const editBenchmarkHandler = () => {
    dispatch(setShowDownloadTab(false));
    setShowFullScreen(false);
    let payload: EditBenchMarkData = {
      benchmarkId: metadata.benchmarkId ? metadata.benchmarkId : 0,
      benchmarkType: metadata.benchmarkType ? metadata.benchmarkType : 0,
      companyFilter: chartData[0],
      peerCompanyFilter: [...chartData].slice(1),
      benchmarkTitle: metadata.benchmarkTitle,
      metrics: metadata.benchmarkMetadata.associatedMetrics
        ? metadata.benchmarkMetadata.associatedMetrics
        : [],
      benchmarkMetadata: metadata.benchmarkMetadata,
      description: metadata.description,
    };
    handleEditBenchmark && handleEditBenchmark(payload);
    setShowGraphOptions(false);
    setShowExportOptions(false);
    setShowSubMenu(false);
    setShowFilter(false);
  };

  const getFilterValues = (label: string) =>
    metadata.benchmarkMetadata.filters &&
    metadata.benchmarkMetadata.filters.length > 0 &&
    metadata.benchmarkMetadata.filters.find((f: any) => f.label === label)
      ? metadata.benchmarkMetadata.filters.find((f: any) => f.label === label)!
          .values
      : [];

  const fetchChartAPIData = () => {
    let dataApi = metadata.benchmarkMetadata.api.endPoint;

    if (metadata.benchmarkMetadata.selectedStyleOption)
      setSelectedGraphOption(metadata.benchmarkMetadata.selectedStyleOption);
    setLoading(true);

    metadata.benchmarkMetadata.api.placeHolders.forEach((p, i) => {
      // Replace the placeholders or load the data
      const placeholderDataTmp = placeholderData.find(
        ({ placeholder }) => p === placeholder
      );
      if (placeholderDataTmp) {
        placeholderDataTmp.selected
          ? (dataApi = dataApi.replace(
              `{${p}}`,
              placeholderDataTmp.selected[p]
            ))
          : loadPlaceholder(placeholderDataTmp);
      }
    });

    const fetchVisualizationData = metadata.benchmarkMetadata.api.payload
      ? metadata.benchmarkTileType === 25
        ? fetchVisualizationDataGet
        : fetchVisualizationDataPost
      : fetchVisualizationDataGet;
    // remove when cdp id done
    setLoading(false);

    // Check if we removed the placeholders from the API call
    if (dataApi && !dataApi.includes("{"))
      fetchVisualizationData(
        dataApi,
        metadata.benchmarkMetadata.api.payload ?? {}
      )
        .then((response) => {
          let data =
            metadata.predefinedBenchmarkId === 6
              ? response
              : response.reduce((acc: any, current: any) => {
                  if (current) {
                    const fieldValue = isNumeric(current.fieldValue)
                      ? parseFloat(current.fieldValue)
                      : current.fieldValue;

                    if (metadata.predefinedBenchmarkId === 3) {
                      const groupValue =
                        metadata.benchmarkMetadata.fieldValueNames[0] ??
                        "globalCompanyId";
                      const company = acc.find(
                        (c: any) => c[groupValue] === current[groupValue]
                      );

                      if (company) {
                        company[normalizeKey(current.fieldName)] = fieldValue;
                        return acc;
                      }
                    }

                    return [
                      ...acc,
                      {
                        ...current,
                        [normalizeKey(current.fieldName)]: fieldValue,
                      },
                    ];
                  }
                  return acc;
                }, []);

          const displayPropertyName =
            metadata.benchmarkMetadata.displayPropertyNames &&
            metadata.benchmarkMetadata.displayPropertyNames[0];
          const filterValues = getFilterValues(groupValue);

          if (data && data[0] && data[0].reportId) {
            setIsPredefinedPeriod(true);
          }

          setResponseSize(data.length);
          const needsGrouping =
            metadata.benchmarkMetadata.displayPropertyNames &&
            metadata.benchmarkMetadata.displayPropertyNames.length > 3;

          let groupedData: GroupedLegends[] = [];
          if (
            metadata.benchmarkMetadata.groupProperties &&
            metadata.benchmarkMetadata.groupProperties.length
          ) {
            const groupPropertyName =
              metadata.benchmarkMetadata.groupProperties[0];
            groupedData = mapGroupedData(
              data,
              groupPropertyName,
              displayPropertyName
            );
            filterValues.length > 0
              ? setGroupedDataset(
                  groupedData.filter(
                    ({ groupName }: GroupedLegends) =>
                      !filterValues.includes(groupName)
                  )
                )
              : setGroupedDataset(groupedData);
            setOriginalGroupedDataset(groupedData);
          } else if (needsGrouping) {
            setOriginalGroupedDataset(mapCustomGroupData(data));

            filterValues.length > 0
              ? setGroupedDataset(
                  mapCustomGroupData(data).filter(
                    ({ groupName }: GroupedLegends) =>
                      !filterValues.includes(groupName)
                  )
                )
              : setGroupedDataset(mapCustomGroupData(data));

            const hasNoData = mapCustomGroupData(data).every((groupData) =>
              groupData.legends.every(
                (legend) =>
                  legend.legendData === null || parseInt(legend.legendData) <= 0
              )
            );

            setHasData(!hasNoData);
          } else {
            setGroupedDataset([]);
            setOriginalGroupedDataset([]);
          }
          let visData: any;
          if (isBubbleChart) {
            visData = mapBubbleChartData(
              data,
              metadata.benchmarkMetadata.displayPropertyNames
            );
            setDataset(visData);
            setOriginalDataset(visData);
          } else {
            visData = custom
              ? mapCustomData(data, needsGrouping)
              : mapData(data, displayPropertyName);

            const dataset_tmp =
              metadata.benchmarkMetadata.legends &&
              metadata.benchmarkMetadata.legends.length
                ? metadata.benchmarkMetadata.legends.map((legend) => ({
                    ...legend,
                    ...visData.find(
                      (data: any) => data.legendValue == legend.legendValue
                    ),
                  }))
                : visData;

            filterValues.length > 0 && !isGrouped
              ? setDataset(
                  dataset_tmp.filter(
                    ({ legendValue }: Legend) =>
                      !filterValues.includes(legendValue.toString())
                  )
                )
              : setDataset(dataset_tmp);
            setOriginalDataset(dataset_tmp);
          }

          setChartData(data);
          setLoading(false);
        })
        .catch((e) => {
          setGroupedDataset(null);
          setDataset(null);
          setLoading(false);
        });
  };

  useEffect(() => {
    if (setTileFullScreen) setTileFullScreen(showFullScreen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFullScreen]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchChartAPIData();
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeholderData, metadata]);

  useEffect(() => {
    if (!loading) {
      const currentState = [...loadedTiles];
      if (!currentState[index]) {
        currentState[index] = true;
        setLoadedTiles(currentState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loadedTiles]);

  useEffect(() => {
    const abortController = new AbortController();

    if (
      dashboardState.applyBenchmark &&
      dashboardState.editBenchmarkData &&
      dashboardState.editBenchmarkData.benchmarkId &&
      dashboardState.editBenchmarkData.benchmarkId === metadata.benchmarkId
    ) {
      fetchChartAPIData();
      dispatch(applyBenchmark(false));
      dispatch(resetBenchmark(SIDE_CUSTOM_TYPE_SAVE));
    }
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardState.applyBenchmark, dashboardState.editBenchmarkData]);

  useEffect(() => {
    const data = dataset as Legend[];
    const colorCodes = metadata.benchmarkMetadata?.colorCodes
      ? metadata.benchmarkMetadata.colorCodes
      : commonsState.dashboardColorCodes;

    if (data && data[0] && !data[0].legendColor && !isBubbleChart) {
      setDataset(
        data.map((legend, i) => ({
          ...legend,
          legendColor: colorCodes[mod(i, colorCodes.length)],
          legendOrder: legend.legendOrder ?? i,
        }))
      );
    }

    if (
      groupedDataset &&
      groupedDataset[0] &&
      groupedDataset[0].legends &&
      groupedDataset[0].legends[0] &&
      !groupedDataset[0].legends[0].legendColor
    ) {
      setGroupedDataset(
        groupedDataset.map((grouped) => ({
          ...grouped,
          legends: grouped.legends.map((legend, i) => ({
            ...legend,
            legendColor: colorCodes[mod(i, colorCodes.length)],
          })),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonsState.dashboardColorCodes, dataset, groupedDataset]);

  const mapGroupedData = (
    data: any,
    groupPropertyName: string,
    displayPropertyName: string
  ) =>
    data.reduce((dataset: GroupedLegends[], current: any) => {
      const currentGroupName = current[groupPropertyName];
      const currentValueName = current[displayPropertyName];

      if (
        !currentGroupName ||
        currentGroupName === "null" ||
        !currentValueName ||
        currentValueName === "null"
      ) {
        return dataset;
      }

      const currentGroup = dataset.find(
        ({ groupName }) => groupName == currentGroupName
      );
      const legend =
        metadata.benchmarkMetadata.legends.find(
          ({ legendValue }) => legendValue == currentValueName
        ) ??
        ({
          legendOrder: 0,
        } as Legend);

      const legendColor = legend ? legend.legendColor : textColor;

      if (currentGroup) {
        const currentLegend = currentGroup.legends.find(
          ({ legendValue }) => legendValue == currentValueName
        );

        if (currentLegend) {
          currentLegend.legendData++;
        } else if (legend.legendValue) {
          currentGroup.legends.push({
            ...legend,
            legendData: 1,
            legendColor: legendColor,
          });
        }

        return dataset;
      }

      return [
        ...dataset,
        {
          groupName: currentGroupName,
          legends: [{ ...legend, legendData: 1, legendColor: legendColor }],
        },
      ];
    }, []);

  const mapData = (data: any, displayPropertyName: string) =>
    data.reduce((dataset: Legend[], current: any) => {
      const currentValue = current[displayPropertyName];

      if (!currentValue || currentValue === "null" || currentValue == "")
        return dataset;

      const currentLabel = dataset.find(
        ({ legendValue }) => legendValue == currentValue
      );

      if (currentLabel) {
        currentLabel.legendData++;
        return dataset;
      }
      return [
        ...dataset,
        {
          legendValue: currentValue,
          legendData: 1,
        },
      ];
    }, []);

  const getLegendColor = (index: number) => {
    const colorCodes = metadata.benchmarkMetadata.colorCodes
      ? metadata.benchmarkMetadata.colorCodes
      : commonsState.dashboardColorCodes;

    return colorCodes[mod(index, colorCodes.length)];
  };

  const getLegendTooltip = (data: any, tooltip: string) => {
    return tooltip.replace(/\{(.*?)\}/g, (a, token) => {
      return isNumeric(data[token])
        ? parseFloat(data[token] ?? 0).toLocaleString()
        : data[token] ?? "";
    });
  };

  const mapCustomGroupData = (data: any): GroupedLegends[] =>
    metadata.benchmarkMetadata.axis.secondary
      ? metadata.benchmarkMetadata.axis.secondary.map((axis, i) => ({
          groupName: axis,
          separateBars: true,
          legends: data.map((d: any, j: number) => ({
            legendValue: metadata.benchmarkMetadata.hideTooltipLegendValue
              ? ""
              : d[metadata.benchmarkMetadata.displayPropertyNames[0]],
            legendOrder: j,
            legendColor: getLegendColor(data.length > 1 ? j : i),
            legendTooltip: getLegendTooltip(
              d,
              metadata.benchmarkMetadata.associatedMetricsValueTooltip
                ? metadata.benchmarkMetadata.associatedMetricsValueTooltip[i] ??
                    ""
                : ""
            ),
            legendHideData: false,
            legendData:
              d[metadata.benchmarkMetadata.displayPropertyNames[i + 1]] ?? null,
          })),
        }))
      : data;

  const mapCustomData = (data: any, needsGrouping?: boolean): Legend[] =>
    data.map((d: any, i: number) => {
      const legendData = needsGrouping
        ? metadata.benchmarkMetadata.displayPropertyNames
            .slice(1)
            .reduce((acc, dn) => Math.min(d[dn], acc), 10)
        : d[metadata.benchmarkMetadata.displayPropertyNames[0]] ?? null;
      const axis = metadata.benchmarkMetadata.axis.primary;
      return {
        legendValue:
          d[
            metadata.benchmarkMetadata.displayPropertyNames[
              needsGrouping ? 0 : 1
            ]
          ],
        legendData:
          isNumber(legendData) || axis.length < legendData
            ? legendData
            : axis.indexOf(legendData) + 1,
        legendOrder: i,
      };
    });

  const exportHandler = () => {
    exportChart(
      selectedExportOption === "pdf" ? EXPORT_FORMAT.pdf : EXPORT_FORMAT.xls
    );
  };
  const downloadHandler = (exportOption: any) => {
    if (
      !commonsState.dashboardExportDisclaimer[
        APP_SETTING.DashboardExportDisclaimer
      ]
    ) {
      exportChart(
        exportOption.type === "pdf" ? EXPORT_FORMAT.pdf : EXPORT_FORMAT.xls
      );
    } else {
      setSelectedExportOption(exportOption.type);
      setShowDisclarimerModal(true);
    }
  };

  const applyFilter = (filters: any) => {
    isGrouped
      ? setGroupedDataset(
          originalGroupedDataset.filter(({ groupName }: GroupedLegends) =>
            filters.includes(groupName)
          )
        )
      : setDataset(
          originalDataset.filter(({ legendValue }: Legend) =>
            filters.includes(legendValue)
          )
        );
    const updateValue = isGrouped
      ? originalGroupedDataset
          .filter(
            ({ groupName }: GroupedLegends) => !filters.includes(groupName)
          )
          .map(({ groupName }: GroupedLegends) => groupName)
      : originalDataset
          .filter(({ legendValue }: Legend) => !filters.includes(legendValue))
          .map(({ legendValue }: Legend) => legendValue.toString());

    dispatch(
      updateVisualizationsMetadata(metadata, {
        updateValue: [{ label: groupValue, values: updateValue }],
        type: UPDATE_VALUE_TYPE.FILTER,
      })
    );

    dispatch(
      filterGraphTrack({
        filters: filters,
        name: metadata.benchmarkTitle,
      })
    );
  };

  const resetFilterToDefault = () => {
    let defaultValues;
    if (isGrouped) {
      setGroupedDataset(originalGroupedDataset);
      defaultValues = originalGroupedDataset.map(
        ({ groupName }: GroupedLegends) => groupName
      );
    } else {
      setDataset(originalDataset);
      defaultValues = originalDataset.map(({ legendValue }: Legend) =>
        legendValue.toString()
      );
    }

    dispatch(
      updateVisualizationsMetadata(metadata, {
        updateValue: [{ label: groupValue, values: [] }],
        type: UPDATE_VALUE_TYPE.FILTER,
      })
    );

    dispatch(
      filterGraphTrack({
        filters: defaultValues,
        name: metadata.benchmarkTitle,
      })
    );
  };

  type PropertyCombinationCount = {
    [yPropertyName: string]: { [xPropertyName: string]: number };
  };

  const mapBubbleChartData = (data: any, displayPropertyNames: string[]) => {
    const [yPropertyName, xPropertyName] = displayPropertyNames;
    const propertyCombinationCount: PropertyCombinationCount = data.reduce(
      (countObj: PropertyCombinationCount, current: any) => {
        const yProperty = current[yPropertyName];
        const xProperty = current[xPropertyName];

        if (yProperty === "null" || yProperty === "") return countObj;

        if (!xProperty) return countObj;

        const xProperties = countObj[yProperty] || {};
        const count = xProperties[xProperty] || 0;

        return {
          ...countObj,
          [yProperty]: { ...xProperties, [xProperty]: count + 1 },
        };
      },
      {}
    );

    return Object.entries(propertyCombinationCount).reduce(
      (acc: BubbleChartDataset[], [yProperty, xProperties], i) => {
        const newEntries = Object.entries(xProperties).map(
          ([xProperty, count], j) => ({
            yProperty,
            xProperty: Number(xProperty),
            zProperty: count,
            id: `${i}${j}`,
          })
        );

        return [...acc, ...newEntries];
      },
      []
    );
  };

  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      setInputDisabled(true);
    }
  };

  const editableInput = () => (
    <div className={`${BLOCK}__input`} onClick={() => setInputDisabled(false)}>
      <input
        type="text"
        ref={inputRef}
        placeholder="Add title"
        onKeyDown={onEnter}
        disabled={inputDisabled}
      />
    </div>
  );

  useEffect(() => {
    if (doPdfExport && !showExportOptions) {
      exportPDFTile(index, index + 1, metadata.benchmarkTitle);
      setDoPdfExport(false);
      dispatch(exportGraphTrackPdf(metadata.benchmarkTitle));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doPdfExport]);

  useEffect(() => {
    if (showFullScreen) dispatch(maximizeGraphTrack(metadata.benchmarkTitle));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFullScreen]);

  const exportChart = async (exportFormat: number) => {
    setShowFullScreen(false);
    if (exportFormat === EXPORT_FORMAT.pdf) {
      setShowExportOptions(false);
      setDoPdfExport(true);
    } else if (exportFormat === EXPORT_FORMAT.xls) {
      const predefinedIndustryId =
        placeholderData.filter((p) => p.selected !== null && p.data.length)
          .length !== 0
          ? placeholderData.filter(
              (p) => p.selected !== null && p.data.length
            )[0].selected.industryId
          : 0;

      let payload = {};
      if (isPredefinedPeriod) {
        payload = {
          ...DEFAULT_VISUALIZATION_EXCEL_EXPORT_PAYLOAD,
          exportFileName: metadata.benchmarkTitle,
          exportReferenceId:
            metadata.benchmarkType === CHART_TYPES.predefined
              ? metadata.predefinedBenchmarkId
              : metadata.benchmarkId,
          exportFormat,
          exportType:
            metadata.benchmarkType === CHART_TYPES.predefined
              ? PREDEFINED_CHART_EXPORT_TYPE
              : CUSTOM_CHART_EXPORT_TYPE,
          reportId:
            metadata.benchmarkType === CHART_TYPES.predefined
              ? predefinedIndustryId
              : metadata.benchmarkId,
          downloadTabOpen: downloadState.showDownloads,
        };
      } else if (
        placeholderData.filter((p) => p.selected !== null && p.data.length)
          .length !== 0
      ) {
        let sectorIds: number[] = [];
        placeholderData[0].data.forEach((industry: Industry) =>
          industry.sectors.forEach((sector: Sector) => {
            if (
              isGrouped &&
              groupedDataset
                ?.map((data) => data.groupName)
                .includes(sector.sector)
            ) {
              sectorIds.push(sector.industryId);
            }
          })
        );

        payload = {
          ...DEFAULT_VISUALIZATION_EXCEL_EXPORT_PAYLOAD,
          exportFileName: metadata.benchmarkTitle,
          exportReferenceId:
            metadata.benchmarkType === CHART_TYPES.predefined
              ? metadata.predefinedBenchmarkId
              : metadata.benchmarkId,
          exportFormat,
          exportType:
            metadata.benchmarkType === CHART_TYPES.predefined
              ? PREDEFINED_CHART_EXPORT_TYPE
              : CUSTOM_CHART_EXPORT_TYPE,
          industryId:
            metadata.benchmarkType === CHART_TYPES.predefined
              ? predefinedIndustryId
              : metadata.benchmarkId,
          sectorIds,
          downloadTabOpen: downloadState.showDownloads,
        };
      } else {
        payload = {
          ...DEFAULT_VISUALIZATION_EXCEL_EXPORT_PAYLOAD,
          exportFileName: metadata.benchmarkTitle,
          exportReferenceId:
            metadata.benchmarkType === CHART_TYPES.predefined
              ? metadata.predefinedBenchmarkId
              : metadata.benchmarkId,
          exportFormat,
          exportType:
            metadata.benchmarkType === CHART_TYPES.predefined
              ? PREDEFINED_CHART_EXPORT_TYPE
              : CUSTOM_CHART_EXPORT_TYPE,
          industryId:
            metadata.benchmarkType === CHART_TYPES.predefined
              ? predefinedIndustryId
              : metadata.benchmarkId,
          downloadTabOpen: downloadState.showDownloads,
        };
      }

      dispatch(sendExportItem(payload));
      setShowExportOptions(false);
      dispatch(exportGraphTrackExcel(metadata.benchmarkTitle));
    }
  };

  const legendData =
    SINGLE_LEGEND_GRAPHS.includes(selectedGraphOption) || !dataset
      ? [
          {
            ...metadata.benchmarkMetadata.legends?.[0],
            legendData: responseSize,
          },
        ]
      : (dataset as Legend[]);

  const handleDownloadDisable = () => {
    setShowExportOptions(!showExportOptions);
    setShowSubMenu(false);
    setShowFilter(false);
    dispatch(showSidedashboardTab(false));
    dispatch(showEditBenchmark(false));
  };

  const handleFilterDisable = () => {
    setShowFilter(!showFilter);
    setShowExportOptions(false);
    setShowSubMenu(false);
    dispatch(showSidedashboardTab(false));
    dispatch(showEditBenchmark(false));
  };

  const handleGraphChangeDisable = () => {
    setShowGraphOptions(!showGraphOptions);
    setShowExportOptions(false);
    setShowSubMenu(false);
    setShowFilter(false);
    dispatch(showSidedashboardTab(false));
    dispatch(showEditBenchmark(false));
  };

  const handleFullScreenDisable = () => {
    setShowFullScreen(!showFullScreen);
    setShowGraphOptions(false);
    setShowExportOptions(false);
    setShowSubMenu(false);
    setShowFilter(false);
    dispatch(showSidedashboardTab(false));
    dispatch(showEditBenchmark(false));
  };

  const getGraphScale = () => {
    const selectedStyleOption = selectedGraphOption;
    const styleIndex =
      metadata.benchmarkMetadata.bechmarkStyleOptions.indexOf(
        selectedStyleOption
      );
    const vScale =
      typeof metadata.benchmarkMetadata.vScale === "number"
        ? metadata.benchmarkMetadata.vScale
        : metadata.benchmarkMetadata.vScale[styleIndex];
    const hScale =
      typeof metadata.benchmarkMetadata.hScale === "number"
        ? metadata.benchmarkMetadata.hScale
        : metadata.benchmarkMetadata.hScale[styleIndex] || 1;

    return { vScale, hScale };
  };
  const { vScale, hScale } = getGraphScale();
  const graph = (
    <div
      className={classNames(`${BLOCK}`, {
        [`${BLOCK}--fullscreen`]: showFullScreen,
      })}
      id={`benchmark-graph-${metadata.benchmarkId}`}
      ref={showFullScreen ? null : dragDropRef}
      style={{
        backgroundColor: isOver ? "#f1f8fb" : "white",
        gridColumnEnd: `span ${hScale}`,
        gridRowEnd: `span ${vScale}`,
        height: "unset",
      }}
      key={`benchmark_graph_${metadata.benchmarkId}`}
      data-testid="benchmarkGraph"
    >
      <div
        className={`${BLOCK}__header`}
        style={{
          borderBottom: "1px solid #f0f0f",
          paddingBottom: "1rem",
        }}
        ref={currentGraphFocusRef}
      >
        {/* {...provided.dragHandleProps} */}

        <span
          className={classNames(``, {
            [`${BLOCK}__header--drag`]: !showFullScreen,
          })}
          onMouseOver={() => {
            if (!enableDrag && !showFullScreen) setEnableDrag(true);
          }}
          onMouseLeave={() => {
            if (enableDrag) setEnableDrag(false);
          }}
          data-testid="graph-tile-headerDrag"
        >
          <Icon name="drag-handler" width={24} height={24} />
        </span>

        <span className={`${BLOCK}__header-text-dashboard`}>
          {/* THIS SPAN IS COVERING WHOLE TITLE */}
          <span className={`${BLOCK}__header__text-dashboard`}>
            {/* THIS SPAN IS FOR GETTING TOOLTIP ONLY ON HOVERING OF TITLE NOT BLANK SPACE */}
            <span className={`${BLOCK}__dashboard-text`}>
              {/* THIS SPAN IS FOR EXPORTING ONLY TITLE HEADER */}
              <span id={`chart-tile-title-${index}`}>
                {
                  (metadata.benchmarkTitle
                    ? metadata.benchmarkTitle
                    : editableInput) as React.ReactNode
                }
              </span>
              <Tooltip position={TooltipPosition.bottom}>
                <>
                  <b>{metadata?.benchmarkTitle}</b>
                  <br />
                  <div>
                    {Parser(metadata.benchmarkMetadata?.benchmarkTitleTooltip)}
                  </div>
                </>
              </Tooltip>
            </span>
          </span>
        </span>

        <div className={`${BLOCK}__actions`}>
          {metadata.benchmarkMetadata && (
            <span
              className={`${BLOCK}__action`}
              onClick={handleDownloadDisable}
              ref={exportOptionsRef}
              data-testid="exportButton"
            >
              {" "}
              <Icon name="download" width={24} height={24} />
              <div
                className={classNames(`${BLOCK}__export-options`, {
                  [`${BLOCK}__export-options--show`]: showExportOptions,
                })}
                data-testid="exportOptions"
              >
                {exportOptions.map((exportOption, i) => (
                  <span
                    key={i}
                    onClick={() => downloadHandler(exportOption)}
                    className={classNames(`${BLOCK}__export-option`, {
                      [`${BLOCK}__export-option--active`]:
                        exportOption.type === selectedExportOption,
                    })}
                    data-testid="export-option"
                  >
                    <Icon name={exportOption.icon} width={24} height={24} />
                    <span className={`${BLOCK}__export-option--text`}>
                      {exportOption.text}
                    </span>
                  </span>
                ))}
              </div>
            </span>
          )}
          {metadata.benchmarkMetadata &&
          metadata.benchmarkMetadata.bechmarkStyleOptions &&
          metadata.benchmarkMetadata.bechmarkStyleOptions.length > 1 ? (
            <span
              ref={graphOptionsRef}
              className={`${BLOCK}__action`}
              onClick={handleGraphChangeDisable}
              data-testid="graphOptions"
            >
              <Icon name="graphs" width={24} height={24} />
              <div
                className={classNames(`${BLOCK}__graph-options`, {
                  [`${BLOCK}__graph-options--show`]: showGraphOptions,
                })}
                data-testid="graph-tile-options"
              >
                {graphOptions.map((graphOption, i) =>
                  metadata.benchmarkMetadata.bechmarkStyleOptions.includes(
                    graphOption.type
                  ) ? (
                    <button
                      key={i}
                      onClick={() => {
                        setSelectedGraphOption(graphOption.type);
                        const updateValue = {
                          updateValue: graphOption.type,
                          type: UPDATE_VALUE_TYPE.GRAPH_OPTION,
                        };
                        dispatch(
                          updateVisualizationsMetadata(metadata, updateValue)
                        );
                        dispatch(
                          changeGraphStyleTrack({
                            style: graphOption.type,
                            name: metadata.benchmarkTitle,
                          })
                        );
                      }}
                      className={classNames(`${BLOCK}__graph-option`, {
                        [`${BLOCK}__graph-option--active`]:
                          graphOption.type === selectedGraphOption,
                      })}
                    >
                      <Icon name={graphOption.icon} width={34} height={34} />
                    </button>
                  ) : null
                )}
              </div>
            </span>
          ) : null}
          {custom &&
            metadata.benchmarkMetadata &&
            metadata.benchmarkMetadata.bechmarkStyleOptions &&
            metadata.benchmarkMetadata.bechmarkStyleOptions.length > 1 && (
              <span
                className={`${BLOCK}__action`}
                onClick={editBenchmarkHandler}
                data-testid="editBenchmark-dashboard-action"
              >
                {" "}
                <Icon name="edit-note" width={24} height={24} />
              </span>
            )}

          {isBubbleChart
            ? null
            : metadata.benchmarkType === CHART_TYPES.predefined && (
                <span ref={filterRef} className={`${BLOCK}__action`}>
                  <div onClick={handleFilterDisable} data-testid="filterOption">
                    <Icon name="filter1" width={24} height={24} />
                  </div>

                  <div
                    className={classNames(`${BLOCK}__filter`, {
                      [`${BLOCK}__filter--show`]: showFilter,
                    })}
                  >
                    <Filters
                      setShowFilter={setShowFilter}
                      detailedViewFilter={true}
                      showFilter={showFilter}
                      filterTitle={
                        groupValue
                          ? groupValue
                          : metadata.benchmarkMetadata.filters.length
                          ? metadata.benchmarkMetadata.filters[0].label
                          : "Filter"
                      }
                      filterPlaceholder={
                        groupValue ? `Select ${groupValue}` : "Select Filter"
                      }
                      grouped={isGrouped}
                      filteredData={
                        isGrouped
                          ? (groupedDataset as GroupedLegends[])
                          : (dataset as Legend[])
                      }
                      originalData={
                        isGrouped ? originalGroupedDataset : originalDataset
                      }
                      applyFilter={applyFilter}
                    />
                  </div>
                </span>
              )}
          <button
            onClick={handleFullScreenDisable}
            className={`${BLOCK}__action`}
            data-testid="fullScreenBtn"
          >
            <Icon
              name={showFullScreen ? "decrease" : "increase"}
              width={20}
              height={20}
            />
          </button>
          {metadata.benchmarkId && (
            <span
              className={classNames(
                `${BLOCK}__action ${BLOCK}__action-menu-btn`
              )}
              ref={subMenuRef}
              onClick={() => {
                setShowSubMenu(!showSubMenu);
              }}
              data-testid="graph-action-menubtn"
            >
              <Icon width={24} height={24} name="menu2" />
              {showSubMenu && (
                <div
                  className={classNames(`${BLOCK}__action-menu`, {
                    [`${BLOCK}__action-menu--hide`]: !showSubMenu,
                  })}
                >
                  <button
                    className={`${BLOCK}__action-menu-item`}
                    onClick={() => {
                      duplicateCustomBenchmarkHanler();
                    }}
                    data-testid="duplicateBtn"
                  >
                    <FormattedMessage id="dashboard.benchmark.tile.duplicate" />
                  </button>
                  {!metadata.isDefault && (
                    <button
                      className={`${BLOCK}__action-menu-item ${BLOCK}__action-menu-item--red`}
                      onClick={() => {
                        setShowDeleteCustomBenchmarkModal(true);
                        setShowSubMenu(false);
                      }}
                      data-testid="dashboard-item-delete"
                    >
                      <FormattedMessage id="dashboard.benchmark.tile.remove" />
                    </button>
                  )}
                </div>
              )}
            </span>
          )}
        </div>
      </div>

      {placeholderData.filter((p) => p.selected !== null && p.data.length)
        .length ? (
        <div className={`${BLOCK}__placeholders`} id={`placeholders-${index}`}>
          {placeholderData
            .filter((p) => p.selected !== null && p.data.length)
            .map((p, i) => (
              <Fragment key={`placeholder-${i}`}>
                <Dropdown
                  key={`placeholder-dropdown-${i}`}
                  parentBlock={BLOCK}
                  onChange={(selected: Placeholder) => {
                    const updateValue = {
                      updateValue: selected,
                      type: UPDATE_VALUE_TYPE.FILTER_INDUSTRY,
                    };
                    dispatch(
                      updateVisualizationsMetadata(metadata, updateValue)
                    );
                    setPlaceholderData([
                      ...placeholderData.filter(
                        ({ placeholder }) => placeholder !== p.placeholder
                      ),
                      { ...p, selected: selected },
                    ]);

                    resetFilterToDefault();
                  }}
                  selected={p.selected}
                  data={p.data}
                  keys={{ displayName: p.displayName, id: p.placeholder }}
                />
              </Fragment>
            ))}
        </div>
      ) : null}

      <div
        id={`chart-tile-data-${index}`}
        className={classNames(`${BLOCK}__main-container`, {
          [`${BLOCK}__container--expand-no-data`]:
            dataset?.length === 0 ||
            (selectedGraphOption === GRAPH_OPTIONS.column && !hasData),
        })}
      >
        <div
          data-testid="tile"
          className={classNames(`${BLOCK}__tile-container`, {
            [`${BLOCK}__container--expand`]:
              dataset?.length === 0 ||
              (selectedGraphOption === GRAPH_OPTIONS.column && !hasData),
          })}
        >
          <div
            className={classNames(`${BLOCK}__tile`, {
              [`${BLOCK}-no-data`]:
                dataset?.length === 0 ||
                (selectedGraphOption === GRAPH_OPTIONS.column && !hasData),
            })}
          >
            {groupedDataset && isGrouped && !custom ? (
              <div className={`${BLOCK}__stats`}>
                <div className={`${BLOCK}__stat`}>
                  <Icon name="pie-negative" width={30} height={30} />
                  <div className={`${BLOCK}__stat--data`}>
                    <b>{groupedDataset.length}</b>
                    {groupedDataset.length > 1 ? `${groupValue}s` : groupValue}
                  </div>
                </div>
                <div className={`${BLOCK}__stat`}>
                  <Icon name="office-negative" width={30} height={30} />
                  <div className={`${BLOCK}__stat--data`}>
                    <b>
                      {groupedDataset.reduce(
                        (acc, curr) =>
                          acc +
                          curr.legends.reduce(
                            (acc, curr) =>
                              curr.legendData ? acc + curr.legendData : acc,
                            0
                          ),
                        0
                      )}
                    </b>
                    {benchmarkValue}
                  </div>
                </div>
              </div>
            ) : null}
            {selectedGraphOption !== GRAPH_OPTIONS.column ||
            dataset?.length ||
            (selectedGraphOption === GRAPH_OPTIONS.column && hasData) ? (
              <ParentSize
                className={classNames(`${BLOCK}__content`)}
                debounceTime={100}
              >
                {({ width: visWidth, height: visHeight }) => {
                  const Component = graphOptions.find(
                    ({ type }) => type === selectedGraphOption
                  )?.graph;
                  const componentProps = {
                    name: benchmarkValue,
                    axis: metadata.benchmarkMetadata.axis,
                    width: visWidth,
                    height: visHeight,
                    handleGraphElementClicked: metadata.benchmarkMetadata
                      .actionUrl
                      ? searchGraphElement
                      : null,
                    data:
                      (metadata.benchmarkMetadata.groupProperties &&
                        metadata.benchmarkMetadata.groupProperties.length) ||
                      (metadata.benchmarkMetadata.displayPropertyNames &&
                        metadata.benchmarkMetadata.displayPropertyNames.length >
                          3)
                        ? groupedDataset
                        : dataset,
                    descSort:
                      metadata.benchmarkTitle.toLowerCase().includes(PERIOD) &&
                      metadata.benchmarkMetadata.selectedStyleOption ===
                        GRAPH_OPTIONS.bar,
                  };
                  return dataset && groupedDataset && !loading ? (
                    <Component {...componentProps} />
                  ) : (
                    <div className={`${BLOCK}__loader`} />
                  );
                }}
              </ParentSize>
            ) : (
              <span
                className="speedometer__label"
                style={{ fontWeight: "bold" }}
              >
                <FormattedMessage id="insights.cdp.no-data" />
              </span>
            )}
          </div>
          {loading || !hasData ? null : (
            <DatasetLegend
              dataset={legendData}
              BLOCK={BLOCK}
              groupedData={groupedDataset ?? []}
              selectedGraphOption={selectedGraphOption}
              name={benchmarkValue}
              custom={custom}
              hideLegend={false && selectedGraphOption === GRAPH_OPTIONS.column}
            />
          )}

          {!loading && !metadata.benchmarkMetadata.hideMSCIDataSourceLabel && (
            <span className={`data-source`}>
              {commonsState.appSettings[APP_SETTING.MSCIDataSourceLabel]}
            </span>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {showFullScreen ? (
        <Modal
          show={showFullScreen}
          handleClose={() => {
            setShowFullScreen(false);
          }}
          fullscreen={true}
        >
          {graph}
        </Modal>
      ) : null}

      {graph}

      {showDeleteCustomBenchmarkModal && (
        <DeleteModal
          title="dashboard.benchmark.tile.delete.title"
          message={
            <>
              <FormattedMessage id="insights.delete.subtitle" />
              <span style={{ fontWeight: 600 }}>
                {` "${metadata.benchmarkTitle}"`}
              </span>
              ?
            </>
          }
          handleCloseModal={() => setShowDeleteCustomBenchmarkModal(false)}
          deleteItem={deleteCustomBenchMark}
        />
      )}
      {showDisclarimerModal && (
        <DisclaimerModal
          title={"dashboard.export.disclaimer"}
          namePlaceholder={"dashboard.disclaimer.message"}
          handleCloseModal={() => setShowDisclarimerModal(false)}
          exportDiclaimer={exportHandler}
        />
      )}
    </>
  );
};

export default DashboardTile;
