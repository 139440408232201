import DropDown from "components/search/filters/drop-down";
import Button from "components/shared/button/button";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useEffect, useState } from "react";
import { GroupedLegends, Legend } from "services/dashboard/dashboard.model";

type Props = {
  setShowFilter: (value: boolean) => any;
  showFilter: boolean;
  filterTitle: string;
  filterPlaceholder: string;
  grouped?: boolean;
  originalData: Legend[] | GroupedLegends[];
  filteredData: Legend[] | GroupedLegends[];
  applyFilter: Function;
  resetCompanyFilter?: Function;
  hideDropdown?: boolean;
  detailedViewFilter?: any | null;
  companyFilterData?: any[];
  originalCompanyData?: any[];
  showDetailedViewFilter?: boolean;
};

const Filters = ({
  setShowFilter,
  showFilter,
  filterTitle,
  filterPlaceholder,
  grouped,
  originalData,
  filteredData,
  applyFilter,
  resetCompanyFilter,
  hideDropdown,
  detailedViewFilter,
  companyFilterData = [],
  originalCompanyData,
  showDetailedViewFilter,
}: Props) => {
  const BLOCK = "Filter-tile";
  const [options, setOptions] = useState<Partial<Legend>[]>([]);
  const [values, setValues] = useState<Partial<Legend>[]>([]);

  useEffect(() => {
    if (originalData && originalData.length)
      setOptions(
        grouped
          ? originalData.map((d: any, i) => mapGroupData(d, i))
          : (originalData as Legend[])
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalData]);

  useEffect(() => {
    if (filteredData && filteredData.length)
      setValues(
        grouped
          ? filteredData.map((d: any, i) => mapGroupData(d, i))
          : (filteredData as Legend[])
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  const mapGroupData = (d: GroupedLegends, i: number): Partial<Legend> => ({
    legendOrder: i + 1,
    legendValue: d.groupName,
  });

  useEffect(() => {
    if (!showFilter && filteredData && filteredData.length) resetFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilter]);

  const resetFilter = () =>
    setValues(
      grouped
        ? filteredData.map((d: any, i) => mapGroupData(d, i))
        : (filteredData as Legend[])
    );

  return (
    <div className={`${BLOCK}`} data-testid={`${BLOCK}`}>
      <div className={`${BLOCK}__header`}>
        <h1 className={`${BLOCK}__header--h1--title`}>
          <FormattedMessage id="search.filter" />
        </h1>
        <Button
          className={`${BLOCK}__close`}
          onClick={() => setShowFilter(false)}
          iconName="cross"
          iconHeight={24}
          iconWidth={24}
          dataTest="cross-btn"
        />
      </div>
      <div className={`${BLOCK}__container`}>
        {hideDropdown ? null : (
          <DropDown
            className={`${BLOCK}__drop-down`}
            label={filterTitle}
            placeholder={filterPlaceholder}
            loading={false}
            objectKeys={{
              name: "legendValue",
              id: "legendValue",
              externalId: "legendValue",
            }}
            values={values}
            options={options}
            handleSelect={(v: any) => setValues([...values, v])}
            handleUnselect={(v: any) =>
              setValues(
                values.filter(
                  ({ legendValue }) => legendValue !== v.legendValue
                )
              )
            }
          />
        )}
        {showDetailedViewFilter ? detailedViewFilter : null}
        <div className={`${BLOCK}__options`}>
          <button
            disabled={
              (hideDropdown
                ? true
                : values.length === (filteredData ? filteredData.length : 0)) &&
              !!detailedViewFilter &&
              companyFilterData?.length === originalCompanyData?.length
            }
            className={
              showDetailedViewFilter &&
              (hideDropdown
                ? true
                : values.length === (filteredData ? filteredData.length : 0)) &&
              !!detailedViewFilter &&
              companyFilterData?.length === originalCompanyData?.length
                ? `${BLOCK}__reset-disabled`
                : !showDetailedViewFilter &&
                  (hideDropdown
                    ? true
                    : values.length ===
                      (filteredData ? filteredData.length : 0))
                ? `${BLOCK}__reset-disabled`
                : `${BLOCK}__reset`
            }
            onClick={() => {
              if (resetCompanyFilter) resetCompanyFilter(originalCompanyData);
              resetFilter();
            }}
            data-testid="reset-btn"
          >
            <FormattedMessage id="reset" />
          </button>

          <Button
            className={`button-primary`}
            disabled={
              showDetailedViewFilter
                ? hideDropdown
                  ? (hideDropdown ? true : values.length === 0) &&
                    companyFilterData?.length === 0
                  : (hideDropdown ? true : values.length === 0) ||
                    companyFilterData?.length === 0
                : hideDropdown
                ? true
                : values.length === 0
            }
            onClick={() => {
              setShowFilter(false);
              applyFilter(values.map((v) => v.legendValue));
            }}
            formattedText="apply"
            dataTest="apply-filter-btn"
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
