import { mapTableChart, useTileContext } from "../tile.context";
import CostChart from "components/visualizations/charts/cost.chart";
import TableChart from "components/visualizations/charts/table-chart";
import { InsightMetricData } from "services/insights/insights.model";
import {
  INSIGHT_BENCHMARK_TYPE,
  costChartMetricDataDetailedType,
  costChartPeerIndustryMetricsType,
} from "utils/constants";
const DirectIndirectCost = () => {
  const {
    isTableViewActive,
    dataset,
    metadata,
    metadata: { benchmarkType, isDetailedView },
  } = useTileContext();
  const getCompanyChartData = () => {
    return dataset.length
      ? dataset[0].metrics.map((item: InsightMetricData) => {
          return {
            metricValue: item.metricValue
              ? `$ ${item.metricValue.toLocaleString()}`
              : "*",
            metricName: item.metricName,
            metricKey: item.metricKey,
            metricTooltip: item.metricTooltip,
          };
        })
      : [];
  };
  const restructuredDataforPeerIndustry: costChartPeerIndustryMetricsType[] =
    [];
  const restructuredDataforDetailedPeer: costChartMetricDataDetailedType[] = [];
  for (let i = 0; i < dataset.length - 1; i++) {
    const baseMetrics = dataset[i].metrics;
    const peerIndustryMetrics = dataset[i + 1].metrics;

    for (let j = 0; j < baseMetrics.length; j++) {
      const metric = baseMetrics[j];
      const nextMetric = peerIndustryMetrics[j];

      restructuredDataforPeerIndustry.push({
        metricKey: metric.metricKey,
        metricName: metric.metricName,
        metricValueBase: metric.metricValue
          ? `$ ${metric.metricValue.toLocaleString()}`
          : "*",
        metricValuePeerIndustry: nextMetric.metricValue
          ? `$ ${nextMetric.metricValue.toLocaleString()}`
          : "*",
        baseCompanyName: dataset[i].companyName,
        peerIndustry: dataset[i + 1].companyName,
        metricTooltip: metric.metricTooltip,
      });
    }
  }

  for (let i = 0; i < dataset.length; i++) {
    restructuredDataforDetailedPeer.push({
      metricNameDirect: dataset[i]?.metrics[0]?.metricName,
      metricNameIndirect: dataset[i]?.metrics[1]?.metricName,
      metricValueIndirect: dataset[i]?.metrics[1]?.metricValue
        ? `$ ${dataset[i]?.metrics[1]?.metricValue.toLocaleString()}`
        : "*",
      metricValueDirect: dataset[i]?.metrics[0]?.metricValue
        ? `$ ${dataset[i]?.metrics[0]?.metricValue.toLocaleString()}`
        : "*",
      companyName: dataset[i]?.companyName,
      metricTooltipDirect: dataset[i]?.metrics[0]?.metricTooltip,
      metricTooltipIndirect: dataset[i]?.metrics[1]?.metricTooltip,
    });
  }
  const getPeerChartData = () => {
    return dataset.length
      ? (benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
          isDetailedView === false) ||
        benchmarkType === INSIGHT_BENCHMARK_TYPE.INDUSTRY
        ? restructuredDataforPeerIndustry
        : restructuredDataforDetailedPeer
      : [];
  };
  const tileProps = {
    data:
      benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY
        ? getCompanyChartData()
        : getPeerChartData(),
    currentInsightView: benchmarkType,
    isDetailedView: isDetailedView,
  };
  return isTableViewActive ? (
    <TableChart
      data={mapTableChart(dataset, metadata, false, false, true)}
      currentInsightView={metadata.benchmarkType}
    />
  ) : (
    <CostChart {...tileProps} />
  );
};

export default DirectIndirectCost;
