import { Dispatch } from "redux";
import { createAction } from "../../utils/redux.utils";
import { Download, DownloadModel, DownloadState } from "./download.model";
import * as api from "./download.api";
import { setInsightTileToExport } from "services/insights/insights.service";

export const ACTIONS = {
  DOWNLOAD_SHOW_DOWNLOAD: "ESG/UI/DOWNLOAD_SHOW_DOWNLOAD",
  DOWNLOAD_SET_SHOW_DOWNLOAD: "ESG/UI/DOWNLOAD_SET_SHOW_DOWNLOAD",
  DOWNLOAD_LOADING: "ESG/UI/DOWNLOAD_LOADING",
  DOWNLOAD_LOAD_DOWNLOADS: "ESG/UI/DOWNLOAD_LOAD_DOWNLOADS",
  DOWNLOAD_SET_DOWNLOADS: "ESG/UI/DOWNLOAD_SET_DOWNLOADS",
  DOWNLOAD_GET_BINARY: "ESG/UI/DOWNLOAD_GET_BINARY",
  DOWNLOAD_SET_STATE: "ESG/UI/DOWNLOAD_SET_STATE",
};

export const toggleDownload = createAction(ACTIONS.DOWNLOAD_SHOW_DOWNLOAD);
export const setDownload = createAction(ACTIONS.DOWNLOAD_SET_SHOW_DOWNLOAD);
export const loadDownloads = createAction(ACTIONS.DOWNLOAD_LOAD_DOWNLOADS);
export const setDownloads = createAction(ACTIONS.DOWNLOAD_SET_DOWNLOADS);
export const downloadLoading = createAction(ACTIONS.DOWNLOAD_LOADING);
export const downloadSetState = createAction(ACTIONS.DOWNLOAD_SET_STATE);

export const showDownloadTab =
  (isOpen: boolean) => async (dispatch: Dispatch<any>) => {
    dispatch(toggleDownload(isOpen ? "My Downloads" : false));
  };
export const setShowDownloadTab =
  (isOpen: boolean) => async (dispatch: Dispatch<any>) =>
    dispatch(setDownload(isOpen));

export const getDownloads = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(downloadLoading(true));
    const res: any = await api.getDownloadsList();
    dispatch(setDownloads(res.data));
    dispatch(downloadLoading(false));
  } catch (e) {
    dispatch(downloadLoading(false));
  }
};

export const sendExportItem =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(downloadLoading(true));
      if (payload.chartItem) {
        await api.exportChartItem(payload.payload);
      } else {
        await api.exportItem(payload);
      }
      const res: any = await api.getDownloadsList();
      dispatch(setDownloads(res.data));

      if (!payload.downloadTabOpen) {
        //only call when its not open
        dispatch(toggleDownload("My Downloads"));
      }
      dispatch(setInsightTileToExport(null));
      dispatch(downloadLoading(false));
    } catch (e) {
      dispatch(downloadLoading(false));
    }
  };

export const updateDownloadState =
  (download: Download) => async (dispatch: Dispatch<any>) => {
    dispatch(downloadSetState(download));
  };

const downloadModel = new DownloadModel();

const downloadReducer = (
  state: DownloadState = downloadModel.initialState(),
  action: any
): DownloadState => {
  switch (action.type) {
    case ACTIONS.DOWNLOAD_LOADING:
      return downloadModel.setLoading(action.payload);
    case ACTIONS.DOWNLOAD_SHOW_DOWNLOAD:
      return downloadModel.toggleShowDownloads();
    case ACTIONS.DOWNLOAD_SET_SHOW_DOWNLOAD:
      return downloadModel.setShowDownloads(action.payload);
    case ACTIONS.DOWNLOAD_SET_DOWNLOADS:
      return downloadModel.setDownloads(action.payload);
    case ACTIONS.DOWNLOAD_SET_STATE:
      return downloadModel.setDownloadState(action.payload);
    default:
      return state;
  }
};

export default downloadReducer;
