import { useEffect } from "react";

export enum PAGES {
  search = "search",
  dashboard = "dashboard",
  peerBenchmark = "peerBenchmark",
  insight = "insight",
}

export const getStorageKey = (pageName: PAGES) =>
  `show-whats-new-with-${pageName}-2.2`;

const useWhatsNewModal = () => {
  useEffect(() => {
    Object.values(PAGES).forEach((pageName) => {
      const storedValue = window.localStorage.getItem(getStorageKey(pageName));

      if (storedValue) return;

      window.localStorage.setItem(getStorageKey(pageName), "true");
    });
  }, []);

  const shouldShowWhatsNew = (pageName: PAGES) =>
    window.localStorage.getItem(getStorageKey(pageName)) === "true";
  const setDontShowAgain = (pageName: PAGES) =>
    window.localStorage.setItem(getStorageKey(pageName), "false");
  const setToShowAgain = (pageName: PAGES) =>
    window.localStorage.setItem(getStorageKey(pageName), "true");

  return { shouldShowWhatsNew, setDontShowAgain, setToShowAgain };
};

export default useWhatsNewModal;
