import classNames from "classnames";
import Icon from "components/shared/icon/icon";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { Link, useLocation } from "react-router-dom";
import { MENU_ITEMS } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setShowFilters } from "services/search/filters.service";
import {
  setSelectedDisclosure,
  setSelectedReport,
} from "services/document-viewer/document-viewer.service";
import history from "utils/history";
import { RootStore } from "services/store.service";

type props = {
  BLOCK: string;
  icon: string;
  path: any;
  isMenuOpen: boolean;
  setPathname?: any;
  setShowModal?: any;
  setShowSave?: any;
  sharedSearchCount?: number;
};

const MenuItem = ({
  BLOCK,
  icon,
  path,
  isMenuOpen,
  setPathname,
  setShowModal,
  sharedSearchCount,
}: props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const shortListState = useSelector((state: RootStore) => state.shortlist);
  const label = <FormattedMessage id={`side-menu.menu-item.${icon}`} />;
  const pathname = typeof path === "string" ? path : path.pathname;

  const handleSearchClick = (e: any) => {
    e.preventDefault();
    if (pathname === MENU_ITEMS.search.path) {
      const locationState = location.state as any;
      dispatch(setShowFilters(true));
      dispatch(setSelectedReport(null));
      dispatch(setSelectedDisclosure(null));
      locationState && locationState.shortlistId
        ? history.push(pathname, {
            shortlistId: locationState.shortlistId,
          })
        : history.push(pathname);
    } else if (
      shortListState &&
      shortListState.results &&
      shortListState.results.shortlistItems &&
      shortListState.results.shortlistItems.length &&
      shortListState.results.shortlistName === null
    ) {
      setShowModal(true);
      setPathname(pathname);
    } else {
      dispatch(setSelectedReport(null));
      dispatch(setSelectedDisclosure(null));
      history.push(pathname, {
        shortlistId:
          shortListState.results !== null &&
          shortListState.results.shortlistId !== undefined
            ? shortListState.results.shortlistId
            : 0,
      });
    }
  };

  const isMenuActive = (path: string) => {
    return new RegExp(`^${path}`).test(location.pathname);
  };

  return (
    <Link
      className={classNames(`${BLOCK}__menu-item`, {
        [`${BLOCK}__menu-item--active`]: isMenuActive(
          typeof path === "string" ? path : path.pathname
        ),
      })}
      data-test="menu-item"
      data-testid="menu-item"
      to={path}
      onClick={handleSearchClick}
    >
      {sharedSearchCount ? (
        <span className={`${BLOCK}__menu-item__icon`}>
          <span
            className={`${BLOCK}__menu-item__icon-content`}
            data-test="shared-search-count"
          >
            {isNaN(sharedSearchCount) ? 0 : sharedSearchCount.toString()}
          </span>
        </span>
      ) : (
        ""
      )}
      <Icon name={icon} height={24} />

      <div
        className={classNames(`${BLOCK}__menu-label`, {
          [`${BLOCK}__menu-label--show`]: isMenuOpen,
        })}
      >
        {label}
      </div>
      {!isMenuOpen && (
        <div className={`${BLOCK}__menu-item__tooltip`}>
          <Tooltip position={TooltipPosition.right}>{label}</Tooltip>
        </div>
      )}
    </Link>
  );
};

export default MenuItem;
