import { Fragment, useEffect, useState } from "react";
import MainTile from "components/insight-tile/main-tile";
import { flattenInsightCompany } from "components/insight-tile/tile.context";
import { isNumber } from "lodash";
import {
  InsightCategory,
  InsightsDashboard,
  InsightCategorySections,
  InsightCategorySectionGroups,
  InsightSectionMappings,
  insightUserPreferences,
} from "services/insights/insights.model";
import Icon from "components/shared/icon/icon";
import { DashboardFunctions, DashboardVariables } from "./insight-dashboard";
import { Visualization } from "services/dashboard/dashboard.model";
import Button from "components/shared/button/button";
import classNames from "classnames";
import {
  COLLAPSABLE_SUBSECTION_SIZES,
  INSIGHT_BENCHMARK_TYPE,
  WORKFORCE_SUBSECTION_TITLE,
  WORKFORCE_TILE_GROUPNAME,
} from "utils/constants";
import { getSectionId } from "utils/functions";

const minTileSize = 23;

type SubSectionsType = {
  sectionGroupId: number;
  title: string;
  size: number;
  tiles: JSX.Element[];
};

type Props = {
  categoryData: {
    sections: InsightCategorySections[];
    sectionGroups: InsightCategorySectionGroups[];
    sectionMappings: InsightSectionMappings[];
  };
  currentDashboard: InsightsDashboard;
  dashboardVariables: DashboardVariables;
  dashboardFunctions: DashboardFunctions;
  filterExpression: (vis: Visualization, category: InsightCategory) => boolean;
  handleCollapse: (isCollapsed: boolean, section: string) => void;
  insightUserPreferences: insightUserPreferences;
};

const InsightDashboardCollapsableLayout = ({
  categoryData,
  currentDashboard,
  dashboardVariables,
  dashboardFunctions,
  filterExpression,
  handleCollapse,
  insightUserPreferences,
}: Props) => {
  let visIndex = 0;
  const BLOCK = "collapsable-layout";
  const [response, setResponse] = useState<any>({});

  const Tiles = Object.assign(
    {},
    ...currentDashboard.insightsCategories?.flatMap((category) =>
      category.benchmarks
        .filter((vis) => filterExpression(vis, category))
        .map((vis) => {
          const tile = (
            <MainTile
              key={`dashboard-tile-${vis.benchmarkId}`}
              isESRS={true}
              index={visIndex}
              metadata={vis}
              currentTabId={dashboardVariables.currentTabId}
              dataPayload={{
                ...(vis.benchmarkMetadata.api.payload ?? {}),
                ...(currentDashboard
                  ? {
                      selectedReportedYear:
                        currentDashboard.selectedReportedYear,
                      selectedQuarter: currentDashboard.selectedQuarter,
                    }
                  : {}),
              }}
              companies={currentDashboard.insightsCompanies.map(
                flattenInsightCompany
              )}
              dataResponse={response}
              setDataResponse={setResponse}
              toggleClicked={dashboardVariables.toggleClicked}
              setToggleClicked={dashboardFunctions.setToggleClicked}
              selectedExport={dashboardVariables.selectedExport}
              toggleDetailView={() =>
                dashboardFunctions.toggleDetailView(
                  vis,
                  category.benchmarks.find(
                    (b) =>
                      b.benchmarkTileType === vis.benchmarkTileType &&
                      b.isDetailedView
                  )!.benchmarkId
                )
              }
              selectedInsightView={dashboardVariables.selectedView}
              setTableViewSelected={dashboardFunctions.setTableViewSelected}
              exportAllLoading={
                isNumber(dashboardVariables.exportAllLoading)
                  ? dashboardVariables.exportAllLoading === visIndex + 1
                  : dashboardVariables.exportAllLoading
              }
              globalCompanyMappings={currentDashboard.globalCompanyMappings}
            />
          );
          visIndex++;
          return {
            key: vis.benchmarkId,
            value: (
              <Fragment key={`insights-tile-${vis.benchmarkId}`}>
                {tile}
              </Fragment>
            ),
          };
        })
        .map((item) => ({ [item.key]: item.value }))
    )
  );

  const getSectionIcon = (sectionId: number) => {
    if (sectionId === 1) return "environmental-icon";
    if (sectionId === 2) return "social-icon";
    if (sectionId === 3) return "governance-icon";

    return "";
  };

  const getSubsections = (sectionId: number) =>
    categoryData.sectionMappings
      .filter((mappings) => mappings.sectionId === sectionId)
      .sort(
        (m1, m2) => m2.sectionGroupDisplayOrder - m1.sectionGroupDisplayOrder
      )
      .reduce((acc: SubSectionsType[], curr: InsightSectionMappings) => {
        const sectionGroup = categoryData.sectionGroups.find(
          (sg) => sg.sectionGroupId === curr.sectionGroupId
        );

        if (!sectionGroup) return acc;

        if (acc.find((sg) => sg.sectionGroupId === curr.sectionGroupId))
          return acc.map((sg) =>
            sg.sectionGroupId === curr.sectionGroupId
              ? { ...sg, tiles: [...sg.tiles, Tiles[curr.benchmarkId]] }
              : sg
          );

        return [
          {
            sectionGroupId: sectionGroup.sectionGroupId,
            title: sectionGroup.sectionGroupName,
            size: COLLAPSABLE_SUBSECTION_SIZES[sectionGroup.sectionGroupWidth],
            tiles: [Tiles[curr.benchmarkId]],
          },
          ...acc,
        ];
      }, []);

  const sections = categoryData.sections.map((s) => ({
    title: s.sectionName,
    color: s.sectionId,
    icon: getSectionIcon(s.sectionId),
    subSections: getSubsections(s.sectionId),
  }));

  return (
    <div className={`${BLOCK}__parent`} data-testid={`${BLOCK}__parent`}>
      {sections.map((section, index) => (
        <CollapsableSection
          key={`section-${index}`}
          sectionTitle={section.title}
          color={section.color}
          icon={section.icon}
          subSections={section.subSections}
          BLOCK={BLOCK}
          handleCollapseAction={handleCollapse}
          insightUserPreferences={insightUserPreferences}
          currentTabId={dashboardVariables.currentTabId}
        />
      ))}
    </div>
  );
};

const CollapsableSection = ({
  BLOCK,
  sectionTitle,
  color,
  icon,
  subSections,
  handleCollapseAction,
  insightUserPreferences,
  currentTabId,
}: {
  BLOCK: string;
  sectionTitle: string;
  color: number;
  icon: string;
  subSections: SubSectionsType[];
  handleCollapseAction: (isCollapsed: boolean, section: string) => void;
  insightUserPreferences: insightUserPreferences;
  currentTabId: number;
}) => {
  const [collapsed, setCollapse] = useState<boolean>(false);
  const SectionTitleComponent = (
    <span
      className={classNames(
        `${BLOCK}__section-title`,
        `${BLOCK}__section-title--color-${color}`,
        { [`${BLOCK}__section-title--collapsed`]: collapsed }
      )}
    >
      <Icon name={icon} width={24} height={24} />
      {sectionTitle}
    </span>
  );

  //set collapse state based on user preferences
  useEffect(() => {
    const isExpanded =
      insightUserPreferences?.expandedSectionIds === undefined
        ? true
        : insightUserPreferences?.expandedSectionIds?.includes(
            getSectionId(sectionTitle)
          );
    isExpanded ? setCollapse(false) : setCollapse(true);
  }, [insightUserPreferences]);

  //Handle collapse action
  const handleCollapse = (c: any) => {
    setCollapse((c) => !c);
    handleCollapseAction(!collapsed, sectionTitle);
  };

  const isTileExist = (fieldGroupName: string, tiles: any) => {
    if (Array.isArray(tiles) && tiles.length > 0) {
      var tile = tiles.filter(
        (x) =>
          x?.props?.children?.props?.dataPayload?.fieldGroupName ==
          fieldGroupName
      );
      return tile?.length > 0 ? true : false;
    }
    return false;
  };

  const getGridTemplateColumns = (subsection: any) => {
    if (
      subsection.title === WORKFORCE_SUBSECTION_TITLE &&
      currentTabId !== INSIGHT_BENCHMARK_TYPE.COMPANY &&
      !isTileExist(WORKFORCE_TILE_GROUPNAME, subsection.tiles)
    ) {
      return `repeat(auto-fill, minmax(32%, 1fr))`;
    } else {
      return `repeat(auto-fill, minmax(${
        (12 / subsection.size) * minTileSize
      }%, 1fr))`;
    }
  };

  return (
    <div className={BLOCK}>
      <div className={`${BLOCK}__section-collapse`}>
        <Button
          onClick={(c: any) => handleCollapse(c)}
          className={classNames(
            `${BLOCK}__collapse-icon ${BLOCK}__collapse-icon--color-${color}`,
            {
              [`${BLOCK}__collapse-icon--collapsed`]: collapsed,
            }
          )}
          iconName="chevron-down"
          iconWidth={25}
          iconHeight={25}
          dataTest="section-collapse-button"
        />
        {!collapsed && SectionTitleComponent}
      </div>
      {collapsed && SectionTitleComponent}
      <div
        className={classNames(`${BLOCK}__content`, {
          [`${BLOCK}__content--collapsed`]: collapsed,
        })}
      >
        {subSections.map((subsection, i) => (
          <div
            key={`subsection-${i}`}
            className={`${BLOCK}__subsection`}
            style={{ gridColumnEnd: `span ${subsection.size}` }}
          >
            <span className={`${BLOCK}__subsection-title`}>
              {subsection.title}
            </span>
            <div
              className={`${BLOCK}__subsection-content`}
              style={{
                gridTemplateColumns: getGridTemplateColumns(subsection),
              }}
            >
              {subsection.tiles}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InsightDashboardCollapsableLayout;
