import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Parser from "html-react-parser";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";

type props = {
  data: InsightData[];
  isDetailedView?: boolean;
  isTableViewActive?: boolean;
  benchmarkType?: number;
};
const WaterIntensityChart = ({
  data,
  isDetailedView,
  isTableViewActive,
  benchmarkType,
}: props) => {
  const BLOCK = "waterIntensityChart";

  const isCompanyView = benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY;
  const emptyValues = data.some((d: InsightData) =>
    d.metrics.some((m: InsightMetricData) => m.metricValue === "*")
  );

  const hasLargeValues = data.some(
    (d: InsightData) =>
      d.metrics.some((m: InsightMetricData) => m.metricValue.length === 6) &&
      !isCompanyView
  );

  const hasXLargeValues = data.some(
    (d: InsightData) =>
      d.metrics.some((m: InsightMetricData) => m.metricValue.length > 6) &&
      !isCompanyView
  );

  return (
    <div className={`${BLOCK}__wrapper`}>
      <div
        className={classNames(`${BLOCK}__container`, {})}
        data-testid="water-intensity-container"
      >
        {data.map((d: InsightData) => {
          return d.metrics.map((m: InsightMetricData) => {
            return (
              <div
                key={m.metricKey}
                className={classNames(`${BLOCK}__company-row`, {
                  [`${BLOCK}__company-row--space-between`]: !isCompanyView,
                  [`${BLOCK}__company-row--grid-content`]: !isCompanyView,
                })}
              >
                {isCompanyView ? null : (
                  <span className={`${BLOCK}__company-name`}>
                    {d.companyName}
                  </span>
                )}
                <span
                  className={classNames(`${BLOCK}__value`, {
                    [`${BLOCK}__value--small2`]:
                      (m.metricValue.length > 7 && isCompanyView) ||
                      hasLargeValues,
                    [`${BLOCK}__value--small3`]: hasXLargeValues,
                    [`${BLOCK}__value--black`]: isTableViewActive,
                    [`${BLOCK}__value--number-2`]: !isCompanyView,
                    [`${BLOCK}__value--sm-fz`]: !isCompanyView,
                    [`${BLOCK}__value--number-1-5`]: isDetailedView,
                  })}
                >
                  {!isNaN(m.metricValue)
                    ? new Intl.NumberFormat().format(m.metricValue)
                    : m.metricValue}

                  {!isNaN(m.metricValue) && m.metricUnit && (
                    <span className={`${classNames(`${BLOCK}__unit`)}`}>
                      {Parser(m.metricUnit.toString())}
                    </span>
                  )}
                </span>
              </div>
            );
          });
        })}
      </div>
      {emptyValues ? (
        <span className={`${BLOCK}__empty-values`}>
          <FormattedMessage id="no.data.available" />
        </span>
      ) : null}
    </div>
  );
};

export default WaterIntensityChart;
