import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Icon from "../shared/icon/icon";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import Tooltip, { TooltipPosition } from "../shared/tooltip/tooltip";
import Parser from "html-react-parser";

type ObjectKeys = {
  name: string;
  id: string;
};

type Props = {
  className: string;
  label?: string | object;
  showLabel?: boolean;
  placeholder: string | object;
  loading: boolean;
  options: any[];
  values: any;
  handleChange?: any;
  handleSelect?: any;
  handleUnselect?: any;
  objectKeys: ObjectKeys;
  textValue?: string;
  displayFunction?: any;
  handleOnEnter?: any;
  totalCount?: number;
  newTopicHandler: (value: boolean) => void;
};

const DropDown = ({
  className,
  label,
  showLabel = true,
  placeholder,
  loading,
  options,
  values,
  handleChange,
  handleSelect,
  newTopicHandler,
  handleUnselect,
  objectKeys,
  textValue,
  handleOnEnter,
  totalCount,
}: Props) => {
  const [showOptions, toggleOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(
    options && options.length ? options : []
  );
  const [inputFilter, setInputFilter] = useState("");
  const wrapperRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const outlineRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const inputSizeRef = useRef<HTMLDivElement>(null);
  const BLOCK = "dropdown";

  const handleLocalSelect = (option: any) => {
    handleSelect(option);
    setInputFilter(option[objectKeys.name]);
    toggleOptions(!showOptions);
  };
  // const handleNewTopic = (option: any) => {
  //   handleSelect(option);
  //   setInputFilter(option[objectKeys.name]);
  //   toggleOptions(!showOptions);
  // };

  useEffect(() => {
    setFilteredOptions(options && options.length ? options : []);
  }, [options]);

  useEffect(() => {
    setInputFilter(textValue ? textValue : "");
  }, [textValue]);

  useEffect(() => {
    if (!loading && filteredOptions.length === 0) {
      toggleOptions(false);
    }

    // if (!loading && inputFilter.trim().length > 1) {
    //   toggleOptions(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, filteredOptions.length]);

  useEffect(() => {
    if (inputFilter === "") {
      toggleOptions(false);
    }
  }, [inputFilter]);

  useEffect(() => {
    const handleClick = (event: any) => {
      return showOptions
        ? toggleOptions(
            (outlineRef.current !== null &&
              outlineRef.current.contains(event.target)) ||
              (optionsRef.current != null &&
                optionsRef.current.contains(event.target))
          )
        : null;
    };

    document.addEventListener("mousedown", handleClick);
    document.addEventListener("touchstart", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlineRef, options, inputFilter, showOptions]);

  const highlightOption = (option: string) => {
    const newOption = option.length > 40 ? option.slice(0, 40) + "..." : option;
    if (inputFilter !== "" && newOption) {
      if (option === inputFilter) {
        return `<b>${newOption}</b>`;
      } else return newOption;
    }

    return newOption;
  };

  const getOption = (
    option: any,
    optionKeys: ObjectKeys,
    parentId?: number
  ) => (
    <button
      className={classNames(`${BLOCK}__option`)}
      key={`${optionKeys.id}-option-${option[optionKeys.id]}`}
      onClick={() => handleLocalSelect({ ...option, parentId })}
      data-testid="select-option-button"
    >
      <div className={`${BLOCK}__option-container`}>
        <>
          <span className={`${BLOCK}__option-value`}>
            {Parser(highlightOption(`${option[optionKeys.name]}`).toString())}
          </span>
          <span>
            {option[optionKeys.name] && option[optionKeys.name].length > 40 ? (
              <Tooltip position={TooltipPosition.right}>
                {option[optionKeys.name]}
              </Tooltip>
            ) : (
              ""
            )}
          </span>
        </>
      </div>
    </button>
  );

  return (
    <div
      ref={wrapperRef}
      id={`${BLOCK}-${objectKeys.id}`}
      className={`${BLOCK} ${className}`}
      data-testid="searchlist-dropdown"
    >
      <div className={`${BLOCK}__label`} data-testid="drop-down">
        {showLabel && label ? <>{label as React.ReactNode}</> : null}
      </div>
      <div ref={outlineRef} className={`${BLOCK}__outline`}>
        <textarea
          ref={textareaRef}
          value={inputFilter}
          readOnly={true}
          className={`${BLOCK}__input`}
          data-test="input-text-area"
          data-testid="input-text-area-dropdown-share"
        />

        <div ref={inputSizeRef} className={`${BLOCK}__input-size`}>
          {inputFilter}
        </div>
        {inputFilter === "" && (
          <div className={`${BLOCK}__placeholder`}>
            {placeholder as React.ReactNode}
          </div>
        )}
        <button
          className={classNames(`${BLOCK}__chevron-button`, {
            [`${BLOCK}__chevron-button--not-empty`]: values && values.length,
          })}
          data-testid="chevron-button"
          onClick={() => toggleOptions(!showOptions)}
        >
          <Icon
            className={classNames(`${BLOCK}__chevron-icon`, {
              [`${BLOCK}__chevron-icon--open`]: showOptions,
              [`${BLOCK}__chevron-icon--close`]: !showOptions,
            })}
            name="chevron-down"
            height={24}
          />
        </button>
      </div>
      <div
        ref={optionsRef}
        className={classNames(`${BLOCK}__options`, {
          [`${BLOCK}__options--show`]: showOptions,
          [`${BLOCK}__options--hide`]: !showOptions,
        })}
        data-testid="drop-down-options"
      >
        <div>
          {!loading && filteredOptions.length
            ? filteredOptions.map((option: any) =>
                getOption(option, objectKeys)
              )
            : []}
          <button
            className={`${BLOCK}__compare-button`}
            onClick={() => newTopicHandler(true)}
            data-testid="create-new-topic-btn"
          >
            <div className={`${BLOCK}__compare-button-new`}>
              <Icon name="plus" width={15} height={15} />
              <span>
                <FormattedMessage id="create.new.topic" />
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DropDown;
