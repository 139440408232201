import { Fragment, useMemo } from "react";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import AxisLeft from "@visx/axis/lib/axis/AxisLeft";
import { GridRows } from "@visx/grid";
import { scaleBand, scaleLinear } from "@visx/scale";
import { withTooltip, defaultStyles } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";

import ScaleSVG from "@visx/responsive/lib/components/ScaleSVG";
import {
  lightColor,
  subTextColor,
  textColor,
  MAX_WIDTH_PERCENTAGE,
  CUSTOM_SPACING_PERCENTAGE,
  MAX_WIDTH_STACKED_BAR_PERCENTAGE,
  CUSTOM_SPACING_STACKED_BAR__PERCENTAGE,
} from "utils/constants";
import {
  AssociatedMetricTooltips,
  GroupedLegends,
  Legend,
} from "services/dashboard/dashboard.model";
import { isInteger } from "lodash";
import { Text } from "@visx/text";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import { getMarginBasedOnMax, getTooltipData } from "utils/functions";

const axisBaseOffset = -0.25;
const axisOffsetMultiplier = -0.45;

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 50,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
  fontSize: 15,
  zIndex: 1,
};

type Props = {
  data: Legend[] | GroupedLegends[];
  name: string;
  width?: number;
  height?: number;
  axis?: { primary: string[]; secondary: string[] };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  showSpaceWhenEmpty?: boolean;
  benchmarkTileType?: number;
};

type GraphProps = {
  data: Legend[] | GroupedLegends[];
  name: string;
  width: number;
  height: number;
  axis?: { primary: string[]; secondary: string[] };
  margin?: { top: number; right: number; bottom: number; left: number };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  showSpaceWhenEmpty?: boolean;
  benchmarkTileType?: number;
};

const UngoupedBarChart = ({
  data,
  axis,
  name,
  width,
  height,
  margin = { top: 5, right: 15, bottom: 5, left: 40 },
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
  handleGraphElementClicked,
  benchmarkTileType,
}: GraphProps & WithTooltipProvidedProps<Legend>) => {
  const dataset = (data as Legend[]).filter((d) => d.legendData > 0);
  let tooltipTimeout: number;
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const yMaxLegend = axis?.primary.length
    ? axis?.primary.length
    : Math.max(...dataset.map((d) => d.legendData ?? 0));

  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: dataset.map((d) => d.legendValue),
        padding: 0.3,
      }),
    [xMax, dataset]
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        nice: true,
        clamp: true,
        domain: [0, yMaxLegend],
      }),
    [yMax, yMaxLegend]
  );

  return (
    <div className="limit-size" style={{ position: "relative" }}>
      <ScaleSVG width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <GridRows
            scale={yScale}
            width={xMax}
            height={yMax}
            stroke={lightColor}
          />
          <AxisLeft
            strokeWidth={1}
            scale={yScale}
            stroke={lightColor}
            tickStroke={lightColor}
            tickValues={yScale.ticks().filter(isInteger)}
            tickFormat={(tick: any) =>
              axis?.primary.length && tick <= axis.primary.length
                ? axis.primary[tick - 1]
                : tick.toFixed()
            }
            tickComponent={(tickProps) => {
              const valueLength = tickProps.formattedValue
                ? tickProps.formattedValue.length
                : 1;

              const dx = axisBaseOffset + valueLength * axisOffsetMultiplier;

              return (
                <text
                  fontFamily="Open Sans"
                  fontSize={12}
                  textAnchor="start"
                  dx={`${dx}em`}
                  dy={tickProps.dy}
                  x={tickProps.x}
                  y={tickProps.y}
                >
                  <tspan x={tickProps.x} dy="0.4em">
                    {tickProps.formattedValue}
                  </tspan>
                </text>
              );
            }}
          />
          {dataset
            .sort((d1, d2) => d1.legendOrder - d2.legendOrder)
            .map((data, i) => {
              let barWidth = xScale.bandwidth();
              const barHeight = yMax - (yScale(data.legendData ?? 0.1) ?? 0);
              let barX = xScale(data.legendValue) || 0;
              const barY = yMax - barHeight;
              let maxWidth = barWidth;
              if (
                (barWidth * 100) / xMax > MAX_WIDTH_PERCENTAGE &&
                data.legendValue &&
                !benchmarkTileType
              ) {
                let bars = dataset.filter((d: any) => data.legendValue);
                maxWidth = xMax * (MAX_WIDTH_PERCENTAGE / 100);
                let spacing = xMax * (CUSTOM_SPACING_PERCENTAGE / 100);
                let spacingQuantity = bars.length - 1;
                let totalWidth =
                  spacing * spacingQuantity + maxWidth * bars.length;

                if (totalWidth < xMax - xScale.padding() * 2) {
                  let startingPoint =
                    xScale.padding() + (xMax - totalWidth) / 2;
                  barX = startingPoint + i * (maxWidth + spacing);
                } else maxWidth = barWidth;
              }

              return barX >= 0 &&
                barY >= 0 &&
                maxWidth >= 0 &&
                barHeight >= 0 ? (
                <Bar
                  key={`bar-${data.legendValue}-${i}`}
                  x={barX}
                  y={barY}
                  width={maxWidth}
                  height={barHeight}
                  style={handleGraphElementClicked ? { cursor: "pointer" } : {}}
                  fillOpacity={
                    tooltipOpen && tooltipData?.legendValue !== data.legendValue
                      ? 0.4
                      : 1
                  }
                  fill={data.legendColor}
                  onMouseLeave={() =>
                    (tooltipTimeout = window.setTimeout(() => {
                      hideTooltip();
                    }, 100))
                  }
                  onClick={() =>
                    handleGraphElementClicked
                      ? handleGraphElementClicked(data)
                      : null
                  }
                  onMouseMove={() => {
                    if (tooltipTimeout) clearTimeout(tooltipTimeout);
                    showTooltip({
                      tooltipData: data,
                      tooltipTop: barY,
                      tooltipLeft: barX,
                    });
                  }}
                ></Bar>
              ) : null;
            })}
        </Group>
      </ScaleSVG>
      {tooltipOpen && tooltipData && (
        <div
          style={{
            ...tooltipStyles,
            top: tooltipTop,
            left: tooltipLeft,
            position: "absolute",
          }}
        >
          <strong>
            {
              (tooltipData.legendTooltip
                ? tooltipData.legendTooltip
                : tooltipData.legendValue) as React.ReactNode
            }
          </strong>{" "}
          ({getTooltipData(axis, tooltipData)})
        </div>
      )}
    </div>
  );
};

const StackedBarChart = ({
  data,
  name,
  width,
  height,
  margin = { top: 15, right: 15, bottom: 85, left: 55 },
  showSpaceWhenEmpty = false,
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
  benchmarkTileType,
}: GraphProps &
  WithTooltipProvidedProps<{
    data: Legend | string | AssociatedMetricTooltips;
    index: number;
  }>) => {
  const dataset = data as GroupedLegends[];

  const datasetTotals = dataset.map((group) => ({
    total: group.separateBars
      ? group.legends.reduce((acc, curr) => Math.max(acc, curr.legendData), 0)
      : group.legends.reduce((acc, curr) => acc + curr.legendData, 0),
    groupName: group.groupName,
  }));
  const metricLegendPopover: {
    metric: string;
    tooltip: AssociatedMetricTooltips[];
    style: any;
  }[] = [];

  let tooltipTimeout: number;
  margin.left = getMarginBasedOnMax(
    datasetTotals.reduce((acc, curr) => Math.max(acc, curr.total), 0)
  );
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: dataset.map((d) => d.groupName),
        padding: 0.3,
      }),
    [xMax, dataset]
  );

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        nice: true,
        clamp: true,
        domain: [0, Math.max(...datasetTotals.map((d) => d.total ?? 0))],
      }),
    [yMax, datasetTotals]
  );

  if (
    dataset.every((group) =>
      group.legends.every((data: any) => data.legendData === null)
    )
  )
    return (
      <div className="speedometer__no-data speedometer__no-data--multiple-bar-chart">
        <FormattedMessage id="insights.speedometer.no-data" />
      </div>
    );

  return (
    <div className="limit-size" style={{ position: "relative" }}>
      <ScaleSVG width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <GridRows
            scale={yScale}
            width={xMax}
            height={yMax}
            stroke={lightColor}
          />
          <AxisLeft
            strokeWidth={1}
            scale={yScale}
            stroke={lightColor}
            tickStroke={lightColor}
            tickValues={yScale
              .ticks()
              .filter(isInteger)
              .filter((v) => v > 0)}
            tickFormat={(tick: any) =>
              parseInt(tick.toFixed()).toLocaleString()
            }
            tickComponent={(tickProps) => {
              const valueLength = tickProps.formattedValue
                ? tickProps.formattedValue.length
                : 1;
              const dx = axisBaseOffset + valueLength * axisOffsetMultiplier;

              return (
                <text
                  fontFamily="Open Sans"
                  fontSize={12}
                  textAnchor="start"
                  dx={`${dx}em`}
                  dy={tickProps.dy}
                  x={tickProps.x}
                  y={tickProps.y}
                >
                  <tspan x={tickProps.x} dy="0.4em">
                    {tickProps.formattedValue}
                  </tspan>
                </text>
              );
            }}
          />
          {dataset.map((group, i) => {
            const total: any = datasetTotals.find(
              ({ groupName }) => groupName === group.groupName
            );
            const sectionWidth = (xMax - xScale.bandwidth()) / dataset.length;
            const sectionSpacing = xMax / dataset.length - sectionWidth;
            let sectionXpos =
              i * (sectionSpacing + sectionWidth) + sectionSpacing / 2;

            const separateBarWidth = sectionWidth / (group.legends.length + 1);
            const separateBarSpacing = separateBarWidth / group.legends.length;
            let offsetY = -1;
            let offsetJ = -1;

            let maxWidth = sectionWidth;

            if (
              (sectionWidth * 100) / xMax > MAX_WIDTH_STACKED_BAR_PERCENTAGE &&
              !benchmarkTileType &&
              !group.separateBars
            ) {
              maxWidth = xMax * (MAX_WIDTH_STACKED_BAR_PERCENTAGE / 100);
              let spacing =
                xMax * (CUSTOM_SPACING_STACKED_BAR__PERCENTAGE / 100);
              let spacingQuantity = dataset.length - 1;
              let totalWidth =
                spacing * spacingQuantity + maxWidth * dataset.length;

              if (totalWidth < xMax - xScale.padding() * 2) {
                let startingPoint = xScale.padding() + (xMax - totalWidth) / 2;
                sectionXpos = startingPoint + i * (maxWidth + spacing);
              } else maxWidth = sectionWidth;
            }

            if (Array.isArray(group.description))
              metricLegendPopover.push({
                metric: group.groupName,
                tooltip: group.description,
                style: {
                  fontSize: Math.min(maxWidth / 6, 14),
                  width: sectionWidth + 8,
                  left: sectionXpos + margin.left - 4,
                  top: yMax + 5 + margin.top,
                },
              });

            return (
              <Fragment key={i}>
                {group.legends
                  .sort((d1, d2) => d1.legendOrder - d2.legendOrder)
                  .map((data, j) => {
                    let barX = sectionXpos;
                    let barWidth = maxWidth;
                    if (group.separateBars) {
                      barWidth = separateBarWidth;
                      offsetJ +=
                        parseFloat(data.legendData) > 0 || showSpaceWhenEmpty
                          ? 1
                          : 0;
                      barX =
                        sectionXpos +
                        offsetJ * (barWidth + separateBarSpacing) +
                        separateBarSpacing / 2;
                      offsetY = 0;
                    }

                    let barHeight: number =
                      data.legendData > 0 && data.legendData !== null
                        ? yMax - (yScale(data.legendData ?? 0.1) ?? 0)
                        : data.legendData === null
                        ? 0
                        : yMax / 100;
                    barHeight =
                      barHeight <= 3 && parseFloat(data.legendData) > 0
                        ? 3
                        : barHeight;
                    const barY = yMax - barHeight - offsetY;
                    offsetY += barHeight;
                    return barX >= 0 &&
                      barY >= 0 &&
                      barWidth >= 0 &&
                      barHeight >= 0 ? (
                      <Fragment key={`bar-${data.legendValue}-${i * 100}`}>
                        {/* An invisible bar to extend the tooltip area so that it is easier to hover over the bar */}
                        {barHeight <= 5 && data.legendData && (
                          <Bar
                            key={`bar-${data.legendValue}-${i * 100 + j}`}
                            x={barX}
                            y={barY - 20} // extend the bar 20px upwards
                            width={barWidth}
                            height={barHeight + 20} // increase the height by 20px
                            fillOpacity={0} // make the bar invisible
                            onMouseLeave={() =>
                              (tooltipTimeout = window.setTimeout(() => {
                                hideTooltip();
                              }, 100))
                            }
                            onMouseMove={() => {
                              if (tooltipTimeout) clearTimeout(tooltipTimeout);
                              showTooltip({
                                tooltipData: {
                                  data,
                                  index: i * 10000 + j,
                                },
                                tooltipTop: barY - 12,
                                tooltipLeft: barX + barWidth / 2,
                              });
                            }}
                          />
                        )}
                        <Bar
                          key={`bar-${data.legendValue}-${i * 10000 + j}`}
                          x={barX}
                          y={barY}
                          width={barWidth}
                          height={barHeight}
                          fillOpacity={
                            tooltipOpen && tooltipData?.index !== i * 10000 + j
                              ? 0.4
                              : 1
                          }
                          fill={data.legendColor}
                          onMouseLeave={() =>
                            (tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 100))
                          }
                          onMouseMove={() => {
                            if (tooltipTimeout) clearTimeout(tooltipTimeout);
                            showTooltip({
                              tooltipData: {
                                data,
                                index: i * 10000 + j,
                              },
                              tooltipTop: barY - 12,
                              tooltipLeft: barX + barWidth / 2,
                            });
                          }}
                        />
                      </Fragment>
                    ) : null;
                  })}
                {metricLegendPopover.length === 0 ? (
                  <Fragment>
                    <Text
                      textAnchor="middle"
                      verticalAnchor="start"
                      fill={
                        tooltipData &&
                        (tooltipData?.index < i * 10000 ||
                          tooltipData?.index >= (i + 1) * 10000)
                          ? subTextColor
                          : textColor
                      }
                      fontSize={Math.min(maxWidth / 6, 14)}
                      letterSpacing={-0.5}
                      width={maxWidth}
                      dx={sectionXpos + maxWidth / 2}
                      dy={yMax + 10}
                      fontWeight="400"
                      onMouseLeave={() =>
                        (tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 100))
                      }
                      onMouseOver={() => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        if (group.description) {
                          showTooltip({
                            tooltipData: {
                              data: group.description as string,
                              index: i * 10000 + 1,
                            },
                            tooltipTop: yMax - 180,
                            tooltipLeft: sectionXpos - 65 + sectionWidth / 2,
                          });
                        }
                      }}
                    >
                      {group.separateBars ? group.groupName : i + 1}
                    </Text>
                    <Text
                      textAnchor="middle"
                      verticalAnchor="middle"
                      fill={textColor}
                      fontSize={14}
                      dx={sectionXpos + maxWidth / 2}
                      dy={yMax - offsetY - 8}
                      fontWeight="600"
                    >
                      {group.separateBars ? null : total.total}
                    </Text>
                  </Fragment>
                ) : null}
                {dataset && dataset[0].separateBars ? (
                  <Bar
                    x={sectionXpos + maxWidth + sectionSpacing / 2}
                    y={0}
                    width={1}
                    fill={lightColor}
                    height={yMax}
                  />
                ) : null}
              </Fragment>
            );
          })}
        </Group>
      </ScaleSVG>
      {tooltipOpen &&
        tooltipData &&
        ((tooltipData.data as Legend).legendTooltip ||
        (tooltipData.data as Legend).legendValue ? (
          <div
            style={{
              ...tooltipStyles,
              top: tooltipTop,
              left: tooltipLeft,
              position: "absolute",
            }}
          >
            <strong>
              {
                ((tooltipData.data as Legend).legendTooltip
                  ? (tooltipData.data as Legend).legendTooltip
                  : (tooltipData.data as Legend).legendValue) as React.ReactNode
              }
            </strong>{" "}
            {(tooltipData.data as Legend).legendHideData
              ? null
              : `(${(
                  (tooltipData.data as Legend).legendData ?? 0
                ).toLocaleString()})`}
          </div>
        ) : null)}

      {/* Grouped legends using the popover component */}
      {metricLegendPopover.map((metric, index) => (
        <div
          className={`bar-chart-metric-label`}
          style={metric.style}
          key={`metric-label-${index}`}
        >
          <Popover
            displayText={metric.metric}
            content={metric.tooltip}
            metricClassName=""
            buttonClassName="button-parent-font"
          />
        </div>
      ))}
    </div>
  );
};

const BarChart = ({
  data,
  name,
  axis,
  width = 1000,
  height = 1000,
  handleGraphElementClicked,
  showSpaceWhenEmpty,
  benchmarkTileType,
}: Props) => {
  const Bar = withTooltip<GraphProps, Legend>(UngoupedBarChart);
  const StackedBar = withTooltip<
    GraphProps,
    { data: Legend | string | AssociatedMetricTooltips; index: number }
  >(StackedBarChart);

  return data && data.length && (data[0] as GroupedLegends).groupName
    ? StackedBar({
        data,
        name,
        width,
        height,
        showSpaceWhenEmpty,
        benchmarkTileType,
      })
    : Bar({
        data,
        name,
        axis,
        width,
        height,
        handleGraphElementClicked,
        benchmarkTileType,
      });
};

export default BarChart;
