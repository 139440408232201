import { Suspense, useState, useEffect } from "react";
import { ReactComponent as ErrorIcon } from "./icons/warning.svg";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: string;
  className?: string;
  width?: number;
  height?: number;
}

const Icon = ({ name, className, width, height }: IconProps) => {
  const [IconComponent, setIconComponent] = useState<React.FC<
    React.SVGProps<SVGSVGElement>
  > | null>(null);

  useEffect(() => {
    const importIcon = async () => {
      try {
        const { default: ImportedIcon } = await import(
          `!!@svgr/webpack?-svgo,+titleProp,+ref!./icons/${name}.svg`
        );
        setIconComponent(() => ImportedIcon);
      } catch (error) {
        setIconComponent(() => ErrorIcon);
      }
    };

    importIcon();
  }, [name]);

  return (
    <div className={className} style={{ width, height }} data-testid={`${name}-icon`}>
      <Suspense fallback={""}>
        {IconComponent ? <IconComponent width={width} height={height} /> : null}
      </Suspense>
    </div>
  );
};

export default Icon;
