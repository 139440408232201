import { Legend } from "services/dashboard/dashboard.model";
import {
  InsightCompanyFlat,
  InsightData,
} from "services/insights/insights.model";
import { useTileContext } from "../tile.context";
import { mapCompanies } from "./EnergyConsumptionTotals";
import TargetTileReportingYear from "./TargetTileReportingYear";

const TargetTile = () => {
  const {
    metadata,
    dataset,
    originalDataset,
    metadata: { benchmarkType, isDetailedView },
    isTableViewActive,
  } = useTileContext();

  const companies = mapCompanies(originalDataset);

  const mapTargetTileData = (dataset: any) => {
    return dataset.length
      ? dataset
          .map((d: InsightData, i: number) => ({
            legendValue: d.metrics[0].metricValue
              ? d.metrics[0].metricValue
              : "",
            legendOrder: i,
            legendColor: "",
            legendData: "",
            legendTitle: d.companyName,
          }))
          .sort((a: Legend, b: Legend) => a.legendOrder - b.legendOrder)
      : [];
  };

  const tileProps = {
    data: mapTargetTileData(dataset),
    benchmarkTileType: metadata.benchmarkTileType,
    isDetailedView: isDetailedView,
    isTableView: isTableViewActive,
    currentInsightView: benchmarkType,
    baselineCompany: {
      company: {
        companyName: companies.filter(
          (c: InsightCompanyFlat) => c.isBaseCompany
        )[0].companyName,
      },
    },
  };

  return <TargetTileReportingYear {...tileProps} />;
};

export default TargetTile;
