import classNames from "classnames";
import TileEmptyContent from "components/insight-tile/tile-empty-content";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import {
  SCOPE_OF_TARGET,
  TargetData,
  TargetMetric,
  TargetMetricGroup,
} from "./targets-tile-chart";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import { sanitize } from "dompurify";
import Parser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";

type props = {
  isDetailedView: boolean;
  currentInsightView: number;
  isTableViewActive: boolean;
  data: TargetData[];
  groupValues: any[];
  groupTooltips: AssociatedMetricTooltips[];
};
const GhgTargetTile = ({
  isDetailedView,
  currentInsightView,
  isTableViewActive,
  data,
  groupValues,
  groupTooltips,
}: props) => {
  const BLOCK = "ghg-target";
  const TABLE_BLOCK = "ghg-target-table";
  const dispatch = useDispatch();
  const mainTooltip = useSelector(
    (store: RootStore) => store.commons.mainTooltip
  );

  const allSingleTarget = data
    .filter((object) => object.targetGroups.length > 0)
    .every((object) => {
      return object.targetGroups.length === 1;
    });

  const hasMissingValue = data.some((obj) => {
    return obj.targetGroups.some((group) => {
      return group.metrics.some((metric) => {
        return metric.metricValue === "*" || metric.metricValue === null;
      });
    });
  });

  const hasMissingTarget = data.some((obj) => {
    return obj.targetGroups.length === 0;
  });

  const showNodata = allSingleTarget && hasMissingTarget;

  const showNodataIndusrty =
    hasMissingTarget &&
    !allSingleTarget &&
    isTableViewActive &&
    currentInsightView === 6;

  const hasAllValueMissing = data.every((obj) => {
    return obj.targetGroups.every((group) => {
      return group.metrics.every((metric) => {
        return metric.metricValue === "*" || metric.metricValue === null;
      });
    });
  });

  const renderSingleTarget = (data: TargetMetricGroup, targetIndex: number) => {
    return (
      <>
        <div className={`${BLOCK}__wrapper`}>
          <div className={`${BLOCK}__header`}>
            <div className={`${BLOCK}__metricName`}>
              <Popover
                displayText={data.metrics[0].metricName}
                metricClassName={``}
                buttonClassName={`${BLOCK}__header`}
                content={
                  groupTooltips
                    ? groupTooltips.filter(
                        (tooltip: AssociatedMetricTooltips) =>
                          tooltip.associatedMetricGroupName ===
                          data.metrics[0].metricName
                      )
                    : []
                }
              />
            </div>
          </div>
          <div className={`${BLOCK}__body`}>{data.metrics[0].metricValue}</div>
        </div>
        <div
          className={classNames(`${BLOCK}__wrapper`, {
            [`${BLOCK}__detailPeerChart-wrapper`]: isDetailedView,
          })}
        >
          <div className={`${BLOCK}__inner-wrapper`}>
            {data.metrics.map(
              (metric: TargetMetric, i: number) =>
                i > 0 && (
                  <div
                    key={`singleTarget_chart_${i}`}
                    className={`${BLOCK}__data`}
                  >
                    <div className={`${BLOCK}__header`}>
                      <div className={`${BLOCK}__metricName`}>
                        <Popover
                          displayText={metric.metricName}
                          metricClassName={``}
                          buttonClassName={`${BLOCK}__header`}
                          content={
                            groupTooltips
                              ? groupTooltips.filter(
                                  (tooltip: AssociatedMetricTooltips) =>
                                    tooltip.associatedMetricGroupName ===
                                    metric.metricName
                                )
                              : []
                          }
                        />
                      </div>
                    </div>
                    <div className={`${BLOCK}__body`}>
                      <span style={{ position: "relative" }}>
                        <span
                          className={classNames({
                            [`${BLOCK}__scope-target-metric`]:
                              metric.metricName === SCOPE_OF_TARGET,
                          })}
                          onMouseEnter={(e) => {
                            if (
                              metric.metricName === SCOPE_OF_TARGET &&
                              (e.currentTarget as HTMLSpanElement)
                                .offsetHeight +
                                1 <
                                (e.currentTarget as HTMLSpanElement)
                                  .scrollHeight
                            ) {
                              handleMouseEnter(
                                e,
                                "span",
                                <div>
                                  {Parser(sanitize(metric.metricValue ?? ""))}
                                </div>,
                                "",
                                dispatch,
                                null,
                                MainTooltipPosition.BottomMiddle
                              );
                            }
                          }}
                          onMouseLeave={(e) => {
                            if (metric.metricName === SCOPE_OF_TARGET)
                              handleMouseLeave(
                                e,
                                dispatch,
                                mainTooltip.isOverTooltip
                                  ? mainTooltip.isOverTooltip
                                  : false
                              );
                          }}
                        >
                          {metric.metricValue}
                        </span>
                      </span>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </>
    );
  };

  const renderMultipleTarget = (
    target: TargetMetricGroup[],
    companyName: string
  ) => {
    return (
      <div
        className={classNames(``, {
          [`${BLOCK}__detailPeerChart`]: isDetailedView,
        })}
      >
        <div className={`${BLOCK}__multiTargetwrapper`}>
          <div className={`${BLOCK}__multiTargetwrapper-header`}>
            <span className={`${BLOCK}__fitMultiTarget`}>
              <Popover
                displayText={target[0]?.metrics[0]?.metricName}
                metricClassName={`${BLOCK}__header`}
                content={
                  groupTooltips
                    ? groupTooltips.filter(
                        (tooltip: AssociatedMetricTooltips) =>
                          tooltip.associatedMetricGroupName ===
                          target[0]?.metrics[0]?.metricName
                      )
                    : []
                }
              />
            </span>
          </div>
          <div className={`${BLOCK}__body`}>
            {target[0]?.metrics[0]?.metricValue}

            {target[0]?.metrics[0]?.metricValue &&
            target[0]?.metrics[0]?.metricValue.toLowerCase() === "neutral" ? (
              <>
                <Tooltip position={TooltipPosition.periodRight}>
                  <FormattedMessage id="insights.tabular.neutral" />
                </Tooltip>
              </>
            ) : null}
          </div>
        </div>

        <table
          className={classNames(`${BLOCK}__tableWrapper`, {
            [`${BLOCK}__detailPeerChart-tableWrapper`]: isDetailedView,
          })}
        >
          <thead className={`${BLOCK}__tableHeader`}>
            <tr className={`${BLOCK}__tableHeaderRow`}>
              <td className={`${BLOCK}__tableFirst`}></td>
              {groupValues.map(
                (group: any, ind: number) =>
                  ind > 0 && (
                    <td
                      key={`ghg-target-multi-data${ind}`}
                      className={`${BLOCK}__tableLast`}
                    >
                      <div className={`${BLOCK}__metricName`}>
                        <Popover
                          displayText={group}
                          metricClassName={``}
                          buttonClassName={`${BLOCK}__tableHeader`}
                          content={
                            groupTooltips
                              ? groupTooltips.filter(
                                  (tooltip: AssociatedMetricTooltips) =>
                                    tooltip.associatedMetricGroupName === group
                                )
                              : []
                          }
                        />
                      </div>
                    </td>
                  )
              )}
            </tr>
          </thead>
          <tbody className={`${BLOCK}__tableBody`}>
            {target.map((group: TargetMetricGroup, ind: number) => (
              <tr key={`ghg-target-multi-row${ind}`}>
                <td>{group.groupName}</td>
                {group.metrics.map(
                  (metric: TargetMetric, i: number) =>
                    i > 0 && (
                      <td
                        className={`${BLOCK}__tableBody-metricData`}
                        key={`ghg-target-multi-row-data${i}`}
                      >
                        {groupValues[i] === SCOPE_OF_TARGET ? (
                          <span style={{ position: "relative" }}>
                            <span
                              className={classNames({
                                [`${BLOCK}__scope-target-metric`]:
                                  groupValues[i] === SCOPE_OF_TARGET,
                              })}
                              onMouseEnter={(e) => {
                                if (
                                  groupValues[i] === SCOPE_OF_TARGET &&
                                  (e.currentTarget as HTMLSpanElement)
                                    .offsetHeight +
                                    1 <
                                    (e.currentTarget as HTMLSpanElement)
                                      .scrollHeight
                                ) {
                                  handleMouseEnter(
                                    e,
                                    "span",
                                    <div>
                                      {Parser(
                                        sanitize(metric.metricValue ?? "")
                                      )}
                                    </div>,
                                    "",
                                    dispatch,
                                    null,
                                    MainTooltipPosition.BottomMiddle
                                  );
                                }
                              }}
                              onMouseLeave={(e) => {
                                handleMouseLeave(
                                  e,
                                  dispatch,
                                  mainTooltip.isOverTooltip
                                    ? mainTooltip.isOverTooltip
                                    : false
                                );
                              }}
                            >
                              {metric.metricValue}
                            </span>
                          </span>
                        ) : (
                          metric.metricValue
                        )}

                        {metric.metricValue &&
                        metric.metricValue.toLowerCase() === "neutral" ? (
                          <>
                            <Tooltip position={TooltipPosition.periodRight}>
                              <FormattedMessage id="insights.tabular.neutral" />
                            </Tooltip>
                          </>
                        ) : null}
                      </td>
                    )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderPeerIndustryView = () => {
    return !allSingleTarget ? (
      <div
        className={classNames(``, {
          [`${BLOCK}__detialView`]: isDetailedView,
        })}
      >
        {data.map((compData: TargetData, index: number) => (
          <div key={`peer-ind-chart${index}`}>
            <div className={`${BLOCK}__detailPeerChart-container`}>
              <div className={`${BLOCK}__companyName`}>
                {compData.companyData.companyName}
              </div>

              {compData.targetGroups.length > 0 ? (
                compData.targetGroups.length > 1 ? (
                  renderMultipleTarget(
                    compData.targetGroups,
                    compData.companyData.companyName
                  )
                ) : (
                  renderSingleTarget(compData.targetGroups[0], 0)
                )
              ) : (
                <div className={`${BLOCK}__container`}>
                  <span className={`${BLOCK}__label`}>
                    <FormattedMessage id="insight.no.data.available" />
                  </span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    ) : (
      renderSingleTargetPeer()
    );
  };

  const renderSingleTargetPeer = () => {
    let combinedData: any[] = [];
    let basecompany = data.filter(
      (td: TargetData) => td.companyData.isBaseCompany
    );
    basecompany = basecompany.length === 0 ? [data[0]] : basecompany;
    
    basecompany[0]?.targetGroups[0]?.metrics.map(
      (m: TargetMetric, indx: number) => {
        let metricBlockData = {
          subheader: m.metricName,
          companies: data.map((td: TargetData, ind: number) => {
            return {
              companyName: td.companyData.companyName,
              metricValue: td.targetGroups[0]?.metrics
                ? td.targetGroups[0]?.metrics?.filter(
                    (tm: TargetMetric) => tm.metricKey === m.metricKey
                  )[0].metricValue ?? "*"
                : "*",
              isVertical: false,
              metricType: td.targetGroups[0]?.metrics
                ? td.targetGroups[0]?.metrics.filter(
                    (tm: TargetMetric) => tm.metricKey === m.metricKey
                  )[0].metricType ?? 1
                : "*",
            };
          }),
        };
        combinedData.push(metricBlockData);
      }
    );
    if (isDetailedView) {
      return (
        <div
          className={`${BLOCK}__singleTargetPeerDetail`}
          style={{ width: "100%" }}
        >
          {renderSingleTargetDetialViewFirst(combinedData)}
          {renderSingleTargetDetialViewOther(combinedData)}
        </div>
      );
    } else {
      return (
        <div>
          {renderSingleTargetPeerFirst(combinedData)}
          {renderSingleTargetPeerOther(combinedData)}
        </div>
      );
    }
  };

  const renderSingleTargetDetialViewFirst = (combinedData: any) => {
    return combinedData.map(
      (data: any, index: any) =>
        index === 0 && (
          <div
            key={`single-target-detail${index}`}
            className={`${BLOCK}__wrapper ${BLOCK}__wrapper--30`}
          >
            <div className={`${BLOCK}__header`}>
              <Popover
                displayText={data.subheader}
                metricClassName={``}
                buttonClassName={`${BLOCK}__header`}
                content={
                  groupTooltips
                    ? groupTooltips.filter(
                        (tooltip: AssociatedMetricTooltips) =>
                          tooltip.associatedMetricGroupName === data.subheader
                      )
                    : []
                }
              />
            </div>
            <div className={`${BLOCK}__singleTargetPeerDetail-body`}>
              {data.companies.map((comp: any) => (
                <div
                  key={`singleTargetData${comp.companyName}`}
                  className={`${BLOCK}__singleTargetPeerDetail-data`}
                >
                  <div
                    className={`${BLOCK}__singleTargetPeerDetail-companyName`}
                  >
                    {comp.companyName}
                  </div>
                  <div
                    className={`${BLOCK}__singleTargetPeerDetail-valueBaseline`}
                  >
                    {comp.metricValue}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
    );
  };

  const renderSingleTargetDetialViewOther = (combinedData: any) => {
    return (
      <div className={`${BLOCK}__wrapper ${BLOCK}__wrapper--60`}>
        <div className={`${BLOCK}__inner-wrapper`}>
          {combinedData.map(
            (data: any, index: number) =>
              index > 0 && (
                <div
                  key={`single-target-details${index}`}
                  className={`${BLOCK}__data`}
                >
                  <div className={`${BLOCK}__header`}>
                    <Popover
                      displayText={data.subheader}
                      metricClassName={``}
                      buttonClassName={`${BLOCK}__header`}
                      content={
                        groupTooltips
                          ? groupTooltips.filter(
                              (tooltip: AssociatedMetricTooltips) =>
                                tooltip.associatedMetricGroupName ===
                                data.subheader
                            )
                          : []
                      }
                    />
                  </div>
                  <div className={`${BLOCK}__singleTargetPeerDetail-body`}>
                    {data.companies.map((comp: any, ind: number) => (
                      <div
                        key={`singleTargetData${comp.companyName}`}
                        className={`${BLOCK}__singleTargetPeerDetail-data`}
                      >
                        <div
                          className={`${BLOCK}__singleTargetPeerDetail-companyName`}
                        >
                          {comp.companyName}
                        </div>
                        <div
                          className={`${BLOCK}__singleTargetPeerDetail-value`}
                        >
                          <span style={{ position: "relative" }}>
                            <span
                              className={classNames({
                                [`${BLOCK}__scope-target-metric`]:
                                  data.subheader === SCOPE_OF_TARGET,
                              })}
                              onMouseEnter={(e) => {
                                if (
                                  data.subheader === SCOPE_OF_TARGET &&
                                  (e.currentTarget as HTMLSpanElement)
                                    .offsetHeight +
                                    1 <
                                    (e.currentTarget as HTMLSpanElement)
                                      .scrollHeight
                                ) {
                                  handleMouseEnter(
                                    e,
                                    "span",
                                    <div>
                                      {Parser(sanitize(comp.metricValue ?? ""))}
                                    </div>,
                                    "",
                                    dispatch,
                                    null,
                                    MainTooltipPosition.BottomMiddle
                                  );
                                }
                              }}
                              onMouseLeave={(e) => {
                                handleMouseLeave(
                                  e,
                                  dispatch,
                                  mainTooltip.isOverTooltip
                                    ? mainTooltip.isOverTooltip
                                    : false
                                );
                              }}
                            >
                              {comp.metricValue}
                            </span>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    );
  };

  const renderSingleTargetPeerFirst = (combinedData: any) => {
    return combinedData.map(
      (data: any, index: any) =>
        index === 0 && (
          <div
            key={`single-target-peer${index}`}
            className={`${BLOCK}__wrapper`}
          >
            <div className={`${BLOCK}__header`}>
              <div className={`${BLOCK}__metricName`}>
                <Popover
                  displayText={data.subheader}
                  metricClassName={``}
                  buttonClassName={`${BLOCK}__header`}
                  content={
                    groupTooltips
                      ? groupTooltips.filter(
                          (tooltip: AssociatedMetricTooltips) =>
                            tooltip.associatedMetricGroupName === data.subheader
                        )
                      : []
                  }
                />
              </div>
            </div>
            <div className={`${BLOCK}__singleTargetPeer-body`}>
              {data.companies.map((comp: any) => (
                <div key={`singleTargetData${comp.companyName}`}>
                  <div className={`${BLOCK}__singleTargetPeer-companyName`}>
                    {comp.companyName}
                  </div>
                  <div className={`${BLOCK}__singleTargetPeer-data`}>
                    <span
                      className={classNames({
                        [`${BLOCK}__scope-target-metric`]:
                          data.subheader === SCOPE_OF_TARGET,
                        [`${BLOCK}__scope-target-metric--max-width`]:
                          data.subheader === SCOPE_OF_TARGET,
                      })}
                    >
                      {comp.metricValue}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
    );
  };

  const renderSingleTargetPeerOther = (combinedData: any) => {
    return (
      <div className={`${BLOCK}__wrapper `}>
        <div className={`${BLOCK}__inner-wrapper`}>
          {combinedData.map(
            (data: any, index: number) =>
              index > 0 && (
                <div
                  key={`single-target-peers${index}`}
                  className={`${BLOCK}__data`}
                >
                  <div className={`${BLOCK}__header`}>
                    <div className={`${BLOCK}__metricName`}>
                      <Popover
                        displayText={data.subheader}
                        metricClassName={``}
                        buttonClassName={`${BLOCK}__header`}
                        content={
                          groupTooltips
                            ? groupTooltips.filter(
                                (tooltip: AssociatedMetricTooltips) =>
                                  tooltip.associatedMetricGroupName ===
                                  data.subheader
                              )
                            : []
                        }
                      />
                    </div>
                  </div>
                  <div className={`${BLOCK}__singleTargetPeer-body`}>
                    {data.companies.map((comp: any, ind: number) => (
                      <div
                        key={`singleTargetData${comp.companyName}`}
                        className={`${BLOCK}__singleTargetPeer-body-container`}
                      >
                        <div
                          className={`${BLOCK}__singleTargetPeer-companyName`}
                        >
                          {comp.companyName}
                        </div>
                        <div className={`${BLOCK}__singleTargetPeer-data`}>
                          <span style={{ position: "relative" }}>
                            <span
                              className={classNames({
                                [`${BLOCK}__scope-target-metric`]:
                                  data.subheader === SCOPE_OF_TARGET,
                              })}
                              onMouseEnter={(e) => {
                                if (
                                  data.subheader === SCOPE_OF_TARGET &&
                                  (e.currentTarget as HTMLSpanElement)
                                    .offsetHeight +
                                    1 <
                                    (e.currentTarget as HTMLSpanElement)
                                      .scrollHeight
                                ) {
                                  handleMouseEnter(
                                    e,
                                    "span",
                                    <div>
                                      {Parser(sanitize(comp.metricValue ?? ""))}
                                    </div>,
                                    "",
                                    dispatch,
                                    null,
                                    MainTooltipPosition.BottomMiddle
                                  );
                                }
                              }}
                              onMouseLeave={(e) => {
                                handleMouseLeave(
                                  e,
                                  dispatch,
                                  mainTooltip.isOverTooltip
                                    ? mainTooltip.isOverTooltip
                                    : false
                                );
                              }}
                            >
                              {comp.metricValue}
                            </span>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    );
  };

  const multiTargetCompTableView = (target: TargetMetricGroup[]) => {
    return (
      <div>
        <div className={`${BLOCK}__compTable`}>
          <div className={`${BLOCK}__compTable-header`}>
            <Popover
              displayText={target[0].metrics[0].metricName}
              metricClassName={`${BLOCK}__compTable-header`}
              buttonClassName={`${BLOCK}__compTable`}
              content={
                groupTooltips
                  ? groupTooltips.filter(
                      (tooltip: AssociatedMetricTooltips) =>
                        tooltip.associatedMetricGroupName ===
                        target[0].metrics[0].metricName
                    )
                  : []
              }
            />
          </div>
          <div className={`${BLOCK}__compTable-body`}>
            {target[0].metrics[0].metricValue}
          </div>
        </div>

        <table className={`${BLOCK}__compTable-table`}>
          <thead className={`${BLOCK}__compTable-tableHeader`}>
            <tr className={`${BLOCK}__compTable-tableHeaderRow`}>
              <td></td>
              {groupValues.map(
                (group: any, ind: number) =>
                  ind > 0 && (
                    <td key={`compTable-table${ind}`}>
                      <div className={`${BLOCK}__metricName`}>
                        <Popover
                          displayText={group}
                          metricClassName={``}
                          buttonClassName={`${BLOCK}__compTable-tableHeader`}
                          content={
                            groupTooltips
                              ? groupTooltips.filter(
                                  (tooltip: AssociatedMetricTooltips) =>
                                    tooltip.associatedMetricGroupName === group
                                )
                              : []
                          }
                        />
                      </div>
                    </td>
                  )
              )}
            </tr>
          </thead>
          <tbody className={`${BLOCK}__compTable-tableBody`}>
            {target.map((group: TargetMetricGroup, ind: number) => (
              <tr key={`compTable-tableBody${ind}`}>
                <td>{group.groupName}</td>
                {group.metrics.map(
                  (metric: TargetMetric, i: number) =>
                    i > 0 && (
                      <td
                        key={`compTable-tabledata${i}`}
                        style={
                          groupValues[i] === SCOPE_OF_TARGET
                            ? { position: "relative" }
                            : {}
                        }
                      >
                        {" "}
                        {groupValues[i] === SCOPE_OF_TARGET ? (
                          <span
                            className={classNames({
                              [`${BLOCK}__scope-target-metric`]:
                                groupValues[i] === SCOPE_OF_TARGET,
                            })}
                            onMouseEnter={(e) => {
                              if (
                                groupValues[i] === SCOPE_OF_TARGET &&
                                (e.currentTarget as HTMLSpanElement)
                                  .offsetHeight +
                                  1 <
                                  (e.currentTarget as HTMLSpanElement)
                                    .scrollHeight
                              ) {
                                handleMouseEnter(
                                  e,
                                  "span",
                                  <div>
                                    {Parser(sanitize(metric.metricValue ?? ""))}
                                  </div>,
                                  "",
                                  dispatch,
                                  null,
                                  MainTooltipPosition.BottomMiddle
                                );
                              }
                            }}
                            onMouseLeave={(e) => {
                              handleMouseLeave(
                                e,
                                dispatch,
                                mainTooltip.isOverTooltip
                                  ? mainTooltip.isOverTooltip
                                  : false
                              );
                            }}
                          >
                            {metric.metricValue}
                          </span>
                        ) : (
                          metric.metricValue
                        )}
                      </td>
                    )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  const renderCompanyTableView = () => {
    return allSingleTarget
      ? data[0].targetGroups[0].metrics.map(
          (metric: TargetMetric, index: number) => (
            <div
              key={`singleTarget-table${index}`}
              className={`${BLOCK}__singleTarget`}
            >
              <Popover
                displayText={metric.metricName}
                metricClassName={``}
                buttonClassName={`${BLOCK}__singleTarget-metricName`}
                content={
                  groupTooltips
                    ? groupTooltips.filter(
                        (tooltip: AssociatedMetricTooltips) =>
                          tooltip.associatedMetricGroupName ===
                          metric.metricName
                      )
                    : []
                }
              />

              <div
                style={{ maxWidth: "50%" }}
                className={`${BLOCK}__singleTarget-metricValue`}
              >
                <span style={{ position: "relative" }}>
                  <span
                    className={classNames(`${BLOCK}__single-metric-value`, {
                      [`${BLOCK}__scope-target-metric`]:
                        metric.metricName === SCOPE_OF_TARGET,
                    })}
                    onMouseEnter={(e) => {
                      if (
                        metric.metricName === SCOPE_OF_TARGET &&
                        (e.currentTarget as HTMLSpanElement).offsetHeight + 1 <
                          (e.currentTarget as HTMLSpanElement).scrollHeight
                      ) {
                        handleMouseEnter(
                          e,
                          "span",
                          <div>
                            {Parser(sanitize(metric.metricValue ?? ""))}
                          </div>,
                          "",
                          dispatch,
                          null,
                          MainTooltipPosition.BottomMiddle
                        );
                      }
                    }}
                    onMouseLeave={(e) => {
                      handleMouseLeave(
                        e,
                        dispatch,
                        mainTooltip.isOverTooltip
                          ? mainTooltip.isOverTooltip
                          : false
                      );
                    }}
                  >
                    {metric.metricValue}
                  </span>
                </span>
              </div>
            </div>
          )
        )
      : multiTargetCompTableView(data[0].targetGroups);
  };

  const renderPeerTableView = () => {
    return !allSingleTarget ? (
      <div
        className={classNames(``, {
          [`detail`]: isTableViewActive,
        })}
      >
        {data.map((compData: TargetData, index: number) => (
          <div key={`detailpeer-${index}`} className={`${BLOCK}__detailPeer`}>
            <div className={`${BLOCK}__detailPeer-header`}>
              {compData.companyData.companyName}
            </div>
            <div className={`${BLOCK}__detailPeer-body`}>
              {compData.targetGroups.length > 0 ? (
                renderMultipleTargetPeerTable(
                  compData.targetGroups,
                  compData.companyData.companyName
                )
              ) : (
                <div className={`${BLOCK}__container`}>
                  <span className={`${BLOCK}__label`}>
                    <FormattedMessage id="insight.no.data.available" />
                  </span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    ) : (
      renderAllSingleTargetPeerTable()
    );
  };

  const renderAllSingleTargetPeerTable = () => {
    return (
      <div>
        <table className={classNames(`${TABLE_BLOCK}__table`)}>
          <thead>
            <tr>
              <td></td>
              {groupValues.map((group: any, i: number) => (
                <td key={`data-header-${i}`} className="data">
                  <div className={`${BLOCK}__metricName`}>
                    <Popover
                      displayText={group}
                      metricClassName={``}
                      buttonClassName={`data`}
                      content={
                        groupTooltips
                          ? groupTooltips.filter(
                              (tooltip: AssociatedMetricTooltips) =>
                                tooltip.associatedMetricGroupName === group
                            )
                          : []
                      }
                    />
                  </div>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((comp: TargetData, i: number) => (
              <tr
                className={classNames(`${TABLE_BLOCK}__row`)}
                key={`table-row-${i}`}
              >
                <td
                  className={`${TABLE_BLOCK}__label`}
                  key={`data-labels-${i}`}
                >
                  {comp.companyData.companyName}
                </td>

                {groupValues.map((group: any, index: number) => (
                  <td
                    className={`${BLOCK}__singleTargetPeerDetail-valueBaseline`}
                    key={`all-single-target_${index}`}
                    style={
                      groupValues[index] === SCOPE_OF_TARGET
                        ? { position: "relative" }
                        : {}
                    }
                  >
                    {groupValues[index] === SCOPE_OF_TARGET ? (
                      <span
                        className={classNames({
                          [`${BLOCK}__scope-target-metric`]:
                            groupValues[index] === SCOPE_OF_TARGET,
                        })}
                        onMouseEnter={(e) => {
                          if (
                            groupValues[index] === SCOPE_OF_TARGET &&
                            (e.currentTarget as HTMLSpanElement).offsetHeight +
                              1 <
                              (e.currentTarget as HTMLSpanElement).scrollHeight
                          ) {
                            handleMouseEnter(
                              e,
                              "span",
                              <div>
                                {Parser(
                                  sanitize(
                                    comp.targetGroups[0]?.metrics[index]
                                      ?.metricValue ?? "*"
                                  )
                                )}
                              </div>,
                              "",
                              dispatch,
                              null,
                              MainTooltipPosition.BottomMiddle
                            );
                          }
                        }}
                        onMouseLeave={(e) => {
                          handleMouseLeave(
                            e,
                            dispatch,
                            mainTooltip.isOverTooltip
                              ? mainTooltip.isOverTooltip
                              : false
                          );
                        }}
                      >
                        {comp.targetGroups[0]?.metrics[index]?.metricValue ??
                          "*"}
                      </span>
                    ) : (
                      comp.targetGroups[0]?.metrics[index]?.metricValue ?? "*"
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderMultipleTargetIndTable = () => {
    return (
      <div>
        <table>
          <thead>
            <tr className={`${BLOCK}__indTable-headerRow`}>
              <td></td>
              {groupValues.map(
                (group: any, i: number) =>
                  i === 0 && (
                    <td key={`data-header-${i}`}>
                      <Popover
                        displayText={group}
                        metricClassName={`${BLOCK}__indTable-groupName`}
                        buttonClassName={``}
                        content={
                          groupTooltips
                            ? groupTooltips.filter(
                                (tooltip: AssociatedMetricTooltips) =>
                                  tooltip.associatedMetricGroupName === group
                              )
                            : []
                        }
                      />
                    </td>
                  )
              )}

              <td></td>
              {groupValues.map(
                (group: any, i: number) =>
                  i > 0 && (
                    <td key={`data-header-other-${i}`}>
                      {" "}
                      <Popover
                        displayText={group}
                        metricClassName={`${BLOCK}__indTable-groupName`}
                        buttonClassName={``}
                        content={
                          groupTooltips
                            ? groupTooltips.filter(
                                (tooltip: AssociatedMetricTooltips) =>
                                  tooltip.associatedMetricGroupName === group
                              )
                            : []
                        }
                      />
                    </td>
                  )
              )}
            </tr>
          </thead>
          <tbody>
            {data.map((comp: TargetData, i: number) => (
              <tr
                className={`${BLOCK}__indTable-tableDataRow`}
                key={`table-row-${i}`}
              >
                <td
                  className={`${BLOCK}__indTable-tableDataRow`}
                  key={`data-labels-${i}`}
                >
                  {comp.companyData.companyName}
                </td>

                {groupValues.map(
                  (group: any, index: number) =>
                    index === 0 && (
                      <td
                        key={`indTable-tableDataRow${index}`}
                        className={`${BLOCK}__indTable-tableDataRow`}
                      >
                        {comp.targetGroups[0]?.metrics[index]?.metricValue ??
                          "*"}
                      </td>
                    )
                )}
                <td className={`${BLOCK}__indTable-tableDataRow`}>
                  {comp.targetGroups.length > 0 ? (
                    comp.targetGroups.map(
                      (target: TargetMetricGroup, ind: number) => (
                        <table key={`tr-table-${ind}`}>
                          <tbody>
                            <tr
                              key={`indTable-dataRow${ind}`}
                              className={`${BLOCK}__indTable-dataRow`}
                            >
                              <td className={`${BLOCK}__indTable-targetName`}>
                                {comp.targetGroups.length > 1
                                  ? target.groupName
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )
                    )
                  ) : (
                    <td>{""}</td>
                  )}
                </td>

                <td className={`${BLOCK}__indTable-tableDataRow`}>
                  {comp.targetGroups.length > 0 ? (
                    comp.targetGroups.map((target: TargetMetricGroup) =>
                      target.metrics.map(
                        (metrics: TargetMetric, id: number) =>
                          id === 1 && (
                            <table key={`tr-table-${id}`}>
                              <tbody>
                                <tr
                                  key={`indTable-dataRow${id}`}
                                  className={`${BLOCK}__indTable-dataRow`}
                                >
                                  <td>{metrics.metricValue}</td>
                                </tr>
                              </tbody>
                            </table>
                          )
                      )
                    )
                  ) : (
                    <td>{"*"}</td>
                  )}
                </td>
                <td className={`${BLOCK}__indTable-tableDataRow`}>
                  {comp.targetGroups.length > 0 ? (
                    comp.targetGroups.map(
                      (target: TargetMetricGroup, targetIndex: number) =>
                        target.metrics.map(
                          (metrics: TargetMetric, id: number) =>
                            id === 2 && (
                              <table key={`tr-table-${id}`}>
                                <tbody>
                                  <tr
                                    key={`indTable-dataRow${id}`}
                                    className={`${BLOCK}__indTable-dataRow`}
                                  >
                                    <td>
                                      {metrics.metricName ===
                                      SCOPE_OF_TARGET ? (
                                        <span style={{ position: "relative" }}>
                                          <span
                                            className={classNames({
                                              [`${BLOCK}__scope-target-metric`]:
                                                metrics.metricName ===
                                                SCOPE_OF_TARGET,
                                            })}
                                            onMouseEnter={(e) => {
                                              if (
                                                metrics.metricName ===
                                                  SCOPE_OF_TARGET &&
                                                (
                                                  e.currentTarget as HTMLSpanElement
                                                ).offsetHeight +
                                                  1 <
                                                  (
                                                    e.currentTarget as HTMLSpanElement
                                                  ).scrollHeight
                                              ) {
                                                handleMouseEnter(
                                                  e,
                                                  "span",
                                                  <div>
                                                    {Parser(
                                                      sanitize(
                                                        metrics.metricValue ??
                                                          ""
                                                      )
                                                    )}
                                                  </div>,
                                                  "",
                                                  dispatch,
                                                  null,
                                                  MainTooltipPosition.BottomMiddle
                                                );
                                              }
                                            }}
                                            onMouseLeave={(e) => {
                                              handleMouseLeave(
                                                e,
                                                dispatch,
                                                mainTooltip.isOverTooltip
                                                  ? mainTooltip.isOverTooltip
                                                  : false
                                              );
                                            }}
                                          >
                                            {metrics.metricValue}
                                          </span>
                                        </span>
                                      ) : (
                                        metrics.metricValue
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )
                        )
                    )
                  ) : (
                    <td>{"*"}</td>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderMultipleTargetPeerTable = (
    target: TargetMetricGroup[],
    companyName: string
  ) => {
    return (
      <div className={`${BLOCK}__detailPeer-tableWrapper`}>
        <div className={`${BLOCK}__detailPeer-multiTargetwrapper`}>
          <div className={`${BLOCK}__detailPeer-tableHeader`}>
            <Popover
              displayText={target[0].metrics[0].metricName}
              metricClassName={``}
              buttonClassName={`${BLOCK}__detailPeer-tableHeader`}
              content={
                groupTooltips
                  ? groupTooltips.filter(
                      (tooltip: AssociatedMetricTooltips) =>
                        tooltip.associatedMetricGroupName ===
                        target[0].metrics[0].metricName
                    )
                  : []
              }
            />
          </div>
          <div className={`${BLOCK}__detailPeer-valueFirst`}>
            {target[0].metrics[0].metricValue}
          </div>
        </div>

        <table className={`${BLOCK}__detailPeer-tableBody`}>
          <thead className={`${BLOCK}__detailPeer-tableHeader`}>
            <tr className={`${BLOCK}__tableHeaderRow`}>
              <td className={`${BLOCK}__tableFirst`}></td>
              {groupValues.map(
                (group: any, ind: number) =>
                  ind > 0 && (
                    <td
                      key={`ghg-target-multi-data${ind}`}
                      className={`${BLOCK}__tableLast`}
                    >
                      <Popover
                        displayText={group}
                        metricClassName={``}
                        buttonClassName={`${BLOCK}__detailPeer-tableHeader`}
                        content={
                          groupTooltips
                            ? groupTooltips.filter(
                                (tooltip: AssociatedMetricTooltips) =>
                                  tooltip.associatedMetricGroupName === group
                              )
                            : []
                        }
                      />
                    </td>
                  )
              )}
            </tr>
          </thead>
          <tbody className={`${BLOCK}__tableBody`}>
            {target.map((group: TargetMetricGroup, ind: number) => (
              <tr key={`ghg-target-multi-row${ind}`}>
                <td className={`${BLOCK}__detailPeer-groupName`}>
                  {target.length === 1 ? "" : group.groupName}
                </td>
                {group.metrics.map(
                  (metric: TargetMetric, i: number) =>
                    i > 0 && (
                      <td
                        key={`ghg-target-multi-row-data${i}`}
                        className={`${BLOCK}__detailPeer-groupValue`}
                        style={
                          groupValues[i] === SCOPE_OF_TARGET
                            ? { position: "relative" }
                            : {}
                        }
                      >
                        {groupValues[i] === SCOPE_OF_TARGET ? (
                          <span
                            className={classNames({
                              [`${BLOCK}__scope-target-metric`]:
                                groupValues[i] === SCOPE_OF_TARGET,
                            })}
                            onMouseEnter={(e) => {
                              if (
                                groupValues[i] === SCOPE_OF_TARGET &&
                                (e.currentTarget as HTMLSpanElement)
                                  .offsetHeight +
                                  1 <
                                  (e.currentTarget as HTMLSpanElement)
                                    .scrollHeight
                              ) {
                                handleMouseEnter(
                                  e,
                                  "span",
                                  <div>
                                    {Parser(sanitize(metric.metricValue ?? ""))}
                                  </div>,
                                  "",
                                  dispatch,
                                  null,
                                  MainTooltipPosition.BottomMiddle
                                );
                              }
                            }}
                            onMouseLeave={(e) => {
                              handleMouseLeave(
                                e,
                                dispatch,
                                mainTooltip.isOverTooltip
                                  ? mainTooltip.isOverTooltip
                                  : false
                              );
                            }}
                          >
                            {metric.metricValue}
                          </span>
                        ) : (
                          metric.metricValue
                        )}
                      </td>
                    )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderIndustryTableView = () => {
    return allSingleTarget
      ? renderAllSingleTargetPeerTable()
      : renderMultipleTargetIndTable();
  };

  const renderTableView = () => {
    return currentInsightView === INSIGHT_BENCHMARK_TYPE.COMPANY
      ? renderCompanyTableView()
      : currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK
      ? renderPeerTableView()
      : renderIndustryTableView();
  };
  const renderChartView = () => {
    return currentInsightView === INSIGHT_BENCHMARK_TYPE.COMPANY
      ? data[0].targetGroups.length === 0
        ? null
        : data[0].targetGroups.length > 1
        ? renderMultipleTarget(
            data[0].targetGroups,
            data[0].companyData.companyName
          )
        : renderSingleTarget(data[0].targetGroups[0], 0)
      : renderPeerIndustryView();
  };
  return (
    <div className={`${BLOCK}`} data-test="ghg-target">
      {data.length > 0 && !hasAllValueMissing && (
        <>
          {isTableViewActive ? renderTableView() : renderChartView()}
          {(hasMissingValue || showNodata || showNodataIndusrty) && (
            <div className={"DashboardTile__noDataAny"}>
              <FormattedMessage id="no.data.available" />
            </div>
          )}
        </>
      )}
      {hasAllValueMissing && (
        <div>
          <TileEmptyContent />
        </div>
      )}
    </div>
  );
};

export default GhgTargetTile;
