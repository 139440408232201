import { MouseEventHandler } from "react";
import Slider from 'components/slider/slider';

export enum FilterTipsPage {
  company = "company",
  content = "content",
}

type Props = {
  page?: FilterTipsPage;
  handleClose?: MouseEventHandler,
};

const FilterTips = ({ page, handleClose = () => {} }: Props) => {
  const slides = Object.values(FilterTipsPage).map((page) => ({
    name: page,
    imgUrl: `${process.env.PUBLIC_URL}/assets/filter-tip_${page}.png`,
    alt: 'overlay.search.tip.company',
  }));

  
  return (
    <Slider
      slides={slides}
      slide={slides.find((s) => s.name === page) || slides[0]}
      nextButtonMsjId="overlay.search.tip.next"
      previousButtonMsjId="overlay.search.tip.previous"
    />
  )
};

export default FilterTips;
