import Modal from "../modal/modal";
import Button from "../button/button";

type props = {
  title: string;
  message?: any;
  isRemove?: boolean;
  handleCloseModal: () => void;
  deleteItem: () => void;
};

const DeleteModal = ({
  title,
  message,
  isRemove,
  handleCloseModal,
  deleteItem,
}: props) => {
  const BLOCK = "DeleteModal";

  return (
    <Modal
      show={true}
      header={title}
      handleClose={handleCloseModal}
      config={false}
    >
      <div className={`${BLOCK} ${BLOCK}__body`}>
        <span className={`${BLOCK}__name`}>{message}</span>
        <div className={`${BLOCK}__options`}>
          <Button
            className={`button-secondary`}
            onClick={handleCloseModal}
            dataTest="cancel-btn"
            data-testid="cansel-report-button"
            formattedText="shortlist.save.button.cancel"
          />
          <Button
            className={`button-critical`}
            dataTest="delete-btn"
            data-testid="delete-report-button"
            formattedText={
              isRemove
                ? "dashboard.benchmark.tile.remove"
                : "shortlist.delete.delete"
            }
            onClick={deleteItem}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
