import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "components/shared/icon/icon";
import FormattedMessage from "../../components/shared/formatted-message/formatted-message";
import Tooltip, {
  TooltipPosition,
} from "../../components/shared/tooltip/tooltip";
import { RootStore } from "services/store.service";
import {
  deleteComparison,
  deleteComparisonPopUp,
  editComparison,
  editComparisonPopUp,
  loadComparisonItems,
  loadsavedComparisonsSortList,
  loadSavedComparisonsList,
  loadSavedComparisonsListNextPage,
  refreshComparisonsList,
  savedComparison,
  showItemsTooltip,
  comparisonFilter,
  filterComparisons,
  resetSavedComparison,
} from "services/saved-comparisons/saved-comparisons.service";
import ShareToUsersModal from "components/shared/share-to-users-modal/share-to-users-modal";
import {
  DEFAULT_SAVED_COMPARISONS_PAYLOAD,
  shareItemType,
  SORT_ORDERS,
  ScrollOffset,
  CONTENT_BENCHMARK_USER_ROLES,
} from "utils/constants";
import { shareCompareList } from "services/saved-comparisons/saved-comparisons.service";
import { ComparisonItem } from "services/saved-comparisons/saved-comparisons.model";
import moment from "moment";
import classNames from "classnames";
import history from "../../utils/history";
import Spinner from "images/spinner.gif";
import EditComparison from "./edit-comparison";
import FilterPopup from "components/shared/filter-popup/filter-popup";
import ActiveFilterTag from "components/shared/active-filter-tag/active-filter-tag";
import { addToastMessage } from "services/commons.service";
import { comparisonTableFilter } from "utils/constants";
import DeleteModal from "components/shared/delete-modal/delete-modal";
import { getUserInitials } from "utils/functions";

const SavedComparisonsTable = () => {
  const BLOCK = "saved-comparisons-table";
  const dispatch = useDispatch();
  const [showCompareModal, setShowCompareModal] = useState<boolean>(false);
  const [currentComparisonSelected, setCurrentComparisonSelected] =
    useState<any>();
  const savedComparisonsListState = useSelector(
    (state: RootStore) => state.comparisonState
  );
  const [sortInfo, setSortInfo] = useState<any>({
    sortOrder: null,
    sortBy: null,
  });
  const [showBold, setShowBold] = useState(true);
  const commonsState = useSelector((state: RootStore) => state.commons);
  const currentFilterType = Object.keys(comparisonTableFilter).find(
    (key) =>
      key ===
      savedComparisonsListState.savedComparisonsFetch.pageInfo.filterBy
  );

  const getKeyFromValue = (value: string) => {
    return Object.keys(comparisonTableFilter).find(
      (key) => (comparisonTableFilter as any)[key] === value
    );
  };
  const tableHeaders = [
    {
      text: "my-searches-name",
      isFormattedText: true,
      sortId: "ComparisonName",
      filter: {
        activeFilter:
          savedComparisonsListState.savedComparisonsFetch.pageInfo.filterBy ===
            currentFilterType &&
          savedComparisonsListState.savedComparisonsFetch.pageInfo
            .filterText !== "",
        modalPosition: "right",
        isFiltering:
          savedComparisonsListState.currentFilter === "my-searches-name",
        currentFilterText:
          getKeyFromValue(comparisonTableFilter.ComparisonName) === currentFilterType
            ? savedComparisonsListState.savedComparisonsFetch.pageInfo
                .filterText
            : "",
        setFilterPop: (filter: string) => {
          dispatch(comparisonFilter(filter));
        },
        handleClose: () => {
          dispatch(comparisonFilter(""));
        },
        handleApply: (filterText: string) => {
          const payload = {
            ...DEFAULT_SAVED_COMPARISONS_PAYLOAD,
            sortBy: sortInfo.sortBy,
            sortOrder: sortInfo.sortOrder,
            filterBy: getKeyFromValue(comparisonTableFilter.ComparisonName),
            filterText: filterText,
          };

          dispatch(filterComparisons(payload));
        },
      },
    },
    {
      text: "my-searches-description",
      isFormattedText: true,
      filter: {
        activeFilter:
          savedComparisonsListState.savedComparisonsFetch.pageInfo.filterBy ===
            currentFilterType &&
          savedComparisonsListState.savedComparisonsFetch.pageInfo
            .filterText !== "",
        modalPosition: "right",
        isFiltering:
          savedComparisonsListState.currentFilter === "my-searches-description",
        currentFilterText:
          getKeyFromValue(comparisonTableFilter.ComparisonDescription) === currentFilterType
            ? savedComparisonsListState.savedComparisonsFetch.pageInfo
                .filterText
            : "",
        setFilterPop: (filter: string) => {
          dispatch(comparisonFilter(filter));
        },
        handleClose: () => {
          dispatch(comparisonFilter(""));
        },
        handleApply: (filterText: string) => {
          const payload = {
            ...DEFAULT_SAVED_COMPARISONS_PAYLOAD,
            sortBy: sortInfo.sortBy,
            sortOrder: sortInfo.sortOrder,
            filterBy: getKeyFromValue(
              comparisonTableFilter.ComparisonDescription
            ),
            filterText: filterText,
          };

          dispatch(filterComparisons(payload));
        },
      },
    },
    {
      text: "my-searches-date-created",
      hide: true,
      isFormattedText: true,
      sortId: "CreatedDate",
      filter: {
        activeFilter:
          savedComparisonsListState.savedComparisonsFetch.pageInfo.filterBy ===
            currentFilterType &&
          savedComparisonsListState.savedComparisonsFetch.pageInfo
            .filterText !== "",
        modalPosition: "right",
        isFiltering:
          savedComparisonsListState.currentFilter ===
          "my-searches-date-created",
        currentFilterText:
          getKeyFromValue(comparisonTableFilter.CreatedDate) === currentFilterType
            ? savedComparisonsListState.savedComparisonsFetch.pageInfo
                .filterText
            : "",
        setFilterPop: (filter: string) => {
          dispatch(comparisonFilter(filter));
        },
        handleClose: () => {
          dispatch(comparisonFilter(""));
        },
        handleApply: (filterText: string) => {
          const dateRange = filterText.split(" - ");
          const payload = {
            ...DEFAULT_SAVED_COMPARISONS_PAYLOAD,
            sortBy: sortInfo.sortBy,
            sortOrder: sortInfo.sortOrder,
            filterBy: getKeyFromValue(comparisonTableFilter.CreatedDate),
            filterText: filterText,
            startDate: dateRange[0],
            endDate: dateRange[1],
          };

          dispatch(filterComparisons(payload));
        },
        dateRange: true,
      },
    },
    {
      text: "my-comparisons-last-saved",
      isFormattedText: true,
      sortId: "LastUpdatedDate",
      filter: {
        activeFilter:
          savedComparisonsListState.savedComparisonsFetch.pageInfo.filterBy ===
            currentFilterType &&
          savedComparisonsListState.savedComparisonsFetch.pageInfo
            .filterText !== "",
        modalPosition: "right",
        isFiltering:
          savedComparisonsListState.currentFilter ===
          "my-comparisons-last-saved",
        currentFilterText:
          getKeyFromValue(comparisonTableFilter.LastUpdatedDate) === currentFilterType
            ? savedComparisonsListState.savedComparisonsFetch.pageInfo
                .filterText
            : "",
        setFilterPop: (filter: string) => {
          dispatch(comparisonFilter(filter));
        },
        handleClose: () => {
          dispatch(comparisonFilter(""));
        },
        handleApply: (filterText: string) => {
          const dateRange = filterText.split(" - ");
          const payload = {
            ...DEFAULT_SAVED_COMPARISONS_PAYLOAD,
            sortBy: sortInfo.sortBy,
            sortOrder: sortInfo.sortOrder,
            filterBy: getKeyFromValue(comparisonTableFilter.LastUpdatedDate),
            filterText: filterText,
            startDate: dateRange[0],
            endDate: dateRange[1],
          };

          dispatch(filterComparisons(payload));
        },
        dateRange: true,
      },
    },
    {
      text: "saved-shortlist.items",
      isFormattedText: true,
      hide: true,
    },
    {
      text: "saved-shortlist.shared-by",
      isFormattedText: true,
      sortId: "SharedBy",
    },
    {
      text: "my-searches-shared-with",
      isFormattedText: true,
    },
    {
      text: "my-comparisons.collaborators",
      isFormattedText: true,
    },
    {
      text: "saved-shortlist.date-shared",
      isFormattedText: true,
      sortId: "SharedDate",
      hide: true,
    },

    {
      text: "my-searches-auto-delete",
      isFormattedText: true,
    },
    {
      text: "saved-shortlist.actions",
      isFormattedText: true,
    },
  ];

  useEffect(() => {
    let timeout = setTimeout(() => {
      setShowBold(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch]);

  useEffect(() => {
    //reset the current selected comparison back to undefined when the modal is not active
    if (!showCompareModal) {
      setCurrentComparisonSelected(undefined);
    }
  }, [showCompareModal]);

  useEffect(() => {
    if (
      savedComparisonsListState.refreshComparisonsList &&
      !savedComparisonsListState.editComparisonPopUp &&
      !savedComparisonsListState.deleteComparisonPopUP
    ) {
      dispatch(loadSavedComparisonsList(DEFAULT_SAVED_COMPARISONS_PAYLOAD));
      dispatch(refreshComparisonsList(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedComparisonsListState.refreshComparisonsList, dispatch]);

  let savedComparisons =
    savedComparisonsListState.savedComparisonsFetch.comparisons;

  const [comparisonIdToShowItemsTooltip, setComparisonIdToShowItemsTooltip] =
    useState<number>(-1);

  const [showEditComparisonModal, setShowEditComparisonModal] =
    useState<boolean>(false);

  const [showDeleteComparisonModal, setShowDeleteComparisonModal] =
    useState<boolean>(false);

  const onClickShareCompareIconRow = (comparison: any) => {
    setCurrentComparisonSelected(comparison);
    setShowCompareModal(true);
  };

  const onSort = (sortId: string) => {
    if (!commonsState.showCalendar) {
      let newSortOrder = sortInfo.sortOrder;

      if (sortId === sortInfo.sortBy) {
        newSortOrder =
          sortInfo.sortOrder === SORT_ORDERS.DESC
            ? SORT_ORDERS.ASC
            : SORT_ORDERS.DESC;
      } else {
        newSortOrder = SORT_ORDERS.DESC;
      }

      const payload = {
        ...savedComparisonsListState.savedComparisonsFetch.pageInfo,
        sortBy: sortId,
        sortOrder: newSortOrder,
      };

      setSortInfo({ sortBy: sortId, sortOrder: newSortOrder });
      dispatch(loadsavedComparisonsSortList(payload));
    }
  };

  const shareCompareHandler = (
    sharedWith: any[],
    includeNotes: boolean,
    includeTags: boolean,
    IsCollaboration: number
  ) => {
    const comparisonName = currentComparisonSelected.comparisonName;
    const comparisonID = currentComparisonSelected.comparisonId;
    const sourceComparisonId = currentComparisonSelected.comparisonId;

    dispatch(
      shareCompareList({
        comparisonName,
        comparisonID,
        sharedWith,
        sourceComparisonId,
        includeNotes,
        includeTags,
        IsCollaboration,
      })
    );
    setShowCompareModal(false);
  };

  function getAutoDelDays(lastRemainingDays: number) {
    let className = "";
    if (lastRemainingDays < 8 && lastRemainingDays > 3) {
      className = `${BLOCK}__autoDelete7`;
    } else if (lastRemainingDays <= 3) {
      className = `${BLOCK}__autoDeleteLessThan7`;
    }
    return (
      <span className={className}>
        {lastRemainingDays > 1 ? (
          `${lastRemainingDays} days left `
        ) : lastRemainingDays === 1 ? (
          `${lastRemainingDays} day left `
        ) : lastRemainingDays === null ? (
          ""
        ) : (
          <span>
            {"Less than ..."}{" "}
            <Tooltip position={TooltipPosition.right}>
              <div>{"Less than a day left."}</div>
            </Tooltip>
          </span>
        )}
      </span>
    );
  }

  const renderHeaderItemContents = (item: any) => {
    let icon;
    const contents = (
      <>
        {item.text && (
          <div
            className={`${BLOCK}__header-text`}
            data-test={`${item.text}`}
            data-testid={`${item.text}`}
          >
            {item.isFormattedText && item.isTooltip ? (
              <div className={`${BLOCK}__header-tooltip`}>
                <span id={item.text}>
                  <FormattedMessage id={item.text} />
                </span>
                <div className={`${BLOCK}__info-icon`}>
                  <Tooltip position={TooltipPosition.left}>
                    <FormattedMessage id="my-searches-auto-delete-message" />
                  </Tooltip>
                  <Icon name="information" height={18} width={18} />
                </div>
              </div>
            ) : (
              <span id={item.text}>
                <FormattedMessage id={item.text} />
              </span>
            )}

            {item.filter && (
              <>
                <>
                  <div
                    className={classNames(`filter`, {
                      [`filter__active`]:
                        item.filter.activeFilter || item.filter.isFiltering,
                    })}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      item.filter.setFilterPop(item.text);
                    }}
                    data-testid="filter-button"
                  >
                    <Icon name="filter" height={14} width={14} />
                  </div>
                  {item.filter.isFiltering && (
                    <div
                      className={classNames(`filter-modal`, {
                        [`filter-modal--right`]:
                          item.filter.modalPosition === "right",
                        [`filter-modal--left`]:
                          item.filter.modalPosition === "left",
                      })}
                    >
                      <FilterPopup
                        iconName="cross"
                        headerId={item.text}
                        currentFilterText={item.filter.currentFilterText}
                        handleClose={() => item.filter.handleClose()}
                        handleApply={(e: any, filterText: string) => {
                          item.filter.handleApply(filterText);
                        }}
                        dateRange={item.filter.dateRange}
                        data-test="filter-popup"
                      />
                    </div>
                  )}
                </>
              </>
            )}
          </div>
        )}
      </>
    );

    if (item.sortId) {
      const className =
        sortInfo.sortBy === item.sortId
          ? `${BLOCK}__header-inner-content ${BLOCK}__header-button ${BLOCK}__header-button--sorted`
          : `${BLOCK}__header-inner-content ${BLOCK}__header-button`;
      if (
        sortInfo.sortBy === item.sortId &&
        sortInfo.sortOrder &&
        sortInfo.sortOrder === "asc"
      ) {
        icon = (
          <Icon
            name="sort-ascending"
            width={16}
            height={16}
            className={`${BLOCK}__sort-icon`}
          />
        );
      } else if (
        sortInfo.sortBy === item.sortId &&
        sortInfo.sortOrder &&
        sortInfo.sortOrder === "desc"
      ) {
        icon = (
          <Icon
            name="sort-descending"
            width={16}
            height={16}
            className={`${BLOCK}__sort-icon`}
          />
        );
      }

      return (
        <div
          className={className}
          role="button"
          data-testid="sort-button"
          onClick={() => onSort(item.sortId)}
        >
          {icon}
          {contents}
        </div>
      );
    } else {
      return <div className={`${BLOCK}__header-inner-content`}>{contents}</div>;
    }
  };
  //This functionality is currently disabled
  /* istanbul ignore next */
  const onMouseOverTooltip = (comparisonId: number) => {
    const comparisonIndex =
      savedComparisonsListState.savedComparisonsFetch.comparisons.findIndex(
        (oldComparison: any) => oldComparison.comparisonId === comparisonId
      );
    if (
      savedComparisonsListState.savedComparisonsFetch.comparisons[
        comparisonIndex
      ].comparisonItems.length === 0
    ) {
      dispatch(loadComparisonItems(comparisonId));
    } else {
      dispatch(showItemsTooltip(true));
    }
    setComparisonIdToShowItemsTooltip(comparisonId);
  };
  //This functionality is currently disabled
  /* istanbul ignore next */
  const onMouseLeaveTooltip = () => {
    dispatch(showItemsTooltip(false));
    setComparisonIdToShowItemsTooltip(-1);
  };
  //This functionality is currently disabled
  /* istanbul ignore next */
  const getItemsTooltip = () => {
    const comparisonIndex =
      savedComparisonsListState.savedComparisonsFetch.comparisons.findIndex(
        (oldComparison: any) =>
          oldComparison.comparisonId === comparisonIdToShowItemsTooltip
      );
    return (
      <Tooltip position={TooltipPosition.left}>
        <>
          <div style={{ textAlign: "left", fontWeight: 600 }}>
            <FormattedMessage id="saved-comparisons.tooltip" />
          </div>
          <div style={{ textAlign: "left" }}>
            <FormattedMessage id="saved-comparisons.tooltip.header" />
          </div>
          {savedComparisonsListState.savedComparisonsFetch.comparisons[
            comparisonIndex
          ].comparisonItems?.map((item: ComparisonItem, index: any) => (
            <div style={{ textAlign: "left" }} key={`${index}__body_item`}>
              <li key={`${index}__body_list_item`}>
                {item.reportTitle || item.sectionName}
              </li>
            </div>
          ))}
        </>
      </Tooltip>
    );
  };

  const editComparisonHandle = (name: string, description: string) => {
    let payload = {
      comparisonId: savedComparisonsListState.savedComparison.comparisonId,
      comparisonName: name,
      comparisonDescription: description,
    };
    dispatch(editComparison(payload));
  };

  const deleteComparisonHandle = (comparisonId: number) => {
    dispatch(deleteComparison(comparisonId));
    dispatch(
      addToastMessage({
        description: <FormattedMessage id="comparison.delete.success" />,
      })
    );
    setShowDeleteComparisonModal(false);
  };

  const onScroll = (e: any) => {
    if (
      e.target.scrollHeight <=
        e.target.scrollTop + e.target.clientHeight + ScrollOffset &&
      !savedComparisonsListState.nextPageLoading &&
      savedComparisonsListState.savedComparisonsFetch.comparisons &&
      savedComparisonsListState.savedComparisonsFetch.comparisons.length > 0
    ) {
      const { pageNumber, totalPageCount } =
        savedComparisonsListState.savedComparisonsFetch.pageInfo;
      if (pageNumber < totalPageCount) {
        const payload = {
          ...DEFAULT_SAVED_COMPARISONS_PAYLOAD,
          pageNumber: pageNumber + 1,
          sortBy: sortInfo.sortBy,
          sortOrder: sortInfo.sortOrder,
        };
        dispatch(loadSavedComparisonsListNextPage(payload));
      }
    }
  };

  const getDescription = (description: any) => {
    return (
      <div className={`${BLOCK}__descriptionTooltip`}>
        <span
          className={`${BLOCK}__name-text ${BLOCK}__hover-tooltip ${BLOCK}__name-tooltip`}
        >
          {description.length > 25
            ? description.slice(0, 25) + "..."
            : description}

          {description.length > 25 ? (
            <Tooltip position={TooltipPosition.right}>{description}</Tooltip>
          ) : (
            ""
          )}
        </span>
      </div>
    );
  };

  const getUserIcon = (user: any, index: number = 0) => {
    return (
      <span
        className={`${BLOCK}__user-icon ${BLOCK}__user-icon--tooltip`}
        key={`${BLOCK}__${index}`}
      >
        {getUserInitials(user)}
        <Tooltip position={TooltipPosition.right}>
          <>
            {user.firstName} {user.lastName}
            {user.userRole > 0 ? (
              <>
                <br />
                Role: {CONTENT_BENCHMARK_USER_ROLES[user.userRole]}
              </>
            ) : null}
          </>
        </Tooltip>
      </span>
    );
  };

  const multipleUserIcons = (users: any) => {
    const iconsToShow: number = 2;
    let moreIcon = null;
    if (users.length > iconsToShow) {
      moreIcon = (
        <span className={`${BLOCK}__user-icon ${BLOCK}__more-users-icon`}>
          +{users.length - iconsToShow}
          <Tooltip position={TooltipPosition.right}>
            {users.slice(iconsToShow).map((u: any, index: number) => (
              <span key={`${BLOCK}-${index}`}>
                <span className={`${BLOCK}__user-icon`}>
                  {getUserInitials(u)}
                </span>
                <span>
                  {u.firstName} {u.lastName}
                  {u.userRole
                    ? ` - ${CONTENT_BENCHMARK_USER_ROLES[u.userRole]}`
                    : null}
                </span>
              </span>
            ))}
          </Tooltip>
        </span>
      );
    }
    return (
      <>
        {users.slice(0, iconsToShow).map(getUserIcon)}
        {moreIcon}
      </>
    );
  };

  const getFilterName = (type: string) => {
    return (comparisonTableFilter as any)[type] || "";
  };

  const getCollaborators = (sharedWith: any, createdUserDetail: any) => {
    const filteredUsers = sharedWith.filter((u: any) => u.userRole !== 0);
    const userIcons = createdUserDetail
      ? [createdUserDetail, ...filteredUsers]
      : filteredUsers;
    return filteredUsers.length > 0 ? (
      multipleUserIcons(userIcons)
    ) : (
      <Icon name="chevron-minus" width={20} height={20} />
    );
  };

  return (
    <div
      className={`${BLOCK}__table-wrapper`}
      data-testid="comparisons-table-container"
      onScroll={onScroll}
    >
      <div className={`${BLOCK}__table`}>
        <table className={`${BLOCK}`}>
          <thead>
            <tr>
              <td className={`${BLOCK}__header`}>
                <div className={`${BLOCK}__results-count`}>
                  <FormattedMessage
                    id={
                      savedComparisonsListState.savedComparisonsFetch
                        .comparisons?.length === 1
                        ? "my-comparisons.count.singular"
                        : "my-comparisons.count.plural"
                    }
                    values={{
                      count:
                        savedComparisonsListState.savedComparisonsFetch.pageInfo
                          .totalRecordCount,
                    }}
                  />
                </div>
                {savedComparisonsListState.savedComparisonsFetch.pageInfo
                  .filterBy &&
                  savedComparisonsListState.savedComparisonsFetch.pageInfo
                    .filterText && (
                    <ActiveFilterTag
                      filterText={`${getFilterName(
                        savedComparisonsListState.savedComparisonsFetch.pageInfo
                          .filterBy
                      )}: ${
                        savedComparisonsListState.savedComparisonsFetch.pageInfo
                          .filterText
                      }`}
                      handleRemove={() =>
                        dispatch(
                          filterComparisons({
                            ...DEFAULT_SAVED_COMPARISONS_PAYLOAD,
                            sortBy: sortInfo.sortBy,
                            sortOrder: sortInfo.sortOrder,
                          })
                        )
                      }
                    />
                  )}
              </td>
            </tr>
            <tr className={`${BLOCK}__heading-text`}>
              {tableHeaders.map((item, index) =>
                item.hide ? null : (
                  <th
                    className={`${BLOCK}__heading-content`}
                    key={`${index}__head`}
                  >
                    {renderHeaderItemContents(item)}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {savedComparisons?.map((comparison: any, index: number) => (
              <tr
                className={classNames(`${BLOCK}__link`, {
                  [`${BLOCK}__link--newItem`]:
                    comparison.lastAccessedDate === null &&
                    comparison.sharedBy &&
                    showBold,
                })}
                key={`${index}_${comparison.comparisonId}__body`}
              >
                <td
                  onClick={() => {
                    history.push(`comparison/${comparison.comparisonId}`);
                  }}
                  data-testid="saved-searches-description"
                  className={`${BLOCK}__name`}
                >
                  <div className={`${BLOCK}__descriptionTooltip`}>
                    <span
                      className={`${BLOCK}__name-text ${BLOCK}__hover-tooltip ${BLOCK}__name-tooltip`}
                    >
                      {comparison.comparisonName &&
                      comparison.comparisonName.length > 18
                        ? comparison.comparisonName.slice(0, 18) + "..."
                        : comparison.comparisonName}

                      {comparison.comparisonName &&
                      comparison.comparisonName.length > 18 ? (
                        <Tooltip position={TooltipPosition.right}>
                          {comparison.comparisonName}
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </td>
                <td
                  onClick={() => {
                    history.push(`comparison/${comparison.comparisonId}`);
                  }}
                  data-testid="saved-searches-description"
                  className={`${BLOCK}__description`}
                >
                  {comparison.comparisonDescription ? (
                    getDescription(comparison.comparisonDescription)
                  ) : (
                    <Icon name="chevron-minus" width={20} height={20} />
                  )}
                </td>
                {tableHeaders.filter(
                  (h) => h.text === "my-searches-date-created" && !h.hide
                ).length ? (
                  <td
                    onClick={() => {
                      history.push(`comparison/${comparison.comparisonId}`);
                    }}
                    data-test="saved-searches-dateCreated"
                  >
                    {moment(comparison.createdDate).format("YYYY-MM-DD")}
                  </td>
                ) : null}
                <td
                  onClick={() => {
                    history.push(`comparison/${comparison.comparisonId}`);
                  }}
                >
                  {comparison.lastUpdatedDate ? (
                    moment(comparison.lastUpdatedDate).format("YYYY-MM-DD")
                  ) : (
                    <Icon name="chevron-minus" width={20} height={20} />
                  )}
                </td>
                {tableHeaders.filter(
                  (h) => h.text === "saved-shortlist.items" && !h.hide
                ).length ? (
                  <td
                    data-testid="saved-searches-description"
                    onClick={() => {
                      history.push(`comparison/${comparison.comparisonId}`);
                    }}
                  >
                    <div className={`${BLOCK}__items`}>
                      <div
                        className={`${BLOCK}__doc-icon`}
                        //This functionality is currently disabled

                        // onMouseOver={(e) => {
                        //   onMouseOverTooltip(comparison.comparisonId);
                        // }}

                        // onMouseLeave={(e) => {
                        //   onMouseLeaveTooltip();
                        // }}
                        data-testid="doc-icon"
                      >
                        {comparisonIdToShowItemsTooltip ===
                          comparison.comparisonId &&
                          savedComparisonsListState.showItemsTooltip &&
                          getItemsTooltip()}
                        <Icon name="documents2" height={18} width={18} />
                      </div>
                      <div className={`saved-shortlist-table__items-text`}>
                        {" "}
                        {comparison.comparisonItemCount}{" "}
                      </div>
                    </div>
                  </td>
                ) : null}
                <td
                  onClick={() => {
                    history.push(`comparison/${comparison.comparisonId}`);
                  }}
                  className={`${BLOCK}__shared-by`}
                  data-testid="saved-searches-description"
                >
                  {comparison.sharedBy && comparison.sharedBy.firstName ? (
                    getUserIcon(comparison.sharedBy)
                  ) : (
                    <Icon name="chevron-minus" width={20} height={20} />
                  )}
                </td>
                <td
                  onClick={() => {
                    history.push(`comparison/${comparison.comparisonId}`);
                  }}
                  className={`${BLOCK}__shared-with`}
                  data-testid="saved-searches-description"
                >
                  {comparison.sharedWith.filter((u: any) => u.userRole === 0)
                    .length > 0 ? (
                    multipleUserIcons(
                      comparison.sharedWith.filter((u: any) => u.userRole === 0)
                    )
                  ) : (
                    <Icon name="chevron-minus" width={20} height={20} />
                  )}
                </td>
                <td
                  onClick={() => {
                    history.push(`comparison/${comparison.comparisonId}`);
                  }}
                  className={`${BLOCK}__shared-with`}
                  data-testid="saved-searches-description"
                >
                  {getCollaborators(
                    comparison.sharedWith,
                    comparison.createdUserDetail
                  )}
                </td>
                {tableHeaders.filter(
                  (h) => h.text === "saved-shortlist.date-shared" && !h.hide
                ).length ? (
                  <td
                    onClick={() => {
                      history.push(`comparison/${comparison.comparisonId}`);
                    }}
                    className={`${BLOCK}__shared-date`}
                    data-testid="saved-searches-description"
                  >
                    {comparison.sharedDate ? (
                      comparison.sharedDate ? (
                        moment(comparison.sharedDate).format("YYYY-MM-DD")
                      ) : (
                        ""
                      )
                    ) : (
                      <Icon name="chevron-minus" width={20} height={20} />
                    )}
                  </td>
                ) : null}
                <td className={`${BLOCK}__autoDelete`}>
                  {comparison.remainingDays !== null ? (
                    getAutoDelDays(comparison.remainingDays)
                  ) : (
                    <Icon name="chevron-minus" width={20} height={20} />
                  )}
                </td>
                <td className={`${BLOCK}__outside-td`}>
                  <div className={`${BLOCK}__icons`}>
                    <span
                      className={`${BLOCK}__share`}
                      data-test="share-icon"
                      onClick={() => {
                        onClickShareCompareIconRow(comparison);
                      }}
                    >
                      <Tooltip position={TooltipPosition.top}>
                        <FormattedMessage id="saved-shortlist.share" />
                      </Tooltip>
                      <Icon name="share" height={24} width={24} />
                    </span>
                    <span
                      className={`${BLOCK}__edit`}
                      data-testid="edit-icon"
                      onClick={() => {
                        dispatch(savedComparison(comparison));
                        dispatch(editComparisonPopUp(true));
                        setShowEditComparisonModal(true);
                      }}
                    >
                      <Tooltip position={TooltipPosition.top}>
                        <FormattedMessage id="saved-shortlist.edit" />
                      </Tooltip>
                      <Icon name="pencil" height={24} width={24} />
                    </span>
                    <span
                      className={classNames(`${BLOCK}__delete`, {
                        [`${BLOCK}__delete--disabled`]:
                          [
                            comparison.createdUserDetail
                              ? comparison.createdUserDetail
                              : {},
                            ...comparison.sharedWith,
                          ].filter(
                            (u: any) =>
                              u.userRole === 2 &&
                              u.userId === commonsState.currentUser.userId
                          ).length > 0,
                      })}
                      data-testid="delete-icon"
                      onClick={() => {
                        if (
                          [
                            comparison.createdUserDetail
                              ? comparison.createdUserDetail
                              : {},
                            ...comparison.sharedWith,
                          ].filter(
                            (u: any) =>
                              u.userRole === 2 &&
                              u.userId === commonsState.currentUser.userId
                          ).length === 0
                        ) {
                          dispatch(savedComparison(comparison));
                          dispatch(deleteComparisonPopUp(true));
                          setShowDeleteComparisonModal(true);
                        }
                      }}
                    >
                      <Tooltip position={TooltipPosition.top}>
                        <FormattedMessage id="saved-shortlist.delete" />
                      </Tooltip>
                      <Icon name="remove1" height={24} width={24} />
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {savedComparisonsListState.nextPageLoading && (
          <div className={`${BLOCK}__spinner-container`}>
            <img
              className={`${BLOCK}__spinner`}
              src={Spinner}
              alt={"loading"}
            />
          </div>
        )}
        {showCompareModal && (
          <ShareToUsersModal
            setShowShare={setShowCompareModal}
            shareShortlist={() => {}}
            setShowSearchModal={() => {}}
            shareSearch={() => {}}
            shareCompare={shareCompareHandler}
            shareType={shareItemType.compare}
            currentComparison={currentComparisonSelected}
          />
        )}
        {showEditComparisonModal && (
          <EditComparison
            setShowModal={setShowEditComparisonModal}
            editComparison={editComparisonHandle}
          />
        )}
        {showDeleteComparisonModal && (
          <DeleteModal
            title="comparison.delete.title"
            message={
              <>
                <FormattedMessage id="comparison.delete.message" />
                <span
                  style={{ fontWeight: 600 }}
                >{` "${savedComparisonsListState.savedComparison.comparisonName}"`}</span>
                ?
              </>
            }
            handleCloseModal={() => {
              dispatch(resetSavedComparison());
              dispatch(deleteComparisonPopUp(false));
              setShowDeleteComparisonModal(false);
            }}
            deleteItem={() =>
              deleteComparisonHandle(
                savedComparisonsListState.savedComparison.comparisonId
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export default SavedComparisonsTable;
