import { BaseModel, errorModel } from "../../utils/redux.utils";
//import { SecSearchResultsReport, EsgSearchResultsReport} from '../search/search.results.model'
import { User } from "../commons.model";
import { shortlistItemType } from "../../utils/constants";

export interface ShortlistResult {
  shortlistId: number;
  esgReport: any;
  sectionItem: any;
  reportId: number | null;
  filingId: number | null;
  sectionId: number | null;
  shortlistItemType: number;
  contentKeywords: string;
  displayOrder: number;
  sharedBy: User;
  sharedWith: User[];
}

export interface ShortlistState {
  error?: errorModel;
  //shortlistResults: ShortlistResult[];
  currentShortlistId: number;
  loading: boolean;
  results: ShortList;
  currentFilter: string;
  errorRename: boolean;
  saveShortList: boolean;
  saveNewShortList: boolean;
  nextPageLoading: boolean;
}

export interface Response {
  data: boolean;
  messages: [];
}

export class ShortlistModel extends BaseModel {
  constructor() {
    super({
      //shortlistResults: [],
      currentShortlistId: 0,
      loading: false,
      results: null,
      currentFilter: null,
      errorRename: false,
      saveShortList: false,
      saveNewShortList: false,
      nextPageLoading: false,
    });
  }

  setLoading = (loading: boolean): ShortlistState => this.merge({ loading });
  setEsgLoading = (loading: boolean): ShortlistState => this.merge({ loading });
  resetShortList = (): ShortlistState =>
    this.merge({
      currentShortlistId: null,
      loading: false,
      results: null,
    });
  setErrorRename = (): ShortlistState =>
    this.merge({ errorRename: !this.state.errorRename });
  setSaveSuccess = (saveShortList: boolean): ShortlistState =>
    this.merge({ saveShortList });
  setSaveNewShortList = (saveNewShortList: boolean): ShortlistState =>
    this.merge({ saveNewShortList });

  setResults = (results: ShortList): ShortlistState => {
    results.hasSameReportTypes =
      results.shortlistItems.every(
        (report: any) => report.shortlistItemType === shortlistItemType.ESG
      ) ||
      results.shortlistItems.every(
        (report: any) => report.shortlistItemType === shortlistItemType.SEC
      );
    results.activeShortlistItems = results.shortlistItems;

    results.activeShortlistItems = results.activeShortlistItems.slice(0, 25);
    results.pageInfo.totalFilteredCount = results.shortlistItems.length;
    results.pageInfo.totalPageCount = 25;
    return this.merge({ results, loading: false });
  };

  setNextPageLoading = (nextPageLoading: boolean): ShortlistState =>
    this.merge({ nextPageLoading });

  toggleSelectAll = (results: ShortList): ShortlistState => {
    return this.merge({ results });
  };

  setCurrentFilter = (currentFilter: string): ShortlistState =>
    this.merge({ currentFilter });
}

export type PageInfo = {
  sortBy: string;
  sortOrder: string;
  pageNumber: number;
  pageSize: number;
};

export type ShortList = {
  shortlistId: number;
  shortlistName?: string;
  displayOrder: number;
  isCurrent: boolean;
  createdBy: number;
  pageInfo: ShortListPageInfo;
  shortlistItems: ShortListReport[];
  selectedItems: number[];
  activeShortlistItems: ShortListReport[];
  hasSameReportTypes: boolean;
  shortListItemIds: any;
  createdDate: string;
  lastUpdatedDate?: string;
  reportList: ShortListReportList;
  secFilingList: ShortListSecFilingList;
  commentNoteId: number;
};

export type ShortListPageInfo = {
  totalRecordCount: number;
  totalSelectedCount: number;
  totalFilteredCount: number;
  pageNumber: number;
  pageSize: number;
  totalPageCount: number;
  sortBy: string;
  sortOrder: string;
  filterBy: string;
  filterText: string;
  currentFilterType: number;
};

export type ShortListReportList = {
  pageInfo: ShortListPageInfo;
  reports: ShortListReport[];
};

export type ShortListReport = {
  reportId: number;
  reportTitle: string;
  reportFileName: string;
  reportYear: number;
  reportStatus: number;
  sourceOperationTypeId?: number;
  processedDate?: string;
  externalDocumentId?: string;
  containerCode?: string;
  memberFirmCode?: string;
  keywords?: string;
  reportFormType: ShortListReportFormType;
  formTypeId: number;
  formTypeName: string;
  reportCompany: ShortListReportCompany;
  companyId: number;
  companyName: string;
  noOfHits: any;
  governingBoards: any;
  createdDate: string;
  lastUpdatedDate?: string;
  elementId: number;
  sectionId: number;
  filingId: number;
  isSelected?: boolean;
  shortlistId: number;
  shortlistItemDocumentType: string;
  shortlistItemId: number;
  shortlistItemPeriod: string;
  shortlistItemTitle: string;
  commentNoteId: number;
  esgRatingValue: string;
  governanceScore: number;
};

export type ShortListReportFormType = {
  documentCategoryId: number;
  formTypeId: number;
  formTypeName: string;
  formTypeNumber?: number;
  loadType: number;
  amendedFormType?: string;
  parentId?: number;
  displayOrder: number;
  createdDate: string;
  lastUpdatedDate?: string;
};

export type ShortListReportCompany = {
  companyId: number;
  companyName: string;
  cikNumber: number;
  ticker?: string;
  industry: ShortListReportCompanyIndustry;
  industryId: number;
  auditor: ShortListReportCompanyAuditor;
  auditorId: number;
  externalCompanyId: number;
  mrStockPriceDate?: string;
  mrFinancialsDate?: string;
  mrfyStockPriceDate?: string;
  mrfyFinancialsDate?: string;
  mrMarketCap: number;
  mrRevenue: number;
  mrfyMarketCap: number;
  mrfyRevenue: number;
  industryIdOverriden: boolean;
  isNameSetManually: boolean;
  isTickerSetManually: boolean;
  isAuditorSetManually: boolean;
  isForeignPrivateIssuer: boolean;
  parentCompanyID: number;
  createdDate: string;
  lastUpdatedDate?: string;
};

export type ShortListReportCompanyIndustry = {
  industryId: number;
  industryName?: string;
  sectors?: string;
  createdDate: string;
  lastUpdatedDate?: string;
};

export type ShortListReportCompanyAuditor = {
  auditorId: number;
  externalAuditorId: number;
  auditorName?: string;
  auditorNumber: number;
  aA_Auditor_Key: number;
  isNameSetManually: boolean;
  createdDate: string;
  lastUpdatedDate?: string;
};

export type ShortListSecFilingList = {
  pageInfo: ShortListPageInfo;
  secFilings: ShortListReport[];
};

export type ShortListSecFiling = {
  filingId: number;
  secFilingCompany: any;
  industryId: number;
  auditor: any;
  companyName: string;
  documentSection: ShortListSecDocumentSection[];
  secFilingFormType: any;
  formType: string;
  year: number;
  period: string;
};

export type ShortListSecDocumentSection = {
  id: number;
  name?: string;
  sectionName?: string;
};
