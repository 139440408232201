import { Tag } from "services/tags/tags.model";
import { BaseModel, errorModel } from "utils/redux.utils";

export interface SearchResultsState {
  error?: errorModel;
  esgLoading: boolean;
  esgResults: EsgReportsSearchResults[];
  secLoading: boolean;
  secResults: SecReportsSearchResults[];
  selectedSectionToc: number;
  tocLoading: boolean;
  tocResults: TOCResults;
  esgNextPageLoading: boolean;
  secNextPageLoading: boolean;
  esgSortLoading: boolean;
  secSortLoading: boolean;
  esgShortlists: number[];
  secShortlists: number[];
  tocShortlists: number[];
  searchPayloadESG: SearchPayload;
  searchPayloadSEC: SearchPayload;
  esgRatingTrack: boolean;
  esgGovernanceTrack: boolean;
  showRequestUploadPanel: boolean;
  requestUploadReportSuccess: boolean;
}

export class SearchResultsModel extends BaseModel {
  constructor() {
    super({
      esgLoading: false,
      esgResults: null,
      secLoading: false,
      secResults: null,
      selectedSectionToc: null,
      tocLoading: false,
      tocResults: null,
      esgNextPageLoading: false,
      secNextPageLoading: false,
      escSortLoading: false,
      secSortLoading: false,
      tocShortlists: [],
      esgShortlists: [],
      secShortlists: [],
      esgRatingTrack: false,
      esgGovernanceTrack: false,
      searchPayloadESG: {
        contentText: "",
        formTypeNames: [],
        years: [],
        companyIds: [],
        industryIds: [],
        auditorIds: [],
        governingBoardIds: [],
      },
      searchPayloadSEC: {
        contentText: "",
        formTypeNames: [],
        years: [],
        companyIds: [],
        industryIds: [],
        auditorIds: [],
        governingBoardIds: [],
      },
      showRequestUploadPanel: false,
      requestUploadReportSuccess: false,
    });
  }

  setEsgLoading = (esgLoading: boolean): SearchResultsState =>
    this.merge({ esgLoading });

  setSecLoading = (secLoading: boolean): SearchResultsState =>
    this.merge({ secLoading });

  setEsgResults = (esgResults: EsgReportsSearchResults): SearchResultsState =>
    this.merge({
      esgResults: esgResults ? [esgResults] : [],
      esgShortlists: esgResults.shortListItemIds
        ? esgResults.shortListItemIds
        : [],
      esgLoading: false,
      esgSortLoading: false,
    });

  setSecResults = (secResults: SecReportsSearchResults): SearchResultsState =>
    this.merge({
      secResults: secResults ? [secResults] : [],
      secShortlists: secResults.shortListItemIds
        ? secResults.shortListItemIds
        : [],
      secLoading: false,
      secSortLoading: false,
    });
  setEsgRatingTrack = (esgRatingTrack: boolean): SearchResultsState =>
    this.merge({ esgRatingTrack });
  setEsgGocernanceTrack = (esgGovernanceTrack: boolean): SearchResultsState =>
    this.merge({ esgGovernanceTrack });
  setESGShortlistItemIds = (shortListItemIds: number[]): SearchResultsState =>
    this.merge({ esgShortlists: shortListItemIds });

  setSECShortlistItemIds = (shortListItemIds: number[]): SearchResultsState =>
    this.merge({ secShortlists: shortListItemIds });

  setTOCLoading = (tocLoading: boolean): SearchResultsState =>
    this.merge({ tocLoading });

  setTOCResults = ({
    res,
    sectionId,
  }: {
    res: TOCResults;
    sectionId: number;
  }): SearchResultsState => {
    return this.merge({
      tocResults: res,
      selectedSectionToc: sectionId,
      tocShortlists: res.data.shortListItemIds ? res.data.shortListItemIds : [],
    });
  };

  setSelectedTOCSection = (selectedTOCSection: number): SearchResultsState => {
    return this.merge({
      selectedSectionToc: selectedTOCSection,
    });
  };

  appendEsgResults = (
    esgResults: EsgReportsSearchResults
  ): SearchResultsState => {
    const currentResults = this.state.esgResults;
    return this.merge({
      esgResults: [...currentResults, esgResults],
      esgShortlists: [
        ...this.state.esgShortlists,
        ...esgResults.shortListItemIds,
      ],
      esgNextPageLoading: false,
    });
  };

  appendSecResults = (
    secResults: EsgReportsSearchResults
  ): SearchResultsState => {
    const currentResults = this.state.secResults;
    return this.merge({
      secResults: [...currentResults, secResults],
      secShortlists: [
        ...this.state.secShortlists,
        ...secResults.shortListItemIds,
      ],
      secNextPageLoading: false,
    });
  };

  setSecNextPageLoading = (secNextPageLoading: boolean): SearchResultsState =>
    this.merge({ secNextPageLoading });

  setEsgNextPageLoading = (esgNextPageLoading: boolean): SearchResultsState =>
    this.merge({ esgNextPageLoading });

  setEsgSortLoading = (esgSortLoading: boolean): SearchResultsState =>
    this.merge({ esgSortLoading });

  setSecSortLoading = (secSortLoading: boolean): SearchResultsState =>
    this.merge({ secSortLoading });
  resetSearch = (): SearchResultsState =>
    this.merge({
      esgLoading: false,
      esgResults: null,
      secLoading: false,
      secResults: null,
      tocLoading: true,
      tocResults: null,
      esgNextPageLoading: false,
      secNextPageLoading: false,
      escSortLoading: false,
      secSortLoading: false,
      tocShortlists: [],
      esgShortlists: [],
      secShortlists: [],
    });

  setEsgShortlist = (reportId: number): SearchResultsState => {
    let updatedArray: number[] = [];
    let found = false;
    if (this.state.esgShortlists.length !== 0) {
      this.state.esgShortlists.forEach((item: number) => {
        if (item !== reportId) {
          updatedArray.push(item);
        } else found = true;
      });
    }
    return this.merge({
      esgShortlists: !found
        ? updatedArray.length === 0
          ? [reportId]
          : [...updatedArray, reportId]
        : updatedArray.length === 0
        ? []
        : updatedArray,
    });
  };

  setSecShortlist = (elementId: number): SearchResultsState => {
    let updatedArray: number[] = [];
    let found = false;
    if (this.state.secShortlists.length !== 0) {
      this.state.secShortlists.forEach((item: number) => {
        if (item !== elementId) {
          updatedArray.push(item);
        } else found = true;
      });
    }
    return this.merge({
      secShortlists: !found
        ? updatedArray.length === 0
          ? [elementId]
          : [...updatedArray, elementId]
        : updatedArray.length === 0
        ? []
        : updatedArray,
    });
  };

  setTocShortlist = (elementId: number): SearchResultsState => {
    let updatedArray: number[] = [];
    let found = false;

    if (this.state.tocShortlists.length !== 0) {
      this.state.tocShortlists.forEach((item: number) => {
        if (item !== elementId) {
          updatedArray.push(item);
        } else found = true;
      });
    }

    return this.merge({
      tocShortlists: !found
        ? updatedArray.length === 0
          ? [elementId]
          : [...updatedArray, elementId]
        : updatedArray.length === 0
        ? []
        : updatedArray,
    });
  };

  setSearchPayloadESG = (searchPayloadESG: SearchPayload): SearchResultsState =>
    this.merge({ searchPayloadESG });

  setSearchPayloadSEC = (searchPayloadSEC: SearchPayload): SearchResultsState =>
    this.merge({ searchPayloadSEC });

  setShowRequestUploadPanel = (
    showRequestUploadPanel: boolean
  ): SearchResultsState => this.merge({ showRequestUploadPanel });

  setRequestUploadReportSuccess = (
    requestUploadReportSuccess: boolean
  ): SearchResultsState => this.merge({ requestUploadReportSuccess });
}

export type TOCResults = {
  data: {
    sections: TOCData;
    shortListItemIds: number[];
  };
  messages: string[];
};

export type TOCData = {
  id: number;
  name: string;
  isKeywordExists: boolean;
  elementId: number;
  childSections:
    | never[]
    | {
        id: number;
        name: string;
        isKeywordExists: boolean;
        childSections: {
          id: number;
          name: string;
          isKeywordExists: boolean;
          childSections: {
            id: number;
            name: string;
            isKeywordExists: boolean;
            childSections: never[];
            hasContent: boolean;
            disclosure: boolean;
            policy: boolean;
            filingSecLink: null;
            elementId: number;
            companyName: string;
            subsidiaries: never[];
            title: string;
            category: string;
            documentSectionPath: (
              | {
                  sectionId: number;
                  sectionName: string;
                  level: number;
                  topicName: null;
                }
              | {
                  sectionId: number;
                  sectionName: string;
                  level: number;
                  topicName: string;
                }
            )[];
            form: string;
            periodFocus: null;
            year: null;
            auditor: string;
            filingDate: string;
            filingId: number;
            wordCount: number;
            fleschKincaidReadabilitySentence: string;
            filingType: string;
            communicationId: number;
            topicsList: never[];
            topicCount: number;
            isCamSection: boolean;
            isItemPartOfShortlist: boolean;
          }[];
          hasContent: boolean;
          disclosure: boolean;
          policy: boolean;
          filingSecLink: null;
          elementId: number;
          companyName: string;
          subsidiaries: never[];
          title: string;
          category: string;
          documentSectionPath: (
            | {
                sectionId: number;
                sectionName: string;
                level: number;
                topicName: null;
              }
            | {
                sectionId: number;
                sectionName: string;
                level: number;
                topicName: string;
              }
          )[];
          form: string;
          periodFocus: null;
          year: null;
          auditor: string;
          filingDate: string;
          filingId: number;
          wordCount: number;
          fleschKincaidReadabilitySentence: string;
          filingType: string;
          communicationId: number;
          topicsList: never[];
          topicCount: number;
          isCamSection: boolean;
          isItemPartOfShortlist: boolean;
        }[];
        hasContent: boolean;
        disclosure: boolean;
        policy: boolean;
        filingSecLink: null;
        elementId: number;
        companyName: string;
        subsidiaries: never[];
        title: string;
        category: string;
        documentSectionPath: (
          | {
              sectionId: number;
              sectionName: string;
              level: number;
              topicName: null;
            }
          | {
              sectionId: number;
              sectionName: string;
              level: number;
              topicName: string;
            }
        )[];
        form: string;
        periodFocus: null;
        year: null;
        auditor: string;
        filingDate: string;
        filingId: number;
        wordCount: number;
        fleschKincaidReadabilitySentence: string;
        filingType: string;
        communicationId: number;
        topicsList: never[];
        topicCount: number;
        isCamSection: boolean;
        isItemPartOfShortlist: boolean;
      }[];
  filingId: number;
  filingType: string;
  isItemPartOfShortlist: boolean;
}[];

export type EsgReportsSearchResults = {
  highlightKeywords: [];
  pageInfo: SearchResultsPageInfo;
  reports: EsgSearchResultsReport[];
  shortListItemIds: number[];
  messages: string[];
};

export type SearchResultsPageInfo = {
  totalRecordCount: number;
  pageNumber: number;
  pageSize: number;
  totalPageCount: number;
  sortBy: string;
  sortOrder: string;
};

export type EsgSearchResultsReport = {
  companyId: string;
  companyName: string;
  reportId: number;
  reportTitle: string;
  formTypeId: string;
  formTypeName: string;
  keywords: Keywords[];
  noOfHits: SearchResultsHits[];
  reportYear: number;
  isItemPartOfShortlist: boolean;
  contentKeywords?: string;
  // For page selection feature
  reportType?: number;
  pageSelection?: string;
  baseReportId?: number;
  esgReport: EsgSearchResultsReport;
  // esgRatingValue?: string;
  // governanceScore?: number;
  reportCompany?: {
    corpGovernanceScore: number;
    ivaCompanyRating: string;
    corpGovernanceReportUrl: string;
    ivaCompanyRatingReportUrl: string;
  };
  tagsList?: Tag[];
};

export type Keywords = {
  keywordTextContent: string;
  pageNumber: number;
  locations: any[];
  colorCode?: string;
};

export type SearchResultsHits = {
  pageNumber: number;
  location: number[];
};

export type SecReportsSearchResults = {
  pageInfo: SearchResultsPageInfo;
  secFilings: SecSearchResultsReport[];
  shortListItemIds: number[];
  messages: string[];
};

export type SecSearchResultsReport = {
  filingId: number;
  elementId: number;
  companyName: string;
  formType: string;
  year: number;
  documentSection: SecSearchResultsDocumentSection[];
  isItemPartOfShortlist: boolean;
  contentKeywords: string;
  sectionName:string;
};

export type SecSearchResultsDocumentSection = {
  sectionId: number;
  sectionName: string;
  level: number;
  topicName?: string;
};

export type SearchPayload = {
  contentText: string;
  formTypeNames: number[];
  years: number[];
  companyIds: number[];
  industryIds: number[];
  auditorIds: number[];
  governingBoardIds: number[];
};
