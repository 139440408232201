import React, { useState, ChangeEvent, useEffect } from "react";
import { RootStore } from "services/store.service";
import { useDispatch, useSelector } from "react-redux";
import {
  loadDocumentViewerSECStatements,
  setSelectedDisclosureElementId,
} from "../../services/document-viewer/document-viewer.service";
import {
  createShortlist,
  removeShortlistItem,
} from "../../services/shortlist/shortlist.service";
import { TOCData } from "services/search/search.results.model";
import TableHeader from "./table-header";
import Icon from "../shared/icon/icon";
import classNames from "classnames";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Checkbox from "components/shared/checkbox/checkbox";
import { setComparisonsSelectedList } from "services/saved-comparisons/saved-comparisons.service";
import { comparisonItemTypes } from "utils/constants";
import { ComparisonItem } from "services/saved-comparisons/saved-comparisons.model";

let init = 1;
let search = false;

const TableOfContents = () => {
  const BLOCK = "TableOfContents";

  const dispatch = useDispatch();
  const searchResults = useSelector((state: RootStore) => state.searchResults);
  const documentViewerState = useSelector(
    (state: RootStore) => state.documentViewer
  );
  const shortlistResults = useSelector((state: RootStore) => state.shortlist);

  const comparisonState = useSelector(
    (state: RootStore) => state.comparisonState
  );

  // Setting the input data into state
  const [formattedData, setFormattedData] = useState<TOCData>(
    searchResults.tocResults?.data?.sections
  );
  const [selectedAll, setselectedAll] = useState(false);
  const [allItems, setAllItems] = useState([]);
  // Render Component
  const [render, setRender] = useState<JSX.Element[]>([]);
  // Decide which row to render
  const [toggle, setToggle] = useState<boolean[]>([]);
  // Control Collapse and Expand
  const [collapse, setCollapse] = useState(true);
  // Yellow line to show if keyword exists
  const [keyword, setKeyword] = useState<boolean[]>([]);
  // Decide which row is the active tab
  // Can be used to open up respective section with the id (not filing id, since everything returned would have the same filing id)
  const [activeTab, setActiveTab] = useState<number>(0);
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    if (documentViewerState.selectedTOCTab)
      setActiveTab(documentViewerState.selectedTOCTab);
    dispatch(setSelectedDisclosureElementId(0));
  }, [documentViewerState.selectedTOCTab, dispatch]);

  // Setting the data for TOC component whenever searchResults.tocResults.data changes
  useEffect(() => {
    if (searchResults.tocResults && searchResults.tocResults.data !== null) {
      setFormattedData(searchResults.tocResults.data.sections);
      if (searchResults.selectedSectionToc === null) {
        setActiveTab(searchResults.tocResults.data.sections[0].elementId);
      }
      // Removing Old data if present in the mounted component
      setKeyword([]);
      setToggle([]);
      setCollapse(true);
      init = 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults.tocResults]);

  useEffect(() => {
    if (activeTab !== undefined) {
      scrollTo(activeTab);
    }
  }, [documentViewerState.selectedDisclosure, activeTab]);

  const scrollTo = (item: number) => {
    document.getElementById(`${item}`)?.scrollIntoView();
  };

  const mapSelectedItem = (item: any) => ({
    sectionId: item.sectionId,
    reportTitle: item.name,
    comparisonItemType: comparisonItemTypes.SEC,
    contentKeywords: searchResults.searchPayloadESG.contentText,
  });

  const onCheckboxChange = (item: any) => {
    let selectedList: Partial<ComparisonItem>[] = [
      ...comparisonState.selectedList,
    ];
    if (isSelected(item.sectionId)) {
      selectedList = selectedList.filter(
        ({ sectionId }) => sectionId !== item.sectionId
      );
    } else {
      selectedList.push(mapSelectedItem(item));
    }

    dispatch(setComparisonsSelectedList(selectedList));

    if (selectedAll) {
      setselectedAll(!selectedAll);
    }
    if (!selectedAll && selectedList.length === allItems.length) {
      setselectedAll(!selectedAll);
    }
  };

  const isSelected = (itemId: number): boolean =>
    comparisonState.selectedList.filter(
      (item: ComparisonItem) => item.sectionId === itemId
    ).length > 0;

  const updateAllSelected = () => {
    setselectedAll(!selectedAll);
    let selectedList: Partial<ComparisonItem>[] = [
      ...comparisonState.selectedList,
    ];
    if (selectedAll) {
      selectedList = selectedList.filter(
        (item) =>
          allItems.filter(({ sectionId }) => sectionId === item.sectionId)
            .length === 0
      );
    } else {
      selectedList = [
        ...selectedList,
        ...allItems
          .filter(({ sectionId }) => !isSelected(sectionId))
          .map(mapSelectedItem),
      ];
    }

    dispatch(setComparisonsSelectedList(selectedList));
  };

  // Finding the elementId for the activeTab, can be used to dispatch the sectionId as clicked
  useEffect(() => {
    //dispatch(setSelectedDisclosure(report));
    let elementId = 0;
    try {
      formattedData!.forEach(
        (item: {
          name: string;
          elementId: number;
          childSections: any[];
          isKeywordExists: boolean;
        }) => {
          if (item.elementId === activeTab) {
            // Determining the elementId of the selected Tab
            elementId = item.elementId;
            throw new Error();
          }
          if (item.childSections.length !== 0) {
            item.childSections.forEach(
              (item2: {
                id: number;
                elementId: number;
                childSections: any[];
              }) => {
                if (item2.elementId === activeTab) {
                  elementId = item2.elementId;
                  throw new Error();
                }
                if (item2.childSections.length !== 0) {
                  item2.childSections.forEach(
                    (item3: {
                      id: number;
                      elementId: number;
                      childSections: any[];
                    }) => {
                      if (item3.elementId === activeTab) {
                        elementId = item3.elementId;
                        throw new Error();
                      }
                      if (item3.childSections.length !== 0) {
                        item3.childSections.forEach(
                          (item4: {
                            id: number;
                            elementId: number;
                            childSections: any[];
                          }) => {
                            if (item4.elementId === activeTab) {
                              elementId = item4.elementId;
                              throw new Error();
                            }
                          }
                        );
                      }
                    }
                  );
                }
              }
            );
          }
        }
      );
    } catch (e) {
      dispatch(
        loadDocumentViewerSECStatements(
          elementId,
          searchResults.searchPayloadSEC.contentText
        )
      );
    }
  }, [
    activeTab,
    formattedData,
    searchResults.searchPayloadSEC.contentText,
    dispatch,
    documentViewerState.selectedDisclosure,
  ]);

  const payload = {
    shortlistId: 0,
    esgReport: {
      reportId: null,
    },
    sectionItem: {
      filingId: null,
      sectionId: null,
      elementId: null,
    },
    sectionId: null,
    shortlistItemType: 1,
    contentKeywords: "",
    displayOrder: 0,
  };

  // Handler to add/remove from shortlist, dispatching to the reducer, and further to the api
  // On Load functionality from the reducer store not yet implemented
  const onShortlistClickHandler = (elementId: number) => {
    let iconValue: boolean = false;
    let itemObject: any = undefined;

    formattedData!.forEach(
      (item: { childSections: any[]; elementId: number }) => {
        if (item.elementId === elementId) {
          iconValue = !searchResults.tocShortlists.includes(elementId);
          itemObject = item;
        }
        if (item.childSections.length !== 0) {
          item.childSections.forEach(
            (item2: { childSections: any[]; elementId: number }) => {
              if (item2.elementId === elementId) {
                iconValue = !searchResults.tocShortlists.includes(elementId);
                itemObject = item2;
              }
              if (item2.childSections.length !== 0) {
                item2.childSections.forEach((item3: any) => {
                  if (item3.elementId === elementId) {
                    iconValue =
                      !searchResults.tocShortlists.includes(elementId);
                    itemObject = item3;
                  }
                  if (item3.childSections.length !== 0) {
                    item3.childSections.forEach((item4: any) => {
                      if (item4.elementId === elementId) {
                        iconValue =
                          !searchResults.tocShortlists.includes(elementId);
                        itemObject = item4;
                      }
                    });
                  }
                });
              }
            }
          );
        }
      }
    );
    if (iconValue) {
      dispatch(
        createShortlist({
          ...payload,
          sectionItem: itemObject,
          sectionId: itemObject.elementId,
          shortlistId:
            shortlistResults.results !== null &&
            shortlistResults.results.shortlistId !== 0
              ? shortlistResults.results.shortlistId
              : 0,
          contentKeywords:
            searchResults.searchPayloadSEC.contentText.length !== 0
              ? searchResults.searchPayloadSEC.contentText
              : "",
        })
      );
    } else {
      dispatch(
        removeShortlistItem({
          ...payload,
          sectionItem: itemObject,
          sectionId: itemObject.elementId,
          shortlistId:
            shortlistResults.results !== null &&
            shortlistResults.results.shortlistId !== 0
              ? shortlistResults.results.shortlistId
              : 0,
          contentKeywords:
            searchResults.searchPayloadSEC.contentText.length !== 0
              ? searchResults.searchPayloadSEC.contentText
              : "",
        })
      );
    }
  };

  // Only initial Run to render component for the first time
  useEffect(() => {
    if (init !== 1) {
      return;
    }

    // Content Mapping for Normal Rendering of the items
    const contentMapping = (
      name: string,
      tab: number,
      elementId: number,
      isChildExists: boolean,
      onIconClickHandler: (elementId: number) => void,
      activeTab: number,
      iconValue: boolean
    ) => {
      return (
        <div
          style={{
            paddingLeft: `${tab}px`,
            display: "flex",
            flexDirection: "row",
          }}
          aria-selected={elementId === activeTab}
          aria-controls={`${BLOCK}__${elementId}`}
          className={classNames(`${BLOCK}__row`, {
            [`${BLOCK}__row--selected`]: elementId === activeTab,
          })}
          key={elementId}
          data-testid="toc-row-selected"
        >
          <div
            className={`${BLOCK}__keywords`}
            style={{
              transform: `translateX(-${tab}px)`,
            }}
          >
            {
              <Checkbox
                value={isSelected(elementId)}
                onChange={() =>
                  onCheckboxChange({ sectionId: elementId, name })
                }
              />
            }
          </div>
          <span
            className={`${BLOCK}__row-icon`}
            data-testid="icon-shortlist"
            style={{ marginLeft: `-${tab - 5}px` }}
            onClick={() => {
              onShortlistClickHandler(elementId);
            }}
          >
            <Icon name={iconValue ? "remove" : "add"} height={24} width={24} />
            <Tooltip position={TooltipPosition.right}>
              <FormattedMessage
                id={`shortlist.tooltip.${iconValue ? "remove" : "add"}`}
              />
            </Tooltip>
          </span>
          <span
            onClick={() => {
              onIconClickHandler(elementId);
            }}
            data-testid="row-icon2"
            className={`${BLOCK}__row-icon2`}
            style={{ marginLeft: `${tab}px` }}
          >
            {isChildExists && (
              <Icon name="chevron-right-regular" height={14} width={14} />
            )}
          </span>
          <span
            className={`${BLOCK}__row-content`}
            style={{ marginLeft: `${tab}px` }}
            onClick={() => {
              setActiveTab(elementId);
              //setToggle((prev) => [...prev]);
            }}
            data-testid="toc-row-content"
          >
            {name}
          </span>
        </div>
      );
    };

    const onIconClickHandler = (elementId: number) => {
      let temp: boolean[] = [];
      let i = 0;
      formattedData!.forEach(
        (item: { elementId: number; childSections: any[] }) => {
          if (item.elementId === elementId) {
            temp[i] = !toggle[i];
          } else temp[i] = toggle[i];
          i++;
          if (item.childSections.length !== 0) {
            item.childSections.forEach(
              (item2: { elementId: number; childSections: any[] }) => {
                if (item2.elementId === elementId) {
                  temp[i] = !toggle[i];
                } else temp[i] = toggle[i];
                i++;
                if (item2.childSections.length !== 0) {
                  item2.childSections.forEach(
                    (item3: { elementId: number; childSections: any[] }) => {
                      if (item3.elementId === elementId) {
                        temp[i] = !toggle[i];
                      } else temp[i] = toggle[i];
                      i++;
                      if (item3.childSections.length !== 0) {
                        item3.childSections.forEach(
                          (item4: { elementId: number }) => {
                            if (item4.elementId === elementId) {
                              temp[i] = !toggle[i];
                            } else temp[i] = toggle[i];
                            i++;
                          }
                        );
                      }
                    }
                  );
                }
              }
            );
          }
        }
      );

      setToggle(temp);
    };

    let contentKeyword = false;
    let found = false;
    let sectionId = undefined;

    if (searchResults.selectedSectionToc !== null) {
      found = true;
      sectionId = searchResults.selectedSectionToc;
    }

    if (
      searchResults.searchPayloadSEC.contentText.trim().length !== 0 &&
      !found
    ) {
      contentKeyword = true;
    }

    let temp: JSX.Element[] = [];
    let temp2: boolean[] = [];
    let temp3: boolean[] = [];
    if (formattedData.length !== 0) {
      formattedData!.forEach(
        (item: {
          name: string;
          elementId: number;
          childSections: any[];
          isKeywordExists: boolean;
        }) => {
          temp.push(
            contentMapping(
              item.name,
              0,
              item.elementId,
              item.childSections.length > 0,
              onIconClickHandler,
              activeTab,
              searchResults.tocShortlists.includes(item.elementId)
            )
          );
          temp2.push(true);
          temp3.push(item.isKeywordExists);
          if (item.isKeywordExists && !found) {
            sectionId = item.elementId;
            found = true;
          }
          if (item.childSections.length !== 0) {
            item.childSections.forEach(
              (item2: {
                elementId: number;
                childSections: any[];
                isKeywordExists: boolean;
              }) => {
                temp2.push(true);
                if (item2.isKeywordExists && !found) {
                  sectionId = item2.elementId;
                  found = true;
                }
                if (item2.childSections.length !== 0) {
                  item2.childSections.forEach((item3: any) => {
                    temp2.push(true);
                    if (item3.isKeywordExists && !found) {
                      sectionId = item3.elementId;
                      found = true;
                    }
                    if (item3.childSections.length !== 0) {
                      item3.childSections.forEach(
                        (item4: {
                          elementId: number;
                          childSections: any[];
                          isKeywordExists: boolean;
                        }) => {
                          temp2.push(true);
                          if (item4.isKeywordExists && !found) {
                            sectionId = item4.elementId;
                            found = true;
                          }
                        }
                      );
                    }
                  });
                }
              }
            );
          }
        }
      );
      setRender((prev) => [...temp]);
      setToggle((prev) => [...temp2]);
      setKeyword((prev) => [...temp3]);
      if (contentKeyword && sectionId !== undefined) {
        setActiveTab(sectionId);
      }
      if (searchResults.selectedSectionToc !== null) {
        setActiveTab(searchResults.selectedSectionToc);
      }
      init = 2;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formattedData,
    toggle,
    comparisonState.selectedList,
    activeTab,
    searchResults.tocResults,
    searchResults.tocLoading,
    searchResults.searchPayloadSEC.contentText,
    searchResults.tocShortlists,
  ]);

  // Further renders based on change in Toggle State by the IconChangeHandler
  useEffect(() => {
    // Return if the user to searching, different flow taken with that, with ContentMapping customised for Search Operation
    if (search) {
      return;
    }

    // Content Mapping for Normal Rendering of the items
    const contentMapping = (
      name: string,
      tab: number,
      elementId: number,
      isChildExists: boolean,
      onIconClickHandler: (elementId: number) => void,
      activeTab: number,
      iconValue: boolean
    ) => {
      return (
        <div
          style={{
            paddingLeft: `${tab}px`,
            display: "flex",
            flexDirection: "row",
          }}
          aria-selected={elementId === activeTab}
          aria-controls={`${BLOCK}__${elementId}`}
          className={classNames(`${BLOCK}__row`, {
            [`${BLOCK}__row--selected`]: elementId === activeTab,
          })}
          key={elementId}
          data-testid="toc-row-selected"
        >
          <div
            className={`${BLOCK}__keywords`}
            style={{
              transform: `translateX(-${tab}px)`,
            }}
          >
            {
              <Checkbox
                value={isSelected(elementId)}
                onChange={() =>
                  onCheckboxChange({ sectionId: elementId, name })
                }
              />
            }
          </div>
          <span
            className={`${BLOCK}__row-icon`}
            style={{ marginLeft: `-${tab - 5}px` }}
            data-testid="icon-shortlist"
            onClick={() => {
              onShortlistClickHandler(elementId);
            }}
          >
            <Icon name={iconValue ? "remove" : "add"} height={24} width={24} />
            <Tooltip position={TooltipPosition.right}>
              <FormattedMessage
                id={`shortlist.tooltip.${iconValue ? "remove" : "add"}`}
              />
            </Tooltip>
          </span>
          <span
            onClick={() => {
              onIconClickHandler(elementId);
            }}
             data-testid="row-icon2"
            className={`${BLOCK}__row-icon2`}
            style={{ marginLeft: `${tab}px` }}
          >
            {isChildExists && (
              <Icon name="chevron-right-regular" height={14} width={14} />
            )}
          </span>
          <span
            className={`${BLOCK}__row-content`}
            style={{ marginLeft: `${tab}px` }}
            onClick={() => {
              setActiveTab(elementId);
              //setToggle((prev) => [...prev]);
            }}
            data-testid="toc-row-content"
          >
            {name}
          </span>
        </div>
      );
    };

    const onIconClickHandler = (elementId: number) => {
      let temp: boolean[] = [];
      let i = 0;
      formattedData!.forEach(
        (item: { elementId: number; childSections: any[] }) => {
          if (item.elementId === elementId) {
            temp[i] = !toggle[i];
          } else temp[i] = toggle[i];
          i++;
          if (item.childSections.length !== 0) {
            item.childSections.forEach(
              (item2: { elementId: number; childSections: any[] }) => {
                if (item2.elementId === elementId) {
                  temp[i] = !toggle[i];
                } else temp[i] = toggle[i];
                i++;
                if (item2.childSections.length !== 0) {
                  item2.childSections.forEach(
                    (item3: { elementId: number; childSections: any[] }) => {
                      if (item3.elementId === elementId) {
                        temp[i] = !toggle[i];
                      } else temp[i] = toggle[i];
                      i++;
                      if (item3.childSections.length !== 0) {
                        item3.childSections.forEach(
                          (item4: { elementId: number }) => {
                            if (item4.elementId === elementId) {
                              temp[i] = !toggle[i];
                            } else temp[i] = toggle[i];
                            i++;
                          }
                        );
                      }
                    }
                  );
                }
              }
            );
          }
        }
      );
      setToggle(temp);
    };

    let temp: JSX.Element[] = [];
    let temp3: boolean[] = [];
    let temp2: any = [];
    let i = 0;
    let set = false,
      set2 = false,
      set3 = false;
    if (formattedData.length > 0) {
      formattedData!.forEach(
        (item: {
          name: string;
          elementId: number;
          childSections: any[];
          isKeywordExists: boolean;
        }) => {
          set = false;
          temp.push(
            contentMapping(
              item.name,
              0,
              item.elementId,
              item.childSections.length > 0,
              onIconClickHandler,
              activeTab,
              searchResults.tocShortlists.includes(item.elementId)
            )
          );
          temp2.push({
            sectionId: item.elementId,
            name: item.name,
            keyword: searchResults.searchPayloadSEC.contentText,
          });
          temp3.push(item.isKeywordExists);
          if (toggle[i]) {
            set = true;
          } else {
            set = false;
            set2 = false;
            set3 = false;
          }
          i++;
          if (item.childSections.length !== 0) {
            item.childSections.forEach(
              (item2: {
                name: string;
                elementId: number;
                childSections: any[];
                isKeywordExists: boolean;
              }) => {
                set2 = false;
                if (set === true) {
                  temp.push(
                    contentMapping(
                      item2.name,
                      10,
                      item2.elementId,
                      item2.childSections.length > 0,
                      onIconClickHandler,
                      activeTab,
                      searchResults.tocShortlists.includes(item2.elementId)
                    )
                  );
                  temp2.push({
                    sectionId: item2.elementId,
                    name: item2.name,
                    keyword: searchResults.searchPayloadSEC.contentText,
                  });
                  temp3.push(item2.isKeywordExists);
                }
                if (toggle[i]) {
                  set2 = true;
                }
                i++;
                if (item2.childSections.length !== 0) {
                  item2.childSections.forEach(
                    (item3: {
                      name: string;
                      elementId: number;
                      isKeywordExists: boolean;
                      childSections: any[];
                    }) => {
                      set3 = false;
                      if (set2 && set) {
                        temp.push(
                          contentMapping(
                            item3.name,
                            20,
                            item3.elementId,
                            item3.childSections.length > 0,
                            onIconClickHandler,
                            activeTab,
                            searchResults.tocShortlists.includes(
                              item3.elementId
                            )
                          )
                        );
                        temp2.push({
                          sectionId: item3.elementId,
                          name: item3.name,
                          keyword: searchResults.searchPayloadSEC.contentText,
                        });
                        temp3.push(item3.isKeywordExists);
                      }
                      if (toggle[i]) {
                        set3 = true;
                      }
                      i++;
                      if (item3.childSections.length !== 0) {
                        item3.childSections.forEach(
                          (item4: {
                            name: string;
                            elementId: number;
                            isKeywordExists: boolean;
                          }) => {
                            if (set2 && set && set3) {
                              temp.push(
                                contentMapping(
                                  item4.name,
                                  30,
                                  item4.elementId,
                                  false,
                                  onIconClickHandler,
                                  activeTab,
                                  searchResults.tocShortlists.includes(
                                    item4.elementId
                                  )
                                )
                              );
                              temp2.push({
                                sectionId: item4.elementId,
                                name: item4.name,
                                keyword:
                                  searchResults.searchPayloadSEC.contentText,
                              });
                              temp3.push(item4.isKeywordExists);
                            }
                            i++;
                          }
                        );
                      }
                    }
                  );
                }
              }
            );
          }
        }
      );
      setAllItems(temp2);
      setKeyword(temp3);
      setRender(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    toggle,
    comparisonState.selectedList,
    formattedData,
    activeTab,
    searchResults.tocShortlists,
    searchResults.tocResults,
  ]);

  useEffect(() => {
    setToggle((prev) => [...prev]);
  }, [searchResults.tocShortlists, searchResults.tocResults]);

  // ContentMapping Function scoped for Search Document
  const contentMapping = (
    name: string,
    tab: number,
    elementId: number,
    isChildExists: boolean,
    onIconClickHandler: (elementId: number) => void,
    activeTab: number,
    iconValue: boolean
  ) => {
    return (
      <div
        style={{
          paddingLeft: `${tab}px`,
          display: "flex",
          flexDirection: "row",
        }}
        aria-selected={elementId === activeTab}
        aria-controls={`${BLOCK}__${elementId}`}
        className={classNames(`${BLOCK}__row`, {
          [`${BLOCK}__row--selected`]: elementId === activeTab,
        })}
        key={elementId}
      >
        <div
          className={`${BLOCK}__keywords`}
          style={{
            transform: `translateX(-${tab}px)`,
          }}
        >
          {
            <Checkbox
              value={isSelected(elementId)}
              onChange={() => onCheckboxChange({ sectionId: elementId, name })}
            />
          }
        </div>
        <span
          className={`${BLOCK}__row-icon`}
          style={{ marginLeft: `-${tab - 5}px` }}
          data-testid="icon-shortlist"
          onClick={() => {
            onShortlistClickHandler(elementId);
          }}
        >
          {<Icon name={iconValue ? "remove" : "add"} height={24} width={24} />}
        </span>
        <span
          onClick={() => {
            onIconClickHandler(elementId);
          }}
          data-testid="row-icon2"
          className={`${BLOCK}__row-icon2`}
          style={{ marginLeft: `${tab}px` }}
        >
          {isChildExists && (
            <Icon name="chevron-right-regular" height={14} width={14} />
          )}
        </span>
        <span
          className={`${BLOCK}__row-content`}
          style={{ marginLeft: `${tab}px` }}
          data-testid="expand-all"
          onClick={() => {
            setActiveTab(elementId);
            search = false;
            setFormattedData(searchResults.tocResults.data.sections);
            onExpandAll();
          }}
        >
          {name}
        </span>
      </div>
    );
  };

  // Filter the sections based on search input by user
  const onSearchDocument = (event: ChangeEvent<HTMLInputElement>): void => {
    const searchText = event.target.value.toLowerCase();
    search = true;
    let temp: JSX.Element[] = [];
    let temp2: boolean[] = [];

    if (searchText.length !== 0) {
      setActiveTab(0);
    }

    if (searchText === "") {
      search = false;
      setFormattedData(searchResults.tocResults.data.sections);
      onCollapseAll();
      return;
    }
    formattedData!.forEach(
      (item: {
        name: string;
        elementId: number;
        childSections: any[];
        isKeywordExists: boolean;
      }) => {
        if (item.name.toLowerCase().includes(searchText)) {
          temp.push(
            contentMapping(
              item.name,
              0,
              item.elementId,
              item.childSections.length > 0,
              onIconClickHandler,
              activeTab,
              searchResults.tocShortlists.includes(item.elementId)
            )
          );
          temp2.push(item.isKeywordExists);
        }
        if (item.childSections.length > 0) {
          item.childSections.forEach(
            (item2: {
              name: string;
              elementId: number;
              childSections: any[];
              isKeywordExists: boolean;
            }) => {
              if (item2.name.toLowerCase().includes(searchText)) {
                temp.push(
                  contentMapping(
                    item2.name,
                    10,
                    item2.elementId,
                    item2.childSections.length > 0,
                    onIconClickHandler,
                    activeTab,
                    searchResults.tocShortlists.includes(item2.elementId)
                  )
                );
                temp2.push(item2.isKeywordExists);
              }
              if (item2.childSections.length > 0) {
                item2.childSections.forEach(
                  (item3: {
                    name: string;
                    elementId: number;
                    isKeywordExists: boolean;
                    childSections: any[];
                  }) => {
                    if (item3.name.toLowerCase().includes(searchText)) {
                      temp.push(
                        contentMapping(
                          item3.name,
                          20,
                          item3.elementId,
                          item3.isKeywordExists,
                          onIconClickHandler,
                          activeTab,
                          searchResults.tocShortlists.includes(item3.elementId)
                        )
                      );
                      temp2.push(item3.isKeywordExists);
                    }
                    if (item3.childSections.length > 0) {
                      item3.childSections.forEach(
                        (item4: {
                          name: string;
                          elementId: number;
                          isKeywordExists: boolean;
                        }) => {
                          if (item4.name.toLowerCase().includes(searchText)) {
                            temp.push(
                              contentMapping(
                                item4.name,
                                30,
                                item4.elementId,
                                false,
                                onIconClickHandler,
                                activeTab,
                                searchResults.tocShortlists.includes(
                                  item4.elementId
                                )
                              )
                            );
                            temp2.push(item4.isKeywordExists);
                          }
                        }
                      );
                    }
                  }
                );
              }
            }
          );
        }
      }
    );
    if (temp.length === 0) {
      search = false;
      return setRender([]);
    }
    setRender(temp);
    setKeyword(temp2);
    setCollapse((collapse) => !collapse);
  };

  // Expanding All items after selection of an item after Search, used only from onSearchDocument
  const onExpandAll = () => {
    let temp: boolean[] = [];
    toggle.forEach((item, index) => {
      temp[index] = true;
    });
    setToggle(temp);
    setCollapse((collapse) => !collapse);
  };

  // Function to expand and collapse all items
  const onCollapseAll = () => {
    let temp: boolean[] = [];
    toggle.forEach((item, index) => {
      temp[index] = !collapse;
    });
    setToggle(temp);
    setCollapse(!collapse);
  };

  // Mapping for on show full document
  const onShowFullDocument = () => {
    // This is where the dispatch call for the entire report would be there
    if (
      documentViewerState.SECStatements !== null &&
      documentViewerState.SECStatements.filingSecLink !== null
    )
      openInNewTab(documentViewerState.SECStatements.filingSecLink);
  };

  // Mapping for icon beside each function to expand it.
  // Changes the toggle state which is then tracked by useEffect to render the component
  const onIconClickHandler = (elementId: number) => {
    let temp: boolean[] = [];
    let i = 0;
    formattedData!.forEach(
      (item: { elementId: number; childSections: any[] }) => {
        if (item.elementId === elementId) {
          temp[i] = !toggle[i];
        } else temp[i] = toggle[i];
        i++;
        if (item.childSections.length !== 0) {
          item.childSections.forEach(
            (item2: { elementId: number; childSections: any[] }) => {
              if (item2.elementId === elementId) {
                temp[i] = !toggle[i];
              } else temp[i] = toggle[i];
              i++;
              if (item2.childSections.length !== 0) {
                item2.childSections.forEach(
                  (item3: { elementId: number; childSections: any[] }) => {
                    if (item3.elementId === elementId) {
                      temp[i] = !toggle[i];
                    } else temp[i] = toggle[i];
                    i++;
                    if (item3.childSections.length !== 0) {
                      item3.childSections.forEach(
                        (item4: { elementId: number }) => {
                          if (item4.elementId === elementId) {
                            temp[i] = !toggle[i];
                          } else temp[i] = toggle[i];
                          i++;
                        }
                      );
                    }
                  }
                );
              }
            }
          );
        }
      }
    );

    setToggle(temp);
  };

  return (
    <div className={`${BLOCK}__container`} data-testid={`${BLOCK}__container`}>
      {/* Following Portion is that of the TOC */}
      <div className={BLOCK}>
        {/* Header Component */}
        <div>
          <div>
            <TableHeader
              BLOCK={BLOCK}
              onSearchDocument={onSearchDocument}
              onCollapseAll={onCollapseAll}
              onShowFullDocument={onShowFullDocument}
              collapseValue={collapse}
              activeTab={activeTab}
              secLinkPresent={
                documentViewerState.SECStatements !== null &&
                documentViewerState.SECStatements.filingSecLink !== null &&
                documentViewerState.SECStatements.filingSecLink.trim()
                  .length !== 0
              }
              updateAllSelected={updateAllSelected}
              selectedAll={selectedAll}
            />
          </div>
          {/* Row Component */}
          <div className={`${BLOCK}__row`} data-testid={`${BLOCK}__row`}>
            {render.map((item, index) => {
              return (
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  key={item.key}
                >
                  {item}
                  {keyword[index] && (
                    <span className={`${BLOCK}__row-exists`}></span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableOfContents;
