import ClimateScoreChart from "components/visualizations/charts/climate.score.chart";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import {
  ESG_IVA_RATING_TILE_PEER_INDUSTRY,
  INSIGHT_BENCHMARK_IDS,
  INSIGHT_BENCHMARK_TYPE,
} from "utils/constants";
import { useTileContext } from "../tile.context";
import TableChart from "components/visualizations/charts/table-chart";
import { isNumeric } from "utils/functions";
const Climate = () => {
  const {
    metadata,
    associatedMetrics,
    associatedMetricsDisplayNames,
    metadata: { benchmarkType, isDetailedView, benchmarkTileType },
    dataset,
    isTableViewActive,
  } = useTileContext();
  const BLOCK = "climate";
  const getFieldWidth = (type: number, value: any) => {
    if (value > 10) {
      return value;
    } else {
      return value * 10;
    }
  };
  function generatePercentage(type: any, value: any) {
    if (type === "Score") {
      const score = ["D-", "D", "C-", "C", "B-", "B", "A-", "A"];
      const eachScore = 100 / score.length;
      return (score.indexOf(value) + 1) * eachScore;
    }
    if (type === "CDPClimateChangeScore") {
      const score = ["F", "D-", "D", "C-", "C", "B-", "B", "A-", "A"];
      const eachScore = 100 / score.length;
      return (score.indexOf(value) + 1) * eachScore;
    }
  }
  function mapScoreData() {
    let colorCode: any = metadata.benchmarkMetadata.colorCodes[0].split(",");
    let tmpData: any = [];
    if (dataset.length === 0)
      return {
        metrics: "",
        companyName: "",
        benchmarkType: "",
        data: [],
      };
    if (benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY) {
      if (
        (
          dataset.find((d: InsightData) => d.isBaseCompany) ?? { metrics: [] }
        ).metrics.every((elem: InsightMetricData) => !elem.metricValue)
      )
        return {
          metrics: "",
          companyName: "",
          benchmarkType: "",
          data: [],
        };
      let newArray: any = [];
      let baseCompany = dataset.find((d: InsightData) => d.isBaseCompany) ?? {
        metrics: [],
        companyName: "",
        isBaseCompany: true,
      };
      baseCompany.metrics.forEach((m: InsightMetricData, ind: number) => {
        newArray.push({
          fieldValueLimit:
            metadata.benchmarkMetadata.valueLimitProperties[ind]
              .fieldValueLimit,
          companyName: baseCompany.companyName,
          description: metadata.associatedMetricsTooltips
            ? metadata.associatedMetricsTooltips.filter(
                (tooltip: any) => tooltip.associatedMetric === m.metricKey
              )
            : [],
          fieldName: m.metricName,
          fieldValue:
            m.metricValue !== "" && m.metricValue !== null
              ? m.metricValue
              : "*",
          feildWidth: !isNaN(m.metricValue as any)
            ? m.metricValue !== "" && m.metricValue !== null
              ? getFieldWidth(metadata.benchmarkTileType, m.metricValue)
              : ""
            : generatePercentage(m.metricKey, m.metricValue),
          baseWidth: "",
          isBaseCompany: baseCompany.isBaseCompany,
          tooltip: m.metricValue + " " + baseCompany.companyName,
          colors: metadata.benchmarkMetadata.colorCodes[ind].split(","),
        });
      });
      newArray = newArray.reduce((acc: any[], cur: any) => {
        const existingLimit = acc.findIndex(
          (item: any) => item.fieldValueLimit === cur.fieldValueLimit
        );
        if (existingLimit !== -1) {
          acc[existingLimit].data.push({
            baseWidth: cur.baseWidth,
            fieldName: cur.fieldName,
            companyName: cur.companyName,
            description: cur.description,
            feildWidth: cur.feildWidth,
            fieldValue: cur.fieldValue,
            isBaseCompany: cur.isBaseCompany,
            tooltip: cur.tooltip,
            colors: cur.colors,
          });
        } else {
          acc.push({
            fieldValueLimit: cur.fieldValueLimit,
            data: [
              {
                baseWidth: cur.baseWidth,
                fieldName: cur.fieldName,
                companyName: cur.companyName,
                description: cur.description,
                feildWidth: cur.feildWidth,
                fieldValue: cur.fieldValue,
                isBaseCompany: cur.isBaseCompany,
                tooltip: cur.tooltip,
                colors: cur.colors,
              },
            ],
          });
        }
        return acc;
      }, []);
      return {
        metrics: metadata,
        tileType: benchmarkTileType,
        companyName: baseCompany,
        colors: colorCode,
        gradient: true,
        benchmarkType: metadata.benchmarkType,
        data: newArray,
      };
    } else {
      if (
        dataset.every((d: InsightData) =>
          d.metrics.every(
            (m: InsightMetricData) => !m.metricValue || m.metricValue === ""
          )
        )
      )
        return {
          metrics: "",
          companyName: "",
          benchmarkType: "",
          data: [],
        };
      if (isDetailedView) {
        tmpData = associatedMetrics.map((am: string, index: number) => {
          return {
            fieldValueLimit:
              metadata.benchmarkMetadata.valueLimitProperties[index]
                .fieldValueLimit,
            groupName: am,
            fieldName: associatedMetricsDisplayNames[index],
            description: metadata.associatedMetricsTooltips
              ? metadata.associatedMetricsTooltips.filter(
                  (tooltip: any) => tooltip.associatedMetric === am
                )
              : [],
            data: dataset.map((d: InsightData) => {
              let currentMetric = d.metrics.filter(
                (m: InsightMetricData) => m.metricKey === am
              )[0];
              return {
                companyName: d.companyName,
                fieldValue: currentMetric.metricValue
                  ? currentMetric.metricValue
                  : "*",
                colorCode: metadata.benchmarkMetadata.colorCodes.filter(
                  (color: any, ind: number) => ind === index
                ),
                baseWidth: !isNaN(currentMetric.metricValue as any)
                  ? currentMetric.metricValue !== "" &&
                    currentMetric.metricValue !== null
                    ? getFieldWidth(0, currentMetric.metricValue)
                    : ""
                  : generatePercentage(
                      currentMetric.metricName,
                      currentMetric.metricValue
                    ),
                isBaseCompany: d.isBaseCompany,
                tooltip: "",
              };
            }),
          };
        });
        tmpData = tmpData.reduce((acc: any[], cur: any) => {
          const existingLimit = acc.findIndex(
            (item: any) => item.fieldValueLimit === cur.fieldValueLimit
          );
          if (existingLimit !== -1) {
            acc[existingLimit].data.push({
              fieldName: cur.fieldName,
              description: cur.description,
              groupName: cur.groupName,
              data: cur.data,
            });
          } else {
            acc.push({
              fieldValueLimit: cur.fieldValueLimit,
              data: [
                {
                  fieldName: cur.fieldName,
                  description: cur.description,
                  groupName: cur.groupName,
                  data: cur.data,
                },
              ],
            });
          }
          return acc;
        }, []);
      } else {
        let baseCompany = dataset.find((d: InsightData) => d.isBaseCompany) ?? {
          metrics: [],
          companyName: "",
          isBaseCompany: true,
        };
        let averageCompany = dataset.find(
          (d: InsightData) =>
            d.globalCompanyId === INSIGHT_BENCHMARK_IDS.INDUSTRY ||
            d.globalCompanyId === INSIGHT_BENCHMARK_IDS.PEER
        ) ?? { metrics: [], companyName: "" };
        if (!averageCompany) {
          return {
            metrics: "",
            companyName: "",
            benchmarkType: "",
            data: [],
          };
        }
        associatedMetrics.forEach((am: string, ind: number) => {
          let currentMetricBaseCompany = baseCompany.metrics.filter(
            (m: InsightMetricData) => m.metricKey === am
          )[0];
          let currentMetricAverageCompany = averageCompany.metrics.filter(
            (m: InsightMetricData) => m.metricKey === am
          )[0];
          tmpData.push({
            fieldValueLimit:
              metadata.benchmarkMetadata.valueLimitProperties[ind]
                .fieldValueLimit,
            compScore:
              currentMetricBaseCompany.metricValue !== "" &&
              currentMetricBaseCompany.metricValue !== null
                ? currentMetricBaseCompany.metricValue
                : "*",
            companyName: baseCompany.companyName,
            fieldValue:
              currentMetricAverageCompany.metricValue !== "" &&
              currentMetricAverageCompany.metricValue !== null
                ? currentMetricAverageCompany.metricValue
                : "*",
            baseWidth: !isNaN(currentMetricAverageCompany.metricValue as any)
              ? currentMetricAverageCompany.metricValue !== ""
                ? getFieldWidth(
                    metadata.benchmarkTileType,
                    currentMetricAverageCompany.metricValue
                  )
                : ""
              : generatePercentage(
                  currentMetricAverageCompany.metricName,
                  currentMetricAverageCompany.metricValue
                ),
            isBaseCompany: baseCompany.isBaseCompany,
            tooltip: `${currentMetricBaseCompany.metricValue} ${baseCompany.companyName}`,
            description: metadata.associatedMetricsTooltips
              ? metadata.associatedMetricsTooltips.filter(
                  (tooltip: any) => tooltip.associatedMetric === am
                )
              : [],
            feildWidth: !isNaN(currentMetricBaseCompany.metricValue as any)
              ? currentMetricBaseCompany.metricValue !== "" &&
                currentMetricBaseCompany.metricValue !== null
                ? getFieldWidth(0, currentMetricBaseCompany.metricValue)
                : ""
              : generatePercentage(
                  currentMetricBaseCompany.metricName,
                  currentMetricBaseCompany.metricValue
                ),

            fieldName: [currentMetricBaseCompany.metricName],
            baseTooltip: currentMetricAverageCompany.metricValue
              ? `${currentMetricAverageCompany.metricValue} ${averageCompany.companyName}`
              : "",
            colors: metadata.benchmarkMetadata.colorCodes[ind].split(","),
          });
          dataset.forEach((d: InsightData) => {
            d.metrics.forEach((m: InsightMetricData) => {});
          });
        });
        tmpData = tmpData.reduce((acc: any[], cur: any) => {
          const existingLimit = acc.findIndex(
            (item: any) => item.fieldValueLimit === cur.fieldValueLimit
          );
          if (existingLimit !== -1) {
            acc[existingLimit].data.push({
              fieldName: cur.fieldName,
              description: cur.description,
              baseWidth: cur.baseWidth,
              baseTooltip: cur.baseTooltip,
              compScore: cur.compScore,
              companyName: cur.companyName,
              feildWidth: cur.feildWidth,
              fieldValue: cur.fieldValue,
              isBaseCompany: cur.isBaseCompany,
              tooltip: cur.tooltip,
              colors: cur.colors,
            });
          } else {
            acc.push({
              fieldValueLimit: cur.fieldValueLimit,
              data: [
                {
                  fieldName: cur.fieldName,
                  description: cur.description,
                  baseWidth: cur.baseWidth,
                  baseTooltip: cur.baseTooltip,
                  compScore: cur.compScore,
                  companyName: cur.companyName,
                  feildWidth: cur.feildWidth,
                  fieldValue: cur.fieldValue,
                  isBaseCompany: cur.isBaseCompany,
                  tooltip: cur.tooltip,
                  colors: cur.colors,
                },
              ],
            });
          }
          return acc;
        }, []);
      }
    }

    return {
      metrics: metadata.benchmarkMetadata,
      tileType: benchmarkTileType,
      gradient: true,
      colors: colorCode,
      benchmarkType: metadata.benchmarkType,
      companyName: {
        companyName: (dataset.find((d: InsightData) => d.isBaseCompany) ?? {})
          .companyName,
      },
      data: tmpData,
      isDetailView: isDetailedView,
    };
  }
  const mapTableChart = () => {
    let tmpResponse = dataset.reduce((data: any[], current: InsightData) => {
      let currentGroup: any = {
        globalCompanyId: current.globalCompanyId,
        header: current.companyName,
      };
      current.metrics.forEach((m: InsightMetricData) => {
        let metricProp =
          m.metricKey?.charAt(0).toLowerCase() + m.metricKey?.slice(1);
        currentGroup = {
          ...currentGroup,
          [metricProp]:
            m.metricValue && isNumeric(m.metricValue)
              ? parseFloat(m.metricValue).toLocaleString()
              : m.metricValue,
        };
      });
      return [
        ...data,
        {
          ...currentGroup,
        },
      ];
    }, []);
    return {
      MSCIESGRatingView: false,
      tileType: benchmarkTileType,
      labels: associatedMetrics
        .map((metric: any, i: number) => ({
          label: associatedMetricsDisplayNames
            ? associatedMetricsDisplayNames[i]
            : metric,
          id: metric?.charAt(0).toLowerCase() + metric?.slice(1),
          tooltip: metadata.associatedMetricsTooltips
            ? metadata.associatedMetricsTooltips.filter(
                (tooltip: any) => tooltip.associatedMetric === metric
              )
            : [],
        }))
        .filter(
          (label: any) =>
            label.id !== ESG_IVA_RATING_TILE_PEER_INDUSTRY.IVA_RATING_DATE
        ),
      response: tmpResponse,
    };
  };

  return !isTableViewActive ? (
    <div className={`${BLOCK}__wrapper`}>
      {" "}
      <ClimateScoreChart data={mapScoreData()} />{" "}
    </div>
  ) : (
    <TableChart data={mapTableChart()} currentInsightView={benchmarkType} />
  );
};
export default Climate;
