import {
  workSpaceId,
  reportId,
  esrsReportId,
  POWERBI_DASHBOARD,
} from "utils/constants";

import PowerbiDashboard from "components/powerbi-dashboard/powerbi-dashboard";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  settingEmbedInfo,
  settingEmbedURL,
} from "services/powerbi-dashboard/powerbi.service";

type Props = {
  currentTab: number;
};
const MainDashboard = ({ currentTab }: Props) => {
  const [currentReportId, setCurrentReportId] = useState<string>("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(settingEmbedInfo({}));
    dispatch(settingEmbedURL(""));
    let id =
      currentTab === POWERBI_DASHBOARD.HUMAN_CAPITAL ? reportId : esrsReportId;
    setCurrentReportId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {currentReportId !== "" && (
        <PowerbiDashboard
          reportId={currentReportId}
          workSpaceId={workSpaceId}
          currentTab={currentTab}
        />
      )}
    </>
  );
};
export default MainDashboard;
