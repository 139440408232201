import Icon from "components/shared/icon/icon";

const UnauthorizedPage = () => {
  const BLOCK = "unauthorized";

  return (
    <div className={`${BLOCK}`} data-testid={`${BLOCK}`}>
      <div className={`${BLOCK}__container`}>
        <Icon
          name={"loading"}
          width={50}
          height={50}
          className="loading-icon"
        />
      </div>
    </div>
  );
};

export default UnauthorizedPage;
