import Button from "components/shared/button/button";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { UserObj } from "services/searchlist/searchlist.model";
import { RootStore } from "services/store.service";
import {
  getUserInitials,
  handleMouseEnter,
  handleMouseLeave,
} from "utils/functions";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";
import { Tag, TagMetadata, TagSelected } from "services/tags/tags.model";
import { useTagContext } from "./tag.context";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import DeleteModal from "components/shared/delete-modal/delete-modal";
import { deleteTag } from "services/tags/tags.service";
import { COMPARISON_TAG_TYPE } from "utils/constants";
import { updateTag, updateTagInstance } from "services/tags/tags.api";
import { addToastMessage } from "services/commons.service";
import { sanitize } from "dompurify";
import Parser from "html-react-parser";

type Props = {
  tag: Tag;
  userDetail: UserObj;
  lastUpdatedDate: string;
  referenceId: number;
  tagMetadata: TagMetadata;
  tagDetailId?: number;
};

const TagCard = ({
  userDetail,
  lastUpdatedDate,
  tag,
  referenceId,
  tagMetadata,
  tagDetailId,
}: Props) => {
  const BLOCK = "tag-card";
  const dispatch = useDispatch();
  const mainTooltip = useSelector(
    (store: RootStore) => store.commons.mainTooltip
  );
  const descriptionRef = useRef<HTMLSpanElement | null>(null);
  const [preview, setPreview] = useState<boolean>(false);

  const [showEditOptions, setShowEditOptions] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [values, setValues] = useState({
    name: tag.tagName,
    description: tag.tagDescription,
  });
  const {
    setSelectedTag,
    selectedTag,
    setShowAllTags,
    setComparisonItemIdToUpdate,
  } = useTagContext();
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const nameInputRef = useRef<HTMLTextAreaElement>(null);
  const [showDelete, setShowDelete] = useState(false);
  const [showTagInstances, setShowTagInstances] = useState<boolean>(false);

  useEffect(() => {
    setValues((v) => ({ name: tag.tagName, description: tag.tagDescription }));
  }, [tag]);

  useEffect(() => {
    if (
      descriptionRef &&
      !preview &&
      descriptionRef.current &&
      descriptionRef.current.clientHeight &&
      descriptionRef.current.clientHeight > 32
    )
      setPreview(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionRef]);

  useEffect(() => {
    setShowTagInstances((show) =>
      show
        ? show
        : !!(
            selectedTag &&
            selectedTag.tagId === tag.tagId &&
            selectedTag.tagDetailId
          )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag]);

  const onChangeHandler = (inputName: string, inputValue: string) => {
    setValues((v) => ({
      ...v,
      [inputName]: inputValue,
    }));
    setDisabled(
      (inputName === "name" ? inputName : values.name).trim().length === 0
    );
  };

  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !disabled) {
      updateTagAction();
    }
  };

  const updateTagAction = () => {
    let payload: {
      tagId: number;
      tagName: string;
      tagDescription: string;
      tagType: number;
      displayOrder?: number;
      tagDetails: any;
    } = {
      tagId: tag.tagId,
      tagName: values.name,
      tagDescription: tagDetailId ? "" : values.description,
      tagType: COMPARISON_TAG_TYPE,
      tagDetails: tagDetailId
        ? [
            {
              tagDetailId,
              referenceId,
            },
          ]
        : [{ referenceId }],
    };

    if (tagDetailId)
      payload = {
        ...payload,
        displayOrder: tag.displayOrder,
      };

    // if tagDetailId is available update tag instance otherwise update parent tag
    const updateTagEntity = tagDetailId ? updateTagInstance : updateTag;

    updateTagEntity(payload)
      .then((data) => {
        if (data) {
          setShowEditOptions(false);
          setComparisonItemIdToUpdate(referenceId);
          setDisabled(true);
        }
      })
      .catch(() =>
        dispatch(
          addToastMessage({
            description: <FormattedMessage id="error.default" />,
          })
        )
      );
  };

  const onDeleteHandler = (tagDetailIds: any) => {
    //Payload depends on type, tag instance or parent tag

    const payload = tagDetailId
      ? [{ tagDetailId: tagDetailIds }]
      : tagDetailIds;

    dispatch(deleteTag(payload, referenceId ?? -1));
    setShowDelete(false);
  };

  const onSelectTag = () => {
    setShowAllTags([]);
    setSelectedTag((tid: TagSelected) =>
      tid?.tagId === tag.tagId &&
      (tid.tagDetailId ? tid.tagDetailId === tagDetailId : true)
        ? null
        : {
            tagId: tag.tagId,
            referenceId: referenceId,
            page: tagMetadata?.page,
            tagDetailId: tagDetailId,
          }
    );
  };

  return (
    <>
      <div
        id={`tag-panel-card-${tag.tagId}`}
        className={classNames(`${BLOCK}__container`, {
          [`${BLOCK}__container__highlight`]:
            selectedTag?.tagId === tag.tagId &&
            (selectedTag.tagDetailId
              ? selectedTag.tagDetailId === tagDetailId
              : true),
          [`${BLOCK}--nested`]: tagDetailId && tagDetailId > 0,
        })}
        data-testid="tag-card-container"
      >
        <div className={`${BLOCK}__actions`}>
          <Button
            id="btn-visible-hidden"
            onClick={onSelectTag}
            className=""
            iconName={
              selectedTag?.tagId === tag.tagId &&
              (selectedTag.tagDetailId
                ? selectedTag.tagDetailId === tagDetailId
                : true)
                ? "visible"
                : "hidden"
            }
            iconHeight={24}
            iconWidth={24}
            dataTest="btn-visible-hidden"
          />

          <>
            <Button
              id="btn-edit"
              className={`${BLOCK}__action`}
              onClick={() => setShowEditOptions(true)}
              iconName="edit-note"
              iconHeight={24}
              iconWidth={24}
              dataTest="btn-edit"
            >
              <Tooltip position={TooltipPosition.left}>
                <FormattedMessage id={"tags.edit.tooltip"} />
              </Tooltip>
            </Button>
            <Button
              id="btn-delete"
              className={`${BLOCK}__action`}
              onClick={() => {
                setShowDelete(true);
              }}
              iconName="remove1"
              iconHeight={24}
              iconWidth={24}
              dataTest="btn-delete"
            >
              <Tooltip position={TooltipPosition.left}>
                <FormattedMessage id={"saved-shortlist.delete"} />
              </Tooltip>
            </Button>
          </>
        </div>
        <div
          className={classNames({
            [`${BLOCK}__separator`]: tagDetailId && tagDetailId > 0,
          })}
        >
          {showEditOptions ? (
            <>
              <div className="create-tag__input">
                <textarea
                  ref={nameInputRef}
                  maxLength={50}
                  onChange={(e: any) =>
                    onChangeHandler(e.target.name, e.target.value)
                  }
                  onKeyDown={onEnter}
                  value={values.name}
                  name="name"
                  data-testid="nameInput"
                  placeholder={"Enter a name"}
                  className={`${BLOCK}__name-input`}
                />
              </div>
              {!tagDetailId && (
                <div className="create-tag__input">
                  <textarea
                    ref={descriptionInputRef}
                    maxLength={2000}
                    onChange={(e: any) =>
                      onChangeHandler(e.target.name, e.target.value)
                    }
                    onKeyDown={onEnter}
                    value={values.description}
                    data-testid="descriptionTextArea"
                    placeholder="Write a description"
                    className={`${BLOCK}__description-input`}
                    name="description"
                  />
                </div>
              )}

              <div className={`${BLOCK}__tag-actions`}>
                <Button
                  className={`button-secondary-small button-no-border`}
                  onClick={() => {
                    setDisabled(true);
                    setShowEditOptions(false);
                    setValues({
                      name: tag.tagName,
                      description: tag.tagDescription,
                    });
                  }}
                  dataTest="cancel-btn"
                  formattedText="tags.edit.cancel"
                />
                <Button
                  className={`button-secondary-small button-no-border`}
                  disabled={disabled}
                  onClick={() => updateTagAction()}
                  formattedText="tags.edit.save"
                  dataTest="edit-save-btn"
                />
              </div>
            </>
          ) : (
            <div className={`${BLOCK}__body`} onClick={onSelectTag}>
              {!tagDetailId && (
                <span className={`${BLOCK}__name`}>{tag.tagName}</span>
              )}
              <span
                ref={descriptionRef}
                className={classNames(`${BLOCK}__description`, {
                  [`${BLOCK}__description--preview`]: preview,
                })}
                onMouseEnter={(e) => {
                  tag.tagDescription &&
                    preview &&
                    handleMouseEnter(
                      e,
                      "span",
                      <div>{Parser(sanitize(tag.tagDescription ?? ""))}</div>,
                      "",
                      dispatch,
                      null,
                      MainTooltipPosition.LeftMiddle
                    );
                }}
                onMouseLeave={(e) =>
                  handleMouseLeave(
                    e,
                    dispatch,
                    mainTooltip.isOverTooltip
                      ? mainTooltip.isOverTooltip
                      : false
                  )
                }
                data-testid="tag-description"
              >
                {tag.tagDescription}
              </span>
            </div>
          )}
          <div className={`${BLOCK}__footer`}>
            <div className={`${BLOCK}__owner`}>
              <div className={`${BLOCK}__owner-icon`}>
                {userDetail ? getUserInitials(userDetail) : "-"}
              </div>
              <span className={`${BLOCK}__owner-name`}>{`${
                userDetail
                  ? `${userDetail.firstName} 
                    ${userDetail.lastName}`
                  : "-"
              }`}</span>
            </div>
            <span className={`${BLOCK}__date`}>
              {moment(lastUpdatedDate).isValid() &&
                moment
                  .utc(lastUpdatedDate)
                  .local()
                  .format("MM[/]DD[/]YYYY [·] h[:]mm A")}
            </span>
          </div>
          {tag.tagDetails.length ? (
            <div>
              <Button
                className={`${BLOCK}__show-all`}
                onClick={() => {
                  setShowTagInstances((state) => !state);
                  if (selectedTag?.tagDetailId) setSelectedTag(null);
                }}
                iconName="tag"
                iconHeight={24}
                iconWidth={24}
                dataTest="show-all-btn"
              >
                <FormattedMessage
                  id={`tags.${
                    showTagInstances ? "collapse" : "showall"
                  }.toggle`}
                  values={{
                    tags: tag.tagDetails?.length || 0,
                  }}
                />
              </Button>
            </div>
          ) : null}
          {showDelete && (
            <DeleteModal
              title={
                tagDetailId ? "tags.delete.instance.title" : "tags.delete.title"
              }
              message={
                <FormattedMessage
                  id={
                    tagDetailId
                      ? "tags.delete.instance.message"
                      : "searchlist.delete.message"
                  }
                  values={{ tagName: tag.tagName }}
                />
              }
              handleCloseModal={() => {
                setShowDelete(false);
              }}
              deleteItem={() =>
                onDeleteHandler(
                  tagDetailId
                    ? tagDetailId
                    : tag.tagDetails
                    ? tag.tagDetails.map((tag: any) => {
                        return { tagDetailId: tag.tagDetailId };
                      })
                    : []
                )
              }
            />
          )}
        </div>
      </div>
      {showTagInstances ? (
        <div>
          {tag.tagDetails.map((t, i) => (
            <TagCard
              key={`${tag.tagId}-child-${i}`}
              tag={{
                ...tag,
                tagDescription: t.tagMetadata?.text ?? "",
                tagDetails: [],
              }}
              userDetail={t.userDetail ?? tag.userDetail}
              lastUpdatedDate={t.lastUpdatedDate ?? tag.lastUpdatedDate}
              referenceId={t.referenceId ?? referenceId}
              tagMetadata={t.tagMetadata ?? tagMetadata}
              tagDetailId={t.tagDetailId}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default TagCard;
