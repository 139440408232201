import { Group } from "@visx/group";
import { useMemo, useState } from "react";
import { scaleBand, scaleLinear } from "@visx/scale";
import { ScaleSVG } from "@visx/responsive";
import {
  AssociatedMetricTooltips,
  GroupedLegends,
  Legend,
} from "services/dashboard/dashboard.model";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { defaultStyles, useTooltipInPortal, withTooltip } from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { GridColumns } from "@visx/grid";
import { darkGrey, lightColor } from "utils/constants";
import { AxisBottom, AxisRight } from "@visx/axis";
import { Bar, BarRounded } from "@visx/shape";
import { getMarginBasedOnMax, isNumeric, mod } from "utils/functions";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import classNames from "classnames";
import Popover from "components/shared/popover/popover";

const tooltipStyles = {
  ...defaultStyles,
  zIndex: 2,
  minWidth: 50,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
  fontSize: 15,
  width: "fit-content",
};

type Props = {
  data: GroupedLegends[];
  associatedMetricGroupTooltips?: AssociatedMetricTooltips[];
  name: string;
  index?: number;
  width?: number;
  height?: number;
  axisRange?: number;
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  showAxisDetails?: boolean;
};

type GraphProps = {
  data: Legend[];
  name: string;
  width: number;
  height: number;
  axisRange?: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  handleGraphElementClicked?: (args: { [arg: string]: any }) => void;
  showAxisDetails?: boolean;
  showBaseLineRepresenter?: boolean;
};

const leftSize = 200;

const IndividualChart = ({
  data,
  axisRange,
  name,
  width,
  height,
  margin = { top: 10, right: 15, bottom: 40, left: 5 },
  tooltipOpen,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
  showTooltip,
  handleGraphElementClicked,
  showAxisDetails,
  showBaseLineRepresenter,
}: GraphProps & WithTooltipProvidedProps<Legend>) => {
  const { containerRef, TooltipInPortal } = useTooltipInPortal();
  const [hoveredTickValue, setHoveredTickValue] = useState<string>("");
  const dataset = showAxisDetails
    ? (data as Legend[]).filter((d) => d.legendData > 0)
    : (data as Legend[]);
  const marginBasedMax = getMarginBasedOnMax(
    Math.max(
      ...dataset.map(({ legendData }) =>
        legendData && isNumeric(legendData) ? parseFloat(legendData) : 0
      ),
      0
    )
  );
  let tooltipTimeout: number;
  margin.left = showAxisDetails ? leftSize : marginBasedMax;
  margin.right = showAxisDetails ? marginBasedMax / 2 : margin.right;
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const xMaxLegend = axisRange
    ? axisRange
    : Math.max(...dataset.map((d) => d.legendData ?? 0));
  const leftOffset =
    xMaxLegend > 0
      ? 0
      : -width / 2 + 16 + (showAxisDetails ? margin.left / 2 : 0);

  const yScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, yMax],
        round: true,
        domain: dataset.map((d) => d.legendValue),
        padding: 0.3,
      }),
    [yMax, dataset]
  );

  const xScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, xMax],
        round: true,
        nice: true,
        clamp: true,
        domain: [0, xMaxLegend],
      }),
    [xMax, xMaxLegend]
  );

  return (
    <div
      className={showAxisDetails ? "multiple-chart__right-aligned" : ""}
      style={{ position: "relative" }}
    >
      <ScaleSVG width={width} height={height}>
        <Group left={margin.left + 1} top={margin.top}>
          <GridColumns
            scale={xScale}
            width={xMax}
            height={yMax}
            stroke={lightColor}
            left={leftOffset}
          />
          {showAxisDetails ? (
            <AxisRight
              strokeWidth={1}
              scale={yScale}
              stroke={lightColor}
              tickStroke={lightColor}
              numTicks={dataset.length}
              hideTicks={true}
              left={-margin.left - 10}
              tickComponent={({ formattedValue, ...tickProps }) => {
                const hasTooltip =
                  formattedValue && formattedValue.length > (leftSize - 40) / 5;
                return formattedValue ? (
                  <g>
                    <text
                      ref={
                        formattedValue === hoveredTickValue
                          ? containerRef
                          : undefined
                      }
                      onPointerEnter={() => setHoveredTickValue(formattedValue)}
                      onPointerLeave={() => setHoveredTickValue("")}
                      {...tickProps}
                      fontFamily="Open Sans"
                      fontSize={12}
                    >
                      {hasTooltip
                        ? formattedValue.slice(0, (leftSize - 40) / 5) + "..."
                        : formattedValue}
                    </text>
                    {hasTooltip && hoveredTickValue === formattedValue ? (
                      <TooltipInPortal>
                        <div
                          style={{
                            ...tooltipStyles,
                            minWidth: 200,
                          }}
                        >
                          {formattedValue}
                        </div>
                      </TooltipInPortal>
                    ) : null}
                  </g>
                ) : null;
              }}
            />
          ) : null}
          <AxisBottom
            strokeWidth={1}
            scale={xScale}
            stroke={lightColor}
            tickStroke={lightColor}
            left={showAxisDetails ? 0 : leftOffset}
            top={yMax}
            numTicks={axisRange ? axisRange : 7}
            ticksComponent={(ticks) => (
              <g>
                {ticks.ticks.map(({ formattedValue, index, to }) =>
                  formattedValue ? (
                    <g key={`text-${index}`}>
                      <text
                        x={to.x}
                        y={
                          to.y +
                          12 * (formattedValue.length > 6 ? mod(index, 2) : 0)
                        }
                        {...ticks.tickLabelProps[index]}
                      >
                        {parseInt(formattedValue)}
                      </text>
                    </g>
                  ) : null
                )}
              </g>
            )}
          />
          {dataset
            .sort((d1, d2) => d1.legendOrder - d2.legendOrder)
            .map((data, i) => {
              const barWidthTmp: number = isNumeric(data.legendData)
                ? xScale(
                    parseFloat(data.legendData) > 0 ? data.legendData : 0.1
                  )
                : xScale(1);
              const barWidth: number =
                barWidthTmp <= 1 && parseFloat(data.legendData) > 0
                  ? 1
                  : barWidthTmp;
              const barHeight = yScale.bandwidth();
              const barY: number = yScale(dataset[i].legendValue) || 0;
              const barX = 0;
              return barX >= 0 &&
                barY >= 0 &&
                barWidth >= 0 &&
                barHeight >= 0 ? (
                <BarRounded
                  right
                  radius={data.legendData ? 0 : 3}
                  key={`bar-${data.legendValue}-${i}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  style={handleGraphElementClicked ? { cursor: "pointer" } : {}}
                  strokeWidth={0.5}
                  stroke={data.legendData ? "unset" : darkGrey}
                  fillOpacity={
                    tooltipOpen && tooltipData?.legendValue !== data.legendValue
                      ? 0.4
                      : 1
                  }
                  fill={
                    data.legendData ? data.legendColor : `url(#GradientRepeat)`
                  }
                  onMouseLeave={() =>
                    (tooltipTimeout = window.setTimeout(() => {
                      hideTooltip();
                    }, 100))
                  }
                  onClick={() =>
                    handleGraphElementClicked
                      ? handleGraphElementClicked(data)
                      : null
                  }
                  onMouseMove={() => {
                    if (tooltipTimeout) clearTimeout(tooltipTimeout);
                    showTooltip({
                      tooltipData: data,
                      tooltipTop: barY,
                      tooltipLeft: barWidth / 2,
                    });
                  }}
                />
              ) : null;
            })}
          <pattern
            id={`GradientRepeat`}
            patternUnits="userSpaceOnUse"
            width="8"
            height="8"
            patternTransform="rotate(135)"
          >
            <line
              x1="0"
              y="0"
              x2="0"
              y2="8"
              stroke={darkGrey}
              strokeWidth="1"
            />
          </pattern>
        </Group>
      </ScaleSVG>
      {dataset.length &&
      !showAxisDetails &&
      dataset[0].legendData !== null &&
      dataset[0].legendData !== "*" &&
      Math.round(dataset[0].legendData).toString() !== "0" ? (
        <div
          className=""
          style={{
            height: yMax,
            left:
              leftOffset + margin.left + xScale(dataset[0].legendData ?? 0.1) ??
              0,
            top: margin.top,
            position: "absolute",
            borderLeft: `0.125rem dashed ${dataset[0].legendColor}`,
          }}
          onMouseLeave={() =>
            (tooltipTimeout = window.setTimeout(() => {
              hideTooltip();
            }, 100))
          }
          onClick={() =>
            handleGraphElementClicked
              ? handleGraphElementClicked(dataset[0])
              : null
          }
          onMouseMove={() => {
            if (tooltipTimeout) clearTimeout(tooltipTimeout);
            showTooltip({
              tooltipData: dataset[0],
              tooltipTop: margin.top,
              tooltipLeft: xScale(dataset[0].legendData ?? 0.1) ?? 0,
            });
          }}
        ></div>
      ) : null}
      {tooltipOpen && tooltipData && (
        <div
          style={{
            ...tooltipStyles,
            top: tooltipTop,
            left: tooltipLeft,
            position: "absolute",
          }}
        >
          <strong>
            {
              (tooltipData.legendTooltip
                ? tooltipData.legendTooltip
                : tooltipData.legendValue) as React.ReactNode
            }
          </strong>
        </div>
      )}
    </div>
  );
};

const MultipleColumnChart = ({
  data,
  associatedMetricGroupTooltips,
  name,
  axisRange,
  width = 1000,
  height = 1000,
  handleGraphElementClicked,
  showAxisDetails,
}: Props) => {
  const dataset = data as GroupedLegends[];
  const Bar = withTooltip<GraphProps, Legend>(IndividualChart);
  const noData = dataset.reduce(
    (acc, group) =>
      acc &&
      group.legends.reduce(
        (acc, curr) => acc && curr.legendData === null,
        true
      ),
    true
  );
  if (noData)
    return (
      <div className="speedometer__no-data">
        <FormattedMessage id="insights.speedometer.no-data" />
      </div>
    );
  return (
    <>
      <div className="multiple-chart">
        {dataset.map((group, index) => {
          const hasData = group.legends.reduce(
            (acc, curr) => acc || curr.legendData !== null,
            false
          );
          return (
            <div
              className={classNames(
                `multiple-chart__${showAxisDetails ? "bar" : "column"}`,
                { "multiple-chart__bar--show-axis": showAxisDetails }
              )}
              key={`multiple-chart-${index}`}
            >
              {showAxisDetails ? (
                <div className="multiple-chart__bar__header">
                  <span style={{ width: leftSize }}>
                    <Popover
                      displayText={group.groupName}
                      content={
                        associatedMetricGroupTooltips
                          ? associatedMetricGroupTooltips.filter(
                              (tooltip: AssociatedMetricTooltips) =>
                                tooltip.associatedMetricGroupName ===
                                group.groupName
                            )
                          : []
                      }
                      metricClassName={"multiple-chart__bar__title"}
                    />
                  </span>
                  <span className="multiple-chart__bar__subtitle">
                    {group.subtitle}
                  </span>
                </div>
              ) : null}
              {hasData ? (
                <ParentSize debounceTime={0}>
                  {({ width: visWidth, height: visHeight }: any) =>
                    Bar({
                      data: group.legends,
                      name,
                      axisRange,
                      width: visWidth,
                      height: visHeight,
                      handleGraphElementClicked,
                      showAxisDetails,
                    })
                  }
                </ParentSize>
              ) : (
                <div
                  className="speedometer__no-data"
                  style={{
                    position: "relative",
                    top: "unset",
                    transform: "unset",
                  }}
                >
                  {!showAxisDetails && "*"}
                  <FormattedMessage id="insights.speedometer.no-data" />
                </div>
              )}
              {showAxisDetails ? null : (
                <span className="multiple-chart__bar-label-container">
                  <Popover
                    metricClassName="multiple-chart__bar-label"
                    displayText={group.groupName}
                    content={
                      group.description && Array.isArray(group.description)
                        ? group.description
                        : []
                    }
                  />
                </span>
              )}
            </div>
          );
        })}
      </div>
      {dataset.reduce(
        (acc, group) =>
          acc ||
          group.legends.reduce(
            (acc, curr) => acc || !curr.legendData || curr.legendData === null,
            false
          ),
        false
      ) ? (
        <div className={"DashboardTile__no-data"}>
          <FormattedMessage id="no.data.available" />
        </div>
      ) : null}
    </>
  );
};

export default MultipleColumnChart;
