import { useEffect, useState } from "react";
import { logoutCurrentUser } from "../../services/commons.api";
import { userLogOut } from "../../services/api.service";
import { expireCookies } from "utils/functions";
import { removeItem } from "utils/localStorageFunctions";
import { LOCALSTORAGE_VARIABLE } from "utils/constants";

const Logout = () => {
  const [userLoggedOut, setUserLoggedOut] = useState(false);
  useEffect(() => {
    const logout = async () => {
      removeItem(LOCALSTORAGE_VARIABLE.closedActiveMaitenanceMessage);
      await logoutCurrentUser();
      userLogOut();
      expireCookies();
      setUserLoggedOut(true);
    };

    if (!userLoggedOut) {
      logout();
    }
  }, [userLoggedOut]);

  return <div data-test="logout-component"></div>;
};

export default Logout;
