import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { TooltipPosition } from "components/shared/tooltip/tooltip";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showTooltip } from "services/commons.service";
import { Legend } from "services/dashboard/dashboard.model";
import { RootStore } from "services/store.service";
import { BENCHMARK_TILE_TYPES, INSIGHT_BENCHMARK_TYPE } from "utils/constants";

type props = {
  data?: Legend[];
  benchmarkTileType?: number;
  isDetailedView?: boolean;
  isTableView?: boolean;
  currentInsightView?: number;
  baselineCompany?: any;
};

const TargetTileReportingYear = ({
  data,
  benchmarkTileType,
  isDetailedView,
  isTableView,
  currentInsightView,
  baselineCompany,
}: props) => {
  const BLOCK = "targetTilesContent";

  const isCompanyView = currentInsightView === INSIGHT_BENCHMARK_TYPE.COMPANY;
  const [cleanedData, setCleanedData] = useState<any>([]);
  const [emptyValues, setEmptyValues] = useState<boolean>(false);

  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const dispatch = useDispatch();

  const values = {
    //add more as needed, currently only this one called out in the PBI
    NO_DATA_AVAIL: `No Data Available`,
    QUESTION_NO_APPLICABLE: `Entity deemed question not applicable`,
  };

  const labels = ["Peers", "Industry"];

  useEffect(() => {
    if (cleanedData.length > 0) setCleanedData([]);
    if (emptyValues) setEmptyValues(false);
    if (data && data.length > 0) {
      let tmpData = data.map((item: Legend) => {
        let value: any = item.legendValue;
        let isGray = false;
        if (!item.legendValue) {
          value = isCompanyView ? values.NO_DATA_AVAIL : "*";
          isGray = true;
        } else if (
          value === "Question not applicable" ||
          value === values.QUESTION_NO_APPLICABLE
        ) {
          value = values.QUESTION_NO_APPLICABLE;
        }

        return {
          companyName: item.legendTitle,
          value: value,
          isGray: isGray,
        };
      });

      if (
        (!isTableView &&
          currentInsightView &&
          !isCompanyView &&
          !isDetailedView) ||
        (isTableView && currentInsightView === INSIGHT_BENCHMARK_TYPE.INDUSTRY)
      ) {
        tmpData = tmpData.filter(
          (val: any) =>
            val.companyName &&
            (val.companyName === baselineCompany.company.companyName ||
              labels.includes(val.companyName))
        );
      }

      if (
        (isTableView || isDetailedView) &&
        currentInsightView !== INSIGHT_BENCHMARK_TYPE.INDUSTRY
      ) {
        tmpData = tmpData.filter(
          (val: any) => val.companyName && !labels.includes(val.companyName)
        );
      }
      if (
        tmpData.find((element: any) => element.value === "*" && !isCompanyView)
      ) {
        setEmptyValues(true);
      } else {
        setEmptyValues(false);
      }

      if (
        tmpData.find(
          (element: any) =>
            element.value === values.NO_DATA_AVAIL && isCompanyView
        )
      ) {
        setCleanedData([]);
      } else if (checkEmptyTile(tmpData) && !isCompanyView) {
        setCleanedData([]);
      } else {
        setCleanedData(tmpData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTableView, data]);

  const handleMouseEnter = (
    e: any,
    children: any,
    position: TooltipPosition = TooltipPosition.bottom,
    className: string,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("span");
    if (!element) {
      return;
    }

    dispatch(
      showTooltip({
        children: (
          <div
          // Removing this for clearing fortify issues as we will be integrating Popover component
          // dangerouslySetInnerHTML={{
          // __html: children,
          // }}
          />
        ),
        position: position,
        customPosition: true,
        elementDimensions: element.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  const getRowValues = (company: any) => {
    return (
      <span className={`${BLOCK}__value-wrapper`}>
        <span
          className={classNames(
            `${BLOCK}__value ${BLOCK}__value--fix-width ${BLOCK}__value--small ${BLOCK}__value--grow`,
            {
              [`${BLOCK}__value--black`]: isTableView,
              [`${BLOCK}__value--gray`]: company.isGray,
              [`${BLOCK}__value--tall-font`]: isDetailedView && !isTableView,
              [`${BLOCK}__value--special`]: company.isGray,
              [`general-neutral-value--color`]: company.value === "Neutral",
            }
          )}
          onMouseEnter={(e) =>
            company.value === "Neutral"
              ? handleMouseEnter(
                  e,
                  <FormattedMessage id="insights.relevant.risks.neutral" />,
                  TooltipPosition.bottom,
                  `${BLOCK}__value-tooltip-neutral`
                )
              : company.tooltip
              ? handleMouseEnter(
                  e,
                  company.tooltip,
                  TooltipPosition.bottom,
                  `${BLOCK}__value-tooltip`
                )
              : null
          }
          onMouseLeave={handleMouseLeave}
        >
          {company.value}
        </span>
      </span>
    );
  };
  const checkEmptyTile = (data: any) => {
    let emptyValueArray = data.filter((company: any) => company.value === "*");
    if (emptyValueArray.length === data.length) return true;
    return false;
  };

  return (
    <div
      className={classNames(`${BLOCK}__container`, {
        [`${BLOCK}__container--no-data`]: cleanedData.length === 0,
        [`${BLOCK}__container--col`]: !isCompanyView,
        [`${BLOCK}__container--big-top`]: isCompanyView,
        [`${BLOCK}__container--medium-top`]: !isCompanyView && !isTableView,
        [`${BLOCK}__container--small-top`]:
          !isCompanyView && (isTableView || isDetailedView),
      })}
    >
      {cleanedData.length > 0 && isCompanyView && (
        <span
          className={classNames(`${BLOCK}__value`, {
            [`${BLOCK}__value--number`]: isCompanyView,
            [`${BLOCK}__value--black`]: isTableView,
            [`${BLOCK}__value--text-reporting`]:
              BENCHMARK_TILE_TYPES.EMISSIONS_TARGET_REPORTING ===
                benchmarkTileType &&
              isCompanyView &&
              isNaN(cleanedData[0].value),
          })}
        >
          {cleanedData[0].value}
        </span>
      )}
      {cleanedData.length > 0 && !isCompanyView && (
        <div
          className={classNames(`${BLOCK}__rows-wrapper`, {
            [`${BLOCK}__rows-wrapper--no-row-gap`]: isTableView,
            [`${BLOCK}__rows-wrapper--min-row-gap`]:
              isDetailedView && !isTableView,
          })}
        >
          {cleanedData.map((company: any, index: number) => {
            return (
              <div
                className={classNames(`${BLOCK}__row`, {
                  [`${BLOCK}__row--dashed-border`]: isTableView,
                  [`${BLOCK}__row--dashed-top-border`]:
                    isTableView && index === 0,
                  [`${BLOCK}__row--table`]: isTableView,
                })}
                key={`company-${index}`}
              >
                <span className={`${BLOCK}__name`}>{company.companyName}</span>

                {getRowValues(company)}
              </div>
            );
          })}
        </div>
      )}
      {cleanedData.length === 0 && (
        <span className={`${BLOCK}__no-data`}>
          <FormattedMessage id="target.tile.no.data" />
        </span>
      )}
    </div>
  );
};

export default TargetTileReportingYear;
