import React, { useEffect, useState } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import { RootStore } from "services/store.service";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import emptyimg from "images/saved.shortlist-empty.png";
import SavedComparisonsTable from "./saved-comparisons.table";
import { loadSavedComparisonsList } from "services/saved-comparisons/saved-comparisons.service";
import {
  ACCESS_MODULES,
  DEFAULT_SAVED_COMPARISONS_PAYLOAD,
  unauthorizedPath,
  // ScrollOffset,
} from "utils/constants";
import Feedback from "components/feedback/feedback";
import history from "utils/history";
import { submitFeedback } from "services/download/download.api";
import Button from "components/shared/button/button";
import useAppInsightsCustomMetric from "appinsights/app-insights-custom-metrics";
const MyComparisons = () => {
  const BLOCK = "my-comparisons";
  const dispatch = useDispatch();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );

  const savedComparisonsListState = useSelector(
    (state: RootStore) => state.comparisonState
  );
  const location = useLocation();

  // App insights custom metric
  useAppInsightsCustomMetric(location.pathname);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.accessibleFeatures &&
      currentUser.accessibleFeatures.length > 0 &&
      !currentUser.accessibleFeatures.some(
        (feature) =>
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_All ||
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_Comparison
      )
    ) {
      history.push(unauthorizedPath);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, currentUser]);
  useEffect(() => {
    dispatch(loadSavedComparisonsList(DEFAULT_SAVED_COMPARISONS_PAYLOAD));
  }, [dispatch]);

  useEffect(() => {}, [savedComparisonsListState.loading]);
  const submitFeedbackHandler = ({
    feedback,
    rating,
  }: {
    feedback: string;
    rating: number;
  }) => {
    submitFeedback({ feedback, rating });
  };

  return (
    <>
      {currentUser && (
        <div className={`${BLOCK}`} data-testid="my-comparisons-container">
          {savedComparisonsListState.loading && (
            <>
              <div className={`${BLOCK}__empty-container`}>
                <Icon
                  name={"loading"}
                  width={50}
                  height={50}
                  className="loading-icon"
                />
              </div>
            </>
          )}
          {!savedComparisonsListState.loading &&
            savedComparisonsListState.savedComparisonsFetch &&
            ((savedComparisonsListState.savedComparisonsFetch.pageInfo
              ?.filterText &&
              savedComparisonsListState.savedComparisonsFetch.comparisons
                ?.length === 0) ||
              savedComparisonsListState.savedComparisonsFetch.comparisons
                ?.length > 0) && (
              <>
                <div className={`${BLOCK}__header`}>
                  <FormattedMessage id="my-comparisons.header" />
                  <div className={`${BLOCK}__feedback-div`}>
                    <div className={`${BLOCK}__feedback-section`}>
                      <Button
                        className={`${BLOCK}__feedback-button button-primary-small`}
                        dataTest="feedback-button"
                        iconName="commenting"
                        iconHeight={24}
                        iconWidth={24}
                        formattedText="submit.feedback"
                        onClick={() => setShowFeedbackModal(true)}
                      />
                    </div>
                    {showFeedbackModal && (
                      <Feedback
                        data-test="feedback-button-modal"
                        setShowFeedbackModal={setShowFeedbackModal}
                        submitFeedback={submitFeedbackHandler}
                      />
                    )}
                  </div>
                </div>
                <SavedComparisonsTable />
              </>
            )}
          {!savedComparisonsListState.loading &&
            savedComparisonsListState.savedComparisonsFetch &&
            !savedComparisonsListState.savedComparisonsFetch.pageInfo
              ?.filterText &&
            (!savedComparisonsListState.savedComparisonsFetch.comparisons ||
              savedComparisonsListState.savedComparisonsFetch.comparisons
                ?.length === 0) && (
              <div>
                <div className={`${BLOCK}__empty-header`}>
                  <div className={`${BLOCK}__feedback-div`}>
                    <div className={`${BLOCK}__feedback-section`}>
                      <Button
                        className={`${BLOCK}__feedback-button button-primary-small`}
                        dataTest="feedback-button"
                        iconName="commenting"
                        iconHeight={24}
                        iconWidth={24}
                        formattedText="submit.feedback"
                        onClick={() => setShowFeedbackModal(true)}
                      />
                    </div>
                    {showFeedbackModal && (
                      <Feedback
                        data-test="feedback-button-modal"
                        setShowFeedbackModal={setShowFeedbackModal}
                        submitFeedback={submitFeedbackHandler}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`${BLOCK}__empty`}
                  data-test="empty-mycomparisons"
                >
                  <div className={`my-searches__img`}>
                    <img
                      data-testid="empty-img"
                      className={""}
                      src={emptyimg}
                      alt={emptyimg}
                    />
                  </div>
                  <div className={`${BLOCK}__empty-text`}>
                    <FormattedMessage id="my-comparisons.empty" />
                  </div>
                  <div className={`${BLOCK}__empty-redirect`}>
                    <FormattedMessage id="my-comparisons.redirect" />
                    &nbsp;
                    <Link
                      className={`my-searches__link ${BLOCK}__link`}
                      to={"/search"}
                    >
                      <FormattedMessage id="my-searches-empty-redirect-link" />
                    </Link>
                  </div>
                </div>
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default MyComparisons;
