import { BaseModel } from "utils/redux.utils";

export interface PowerbiState {
  loading: boolean;
  accessToken: string;
  embedToken: embedTokenInfo;
  embedURL: string;
  powerBiBookmark: Bookmark;
  isEsrsDashboardAccessed: boolean;
}

export class PowerbiModel extends BaseModel {
  constructor() {
    super({
      loading: false,
      accessToken: "",
      embedToken: {},
      embedURL: "",
      powerBiBookmark: null,
      isEsrsDashboardAccessed: false,
    });
  }

  setAccessTokenForPowerBI = (accessToken: string): PowerbiState =>
    this.merge({ accessToken });
  setEmbedTokenForPowerBI = (embedToken: string): PowerbiState =>
    this.merge({ embedToken });
  setEmbedUrlForPowerBI = (embedURL: string): PowerbiState =>
    this.merge({ embedURL });
  setPowerbiBookmark = (powerBiBookmark: PowerbiBookmark): PowerbiState =>
    this.merge({ powerBiBookmark });

  setIsEsrsDashboardAccessed = (
    isEsrsDashboardAccessed: boolean
  ): PowerbiState => this.merge({ isEsrsDashboardAccessed });
}

export type embedTokenInfo = {
  expiration: string;
  token: string;
  tokenId: string;
};

export type PowerbiBookmark = {
  displayName: string;
  name: string;
  state?: string;
  insightsCategoryId?: number;
};

export type Bookmark = {
  powerBIBookmarkDisplayName: string;
  powerBIBookmarkName: string;
  powerBIBookmarkState?: string;
  insightsCategoryId?: number;
};
