import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import { useEffect, useState } from "react";

const Header = () => {
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  useEffect(() => {
    if (!assetsLoaded) {
      setAssetsLoaded(true);

      // Adobe Analytics
      const adobeAnalytics = document.createElement("script");
      adobeAnalytics.src = `${window.ESG?.ENV?.ADOBE_URL}`;
      adobeAnalytics.async = true;
      document.head.appendChild(adobeAnalytics);

      const adobeSatellite = document.createElement("div");
      adobeSatellite.innerHTML = `<script type="text/javascript">_satellite.pageBottom();</script>`;
      document.body.appendChild(adobeSatellite);

      // DA Header Styles
      const headerCommonCSS = document.createElement("link");
      headerCommonCSS.href = `${window.ESG?.ENV?.DA_URL}/public/CommonStyle/style.css`;
      headerCommonCSS.rel = "stylesheet";
      document.head.appendChild(headerCommonCSS);

      const headerBundleCSS = document.createElement("link");
      headerBundleCSS.href = `${window.ESG?.ENV?.DA_URL}/public/HeaderPlugin/styles.bundle.css`;
      headerBundleCSS.rel = "stylesheet";
      document.head.appendChild(headerBundleCSS);

      // DA Header Scripts
      const headerInlineJS = document.createElement("script");
      headerInlineJS.src = `${window.ESG?.ENV?.DA_URL}/public/HeaderPlugin/inline.bundle.js`;
      headerInlineJS.type = "text/javascript";
      document.body.appendChild(headerInlineJS);

      const headerPolyfillsJS = document.createElement("script");
      headerPolyfillsJS.src = `${window.ESG?.ENV?.DA_URL}/public/HeaderPlugin/polyfills.bundle.js`;
      headerPolyfillsJS.type = "text/javascript";
      document.body.appendChild(headerPolyfillsJS);

      const headerMainJS = document.createElement("script");
      headerMainJS.src = `${window.ESG?.ENV?.DA_URL}/public/HeaderPlugin/main.bundle.js`;
      headerMainJS.type = "text/javascript";
      headerMainJS.dataset["name"] = "HeaderPlugin";
      document.body.appendChild(headerMainJS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="Header">
        <Icon name="deloitte" height={16} className="icon" />
        <Icon name="omnia" height={16} className="icon" />
      </div>
      <div className="App-Name">
        <Icon name="menu-header" height={28} className="icon" />
        <FormattedMessage id="esg.insights" />
      </div>
    </>
  );
};

export default Header;
