import { useContext, createContext } from "react";
import { Tag, TagCountDetail, TagSelected } from "services/tags/tags.model";

interface ITagContext {
  showCreateTag: boolean;
  setShowCreateTag: any;
  showTagPanel: boolean;
  setShowTagPanel: any;
  currentTagsList: Tag[];
  setCurrentTagsList: any;
  tagSelection: any;
  setTagSelection: any;
  tagCreated: boolean;
  setTagCreated: any;
  referenceId: number;
  setReferenceId: any;
  comparisonId: number;
  setComparisonId: any;
  tagReportId: number;
  setTagReportId: any;
  setShowContinueModal: any;
  comparisonItemIdToUpdate: number;
  setComparisonItemIdToUpdate: any;
  tagPanelMaxMargin: boolean;
  setTagPanelMaxMargin: any;
  tagPanelMinMargin: boolean;
  setTagPanelMinMargin: any;
  setSelectedTag: any;
  selectedTag: null | TagSelected;
  showAllTags: number[];
  tagCountDetail: TagCountDetail[];
  setTagCountDetail: any;
  setShowAllTags: any;
  showNotificationModal: boolean;
  setShowNotificationModal: any;
  notificationTypeId: number;
  setNotificationTypeId: any;
}

const TagContext = createContext<ITagContext>({
  showCreateTag: false,
  setShowCreateTag: () => {},
  showTagPanel: false,
  setShowTagPanel: () => {},
  currentTagsList: [],
  setCurrentTagsList: () => {},
  tagSelection: {},
  setTagSelection: () => {},
  tagCreated: false,
  setTagCreated: () => {},
  referenceId: 0,
  setReferenceId: () => {},
  tagReportId: 0,
  setTagReportId: () => {},
  comparisonId: 0,
  setComparisonId: () => {},
  setShowContinueModal: () => {},
  comparisonItemIdToUpdate: -1,
  setComparisonItemIdToUpdate: () => {},
  tagPanelMaxMargin: false,
  setTagPanelMaxMargin: () => {},
  tagPanelMinMargin: false,
  setTagPanelMinMargin: () => {},
  selectedTag: null,
  setSelectedTag: () => {},
  showAllTags: [],
  setShowAllTags: () => {},
  tagCountDetail: [],
  setTagCountDetail: () => {},
  showNotificationModal: false,
  setShowNotificationModal: () => {},
  notificationTypeId: -1,
  setNotificationTypeId: () => {},
});

export const useTagContext = () => useContext(TagContext);
export default TagContext;
