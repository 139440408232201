import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { Fragment, useState, useEffect } from "react";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import classNames from "classnames";
import Popover from "components/shared/popover/popover";
import { INSIGHT_PEER_COMPANY } from "utils/constants";
import Parser from "html-react-parser";

type Props = {
  data: any;
  currentInsightView: number;
};

const ReportingAndDisclosureTableTile = ({ data }: any) => {
  const [noPeerResponse, setNoPeerResponse] = useState([]);
  const BLOCK = "reportingDisclosure-table-industry";
  useEffect(() => {
    if (data && data.response) {
      setNoPeerResponse(
        data.response.filter(
          (res: any) => res.header !== INSIGHT_PEER_COMPANY.companyName
        )
      );
    }
  }, [data]);

  const hasMissingValue = noPeerResponse.some(
    (obj) =>
      Object.values(obj).includes("") || Object.values(obj).includes(null)
  );
  const allIdsHaveEmptyString = data.labels.every((obj: any) =>
    noPeerResponse.every((Obj) => Obj[obj.id] === "" || Obj[obj.id] === null)
  );
  if (allIdsHaveEmptyString) {
    return (
      <div className={`${BLOCK}__no-data`}>
        {" "}
        <span className="speedometer__no-data" style={{ fontWeight: "bold" }}>
          {" "}
          <FormattedMessage id="insights.cdp.no-data" />{" "}
        </span>{" "}
      </div>
    );
  }
  let hasMissingScope = false;

  const renderSection = (l: any, i: number) => {
    noPeerResponse.forEach((response: any) => {
      if (response[l.id]?.split(",").length !== l.groupValues.length) {
        hasMissingScope = true;
      }
    });
    return (
      <>
        <span className={`${BLOCK}__label--fit-content`}>
          <Popover
            metricClassName={classNames(`${BLOCK}__label--align-left-bold`)}
            displayText={<span>{Parser(l.sectionHeader ?? "")}</span>}
            content={l.tooltip ? l.tooltip : []}
            buttonClassName={`${BLOCK}__label-button`}
          />
        </span>
        {l.groupValues.map((value: any, ind: number) => (
          <tr className={classNames(`${BLOCK}__row`)} key={`table-row-${ind}`}>
            <td className={classNames(`${BLOCK}__data data`)}>
              <Popover
                metricClassName={classNames(`${BLOCK}__label--align-left`)}
                displayText={<span>{Parser(value ?? "")}</span>}
                content={l.tooltip ? l.tooltip : []}
                buttonClassName={`${BLOCK}__label-button`}
              />
            </td>
            {noPeerResponse.map((response: any) => (
              <td className={classNames(`${BLOCK}__data data`)}>
                {response[l.id]?.split(",")[ind] ?? "*"}
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  };
  const renderNormalRow = (l: any, i: number) => {
    return (
      <tr className={classNames(`${BLOCK}__row`)} key={`table-row-${i}`}>
        <td className={`${BLOCK}__label`} key={`data-labels-${i}`}>
          <span className={`${BLOCK}__label--fit-content`}>
            <Popover
              metricClassName={classNames(`${BLOCK}__label--align-left`)}
              displayText={<span>{Parser(l.label ?? "")}</span>}
              content={l.tooltip ? l.tooltip : []}
              buttonClassName={`${BLOCK}__label-button`}
            />
          </span>
        </td>
        {noPeerResponse.map((d: any, j: number) => {
          const fieldValue = d[l.id];

          return (
            <Fragment key={`table-data-${i}-${j}`}>
              {/* THIS IS FOR TOOL TIP ON IVA_COMPANY_RATING OF MSCI ESG RATING TILE     */}
              {/* IN THIS, TOOLTIP HAS TO BE ON THE FIELD VALUE OF IVA COMPANY RATING */}

              <td
                className={classNames(`${BLOCK}__data data`)}
                key={`data-data-${i}`}
              >
                {fieldValue ? getCommaSeperatedData(fieldValue) : "*"}

                {data.tileType === 48 &&
                fieldValue &&
                (fieldValue === "-" ||
                  fieldValue?.toLowerCase() === "neutral" ||
                  fieldValue.includes("(Neutral)")) ? (
                  <Tooltip position={TooltipPosition.bottom}>
                    {fieldValue === "-" ? (
                      <>None</>
                    ) : (
                      <FormattedMessage id="insights.tabular.neutral" />
                    )}
                  </Tooltip>
                ) : (
                  <></>
                )}
              </td>
            </Fragment>
          );
        })}
      </tr>
    );
  };
  const getCommaSeperatedData = (data: string) => (
    <span className={`${BLOCK}--data`}>
      {data &&
        data.split(",").map((item: any, index: any) => (
          <span key={`data-${index}`} className={`${BLOCK}--item`}>
            {item}
          </span>
        ))}
    </span>
  );
  return (
    <>
      {noPeerResponse.length > 1 ? (
        <>
          <table
            className={`${BLOCK}`}
            data-test="reporting-disclosure-industry"
            data-testid="reporting-disclosure-industry"
          >
            <thead>
              <tr>
                <td></td>
                {noPeerResponse.map((d: any, i: number) => (
                  <td key={`data-header-${i}`} className="data">
                    {d.header}
                  </td>
                ))}
              </tr>
            </thead>

            <tbody>
              {data.labels.map((l: any, i: number) => {
                return i !== 4 ? renderNormalRow(l, i) : renderSection(l, i);
              })}
            </tbody>
          </table>
          {(hasMissingValue || hasMissingScope) && (
            <div className={"DashboardTile__noDataAny"}>
              <FormattedMessage id="no.data.available" />
            </div>
          )}
        </>
      ) : (
        <div className={`${BLOCK}__no-data`}>
          <span className="speedometer__no-data" style={{ fontWeight: "bold" }}>
            <FormattedMessage id="insights.cdp.no-data" />
          </span>
        </div>
      )}
    </>
  );
};

const ReportingAndDisclosureCompanyTableTile = ({ data }: any) => {
  const BLOCK = "reportingDisclosure-table";
  let showNoDataFootnote = false;
  let NoDataAvailable = data.response.every((companyData: any) =>
    companyData?.data.length > 0
      ? companyData?.data?.every((metricData: any) =>
          metricData?.metrics?.every((metric: any) => metric.value === null)
        )
      : true
  );

  showNoDataFootnote = data.response[0].data.some(
    (metrics: any, index: number) =>
      index !== 4
        ? metrics.metrics.every((metric: any) => metric.value === null)
        : metrics.metrics.some((metric: any) => metric.value === null)
  );

  if (!data)
    return (
      <div className={`${BLOCK}__no-data`}>
        <span className="speedometer__no-data" style={{ fontWeight: "bold" }}>
          <FormattedMessage id="insights.cdp.no-data" />
        </span>
      </div>
    );

  const renderTableDataSectionally = (d: any, sectionId: number) => (
    <Fragment>
      {sectionId !== 2 ? (
        <span
          className={`${BLOCK}-company__section-value`}
          key={`reporting-table-${sectionId}`}
        >
          {!d.metrics.every((metric: any) => metric.value === null) ? (
            d.metrics.map((metric: any, indexForMetric: number) =>
              metric.value && metric.value.toLowerCase() === "yes" ? (
                <span
                  className={`${BLOCK}-company__section-value-padding`}
                  key={indexForMetric}
                >
                  {metric.metric}
                </span>
              ) : null
            )
          ) : (
            <span
              className={`${BLOCK}-company__section-value-padding`}
              key={sectionId}
            >
              {"*"}
            </span>
          )}
        </span>
      ) : (
        <span
          className={`${BLOCK}-company__section-value`}
          key={`reporting-table-${sectionId}`}
        >
          {!d.metrics.every((metric: any) => metric.value === null) ? (
            d.metrics.map((metric: any, indexForMetric: number) =>
              metric.value && indexForMetric === 0
                ? metric.value + " - "
                : metric.value
            )
          ) : (
            <span
              className={`${BLOCK}-company__section-value-padding`}
              key={sectionId}
            >
              {"*"}
            </span>
          )}
        </span>
      )}
    </Fragment>
  );

  const renderTableInTranspose = (d: any, metricGroupDisplayNames: any) => (
    <table className={`${BLOCK}-company__data`}>
      <tbody>
        {metricGroupDisplayNames.groupMetric.map(
          (metric: any, index: number) => (
            <tr key={index}>
              <td>{metric}</td>
              <td>{d.metrics[index].value ?? "*"}</td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );

  return (
    <div
      className={BLOCK}
      data-test="company-reporting-disclosure-table"
      data-testid="company-reporting-disclosure-table"
    >
      {!NoDataAvailable &&
        data?.response[0].data?.map((d: any, sectionId: number) => {
          return (
            <div
              key={`reporting-table-${sectionId}`}
              className={classNames(
                `${BLOCK}-company`,
                `${BLOCK}-company-${sectionId}`
              )}
            >
              <span className={`${BLOCK}-company__section-name`}>
                <Popover
                  displayText={d.sectionHeader}
                  metricClassName={""}
                  buttonClassName={`${BLOCK}-company__section-name ${BLOCK}-company__section-name-${sectionId}`}
                  content={
                    data.labels[sectionId].tooltip
                      ? data.labels[sectionId].tooltip
                      : []
                  }
                />
              </span>
              {sectionId !== 4
                ? renderTableDataSectionally(d, sectionId)
                : renderTableInTranspose(d, data.labels[sectionId])}
            </div>
          );
        })}
      {showNoDataFootnote && !NoDataAvailable ? (
        <span className={`reporting__no-data-footnote`}>
          <FormattedMessage id="no.data.available" />
        </span>
      ) : null}
      {NoDataAvailable ? (
        <div className={`reportingDisclosure__no-data`}>
          <FormattedMessage id="insights.speedometer.no-data" />
        </div>
      ) : null}
    </div>
  );
};

const ReportingAndDisclosurePeerTableTile = ({ data }: any) => {
  const BLOCK = "reporting-disclosure-table";
  const REPORTINGDISCLOSUREBLOCK = "reportingDisclosure";
  let showNoDataFootnote = false;
  const renderForNormalMetric = (metricData: any, indexForMetric: number) => {
    const ShowAsterix = metricData.metrics.every(
      (metric: any) => metric.value === null
    );
    if (indexForMetric !== 4) {
      if (metricData.metrics.every((metric: any) => !metric.value))
        showNoDataFootnote = true;
      return (
        <td className={classNames(`${BLOCK}__data data`)}>
          {ShowAsterix ? (
            <span> * </span>
          ) : (
            metricData.metrics.map((metric: any, metricIndex: number) =>
              indexForMetric === 2 ? (
                <span key={`${BLOCK}-metric-${metricIndex}`}>
                  {metric.value
                    ? metric.metric === "ReportingDataStartDate"
                      ? metric.value + " - "
                      : metric.value
                    : "*"}
                </span>
              ) : (
                <>
                  {metric.value === "yes" ? (
                    <span key={`${BLOCK}-metric-${metricIndex}`}>
                      {`${metric.metric}\n`}
                    </span>
                  ) : null}
                </>
              )
            )
          )}
        </td>
      );
    } else {
      if (metricData.metrics.some((metric: any) => !metric.value))
        showNoDataFootnote = true;
      return (
        <Fragment>
          <td className={classNames(`${BLOCK}__assurance `)}>
            {metricData.metrics.map((metric: any, metricIndex: number) => (
              <td
                className={classNames(`${BLOCK}__data ${BLOCK}__assurance `)}
                key={`${BLOCK}-metric-${metricIndex}`}
              >
                {metric.value ?? "*"}
              </td>
            ))}
          </td>
        </Fragment>
      );
    }
  };

  let NoDataAvailable = data.response.every((companyData: any) =>
    companyData?.data.length > 0
      ? companyData?.data?.every((metricData: any) =>
          metricData?.metrics?.every((metric: any) => metric.value === null)
        )
      : true
  );

  return (
    <Fragment>
      {!NoDataAvailable && (
        <table
          className={classNames(`${BLOCK}__table`, {})}
          data-test="peer-view-reporting-disclosure"
          data-testid="peer-view-reporting-disclosure"
        >
          <thead className={`${REPORTINGDISCLOSUREBLOCK}__peer-table-header`}>
            <tr className={`${REPORTINGDISCLOSUREBLOCK}__peer-table-header`}>
              <th
                className={`${REPORTINGDISCLOSUREBLOCK}__peer-table-header-text`}
              ></th>
              {data.labels.map((l: any, i: number) => {
                return (
                  <th
                    key={`data-label-${i}`}
                    className={`${REPORTINGDISCLOSUREBLOCK}__peer-table-header-text`}
                  >
                    <span
                      className={classNames(
                        `${REPORTINGDISCLOSUREBLOCK}__peer-table-header`,
                        {
                          [`${BLOCK}__label--left`]: data.data,
                        }
                      )}
                    >
                      <Popover
                        metricClassName={classNames({
                          [`${BLOCK}__label--align-left`]: data,
                        })}
                        displayText={
                          <span>{Parser(l.sectionHeader ?? "")}</span>
                        }
                        content={l.tooltip ? l.tooltip : []}
                        buttonClassName={`${REPORTINGDISCLOSUREBLOCK}__peer-table-header-text`}
                      />
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr
              className={classNames(
                `${REPORTINGDISCLOSUREBLOCK}__peer-table-subheader`
              )}
              key={`table-row`}
            >
              <td></td>
              {data.labels?.map((d: any, sectionId: number) => {
                if (sectionId !== 4) {
                  return (
                    <td
                      className={classNames(`${BLOCK}__company`)}
                      key={`table-row=${sectionId}`}
                    />
                  );
                } else {
                  return (
                    <td>
                      {d.groupMetric.map((metric: any, metricIndex: number) => {
                        return (
                          metric !== "" && (
                            <td key={`table-row-${metricIndex}`}>{metric}</td>
                          )
                        );
                      })}
                    </td>
                  );
                }
              })}
            </tr>
            {data.response?.map((d: any, columnId: number) => {
              return (
                <tr
                  className={classNames(
                    `${REPORTINGDISCLOSUREBLOCK}__peer-table-chart`
                  )}
                  key={`row-data-${columnId}`}
                >
                  <td className={classNames(`${BLOCK}__company`, {})}>
                    {d.header}
                  </td>
                  {d.data.map((metricData: any, metricNumber: number) => {
                    return renderForNormalMetric(metricData, metricNumber);
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {NoDataAvailable ? (
        <div className={`reportingDisclosure__no-data`}>
          <FormattedMessage id="insights.speedometer.no-data" />
        </div>
      ) : null}
      {!NoDataAvailable && showNoDataFootnote ? (
        <span className={`reporting__no-data-footnote`}>
          <FormattedMessage id="no.data.available" />
        </span>
      ) : null}
    </Fragment>
  );
};

const ReportingAndDisclosureTableView = ({
  data,
  currentInsightView,
}: Props) => {
  return (
    <>
      {data && currentInsightView === 6 ? (
        <ReportingAndDisclosureTableTile data={data} />
      ) : data && currentInsightView === 5 ? (
        <ReportingAndDisclosurePeerTableTile data={data} />
      ) : (
        <ReportingAndDisclosureCompanyTableTile data={data} />
      )}
    </>
  );
};

export default ReportingAndDisclosureTableView;
