import Icon from "components/shared/icon/icon";
import React, { Fragment, useEffect, useRef, useState } from "react";
import emptyImg from "images/peer-benchmark.empty-state.png";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  APP_SETTING,
  BenchmarkActionTypes,
  DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD,
  EXPORT_FORMAT,
  EXPORT_TYPES,
  peerBenchmarkIvaRatingTrend,
  peerBenchmarkPath,
  peerBenchmarkTableFilter,
  OVERLAY_PEER_BENCHMARKS,
  BENCHMARK_TYPE,
} from "utils/constants";
import { useLocation } from "react-router-dom";
import history from "utils/history";
import CreateNewView from "./create-new";
import BenchmarkGridView, { BenchmarkCompany, compare } from "./benchmark-grid";
import classNames from "classnames";
import { RootStore } from "services/store.service";
import { useDispatch, useSelector } from "react-redux";
import {
  errorRename,
  loadCurrentBenchmark,
  loadPeerCompanies,
  renameSuccess,
  resetCurrentSavedBenchmark,
  resetSavedBenchmarkID,
  savedBenchmarkID,
  searchLoadCompanyFilter,
  loadSavedPeerbenchmarks,
  peerBenchmarkToSave,
  exportPeerBenchmarkTrack,
  removePeerBenchmarkItems,
  deletePeerBenchmarkSuccess,
  isFilteringPeerBenchMark,
  peerBenchMarkRemoveSuccess,
  renameEditSuccess,
  clearPeerBenchmarkFilter,
  editPeerBenchmark,
  peerBenchMarkLoading,
  changePeerBenchmarkPeriod,
  getSavedUserPreference,
  patchUserPreference,
  settingSavedUserPreference,
  setCompanyFilter,
  setPeerCompanyFilter,
  setGlobalCompanyMappingsFilter,
  setGlobalMappingFilter,
} from "services/peer-benchmark/peer-benchmark.service";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import SavedPeerbenchmarksTable from "./saved-peerbenchmarks-table";
import { sendExportItem } from "services/download/download.service";
import {
  getBenchmarkMetadata,
  savePeerBenchmarkData,
} from "services/peer-benchmark/peer-benchmark.api";
import { addToastMessage } from "services/commons.service";
import WhatsNewModal from "components/whatsNewModal/whatsNewModal";
import { PAGES } from "components/whatsNewModal/useWhatsNewModal";
import ActiveFilterTag from "components/shared/active-filter-tag/active-filter-tag";
import DisclaimerModal from "components/shared/disclaimer-modal/disclaimer-modal";
import Button from "components/shared/button/button";
import CDPBenchmarkGridView, { fieldData } from "./cdp-benchmark-grid";
import {
  getBenchmarkCompanyFromGlobalCompanyGroup,
  getNQuarters,
  getQuarterRange,
} from "utils/functions";
import moment from "moment";
import YearDropDown from "components/shared/dropdown/year-dropdown";
import Toggle from "components/shared/toggle/toggle";
import {
  GlobalCompanyGroup,
  GlobalCompanyMapping,
} from "services/insights/insights.model";
import { isCompanyInGlobalGroup } from "components/shared/global-search/global-search-dropdown";
import { UserPreference } from "services/peer-benchmark/peer-benchmark.model";
import Popover from "components/shared/popover/popover";
import { CompanyFilter } from "services/search/filters.model";
import useAppInsightsCustomMetric from "appinsights/app-insights-custom-metrics";

type BenchmarkTitleErrors = {
  requiredError: boolean;
  existingNameError: boolean;
};

type dataForFilter = {
  selectedReportedYear: string;
  selectedQuarter: string;
};

const PeerBenchmark = () => {
  const BLOCK = "peer-benchmark";
  const location = useLocation();
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [editView, setEditview] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<any>(null);
  const [peerBenchmarkData, setPeerBenchmarkData] = useState<any>();
  const [selectedFilterOption, setSelectedFilterOption] = useState<string>("");
  const [dropDownOptions, setdropDownOptions] = useState<any[]>([]);
  const [showCurrentBenchmarkView, setShowCurrentBenchmarkView] =
    useState<boolean>(false);
  const peerBenchmarkState = useSelector(
    (state: RootStore) => state.peerBenchmarkState
  );

  const [openCategory, setOpenCategory] = useState<any[]>([]);
  const [userPreferences, setUserPreferences] = useState<
    Partial<UserPreference>
  >({});
  const commonsState = useSelector((state: RootStore) => state.commons);
  const [currentBenchmarkTitle, setCurrentBenchmarkTitle] =
    useState<string>("");
  const [initialCurrentBenchmarkTitle, setInitialCurrentBenchmarkTitle] =
    useState<string>("");
  const dispatch = useDispatch();
  const [showEditableInput, setShowEditableInput] = useState<boolean>(false);
  const [expandAllMetricsValues, setExpandAllMetricsValue] = useState(true);
  const editableInputRef = useRef<HTMLInputElement>(null);
  const [showDisclarimerModal, setShowDisclarimerModal] =
    useState<boolean>(false);
  const [benchmarkTitleErrors, setBenchMarkTitleErrors] =
    useState<BenchmarkTitleErrors>({
      requiredError: false,
      existingNameError: false,
    });

  const [hasNullValues, setHasNullValues] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [benchmarkCompanies, setBenchmarkCompanies] = useState<
    BenchmarkCompany[]
  >([]);

  // App insights custom metric
  useAppInsightsCustomMetric(location.pathname);

  const cleanCompanies = (
    globalCompanyMappings: GlobalCompanyGroup[],
    companies: BenchmarkCompany[],
    benchmarkId: number
  ) => {
    let baseCompanyAGroup = globalCompanyMappings.find(
      (g: GlobalCompanyGroup) => g.isBaseMapping
    );
    let tmpCompanies: BenchmarkCompany[] = [
      ...companies.filter(
        (bc: BenchmarkCompany) =>
          isCompanyInGlobalGroup(
            globalCompanyMappings ?? [],
            bc.globalCompanyId
          ) === 0
      ),
    ];

    tmpCompanies.forEach((comp: BenchmarkCompany) => {
      comp.company = {
        companyId: comp.globalCompanyId,
        companyName: comp.globalCompany ? comp.globalCompany.companyName : "",
        globalCompanyId: comp.globalCompanyId,
      };
    });

    tmpCompanies.sort(compare);
    if (baseCompanyAGroup) {
      tmpCompanies.splice(
        0,
        0,
        getBenchmarkCompanyFromGlobalCompanyGroup(
          baseCompanyAGroup,
          benchmarkId,
          0
        )
      );
    }
    if (globalCompanyMappings.length) {
      tmpCompanies = tmpCompanies.concat(
        globalCompanyMappings
          .filter((g: GlobalCompanyGroup) => !g.isBaseMapping)
          .map((g: GlobalCompanyGroup, gIndex: number) => {
            let defaultCompany = g.globalCompanyMappingDetails.find(
              (m: GlobalCompanyMapping) => m.isDefault
            );
            let defaultCompanyInCompanies = companies.find(
              (icf: BenchmarkCompany) =>
                defaultCompany &&
                icf.globalCompanyId === defaultCompany.globalCompanyId
            );
            return getBenchmarkCompanyFromGlobalCompanyGroup(
              g,
              benchmarkId,
              defaultCompanyInCompanies &&
                defaultCompanyInCompanies.displayOrder
                ? defaultCompanyInCompanies.displayOrder
                : gIndex + 1
            );
          })
      );
    }

    tmpCompanies = tmpCompanies.sort(
      (a: BenchmarkCompany, b: BenchmarkCompany) =>
        a.displayOrder > b.displayOrder
          ? 1
          : a.displayOrder < b.displayOrder
          ? -1
          : 0
    );
    return tmpCompanies;
  };

  useEffect(() => {
    if (peerBenchmarkState.deletePeerBenchmarkSuccess) {
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="peerbenchmark.delete.success" />,
        })
      );
      dispatch(deletePeerBenchmarkSuccess(false));
    }
    // eslint-disable-next-line
  }, [peerBenchmarkState.deletePeerBenchmarkSuccess]);

  useEffect(() => {
    if (showEditableInput) editableInputRef.current!.focus();
  }, [showEditableInput]);

  useEffect(() => {
    if (peerBenchmarkState.errorRename && showCurrentBenchmarkView) {
      setBenchMarkTitleErrors({
        ...benchmarkTitleErrors,
        existingNameError: true,
      });
      setShowEditableInput(true);
      dispatch(errorRename(false));
    }
    // eslint-disable-next-line
  }, [peerBenchmarkState.errorRename]);

  useEffect(() => {
    const benchmarkId = parseInt(
      location.pathname.replace(`${peerBenchmarkPath}/`, "")
    );
    if (benchmarkId) {
      dispatch(savedBenchmarkID(benchmarkId));
    }
    // eslint-disable-next-line
  }, [location]);

  const loadCurrentPeerBenchmark = (id: number, params?: any) =>
    getBenchmarkMetadata(id, params).then(({ data }) => {
      setBenchmarkCompanies(
        cleanCompanies(
          data.globalCompanyMappings ?? [],
          data.benchmarkCompanies,
          data.benchmarkId
        )
      );

      setCurrentBenchmarkTitle(data.benchmarkTitle);
      setInitialCurrentBenchmarkTitle(data.benchmarkTitle);
      let dropDownData =
        data.benchmarkType === BENCHMARK_TYPE.CDP
          ? data.availableReportedYears.map(String)
          : getQuartersWithYears();
      if (dropDownData.length > 0) {
        setdropDownOptions(dropDownData);
      }
      setPeerBenchmarkData(data);
      setMetadata(data.benchmarkMetadata);
      return { data, dropDownOptions: dropDownData };
    });

  useEffect(() => {
    const benchmarkId = parseInt(
      location.pathname.replace(`${peerBenchmarkPath}/`, "")
    );

    if (peerBenchmarkState.removeSuccess) {
      loadCurrentPeerBenchmark(benchmarkId).then((r) => {
        dispatch(peerBenchMarkRemoveSuccess(false));
        dispatch(setGlobalMappingFilter(r?.data?.globalCompanyMappings));
      });
    }
    // eslint-disable-next-line
  }, [peerBenchmarkState.removeSuccess]);

  useEffect(() => {
    if (peerBenchmarkState.editBenchmarkSuccess) {
      dispatch(peerBenchMarkLoading(true));
      loadCurrentPeerBenchmark(peerBenchmarkState.savedBenchmarkID)
        .then((r) => {
          let data: dataForFilter;
          if (r?.data?.benchmarkType !== BENCHMARK_TYPE.CDP) {
            data = checkScenariosForMSCIQuarterFilterForAPIPayload(
              r.data,
              r.dropDownOptions
            );
          }
          dispatch(
            loadCurrentBenchmark({
              benchmarkId: r?.data?.benchmarkId,
              benchmarkTypeId: r?.data?.benchmarkType,
              selectedReportedYear:
                r?.data?.benchmarkType !== BENCHMARK_TYPE.CDP
                  ? data!.selectedReportedYear
                  : r?.data.selectedReportedYear ?? r?.dropDownOptions[0],
              selectedQuarter:
                r?.data?.benchmarkType !== BENCHMARK_TYPE.CDP
                  ? data!.selectedQuarter
                  : null,
            })
          );
          dispatch(getSavedUserPreference(r?.data?.benchmarkId));
          dispatch(setGlobalMappingFilter(r?.data?.globalCompanyMappings));
          dispatch(renameEditSuccess(false));
          setCreateNew(false);
          dispatch(peerBenchMarkLoading(false));
        })
        .catch((e) => {
          dispatch(peerBenchMarkLoading(false));
        });
    }
    // eslint-disable-next-line
  }, [peerBenchmarkState.editBenchmarkSuccess]);

  useEffect(() => {
    if (peerBenchmarkState.renameSuccess && showCurrentBenchmarkView) {
      setBenchMarkTitleErrors({
        ...benchmarkTitleErrors,
        requiredError: false,
        existingNameError: false,
      });
      loadCurrentPeerBenchmark(peerBenchmarkState.savedBenchmarkID, {
        params: { accessedBenchmark: true },
      });
      dispatch(renameSuccess(false));
    }
    // eslint-disable-next-line
  }, [peerBenchmarkState.renameSuccess]);

  useEffect(() => {
    if (
      peerBenchmarkState.currentSavedBenchmark &&
      peerBenchmarkState.savedUserPreference
    ) {
      if (peerBenchmarkState.savedUserPreference.userPreferenceValue !== null) {
        setUserPreferences(peerBenchmarkState.savedUserPreference);
        if (
          peerBenchmarkState.currentSavedBenchmark.metricCategories.length > 0
        ) {
          setExpandAllMetricsValue(
            peerBenchmarkState.savedUserPreference.userPreferenceValue
              ?.isExpanded ?? true
          );
          setOpenCategory(
            peerBenchmarkState.savedUserPreference.userPreferenceValue
              ?.expandedMetricCategories ??
              peerBenchmarkState?.currentSavedBenchmark?.metricCategories?.map(
                (metric: any) => metric?.metricCategoryId
              ) ??
              []
          );
        }
      }

      if (
        peerBenchmarkState.savedUserPreference.userPreferenceValue ===
          undefined ||
        peerBenchmarkState.savedUserPreference?.userPreferenceValue === null
      ) {
        if (
          peerBenchmarkState.currentSavedBenchmark.metricCategories.length > 0
        ) {
          setOpenCategory(
            peerBenchmarkState?.currentSavedBenchmark?.metricCategories?.map(
              (metric: any) => metric?.metricCategoryId
            )
          );
          setExpandAllMetricsValue(true);
        }
        setUserPreferences({
          ...peerBenchmarkState.savedUserPreference,
          referenceId: peerBenchmarkState.savedBenchmarkID,
          userId: commonsState?.currentUser?.userId,
          userPreferenceId: 1,
          userPreferenceType: 1,
          userPreferenceValue: {
            isExpanded: true,
            isTranspose: false,
            companiesDisplayOrder: benchmarkCompanies.map(
              (benchmarkCompany: BenchmarkCompany) => {
                return {
                  globalCompanyId: benchmarkCompany.globalCompanyId,
                  displayOrder: benchmarkCompany.displayOrder,
                };
              }
            ),
            fieldDisplayOrder: peerBenchmarkState.currentSavedBenchmark?.fields
              .filter((field: fieldData) => field.fieldName !== "IvaRatingDate")
              .map((field: fieldData) => {
                return {
                  fieldId: field.fieldId,
                  displayOrder: field.displayOrder,
                };
              }),
            expandedMetricCategories:
              peerBenchmarkState.currentSavedBenchmark?.metricCategories
                ?.length > 0
                ? peerBenchmarkState?.currentSavedBenchmark?.metricCategories?.map(
                    (metric: any) => metric?.metricCategoryId
                  )
                : [],
            companiesColumnWidths: [],
            fieldColumnWidths: [],
            columnHeaderWidth: 0,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    peerBenchmarkState.savedUserPreference,
    peerBenchmarkState.currentSavedBenchmark,
  ]);

  // USE EFFECT FOR LOADING OF PEER BENCHMARK STATE
  useEffect(() => {
    if (peerBenchmarkState.savedBenchmarkID !== -1) {
      setCreateNew(false);
      setShowCurrentBenchmarkView(true);
      loadCurrentPeerBenchmark(peerBenchmarkState.savedBenchmarkID, {
        params: { accessedBenchmark: true },
      }).then((r: any) => {
        let data: dataForFilter;
        if (r?.data?.benchmarkType !== BENCHMARK_TYPE.CDP) {
          data = checkScenariosForMSCIQuarterFilterForAPIPayload(
            r.data,
            r.dropDownOptions
          );
        }

        dispatch(
          loadCurrentBenchmark({
            benchmarkId: r?.data?.benchmarkId,
            benchmarkTypeId: r?.data?.benchmarkType,
            selectedReportedYear:
              r?.data?.benchmarkType !== BENCHMARK_TYPE.CDP
                ? data!.selectedReportedYear
                : r?.data.selectedReportedYear ?? r?.dropDownOptions[0],
            selectedQuarter:
              r?.data?.benchmarkType !== BENCHMARK_TYPE.CDP
                ? data!.selectedQuarter
                : null,
          })
        );
        dispatch(getSavedUserPreference(r?.data?.benchmarkId));
        dispatch(setGlobalMappingFilter(r?.data?.globalCompanyMappings));
      });
    } else {
      setShowCurrentBenchmarkView(false);
    }
    // eslint-disable-next-line
  }, [peerBenchmarkState.savedBenchmarkID]);

  // for updating current metadata
  useEffect(() => {
    if (peerBenchmarkState.currentBenchMarkData) {
      setPeerBenchmarkData(peerBenchmarkState.currentBenchMarkData);
      setMetadata(peerBenchmarkState.currentBenchMarkData.benchmarkMetadata);
    }
  }, [peerBenchmarkState.currentBenchMarkData]);

  useEffect(() => {
    if (
      peerBenchmarkState.currentSavedBenchmark &&
      peerBenchmarkState.currentSavedBenchmark.benchmarkFieldValues
    ) {
      setHasNullValues(
        peerBenchmarkState.currentSavedBenchmark.benchmarkFieldValues.filter(
          (bfv: any) => !bfv.fieldValue || bfv.fieldValue === ""
        ).length > 0
      );
    }
  }, [peerBenchmarkState.currentSavedBenchmark]);

  useEffect(() => {
    if (dropDownOptions && dropDownOptions.length > 0 && peerBenchmarkData) {
      setSelectedFilterOption(
        peerBenchmarkData.benchmarkType === BENCHMARK_TYPE.CDP
          ? peerBenchmarkData.selectedReportedYear &&
            peerBenchmarkData.selectedReportedYear !== null
            ? peerBenchmarkData.selectedReportedYear.toString()
            : dropDownOptions[0].toString()
          : checkScenariosForMSCISelectedFilter(peerBenchmarkData)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownOptions, peerBenchmarkData]);

  // for getting selected quarter from date range
  const getSelectedQuarterFromDateRange = (quarterRange: string) => {
    const startOfQuarter = quarterRange.split("-")[0];
    const startdate = moment(startOfQuarter, "DD/MM/YYYY");
    const year = moment(startOfQuarter).year();
    const quarter = startdate.quarter();

    return { name: `Q${quarter} - ${year}`, quarter, year };
  };

  const getQuartersWithYears = () => {
    return getNQuarters(8);
  };

  //Checking scenarios for sending API payload for MSCI Quarter+Year Filter
  const checkScenariosForMSCIQuarterFilterForAPIPayload = (
    data: any,
    dropDown: any[]
  ): dataForFilter => {
    if (data && data.selectedQuarter && data.selectedReportedYear) {
      let selectedQuarterName = getSelectedQuarterFromDateRange(
        data.selectedQuarter
      )?.name;
      return dropDown.some((d) => d.name === selectedQuarterName)
        ? {
            selectedQuarter: data.selectedQuarter,
            selectedReportedYear: data.selectedReportedYear,
          }
        : {
            selectedQuarter: getQuarterRange(
              dropDown[0]?.quarter,
              dropDown[0]?.year
            ),
            selectedReportedYear: dropDown[0]?.year,
          };
    } else {
      return dropDown && dropDown.length > 0
        ? {
            selectedQuarter: getQuarterRange(
              dropDown[0]?.quarter,
              dropDown[0]?.year
            ),
            selectedReportedYear: dropDown[0]?.year,
          }
        : {
            selectedQuarter: "",
            selectedReportedYear: "",
          };
    }
  };

  //Checking scenarios for setting dropdown value for MSCI Quarter+Year Filter On load of page
  const checkScenariosForMSCISelectedFilter = (data: any) => {
    if (data.selectedQuarter && data.selectedReportedYear) {
      let selectedQuarterName = getSelectedQuarterFromDateRange(
        data.selectedQuarter
      )?.name;
      return dropDownOptions.some((d) => d.name === selectedQuarterName)
        ? selectedQuarterName
        : dropDownOptions[0]?.name;
    } else {
      return dropDownOptions && dropDownOptions.length > 0
        ? dropDownOptions[0]?.name
        : [];
    }
  };

  useEffect(() => {
    return () => {
      dispatch(savedBenchmarkID(-1));
      setShowCurrentBenchmarkView(false);
    };
    // eslint-disable-next-line
  }, []);

  const trimStartFunc = (value: any) => {
    if (!value) return value;
    return value.replace(/^\s+/g, "");
  };

  const onChangeHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    let newValue = trimStartFunc(event.target.value);
    setCurrentBenchmarkTitle(event.target.value);
    if (newValue === "") {
      setBenchMarkTitleErrors({
        ...benchmarkTitleErrors,
        requiredError: true,
        existingNameError: false,
      });
    } else if (benchmarkTitleErrors.requiredError && newValue !== "") {
      setBenchMarkTitleErrors({
        ...benchmarkTitleErrors,
        existingNameError: false,
        requiredError: false,
      });
    } else if (benchmarkTitleErrors.existingNameError) {
      setBenchMarkTitleErrors({
        ...benchmarkTitleErrors,
        existingNameError: false,
      });
    }
  };
  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      editPeerBenchmarkNameHandler();
    }
  };

  const exportHandler = () => {
    if (
      !commonsState.dashboardExportDisclaimer[
        APP_SETTING.DashboardExportDisclaimer
      ]
    ) {
      dispatch(
        sendExportItem({
          exportReferenceId: peerBenchmarkState.savedBenchmarkID,
          exportFileName: currentBenchmarkTitle,
          exportType: EXPORT_TYPES.PEER_BENCHMARK,
          exportFormat: EXPORT_FORMAT.xls,
          searchCriteria: {
            contentText: "",
          },
        })
      );
    } else {
      setShowDisclarimerModal(true);
    }
    dispatch(exportPeerBenchmarkTrack(true));
  };
  const handleEdit = () => {
    setEditview(true);
    setCreateNew(true);
  };

  const editPeerBenchmarkNameHandler = () => {
    if (
      currentBenchmarkTitle !== "" &&
      currentBenchmarkTitle !== initialCurrentBenchmarkTitle &&
      !benchmarkTitleErrors.existingNameError
    ) {
      setShowEditableInput(false);
      dispatch(
        editPeerBenchmark(
          {
            benchmark: {
              ...peerBenchmarkData,
              benchmarkTitle: trimStartFunc(currentBenchmarkTitle),
            },
          },
          "editName"
        )
      );
    }
    if (currentBenchmarkTitle === initialCurrentBenchmarkTitle) {
      setShowEditableInput(false);
    }
  };

  const clearStatesOnCurrentBenchmarkView = () => {
    dispatch(resetSavedBenchmarkID());
    dispatch(resetCurrentSavedBenchmark());
    setBenchMarkTitleErrors({
      ...benchmarkTitleErrors,
      existingNameError: false,
      requiredError: false,
    });
    setCurrentBenchmarkTitle("");
    setInitialCurrentBenchmarkTitle("");
    setShowCurrentBenchmarkView(false);
    dispatch(loadPeerCompanies([]));
    dispatch(searchLoadCompanyFilter([]));
    dispatch(setCompanyFilter([]));
    dispatch(setPeerCompanyFilter([]));
    dispatch(peerBenchmarkToSave(null));
    dispatch(setGlobalCompanyMappingsFilter([]));
    setShowEditableInput(false);
  };

  const clearFilter = () => {
    const newPeerBenchmarkData = {
      benchmark: {
        ...peerBenchmarkData,
        benchmarkMetadata: {
          ...metadata,
          filters: {
            label: "",
            values: [],
            columnName: "",
          },
        },
      },
    };

    dispatch(
      clearPeerBenchmarkFilter(
        peerBenchmarkState.savedBenchmarkID,
        newPeerBenchmarkData
      )
    );
  };

  const setTranspose = () => {
    setLoadingTable(true);
    const newUserPref = {
      ...userPreferences,
      userPreferenceValue: {
        ...userPreferences.userPreferenceValue,
        isTranspose:
          userPreferences && userPreferences?.userPreferenceValue
            ? !userPreferences?.userPreferenceValue.isTranspose
            : true,
      },
    };

    dispatch(patchUserPreference(newUserPref));

    setUserPreferences((userPref: any) => {
      return {
        ...userPref,
        userPreferenceValue: {
          ...userPref?.userPreferenceValue,
          isTranspose:
            userPreferences && userPreferences?.userPreferenceValue
              ? !userPreferences?.userPreferenceValue.isTranspose
              : true,
        },
      };
    });
  };

  const getFilterOptions = () => {
    return {
      activeFilter: metadata ? metadata.filters?.columnName : "",
      currentFilterText:
        metadata && metadata.filters?.values.length > 0
          ? metadata.filters.values[0]
          : "",
      currentFilter: peerBenchmarkState.currentFilter,
      setFilterPop: (filter: string) => {
        dispatch(isFilteringPeerBenchMark(filter));
      },
      handleClose: () => {
        dispatch(isFilteringPeerBenchMark(""));
      },
      handleApply: (filterText: string, filterBy: string) => {
        const newPeerBenchmarkData = {
          benchmark: {
            ...peerBenchmarkData,
            benchmarkMetadata: {
              ...metadata,
              filters: {
                label: "",
                values: [filterText],
                columnName: filterBy,
              },
            },
          },
        };

        dispatch(isFilteringPeerBenchMark(""));
        savePeerBenchmarkData(
          peerBenchmarkState.savedBenchmarkID,
          newPeerBenchmarkData
        ).then(() => {
          dispatch(renameEditSuccess(true));
        });
      },
    };
  };

  const dropDownFilterChange = (e: any) => {
    let selectedRange: any, dateRange: any;
    if (peerBenchmarkData.benchmarkType === BENCHMARK_TYPE.MSCI) {
      selectedRange = dropDownOptions.find((option) => option.name === e);
      if (selectedRange) {
        dateRange = getQuarterRange(selectedRange.quarter, selectedRange.year);
      }
    }
    setSelectedFilterOption(e);
    dispatch(
      changePeerBenchmarkPeriod({
        benchmarkId: peerBenchmarkData.benchmarkId,
        benchmarkTypeId: peerBenchmarkData.benchmarkType,
        selectedReportedYear:
          peerBenchmarkData.benchmarkType === BENCHMARK_TYPE.MSCI
            ? parseInt(selectedRange?.year)
            : parseInt(e),
        selectedQuarter:
          peerBenchmarkData.benchmarkType === BENCHMARK_TYPE.MSCI
            ? dateRange
            : null,
      })
    );
    setPeerBenchmarkData((peerBenchmark: any) => {
      return {
        ...peerBenchmark,
        selectedReportedYear:
          peerBenchmarkData.benchmarkType === BENCHMARK_TYPE.MSCI
            ? parseInt(selectedRange?.year)
            : parseInt(e),
        selectedQuarter:
          peerBenchmarkData.benchmarkType === BENCHMARK_TYPE.MSCI
            ? dateRange
            : null,
      };
    });
    dispatch(settingSavedUserPreference(userPreferences));
  };

  const getFilterName = (filterType: string) => {
    switch (filterType) {
      case peerBenchmarkTableFilter.company.type:
        return peerBenchmarkTableFilter.company.name;
      default:
        return "";
    }
  };

  const getBenchmarkCompaniesPayload = (
    tmpCompany: any,
    isBaseCompany: boolean,
    displayOrder: number
  ) => {
    return {
      benchmarkId: tmpCompany.benchmarkId,
      cikNumber: tmpCompany.cikNumber,
      isBaseCompany: isBaseCompany,
      displayOrder: displayOrder,
      isSuggestedPeerCompany:
        tmpCompany.isSuggestedPeerCompany === true ? true : false,
      globalCompany: {
        companyName: tmpCompany.globalCompany.companyName,
        cikNumber: tmpCompany.cikNumber,
        globalCompanyId: tmpCompany.globalCompanyId,
      },
      globalCompanyId: tmpCompany.globalCompanyId,
    };
  };

  const payloadForRemovingCellorRow = (item: string, type?: string) => {
    {
      let { benchmarkCompanies, fields } =
        peerBenchmarkState.currentSavedBenchmark;

      //array variable to set the display order to the peer companies after removal of a company/group
      let peerDisplayOrderValues: any[] =
        peerBenchmarkState.globalCompanyMappingsFilter
          .filter((gc: Partial<GlobalCompanyGroup>) => !gc.isBaseMapping)
          .map((gc: Partial<GlobalCompanyGroup>) => gc.displayOrder)
          .concat(
            peerBenchmarkState.peerCompanyFilter.map(
              (pc: CompanyFilter) => pc.displayOrder
            )
          )
          .sort();

      //object array to hold the temporary global company mappings
      let tmpGlobalCompanyMappings: {
        globalCompanyMappingId: number;
        isBaseMapping: boolean;
      }[] =
        peerBenchmarkState.globalCompanyMappingsFilter &&
        peerBenchmarkState.globalCompanyMappingsFilter.length
          ? peerBenchmarkState.globalCompanyMappingsFilter.map(
              (globalComp: Partial<GlobalCompanyGroup>) => {
                return {
                  globalCompanyMappingId:
                    globalComp.globalCompanyMappingId ?? 1,
                  globalCompanyMappingName: globalComp.globalCompanyMappingName,
                  isBaseMapping: globalComp.isBaseMapping ?? false,
                  displayOrder:
                    peerDisplayOrderValues.indexOf(globalComp.displayOrder) +
                      1 ?? 0,
                };
              }
            )
          : [];
      let removedGroupedCompanies: string[] = []; //to collect all the names of grouped companies against the default company to remove

      //performs the push operation of company names into above variable
      peerBenchmarkState.globalCompanyMappingsFilter &&
        peerBenchmarkState.globalCompanyMappingsFilter.length &&
        peerBenchmarkState.globalCompanyMappingsFilter.filter((group: any) => {
          if (group.globalCompanyMappingName === item) {
            group.globalCompanyMappingDetails.map((mapping: any) => {
              removedGroupedCompanies.push(mapping.globalCompany.companyName);
            });
          }
        });

      //holds the object for baseline company
      let baselineCompany = getBenchmarkCompaniesPayload(
        benchmarkCompanies[0],
        true,
        0
      );

      //variable to hold list of benchmark companies to send to payload after filtering the removed compan(y)/(ies)
      let newBenchmarkCompanies = benchmarkCompanies.filter(
        (companies: any) => {
          if (!removedGroupedCompanies.length)
            return companies.globalCompany.companyName !== item;
          //removes only the company name from the list of benchmarkCompanies
          else
            return !removedGroupedCompanies.includes(
              companies.globalCompany.companyName //removes all companies under the group when removing a default grouped company
            );
        }
      );

      //to remove mapping to the removed group of companies
      tmpGlobalCompanyMappings = tmpGlobalCompanyMappings.filter(
        (group: any) => {
          return group.globalCompanyMappingName !== item;
        }
      );
      if (
        (peerBenchmarkState.globalCompanyMappingsFilter.length === 0 &&
          benchmarkCompanies[0].isBaseCompany !== true) ||
        (peerBenchmarkState.globalCompanyMappingsFilter &&
          peerBenchmarkState.globalCompanyMappingsFilter.filter(
            (g: Partial<GlobalCompanyGroup>) => g.isBaseMapping
          ).length === 0 &&
          benchmarkCompanies[0].isBaseCompany !== true)
      )
        newBenchmarkCompanies.push(baselineCompany); // to push baseline company back to the newBenchmarkCompanies in case it got removed

      let newTrackingType;
      let newMetrics = [...fields];
      if (benchmarkCompanies.length > newBenchmarkCompanies.length) {
        newTrackingType = BenchmarkActionTypes.Edit_Benchmark_RemoveCompany;
      } else {
        newMetrics = newMetrics.filter((metric: any) => {
          return metric.fieldName !== item;
        });

        if (fields.length > newMetrics.length) {
          newTrackingType = BenchmarkActionTypes.Edit_Benchmark_RemoveMetric;
        }
      }
      let newData: any;
      if (type === "MSCI") {
        let yearQuarterFilterdata: dataForFilter;
        yearQuarterFilterdata = checkScenariosForMSCIQuarterFilterForAPIPayload(
          peerBenchmarkData,
          dropDownOptions
        );
        newData = {
          benchmark: {
            ...peerBenchmarkData,
            selectedQuarter: yearQuarterFilterdata.selectedQuarter,
            selectedReportedYear: yearQuarterFilterdata.selectedReportedYear,
            benchmarkCompanies: newBenchmarkCompanies,
            metrics: newMetrics,
            globalCompanyMappings: tmpGlobalCompanyMappings,
          },
          trackingType:
            newTrackingType || BenchmarkActionTypes.Null_or_Zero_tracking_type,
        };
      } else if (type === "CDP") {
        newData = {
          benchmark: {
            ...peerBenchmarkData,
            selectedReportedYear:
              peerBenchmarkData.selectedReportedYear ?? dropDownOptions[0],
            benchmarkCompanies: newBenchmarkCompanies,
            metrics: newMetrics,
            globalCompanyMappings: tmpGlobalCompanyMappings,
          },
          trackingType:
            newTrackingType || BenchmarkActionTypes.Null_or_Zero_tracking_type,
        };
      }

      dispatch(
        removePeerBenchmarkItems(peerBenchmarkState.savedBenchmarkID, newData)
      );
    }
  };
  return (
    <div
      className={classNames(`${BLOCK}`, {
        [`${BLOCK}--padding`]:
          !peerBenchmarkState.loading &&
          !createNew &&
          !showCurrentBenchmarkView,
      })}
      data-testid="peer-benchmark-container"
    >
      {peerBenchmarkState.loading && (
        <div className={classNames(`${BLOCK}__empty-container`)}>
          <Icon
            name={"loading"}
            width={50}
            height={50}
            className="loading-icon"
            data-test="loading-icon"
          />
        </div>
      )}

      {!peerBenchmarkState.loading && createNew && (
        <CreateNewView
          handleCreateNew={setCreateNew}
          editView={editView}
          peerBenchmarkData={peerBenchmarkData}
          data-test="create-new-modal"
        />
      )}
      {!peerBenchmarkState.loading && !createNew && (
        <div>
          <div
            className={classNames(`${BLOCK}__header`, {
              [`${BLOCK}__header-for-current`]: showCurrentBenchmarkView,
            })}
          >
            {showCurrentBenchmarkView && (
              <div
                className={`${BLOCK}__back-button`}
                onClick={() => {
                  clearStatesOnCurrentBenchmarkView();
                  dispatch(
                    loadSavedPeerbenchmarks(
                      DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD
                    )
                  );
                  history.replace(peerBenchmarkPath, {});
                }}
                data-testid="peer-benchmark-back-button"
              >
                <Icon name="chevron-left" width={24} height={24} />
              </div>
            )}
            <div
              className={classNames(`${BLOCK}__header-title`, {
                [`${BLOCK}__header-name`]: showCurrentBenchmarkView,
              })}
            >
              {showCurrentBenchmarkView ? (
                <>
                  <div className={`${BLOCK}__name-container`}>
                    {showEditableInput && (
                      <input
                        className={classNames(`${BLOCK}__editabled-input`, {
                          [`${BLOCK}__editabled-input--active`]:
                            showEditableInput,
                          [`${BLOCK}__editabled-input--error`]:
                            benchmarkTitleErrors.requiredError ||
                            benchmarkTitleErrors.existingNameError,
                        })}
                        disabled={!showEditableInput}
                        value={currentBenchmarkTitle}
                        onBlur={() => {
                          if (showEditableInput) {
                            editPeerBenchmarkNameHandler();
                          }
                        }}
                        maxLength={200}
                        onChange={onChangeHandler}
                        onKeyDown={onEnter}
                        ref={editableInputRef}
                        data-testid="PeerBenchmark name edit container"
                      />
                    )}
                    {!showEditableInput && (
                      <span className={`${BLOCK}__header-not-editable-title`}>
                        {currentBenchmarkTitle.length > 40
                          ? `${currentBenchmarkTitle.slice(0, 40)}...`
                          : currentBenchmarkTitle}
                        {currentBenchmarkTitle.length > 40 && (
                          <Tooltip position={TooltipPosition.bottom}>
                            {currentBenchmarkTitle as any}
                          </Tooltip>
                        )}
                      </span>
                    )}
                    <div
                      className={`${BLOCK}__header-pencil-container`}
                      onClick={() => {
                        setShowEditableInput(true);
                      }}
                      data-testid="Edit"
                    >
                      <Icon width={24} height={24} name="pencil" />
                    </div>
                    {benchmarkTitleErrors.requiredError ? (
                      <span className={`${BLOCK}__name-error`}>
                        <FormattedMessage id="peer-benchmarks.required.name" />
                      </span>
                    ) : benchmarkTitleErrors.existingNameError ? (
                      <span className={`${BLOCK}__name-error`}>
                        <FormattedMessage id="peer-benchmarks.name.error" />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : peerBenchmarkState.savedBenchmark &&
                peerBenchmarkState.savedBenchmark.benchmarks &&
                peerBenchmarkState.savedBenchmark.benchmarks.length === 0 ? (
                <FormattedMessage id="peer-benchmarks.header" />
              ) : (
                <FormattedMessage
                  id="peer-benchmarks.header.count"
                  data-test="peer-benchmark header"
                />
              )}
              {showCurrentBenchmarkView &&
                peerBenchmarkData &&
                dropDownOptions &&
                dropDownOptions.length > 0 && (
                  <YearDropDown
                    values={selectedFilterOption}
                    options={
                      peerBenchmarkData.benchmarkType === BENCHMARK_TYPE.CDP
                        ? peerBenchmarkData.availableReportedYears.map(String)
                        : getQuartersWithYears()?.map(
                            (quarter: any) => quarter?.name
                          )
                    }
                    handleChange={dropDownFilterChange}
                    loading={false}
                    handleSelect={dropDownFilterChange}
                    handleUnselect={dropDownFilterChange}
                    key={`dropdown--insights.select.categories1`}
                  />
                )}
            </div>
            {(showCurrentBenchmarkView || !createNew) && (
              <div className={`${BLOCK}__header-actions`}>
                {showCurrentBenchmarkView &&
                peerBenchmarkData &&
                peerBenchmarkData.benchmarkType !== BENCHMARK_TYPE.CDP ? (
                  <>
                    <Toggle
                      value={
                        !!(
                          userPreferences &&
                          userPreferences?.userPreferenceValue &&
                          userPreferences?.userPreferenceValue?.isTranspose
                        )
                      }
                      label={
                        <FormattedMessage id="peer-benchmarks.grid.transpose" />
                      }
                      onChange={() => setTranspose()}
                      tooltip={<FormattedMessage id="tooltip.toggle" />}
                      data-testid="transpose-button"
                    />
                  </>
                ) : (
                  showCurrentBenchmarkView && (
                    <div className={`${BLOCK}-expand-collapse`}>
                      <Button
                        dataTest="expand-collapse-btn"
                        className={`text-button ${BLOCK}__expand-collapse-btn`}
                        disabled={false}
                        formattedText={
                          !expandAllMetricsValues ||
                          openCategory?.length !==
                            peerBenchmarkState?.currentSavedBenchmark
                              ?.metricCategories?.length
                            ? "peer-benchmarks.button.expand.all"
                            : "peer-benchmarks.button.collapse.all"
                        }
                        iconName={
                          !expandAllMetricsValues ||
                          openCategory?.length !==
                            peerBenchmarkState?.currentSavedBenchmark
                              ?.metricCategories?.length
                            ? "expandAll"
                            : "collapseAll"
                        }
                        onClick={() => {
                          if (
                            !expandAllMetricsValues ||
                            openCategory?.length !==
                              peerBenchmarkState?.currentSavedBenchmark
                                ?.metricCategories?.length
                          ) {
                            setOpenCategory(
                              peerBenchmarkState?.currentSavedBenchmark?.metricCategories?.map(
                                (metric: any) => metric?.metricCategoryId
                              )
                            );
                            setExpandAllMetricsValue(true);
                            dispatch(
                              patchUserPreference({
                                ...userPreferences,
                                userPreferenceValue: {
                                  ...userPreferences.userPreferenceValue,
                                  isExpanded: true,
                                  expandedMetricCategories:
                                    peerBenchmarkState?.currentSavedBenchmark?.metricCategories?.map(
                                      (metric: any) => metric?.metricCategoryId
                                    ),
                                },
                              })
                            );
                          } else {
                            setOpenCategory([]);
                            setExpandAllMetricsValue(false);
                            dispatch(
                              patchUserPreference({
                                ...userPreferences,
                                userPreferenceValue: {
                                  ...userPreferences.userPreferenceValue,
                                  isExpanded: false,
                                  expandedMetricCategories: [],
                                },
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  )
                )}
                {showCurrentBenchmarkView && peerBenchmarkData && (
                  <>
                    <button
                      className={`${BLOCK}__export-button`}
                      data-test="export-peerBenchmark-button"
                      onClick={exportHandler}
                    >
                      <Icon name="xls" height={24} width={24} />
                      <div style={{ marginLeft: "0.25rem" }}>
                        <FormattedMessage id="shortlists.export" />
                      </div>
                      <Tooltip position={TooltipPosition.top}>
                        <FormattedMessage id="peerbenchmark.export.tooltip" />
                      </Tooltip>
                    </button>
                    <button
                      className={`${BLOCK}__edit-button`}
                      data-testid="edit-button-blue"
                      onClick={() => handleEdit()}
                    >
                      <Icon name="edit-box-blue" height={14} width={14} />
                      <div style={{ marginLeft: "0.25rem" }}>
                        <FormattedMessage id="peer.benchmark.grid.edit" />
                      </div>
                    </button>
                  </>
                )}

                <Button
                  className={`${BLOCK}__start-new-btn button-secondary`}
                  dataTest="create-new-button"
                  formattedText="workspace.peerbenchmark.create.btn"
                  onClick={() => {
                    setEditview(false);
                    setCreateNew(true);
                    clearStatesOnCurrentBenchmarkView();
                    history.replace(peerBenchmarkPath, {});
                  }}
                />
              </div>
            )}
          </div>
          {showCurrentBenchmarkView && peerBenchmarkState.savedBenchmarkID && (
            <>
              <div className={`${BLOCK}__current-content`}>
                {peerBenchmarkData &&
                peerBenchmarkData.benchmarkType !== BENCHMARK_TYPE.CDP ? (
                  <Fragment>
                    {metadata &&
                      userPreferences &&
                      userPreferences?.userPreferenceValue &&
                      userPreferences?.userPreferenceValue?.isTranspose &&
                      metadata.filters?.columnName && (
                        <div className={`${BLOCK}__active-filter-wrapper`}>
                          <ActiveFilterTag
                            filterText={`${getFilterName(
                              metadata.filters.columnName
                            )}: ${metadata.filters.values[0]}`}
                            handleRemove={clearFilter}
                          />
                        </div>
                      )}
                    <BenchmarkGridView
                      transpose={
                        !!(
                          userPreferences &&
                          userPreferences?.userPreferenceValue &&
                          userPreferences?.userPreferenceValue?.isTranspose
                        )
                      }
                      loadingTable={loadingTable}
                      setLoadingTable={setLoadingTable}
                      filter={getFilterOptions()}
                      userPreferences={userPreferences}
                      setUserPreferences={setUserPreferences}
                      removeCellOrRow={(item: string, type?: string) =>
                        payloadForRemovingCellorRow(item, "MSCI")
                      }
                      setHasNullValues={setHasNullValues}
                      benchmarkCompanies={benchmarkCompanies}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <CDPBenchmarkGridView
                      loadingTable={loadingTable}
                      setLoadingTable={setLoadingTable}
                      setHasNullValues={setHasNullValues}
                      removeCellOrRow={(item: string, type?: string) =>
                        payloadForRemovingCellorRow(item, "CDP")
                      }
                      expandAllMetricsValues={expandAllMetricsValues}
                      setExpandAllMetricsValue={setExpandAllMetricsValue}
                      setOpenCategory={setOpenCategory}
                      openCategory={openCategory}
                      benchmarkCompanies={benchmarkCompanies}
                      userPreferences={userPreferences}
                      setUserPreferences={setUserPreferences}
                    />
                  </Fragment>
                )}
              </div>
              <div className={`${BLOCK}__options`}>
                <span className={`data-source`}>
                  <Popover
                    displayText={peerBenchmarkData?.benchmarkMetadata?.source}
                    content={
                      peerBenchmarkData?.benchmarkMetadata?.sourceTooltip &&
                      peerBenchmarkData?.benchmarkMetadata?.sourceTooltip
                        .length > 0 &&
                      peerBenchmarkData?.benchmarkMetadata?.sourceTooltip[0] !==
                        ""
                        ? [
                            {
                              associatedMetricTooltipText:
                                peerBenchmarkData?.benchmarkMetadata
                                  ?.sourceTooltip &&
                                peerBenchmarkData?.benchmarkMetadata?.sourceTooltip.join(
                                  " <br/><br/>"
                                ),
                              displayOrder: 1,
                              associatedMetric: "",
                              associatedMetricDisplayName: "",
                              tooltipType: 0,
                            },
                          ]
                        : []
                    }
                    metricClassName=""
                    hideTabs={true}
                  />
                  {peerBenchmarkData?.metrics &&
                  peerBenchmarkData.metrics.filter(
                    ({ fieldName }: any) =>
                      fieldName === peerBenchmarkIvaRatingTrend
                  ).length ? (
                    <span className={`${BLOCK}__trend-indicator`}>
                      <Icon name="metric-increase" width={15} height={15} />
                      <FormattedMessage id="peer.benchmark.metric.indicator.increase" />
                      <Icon name="metric-decrease" width={15} height={15} />
                      <FormattedMessage id="peer.benchmark.metric.indicator.decrease" />
                      <Icon name="metric-no-change" width={15} height={15} />
                      <FormattedMessage id="peer.benchmark.metric.indicator.no-change" />
                    </span>
                  ) : (
                    <span></span>
                  )}
                </span>
                {hasNullValues && (
                  <span className={`${BLOCK}__no-data`}>
                    <FormattedMessage id="no.data.available" />
                  </span>
                )}
              </div>
            </>
          )}
          {peerBenchmarkState.savedBenchmark.benchmarks.length === 0 &&
          !showCurrentBenchmarkView &&
          !createNew ? (
            <>
              <div
                className={`${BLOCK}__empty`}
                data-test="empty-peerBenchmark"
              >
                <div className={`${BLOCK}__img`}>
                  <img
                    data-testid="empty-img"
                    className={""}
                    src={emptyImg}
                    alt={emptyImg}
                  />
                </div>
                <div className={`${BLOCK}__empty-text`}>
                  <FormattedMessage id="peer-benchmarks.empty" />
                </div>
                <div className={`${BLOCK}__empty-redirect`}>
                  <FormattedMessage id="peer-benchmarks.redirect" />
                  &nbsp;
                  <button
                    className={`my-searches__link ${BLOCK}__link`}
                    onClick={() => {
                      setEditview(false);
                      setCreateNew(true);
                      history.replace(peerBenchmarkPath, {});
                    }}
                    data-testid="create-new-link"
                  >
                    <FormattedMessage id="peer-benchmarks.empty-redirect-link" />
                  </button>
                </div>
              </div>
            </>
          ) : (
            !peerBenchmarkState.loading &&
            !createNew &&
            !showCurrentBenchmarkView &&
            peerBenchmarkState.savedBenchmark.benchmarks.length > 0 && (
              <SavedPeerbenchmarksTable />
            )
          )}
        </div>
      )}
      {OVERLAY_PEER_BENCHMARKS.length ? (
        <WhatsNewModal
          slides={OVERLAY_PEER_BENCHMARKS}
          slide={OVERLAY_PEER_BENCHMARKS[0]}
          page={PAGES.peerBenchmark}
        />
      ) : null}
      {showDisclarimerModal && (
        <DisclaimerModal
          title={"dashboard.export.disclaimer"}
          namePlaceholder={"dashboard.disclaimer.message"}
          handleCloseModal={() => setShowDisclarimerModal(false)}
          exportDiclaimer={() => {
            dispatch(
              sendExportItem({
                exportReferenceId: peerBenchmarkState.savedBenchmarkID,
                exportFileName: currentBenchmarkTitle,
                exportType: EXPORT_TYPES.PEER_BENCHMARK,
                exportFormat: EXPORT_FORMAT.xls,
                searchCriteria: {
                  contentText: "",
                },
              })
            );
          }}
        />
      )}
    </div>
  );
};

export default PeerBenchmark;
