import Button from "components/shared/button/button";
import FormattedMessage from "components/shared/formatted-message/formatted-message";

type Props = {
  BLOCK: string;
  position: any;
  show: boolean;
  copySelection: any;
  createTag: any;
  showTagOption: boolean;
};

const OverlayMenu = ({
  BLOCK,
  position,
  show,
  copySelection,
  createTag,
  showTagOption,
}: Props) => {
  return show ? (
    <div
      className={`${BLOCK}__overlay-menu`}
      style={{ top: position.y, left: position.x }}
    >
      {showTagOption && (
        <Button
          onClick={createTag}
          className={`${BLOCK}__overlay-action`}
          iconName="tag"
        >
          <FormattedMessage id="overlay.add-tag-text" />
        </Button>
      )}
      {/* <div className={`${BLOCK}__overlay-separator`} /> */}
      <Button
        onClick={copySelection}
        iconName="copy"
        className={`${BLOCK}__overlay-action`}
      >
        <FormattedMessage id="overlay.copy-text" />
      </Button>
    </div>
  ) : null;
};

export default OverlayMenu;
