import classNames from "classnames";
import DocumentViewer from "components/document-viewer/document-viewer";
import DocumentViewerSEC from "components/document-viewer/document-viewer.sec";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { useEffect, useRef, useState, Fragment } from "react";
import { getUserInitials, useClickOutside } from "utils/functions";
import history from "utils/history";
import SearchEmptyState from "images/search.empty-state.png";
import {
  fetchComparisonItems,
  saveComparisonSearchKeywords,
} from "services/saved-comparisons/saved-comparisons.api";
import {
  ACCESS_MODULES,
  CHECKBOX_DATA,
  comparisonNameLimit,
  comparisonPath,
  CONTENT_BENCHMARK_USER_ROLES,
  EXPORT_FORMAT,
  TAG_NOTIFICATION_ACTIONS,
  unauthorizedPath,
} from "utils/constants";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "services/store.service";
import { addToastMessage, showTooltip } from "services/commons.service";
import { sendExportItem } from "services/download/download.service";
import {
  shareCompareList,
  shareCompareSuccess,
  saveComparison,
  setComparisonsSelectedList,
  saveAsComparison,
  autoSaveComparisonList,
  deleteEsgReportFromCompare,
  deleteSECReportFromCompare,
  toggleUncheckedKeyword,
  uncheckKeywordReport,
  addToComparisonTrack,
  executeAddToComparisonTrack,
  triggerPageAddToComparisonTrack,
  triggerPageNewComparisonTrack,
  newComparisonTrack,
  executeNewComparisonTrack,
  contentComparisonExcelExportTrack,
  contentComparisonShareCollab,
} from "services/saved-comparisons/saved-comparisons.service";
import ShareToUsersModal from "components/shared/share-to-users-modal/share-to-users-modal";
import {
  DEFAULT_COMPARISON_EXPORT_PAYLOAD,
  shareItemType,
} from "utils/constants";
import SaveComparison from "./save-comparison";
import Modal from "components/shared/modal/modal";
import ReportModal from "components/search/report.modal";
import ReportSelector from "./report-selector";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  ComparisonItem,
  ComparisonUncheckKeyword,
} from "services/saved-comparisons/saved-comparisons.model";
import Split from "react-split";
import Button from "components/shared/button/button";
import TagsPanel from "components/tags/tags-panel";
import CreateTag from "components/tags/tag.create-panel";
import TagContext from "components/tags/tag.context";
import { Tag, TagCountDetail, TagSelected } from "services/tags/tags.model";
import ExportPanel from "components/insights/export-panel";
import { setShowExportPanel } from "services/insights/insights.service";
import TagNotification from "components/tags/tag.notification";
import socket, { rooms } from "utils/socket";
import { broadcastUserClosesCB } from "services/tags/tags.api";
import SearchPanel from "./search-panel";

const Comparison = () => {
  const BLOCK = "comparison";
  const dispatch = useDispatch();
  const location = useLocation();
  const [reportList, setReportList] = useState<any[]>([]);
  const [reportToCompareIndex, setReportToCompareIndex] = useState<number>(1);
  const [showExportPanel, setEnableExportPanel] = useState<boolean>(false);
  const [baseReportIndex, setBaseReportIndex] = useState<number>(0);
  //const [isReOrdered, setIsReOrdered] = useState<boolean>(false);
  const [goToSearch, setGoToSearch] = useState<boolean>(false);
  const [showSettingsSelector, setShowSettingsSelector] =
    useState<boolean>(false);
  const commons = useSelector((state: RootStore) => state.commons);
  const comparisonState = useSelector(
    (state: RootStore) => state.comparisonState
  );
  const downloadState = useSelector((state: RootStore) => state.download);
  const insightsState = useSelector((state: RootStore) => state.insights);
  const currentUser = useSelector(
    (state: RootStore) => state.commons.currentUser
  );
  const localStoreHideDocumentPanel = `${commons.currentUser.userId}-comparison-hideDocumentPanel`;
  const localStoreHideKeywordsPanel = `${commons.currentUser.userId}-comparison-hideKeywordsPanel`;
  const [showDocumentPanel, setShowDocumentPanel] = useState<boolean>(
    localStorage.getItem(localStoreHideDocumentPanel) ? false : true
  );
  const [showKeywordsPanel, setShowKeywordsPanel] = useState<boolean>(
    localStorage.getItem(localStoreHideKeywordsPanel) ? false : true
  );

  const [showReorderPanel, setShowReorderPanel] = useState<boolean>(false);
  const [showCompareModal, setShowCompareModal] = useState<Boolean>(false);
  const [IncludeInSharing, setIncludeInSharing] = useState<any[]>([]);
  const [selectedExport, setExport] = useState<string[]>([
    CHECKBOX_DATA.EXPORT_EXCEL,
  ]);
  const [exportFormat, setExportFormat] = useState<any[]>([
    CHECKBOX_DATA.EXPORT_DETAILEDVIEW,
  ]);
  const [comparisonId, setComparisonId] = useState<number>(0);
  const settingsMenuRef = useRef(null);
  const reOrderPanelRef = useRef(null);
  const [showSavePopUp, setShowSavePopUp] = useState<boolean>(false);
  const NoteState = useSelector((state: RootStore) => state.note);
  const [currentComparisonName, setCurrentComparisonName] =
    useState<string>("");
  const [currentComparison, setCurrentComparison] = useState<any>([]);
  const [showContinueModal, setShowContinueModal] = useState<boolean>(false);
  useClickOutside(settingsMenuRef, () => setShowSettingsSelector(false));
  useClickOutside(reOrderPanelRef, () => setShowReorderPanel(false));
  const [comparisonSplit, setComparisonSplit] = useState<number>(0);
  const [comparisonKeywords, setComparisonKeywords] = useState<string | null>(
    null
  );
  const divRef = useRef(null);

  const exportFormatData: string[] = [
    CHECKBOX_DATA.EXPORT_COMPACTVIEW,
    CHECKBOX_DATA.EXPORT_DETAILEDVIEW,
  ];
  // Tags
  const [showCreateTag, setShowCreateTag] = useState<boolean>(false);
  const [showTagPanel, setShowTagPanel] = useState<boolean>(false);
  const [currentTagsList, setCurrentTagsList] = useState<Tag[]>([]);
  const [selectedTag, setSelectedTag] = useState<null | TagSelected>(null);
  const [showSearchPanel, setShowSearchPanel] = useState<boolean>(false);
  const [checkboxData, setCheckboxData] = useState<string[]>([
    CHECKBOX_DATA.NOTES,
  ]);
  const [showAllTags, setShowAllTags] = useState<number[]>([]);
  const [tagCountDetail, setTagCountDetail] = useState<TagCountDetail[]>([]);
  const [tagSelection, setTagSelection] = useState(null);
  const [tagCreated, setTagCreated] = useState(false);
  const [referenceId, setReferenceId] = useState<number>(0);
  const [tagReportId, setTagReportId] = useState<number>(0);
  const [comparisonItemIdToUpdate, setComparisonItemIdToUpdate] =
    useState<number>(-1);
  const [tagPanelMaxMargin, setTagPanelMaxMargin] = useState<boolean>(false);
  const [tagPanelMinMargin, setTagPanelMinMargin] = useState<boolean>(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notificationTypeId, setNotificationTypeId] = useState(-1);
  const [usersOnDocument, setUsersOnDocument] = useState<
    {
      userId: number;
      firstName: string;
      lastName: string;
      userRole: number;
      notificationTypeId: number;
    }[]
  >([]);

  const exportOptions: string[] = [
    CHECKBOX_DATA.EXPORT_EXCEL,
    CHECKBOX_DATA.EXPORT_PDF,
  ];

  const baseReportActionsContainerRef = useRef<HTMLDivElement>(null);
  const otherReportActionsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const comparisonIdPath = parseInt(
      location.pathname.replace(`${comparisonPath}/`, "")
    );
    if (comparisonIdPath > 0 && commons.currentUser.userId > 0) {
      socket.on("comparison.user.status", updateComparisonUsers);
      socket.join(rooms.comparison(comparisonIdPath));

      return () => {
        broadcastUserClosesCB({
          notificationType: TAG_NOTIFICATION_ACTIONS.USER_LEAVE,
          refernceId: comparisonIdPath,
          notifyToUser: {
            userId: commons.currentUser.userId,
            firstName: commons.currentUser.firstName,
            lastName: commons.currentUser.lastName,
          },
        });

        socket.leave(rooms.comparison(comparisonIdPath));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commons.currentUser.userId, location]);

  useEffect(() => {
    const comparisonIdPath = parseInt(
      location.pathname.replace(`${comparisonPath}/`, "")
    );

    if (comparisonIdPath && comparisonIdPath > 0 && tagSelection !== null) {
      const refId =
        reportList.filter((r) => r.reportId === tagReportId)[0] ?? null;
      referenceId > 0
        ? setShowCreateTag(true)
        : setReferenceId((reference) => {
            setShowCreateTag(true);
            return refId ? refId.comparisonItemId : reference;
          });
    }

    const unloadCallback = (e: any) => {
      if (comparisonIdPath && comparisonIdPath > 0) return;
      e.preventDefault();
      e.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    if (
      currentUser &&
      currentUser.accessibleFeatures &&
      currentUser.accessibleFeatures.length > 0 &&
      !currentUser.accessibleFeatures.some(
        (feature) =>
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_All ||
          feature.applicationFeatureId === ACCESS_MODULES.Feature_ESG_Comparison
      )
    ) {
      history.push(unauthorizedPath);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, currentUser]);

  useEffect(() => {
    const comparisonIdPath = parseInt(
      location.pathname.replace(`${comparisonPath}/`, "")
    );
    if (comparisonIdPath && comparisonId !== comparisonIdPath) {
      const prevSelectedList = [...comparisonState.selectedList];
      dispatch(setComparisonsSelectedList([]));
      setComparisonId(comparisonIdPath);
      fetchComparisonItems(comparisonIdPath, {
        params: { execute: true },
      }).then((response: any) => {
        //This logic is not in use currently
        /* istanbul ignore if */
        if (location.pathname.split("/")[3]) {
          const report = parseInt(location.pathname.split("/")[3]);
          let reportToCompare = response.data.comparisonItems.findIndex(
            (comparisonItem: ComparisonItem) =>
              comparisonItem.comparisonItemId === report
          );
          if (reportToCompare !== -1 && reportToCompare !== baseReportIndex) {
            setReportToCompareIndex(reportToCompare);
          }
        }

        // setReportToCompareIndex(2);
        setCurrentComparisonName(response.data.comparisonName);
        setComparisonKeywords(response.data.contentKeywords);
        setCurrentComparison(response.data);
        setUsersOnDocument(
          response.data.collaborationActiveUsers?.map((u: any) => ({
            userId: u.userId,
            firstName: u.firstName,
            lastName: u.lastName,
            userRole: u.userRole,
          })) ?? []
        );
        if (
          prevSelectedList &&
          prevSelectedList[0] &&
          response.data.comparisonItems.filter((compItem: any) =>
            prevSelectedList[0].reportId
              ? prevSelectedList[0].reportId === compItem.reportId
              : prevSelectedList[0].sectionId === compItem.sectionId
          ).length === 0
        ) {
          setReportList([
            ...response.data.comparisonItems,
            ...prevSelectedList,
          ]);
        } else {
          setReportList(response.data.comparisonItems);
        }
      });
    } else {
      setReportList(comparisonState.selectedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonState.selectedList, location]);

  useEffect(() => {
    if (comparisonState.shareSuccess) {
      dispatch(
        addToastMessage({
          description: (
            <FormattedMessage
              id={
                comparisonState.isCollab
                  ? "compare.share.success.collab"
                  : "compare.share.success"
              }
            />
          ),
        })
      );
      dispatch(contentComparisonShareCollab(false));
      dispatch(shareCompareSuccess(false));
      const prevSelectedList = [...comparisonState.selectedList];
      fetchComparisonItems(comparisonId, {
        params: { execute: true },
      }).then((response: any) => {
        setComparisonKeywords(response.data.contentKeywords);
        setCurrentComparison(response.data);
        setUsersOnDocument(
          response.data.collaborationActiveUsers?.map((u: any) => ({
            userId: u.userId,
            firstName: u.firstName,
            lastName: u.lastName,
            userRole: u.userRole,
          })) ?? usersOnDocument
        );
        if (
          prevSelectedList &&
          prevSelectedList[0] &&
          response.data.comparisonItems.filter((compItem: any) =>
            prevSelectedList[0].reportId
              ? prevSelectedList[0].reportId === compItem.reportId
              : prevSelectedList[0].sectionId === compItem.sectionId
          ).length === 0
        ) {
          setReportList([
            ...response.data.comparisonItems,
            ...prevSelectedList,
          ]);
        } else {
          setReportList(response.data.comparisonItems);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonState.shareSuccess]);

  useEffect(() => {
    if (
      comparisonState.executeAddToComparisonTrack &&
      currentComparisonName !== ""
    ) {
      let addToComparisonPayload = {
        pageName: comparisonState.triggerPageAddToComparisonTrack,
        addToComparison: `Add to comparison | ${currentComparisonName}`,
      };
      dispatch(addToComparisonTrack(addToComparisonPayload));
      dispatch(executeAddToComparisonTrack(false));
      dispatch(triggerPageAddToComparisonTrack(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentComparisonName]);

  useEffect(() => {
    handleCloseTagsPanel();
    if (
      comparisonState.executeNewComparisonTrack &&
      comparisonState.triggerPageNewComparisonTrack !== "" &&
      comparisonState.savedComparisonResult.comparisonName
    ) {
      let tmpNewComparisonPayload = {
        newComparison: `New comparison | ${comparisonState.savedComparisonResult.comparisonName}`,
        pageName: comparisonState.triggerPageNewComparisonTrack,
      };
      dispatch(newComparisonTrack(tmpNewComparisonPayload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, comparisonState.executeNewComparisonTrack]);

  useEffect(() => {
    if (reportList.length > 1) {
      setTagPanelMinMargin(false);
    } else {
      setTagPanelMinMargin(true);
    }
  }, [reportList.length]);

  const updateComparisonUsers = (payload: any) => {
    const responseData = payload.data;
    setUsersOnDocument((currentUsers) =>
      responseData.notificationTypeId === TAG_NOTIFICATION_ACTIONS.USER_LEAVE
        ? currentUsers.filter((u) => u.userId !== responseData.userId)
        : currentUsers.filter((u) => u.userId === responseData.userId).length >
          0
        ? [
            ...currentUsers.filter((u) => u.userId !== responseData.userId),
            {
              ...currentUsers.filter(
                (u) => u.userId === responseData.userId
              )[0],
              userRole: responseData.userRoleID,
            },
          ]
        : [
            ...currentUsers,
            { ...responseData, userRole: responseData.userRoleID },
          ]
    );
    if (
      [
        TAG_NOTIFICATION_ACTIONS.USER_REMOVED,
        TAG_NOTIFICATION_ACTIONS.ROL_CHANGEG,
        TAG_NOTIFICATION_ACTIONS.CONTENT_BENCHMARK_DELETED,
      ].includes(responseData.notificationTypeId)
    ) {
      const comparisonIdPath = parseInt(
        location.pathname.replace(`${comparisonPath}/`, "")
      );
      setNotificationTypeId(responseData.notificationTypeId);
      setShowNotificationModal(
        showNotificationModal ||
          responseData.userId === commons.currentUser.userId
      );
      if (
        responseData.notificationTypeId === TAG_NOTIFICATION_ACTIONS.ROL_CHANGEG
      )
        fetchComparisonItems(comparisonIdPath).then((response: any) =>
          setCurrentComparison(response.data)
        );
    }
  };

  const shareCompare = () => {
    setShowCompareModal(true);
  };

  const exportCompare = () => {
    let payload = {
      ...DEFAULT_COMPARISON_EXPORT_PAYLOAD,
    };

    if (comparisonState.uncheckedKeywordReports) {
      payload = {
        ...payload,
        withoutKeywordHighlightItems:
          comparisonState.uncheckedKeywordReports &&
          comparisonState.uncheckedKeywordReports.comparisionItemIds
            ? comparisonState.uncheckedKeywordReports.comparisionItemIds.map(
                (uncheckedKeyword: ComparisonUncheckKeyword) =>
                  uncheckedKeyword.comparisonItemId
              )
            : [],
      };
    }

    dispatch(
      sendExportItem({
        ...payload,
        exportReferenceId: comparisonId,
        exportFileName: currentComparisonName,
        exportFormat: selectedExport.includes(CHECKBOX_DATA.EXPORT_PDF)
          ? EXPORT_FORMAT.pdf
          : EXPORT_FORMAT.xls,
        isExportTags: true,
        isExportNotes: IncludeInSharing.includes(CHECKBOX_DATA.NOTES),
        isCompactView: exportFormat.includes(CHECKBOX_DATA.EXPORT_COMPACTVIEW),
        downloadTabOpen: downloadState.showDownloads,
      })
    );
    if (selectedExport.includes(CHECKBOX_DATA.EXPORT_EXCEL))
      dispatch(contentComparisonExcelExportTrack(true));

    dispatch(setShowExportPanel(false));
    setEnableExportPanel(false);
    setExport([exportOptions[0]]);
    setIncludeInSharing([]);
  };

  const displayControl = (offset: number): boolean => {
    const newIndex = reportToCompareIndex + offset;
    if (newIndex === baseReportIndex) return displayControl(offset + offset);
    return newIndex >= 0 && newIndex < reportList.length;
  };

  const goToReport = (offset: number): any => {
    const newIndex = reportToCompareIndex + offset;
    if (newIndex === baseReportIndex) return goToReport(offset + offset);
    setReportToCompareIndex(reportToCompareIndex + offset);
  };

  const shareCompareHandler = (
    sharedWith: any[],
    includeNotes: boolean,
    includeTags: boolean,
    IsCollaboration: number
  ) => {
    dispatch(
      shareCompareList({
        comparisonName: currentComparisonName,
        comparisonID: comparisonId,
        sharedWith,
        sourceComparisonId: comparisonId,
        includeNotes,
        includeTags,
        IsCollaboration,
      })
    );
    setShowCompareModal(false);
  };

  /* This function will called when 'fromComparison: false', 
     and this is comparison component not found any refernce in the same component of this function with 'fromComparison : false' */
  /* istanbul ignore next */
  const handleDelete = (reportIndex: number) => {
    const reportListCopy = reportList.slice();
    const reportDeleted = reportListCopy.splice(reportIndex, 1)[0];

    if (reportDeleted.reportId === reportList[reportList.length - 1].reportId) {
      setReportToCompareIndex(reportList.length - 2);
    }
    const isESGReport = reportDeleted.comparisonItemType === 0;

    if (isESGReport) {
      const payload = {
        comparisonId: comparisonId,
        reportId: reportDeleted.reportId,
      };
      dispatch(deleteEsgReportFromCompare(payload));
    } else {
      const payload = {
        comparisonId: comparisonId,
        sectionId: reportDeleted.sectionId,
      };
      dispatch(deleteSECReportFromCompare(payload));
    }

    dispatch(setComparisonsSelectedList(reportListCopy));
    dispatch(
      addToastMessage({
        description: <FormattedMessage id="compare.remove.report.message" />,
      })
    );
  };

  const renderBaseDocument = () => {
    const report = reportList.filter(
      (item, index) => index === baseReportIndex
    )[0];
    const props = {
      key: `base-report-${report.comparisonItemId}`,
      fromComparison: true,
      comparisonIsSaved: comparisonId !== 0,
      report: report.comparisonItemType
        ? { ...report, elementId: report.sectionId }
        : report,
      comparisonKeywords,
      isBaseReport: true,
      isVisibleInComparison: true,
      comparisonItemId: report.comparisonItemId,
      commentNoteId: report.commentNoteId,
      showSaveComparison: setShowSavePopUp,
      deleteFromComparison: handleDelete,
      reportIndex: baseReportIndex,
      handleCheckboxChange: (id: number) => {
        dispatch(
          toggleUncheckedKeyword(
            id,
            report.comparisonItemId,
            comparisonState.uncheckedKeywordReports
          )
        );
      },
      comparisonSplit: comparisonSplit,
      setShowTagPanel,
      setSelectedTag,
      setReportList,
      comparisonId,
      actionsRef: baseReportActionsContainerRef,
    };

    return (
      <div
        key={`report-container-${baseReportIndex}`}
        className={`${BLOCK}__report`}
      >
        {report.comparisonItemType ? (
          <DocumentViewerSEC {...props} />
        ) : (
          <DocumentViewer {...props} />
        )}
      </div>
    );
  };

  const renderCompareToDocuments = () => {
    return reportList.map((report, index) => {
      if (index === baseReportIndex) {
        return <Fragment key={index}></Fragment>;
      }

      const props = {
        key: `report-${report.comparisonItemId}`,
        fromComparison: true,
        comparisonIsSaved: comparisonId !== 0,
        report: report.comparisonItemType
          ? { ...report, elementId: report.sectionId }
          : report,
        comparisonKeywords,
        isBaseReport: false,
        isVisibleInComparison: reportToCompareIndex === index,
        comparisonItemId: report.comparisonItemId,
        commentNoteId: report.commentNoteId,
        showSaveComparison: setShowSavePopUp,
        deleteFromComparison: handleDelete,
        reportIndex: index,
        handleCheckboxChange: (id: number) => {
          dispatch(
            toggleUncheckedKeyword(
              id,
              report.comparisonItemId,
              comparisonState.uncheckedKeywordReports
            )
          );
        },
        comparisonSplit: comparisonSplit,
        setShowTagPanel,
        setSelectedTag,
        setReportList,
        comparisonId,
        actionsRef:
          reportToCompareIndex === index
            ? otherReportActionsContainerRef
            : undefined,
      };
      return (
        <div
          key={`report-container-${index}`}
          className={classNames(`${BLOCK}__report`, {
            [`${BLOCK}__report--hide`]: reportToCompareIndex !== index,
          })}
        >
          {report.comparisonItemType ? (
            <DocumentViewerSEC {...props} />
          ) : (
            <DocumentViewer {...props} />
          )}
        </div>
      );
    });
  };

  const onSaveComparisonHandler = (name: string, description: string) => {
    let tmpComparisonItems = createTmpCompareList(reportList);

    let payload = {
      comparisonId: comparisonId,
      comparisonName: name,
      comparisonDescription: description,
      comparisonItems: tmpComparisonItems,
    };
    let payloadSaveAs = {
      comparisonId: comparisonId,
      comparisonName: name,
      comparisonDescription: description,
    };

    if (comparisonId === 0) {
      setGoToSearch(true);
      dispatch(saveComparison(payload));
    } else {
      dispatch(saveAsComparison(payloadSaveAs));
    }
  };
  const updateComparisonDetails = (
    savedComparisonId: number,
    savedComparisonName: string,
    comparisonItems: [],
    currComparison: []
  ) => {
    if (comparisonId === 0) {
      setComparisonId(savedComparisonId);
      setCurrentComparisonName(savedComparisonName);
      setReportList(comparisonItems);
      setCurrentComparison(currComparison);
    }
  };

  const handleStartNewComparison = () => {
    dispatch(triggerPageNewComparisonTrack(""));
    dispatch(executeNewComparisonTrack(false));
    setShowContinueModal(false);
    dispatch(setComparisonsSelectedList([]));
    setGoToSearch(false);
    history.action === "POP" || goToSearch
      ? history.push("/search")
      : history.goBack();
    dispatch(uncheckKeywordReport(null));
  };

  const handleGoToSearch = () => {
    dispatch(setComparisonsSelectedList([]));
    history.push("/search");
  };

  const createTmpCompareList = (newReportList: any) => {
    let tmpComparisonItems = newReportList.map((report: any, index: number) => {
      return {
        esgReport: {
          reportId:
            report.comparisonItemType === 0
              ? report.reportId || report.esgReport.reportId
              : 0,
          reportTitle:
            report.comparisonItemType === 0
              ? report.reportTitle || report.esgReport.reportTitle
              : "",
        },
        sectionItem: {
          sectionId: report.comparisonItemType === 0 ? 0 : report.sectionId,
          sectionName:
            report.comparisonItemType === 0 ? "" : report.reportTitle,
        },
        comparisonItemType: report.comparisonItemType,
        displayOrder: index,
        isBaseDocument: baseReportIndex === index,
        contentKeywords: report.contentKeywords,
        comparisonItemId: report.comparisonItemId,
      };
    });

    return tmpComparisonItems;
  };

  const autoSave = async (reportArrayCopy: any) => {
    let autoSavePayload = {
      comparisonId: comparisonId,
      comparisonItems: createTmpCompareList(reportArrayCopy),
    };

    dispatch(autoSaveComparisonList(autoSavePayload));
  };

  const handleDragEnd = (draggedItem: any) => {
    const reportArrayCopy = reportList.slice();
    const srcInd = draggedItem.source.index;
    const destInd = draggedItem.destination.index;

    if (srcInd !== destInd) {
      reportArrayCopy.splice(destInd, 0, reportArrayCopy.splice(srcInd, 1)[0]);

      dispatch(setComparisonsSelectedList(reportArrayCopy));
      // Has been commented due to no usage as of now
      //setIsReOrdered(true);

      if (comparisonId) {
        //auto save only if the current comparison has already been saved once
        autoSave(reportArrayCopy);
      }
    }
  };

  const handleMouseEnter = (
    e: any,
    children: any,
    position?: TooltipPosition
  ) => {
    const element = e.target;

    if (!element || element.scrollWidth > element.offsetWidth) {
      dispatch(
        showTooltip({
          children: children,
          position: position || TooltipPosition.top,
          customPosition: true,
          elementDimensions: e.target.getBoundingClientRect(),
          executeMouseLeaveEvent: true,
        })
      );
    }
  };

  const handleMouseLeave = (e: any) => {
    if (commons.toolTip.elementDimensions && !commons.toolTip.isOverTooltip) {
      dispatch(
        showTooltip({
          children: null,
          customPosition: null,
          position: null,
          arrowPosition: null,
          elementDimensions: null,
          executeMouseLeaveEvent: false,
          isOverTooltip: null,
        })
      );
    }
  };

  const renderReorderDocumentMap = (report: any, i: number) => {
    if (i === baseReportIndex) {
      return;
    }

    const unqiueID =
      report.reportId && report.reportId !== 0
        ? `${report.reportId}_${i}`
        : `${report.sectionId}_${i}`;

    let tmpReportName: any = report.reportTitle
      ? report.reportTitle
      : report.sectionName;
    return (
      <Draggable key={unqiueID} draggableId={`draggable-${unqiueID}`} index={i}>
        {(provided, snapshot) => (
          <div
            className={`${BLOCK}__reorder-panel--documents--report`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              boxShadow: snapshot.isDragging ? "0 0 0.2rem #666" : "none",
            }}
            data-testid="comparison-panel-document-report"
          >
            <Icon
              name="pdf"
              width={15}
              height={15}
              className={`${BLOCK}__reorder-panel--documents--report--icon`}
            />
            <span
              className={`${BLOCK}__reorder-panel--documents--report--title`}
              onMouseEnter={(e: any) => {
                handleMouseEnter(e, <>{tmpReportName}</>, TooltipPosition.top);
              }}
              onMouseLeave={handleMouseLeave}
            >
              {tmpReportName}
            </span>
          </div>
        )}
      </Draggable>
    );
  };

  const updateBaseReport = (index: number) => {
    if (baseReportIndex !== index) {
      let newReportArray = reportList.slice();
      const newBaseReport = newReportArray.splice(index, 1);
      newReportArray = [newBaseReport[0], ...newReportArray];

      dispatch(setComparisonsSelectedList(newReportArray));

      //since report list is changing from above dispatch, force the state values to be base (always 0th index)
      //and compare index value to be 1
      setReportToCompareIndex(1);
      setBaseReportIndex(0);

      if (comparisonId) {
        autoSave(newReportArray);
      }
    }
  };

  const handleCloseTagsPanel = () => {
    setShowTagPanel(false);
    setCurrentTagsList([]);
    setSelectedTag(null);
  };

  const showMoreUsersIcon = (contributors: any[]) => {
    return contributors.length ? (
      <span
        className={`${BLOCK}__contributor-icon ${BLOCK}__contributor-icon--more`}
      >
        +{contributors.length}
        <Tooltip position={TooltipPosition.right}>
          <>
            {contributors.map((u: any, index: number) => (
              <span key={`${BLOCK}-${index}`}>
                <span className={`${BLOCK}__contributor-icon`}>
                  {getUserInitials(u)}
                </span>
                <span>
                  {u.firstName} {u.lastName}
                  {u.userRole
                    ? ` - ${CONTENT_BENCHMARK_USER_ROLES[u.userRole]}`
                    : null}
                </span>
              </span>
            ))}
          </>
        </Tooltip>
      </span>
    ) : null;
  };

  return (
    <>
      {currentUser && (
        <div className={BLOCK} data-testid="comparison">
          <div className={`${BLOCK}__header`}>
            <div className={`${BLOCK}__title`}>
              {currentComparisonName && currentComparisonName !== "" ? (
                currentComparisonName.length > comparisonNameLimit * 0.5 ? (
                  <span className={`${BLOCK}__comparison-name`}>
                    {currentComparisonName}
                    <Tooltip position={TooltipPosition.bottom}>
                      <>{currentComparisonName}</>
                    </Tooltip>
                  </span>
                ) : (
                  currentComparisonName
                )
              ) : (
                <FormattedMessage id="comparison.title" />
              )}
              <div
                className={`${BLOCK}__title-actions`}
                style={{
                  flexGrow:
                    currentComparisonName &&
                    currentComparisonName.length > comparisonNameLimit * 0.5
                      ? 1
                      : "unset",
                }}
              >
                <span className={`${BLOCK}__icon ${BLOCK}__guidance`}>
                  <Icon name="guidance" width={24} height={24} />
                  <Tooltip position={TooltipPosition.right}>
                    <FormattedMessage id="comparison.guidance.tooltip" />
                  </Tooltip>
                </span>
                <div style={{ display: "flex" }} ref={settingsMenuRef}>
                  <Button
                    className=""
                    onClick={() => {
                      setShowSettingsSelector(!showSettingsSelector);
                    }}
                    iconName="cog"
                  />
                  <div
                    className={classNames(
                      `${BLOCK}__hidden-menu ${BLOCK}__settings-menu`,
                      {
                        [`${BLOCK}__hidden-menu--open`]: showSettingsSelector,
                      }
                    )}
                    style={{
                      flexGrow:
                        currentComparisonName &&
                        currentComparisonName.length > comparisonNameLimit * 0.5
                          ? 1
                          : "unset",
                      marginLeft: "1.6rem",
                    }}
                  >
                    <span className={`${BLOCK}__settings-title`}>
                      <FormattedMessage id="comparison.settings.title" />
                    </span>
                    <button
                      className={classNames(`${BLOCK}__menu-item`, {
                        [`${BLOCK}__menu-item--active`]: showDocumentPanel,
                      })}
                      onClick={() => {
                        showDocumentPanel
                          ? localStorage.setItem(
                              localStoreHideDocumentPanel,
                              "true"
                            )
                          : localStorage.removeItem(
                              localStoreHideDocumentPanel
                            );
                        setShowDocumentPanel(!showDocumentPanel);
                      }}
                    >
                      <FormattedMessage id="comparison.settings.show-document" />
                      {showDocumentPanel && <Icon name="tick" width={24} />}
                    </button>
                    <button
                      className={classNames(`${BLOCK}__menu-item`, {
                        [`${BLOCK}__menu-item--active`]: showKeywordsPanel,
                      })}
                      onClick={() => {
                        showKeywordsPanel
                          ? localStorage.setItem(
                              localStoreHideKeywordsPanel,
                              "true"
                            )
                          : localStorage.removeItem(
                              localStoreHideKeywordsPanel
                            );
                        setShowKeywordsPanel(!showKeywordsPanel);
                      }}
                    >
                      <FormattedMessage id="comparison.settings.show-keywords" />
                      {showKeywordsPanel && <Icon name="tick" width={24} />}
                    </button>
                  </div>
                </div>
                {reportList.length > 2 && (
                  <div className={`${BLOCK}__reorder`}>
                    <Button
                      className={`${BLOCK}__reorder-btn`}
                      onClick={() => setShowReorderPanel(true)}
                      iconName="reorder"
                      dataTest={`${BLOCK}__reorder--icon`}
                    />
                    {!showReorderPanel && (
                      <Tooltip position={TooltipPosition.bottom}>
                        <FormattedMessage id="comparison.reorder.tooltip.title" />
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={`${BLOCK}__actions`}>
              <div className={`${BLOCK}__export-container`}>
                <button
                  className={classNames(`${BLOCK}__export-button`, {
                    [`${BLOCK}__export-button--disabled`]: !comparisonId,
                  })}
                  disabled={!comparisonId}
                  onClick={() => {
                    setShowSearchPanel(true);
                  }}
                >
                  <span>
                    <Icon name={"search-primary"} width={16} height={16} />
                  </span>
                  {!comparisonId ? (
                    <Tooltip position={TooltipPosition.bottom}>
                      <FormattedMessage id="compare.search-notsave.tooltip" />
                    </Tooltip>
                  ) : (
                    <Tooltip position={TooltipPosition.bottom}>
                      <FormattedMessage id="compare.search.tooltip" />
                    </Tooltip>
                  )}
                </button>
              </div>
              {/* Contributor icons */}
              <div className={`${BLOCK}__contributor-list`}>
                {usersOnDocument?.slice(0, 6).map((u, i) => (
                  <span
                    className={`${BLOCK}__contributor-icon`}
                    key={`tag-icon-${i}`}
                  >
                    {getUserInitials(u)}
                    <Tooltip position={TooltipPosition.right}>
                      <>
                        {u.firstName} {u.lastName}
                        {u.userRole > 0 ? (
                          <>
                            <br />
                            Role: {CONTENT_BENCHMARK_USER_ROLES[u.userRole]}
                          </>
                        ) : null}
                      </>
                    </Tooltip>
                  </span>
                ))}
                {showMoreUsersIcon(
                  usersOnDocument.slice(6, usersOnDocument.length)
                )}
              </div>

              <div className={`${BLOCK}__export-container`}>
                <button
                  className={classNames(`${BLOCK}__export-button`, {
                    [`${BLOCK}__export-button--disabled`]: !comparisonId,
                  })}
                  data-testid="export-compare"
                  disabled={!comparisonId}
                  onClick={() => {
                    setEnableExportPanel(true);
                    dispatch(setShowExportPanel(true));
                  }}
                >
                  <span>
                    <FormattedMessage id="compare.export.button" />
                  </span>
                  {!comparisonId ? (
                    <Tooltip position={TooltipPosition.bottom}>
                      <FormattedMessage id="compare.export.tooltip" />
                    </Tooltip>
                  ) : (
                    <Tooltip position={TooltipPosition.bottom}>
                      <FormattedMessage id="export.compare.tooltip" />
                    </Tooltip>
                  )}
                </button>
              </div>
              <div className={`${BLOCK}__share-compare-button-icon`}>
                <button
                  className={classNames(`${BLOCK}__share-compare-button`, {
                    [`${BLOCK}__share-compare-button--disabled`]: !comparisonId,
                  })}
                  disabled={!comparisonId}
                  onClick={() => shareCompare()}
                  data-testid="share-compare"
                >
                  <Icon
                    name="share"
                    height={24}
                    width={24}
                    className={classNames(`${BLOCK}__share-compare-icon`, {
                      [`${BLOCK}__share-compare-icon--disabled`]: !comparisonId,
                    })}
                  />
                  <FormattedMessage id="compare.share.button.sideByside" />
                  {!comparisonId ? (
                    <Tooltip position={TooltipPosition.bottom}>
                      <FormattedMessage id="compare.share.tooltip" />
                    </Tooltip>
                  ) : (
                    <Tooltip position={TooltipPosition.bottom}>
                      <FormattedMessage id="share.compare" />
                    </Tooltip>
                  )}
                </button>
              </div>

              <div className={`${BLOCK}__save-actions`}>
                <Button
                  className={`${BLOCK}__save-dropdown-btn button-secondary`}
                  dataTest="save-compare"
                  onClick={() => setShowSavePopUp(true)}
                  formattedText={`${
                    comparisonId
                      ? "save-as.comparison.label"
                      : "shortlist.save.button.save"
                  }`}
                />
              </div>
              <div className={`${BLOCK}__close`}>
                <Button
                  className=""
                  dataTest="close-compare"
                  onClick={() => {
                    if (comparisonId === 0) {
                      setShowContinueModal(true);
                    } else {
                      handleStartNewComparison();
                    }
                  }}
                  iconName="cross"
                />
                <Tooltip position={TooltipPosition.left}>
                  <FormattedMessage id="comparison.close.tooltip" />
                </Tooltip>
              </div>
            </div>
          </div>
          <TagContext.Provider
            value={{
              showCreateTag,
              setShowCreateTag,
              showTagPanel,
              setShowTagPanel,
              currentTagsList,
              setCurrentTagsList,
              tagSelection,
              setTagSelection,
              tagCreated,
              setTagCreated,
              referenceId,
              setReferenceId,
              tagReportId,
              setTagReportId,
              comparisonId,
              setComparisonId,
              setShowContinueModal,
              comparisonItemIdToUpdate,
              setComparisonItemIdToUpdate,
              tagPanelMaxMargin,
              setTagPanelMaxMargin,
              tagPanelMinMargin,
              setTagPanelMinMargin,
              selectedTag,
              setSelectedTag,
              tagCountDetail,
              setTagCountDetail,
              showAllTags,
              setShowAllTags,
              showNotificationModal,
              setShowNotificationModal,
              notificationTypeId,
              setNotificationTypeId,
            }}
          >
            <div
              className={classNames(`${BLOCK}__container`, {
                [`${BLOCK}__container--hide-document`]: !showDocumentPanel,
                [`${BLOCK}__container--hide-keywords`]: !showKeywordsPanel,
              })}
            >
              <div
                className={classNames(`${BLOCK}__reorder-panel`, {
                  [`${BLOCK}__reorder-panel--open`]: showReorderPanel,
                })}
                ref={reOrderPanelRef}
                data-testid="comparison-reorder-panel"
              >
                <div className={`${BLOCK}__reorder-panel--items`}>
                  <div className={`${BLOCK}__reorder-panel--header`}>
                    <span className={`${BLOCK}__reorder-panel--header--title`}>
                      <FormattedMessage id="comparison.reorder.header.title" />
                    </span>
                    <button
                      className={`${BLOCK}__icon ${BLOCK}__reorder-panel--header--close`}
                      onClick={() => setShowReorderPanel(false)}
                      data-testid="comparison-penal-close"
                    >
                      <Icon name="cross" width={20} height={20} />
                    </button>
                  </div>
                  <div className={`${BLOCK}__reorder-panel--guidance`}>
                    <span className={`${BLOCK}__reorder-panel--guidance--text`}>
                      <FormattedMessage id="comparison.reorder.guidance.text" />
                    </span>
                  </div>
                  <DragDropContext
                    onDragEnd={(param) => {
                      handleDragEnd(param);
                    }}
                  >
                    <Droppable droppableId="droppable-1">
                      {(provided, snapshot) => (
                        <div
                          className={`${BLOCK}__reorder-panel--documents`}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {reportList.map(renderReorderDocumentMap)}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
              <Split
                className="split"
                cursor="col-resize"
                gutterSize={7}
                onDragEnd={() =>
                  setComparisonSplit(
                    divRef.current ? (divRef.current as any).offsetWidth : 0
                  )
                }
              >
                <div className={`${BLOCK}__base-report`} ref={divRef}>
                  {reportList.length ? (
                    <>
                      <ReportSelector
                        BLOCK={BLOCK}
                        selectedIndex={baseReportIndex}
                        options={reportList}
                        hideComponent={!showDocumentPanel}
                        indexListToHide={[]}
                        onSelectReport={(index: number) => {
                          updateBaseReport(index);
                          handleCloseTagsPanel();
                        }}
                        customWidth={
                          baseReportActionsContainerRef
                            ? baseReportActionsContainerRef.current?.offsetWidth
                            : undefined
                        }
                      />

                      {renderBaseDocument()}
                    </>
                  ) : (
                    <Icon
                      name={"loading"}
                      width={50}
                      height={50}
                      className="loading-icon"
                    />
                  )}
                </div>
                <div className={`${BLOCK}__other-reports`}>
                  {reportList.length > 1 ? (
                    <ReportSelector
                      BLOCK={BLOCK}
                      selectedIndex={reportToCompareIndex}
                      options={reportList}
                      hideComponent={!showDocumentPanel}
                      indexListToHide={[baseReportIndex]}
                      onSelectReport={(index: number) => {
                        if (baseReportIndex !== index) {
                          setBaseReportIndex(baseReportIndex);
                          setReportToCompareIndex(index);
                          handleCloseTagsPanel();
                        }
                      }}
                      customWidth={
                        otherReportActionsContainerRef
                          ? otherReportActionsContainerRef.current?.offsetWidth
                          : undefined
                      }
                    />
                  ) : null}
                  {renderCompareToDocuments()}
                  <div className={`${BLOCK}__report-controls`}>
                    {displayControl(-1) && (
                      <button
                        className={`${BLOCK}__control ${BLOCK}__control--left`}
                        onClick={() => goToReport(-1)}
                        data-testid="report-control-1"
                      >
                        <Icon name="chevron-left-circular" width={40} />
                      </button>
                    )}
                    {displayControl(1) && (
                      <button
                        className={`${BLOCK}__control ${BLOCK}__control--right`}
                        onClick={() => goToReport(1)}
                        data-testid="report-control-2"
                      >
                        <Icon name="chevron-right-circular" width={40} />
                      </button>
                    )}
                  </div>
                  {reportList.length === 1 && (
                    <div
                      className={`${BLOCK}__empty-section`}
                      data-test="empty-result"
                    >
                      <div className={`${BLOCK}__empty`}>
                        <img
                          className={`${BLOCK}__empty-image`}
                          src={SearchEmptyState}
                          alt={""}
                        />
                        <span className={`${BLOCK}__empty-title`}>
                          <FormattedMessage id="comparison.empty.title" />
                        </span>
                        <span className={`${BLOCK}__empty-message`}>
                          <Button
                            className={`button-primary`}
                            formattedText={`comparison.empty.button-label`}
                            onClick={() => {
                              comparisonId === 0
                                ? setShowContinueModal(true)
                                : handleGoToSearch();
                            }}
                            iconName="search"
                            iconHeight={24}
                            iconWidth={24}
                            dataTest="goto-search"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </Split>
            </div>
            {showCreateTag && <CreateTag />}
            {showTagPanel && (
              <TagsPanel
                handleClose={handleCloseTagsPanel}
                showPanel={showTagPanel}
              />
            )}
            {showSearchPanel && (
              <SearchPanel
                BLOCK={`${BLOCK}__searchPanel`}
                setShowSearchPanel={setShowSearchPanel}
                handleSearchKeyword={setComparisonKeywords}
                defaultSearchKeyword={
                  comparisonKeywords
                    ? comparisonKeywords
                    : reportList
                        .filter(({ contentKeywords }) => contentKeywords)
                        .map(({ contentKeywords }) => contentKeywords)
                        .join(" or ")
                }
                comparisonId={comparisonId}
              />
            )}
            <TagNotification />
            {showExportPanel && (
              <ExportPanel
                BLOCK={`${BLOCK}__exportPanel`}
                exportOptions={exportOptions}
                IncludeInSharing={IncludeInSharing}
                setIncludeInSharing={setIncludeInSharing}
                checkboxData={checkboxData}
                exportFormatData={exportFormatData}
                exportFormat={exportFormat}
                setExportFormat={setExportFormat}
                selectedExport={selectedExport}
                setExport={setExport}
                ExportButtonHandler={exportCompare}
                setShowExport={setEnableExportPanel}
              />
            )}
          </TagContext.Provider>
          {showCompareModal && (
            <ShareToUsersModal
              setShowShare={setShowCompareModal}
              shareShortlist={() => {}}
              setShowSearchModal={() => {}}
              shareSearch={() => {}}
              shareCompare={shareCompareHandler}
              shareType={shareItemType.compare}
              currentComparison={currentComparison}
            />
          )}
          {showSavePopUp && (
            <SaveComparison
              setShowModal={setShowSavePopUp}
              saveComparison={onSaveComparisonHandler}
              updateComparisonDetails={updateComparisonDetails}
              comparisonId={comparisonId}
            />
          )}

          {showContinueModal && comparisonId === 0 && (
            <div data-testid="start-new-modal">
              <Modal
                show={showContinueModal}
                handleClose={() => {
                  setShowContinueModal(false);
                  setTagSelection(null);
                }}
                header="comparison.unsaved.warning"
                config={false}
              >
                <ReportModal
                  continueLabel={
                    tagSelection
                      ? "document-viewer.page-selector.cancel"
                      : undefined
                  }
                  handleClose={() => {
                    if (tagSelection) {
                      setShowContinueModal(false);
                      setTagSelection(null);
                    } else handleStartNewComparison();
                  }}
                  handleSave={() => {
                    setShowContinueModal(false);
                    setShowSavePopUp(true);
                  }}
                />
              </Modal>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Comparison;
