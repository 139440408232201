import classNames from "classnames";
import Icon from "components/shared/icon/icon";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { COUNTRY_CODE, dropdownHeight } from "utils/constants";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { CompanyFilter } from "services/search/filters.model";
import { setCompanyFilter } from "services/dashboard/dashboard.service";
import { useDispatch } from "react-redux";
import Button from "components/shared/button/button";
import Parser from "html-react-parser";
import { getVendorCollection } from "utils/functions";
import { escapeRegExp } from "lodash";

type ObjectKeys = {
  name: string;
  id: string;
  externalId: string;
  ticker?: string;
  valueText?: string;
  children?: string;
  childName?: string;
  childId?: string;
  childExternalId?: string;
};

type Props = {
  className: string;
  label: string | object;
  showLabel?: boolean;
  placeholder: string | object;
  loading: boolean;
  options: CompanyFilter[];
  values: CompanyFilter[];
  handleChange?: any;
  handleSelect: any;
  handleUnselect?: any;
  handleGroupUnselect?: any;
  handleChangeValue?: any;
  objectKeys: ObjectKeys;
  contentStyle?: boolean;
  textValue?: string;
  displayFunction?: any;
  handleOnEnter?: any;
  type?: string;
  fromPeerBenchmark?: boolean;
  disabled?: boolean;
  editView?: boolean;
  showOnEmpty?: boolean;
  showAdditionalText?: boolean;
  additionalText?: string | object;
  fromInsight?: boolean;
  exceededLimit?: boolean;
  beforeSelectBaselineCompany?: any;
  changeNonSecCompany?: boolean;
  localSelectedBaseline?: CompanyFilter | null;
  setLocalSelectedBaseline?: any;
};

const DropDown = ({
  className,
  label,
  showLabel = true,
  placeholder,
  loading,
  options,
  values,
  handleChange,
  handleSelect,
  handleUnselect,
  handleGroupUnselect,
  handleChangeValue,
  objectKeys,
  contentStyle,
  displayFunction,
  textValue,
  handleOnEnter,
  type,
  fromPeerBenchmark,
  disabled,
  editView,
  showOnEmpty = true,
  showAdditionalText,
  additionalText,
  fromInsight,
  exceededLimit,
  beforeSelectBaselineCompany,
  changeNonSecCompany,
  localSelectedBaseline,
  setLocalSelectedBaseline,
}: Props) => {
  const [showOptions, toggleOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] =
    useState<CompanyFilter[]>(options);
  const [inputFilter, setInputFilter] = useState("");
  const [input, setInput] = useState(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(true);
  const [localFocus, setLocalFocus] = useState<boolean>(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const inputSizeRef = useRef<HTMLDivElement>(null);
  const BLOCK = "bench-mark";
  const dispatch = useDispatch();
  const TRIGGER_CLASS = "option-trigger";

  const handleLocalSelect = (option: any) => {
    if (
      beforeSelectBaselineCompany &&
      type === "baseline" &&
      setLocalSelectedBaseline &&
      localSelectedBaseline &&
      localSelectedBaseline.countryCode !== COUNTRY_CODE.US
    ) {
      beforeSelectBaselineCompany();
    } else {
      handleSelect(option, type);
      if (type === "baseline") {
        setInputFilter(option[objectKeys.name]);
        toggleOptions(false);
      }
    }
    if (setLocalSelectedBaseline) setLocalSelectedBaseline(option);
  };

  const handleLocalUnselect = (option: any) => {
    handleUnselect(option, type);
    if (type === "baseline") {
      toggleOptions(false);
      if (setLocalSelectedBaseline) setLocalSelectedBaseline(null);
    }
  };

  const unselectBaseline = () => {
    if (handleUnselect) {
      handleLocalUnselect(values[0]);
      setInputFilter("");
    } else {
      dispatch(setCompanyFilter([]));
    }
  };

  useEffect(() => {
    if (
      beforeSelectBaselineCompany &&
      type === "baseline" &&
      localSelectedBaseline &&
      changeNonSecCompany
    ) {
      handleSelect(localSelectedBaseline, type);
      setInputFilter(
        localSelectedBaseline &&
          localSelectedBaseline[objectKeys.name as keyof CompanyFilter]
          ? (localSelectedBaseline[
              objectKeys.name as keyof CompanyFilter
            ] as string)
          : ""
      );
      toggleOptions(false);
    }
  }, [changeNonSecCompany]);

  useEffect(() => {
    setFilteredOptions(options ? options : []);
  }, [options]);

  useEffect(() => {
    if (values && values.length && type === "baseline" && values[0]) {
      setInputFilter(values[0].companyName);
      if (setLocalSelectedBaseline) setLocalSelectedBaseline(values[0]);
    }

    if (values && values.length === 0) setInputFilter("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if ((fromInsight || fromPeerBenchmark) && type === "peers") {
      if (disabled) {
        setShowDropdown(false);
      } else {
        setShowDropdown(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useEffect(() => {
    if (showOptions && optionsRef.current) {
      optionsRef.current.focus();
      const dropdownElement = optionsRef.current?.parentElement;
      const scrollView = optionsRef.current?.parentElement?.parentElement;
      if (dropdownElement && scrollView) {
        const dropdownBottomOffset = dropdownElement.offsetTop + dropdownHeight;
        const offsetToScroll =
          dropdownBottomOffset > scrollView.clientHeight
            ? dropdownBottomOffset - scrollView.clientHeight
            : 0;
        setTimeout(() => {
          if (scrollView.scrollTo !== undefined) {
            scrollView.scrollTo({ top: offsetToScroll, behavior: "smooth" });
          }
        }, 200); // wait for the transition to finish
      }
    }
  }, [showOptions, values]);

  useEffect(() => {
    if (!showOptions) {
      if ((type === "baseline" && values.length === 0) || type === "peers")
        setInputFilter("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions]);

  /**
   * Hook to reset the focus to first line of the textarea when the company name is long
   */
  useLayoutEffect(() => {
    if (!showOptions && (type === "baseline") && inputFilter.length > 35 && textareaRef.current){
      textareaRef.current.focus();
      textareaRef.current.scrollTop = 0;
    }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions, inputFilter, textareaRef]);

  useEffect(() => {
    if (!loading && filteredOptions.length === 0 && inputFilter !== "") {
      setInput(true);
    } else {
      setInput(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFilter, loading, filteredOptions.length]);

  const handleClick = (event: any) => {
    if (
      showDropdown &&
      event.target &&
      (event.target.nodeName === "svg" ||
        (typeof event.target.className === "string" &&
          (event.target.className.includes(`${BLOCK}__options`) ||
            event.target.className.includes(`${BLOCK}__options--show`) ||
            event.target.className.includes(TRIGGER_CLASS))))
    )
      return null;

    if (inputFilter === "" && values && values.length && type === "baseline") {
      if (beforeSelectBaselineCompany) handleLocalUnselect(values[0]);
      else unselectBaseline();
    }

    if (
      inputFilter !== "" &&
      (localSelectedBaseline || (values && values.length)) &&
      type === "baseline" &&
      textValue &&
      ((beforeSelectBaselineCompany && !changeNonSecCompany) ||
        !beforeSelectBaselineCompany)
    ) {
      setInputFilter(textValue);
    }

    return toggleOptions(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClick]);

  const highlightOption = (option: string) => {
    if (inputFilter !== "" && option && !contentStyle) {
      let replace: any;
      // Replacing Open Braces and Close braces if found individually
      // Would break the regex condition
      // if (inputFilter?.includes("(") && inputFilter.includes(")")) {
      //   replace = new RegExp(`${inputFilter}`, "i");
      // } else if (inputFilter?.includes("(")) {
      //   replace = new RegExp(`${inputFilter})`, "i");
      // } else if (inputFilter?.includes(")")) {
      //   replace = new RegExp(`(${inputFilter}`, "i");
      // } else if (inputFilter?.includes("\\")) {
      //   replace = new RegExp(`${inputFilter}\\`, "i");
      // } else if (
      //   inputFilter?.includes("*") ||
      //   inputFilter?.includes("+") ||
      //   inputFilter?.includes("?")
      // ) {
      //   replace = new RegExp(`${inputFilter}`, "i");
      // } else replace = new RegExp(`(${inputFilter})`, "i");

      // Have kept previous code in comments. just in case we need to revert.
      // will remove it later
      replace = new RegExp(`(${escapeRegExp(inputFilter)})`, "i");

      return option
        .split(replace)
        .map((subtext) =>
          replace.test(subtext)
            ? `<b class="${TRIGGER_CLASS}">${subtext}</b>`
            : subtext
        )
        .join("");
    }
    return option;
  };

  const determineIsSelected = (option: any, optionKeys: any) => {
    if (option) {
      if (editView) {
        return (
          values.findIndex(
            (value: any) => value.cikNumber === option.cikNumber
          ) >= 0
        );
      } else {
        return contentStyle
          ? inputFilter?.includes(
              option[
                objectKeys.valueText ? objectKeys.valueText : objectKeys.name
              ]
            )
          : values.findIndex((value: any) =>
              value.cikNumber && option.cikNumber
                ? value.cikNumber === option.cikNumber
                : value.globalCompanyId === option.globalCompanyId
            ) >= 0;
      }
    }
  };

  const getVendorPill = (
    vendor: {
      priority: number;
      vendorId: number;
      vendorName: string;
      color: string;
    },
    i: number,
    companyIndex: number
  ) => {
    if (vendor.vendorId === 2) return;
    return (
      <div
        key={`vendor-${i}-${companyIndex}`}
        className={`${BLOCK}__vendorTag ${TRIGGER_CLASS}`}
        style={{
          borderColor: `${vendor.color}`,
          color: `${vendor.color}`,
        }}
      >
        {vendor.vendorName}
      </div>
    );
  };

  const getOption = (
    option: any,
    optionKeys: ObjectKeys,
    index: number,
    parentId?: number
  ) => {
    const isSelected = determineIsSelected(option, optionKeys);
    let vendor = getVendorCollection(option);
    return option ? (
      <button
        className={classNames(`${BLOCK}__option ${TRIGGER_CLASS}`, {
          [`${BLOCK}__option--selected`]: isSelected,
        })}
        key={`${optionKeys.id}-option-${index}-${option[optionKeys.id]}`}
        onClick={() => {
          isSelected
            ? handleLocalUnselect({ ...option, parentId })
            : handleLocalSelect({ ...option, parentId });
        }}
        data-test="select-option-button"
        data-testid="select-option-button"
      >
        <table className={`${TRIGGER_CLASS}`}>
          <tbody className={`${TRIGGER_CLASS}`}>
            <tr className={`${TRIGGER_CLASS}`}>
              <td className={`${BLOCK}__option-item--ticker ${TRIGGER_CLASS}`}>
                {optionKeys.ticker && option[optionKeys.ticker]
                  ? Parser(
                      highlightOption(option[optionKeys.ticker]).toString()
                    )
                  : null}
              </td>
              <td
                className={`${BLOCK}__option-item--name ${TRIGGER_CLASS}`}
                data-test="option-value"
              >
                {Parser(
                  highlightOption(
                    displayFunction
                      ? displayFunction(option)
                      : option[optionKeys.name] ?? ""
                  ).toString()
                )}
              </td>
              {fromPeerBenchmark && (
                <td
                  className={`${BLOCK}__option-item--vendor-wrapper ${TRIGGER_CLASS}`}
                >
                  {vendor.length &&
                    vendor.map(
                      (
                        vendor: {
                          priority: number;
                          vendorId: number;
                          vendorName: string;
                          color: string;
                        },
                        i: number
                      ) => getVendorPill(vendor, i, index)
                    )}
                </td>
              )}
            </tr>
          </tbody>
        </table>
        {isSelected && <Icon name="tick" height={20} />}
      </button>
    ) : null;
  };

  const handleFilterChange = (e: any) => {
    const value = e.target.value;
    setInputFilter(value);

    if (!showOnEmpty) {
      toggleOptions(value ? true : false);
    }

    if (handleChangeValue) handleChangeValue(value);

    if (value) {
      if (handleChange) {
        handleChange(e, type);
        return;
      }
      /* istanbul ignore if */
      if (objectKeys.children) {
        setFilteredOptions(
          options.reduce((filtered: any[], option: any) => {
            filtered.push({
              ...option,
              [objectKeys.children || ""]: option[
                objectKeys.children || ""
              ].filter((child: any) =>
                child[objectKeys.childName || ""]
                  .toLowerCase()
                  .includes(value.toLowerCase())
              ),
            });
            let newFilter = filtered.filter((child: any) => {
              if (child.formTypes) {
                return child.formTypes.length !== 0;
              } else if (child.sectors) {
                return child.sectors.length !== 0;
              }
              return true;
            });
            return newFilter;
          }, [])
        );
      } else {
        setFilteredOptions(
          options.filter((option: any) =>
            option[objectKeys.name].toLowerCase().includes(value.toLowerCase())
          )
        );
      }
    } else {
      setFilteredOptions(handleChange ? [] : options);
    }
  };

  return (
    <div
      ref={wrapperRef}
      id={`${BLOCK}-${objectKeys.id}`}
      className={`${BLOCK} ${className}`}
      data-test="drop-down"
      key={`${BLOCK}-${objectKeys.id}`}
    >
      <div className={`${BLOCK}__label`} data-test="drop-down">
        {showLabel && (label as React.ReactNode)}
      </div>

      <div className={`${BLOCK}__label`} data-test="drop-down">
        {!fromInsight &&
          showAdditionalText &&
          localFocus &&
          (additionalText as React.ReactNode)}
      </div>

      <div className={`${BLOCK}__outline`}>
        <textarea
          ref={textareaRef}
          value={inputFilter}
          className={`${BLOCK}__input`}
          data-test="input-text-area"
          onChange={handleFilterChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleOnEnter({});
            }
          }}
          onFocus={() => setLocalFocus(true)}
          onBlur={() => setLocalFocus(false)}
          disabled={
            (fromInsight || fromPeerBenchmark) && type === "peers" && disabled
          }
          data-testid="textarea-dropdown"
        />
        {type === "baseline" && inputFilter !== "" && (
          <Button
            className={`${BLOCK}__cross-icon`}
            dataTest="unselect-base-company-option"
            iconName="cross"
            iconHeight={24}
            iconWidth={24}
            onClick={unselectBaseline}
          />
        )}

        {(!values?.length || type === "peers") && inputFilter === "" && (
          <div className={`${BLOCK}__placeholder`}>
            {placeholder as React.ReactNode}
          </div>
        )}

        {filteredOptions.length > 0 &&
          (showOnEmpty || type !== "baseline") &&
          showOptions && (
            <button
              className={classNames(`${BLOCK}__chevron-button`, {
                [`${BLOCK}__chevron-button--not-empty`]:
                  values && values.length,
              })}
              data-test="chevron-button"
              data-testid="chevron-button"
              onClick={(e) => {
                e.preventDefault();
                toggleOptions(!showOptions);
              }}
            >
              <Icon
                className={classNames(`${BLOCK}__chevron-icon`, {
                  [`${BLOCK}__chevron-icon--open`]: showOptions,
                  [`${BLOCK}__chevron-icon--close`]: !showOptions,
                })}
                name="chevron-down"
                height={24}
              />
            </button>
          )}
      </div>
      {showDropdown && (
        <div
          ref={optionsRef}
          className={classNames(`${BLOCK}__options`, {
            [`${BLOCK}__options--show`]: showOptions,
            [`${BLOCK}__options--hide`]: !showOptions,
          })}
          data-test="drop-down-options"
        >
          {loading || filteredOptions.length === 0 ? (
            <button className={`${BLOCK}__option`}>
              {loading && (
                <Icon
                  name="loading"
                  width={20}
                  height={20}
                  data-test="loading"
                  className={classNames(`${BLOCK}__loading`, {
                    "loading-icon": loading,
                  })}
                />
              )}
              {!loading &&
                inputFilter !== "" &&
                filteredOptions.length === 0 && (
                  <div>
                    <FormattedMessage id="filter.no.matches" />
                  </div>
                )}
            </button>
          ) : (
            filteredOptions.map((option: any, index: number) =>
              objectKeys.children ? (
                <React.Fragment key={`fragment-${option[objectKeys.name]}`}>
                  <div
                    className={`${BLOCK}__option-category`}
                    key={option[objectKeys.id]}
                    data-test="option-category"
                  >
                    {option[objectKeys.name]}
                  </div>
                </React.Fragment>
              ) : (
                getOption(option, objectKeys, index)
              )
            )
          )}
        </div>
      )}
    </div>
  );
};

export default DropDown;
