import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import { useDispatch, useSelector } from "react-redux";
import ActionContainer from "components/shared/action-container/actions-container";
import {
  setShowExportPanel,
  showExportPanel,
} from "services/insights/insights.service";
import CheckboxContainer from "components/shared/checkbox-container/checkbox-container";
import { setInsightTileToExport } from "services/insights/insights.service";
import { RootStore } from "services/store.service";
import classNames from "classnames";
import SidePanel from "components/shared/side-panel/side-panel";
import {
  CHECKBOX_DATA,
  COMPARISON_EXPORT_PANEL_EXCEPTIONS,
  insightsPath,
  PDF_PPT_COLLAPSED_MESSAGE,
} from "utils/constants";
import { useTagContext } from "components/tags/tag.context";
import { TooltipPosition } from "components/shared/tooltip/tooltip";
import { showTooltip } from "services/commons.service";
import { useState, useEffect } from "react";

type props = {
  BLOCK: string;
  exportOptions: any;
  IncludeInSharing?: number[];
  setIncludeInSharing?: any;
  checkboxData?: string[];
  selectedExport?: any;
  setExport?: any;
  exportFormatData?: string[];
  exportFormat?: any;
  setExportFormat?: any;
  selectedDetailedViewBenchmarkIds?: number[];
  ExportButtonHandler: any;
  showExportRestrictionMsg?: boolean;
  setShowExport: Function;
  isESRS?: boolean;
};

const ExportPanel = ({
  BLOCK,
  exportOptions,
  IncludeInSharing,
  setIncludeInSharing,
  checkboxData,
  selectedExport,
  setExport,
  exportFormatData,
  exportFormat,
  setExportFormat,
  selectedDetailedViewBenchmarkIds,
  ExportButtonHandler,
  showExportRestrictionMsg,
  setShowExport,
  isESRS = false,
}: props) => {
  const { tagCountDetail } = useTagContext();
  const dispatch = useDispatch();
  const insightsState = useSelector((state: RootStore) => state.insights);
  const tooltip = useSelector((store: RootStore) => store.commons.toolTip);
  const [hidePDFPPTExport, setHidePDFPPTExport] = useState(false);

  const cancelHandler = () => {
    dispatch(setInsightTileToExport(null));
    setShowExport(false);
    dispatch(setShowExportPanel(false));
    if (setIncludeInSharing) {
      setIncludeInSharing([]);
    }
    if (BLOCK === COMPARISON_EXPORT_PANEL_EXCEPTIONS.EXPORT_PANEL_BLOCK) {
      setExport([exportOptions[0]]);
    }
    if (showExportRestrictionMsg) setExport("pdf");
  };

  useEffect(() => {
    if (window.location.pathname.includes(insightsPath) && isESRS) {
      let insightsSectionsPreference =
        (insightsState?.savedUserPreference?.userPreferenceValue?.insightsSectionsPreference?.find(
          (x) => x.benchmarkTypeId == insightsState.currentTabId
        ) || []) as any;

      let isHide =
        insightsSectionsPreference != null
          ? insightsSectionsPreference?.expandedSectionIds?.length == 0
          : false;
      if (isHide) setExport("xls");
      setHidePDFPPTExport(isHide);
    }
  }, [insightsState, insightsState.currentTabId]);

  const handleMouseLeave = (e: any) => {
    if (tooltip.isOverTooltip) return;
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };

  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    position: TooltipPosition = TooltipPosition.progressBarTop
  ) => {
    const element = e.target.closest("div");
    dispatch(
      showTooltip({
        children: children,
        position: position,
        customPosition: true,
        elementDimensions: element?.getBoundingClientRect(),
        className: className,
        width: null,
        executeMouseLeaveEvent: true,
      })
    );
  };

  return (
    <SidePanel
      showPanel={true}
      className={`${BLOCK}`}
      dataTest="export-all"
      headerText="dashboard.export.panel"
      handleClose={() => {
        dispatch(showExportPanel(false));
        dispatch(setInsightTileToExport(false));
        setShowExport(false);
        if(setIncludeInSharing) setIncludeInSharing([]);
        if (BLOCK === COMPARISON_EXPORT_PANEL_EXCEPTIONS.EXPORT_PANEL_BLOCK) {
          setExport([exportOptions[0]]);
        }
        if (showExportRestrictionMsg) setExport("pdf");
      }}
    >
      <>
        <div className={`${BLOCK}__exportbody`}>
          <div className={`${BLOCK}__dashboard-name`}>
            {BLOCK !==
              COMPARISON_EXPORT_PANEL_EXCEPTIONS.EXPORT_PANEL_BLOCK && (
              <>
                <div className={`${BLOCK}__dashboard-name--export-header`}>
                  <FormattedMessage id="dashboard.export.panel.header" />
                </div>
                <span className={`${BLOCK}__dashboard-name--export-subheader`}>
                  <FormattedMessage id="dashboard.export.panel.chart" />
                </span>
                <div className={`${BLOCK}__dashboard-name--export--list`}>
                  {exportOptions.export_ChartView.map(
                    (exportOption: any, i: number) => (
                      <div
                        className={classNames(
                          `${BLOCK}__dashboard-name--export-option-disabled`,
                          {
                            [`${BLOCK}__dashboard-name--export-option-selected`]:
                              selectedExport === exportOption.type,
                            [`${BLOCK}__export-option--unclickable`]:
                              exportOption.disabled && showExportRestrictionMsg,
                            [`${BLOCK}__export-option--unclickable-esrs`]:
                              isESRS && hidePDFPPTExport,
                          }
                        )}
                        key={`${BLOCK}-${i}`}
                        onClick={() => {
                          if (!exportOption.disabled) {
                            setExport("");
                            setExport(exportOption.type);
                          }
                        }}
                        data-testid={exportOption.type}
                        onMouseEnter={(e) => {
                          hidePDFPPTExport &&
                            handleMouseEnter(e, PDF_PPT_COLLAPSED_MESSAGE);
                        }}
                        onMouseLeave={handleMouseLeave}
                      >
                        <Icon
                          name={exportOption.icon}
                          height={30}
                          width={30}
                          className={classNames(
                            `${BLOCK}__dashboard-name--export-icon`,
                            {
                              [`${BLOCK}__export-option--disabled`]:
                                exportOption.disabled &&
                                showExportRestrictionMsg,
                              [`${BLOCK}__export-option--disabled-esrs`]:
                                hidePDFPPTExport,
                            }
                          )}
                        />
                      </div>
                    )
                  )}
                </div>
                {exportOptions.export_TableView && (
                  <>
                    <span
                      className={`${BLOCK}__dashboard-name--export-subheader`}
                    >
                      <FormattedMessage id="dashboard.export.panel.table" />
                    </span>
                    <div className={`${BLOCK}__dashboard-name--export--list`}>
                      {exportOptions.export_TableView?.map(
                        (exportOption: any, i: number) => (
                          <div
                            className={classNames(
                              `${BLOCK}__dashboard-name--export-option-disabled`,
                              {
                                [`${BLOCK}__dashboard-name--export-option-selected`]:
                                  selectedExport === exportOption.type,
                                [`${BLOCK}__export-option--unclickable`]:
                                  exportOption.disabled &&
                                  showExportRestrictionMsg,
                              }
                            )}
                            key={`${BLOCK}-${i}`}
                            onClick={() => {
                              if (!exportOption.disabled) {
                                setExport("");
                                setExport(exportOption.type);
                              }
                            }}
                            data-testid={exportOption.type}
                          >
                            <Icon
                              name={exportOption.icon}
                              height={30}
                              width={30}
                              className={classNames(
                                `${BLOCK}__dashboard-name--export-icon`,
                                {
                                  [`${BLOCK}__export-option--disabled`]:
                                    exportOption.disabled &&
                                    showExportRestrictionMsg,
                                }
                              )}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            {showExportRestrictionMsg && (
              <div className={`${BLOCK}__restriction-msg`}>
                <FormattedMessage id="insight.syp.export.restriction.message" />
              </div>
            )}
            {BLOCK ===
              COMPARISON_EXPORT_PANEL_EXCEPTIONS.EXPORT_PANEL_BLOCK && (
              <div>
                <CheckboxContainer
                  alignContainer={`${BLOCK}-NoMargin`}
                  dispatchFunction={setExport}
                  reducerState={selectedExport!}
                  checkboxData={exportOptions}
                  type={"export"}
                  radio={true}
                />
              </div>
            )}

            {checkboxData && checkboxData.length ? (
              <div>
                <CheckboxContainer
                  alignContainer={`${BLOCK}`}
                  header={
                    BLOCK ===
                    COMPARISON_EXPORT_PANEL_EXCEPTIONS.EXPORT_PANEL_BLOCK
                      ? "select.comparison.export.notes.sharing"
                      : "select.export.sharing"
                  }
                  dispatchFunction={setIncludeInSharing}
                  checkboxDisabled={
                    (exportFormat?.includes(CHECKBOX_DATA.EXPORT_COMPACTVIEW) &&
                      !selectedExport.includes(CHECKBOX_DATA.EXPORT_PDF)) ??
                    false
                  }
                  reducerState={IncludeInSharing!}
                  checkboxData={checkboxData}
                  type={"export"}
                  radio={false}
                />
              </div>
            ) : null}
            {BLOCK === COMPARISON_EXPORT_PANEL_EXCEPTIONS.EXPORT_PANEL_BLOCK &&
              exportFormatData &&
              tagCountDetail && (
                <div>
                  <CheckboxContainer
                    alignContainer={`${BLOCK}-NoMargin`}
                    header={"select.comparison.export.tags.sharing"}
                    dispatchFunction={setExportFormat}
                    reducerState={exportFormat}
                    checkboxData={exportFormatData}
                    checkboxDisabled={
                      selectedExport.includes(CHECKBOX_DATA.EXPORT_PDF)
                        ? true
                        : false
                    }
                    checkboxConditionForDisabled={
                      tagCountDetail.length > 0 ? false : true
                    }
                    type={"export"}
                    radio={true}
                  />
                </div>
              )}
            {selectedExport === "ppt" && exportFormatData && (
              <div style={{
                display: 
                !isESRS 
                  ? "block" 
                  : "none"
              }}>
                <CheckboxContainer
                  header={"export.format.layout"}
                  dispatchFunction={setExportFormat}
                  reducerState={exportFormat}
                  checkboxData={exportFormatData!}
                  type={"export"}
                  radio={true}
                  checkboxDisabled={
                    insightsState.insightTileToExport ? true : false
                  }
                  checkboxConditionForDisabled={
                    selectedDetailedViewBenchmarkIds &&
                    selectedDetailedViewBenchmarkIds.length > 0
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div data-testid="side-custom-benchmark-panel-buttons">
          <ActionContainer
            disabled={{
              // Both the buttons are dependent on the same conditions
              actionButtonOneState: false,
              actionButtonTwoState: false,
            }}
            actionButtonOne={{
              handleActionButton: cancelHandler,
              actionButtonText: "shortlist.edit.button.cancel",
              actionButtonType: "button",
            }}
            actionButtonTwo={{
              handleActionButton: ExportButtonHandler,
              actionButtonText: "compare.export.button",
              actionButtonType: "primary",
            }}
          />
        </div>
      </>
    </SidePanel>
  );
};

export default ExportPanel;
