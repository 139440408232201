import classNames from "classnames";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchEsgReportsForInsights,
  fetchSecReportsForInsights,
} from "services/insights/insights.api";
import { InsightDashboardCompany } from "services/insights/insights.model";
import { esgLinkTrack, secLinkTrack } from "services/insights/insights.service";
import { RootStore } from "services/store.service";
import { INSIGHT_DASHBOARD_BASE_LINKS, MENU_ITEMS } from "utils/constants";

type LinkData = {
  link: {
    label: string;
    description: string;
    hash: string;
    formTypeName: string;
  };
  reportsResult: any[];
  track: Function;
};

const Links = () => {
  const BLOCK = "linksTile";
  const [linksData, setLinksData] = useState<LinkData[]>([]);
  const dispatch = useDispatch();
  const searchPath = MENU_ITEMS.search.path;
  const insightsState = useSelector((state: RootStore) => state.insights);
  const [executedEsglinkData, setExecutedEsgLinksData] =
    useState<boolean>(false);
  const [executedSeclinkData, setExecutedSecLinksData] =
    useState<boolean>(false);
  const [esglinkData, setEsgLinksData] = useState<any>(null);
  const [seclinkData, setSecLinksData] = useState<any>(null);
  const baseCompany: any | undefined =
    insightsState.currentSavedInsightDashboard?.insightsCompanies.find(
      (insightDashboardCompany: InsightDashboardCompany) =>
        insightDashboardCompany.isBaseCompany
    )?.globalCompany;

  const getLink = (linkData: LinkData, index: number) => {
    return linkData.reportsResult.length ? (
      <Link
        className={`linksTile__link-container`}
        key={`link__${index}`}
        to={`${searchPath}?companyId=${baseCompany.cikNumber}&formTypeName=${linkData.link.formTypeName}${linkData.link.hash}`}
        target="_blank"
        data-testid="link"
        onClick={() => {
          dispatch(linkData.track(true));
        }}
      >
        <div className={`linksTile__link-icon`}>
          <Icon width={24} name="link" />
        </div>
        <div className={`linksTile__link-data`}>
          <div className={`linksTile__link-text`}>
            <span className={`linksTile__link-label`}>
              <FormattedMessage id={linkData.link.label} />
            </span>
            <span className={`linksTile__link-description`}>
              <FormattedMessage id={linkData.link.description} />
            </span>
          </div>
          <div className={`linksTile__link-external`}>
            <Icon width={24} height={24} name="external-tab" />
          </div>
        </div>
      </Link>
    ) : null;
  };
  const getSearchPayloadForLinks = (link: any) => {
    const payload = {
      shortlistId: 0,
      contentText: "",
      formTypeNames:
        link.formTypeName === INSIGHT_DASHBOARD_BASE_LINKS[0].formTypeName
          ? [link.formTypeName]
          : link.formTypeName.split(","),
      years: [],
      companyIds:
        link.formTypeName === INSIGHT_DASHBOARD_BASE_LINKS[0].formTypeName
          ? baseCompany.cikNumber ? [baseCompany.cikNumber] : []
          : baseCompany.externalCompanyId ? [baseCompany.externalCompanyId] : [],
      industryIds: [],
      auditorIds: [],
      governingBoardIds: [],
      pageInfo: {
        pageNumber: 1,
        pageSize: 30,
        sortBy: "PeriodName",
        sortOrder: "desc",
      },
    };
    return payload;
  };

  const fetchLinksData = async (fetchReportsPromises: any) => {
    await fetchReportsPromises[0]
      .then((result: any) => {
        if (result.data.reports && result.data.reports.length) {
          setEsgLinksData({
            link: INSIGHT_DASHBOARD_BASE_LINKS[0],
            reportsResult: result.data.reports,
            track: esgLinkTrack,
          });
        }
        setExecutedEsgLinksData(true);
      })
      .catch((error: any) => {
        setExecutedEsgLinksData(true);
      });

    await fetchReportsPromises[1]
      .then((result: any) => {
        if (result.data.secFilings && result.data.secFilings.length) {
          setSecLinksData({
            link: INSIGHT_DASHBOARD_BASE_LINKS[1],
            reportsResult: result.data.secFilings,
            track: secLinkTrack,
          });
        }
        setExecutedSecLinksData(true);
      })
      .catch((error: any) => {
        setExecutedSecLinksData(true);
      });
  };

  useEffect(() => {
    const fetchReportsPromises = INSIGHT_DASHBOARD_BASE_LINKS.map(
      async (link: any) => {
        let payload = getSearchPayloadForLinks(link);
        let fetchSearchResults =
          link.formTypeName === INSIGHT_DASHBOARD_BASE_LINKS[0].formTypeName
            ? fetchEsgReportsForInsights
            : fetchSecReportsForInsights;
        return fetchSearchResults(payload);
      }
    );

    fetchLinksData(fetchReportsPromises);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (executedEsglinkData && executedSeclinkData) {
      let links: any = [];
      if (esglinkData) links.push(esglinkData);
      if (seclinkData) links.push(seclinkData);
      setLinksData(links);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executedEsglinkData, executedSeclinkData]);

  return (
    <div
      id={BLOCK}
      className={classNames(`${BLOCK}__body`, {
        [`${BLOCK}__body--no-data`]: linksData?.length === 0,
      })}
      data-testid={`links-container`}
    >
      {linksData && linksData.length ? (
        linksData.map((link: LinkData, index: number) => getLink(link, index))
      ) : (
        <span className={`${BLOCK}__no-data`}>
          <FormattedMessage id="insights.cdp.no-data" />
        </span>
      )}
    </div>
  );
};

export default Links;
