import { useState, useEffect } from "react";
import Notification from "../shared/notification/notification";
import { useSelector, useDispatch } from "react-redux";
import { loadNotification } from "services/notification/notification.service";
import socket, { rooms } from "utils/socket";
import { RootStore } from "services/store.service";
import { NotificationData } from "services/notification/notification.model";
import { getSharedSearchCount } from "services/searchlist/searchlist.service";
import { getSavedShortlistCount } from "services/saved-shortlist/saved-shortlist.service";
import {
  NOTIFICATION_TYPES,
  NOTIFICATION_STATUS,
  logout,
  homepage,
  FILEUPLOAD_STATUS_TYPES,
  comparisonPath,
  DEFAULT_SAVED_COMPARISONS_PAYLOAD,
  MENU_ITEMS,
  DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD,
  LOCALSTORAGE_VARIABLE,
} from "utils/constants";
import { useLocation } from "react-router";
import { addToastMessage } from "services/commons.service";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import {
  getComparisonCount,
  loadSavedComparisonsList,
} from "services/saved-comparisons/saved-comparisons.service";
import { getSavedInsightCount } from "services/insights/insights.service";
import { updateNotification } from "services/notification/notification.api";
import { loadSavedPeerbenchmarks } from "services/peer-benchmark/peer-benchmark.service";
import { setActiveMaintenanceMessages } from "services/manage-files/manage-files.service";
import { getItem, setItem } from "utils/localStorageFunctions";
import { isEmpty } from "lodash";

type props = {
  setShowNotification: (value: boolean) => void;
};

const Notifications = ({ setShowNotification }: props) => {
  const BLOCK = "Notifications";

  const location = useLocation();
  const [notification, setNotification] = useState(false);
  const [totalNotification, setTotalNotification] = useState<
    NotificationData[]
  >([]);
  const dispatch = useDispatch();
  const notificationState = useSelector(
    (state: RootStore) => state.notification
  );
  const commonState = useSelector((state: RootStore) => state.commons);
  const currentuser = commonState.currentUser.userId;

  useEffect(() => {
    if (
      location.pathname !== homepage &&
      location.pathname !== logout &&
      !isEmpty(commonState.currentUser)
    ) {
      updateNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonState.currentUser]);

  useEffect(() => {
    if (currentuser) {
      socket.on("fileUpload.status.update", updateFileUpload);
      socket.join(rooms.fileUpload(currentuser));

      return () => {
        socket.leave(rooms.fileUpload(currentuser));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentuser]);

  const updateFileUpload = (payload: any) => {
    const fileUploadState = payload.data.status;
    switch (fileUploadState) {
      case FILEUPLOAD_STATUS_TYPES.VIRUS_INFECTED:
        //Mapping excel in the imported zip file is virus infected
        dispatch(
          addToastMessage({
            description: <FormattedMessage id="manage-files.upload.virus" />,
          })
        );
        break;

      case FILEUPLOAD_STATUS_TYPES.PROCESSING_ERROR:
        //There was no mapping excel in the zip file import
        dispatch(
          addToastMessage({
            description: (
              <FormattedMessage id="manage-files.upload.processingerror" />
            ),
          })
        );
        break;

      case FILEUPLOAD_STATUS_TYPES.METADATA_MISSING:
        //Failed due to a technical error
        dispatch(
          addToastMessage({
            description: (
              <FormattedMessage id="manage-files.upload.missing-metadata" />
            ),
          })
        );
        break;

      case FILEUPLOAD_STATUS_TYPES.UNSUPPORTED_FORMAT:
        // Mapping excel file is corrupted or not in the expected format
        dispatch(
          addToastMessage({
            description: (
              <FormattedMessage id="manage-files.upload.unsupported-format" />
            ),
          })
        );
        break;
    }
  };

  useEffect(() => {
    if (currentuser) {
      socket.on("user.notification", updateNotifications);
      socket.join(rooms.alert(currentuser));

      return () => {
        socket.leave(rooms.alert(currentuser));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentuser]);

  useEffect(() => {
    let totalNotif = notificationState.NotificationFetch.filter(
      (notif) =>
        [
          NOTIFICATION_TYPES.SHARE_SEARCH,
          NOTIFICATION_TYPES.SHARE_SHORTLIST,
          NOTIFICATION_TYPES.TO_BE_PURGED_SHARE,
          NOTIFICATION_TYPES.SHARE_COMPARE,
          NOTIFICATION_TYPES.SHARED_COMPARISON_FOR_COLLABORATION,
          NOTIFICATION_TYPES.SHARED_INSIGHT,
          NOTIFICATION_TYPES.AUTO_DELETE_COMPARE,
          NOTIFICATION_TYPES.AUTO_DELETE_INSIGHT,
          NOTIFICATION_TYPES.CONTENT_BENCHMARK_ROL_CHANGED,
          NOTIFICATION_TYPES.CONTENT_BENCHMARK_USER_REMOVED,
          NOTIFICATION_TYPES.CONTENT_BENCHMARK_DELETED,
          NOTIFICATION_TYPES.SHARED_PEER_BENCHMARK,
        ].includes(notif.notificationType) &&
        notif.notificationStatus === NOTIFICATION_STATUS.SHOW
    );

    // Skip content benchmark notifications if its open
    const CBNotifs = notificationState.NotificationFetch.filter(
      (n) =>
        [
          NOTIFICATION_TYPES.CONTENT_BENCHMARK_ROL_CHANGED,
          NOTIFICATION_TYPES.CONTENT_BENCHMARK_USER_REMOVED,
          NOTIFICATION_TYPES.CONTENT_BENCHMARK_DELETED,
        ].includes(n.notificationType) &&
        location.pathname === `${comparisonPath}/${n.referenceId}`
    );

    // Reload content benchmark list to avoid inconsistencies
    if (
      notificationState.NotificationFetch.filter((n) =>
        [
          NOTIFICATION_TYPES.CONTENT_BENCHMARK_ROL_CHANGED,
          NOTIFICATION_TYPES.CONTENT_BENCHMARK_USER_REMOVED,
          NOTIFICATION_TYPES.CONTENT_BENCHMARK_DELETED,
        ].includes(n.notificationType)
      ).length &&
      location.pathname === MENU_ITEMS.comparisons.path
    )
      dispatch(loadSavedComparisonsList(DEFAULT_SAVED_COMPARISONS_PAYLOAD));

    totalNotif = totalNotif.filter(
      (n) =>
        !CBNotifs.map(({ notificationId }) => notificationId).includes(
          n.notificationId
        )
    );

    // Close all of the content benchmark notifications to avoid
    // showing them up later in other pages of the app
    CBNotifs.map((n) =>
      updateNotification({
        notificationId: n.notificationId,
        notificationStatus: NOTIFICATION_STATUS.CLOSED,
      })
    );

    if (totalNotif.length > 0) {
      setTotalNotification(totalNotif);
      setNotification(true);

      dispatch(getSharedSearchCount());
      dispatch(getSavedShortlistCount());
      dispatch(getComparisonCount());
      dispatch(getSavedInsightCount());
      dispatch(loadSavedPeerbenchmarks(DEFAULT_SAVED_PEER_BENCHMARK_PAYLOAD));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationState.NotificationFetch]);

  const updateNotifications = () => {
    dispatch(loadNotification());
  };

  return (
    <>
      <div data-test="notification-component">
        {totalNotification &&
          totalNotification.length > 0 &&
          totalNotification.map((notif) => (
            <Notification
              show={notification}
              header={notif.notificationTitle}
              config={false}
              notif={notif}
              key={notif.notificationId}
              BLOCK2={BLOCK}
            />
          ))}
      </div>
    </>
  );
};

export default Notifications;
