import React, { useState, useEffect } from "react";
import Modal from "../shared/modal/modal";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import DropDown from "components/compare/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import {
  executeAddToComparisonTrack,
  loadSavedComparisonsList,
  triggerPageAddToComparisonTrack,
  triggerPageNewComparisonTrack,
} from "services/saved-comparisons/saved-comparisons.service";
import { DEFAULT_SAVED_COMPARISONS_PAYLOAD } from "utils/constants";
import history from "utils/history";
import Button from "components/shared/button/button";

type props = {
  setShowCompareModal: (value: boolean) => void;
  submitCompare: Function;
};

const Compare = ({ setShowCompareModal, submitCompare }: props) => {
  const BLOCK = "Compare";

  const comparisonState = useSelector(
    (state: RootStore) => state.comparisonState
  );
  const dispatch = useDispatch();

  const [selectedComparison, setSelected] = useState([]);

  const [totalCount, settotalCount] = useState(
    comparisonState.selectedList.length
  );
  useEffect(() => {
    dispatch(loadSavedComparisonsList(DEFAULT_SAVED_COMPARISONS_PAYLOAD));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleSelected = (selectedComp: any) => {
    setSelected(selectedComp);
    settotalCount(
      selectedComp?.comparisonItemCount + comparisonState.selectedList.length
    );
  };

  const handleUnSelect = (selectedComp: any) => {
    if (selectedComparison) {
      setSelected([]);
      settotalCount(comparisonState.selectedList.length);
    }
  };

  const compareHandler = (selectedComparison: any) => {
    setShowCompareModal(false);
    if (selectedComparison.comparisonId !== 0) {
      dispatch(executeAddToComparisonTrack(true));
      dispatch(triggerPageAddToComparisonTrack(`${history.location.pathname}`));
    } else {
      dispatch(triggerPageNewComparisonTrack(`${history.location.pathname}`));
    }
    submitCompare(selectedComparison);
  };

  return (
    <Modal
      show={true}
      header={
        totalCount > 1 ? `compare.title.plural` : "compare.title.singular"
      }
      headerParams={{ comparisonCount: comparisonState.selectedList.length }}
      handleClose={() => {
        setShowCompareModal(false);
      }}
      config={false}
    >
      <div className={BLOCK} data-testid={BLOCK}>
        <span className={`${BLOCK}__content`}>
          <DropDown
            className={`${BLOCK}__dropdown`}
            label={
              <div className={`${BLOCK}__dropdown-label`}>
                <FormattedMessage id="compare.message" />
              </div>
            }
            createNewLabel={<FormattedMessage id="compare.create.new" />}
            placeholder={<FormattedMessage id="compare.notselected" />}
            values={selectedComparison}
            objectKeys={{
              name: "comparisonName",
              id: "comparisonId",
              items: "comparisonItemCount",
            }}
            options={comparisonState.savedComparisonsFetch?.comparisons}
            loading={comparisonState.loading}
            handleSelect={handleSelected}
            handleUnselect={handleUnSelect}
            handleChange={() => {}}
          />
        </span>
        <div className={`${BLOCK}__options`}>
          <Button
            className={`button-secondary`}
            dataTest="cancel-button"
            onClick={() => setShowCompareModal(false)}
            formattedText="shortlist.edit.button.cancel"
          />

          <Button
            className={`button-primary`}
            dataTest="compare-apply-button"
            disabled={selectedComparison.length === 0}
            formattedText="compare.button"
            onClick={() => compareHandler(selectedComparison)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Compare;
