import { BaseModel } from "utils/redux.utils";
import { CompanyFilter } from "services/search/filters.model";
import {
  AssociatedMetricTooltips,
  SuggestPeerCompanyFilter,
  Visualization,
} from "services/dashboard/dashboard.model";
import {
  FILTER_OPTIONS,
  INSIGHT_DASHBOARD_FILTER_LABELS,
} from "utils/constants";
import { User } from "services/commons.model";

export interface InsightsState {
  loading: boolean;
  loadingFilter: FILTER_OPTIONS[];
  showCreateNewTab: boolean;
  showTalkingPoints: boolean;
  showEditTab: boolean;
  showExportPanel: boolean;
  featureSetting: FeatureSetting[];
  savedInsights: InsightsDashboard[];
  deleteSavedInsightPopUP: boolean;
  shareInsightsSuccess: boolean;
  refreshSavedInsightsList: boolean;
  showCurrentInsightDashboard: boolean;
  currentSavedInsightDashboard: InsightsDashboard;
  companyFilterOptions: CompanyFilter[];
  peerCompanyFilterOptions: CompanyFilter[];
  companyFilter: CompanyFilter[];
  peerCompanyFilter: CompanyFilter[];
  suggestedPeerCompanyFilter: SuggestPeerCompanyFilter[];
  selectedItems: number[];
  savedInsightDashboardId: number;
  categoryData: InsightCategory[];
  selectedCategoryIds: InsightCategory[];
  diplicateInsightError: boolean;
  duplicatePeerGroupName: boolean;
  insightSavedSuccess: boolean;
  insightPeerGroupSuccess: boolean;
  additionalInfo: AdditionalInfo[];
  showEditor: boolean;
  showInformation: boolean;
  talkingPointCollection: talkingPointCollection[];
  insightTileToExport: InsightTileToExport;
  tileDetails: Visualization[];
  savedPeerGroupList: GlobalPeerGroup[];
  deleteSavedPeerGroupPopUP: boolean;
  refreshSavedPeerGroupsList: boolean;
  selectedPeerGroupItems: number[];
  benchmarkFilters: BenchmarkFilter[];
  insightsCount: number;
  globalCompanyMappingsFilter: Partial<GlobalCompanyGroup>[];
  savedUserPreference: Partial<InsightUserPreference>;
  userPreferenceError: boolean;
  currentTabId: number;
}
export class InsightsModel extends BaseModel {
  constructor() {
    super({
      loading: false,
      showCreateNewTab: false,
      showTalkingPoints: false,
      showEditTab: false,
      showExportPanel: false,
      savedInsights: null,
      deleteSavedInsightPopUP: false,
      refreshSavedInsightsList: false,
      showCurrentInsightDashboard: false,
      shareInsightsSuccess: false,
      currentSavedInsightDashboard: null,
      companyFilterOptions: [],
      peerCompanyFilterOptions: [],
      companyFilter: [],
      peerCompanyFilter: [],
      suggestedPeerCompanyFilter: [],
      loadingFilter: [],
      selectedItems: [],
      savedInsightDashboardId: -1,
      categoryData: [],
      selectedCategoryIds: [],
      diplicateInsightError: false,
      duplicatePeerGroupName: false,
      insightSavedSuccess: false,
      insightPeerGroupSuccess: false,
      additionalInfo: [],
      showEditor: false,
      showInformation: false,
      talkingPointCollection: [],
      featureSetting: [],
      insightTileToExport: null,
      tileDetails: [],
      savedPeerGroupList: null,
      deleteSavedPeerGroupPopUP: false,
      refreshSavedPeerGroupsList: false,
      selectedPeerGroupItems: [],
      benchmarkFilters: [],
      insightsCount: 0,
      globalCompanyMappingsFilter: [],
      savedUserPreference: null,
      userPreferenceError: false,
      currentTabId: 0,
    });
  }
  setShowCreateNew = (showCreateNewTab: boolean): InsightsState =>
    this.merge({ showCreateNewTab });
  setShowTalkingPoints = (showTalkingPoints: boolean): InsightsState =>
    this.merge({ showTalkingPoints });
  setShowEdit = (showEditTab: boolean): InsightsState =>
    this.merge({ showEditTab });
  setShowExportPanel = (showExportPanel: boolean): InsightsState =>
    this.merge({ showExportPanel });
  setInsightsShareSuccess = (shareInsightsSuccess: boolean): InsightsState =>
    this.merge({ shareInsightsSuccess });

  setLoading = (loading: boolean): InsightsState => this.merge({ loading });
  setDuplicateInsightError = (diplicateInsightError: boolean): InsightsState =>
    this.merge({ diplicateInsightError });

  setInsightsCount = (insightsCount: number): InsightsState =>
    this.merge({ insightsCount });

  setDuplicatePeerGroupNameError = (
    duplicatePeerGroupName: boolean
  ): InsightsState => this.merge({ duplicatePeerGroupName });

  setInsightSavedSuccess = (insightSavedSuccess: boolean): InsightsState =>
    this.merge({ insightSavedSuccess });

  setInsightPeerGroupSuccess = (
    insightPeerGroupSuccess: boolean
  ): InsightsState => this.merge({ insightPeerGroupSuccess });

  setRefreshSavedInsightsList = (
    refreshSavedInsightsList: boolean
  ): InsightsState => this.merge({ refreshSavedInsightsList });

  resetSavedInsights = (savedInsights: any): InsightsState =>
    this.merge({ savedInsights });

  setSavedInsights = (savedInsights: any): InsightsState =>
    this.merge({ savedInsights });

  setDeleteSavedInsightPopUP = (
    deleteSavedInsightPopUP: boolean
  ): InsightsState => this.merge({ deleteSavedInsightPopUP });

  setCurrentSavedInsightDashboard = (
    currentSavedInsightDashboard: any
  ): InsightsState => this.merge({ currentSavedInsightDashboard });

  setCurrentSelectedDetailedViewBenchmarkIds = (
    selectedDetailedViewBenchmarkIds: number[]
  ): InsightsState =>
    this.merge({
      currentSavedInsightDashboard: {
        ...this.state.currentSavedInsightDashboard,
        insightsCategories:
          this.state.currentSavedInsightDashboard.insightsCategories.map(
            (categories: any) => ({
              ...categories,
              selectedDetailedViewBenchmarkIds:
                selectedDetailedViewBenchmarkIds,
            })
          ),
      },
    });

  setFeatureSettings = (featureSetting: any): InsightsState => {
    return this.merge({ featureSetting });
  };

  loadTalkingPoints = (
    talkingPoints: talkingPointCollection[]
  ): InsightsState => this.merge({ talkingPoints });

  setLoadingFilter = (loadingFilter: FILTER_OPTIONS): InsightsState =>
    this.merge({ loadingFilter: [...this.state.loadingFilter, loadingFilter] });

  loadCompanyFilterOptions = (
    companyFilterOptions: CompanyFilter[]
  ): InsightsState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      companyFilterOptions,
    });

  loadPeerCompanyFilterOptions = (
    peerCompanyFilterOptions: CompanyFilter[]
  ): InsightsState => {
    return this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      peerCompanyFilterOptions,
    });
  };

  loadSuggestedPeerCompanyFilterOptions = (
    suggestedPeerCompanyFilter: SuggestPeerCompanyFilter[]
  ): InsightsState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      suggestedPeerCompanyFilter,
    });

  setCompanyFilter = (companyFilter: CompanyFilter[]): InsightsState =>
    this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      companyFilter,
      peerCompanyFilter:
        companyFilter.length !== 0
          ? this.state.peerCompanyFilter.filter(
              (company: CompanyFilter) =>
                company.cikNumber !== companyFilter[0].cikNumber
            )
          : this.state.peerCompanyFilter,
      selectedItems:
        companyFilter.length !== 0
          ? this.state.selectedItems.filter(
              (item: number) => item !== companyFilter[0].cikNumber
            )
          : this.state.selectedItems,
    });

  setPeerCompanyFilter = (
    peerCompanyFilter: CompanyFilter[]
  ): InsightsState => {
    return this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.COMPANY
      ),
      peerCompanyFilter: peerCompanyFilter,
    });
  };

  setSelectedItems = (selectedItems: number[]): InsightsState =>
    this.merge({ selectedItems });

  setAdditionalInfo = (additionalInfo: AdditionalInfo[]): InsightsState =>
    this.merge({ additionalInfo });

  setShowCurrentInsightDashboard = (
    showCurrentInsightDashboard: boolean
  ): InsightsState => this.merge({ showCurrentInsightDashboard });

  setSavedInsightDashboardId = (
    savedInsightDashboardId: number
  ): InsightsState => this.merge({ savedInsightDashboardId });

  setTalkingPoints = (
    talkingPointCollection: talkingPointCollection
  ): InsightsState => this.merge({ talkingPointCollection });

  setCategoryData = (categoryData: InsightCategory[]): InsightsState =>
    this.state.checkboxData && this.state.checkboxData.length
      ? this.merge({})
      : this.merge({ categoryData });

  setSelectedCategoryIds = (
    selectedCategoryIds: InsightCategory[]
  ): InsightsState => this.merge({ selectedCategoryIds });

  setShowEditor = (showEditor: boolean): InsightsState =>
    this.merge({ showEditor });

  setShowInformation = (showInformation: boolean): InsightsState =>
    this.merge({ showInformation });

  resetInsightsData = (): InsightsState =>
    this.merge({
      selectedCategoryIds: [],
      companyFilterOptions: [],
      peerCompanyFilterOptions: [],
      companyFilter: [],
      peerCompanyFilter: [],
      suggestedPeerCompanyFilter: [],
      selectedItems: [],
      selectedGroupCheckboxIds: [],
      editBenchmarkData: null,
      globalCompanyMappingsFilter: [],
    });
  setInsightTileToExport = (
    insightTileToExport: InsightTileToExport
  ): InsightsState => this.merge({ insightTileToExport });

  setTileDetails = (tileDetails: Visualization[]): InsightsState =>
    this.merge({ tileDetails });

  setSavedPeerGroupList = (savedPeerGroupList: PeerGroup[]): InsightsState =>
    this.merge({ savedPeerGroupList });

  setDeletePeerGroupPopUP = (
    deleteSavedPeerGroupPopUP: boolean
  ): InsightsState => this.merge({ deleteSavedPeerGroupPopUP });

  setRefreshSavedPeerGroupsList = (
    refreshSavedPeerGroupsList: boolean
  ): InsightsState => this.merge({ refreshSavedPeerGroupsList });

  setSelectedPeerGroupItems = (
    selectedPeerGroupItems: number[]
  ): InsightsState => this.merge({ selectedPeerGroupItems });

  updateTileFilters = (benchmarkMetadata: any): InsightsState => {
    let tmpBenchmarkFilters: BenchmarkFilter[] = JSON.parse(
      JSON.stringify(this.state.benchmarkFilters)
    );

    let tmpCompanyFilter =
      benchmarkMetadata.benchmarkMetadataValue.filters.find(
        (f: any) => f.label === INSIGHT_DASHBOARD_FILTER_LABELS.COMPANIES
      );

    if (tmpCompanyFilter && tmpCompanyFilter.values.length === 0)
      tmpBenchmarkFilters = tmpBenchmarkFilters.filter(
        (item: BenchmarkFilter) =>
          item.benchmarkId !== benchmarkMetadata.benchmarkId
      );
    else {
      if (
        tmpBenchmarkFilters.find(
          (fil: BenchmarkFilter) =>
            fil.benchmarkId === benchmarkMetadata.benchmarkId
        )
      ) {
        tmpBenchmarkFilters.forEach((item) => {
          if (item.benchmarkId === benchmarkMetadata.benchmarkId)
            item.filters = benchmarkMetadata.benchmarkMetadataValue.filters;
        });
      } else {
        tmpBenchmarkFilters.push({
          benchmarkId: benchmarkMetadata.benchmarkId,
          filters: benchmarkMetadata.benchmarkMetadataValue.filters,
        });
      }
    }

    return this.merge({ benchmarkFilters: tmpBenchmarkFilters });
  };

  resetBenchmarkFilters = (): InsightsState =>
    this.merge({ benchmarkFilters: [] });

  setGlobalCompanyMappingsFilter = (
    globalCompanyMappingsFilter: Partial<GlobalCompanyGroup>[]
  ): InsightsState => {
    return this.merge({
      loadingFilter: this.state.loadingFilter.filter(
        (filter: FILTER_OPTIONS) => filter !== FILTER_OPTIONS.GLOBAL_COMPANY
      ),
      globalCompanyMappingsFilter: globalCompanyMappingsFilter,
    });
  };

  setSavedUserPreference = (
    savedUserPreference: InsightUserPreference
  ): InsightsState => this.merge({ savedUserPreference });

  setUserPreferenceError = (userPreferenceError: boolean): InsightsState =>
    this.merge({ userPreferenceError });

  setCurrentTab = (currentTabId: number): InsightsState =>
    this.merge({ currentTabId });
}

export type InsightsDashboard = {
  insightsId: number;
  insightsName: string;
  createdBy: number;
  insightsCompanies: InsightDashboardCompany[];
  insightsCategories: InsightCategory[];
  createdDate: string;
  lastUpdatedDate: string;
  lastAccessedDate: string;
  insightsViewType: number;
  sharedBy: User;
  remainingDays: number;
  sharedWith: User[];
  sharedDate: string;
  availableReportedYears: number[];
  selectedQuarter: string;
  selectedReportedYear: number;
  globalCompanyMappings: GlobalCompanyGroup[];
  vendorUpdatedDates: VendorUpdatedDate[];
};

export type InsightsSharePayload = {
  insightsName: string;
  insightsId: number;
  sharedWith: [
    {
      userId: string;
    }
  ];
  sourceinsightsId: number;
};

export type FeatureSetting = {
  featureName: string;
  isEnabled: boolean;
};

export type InsightDashboardCompany = {
  insightsId: number;
  cikNumber: number;
  isBaseCompany: boolean;
  displayOrder: number;
  isSuggestedPeerCompany: boolean;
  globalCompany: InsightCompany;
  createdDate: string;
  lastUpdatedDate: string;
  selectedDetailedViewBenchmarkIds: number[];
  globalCompanyId: number;
};

export type savePeerGroupNamePayload = {
  companyGroupName: string;
  baselineCompanyCikNumber?: number | string;
  baselineCompany?: baselineCompanyForPeerGroupPayload;
  companyGroupCompanies: peerGroupCompanies[];
  globalCompanyMappings?: Partial<GlobalCompanyGroup>[];
};

export type baselineCompanyForPeerGroupPayload = {
  cikNumber: number | string;
  company: CompanyFilter;
};

export type peerGroupCompanies = {
  cikNumber: number;
  displayOrder: number;
  isSuggestedPeerCompany: boolean;
  company: CompanyFilter;
};

export type InsightCompany = {
  companyId: number;
  companyName: string;
  cikNumber: number;
  ticker: string;
  industry: {
    industryId: number;
    industryName: string;
    sectors: any;
  };
  industryName: string;
  sector: any;
  auditor: {
    auditorId: number;
    auditorName: string;
    auditorNumber: number;
    externalAuditorId: number;
  };
  auditorId: number;
  externalCompanyId: number;
  mrStockPriceDate: any;
  mrFinancialsDate: any;
  mrfyStockPriceDate: any;
  mrfyFinancialsDate: any;
  mrMarketCap: number;
  mrRevenue: number;
  mrfyMarketCap: number;
  mrfyRevenue: number;
  industryIdOverridden: boolean;
  isNameSetManually: boolean;
  isTickerSetManually: boolean;
  isAuditorSetManually: boolean;
  isForeignPrivateIssuer: boolean;
  parentCompanyID: number;
  ivaCompanyRating: any;
  ivaCompanyRatingOrder: number;
  ivaCompanyRatingReportUrl: any;
  corpGovernanceScore: any;
  corpGovernanceReportUrl: any;
  totalAssets: any;
  fields: any;
  globalCompanyId?: number;
};

export type InsightCategory = {
  insightsCategoryId: number;
  insightsCategoryName: string;
  displayOrder: number;
  isHidden: boolean;
  benchmarks: Visualization[];
  createdDate: string;
  lastUpdatedDate: string | null;
  selectedDetailedViewBenchmarkIds: number[];
  sections?: InsightCategorySections[];
  sectionGroups?: InsightCategorySectionGroups[];
  sectionBenchmarkMappings?: InsightSectionMappings[];
};

export type InsightCategorySections = {
  displayOrder: number;
  sectionId: number;
  sectionName: string;
};
export type InsightCategorySectionGroups = {
  displayOrder: number;
  sectionGroupId: number;
  sectionGroupName: string;
  sectionGroupWidth: number;
};

export type InsightSectionMappings = {
  benchmarkDisplayOrder: number;
  benchmarkId: number;
  sectionDisplayOrder: number;
  sectionGroupDisplayOrder: number;
  sectionGroupId: number;
  sectionId: number;
};

export type FilterPayloadToSend = {
  benchmarkTileTypeId: any;
  benchmarkMetadataSection: Filter;
};

export type Filter = {
  filters: {
    label: string;
    values: any[];
    columnName: string;
  };
};

export type AdditionalInfo = {
  additionalInformationId: number;
  referenceId: number;
  benchmarkId: number;
  additionalInformationType: number;
  additionalInformationText: string;
  createdBy: number;
  createdDate: string;
  lastUpdatedDate: string;
};

export type exportOptions = {
  export_ChartView: any[];
  export_TableView: any[];
};

export type talkingPoints = {
  talkingPointId: number;
  talkingPointText: string;
  talkingPointDisplayOrder: number;
};

export type talkingPointHeaders = {
  talkingPointHeaderId: number;
  talkingPointHeaderText: string;
  talkingPointHeaderDisplayOrder: number;
  talkingPoints: talkingPoints[];
};

export type talkingPointCollection = {
  insightsCategoryId: number;
  benchmarkTitle: string;
  benchmarkTileType: number;
  benchmarkType: number;
  talkingPointHeaders: talkingPointHeaders[];
};

export type InsightTileToExport = {
  exportFileName: string;
  exportReferenceId: number;
  exportBenchmarkId: number;
  index: number;
  tileName: string;
  isTableView: boolean;
};

export type PeerGroup = {
  baselineCompany?: InsightCompany;
  baselineCompanyCikNumber: number;
  companyGroupCompanies: CompanyGroupCompany[];
  companyGroupId: number;
  companyGroupName: string;
  createdBy: number;
  createdDate: string;
  lastUpdatedDate: string;
  globalCompanyMappings?: Partial<GlobalCompanyGroup>[];
};

export type GlobalPeerGroup = {
  baselineCompany?: InsightCompany;
  baselineCompanyCikNumber: number;
  companyGroupCompanies: CompanyGroupCompany[];
  companyGroupId: number;
  companyGroupName: string;
  createdBy: number;
  createdDate: string;
  lastUpdatedDate: string;
  globalCompanyMappings?: Partial<GlobalCompanyGroup>[];
};

export type companyGroupGlobal = {
  cikNumber: number;
  globalCompany: InsightCompany;
  company?: InsightCompany;
  companyGroupId: number;
  createdDate: string;
  displayOrder: number;
  isSuggestedPeerCompany: boolean;
  lastUpdatedDate: string;
  globalCompanyId?: number;
};

export type CompanyGroupCompany = {
  cikNumber: number;
  company?: InsightCompany;
  globalCompany: InsightCompany;
  companyGroupId: number;
  createdDate?: string;
  displayOrder: number;
  isSuggestedPeerCompany: boolean;
  lastUpdatedDate?: string;
  globalCompanyId?: number;
  globalCompanyMapping?: Partial<GlobalCompanyGroup>;
};

export type BenchmarkFilter = {
  benchmarkId: number;
  filters: any;
};

export type InsightCompanyFlat = {
  externalCompanyId: number;
  globalCompanyId: number;
  companyName: string;
  isBaseCompany?: boolean;
  industryId?: number;
  industryName?: string;
  globalCompanyMapping?: GlobalCompanyGroup;
  displayOrder?: number;
};

export type InsightData = InsightCompanyFlat & {
  metrics: InsightMetricData[];
};

export type InsightMetricData = {
  metricKey: string;
  metricName: string;
  metricValue: any;
  metricMinValue?: any;
  metricMaxValue?: any;
  metricTooltip?: string | AssociatedMetricTooltips[];
  isRound?: boolean;
  metricValueIcon?: any;
  metricValueTooltip?: any;
  metricDescription?: string | null;
  metricUnit?: string;
  metricValueColor?: string;
};

export type CdpScope3CategoriesStatus = {
  categoryName: string;
  categoryColor: string;
  categoryValue: string;
};

export type GlobalCompany = {
  auditorId?: number;
  cikNumber?: number;
  companyName: string;
  countryCode: string;
  externalCompanyId?: number;
  globalCompanyId: number;
  industryId?: number;
  isinNumber: string;
  mrfyMarketCap?: number;
  ticker?: string;
  vendorCollection?: {
    priority: number;
    vendorId: number;
    vendorName: string;
    color: string;
  }[];
  isCDPCompany?: boolean;
  isMSCICompany?: boolean;
  isRaterRankerCompany?: boolean;
  isSNPCompany?: boolean;
};

export type GlobalCompanyMapping = {
  globalCompanyId: number;
  globalCompanyMappingDetailId: number;
  globalCompanyMappingId: number;
  isDefault: boolean;
  displayOrder: number;
  globalCompany: GlobalCompany;
};

export type GlobalCompanyGroup = {
  globalCompanyMappingId: number;
  globalCompanyMappingName: string;
  createdBy: number;
  globalCompanyMappingDetails: GlobalCompanyMapping[];
  isSelected?: boolean;
  disabled?: boolean;
  isBaseMapping?: boolean;
  isSaved?: boolean;
  isActive?: boolean;
  displayOrder?: number;
};

export type HybridCompany = {
  displayOrder: number;
  company?: CompanyFilter;
  globalCompanyGroup?: Partial<GlobalCompanyGroup>;
};

export type InsightUserPreference = {
  userPreferenceId: number;
  userId: number;
  referenceId: number;
  userPreferenceValue: insightUserPreferenceObject;
  userPreferenceType: number;
  createdDate: string;
  lastUpdatedDate: string;
};

export type insightUserPreferenceObject = {
  insightsSectionsPreference: insightUserPreferences[];
};

export type insightUserPreferences = {
  benchmarkTypeId: number;
  expandedSectionIds: number[];
};

export type VendorUpdatedDate = {
  vendorId: number;
  vendorName: string;
  updatedDate: string;
};
