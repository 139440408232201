import classNames from "classnames";
import TileEmptyContent from "components/insight-tile/tile-empty-content";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Popover from "components/shared/popover/popover";
import { useEffect, useState } from "react";
import {
  INSIGHT_BENCHMARK_TYPE,
  INSIGHT_PEER_COMPANY,
  NEUTRAL_VALUES,
} from "utils/constants";
import Parser from "html-react-parser";
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";

type Label = {
  id: string;
  label: string;
  tooltip: any;
};

type TableData = {
  MSCIESGRatingView: boolean;
  labels: Label[];
  response: any;
  tileType: number;
};

type Props = {
  data: TableData;
  currentInsightView: number;
  hasDate?: boolean;
  leftAlignment?: boolean;
  customTableClassName?: string;
  hasCustomNoDataAvailableMsg?: boolean;
  horizontalTable?: boolean;
  renderNeutralTooltip?: boolean;
  getCustomValue?: any;
  customNeutralTooltipContent?: any;
  hideTableHeader?: boolean;
  rightAlignment?: boolean;
};

//ADD NEW PROPERTIES WHEN REQUIRED, DO NOT ADD SPECIFIC VALIDATIONS OR STYLES FOR A SPECIFIC TILE WITHIN THIS COMPONENT
const TableChart = ({
  data,
  currentInsightView,
  hasDate,
  leftAlignment,
  customTableClassName,
  hasCustomNoDataAvailableMsg,
  horizontalTable,
  renderNeutralTooltip,
  getCustomValue,
  customNeutralTooltipContent,
  hideTableHeader,
  rightAlignment,
}: Props) => {
  const BLOCK = "tableChart";
  const [companiesResponse, setCompaniesResponse] = useState(
    data && data.response
      ? data.response.filter(
          (res: any) => res.header !== INSIGHT_PEER_COMPANY.companyName
        )
      : []
  );
  const dispatch = useDispatch();
  const mainTooltip = useSelector(
    (store: RootStore) => store.commons.mainTooltip
  );

  useEffect(() => {
    if (data && data.response) {
      setCompaniesResponse(
        data.response.filter(
          (res: any) => res.header !== INSIGHT_PEER_COMPANY.companyName
        )
      );
    }
  }, [data]);

  const hasNoData = data?.labels?.reduce(
    (acc: any, l: any) =>
      acc &&
      companiesResponse.filter(
        (d: any) => d[l.id] === null || d[l.id] === undefined || d[l.id] === "*"
      ).length === companiesResponse.length,
    true
  );

  const hasMissingData = hasDate
    ? hasDate
    : data?.labels?.reduce(
        (acc: any, l: any) =>
          acc ||
          companiesResponse.filter(
            (d: any) =>
              d[l.id] === null || d[l.id] === undefined || d[l.id] === "*"
          ).length > 0,
        false
      );

  if (hasNoData || !data || !data.labels || data.labels.length === 0)
    return <TileEmptyContent />;

  const getNeutralTooltip = (value: any) =>
    customNeutralTooltipContent ? (
      customNeutralTooltipContent(value)
    ) : value && NEUTRAL_VALUES.includes(value) ? (
      <FormattedMessage id="insights.tabular.neutral" />
    ) : null;

  const getVerticalTable = () => {
    /*Metrics on y axis
    Values and companies on x axis*/
    return (
      <>
        {companiesResponse.length > 1 && (
          <thead className={`${BLOCK}__table-header`}>
            {/* Values & companies */}
            <tr>
              <th
                style={{
                  width: `calc( (100%) / ${companiesResponse.length + 1} )`,
                }}></th>
              {companiesResponse.map((comp: any, i: number) => (
                <th
                  key={`table-header-${i}`}
                  className={classNames(`${BLOCK}__table-header-label`, {
                    [`${BLOCK}__table-header-label--left`]: leftAlignment,
                  })}>
                  {comp.header}
                </th>
              ))}
            </tr>
          </thead>
        )}

        <tbody
          className={classNames(`${BLOCK}__table-body`, {
            [` ${BLOCK}__table-body-cell--border-top`]:
              companiesResponse.length === 1,
          })}>
          {/* Metrics */}
          {data.labels.map((l: Label, ind: number) => (
            <tr key={`${ind}-lbl`}>
              <td
                style={{
                  width: `calc( (100%) / ${companiesResponse.length + 1} )`,
                }}>
                <span className={`${BLOCK}__fit-content`}>
                  <Popover
                    metricClassName={classNames(
                      `${BLOCK}__table-body-value ${BLOCK}__table-body-value--left`
                    )}
                    displayText={<span>{Parser(l.label ?? "")}</span>}
                    content={l.tooltip ? l.tooltip : []}
                    buttonClassName={`${BLOCK}__label-button ${BLOCK}__text--line-height`}
                  />
                </span>
              </td>
              {companiesResponse.map((d: any, index: number) => (
                <td
                  className={classNames(
                    `${BLOCK}__table-body-value ${BLOCK}__table-body-value--bold`,
                    {
                      [`${BLOCK}__table-body-value--right`]:
                        companiesResponse.length === 1 && !leftAlignment,
                      [`${BLOCK}__table-body-value--left`]: leftAlignment,
                      [`${BLOCK}__table-body-value--center`]:
                        companiesResponse.length > 1 && !leftAlignment,
                    }
                  )}
                  onMouseEnter={(e) => {
                    let tmpTooltip =
                      renderNeutralTooltip && getNeutralTooltip(d[l.id]);
                    if (tmpTooltip)
                      handleMouseEnter(
                        e,
                        "td",
                        <div>{tmpTooltip}</div>,
                        "",
                        dispatch,
                        null,
                        MainTooltipPosition.BottomMiddle
                      );
                  }}
                  onMouseLeave={(e) => {
                    let tmpTooltip =
                      renderNeutralTooltip && getNeutralTooltip(d[l.id]);

                    if (tmpTooltip)
                      handleMouseLeave(
                        e,
                        dispatch,
                        mainTooltip.isOverTooltip
                          ? mainTooltip.isOverTooltip
                          : false
                      );
                  }}
                  key={`${index}-val`}>
                  {getCustomValue ? null : d[l.id] ? Parser(d[l.id]) : "*"}

                  {getCustomValue && getCustomValue(l, d[l.id], d, ind)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </>
    );
  };

  const getHorizontalTable = () => {
    /*Metrics on x axis
    Values and companies on y axis*/
    return (
      <>
        {hideTableHeader ? null : (
          <thead className={`${BLOCK}__table-header`}>
            {/* Metrics */}
            <tr>
              <th></th>
              {data.labels.map((l: Label, i: number) => (
                <th
                  key={`table-header-${i}`}
                  className={classNames({
                    [`${BLOCK}__table-header-label--left`]: leftAlignment,
                  })}>
                  <span className={`${BLOCK}__fit-content`}>
                    <Popover
                      metricClassName={classNames(
                        `${BLOCK}__table-header-label`,
                        {
                          [`${BLOCK}__table-header-label--left`]: leftAlignment,
                        }
                      )}
                      displayText={<span>{Parser(l.label ?? "")}</span>}
                      content={l.tooltip ? l.tooltip : []}
                      buttonClassName={`${BLOCK}__label-button ${BLOCK}__text--line-height`}
                    />
                  </span>
                </th>
              ))}
            </tr>
          </thead>
        )}

        <tbody className={`${BLOCK}__table-body`}>
          {/* Values & companies */}
          {companiesResponse.map((d: any, ind: number) => (
            <tr key={`table-row-${ind}`}>
              <td
                className={classNames(
                  `${BLOCK}__table-body-value ${BLOCK}__table-body-value--left`
                )}>
                {d.header}
              </td>
              {data.labels.map((l: Label, index: number) => {
                return (
                  <td
                    className={classNames(
                      `${BLOCK}__table-body-value ${BLOCK}__table-body-value--bold`,
                      {
                        [`${BLOCK}__table-body-value--left`]: leftAlignment,
                        [`${BLOCK}__table-body-value--right`]: rightAlignment,
                      }
                    )}
                    key={`${index}-lbl`}>
                    {getCustomValue ? null : d[l.id] ? Parser(d[l.id]) : "*"}
                    {getCustomValue && getCustomValue(l, d[l.id], d, ind)}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </>
    );
  };

  return (
    <div className={`${BLOCK}__container`} data-testid={`${BLOCK}__container`}>
      <table
        className={classNames(`${BLOCK}__table`, {
          [`${customTableClassName}`]: customTableClassName,
        })}>
        {horizontalTable ||
        (currentInsightView === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK &&
          !horizontalTable)
          ? getHorizontalTable()
          : getVerticalTable()}
      </table>
      {!hasCustomNoDataAvailableMsg && hasMissingData && (
        <div className={`${BLOCK}__missing-data-msg`}>
          <FormattedMessage id="no.data.available" />
        </div>
      )}
    </div>
  );
};

export default TableChart;
