import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Checkbox from "./checkbox";
import { useDispatch } from "react-redux";
import { Fragment } from "react";
import {
  CustomMetricsData,
  FieldGroupMetricData,
  FieldMetricData,
} from "services/dashboard/dashboard.model";
import { InsightCategory } from "services/insights/insights.model";
import {
  CHECKBOX_DATA,
  COMPARISON_EXPORT_PANEL_EXCEPTIONS,
  PPT_EXPORT_FORMAT,
} from "utils/constants";
import { showTooltip } from "services/commons.service";
import { TooltipPosition } from "../tooltip/tooltip";
import classNames from "classnames";
import Parser from "html-react-parser";

type props = {
  // header for the component
  header?: string;
  // Function that is to be dispatched on adding/removing from selected checklist items
  dispatchFunction: (array: number[]) => void;
  dispatchFunctionTwo?: (array: number[]) => void;
  // State of the reducer when items are added/removed
  reducerState: number[];
  reducerStateTwo?: number[];
  //  Data from which checkboxes are populated
  checkboxData: any[];
  //IF YOU WANT TO ALIGN THE RADIO/CHECKBOX BUTTON AS PER YOUR CHOICE.
  alignContainer?: string;
  type?: string;
  radio?: boolean;
  checkboxDisabled?: boolean;
  checkboxConditionForDisabled?: boolean;
};
const CheckboxContainer = ({
  header,
  dispatchFunction,
  dispatchFunctionTwo,
  reducerState,
  reducerStateTwo,
  checkboxData,
  alignContainer,
  type,
  radio,
  checkboxDisabled,
  checkboxConditionForDisabled,
}: props) => {
  const BLOCK = "checkbox-container";
  const dispatch = useDispatch();
  const handleMouseEnter = (
    e: any,
    children: any,
    className: string | null = null,
    customWidth: number | null = null
  ) => {
    const element = e.target.closest("span");
    let data = "( ";
    children.fields &&
      children.fields.length !== 0 &&
      children.fields
        .sort(
          (field1: FieldMetricData, field2: FieldMetricData) =>
            field1.displayOrder - field2.displayOrder
        )
        .map(
          (fields: any) =>
            (data = data + fields.displayName.split(" ", 2)[0] + " , ")
        );
    data = data.substring(0, data.length - 2);
    data = data + ")";
    dispatch(
      showTooltip({
        children: Parser(data),
        position: TooltipPosition.progressBarTop,
        customPosition: true,
        elementDimensions: element?.getBoundingClientRect(),
        className: className,
        width: customWidth,
        executeMouseLeaveEvent: true,
      })
    );
  };
  const handleMouseLeave = (e: any) => {
    dispatch(
      showTooltip({
        children: null,
        customPosition: null,
        position: null,
        arrowPosition: null,
        elementDimensions: null,
        className: null,
        width: null,
      })
    );
  };
  const onCheckboxItemChangeHandler = (id: any, type: string) => {
    if (type === "field") {
      const indexFound = reducerState.findIndex((itemId) => itemId === id);
      if (indexFound !== -1) {
        dispatch(
          dispatchFunction(reducerState.filter((itemId) => itemId !== id))
        );
      } else {
        dispatch(dispatchFunction([...reducerState, id]));
      }
    } else if (type === "fieldGroup") {
      const indexFound = reducerStateTwo!.findIndex((itemId) => itemId === id);
      if (indexFound !== -1) {
        dispatch(
          dispatchFunctionTwo!(
            reducerStateTwo!.filter((itemId) => itemId !== id)
          )
        );
      } else {
        dispatch(dispatchFunctionTwo!([...reducerStateTwo!, id]));
      }
    } else if (type === "insights") {
      const indexFound = reducerState.findIndex((itemId) => itemId === id);
      if (indexFound !== -1) {
        dispatch(dispatchFunction([]));
      } else {
        dispatch(dispatchFunction([id]));
      }
    } else if (type === "export") {
      const indexFound = reducerState.findIndex((itemId) => itemId === id);
      if (radio) {
        dispatchFunction([id]);
      } else {
        if (indexFound !== -1) {
          dispatchFunction(reducerState!.filter((itemId) => itemId !== id));
        } else {
          dispatchFunction([...reducerState, id]);
        }
      }
    }
  };
  const createInsightsCheckboxItem = (item: InsightCategory) => {
    return (
      <div
        className={`${BLOCK}__checkbox-item`}
        key={`checkbox-container-${item.insightsCategoryId}--parent`}
      >
        <div
          className={`${BLOCK}__checkbox-parent`}
          key={item.insightsCategoryId}
        >
          <Checkbox
            label={item.insightsCategoryName}
            key={item.insightsCategoryId}
            item={item}
            value={reducerState.includes(item.insightsCategoryId)}
            onChange={onCheckboxItemChangeHandler}
            disabled={type === "edit" ? true : false}
            type={"insights"}
          />
        </div>
      </div>
    );
  };
  // Recursive call to populate checkbox items
  const createCheckboxItem = (items: FieldMetricData[]) => {
    return (
      <Fragment>
        {items
          .sort(
            (item1: FieldMetricData, item2: FieldMetricData) =>
              item1.displayOrder - item2.displayOrder
          )
          .map((item: FieldMetricData, index: number) => (
            <div
              className={`${BLOCK}__checkbox-item`}
              key={`checkbox-container-${item.fieldId}--parent`}
            >
              <div className={`${BLOCK}__checkbox-parent`} key={item.fieldId}>
                <Checkbox
                  label={item.displayName}
                  key={item.fieldId}
                  item={item}
                  value={reducerState.includes(item.fieldId)}
                  onChange={onCheckboxItemChangeHandler}
                  disabled={type === "edit" ? true : false}
                />
              </div>
            </div>
          ))}
      </Fragment>
    );
  };
  const createCheckboxGroup = (item: FieldGroupMetricData[]) => {
    return (
      <Fragment>
        {item
          .sort(
            (item1: FieldGroupMetricData, item2: FieldGroupMetricData) =>
              item1.displayOrder - item2.displayOrder
          )
          .map((item: FieldGroupMetricData, index: number) => (
            <div
              className={`${BLOCK}__checkbox-item`}
              key={`checkbox-container-${item.fieldGroupId}--parent`}
            >
              <span
                className={`${BLOCK}__checkbox-parent`}
                key={item.fieldGroupId}
                onMouseEnter={(e) => {
                  if (item && item.displayName === "Pillar scores")
                    handleMouseEnter(e, item);
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e);
                }}
                data-testid="checkbox-parent-label"
              >
                <Checkbox
                  label={item.displayName}
                  key={item.fieldGroupId}
                  item={item}
                  value={reducerStateTwo!.includes(item.fieldGroupId)}
                  onChange={onCheckboxItemChangeHandler}
                  disabled={type === "edit" ? true : false}
                />
              </span>
            </div>
          ))}
      </Fragment>
    );
  };
  const createExportsCheckboxItem = (item: any) => {
    return (
      <div
        className={`${BLOCK}__checkbox-item`}
        key={`checkbox-container-${item}--parent`}
      >
        <div className={`${BLOCK}__checkbox-parent`} key={item}>
          <Checkbox
            label={item}
            key={item}
            item={item}
            value={reducerState.includes(item)}
            onChange={onCheckboxItemChangeHandler}
            disabled={
              checkboxDisabled
                ? true
                : (checkboxConditionForDisabled &&
                    item === PPT_EXPORT_FORMAT.SINGLE.NAME) ||
                  (checkboxConditionForDisabled &&
                    item === CHECKBOX_DATA.EXPORT_COMPACTVIEW)
                ? true
                : false
            }
            type={"export"}
            radio={radio}
            tooltip={
              !checkboxDisabled &&
              checkboxConditionForDisabled &&
              item === PPT_EXPORT_FORMAT.SINGLE.NAME
                ? "not.supported.layout"
                : !checkboxDisabled &&
                  checkboxConditionForDisabled &&
                  item === CHECKBOX_DATA.EXPORT_COMPACTVIEW
                ? "not.supported.compact.view"
                : ""
            }
          />
        </div>
      </div>
    );
  };
  return (
    <div className={`${BLOCK}`} data-testid="checkbox-container">
      <div className={`${BLOCK}__header`}>
        {" "}
        {header && <FormattedMessage id={header} />} {}
      </div>
      <div
        className={classNames(`${BLOCK}__body`, {
          [`${BLOCK}__AlignInRow`]:
            alignContainer ===
            COMPARISON_EXPORT_PANEL_EXCEPTIONS.EXPORT_PANEL_BLOCK,
          [`${BLOCK}__NoMargin`]:
            alignContainer ===
            COMPARISON_EXPORT_PANEL_EXCEPTIONS.EXPORT_PANEL_ALIGN_MARGIN_REMOVE,
        })}
      >
        {checkboxData &&
          checkboxData?.length !== 0 &&
          checkboxData.map((item: CustomMetricsData, index: number) => {
            if (
              item.fields ||
              item.fieldGroups ||
              item.fields?.length > 0 ||
              item.fieldGroups?.length > 0
            ) {
              return (
                <Fragment key={`checkbox-${index}`}>
                  {item.fields && item.fields.length > 0
                    ? createCheckboxItem(item.fields)
                    : null}
                  {item.fieldGroups && item.fieldGroups.length > 0
                    ? createCheckboxGroup(item.fieldGroups)
                    : null}
                </Fragment>
              );
            }
            return null;
          })}
        {checkboxData &&
          checkboxData.length !== 0 &&
          type === "insights" &&
          checkboxData.map((item: InsightCategory, index: number) => {
            if (item.insightsCategoryId) {
              return (
                <Fragment key={`checkbox-${index}`}>
                  {createInsightsCheckboxItem(item)}
                </Fragment>
              );
            }
            return null;
          })}
        {checkboxData &&
          checkboxData.length !== 0 &&
          type === "export" &&
          checkboxData.map((item: any, index: number) => {
            if (item) {
              return (
                <Fragment key={`checkbox-${index}`}>
                  {createExportsCheckboxItem(item)}
                </Fragment>
              );
            }
          })}
      </div>
    </div>
  );
};
export default CheckboxContainer;
