import GHGIntensityChart, {
  GHGIntensityCompanyData,
  GHGIntensityMetric,
} from "components/visualizations/charts/ghg-intensity.chart";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import { useTileContext } from "../tile.context";
import TileEmptyContent from "../tile-empty-content";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import { GHG_INTESITY_UNIT_TOOLTIP, SEPARATORS } from "utils/constants";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
const GHGIntensity = () => {
  const {
    dataset,
    metadata: {
      benchmarkType,
      isDetailedView,
      associatedMetricGroupTooltips,
      benchmarkMetadata,
      benchmarkTileType,
    },
    isTableViewActive,
    exporting,
  } = useTileContext();
  const insightsState = useSelector((state: RootStore) => state.insights);
  const ESRSCatgory =
    insightsState.currentSavedInsightDashboard.insightsCategories.find(
      (ic) => ic.insightsCategoryId === 6
    );
  const isESRS = !!ESRSCatgory;

  const METRIC_LABEL = "Scope 1 and 2 Intensity (MtCO2e)";
  const NO_UNIT_SPECIFIED = "No unit specified";

  if (
    dataset.length === 0 ||
    (dataset.length > 0 &&
      dataset.every((d: InsightData) =>
        d.metrics.every((m: InsightMetricData) => {
          if (!m.metricValue) return true;
          let units = m.metricValue.split(SEPARATORS.FIELD);
          return units.every((u: string) => {
            let unit = u.split(SEPARATORS.VALUE);
            return unit.length === 0;
          });
        })
      ))
  )
    return (
      <div>
        <TileEmptyContent />
      </div>
    );

  const getFormattedMetrics = (
    fieldValue: string | null,
    companyName: string,
    minValue?: string,
    maxValue?: string
  ) => {
    if (!fieldValue) return [];

    let units = fieldValue.split(SEPARATORS.FIELD);
    let unitsMinValue = minValue ? minValue.split(SEPARATORS.FIELD) : [];
    let unitsMaxValue = maxValue ? maxValue.split(SEPARATORS.FIELD) : [];

    return units.reduce((prev: GHGIntensityMetric[], curr: string) => {
      let unit = curr.split(SEPARATORS.VALUE);
      let unitMinValue: string[] = [];
      let unitMaxValue: string[] = [];

      if (unitsMinValue.length > 0) {
        const currUnitMinValue = unitsMinValue.filter((unitMinValue: string) =>
          unitMinValue.includes(unit[0])
        );
        if (currUnitMinValue.length > 0) {
          let minValue = currUnitMinValue[0].split(SEPARATORS.VALUE);
          unitMinValue = [...minValue];
        }
      }

      if (unitsMaxValue.length > 0) {
        const currUnitMaxValue = unitsMaxValue.filter((unitMaxValue: string) =>
          unitMaxValue.includes(unit[0])
        );
        if (currUnitMaxValue.length > 0) {
          let maxValue = currUnitMaxValue[0].split(SEPARATORS.VALUE);
          unitMaxValue = [...maxValue];
        }
      }

      return [
        ...prev,
        {
          unit: unit.length > 0 ? unit[0] : "",
          unitLabel:
            unit.length === 2 && unit[0] !== ""
              ? `${unit[0].charAt(0).toUpperCase()}${unit[0].slice(1)}`
              : NO_UNIT_SPECIFIED,
          unitTooltip: associatedMetricGroupTooltips
            ? associatedMetricGroupTooltips.filter(
                (tooltip: AssociatedMetricTooltips) =>
                  tooltip.associatedMetricGroupName ===
                  GHG_INTESITY_UNIT_TOOLTIP
              )
            : [],
          metricLabel: METRIC_LABEL,
          metricTooltip: associatedMetricGroupTooltips
            ? associatedMetricGroupTooltips.filter(
                (tooltip: AssociatedMetricTooltips) =>
                  tooltip.associatedMetricGroupName === METRIC_LABEL
              )
            : [],
          value: unit.length === 2 && unit[1] !== "" ? unit[1] : "*",
          minValue:
            unitMinValue.length === 2 && unitMinValue[1] !== ""
              ? unitMinValue[1]
              : null,
          maxValue:
            unitMaxValue.length === 2 && unitMaxValue[1] !== ""
              ? unitMaxValue[1]
              : null,
          companyName: companyName,
        },
      ];
    }, []);
  };

  const mapData = () => {
    return dataset.reduce(
      (prev: GHGIntensityCompanyData[], current: InsightData) => {
        return [
          ...prev,
          {
            globalCompanyId: current.globalCompanyId,
            companyName: current.companyName,
            metrics: current.metrics.reduce(
              (previous: any, curr: InsightMetricData) => {
                return [
                  ...previous,
                  ...getFormattedMetrics(
                    curr.metricValue,
                    current.companyName,
                    curr.metricMinValue,
                    curr.metricMaxValue
                  ),
                ];
              },
              []
            ),
          },
        ];
      },
      []
    );
  };

  const mapDetailedTableData = () => {
    let tmpData = mapData();
    let allUnits: string[] = [];
    tmpData.forEach((comp: GHGIntensityCompanyData) => {
      comp.metrics.forEach((m: GHGIntensityMetric) => {
        if (m.unit !== "") allUnits.push(m.unit);
      });
    });

    allUnits = Array.from(new Set(allUnits));
    return allUnits.reduce(
      (prev: GHGIntensityCompanyData[], currUnit: string) => {
        return [
          ...prev,
          {
            globalCompanyId: 0,
            unitTooltip: associatedMetricGroupTooltips
              ? associatedMetricGroupTooltips.filter(
                  (tooltip: AssociatedMetricTooltips) =>
                    tooltip.associatedMetricGroupName ===
                    GHG_INTESITY_UNIT_TOOLTIP
                )
              : [],
            companyName: `${currUnit.charAt(0).toUpperCase()}${currUnit.slice(
              1
            )}`,
            metrics: tmpData.map((td: GHGIntensityCompanyData) =>
              getMetricForDetailedTableView(
                td.metrics,
                currUnit,
                td.companyName
              )
            ),
          },
        ];
      },
      []
    );
  };

  const getMetricForDetailedTableView = (
    met: GHGIntensityMetric[],
    unit: string,
    companyName: string
  ) => {
    let tmpMetric = met.filter((m: GHGIntensityMetric) => m.unit === unit);
    if (tmpMetric.length > 0) {
      return tmpMetric[0];
    } else {
      return {
        unit: unit,
        unitLabel: NO_UNIT_SPECIFIED,
        value: "*",
        companyName: companyName,
        metricLabel: METRIC_LABEL,
        metricTooltip: associatedMetricGroupTooltips
          ? associatedMetricGroupTooltips.filter(
              (tooltip: AssociatedMetricTooltips) =>
                tooltip.associatedMetricGroupName === METRIC_LABEL
            )
          : [],
      };
    }
  };

  //TO DO: complete mapping data for detailed and table view
  const tileProps = {
    currentInsightView: benchmarkType,
    isDetailedView: isDetailedView,
    isTableViewActive: isTableViewActive,
    isESRS: isESRS,
    data: mapData(),
    defaultUnits: benchmarkMetadata.defaultUnits,
    expandAllUnits:
      typeof benchmarkMetadata.expandAllUnits === "boolean"
        ? benchmarkMetadata.expandAllUnits
        : benchmarkMetadata.expandAllUnits === "true",
    detailedTableData: mapDetailedTableData(),
    benchmarkTileType,
    exporting,
  };

  return <GHGIntensityChart {...tileProps} />;
};

export default GHGIntensity;
