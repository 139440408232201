import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  renameShortlistPop,
  shortlistRenameError,
  renameShortlistSuccess,
} from "services/saved-shortlist/saved-shortlist.service";
import Modal from "../shared/modal/modal";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import { RootStore } from "services/store.service";
import { addToastMessage } from "services/commons.service";
import { saveShortlistSuccess } from "services/shortlist/shortlist.service";
import Button from "components/shared/button/button";

type props = {
  setShowModal: (value: boolean) => void;
  renameShortlist: ({
    shortlistName,
    shortlistId,
  }: {
    shortlistName: string;
    shortlistId: number;
  }) => void;
};

const RenameShortlist = ({ setShowModal, renameShortlist }: props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const BLOCK = "RenameShortlist";
  const [disabled, setDisabled] = useState(0);
  const dispatch = useDispatch();
  const shortlistResults = useSelector(
    (state: RootStore) => state.savedShorList
  );
  const [count, setCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(false);
  const [value, setValue] = useState("");
  // Put focus on input tag
  useEffect(() => {
    inputRef.current!.focus();
  }, [shortlistResults.errorRename]);

  useEffect(() => {
    inputRef.current!.value = shortlistResults.renameShortlist.shortlistName;
    setValue(shortlistResults.renameShortlist.shortlistName);
    setCount(shortlistResults.renameShortlist.shortlistName.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDisabled(inputRef.current!.value.trim().length);
    if (
      shortlistResults.renameShortlist.shortlistName ===
      inputRef.current!.value.trim()
    ) {
      setDisabled(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current?.value]);

  useEffect(() => {
    if (shortlistResults.errorRename) {
      setErrorMessage(true);
      inputRef.current!.value = shortlistResults.renameShortlist.shortlistName;
      setCount(shortlistResults.renameShortlist.shortlistName.length);
      dispatch(shortlistRenameError());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortlistResults.errorRename]);

  useEffect(() => {
    if (shortlistResults.renameSuccess) {
      setShowModal(false);
      setErrorMessage(false);
      dispatch(renameShortlistSuccess(false));
      dispatch(saveShortlistSuccess(false));
      dispatch(
        addToastMessage({
          description: <FormattedMessage id="shortlist.save" />,
        })
      );
    }
  }, [shortlistResults.renameSuccess, dispatch, setShowModal]);

  // Count the number of characters input by the user
  const onInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCount(event.target.value.length);
    setValue(event.target.value);
  };

  const saveInputHandler = () => {
    // Not required as button is disabled at length 0
    // if (inputRef.current!.value.trim().length === 0) {
    //   return;
    // }
    renameShortlist({
      shortlistName: inputRef.current!.value,
      shortlistId: shortlistResults.renameShortlist.shortlistId,
    });
    //setShowModal(false);
  };

  // Automatically call saveInputHandler on Enter
  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      saveInputHandler();
    }
  };

  return (
    <Modal
      show={true}
      header="shortlist.edit.title"
      handleClose={() => {
        setShowModal(false);
        dispatch(renameShortlistPop(null));
      }}
      config={false}
    >
      <div className={BLOCK} data-testid="rename-shortlist-component">
        <span
          className={!errorMessage ? `${BLOCK}__name` : `${BLOCK}__nameError`}
        >
          <FormattedMessage id="shortlist.edit.name" />
        </span>
        <span
          className={!errorMessage ? `${BLOCK}__input` : `${BLOCK}__inputError`}
        >
          <input
            type="text"
            data-testid="rename-input"
            data-test="rename-input"
            ref={inputRef}
            maxLength={200}
            onChange={onInputHandler}
            onKeyDown={onEnter}
            value={value}
          />
        </span>

        <span className={`${BLOCK}__content`}>
          {count}
          <FormattedMessage id="shortlist.edit.input.character" />
        </span>
        {errorMessage ? (
          <span className={`${BLOCK}__contentError`}>
            <FormattedMessage id="shortlist.report.error" />
          </span>
        ) : (
          ""
        )}

        <div className={`${BLOCK}__options`}>
          <Button
            className={`button-secondary`}
            onClick={() => {
              setShowModal(false);
              dispatch(renameShortlistPop(null));
            }}
            formattedText="shortlist.edit.button.cancel"
            dataTest="cancel-btn"
          />
          <Button
            className={`button-primary`}
            onClick={saveInputHandler}
            dataTest="rename-shortlist"
            formattedText="shortlist.edit.button.save"
            disabled={disabled === 0}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RenameShortlist;
