import DetailedScoreChart from "components/visualizations/charts/detailed.score.chart";
import ScoreChart from "components/visualizations/charts/score.chart";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import {
  INSIGHT_BENCHMARK_IDS,
  INSIGHT_BENCHMARK_TYPE,
  SPEEDOMETER_DEFAULT_COLORS,
} from "utils/constants";
import { mapTableChart, useTileContext } from "../tile.context";
import TableChart from "components/visualizations/charts/table-chart";

const OtherSustainabilityRankings = () => {
  const {
    metadata,
    associatedMetrics,
    associatedMetricsDisplayNames,
    metadata: { benchmarkType, isDetailedView, benchmarkTileType },
    dataset,
    isTableViewActive,
  } = useTileContext();
  const BLOCK = "climate";
  const getFieldWidth = (type: number, value: any) => {
    if (type === 23) {
      return value;
    } else {
      return value * 10;
    }
  };
  function generatePercentage(type: any, value: any) {
    if (type === "Score" && value !== "Neutral") {
      const score = ["D-", "D", "C-", "C", "B-", "B", "A-", "A"];

      const eachScore = 100 / score.length;
      return (score.indexOf(value) + 1) * eachScore;
    }

    if (type === "CDPClimateChangeScore" && value !== "Neutral") {
      const score = ["F", "D-", "D", "C-", "C", "B-", "B", "A-", "A"];

      const eachScore = 100 / score.length;
      return (score.indexOf(value) + 1) * eachScore;
    }

    return null;
  }

  const mapScoreData = () => {
    let colorCode = metadata.benchmarkMetadata.colorCodes.slice(0, 2);
    let tmpData: any = [];
    if (dataset.length === 0)
      return {
        metrics: "",
        companyName: "",
        benchmarkType: "",
        data: [],
        tileType: "",
      };
    if (benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY) {
      if (
        dataset
          .filter((d: InsightData) => d.isBaseCompany)[0]
          .metrics.every((elem: InsightMetricData) => !elem.metricValue)
      )
        return {
          metrics: "",
          companyName: "",
          benchmarkType: "",
          data: [],
        };

      let newArray: any = [];

      let baseCompany = dataset.filter((d: InsightData) => d.isBaseCompany)[0];
      baseCompany.metrics.forEach((m: InsightMetricData) => {
        newArray.push({
          companyName: baseCompany.companyName,
          description: metadata.associatedMetricsTooltips
            ? metadata.associatedMetricsTooltips.filter(
                (tooltip: any) => tooltip.associatedMetric === m.metricKey
              )
            : [],
          fieldName: m.metricName,
          fieldValue:
            m.metricValue !== "" && m.metricValue !== null
              ? m.metricValue
              : "*",
          feildWidth: !isNaN(m.metricValue as any)
            ? m.metricValue !== "" && m.metricValue !== null
              ? m.metricValue
              : ""
            : generatePercentage(m.metricKey, m.metricValue),
          baseWidth: "",
          isBaseCompany: baseCompany.isBaseCompany,
          tooltip: m.metricValue + " " + baseCompany.companyName,
        });
      });

      return {
        metrics: metadata,
        companyName: baseCompany,
        colors: colorCode,
        gradient: false,
        benchmarkType: "",
        data: newArray,
        tileType: benchmarkTileType,
      };
    } else {
      if (
        dataset.every((d: InsightData) =>
          d.metrics.every(
            (m: InsightMetricData) => !m.metricValue || m.metricValue === ""
          )
        )
      )
        return {
          metrics: "",
          companyName: "",
          benchmarkType: "",
          data: [],
          tileType: "",
        };
      if (isDetailedView) {
        tmpData = associatedMetrics.map((am: string, index: number) => {
          return {
            groupName: am,
            fieldName: associatedMetricsDisplayNames[index],
            description: metadata.associatedMetricsTooltips
              ? metadata.associatedMetricsTooltips.filter(
                  (tooltip: any) => tooltip.associatedMetric === am
                )
              : [],
            data: dataset.map((d: InsightData) => {
              let currentMetric = d.metrics.filter(
                (m: InsightMetricData) => m.metricKey === am
              )[0];
              return {
                companyName: d.companyName,
                fieldValue: currentMetric.metricValue,
                baseWidth: !isNaN(currentMetric.metricValue as any)
                  ? currentMetric.metricValue !== "" &&
                    currentMetric.metricValue !== null
                    ? getFieldWidth(
                        benchmarkTileType,
                        currentMetric.metricValue
                      )
                    : ""
                  : generatePercentage(
                      currentMetric.metricName,
                      currentMetric.metricValue
                    ),
                isBaseCompany: d.isBaseCompany,
                tooltip: "",
              };
            }),
          };
        });
      } else {
        let baseCompany = dataset.filter(
          (d: InsightData) => d.isBaseCompany
        )[0];
        let averageCompany = dataset.filter(
          (d: InsightData) =>
            d.globalCompanyId === INSIGHT_BENCHMARK_IDS.INDUSTRY ||
            d.globalCompanyId === INSIGHT_BENCHMARK_IDS.PEER
        )[0];
        if (!averageCompany) {
          return {
            metrics: "",
            companyName: "",
            benchmarkType: "",
            data: [],
            tileType: "",
          };
        }
        associatedMetrics.forEach((am: string, ind: number) => {
          let currentMetricBaseCompany = baseCompany.metrics.filter(
            (m: InsightMetricData) => m.metricKey === am
          )[0];
          let currentMetricAverageCompany = averageCompany.metrics.filter(
            (m: InsightMetricData) => m.metricKey === am
          )[0];
          tmpData.push({
            avgScore:
              currentMetricAverageCompany.metricValue !== "" &&
              currentMetricAverageCompany.metricValue !== null
                ? currentMetricAverageCompany.metricValue
                : "*",
            companyName: baseCompany.companyName,
            fieldValue:
              currentMetricBaseCompany.metricValue !== "" &&
              currentMetricBaseCompany.metricValue !== null
                ? currentMetricBaseCompany.metricValue
                : "*",
            baseWidth: !isNaN(currentMetricAverageCompany.metricValue as any)
              ? currentMetricAverageCompany.metricValue !== "" &&
                currentMetricAverageCompany.metricValue !== "Neutral"
                ? getFieldWidth(
                    benchmarkTileType,
                    currentMetricAverageCompany.metricValue
                  )
                : ""
              : generatePercentage(
                  currentMetricAverageCompany.metricKey,
                  currentMetricAverageCompany.metricValue
                ),
            isBaseCompany: baseCompany.isBaseCompany,
            tooltip: `${currentMetricBaseCompany.metricValue} ${baseCompany.companyName}`,
            description: metadata.associatedMetricsTooltips
              ? metadata.associatedMetricsTooltips.filter(
                  (tooltip: any) => tooltip.associatedMetric === am
                )
              : [],
            feildWidth: !isNaN(currentMetricBaseCompany.metricValue as any)
              ? currentMetricBaseCompany.metricValue !== "" &&
                currentMetricBaseCompany.metricValue !== null
                ? getFieldWidth(
                    benchmarkTileType,
                    currentMetricBaseCompany.metricValue
                  )
                : ""
              : generatePercentage(
                  currentMetricBaseCompany.metricKey,
                  currentMetricBaseCompany.metricValue
                ),

            fieldName: [currentMetricBaseCompany.metricName],
            baseTooltip: `${currentMetricAverageCompany.metricValue} ${averageCompany.companyName}`,
          });
        });
      }
    }
    return {
      metrics: metadata.benchmarkMetadata,
      gradient: true,
      colors: colorCode,
      benchmarkType: metadata.benchmarkType,
      companyName: {
        companyName: dataset.filter((d: InsightData) => d.isBaseCompany)[0]
          .companyName,
      },
      data: tmpData,
      isDetailView: isDetailedView,
      tileType: benchmarkTileType,
    };
  };

  const mapDetailedScoreChart = () => {
    let tmpResponse = dataset.reduce((prev: any[], ds: InsightData) => {
      let currentGroup = {
        companyName: ds.companyName,

        header: ds.companyName,
      };
      ds.metrics.forEach((m: InsightMetricData) => {
        let formattedMetric =
          m.metricKey.charAt(0).toLowerCase() + m.metricKey.slice(1);
        currentGroup = {
          ...currentGroup,
          [formattedMetric]: m.metricValue,
        };
      });
      return [...prev, currentGroup];
    }, []);
    return {
      labels: associatedMetrics.map((m: string, i: number) => ({
        label: associatedMetricsDisplayNames[i],
        id: m?.charAt(0).toLowerCase() + m?.slice(1),
        tooltip: metadata.associatedMetricsTooltips
          ? metadata.associatedMetricsTooltips.filter(
              (tooltip: any) => tooltip.associatedMetric === m
            )
          : [],
      })),
      colorCodes: metadata.benchmarkMetadata.conditionalColorCodes?.length
        ? metadata.benchmarkMetadata.conditionalColorCodes
        : SPEEDOMETER_DEFAULT_COLORS.COLORS,
      isDetailedView: isDetailedView,
      response: tmpResponse,
    };
  };

  return isTableViewActive ? (
    <TableChart
      data={mapTableChart(dataset, metadata)}
      currentInsightView={benchmarkType}
    />
  ) : isDetailedView ? (
    <DetailedScoreChart
      data={mapDetailedScoreChart()}
      currentInsightView={benchmarkType}
    />
  ) : (
    <div className={`${BLOCK}__wrapper`}>
      {" "}
      {metadata.benchmarkMetadata.benchmarkSubTitle ? (
        <span className={`${BLOCK}__header__sub-text`}>
          {metadata.benchmarkMetadata.benchmarkSubTitle}
        </span>
      ) : null}
      <ScoreChart data={mapScoreData()} />
    </div>
  );
};

export default OtherSustainabilityRankings;
