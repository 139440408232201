import React from "react";
import Modal from "../shared/modal/modal";
import classNames from "classnames";
import FormattedMessage from "../shared/formatted-message/formatted-message";
import { RootStore } from "../../services/store.service";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentSearchItem } from "services/published-searchlist/published-searchlist.service";
import { PublishedSearchPayload } from "services/published-searchlist/published-searchlist.model";
import Button from "components/shared/button/button";
type props = {
  setShowModal: (value: boolean) => void;
  unpublishSearch: (payload: {
    payload: PublishedSearchPayload;
    type: string;
  }) => void;
};
const UnpublishSearch = ({ setShowModal, unpublishSearch }: props) => {
  const BLOCK = "RenameShortlist";
  const publishedSearchlist = useSelector(
    (state: RootStore) => state.publishedSearchlist
  );
  const dispatch = useDispatch();
  return (
    <Modal
      show={true}
      header="unpublish.search.header"
      handleClose={() => {
        setShowModal(false);
        dispatch(setCurrentSearchItem(null));
      }}
      config={false}
    >
      <div
        className={`${BLOCK} ${BLOCK}--saveSearchlist ${BLOCK}--deleteSearch`}
        data-testid={`${BLOCK}`}
      >
        <span className={classNames(`${BLOCK}__name--deleteSavedSearch`)}>
          <FormattedMessage id="unpublish.search.title" />
        </span>
        <div className={`${BLOCK}__options ${BLOCK}__options--deleteSearch`}>
          <Button
            className={`button-secondary`}
            formattedText="unpublish.search.cancel.button"
            onClick={() => {
              setShowModal(false);
              dispatch(setCurrentSearchItem(null));
            }}
            dataTest="cancel-btn"
          />
          <Button
            className={`button-primary`}
            formattedText="unpublish.search.unpublish.button"
            onClick={() => {
              let payload = {
                savedSearchId:
                  publishedSearchlist.currentSearchItem.item.savedSearchId,
                name: publishedSearchlist.currentSearchItem.item.name,
                description:
                  publishedSearchlist.currentSearchItem.item.description,
                searchCriteria: {},
                hasPublished: false,
              };
              unpublishSearch({
                payload,
                type: publishedSearchlist.currentSearchItem.type,
              });
            }}
            dataTest="unpublish-btn"
          />
        </div>
      </div>
    </Modal>
  );
};

export default UnpublishSearch;
