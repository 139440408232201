import { ReactElement } from "react";
import { BaseModel } from "utils/redux.utils";
import { TooltipPosition } from "components/shared/tooltip/tooltip";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";
export interface CommonsState {
  toastMessages: ToastMessage[];
  toolTip: {
    position: TooltipPosition;
    customPosition: boolean;
    arrowPosition: Position;
    children: JSX.Element;
    elementDimensions: any;
    className?: string;
    width?: number;
    executeMouseLeaveEvent?: boolean;
    isOverTooltip?: boolean;
    ref?: any;
  };
  mainTooltip: {
    position: MainTooltipPosition;
    children: JSX.Element;
    elementDimensions: any;
    executeMouseLeaveEvent?: boolean;
    isOverTooltip?: boolean;
  };
  shareLoading: boolean;
  currentUser: User;
  loadingUser: boolean;
  esgAssets: EsgAssets[];
  loadEsgAssets: boolean;
  dashboardColorCodes: string[];
  InsightsDetailedPeerViewColorCodes: string[];
  termsOfUse: AppSettings;
  LowestScoringColorCodes: string[];
  HighestScoringColorCodes: string[];
  IvaCompanyRatingColorCodes: string[];
  showCalendar: boolean;
  appSettings: AppSettings;
  dashboardExportDisclaimer: AppSettings;
  insightsPageDisclaimer: AppSettings;
  insightsPeerCompanyLimit: AppSettings;
  peerBenchmarkMetricsSelectionMaxCount: PeerBenchmarkSourceMaxCount[];
  scope3CategoriesColorCodes: Scope3CategoriesColorCodes[];
}

type AppSettings = {
  [key: string]: string;
};

export class CommonsModel extends BaseModel {
  constructor() {
    super({
      toastMessages: [],
      toolTip: {
        position: null,
        children: null,
        arrowPosition: null,
        customPosition: null,
        element: null,
        className: null,
        width: null,
        executeMouseLeaveEvent: null,
        isOverTooltip: null,
      },
      mainTooltip: {
        position: null,
        children: null,
        elementDimensions: null,
        executeMouseLeaveEvent: null,
        isOverTooltip: null,
      },
      shareLoading: false,
      currentUser: {},
      loadEsgAssets: false,
      esgAssets: [],
      dashboardColorCodes: [],
      InsightsDetailedPeerViewColorCodes: [],
      LowestScoringColorCodes: [],
      HighestScoringColorCodes: [],
      IvaCompanyRatingColorCodes: [],
      showCalendar: false,
      termsOfUse: {},
      appSettings: {},
      dashboardExportDisclaimer: {},
      insightsPageDisclaimer: null,
      insightsPeerCompanyLimit: null,
      peerBenchmarkMetricsSelectionMaxCount: null,
      scope3CategoriesColorCodes: null,
    });
  }

  addToastMessage = (toastMessage: any): CommonsState =>
    this.merge({ toastMessages: [...this.state.toastMessages, toastMessage] });

  removeToastMessage = (index: number): CommonsState =>
    this.merge({
      toastMessages: this.state.toastMessages.filter(
        (item: any, i: number) => i !== index
      ),
    });

  setShareLoading = (shareLoading: boolean): CommonsState =>
    this.merge({ shareLoading });

  showToolTip = (toolTip: any): CommonsState => this.merge({ toolTip });

  loadCurrentUser = (currentUser: User): CommonsState =>
    this.merge({ currentUser });
  setEsgLoading = (loadEsgAssets: boolean): CommonsState =>
    this.merge({ loadEsgAssets });
  setEsgAssets = (esgAssets: EsgAssets[]): CommonsState =>
    this.merge({ esgAssets });

  setShowCalendar = (showCalendar: boolean): CommonsState =>
    this.merge({ showCalendar });

  setDashboardColorCodes = (dashboardColorCodes: string[]): CommonsState =>
    this.merge({ dashboardColorCodes });
  setShowTermsOfUseFooter = (termsOfUse: string[]): CommonsState =>
    this.merge({ termsOfUse });

  setDetailPeerViewColorCodes = (
    InsightsDetailedPeerViewColorCodes: string[]
  ): CommonsState => this.merge({ InsightsDetailedPeerViewColorCodes });

  setLowestScoringColorCodes = (
    LowestScoringColorCodes: string[]
  ): CommonsState => this.merge({ LowestScoringColorCodes });
  setHighestScoringColorCodes = (
    HighestScoringColorCodes: string[]
  ): CommonsState => this.merge({ HighestScoringColorCodes });
  setIvaCompanyRatingColorCodes = (
    IvaCompanyRatingColorCodes: string[]
  ): CommonsState => this.merge({ IvaCompanyRatingColorCodes });

  setAppSetting = (appSetting: any): CommonsState => {
    const newAppSettings = {
      ...this.state.appSettings,
      [appSetting.applicationSettingName]: appSetting.applicationSettingValue,
    };
    return this.merge({ appSettings: newAppSettings });
  };

  setDashboardExportDisclaimer = (
    dashboardExportDisclaimer: any
  ): CommonsState => {
    const newdashboardExportDisclaimer = {
      ...this.state.dashboardExportDisclaimer,
      [dashboardExportDisclaimer.applicationSettingName]:
        dashboardExportDisclaimer.applicationSettingValue,
    };
    return this.merge({
      dashboardExportDisclaimer: newdashboardExportDisclaimer,
    });
  };

  setInsightsPageDisclaimer = (insightsPageDisclaimer: any): CommonsState => {
    const newInsightsPageDisclaimer = {
      ...this.state.insightsPageDisclaimer,
      [insightsPageDisclaimer.applicationSettingName]:
        insightsPageDisclaimer.applicationSettingValue,
    };
    return this.merge({
      insightsPageDisclaimer: newInsightsPageDisclaimer,
    });
  };

  setInsightsPeerCompanyLimit = (
    insightsPeerCompanyLimit: any
  ): CommonsState => {
    const newInsightsPeerCompanyLimit = {
      ...this.state.insightsPeerCompanyLimit,
      [insightsPeerCompanyLimit.applicationSettingName]:
        insightsPeerCompanyLimit.applicationSettingValue,
    };
    return this.merge({
      insightsPeerCompanyLimit: newInsightsPeerCompanyLimit,
    });
  };

  showMainToolTip = (mainTooltip: any): CommonsState =>
    this.merge({ mainTooltip });

  setPeerBenchmarkMetricsSelectionMaxCount = (
    peerBenchmarkMetricsSelectionMaxCount: any
  ): CommonsState => this.merge({ peerBenchmarkMetricsSelectionMaxCount });
  setScope3CtaegoriesColorCodes = (
    scope3CategoriesColorCodes: Scope3CategoriesColorCodes
  ): CommonsState => this.merge({ scope3CategoriesColorCodes });
}

export type Position = {
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
};

export type ToastMessage = {
  description: ReactElement;
};

export type User = {
  containerCode: string;
  containerName: string;
  displayName: string;
  email: string;
  employeeStatusCode: string;
  externalSystemId: string;
  firstName: string;
  geoCode: string;
  isActive: boolean;
  lastName: string;
  memberFirmCode: string;
  organizationCode: string;
  preferredFullName: string;
  title: string;
  userId: number;
  adminRole: Role;
  accessibleFeatures: ApplicationFeature[];
  isTechnicalAdmin: boolean;
  countryName: string;
};

export type Role = {
  adminRoleId: number;
  adminRoleName: string;
};

export type ApplicationFeature = {
  applicationFeatureId: number;
  applicationFeatureName: string;
};

export type EsgAssets = {
  applicationCategoryId: number;
  applicationCategoryName: string;
  applications: [
    {
      applicationId: number;
      applicationName: string;
      description: string;
      applicationCategoryId: number;
      userAccessType: { userAccessTypeId: number; userAccessTypeName: string };
      url: string;
      icon: string;
      displayOrder: 1;
    }
  ];
  displayOrder: number;
};

export type ApplicationCategories = {
  applicationCategoryId: number;
  applicationCategoryName: string;
  applications: [
    {
      applicationId: number;
      applicationName: string;
      description: string;
      userAccessType: { id: number; name: string };
      url: string;
      icon: string;
      displayOrder: 1;
    }
  ];
};

export type PeerBenchmarkSourceMaxCount = {
  [key: string]: number;
};

export type Scope3CategoriesColorCodes = {
  fieldValue: string;
  colorCode: string;
};
