import emptyimg from "images/saved.shortlist-empty.png";
import FormattedMessage from "components/shared/formatted-message/formatted-message";

const ManageADFPipeline = () => {
  const BLOCK = "manage-adf-pipeline";

  return (
    <div className={BLOCK}>
      <div className={`${BLOCK}__empty`}>
        <div className={`${BLOCK}__img`}>
          <img className={""} src={emptyimg} alt={emptyimg} />
        </div>
        <div className={`${BLOCK}__emptytext`}>
          <FormattedMessage id="technical.admin.manage-adf.empty.msg" />
        </div>
      </div>
    </div>
  );
};

export default ManageADFPipeline;
