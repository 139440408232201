import { HighlightOptions, overlay } from "utils/constants";

export const clearCanvas = (canvas: any) => {
  canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
};

/**
 * Used to get plain text string from character range
 */
export const getText = (start: any, end: any, characters: any[]) => {
  if (characters !== null && characters.length > 0) {
    var loc = [start, end].sort((a, b) => (a < b ? -1 : b < a ? 1 : 0));
    var firstIndex = characters.findIndex((c: any) => c.i === loc[0]);
    var secondIndex = characters.findIndex((c: any) => c.i === loc[1]);
    var characterRange = characters.slice(firstIndex, secondIndex + 1);

    return characterRange.reduce(
      (a: any, b: any) =>
        a +
        (b.u.length
          ? b.u.map((c: any) => String.fromCharCode(c)).join("")
          : String.fromCharCode(b.u)),
      ""
    );
  }

  return "";
};

/**
 * Converts Hexadecimal color to rgba
 * Return transparent in case of default
 */
export const hexToRgbA = (hex: string, type?: string) => {
  if (hex === "default") {
    return "rgba(255, 255, 255, 0)";
  }
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    if (type === "ESG") {
      return (
        "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.5)"
      );
    } else {
      return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255] + ")";
    }
  }
  throw new Error();
};

/**
 * Highlights the keywords in the canvas
 */
export const renderKeywords = (
  highlights: any,
  showHighlights: boolean,
  canvas: any,
  characters: any,
  metadata: any
) => {
  if (!showHighlights) {
    return;
  }

  const context = canvas.getContext("2d");

  highlights.forEach((highlight: any) => {
    // fillStyle has been moved within the loop to define the highlight for each keyword
    // rather than a global highlight
    context.fillStyle = highlight.colorCode
      ? hexToRgbA(highlight.colorCode, "ESG")
      : hexToRgbA("default");

    highlight.locations.forEach((location: any) => {
      let wordChars = characters.filter(
        ({ i }: any) => i >= location.offset[0] && i <= location.offset[1]
      );

      let firstLineIndex = 0;
      let word = [];

      while (firstLineIndex < wordChars.length) {
        let finalIndex = wordChars.findIndex(function callback(
          this: number,
          w: any
        ) {
          return w.y1 > wordChars[this].y2;
        },
        firstLineIndex);
        finalIndex = finalIndex >= 0 ? finalIndex : wordChars.length;
        word = wordChars.slice(firstLineIndex, finalIndex);
        firstLineIndex = finalIndex;
        if (word.length > 0) {
          const x1 = word[0].x1;
          const y1 = word[0].y1;
          const x2 = word[word.length - 1].x2;
          const y2 = word[word.length - 1].y2;

          context.beginPath();
          context.fillRect(
            x1,
            y1 - HighlightOptions.padding,
            x2 - x1,
            y2 - y1 + 2 * HighlightOptions.padding
          );
          context.stroke();
        }
      }
    });
  });
};

export const isBreak = (unicode: number) => {
  return overlay.BREAK_CHARACTERS.indexOf(unicode) !== -1;
};

/**
 * Checks if current word is a break
 */
export const isWordBreak = (word: any) => {
  const characters = word.start.character.u;
  return (
    word.start.character.i === word.end.character.i &&
    overlay.BREAK_CHARACTERS.indexOf(
      characters.length ? characters[characters.length - 1] : characters
    ) !== -1
  );
};

export const getSecondIndex = (characters: any[], lastIndex: any) => {
  var findIndex = (offset: any) =>
    characters.findIndex((ch) => ch.i === lastIndex + offset) - 1;
  for (var i = 2; i <= 2 + overlay.NEXT_WORD_DELTA; i++) {
    const found = findIndex(i);
    if (found >= 0) {
      return found;
    }
  }
  return -1;
};

/**
 * Divides the character range in different text lines
 */
export const processCharacterRangeAsTextLines = (characterRange: any[]) => {
  if (characterRange && characterRange.length > 0) {
    var lines = [];
    var currentLine = {
      x1: characterRange[0].x1,
      x2: characterRange[0].x2,
      y1: characterRange[0].y1,
      y2: characterRange[0].y2,
    };

    characterRange.forEach((ch: any) => {
      var line_grouping_percentage = 0.5;
      var height = currentLine.y2 - currentLine.y1;

      if (
        ch.y1 >= currentLine.y1 - height * line_grouping_percentage &&
        ch.y2 <= currentLine.y2 + height * line_grouping_percentage
      ) {
        currentLine = {
          x1: currentLine.x1,
          x2: ch.x2,
          y1: currentLine.y1 <= ch.y1 ? currentLine.y1 : ch.y1,
          y2: currentLine.y2 >= ch.y2 ? currentLine.y2 : ch.y2,
        };
      } else {
        lines.push(currentLine);
        currentLine = {
          x1: ch.x1,
          x2: ch.x2,
          y1: ch.y1,
          y2: ch.y2,
        };
      }
    });

    lines.push(currentLine);
    return lines;
  }

  return [];
};

export const getMinDistance = (word: any, point: any) => {
  var dx = Math.max(word.x1 - point.x, 0, point.x - word.x2);
  var dy = Math.max(word.y1 - point.y, 0, point.y - word.y2);
  return Math.sqrt(dx * dx + dy * dy);
};

export const determineWord = (character: any, page: any) => {
  if (!character || !character.character) {
    return null;
  }
  const characterCode = character.character.u;
  if (
    characterCode.length > 1 ||
    isBreak(
      characterCode.length
        ? characterCode[characterCode.length - 1]
        : characterCode
    )
  ) {
    return {
      start: character,
      end: character,
    };
  }

  if (character.page === page.pageNumber) {
    var origin = page.characters.indexOf(character.character);
    var start = null;
    var end = null;
    var i = 1;

    while ((start === null || end === null) && i < 100) {
      if (start === null && origin - i >= 0) {
        const prevChar = page.characters[origin - i].u;
        if (
          prevChar.length > 1 ||
          isBreak(prevChar.length ? prevChar[prevChar.length - 1] : prevChar)
        ) {
          start = page.characters[origin - i + 1];
        }
      } else if (start === null) {
        start = page.characters[0];
      }

      if (end === null && origin + i < page.characters.length) {
        const nextChar = page.characters[origin + i].u;
        if (
          nextChar.length > 1 ||
          isBreak(nextChar.length ? nextChar[nextChar.length - 1] : nextChar)
        ) {
          end = page.characters[origin + i - 1];
        }
      } else if (end === null) {
        end = page.characters[page.characters.length - 1];
      }

      i++;
    }

    return {
      start: {
        min: character.min,
        character: start,
        page: character.page,
      },
      end: {
        min: character.min,
        character: end,
        page: character.page,
      },
    };
  }
};

export const renderSelection = (lines: any[], canvas: any) => {
  // clearCanvas(canvas);
  const context = canvas.getContext("2d");
  context.fillStyle = HighlightOptions.baseColor;
  lines.forEach((line) => {
    var { x1, x2, y1, y2 } = line;

    context.beginPath();
    context.fillRect(
      x1,
      y1 - HighlightOptions.padding,
      x2 - x1,
      y2 - y1 + 2 * HighlightOptions.padding
    );
    context.stroke();
  });
  if (lines.length) textBookends(lines[0], lines[lines.length - 1], canvas);
};

export const renderTag = (lines: any[], canvas: any) => {
  const context = canvas.getContext("2d");
  context.fillStyle = HighlightOptions.tagColor;
  lines.forEach((line) => {
    var { x1, x2, y1, y2 } = line;

    context.beginPath();
    context.fillRect(
      x1,
      y1 - HighlightOptions.padding,
      x2 - x1,
      y2 - y1 + 2 * HighlightOptions.padding
    );
    context.stroke();
  });
  // if (lines.length) endTag(lines[0], lines[lines.length - 1], canvas);
};

// Adds text handles to the start and end of a users highlighted region (decoration only)
const textBookends = (firstElement: any, lastElement: any, canvas: any) => {
  //Thickness of bookends is based on the height of the text on the appropriate element
  var w1 = (firstElement.y2 - firstElement.y1) / 5;
  var w2 = (lastElement.y2 - lastElement.y1) / 5;
  const padding = HighlightOptions.padding;

  var ctx = canvas.getContext("2d");
  ctx.beginPath();
  ctx.fillStyle = HighlightOptions.bookends;

  //First bookend
  ctx.fillRect(
    firstElement.x1 - w1,
    firstElement.y1 - padding,
    w1,
    firstElement.y2 - firstElement.y1 + padding * 2
  );
  ctx.arc(
    firstElement.x1 - w1 + w1 / 2,
    firstElement.y1 - w1 * 1.5,
    w1 * 1.5,
    0,
    2 * Math.PI
  );
  ctx.fill();

  //Last bookend
  ctx.fillRect(
    lastElement.x2,
    lastElement.y1 - padding,
    w2,
    lastElement.y2 - lastElement.y1 + padding * 2
  );
  ctx.arc(
    lastElement.x2 + w2 / 2,
    lastElement.y2 + w2 * 1.5,
    w2 * 1.5,
    0,
    2 * Math.PI
  );
  ctx.fill();

  ctx.closePath();
};
const endTag = (firstElement: any, lastElement: any, canvas: any) => {
  //Thickness of bookends is based on the height of the text on the appropriate element
  var w1 = (firstElement.y2 - firstElement.y1) / 5;
  var w2 = (lastElement.y2 - lastElement.y1) / 5;
  const padding = HighlightOptions.padding;

  var ctx = canvas.getContext("2d");
  ctx.beginPath();
  ctx.fillStyle = HighlightOptions.tagEnd;

  //First bookend
  ctx.fillRect(
    firstElement.x1 - w1,
    firstElement.y1 - padding,
    w1,
    firstElement.y2 - firstElement.y1 + padding * 2
  );
  ctx.fill();

  //Last bookend
  ctx.fillRect(
    lastElement.x2,
    lastElement.y1 - padding,
    w2,
    lastElement.y2 - lastElement.y1 + padding * 2
  );

  ctx.fill();

  ctx.closePath();
};
