import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../components/shared/icon/icon";
import FormattedMessage from "../../components/shared/formatted-message/formatted-message";
import Tooltip, {
  TooltipPosition,
} from "../../components/shared/tooltip/tooltip";
import moment from "moment";
import { RootStore } from "../../services/store.service";
import classNames from "classnames";
import UnpublishSearch from "components/searchlist-actions/unpublish-search";
import { PublishedSearchPayload } from "services/published-searchlist/published-searchlist.model";
import {
  setCurrentSearchItem,
  unpublishSearchItem,
} from "services/published-searchlist/published-searchlist.service";
import PublishSearch from "components/searchlist-actions/publish-search";
import {
  addNewTopic,
  editTopic,
  resetTopic,
  savedSearchResult,
  saveSearch,
} from "services/searchlist/searchlist.service";
import { publishSearchType } from "utils/constants";
import { useLocation } from "react-router";
import { publishedSearchesPath } from "../../utils/constants";
import Table, { TableRowItem } from "components/shared/table/table";

const PublishedSearchesTable = () => {
  const BLOCK = "published-searches-table";
  const dispatch = useDispatch();
  const location = useLocation();
  const publishedSearchlistState = useSelector(
    (state: RootStore) => state.publishedSearchlist
  );
  const savedSearchListState = useSelector(
    (state: RootStore) => state.searchlist
  );
  const [publishSearch, setPublishSearch] = useState<boolean>(false);
  const [topicPayload, setTopicPayload] = useState<any>({});
  // const [sortInfo, setSortInfo] = useState<any>({
  //   sortOrder: null,
  //   sortBy: null,
  // });
  const [showUnpublish, setShowUnpublish] = useState(false);
  let savedSearch = publishedSearchlistState.results;

  useEffect(() => {
    if (
      savedSearchListState.newTopic &&
      location.pathname === publishedSearchesPath
    ) {
      let payload = {
        savedSearchId: topicPayload.savedSearchId,
        name: topicPayload.name,
        description: topicPayload.description,
        searchCriteria: {},
        topic: {
          topicId: savedSearchListState.newTopic.topicId,
        },
        hasPublished: true,
        type: "publish",
        location: publishSearchType.publishedSearches,
      };

      dispatch(saveSearch(payload));
      dispatch(resetTopic());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSearchListState.newTopic]);

  const publishSearchHandler = (value: any, selectedTopic: any) => {
    let payload: any;
    if (selectedTopic && selectedTopic.topicId) {
      payload = {
        savedSearchId: savedSearchListState.savedSearch.savedSearchId,
        name: value.name,
        description: value.description,
        searchCriteria: {},
        topic: {
          topicId: selectedTopic.topicId,
        },
        hasPublished: true,
        type: "publish",
        location: publishSearchType.publishedSearches,
      };
      setTopicPayload(payload);
      let EditPayload = {
        topicId: selectedTopic.topicId,
        topicName: selectedTopic.topicName,
        topicDescription: selectedTopic.topicDescription,
      };
      dispatch(editTopic(EditPayload));
    } else {
      payload = {
        savedSearchId: savedSearchListState.savedSearch.savedSearchId,
        name: value.name,
        description: value.description,
        searchCriteria: {},
        hasPublished: true,
        type: "publish",
        location: publishSearchType.publishedSearches,
      };
      setTopicPayload(payload);
      let newTopicPayload = {
        topicName: selectedTopic.topicName,
        topicDescription: selectedTopic.topicDescription,
      };
      dispatch(addNewTopic(newTopicPayload));
    }
  };

  const renderHeaderItemContents = (item: any) => {
    //let icon;
    const contents = (
      <>
        {item.text && (
          <div
            className={`${BLOCK}__header-text`}
            data-test={`${item.text}`}
            data-testid={`${item.text}`}
          >
            {item.isFormattedText && item.isTooltip ? (
              <div className={`${BLOCK}__header-tooltip`}>
                <FormattedMessage id={item.text} />
                <div className={`${BLOCK}__info-icon`}>
                  <Tooltip position={TooltipPosition.left}>
                    <FormattedMessage id="my-searches-auto-delete-message" />
                  </Tooltip>
                  <Icon name="information" height={18} width={18} />
                </div>
              </div>
            ) : (
              <FormattedMessage id={item.text} />
            )}
          </div>
        )}
      </>
    );

    return <div className={`${BLOCK}__header-inner-content`}>{contents}</div>;

    // Future Addition of Sorting in Published Searches Page
    // if (item.sortId) {
    //   const className =
    //     sortInfo.sortBy === item.sortId
    //       ? `${BLOCK}__header-inner-content ${BLOCK}__header-button ${BLOCK}__header-button--sorted`
    //       : `${BLOCK}__header-inner-content ${BLOCK}__header-button`;
    //   if (
    //     sortInfo.sortBy === item.sortId &&
    //     sortInfo.sortOrder &&
    //     sortInfo.sortOrder === "asc"
    //   ) {
    //     icon = (
    //       <Icon
    //         name="sort-ascending"
    //         width={16}
    //         height={16}
    //         className={`${BLOCK}__sort-icon`}
    //       />
    //     );
    //   } else if (
    //     sortInfo.sortBy === item.sortId &&
    //     sortInfo.sortOrder &&
    //     sortInfo.sortOrder === "desc"
    //   ) {
    //     icon = (
    //       <Icon
    //         name="sort-descending"
    //         width={16}
    //         height={16}
    //         className={`${BLOCK}__sort-icon`}
    //       />
    //     );
    //   }

    //   return (
    //     <button
    //       className={className}
    //       data-test="sort-button"
    //       onClick={() => onSort(item.sortId)}
    //     >
    //       {icon}
    //       {contents}
    //     </button>
    //   );
    // } else {
    //   return <div className={`${BLOCK}__header-inner-content`}>{contents}</div>;
    // }
  };

  // Future Addition of sorting on Published Searches Page
  // const onSort = (sortId: string) => {
  //   let newSortOrder = sortInfo.sortOrder;

  //   if (sortId === sortInfo.sortBy) {
  //     newSortOrder =
  //       sortInfo.sortOrder === SORT_ORDERS.DESC
  //         ? SORT_ORDERS.ASC
  //         : SORT_ORDERS.DESC;
  //   } else {
  //     newSortOrder = SORT_ORDERS.DESC;
  //   }

  //   const payload = {
  //     ...DEFAULT_SAVED_SEARCHES_PAYLOAD,
  //     sortBy: sortId,
  //     sortOrder: newSortOrder,
  //   };
  //   setSortInfo({ sortBy: sortId, sortOrder: newSortOrder });
  //   dispatch(loadSavedSearchListSort(payload));
  // };

  const getDescription = (description: any) => {
    return (
      <div className={`${BLOCK}__descriptionTooltip`}>
        <span>
          {description.length > 60
            ? description.slice(0, 60) + "..."
            : description}

          {description.length > 60 ? (
            <Tooltip position={TooltipPosition.right}>{description}</Tooltip>
          ) : (
            ""
          )}
        </span>
      </div>
    );
  };

  const getSharedBy = (firstName: any, lastName: any) => {
    let fullName: any = `${lastName}, ${firstName}`;
    return <span className={`${BLOCK}__sharedByTooltip`}>{fullName}</span>;
  };

  const onUnpublishSearchHandler = (payload: {
    payload: PublishedSearchPayload;
    type: string;
  }) => {
    dispatch(unpublishSearchItem(payload));
    setShowUnpublish(false);
  };

  const getTableHeaders = () => [
    {
      text: "published-searches-name",
      isFormattedText: true,
    },
    {
      text: "published-searches-description",
      isFormattedText: true,
    },
    {
      text: "published-searches-published-by",
      isFormattedText: true,
    },
    {
      text: "published-searches-publish-date",
      isFormattedText: true,
      sortId: "lastexecuteddate",
    },
    {
      text: "published-searches-status",
      isFormattedText: true,
    },
    {
      text: "published-searches-actions",
      isFormattedText: true,
    },
  ];

  const getTableRows = () => {
    return savedSearch?.map((item: any, index: number) => {
      const actions = [
        {
          name: "unpublish",
          iconName: "unpublish",
          onClick: () => {
            dispatch(
              setCurrentSearchItem({
                item,
                type: "publishedSearches",
              })
            );
            setShowUnpublish(true);
          },
          id: "unpublish",
        },

        {
          name: "edit",
          iconName: "edit-search",
          onClick: () => {
            dispatch(savedSearchResult(item));
            setPublishSearch(true);
          },
          id: "edit",
        },
      ];

      let tableRows = {
        id: item.savedSearchId,
        key: `${item.savedSearchId}-${index}`,
        className: `${BLOCK}__row `,
        onClick: () => {},
        columnItems: [
          {
            text:
              item.name
          },
          {
            text: item.description || (
              <Icon name="chevron-minus" width={20} height={20} />
            ),
          },
          {
            text: item.publishedBy
              ? getSharedBy(
                  item.publishedBy.firstName,
                  item.publishedBy.lastName
                )
              : "",
          },
          {
            text: item.publishedDate ? (
              moment(item.publishedDate).format("YYYY-MM-DD")
            ) : (
              <Icon name="chevron-minus" width={20} height={20} />
            ),
          },
          {
            text: item.hasPublished ? "Published" : "Unpublished",
          },
        ],
      } as TableRowItem;

      tableRows.columnItems.push({
        className: `${BLOCK}__column-content ${BLOCK}__actions`,
        onClick: (e: any) => {
          e.stopPropagation();
        },
        renderContent: () =>
          actions.map((action) => (
            <button
              data-testid={`${action.id}-button`}
              key={action.name}
              className={`${BLOCK}__action-icon ${BLOCK}__${action.name}`}
              onClick={(e: any) => {
                action.onClick();
              }}
            >
              <Tooltip position={TooltipPosition.top}>
                <FormattedMessage id={action.id} />
              </Tooltip>
              <Icon name={action.iconName} height={24} width={24} />
            </button>
          )),
      });

      return tableRows;
    });
  };

  return (
    <div className={`${BLOCK}__table-container`}>
      <div className={`${BLOCK}__data`}>
        <div className={`${BLOCK}__results-count`}>
          <FormattedMessage
            id={
              savedSearch?.length === 1
                ? "saved-searches-count-singular"
                : "saved-searches-count-plural"
            }
            values={{ count: savedSearch?.length }}
          />
        </div>
      </div>
      <div className={classNames(`${BLOCK}__table-wrapper`)}>
        <Table
          className={`${BLOCK}__table-inner-wrapper`}
          innerClassName={`${BLOCK}__table`}
          headerItems={getTableHeaders()}
          rowItems={getTableRows()}
          onSort={() => {}}
          sortBy={""}
          sortOrder={""}
          dataTest="published-searches-component"
        />
      </div>
      {showUnpublish && (
        <UnpublishSearch
          setShowModal={setShowUnpublish}
          unpublishSearch={onUnpublishSearchHandler}
        />
      )}
      {publishSearch && (
        <PublishSearch
          setShowModal={setPublishSearch}
          publishSearch={publishSearchHandler}
          type={publishSearchType.publishedSearches}
        />
      )}
    </div>
  );
};

export default PublishedSearchesTable;
