import { useEffect } from "react";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";
import { showMainTooltip } from "services/commons.service";
import {
  VENDOR_DETAILS,
  constantColorCodes,
  cookiesToIgnore,
  secretKey,
} from "./constants";
import { sanitize } from "dompurify";
import React from "react";
import data from "components/shared/formatted-message/messages.json";
import { CompanyFilter } from "services/search/filters.model";
import {
  GlobalCompany,
  GlobalCompanyGroup,
  GlobalCompanyMapping,
} from "services/insights/insights.model";
import { escapeRegExp } from "lodash";
import moment from "moment";
import { KJUR } from "jsrsasign";
import { SpeedometerChartDataset } from "services/dashboard/dashboard.model";

export const isNumeric = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const mod = (n: number, m: number) => ((n % m) + m) % m;

export const useClickOutside = (ref: any, fn: any) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) fn();
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export const dollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getMarketCap = (cap: number) => {
  let currencyFormmater = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: "compact",
    maximumSignificantDigits: 6,
  });

  return currencyFormmater.format(cap);
};

export const compareByDisplayName = (field1: any, field2: any) => {
  if (field1.displayName < field2.displayName) {
    return -1;
  } else if (field1.displayName > field2.displayName) {
    return 1;
  } else {
    return 0;
  }
};

export const getLegendTooltip = (data: any, tooltip: string) => {
  return setHyperLinks(
    tooltip.replace(/\{(.*?)\}/g, (a, token) => {
      return isNumeric(data[token])
        ? parseFloat(data[token] ?? 0).toLocaleString()
        : data[token] ?? "";
    })
  );
};

export const setHyperLinks = (data: string) => {
  const links = data && data.match(/<link>(.*?)<\/link>/g);

  return links
    ? links.reduce((prev, curr) => {
        const relativeLink: string = curr
          .replace("<link>", "")
          .startsWith("http")
          ? ""
          : "//";
        return prev.replace(
          /<link>(.*?)<\/link>/g,
          `<a target="_blank" href="${relativeLink}$1">$1</a>`
        );
      }, data)
    : data;
};

export const formatArcTooltipData = (arcData: SpeedometerChartDataset | undefined): SpeedometerChartDataset | undefined => {
  if (!arcData || arcData.tooltip === undefined) return undefined;

  const [value, ...companyNameParts] = arcData.tooltip.split(' ');
  const companyName = companyNameParts.join(' ');
  const newTooltip = `${companyName} (${value})`;

  return {
    ...arcData,
    tooltip: newTooltip,
  };
};

export const normalizeKey = (key: string) =>
  key.charAt(0).toLowerCase() + key.slice(1);

export const capitalizeFirst = (key: string) =>
  key.charAt(0).toUpperCase() + key.slice(1);

export const trimStartFunc = (value: any) => {
  if (!value) return value;
  return value.replace(/^\s+/g, "");
};

export const handleMouseLeave = (
  e: any,
  dispatch: any,
  isOverTooltip: boolean
) => {
  if (isOverTooltip) return;
  dispatch(
    showMainTooltip({
      children: null,
      customPosition: null,
      position: null,
      arrowPosition: null,
      elementDimensions: null,
      className: null,
      width: null,
    })
  );
};

export const handleMouseEnter = (
  e: any,
  elementTag: string,
  children: any,
  className: string,
  dispatch: any,
  customWidth: number | null = null,
  position = MainTooltipPosition.BottomMiddle,
  executeMouseLeaveEvent: boolean = true
) => {
  const element = e.target.closest(elementTag);

  if (!element) return;
  dispatch(
    showMainTooltip({
      children: children,
      position: position,
      elementDimensions: element.getBoundingClientRect(),
      executeMouseLeaveEvent: executeMouseLeaveEvent,
    })
  );
};

export const getUserInitials = (user: any) =>
  (user.firstName ? user.firstName[0].toUpperCase() : "") +
  (user.lastName ? user.lastName[0].toUpperCase() : "");

export const expireCookies = () => {
  // Expiring the cookies
  document.cookie.split(";").forEach((c) => {
    // Ignore the cookies related to the cookie notice
    if (!cookiesToIgnore.some((ignore) => c.includes(sanitize(ignore))))
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

export const FormattedMessageString = (
  id: string,
  values?: { [key: string]: React.ReactNode }
): string => {
  const key = id in data ? id : "default";

  let formattedText = (data as any)[key].split(/\{(.*?)\}/i);

  if (values) {
    formattedText = formattedText.map((part: string, index: number) => {
      if (values.hasOwnProperty(part)) {
        return values[part];
      }
      return part;
    });
  }

  return formattedText ?? "";
};

export const getVendorCollection = (
  company: CompanyFilter | GlobalCompany
): {
  priority: number;
  vendorId: number;
  vendorName: string;
  color: string;
}[] => {
  let vendors = [];
  if (company.isCDPCompany) vendors.push(VENDOR_DETAILS.cdp);
  if (company.isMSCICompany) vendors.push(VENDOR_DETAILS.msci);
  if (company.isSNPCompany) vendors.push(VENDOR_DETAILS.snp);
  if (company.isRaterRankerCompany) vendors.push(VENDOR_DETAILS.sec);
  vendors.sort(
    (
      a: {
        priority: number;
        vendorId: number;
        vendorName: string;
        color: string;
      },
      b: {
        priority: number;
        vendorId: number;
        vendorName: string;
        color: string;
      }
    ) => a.priority - b.priority
  );
  return vendors;
};

export const getHighlightedText = (option: string, contentText: string) => {
  const replace = new RegExp(`(${escapeRegExp(contentText)})`, "i");
  return option
    .split(replace)
    .map((subtext) =>
      replace.test(subtext) ? `<strong>${subtext}</strong>` : subtext
    )
    .join("");
};

export const convertDateFormatToMMYYY = (date: Date) => {
  // Using moment.js to convert date format
  const convertedDate = moment(date, "MM/DD/YYYY").format("MM/YYYY");
  return convertedDate;
};

export const getQuarters = (year: number): number[] => {
  const currentTime = moment();
  const currentQuarter =
    year < currentTime.year()
      ? moment(`${year}-12-01`).utc().quarter()
      : currentTime.utc().quarter();
  return Array.from({ length: currentQuarter }, (_, i) => currentQuarter - i);
};

export const getNQuarters = (number: number) => {
  return Array.from({ length: number }, (_, i) => {
    let momentObj = moment().subtract(i, "Q");
    let name = momentObj.format("[Q]Q - Y");
    let year = momentObj.format("Y");
    let quarter = momentObj.format("Q");
    return {
      name,
      year,
      quarter,
    };
  });
};

export const getQuarterRange = (quarter: number, year: number) => {
  const start = moment()
    .year(year)
    .quarter(quarter)
    .startOf("quarter")
    .format("DD/MM/YYYY");
  const end = moment()
    .year(year)
    .quarter(quarter)
    .endOf("quarter")
    .format("DD/MM/YYYY");
  return `${start}-${end}`;
};

export const getBenchmarkCompanyFromGlobalCompanyGroup = (
  group: GlobalCompanyGroup,
  benchmarkId: number,
  displayOrder: number
) => {
  let defaultCompany = group.globalCompanyMappingDetails.find(
    (c: GlobalCompanyMapping) => c.isDefault
  );

  return {
    benchmarkId: benchmarkId,
    globalCompanyId: defaultCompany ? defaultCompany.globalCompanyId : 0,
    isBaseCompany: group.isBaseMapping ?? false,
    displayOrder: displayOrder ?? 0,
    company: {
      companyId: defaultCompany ? defaultCompany.globalCompanyId : 0,
      companyName: group.globalCompanyMappingName ?? "",
      globalCompanyId: defaultCompany ? defaultCompany.globalCompanyId : 0,
    },
    globalCompany: {
      companyId: defaultCompany ? defaultCompany.globalCompanyId : 0,
      companyName: group.globalCompanyMappingName ?? "",
      globalCompanyId: defaultCompany ? defaultCompany.globalCompanyId : 0,
    },
    globalCompanyMapping: group,
  };
};

export const getKeyByValue = (object: any, value: any) =>
  Object.keys(object).find((key) => object[key] === value);

export const getFormattedNumber = (
  num: any,
  minimumFractionDigits: number = 0,
  maximumFractionDigits: number = 0,
  useGrouping: boolean = true
) => {
  const numberFormatter1 = new Intl.NumberFormat("en", {
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits,
    useGrouping: true,
  });

  return Number.isNaN(num) ? num : numberFormatter1.format(+num);
};

export const checkAllValueMissing = (metric: any) => {
  if (
    metric.every((value: []) =>
      value.every((val: string) => val?.trim() === "")
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkOtherMetricMissing = (otherMetric: any) => {
  if (
    otherMetric.every((mrtric: string[]) =>
      mrtric.every((value: string) => value === "")
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const getInsightsDetailPeerViewColorCode = (
  colors: string[],
  index: number
) => {
  return colors.length > 0 ? colors[index] : constantColorCodes[index];
};

//Get section id based on section title
export const getSectionId = (sectionTitle: string) => {
  switch (sectionTitle) {
    case "Environmental":
      return 1;
    case "Social":
      return 2;
    case "Governance":
      return 3;
    default:
      return 0;
  }
};

const isNumberAxis = (array: any[]) =>
  // eslint-disable-next-line
  array.every((item) => item == parseInt(item));
export const getTooltipData = (axis: any, tooltipData: any) => {
  if (isNumberAxis(axis.primary)) {
    return axis?.primary.length
      ? tooltipData.legendData.toFixed(1)
      : tooltipData.legendData;
  }

  return axis?.primary.length && tooltipData.legendData <= axis.primary.length
    ? axis.primary[tooltipData.legendData - 1]
    : tooltipData.legendData ?? 0;
};

export const getMarginBasedOnMax = (maxValue: number) => {
  if (maxValue < 100) return 25;
  if (maxValue < 1000) return 30;
  if (maxValue < 10000) return 42;
  if (maxValue < 100000) return 45;
  if (maxValue < 1000000) return 55;
  if (maxValue < 10000000) return 60;
  if (maxValue < 100000000) return 65;
  if (maxValue < 1000000000) return 75;

  return 80;
};

export const getHexCode = (text: string) => {
  let hex = "";
  for (let i = 0; i < text.length; i++) {
    hex += text.charCodeAt(i).toString(16);
  }
  return hex;
};

export const verifySignatureAndGetDecodedJwt = (token: string) => {
  const isValid = KJUR.jws.JWS.verify(
    window.atob(token),
    {
      hex: getHexCode(secretKey),
    },
    ["HS256"]
  );
  if (isValid) {
    const decodedToken = KJUR.jws.JWS.parse(window.atob(token)).payloadPP;
    return decodedToken.replace(/"/g, "");
  } else {
    return "";
  }
};
