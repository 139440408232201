import { createAction } from "../../utils/redux.utils";
import { Dispatch } from "redux";
import {
  CustomMetricsData,
  DashboardModel,
  DashboardState,
  EditBenchMarkData,
} from "./dashboard.model";
import {
  FILTER_OPTIONS,
  SIDE_CUSTOM_TYPE_SAVE,
  UPDATE_VALUE_TYPE,
} from "utils/constants";
import * as api from "./dashboard.api";
import { CompanyFilter } from "services/search/filters.model";

export const ACTIONS = {
  DASHBOARD_LOADING: "ESG/UI/DASHBOARD_LOADING",
  DASHBOARD_SHOW_SIDE_DASHBOARD: "ESG/UI/DASHBOARD_SHOW_SIDE_DASHBOARD",
  DASHBOARD_SHOW_EDIT_BENCHMARK: "ESG/UI/ DASHBOARD_SHOW_EDIT_BENCHMARK",
  DASHBOARD_SIDE_DASHBOARD_LOADING: "ESG/UI/DASHBOARD_SIDE_DASHBOARD_LOADING",
  DASHBOARD_SIDE_DASHBOARD_CHECKBOX_SELECT:
    "ESG/UI/DASHBOARD_SIDE_DASHBOARD_CHECKBOX_SELECT",
  DASHBOARD_SIDE_DASHBOARD_CHECKBOX_GROUP_SELECT:
    "ESG/UI/DASHBOARD_SIDE_DASHBOARD_CHECKBOX_GROUP_SELECT",
  DASHBOARD_SIDE_DASHBOARD_CHECKBOX_DATA:
    "ESG/UI/DASHBOARD_SIDE_DASHBOARD_CHECKBOX_DATA",
  DASHBOARD_LOADING_FILTER: "ESG/UI/DASHBOARD_LOADING_FILTER",
  DASHBOARD_LOAD_COMPANY_FILTER: "ESG/UI/DASHBOARD_LOAD_COMPANY_FILTER",
  SEARCH_ERROR: "ESG/UI/SEARCH_ERROR",
  DASHBOARD_SET_COMPANY_FILTER: "ESG/UI/DASHBOARD_SET_COMPANY_FILTER",
  SEARCH_SET_PEER_COMPANY_FILTER: "ESG/UI/SEARCH_SET_PEER_COMPANY_FILTER",
  DASHBOARD_LOAD_PEER_COMPANIES: "ESG/UI/DASHBOARD_LOAD_PEER_COMPANIES",
  DASHBOARD_LOAD_SUGGESTED_PEER_COMPANIES:
    "ESG/UI/DASHBOARD_LOAD_SUGGESTED_PEER_COMPANIES",
  DASHBOARD_SET_SELECTED_ITEMS: "ESG/UI/ DASHBOARD_SET_SELECTED_ITEMS",
  DASHBOARD_SET_VISUALIZATIONS: "ESG/UI/ DASHBOARD_SET_VISUALIZATIONS",
  DASHBOARD_RESET_BENCHMARK: "ESG/UI/ DASHBOARD_RESET_BENCHMARK",
  DASHBOARD_SET_APPLY_BENCHMARK: "ESG/UI/DASHBOARD_SET_APPLY_BENCHMARK",
  DASHBOARD_SET_EDIT_BENCHMARK_DATA: "ESG/UI/DASHBOARD_SET_EDIT_BENCHMARK_DATA",
  DASHBOARD_DELETE_CUSTOM_BENCHMARK_POP_UP:
    "ESG/UI/DASHBOARD_DELETE_CUSTOM_BENCHMARK_POP_UP",
  DASHBOARD_REFRESH_DASHBOARD: "ESG/UI/DASHBOARD_REFRESH_DASHBOARD",
  DASHBOARD_EXECUTE_SCROLL_DUPLICATED_TILE:
    "ESG/UI/DASHBOARD_EXECUTE_SCROLL_DUPLICATED_TILE",
  DASHBOARD_FILTER_TRACK: "ESG/UI/DASHBOARD_FILTER_TRACK",
  DASHBOARD_CHANGE_STYLE_TRACK: "ESG/UI/DASHBOARD_CHANGE_STYLE_TRACK",
  DASHBOARD_MAXIMIZE_TRACK: "ESG/UI/DASHBOARD_MAXIMIZE_TRACK",
  DASHBOARD_EXPORT_TRACK_EXCEL: "ESG/UI/DASHBOARD_EXPORT_TRACK_EXCEL",
  DASHBOARD_EXPORT_TRACK_PDF: "ESG/UI/DASHBOARD_EXPORT_TRACK_PDF",
  DASHBOARD_EXPORTALL_TRACK_EXCEL: "ESG/UI/DASHBOARD_EXPORTALL_TRACK_EXCEL",
  DASHBOARD_EXPORTALL_TRACK_PDF: "ESG/UI/DASHBOARD_EXPORTALL_TRACK_PDF",
  DASHBOARD_CREATECUSTOM_BENCHMARK_TRACK_BUTTON:
    "ESG/UI/DASHBOARD_CREATECUSTOM_BENCHMARK_TRACK_BUTTON",
  DASHBOARD_CREATECUSTOM_BENCHMARK_TRACK_TILE:
    "ESG/UI/DASHBOARD_CREATECUSTOM_BENCHMARK_TRACK_TILEf",
  DASHBOARD_FOCUS_BENCHMARK: "ESG/UI/DASHBOARD_FOCUS_BENCHMARK",
  DASHBOARD_SET_SELECTED_PEER_GROUP_ITEMS:
    "ESG/UI/DASHBOARD_SET_SELECTED_PEER_GROUP_ITEMS",
};

export const setLoading = createAction(ACTIONS.DASHBOARD_LOADING);
export const showSideDashboard = createAction(
  ACTIONS.DASHBOARD_SHOW_SIDE_DASHBOARD
);

export const applyBenchmark = createAction(
  ACTIONS.DASHBOARD_SET_APPLY_BENCHMARK
);

export const setEditBenchmark = createAction(
  ACTIONS.DASHBOARD_SET_EDIT_BENCHMARK_DATA
);
export const showEditBenchmark = createAction(
  ACTIONS.DASHBOARD_SHOW_EDIT_BENCHMARK
);
export const sideDashboardLoading = createAction(
  ACTIONS.DASHBOARD_SIDE_DASHBOARD_LOADING
);
export const selectCheckboxIds = createAction(
  ACTIONS.DASHBOARD_SIDE_DASHBOARD_CHECKBOX_SELECT
);
export const selectCheckboxGroupIds = createAction(
  ACTIONS.DASHBOARD_SIDE_DASHBOARD_CHECKBOX_GROUP_SELECT
);
export const setCheckboxDataItems = createAction(
  ACTIONS.DASHBOARD_SIDE_DASHBOARD_CHECKBOX_DATA
);
export const searchError = createAction(ACTIONS.SEARCH_ERROR);
export const searchLoadingFilter = createAction(
  ACTIONS.DASHBOARD_LOADING_FILTER
);
export const loadPeerCompanies = createAction(
  ACTIONS.DASHBOARD_LOAD_PEER_COMPANIES
);
export const loadSuggestedPeerCompanies = createAction(
  ACTIONS.DASHBOARD_LOAD_SUGGESTED_PEER_COMPANIES
);
export const searchLoadCompanyFilter = createAction(
  ACTIONS.DASHBOARD_LOAD_COMPANY_FILTER
);
export const searchSetCompanyFilter = createAction(
  ACTIONS.DASHBOARD_SET_COMPANY_FILTER
);

export const searchSetPeerCompanyFilter = createAction(
  ACTIONS.SEARCH_SET_PEER_COMPANY_FILTER
);

export const updateSelectedItems = createAction(
  ACTIONS.DASHBOARD_SET_SELECTED_ITEMS
);

export const updateVisualizations = createAction(
  ACTIONS.DASHBOARD_SET_VISUALIZATIONS
);

export const resetBenchmark = createAction(ACTIONS.DASHBOARD_RESET_BENCHMARK);

export const deleteCustomBenchmarkPopUp = createAction(
  ACTIONS.DASHBOARD_DELETE_CUSTOM_BENCHMARK_POP_UP
);
export const refreshDashboard = createAction(
  ACTIONS.DASHBOARD_REFRESH_DASHBOARD
);

export const executeScrollDuplicatedTile = createAction(
  ACTIONS.DASHBOARD_EXECUTE_SCROLL_DUPLICATED_TILE
);
export const filterGraphTrack = createAction(ACTIONS.DASHBOARD_FILTER_TRACK);
export const changeGraphStyleTrack = createAction(
  ACTIONS.DASHBOARD_CHANGE_STYLE_TRACK
);
export const maximizeGraphTrack = createAction(
  ACTIONS.DASHBOARD_MAXIMIZE_TRACK
);
export const exportGraphTrackExcel = createAction(
  ACTIONS.DASHBOARD_EXPORT_TRACK_EXCEL
);
export const exportGraphTrackPdf = createAction(
  ACTIONS.DASHBOARD_EXPORT_TRACK_PDF
);
export const exportDashboardTrackExcel = createAction(
  ACTIONS.DASHBOARD_EXPORTALL_TRACK_EXCEL
);
export const exportDashboardTrackPDF = createAction(
  ACTIONS.DASHBOARD_EXPORTALL_TRACK_PDF
);
export const createCustomBenchmarkTrackButton = createAction(
  ACTIONS.DASHBOARD_CREATECUSTOM_BENCHMARK_TRACK_BUTTON
);
export const createCustomBenchmarkTrackTile = createAction(
  ACTIONS.DASHBOARD_CREATECUSTOM_BENCHMARK_TRACK_TILE
);
export const focusBenchmark = createAction(ACTIONS.DASHBOARD_FOCUS_BENCHMARK);

export const updateSelectedPeerGroupItems = createAction(
  ACTIONS.DASHBOARD_SET_SELECTED_PEER_GROUP_ITEMS
);

export const showSidedashboardTab =
  (payload: boolean) => async (dispatch: Dispatch<any>) =>
    dispatch(showSideDashboard(payload));

export const setSelectedCheckboxItemIds =
  (selectedCheckboxIds: number[]) => async (dispatch: Dispatch<any>) => {
    dispatch(selectCheckboxIds(selectedCheckboxIds));
  };

export const setSelectedCheckboxGroupItemIds =
  (selectedCheckboxIds: number[]) => async (dispatch: Dispatch<any>) => {
    dispatch(selectCheckboxGroupIds(selectedCheckboxIds));
  };

export const applyCustomBenchmark =
  (payload: any, type: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(sideDashboardLoading(true));
      if (type === "save") {
        const savedBenchmark = await api.applyCustomBenchmarkData(payload);
        dispatch(focusBenchmark(savedBenchmark.data));
        dispatch(loadVisualizations());
      } else {
        await api.editCustomBenchmarkData(payload);
        dispatch(loadVisualizations("applyCustom"));
      }

      dispatch(sideDashboardLoading(false));
    } catch (e) {
      dispatch(sideDashboardLoading(false));
    }
  };

export const fetchCheckboxData = () => async (dispatch: Dispatch<any>) => {
  try {
    // fetch the checkbox data from backend
    dispatch(sideDashboardLoading(true));

    const res = await api.fetchCustomBenchmarkMetics(1);
    // set the data in the dispatcher
    // Currently setting up empty array
    dispatch(setCheckboxDataItems(res.data));
    dispatch(sideDashboardLoading(false));
  } catch (e) {
    dispatch(setCheckboxDataItems([]));
    dispatch(sideDashboardLoading(false));
  }
};

export const loadEditBenchmarkData =
  (payload: EditBenchMarkData) => async (dispatch: Dispatch<any>) => {
    try {
      // fetch the checkbox data from backend
      dispatch(showSidedashboardTab(false));
      dispatch(resetBenchmark(SIDE_CUSTOM_TYPE_SAVE));
      dispatch(showEditBenchmark(true));
      dispatch(sideDashboardLoading(true));

      // Setting up Baseline Company Filter
      dispatch(searchSetCompanyFilter([payload.companyFilter]));
      dispatch(searchLoadCompanyFilter([payload.companyFilter]));

      // Setting up PeerCompany Filter
      dispatch(setEditBenchmark(payload));

      // Setting up Metrics Data and Selection
      const res = await api.fetchCustomBenchmarkMetics(1);
      res.data.forEach((metric: CustomMetricsData) => {
        if (
          metric.fields &&
          metric.fields.length > 0 &&
          metric.fields.find(
            (field: any) => field.fieldName === payload.metrics[0]
          )
        ) {
          let index = metric.fields.findIndex(
            (field: any) => field.fieldName === payload.metrics[0]
          );
          if (index !== -1) {
            dispatch(selectCheckboxIds([metric.fields[index].fieldId]));
          }
          // return true;
        } else if (
          metric.fieldGroups &&
          metric.fieldGroups.length > 0 &&
          metric.fieldGroups.some((group: any) =>
            group.fields.find((metric: any) =>
              payload.metrics.includes(metric.fieldName)
            )
          )
        ) {
          let index = metric.fieldGroups.findIndex((group: any) =>
            group.fields.find((metric: any) =>
              payload.metrics.includes(metric.fieldName)
            )
          );
          dispatch(
            selectCheckboxGroupIds([metric.fieldGroups[index].fieldGroupId])
          );
        }
      });

      dispatch(setCheckboxDataItems(res.data));

      // Setting up selections in Suggested Peers
      const suggestedPeers = await api.fetchSuggestedPeers(
        payload.companyFilter.cikNumber
      );

      let selectedSuggestedPeers: number[] = [];

      payload.peerCompanyFilter.forEach((peer) => {
        const found = suggestedPeers.data.find(
          (sug: CompanyFilter) => sug.cikNumber === peer.cikNumber
        );
        if (found) {
          selectedSuggestedPeers.push(found.cikNumber);
        }
      });

      dispatch(updateSelectedItems(selectedSuggestedPeers));
      dispatch(loadSuggestedPeerCompanies(suggestedPeers.data));
      let setPeerCompanies: any = [];
      payload.peerCompanyFilter.forEach((peer: any) => {
        if (
          suggestedPeers.data.filter(
            (peers: any) => peers.cikNumber === peer.cikNumber
          ).length > 0
        ) {
          setPeerCompanies.push({ ...peer, isSuggestedPeerCompany: true });
        } else {
          setPeerCompanies.push({ ...peer, isSuggestedPeerCompany: false });
        }
      });
      dispatch(searchSetPeerCompanyFilter(setPeerCompanies));
      dispatch(sideDashboardLoading(false));
    } catch (e) {
      dispatch(setCheckboxDataItems([]));
      dispatch(sideDashboardLoading(false));
    }
  };

export const loadCompanyFilter =
  (searchText: string, isSECFiler: boolean = true) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = searchText
        ? await api.fetchGlobalCompaniesFilter(searchText, isSECFiler)
        : [];
      dispatch(searchLoadCompanyFilter(res.data.globalCompanies));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.COMPANY));
    }
  };

export const getPeerCompanyFilter =
  (searchText: string, isSECFiler: boolean = true) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = searchText
        ? await api.fetchGlobalCompaniesFilter(searchText, isSECFiler)
        : [];
      dispatch(loadPeerCompanies(res.data.globalCompanies));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.COMPANY));
    }
  };

export const loadSuggestedPeerCompanyFilter =
  (cikNumber: number) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchLoadingFilter(FILTER_OPTIONS.COMPANY));
      const res = cikNumber ? await api.fetchSuggestedPeers(cikNumber) : [];
      dispatch(loadSuggestedPeerCompanies(res.data));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.COMPANY));
    }
  };

export const setCompanyFilter =
  (companyFilter: CompanyFilter[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchSetCompanyFilter(companyFilter));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.COMPANY));
    }
  };

export const setPeerCompanyFilter =
  (companyFilter: CompanyFilter[]) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(searchSetPeerCompanyFilter(companyFilter));
    } catch (e) {
      dispatch(searchError(FILTER_OPTIONS.COMPANY));
    }
  };

export const loadVisualizations =
  (location?: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      const visualizations = await api.fetchVisualizationsMetadata();
      dispatch(updateVisualizations(visualizations.benchmarks));
      if (location && location === "applyCustom") {
        dispatch(applyBenchmark(true));
      }
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const updateVisualizationsMetadata =
  (payload: any, updateValue: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      if (updateValue.type === UPDATE_VALUE_TYPE.GRAPH_OPTION)
        payload.benchmarkMetadata.selectedStyleOption = updateValue.updateValue;
      else if (updateValue.type === UPDATE_VALUE_TYPE.FILTER)
        payload.benchmarkMetadata.filters = updateValue.updateValue;
      else if (updateValue.type === UPDATE_VALUE_TYPE.FILTER_INDUSTRY) {
        payload.benchmarkMetadata.filters.values = [];
        payload.benchmarkMetadata.api.selectedIndustry =
          updateValue.updateValue;
        dispatch(
          filterGraphTrack({
            filters: updateValue.updateValue.industryName,
            name: payload.benchmarkTitle,
          })
        );
      }
      await api.updateVisualizationsMetadata(
        payload.benchmarkMetadata,
        payload.benchmarkId
      );
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const editDashboardView =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      await api.editDashboardView(payload);
      dispatch(setLoading(false));
      dispatch(refreshDashboard(true));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const removeCustomBenchmark =
  (customBenchmarkId: number) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      await api.deleteCustomBenchmark(customBenchmarkId);
      dispatch(setLoading(false));
      dispatch(refreshDashboard(true));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const duplicateSavedCustomBenchmark =
  (payload: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading(true));
      await api.duplicateCustomBenchmark(payload);
      dispatch(setLoading(false));
      dispatch(refreshDashboard(true));
      dispatch(executeScrollDuplicatedTile(true));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

const dashboardModel = new DashboardModel();

const dashboardReducer = (
  state: DashboardState = dashboardModel.initialState(),
  action: any
): DashboardState => {
  switch (action.type) {
    case ACTIONS.DASHBOARD_LOADING:
      return dashboardModel.setLoading(action.payload);
    case ACTIONS.DASHBOARD_SIDE_DASHBOARD_LOADING:
      return dashboardModel.setSideDashboardLoading(action.payload);
    case ACTIONS.DASHBOARD_SHOW_SIDE_DASHBOARD:
      return dashboardModel.setShowSideDashboard(action.payload);
    case ACTIONS.DASHBOARD_SHOW_EDIT_BENCHMARK:
      return dashboardModel.setShowEditBenchmark(action.payload);
    case ACTIONS.DASHBOARD_SIDE_DASHBOARD_CHECKBOX_SELECT:
      return dashboardModel.setSelectedCheckboxIds(action.payload);
    case ACTIONS.DASHBOARD_SIDE_DASHBOARD_CHECKBOX_GROUP_SELECT:
      return dashboardModel.setSelectedGroupCheckboxIds(action.payload);
    case ACTIONS.DASHBOARD_SIDE_DASHBOARD_CHECKBOX_DATA:
      return dashboardModel.setCheckboxData(action.payload);
    case ACTIONS.DASHBOARD_LOAD_COMPANY_FILTER:
      return dashboardModel.loadCompanyFilterOptions(action.payload);
    case ACTIONS.DASHBOARD_LOADING_FILTER:
      return dashboardModel.setLoadingFilter(action.payload);
    case ACTIONS.SEARCH_ERROR:
      return dashboardModel.setError(
        { code: 404, message: "Error" },
        action.payload
      );
    case ACTIONS.DASHBOARD_SET_COMPANY_FILTER:
      return dashboardModel.setCompanyFilter(action.payload);
    case ACTIONS.SEARCH_SET_PEER_COMPANY_FILTER:
      return dashboardModel.setPeerCompanyFilter(action.payload);
    case ACTIONS.DASHBOARD_LOAD_PEER_COMPANIES:
      return dashboardModel.loadPeerCompanyFilterOptions(action.payload);
    case ACTIONS.DASHBOARD_SET_SELECTED_ITEMS:
      return dashboardModel.setSelectedItems(action.payload);
    case ACTIONS.DASHBOARD_LOAD_SUGGESTED_PEER_COMPANIES:
      return dashboardModel.loadSuggestedPeerCompanyFilterOptions(
        action.payload
      );
    case ACTIONS.DASHBOARD_SET_VISUALIZATIONS:
      return dashboardModel.setVisualizations(action.payload);
    case ACTIONS.DASHBOARD_RESET_BENCHMARK:
      return dashboardModel.setBenchmark(action.payload);
    case ACTIONS.DASHBOARD_SET_APPLY_BENCHMARK:
      return dashboardModel.setApplyBenchmark(action.payload);
    case ACTIONS.DASHBOARD_SET_EDIT_BENCHMARK_DATA:
      return dashboardModel.setEditBenchmarkData(action.payload);
    case ACTIONS.DASHBOARD_DELETE_CUSTOM_BENCHMARK_POP_UP:
      return dashboardModel.setDeleteCustomBenchMarkPopUp(action.payload);
    case ACTIONS.DASHBOARD_REFRESH_DASHBOARD:
      return dashboardModel.setRefreshDashboard(action.payload);
    case ACTIONS.DASHBOARD_EXECUTE_SCROLL_DUPLICATED_TILE:
      return dashboardModel.setExecuteScrollDuplicatedTile(action.payload);
    case ACTIONS.DASHBOARD_FOCUS_BENCHMARK:
      return dashboardModel.setBenchmarkToFocus(action.payload);
    case ACTIONS.DASHBOARD_SET_SELECTED_PEER_GROUP_ITEMS:
      return dashboardModel.setSelectedPeerGroupItems(action.payload);
    default:
      return state;
  }
};

export default dashboardReducer;
