import moment from "moment";
import React, { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { MaintenanceMessage } from "services/manage-files/manage-files.model";
import FormattedMessage from "../formatted-message/formatted-message";
import {
  CONSTS_FOR_DATE_TIME_PICKER,
  INVALID_CLASS_FOR_DATE_TIME_PICKER,
} from "utils/constants";

type props = {
  startDate: Date;
  setStartDate: Function;
  endDate: Date;
  setEndDate: Function;
  setOnChangeActivatedForDatePicker: Function;
  savedMessage?: MaintenanceMessage;
  setInvalidDate: Function;
};

const DateTimeRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setOnChangeActivatedForDatePicker,
  savedMessage,
  setInvalidDate,
}: props) => {
  const BLOCK = "maintenance-modal";
  const tempDate = new Date(startDate);
  const maxStartDate = new Date(new Date().setMonth(new Date().getMonth() + 3));
  const [maxDate, setMaxDate] = useState<Date>(
    new Date(tempDate.setMonth(tempDate.getMonth() + 3))
  );
  const DISABLE_STARTDATE = savedMessage?.status == 1 ? BLOCK + "__disabled" : "";

  useEffect(() => {
    let temporaryDate = new Date(startDate);
    setMaxDate(new Date(temporaryDate.setMonth(temporaryDate.getMonth() + 3)));
  }, [startDate]);

  useEffect(() => {
    let invalidClass = document.querySelectorAll(
      `${INVALID_CLASS_FOR_DATE_TIME_PICKER}`
    ) as any;

    if (invalidClass.length > 0) {
      setInvalidDate(true);
    } else {
      setInvalidDate(false);
    }
  }, [startDate, endDate]);

  const inputChange = (e: any) => {
    setOnChangeActivatedForDatePicker(true);
    let invalidClass = document.querySelectorAll(
      `${INVALID_CLASS_FOR_DATE_TIME_PICKER}`
    ) as any;

    if (invalidClass.length > 0) {
      setInvalidDate(true);
    } else {
      setInvalidDate(false);
    }

    if (
      e.target.name === CONSTS_FOR_DATE_TIME_PICKER.MINUTE &&
      e.target.value > 59
    ) {
      e.target.value = 59;
    }

    if (
      e.target.name === CONSTS_FOR_DATE_TIME_PICKER.YEAR &&
      e.target.value > maxDate.getFullYear()
    ) {
      e.target.value = maxDate.getFullYear();
    }
    if (
      e.target.name === CONSTS_FOR_DATE_TIME_PICKER.MONTH &&
      e.target.value > maxDate.getMonth() + 1
    ) {
      e.target.value = maxDate.getMonth() + 1;
    }
  };

  const handleInvalidStartDate = (e: any) => {
    let invalidClass = document.querySelectorAll(
      `${INVALID_CLASS_FOR_DATE_TIME_PICKER}`
    ) as any;
    /* istanbul ignore next */
    if (invalidClass.length > 0 && invalidClass[0] === e.target) {
      if (
        (e.target.name === CONSTS_FOR_DATE_TIME_PICKER.MONTH &&
          e.target.value < new Date().getMonth() + 1) ||
        (e.target.name === CONSTS_FOR_DATE_TIME_PICKER.DAY &&
          e.target.value < new Date().getDate()) ||
        (e.target.name === CONSTS_FOR_DATE_TIME_PICKER.YEAR &&
          e.target.value < new Date().getFullYear())
      ) {
        switch (e.target.name) {
          case CONSTS_FOR_DATE_TIME_PICKER.DAY:
            e.target.value = new Date().getDate();
            break;
          case CONSTS_FOR_DATE_TIME_PICKER.YEAR:
            e.target.value = new Date().getFullYear();
            break;
          case CONSTS_FOR_DATE_TIME_PICKER.MONTH:
            e.target.value = new Date().getMonth();
            break;
          default:
            break;
        }
        setStartDate(new Date());
      }

      if (
        (e.target.name === CONSTS_FOR_DATE_TIME_PICKER.MONTH &&
          e.target.value > maxStartDate.getMonth() + 1) ||
        (e.target.name === CONSTS_FOR_DATE_TIME_PICKER.DAY &&
          e.target.value > maxStartDate.getDate()) ||
        (e.target.name === CONSTS_FOR_DATE_TIME_PICKER.YEAR &&
          e.target.value > maxStartDate.getFullYear())
      ) {
        switch (e.target.name) {
          case CONSTS_FOR_DATE_TIME_PICKER.DAY:
            e.target.value = maxStartDate.getDate();
            break;
          case CONSTS_FOR_DATE_TIME_PICKER.YEAR:
            e.target.value = maxStartDate.getFullYear();
            break;
          case CONSTS_FOR_DATE_TIME_PICKER.MONTH:
            e.target.value = maxStartDate.getMonth();
            break;
          default:
            break;
        }
        setStartDate(maxStartDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleInvalidEndDate = (e: any) => {
    let invalidClass = document.querySelectorAll(
      `${INVALID_CLASS_FOR_DATE_TIME_PICKER}`
    ) as any;

    /* istanbul ignore next */
    if (invalidClass.length > 0 && invalidClass[0] === e.target) {
      if (
        (e.target.name === CONSTS_FOR_DATE_TIME_PICKER.MONTH &&
          e.target.value < startDate.getMonth() + 1) ||
        (e.target.name === CONSTS_FOR_DATE_TIME_PICKER.DAY &&
          e.target.value < startDate.getDate()) ||
        (e.target.name === CONSTS_FOR_DATE_TIME_PICKER.YEAR &&
          e.target.value < startDate.getFullYear())
      ) {
        switch (e.target.name) {
          case CONSTS_FOR_DATE_TIME_PICKER.DAY:
            e.target.value = startDate.getDate();
            break;
          case CONSTS_FOR_DATE_TIME_PICKER.YEAR:
            e.target.value = startDate.getFullYear();
            break;
          case CONSTS_FOR_DATE_TIME_PICKER.MONTH:
            e.target.value = startDate.getMonth() + 1;
            break;
          default:
            break;
        }
        setEndDate(startDate);
      }

      if (
        (e.target.name === CONSTS_FOR_DATE_TIME_PICKER.MONTH &&
          e.target.value > new Date(maxDate).getMonth() + 1) ||
        (e.target.name === CONSTS_FOR_DATE_TIME_PICKER.DAY &&
          e.target.value > new Date(maxDate).getDate()) ||
        (e.target.name === CONSTS_FOR_DATE_TIME_PICKER.YEAR &&
          e.target.value > new Date(maxDate).getFullYear())
      ) {
        const tempDate = new Date(maxDate);
        switch (e.target.name) {
          case CONSTS_FOR_DATE_TIME_PICKER.DAY:
            e.target.value = new Date(tempDate).getDate();
            break;
          case CONSTS_FOR_DATE_TIME_PICKER.YEAR:
            e.target.value = new Date(tempDate).getFullYear();
            break;
          case CONSTS_FOR_DATE_TIME_PICKER.MONTH:
            e.target.value = new Date(tempDate).getMonth();
            break;
          default:
            break;
        }
        setEndDate(new Date(tempDate));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleStartDateChange = (e: any) => {
    setOnChangeActivatedForDatePicker(true);
    setStartDate(e);
  };

  const handleEndDateChange = (e: any) => {
    setOnChangeActivatedForDatePicker(true);
    setEndDate(new Date(e));
  };

  const startDateKey = startDate.getTime();
  const endDateKey = endDate.getTime();

  return (
    <div className="calendar-container">
      <div
        className={`calendar-container__calendar ${DISABLE_STARTDATE}`}
        onInput={inputChange}
        onBlur={handleInvalidStartDate}
        data-testid="calender-container-start-date"
      >
        <label
          htmlFor="start-date-time"
          className={`${BLOCK}__label`}
          id={"start-date-time"}
        >
          <FormattedMessage id="manage-files.maintenance.message.start.date" />
        </label>
        <DateTimePicker
          key={startDateKey}
          minDate={
            savedMessage
              ? moment(savedMessage?.startDate).isBefore(new Date())
                ? new Date(savedMessage?.startDate)
                : new Date()
              : new Date()
          }
          maxDate={maxStartDate}
          closeWidgets={true}
          onChange={(e: any) => handleStartDateChange(e)}
          value={startDate}
          disableClock={true}
          clearIcon={null}
          maxDetail="minute"
        />
      </div>
      <div
        className="calendar-container__calendar"
        onInput={inputChange}
        onBlur={handleInvalidEndDate}
        data-testid="calender-container-end-date"
      >
        <label
          htmlFor="end-date-time"
          className={`${BLOCK}__label`}
          id={"end-date-time"}
        >
          <FormattedMessage id="manage-files.maintenance.message.end.date" />
        </label>
        <DateTimePicker
          key={endDateKey}
          minDate={startDate}
          maxDate={maxDate}
          closeWidgets={true}
          onChange={(e: any) => handleEndDateChange(e)}
          value={endDate}
          disableClock={true}
          clearIcon={null}
          maxDetail="minute"
        />
      </div>
    </div>
  );
};

export default DateTimeRangePicker;
