import GhgTargetTile from "components/visualizations/charts/ghg-target.chart";
import {
  TargetData,
  TargetMetric,
  TargetMetricGroup,
} from "components/visualizations/charts/targets-tile-chart";
import { useState } from "react";
import {
  InsightData,
  InsightMetricData,
} from "services/insights/insights.model";
import { INSIGHT_BENCHMARK_TYPE } from "utils/constants";
import TileEmptyContent from "../tile-empty-content";
import { useTileContext } from "../tile.context";

const GhgTarget = () => {
  const {
    dataset,
    originalDataset,
    metadata: {
      benchmarkType,
      isDetailedView,
      benchmarkMetadata,
      associatedMetricGroupTooltips,
    },
    isTableViewActive,
  } = useTileContext();
  const [hasEmptyValues, setHasEmptyValues] = useState<boolean>(false);

  const ENTITY_DEEMED_METRIC_1 = "Science-Based Target Set";
  const ENTITY_DEEMED_METRIC_2 = "Scope of Target";
  const ENTITY_DEEMED_VALUE = "Entity deemed question not applicable";
  const PREFIX = "Target";
  const keywordForNumberTypes = ["TargetYear", "BaseYear", "Percentage"];
  const keywordForFirstMetric = "TargetThatWasActiveInTheReportingYear";

  if (
    dataset.length === 0 ||
    (dataset.length > 0 &&
      dataset.every((d: InsightData) =>
        d.metrics.every(
          (m: InsightMetricData) =>
            !m.metricValue ||
            (m.metricKey.includes(keywordForFirstMetric) && !m.metricValue)
        )
      ))
  )
    return (
      <div>
        <TileEmptyContent />
      </div>
    );

  const metricTypes = {
    TEXT: 1,
    NUMBER: 2,
  };

  const getTargetData = (datasetToMap: InsightData[]): TargetData[] =>
    datasetToMap.map((d: InsightData) => {
      const tmpTargets = benchmarkMetadata.associatedMetrics.map(
        (g: { groupName: string; groupValues: string[] }) => ({
          groupName: g.groupName,
          metrics: g.groupValues.map((gv: string) => {
            let currentMetric = d.metrics.filter(
              (mt: InsightMetricData) => mt.metricKey === gv
            )[0];
            return {
              metricKey: gv,
              metricName: currentMetric.metricName ?? "",
              metricValue: currentMetric.metricValue
                ? currentMetric.metricValue
                : "*",
              metricType: keywordForNumberTypes.every(
                (kw: string) => !gv.includes(kw)
              )
                ? metricTypes.TEXT
                : metricTypes.NUMBER,
              metricTooltip: currentMetric.metricTooltip ?? [],
            };
          }),
        })
      );

      return {
        companyData: { ...d },
        targetGroups: [...tmpTargets],
      };
    });

  const mapChartData = () => {
    let tmpData: TargetData[] = getTargetData(dataset);

    if (benchmarkType === INSIGHT_BENCHMARK_TYPE.PEER_BENCHMARK) {
      tmpData.forEach((td: TargetData) => {
        td.targetGroups = td.targetGroups.filter(
          (tg: TargetMetricGroup, ind: number) =>
            !tg.metrics.every(
              (m: TargetMetric, i: number) =>
                m.metricValue === "*" || (i === 0 && ind !== 0)
            )
        );

        if (!isDetailedView) {
          // Get the max targets based on peer company data
          const peerCompaniesTargets = getTargetData(originalDataset)
            .filter((td) => td.companyData.globalCompanyId > 0)
            .map(
              (td) =>
                td.targetGroups.filter(
                  (tg: TargetMetricGroup, ind: number) =>
                    !tg.metrics.every(
                      (m: TargetMetric, i: number) =>
                        m.metricValue === "*" || (i === 0 && ind !== 0)
                    )
                ).length
            );
          const maxTargetsToShow = Math.max(...peerCompaniesTargets);

          if (td.targetGroups.length > maxTargetsToShow)
            td.targetGroups = td.targetGroups.slice(0, maxTargetsToShow);
        }

        td.targetGroups.forEach((tg: TargetMetricGroup, ind: number) => {
          tg.groupName = `${PREFIX} ${ind + 1}`;
        });

        if (
          td.targetGroups.filter(
            (tgr) => !tgr.metrics.every((met) => met.metricValue !== "*")
          ).length > 0 &&
          !hasEmptyValues
        )
          setHasEmptyValues(true);
      });

      // variable to store the maximum length of targets array
      let maxTargetsLength = 0;
      let longestTargets: TargetMetricGroup[] = [];

      tmpData.forEach((data: TargetData) => {
        const targets = data.targetGroups.filter(
          (target: TargetMetricGroup) => {
            return target.metrics.some((metric: TargetMetric) => {
              return (
                (metric.metricName === ENTITY_DEEMED_METRIC_1 &&
                  metric.metricValue !== "*") ||
                (metric.metricName === ENTITY_DEEMED_METRIC_2 &&
                  metric.metricValue !== "*")
              );
            });
          }
        );
        // get the length of the filtered targets array
        const targetsLength = targets.length;
        if (targetsLength > maxTargetsLength) {
          // update the maxTargetsLength variable if the current targets array length is greater than the current maximum
          maxTargetsLength = targetsLength;
          longestTargets = targets;
        }
      });

      //Inserting * for companies not having Targets
      for (const data of tmpData) {
        for (const longestTarget of longestTargets) {
          const targetExists = data.targetGroups.some(
            (target: TargetMetricGroup) =>
              target.groupName === longestTarget.groupName
          );
          if (!targetExists) {
            const newMetrics = longestTarget.metrics.map(
              (metric: TargetMetric) => ({
                ...metric,
                metricValue: "*",
              })
            );
            const newTargetObj = {
              groupName: longestTarget.groupName,
              metrics: newMetrics,
            };
            data.targetGroups.push(newTargetObj);
          }
        }
      }
    } else {
      // get baseline company targets
      tmpData.forEach((td: TargetData) => {
        if (td.companyData.isBaseCompany) {
          td.targetGroups = td.targetGroups.filter(
            (tg: TargetMetricGroup, ind: number) =>
              !tg.metrics.every(
                (m: TargetMetric, i: number) =>
                  m.metricValue === "*" || (i === 0 && ind !== 0)
              )
          );
          td.targetGroups.forEach((tg: TargetMetricGroup, ind: number) => {
            tg.groupName = `${PREFIX} ${ind + 1}`;
          });

          if (
            td.targetGroups.filter(
              (tgr) => !tgr.metrics.every((met) => met.metricValue !== "*")
            ).length > 0 &&
            !hasEmptyValues
          )
            setHasEmptyValues(true);
        }
      });
      //Remove targets
      let baselineCompanyData = tmpData.filter(
        (td: TargetData) => td.companyData.isBaseCompany
      );
      let baselineCompanyMetrics =
        baselineCompanyData.length > 0 &&
        baselineCompanyData[0].targetGroups.length > 0
          ? baselineCompanyData[0].targetGroups.reduce(
              (prev: string[], current: TargetMetricGroup) =>
                [...prev].concat(
                  current.metrics.map((tm: TargetMetric) => tm.metricKey)
                ),

              []
            )
          : [];

      tmpData.forEach((td: TargetData) => {
        if (!td.companyData.isBaseCompany) {
          td.targetGroups = td.targetGroups.filter((tg: TargetMetricGroup) =>
            tg.metrics.every((tm: TargetMetric) =>
              baselineCompanyMetrics.includes(tm.metricKey)
            )
          );
          td.targetGroups.forEach((tg: TargetMetricGroup, ind: number) => {
            tg.groupName = `${PREFIX} ${ind + 1}`;
          });
          if (
            td.targetGroups.filter(
              (tgr) => !tgr.metrics.every((met) => met.metricValue !== "*")
            ).length > 0 &&
            !hasEmptyValues
          )
            setHasEmptyValues(true);
        }
      });

      /*If the selected baseline company has all target references 
    as 'Entity deemed question not applicable'/'Question not applicable"
    for both Metric 2 and Metric 3, then we will see the target tiles as
    per single target view*/

      if (benchmarkType === INSIGHT_BENCHMARK_TYPE.COMPANY) {
        const isEntityDeemed = tmpData[0].targetGroups.every(
          (targetGroup: TargetMetricGroup) => {
            const scienceBasedTargetSetMetric = targetGroup.metrics.find(
              (metric: TargetMetric) =>
                metric.metricName === ENTITY_DEEMED_METRIC_1
            );
            const scopeOfTargetMetric = targetGroup.metrics.find(
              (metric: TargetMetric) =>
                metric.metricName === ENTITY_DEEMED_METRIC_2
            );
            return (
              scienceBasedTargetSetMetric!.metricValue ===
                ENTITY_DEEMED_VALUE &&
              scopeOfTargetMetric!.metricValue === ENTITY_DEEMED_VALUE
            );
          }
        );

        if (isEntityDeemed) {
          tmpData = tmpData.map((data) => {
            return {
              companyData: data.companyData,
              targetGroups: data.targetGroups.filter(
                (target) => target.groupName === "Target 1"
              ),
            };
          });
        }
      }
    }

    return tmpData;
  };

  const tileProps = {
    currentInsightView: benchmarkType,
    isDetailedView: isDetailedView,
    isTableViewActive: isTableViewActive,
    data: mapChartData(),
    hasEmptyValues: hasEmptyValues,
    groupValues: benchmarkMetadata.associatedMetricsDisplayNames[0].groupValues,
    groupTooltips: associatedMetricGroupTooltips,
  };

  return (
    <div data-testid="ghg-target-container">
      <GhgTargetTile {...tileProps} />
    </div>
  );
};

export default GhgTarget;
