/** Redux actions in the applications to intercept and track  */
import { ACTIONS as downloadActions } from "services/download/download.service";
import { ACTIONS as searchActions } from "services/search/search.results.service";
import { ACTIONS as documentViewerActions } from "services/document-viewer/document-viewer.service";
import { ACTIONS as shortlistActions } from "services/shortlist/shortlist.service";
import { ACTIONS as savedShortlistActions } from "services/saved-shortlist/saved-shortlist.service";
import { ACTIONS as SavedSearchActions } from "services/searchlist/searchlist.service";
import { ACTIONS as savedComparisonsActions } from "services/saved-comparisons/saved-comparisons.service";
import { ACTIONS as peerBenchmarkActions } from "services/peer-benchmark/peer-benchmark.service";
import { ACTIONS as dashboardActions } from "services/dashboard/dashboard.service";
import { ACTIONS as insightActions } from "services/insights/insights.service";
import { ACTIONS as tagActions } from "services/tags/tags.service";
import { ACTIONS as commonsActions } from "services/commons.service";

// Only "page_load" is set up as of now, to be triggered when page navigation happens, resulting in change of router location
const TRACKING_ACTIONS: any = {
  "@@router/LOCATION_CHANGE": {
    type: "@@router/LOCATION_CHANGE",
    dcr: "page_load",
    action: "Location change",
  },
  [searchActions.SEARCH_ESG_REPORTS_TRACK]: {
    type: searchActions.SEARCH_ESG_REPORTS_TRACK,
    dcr: "search",
    action: "Search ESG Reports",
  },
  [searchActions.SEARCH_SEC_REPORTS_TRACK]: {
    type: searchActions.SEARCH_SEC_REPORTS_TRACK,
    dcr: "search",
    action: "Search SEC Disclosures",
  },
  [searchActions.SEARCH_ESG_RATINGS_TRACK]: {
    type: searchActions.SEARCH_ESG_RATINGS_TRACK,
    dcr: "page_load",
    action: "ESG ratings",
  },
  [searchActions.SEARCH_ESG_GOVERNANCE_METRICS_TRACK]: {
    type: searchActions.SEARCH_ESG_GOVERNANCE_METRICS_TRACK,
    dcr: "page_load",
    action: "Governance score",
  },
  [documentViewerActions.DOCUMENT_VIEWER_SET_SELECTED_REPORT]: {
    type: documentViewerActions.DOCUMENT_VIEWER_SET_SELECTED_REPORT,
    dcr: "page_load",
    action: "Document Viewer - ESG",
  },
  [documentViewerActions.DOCUMENT_VIEWER_SET_SELECTED_DISCLOSURE]: {
    type: documentViewerActions.DOCUMENT_VIEWER_SET_SELECTED_DISCLOSURE,
    dcr: "page_load",
    action: "Document Viewer - SEC",
  },
  [shortlistActions.SHORTLIST_ITEM_ADD]: {
    type: shortlistActions.SHORTLIST_ITEM_ADD,
    dcr: "page_load",
    action: "Add to shortlist",
  },
  [shortlistActions.SHORTLIST_ITEM_REMOVE]: {
    type: shortlistActions.SHORTLIST_ITEM_REMOVE,
    dcr: "page_load",
    action: "Remove from shortlist",
  },
  [shortlistActions.SHORTLIST_SAVE]: {
    type: shortlistActions.SHORTLIST_SAVE,
    dcr: "page_load",
    action: "Save shortlist",
  },
  [savedShortlistActions.SHORT_LIST_RENAME]: {
    type: savedShortlistActions.SHORT_LIST_RENAME,
    dcr: "page_load",
    action: "Rename shortlist",
  },
  [savedShortlistActions.SHORT_LIST_DELETE]: {
    type: savedShortlistActions.SHORT_LIST_DELETE,
    dcr: "page_load",
    action: "Delete shortlist",
  },
  [downloadActions.DOWNLOAD_SHOW_DOWNLOAD]: {
    type: downloadActions.DOWNLOAD_SHOW_DOWNLOAD,
    dcr: "page_load",
    action: "My Downloads",
  },
  [SavedSearchActions.SEARCH_SAVE]: {
    type: SavedSearchActions.SEARCH_SAVE,
    dcr: "page_load",
    action: "Save New Search",
  },
  [SavedSearchActions.EXECUTE_SAVED_SEARCH]: {
    type: SavedSearchActions.EXECUTE_SAVED_SEARCH,
    dcr: "page_load",
    action: "Execute Saved Search",
  },
  [SavedSearchActions.EXECUTE_SHARED_SEARCH]: {
    type: SavedSearchActions.EXECUTE_SHARED_SEARCH,
    dcr: "page_load",
    action: "Execute Shared Search",
  },
  [SavedSearchActions.SHARE_SEARCH_SUCCESS]: {
    type: SavedSearchActions.SHARE_SEARCH_SUCCESS,
    dcr: "page_load",
    action: "Share Search",
  },
  [SavedSearchActions.EDIT_SEARCH]: {
    type: SavedSearchActions.EDIT_SEARCH,
    dcr: "page_load",
    action: "Edit Search",
  },
  [SavedSearchActions.DELETE_SEARCH]: {
    type: SavedSearchActions.DELETE_SEARCH,
    dcr: "page_load",
    action: "Delete Search",
  },
  [savedShortlistActions.SHORTLIST_SHARE_SUCCESS_FLAG]: {
    type: savedShortlistActions.SHORTLIST_SHARE_SUCCESS_FLAG,
    dcr: "page_load",
    action: "Share Shortlist",
  },
  [savedComparisonsActions.NEW_COMPARISON_TRACK]: {
    type: savedComparisonsActions.NEW_COMPARISON_TRACK,
    dcr: "page_load",
    action: "New comparison",
  },
  [savedComparisonsActions.ADD_TO_COMPARISON_TRACK]: {
    type: savedComparisonsActions.ADD_TO_COMPARISON_TRACK,
    dcr: "page_load",
    action: "Add to comparison",
  },
  [savedComparisonsActions.SHARE_COMPARISON_TRACK]: {
    type: savedComparisonsActions.SHARE_COMPARISON_TRACK,
    dcr: "page_load",
    action: "Share comparison",
  },
  [peerBenchmarkActions.SAVE_PEER_BENCHMARK]: {
    type: peerBenchmarkActions.SAVE_PEER_BENCHMARK,
    dcr: "page_load",
    action: "Save peer benchmark",
  },
  [peerBenchmarkActions.PEER_BENCHMARK_DISPLAY_TRACK]: {
    type: peerBenchmarkActions.PEER_BENCHMARK_DISPLAY_TRACK,
    dcr: "page_load",
    action: "Display peer benchmark",
  },
  [peerBenchmarkActions.PEER_BENCHMARK_EXPORT_TRACK]: {
    type: peerBenchmarkActions.PEER_BENCHMARK_EXPORT_TRACK,
    dcr: "page_load",
    action: "Export peer benchmark",
  },
  [peerBenchmarkActions.PEER_BENCHMARK_SHARE_TRACK]: {
    type: peerBenchmarkActions.PEER_BENCHMARK_SHARE_TRACK,
    dcr: "page_load",
    action: "Share peer benchmark",
  },
  [dashboardActions.DASHBOARD_FILTER_TRACK]: {
    type: dashboardActions.DASHBOARD_FILTER_TRACK,
    dcr: "page_load",
    action: "Dashboard - Filter visualization",
  },
  [dashboardActions.DASHBOARD_CHANGE_STYLE_TRACK]: {
    type: dashboardActions.DASHBOARD_CHANGE_STYLE_TRACK,
    dcr: "page_load",
    action: "Dashboard - Change visualization style",
  },
  [dashboardActions.DASHBOARD_MAXIMIZE_TRACK]: {
    type: dashboardActions.DASHBOARD_MAXIMIZE_TRACK,
    dcr: "page_load",
    action: "Dashboard - Maximize visualization",
  },
  [dashboardActions.DASHBOARD_EXPORT_TRACK_EXCEL]: {
    type: dashboardActions.DASHBOARD_EXPORT_TRACK_EXCEL,
    dcr: "page_load",
    action: "Dashboard - Export visualization Excel",
  },
  [dashboardActions.DASHBOARD_EXPORT_TRACK_PDF]: {
    type: dashboardActions.DASHBOARD_EXPORT_TRACK_PDF,
    dcr: "page_load",
    action: "Dashboard - Export visualization PDF",
  },
  [dashboardActions.DASHBOARD_EXPORTALL_TRACK_EXCEL]: {
    type: dashboardActions.DASHBOARD_EXPORTALL_TRACK_EXCEL,
    dcr: "page_load",
    action: "Dashboard - Export all Excel",
  },
  [dashboardActions.DASHBOARD_EXPORTALL_TRACK_PDF]: {
    type: dashboardActions.DASHBOARD_EXPORTALL_TRACK_PDF,
    dcr: "page_load",
    action: "Dashboard - Export all PDF",
  },
  [dashboardActions.DASHBOARD_CREATECUSTOM_BENCHMARK_TRACK_BUTTON]: {
    type: dashboardActions.DASHBOARD_CREATECUSTOM_BENCHMARK_TRACK_BUTTON,
    dcr: "page_load",
    action: "Dashboard - Create custom benchmark from button",
  },
  [dashboardActions.DASHBOARD_CREATECUSTOM_BENCHMARK_TRACK_TILE]: {
    type: dashboardActions.DASHBOARD_CREATECUSTOM_BENCHMARK_TRACK_TILE,
    dcr: "page_load",
    action: "Dashboard - Create custom benchmark from tile",
  },
  [insightActions.INSIGHTS_CREATE_INSIGHT_TRACK]: {
    type: insightActions.INSIGHTS_CREATE_INSIGHT_TRACK,
    dcr: "page_load",
    action: "Insight - Create insight",
  },
  [insightActions.INSIGHTS_EXPORT_INSIGHT_TRACK]: {
    type: insightActions.INSIGHTS_EXPORT_INSIGHT_TRACK,
    dcr: "page_load",
    action: "Insight - Export insight",
  },
  [insightActions.INSIGHTS_EDIT_INSIGHT_TRACK]: {
    type: insightActions.INSIGHTS_EDIT_INSIGHT_TRACK,
    dcr: "page_load",
    action: "Insight - Edit insight",
  },
  [insightActions.INSIGHTS_DELETE_TILE_TRACK]: {
    type: insightActions.INSIGHTS_DELETE_TILE_TRACK,
    dcr: "page_load",
    action: "Insight - Delete insight",
  },
  [insightActions.INSIGHTS_ADD_INFORMATION_TILE_TRACK]: {
    type: insightActions.INSIGHTS_ADD_INFORMATION_TILE_TRACK,
    dcr: "page_load",
    action: "Insight - Add additional information",
  },
  [insightActions.INSIGHTS_ESG_LINK_TRACK]: {
    type: insightActions.INSIGHTS_ESG_LINK_TRACK,
    dcr: "page_load",
    action: "Insight - ESG link",
  },
  [insightActions.INSIGHTS_SEC_LINK_TRACK]: {
    type: insightActions.INSIGHTS_SEC_LINK_TRACK,
    dcr: "page_load",
    action: "Insight - SEC link",
  },

  [insightActions.INSIGHTS_COMPANY_TAB_TRACK]: {
    type: insightActions.INSIGHTS_COMPANY_TAB_TRACK,
    dcr: "page_load",
    action: "Insight - Company tab",
  },
  [insightActions.INSIGHTS_PEER_BENCHMARK_TAB_TRACK]: {
    type: insightActions.INSIGHTS_PEER_BENCHMARK_TAB_TRACK,
    dcr: "page_load",
    action: "Insight - Peer Benchmark tab",
  },
  [insightActions.INSIGHTS_INDUSTRY_TAB_TRACK]: {
    type: insightActions.INSIGHTS_INDUSTRY_TAB_TRACK,
    dcr: "page_load",
    action: "Insight - Industry tab",
  },
  [insightActions.INSIGHTS_PDF_EXPORT_ALL_TRACK]: {
    type: insightActions.INSIGHTS_PDF_EXPORT_ALL_TRACK,
    dcr: "page_load",
    action: "Insight - Export all PDF",
  },
  [insightActions.INSIGHTS_XLS_EXPORT_ALL_TRACK]: {
    type: insightActions.INSIGHTS_XLS_EXPORT_ALL_TRACK,
    dcr: "page_load",
    action: "Insight - Export all EXCEL",
  },
  [insightActions.INSIGHTS_PPT_EXPORT_ALL_TRACK]: {
    type: insightActions.INSIGHTS_PPT_EXPORT_ALL_TRACK,
    dcr: "page_load",
    action: "Insight - Export all POWER POINT",
  },
  [insightActions.INSIGHTS_FILTER_TRACK]: {
    type: insightActions.INSIGHTS_FILTER_TRACK,
    dcr: "page_load",
    action: "Insight - Filter visualization",
  },
  [insightActions.INSIGHTS_COMPANY_FILTER_TRACK]: {
    type: insightActions.INSIGHTS_COMPANY_FILTER_TRACK,
    dcr: "page_load",
    action: "Insight - Company Filter visualization",
  },
  [insightActions.INSIGHTS_PDF_EXPORT_TILE_TRACK]: {
    type: insightActions.INSIGHTS_PDF_EXPORT_TILE_TRACK,
    dcr: "page_load",
    action: "Insight - Export tile PDF",
  },
  [insightActions.INSIGHTS_XLS_EXPORT_TILE_TRACK]: {
    type: insightActions.INSIGHTS_XLS_EXPORT_TILE_TRACK,
    dcr: "page_load",
    action: "Insight - Export tile EXCEL",
  },
  [insightActions.INSIGHTS_PPT_EXPORT_TILE_TRACK]: {
    type: insightActions.INSIGHTS_PPT_EXPORT_TILE_TRACK,
    dcr: "page_load",
    action: "Insight - Export tile POWER POINT",
  },
  [insightActions.INSIGHTS_TABLE_VIEW_TRACK]: {
    type: insightActions.INSIGHTS_TABLE_VIEW_TRACK,
    dcr: "page_load",
    action: "Insight - Table View all",
  },
  [insightActions.INSIGHTS_TABLE_VIEW_TILE_TRACK]: {
    type: insightActions.INSIGHTS_TABLE_VIEW_TILE_TRACK,
    dcr: "page_load",
    action: "Insight - Table View tile",
  },
  [insightActions.INSIGHTS_DETAILED_VIEW_TILE_TRACK]: {
    type: insightActions.INSIGHTS_DETAILED_VIEW_TILE_TRACK,
    dcr: "page_load",
    action: "Insight - Detailed View tile",
  },
  [insightActions.INSIGHTS_DETAILED_VIEW_TRACK]: {
    type: insightActions.INSIGHTS_DETAILED_VIEW_TRACK,
    dcr: "page_load",
    action: "Insight - Detailed Peer View all",
  },
  [insightActions.INSIGHTS_CHART_VIEW_TILE_TRACK]: {
    type: insightActions.INSIGHTS_CHART_VIEW_TILE_TRACK,
    dcr: "page_load",
    action: "Insight - Chart View tile",
  },
  [insightActions.INSIGHTS_CHART_VIEW_TRACK]: {
    type: insightActions.INSIGHTS_CHART_VIEW_TRACK,
    dcr: "page_load",
    action: "Insight - Chart View all",
  },
  [insightActions.INSIGHTS_TALKING_POINTS_TRACK]: {
    type: insightActions.INSIGHTS_TALKING_POINTS_TRACK,
    dcr: "page_load",
    action: "Insight - Talking points",
  },
  [insightActions.INSIGHTS_CREATE_PEER_GROUP_TRACK]: {
    type: insightActions.INSIGHTS_CREATE_PEER_GROUP_TRACK,
    dcr: "page_load",
    action: "Create peer group",
  },
  [insightActions.INSIGHTS_EDIT_PEER_GROUP_TRACK]: {
    type: insightActions.INSIGHTS_EDIT_PEER_GROUP_TRACK,
    dcr: "page_load",
    action: "Edit peer group",
  },
  [insightActions.INSIGHTS_DELETE_PEER_GROUP_TRACK]: {
    type: insightActions.INSIGHTS_DELETE_PEER_GROUP_TRACK,
    dcr: "page_load",
    action: "Delete peer group",
  },
  [tagActions.CREATE_NEW_TAG_TRACK]: {
    type: tagActions.CREATE_NEW_TAG_TRACK,
    dcr: "page_load",
    action: "Create new tag",
  },
  [tagActions.DELETE_TAG_TRACK]: {
    type: tagActions.DELETE_TAG_TRACK,
    dcr: "page_load",
    action: "Delete tag",
  },
  [tagActions.CREATE_TAG_COMMENT_TRACK]: {
    type: tagActions.CREATE_TAG_COMMENT_TRACK,
    dcr: "page_load",
    action: "Create tag comment",
  },
  [tagActions.DELETE_TAG_COMMENT_TRACK]: {
    type: tagActions.DELETE_TAG_COMMENT_TRACK,
    dcr: "page_load",
    action: "Delete tag comment",
  },
  [tagActions.HIDE_TAGS_TRACK]: {
    type: tagActions.HIDE_TAGS_TRACK,
    dcr: "page_load",
    action: "Hide tags",
  },
  [tagActions.SHOW_TAGS_TRACK]: {
    type: tagActions.SHOW_TAGS_TRACK,
    dcr: "page_load",
    action: "Show tags ",
  },
  [savedComparisonsActions.CONTENT_COMPARISON_EXCEL_EXPORT_TRACK]: {
    type: savedComparisonsActions.CONTENT_COMPARISON_EXCEL_EXPORT_TRACK,
    dcr: "page_load",
    action: "Export content benchmark Excel",
  },
  [savedComparisonsActions.CONTENT_COMPARISON_COLABORATION_SHARE_TRACK]: {
    type: savedComparisonsActions.CONTENT_COMPARISON_COLABORATION_SHARE_TRACK,
    dcr: "page_load",
    action: "Share Content benchmark",
  },
  [insightActions.INSIGHTS_SHARE_TRACK]: {
    type: insightActions.INSIGHTS_SHARE_TRACK,
    dcr: "page_load",
    action: "Share insight",
  },
  [insightActions.INSIGHTS_SHARE_WITH_TRACK]: {
    type: insightActions.INSIGHTS_SHARE_WITH_TRACK,
    dcr: "page_load",
    action: "Share insight - ",
  },
  [commonsActions.GLOBAL_COMPANY_GROUPING_TRACK]: {
    type: commonsActions.GLOBAL_COMPANY_GROUPING_TRACK,
    dcr: "page_load",
    action: "Non-Sec company grouping",
  },
};
export default TRACKING_ACTIONS;
