import classNames from "classnames";
import Icon from "components/shared/icon/icon";
import { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { handleMouseEnter, handleMouseLeave } from "utils/functions";

const defaultSize = 304;
const SPACING = 8;

type Props = {
  BLOCK: string;
  className: string;
  disableDragDrop?: boolean;
  header: any;
  index: number;
  reorderColumns: Function;
  lowerLimit?: number;
  isBaseline?: boolean;
  showInfoIcon?: boolean;
  finishLoading?: Function;
  headerTooltip?: JSX.Element | string;
  savePreferences: Function;
  width?: number;
  columnID?: number;
};

const ResizeGridHeader = ({
  BLOCK,
  className,
  disableDragDrop,
  header,
  index,
  reorderColumns,
  lowerLimit = 110,
  isBaseline,
  showInfoIcon,
  finishLoading,
  headerTooltip,
  savePreferences,
  width,
  columnID,
}: Props) => {
  const dispatch = useDispatch();
  const [canDrag, setCanDrag] = useState(false);
  const eRef = useRef(null);

  const [initialWidth, setInitialWidth] = useState<number | null>(null);
  const [colWidth, setColWidth] = useState<number>(width || 0);
  const [colResize, setColResize] = useState<number | null>(null);
  useEffect(() => {
    if (!colResize) return;
    const onMouseUpHandler = (e: any) => {
      const widthLimit = initialWidth ? initialWidth : defaultSize;
      const diff = e.clientX - colResize;
      setColResize(null);
      setColWidth((w) => {
        const newSize = w + diff;
        if (newSize < lowerLimit) return lowerLimit;
        if (newSize > widthLimit * 2) return widthLimit * 2;
        return newSize;
      });

      const newSize = colWidth + diff;
      const newWidth =
        newSize < lowerLimit
          ? lowerLimit
          : newSize > widthLimit * 2
          ? widthLimit * 2
          : newSize;
      savePreferences(columnID, newWidth);
    };
    window.addEventListener("mouseup", onMouseUpHandler);

    return () => window.removeEventListener("mouseup", onMouseUpHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colResize]);

  const [, dragRef] = useDrag(
    () => ({
      type: "graph",
      item: () => ({
        eRef,
        index,
      }),
      end: () => setCanDrag(false),
      canDrag: canDrag,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.9 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [canDrag]
  );

  const [, dropRef] = useDrop({
    accept: "graph",
    drop: (item: any) => {
      setCanDrag(false);
      if (!disableDragDrop) reorderColumns(item.index, index);
    },
    collect: (monitor) => ({ isOver: monitor.isOver() }),
  });

  const colDragDropRef: any = dragRef(dropRef(eRef));

  useEffect(() => {
    const headerRefSize = colDragDropRef
      ? (colDragDropRef as any).current.offsetWidth + SPACING
      : null;

    setInitialWidth(width ? width : headerRefSize);
    setColWidth(width ? width : headerRefSize);
    if (finishLoading)
      setTimeout(() => {
        finishLoading();
      }, 100);

    return () => {
      setInitialWidth(null);
      setColWidth(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <th
      style={{
        width: `${colWidth ? colWidth : defaultSize}px`,
        ...(showInfoIcon ? { paddingRight: `3.5rem` } : {}),
      }}
      ref={colDragDropRef}
      className={classNames(className, `${BLOCK}__drag-th`)}
      data-testid={className}
    >
      <div
        className={`${BLOCK}__drag-header`}
        onMouseEnter={(e: any) => {
          if (initialWidth && initialWidth > colWidth && headerTooltip)
            handleMouseEnter(e, "div", headerTooltip, "", dispatch);
        }}
        onMouseLeave={(e) => {
          handleMouseLeave(e, dispatch, false);
        }}
        data-testid={`${BLOCK}__drag-header`}
      >
        {header}
        {disableDragDrop ? null : (
          <span
            style={{
              ...(showInfoIcon ? { right: `-3rem` } : {}),
            }}
            className={`${BLOCK}__drag-header__icon ${
              showInfoIcon ? {} : "hide-drag-icon"
            }`}
            onMouseDown={() => setCanDrag(true)}
            onMouseUp={() => setCanDrag(false)}
            data-testid={`${BLOCK}__drag-header__icon`}
          >
            <Icon name="drag-handler" height={25} />
          </span>
        )}
        {isBaseline && (
          <div className={`${BLOCK}__base-company`}>
            <Icon
              name={"baseline-star"}
              width={16}
              height={16}
              className={`${BLOCK}__rowItem--company--items--icon`}
              data-testid="baseline-icon"
            />
          </div>
        )}
      </div>
      <span
        onMouseDown={(e) => setColResize((size) => (size ? size : e.clientX))}
        className={`${BLOCK}__col-resize-handler`}
        data-testid={`${BLOCK}__col-resize-handler`}
      ></span>
    </th>
  );
};

export default ResizeGridHeader;
