/* eslint-disable no-loop-func */
import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import classNames from "classnames";
import Checkbox from "components/shared/checkbox/checkbox";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCollapseAllMetricsValue,
  setExpandAllMetricsValue,
  setHasMetricOpenValue,
} from "services/peer-benchmark/peer-benchmark.service";
import { RootStore } from "services/store.service";
import {
  AccordionMetric,
  AccordionMetricField,
  AccordionMetricFieldGroup,
  Metric,
  MetricField,
  MetricFieldGroup,
  SelectedField,
} from "services/peer-benchmark/peer-benchmark.model";
import useDebounceMetrics from "utils/useDebounceMetrics";
import { compareByDisplayName } from "utils/functions";

type props = {
  handleSelectedMetrics: (selectedFields: SelectedField[]) => void;
  searchInputVal: string;
  editView: boolean;
  preSelectedMetrics: any;
  peerBenchmarkMetrics: Metric[];
  selectedMetricsFromParent: SelectedField[];
  setNoResults: (pnoResults: boolean) => void;
  disabledMetrics: boolean;
  maxCount: number;
  index: number;
  resetMetrics: boolean;
  setResetMetrics: any;
};
const MetricsAccordion = ({
  handleSelectedMetrics,
  searchInputVal,
  editView,
  preSelectedMetrics,
  peerBenchmarkMetrics,
  selectedMetricsFromParent,
  setNoResults,
  disabledMetrics,
  maxCount,
  index,
  resetMetrics,
  setResetMetrics,
}: props) => {
  const BLOCK = "metrics-accordion";
  const dispatch = useDispatch();
  const [openItems, setOpenItems] = useState<number[]>([]);
  const [allMetricsLoaded, setAllMetricsLoaded] = useState<boolean>(false);
  const [selectedMetrics, setSelectedMetrics] = useState<SelectedField[]>([]);
  const peerBenchmarkState = useSelector(
    (state: RootStore) => state.peerBenchmarkState
  );
  const [sortedMetrics, setSortedMetrics] = useState<AccordionMetric[]>();
  const [filteredMetrics, setFilteredMetrics] = useState<AccordionMetric[]>();
  const newFilter = useDebounceMetrics(searchInputVal);

  useEffect(() => {
    if (peerBenchmarkMetrics && peerBenchmarkMetrics.length > 0) {
      let tmpSortedMetrics = peerBenchmarkMetrics
        .sort((a: Metric, b: Metric) => a.displayOrder - b.displayOrder)
        .map((met: Metric) => buildNewMetric(met));
      setSortedMetrics(tmpSortedMetrics);
      if (editView || (editView && resetMetrics)) {
        const tmpSelectedMetrics =
          determineCorrectSelectedMetrics(tmpSortedMetrics);
        setSelectedMetrics([...tmpSelectedMetrics]);
        handleSelectedMetrics([...tmpSelectedMetrics]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peerBenchmarkMetrics]);

  useEffect(() => {
    if (peerBenchmarkMetrics && peerBenchmarkMetrics.length > 0) {
      let tmpSortedMetrics = peerBenchmarkMetrics
        .sort((a: Metric, b: Metric) => a.displayOrder - b.displayOrder)
        .map((met: Metric) => buildNewMetric(met));
      setSortedMetrics(tmpSortedMetrics);
      if (editView && resetMetrics) {
        const tmpSelectedMetrics =
          determineCorrectSelectedMetrics(tmpSortedMetrics);
        setSelectedMetrics([...tmpSelectedMetrics]);
        handleSelectedMetrics([...tmpSelectedMetrics]);
        setResetMetrics(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetMetrics]);

  useEffect(() => {
    if (sortedMetrics && sortedMetrics.length > 0) {
      setAllMetricsLoaded(true);
    }
  }, [sortedMetrics]);

  useEffect(() => {
    handleFilterMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFilter]);

  useEffect(() => {
    if (peerBenchmarkState.expandAllMetrics && allMetricsLoaded) {
      let tmpSortedMetrics = sortedMetrics ? [...sortedMetrics] : [];
      let tmpFilteredMetrics = filteredMetrics ? [...filteredMetrics] : [];

      let metricsToUse =
        tmpFilteredMetrics.length > 0 ? tmpFilteredMetrics : tmpSortedMetrics;

      tmpSortedMetrics.forEach((metric) => {
        metric.fieldGroups = metric.fieldGroups?.map((group) => ({
          ...group,
          collapse: false,
        }));
      });

      tmpFilteredMetrics.forEach((metric) => {
        metric.fieldGroups = metric.fieldGroups?.map((group) => ({
          ...group,
          collapse: false,
        }));
      });

      setOpenItems(metricsToUse.map((metric) => metric.metricCategoryId));
      setSortedMetrics(tmpSortedMetrics);
      setFilteredMetrics(tmpFilteredMetrics);
      dispatch(setHasMetricOpenValue(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peerBenchmarkState.expandAllMetrics]);

  useEffect(() => {
    if (peerBenchmarkState.collapseAllMetrics && allMetricsLoaded) {
      let tmpSortedMetrics = sortedMetrics ? [...sortedMetrics] : [];
      let tmpFilteredMetrics = filteredMetrics ? [...filteredMetrics] : [];

      tmpSortedMetrics.forEach((metric) => {
        metric.fieldGroups = metric.fieldGroups?.map((group) => ({
          ...group,
          collapse: true,
        }));
      });

      tmpFilteredMetrics.forEach((metric) => {
        metric.fieldGroups = metric.fieldGroups?.map((group) => ({
          ...group,
          collapse: true,
        }));
      });

      setOpenItems([]);
      setSortedMetrics(tmpSortedMetrics);
      setFilteredMetrics(tmpFilteredMetrics);
      dispatch(setHasMetricOpenValue(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peerBenchmarkState.collapseAllMetrics]);

  useEffect(() => {
    if (selectedMetricsFromParent.length === 0 && selectedMetrics.length > 0) {
      let tmpSortedMetrics = sortedMetrics?.map((am: AccordionMetric) => ({
        ...am,
        selectAll: false,
        selectedFields: 0,
        fields: am.fields
          ? am.fields.map((f: AccordionMetricField) => ({
              ...f,
              checked: false,
            }))
          : [],
        fieldGroups: am.fieldGroups
          ? am.fieldGroups.map((fg: AccordionMetricFieldGroup) => ({
              ...fg,
              selectAllGroup: false,
              selectedFields: 0,
              fields: fg.fields
                ? fg.fields.map((f: AccordionMetricField) => ({
                    ...f,
                    checked: false,
                  }))
                : [],
            }))
          : [],
      }));
      setSortedMetrics(tmpSortedMetrics);

      if (filteredMetrics && filteredMetrics?.length > 0) {
        let tmpFilteredMetrics = filteredMetrics?.map(
          (am: AccordionMetric) => ({
            ...am,
            selectAll: false,
            selectedFields: 0,
            fields: am.fields
              ? am.fields.map((f: AccordionMetricField) => ({
                  ...f,
                  checked: false,
                }))
              : [],
            fieldGroups: am.fieldGroups
              ? am.fieldGroups.map((fg: AccordionMetricFieldGroup) => ({
                  ...fg,
                  selectAllGroup: false,
                  selectedFields: 0,
                  fields: fg.fields
                    ? fg.fields.map((f: AccordionMetricField) => ({
                        ...f,
                        checked: false,
                      }))
                    : [],
                }))
              : [],
          })
        );
        setFilteredMetrics(tmpFilteredMetrics);
      }
      setSelectedMetrics([]);
    }
  }, [selectedMetricsFromParent]);

  useEffect(() => {
    if (
      allMetricsLoaded &&
      newFilter !== "" &&
      filteredMetrics &&
      filteredMetrics?.length === 0
    )
      setNoResults(true);
    else setNoResults(false);
  }, [filteredMetrics]);
  const determineCorrectSelectedMetrics = (builtMetrics: any) => {
    //used to set the selected metrics from the preselected ones with the correct display order
    let tmpPreSelectedMetrics: SelectedField[] =
      editView && resetMetrics ? [] : [...selectedMetrics];
    for (let i = 0; i < builtMetrics.length; i++) {
      let currentMetric = builtMetrics[i];
      if (currentMetric.fieldGroups.length === 0) {
        currentMetric.fields?.forEach((field: any) => {
          if (field.checked) {
            const findMetric = preSelectedMetrics.filter(
              (metric: any) => metric.fieldName === field.fieldName
            );
            let tmpSelectedMetric = {
              fieldName: field.fieldName,
              fieldGroupName: null,
              displayOrder: findMetric[0].displayOrder,
            };
            tmpPreSelectedMetrics.push(tmpSelectedMetric);
          }
        });
      } else {
        currentMetric.fieldGroups?.forEach((fieldGroup: any) => {
          const findGroup = preSelectedMetrics.filter(
            (metric: any) => metric.fieldGroupName === fieldGroup.fieldGroupName
          );
          fieldGroup.fields.forEach((field: any) => {
            if (field.checked) {
              const findMetric = preSelectedMetrics.filter(
                (metric: any) => metric.fieldName === field.fieldName
              );
              let tmpSelectedMetric = {
                fieldName: field.fieldName,
                fieldGroupName: fieldGroup.fieldGroupName,
                displayOrder:
                  findMetric.length > 0
                    ? findMetric[0].displayOrder
                    : findGroup[0].displayOrder,
              };
              tmpPreSelectedMetrics.push(tmpSelectedMetric);
            }
          });
        });
      }
    }
    return tmpPreSelectedMetrics;
  };

  const determineChecked = (field: any) => {
    if (editView) {
      return preSelectedMetrics.some(
        (el: any) => el.fieldName === field.fieldName
      );
    } else {
      return false;
    }
  };

  const determineSelectAll = (tmpMetric: any) => {
    let filterUnchecked = tmpMetric.fields.filter(
      (value: any) => value.checked === false
    );
    return filterUnchecked.length === 0;
  };

  const buildNewMetric = (metric: Metric): AccordionMetric => {
    let tmpMetric = {
      ...metric,
      fields: metric.fields.map((field: MetricField): AccordionMetricField => {
        return {
          ...field,
          checked: determineChecked(field),
        };
      }),
      selectAll: false,
      fieldGroups: metric.fieldGroups.map(
        (fieldGroup: MetricFieldGroup): AccordionMetricFieldGroup => {
          let filteredList = preSelectedMetrics.filter(
            (value: any) => value.fieldGroupName === fieldGroup.fieldGroupName
          );
          let tmpFields = fieldGroup.fields.map(
            (field: MetricField): AccordionMetricField => {
              return {
                ...field,
                checked:
                  editView && filteredList.length > 0
                    ? true
                    : determineChecked(field),
              };
            }
          );
          return {
            ...fieldGroup,
            collapse: true,
            selectAllGroup: editView ? filteredList.length > 0 : false,
            fields: tmpFields,
            selectedFields: tmpFields.reduce(
              (prevValue: number, current: AccordionMetricField) => {
                return current.checked ? ++prevValue : prevValue;
              },
              0
            ),
          };
        }
      ),
      selectedFields: 0,
    };
    if (editView && metric.fieldGroups.length === 0) {
      let selectAllChecked = determineSelectAll(tmpMetric);
      tmpMetric = {
        ...tmpMetric,
        selectAll: selectAllChecked,
      };
    }

    if (editView && tmpMetric?.fieldGroups?.length > 0) {
      let selectAll = tmpMetric.fieldGroups.every(
        (group: AccordionMetricFieldGroup) => {
          return group.fields!.every((field: AccordionMetricField) => {
            return field.checked === true;
          });
        }
      );
      tmpMetric = {
        ...tmpMetric,
        selectAll: selectAll,
      };
    }

    if (tmpMetric.fieldGroups.length > 0) {
      tmpMetric.fieldGroups.forEach((fg: AccordionMetricFieldGroup) => {
        fg.fields?.sort(compare);
      });
      tmpMetric.selectedFields = tmpMetric.fieldGroups.reduce(
        (prev: number, curr: AccordionMetricFieldGroup) => {
          return prev + curr.selectedFields;
        },
        0
      );
    } else {
      tmpMetric.selectedFields = tmpMetric.fields.reduce(
        (prevValue: number, current: AccordionMetricField) => {
          return current.checked ? ++prevValue : prevValue;
        },
        0
      );
    }

    return tmpMetric;
  };

  const handleOnchange = (
    metricCategoryId: number,
    fieldGroupId: number,
    fieldGroupName: string,
    factorId: number,
    isTriggerBySelectAll: boolean,
    isGrouped: boolean,
    hasSubcategories: boolean = false
  ) => {
    let fieldsToUnselect: AccordionMetricField[] = [];
    let fieldGroupsToSelect: {
      fields: AccordionMetricField[];
      fieldGroupName: string;
    }[] = [];
    if (isTriggerBySelectAll) {
      //Remaining capacity
      let qty =
        maxCount !== -1 ? maxCount - selectedMetricsFromParent.length : -1;
      if (isGrouped && !hasSubcategories) {
        let tmpSortedMetrics: any = sortedMetrics?.map((m: AccordionMetric) => {
          if (m.metricCategoryId === metricCategoryId) {
            //find Filtered categroy
            let filteredMetricCategory =
              filteredMetrics && filteredMetrics.length > 0
                ? filteredMetrics.filter(
                    (fm: AccordionMetric) =>
                      fm.metricCategoryId === metricCategoryId
                  )[0]
                : null;
            let tmpFieldGroupsToUpdate: {
              fields: AccordionMetricField[];
              fieldGroupName: string;
            }[] = [];

            let fieldsToUpdate: AccordionMetricField[] = [];
            let tmpMetricCategory = {
              ...m,
              fieldGroups: m.fieldGroups?.map(
                (sub: AccordionMetricFieldGroup) => {
                  if (sub.fieldGroupId === fieldGroupId) {
                    //find filtered subcategory/group
                    let filteredSubcategory =
                      filteredMetricCategory &&
                      filteredMetricCategory.fieldGroups &&
                      isFieldGroupInFilteredMetrics(
                        metricCategoryId,
                        sub.fieldGroupId
                      )
                        ? filteredMetricCategory.fieldGroups.filter(
                            (fmg: AccordionMetricFieldGroup) =>
                              fmg.fieldGroupId === sub.fieldGroupId
                          )[0]
                        : null;

                    if (
                      (sub.selectAllGroup &&
                        sub.fields &&
                        filteredMetrics &&
                        filteredMetrics.length === 0) ||
                      (isFullMetricInFilteredMetrics(
                        metricCategoryId,
                        fieldGroupId
                      ) &&
                        sub.fields &&
                        sub.selectAllGroup) ||
                      (filteredMetricCategory &&
                        filteredSubcategory &&
                        filteredSubcategory.selectAllGroup) ||
                      (qty !== -1 && disabledMetrics)
                    ) {
                      fieldsToUpdate =
                        filteredSubcategory && filteredSubcategory.fields
                          ? fieldsToUpdate.concat(filteredSubcategory.fields)
                          : fieldsToUpdate.concat(sub.fields ?? []);

                      fieldsToUpdate = fieldsToUpdate.filter(
                        (ftup: AccordionMetricField) =>
                          selectedMetrics.find(
                            (v: SelectedField) => v.fieldName === ftup.fieldName
                          )
                      );
                    } else if (sub.fields) {
                      let remainingFields = qty;
                      if (filteredSubcategory) {
                        tmpFieldGroupsToUpdate.push({
                          fields:
                            qty === -1 && filteredSubcategory.fields
                              ? filteredSubcategory.fields
                              : qty !== -1 &&
                                filteredSubcategory.fields &&
                                qty > 0
                              ? filteredSubcategory.fields.filter(
                                  (v: AccordionMetricField, i: number) => {
                                    if (
                                      categoryHasSelected(sub) &&
                                      remainingFields > 0 &&
                                      !selectedMetrics.find(
                                        (sm: SelectedField) =>
                                          sm.fieldName === v.fieldName
                                      )
                                    ) {
                                      remainingFields--;
                                      return true;
                                    } else if (!categoryHasSelected(sub)) {
                                      return i < qty;
                                    }
                                  }
                                )
                              : [],
                          fieldGroupName: sub.fieldGroupName,
                        });
                      } else if (filteredMetrics?.length === 0) {
                        tmpFieldGroupsToUpdate.push({
                          fields:
                            qty === -1
                              ? sub.fields
                              : qty !== -1 && qty > 0
                              ? sub.fields.filter(
                                  (v: AccordionMetricField, i: number) => {
                                    if (
                                      categoryHasSelected(sub) &&
                                      remainingFields > 0 &&
                                      !selectedMetrics.find(
                                        (sm: SelectedField) =>
                                          sm.fieldName === v.fieldName
                                      )
                                    ) {
                                      remainingFields--;
                                      return true;
                                    } else if (!categoryHasSelected(sub)) {
                                      return i < qty;
                                    }
                                  }
                                )
                              : [],
                          fieldGroupName: sub.fieldGroupName,
                        });
                      }
                    }

                    let tmpSub = {
                      ...sub,
                      fields: sub.fields?.map((f: any) => {
                        let executeSelection = true;
                        if (qty !== -1 && tmpFieldGroupsToUpdate.length > 0) {
                          let fgtoUpdate = tmpFieldGroupsToUpdate.filter(
                            (val: {
                              fields: AccordionMetricField[];
                              fieldGroupName: string;
                            }) => val.fieldGroupName === sub.fieldGroupName
                          );

                          if (
                            fgtoUpdate.length > 0 &&
                            fgtoUpdate[0].fields.filter(
                              (af: AccordionMetricField) =>
                                af.fieldId === f.fieldId
                            ).length === 0
                          )
                            executeSelection = false;
                        }
                        if (
                          disabledMetrics &&
                          qty !== -1 &&
                          fieldsToUpdate.length > 0 &&
                          fieldsToUpdate.filter(
                            (ftu: AccordionMetricField) =>
                              ftu.fieldId === f.fieldId
                          ).length === 0
                        )
                          executeSelection = false;

                        if (
                          (isFieldInFilteredMetrics(
                            metricCategoryId,
                            fieldGroupId,
                            f.fieldId
                          ) ||
                            (filteredMetrics &&
                              filteredMetrics.length === 0)) &&
                          executeSelection
                        )
                          return {
                            ...f,
                            checked:
                              disabledMetrics &&
                              qty !== -1 &&
                              fieldsToUpdate.length > 0 &&
                              fieldsToUpdate.filter(
                                (ftu: AccordionMetricField) =>
                                  ftu.fieldId === f.fieldId
                              ).length > 0
                                ? !f.checked
                                : filteredSubcategory &&
                                  isFieldInFilteredMetrics(
                                    metricCategoryId,
                                    sub.fieldGroupId,
                                    f.fieldId
                                  )
                                ? !filteredSubcategory.selectAllGroup
                                : !sub.selectAllGroup,
                          };
                        else return f;
                      }),
                    };

                    if (tmpSub.fields) {
                      tmpSub.selectAllGroup = tmpSub.fields.every(
                        (tmpSubField: AccordionMetricField) =>
                          tmpSubField.checked
                      );

                      tmpSub.selectedFields = tmpSub.fields.reduce(
                        (prev: number, curr: AccordionMetricField) => {
                          return curr.checked ? ++prev : prev;
                        },
                        0
                      );
                    }

                    return tmpSub;
                  } else {
                    return sub;
                  }
                }
              ),
            };

            if (fieldsToUpdate.length > 0) {
              fieldsToUnselect = fieldsToUpdate;
              handleUnSelectAllField(fieldsToUpdate);
            } else if (tmpFieldGroupsToUpdate.length > 0) {
              fieldGroupsToSelect = tmpFieldGroupsToUpdate;
              handleSelectedAllFields(tmpFieldGroupsToUpdate);
            }

            if (tmpMetricCategory.fieldGroups) {
              tmpMetricCategory.selectAll =
                tmpMetricCategory.fieldGroups?.every(
                  (fgr: AccordionMetricFieldGroup) => fgr.selectAllGroup
                );

              tmpMetricCategory.selectedFields =
                tmpMetricCategory.fieldGroups.reduce(
                  (prev: number, curr: AccordionMetricFieldGroup) => {
                    return prev + curr.selectedFields;
                  },
                  0
                );
            }

            return tmpMetricCategory;
          }
          return m;
        });

        setSortedMetrics(tmpSortedMetrics);
      } else if (isGrouped && hasSubcategories) {
        let tmpSortedMetrics: any = sortedMetrics?.map((m: AccordionMetric) => {
          if (m.metricCategoryId === metricCategoryId) {
            let innerCounter = 0;
            let filteredMetricCategory =
              filteredMetrics && filteredMetrics.length > 0
                ? filteredMetrics.filter(
                    (fm: AccordionMetric) =>
                      fm.metricCategoryId === metricCategoryId
                  )[0]
                : null;

            let tmpFieldGroupsToUpdate: {
              fields: AccordionMetricField[];
              fieldGroupName: string;
            }[] = [];

            let fieldsToUpdate: AccordionMetricField[] = [];
            let tmpMetricCategory = {
              ...m,
              fieldGroups: m.fieldGroups?.map(
                (sub: AccordionMetricFieldGroup) => {
                  let filteredSubcategory =
                    filteredMetricCategory &&
                    filteredMetricCategory.fieldGroups &&
                    isFieldGroupInFilteredMetrics(
                      metricCategoryId,
                      sub.fieldGroupId
                    )
                      ? filteredMetricCategory.fieldGroups.filter(
                          (fmg: AccordionMetricFieldGroup) =>
                            fmg.fieldGroupId === sub.fieldGroupId
                        )[0]
                      : null;

                  if (
                    (m.selectAll &&
                      (isFullMetricInFilteredMetrics(
                        metricCategoryId,
                        sub.fieldGroupId
                      ) ||
                        (filteredMetrics && filteredMetrics.length === 0))) ||
                    (filteredMetricCategory &&
                      filteredSubcategory &&
                      filteredMetricCategory.selectAll) ||
                    (qty !== -1 && disabledMetrics)
                  ) {
                    fieldsToUpdate =
                      filteredSubcategory && filteredSubcategory.fields
                        ? fieldsToUpdate.concat(filteredSubcategory.fields)
                        : fieldsToUpdate.concat(sub.fields ?? []);

                    fieldsToUpdate = fieldsToUpdate.filter(
                      (ftup: AccordionMetricField) =>
                        selectedMetrics.find(
                          (v: SelectedField) => v.fieldName === ftup.fieldName
                        )
                    );
                  } else if (sub.fields) {
                    let remainingFields =
                      qty > innerCounter ? qty - innerCounter : 0;
                    if (
                      isFieldGroupInFilteredMetrics(
                        metricCategoryId,
                        sub.fieldGroupId
                      )
                    ) {
                      tmpFieldGroupsToUpdate.push({
                        fields:
                          qty === -1 &&
                          filteredSubcategory &&
                          filteredSubcategory.fields
                            ? filteredSubcategory.fields
                            : qty !== -1 &&
                              innerCounter < qty &&
                              filteredSubcategory &&
                              filteredSubcategory.fields
                            ? filteredSubcategory.fields.filter(
                                (v: AccordionMetricField, i: number) => {
                                  if (
                                    categoryHasSelected(sub) &&
                                    remainingFields > 0 &&
                                    !selectedMetrics.find(
                                      (sm: SelectedField) =>
                                        sm.fieldName === v.fieldName
                                    )
                                  ) {
                                    remainingFields--;
                                    return v;
                                  } else if (!categoryHasSelected(sub)) {
                                    return i < qty - innerCounter;
                                  }
                                }
                              )
                            : [],
                        fieldGroupName: sub.fieldGroupName,
                      });
                    } else if (filteredMetrics?.length === 0) {
                      tmpFieldGroupsToUpdate.push({
                        fields:
                          qty === -1
                            ? sub.fields
                            : innerCounter < qty
                            ? sub.fields.filter(
                                (v: AccordionMetricField, i: number) => {
                                  if (
                                    categoryHasSelected(sub) &&
                                    remainingFields > 0 &&
                                    !selectedMetrics.find(
                                      (sm: SelectedField) =>
                                        sm.fieldName === v.fieldName
                                    )
                                  ) {
                                    remainingFields--;
                                    return v;
                                  } else if (!categoryHasSelected(sub)) {
                                    return i < qty - innerCounter;
                                  }
                                }
                              )
                            : [],
                        fieldGroupName: sub.fieldGroupName,
                      });
                    }
                  }

                  if (
                    filteredSubcategory ||
                    (filteredMetrics && filteredMetrics.length === 0)
                  ) {
                    let tmpSelected =
                      isFullMetricInFilteredMetrics(
                        metricCategoryId,
                        sub.fieldGroupId
                      ) ||
                      (filteredMetrics && filteredMetrics.length === 0)
                        ? !m.selectAll
                        : m.selectAll;

                    let tmpSub = {
                      ...sub,
                      selectAllGroup: tmpSelected,
                      fields: sub.fields?.map((f: AccordionMetricField) => {
                        let executeSelection = true;
                        if (qty !== -1 && tmpFieldGroupsToUpdate.length > 0) {
                          let fgtoUpdate = tmpFieldGroupsToUpdate.filter(
                            (val: {
                              fields: AccordionMetricField[];
                              fieldGroupName: string;
                            }) => val.fieldGroupName === sub.fieldGroupName
                          );

                          if (
                            fgtoUpdate.length > 0 &&
                            fgtoUpdate[0].fields.filter(
                              (af: AccordionMetricField) =>
                                af.fieldId === f.fieldId
                            ).length > 0
                          )
                            innerCounter++;
                          else executeSelection = false;
                        }
                        if (
                          disabledMetrics &&
                          qty !== -1 &&
                          fieldsToUpdate.length > 0 &&
                          fieldsToUpdate.filter(
                            (ftu: AccordionMetricField) =>
                              ftu.fieldId === f.fieldId
                          ).length === 0
                        )
                          executeSelection = false;

                        if (
                          ((filteredMetrics && filteredMetrics.length === 0) ||
                            isFieldInFilteredMetrics(
                              metricCategoryId,
                              sub.fieldGroupId,
                              f.fieldId
                            )) &&
                          executeSelection
                        ) {
                          return {
                            ...f,
                            checked:
                              disabledMetrics &&
                              qty !== -1 &&
                              fieldsToUpdate.length > 0 &&
                              fieldsToUpdate.filter(
                                (ftu: AccordionMetricField) =>
                                  ftu.fieldId === f.fieldId
                              ).length > 0
                                ? !f.checked
                                : filteredSubcategory &&
                                  isFieldInFilteredMetrics(
                                    metricCategoryId,
                                    sub.fieldGroupId,
                                    f.fieldId
                                  ) &&
                                  filteredMetricCategory &&
                                  filteredSubcategory
                                ? !filteredMetricCategory.selectAll
                                : tmpSelected,
                          };
                        }
                        return f;
                      }),
                    };

                    if (tmpSub.fields) {
                      tmpSub.selectAllGroup = tmpSub.fields.every(
                        (tmpSubField: AccordionMetricField) =>
                          tmpSubField.checked
                      );
                      tmpSub.selectedFields = tmpSub.fields.reduce(
                        (prev: number, curr: AccordionMetricField) => {
                          return curr.checked ? ++prev : prev;
                        },
                        0
                      );
                    }

                    return tmpSub;
                  }

                  return sub;
                }
              ),
            };

            if (fieldsToUpdate.length > 0) {
              fieldsToUnselect = fieldsToUpdate;
              handleUnSelectAllField(fieldsToUpdate);
            } else if (tmpFieldGroupsToUpdate.length > 0) {
              fieldGroupsToSelect = tmpFieldGroupsToUpdate;
              handleSelectedAllFields(tmpFieldGroupsToUpdate);
            }

            if (tmpMetricCategory.fieldGroups) {
              tmpMetricCategory.selectAll = tmpMetricCategory.fieldGroups.every(
                (tmpFG: AccordionMetricFieldGroup) => tmpFG.selectAllGroup
              );
              tmpMetricCategory.selectedFields =
                tmpMetricCategory.fieldGroups.reduce(
                  (prev: number, curr: AccordionMetricFieldGroup) => {
                    return prev + curr.selectedFields;
                  },
                  0
                );
            }

            return tmpMetricCategory;
          }
          return m;
        });
        setSortedMetrics(tmpSortedMetrics);
      } else {
        let tmpSortedMetrics: any = sortedMetrics?.map((m: AccordionMetric) => {
          if (m.metricCategoryId === metricCategoryId) {
            let filteredMetricCategory =
              filteredMetrics && filteredMetrics.length > 0
                ? filteredMetrics.filter(
                    (fm: AccordionMetric) =>
                      fm.metricCategoryId === metricCategoryId
                  )[0]
                : null;

            let tmpFieldGroupsToUpdate: {
              fields: AccordionMetricField[];
              fieldGroupName: string;
            }[] = [];
            let fieldsToUpdate: AccordionMetricField[] = [];

            if (
              (m.selectAll &&
                m.fields &&
                filteredMetrics &&
                filteredMetrics.length === 0) ||
              (m.fields &&
                m.selectAll &&
                isFullMetricInFilteredMetrics(metricCategoryId, -1)) ||
              (filteredMetricCategory && filteredMetricCategory.selectAll) ||
              (disabledMetrics && qty !== -1)
            ) {
              fieldsToUpdate =
                filteredMetricCategory && filteredMetricCategory.fields
                  ? filteredMetricCategory.fields
                  : m.fields
                  ? m.fields
                  : [];

              fieldsToUpdate = fieldsToUpdate.filter(
                (ftup: AccordionMetricField) =>
                  selectedMetrics.find(
                    (v: SelectedField) => v.fieldName === ftup.fieldName
                  )
              );
              fieldsToUnselect = fieldsToUpdate;
              handleUnSelectAllField(fieldsToUpdate);
            } else if (m.fields) {
              let remainingFields = qty;
              tmpFieldGroupsToUpdate.push({
                fields:
                  qty === -1
                    ? filteredMetricCategory && filteredMetricCategory.fields
                      ? filteredMetricCategory.fields
                      : m.fields
                    : qty !== -1 && qty > 0
                    ? filteredMetricCategory && filteredMetricCategory.fields
                      ? filteredMetricCategory.fields.filter(
                          (v: AccordionMetricField, i: number) => {
                            if (
                              categoryHasSelected(m) &&
                              remainingFields > 0 &&
                              !selectedMetrics.find(
                                (sm: SelectedField) =>
                                  sm.fieldName === v.fieldName
                              )
                            ) {
                              remainingFields--;
                              return v;
                            } else if (!categoryHasSelected(m)) {
                              return i < qty;
                            }
                          }
                        )
                      : m.fields.filter(
                          (v: AccordionMetricField, i: number) => {
                            if (
                              categoryHasSelected(m) &&
                              remainingFields > 0 &&
                              !selectedMetrics.find(
                                (sm: SelectedField) =>
                                  sm.fieldName === v.fieldName
                              )
                            ) {
                              remainingFields--;
                              return true;
                            } else if (!categoryHasSelected(m)) {
                              return i < qty;
                            }
                          }
                        )
                    : [],
                fieldGroupName: "",
              });
              fieldGroupsToSelect = tmpFieldGroupsToUpdate;
              handleSelectedAllFields(tmpFieldGroupsToUpdate);
            }
            let tmpMetricCategory = {
              ...m,
              fields: m.fields?.map((f: AccordionMetricField) => {
                let executeSelection = true;

                if (
                  (qty !== -1 &&
                    tmpFieldGroupsToUpdate.length > 0 &&
                    tmpFieldGroupsToUpdate[0].fields.filter(
                      (af: AccordionMetricField) => af.fieldId === f.fieldId
                    ).length === 0) ||
                  (disabledMetrics &&
                    qty !== -1 &&
                    fieldsToUpdate.length > 0 &&
                    fieldsToUpdate.filter(
                      (ftu: AccordionMetricField) => ftu.fieldId === f.fieldId
                    ).length === 0)
                )
                  executeSelection = false;

                if (
                  (isFieldInFilteredMetrics(metricCategoryId, -1, f.fieldId) ||
                    (filteredMetrics && filteredMetrics.length === 0)) &&
                  executeSelection
                ) {
                  return {
                    ...f,
                    checked:
                      disabledMetrics &&
                      qty !== -1 &&
                      fieldsToUpdate.length > 0 &&
                      fieldsToUpdate.filter(
                        (ftu: AccordionMetricField) => ftu.fieldId === f.fieldId
                      ).length > 0
                        ? !f.checked
                        : filteredMetricCategory &&
                          isFieldInFilteredMetrics(
                            metricCategoryId,
                            -1,
                            f.fieldId
                          )
                        ? !filteredMetricCategory.selectAll
                        : !m.selectAll,
                  };
                } else return f;
              }),
            };

            tmpMetricCategory.selectedFields = tmpMetricCategory.fields
              ? tmpMetricCategory.fields.reduce(
                  (prevValue: number, current: AccordionMetricField) => {
                    return current.checked ? ++prevValue : prevValue;
                  },
                  0
                )
              : 0;

            tmpMetricCategory.selectAll = tmpMetricCategory.fields
              ? tmpMetricCategory.fields.every(
                  (tmcf: AccordionMetricField) => tmcf.checked
                )
              : false;

            return tmpMetricCategory;
          }
          return m;
        });

        setSortedMetrics(tmpSortedMetrics);
      }
    } else {
      if (isGrouped) {
        let tmpSortedMetrics: any = sortedMetrics?.map((m: AccordionMetric) => {
          if (m.metricCategoryId === metricCategoryId) {
            let tmpMetricCategory = {
              ...m,

              fieldGroups: m.fieldGroups?.map(
                (sub: AccordionMetricFieldGroup) => {
                  if (sub.fieldGroupId === fieldGroupId) {
                    let tmpFieldGroup = {
                      ...sub,
                      selectAllGroup: isSelectedFields(sub.fields, factorId),
                      fields: sub.fields?.map((f: any) => {
                        if (f.fieldId === factorId) {
                          if (f.checked) {
                            handleUnSelectField(f.fieldName);
                          } else {
                            handleSelectedField(f, fieldGroupName);
                          }
                          return {
                            ...f,
                            checked: !f.checked,
                          };
                        }

                        return f;
                      }),
                    };
                    tmpFieldGroup.selectedFields = tmpFieldGroup.fields
                      ? tmpFieldGroup.fields.reduce(
                          (
                            prevValue: number,
                            current: AccordionMetricField
                          ) => {
                            return current.checked ? ++prevValue : prevValue;
                          },
                          0
                        )
                      : 0;
                    return tmpFieldGroup;
                  }
                  return sub;
                }
              ),
            };

            if (tmpMetricCategory.fieldGroups) {
              tmpMetricCategory.selectAll =
                tmpMetricCategory.fieldGroups?.every(
                  (fgr: AccordionMetricFieldGroup) => fgr.selectAllGroup
                );

              tmpMetricCategory.selectedFields =
                tmpMetricCategory.fieldGroups.reduce(
                  (prev: number, curr: AccordionMetricFieldGroup) => {
                    return prev + curr.selectedFields;
                  },
                  0
                );
            }

            return tmpMetricCategory;
          }
          return m;
        });

        setSortedMetrics(tmpSortedMetrics);
      } else {
        let tmpSortedMetrics: any = sortedMetrics?.map((m: AccordionMetric) => {
          if (m.metricCategoryId === metricCategoryId) {
            let tmpMetricCat = {
              ...m,
              selectAll: isSelectedFields(m.fields, factorId),
              fields: m.fields?.map((f: AccordionMetricField) => {
                if (f.fieldId === factorId) {
                  if (f.checked) {
                    handleUnSelectField(f.fieldName);
                  } else {
                    handleSelectedField(f, fieldGroupName);
                  }
                  return {
                    ...f,
                    checked: !f.checked,
                  };
                }
                return f;
              }),
            };

            tmpMetricCat.selectedFields = tmpMetricCat.fields
              ? tmpMetricCat.fields.reduce(
                  (prevValue: number, current: AccordionMetricField) => {
                    return current.checked ? ++prevValue : prevValue;
                  },
                  0
                )
              : 0;

            return tmpMetricCat;
          }

          return m;
        });
        setSortedMetrics(tmpSortedMetrics);
      }
    }
    if (filteredMetrics && filteredMetrics?.length > 0) {
      handleOnchangeForFiltered(
        metricCategoryId,
        fieldGroupId,
        fieldGroupName,
        factorId,
        isTriggerBySelectAll,
        isGrouped,
        hasSubcategories,
        fieldsToUnselect,
        fieldGroupsToSelect
      );
    }
  };

  const isFullMetricInFilteredMetrics = (
    metricCategoryId: number,
    fieldGroupId: number
  ) => {
    let tmpFilteredMetric =
      filteredMetrics &&
      filteredMetrics.find(
        (fm: AccordionMetric) => fm.metricCategoryId === metricCategoryId
      );
    let tmpSortedMetrics =
      sortedMetrics &&
      sortedMetrics.find(
        (fm: AccordionMetric) => fm.metricCategoryId === metricCategoryId
      );

    if (fieldGroupId !== -1) {
      let fieldGroupFiltered = tmpFilteredMetric?.fieldGroups?.find(
        (fg: AccordionMetricFieldGroup) => fg.fieldGroupId === fieldGroupId
      );
      let fieldGroupSorted = tmpSortedMetrics?.fieldGroups?.find(
        (fg: AccordionMetricFieldGroup) => fg.fieldGroupId === fieldGroupId
      );

      if (
        fieldGroupFiltered?.fields?.length === fieldGroupSorted?.fields?.length
      )
        return true;
    } else {
      if (
        tmpFilteredMetric?.fields?.length === tmpSortedMetrics?.fields?.length
      )
        return true;
    }
    return false;
  };

  const isFieldGroupInFilteredMetrics = (
    metricCategoryId: number,
    fieldGroupId: number
  ) => {
    if (filteredMetrics && filteredMetrics.length > 0) {
      let tmpFilteredMetric =
        filteredMetrics &&
        filteredMetrics.find(
          (fm: AccordionMetric) => fm.metricCategoryId === metricCategoryId
        );
      let fieldGroupFiltered = tmpFilteredMetric?.fieldGroups?.find(
        (fg: AccordionMetricFieldGroup) => fg.fieldGroupId === fieldGroupId
      );
      if (fieldGroupFiltered) return true;
    }
    return false;
  };

  const isFieldInFilteredMetrics = (
    metricCategoryId: number,
    fieldGroupId: number,
    fieldId: number
  ) => {
    if (filteredMetrics && filteredMetrics.length > 0) {
      let field = null;
      let tmpMetric = filteredMetrics.find(
        (fm: AccordionMetric) => fm.metricCategoryId === metricCategoryId
      );
      if (fieldGroupId !== -1) {
        let fieldGroup = tmpMetric?.fieldGroups?.find(
          (fg: AccordionMetricFieldGroup) => fg.fieldGroupId === fieldGroupId
        );
        field = fieldGroup?.fields?.find(
          (f: AccordionMetricField) => f.fieldId === fieldId
        );
      } else {
        field = tmpMetric?.fields?.find(
          (f: AccordionMetricField) => f.fieldId === fieldId
        );
      }
      if (field) return true;
    }
    return false;
  };

  const handleOnchangeForFiltered = (
    metricCategoryId: number,
    fieldGroupId: number,
    fieldGroupName: string,
    factorId: number,
    isTriggerBySelectAll: boolean,
    isGrouped: boolean,
    hasSubcategories: boolean,
    fieldsToUnselect: AccordionMetricField[],
    fieldGroupToSelect: {
      fields: AccordionMetricField[];
      fieldGroupName: string;
    }[] = []
  ) => {
    if (isTriggerBySelectAll) {
      if (isGrouped && !hasSubcategories) {
        let tmpFilteredMetrics: any = filteredMetrics?.map(
          (m: AccordionMetric) => {
            if (m.metricCategoryId === metricCategoryId) {
              let tmpMetricCategory = {
                ...m,
                fieldGroups: m.fieldGroups?.map(
                  (sub: AccordionMetricFieldGroup) => {
                    if (sub.fieldGroupId === fieldGroupId) {
                      let tmpSub = {
                        ...sub,
                        fields: sub.fields?.map((f: AccordionMetricField) => {
                          let tmpChecked = f.checked;
                          if (
                            fieldsToUnselect.length > 0 &&
                            fieldsToUnselect.find(
                              (ftu: AccordionMetricField) =>
                                ftu.fieldName === f.fieldName
                            )
                          )
                            tmpChecked = false;
                          else if (fieldGroupToSelect.length > 0) {
                            let fgroup = fieldGroupToSelect.filter(
                              (fg: {
                                fields: AccordionMetricField[];
                                fieldGroupName: string;
                              }) => fg.fieldGroupName === sub.fieldGroupName
                            );
                            if (
                              fgroup.length > 0 &&
                              fgroup[0].fields.find(
                                (fgf: AccordionMetricField) =>
                                  fgf.fieldName === f.fieldName
                              )
                            )
                              tmpChecked = true;
                          }

                          return {
                            ...f,
                            checked: tmpChecked,
                          };
                        }),
                      };

                      if (tmpSub.fields) {
                        tmpSub.selectAllGroup = tmpSub.fields.every(
                          (tmpSubField: AccordionMetricField) =>
                            tmpSubField.checked
                        );
                        tmpSub.selectedFields = tmpSub.fields.reduce(
                          (prev: number, curr: AccordionMetricField) => {
                            return curr.checked ? ++prev : prev;
                          },
                          0
                        );
                      }

                      return tmpSub;
                    }
                    return sub;
                  }
                ),
              };

              if (tmpMetricCategory.fieldGroups) {
                tmpMetricCategory.selectAll =
                  tmpMetricCategory.fieldGroups?.every(
                    (fgr: AccordionMetricFieldGroup) => fgr.selectAllGroup
                  );

                tmpMetricCategory.selectedFields =
                  tmpMetricCategory.fieldGroups.reduce(
                    (prev: number, curr: AccordionMetricFieldGroup) => {
                      return prev + curr.selectedFields;
                    },
                    0
                  );
              }

              return tmpMetricCategory;
            }
            return m;
          }
        );

        setFilteredMetrics(tmpFilteredMetrics);
      } else if (isGrouped && hasSubcategories) {
        let tmpFilteredMetrics: any = filteredMetrics?.map(
          (m: AccordionMetric) => {
            if (m.metricCategoryId === metricCategoryId) {
              let tmpMetricCategory = {
                ...m,
                fieldGroups: m.fieldGroups?.map(
                  (sub: AccordionMetricFieldGroup) => {
                    let tmpSub = {
                      ...sub,
                      fields: sub.fields?.map((f: any) => {
                        let tmpChecked = f.checked;
                        if (
                          fieldsToUnselect.length > 0 &&
                          fieldsToUnselect.find(
                            (ftu: AccordionMetricField) =>
                              ftu.fieldName === f.fieldName
                          )
                        )
                          tmpChecked = false;
                        else if (fieldGroupToSelect.length > 0) {
                          let fgroup = fieldGroupToSelect.filter(
                            (fg: {
                              fields: AccordionMetricField[];
                              fieldGroupName: string;
                            }) => fg.fieldGroupName === sub.fieldGroupName
                          );
                          if (
                            fgroup.length > 0 &&
                            fgroup[0].fields.find(
                              (fgf: AccordionMetricField) =>
                                fgf.fieldName === f.fieldName
                            )
                          )
                            tmpChecked = true;
                        }

                        return {
                          ...f,
                          checked: tmpChecked,
                        };
                      }),
                    };

                    if (tmpSub.fields) {
                      tmpSub.selectAllGroup = tmpSub.fields.every(
                        (tmpSubField: AccordionMetricField) =>
                          tmpSubField.checked
                      );
                      tmpSub.selectedFields = tmpSub.fields.reduce(
                        (prev: number, curr: AccordionMetricField) => {
                          return curr.checked ? ++prev : prev;
                        },
                        0
                      );
                    }

                    return tmpSub;
                  }
                ),
              };

              if (tmpMetricCategory.fieldGroups) {
                tmpMetricCategory.selectAll =
                  tmpMetricCategory.fieldGroups.every(
                    (tmpFG: AccordionMetricFieldGroup) => tmpFG.selectAllGroup
                  );

                tmpMetricCategory.selectedFields =
                  tmpMetricCategory.fieldGroups.reduce(
                    (prev: number, curr: AccordionMetricFieldGroup) => {
                      return prev + curr.selectedFields;
                    },
                    0
                  );
              }

              return tmpMetricCategory;
            }
            return m;
          }
        );

        setFilteredMetrics(tmpFilteredMetrics);
      } else {
        let tmpSortedMetrics: AccordionMetric[] = sortedMetrics
          ? [...sortedMetrics]
          : [];
        let tmpFilteredMetrics: any = filteredMetrics?.map(
          (m: AccordionMetric) => {
            if (m.metricCategoryId === metricCategoryId) {
              let tmpMetricCat = {
                ...m,
                fields: m.fields?.map((f: AccordionMetricField) => {
                  let tmpChecked = f.checked;
                  if (
                    fieldsToUnselect.length > 0 &&
                    fieldsToUnselect.find(
                      (ftu: AccordionMetricField) =>
                        ftu.fieldName === f.fieldName
                    )
                  )
                    tmpChecked = false;
                  else if (
                    fieldGroupToSelect.length > 0 &&
                    fieldGroupToSelect[0].fields.find(
                      (fgf: AccordionMetricField) =>
                        fgf.fieldName === f.fieldName
                    )
                  )
                    tmpChecked = true;

                  return {
                    ...f,
                    checked: tmpChecked,
                  };
                }),
              };

              tmpMetricCat.selectAll = tmpMetricCat.fields
                ? tmpMetricCat.fields.every(
                    (tmcf: AccordionMetricField) => tmcf.checked
                  )
                : false;

              tmpMetricCat.selectedFields = tmpMetricCat.fields
                ? tmpMetricCat.fields.reduce(
                    (prevValue: number, current: AccordionMetricField) => {
                      return current.checked ? ++prevValue : prevValue;
                    },
                    0
                  )
                : 0;

              return tmpMetricCat;
            }
            return m;
          }
        );

        setFilteredMetrics(tmpFilteredMetrics);
      }
    } else {
      if (isGrouped) {
        let tmpSortedMetrics: any = filteredMetrics?.map(
          (m: AccordionMetric) => {
            if (m.metricCategoryId === metricCategoryId) {
              let tmpMetric = {
                ...m,
                fieldGroups: m.fieldGroups?.map(
                  (sub: AccordionMetricFieldGroup) => {
                    if (sub.fieldGroupId === fieldGroupId) {
                      let tmpSub = {
                        ...sub,
                        selectAllGroup: isSelectedFields(sub.fields, factorId),
                        fields: sub.fields?.map((f: any) => {
                          if (f.fieldId === factorId) {
                            return {
                              ...f,
                              checked: !f.checked,
                            };
                          }

                          return f;
                        }),
                      };

                      if (tmpSub.fields)
                        tmpSub.selectedFields = tmpSub.fields.reduce(
                          (prev: number, curr: AccordionMetricField) => {
                            return curr.checked ? ++prev : prev;
                          },
                          0
                        );

                      return tmpSub;
                    }

                    return sub;
                  }
                ),
              };

              if (tmpMetric.fieldGroups) {
                tmpMetric.selectAll = tmpMetric.fieldGroups.every(
                  (tmpMetricFieldGroup: AccordionMetricFieldGroup) =>
                    tmpMetricFieldGroup.selectAllGroup
                );
                tmpMetric.selectedFields = tmpMetric.fieldGroups.reduce(
                  (prev: number, curr: AccordionMetricFieldGroup) => {
                    return prev + curr.selectedFields;
                  },
                  0
                );
              }

              return tmpMetric;
            }
            return m;
          }
        );
        setFilteredMetrics(tmpSortedMetrics);
      } else {
        let tmpSortedMetrics: any = filteredMetrics?.map(
          (m: AccordionMetric) => {
            if (m.metricCategoryId === metricCategoryId) {
              let tmpMetric = {
                ...m,
                selectAll: isSelectedFields(m.fields, factorId),
                fields: m.fields?.map((f: AccordionMetricField) => {
                  if (f.fieldId === factorId) {
                    return {
                      ...f,
                      checked: !f.checked,
                    };
                  }
                  return f;
                }),
              };

              tmpMetric.selectedFields = tmpMetric.fields
                ? tmpMetric.fields.reduce(
                    (prevValue: number, current: AccordionMetricField) => {
                      return current.checked ? ++prevValue : prevValue;
                    },
                    0
                  )
                : 0;

              return tmpMetric;
            }
            return m;
          }
        );
        setFilteredMetrics(tmpSortedMetrics);
      }
    }
  };

  const isSelectedFields = (
    fields: AccordionMetricField[] = [],
    fieldId: number
  ) => {
    let fieldsUnchecked = fields?.filter(
      (f: AccordionMetricField) => !f.checked
    );
    if (
      fieldsUnchecked.length !== 1 ||
      (fieldsUnchecked.length === 1 && fieldsUnchecked[0].fieldId !== fieldId)
    )
      return false;
    else return true;
  };

  const handleSelectedField = (
    field: AccordionMetricField,
    fieldGroupName: string
  ) => {
    let tmpSelectedField: SelectedField = {
      fieldName: field.fieldName,
      fieldGroupName: fieldGroupName.length > 0 ? fieldGroupName : null,
      displayOrder: selectedMetrics.length + 1,
    };
    let tmpSelectedMetrics: SelectedField[] = [...selectedMetrics];
    tmpSelectedMetrics.push(tmpSelectedField);

    setSelectedMetrics(tmpSelectedMetrics);
    handleSelectedMetrics(tmpSelectedMetrics);
  };

  const handleUnSelectField = (fieldName: string) => {
    let tmpSelectedMetrics = selectedMetrics.filter(
      (f: SelectedField) => f.fieldName !== fieldName
    );

    setSelectedMetrics(tmpSelectedMetrics);
    handleSelectedMetrics(tmpSelectedMetrics);
  };
  const handleSelectedAllFields = (
    fieldsGrouped: {
      fields: AccordionMetricField[];
      fieldGroupName: string;
    }[]
  ) => {
    let tmpSelectedMetrics = [...selectedMetrics];
    fieldsGrouped.forEach(
      (fg: { fields: AccordionMetricField[]; fieldGroupName: string }) => {
        //Build selected fields
        let tmpAllFields: SelectedField[] = fg.fields.map(
          (f: AccordionMetricField) => {
            return {
              fieldName: f.fieldName,
              fieldGroupName:
                fg.fieldGroupName.length > 0 ? fg.fieldGroupName : null,
              displayOrder: 1,
            };
          }
        );

        tmpSelectedMetrics = tmpSelectedMetrics.concat(tmpAllFields);
      }
    );
    let notDuplicatedMetrics = tmpSelectedMetrics.reduce(
      (prev: SelectedField[], curr: SelectedField) =>
        prev.find((pr: SelectedField) => pr.fieldName === curr.fieldName)
          ? prev
          : [...prev, curr],
      []
    );

    setSelectedMetrics(notDuplicatedMetrics);
    handleSelectedMetrics(notDuplicatedMetrics);
  };

  const handleUnSelectAllField = (fields: AccordionMetricField[]) => {
    let allFieldsName = fields.map((f: AccordionMetricField) => f.fieldName);
    let tmpSelectedMetrics = selectedMetrics.filter(
      (f: SelectedField) =>
        !allFieldsName.includes(f.fieldName ? f.fieldName : "")
    );
    setSelectedMetrics(tmpSelectedMetrics);
    handleSelectedMetrics(tmpSelectedMetrics);
  };

  const getFactor = (
    metricCategoryId: number,
    fieldGroupId: number,
    fieldGroupName: string,
    factor: AccordionMetricField,
    i: number,
    isGrouped: boolean
  ) => (
    <div
      className={`${BLOCK}__item-checkbox`}
      key={`${factor.fieldId}_${factor.displayName}_${i}_${metricCategoryId}`}
    >
      <Checkbox
        label={factor.displayName}
        value={factor.checked || false}
        onChange={() => {
          if (!(disabledMetrics && !factor.checked))
            handleOnchange(
              metricCategoryId,
              fieldGroupId,
              fieldGroupName,
              factor.fieldId,
              false,
              isGrouped
            );
        }}
        isCheckboxBlue={factor.checked}
        labelChild={
          <Tooltip position={TooltipPosition.top}>
            {(factor.description as any) || "Description"}
          </Tooltip>
        }
        disabled={disabledMetrics && !factor.checked}
      />
    </div>
  );

  const categoryHasSelected = (category: any) => {
    return (
      (category &&
        category.fieldGroups &&
        category.fieldGroups?.some((fg: AccordionMetricFieldGroup) =>
          fg.fields?.some((field: any) => field.checked)
        )) ||
      (category && category.fields?.some((field: any) => field.checked))
    );
  };

  const getFields = (pmetric: AccordionMetric) => {
    return (
      <Fragment key={`category_fields_${pmetric.metricCategoryId}`}>
        <div className={`${BLOCK}__item-factors`}>
          {pmetric &&
            pmetric.fields
              ?.sort(compare)
              .map((factor: AccordionMetricField, i: number) =>
                getFactor(pmetric.metricCategoryId, -1, "", factor, i, false)
              )}
        </div>
      </Fragment>
    );
  };

  const handleExpandSubcategory = (
    metricCategoryId: number,
    fieldGroupId: number,
    pmetric: AccordionMetric
  ) => {
    let currentMetricsArray =
      filteredMetrics && filteredMetrics?.length > 0
        ? filteredMetrics
        : sortedMetrics;

    let tmpSortedMetrics = currentMetricsArray?.map((met: AccordionMetric) => {
      if (met.metricCategoryId === metricCategoryId) {
        return {
          ...pmetric,
          fieldGroups: pmetric.fieldGroups?.map(
            (subcategory: AccordionMetricFieldGroup) => {
              if (subcategory.fieldGroupId === fieldGroupId) {
                const tmpSubCategory = {
                  ...subcategory,
                  collapse: !subcategory.collapse,
                };

                if (!subcategory.collapse) {
                  dispatch(setExpandAllMetricsValue(false));
                }

                return tmpSubCategory;
              }
              return subcategory;
            }
          ),
        };
      }
      return met;
    });

    if (isAllMetricsOpen(tmpSortedMetrics || [])) {
      dispatch(setExpandAllMetricsValue(true));
    }

    if (filteredMetrics && filteredMetrics?.length > 0)
      setFilteredMetrics(tmpSortedMetrics);
    else setSortedMetrics(tmpSortedMetrics);
  };

  const isAllMetricsOpen = (metrics: AccordionMetric[]) => {
    const allSubCategoryOpen = metrics.every((met: AccordionMetric) => {
      if (met.fieldGroups) {
        return met.fieldGroups.every((sub) => !sub.collapse);
      }

      return true;
    });

    return metrics.length === openItems.length && allSubCategoryOpen;
  };

  const getSubcategoryCol = (
    metricCategoryId: number,
    sub: AccordionMetricFieldGroup
  ) => {
    let rowNum: number = sub.fields ? Math.ceil(sub.fields?.length / 2) : 1;
    let cols: any = [];
    let from: number = 0;
    for (let i = 0; i < 2; i++) {
      cols.push(
        <div
          key={`${sub.fieldGroupId}__${i}__${metricCategoryId}`}
          className={classNames(
            `${BLOCK}__item-factors ${BLOCK}__item-factors--wide`,
            {
              [`${BLOCK}__item-factors--hide`]: sub.collapse,
              [`${BLOCK}__item-factors--shadow`]: !Number.isInteger(i / 2),
            }
          )}
        >
          {sub.fields?.map((factor: AccordionMetricField, ind: number) => {
            if (ind >= from && ind < from + rowNum) {
              return getFactor(
                metricCategoryId,
                sub.fieldGroupId,
                sub.fieldGroupName,
                factor,
                i,
                true
              );
            } else {
              return null;
            }
          })}
        </div>
      );
      from += rowNum;
    }

    return cols;
  };

  const getFieldGroups = (pmetric: AccordionMetric) => {
    return (
      <div className={`${BLOCK}__subcategories-container`}>
        {pmetric &&
          pmetric.fieldGroups?.map(
            (sub: AccordionMetricFieldGroup, i: number) => (
              <Fragment key={`subcategory_container_${sub.fieldGroupId}`}>
                <div
                  className={`${BLOCK}__item-category ${BLOCK}__item-category--sub`}
                  key={`${sub.fieldGroupId}__${sub.displayName}__${i}`}
                >
                  <div
                    className={classNames(`${BLOCK}__item-category--sub-label`)}
                  >
                    <button
                      data-testid="fieldGroup"
                      className={`${BLOCK}__item-button ${BLOCK}__item-button--short`}
                      onClick={() => {
                        handleExpandSubcategory(
                          pmetric.metricCategoryId,
                          sub.fieldGroupId,
                          pmetric
                        );
                      }}
                    >
                      <Icon
                        name={sub.collapse ? "caret-right" : "caret-down"}
                        width={11}
                        height={11}
                      />
                    </button>
                    <div className={`${BLOCK}__subcategory-checkbox-container`}>
                      <Checkbox
                        label={`${sub.displayName} (${sub.fields?.length})`}
                        onChange={() => {
                          if (!(disabledMetrics && !categoryHasSelected(sub))) {
                            handleOnchange(
                              pmetric.metricCategoryId,
                              sub.fieldGroupId,
                              sub.fieldGroupName,
                              -1,
                              true,
                              true
                            );
                          }
                        }}
                        value={sub.selectAllGroup}
                        isCheckboxBlue={sub.selectAllGroup}
                        indeterminate={
                          !sub.selectAllGroup && categoryHasSelected(sub)
                        }
                        disabled={disabledMetrics && !categoryHasSelected(sub)}
                      />
                    </div>
                  </div>
                  <span>
                    <span className={`${BLOCK}__fields-count--green`}>
                      {`${sub.selectedFields}`}
                    </span>
                    <FormattedMessage
                      id="peerbenchmark.metrics.counter"
                      values={{ total: sub.fields?.length }}
                    />
                  </span>
                </div>
                {!sub.collapse && (
                  <div className={`${BLOCK}__item-factors-container`}>
                    {sub.fields &&
                      getSubcategoryCol(pmetric.metricCategoryId, sub)}
                  </div>
                )}
              </Fragment>
            )
          )}
      </div>
    );
  };

  const getTotalFieldsCount = (metric: AccordionMetric) => {
    let totalFieldsFieldGroup: number = metric.fieldGroups
      ? metric.fieldGroups.reduce(
          (prev: number, curr: AccordionMetricFieldGroup) =>
            prev + (curr.fields ? curr.fields.length : 0),
          0
        )
      : 0;
    let totalFields: number = metric.fields ? metric.fields.length : 0;
    return totalFieldsFieldGroup + totalFields;
  };
  const renderAccordionItems = (currentMetrics: AccordionMetric[]) => {
    return currentMetrics?.map((item: AccordionMetric, index: number) => (
      <div
        className={`${BLOCK}__item`}
        key={`${item.displayName}__${item.metricCategoryId}__${index}`}
      >
        <span className={`${BLOCK}__item-header`}>
          <button
            data-testid="expand-category-btn"
            className={classNames(
              `${BLOCK}__item-button ${BLOCK}__item-button--fit-content`
            )}
            onClick={() => {
              if (peerBenchmarkState.expandAllMetrics) {
                dispatch(setExpandAllMetricsValue(false));
              }

              if (peerBenchmarkState.collapseAllMetrics) {
                dispatch(setCollapseAllMetricsValue(false));
              }

              if (openItems.includes(item.metricCategoryId)) {
                let tmpOpenItems = openItems.filter(
                  (items) => items !== item.metricCategoryId
                );
                setOpenItems(tmpOpenItems);
                if (tmpOpenItems.length === 0) {
                  dispatch(setHasMetricOpenValue(false));
                }
              } else {
                setOpenItems([...openItems, item.metricCategoryId]);
                dispatch(setHasMetricOpenValue(true));
              }
            }}
          >
            <Icon
              name={
                peerBenchmarkState.expandAllMetrics ||
                openItems.includes(item.metricCategoryId)
                  ? "caret-down"
                  : "caret-right"
              }
              width={11}
              height={11}
            />
          </button>
          <div
            className={`${BLOCK}__item-category ${BLOCK}__item-category--main-category`}
          >
            <span className={classNames(`${BLOCK}__item-category--sub-label`)}>
              <Checkbox
                label={`${item.displayName} (${getTotalFieldsCount(item)})`}
                onChange={() => {
                  if (!(disabledMetrics && !categoryHasSelected(item))) {
                    if (item.fieldGroups && item.fieldGroups.length > 0)
                      handleOnchange(
                        item.metricCategoryId,
                        -1,
                        "",
                        -1,
                        true,
                        true,
                        true
                      );
                    else
                      handleOnchange(
                        item.metricCategoryId,
                        -1,
                        "",
                        -1,
                        true,
                        false
                      );
                  }
                }}
                value={item.selectAll || false}
                isCheckboxBlue={item.selectAll}
                indeterminate={!item.selectAll && categoryHasSelected(item)}
                disabled={disabledMetrics && !categoryHasSelected(item)}
              />
            </span>

            <span>
              <span
                className={`${BLOCK}__fields-count--green`}
              >{`${item.selectedFields}`}</span>
              <FormattedMessage
                id="peerbenchmark.metrics.counter"
                values={{ total: getTotalFieldsCount(item) }}
              />
            </span>
          </div>
        </span>
        <div
          className={classNames(`${BLOCK}__item-collapse`, {
            [`${BLOCK}__item-collapse--hide`]:
              !peerBenchmarkState.expandAllMetrics &&
              !openItems.includes(item.metricCategoryId),
          })}
          data-testid="collapsible-container"
        >
          <div className={`${BLOCK}__item-body`}>
            {item.fields && item.fields.length > 0 && getFields(item)}
            {item.fieldGroups &&
              item.fieldGroups.length > 0 &&
              getFieldGroups(item)}
          </div>
        </div>
      </div>
    ));
  };

  const compare = (field1: any, field2: any) => {
    if (field1.displayOrder < field2.displayOrder) {
      return -1;
    }

    if (field1.displayOrder > field2.displayOrder) {
      return 1;
    }

    return 0;
  };

  const handleFilterMetrics = () => {
    if (newFilter !== "") {
      let tmpSortedMetrics: AccordionMetric[] = JSON.parse(
        JSON.stringify(sortedMetrics)
      );
      let tmpMetricsFiltered: AccordionMetric[] = tmpSortedMetrics?.filter(
        (m: AccordionMetric) => {
          if (m.fieldGroups && m.fieldGroups?.length > 0) {
            m.fieldGroups = m.fieldGroups.filter(
              (fg: AccordionMetricFieldGroup) => {
                fg.fields = fg.fields?.filter((f: AccordionMetricField) =>
                  f.displayName.toLowerCase().includes(newFilter.toLowerCase())
                );

                return fg.fields && fg.fields?.length > 0;
              }
            );
          } else if (m.fields && m.fields.length > 0) {
            m.fields = m.fields?.filter((f: AccordionMetricField) =>
              f.displayName.toLowerCase().includes(newFilter.toLowerCase())
            );
          }

          return (
            (m.fields && m.fields?.length > 0) ||
            (m.fieldGroups && m.fieldGroups?.length > 0)
          );
        }
      );

      tmpMetricsFiltered.forEach((tm: AccordionMetric) => {
        if (tm.fields && tm.fields?.length > 0) {
          let uncheckedField = tm.fields.find(
            (mf: AccordionMetricField) => !mf.checked
          );
          if (!uncheckedField) tm.selectAll = true;
        } else if (tm.fieldGroups && tm.fieldGroups.length > 0) {
          tm.fieldGroups.forEach((fgm: AccordionMetricFieldGroup) => {
            if (fgm.fields && fgm.fields?.length > 0) {
              let uncheckedField = fgm.fields.find(
                (gf: AccordionMetricField) => !gf.checked
              );
              if (!uncheckedField) fgm.selectAllGroup = true;
            }
          });
        }
      });

      tmpMetricsFiltered.forEach((tm: AccordionMetric) => {
        if (tm.fields && tm.fields?.length > 0) {
          tm.selectedFields = tm.fields.reduce(
            (prev: number, curr: AccordionMetricField) => {
              return curr.checked ? ++prev : prev;
            },
            0
          );
        } else if (tm.fieldGroups && tm.fieldGroups.length > 0) {
          tm.fieldGroups.forEach((tfg: AccordionMetricFieldGroup) => {
            tfg.selectedFields = tfg.fields
              ? tfg.fields.reduce(
                  (prev: number, curr: AccordionMetricField) => {
                    return curr.checked ? ++prev : prev;
                  },
                  0
                )
              : 0;
          });
          tm.selectedFields = tm.fieldGroups.reduce(
            (prev: number, curr: AccordionMetricFieldGroup) => {
              return prev + curr.selectedFields;
            },
            0
          );
          tm.selectAll = tm.fieldGroups.every(
            (tfg: AccordionMetricFieldGroup) => tfg.selectAllGroup
          );
        }
      });

      setFilteredMetrics(tmpMetricsFiltered);
    } else {
      let tmpMetricsFiltered: any[] = [];
      setFilteredMetrics(tmpMetricsFiltered);
    }
  };

  return (
    <div
      className={`${BLOCK}`}
      key={`metricsAccordion`}
      data-testid="metrics-container"
    >
      {allMetricsLoaded &&
        newFilter !== "" &&
        filteredMetrics &&
        filteredMetrics?.length === 0 && (
          <div className={`${BLOCK}__not-results`}>
            <span>
              <FormattedMessage id="peer-benchmarks.search.metric.not.results" />
            </span>
          </div>
        )}
      {allMetricsLoaded &&
        sortedMetrics &&
        sortedMetrics.length > 0 &&
        newFilter === "" &&
        filteredMetrics &&
        filteredMetrics.length === 0 &&
        renderAccordionItems(sortedMetrics)}
      {allMetricsLoaded &&
        filteredMetrics &&
        filteredMetrics.length > 0 &&
        renderAccordionItems(filteredMetrics)}
    </div>
  );
};

export default MetricsAccordion;
