import { BENCHMARK_TYPE } from "utils/constants";
import api from "../api.service";
import { companyPagination } from "utils/constants";
import { AxiosRequestConfig } from "axios";

export const fetchAllMetrics = (payload: any) =>
  api.get(`benchmarks/${payload}/metrics`).then(({ data }) => data);

export const savePeerBenchmark = (payload: any) =>
  api.post(`benchmarks`, payload).then(({ data }) => data);

export const fetchDocumentFilter = (searchText: string) =>
  api
    .post(`search/companies`, {
      contentText: searchText,
      pageInfo: companyPagination,
      searchCompanies: "msci",
    })
    .then(({ data }) => data);

export const getCurrentPeerBenchmark = (payload: any) =>
  api.post(`benchmarkValues/peerbenchmark`, payload).then(({ data }) => data);

export const selectPeerBenchmarkPeriod = (payload: any) =>
  api.patch(`benchmarks/periodselection`, payload).then(({ data }) => data);

export const updatePeerBenchmarkName = (payload: any) =>
  api.put(`benchmarks/editbenchmarktitle`, payload).then(({ data }) => data);

export const fetchSuggestedPeers = (cikNumber: number) =>
  api.get(`company/${cikNumber}/suggestedPeers`).then(({ data }) => data);

export const fetchSavedPeerbenchmarks = (payload: any) =>
  api
    .post(`benchmarks/${BENCHMARK_TYPE.GET_SAVED_LIST}/list`, payload)
    .then(({ data }) => data);

export const sharePeerBenchmark = (payload: any) =>
  api.post(`benchmarks/share`, payload).then(({ data }) => data);
export const deletePeerBenchmark = (peerBenchmarkId: number) =>
  api.delete(`benchmarks/${peerBenchmarkId}`);

export const getBenchmarkMetadata = (
  benchmarkId: number,
  config?: AxiosRequestConfig
) => api.get(`benchmarks/${benchmarkId}`, config).then(({ data }) => data);

export const savePeerBenchmarkData = (benchmarkId: number, payload: any) =>
  api.put(`benchmarks/${benchmarkId}`, payload).then(({ data }) => data);

export const editPeerBenchmark = (payload: any) =>
  api
    .put(`benchmarks/${payload.benchmark.benchmarkId}`, payload)
    .then(({ data }) => data);

// TO DO: UPDATE GLOBAL SEARCH ENDPOINTS
export const saveCompanyGrouping = (payload: any) =>
  api.post(`globalcompanymappings`, payload).then(({ data }) => data);

export const fetchSavedGroupings = () =>
  api.get(`globalcompanymappings`).then(({ data }) => data);

export const deleteSavedGrouping = (savedGroupingId: number) =>
  api.delete(`globalcompanymappings/${savedGroupingId}`);

export const fetchGlobalCompaniesFilter = (
  searchText: string,
  isSECFiler: boolean
) =>
  api
    .post(`globalcompanies/list`, {
      contentText: searchText,
      pageInfo: companyPagination,
      searchCompanies: "msci,sec,cdp,snp",
      isSECFiler: isSECFiler,
    })
    .then(({ data }) => data);
