import { ReactElement, useEffect, useRef, useState } from "react";
import Button from "../button/button";
import TabbedContent from "../tabbed-content/tabbed-content";
import { setHyperLinks, useClickOutside } from "utils/functions";
import Parser from "html-react-parser";
import classNames from "classnames";
import { AssociatedMetricTooltips } from "services/dashboard/dashboard.model";
import useHandleTooltip from "utils/useHandleTooltip";
import { NORMAL_LINK_INDEX, SOURCE_REGEX } from "utils/constants";

type Props = {
  metricClassName: string;
  displayText: string | Element | ReactElement | JSX.Element[];
  content: AssociatedMetricTooltips[];
  customDisplayText?: string | Element | ReactElement;
  hideTabs?: boolean;
  buttonClassName?: string;
  onHoverToolTip?: boolean;
  onHoverToolTipValue?: string;
};

const Popover = ({
  metricClassName,
  displayText,
  content,
  customDisplayText,
  hideTabs,
  buttonClassName,
  onHoverToolTip,
  onHoverToolTipValue,
}: Props) => {
  const BLOCK = "popover";
  const HEADER_HEIGHT = 90;
  const ARROW_WIDTH = 28;
  const ARROW_HEIGHT = 24;
  const ARROW_OUTLINE_HEIGHT = 25;
  const ARROW_OUTLINE_WIDTH = 28;
  const ARROW_RIGHT = { clipPath: `polygon(0 0, 0 100%, 100% 50%)` };
  const ARROW_LEFT = { clipPath: `polygon(100% 100%, 100% 0, 0 50%)` };
  const ARROW_UP = { clipPath: `polygon(50% 0, 0 100%, 100% 100%)` };
  const ARROW_DOWN = { clipPath: `polygon(100% 0, 0 0, 50% 100%)` };
  const SPACING = 16;
  const POP_OVER_WIDTH = 600;
  const POP_OVER_HEIGHT = 540;

  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [popoverPosition, setPopoverPosition] = useState({});
  const [popoverArrowPosition, setPopoverArrowPosition] = useState({});
  const [popoverArrowOutlinePosition, setPopoverArrowOutlinePosition] =
    useState({});
  const [currentTab, setCurrentTab] = useState<number>(0);
  const popoverRef = useRef(null);
  const popupRef = useRef(null);
  useClickOutside(popoverRef, () => {
    setShowPopover(false);
    setCurrentTab(0);
  });
  const { handleMouseEnter, handleMouseLeave } = useHandleTooltip(BLOCK);
  /* istanbul ignore next */
  useEffect(() => {
    const popoverPosition = (
      popoverRef.current as any
    )?.getBoundingClientRect();

    if (showPopover && popoverPosition && popupRef && popupRef.current) {
      const popupPosition = (popupRef.current as any)?.getBoundingClientRect();
      let popupMaxHeight = POP_OVER_HEIGHT;
      if (popupPosition.height < POP_OVER_HEIGHT)
        popupMaxHeight = popupPosition.height;

      const middleXPopOverPosition =
        popoverPosition.left + popoverPosition.width / 2;
      const middleYPopOverPosition =
        popoverPosition.top + popoverPosition.height / 2;

      let tmpPopoverPosition: any = { visibility: "visible" };
      let tmpPopoverArrowPosition: any = { visibility: "visible" };
      let tmpPopoverArrowOutlinePosition: any = { visibility: "visible" };

      //Validate if tooltip position can be set at bottom
      if (
        (middleYPopOverPosition < window.innerHeight / 2 ||
          middleYPopOverPosition === window.innerHeight / 2) &&
        popoverPosition.bottom + SPACING + ARROW_HEIGHT + popupMaxHeight <
          window.innerHeight * 0.82
      ) {
        // To the right
        if (
          middleXPopOverPosition < window.innerWidth / 2 ||
          middleXPopOverPosition === window.innerWidth / 2
        ) {
          tmpPopoverPosition = {
            ...tmpPopoverPosition,
            top: popoverPosition.bottom + SPACING + ARROW_HEIGHT,
            left: popoverPosition.left,
          };

          tmpPopoverArrowPosition = {
            ...tmpPopoverArrowPosition,
            ...ARROW_UP,
            top: popoverPosition.bottom + SPACING + 2,
            left: middleXPopOverPosition - ARROW_WIDTH / 2,
          };
          tmpPopoverArrowOutlinePosition = {
            ...tmpPopoverArrowOutlinePosition,
            ...ARROW_UP,
            top: popoverPosition.bottom + SPACING,
            left: middleXPopOverPosition - ARROW_OUTLINE_WIDTH / 2,
          };
        }
        // To the left
        else {
          tmpPopoverPosition = {
            ...tmpPopoverPosition,
            top: popoverPosition.bottom + SPACING + ARROW_HEIGHT,
            left: popoverPosition.right - POP_OVER_WIDTH,
          };

          tmpPopoverArrowPosition = {
            ...tmpPopoverArrowPosition,
            ...ARROW_UP,
            top: popoverPosition.bottom + SPACING + 2,
            left: middleXPopOverPosition - ARROW_OUTLINE_WIDTH / 2,
          };
          tmpPopoverArrowOutlinePosition = {
            ...tmpPopoverArrowOutlinePosition,
            ...ARROW_UP,
            top: popoverPosition.bottom + SPACING,
            left: middleXPopOverPosition - ARROW_OUTLINE_WIDTH / 2,
          };
        }
      }
      //Validate if tooltip position can be set at top
      else if (
        middleYPopOverPosition > window.innerHeight / 2 &&
        popoverPosition.top - SPACING - ARROW_HEIGHT - popupMaxHeight >
          HEADER_HEIGHT
      ) {
        // To the right
        if (
          middleXPopOverPosition < window.innerWidth / 2 ||
          middleXPopOverPosition === window.innerWidth / 2
        ) {
          tmpPopoverPosition = {
            ...tmpPopoverPosition,
            top: popoverPosition.top - ARROW_HEIGHT - popupMaxHeight,
            left: popoverPosition.left,
          };

          tmpPopoverArrowPosition = {
            ...tmpPopoverArrowPosition,
            ...ARROW_DOWN,
            top: popoverPosition.top - SPACING - 10,
            left: middleXPopOverPosition - ARROW_WIDTH / 2,
          };
          tmpPopoverArrowOutlinePosition = {
            ...tmpPopoverArrowOutlinePosition,
            ...ARROW_DOWN,
            top: popoverPosition.top - SPACING - 8,
            left: middleXPopOverPosition - ARROW_OUTLINE_WIDTH / 2,
          };
        }
        // To the left
        else {
          tmpPopoverPosition = {
            ...tmpPopoverPosition,
            top: popoverPosition.top - ARROW_HEIGHT - popupMaxHeight,
            left: popoverPosition.right - POP_OVER_WIDTH,
          };

          tmpPopoverArrowPosition = {
            ...tmpPopoverArrowPosition,
            ...ARROW_DOWN,
            top: popoverPosition.top - SPACING - 10,
            left: middleXPopOverPosition - ARROW_OUTLINE_WIDTH / 2,
          };
          tmpPopoverArrowOutlinePosition = {
            ...tmpPopoverArrowOutlinePosition,
            ...ARROW_DOWN,
            top: popoverPosition.top - SPACING - 8,
            left: middleXPopOverPosition - ARROW_OUTLINE_WIDTH / 2,
          };
        }
      }
      //Validate if tooltip position can be set at right
      else if (
        middleXPopOverPosition < window.innerWidth / 2 ||
        middleXPopOverPosition === window.innerWidth / 2
      ) {
        tmpPopoverPosition = {
          ...tmpPopoverPosition,
          top:
            popoverPosition.bottom - popupMaxHeight < HEADER_HEIGHT
              ? HEADER_HEIGHT
              : popoverPosition.bottom - popupMaxHeight,
          left: popoverPosition.right + ARROW_WIDTH + SPACING,
        };

        tmpPopoverArrowPosition = {
          ...tmpPopoverArrowPosition,
          ...ARROW_LEFT,
          top:
            popoverPosition.top + popoverPosition.height / 2 - ARROW_HEIGHT / 2,
          left: popoverPosition.right + 4 + SPACING,
        };
        tmpPopoverArrowOutlinePosition = {
          ...tmpPopoverArrowOutlinePosition,
          ...ARROW_LEFT,
          top:
            popoverPosition.top +
            popoverPosition.height / 2 -
            ARROW_OUTLINE_HEIGHT / 2,
          left: popoverPosition.right + 1.5 + SPACING,
        };
      }
      //tooltip position set at left
      else {
        tmpPopoverPosition = {
          ...tmpPopoverPosition,
          top:
            popoverPosition.bottom - popupMaxHeight < HEADER_HEIGHT
              ? HEADER_HEIGHT
              : popoverPosition.bottom - popupMaxHeight,
          left: popoverPosition.left - SPACING - ARROW_WIDTH - POP_OVER_WIDTH,
        };

        tmpPopoverArrowPosition = {
          ...tmpPopoverArrowPosition,
          ...ARROW_RIGHT,
          top:
            popoverPosition.top + popoverPosition.height / 2 - ARROW_HEIGHT / 2,
          left: popoverPosition.left - 13 - SPACING * 2,
        };
        tmpPopoverArrowOutlinePosition = {
          ...tmpPopoverArrowOutlinePosition,
          ...ARROW_RIGHT,
          top:
            popoverPosition.top +
            popoverPosition.height / 2 -
            ARROW_OUTLINE_HEIGHT / 2,
          left: popoverPosition.left - 11.5 - SPACING * 2,
        };
      }

      // FOR AVOIDING BOTTOM CUTOFF
      if (popupPosition.height + HEADER_HEIGHT > window.innerHeight) {
        tmpPopoverPosition.height =
          window.innerHeight - window.innerHeight * 0.2;
        tmpPopoverPosition.overflow = "hidden";
      }

      // FOR AVOIDING LEFT AND RIGHT CUTOFF
      if (
        tmpPopoverPosition.left < 0 ||
        tmpPopoverPosition.left + SPACING + ARROW_WIDTH + POP_OVER_WIDTH >
          window.innerWidth
      ) {
        tmpPopoverPosition.left =
          window.innerWidth / 2 - popoverPosition.width / 2;
        tmpPopoverArrowPosition.visibility = "hidden";
        tmpPopoverArrowOutlinePosition.visibility = "hidden";
      }
      setPopoverPosition(tmpPopoverPosition);
      setPopoverArrowPosition(tmpPopoverArrowPosition);
      setPopoverArrowOutlinePosition(tmpPopoverArrowOutlinePosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover, popoverRef]);

  useEffect(() => {
    const insightDashboardContainer = document.getElementById(
      "insight-dashboard-container"
    );
    if (insightDashboardContainer) {
      const handleScroll = () => setShowPopover(false);
      document.addEventListener("scroll", handleScroll);

      return () => document.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const setSourceLink = (
    expression: RegExp,
    tooltipText: string,
    expressionIndex: number
  ) => {
    const replacer = new RegExp(expression, "g");
    const sourceLink = tooltipText && tooltipText.match(replacer);
    return sourceLink
      ? sourceLink.reduce((prev, curr) => {
          return prev.replace(
            replacer,
            NORMAL_LINK_INDEX.includes(expressionIndex)
              ? `<a style="word-break: break-all;" target="_blank" href="$1">$1</a>`
              : `<a class="button icon-text-button" style="width: fit-content;" target="_blank" href="$1">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 6C10.7761 6 11 6.22386 11 6.5C11 6.74546 10.8231 6.94961 10.5899 6.99194L10.5 7H7V17H17V13.5C17 13.2239 17.2239 13 17.5 13C17.7455 13 17.9496 13.1769 17.9919 13.4101L18 13.5V17.5C18 17.78 17.78 18 17.5 17.9933L6.5 18C6.22 18 6 17.78 6.00673 17.5L6 6.5C6 6.22 6.22 6.0001 6.5 6.0001L10.5 6ZM13.5 6L17.5428 6.00182C17.78 6.00365 18 6.22 18 6.5V10.5C18 10.7761 17.7761 11 17.5 11C17.2545 11 17.0504 10.8231 17.0081 10.5899L17 10.5V7.706L11.8536 12.8536C11.68 13.0271 11.4106 13.0464 11.2157 12.9114L11.1464 12.8536C10.9729 12.68 10.9536 12.4106 11.0886 12.2157L11.1464 12.1464L16.292 7H13.5C13.2545 7 13.0504 6.82312 13.0081 6.58988L13 6.5C13 6.22386 13.2239 6 13.5 6Z" fill="#007CB0"/></svg>
              Source
            </a>`
          );
        }, tooltipText)
      : tooltipText;
  };

  const tabItems = content
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map((t) => ({
      text: `popover.tab-text-${t.tooltipType}`,
      tabId: `popover.tab-id-${t.tooltipType}`,
      tabPanelId: `popover.tab-panel-${t.tooltipType}`,
      tabContent: t.associatedMetricTooltipText
        ? t.associatedMetricTooltipText
        : t.associatedMetricGroupTooltipText,
    }));

  return (
    <div
      ref={popoverRef}
      className={classNames(`${BLOCK}`, {})}
      data-test="pop-over"
    >
      {!onHoverToolTip && (
        <Button
          dataTest="display-text-button"
          className={classNames(`${BLOCK}__popup-metric ${buttonClassName}`, {
            [`${BLOCK}__popup-metric--hover`]:
              content.length &&
              content.every(
                (tooltipObj) =>
                  (tooltipObj?.associatedMetricTooltipText &&
                    tooltipObj?.associatedMetricTooltipText !== "") ||
                  (tooltipObj?.associatedMetricGroupTooltipText &&
                    tooltipObj?.associatedMetricGroupTooltipText !== "")
              ),
          })}
          data-test="display-text-button"
          onClick={() =>
            content.length &&
            content.every(
              (tooltipObj) =>
                (tooltipObj?.associatedMetricTooltipText &&
                  tooltipObj?.associatedMetricTooltipText !== "") ||
                (tooltipObj?.associatedMetricGroupTooltipText &&
                  tooltipObj?.associatedMetricGroupTooltipText !== "")
            ) &&
            setShowPopover(true)
          }
          // ADDED THIS VALIDATION TO PREVENT CLICKING ON POPOVER when there is no content
          // it was breaking the application when no content
        >
          <span data-test="display-text" className={metricClassName}>
            {displayText as React.ReactNode}
          </span>
        </Button>
      )}
      {onHoverToolTip && (
        <div
          className={metricClassName}
          data-test="tooltip-on-enter"
          onMouseEnter={(e) => {
            handleMouseEnter(e, setHyperLinks(`${onHoverToolTipValue}`));
          }}
          onMouseLeave={handleMouseLeave}
        >
          {displayText as React.ReactNode}
        </div>
      )}
      {showPopover && (
        <>
          <div
            ref={popupRef}
            data-test="pop-over-modal"
            className={`${BLOCK}__popup`}
            style={{ ...popoverPosition }}
          >
            <div className={`${BLOCK}__popup-header`}>
              <span className={`${BLOCK}__popup-header-title`}>
                {
                  (content[0].associatedMetricDisplayName
                    ? content[0].associatedMetricDisplayName
                    : displayText) as React.ReactNode
                }
              </span>
              <Button
                iconName={"cross"}
                dataTest="popover-cross-btn"
                data-test="popover-cross-btn"
                className=""
                onClick={() => {
                  setCurrentTab(0);
                  setShowPopover(false);
                }}
              ></Button>
            </div>

            {!hideTabs && (
              <div className={`${BLOCK}__popup-tabs`}>
                <TabbedContent
                  tabItems={tabItems}
                  selectedIndex={currentTab}
                  onChange={(index: number) => {
                    setCurrentTab(index);
                    setPopoverArrowPosition({});
                    setPopoverArrowOutlinePosition({});
                  }}
                  className={`${BLOCK}__tabbed-content`}
                  isPopover={true}
                />
              </div>
            )}

            <div className={`${BLOCK}__popup-body`}>
              {Parser(
                SOURCE_REGEX.reduce(
                  (tooltip, expression, index) =>
                    setSourceLink(expression, tooltip, index),
                  tabItems[currentTab].tabContent!
                )
              )}
            </div>
          </div>

          <div
            className={classNames(`${BLOCK}__popup-arrow-outline`, {
              [`${BLOCK}__popup-arrow--left`]: true,
            })}
            style={{
              ...popoverArrowOutlinePosition,
            }}
          >
            <div
              className={classNames(`${BLOCK}__popup-arrow`, {
                [`${BLOCK}__popup-arrow--left`]: true,
              })}
              style={{ ...popoverArrowPosition }}
            ></div>
          </div>
        </>
      )}
    </div>
  );
};

export default Popover;
