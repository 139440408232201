import { lightColor } from "utils/constants";
import { mod, handleMouseEnter, handleMouseLeave } from "utils/functions";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { RootStore } from "services/store.service";
import { MainTooltipPosition } from "components/shared/main-tooltip/main-tooltip";

const CircularBarChart = ({
  value,
  minValue,
  maxValue,
  subtitle,
  colors,
  avgData,
  companyName,
  tileWidth,
}: {
  value: number;
  minValue: number;
  maxValue: number;
  subtitle: string;
  colors: string[];
  avgData?: any;
  companyName?: string;
  tileWidth?: number | null;
}) => {
  const BLOCK = "circular-bar-chart";
  const {
    isNotCompanyView = false,
    avgValue = "",
    avgName = "",
    avgColorCodes = [],
  } = avgData || {};

  const chartRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();

  //calculates angle in [0-360]degree range within the div the onMouseMoveCapture event is triggered at
  function calculateAngle(
    x: number,
    y: number,
    centerX: number,
    centerY: number
  ): number {
    const angle = Math.atan2(y - centerY, x - centerX) * (180 / Math.PI) + 90;
    return angle >= 0 ? angle : 360 + angle;
  }

  const onHoverfunction = (e: any, children: any) => {
    const donutdiv = chartRef.current?.getBoundingClientRect();
    const centerX =
      donutdiv !== undefined ? donutdiv.left + donutdiv.width / 2 : 0;
    const centerY =
      donutdiv !== undefined ? donutdiv.top + donutdiv.height / 2 : 0;
    const angle = calculateAngle(e.clientX, e.clientY, centerX, centerY);
    const isWithinRange = angle >= 0 && angle <= radiantValue;
    if (isWithinRange && children) {
      return handleMouseEnter(
        e,
        "div",
        <div data-testid="companyToolTip">{children}</div>,
        "DashboardTile__tooltip",
        dispatch,
        null,
        MainTooltipPosition.CircularBarChart
      );
    } else {
      return handleMouseLeave(e, dispatch, false);
    }
  };

  const radiantValue = isNaN(value) ? 0 : (value / maxValue) * 360;

  const colorStep = radiantValue / (colors.length - 1);

  const colorGradient = colors.map(
    (c, i) => `${c} ${i ? colorStep * i : 0}deg`
  );

  const avgRadiantValue = isNaN(avgValue) ? 0 : (avgValue / maxValue) * 360;

  const gradientTextStyle = (colors: string[]) => ({
    background: `linear-gradient(to right, ${colors[0]}, ${
      colors.length > 1 ? colors[1] : colors[0]
    })`,
    WebkitBackgroundClip: "text",
    color: "transparent",
  });

  const legendBg = (colorsArr: string[]) => {
    if (colorsArr.length > 1) {
      return `linear-gradient(45deg, ${colors[0]}, ${colors[1]})`;
    } else {
      return `${colorsArr[0]}`;
    }
  };

  const legendDiv = (companyName: string, value: number, colors: string[]) => {
    return (
      <div
        key={`${companyName}-company-legend`}
        className={`${BLOCK}__legend-company-block`}
      >
        {value === null ? null : (
          <div
            className={`${BLOCK}__legend-company-pill`}
            style={{
              background: legendBg(colors),
            }}
          ></div>
        )}
        <span>{`${companyName}${
          value === null || isNaN(value) ? " *" : ""
        }`}</span>
      </div>
    );
  };

  return (
    <div className={`${BLOCK}__wrapper`} data-testid={BLOCK}>
      <div className={`${BLOCK}__chartContent`}>
        <div className={`${BLOCK}__legend-section`}>
          <span className={`${BLOCK}__subTitle`}>{subtitle}</span>
          {isNotCompanyView ? (
            <div className={`${BLOCK}__legend-companies`} data-testid={`${BLOCK}__legend-companies`}>
              {legendDiv(companyName ?? "", value, colors)}
              {legendDiv(avgName ?? "", avgValue, avgColorCodes)}
            </div>
          ) : null}
        </div>
        <div className={`${BLOCK}__container`}>
          <div className={BLOCK}>
            <div className={`${BLOCK}__range-gap`}></div>
            <div className={`${BLOCK}__half-gap`}></div>
            <div className={`${BLOCK}__min-value`}>
              <DigitSVG value={minValue} />
            </div>
            <div className={`${BLOCK}__max-value`}>
              <DigitSVG value={maxValue} />
            </div>
            <div className={`${BLOCK}__half-value`}>
              <DigitSVG value={parseInt((maxValue / 2).toFixed(0))} />
            </div>
            <div
              className={`${BLOCK}__donut`}
              style={{
                background: `conic-gradient(
                              ${colorGradient.join(",")},
                              ${colors[colors.length - 1]} ${radiantValue}deg,
                              ${lightColor} ${radiantValue}deg 360deg
                          )`,
              }}
              data-testid={`${BLOCK}__donut`}
              onMouseMoveCapture={(e) => {
                if (!isNaN(value)) {
                  let tooltipContent =
                    companyName +
                    " (" +
                    (value % 1 === 0 ? value : value.toFixed(1)) +
                    ")";
                  onHoverfunction(e, tooltipContent);
                }
              }}
              onMouseLeave={(e: any) => {
                handleMouseLeave(e, dispatch, false);
              }}
              ref={chartRef}
            >
              <div
                className={`${BLOCK}__hole`}
                onMouseMoveCapture={(e) => {
                  onHoverfunction(e, null);
                }}
                onMouseLeave={(e: any) => {
                  handleMouseLeave(e, dispatch, false);
                }}
              >
                {isNotCompanyView ? (
                  <div className={`${BLOCK}__hole-container`}>
                    <div className={`${BLOCK}__hole-company`}>
                      <span
                        className={`${BLOCK}__hole-company--value`}
                        style={gradientTextStyle(colors)}
                        data-testid={`${BLOCK}__hole-company--value`}
                        onMouseMoveCapture={(e) => {
                          let tooltipContent;
                          if (!isNaN(value)) {
                            tooltipContent =
                              companyName +
                              " (" +
                              (value % 1 === 0 ? value : value.toFixed(1)) +
                              ")";
                          } else {
                            tooltipContent = companyName + " (*)";
                          }
                          handleMouseEnter(
                            e,
                            "span",
                            <div data-testid="companyToolTip">
                              {tooltipContent}
                            </div>,
                            "DashboardTile__tooltip",
                            dispatch,
                            null,
                            MainTooltipPosition.CircularBarChart
                          );
                        }}
                        onMouseLeave={(e: any) => {
                          handleMouseLeave(e, dispatch, false);
                        }}
                      >
                        {isNaN(value)
                          ? "*"
                          : value % 1 === 0
                          ? value
                          : value.toFixed(1)}
                      </span>
                    </div>
                    <div className={`${BLOCK}__hole-peer`}>
                      <span
                        className={`${BLOCK}__hole-peer--value`}
                        style={gradientTextStyle(avgColorCodes)}
                        data-testid={`${BLOCK}__hole-peer--value`}
                        onMouseMoveCapture={(e) => {
                          let tooltipContent;
                          if (!isNaN(avgValue)) {
                            tooltipContent =
                              avgName +
                              " (" +
                              (avgValue % 1 === 0
                                ? avgValue
                                : avgValue.toFixed(1)) +
                              ")";
                          } else {
                            tooltipContent = avgName + " ( * )";
                          }
                          handleMouseEnter(
                            e,
                            "span",
                            <div data-testid="companyToolTip">
                              {tooltipContent}
                            </div>,
                            "DashboardTile__tooltip",
                            dispatch,
                            null,
                            MainTooltipPosition.CircularBarChart
                          );
                        }}
                        onMouseLeave={(e: any) => {
                          handleMouseLeave(e, dispatch, false);
                        }}
                      >
                        {isNaN(avgValue)
                          ? "*"
                          : avgValue % 1 === 0
                          ? avgValue
                          : avgValue.toFixed(1)}
                      </span>
                    </div>
                  </div>
                ) : (
                  <span
                    className={`${BLOCK}__value`}
                    data-testid={`${BLOCK}__value`}
                    onMouseMoveCapture={(e) => {
                      let tooltipContent;
                      if (!isNaN(value)) {
                        tooltipContent =
                          companyName +
                          " (" +
                          (value % 1 === 0 ? value : value.toFixed(1)) +
                          ")";
                      } else {
                        tooltipContent = companyName + " (*)";
                      }
                      handleMouseEnter(
                        e,
                        "span",
                        <div data-testid="companyToolTip">
                          {tooltipContent}
                        </div>,
                        "DashboardTile__tooltip",
                        dispatch,
                        null,
                        MainTooltipPosition.CircularBarChart
                      );
                    }}
                    onMouseLeave={(e: any) => {
                      handleMouseLeave(e, dispatch, false);
                    }}
                  >
                    <DigitSVG value={parseFloat(value.toFixed(1))} />
                  </span>
                )}
              </div>
              {isNotCompanyView && !isNaN(avgValue) && (
                <div
                  className={`${BLOCK}__peer-arrow`}
                  style={{
                    transform: `rotate(${avgRadiantValue}deg)`, // rotate the arrow according to the peerValue
                  }}
                  data-testid={`${BLOCK}__peer-arrow`}
                  onMouseMoveCapture={(e) => {
                    if (!isNaN(avgValue)) {
                      let tooltipContent =
                        avgName +
                        " (" +
                        (avgValue % 1 === 0 ? avgValue : avgValue.toFixed(1)) +
                        ")";
                      handleMouseEnter(
                        e,
                        "path",
                        <div data-testid="companyToolTip">
                          {tooltipContent}
                        </div>,
                        "DashboardTile__tooltip",
                        dispatch,
                        null,
                        MainTooltipPosition.CircularBarChart
                      );
                    }
                  }}
                  onMouseLeave={(e: any) => {
                    handleMouseLeave(e, dispatch, false);
                  }}
                >
                  <svg width="16">
                    <line
                      x1="8"
                      y1="0"
                      x2="8"
                      y2="100"
                      stroke={legendBg(avgColorCodes)}
                      strokeWidth="4"
                    />
                    <path
                      d="M0 0 L8 15 L16 0 Z"
                      style={{
                        fill: legendBg(avgColorCodes),
                        stroke: "white",
                        strokeWidth: 1,
                      }}
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isNaN(value) || (isNotCompanyView && isNaN(avgValue)) ? (
        <div className={`${BLOCK}__missing-data-msg`} data-testid={`${BLOCK}__missing-data-msg`}>
          <FormattedMessage id="no.data.available" />
        </div>
      ) : null}
    </div>
  );
};

const DigitSVG = ({ value }: { value: number }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 500 100"
    preserveAspectRatio="xMinYmin meet"
  >
    <text x={value >= 10 || mod(value, 1) > 0 ? "10%" : "30%"} y="175%">
      {value}
    </text>
  </svg>
);

export default CircularBarChart;
