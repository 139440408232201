import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Modal from "components/shared/modal/modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setduplicatePeerGroupError } from "services/insights/insights.service";
import { CompanyFilter } from "services/search/filters.model";
import { RootStore } from "services/store.service";
import Button from "../button/button";
import DropdownContainer from "../dropdown-container/dropdown-container";
import PeerCompanies from "../peer-companies/peer-companies";
import {
  GlobalCompanyGroup,
  HybridCompany,
} from "services/insights/insights.model";
type props = {
  setShowModal: (value: boolean) => void;
  dropdownHeadersForSavePeerModal: any;
  handleUnselectCompany: any;
  isdispatchFunction: boolean;
  dispatchFunction: any;
  peerCompanyFilter: CompanyFilter[];
  setSelectedPeerCompany: any;
  setSelectedGlobalPeerCompanies?: any;
  savePeerGroupHandler: any;
  debounceChangeHandler: any;
  globalPeerCompanies?: Partial<GlobalCompanyGroup>[];
  peerOrder?: number;
  setPeerOrder?: Function;
};
const SavedPeerModal = ({
  setShowModal,
  dropdownHeadersForSavePeerModal,
  isdispatchFunction,
  dispatchFunction,
  peerCompanyFilter,
  setSelectedPeerCompany,
  savePeerGroupHandler,
  debounceChangeHandler,
  globalPeerCompanies,
  setSelectedGlobalPeerCompanies,
  peerOrder,
  setPeerOrder,
}: props) => {
  const BLOCK = "save-peer-modal";
  const [disabled, setDisabled] = useState(0);
  const [peerGroupName, setPeerGroupName] = useState<string>("");
  const insightsState = useSelector((state: RootStore) => state.insights);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      ((peerCompanyFilter && peerCompanyFilter.length !== 0) ||
        (globalPeerCompanies && globalPeerCompanies.length !== 0)) &&
      trimStartFunc(peerGroupName) &&
      peerGroupName.length !== 0
    ) {
      setDisabled(1);
    } else {
      setDisabled(0);
    }
  }, [peerCompanyFilter, peerGroupName, globalPeerCompanies]);

  const trimStartFunc = (value: any) => {
    if (!value) return value;
    return value.replace(/^\s+/g, "");
  };

  useEffect(() => {
    dispatch(setduplicatePeerGroupError(false));
    setSelectedPeerCompany(peerCompanyFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (insightsState.insightPeerGroupSuccess) {
      if (isdispatchFunction) {
        dispatch(dispatchFunction(peerCompanyFilter));
      } else {
        dispatchFunction(peerCompanyFilter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightsState.insightPeerGroupSuccess]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPeerGroupName(event.target.value);
    dispatch(setduplicatePeerGroupError(false));
  };

  const saveHandler = () => {
    savePeerGroupHandler(peerGroupName);
  };

  const cancelHandler = () => {
    setSelectedPeerCompany([]);
    if (setSelectedGlobalPeerCompanies) setSelectedGlobalPeerCompanies([]);
    setShowModal(false);
  };

  const unselectCompany = (companyFilter: CompanyFilter, type: string) => {
    let unDeletedPeerCompany = peerCompanyFilter.filter(
      (item: any) => item.cikNumber !== companyFilter.cikNumber
    );
    setSelectedPeerCompany(unDeletedPeerCompany);
  };

  const selectCompany = (companyFilter: CompanyFilter, type: string) => {
    let tmpCompany = { ...companyFilter };
    if (peerOrder && setPeerOrder) {
      let tmpPeerOrder = peerOrder;
      tmpCompany = { ...companyFilter, displayOrder: tmpPeerOrder++ };
      setPeerOrder(tmpPeerOrder);
    }
    setSelectedPeerCompany([...peerCompanyFilter, tmpCompany]);
  };

  const unselectGlobalCompany = (globalCompanyFilter: GlobalCompanyGroup) => {
    if (globalPeerCompanies && setSelectedGlobalPeerCompanies)
      setSelectedGlobalPeerCompanies(
        globalPeerCompanies.filter(
          (g: Partial<GlobalCompanyGroup>) =>
            g.globalCompanyMappingId !==
            globalCompanyFilter.globalCompanyMappingId
        )
      );
  };

  return (
    <Modal
      show={true}
      header={"save.peer.group"}
      handleClose={() => {
        setShowModal(false);
        setSelectedPeerCompany([]);
      }}
      config={false}
    >
      <div className={`${BLOCK}__body`} data-testid="save-peers-modal">
        <div className={`${BLOCK}__body-input`}>
          <span className={`${BLOCK}__text`}>
            <FormattedMessage id="group.name" />
          </span>
          <input
            type="text"
            maxLength={200}
            onChange={onChangeHandler}
            value={peerGroupName}
            name="peerGroupName"
            placeholder="Enter name"
            autoComplete="off"
            data-testid="Insight-name"
          />
        </div>
        {insightsState.duplicatePeerGroupName && (
          <span
            className={`${BLOCK}__contentError`}
            data-testid="duplicate-name-error"
          >
            <FormattedMessage id="duplicate.insight.name.error" />
          </span>
        )}
        <div
          className={`${BLOCK}__dropdown`}
          data-test="benchmark-baseline-company-dropdown"
          data-testid="benchmark-baseline-company-dropdown"
        >
          <DropdownContainer
            BLOCK={"insights__create-new"}
            dropdownLabel={dropdownHeadersForSavePeerModal}
            debounceChangeHandler={debounceChangeHandler}
            selectCompany={selectCompany}
            unselectCompany={unselectCompany}
          />
        </div>
        <div className={`${BLOCK}__peerCompanies-container`}>
          <PeerCompanies
            BLOCK={`${BLOCK}`}
            peerCompanies={peerCompanyFilter}
            unselectCompany={unselectCompany}
            globalPeerCompanies={globalPeerCompanies}
            unselectGlobalCompanyMapping={unselectGlobalCompany}
          />
        </div>
        <div className={`${BLOCK}__options`}>
          <Button
            className={`button-secondary`}
            dataTest="cancel-button"
            onClick={cancelHandler}
            formattedText="shortlist.edit.button.cancel"
          />
          <Button
            className={`button-primary`}
            disabled={disabled === 0}
            dataTest="add-suggested-company-btn"
            formattedText="shortlist.save.button.save"
            onClick={saveHandler}
          />
        </div>
      </div>
    </Modal>
  );
};
export default SavedPeerModal;
