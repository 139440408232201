import api from "services/api.service";

export const fetchESGReports = (payload: any) =>
  api.post(`reports/esg`, payload).then(({ data }) => data);

export const getPendingItems = () => {
  return api.get(`reports/pendingitems`).then(({ data }) => data);
};

export const deleteESGReports = (payload: any) =>
  api
    .delete(`reports/${payload[0].id}/delete`, payload)
    .then(({ data }) => data);

export const uploadFile = (payload: any, options: any) => {
  let formData = new FormData();
  formData.append("", payload);
  return api.post(`reports/upload`, formData, options).then(({ data }) => data);
};

export const processMapFiles = (payload: any) => {
  return api.post(`reports/process`, payload).then(({ data }) => data);
};

export const editESGReport = (payload: any) =>
  api.put(`reports/update`, payload).then(({ data }) => data);

export const cancelMapFiles = (payload: any) =>
  api.post(`reports/process`, payload).then(({ data }) => data);

export const fetchMaintenanceMessages = () => {
  return api.get(`maintenancemessages`).then(({ data }) => data);
};

export const getRegions = () => {
  return api.get(`regions`).then(({ data }) => data);
};

export const getTimezones = () => {
  return api.get(`timezones`).then(({ data }) => data);
};

export const postMaintenanceMessage = (payload: any) =>
  api.post(`maintenancemessages`, payload).then(({ data }) => data);

export const getMaintenanceMessage = () =>
  api.get(`maintenancemessages/banner`).then(({ data }) => data);

export const deleteMaintenanceMessage = (maintenanceMessageId: any) =>
  api
    .delete(`maintenancemessages/${maintenanceMessageId}`)
    .then(({ data }) => data);
