import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showMainTooltip, showTooltip } from "services/commons.service";
import { RootStore } from "services/store.service";

export enum MainTooltipPosition {
  BottomMiddle = "bottom-middle",
  BottomLeft = "bottom-left",
  BottomRight = "bottom-right",
  TopMiddle = "top-middle",
  TopLeft = "top-left",
  TopRight = "top-right",
  RightMiddle = "right-middle",
  LeftMiddle = "left-middle",
  CircularBarChart = "circular-bar-chart",
}

enum Alignment {
  Left = "Left",
  Right = "Right",
}

type Props = {
  children: any;
  position: MainTooltipPosition;
  elementDimensions: DOMRect;
  hideArrow?: boolean;
  executeMouseLeaveEvent?: boolean;
};

const MainTooltip = ({
  children,
  position,
  hideArrow,
  elementDimensions,
  executeMouseLeaveEvent,
}: Props) => {
  const BLOCK = "main-tooltip";
  const MAX_TOOLTIP_WIDTH = 288;
  const MAX_TOOLTIP_HEIGHT = 400;
  const SPACING = 16;
  const [finalPosition, setFinalPosition] = useState<any>(position);
  const [positionStyles, setPositionStyles] = useState<any>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const [customHeightStyles, setCustomHeightStyles] = useState<any>({});
  const mainTooltip = useSelector(
    (store: RootStore) => store.commons.mainTooltip
  );
  const dispatch = useDispatch();
  const [repositioningCompleted, setRepositioningCompleted] =
    useState<boolean>(false);

  useEffect(() => {
    //initial positioning
    setPositionStyles({
      ...getPredefinedPositionStyles(position),
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      dispatch(
        showMainTooltip({
          children: null,
          position: null,
          elementDimensions: null,
          executeMouseLeaveEvent: null,
          isOverTooltip: null,
        })
      );
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    if (!repositioningCompleted && Object.keys(positionStyles).length > 0) {
      reposition();
    }
  }, [containerRef.current, positionStyles]);

  const getPredefinedPositionStyles = (tmpPosition: MainTooltipPosition) => {
    let tooltipHeight =
      containerRef && containerRef.current
        ? containerRef.current?.clientHeight
        : MAX_TOOLTIP_HEIGHT;
    let tooltipWidth =
      containerRef && containerRef.current
        ? containerRef.current.clientWidth
        : MAX_TOOLTIP_WIDTH;
    const offset = 100;
    switch (tmpPosition) {
      case MainTooltipPosition.BottomMiddle:
        return {
          top: `${elementDimensions.top + elementDimensions.height}px`,
          bottom: "unset",
          right: "unset",
          left: `${
            elementDimensions.left +
            elementDimensions.width / 2 -
            tooltipWidth / 2
          }px`,
        };
      case MainTooltipPosition.BottomLeft:
        return {
          top: `${elementDimensions.top + elementDimensions.height}px`,
          bottom: "unset",
          right: "unset",
          left: `${
            elementDimensions.left + elementDimensions.width / 2 - tooltipWidth
          }px`,
        };

      case MainTooltipPosition.BottomRight:
        return {
          top: `${elementDimensions.top + elementDimensions.height}px`,
          bottom: "unset",
          right: "unset",
          left: `${elementDimensions.left + elementDimensions.width / 2}px`,
        };

      case MainTooltipPosition.TopMiddle:
        return {
          top: `${elementDimensions.top - SPACING - tooltipHeight}px`,
          bottom: "unset",
          right: "unset",
          left: `${
            elementDimensions.left +
            elementDimensions.width / 2 -
            tooltipWidth / 2
          }px`,
        };

      case MainTooltipPosition.TopLeft:
        return {
          top: `${elementDimensions.top - tooltipHeight}px`,
          bottom: "unset",
          right: "unset",
          left: `${
            elementDimensions.left + elementDimensions.width / 2 - tooltipWidth
          }px`,
        };

      case MainTooltipPosition.TopRight:
        return {
          top: `${elementDimensions.top - tooltipHeight}px`,
          bottom: "unset",
          right: "unset",
          left: `${elementDimensions.left + elementDimensions.width / 2}px`,
        };

      case MainTooltipPosition.RightMiddle:
        return {
          top: `${
            elementDimensions.top +
            elementDimensions.height / 2 -
            tooltipHeight / 2
          }px`,
          bottom: "unset",
          right: "unset",
          left: `${elementDimensions.right + SPACING}px`,
        };

      case MainTooltipPosition.LeftMiddle:
        return {
          top: `${
            elementDimensions.top +
            elementDimensions.height / 2 -
            tooltipHeight / 2
          }px`,
          bottom: "unset",
          right: "unset",
          left: `${elementDimensions.left - SPACING - tooltipWidth}px`,
        };
      case MainTooltipPosition.CircularBarChart:
        return {
          top: `${elementDimensions.top + elementDimensions.height}px`,
          bottom: "unset",
          right: "unset",
          left: `${
            elementDimensions.x + elementDimensions.width / 2 - offset / 2
          }px`,
        };

      default:
        return {
          top: null,
          bottom: null,
          right: null,
          left: null,
        };
    }
  };

  const isValidPosition = (
    tmpRect: {
      top: number;
      right: number;
      bottom: number;
      left: number;
    },
    hasScroll: boolean
  ) => {
    let tmpWindowHeight = hasScroll
      ? document.body.scrollHeight
      : window.innerHeight;
    return (
      tmpRect.top > 0 &&
      tmpRect.right < window.innerWidth &&
      tmpRect.bottom < tmpWindowHeight &&
      tmpRect.left > 0
    );
  };

  const reposition = () => {
    if (containerRef && containerRef.current) {
      let currentBoundaries = containerRef.current.getBoundingClientRect();
      //Validate initial position
      if (
        isValidPosition(
          {
            top: currentBoundaries.top,
            right: currentBoundaries.right,
            bottom: currentBoundaries.bottom,
            left: currentBoundaries.left,
          },
          false
        )
      ) {
        setRepositioningCompleted(true);
        return;
      } else {
        // Tooltip height greater than inner height
        if (currentBoundaries.height > window.innerHeight) {
          // is Overflowing
          if (document.body.scrollHeight > document.body.clientHeight) {
            //is Tooltip height greater than scrollHeight
            if (currentBoundaries.height > document.body.scrollHeight) {
              // set max height to 90%
              let newHeight = window.innerHeight * 0.85;
              setCustomHeightStyles({
                height: `${newHeight}px`,
                overflow: "auto",
              });
              setPositionStyles(
                buildCustomPosition(newHeight, currentBoundaries.width)
              );
              setRepositioningCompleted(true);
              return;
            } else {
              if (
                isValidPosition(
                  {
                    top: currentBoundaries.top,
                    right: currentBoundaries.right,
                    bottom: currentBoundaries.bottom,
                    left: currentBoundaries.left,
                  },
                  true
                )
              ) {
                return;
              } else {
                let suggestedPosition = getSuggestedPredefinedPositions(
                  currentBoundaries,
                  finalPosition,
                  true
                );
                //Validate suggested predefined position
                if (
                  validateSuggestedPredefinedPosition(
                    currentBoundaries,
                    suggestedPosition,
                    true
                  )
                ) {
                  //Set accurate position

                  setPositionStyles({
                    ...getPredefinedPositionStyles(suggestedPosition),
                  });
                  setRepositioningCompleted(true);
                  return;
                } else {
                  setPositionStyles(
                    buildCustomPosition(
                      currentBoundaries.height,
                      currentBoundaries.width,
                      true
                    )
                  );
                  setRepositioningCompleted(true);
                  return;
                }
              }
            }
          } else {
            // set max height to 90%
            let newHeight = window.innerHeight * 0.85;
            setCustomHeightStyles({
              height: `${newHeight}px`,
              overflow: "auto",
            });

            setPositionStyles(
              buildCustomPosition(newHeight, currentBoundaries.width)
            );
            setRepositioningCompleted(true);
            return;
          }
        }
        //tooltip smaller than inner height
        else {
          let suggestedPosition = getSuggestedPredefinedPositions(
            currentBoundaries,
            finalPosition,
            false
          );
          //Validate suggested predefined position
          if (
            validateSuggestedPredefinedPosition(
              currentBoundaries,
              suggestedPosition
            )
          ) {
            //Set accurate position
            setPositionStyles({
              ...getPredefinedPositionStyles(suggestedPosition),
            });
            setRepositioningCompleted(true);
            return;
          } else {
            setPositionStyles(
              buildCustomPosition(
                currentBoundaries.height,
                currentBoundaries.width
              )
            );
            setRepositioningCompleted(true);
            return;
          }
        }
      }
    }
  };

  const buildCustomPosition = (
    newHeight: number,
    newWidth: number,
    hasScroll: boolean = false
  ) => {
    let halfScreenXCoord = window.innerWidth / 2;
    let halfScreenYCoord = hasScroll
      ? document.body.scrollHeight / 2
      : window.innerHeight / 2;

    let halfElementXCoord =
      elementDimensions.left + elementDimensions.width / 2;
    let halfElementYCoord =
      elementDimensions.top + elementDimensions.height / 2;
    let tmpCustomStyles = {};

    //centered
    if (
      halfElementXCoord === halfScreenXCoord &&
      halfElementYCoord === halfScreenYCoord
    ) {
      tmpCustomStyles = getCustomSytles(newHeight, newWidth, Alignment.Right);
    } else {
      //right - center
      if (
        (halfElementXCoord > halfScreenXCoord &&
          halfElementYCoord === halfScreenYCoord) ||
        // right - bottom
        (halfElementXCoord > halfScreenXCoord &&
          halfElementYCoord > halfScreenYCoord) ||
        // right - top
        (halfElementXCoord > halfScreenXCoord &&
          halfElementYCoord < halfScreenYCoord) ||
        // center - bottom
        (halfElementXCoord === halfScreenXCoord &&
          halfElementYCoord > halfScreenYCoord)
      ) {
        tmpCustomStyles = getCustomSytles(newHeight, newWidth, Alignment.Left);
      }
      //left - center
      else if (
        (halfElementXCoord < halfScreenXCoord &&
          halfElementYCoord === halfScreenYCoord) ||
        // left - bottom
        (halfElementXCoord < halfScreenXCoord &&
          halfElementYCoord > halfScreenYCoord) ||
        // left - top
        (halfElementXCoord < halfScreenXCoord &&
          halfElementYCoord < halfScreenYCoord) ||
        // center - top
        (halfElementXCoord === halfScreenXCoord &&
          halfElementYCoord < halfScreenYCoord)
      ) {
        tmpCustomStyles = getCustomSytles(newHeight, newWidth, Alignment.Right);
      }
    }
    return tmpCustomStyles;
  };

  const getCustomSytles = (
    newHeight: number,
    newWidth: number,
    alignment: Alignment
  ) => {
    switch (alignment) {
      case Alignment.Left: {
        return {
          top: `${(window.innerHeight - newHeight) / 2}px`,
          bottom: "unset",
          right: "unset",
          left: `${elementDimensions.left - SPACING - newWidth}px`,
        };
      }
      //Right alignment
      default:
        return {
          top: `${(window.innerHeight - newHeight) / 2}px`,
          bottom: "unset",
          right: "unset",
          left: `${
            elementDimensions.left + elementDimensions.width + SPACING
          }px`,
        };
    }
  };

  const validateSuggestedPredefinedPosition = (
    currentBoundaries: DOMRect,
    currentPosition: MainTooltipPosition,
    hasScroll: boolean = false
  ) => {
    //Build new boundaries
    let tmpBoundaries = getNewBoundaries(
      currentBoundaries.width,
      currentBoundaries.height,
      currentPosition
    );

    let tmpCurrentBoundaries = {
      top: tmpBoundaries.top ? tmpBoundaries.top : currentBoundaries.top,
      right: tmpBoundaries.right
        ? tmpBoundaries.right
        : currentBoundaries.right,
      bottom: tmpBoundaries.bottom
        ? tmpBoundaries.bottom
        : currentBoundaries.bottom,
      left: tmpBoundaries.left ? tmpBoundaries.left : currentBoundaries.left,
      x: tmpBoundaries.left ? tmpBoundaries.left : currentBoundaries.left,
      y: tmpBoundaries.top ? tmpBoundaries.top : currentBoundaries.top,
      height: currentBoundaries.height,
      width: currentBoundaries.width,
    };
    return isValidPosition(tmpCurrentBoundaries, hasScroll);
  };

  const getNewBoundaries = (
    width: number,
    height: number,
    tmpPosition: MainTooltipPosition
  ) => {
    let halfHeight = height / 2;
    let halfWidth = width / 2;

    switch (tmpPosition) {
      case MainTooltipPosition.BottomMiddle:
        return {
          top: elementDimensions.top + elementDimensions.height + SPACING,
          bottom:
            elementDimensions.top + elementDimensions.height + SPACING + height,
          right:
            elementDimensions.left + elementDimensions.width / 2 + halfWidth,
          left:
            elementDimensions.left + elementDimensions.width / 2 - halfWidth,
        };

      case MainTooltipPosition.BottomLeft:
        return {
          top: elementDimensions.top + elementDimensions.height + SPACING,
          bottom:
            elementDimensions.top + elementDimensions.height + SPACING + height,
          right: elementDimensions.left + elementDimensions.width / 2,
          left: elementDimensions.left - halfWidth,
        };

      case MainTooltipPosition.BottomRight:
        return {
          top: elementDimensions.top + elementDimensions.height + SPACING,
          bottom:
            elementDimensions.top + elementDimensions.height + SPACING + height,
          right: elementDimensions.left + elementDimensions.width / 2 + width,
          left: elementDimensions.left + elementDimensions.width / 2,
        };

      case MainTooltipPosition.TopMiddle:
        return {
          top: elementDimensions.top - SPACING - height,
          bottom: elementDimensions.top - SPACING,
          right:
            elementDimensions.left + elementDimensions.width / 2 + halfWidth,
          left:
            elementDimensions.left + elementDimensions.width / 2 - halfWidth,
        };

      case MainTooltipPosition.TopLeft:
        return {
          top: elementDimensions.top - SPACING - height,
          bottom: elementDimensions.top - SPACING,
          right: elementDimensions.left + elementDimensions.width / 2,
          left: elementDimensions.left - halfWidth,
        };

      case MainTooltipPosition.TopRight:
        return {
          top: elementDimensions.top - SPACING - height,
          bottom: elementDimensions.top - SPACING,
          right: elementDimensions.left + elementDimensions.width / 2 + width,
          left: elementDimensions.left + elementDimensions.width / 2,
        };

      case MainTooltipPosition.RightMiddle:
        return {
          top:
            elementDimensions.top + elementDimensions.height / 2 - halfHeight,
          bottom:
            elementDimensions.top + elementDimensions.height / 2 + halfHeight,
          right:
            elementDimensions.left + elementDimensions.width + SPACING + width,
          left: elementDimensions.left + elementDimensions.width + SPACING,
        };

      case MainTooltipPosition.LeftMiddle:
        return {
          top:
            elementDimensions.top + elementDimensions.height / 2 - halfHeight,
          bottom:
            elementDimensions.top + elementDimensions.height / 2 + halfHeight,
          right: elementDimensions.left - SPACING,
          left: elementDimensions.left - SPACING - width,
        };

      default:
        return {
          top: null,
          bottom: null,
          right: null,
          left: null,
        };
    }
  };

  const getSuggestedPredefinedPositions = (
    currentBoundaries: DOMRect,
    currentPosition: MainTooltipPosition,
    hasScroll: boolean
  ) => {
    let tmpWindowHeight = hasScroll
      ? document.body.scrollHeight
      : window.innerHeight;
    // bottom overlapping
    if (
      currentBoundaries.bottom > tmpWindowHeight &&
      currentBoundaries.right < window.innerWidth &&
      currentBoundaries.left > 0 &&
      currentBoundaries.top > 0
    ) {
      return MainTooltipPosition.TopMiddle;
    }
    // bottom left overlapping
    else if (
      currentBoundaries.bottom > tmpWindowHeight &&
      currentBoundaries.right < window.innerWidth &&
      currentBoundaries.left < 0 &&
      currentBoundaries.top > 0
    ) {
      return MainTooltipPosition.TopRight;
    }
    // bottom right overlapping
    else if (
      currentBoundaries.bottom > tmpWindowHeight &&
      currentBoundaries.right > window.innerWidth &&
      currentBoundaries.left > 0 &&
      currentBoundaries.top > 0
    ) {
      return MainTooltipPosition.TopLeft;
    }
    //top overlapping
    else if (
      currentBoundaries.bottom < tmpWindowHeight &&
      currentBoundaries.right < window.innerWidth &&
      currentBoundaries.left > 0 &&
      currentBoundaries.top < 0
    ) {
      return MainTooltipPosition.BottomMiddle;
    }
    //top left overlapping
    else if (
      currentBoundaries.bottom < tmpWindowHeight &&
      currentBoundaries.right < window.innerWidth &&
      currentBoundaries.left < 0 &&
      currentBoundaries.top < 0
    ) {
      return MainTooltipPosition.BottomRight;
    }
    //top right overlapping
    else if (
      currentBoundaries.bottom < tmpWindowHeight &&
      currentBoundaries.right > window.innerWidth &&
      currentBoundaries.left > 0 &&
      currentBoundaries.top < 0
    ) {
      return MainTooltipPosition.BottomLeft;
    }
    //left overlapping
    else if (
      currentBoundaries.bottom < tmpWindowHeight &&
      currentBoundaries.right < window.innerWidth &&
      currentBoundaries.left < 0 &&
      currentBoundaries.top > 0
    ) {
      return MainTooltipPosition.RightMiddle;
    }
    //right overlapping
    else if (
      currentBoundaries.bottom < tmpWindowHeight &&
      currentBoundaries.right > window.innerWidth &&
      currentBoundaries.left > 0 &&
      currentBoundaries.top > 0
    ) {
      return MainTooltipPosition.LeftMiddle;
    } else {
      return currentPosition;
    }
  };

  const handleMouseLeave = (e: any) => {
    if (executeMouseLeaveEvent)
      dispatch(
        showMainTooltip({
          children: null,
          position: null,
          elementDimensions: null,
          executeMouseLeaveEvent: null,
          isOverTooltip: null,
        })
      );
  };

  return (
    <div
      className={classNames(`${BLOCK}`)}
      ref={containerRef}
      style={{
        ...positionStyles,
        ...customHeightStyles,

        visibility: repositioningCompleted ? "visible" : "hidden",
      }}
      onMouseEnter={(e) => {
        if (executeMouseLeaveEvent)
          dispatch(showMainTooltip({ ...mainTooltip, isOverTooltip: true }));
      }}
      onMouseLeave={handleMouseLeave}
    >
      {hideArrow ? null : (
        <div className={`${BLOCK}__arrow ${BLOCK}--${position}__arrow`}></div>
      )}
      <div className={`${BLOCK}__label`}>{children}</div>
    </div>
  );
};

export default MainTooltip;
