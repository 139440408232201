import api from "services/api.service";
import { ApplicationAccess } from "./module-access.model";

export const getAppModuleAccessList = () => {
  return api.get(`appmodules`).then(({ data }) => data);
};

export const updateModuleAccess = (updatedModule: ApplicationAccess[]) => {
  return api.put(`appmodules`, updatedModule).then(({ data }) => data);
};
